import axiosClient from 'api/axiosClient'
import { TProperty } from 'types/businessLogic/site'
import { TRecentSiteEvent, TSiteEvent } from 'types/businessLogic/siteEvent'
import { TFetchParamsRequest, TGroupParams, TGroupResponse } from 'types/network'

class SiteEventsService {
    private static _api = axiosClient()

    public static async getSiteEvents(params: TFetchParamsRequest): Promise<{siteEvents: TSiteEvent[], totalNumber: number}> {
        const {GroupedView, ...rest} = params
        const response = await this._api.get<{siteEvents: TSiteEvent[], totalNumber: number}>('SiteEvents', {
            params: {
                ...rest,
                groupResolved : GroupedView
            }
        })
        return response.data
    }

    public static async getSiteEvent(id: number): Promise<TSiteEvent> {
        const response = await this._api.get<TSiteEvent>(`SiteEvents/${id}`)
        return response.data
    }

    public static async getSiteEventProperties(): Promise<TProperty[]> {
        const response = await this._api.get<TProperty[]>('SiteEvents/Properties')
        return response.data
    }

    public static async getSiteEntityGroups(params: TGroupParams) {
        const response = await this._api.get<TGroupResponse>(`SiteEvents/groups/${params.groupBy}`, {
            params: {
                Descending: params.descending,
                Filters: params.filters,
                PageSize: params.pageSize,
                PageNumber: params.pageNumber
            }
        })
        return response.data
    }

    public static async updateSiteEvent(id: number, labels: (string | number)[]) : Promise<void> {
        const response = await this._api.post(`SiteEvents/Update/${id}`, {
            Labels: labels
        })
        return response.data
    }

    public static async getRecentSiteEvents(amount: number): Promise<TRecentSiteEvent[]> {
        const response = await this._api.get<TRecentSiteEvent[]>('SiteEvents/RecentEvents', {
            params: {
                amount
            }
        })
        return response.data
    }
}

export default SiteEventsService