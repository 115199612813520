import React from 'react'
import { ESiteState, ETableFilters } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'
import TransMessage from 'components/TransMessage/TransMessage'
import AppConstants from 'helpers/AppConstants'

const columnConfig: TTableColumnsConfig = {
    name: {
        key: 'name',
        translationKey: i18nKeys.site_list_table_head_name.id,
        sortKey: 'Name',
        displayed: true,
        filter: {
            key: 'Name',
            type: ETableFilters.INPUT_TEXT
        }
    },
    location: {
        key: 'location',
        translationKey: i18nKeys.site_list_table_head_location.id,
        sortKey: 'Location',
        groupKey: 'Location',
        displayed: false,
        filter: {
            key: 'Location',
            type: ETableFilters.INPUT_TEXT
        }
    },
    state: {
        key: 'state',
        translationKey: i18nKeys.site_list_table_head_state.id,
        sortKey: 'State',
        groupKey: 'State',
        displayed: false,
        filter: {
            key: 'State',
            type: ETableFilters.DROPDOWN,
            dropdownItems: [
                {
                    text: <TransMessage {...i18nKeys.dropdown_selection_none} />,
                    value: ''
                },
                {
                    text: <TransMessage {...i18nKeys.site_states_healthy} />,
                    value: ESiteState.HEALTHY
                },
                {
                    text: <TransMessage {...i18nKeys.site_states_offline} />,
                    value: ESiteState.OFFLINE
                },
                {
                    text: <TransMessage {...i18nKeys.site_states_warning} />,
                    value: ESiteState.WARNING
                },
                {
                    text: <TransMessage {...i18nKeys.site_states_updating} />,
                    value: ESiteState.UPDATING
                },
                {
                    text: <TransMessage {...i18nKeys.site_states_vms_not_reachable} />,
                    value: ESiteState.VMS_NOT_REACHABLE
                }
            ]
        }
    },
    serverVersion: {
        key: 'vmsServerVersion',
        translationKey: i18nKeys.site_list_table_head_server_version.id,
        sortKey: 'VMSServerVersion',
        groupKey: 'VMSServerVersion',
        displayed: true,
        filter: {
            key: 'VMSServerVersion',
            type: ETableFilters.INPUT_TEXT
        }
    },
    serverId: {
        key: 'vmsServerId',
        translationKey: i18nKeys.site_list_table_head_server_id.id,
        sortKey: 'VMSServerID',
        displayed: true,
        filter: {
            key: 'VMSServerID',
            type: ETableFilters.INPUT_TEXT
        }
    },
    machineCode: {
        key: 'machineCode',
        translationKey: i18nKeys.site_list_table_head_machine_code.id,
        sortKey: 'MachineCode',
        displayed: true,
        filter: {
            key: 'MachineCode',
            type: ETableFilters.INPUT_TEXT
        }
    },
    labels: {
        key: 'labels',
        translationKey: i18nKeys.site_list_table_head_labels.id,
        displayed: true,
        filter: {
            key: 'Labels',
            type: ETableFilters.INPUT_TEXT
        }
    },
    approved: {
        key: 'approved',
        translationKey: i18nKeys.site_list_table_head_approved.id,
        sortKey: 'Approved',
        groupKey: 'Approved',
        displayed: false,
        filter: {
            key: 'Approved',
            type: ETableFilters.DROPDOWN,
            dropdownItems: [
                {
                    text: <TransMessage {...i18nKeys.dropdown_selection_none} />,
                    value: ''
                },
                {
                    text: <TransMessage {...i18nKeys.dropdown_selection_true} />,
                    value: true
                },
                {
                    text: <TransMessage {...i18nKeys.dropdown_selection_false} />,
                    value: false
                }
            ]
        }
    },
    type: {
        key: 'type',
        translationKey: i18nKeys.site_list_table_head_type.id,
        sortKey: 'Type',
        groupKey: 'Type',
        displayed: false,
        filter: {
            key: 'Type',
            type: ETableFilters.INPUT_TEXT
        }
    },
    version: {
        key: 'version',
        translationKey: i18nKeys.site_list_table_head_version.id,
        sortKey: 'Version',
        groupKey: 'Version',
        displayed: false,
        filter: {
            key: 'Version',
            type: ETableFilters.INPUT_TEXT
        }
    },
    clientVersion: {
        key: 'vmsClientVersion',
        translationKey: i18nKeys.site_list_table_head_client_version.id,
        sortKey: 'VMSClientVersion',
        groupKey: 'VMSClientVersion',
        displayed: false,
        filter: {
            key: 'VMSClientVersion',
            type: ETableFilters.INPUT_TEXT
        }
    },
    lastHeartBeat: {
        key: 'lastHeartBeat',
        translationKey: i18nKeys.site_list_table_head_last_heartbeat.id,
        sortKey: 'LastHeartBeat',
        displayed: false
    },
    ip: {
        key: 'ip',
        translationKey: i18nKeys.site_list_table_head_ip.id,
        sortKey: 'IP',
        displayed: false,
        filter: {
            key: 'IP',
            type: ETableFilters.INPUT_TEXT
        }
    },
    host: {
        translationKey: i18nKeys.site_list_table_head_host.id,
        key: 'host',
        sortKey: 'Host',
        displayed: false,
        filter: {
            key: 'Host',
            type: ETableFilters.INPUT_TEXT
        }
    }
}

const defaultLayout = [
    columnConfig.name.key,
    columnConfig.serverVersion.key,
    columnConfig.serverId.key,
    columnConfig.machineCode.key,
    columnConfig.labels.key
]

export const tableInfo: TTableInfo = {
    id: AppConstants.tableIds.SITE_LICENCES,
    defaultLayout,
    columnConfig
}