import { SiteEntityService } from 'services/siteEntityService'
import { TPropertyValue } from 'types/businessLogic/site'

export type TEventConfig = {
    propertyValues : TPropertyValue[]
    ids: number[]
}

export type TEventConfigResponse = {
    vmseventConfigs : TEventConfig[]
}

class SiteEventConfigService extends SiteEntityService {
    constructor() {
        super('SiteEventConfigs')
    }

    public async getEventConfigsForSites(ids: number[]) : Promise<TEventConfigResponse> {
        const response = await this._api.post<TEventConfigResponse>('SiteEventConfigs/CommonVMSEventConfigs', {
            Ids: ids
        })
        return response.data
    }
    
}

export const siteEventConfigService = new SiteEventConfigService()