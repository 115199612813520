import Icon, { Icons } from 'components/Icon/Icon'
import useAuthorization from 'hooks/useAuthorization'
import { TTranslationKey } from 'types/ui/translation'
import React, { PropsWithChildren, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { EProfileRights } from 'enums/enums'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import styled from 'styled-components'

type TWrapperProps = {
    open : boolean
    active: boolean
}

const Wrapper = styled.div<TWrapperProps>`
    .parent {
        cursor: pointer;
        margin: 0 auto 8px auto;
        min-height: 32px;
        border-radius: ${borderRadius.SECONDARY};
        width: calc(100% - 16px);
        color: ${colors.primary.LIGHT_PRIMARY};
        fill: ${colors.primary.LIGHT_PRIMARY};
        font-family: LatoMedium;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.6px;
        display: flex;
        align-items: center;
        padding: 12px 8px 12px 16px;   ;
        background-color: ${p => p.active ? colors.primary.DEFAULT_PRIMARY : 'transparent'};
        fill: ${colors.primary.LIGHT_PRIMARY};
        
        & > svg {
            min-width: 24px;
        }
   
        & > .indicator {
            transform: ${p => p.open ? 'rotate(180deg)' : 'rotate(0)'};
        }

        & > span {
            padding-left: 32px;
            flex: 1 1 auto;
        }

        &:hover {
            color: ${colors.shades.PURE_WHITE};

            & > svg {
                fill: ${colors.shades.PURE_WHITE};
            }
        }
    }

    .content {
        padding-left: 32px;
    }
`

type TProps = {
    id: string
    icon: string | JSX.Element
    label: TTranslationKey
    rights?: EProfileRights[]
    collapsed: boolean
    to: string
}

const ListMenuItem = (props: PropsWithChildren<TProps>) => {
    const [open, setOpen] = useState(false)
    const checkAuth = useAuthorization()
    const location = useLocation()

    const renderIcon = () => {
        if (typeof props.icon === 'string') {
            return <Icon d={props.icon} />
        }

        return props.icon
    }

    if (checkAuth(...(props.rights ?? []))) {
        return <Wrapper open={open && !props.collapsed} active={location.pathname.startsWith(props.to)}>
            <div id={props.id} className='parent' onClick={() => setOpen(!open)}>
                {renderIcon()}
                <span>
                    <FormattedMessage {...props.label} />
                </span>
                <Icon d={Icons.ARROW_RIGHT} className='indicator' />
            </div>
            
            {(open && !props.collapsed) && <div className='content'> {props.children} </div> }
            
        </Wrapper>
    }

    return null
    
}

export default ListMenuItem