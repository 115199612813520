import { useModal } from 'hooks/modalProvider'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { EMessageType } from 'enums/enums'
import FormHelper from 'helpers/FormHelper'
import GeneralHelper from 'helpers/GeneralHelper'
import { i18nKeys } from 'i18n/keys'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { siteEventConfigService } from 'services/siteEventConfigsService'
import { showMessage } from 'state/slices/uiSlice'
import { pushItem, setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import { dispatch } from 'state/store'
import { TChangePropertyValuesRequest } from 'types/businessLogic/job'
import { TQueryFilter } from 'types/network'
import { TAdvancedJobConfigFields, TPropertyValueForm } from 'types/ui/tab'
import { TUploadManagerItem } from 'types/ui/uploadManager'
import SystemMesagesForm from 'views/Sites/Modals/SystemMessages/SystemMesagesForm'
import { TSystemMessageFormInfo } from 'views/Sites/Modals/SystemMessages/TSystemMessageFormInfo'

type Props = {
    ids: number[] | string[]
    filters?: TQueryFilter[] | undefined
}

const SystemMessagesModal = (props: Props) => {
    const [formInfos, setFormInfos] = useState<TSystemMessageFormInfo[]>()
    const intl = useIntl()
    const {closeModal} = useModal()

    useEffect(() => {
        siteEventConfigService.getEventConfigsForSites(props.ids as number[]).then(res => {
            const newFormInfos :TSystemMessageFormInfo[] = []

            res.vmseventConfigs.forEach(config => {
                const name = config.propertyValues.find(x => x.name === 'Name')
                const groupName = config.propertyValues.find(x => x.name === 'GroupName')
                const formValues = FormHelper.convertToPropertyValueForm(config.propertyValues.filter(x => x.name !== 'Name' && x.name !== 'GroupName'))
                
                if (name && groupName) {
                    newFormInfos.push({
                        name: name.stringValue!,
                        groupName: groupName.stringValue!,
                        ids: config.ids,
                        formValues
                    })
                }
            })
            
            setFormInfos(newFormInfos)
        }).catch(() => {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        })
    }, [props.ids])

    const groupPropertyValueForm = (values: TPropertyValueForm[]) : { ids: number[], values: TPropertyValueForm[] }[] => {
        const result : { ids: number[], values: TPropertyValueForm[] }[] = []

        values.forEach(value => {
            const ids = formInfos?.find(x => x.name === value.groupedBy)?.ids ?? []
            
            if (ids !== []) {
                const item = result.find(x => JSON.stringify(x.ids) === JSON.stringify(ids))
                if (item) {
                    item.values.push(value)
                } else {
                    result.push({
                        ids,
                        values: [value]
                    })
                }
            }
        })

        return result
    }

    const handleOnSubmit = async (values: TPropertyValueForm[], advancedJobConfig: TAdvancedJobConfigFields, comment: string) => {
        let uploadManagerItem : TUploadManagerItem

        try {
            const numberofJobs = (props.ids as number[]).length * values.length

            const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_change_system_message_config,
                {count: advancedJobConfig.undoTimeEnabled ? numberofJobs * 2 : numberofJobs})
                    
            uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)
            
            const groupedValues = groupPropertyValueForm(values)

            groupedValues.forEach(async (value) => {
                const p: TChangePropertyValuesRequest = {
                    changePropertyValues: GeneralHelper.transformPropertyValues(value.ids, FormHelper.convertToPropertyValue(value.values)),
                    comment,
                    ...advancedJobConfig
                }
                
                await siteEventConfigService.changePropertyValues(p)
            })

            dispatch(pushItem(uploadManagerItem))

            closeModal()
            dispatch(showMessage({message: i18nKeys.create_card_holder_success_message, type: EMessageType.SUCCESS}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id}))
        } catch {
            dispatch(showMessage({message: i18nKeys.create_card_holder_error_message, type: EMessageType.ERROR}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem!.id, isError: true}))
        }
    }

    if (!formInfos) {
        return <></>
    }

    return (
        <SystemMesagesForm
            formInfos={formInfos}
            onSubmit={handleOnSubmit}
        />
    )
}
export default SystemMessagesModal