import React, { useCallback, useEffect, useState } from 'react'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import styled from 'styled-components'
import { TGroup, TTableFetchState } from 'types/network'
import { TTableGroup, TTableRowData, TTableColumnProperty, TTableInfo } from 'types/ui/table'
import useAuthorization from 'hooks/useAuthorization'
import { EDataSyncItems, EProfileRights, EVMSEntityType } from 'enums/enums'
import SelectionBox from 'components/Tables/SelectionBox'
import { i18nKeys } from 'i18n/keys'
import useLicense from 'hooks/useLicense'
import { ELicenseTypes } from 'enums/ELicenseTypes'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import TransMessage from 'components/TransMessage/TransMessage'
import { Icons } from 'components/Icon/Icon'
import ColumnPreset from 'components/Tables/ColumnPreset'
import ColumnChooser from 'components/ColumnChooser/ColumnChooser'
import TableHelper from 'components/Tables/TableHelper'
import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import Toolbar from 'components/Toolbar/Toolbar'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import { useAppSelector } from 'state/store'
import { useModal } from 'hooks/modalProvider'
import CommonPropertiesModal from 'components/CommonPropertiesModal/CommonPropertiesModal'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`

type TProps = {
    data: TTableRowData[]
    groups: TTableGroup<TTableRowData>[]
    totalCount: number
    tableInfo: TTableInfo
    getTableData: (params: TTableFetchState) => void
    getGroupData: (params: TTableFetchState, group: TGroup) => void
    dynamicPropertiesColumns: TTableColumnProperty[]
}

const SiteServices = (props: TProps) => {
    const { tableInfo, data, totalCount, groups, dynamicPropertiesColumns, getTableData, getGroupData } = props
    const [identifier] = useState(uid())
    const {showModal, isModalOpen} = useModal()
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)
    const { situatorExclusive } = useAppSelector(store => store.license)

    const colHandlerProps = useColumnsHandler({
        ...tableInfo,
        dynamicColumns: dynamicPropertiesColumns
    })

    const enhanceTableProps = useEnhanceTable({
        groups
    })
    
    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
        enhanceTableProps.selectedGroups.forEach(group => {
            getGroupData(enhanceTableProps.fetchState, group)
        })
    }, [enhanceTableProps.fetchState, enhanceTableProps.selectedGroups, getGroupData, getTableData])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.SITE_SERVICES, loadData, identifier)

    useEffect(() => {
        const hasSelectedRows = enhanceTableProps.selectionCount > 0
        setDisabled(identifier, isModalOpen || hasSelectedRows)
        setAutoupdatePaused(isModalOpen || hasSelectedRows)
    }, [enhanceTableProps.selectionCount, identifier, isModalOpen, setDisabled])

    const openChangePropertiesModal = () => {
        const serviceIds = enhanceTableProps.getSelectedIds() as number[]
        const filters = enhanceTableProps.getSelectAllFilters()

        showModal({
            content: <CommonPropertiesModal
                ids={serviceIds}
                entityType={EVMSEntityType.VMS_RUNTIME}
                subtitleKey={i18nKeys.change_properties_multiple_siteServices_subtitle}
                successKey={i18nKeys.change_properties_multi_success_message}
                errorKey={i18nKeys.change_properties_multi_error_message}
                uploadManagerKey={i18nKeys.upload_manager_job_async_site_services_change_properties}
                filters={filters}
                totalCount={totalCount} />,
            title: ''
        })
    }

    const authorize = useAuthorization()
    const license = useLicense()

    const actionsAuthorized = authorize(EProfileRights.IS_SITE_ADMIN)
    const actionsLicensed = license(ELicenseTypes.CONFIGURATION)

    const disableToolbarButtons = !enhanceTableProps.selectionCount
    const rowCount = TableHelper.countDisplayedRows(data, !!enhanceTableProps.fetchState.global.groupBy, groups)
    const totalSelectedRows = enhanceTableProps.selectAll ? totalCount : enhanceTableProps.selectionCount

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        {
                            actionsAuthorized && actionsLicensed && !situatorExclusive && (
                                <>
                                    <SelectionBox
                                        id='selectedSitesCheckbox'
                                        rowCount={enhanceTableProps.selectAll ? totalSelectedRows : rowCount}
                                        onAllRowsSelect={checked => enhanceTableProps.onAllRowsSelect(props.data, checked)}
                                        messageKey={i18nKeys.siteServices_table_toolbar_selected_siteServices}
                                        totalSelectedRows={totalSelectedRows}
                                        numSelectedRows={enhanceTableProps.selectAll ? totalSelectedRows : enhanceTableProps.selectionCount}
                                        switchSelectionMode={event => enhanceTableProps.switchSelectionMode(event, props.data)}
                                        disabled={enhanceTableProps.selectAll}
                                    />
                   
                                    <CustomTooltip title={<TransMessage {...i18nKeys.siteServices_table_toolbar_change_properties_tooltip} />}>
                                        <div>
                                            <CustomIconButton
                                                id='changePropertiesVBtn'
                                                disabled={disableToolbarButtons}
                                                onClick={openChangePropertiesModal}
                                                icon={Icons.EDIT}
                                            />
                                        </div>
                                    </CustomTooltip>
                                </>
                            )
                        
                        }
                    </>
                }
                RightContent={
                    <>
                        <ColumnChooser
                            minColumns={1}
                            onToggleColumn={colHandlerProps.onToggleColumn}
                            columns={colHandlerProps.columns}
                        />
                        <ColumnPreset
                            tableId={tableInfo.id}
                            {...colHandlerProps}
                        />
                        <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                    </>
                }
            />

            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                disableSelectionForIds={[]}
                columns={colHandlerProps.columns}
                rows={data}
                groups={groups}
            />
        </ViewWrapper>
    )
}

export default SiteServices