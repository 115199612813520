import { IconButton, TableCell } from '@mui/material'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import colors from 'style/colors'
import styled from 'styled-components'

type TProps = {
    name: string | JSX.Element
    sortKey?: string
    groupByIntlKey?: string
    groupActive: boolean
    sortActive: boolean
    groupKey?: string
    order: string
    onSort?: (sortKey: string) => void
    onGroup?: (groupKey: string, groupByIntlKey: string) => void
}

const ThWrapper = styled.div<{active: boolean}>`
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${p => p.active ? `color: ${colors.primary.DEFAULT_PRIMARY};` : ''}
`
const SortIconButton = styled(IconButton)<{$descending?: boolean, $sortActive: boolean}>`
    padding: 0;
    transform: ${p => p.$descending ? 'rotate(180deg)' : 'none'};
    fill: ${p => p.$sortActive ? colors.primary.DEFAULT_PRIMARY : colors.primary.DARK_PRIMARY};
`
const GroupIconButton = styled(IconButton)<{$groupActive: boolean}>`
    padding: 0;
    fill: ${p => p.$groupActive ? colors.primary.DEFAULT_PRIMARY : colors.primary.DARK_PRIMARY};
`

const CustomTableHeadCell = (props: TProps) => {
    const { name, sortKey, order, groupKey, groupByIntlKey, sortActive, groupActive } = props
    const { onSort, onGroup } = props

    return (
        <TableCell>
            <ThWrapper active={sortActive || groupActive}>
                {name}
                <div>
                    {
                        onGroup && groupKey && groupByIntlKey &&
                        <CustomTooltip
                            placement='top'
                            title={<TransMessage {...i18nKeys.site_list_group_tooltip} />}
                        >
                            <GroupIconButton
                                $groupActive={groupActive}
                                id={`groupButton${groupKey}`}
                                data-testid={`groupButton${groupKey}`}
                                onClick={() => onGroup(groupKey, groupByIntlKey)}
                            >
                                <Icon
                                    d={groupActive ? Icons.GROUP_ACTIVE : Icons.GROUP_INACTIVE}
                                />
                            </GroupIconButton>
                        </CustomTooltip>
                    }
                    {
                        onSort && sortKey &&
                        <CustomTooltip placement='top' title={<TransMessage {...i18nKeys.site_list_sort_tooltip} />}>
                            <SortIconButton
                                id={`sortButton${sortKey}`}
                                data-testid={`sortButton${sortKey}`}
                                $sortActive={sortActive}
                                $descending={sortActive && GeneralHelper.isSortDescending(order)}
                                onClick={() => onSort(sortKey)}>
                                <Icon
                                    d={sortActive ? Icons.ARROW : Icons.SORT} />
                            </SortIconButton>
                        </CustomTooltip>
                    }
                </div>
            </ThWrapper>
        </TableCell>
    )
}

export default CustomTableHeadCell