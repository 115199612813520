import FormHelper from 'helpers/FormHelper'
import { useAppDispatch } from 'state/store'
import {TUmbrellaRoleDetail, TUpdateUmbrellaRoleRequest } from 'types/businessLogic/role'
import UmbrellaRoleForm, { TUmbrellaRoleDetailsForm } from 'views/Admin/UmbrellaRoles/UmbrellaRoleForm'
import React from 'react'
import { updateUmbrellaRoleAsync } from 'state/slices/umbrellaRoleSlice'

type TProps = {
    umbrellaRole: TUmbrellaRoleDetail
    onSubmit?: () => void
}

const UmbrellaRoleDetails = (props: TProps) => {
    const dispatch = useAppDispatch()
    
    const {umbrellaRole, onSubmit} = props

    const handleUpdateUmbrellaRoleSubmit = (formValues: TUmbrellaRoleDetailsForm) => {
        FormHelper.removeEmptyFields(formValues)
        
        const updateUmbrellaRoleRequest : TUpdateUmbrellaRoleRequest = {
            ...formValues,
            id: formValues.id!,
            sites: FormHelper.extractValuesFromOptionTypeBase(formValues.sites),
            siteLabels: FormHelper.extractValuesFromOptionTypeBase(formValues.siteLabels),
            users: FormHelper.extractValuesFromOptionTypeBase(formValues.users),
            activeDirectoryDomainAndSid: formValues.activeDirectoryDomainAndSid?.value.toString() ?? undefined,
            filter: FormHelper.extractValuesFromOptionTypeBase(formValues.filter),
            vmsEntityTypeSubscription: FormHelper.extractValuesFromOptionTypeBase(formValues.vmsEntityTypeSubscription),
            vmsEventCategorySubscription: FormHelper.extractValuesFromOptionTypeBase(formValues.vmsEventCategorySubscription)
        }

        if (updateUmbrellaRoleRequest.umbrellaAdmin) {
            updateUmbrellaRoleRequest.sites = []
            updateUmbrellaRoleRequest.siteLabels = []
        }

        dispatch(updateUmbrellaRoleAsync(updateUmbrellaRoleRequest))

        onSubmit && onSubmit()
    }

    return (
        <UmbrellaRoleForm
            umbrellaRole={umbrellaRole}
            handleUmbrellaRoleSubmit={handleUpdateUmbrellaRoleSubmit}
        />
    )
}

export default UmbrellaRoleDetails