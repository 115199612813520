import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import zIndex from 'style/zIndex'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`   
  body {
    font-family: '${fonts.PRIMARY_REGULAR}', Courier;
    font-size: ${typographyScale.TYPE16};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE16LS};
    background-color: ${colors.shades.PURE_WHITE};
  }

  main {
    transition: padding-left .25s ease;
  }

  a {
    text-decoration: none;
  }

  h1, h2, span, p {
    margin: 0px;
  }

  h1 {
    font-family: ${fonts.PRIMARY_HEAVY};
    font-size: ${typographyScale.TYPE32};
    line-height: ${lineHeights.TYPE32LineHeight};
    letter-spacing: ${letterSpacings.TYPE32LS};
  }

  h2 {
    font-family: ${fonts.PRIMARY_BOLD};
    font-size: ${typographyScale.TYPE20};
    line-height: ${lineHeights.TYPE20LineHeight};
    letter-spacing: ${letterSpacings.TYPE20LS};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active,
  input:-internal-autofill-selected,
  input:-internal-autofill-previewed {
    background-color: white !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .select-popup {
    & li {
      font-family: LatoSemibold;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.6px;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${colors.primary.DEFAULT_PRIMARY};
  }

  .MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: black;
  }

  .MuiTooltip-popper {
        opacity: 1;
        word-break: break-all;
        white-space: pre-line;
        z-index: ${zIndex.HIGHEST};
    }

  .MuiTooltip-tooltip {
        word-break: break-word;
        white-space: pre-line;
        border-radius: ${borderRadius.SECONDARY};
        color: ${colors.shades.PURE_WHITE};
        background-color: ${colors.primary.DARK_PRIMARY};
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
        font-family: ${fonts.PRIMARY_MEDIUM};
        font-size: ${typographyScale.TYPE12};
        line-height: ${lineHeights.TYPE12LineHeight};
        letter-spacing: ${letterSpacings.TYPE12LS};
    }

    .MuiAccordion-root.Mui-expanded {
        margin: 0;
        border-bottom: 1px solid #C5C5C5;
        border-top: 1px solid #C5C5C5;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }

    // DataTimePicker start
  
    .PrivatePickersToolbar-root {
      background-color: ${colors.primary.DEFAULT_PRIMARY};

      .PrivateDateTimePickerToolbar-penIcon {
        display: none;
      }

      .MuiTypography-root {
        color: white !important;
      }
    }

    .MuiTabs-root {
        background-color: ${colors.primary.DEFAULT_PRIMARY};
        
        .MuiTab-root {
          color: white;
          fill: white;
        }
    }

    // DataTimePicker end
    .MuiOutlinedInput-root.Mui-disabled {
      opacity: 1 !important;
      background-color: #F2F2F2;   
      border: 1px solid #E6E6E6;   

      .MuiOutlinedInput-notchedOutline {
        border: 0px;
      }
    }

    .MuiOutlinedInput-input.Mui-disabled {
      -webkit-text-fill-color: #999999;
      
    }
`
 
export default GlobalStyle