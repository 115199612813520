import { EPerformanceState, EServiceState, EVMSState } from 'enums/enums'
import { TPerformanceItem, TPerformanceItemsPerSite } from 'types/businessLogic/performance'

export type TPagedSiteService = {
    results: TSiteServicePerformance[]
    currentPage: number
    pageCount: number
    pageSize: number
    recordCount: number
}

export type TSiteServicesPerSite = TPerformanceItemsPerSite

export type TSiteServicePerformance = TPerformanceItem & {
    serviceName: string
    vmsState: EVMSState
    serviceState: EServiceState
    vmsPerformanceState: EPerformanceState
    servicePerformanceState: EPerformanceState
    networkUsageMbitPerSecond: number
    vmsStateCode?: string
}

export function isTSiteServicePerformance(item: TPerformanceItem): item is TSiteServicePerformance {
    return (item as TSiteServicePerformance).serviceName !== undefined
}