import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import SiteService from 'services/siteService'
import { TPluginType } from 'types/businessLogic/site'

export type TPluginTypeState = {
    pluginTypes: TPluginType[]
    fetched: boolean
}

const initialState : TPluginTypeState = {
    pluginTypes: [],
    fetched: false
}

export const getPluginTypesAsync = createAsyncThunk('pluginType/getPluginTypesAsync', async () => {
    // no try/catch wrapper to prefent popups when user has no roles
    return await SiteService.getPluginTypes()
})

const slice = createSlice({
    name: 'pluginType',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPluginTypesAsync.fulfilled, (state, action) => {
                state.pluginTypes = action.payload
                state.fetched = true
            })
    }
})

export default slice.reducer