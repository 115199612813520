import { TablePagination } from '@mui/material'
import TablePaginationActions from 'components/Tables/TablePaginationActions'
import { paginationConfig } from 'hooks/useEnhanceTable'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'

const TableWrapper = styled.table`
    display: block;

    > tbody, > tbody > tr {
        display: block;
    }
`
const StyledPagination = styled(TablePagination)`
    &.MuiTablePagination-root {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        border-top: 1px solid ${colors.shades.DARK_SHADE};
        border-bottom: none;
    }
    .MuiTablePagination-toolbar {
        min-height: 64px;
    }
    .MuiTablePagination-caption {
        color: ${colors.shades.DEEP_SHADE};
        font-family: ${fonts.PRIMARY_SEMIBOLD};
        font-size: ${typographyScale.TYPE12};
        line-height: ${lineHeights.TYPE12LineHeight};
        letter-spacing: ${letterSpacings.TYPE12LS};
    }
    .MuiTablePagination-menuItem {
        font-family: ${fonts.PRIMARY_REGULAR};
        font-size: ${typographyScale.TYPE16};
        line-height: ${lineHeights.TYPE14LineHeight};
        letter-spacing: ${letterSpacings.TYPE16LS};
        background-color: ${colors.shades.PURE_WHITE};
        color: ${colors.primary.DARK_PRIMARY};
        &:hover, &:focus, &:active {
            background-color: ${colors.shades.LIGHT_SHADE};
            color: ${colors.primary.DEFAULT_PRIMARY}
        }
        min-height: 20px;
    }
    .MuiTablePagination-select,
    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows {
        color: ${colors.shades.DEEP_SHADE};
        font-family: ${fonts.PRIMARY_MEDIUM};
        font-size: ${typographyScale.TYPE12};
        line-height: ${lineHeights.TYPE12LineHeight};
        letter-spacing: ${letterSpacings.TYPE12LS};
        padding-right: 24px;
    }
    .MuiTablePagination-selectIcon {
        top: 5px;
        color: ${colors.shades.DEEP_SHADE};
    }
`

type TProps = {
    rowsPerPage: number
    count: number
    page: number
    onChangePage: (page: number) => void
    onChangeRowsPerPage: (rowsPerPage: number) => void
}

const CustomTablePagination = (props: TProps) => {
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        props.onChangePage(page)
    }

    const handleChangeRowsPerPage = (event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event) {
            const rowsPerPage = Number(event.target.value)
            props.onChangeRowsPerPage(rowsPerPage)
        }
    }

    const {count, rowsPerPage, page} = props

    return (
        <TableWrapper>
            <tbody>
                <tr>
                    <StyledPagination
                        id='tablePagination'
                        colSpan={12}
                        count={count}
                        rowsPerPageOptions={paginationConfig.rowsPerPageOptions}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{id: 'tableSelectRowsPerPage'}}
                        labelDisplayedRows={(values: { from: number, to: number, count: number }) =>
                            <TransMessage {...i18nKeys.table_pagination_displayed_rows} values={values} />}
                        labelRowsPerPage={<TransMessage {...i18nKeys.table_pagination_rows_per_page} />}
                        ActionsComponent={TablePaginationActions}
                    />
                </tr>
            </tbody>
        </TableWrapper>
    )
}

export default CustomTablePagination