import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormWrapper, FormContent, FormInput, FormLabel, InputWrapper, FormButtonGroup, FormFooter, FormGroup } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'state/store'
import * as yup from 'yup'
import { TUploadFirmwareRequest } from 'types/businessLogic/job'
import FirmwaresService from 'services/firmwaresService'
import UploadNewFirmwareDropZone from 'views/Control/CameraFirmwares/Modals/UploadNewFirmwareDropZone'
import { FormCreatableSelect } from 'components/FormControls/FormCreatableSelect'
import FormHelper from 'helpers/FormHelper'
import { pushItem, setDoneOrErrorOnItem, updateProgress } from 'state/slices/uploadManagerSlice'
import axios from 'axios'
import { useIntl } from 'react-intl'
import { createUploadManagerItemForFirmwareUpdate } from 'components/UploadManager/UploadManager'
import { cancelTokenStore } from 'api/cancelTokenStore'
import { TSelectOptions } from 'types/ui/dropdown'
import { useModal } from 'hooks/modalProvider'

const UploadNewCameraFirmware = () => {
    const intl = useIntl()
    const {closeModal} = useModal()
    const dispatch = useAppDispatch()
    const isCommentMandatory = useAppSelector(state => state.ui.uiSettings.isCommentMandatoryForJobs)

    const [file, setFile] = useState<File>(new File([], ''))

    const handleProgress = (id: number | string, percentage: number) => {
        dispatch(updateProgress({id, percentage}))
    }

    const handleUploadNewCameraFirmwareSubmit = async (formValues: TUploadFirmwareRequestForm) : Promise<void> => {
        formValues.firmware = file
        formValues.supportedTypes = FormHelper.extractValuesFromOptionTypeBase(formValues.supportedTypesOptions)
        
        const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_type_firmware_upload, {name: file.name})
        const uploadManagerItem = createUploadManagerItemForFirmwareUpdate(uploadManagerItemText)

        const cancelToken = axios.CancelToken.source()
        const tokenId = cancelTokenStore.add(cancelToken)
        uploadManagerItem.cancelTokenId = tokenId

        dispatch(pushItem(uploadManagerItem))

        FirmwaresService.uploadFirmware(formValues, (percentage) => handleProgress(uploadManagerItem.id, percentage), cancelToken.token)
            .then(() => dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id})))
            .catch(() => setDoneOrErrorOnItem({ id: uploadManagerItem.id, isError: true}))

        closeModal()
    }

    type TUploadFirmwareRequestForm = TUploadFirmwareRequest & {
        supportedTypesOptions: TSelectOptions
    }

    const defaultValues : TUploadFirmwareRequestForm = {
        firmware: new File([], ''),
        manufacturer: '',
        comment: '',
        supportedTypes: [],
        supportedTypesOptions: []
    }

    const handleOnDrop = (acceptedFile: File[]) => {
        setFile(acceptedFile[0])
    }

    const validationSchema = (mandatoryComment: boolean) => yup.object().shape({
        manufacturer: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)),
        supportedTypesOptions: yup.array().min(1),
        comment: mandatoryComment ? yup.string().max(500).required(intl.formatMessage(i18nKeys.general_validation_required_field)) : yup.string().max(500)
    })
    
    const { handleSubmit, formState, control} = useForm<TUploadFirmwareRequestForm>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema(isCommentMandatory))
    })
    const { isValid, isValidating, isSubmitting, isDirty } = formState

    return (
        <FormWrapper onSubmit={handleSubmit(handleUploadNewCameraFirmwareSubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_site_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='manufacturer'>
                            <TransMessage {...i18nKeys.camera_firmware_modal_manfucturer} />
                        </FormLabel>
                        <FormInput
                            name='manufacturer'
                            control={control}
                            type='text'
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='supportedTypesOptions'>
                            <TransMessage {...i18nKeys.camera_firmware_modal_supported_model} />
                        </FormLabel>
                        <FormCreatableSelect
                            name='supportedTypesOptions'
                            control={control}
                            isMulti
                            options={[]}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='comment'>
                            <TransMessage {...i18nKeys.camera_firmware_modal_comment} />
                        </FormLabel>
                        <FormInput
                            name='comment'
                            autoComplete='job-comment'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel />
                        <UploadNewFirmwareDropZone onDrop={handleOnDrop} />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='comment'>
                            <TransMessage {...i18nKeys.camera_firmware_modal_file_name} />
                        </FormLabel>
                        <FormLabel>
                            {
                                file && file.name.length > 0 ? file.name :
                                    <TransMessage {...i18nKeys.camera_firmware_modal_no_file_selected} />
                            }
                        </FormLabel>
                    </InputWrapper>
                </FormGroup>

            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty || !file.size}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.camera_firmware_modal_upload_btn} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}

export default UploadNewCameraFirmware
