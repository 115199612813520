import colors from 'style/colors'
import styled from 'styled-components'

export const SearchGroup = styled.div`
    fill: ${colors.shades.DEEP_SHADE};
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    margin-left: 16px;
`
