import { EPerformanceState } from 'enums/enums'
import { useIntl } from 'react-intl'
import { TTranslationKey } from 'types/ui/translation'

export type TUseCameraStateProps = {
    stateInfo: {
        translationKey: TTranslationKey
        color: string
    }
    overallState: EPerformanceState
    disabled: boolean
    tooltip?: string
}

export type TUseCameraState = {
    value: string
    color: string
    toolTipValue: string | undefined
}

const useCameraState = (props: TUseCameraStateProps) : TUseCameraState => {
    const { stateInfo, overallState, disabled, tooltip } = props

    const intl = useIntl()
    
    let toolTipValue = tooltip
    let value = intl.formatMessage(stateInfo.translationKey)
    let color = stateInfo.color

    if (overallState === EPerformanceState.IGNORED &&
        !disabled) {
        value = '-'
        color = '#959595'
        toolTipValue = undefined
    }

    return {
        value,
        color,
        toolTipValue
    }
}

export default useCameraState