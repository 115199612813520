import { List } from '@mui/material'
import Icon, { Icons } from 'components/Icon/Icon'
import React, { PropsWithChildren } from 'react'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import shadows from 'style/shadows'
import zIndex from 'style/zIndex'
import styled from 'styled-components'

const MenuWrapper = styled.div`
    position: fixed;
    display: flex;
    top: 69px;
    right: 0px;
    transform: translateX(calc(100% - 40px));
    z-index: ${zIndex.MEDIUM};
    transition: transform 260ms ease-in-out;
       
    &:hover {
        transform: translateX(0px);
    }

    .expander {
        display: grid;
        place-items: center;
        width: 40px;
        height: 40px;
        background-color: ${colors.primary.DEFAULT_PRIMARY};
        border-top-left-radius: ${borderRadius.SECONDARY};
        border-bottom-left-radius: ${borderRadius.SECONDARY};
        box-shadow: ${shadows.SEPTENARY};
        fill: ${colors.shades.PURE_WHITE};
    }

    .menu {
        width: auto;
        min-width: 210px;
        box-shadow: ${shadows.SEPTENARY};
    }
`

const StyledList = styled(List)`
    padding-bottom: 0px;

    li {
        cursor: pointer;
        background-color: ${colors.shades.PURE_WHITE};
        margin-top: -8px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
`

const QuickActionMenu = (props: PropsWithChildren<unknown>) => {
    return (
        <MenuWrapper>
            <div className='expander'>
                <Icon d={Icons.CLIP} />
            </div>
            <div className='menu'>
                <StyledList>
                    {props.children}
                </StyledList>
            </div>
        </MenuWrapper>
    )
}
export default QuickActionMenu