import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import ApiKeyService from 'services/ApiKeyService'
import { showMessage } from 'state/slices/uiSlice'
import { TApiKey } from 'types/businessLogic/misc'
import { TFetchParamsRequest } from 'types/network'

export type TApiKeysState = {
    apiKeys: TApiKey[]
    totalNumber: number
}

const initialState : TApiKeysState = {
    apiKeys: [],
    totalNumber: 0
}

export const getApiKeysAsync = createAsyncThunk('apiKeys/getApiKeysAsync', async (params: TFetchParamsRequest, thunkApi) => {
    try {
        return await ApiKeyService.getOverview(params)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'apiKey',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getApiKeysAsync.fulfilled, (state, action) => {
                state.apiKeys = action.payload.apiKeys
                state.totalNumber = action.payload.totalNumber
            })
    }
})

export default slice.reducer