import TransMessage from 'components/TransMessage/TransMessage'
import React from 'react'
import styled from 'styled-components'
import { TTranslationKey } from 'types/ui/translation'

type StatusCardContainerProps = {
    backgroundColor?: string
    textColor?: string
}

const StatusCardContainer = styled.div<StatusCardContainerProps>`
      width: 300px;
      border-radius: 8px;
      background-color: ${p => p.backgroundColor ? p.backgroundColor : 'transparent'};
      color: ${p => p.textColor ? p.textColor : '#303030'};
      padding: 5px 20px;
      justify-content: center;
      border: 1px solid #c2c0bf;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-left: 16px;
      display: block;
      
      .title {
        font-size: 12px;
        font-family: LatoBold;
        line-height: 24px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
      }
  
      .value {
        display: block;
        font-size: 20px;
        font-family: LatoBold;
        line-height: 32px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
      }
  `

type TStatusCardProps = {
    title: TTranslationKey
    value: string
    backgroundColor?: string
    textColor?: string
    id?: string
}

export const StatusCard = (props : TStatusCardProps) => {
    return (
        <StatusCardContainer
            backgroundColor={props.backgroundColor}
            textColor={props.textColor}
        >
            <div>
                <span className='title' id={`${props.id}_title`}>
                    <TransMessage {...props.title} />
                </span>
                <span className='value' id={`${props.id}_value`}>
                    {props.value}
                </span>
            </div>
        </StatusCardContainer>
    )
}