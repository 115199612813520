import { TCommonProperties, TSiteEntity } from 'types/businessLogic/site'
import { TExtendedTimeProfileDay, TExtendedTimeRange } from 'types/timeprofile'

class TimeProfileHelper {
    public getTimeProfileInfo = (siteEntity: TCommonProperties | TSiteEntity) : TExtendedTimeProfileDay[] => {
        if (siteEntity.propertyValues[0] && siteEntity.propertyValues[0].stringValue) {
            return this.parsePropertyValue(siteEntity.propertyValues[0].stringValue)
        } else {
            return this.createNewTimeProfileInfo()
        }
    }

    public timeProfileDayToJson = (timeprofileDay: TExtendedTimeProfileDay[]) => {
        const result = { Days: timeprofileDay.map(day => {
            return {
                TimeProfileDay: day.timeProfileDay,
                TimeRanges: day.timeRanges.map(range => ({Start: range.start, Stop: range.stop}))
            }
        })}

        return JSON.stringify(result)
    }

    public validateTimeRanges = (timeRanges: TExtendedTimeRange[]) : TExtendedTimeRange[] => {
        const skippedRanges = timeRanges.filter(x => x.skipInCalculation === true)
        const ranges = timeRanges.filter(x => x.skipInCalculation === false).sort((a, b) => a.start - b.start)
        const temp: TExtendedTimeRange[] = []
        
        ranges.forEach(range => {
            if (temp.length > 0) {
                if (range.start <= temp[temp.length - 1].stop) {
                    if (range.start < temp[temp.length - 1].start) {
                        temp[temp.length - 1].start = range.start
                    }

                    if (range.stop > temp[temp.length - 1].stop) {
                        temp[temp.length - 1].stop = range.stop
                    }
                    
                    return
                }
            }

            temp.push(range)
        })

        return temp.concat(skippedRanges)
    }

    public createNewTimeProfileInfo = () : TExtendedTimeProfileDay[] => {
        return [
            { timeProfileDay: 0, timeRanges: [] },
            { timeProfileDay: 1, timeRanges: [] },
            { timeProfileDay: 2, timeRanges: [] },
            { timeProfileDay: 3, timeRanges: [] },
            { timeProfileDay: 4, timeRanges: [] },
            { timeProfileDay: 5, timeRanges: [] },
            { timeProfileDay: 6, timeRanges: [] },
            { timeProfileDay: 7, timeRanges: [] },
        ]
    }

    private parsePropertyValue = (propertyValue: string) : TExtendedTimeProfileDay[] => {
        const value: any = JSON.parse(propertyValue)
        return value.Days.map((day: any, index: number) => {
            return {
                timeProfileDay: index,
                timeRanges: day.TimeRanges.map((timeRange: any) => ({
                    start: timeRange.Start,
                    stop: timeRange.Stop,
                    index: index,
                    skipInCalculation: false,
                    skipInTableAndSliderRender: false
                }))
            }
        })
    }
}

export const timeProfileHelper = new TimeProfileHelper()