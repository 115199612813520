import { MenuItem } from '@mui/material'
import React from 'react'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'

const DropdownItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        min-height: 40px;
        font-family: ${fonts.PRIMARY_REGULAR};
        font-size: ${typographyScale.TYPE16};
        line-height: ${lineHeights.TYPE14LineHeight};
        letter-spacing: ${letterSpacings.TYPE16LS};
        background-color: ${colors.shades.PURE_WHITE};
        color: ${colors.primary.DARK_PRIMARY};
        padding-top: 0;
        padding-bottom: 0;
        &:hover, &:focus, &:active {
            background-color: ${colors.shades.LIGHT_SHADE};
            color: ${colors.primary.DEFAULT_PRIMARY};
        }
    }

    &.MuiMenuItem-selected {
        color: ${colors.primary.DEFAULT_PRIMARY} !important;
        background-color: ${colors.shades.LIGHT_SHADE} !important;
    }
`

export default DropdownItem