import styled from 'styled-components'

export const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;

    .infoCard {
        width: 100%;
        height: 100%
    }

    .nodataCard {
        height: 400px;
        color: #303030;
        font-size: 20px;
        font-style: normal;
        align-self: center;
        font-family: LatoRegular;
        font-weight: 700;
        font-stretch: normal;
        letter-spacing: 0.6px;
        display: grid;
        place-items: center;
    }
`