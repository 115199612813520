import { IconButton } from '@mui/material'
import DropdownCheckbox from 'components/ColumnChooser/DropdownCheckbox'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useRef, useState } from 'react'
import colors from 'style/colors'
import styled from 'styled-components'
import { TDropdownCheckboxOption } from 'types/ui/dropdown'
import { TTableColumn } from 'types/ui/table'

type TProps = {
    minColumns?: number
    onToggleColumn: (key: string, checked: boolean) => void
    columns: TTableColumn[]
}

const defaultMinColumns = 1

const StyledIconButton = styled(IconButton)<{$open: boolean}>`
    margin-left: 10px;
    margin-right: 10px;
    fill: ${p => p.$open ? colors.primary.DEFAULT_PRIMARY : colors.primary.DARK_PRIMARY};
`

const ColumnChooser = (props: TProps) => {
    const {columns, minColumns, onToggleColumn} = props

    const [open, setOpen] = useState<boolean>(false)
    const buttonRef = useRef<HTMLButtonElement>(null)

    const handleToggle = () => setOpen(prevState => !prevState)

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (buttonRef.current && buttonRef.current.contains(event.currentTarget as Node)) return
        setOpen(false)
    }

    const shouldDisableCheckbox = () => {
        const visibleColumns = columns.filter(column => column.displayed)
        return visibleColumns.length <= (minColumns ?? defaultMinColumns)
    }

    const disable = shouldDisableCheckbox()

    const options: TDropdownCheckboxOption[] = columns.map(column => ({
        key: column.key,
        name: <TransMessage debugId={column.key} {...i18nKeys[column.translationKey]} />,
        checked: !!column.displayed,
        disabled: !!column.displayed && disable
    }))

    return (
        <>
            <StyledIconButton
                id='columnChooser'
                $open={open}
                ref={buttonRef}
                onClick={handleToggle}
            >
                <Icon d={Icons.COLUMNS} />
            </StyledIconButton>
            <DropdownCheckbox
                id='columnChooserDropdown'
                open={open}
                onClose={handleClose}
                onChange={onToggleColumn}
                options={options}
                anchorEl={buttonRef.current}
            />
        </>
    )
}

export default ColumnChooser