import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import TransMessage, { TIntlMessage } from 'components/TransMessage/TransMessage'
import React, { useEffect, useState } from 'react'

type TProps = {
    id: string
    onClick: () => void
    defaultIcon: string
    toggledIcon: string
    isToggled?: boolean
    toggeledTooltip?: TIntlMessage
    untoggledTooltip?: TIntlMessage
}

const CustomIconToggleButton = (props: TProps) => {
    const { id, onClick, isToggled, toggledIcon, defaultIcon, toggeledTooltip, untoggledTooltip, ...rest } = props
    const [toggled, setToggled] = useState(isToggled)

    useEffect(() => {
        setToggled(isToggled)
    }, [isToggled])

    const handleClick = () => {
        setToggled(!toggled)
        onClick()
    }

    const activeIcon = toggled ? toggledIcon : defaultIcon
    const activeTooltip = toggled ? toggeledTooltip : untoggledTooltip

    return (
        <CustomTooltip title={<TransMessage {...activeTooltip} />}>
            <div>
                <CustomIconButton {...rest} id={id} icon={activeIcon} onClick={handleClick} />
            </div>
        </CustomTooltip>
        
    )
}

export default CustomIconToggleButton