import { ButtonBase, Grid} from '@mui/material'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormWrapper, FormFooter, FormButtonGroup } from 'components/FormControls/FormControls'
import { useModal } from 'hooks/modalProvider'
import EnhancedTabs from 'components/Tabs/EnhancedTabs'
import TransMessage from 'components/TransMessage/TransMessage'
import useLicense from 'hooks/useLicense'
import { i18nKeys } from 'i18n/keys'
import React, { useState } from 'react'
import { useAppSelector } from 'state/store'
import images from 'style/images'
import styled from 'styled-components'
import { DashboardElementsWithCategories, EDashboardElements, EDashboardElementsCategory, TDashboardElementMapping } from 'types/ui/dashboard'
import { TTabButton } from 'types/ui/tab'
import { GridImage, SelectedImage } from 'views/Dashboard/Modals/common'
import useAuthorization from 'hooks/useAuthorization'

const Wrapper = styled.div`
    min-height: 550px;
`

type TProps = {
    onAddElement: (element : EDashboardElements) => void
    hideMapOption: boolean
}

const GridCell = styled(Grid)`
    margin-top: 2%;
    display: grid;
    place-items: center;
`

const ElementSelection = (props: TProps) => {
    const [selectedElement, setSelectedElement] = useState(-1)
    const { situatorExclusive } = useAppSelector(store => store.license)
    const {closeModal} = useModal()
    
    const handleSaveClick = () => {
        props.onAddElement(selectedElement)
        closeModal()
    }

    const checkLicense = useLicense()
    const checkAuth = useAuthorization()
    
    const renderElements = (elements: TDashboardElementMapping[]) => {
        return elements.map((item: TDashboardElementMapping, i: number) => {
            const useHasRightForElement = item.rights ? checkAuth(...item.rights) : true
            if (checkLicense(item.license, item.ignoreForSituatorExclusive) && useHasRightForElement) {
                return (
                    <GridCell key={'dashboardLayout' + i} item sm={4} md={4} lg={4}>
                        <ButtonBase id={item.id} onClick={() => setSelectedElement(item.enum)}>
                            <GridImage src={item.image} active={selectedElement === item.enum} />
                            {selectedElement === item.enum && <SelectedImage src={images.selectedIcon} />}
                        </ButtonBase>
                    </GridCell>
                )
            } else {
                return null
            }
            
        })
    }

    const tabButtons: TTabButton[] = [
        {
            id: 'chartTabButton',
            label: i18nKeys.umbrella_dashboard_chart_tab_button
        },
        {
            id: 'tableTabButton',
            label: i18nKeys.umbrella_dashboard_table_tab_button
        }
    ]

    const tabContents = [
        {
            title:  <TransMessage {...i18nKeys.umbrella_dashboard_select_card_description} />,
            content: <Wrapper key='chartTabButton'>
                <Grid direction='row' container>
                    {renderElements(DashboardElementsWithCategories[EDashboardElementsCategory.Charts])}
                </Grid>
            </Wrapper>
        },
        {
            title:  <TransMessage {...i18nKeys.umbrella_dashboard_select_card_description} />,
            content: <Wrapper key='tableTabButton'>
                <Grid direction='row' container>
                    {renderElements(DashboardElementsWithCategories[EDashboardElementsCategory.Tables])}
                </Grid>
            </Wrapper>
        }
    ]

    if (!situatorExclusive) {
        tabContents.push(
            {
                title:  <TransMessage {...i18nKeys.umbrella_dashboard_select_card_description} />,
                content: <Wrapper key='tableMapButton'>
                    <Grid direction='row' container>
                        {renderElements(DashboardElementsWithCategories[EDashboardElementsCategory.Maps])}
                    </Grid>
                </Wrapper>
            }
        )
    }

    if (!props.hideMapOption && !situatorExclusive) {
        tabButtons.push({
            id: 'tableMapButton',
            label: i18nKeys.umbrella_dashboard_map_tab_button
        })
    }

    return <FormWrapper>
        <EnhancedTabs
            subtitleKey={i18nKeys.umbrella_dashboard_select_card_description}
            numOfEntities={1}
            readOnlyForm={true}
            tabButtons={tabButtons}
            tabContent={tabContents}
        />
        <FormFooter>
            <FormButtonGroup>
                <CustomButton onClick={() => handleSaveClick()} id='select'>
                    <TransMessage {...i18nKeys.umbrella_dashboard_choose_layout_save_button} />
                </CustomButton>
            </FormButtonGroup>
        </FormFooter>
    </FormWrapper>
}

export default ElementSelection