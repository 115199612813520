import React, { useCallback, useEffect, useState } from 'react'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import styled from 'styled-components'
import { TGroup, TTableFetchState } from 'types/network'
import { TTableGroup, TTableRowData, TTableColumnProperty, TTableInfo } from 'types/ui/table'
import Toolbar from 'components/Toolbar/Toolbar'
import useAuthorization from 'hooks/useAuthorization'
import { EDataSyncItems, EProfileRights, EVMSEntityType } from 'enums/enums'
import SelectionBox from 'components/Tables/SelectionBox'
import { i18nKeys } from 'i18n/keys'
import useLicense from 'hooks/useLicense'
import { ELicenseTypes } from 'enums/ELicenseTypes'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import TransMessage from 'components/TransMessage/TransMessage'
import { Icons } from 'components/Icon/Icon'
import DeleteButton from 'components/Tables/DeleteButton'
import ColumnPreset from 'components/Tables/ColumnPreset'
import ColumnChooser from 'components/ColumnChooser/ColumnChooser'
import TableHelper from 'components/Tables/TableHelper'
import { TModalProps } from 'types/ui/modal'
import { TSiteEntity } from 'types/businessLogic/site'
import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import { useModal } from 'hooks/modalProvider'
import ChangeSiteUserPassword from 'views/Control/SiteUsers/Modals/ChangeSiteUserPassword'
import CopySiteUser from 'views/Control/SiteUsers/Modals/CopySiteUser'
import ChangeSiteUserName from 'views/Control/SiteUsers/Modals/ChangeSiteUserName'
import CommonPropertiesModal from 'components/CommonPropertiesModal/CommonPropertiesModal'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`

type TProps = {
    data: TTableRowData[]
    groups: TTableGroup<TTableRowData>[]
    totalCount: number
    tableInfo: TTableInfo
    getTableData: (params: TTableFetchState) => void
    getGroupData: (params: TTableFetchState, group: TGroup) => void
    deleteSiteUsers: (params: TModalProps) => void
    dynamicPropertiesColumns: TTableColumnProperty[]
}

const SiteUsers = (props: TProps) => {
    const { tableInfo, data, totalCount, groups, dynamicPropertiesColumns, getGroupData, getTableData } = props
    const [identifier] = useState(uid())
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)
    const {showModal, isModalOpen} = useModal()
    const colHandlerProps = useColumnsHandler({
        ...tableInfo,
        dynamicColumns: dynamicPropertiesColumns
    })

    const enhanceTableProps = useEnhanceTable({
        groups
    })

    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
        enhanceTableProps.selectedGroups.forEach(group => {
            getGroupData(enhanceTableProps.fetchState, group)
        })
    }, [enhanceTableProps.fetchState, enhanceTableProps.selectedGroups, getGroupData, getTableData])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.SITE_USERS, loadData, identifier)

    useEffect(() => {
        const hasSelectedRows = enhanceTableProps.selectionCount > 0
        setDisabled(identifier, isModalOpen || hasSelectedRows)
        setAutoupdatePaused(isModalOpen || hasSelectedRows)
    }, [enhanceTableProps.selectionCount, identifier, isModalOpen, setDisabled])

    const openChangeMultiplePropsModal = () => {
        showModal({
            content: <CommonPropertiesModal
                ids={enhanceTableProps.getSelectedIds() as number[]}
                entityType={EVMSEntityType.VMS_USER}
                subtitleKey={i18nKeys.change_properties_multiple_users_subtitle}
                successKey={i18nKeys.change_properties_multi_success_message}
                errorKey={i18nKeys.change_properties_multi_error_message}
                uploadManagerKey={i18nKeys.upload_manager_job_async_site_user_change_properties}
                filters={enhanceTableProps.getSelectAllFilters()}
                totalCount={totalCount}
            />,
            title: i18nKeys.copy_site_user_modal_title,
            description: i18nKeys.copy_site_user_modal_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const openChangeSiteUserName = () => {
        showModal({
            content: <ChangeSiteUserName
                ids={enhanceTableProps.getSelectedIds() as number[]}
                filters={enhanceTableProps.getSelectAllFilters()}
            />,
            title: i18nKeys.copy_site_user_modal_title,
            description: i18nKeys.copy_site_user_modal_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const openChangePasswordModal = () => {
        showModal({
            content: <ChangeSiteUserPassword
                ids={enhanceTableProps.getSelectedIds() as number[]}
                filters={enhanceTableProps.getSelectAllFilters()}
                totalCount={totalCount}
            />,
            title: i18nKeys.change_password_modal_title,
            description: i18nKeys.change_password_modal_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const openCopySiteUser = () => {
        showModal({
            content: <CopySiteUser
                ids={enhanceTableProps.getSelectedIds() as number[]}
            />,
            title: i18nKeys.copy_site_user_modal_title,
            description: i18nKeys.copy_site_user_modal_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const deleteSiteUsers = useCallback(() => {
        props.deleteSiteUsers({
            ids: enhanceTableProps.getSelectedIds() as number[],
            filters: enhanceTableProps.getSelectAllFilters()
        })
        enhanceTableProps.deselectAllRows()
    }, [enhanceTableProps, props])

    const authorize = useAuthorization()
    const license = useLicense()

    const actionsAuthorized = authorize(EProfileRights.IS_SITE_ADMIN)
    const actionsLicensed = license(ELicenseTypes.CONFIGURATION)

    const disableToolbarButtons = !enhanceTableProps.selectionCount
    const rowCount = TableHelper.countDisplayedRows(data, !!enhanceTableProps.fetchState.global.groupBy, groups)
    const totalSelectedRows = enhanceTableProps.selectAll ? totalCount : enhanceTableProps.selectionCount

    let noDuplicateSitesSelected = true
    const uniqueSiteNames = new Map<string, string>()
    for (const siteUser of enhanceTableProps.selectedRows) {
        if (!uniqueSiteNames.has((siteUser as TSiteEntity).siteName)) {
            uniqueSiteNames.set((siteUser as TSiteEntity).siteName, '')
        } else {
            noDuplicateSitesSelected = false
            break
        }
    }
    const disableCopyAndChangeName = enhanceTableProps.selectAll || !noDuplicateSitesSelected || totalSelectedRows === 0

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        {
                            actionsAuthorized && actionsLicensed && (
                                <>
                                    <SelectionBox
                                        id='selectedSiteUsersCheckbox'
                                        rowCount={enhanceTableProps.selectAll ? totalSelectedRows : rowCount}
                                        onAllRowsSelect={checked => enhanceTableProps.onAllRowsSelect(props.data, checked)}
                                        messageKey={i18nKeys.site_users_list_toolbar_selected_users}
                                        totalSelectedRows={totalSelectedRows}
                                        numSelectedRows={enhanceTableProps.selectAll ? totalSelectedRows : enhanceTableProps.selectionCount}
                                        switchSelectionMode={event => enhanceTableProps.switchSelectionMode(event, props.data)}
                                        disabled={enhanceTableProps.selectAll}
                                    />
                                    <CustomTooltip title={<TransMessage {...i18nKeys.site_users_list_toolbar_change_password_tooltip} />}>
                                        <div>
                                            <CustomIconButton
                                                id='changePasswordBtn'
                                                disabled={disableToolbarButtons}
                                                onClick={openChangePasswordModal}
                                                icon={Icons.CHANGE_PASSWORD}
                                            />
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip title={<TransMessage {...i18nKeys.site_users_list_toolbar_change_properties_tooltip} />}>
                                        <div>
                                            <CustomIconButton
                                                id='updateSiteUsersBtn'
                                                disabled={disableToolbarButtons}
                                                onClick={openChangeMultiplePropsModal}
                                                icon={Icons.EDIT}
                                            />
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip title={<TransMessage {...(!disableToolbarButtons && disableCopyAndChangeName ?
                                        i18nKeys.site_users_list_change_name_invalid_tooltip :
                                        i18nKeys.site_users_list_change_name_tooltip)
                                    } />}>
                                        <div>
                                            <CustomIconButton
                                                id='changeSiteUserNameBtn'
                                                disabled={disableCopyAndChangeName}
                                                onClick={openChangeSiteUserName}
                                                icon={Icons.CHANGE_SITE_USER_NAME}
                                            />
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip title={<TransMessage {...(!disableToolbarButtons && disableCopyAndChangeName ?
                                        i18nKeys.site_users_list_copy_invalid_tooltip :
                                        i18nKeys.site_users_list_copy_tooltip)
                                    } />}>
                                        <div>
                                            <CustomIconButton
                                                id='copySiteUserNameBtn'
                                                disabled={disableCopyAndChangeName}
                                                onClick={openCopySiteUser}
                                                icon={Icons.COPY_SITE_USER}
                                            />
                                        </div>
                                    </CustomTooltip>
                                    <DeleteButton
                                        id='deleteSiteUsersBtn'
                                        title={<TransMessage {...i18nKeys.site_users_list_toolbar_delete_site_users_tooltip} />}
                                        disabled={disableToolbarButtons}
                                        onClick={deleteSiteUsers}
                                    />
                                </>
                            )
                        
                        }
                    </>
                }
                RightContent={
                    <>
                        <ColumnChooser
                            minColumns={1}
                            onToggleColumn={colHandlerProps.onToggleColumn}
                            columns={colHandlerProps.columns}
                        />
                        <ColumnPreset
                            tableId={tableInfo.id}
                            {...colHandlerProps}
                        />
                        <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                    </>
                }
            />

            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                disableSelectionForIds={[]}
                columns={colHandlerProps.columns}
                rows={data}
                groups={groups}
            />
        </ViewWrapper>
    )
}

export default SiteUsers