import { TLanguages, TTranslationKey } from 'types/ui/translation'
import AuthService from 'services/authService'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TMessage } from 'types/ui/message'
import { uid } from 'uid'
import DateHelper from 'helpers/DateHelper'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'

export type TUIState = {
    isRememberMeAvailable: boolean
    isAdLoginAvailable: boolean
    isEulaAccepted?: boolean
    showEulaModal: boolean
    isFixedSnackbarOpen: boolean
    uiSettings: {
        displayUsernameInJobs: boolean
        isCommentMandatoryForJobs: boolean
        deleteReportsWhenDownloading: boolean
    }
    version: string
    messages: TMessage[]
    breadcrumbs: (string | TTranslationKey)[]
    language: TLanguages
    fetched: boolean
}

const initialState : TUIState = {
    isRememberMeAvailable: false,
    isAdLoginAvailable: false,
    showEulaModal: false,
    isFixedSnackbarOpen: false,
    uiSettings: {
        displayUsernameInJobs: false,
        isCommentMandatoryForJobs: false,
        deleteReportsWhenDownloading: false,
    },
    version: '',
    messages : [],
    breadcrumbs: [],
    language: 'en',
    fetched: false
}

export const getLanguageAsync = createAsyncThunk('ui/getLanguageAsync', (_, thunkApi) => {

    let language = localStorage.getItem('LANGUAGE')?.replaceAll('"', '') as TLanguages
    if (!language) {
        language = 'en'
    }
    
    if (navigator.languages && navigator.languages[0]) {
        const allowedLanguages = ['de, en, fr']
        
        if (allowedLanguages.includes(navigator.languages[0])) {
            language = navigator.languages[0] as TLanguages
        }
    }
    
    thunkApi.dispatch(setLanguageAsync(language))
})

export const setLanguageAsync = createAsyncThunk('ui/setLanguageAsync', (language: TLanguages) => {
    localStorage.setItem('LANGUAGE', language)
    DateHelper.setLocale(language)
    return language
})

export const optionsAsync = createAsyncThunk('ui/optionsAsync', async (_, thunkApi) => {
    try {
        return await AuthService.getOptions()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const acceptEulaAsync = createAsyncThunk('ui/acceptEulaAsync', async (_, thunkApi) => {
    try {
        await AuthService.acceptEula()
    } catch {
        thunkApi.dispatch(showMessage({type: EMessageType.ERROR, message: i18nKeys.site_jobs_status_failed}))
    }
})

const slice = createSlice({
    name: 'ui',
    initialState: initialState,
    reducers: {
        showMessage: (state, action: PayloadAction<TMessage>) => {
            const message: TMessage = {
                ...action.payload,
                id: uid()
            }
            state.messages.push(message)
        },
        hideMessage: (state, action: PayloadAction<string>) => {
            state.messages = [...state.messages.filter(message => message.id !== action.payload)]
        },
        setBreadCrumbs: (state, action: PayloadAction<(string | TTranslationKey)[]>) => {
            state.breadcrumbs = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(optionsAsync.fulfilled, (state, action) => {
            Object.assign(state, action.payload)
            if (!action.payload?.isEulaAccepted) {
                state.showEulaModal = true
            }
        })
        builder.addCase(optionsAsync.rejected, (state) => {
            state.version = '-'
        })
        builder.addCase(acceptEulaAsync.fulfilled, (state) => {
            state.isEulaAccepted = true
            state.showEulaModal = false
        })
        builder.addCase(setLanguageAsync.fulfilled, (state, action) => {
            state.language = action.payload
        })
    }
})

export const {
    showMessage, hideMessage, setBreadCrumbs
} = slice.actions

export default slice.reducer