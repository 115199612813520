import { EMessageType } from 'enums/enums'
import EnumHelper from 'helpers/EnumHelper'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import CameraPerformanceService from 'services/cameraPerformanceService'
import { siteCameraService } from 'services/siteCameraService'
import { fetchCameraPerformanceAsync } from 'state/slices/cameraPerformanceSlice'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import styled from 'styled-components'
import { TGetCardPageRequest, TPerformanceItem } from 'types/businessLogic/performance'
import CamerasPerformance from 'views/Monitor/Cameras/CamerasPerformance'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const CamerasPerformanceContainer = () => {
    const dispatch = useAppDispatch()
    const { totalNumberCameras, allCamerasPerSite, overallState } = useAppSelector(state => state.cameraPerformance)
    const { filter } = useParams()

    useBreadCrumbs([i18nKeys.breadcrumb_camera_performance])

    const handleFetchAction = useCallback((params: TGetCardPageRequest) => {
        dispatch(fetchCameraPerformanceAsync(params))
    }, [dispatch])

    const restartCameras = async (cameras: TPerformanceItem[]) => {
        const ids = cameras.map(camera => camera.entityId)

        try {
            await siteCameraService.restartCameras({ids})
            dispatch(showMessage({message: i18nKeys.restart_cameras_success_snack_message, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.restart_cameras_error_snack_message, type: EMessageType.ERROR}))
        }
    }

    const ignoreCameras = async (cameras: TPerformanceItem[]) => {
        const ids = cameras.map(camera => camera.entityId)

        try {
            await CameraPerformanceService.ignoreCameras(ids)
            dispatch(showMessage({message: i18nKeys.camera_performance_ignore_success, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.camera_performance_ignore_error, type: EMessageType.ERROR}))
        }
    }

    return (
        <ViewContainer>
            <CamerasPerformance
                totalNumberCameras={totalNumberCameras}
                allCamerasPerSite={allCamerasPerSite}
                handleFetchAction={handleFetchAction}
                overallState={overallState}
                preSelectedState={EnumHelper.parseStringToEPerformanceState(filter)}
                restartCameras={restartCameras}
                ignoreCameras={ignoreCameras}
            />
        </ViewContainer>
    )
}

export default CamerasPerformanceContainer