import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { TSiteEntity } from 'types/businessLogic/site'
import { tableInfo } from 'views/Control/SiteGroups/siteGroupsTableColumns'
import CustomLink from 'components/CustomControls/CustomLink'

class SiteGroupsTablePreparer {
    public static prepareSiteGroupRows = (siteGroups: TSiteEntity[], openSiteGroupDetailsModal: (id: number) => void): TTableRowData[] =>
        siteGroups.map(siteGroup => ({
            ...siteGroup,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.name.key,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    readOnly: false,
                    [GeneralHelper.propertyTypes.customRender.key]: () =>
                        <CustomLink onClick={() => openSiteGroupDetailsModal(siteGroup.id)}>{siteGroup.name}</CustomLink>
                },
                {
                    name: tableInfo.columnConfig.siteName.key,
                    translationKey: tableInfo.columnConfig.siteName.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: siteGroup.siteName
                },
                {
                    name: tableInfo.columnConfig.labels.key,
                    translationKey: tableInfo.columnConfig.labels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: siteGroup.labels
                },
                {
                    name: tableInfo.columnConfig.siteLabels.key,
                    translationKey: tableInfo.columnConfig.siteLabels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: siteGroup.siteLabels
                },
                ...siteGroup.propertyValues
            ]
        }))

    public static prepareGroups = (groups: TTableGroup<TSiteEntity>[], openSiteGroupDetailsModal: (id: number) => void): TTableGroup<TTableRowData>[] => groups.map(group => ({
        ...group,
        data: group.data ? SiteGroupsTablePreparer.prepareSiteGroupRows(group.data, openSiteGroupDetailsModal) : undefined
    }))
}

export default SiteGroupsTablePreparer