import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { TSiteEntity } from 'types/businessLogic/site'
import { tableInfo } from 'views/Control/SiteCamera/siteCamerasTableColumns'
import CustomLink from 'components/CustomControls/CustomLink'

class SiteCamerasTablePreparer {
    public static prepareSiteCameraRows = (cameras: TSiteEntity[], openSiteCamerasDetailsModal: (id: number) => void): TTableRowData[] =>
        cameras.map(siteCamera => ({
            ...siteCamera,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.name.key,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    readOnly: false,
                    [GeneralHelper.propertyTypes.customRender.key]: () =>
                        <CustomLink onClick={() => openSiteCamerasDetailsModal(siteCamera.id)}>{siteCamera.name}</CustomLink>
                },
                {
                    name: tableInfo.columnConfig.siteName.key,
                    translationKey: tableInfo.columnConfig.siteName.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: siteCamera.siteName
                },
                {
                    name: tableInfo.columnConfig.labels.key,
                    translationKey: tableInfo.columnConfig.labels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: siteCamera.labels
                },
                {
                    name: tableInfo.columnConfig.siteLabels.key,
                    translationKey: tableInfo.columnConfig.siteLabels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: siteCamera.siteLabels
                },
                ...siteCamera.propertyValues
            ]
        }))

    public static prepareGroups = (groups: TTableGroup<TSiteEntity>[], openSiteCameraDetailsModal: (id: number) => void): TTableGroup<TTableRowData>[] => groups.map(group => ({
        ...group,
        data: group.data ? SiteCamerasTablePreparer.prepareSiteCameraRows(group.data, openSiteCameraDetailsModal) : undefined
    }))
}

export default SiteCamerasTablePreparer