// eslint-disable-next-line import/no-anonymous-default-export
export default {
    storageKeys: {
        LANGUAGE: 'LANGUAGE'
    },
    languages: {
        ENGLISH: 'en',
        GERMAN: 'de',
        FRENCH: 'fr'
    },
    sort: {
        NONE: 'none',
        ASCENDING: 'asc',
        DESCENDING: 'desc'
    },
    tableIds: {
        CONTROL_CENTRE_CAMERAS: 'control_centre_cameras',
        SITES: 'sites',
        SITE_USERS: 'site_users',
        SITE_GROUPS: 'site_groups',
        SITE_LICENCES: 'site_licences',
        CONTROL_CENTRE_SITE_SERVICES: 'control_centre_site_services',
        SITE_JOBS: 'site_jobs',
        UMBRELLA_USERS: 'umbrella_users',
        UMBRELLA_ROLES: 'umbrella_roles',
        SITE_EVENTS: 'site_events',
        CAMERA_FIRMWARES: 'camera_firmwares',
        APPLY_FIRMWARE_CAMERAS: 'apply_firmware_cameras',
        APPLY_FIRMWARE_CAMERA_FIRMWARES: 'apply_firmware_camera_firmwares',
        SITE_TIME_PROFILES: 'site_time_profiles',
        REPORTS: 'reports',
        AUDIT_REPORTS: 'audit_reports',
        PLUGINS: 'plugins',
        LABELS: 'labels',
        API_KEYS: 'api_keys'
    },
    other: {
        MAX_NOTIFICATIONS: 50,
        INVALID_SESSION_RETRIES: 3,
        INVALID_SESSION_TIMEOUT: 5000,
        TABEL_MIN_COLUMN_AMOUNT: 4
    }
}