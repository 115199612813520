import GeneralHelper from 'helpers/GeneralHelper'
import { TSiteLabel } from 'types/businessLogic/misc'
import { TTableRowData } from 'types/ui/table'
import {tableInfo} from './LabelsTableColumns'
import React from 'react'

class LabelsTablePreparer {
    public static prepareLabelsRows = (labels: TSiteLabel[]) : TTableRowData[] =>
        labels.map(label => ({
            ...label,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.name.key,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: label.name
                }
            ]
        }))
}

export default LabelsTablePreparer