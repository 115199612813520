import { yupResolver } from '@hookform/resolvers/yup'
import { CustomButton } from 'components/CustomControls/CustomButton'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { FormButtonGroup, FormCheckbox, FormContent, FormGroup, FormInput, FormLabel, FormWrapper, FormFooter, InputWrapper, Instructions } from 'components/FormControls/FormControls'
import { FormSelect } from 'components/FormControls/FormSelect'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType, EPluginType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import FormHelper from 'helpers/FormHelper'
import Validation from 'helpers/Validation'
import SiteService from 'services/siteService'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TDeploySite } from 'types/businessLogic/site'
import * as yup from 'yup'
import { FormNumberInput } from 'components/FormControls/FormNumberInput'
import LabelsHelper from 'helpers/LabelsHelper'
import { FormAsyncCreatableSelect } from 'components/FormControls/FormAsyncCreatableSelect'
import { debounce } from '@mui/material'
import colors from 'style/colors'
import IconWrapper from 'components/Icon/IconWrapper'
import { TSelectOption, TSelectOptions } from 'types/ui/dropdown'
import { useModal } from 'hooks/modalProvider'

const isHttps = () => window.location.protocol === 'https:'

const getPortNumber = () => {
    const httpPort = 80
    const httpsPort = 443
    return window.location.port || (isHttps() ? httpsPort : httpPort)
}

export type TDeploySiteForm = TDeploySite & {
    pluginTypeOptions: TSelectOption
    labelOptions: TSelectOptions
}

const DeploySite = () => {
    const dispatch = useAppDispatch()
    const pluginTypes = useAppSelector(store => store.pluginTypes)
    const intl = useIntl()
    const {closeModal} = useModal()

    const handleDeploySubmit = useCallback(async (formValues: TDeploySiteForm) : Promise<void> => {
        try {
            formValues.pluginType = formValues.pluginTypeOptions.value as EPluginType
            formValues.labels = FormHelper.extractValuesFromOptionTypeBase(formValues.labelOptions)
            await SiteService.deploySite(formValues)
            dispatch(showMessage({message: i18nKeys.deploy_site_success_message, type: EMessageType.SUCCESS}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.deploy_site_error_message, type: EMessageType.ERROR}))
        }

        closeModal()
    }, [closeModal, dispatch])
    
    const defaultValue : TDeploySiteForm = useMemo(() => ({
        coreHost: window.location.hostname,
        corePort: getPortNumber() as number,
        useHttps: isHttps(),
        isService: false,
        serviceName: 'VMS_Umbrella_Gateway',
        workingDir: '',
        name: '',
        pluginType: EPluginType.QognifyVMS71,
        pluginTypeOptions: {
            label: intl.formatMessage({...i18nKeys.site_plugin_type_qognifyvms71}),
            value: EPluginType.QognifyVMS71
        },
        location: '',
        labels: [],
        labelOptions: [],
        vmsHost: 'localhost',
        vmsPort: 60000,
        vmsUsername: '',
        vmsPassword: '',
        branchLess: false
    }), [intl])

    const pluginTypeOptions = pluginTypes.pluginTypes.map(pluginType => {
        return {
            value: pluginType.pluginType,
            label: intl.formatMessage({...i18nKeys[pluginType.translationKey]})
        }
    })
   
    const validationSchema = yup.object().shape({
        coreHost: yup.string()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .matches(Validation.config.HOSTNAME, intl.formatMessage(i18nKeys.deploy_site_input_validation_host))
            .required(),
        corePort: yup.number()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .required()
            .test('port', intl.formatMessage(i18nKeys.deploy_site_input_validation_port), Validation.isValidPortNumber),
        serviceName: yup.string().optional()
            .when('isService', { is: true, then:
                yup.string()
                    .required(intl.formatMessage(i18nKeys.general_validation_required_field))
                    .matches(Validation.config.SERVICE_NAME_REGEX, intl.formatMessage(i18nKeys.deploy_site_input_validation_service_name))
            }),
        workingDir: yup.string()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .required()
            .test('path', intl.formatMessage(i18nKeys.deploy_site_input_validation_working_dir), Validation.isValidDirPath),
        name: yup.string().optional(),
        location: yup.string().optional(),
        vmsHost: yup.string()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .required()
            .matches(Validation.config.HOSTNAME, intl.formatMessage(i18nKeys.deploy_site_input_vms_host_placeholder)),
        vmsPort: yup.number()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .required()
            .test('port', intl.formatMessage(i18nKeys.deploy_site_input_validation_port), Validation.isValidPortNumber),
        vmsUsername: yup.string()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .required(),
        vmsPassword: yup.string()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .required(),
    })

    const { handleSubmit, formState, control, watch } = useForm<TDeploySiteForm>({
        mode: 'onChange',
        defaultValues: defaultValue,
        resolver: yupResolver(validationSchema)
    })
    
    const asAServiceChecked = watch('isService')

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            LabelsHelper.loadLabels(inputText).then((options) => callback(options))
        }, 500), [])

    const { isValid, isValidating, isSubmitting, isDirty } = formState
    return (

        <FormWrapper onSubmit={handleSubmit(handleDeploySubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_site_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='coreHost'>
                            <TransMessage {...i18nKeys.deploy_site_input_core_host_label} />
                        </FormLabel>
                        <FormInput
                            name='coreHost'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='corePort'>
                            <TransMessage {...i18nKeys.deploy_site_input_core_port_label} />
                        </FormLabel>
                        <FormNumberInput
                            name='corePort'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='useHttps'>
                            <TransMessage {...i18nKeys.deploy_site_input_https_label} />
                        </FormLabel>
                        <FormCheckbox
                            name='useHttps'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='isService'>
                            <TransMessage {...i18nKeys.deploy_site_input_as_a_service_label} />
                        </FormLabel>
                        <FormCheckbox
                            name='isService'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='serviceName'>
                            <TransMessage {...i18nKeys.deploy_site_input_service_name_label} />
                        </FormLabel>
                        <FormInput
                            disabled={!asAServiceChecked}
                            name='serviceName'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='workingDir'>
                            <TransMessage {...i18nKeys.deploy_site_input_working_dir_label} />
                        </FormLabel>
                        <FormInput
                            name='workingDir'
                            control={control}
                        />
                        <CustomTooltip title={intl.formatMessage({...i18nKeys.deploy_site_input_working_dir_tooltip})}>
                            <IconWrapper color={colors.primary.DEFAULT_PRIMARY} margin='0px 0px 0px 10px'>
                                <Icon d={Icons.INFO} size={16} />
                            </IconWrapper>
                        </CustomTooltip>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='pluginTypeOptions'>
                            <TransMessage {...i18nKeys.deploy_site_input_plugin_type_label} />
                        </FormLabel>
                        <FormSelect
                            name='pluginTypeOptions'
                            control={control}
                            options={pluginTypeOptions}
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup title={i18nKeys.deploy_site_optional_site_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='name'>
                            <TransMessage {...i18nKeys.deploy_site_input_name_label} />
                        </FormLabel>
                        <FormInput
                            name='name'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='location'>
                            <TransMessage {...i18nKeys.deploy_site_input_location_label} />
                        </FormLabel>
                        <FormInput
                            name='location'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='labelOptions'>
                            <TransMessage {...i18nKeys.deploy_site_input_labels_label} />
                        </FormLabel>
                        <FormAsyncCreatableSelect
                            name='labelOptions'
                            control={control}
                            isMulti
                            loadOptions={loadOptions}
                            defaultOptions
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup title={i18nKeys.deploy_site_vms_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='vmsHost'>
                            <TransMessage {...i18nKeys.deploy_site_input_vms_host_label} />
                        </FormLabel>
                        <FormInput
                            name='vmsHost'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='vmsPort'>
                            <TransMessage {...i18nKeys.deploy_site_input_vms_port_label} />
                        </FormLabel>
                        <FormNumberInput
                            name='vmsPort'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='vmsUsername'>
                            <TransMessage {...i18nKeys.deploy_site_input_vms_user_name_label} />
                        </FormLabel>
                        <FormInput
                            name='vmsUsername'
                            control={control}
                            autoComplete='nope'
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='vmsPassword'>
                            <TransMessage {...i18nKeys.deploy_site_input_vms_password_label} />
                        </FormLabel>
                        <FormInput
                            name='vmsPassword'
                            control={control}
                            type='password'
                            autoComplete='new-password'
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='branchLess'>
                            <TransMessage {...i18nKeys.deploy_site_input_branchless} />
                        </FormLabel>
                        <FormCheckbox
                            name='branchLess'
                            control={control}
                        />
                        <CustomTooltip title={intl.formatMessage({...i18nKeys.deploy_site_input_branchless_tooltip})}>
                            <IconWrapper color={colors.primary.DEFAULT_PRIMARY}>
                                <Icon d={Icons.INFO} size={16} />
                            </IconWrapper>
                        </CustomTooltip>
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty}
                        id='submitBtn'
                        type='submit'
                        endIcon={<Icon d={Icons.DOWNLOAD} />}
                    >
                        <TransMessage {...i18nKeys.deploy_site_download_installer_button} />
                    </CustomButton>
                </FormButtonGroup>
                <Instructions>
                    <TransMessage {...i18nKeys.deploy_site_instructions} />
                </Instructions>
            </FormFooter>
        </FormWrapper>
    )
}

export default DeploySite