import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { TLanguages } from 'types/ui/translation'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'

dayjs.extend(utc)
dayjs.extend(localizedFormat)

class DateHelper {
    static formats = {
        DATE_TIME_PRIMARY: 'L LT', // example: 09.04.1986 20:30
        DATE_TIME_SECONDARY: 'L LTS', // example: 09.04.1986 20:30:23
        TIME_PRIMARY: 'LTS',
        DATE_PRIMARY: 'L',
        DATE_SECONDARY: 'ddd L',
        YEAR_TO_SECONDS_PM_AM: 'L LTS' // example: 2019/09/20 8:56:35 AM
    }

    static formatDate = (date: string | Dayjs, format: string) => dayjs(date).format(format)

    static formatUtcDate = (date: string | Dayjs, format: string) => dayjs(date).utc().format(format)

    static convertUTCToLocal = (date: string, format: string) => dayjs(dayjs.utc(date)).local().format(format)

    static millisecondsToHoursAndMinutes = (value: number) => dayjs.utc(value).format('HH:mm')

    static setLocale = (language: TLanguages) => dayjs.locale(language)
}

export default DateHelper