import { ESetThresholdType, EThresholdType } from 'enums/enums'
import { useModal } from 'hooks/modalProvider'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'state/store'
import SetMonitoringThresholdsForm, { TSetMonitoringThresholdsFormValues, TSetMonitoringThresholdsModalProps } from 'views/Monitor/Modals/SetMonitoringThresholds/SetMonitoringThresholdsForm'

type SetMonitoringThresholdsProps = {
    ids: number[]
    onSubmit: (values: TSetMonitoringThresholdsFormValues) => void
    type: ESetThresholdType
}

const SetMonitoringThresholds = ({ids, onSubmit, type} : SetMonitoringThresholdsProps) => {
    const { sites } = useAppSelector(state => state.site)
    const { allSiteServicesPerSite } = useAppSelector(state => state.siteServicePerformance)
    const { allSiteSystemsPerSite } = useAppSelector(state => state.siteSystemPerformance)
    
    const {
        cpuThresholdWarningInPercent,
        cpuThresholdCriticalInPercent,
        memoryThresholdWarningInPercent,
        memoryThresholdCriticalInPercent,
        diskThresholdWarningInPercent,
        diskThresholdCriticalInPercent,
        networkThresholdWarningInPercent,
        networkThresholdCriticalInPercent,
    } = useAppSelector(state => state.settings)
    
    const {closeModal} = useModal()

    const [cpuThresholds, setCpuThresholds] = useState<number[]>([])
    const [memoryThresholds, setMemoryThresholds] = useState<number[]>([])
    const [diskThresholds, setDiskThresholds] = useState<number[]>([])
    const [networkThresholds, setNetworkThresholds] = useState<number[]>([])

    const [cpuUseDefault, setCpuUseDefault] = useState<boolean>(false)
    const [memoryUseDefault, setMemoryUseDefault] = useState<boolean>(false)
    const [diskUseDefault, setDiskUseDefault] = useState<boolean>(false)
    const [networkUseDefault, setNetworkUseDefault] = useState<boolean>(false)
    
    const [fetched, setFetched] = useState(false)

    const siteServices = allSiteServicesPerSite.flatMap(x => x.items)
    const siteSystems = allSiteSystemsPerSite.flatMap(x => x.items)

    useEffect(() => {
        if (fetched) {
            return
        }

        const setSettingsValues = () => {
            setCpuThresholds([cpuThresholdWarningInPercent, cpuThresholdCriticalInPercent])
            setMemoryThresholds([memoryThresholdWarningInPercent, memoryThresholdCriticalInPercent])
            setDiskThresholds([diskThresholdWarningInPercent, diskThresholdCriticalInPercent])
            setNetworkThresholds([networkThresholdWarningInPercent, networkThresholdCriticalInPercent])
            setCpuUseDefault(true)
            setMemoryUseDefault(true)
            setDiskUseDefault(true)
            setNetworkUseDefault(true)
        }

        if (ids.length === 1) {
            if (type === ESetThresholdType.SITES) {
                const site = sites.find(x => x.id === ids[0])
                if (site) {
                    setCpuThresholds([site.cpuThresholdWarningInPercent, site.cpuThresholdCriticalInPercent])
                    setMemoryThresholds([site.memoryThresholdWarningInPercent, site.memoryThresholdCriticalInPercent])
                    setDiskThresholds([site.diskThresholdWarningInPercent, site.diskThresholdCriticalInPercent])
                    setNetworkThresholds([site.networkThresholdWarningInPercent, site.networkThresholdCriticalInPercent])
                    setCpuUseDefault(site.cpuThresholdType === EThresholdType.SETTINGS)
                    setMemoryUseDefault(site.memoryThresholdType === EThresholdType.SETTINGS)
                    setDiskUseDefault(site.diskThresholdType === EThresholdType.SETTINGS)
                    setNetworkUseDefault(site.networkThresholdType === EThresholdType.SETTINGS)
                } else {
                    setSettingsValues()
                }
            } else if (type === ESetThresholdType.SERVICES) {
                const siteService = siteServices.find(x => x.id === ids[0])
                if (siteService) {
                    setCpuThresholds([siteService.cpuThresholdWarningInPercent, siteService.cpuThresholdCriticalInPercent])
                    setMemoryThresholds([siteService.memoryThresholdWarningInPercent, siteService.memoryThresholdCriticalInPercent])
                    setCpuUseDefault(siteService.cpuThresholdType !== EThresholdType.ENTITY)
                    setMemoryUseDefault(siteService.memoryThresholdType !== EThresholdType.ENTITY)
                } else {
                    setSettingsValues()
                }
            } else if (type === ESetThresholdType.SYSTEMS) {
                const siteSystem = siteSystems.find(x => x.id === ids[0])
                if (siteSystem) {
                    setCpuThresholds([siteSystem.cpuThresholdWarningInPercent, siteSystem.cpuThresholdCriticalInPercent])
                    setMemoryThresholds([siteSystem.memoryThresholdWarningInPercent, siteSystem.memoryThresholdCriticalInPercent])
                    setDiskThresholds([siteSystem.diskThresholdWarningInPercent, siteSystem.diskThresholdCriticalInPercent])
                    setNetworkThresholds([siteSystem.networkThresholdWarningInPercent, siteSystem.networkThresholdCriticalInPercent])
                    setCpuUseDefault(siteSystem.cpuThresholdType !== EThresholdType.ENTITY)
                    setMemoryUseDefault(siteSystem.memoryThresholdType !== EThresholdType.ENTITY)
                    setDiskUseDefault(siteSystem.diskThresholdType !== EThresholdType.ENTITY)
                    setNetworkUseDefault(siteSystem.networkThresholdType !== EThresholdType.ENTITY)
                } else {
                    setSettingsValues()
                }
            }
        } else {
            setSettingsValues()
        }

        setFetched(true)
    }, [cpuThresholdCriticalInPercent, cpuThresholdWarningInPercent, diskThresholdCriticalInPercent, diskThresholdWarningInPercent, fetched, ids, memoryThresholdCriticalInPercent, memoryThresholdWarningInPercent, networkThresholdCriticalInPercent, networkThresholdWarningInPercent, siteServices, siteSystems, sites, type])
    
    if (!fetched) {
        return <></>
    }

    const thresholdProps : TSetMonitoringThresholdsModalProps = {
        type,
        cpuThresholds,
        memoryThresholds,
        diskThresholds,
        networkThresholds,
        cpuUseDefault,
        memoryUseDefault,
        networkUseDefault,
        diskUseDefault,
        onSubmit: async (formValues : TSetMonitoringThresholdsFormValues) => {
            onSubmit(formValues)
            closeModal()
        }
    }

    return (
        <SetMonitoringThresholdsForm {...thresholdProps} />
    )
}
export default SetMonitoringThresholds