import { CustomCheckbox } from 'components/CustomControls/CustomCheckbox'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import Icon from 'components/Icon/Icon'
import { getPerformanceStateInfos } from 'components/PagedCardList/getPerformanceStateInfos'
import { ENotificationPriority, ENotificationType, EPerformanceState } from 'enums/enums'
import React, { PropsWithChildren } from 'react'
import fonts from 'style/fonts'
import styled from 'styled-components'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'
import colors from 'style/colors'
import { i18nKeys } from 'i18n/keys'
import { NotificationTypeInfos } from 'components/PagedCardList/NotificationTypeInfos'
import { useIntl } from 'react-intl'

type TRootProps = {
    $color: string
    $selected: boolean
    $borderColor: string
    $isfiltered: boolean
    $isHeight?: string
}

const Root = styled.div<TRootProps>`
    width: 100%;
    border-radius: 8px;
    border: solid ${p => p.$isfiltered ? '3px' : '1px'} #c6ccd4;
    border-color: ${p => p.$borderColor};
    background-color: ${p => p.$selected ? '#C7EBE7' : '#ffffff'};
    fill: ${p => p.$color};
    padding-top: 8px;
    padding-bottom: 16px;
    height: ${p => p.$isHeight}
`

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    padding-right: 16px;
    padding-left: 8px;
    
    #iconContainer {
        width: 50px;

        #notificationPriority {
            color: ${colors.extra.EXTRA_GRAY} !important;
        }
    }
    
`

const Title = styled.div`
    display: flex;
    align-items: center;
    font-family: LatoBold;
    font-weight: 400;
    color: #303030;
    font-size: 14px;
    line-height: 24px;
`

const TitleText = styled.div`
    width: 125px;    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-decoration: underline;
`

const Line = styled.div`
    height: 2px;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 2px;
    background-color: #c6ccd4;
`

const ValueContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 8px;
`

const ValueBlock = styled.div`
    height: 42px;
    width: 88px;
`

const Value = styled.div<{$color: string}>`
    display: grid;
    place-items: center;
    font-size: 16px;
    font-family: ${fonts.PRIMARY_REGULAR};
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    color: ${p => p.$color ? p.$color : '#303030'};
    display: flex;
    justify-content: center;
`

const Text = styled.div`
    display: grid;
    place-items: center;
    font-size: 12px;
    font-family: ${fonts.PRIMARY_REGULAR};
    font-weight: 600;
    color: #959595;
    font-stretch: normal;
    font-style: normal;
`

const StateContainer = styled.div`
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    padding-left: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
`

const StateName = styled.div`
    align-items: center;
    height: 20px;
    font-family: LatoBold;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #303030;
    margin-right: 4px;
`

const StateValue = styled.div<{$color: string}>`
    align-items: center;
    height: 20px;
    font-family: LatoBold;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: ${p => p.$color};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;    
`

type TInfoBlock = {
    value: number
    state: EPerformanceState
    text: string
    tooltip?: string
}

export type TInfoTable = {
    value: string
    text: string
    color: string
    tooltip?: string
    labelTooltip?: string
}

type TProps = {
    id: string
    isChecked: boolean
    isFiltered: boolean
    selectAll: boolean
    overallState?: EPerformanceState

    handleCheckboxClick: (value: boolean) => void
    openDetails?: () => void

    title: string
    borderColor: string
    iconInfo: { icon: string, fill: string }

    tableInfos?: TInfoTable[]
    blockInfos?: TInfoBlock[]

    height?: string

    notificationPriority: ENotificationPriority
    notificationType: ENotificationType
}

const InfoCard = (props: PropsWithChildren<TProps>) => {
    const { id, isChecked, selectAll, title, iconInfo, borderColor, isFiltered, blockInfos, tableInfos, overallState, handleCheckboxClick, openDetails, height, notificationPriority, notificationType } = props

    const renderBlockInfos = () => {
        return blockInfos?.map((info, index) => {
            let color = ''
            let valueRender = `${info.value} %`

            if (overallState === EPerformanceState.IGNORED || overallState === EPerformanceState.OFFLINE) {
                color = '#959595'
                valueRender = '-'
            } else if (info.state === EPerformanceState.CRITICAL || info.state === EPerformanceState.WARNING) {
                color = getPerformanceStateInfos(info.state).color
            }

            if (info.value < 0) {
                color = '#959595'
                valueRender = '-'
            }

            let textElement = <>
                <Value $color={color}>
                    {valueRender}
                </Value>
                <Text>
                    {info.text}
                </Text>
            </>

            if (info.tooltip) {
                textElement = <CustomTooltip title={info.tooltip}>
                    <div>
                        <Value $color={color}>
                            {valueRender}
                        </Value>
                        <Text>
                            {info.text}
                        </Text>
                    </div>
                </CustomTooltip>
            }

            return (
                <ValueBlock key={index}>
                    {textElement}
                </ValueBlock>
            )
        })
    }

    const renderTableInfo = () => {
        return tableInfos?.map((info, index, array) => {

            let textElement = <StateValue $color={info.color}>
                {info.value}
            </StateValue>
            
            if (info.tooltip) {
                textElement = <CustomTooltip title={info.tooltip}>
                    <StateValue $color={info.color}>
                        {info.value}
                    </StateValue>
                </CustomTooltip>
            }

            return (
                <div key={index}>
                    <StateContainer>
                        {renderLabelTooltip(info.text, info.labelTooltip)}
                        {textElement}
                    </StateContainer>
                    
                    {index !== array.length - 1 && <Line /> }
                </div>
            )
        })
    }

    const renderLabelTooltip = (text: string, tooltipText?: string) => {
        if (!tooltipText) {
            return (
                <StateName>
                    {text}
                </StateName>
            )
        }

        return (
            <CustomTooltip title={tooltipText}>
                <StateName>
                    {text}
                </StateName>
            </CustomTooltip>
        )

    }

    const renderLine = () => {
        if (tableInfos !== undefined) {
            return <Line />
        }
    }

    const intl = useIntl()

    const renderNotificationPriorityIcon = () => {
        let text = ''
        switch (notificationPriority) {
            case ENotificationPriority.HIGH:
                text = `${intl.formatMessage(i18nKeys.threshold_type_prefix)} ${intl.formatMessage(NotificationTypeInfos[notificationType].translationKey)}
                Prio: ${intl.formatMessage(i18nKeys.notification_priority_high)}`

                return (
                    <CustomTooltip title={text}>
                        <NotificationImportantIcon id='notificationPriority' data-testid='notificationPriorityHigh' />
                    </CustomTooltip>
                )
            case ENotificationPriority.LOW:
                text = `${intl.formatMessage(i18nKeys.threshold_type_prefix)} ${intl.formatMessage(NotificationTypeInfos[notificationType].translationKey)}
                Prio: ${intl.formatMessage(i18nKeys.notification_priority_low)}`

                return (
                    <CustomTooltip title={text}>
                        <NotificationsPausedIcon id='notificationPriority' data-testid='notificationPriorityLow' />
                    </CustomTooltip>
                )
            case ENotificationPriority.NORMAL:
                text = `${intl.formatMessage(i18nKeys.threshold_type_prefix)} ${intl.formatMessage(NotificationTypeInfos[notificationType].translationKey)}
                Prio: ${intl.formatMessage(i18nKeys.notification_priority_normal)}`

                return (
                    <CustomTooltip title={text}>
                        <NotificationsIcon id='notificationPriority' data-testid='notificationPriorityNormal' />
                    </CustomTooltip>
                )
        }
    }

    return (
        <Root $color={iconInfo.fill} $selected={isChecked} $borderColor={borderColor} $isfiltered={isFiltered} $isHeight={height}>
            <TitleContainer>
                <Title>
                    <CustomCheckbox
                        id={id}
                        onChange={(_, checked) => handleCheckboxClick(checked)}
                        disabled={selectAll}
                        checked={isChecked || selectAll}
                    />
                    <CustomTooltip title={title}>
                        <TitleText onClick={openDetails}>
                            {title}
                        </TitleText>
                    </CustomTooltip>
                </Title>
                <div id='iconContainer'>
                    {renderNotificationPriorityIcon()}
                    <Icon d={iconInfo.icon} />
                </div>
            </TitleContainer>
            
            {renderTableInfo()}
            
            {renderLine()}

            <ValueContainer>
                {renderBlockInfos()}
            </ValueContainer>
            
        </Root>
    )
}
export default InfoCard