import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TPlugin, TPluginKindType } from 'types/businessLogic/plugin'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { tableInfo } from 'views/Plugins/pluginsTableColumns'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'

class PluginsTablePreparer {
    public static preparePluginRows = (plugins: TPlugin[], pluginTypes: TPluginKindType[]): TTableRowData[] =>
        plugins.map(plugin => ({
            ...plugin,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.hostName.key,
                    translationKey: tableInfo.columnConfig.hostName.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    readOnly: false,
                    [GeneralHelper.propertyTypes.string.key]: plugin.hostName
                },
                {
                    name: tableInfo.columnConfig.siteName.key,
                    translationKey: tableInfo.columnConfig.siteName.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: plugin.siteName
                },
                {
                    name: tableInfo.columnConfig.siteLabels.key,
                    translationKey: tableInfo.columnConfig.siteLabels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: plugin.siteLabels
                },
                {
                    name: tableInfo.columnConfig.type.key,
                    translationKey: tableInfo.columnConfig.type.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: () => {
                        const pluginType = PluginsTablePreparer.findPluginType(pluginTypes, plugin.type)
                        return (pluginType && pluginType.translationKey) ? <TransMessage {...i18nKeys[pluginType.translationKey]} /> : plugin.type
                    }
                },
                {
                    name: tableInfo.columnConfig.host.key,
                    translationKey: tableInfo.columnConfig.host.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: plugin.host
                },
                {
                    name: tableInfo.columnConfig.port.key,
                    translationKey: tableInfo.columnConfig.port.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: plugin.port
                }
            ]
        }))

    public static prepareGroups = (groups: TTableGroup<TPlugin>[], pluginTypes: TPluginKindType[]): TTableGroup<TTableRowData>[] => groups.map(group => ({
        ...group,
        data: group.data ? PluginsTablePreparer.preparePluginRows(group.data, pluginTypes) : undefined
    }))

    static findPluginType = (pluginTypes: TPluginKindType[], pluginType: number): TPluginKindType | undefined =>
        pluginTypes.find(plugin => plugin.type === pluginType)
}

export default PluginsTablePreparer