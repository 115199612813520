import { TChartEntry } from 'components/CustomControls/CustomCharts'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import GeneralHelper from 'helpers/GeneralHelper'
import JobService from 'services/jobService'
import { cancelJobsAsync, retryJobsAsync } from 'state/slices/siteJobSlice'
import { pushItem } from 'state/slices/uploadManagerSlice'
import { useAppDispatch } from 'state/store'
import colors from 'style/colors'
import styled from 'styled-components'
import { TBatchJob, TCancelAndRetryJobs, TJob } from 'types/businessLogic/job'
import { TTableFetchState } from 'types/network'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import BatchJobDetails from 'views/SiteJobs/BatchJob/BatchJobDetails'
import { tableInfo } from 'views/SiteJobs/siteJobTableColumns'
import SiteJobTablePreparer from 'views/SiteJobs/siteJobTablePreparer'
import { CustomModal } from 'components/CustomControls/CustomModal'
import SiteJobDetails from 'views/SiteJobs/JobDetails/SiteJobDetails'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { useIntl } from 'react-intl'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`

const BatchJobContainer = () => {
    const { id } = useParams()
    const batchJobId = parseInt(id!)
    
    const intl = useIntl()
    const dispatch = useAppDispatch()
    
    const [batchJob, setBatchJob] = useState<TBatchJob>()
    const [jobs, setJobs] = useState<TJob[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [groups, setGroups] = useState<TTableGroup<TJob>[]>([])
    const [currentSingleJobId, setCurrentSingleJobId] = useState<number>()

    const getBatchJob = useCallback(async () => {
        try {
            const job = await JobService.getBatchJob(batchJobId)
            setBatchJob(job)
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }

    }, [batchJobId, dispatch])

    useEffect(() => {
        getBatchJob()
    }, [getBatchJob])

    useBreadCrumbs([
        i18nKeys.breadcrumb_site_jobs,
        i18nKeys.batch_job_details_title
    ])

    const getBatchJobDetails = useCallback(async (params: TTableFetchState) => {
        const getBatchJobsGroups = async () => {
            const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)
            const groupResult = await JobService.getBatchJobsGroups(groupingParams)
    
            const newGroups: TTableGroup<TJob>[] = []

            for (const group of groupResult.groups) {
                const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
                const jobsResult = await JobService.getJobsForBatchJob(requestParams)
                
                newGroups.push({
                    count: jobsResult.totalNumber,
                    name: group.name,
                    data: jobsResult.jobs,
                    translationKey: group.translationKey
                })
            }

            setGroups(newGroups)
        }
    
        if (!params.global.groupBy) {
            const overwrittenParams = {...params}
            if (overwrittenParams.global.order === 'none') {
                overwrittenParams.global.descending = true
            }
            overwrittenParams.global.filters = {
                ...overwrittenParams.global.filters,
                BatchJobID: batchJobId.toString()
            }

            const requestParams = GeneralHelper.buildRequestFromTableState(overwrittenParams)
            const jobsResult = await JobService.getJobsForBatchJob(requestParams)
            setJobs(jobsResult.jobs)
            setTotalCount(jobsResult.totalNumber)
        } else if (params.groupName) {
            const overwrittenParams = {...params}
            overwrittenParams.global.filters = {
                ...overwrittenParams.global.filters,
                BatchJobID: batchJobId.toString()
            }

            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(overwrittenParams, params.groupName)
            const jobsResult = await JobService.getJobsForBatchJob(requestParams)
            setJobs(jobsResult.jobs)
            setTotalCount(jobsResult.totalNumber)
        } else {
            getBatchJobsGroups()
        }
    }, [batchJobId])

    const prepareChartData = (job: TBatchJob): TChartEntry[] => [
        {
            id: 'CountDone',
            name: 'Done',
            value: job.countDone,
            color: colors.extra.SUCCESS
        },
        {
            id: 'CountFailed',
            name: 'Failed',
            value: job.countFailed,
            color: colors.extra.ALERT
        },
        {
            id: 'CountTodo',
            name: 'TODO',
            value: job.countTodo,
            color: colors.brandColors.BLUE
        },
        {
            id: 'CountTransmitted',
            name: 'Transmitted',
            value: job.countTransmitted,
            color: colors.shades.DEEP_SHADE
        },
        {
            id: 'CountCanceled',
            name: 'Canceled',
            value: job.countCanceled,
            color: colors.extra.WARNING
        }
    ]

    if (!batchJob) return null

    const prepareCancelAndRetryJobs = (selectedJobs: Array<TTableRowData & {job: string}>): TCancelAndRetryJobs[] =>
        selectedJobs.map(job => ({
            id: Number((job.id as string).replace('batchJob', '')),
            isBatchJob: job.job === 'BatchJob'
        }))

    const cancelJobs = (selectedJobs: Array<TTableRowData & {job: string}>) => {
        const preparedJobs = prepareCancelAndRetryJobs(selectedJobs)

        dispatch(cancelJobsAsync(preparedJobs))
    }

    const retryJobs = (selectedJobs: Array<TTableRowData & {job: string}>) => {
        const preparedJobs = prepareCancelAndRetryJobs(selectedJobs)

        const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_retry_jobs, {count: preparedJobs.length})

        const uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)
        dispatch(pushItem(uploadManagerItem))
        dispatch(retryJobsAsync({jobs: preparedJobs, uploadManagerItemId: uploadManagerItem.id}))
    }

    const preparedGroups = SiteJobTablePreparer.prepareGroups(groups, setCurrentSingleJobId)
    const data = SiteJobTablePreparer.prepareJobs(jobs, setCurrentSingleJobId)
    
    const renderModal = () => {
        const siteJobDetails = jobs.find(job => job.job !== 'BatchJob' && job.id === currentSingleJobId)

        if (!siteJobDetails) return

        return (
            <CustomModal
                open={true}
                onClose={() => setCurrentSingleJobId(undefined)}
                title={<TransMessage {...i18nKeys[siteJobDetails.translationKey]} />}
                description={siteJobDetails.about}
            >
                <SiteJobDetails jobId={siteJobDetails.id as number} switchToRelatedJob={setCurrentSingleJobId} />
            </CustomModal>
        )
    }

    return (
        <ViewContainer>
            <BatchJobDetails
                batchJob={batchJob}
                batchJobId={batchJobId}
                getBatchJobDetails={getBatchJobDetails}
                getBatchJob={getBatchJob}
                chartData={prepareChartData(batchJob)}
                tableInfo={tableInfo}
                data={data}
                groups={preparedGroups}
                totalCount={totalCount}
                cancelJobs={cancelJobs}
                retryJobs={retryJobs}
                isDetailOpen={!!currentSingleJobId}
            />
            {renderModal()}
        </ViewContainer>
    )
}

export default BatchJobContainer