import { FormLabel, FormCheckbox } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import React, { useState } from 'react'
import { Control, UseFormSetValue } from 'react-hook-form'
import styled from 'styled-components'
import { TTranslationKey } from 'types/ui/translation'
import { TUmbrellaRoleDetailsForm } from 'views/Admin/UmbrellaRoles/UmbrellaRoleForm'

const RightsList = styled.ul`
    width: 100%;
    list-style: none;
    padding-inline-start: 0;

    & > li {
        display: flex;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 24px;
        height: 24px;
    }

    & > li:hover {
        background-color: #F0F0F0;
    }

    & > li:first-child > div > label {
        margin-left: 0px;
    }

    .MuiButtonBase-root {
        padding: 0px 9px;
    }
`

const CustomFormLabel = styled(FormLabel)`
    height: 24px;
    padding: 0px;
`

type TProps = {
    parent: TRightsItem
    listItems: Array<TRightsItem>
    disabled?: boolean
    defaultvalue: boolean
    setValue: UseFormSetValue<TUmbrellaRoleDetailsForm>
}

type TRightsItem = {
    label: TTranslationKey
    id: string
    control: Control<any>
}

const FormRightsList = (props: TProps) => {

    const {parent, listItems, disabled, defaultvalue} = props
    const {setValue} = props
    const [expanded, setExpanded] = useState(defaultvalue)

    return (
        <RightsList>
            <li>
                <CustomFormLabel htmlFor={parent.id}>
                    <TransMessage {...parent.label} />
                </CustomFormLabel>
                <FormCheckbox
                    name={parent.id}
                    control={parent.control}
                    disabled={disabled}
                    height='24px'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (!event.target.checked) {
                            listItems.forEach(item => setValue(item.id as keyof TUmbrellaRoleDetailsForm, false))
                        }
                        setExpanded(event.target.checked)
                    }}
                />
            </li>
           
            {(expanded || disabled) &&
                listItems.map(item => {
                    return (
                        <li key={item.id}>
                            <CustomFormLabel htmlFor={item.id}>
                                <TransMessage {...item.label} />
                            </CustomFormLabel>
                            <FormCheckbox
                                height='24px'
                                name={item.id}
                                control={item.control}
                                disabled={disabled}
                            />
                        </li>
                    )
                })
            }
        </RightsList>
    )
}

export default FormRightsList