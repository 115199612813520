import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { CustomCheckbox } from 'components/CustomControls/CustomCheckbox'
import Icon from 'components/Icon/Icon'
import { TUseCardPage } from 'hooks/useCardPage'
import { ECheckboxState, EPerformanceState } from 'enums/enums'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TOverallState, TPerformanceItem, TPerformanceItemsPerSite } from 'types/businessLogic/performance'
import { TTranslationKey } from 'types/ui/translation'
import TransMessage from 'components/TransMessage/TransMessage'
import { getPerformanceStateInfos } from 'components/PagedCardList/getPerformanceStateInfos'
import StatusDot from 'components/StatusDot/StatusDot'
import SiteHelper from 'helpers/SiteHelper'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'

type TSyledAccordionSummaryProps = {
    $selected: boolean
}

const StyledAccordionSummary = styled(AccordionSummary)<TSyledAccordionSummaryProps>`
    background-color: ${p => p.$selected ? '#C7EBE7' : '#f0f0f0'};

    &.MuiAccordionSummary-root {
        transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
`

const StyledAccordionDetails = styled(AccordionDetails)`
    background-color: #f9f9f9;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    font-family: LatoBold;
    font-weight: 400;
    color: #303030;
    font-size: 14px;
    line-height: 24px;
`

const IconWrapper = styled.div`
    display: flex;
`

const Marker = styled.div<{$color: string}>`
    fill: ${p => p.$color};
    display: flex;
    align-items: center;
    margin-left: 8px;

    & > svg {
        margin-left: 4px;
    }
`

const ContentWrapper = styled.div`
    display: flex;
    grid-gap: 16px;
    flex-wrap: wrap;
    width: 100%;
`

const SiteStateWrapper = styled.div`
    display: flex;
    width: 200px;
    padding-left: 20px;
`

const NameWrapper = styled.div`
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
`

type TProps = TUseCardPage & {
    overallState?: TOverallState
    performanceItemsPerSite: TPerformanceItemsPerSite
    isParentChecked: boolean
    itemRenderer : (item: any, key: number, isChecked: boolean, isFiltered: boolean) => JSX.Element
    startOpen?: boolean
}

const PagedCardGroup = (props: TProps) => {
    const { startOpen, isParentChecked, overallState, performanceItemsPerSite, fetchState, selectedItems, selectAll } = props
    const { itemRenderer, onSiteSelect } = props

    const [isExpanded, setIsExpanded] = useState<boolean>(startOpen ?? false)

    useEffect(() => {
        setIsExpanded(startOpen ?? false)
    }, [startOpen])

    const initialCheckBoxState = () : ECheckboxState => {
        let currentState = ECheckboxState.UNCHECKED
        if (isParentChecked || selectedItems.filter(item => item.gatewayId === performanceItemsPerSite.gatewayId).length === performanceItemsPerSite.items.length) {
            currentState = ECheckboxState.CHECKED
        } else if (selectedItems.filter(item => item.gatewayId === performanceItemsPerSite.gatewayId).length > 0) {
            currentState = ECheckboxState.INDETERMINATE
        }

        return currentState
    }

    const checkboxState = initialCheckBoxState()
    
    const renderOverallState = () => {
        if (!overallState)
            return null

        return <>
            {renderIcon(overallState.healthy, EPerformanceState.HEALTHY)}
            {renderIcon(overallState.warning, EPerformanceState.WARNING)}
            {renderIcon(overallState.critical, EPerformanceState.CRITICAL)}
            {renderIcon(overallState.offline, EPerformanceState.OFFLINE)}
            {renderIcon(overallState.ignored, EPerformanceState.IGNORED)}
        </>
    }

    const renderIcon = (value: number, state: EPerformanceState) : JSX.Element => {
        if (value === 0) {
            return <></>
        }

        const stateInfo = getPerformanceStateInfos(state)

        return <Marker $color={stateInfo.color}>
            {value} <Icon d={stateInfo.icon} size={18} />
        </Marker>
    }

    const renderItems = () => {
        const isSelected = (item: TPerformanceItem) => {
            return selectedItems.some(selectedItem => selectedItem.id === item.id && selectedItem.type === item.type)
        }
    
        const isFiltered = (item: TPerformanceItem) => {
            return fetchState.filterByStates.some(state => state === item.overallState)
        }

        return performanceItemsPerSite.items.map((item, index) => {
            return itemRenderer(item, index, isSelected(item), isFiltered(item))
        })
    }

    const handleCheckBoxClick = (e: React.MouseEvent) => {
        e.stopPropagation() // So the click on the checkbox does not expend the Accordion

        const currentState = checkboxState
        let newState = ECheckboxState.UNCHECKED
        if (currentState === ECheckboxState.INDETERMINATE || currentState === ECheckboxState.UNCHECKED) {
            newState = ECheckboxState.CHECKED
        }

        onSiteSelect([performanceItemsPerSite], newState === ECheckboxState.CHECKED)
    }

    const indeterminate = checkboxState === ECheckboxState.INDETERMINATE
    const checked = checkboxState === ECheckboxState.CHECKED || selectAll

    const renderName = (value: string | TTranslationKey) => {
        if (typeof value === 'string') {
            return value
        }

        return <TransMessage {...value} />
    }

    let accordionId : string | undefined = undefined

    if (props.id) {
        if (performanceItemsPerSite.gatewayName != null && typeof performanceItemsPerSite.gatewayName === 'object') {
            accordionId = accordionId = `${props.id}-${(performanceItemsPerSite.gatewayName as TTranslationKey).id}`
        } else {
            accordionId = `${props.id}-${performanceItemsPerSite.gatewayName}`
        }
    }
    
    return (
        <Accordion id={accordionId} onChange={(_, expanded) => setIsExpanded(expanded)} expanded={isExpanded}>
            <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                $selected={indeterminate || checked}
            >
                <Header>
                    <TitleWrapper>
                        <CustomCheckbox
                            id={performanceItemsPerSite.gatewayId.toString()}
                            onClick={handleCheckBoxClick}
                            indeterminate={indeterminate}
                            checked={checked}
                            disabled={selectAll}
                        />
                        <CustomTooltip
                            title={renderName(performanceItemsPerSite.gatewayName)}>
                            <NameWrapper>
                                {renderName(performanceItemsPerSite.gatewayName)}
                            </NameWrapper>
                        </CustomTooltip>
                        {
                            performanceItemsPerSite.gatewayState !== undefined &&
                            <SiteStateWrapper>
                                <StatusDot
                                    color={SiteHelper.getSiteStateColor(performanceItemsPerSite.gatewayState)}
                                    textKey={SiteHelper.getSiteStateIntlKey(performanceItemsPerSite.gatewayState)}
                                />
                            </SiteStateWrapper>
                        }
                    </TitleWrapper>
                    <IconWrapper id={accordionId + '-overall-state'}>
                        {renderOverallState()}
                    </IconWrapper>
                </Header>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <ContentWrapper>
                    {
                        isExpanded &&
                        renderItems()
                    }
                </ContentWrapper>
            </StyledAccordionDetails>
        </Accordion>
    )
}
export default PagedCardGroup