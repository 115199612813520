import React, { forwardRef } from 'react'
import { Slider, SliderProps } from '@mui/material'
import styled from 'styled-components'

const StyledSlider = styled(Slider)`
    width: 360px;
`

type TProps = SliderProps & {
    value: number
    onInputChange: (value: number) => void
}

const CustomSlider = forwardRef<HTMLSpanElement, TProps>((props: TProps, ref) => {
    const { value: sliderValue, onInputChange, ...rest} = props

    const onChange = (event: Event, value: number | number[]) => {
        onInputChange(value as number)
    }

    return (
        <StyledSlider
            aria-labelledby='label'
            onChange={onChange}
            value={sliderValue}
            ref={ref}
            {...rest}
        />
    )
})

export default CustomSlider