import { yupResolver } from '@hookform/resolvers/yup'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormAsyncSelect } from 'components/FormControls/FormAsyncSelect'
import { FormButtonGroup, FormContent, FormGroup, FormInput, FormLabel, FormWrapper, FormFooter, InputWrapper, } from 'components/FormControls/FormControls'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormHelper from 'helpers/FormHelper'
import Validation from 'helpers/Validation'
import UmbrellaRolesService from 'services/umbrellaRolesService'
import { createUmbrellaUserAsync } from 'state/slices/umbrellaUserSlice'
import { useAppDispatch } from 'state/store'
import { TCreateUmbrellaUserRequest } from 'types/businessLogic/user'
import { TQueryFilter } from 'types/network'
import * as yup from 'yup'
import PasswordRequirements from 'components/PasswordRequirements/PasswordRequirements'
import { TPasswordRequirements } from 'types/businessLogic/validation'
import AuthService from 'services/authService'
import { useIntl } from 'react-intl'
import { debounce } from '@mui/material'
import { TSelectOptions } from 'types/ui/dropdown'
import { useModal } from 'hooks/modalProvider'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

export type TCreateUmbrellaUserForm = TCreateUmbrellaUserRequest & {
    rolesOptions: TSelectOptions
}

const CreateUmbrellaUser = () => {
    const intl = useIntl()
    const [nameNotTaken, setNameNotTaken] = useState(true)
    const {closeModal} = useModal()

    const validationSchema = yup.object().shape({
        name: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)).test('name-not-taken', intl.formatMessage(i18nKeys.create_umbrella_user_error_unique_username), () => nameNotTaken),
        password: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)),
        email: yup.string().email(intl.formatMessage(i18nKeys.create_user_input_email_not_matching_regex)).optional()
    })

    const dispatch = useAppDispatch()

    const loadRoles = async (inputValue: string) => {
        try {
            const filters : TQueryFilter[] = []

            if (inputValue.length > 0) {
                filters.push({
                    property: 'Name',
                    value: inputValue,
                    isExact: false
                })
            }
            const rolesResponse = await UmbrellaRolesService.getRoles({
                PageNumber: 1,
                PageSize: 20,
                Filters: filters
            })
    
            return rolesResponse.roles.map(umbrellaRole => {
                return {
                    value: umbrellaRole.name,
                    label: umbrellaRole.name
                }
            })
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const roleOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            loadRoles(inputText).then((options) => callback(options))
        }, 500), [])

    const [passwordRequirements, setPasswordRequirements] = useState<TPasswordRequirements>()
    const [password, setPassword] = useState<string>()
    const [isPasswordvalid, setIsPasswordValid] = useState<boolean>(false)

    useEffect(() => {
        const getPasswordRequirements = async () => {
            try {
                const result = await AuthService.getPasswordSettings()
                setPasswordRequirements(result)
            } catch {
                dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
            }
        }
        getPasswordRequirements()
    }, [dispatch])

    const handlePasswordChange = (value: string) => {
        if (passwordRequirements) {
            const validationResult = Validation.getPasswordValidationError(value, passwordRequirements)
            setIsPasswordValid(validationResult === undefined)
        }
        setPassword(value)
    }

    const handleCreateUmbrellaUserSubmit = (formValues: TCreateUmbrellaUserForm) => {
        FormHelper.removeEmptyFields(formValues)

        formValues.roles = FormHelper.extractValuesFromOptionTypeBase(formValues.rolesOptions)

        dispatch(createUmbrellaUserAsync(formValues))
        closeModal()
    }

    const createUmbrellaUserDefaultValues : TCreateUmbrellaUserForm = {
        id: '',
        name: '',
        password: '',
        rolesOptions: []
    }

    const { handleSubmit, formState, control, trigger } = useForm<TCreateUmbrellaUserForm>({
        mode: 'onChange',
        defaultValues: createUmbrellaUserDefaultValues,
        resolver: yupResolver(validationSchema)
    })

    const { isValid, isValidating, isSubmitting, isDirty } = formState

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleNameChange = useCallback(debounce((value: string) => {
        Validation.isUmbrellaUserNameNotTaken(value, '').then(result => {
            setNameNotTaken(result)
            trigger('name')
        })
    }, 250), [])

    return (
        <FormWrapper onSubmit={handleSubmit(handleCreateUmbrellaUserSubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_site_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='name'>
                            <TransMessage {...i18nKeys.create_user_input_username_label} />
                        </FormLabel>
                        <FormInput
                            name='name'
                            control={control}
                            autoComplete='nope'
                            onChange={handleNameChange}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='email'>
                            <TransMessage {...i18nKeys.create_user_input_email_label} />
                        </FormLabel>
                        <FormInput
                            name='email'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='rolesOptions'>
                            <TransMessage {...i18nKeys.umbrella_user_form_label_roles} />
                        </FormLabel>
                        <FormAsyncSelect
                            name='rolesOptions'
                            control={control}
                            isMulti
                            defaultOptions
                            loadOptions={roleOptions}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='password'>
                            <TransMessage {...i18nKeys.create_user_input_password_label} />
                        </FormLabel>
                        <FormInput
                            name='password'
                            control={control}
                            type='password'
                            autoComplete='new-password'
                            onChange={handlePasswordChange}
                        />
                    </InputWrapper>
                </FormGroup>
                {
                    passwordRequirements &&
                    <FormGroup title={i18nKeys.password_requirements_title}>
                        <PasswordRequirements
                            passwordRequirements={passwordRequirements}
                            password={password}
                        />
                    </FormGroup>
                }
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty || !isPasswordvalid}
                        id='submitBtn'
                        type='submit'
                        endIcon={<Icon d={Icons.TICK} />}
                    >
                        <TransMessage {...i18nKeys.create_umbrella_user_submit_button} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}

export default CreateUmbrellaUser