import { TableCell, TableRow } from '@mui/material'
import { CardHeader, TableCard, TableInfoBlock } from 'components/CustomControls/CustomCharts'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import StatusDot from 'components/StatusDot/StatusDot'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import DateHelper from 'helpers/DateHelper'
import SiteHelper from 'helpers/SiteHelper'
import { TRecentJob } from 'types/businessLogic/job'
import { TTranslationKey } from 'types/ui/translation'
import React, { useCallback, useState } from 'react'
import JobService from 'services/jobService'
import { EDataSyncItems } from 'enums/enums'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'

const RecentSiteJobsElement = () => {
    const [data, setData] = useState<TRecentJob[]>([])
    const [identifier] = useState(uid())

    const headers: TTranslationKey[] = [
        i18nKeys.recent_site_jobs_description_column_name,
        i18nKeys.recent_site_jobs_state_column_name,
        i18nKeys.recent_site_jobs_created_at_column_name
    ]

    const loadData = useCallback(async () => {
        const response = await JobService.getRecentJobs(30)
        setData(response)
    }, [])

    useBackgroundService(EDataSyncItems.JOBS, loadData, identifier)

    const renderJobs = () => {
        return data.map((job, index) => {
            return (
                <TableRow key={index}>
                    <TableCell className='desc'>
                        <CustomTooltip title={job.description} placement='top-start'>
                            <span>{job.description}</span>
                        </CustomTooltip>
                    </TableCell>
                    <TableCell padding='none'>
                        <StatusDot
                            color={SiteHelper.getJobStateColor(job.state)}
                            textKey={SiteHelper.getJobStateIntlKey(job.state)}
                        />
                    </TableCell>
                    <TableCell>
                        {DateHelper.convertUTCToLocal(job.createdAt, DateHelper.formats.YEAR_TO_SECONDS_PM_AM)}
                    </TableCell>
                </TableRow>
            )
        })
    }

    const renderTable = () => {
        return (
            <>
                <CardHeader title={i18nKeys.recent_site_jobs_heading} />
                <TableCard headers={headers}>
                    {renderJobs()}
                </TableCard>
            </>
        )
    }

    return (
        <>
            { data.length ? renderTable() : <TableInfoBlock> <TransMessage {...i18nKeys.recent_site_jobs_no_jobs} /> </TableInfoBlock> }
        </>
        
    )
}

export default RecentSiteJobsElement