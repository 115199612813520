// eslint-disable-next-line import/no-anonymous-default-export
export default {
    MOUNT_EVEREST: 9999999,
    HIGHEST: 9999,
    HIGH: 8000,
    FULL_SCREEN_DASHBOARD: 1002,
    
    MESSAGES: 1110,

    MODAL: 1100, // *
    PROFILE_BAR: 1050,
    SIDE_NAV: 1001, // *
    
    MEDIUM: 1000,   // QuickActionMenu
    LOW_TO_MEDIUM: 750,
    LOW: 500,
    VERY_LOW: 200,
    
    DEFAULT: 1,
    
    BELOW: -1,
    DOWN_BELOW: -9000,
    MARIANA_TRENCH: -9999,
    NODE_MODULES: -9999999
}