import axiosClient from 'api/axiosClient'
import { TFetchParamsRequest, TGroupParams, TGroupResponse } from 'types/network'
import { TReport, TDeleteAllReportsRequest, TDownloadAllReportsRequest } from 'types/businessLogic/report'
import { AxiosResponse } from 'axios'

class ReportsService {
    private static _api = axiosClient()

    public static async getReports(params: TFetchParamsRequest): Promise<{reports: TReport[], totalNumber: number}> {
        const response = await this._api.get<{reports: TReport[], totalNumber: number}>('Reports', {
            params: params
        })
        return response.data
    }
    
    public static async deleteReports(ids: number[]) {
        await this._api.delete('Reports', {
            data: {
                Ids: ids
            }
        })
    }

    public static async deleteAllReports(params: TDeleteAllReportsRequest) {
        await this._api.delete('Reports/All', {
            data: params
        })
    }

    public static async downloadReports(ids: number[]) {
        const response = await this._api.post('Reports/Download', {
            Ids: ids
        },
        {
            responseType: 'arraybuffer',
            timeout: 5 * 60 * 1000,
        })

        this.downloadReportZip(response)
    }

    public static async downloadAllReports(params: TDownloadAllReportsRequest) {
        const response = await this._api.post('Reports/All/Download', params, {
            responseType: 'arraybuffer',
            timeout: 5 * 60 * 1000,
        })
        this.downloadReportZip(response)
    }

    public static async getReportsGroups(params: TGroupParams) {
        const response = await this._api.get<TGroupResponse>(`Reports/groups/${params.groupBy}`, {
            params: {
                Descending: params.descending,
                Filters: params.filters,
                PageSize: params.pageSize,
                PageNumber: params.pageNumber
            }
        })
        return response.data
    }

    public static downloadReportZip(response: AxiosResponse<any>) {
        const blob = new Blob([response.data], {type: response.headers['content-type']})
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.href = url
        const contentDisposition = response.headers['content-disposition']
        let fileName = 'site_reports.zip'
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+);/)
            if (fileNameMatch && fileNameMatch.length === 2)
                fileName = fileNameMatch[1]
        }
        link.setAttribute('download', fileName)
        link.click()
        window.URL.revokeObjectURL(url)
        link.remove()
    }
}

export default ReportsService
