import { ListItem, ListItemIcon } from '@mui/material'
import Icon from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import colors from 'style/colors'
import { TTranslationKey } from 'types/ui/translation'
import React from 'react'
import styled from 'styled-components'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'

const StyledListItemText = styled.span`
    font-family: ${fonts.PRIMARY_REGULAR};
    font-size: ${typographyScale.TYPE18};
    line-height: ${lineHeights.TYPE18LineHeight};
    letter-spacing: ${letterSpacings.TYPE18LS};
`

type TProps = {
    id: string
    icon: string | JSX.Element
    textKey: TTranslationKey
    onClick: () => void
}

const StyledListItemIcon = styled(ListItemIcon)`
    fill: ${colors.primary.DARK_PRIMARY};
`

const renderIcon = (icon: string | JSX.Element) => {
    if (typeof icon === 'string') {
        return <Icon d={icon} />
    } else {
        return icon
    }
}

const QuickActionMenuItem = (props: TProps) => {
    return (
        <ListItem id={props.id} onClick={props.onClick}>
            <StyledListItemIcon>
                {renderIcon(props.icon)}
            </StyledListItemIcon>
            <StyledListItemText>
                <TransMessage {...props.textKey} />
            </StyledListItemText>
        </ListItem>
    )
}
export default QuickActionMenuItem