import axiosClient from 'api/axiosClient'
import { TChangePasswordRequest, TChangePasswordResponse, TCheckLoginResponse, TLoginRequest, TLoginResponse, TOptionsResponse } from 'types/auth'
import { TPasswordRequirements } from 'types/businessLogic/validation'

class AuthService {
    private static _api = axiosClient()

    public static async login(loginInfo : TLoginRequest): Promise<TLoginResponse> {
        const response = await this._api.post<TLoginResponse>('Auth/Login', loginInfo)
        if (!response.data.succeeded) {
            throw Error('login failed')
        }
        return response.data
    }

    public static async loginWindows(): Promise<TLoginResponse> {
        const response = await this._api.get<TLoginResponse>('Auth/Windows/Login')
        if (!response.data.succeeded) {
            throw Error('login failed')
        }
        return response.data
    }

    public static async checkLogin() : Promise<TCheckLoginResponse> {
        const response = await this._api.get<TCheckLoginResponse>('Auth/CheckLogin')
        return response.data
    }

    public static async getOptions(): Promise<TOptionsResponse> {
        const response = await this._api.get<TOptionsResponse>('Auth/Options')
        return response.data
    }

    public static async getPasswordSettings(): Promise<TPasswordRequirements> {
        const response = await this._api.get<TPasswordRequirements>('Auth/GetPasswordSettings')
        return response.data
    }

    public static async changePassword(changePassword : TChangePasswordRequest): Promise<TChangePasswordResponse> {
        const response = await this._api.post<TChangePasswordResponse>('Auth/ChangePassword', changePassword)
        if (!response.data.succeeded) {
            throw Error('change password failed')
        }
        return response.data
    }

    public static async acceptEula(): Promise<void> {
        await this._api.post('Auth/AcceptEULA')
    }

    public static async logout(): Promise<void> {
        await this._api.get('Auth/Logout')
    }
}

export default AuthService