import { yupResolver } from '@hookform/resolvers/yup'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormGroup, FormLabel, FormWrapper, FormFooter, InputWrapper } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import SiteService from 'services/siteService'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import { TModalProps } from 'types/ui/modal'
import * as yup from 'yup'
import Validation from 'helpers/Validation'
import { TAllSiteAuditRequest, TSiteAuditRequest } from 'types/businessLogic/site'
import dayjs from 'dayjs'
import { FormDateTimePicker } from 'components/FormControls/FormDateTimePicker'
import { useModal } from 'hooks/modalProvider'

const validationSchema = yup.object().shape({
    from: yup.string().required().matches(Validation.config.NO_WHITESPACE_START_END),
    till: yup.string().required().matches(Validation.config.NO_WHITESPACE_START_END),
})

export type TCreateSiteAuditReport = {
    from: Date
    till: Date
}

const CreateSiteAuditReport = (props: TModalProps) => {
    const {filters, ids} = props
    const isSelectAll = !!filters
    const dispatch = useAppDispatch()
    const {closeModal} = useModal()
    
    const handleCreateSiteAuditReportSubmit = useCallback(async (formValues: TCreateSiteAuditReport) : Promise<void> => {
        try {
            if (isSelectAll) {
                const request : TAllSiteAuditRequest = {
                    filters: filters,
                    from: dayjs(formValues.from),
                    till: dayjs(formValues.till)
                }

                await SiteService.createAllSiteAuditReport(request)
            } else {
                const request: TSiteAuditRequest = {
                    siteIds: ids as number[],
                    from: dayjs(formValues.from),
                    till: dayjs(formValues.till)
                }

                await SiteService.createSiteAuditReport(request)
            }
            dispatch(showMessage({message: i18nKeys.create_audit_report_success_snack_message, type: EMessageType.SUCCESS}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.create_audit_report_failed_snack_message, type: EMessageType.ERROR}))
        }

        closeModal()
    }, [dispatch, filters, ids, isSelectAll, closeModal])

    const defaultValues : TCreateSiteAuditReport = {
        from: dayjs().subtract(1, 'week').toDate(),
        till: dayjs().toDate()
    }

    const { handleSubmit, formState, control } = useForm<TCreateSiteAuditReport>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema)
    })

    const { isValid, isValidating, isSubmitting } = formState
    
    return (
        <FormWrapper onSubmit={handleSubmit(handleCreateSiteAuditReportSubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_site_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='from'>
                            <TransMessage {...i18nKeys.create_audit_report_modal_start_label} />
                        </FormLabel>
                        <FormDateTimePicker name='from'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='till'>
                            <TransMessage {...i18nKeys.create_audit_report_modal_end_label} />
                        </FormLabel>
                        <FormDateTimePicker name='till'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.create_audit_report_modal_submit_button} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}

export default CreateSiteAuditReport