import CustomAreaChart, { TData } from 'components/CustomControls/CustomAreaChart'
import { CardHeader, CardWrapper } from 'components/CustomControls/CustomCharts'
import { i18nKeys } from 'i18n/keys'
import colors from 'style/colors'
import React, { useCallback, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'state/store'
import { getCoreCPUAsync } from 'state/slices/statisticSlice'
import { AutoSizer } from 'react-virtualized'
import { EDataSyncItems } from 'enums/enums'
import Container from 'components/Container/Container'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'

const CoreCpuElement = () => {
    const data = useAppSelector(store => store.statistic.coreCpu)
    const dispatch = useAppDispatch()
    const request = useRef({
        from: dayjs().subtract(1, 'days'),
        till: dayjs(),
        id: -1
    })

    const [identifier] = useState(uid())

    const loadData = useCallback(async () => {
        request.current = {
            from: dayjs().subtract(1, 'days'),
            till: dayjs(),
            id: -1
        }
        dispatch(getCoreCPUAsync(request.current))
    }, [dispatch])

    useBackgroundService(EDataSyncItems.CORE_PERFORMANCE, loadData, identifier)

    const cpuData: TData[] = data.map(item => ({
        value: item.cpu * 100,
        time: dayjs(item.time).unix()
    }))

    return (
        <>
            <CardHeader title={i18nKeys.umbrella_sites_table_cpu_usage} />
            <Container width='100%' height='100%'>
                <AutoSizer>
                    {({width, height}) => {
                        return (
                            <CardWrapper>
                                <CustomAreaChart
                                    unit='%'
                                    data={cpuData}
                                    graphColor={colors.extra.SUCCESS}
                                    from={request.current.from}
                                    till={request.current.till}
                                    width={width}
                                    height={height}
                                />
                            </CardWrapper>
                        )
                    }}
                </AutoSizer>
            </Container>
        </>
    )
}

export default CoreCpuElement
