import { ClickAwayListener, Paper, Popper } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import borderRadius from 'style/borderRadius'
import shadows from 'style/shadows'
import zIndex from 'style/zIndex'
import styled from 'styled-components'

type TProps = {
    id: string
    open: boolean
    onClose: (event: MouseEvent | TouchEvent) => void
    anchorEl?: HTMLElement | null
}

const StyledPopper = styled(Popper)`
    z-index: ${zIndex.HIGHEST};
`
const StyledPaperSuperSmall = styled(Paper)`
    border-radius: ${borderRadius.SECONDARY};
    box-shadow: ${shadows.QUINARY};

    padding: 20px 8px;
    & .MuiPaper-paper {
        width: 160px;
    }
`
const StyledPaperSmall = styled(Paper)`
    width: 160px;
    max-height: 432px;
    overflow-y: auto;
    box-shadow: none;
`

const DropdownAlternate = (props: PropsWithChildren<TProps>) => {
    const { open, onClose, anchorEl, children } = props
    
    return (
        <StyledPopper
            open={open}
            anchorEl={anchorEl}
            placement='bottom-start'>
            <ClickAwayListener onClickAway={onClose}>
                <StyledPaperSuperSmall>
                    <StyledPaperSmall>
                        {children}
                    </StyledPaperSmall>
                </StyledPaperSuperSmall>
            </ClickAwayListener>
        </StyledPopper>
    )

}

export default DropdownAlternate