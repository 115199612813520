import { getPerformanceStateInfos } from 'components/PagedCardList/getPerformanceStateInfos'
import InfoCard from 'components/PagedCardList/InfoCard'
import { ThresholdTypeInfos } from 'components/PagedCardList/ThresholdTypeInfos'
import { EThresholdType } from 'enums/enums'

import { TUseCardPage } from 'hooks/useCardPage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { TSiteSystemPerformance } from 'types/businessLogic/siteSystem'

const Container = styled.div`
    width: 264px;
`

type TProps = TUseCardPage & {
    siteSystem: TSiteSystemPerformance
    isChecked: boolean
    isFiltered: boolean
    openDetails?: (id: number) => void
}

const SiteSystemInfoCard = (props: TProps) => {
    const { isChecked, isFiltered, selectAll, siteSystem, onItemSelect, openDetails } = props
    
    const siteSystemState = siteSystem.overallState
    const stateInfo = getPerformanceStateInfos(siteSystemState, siteSystem.isSiteIgnored)
    const borderColor = isFiltered ? stateInfo.color : '#c6ccd4'

    const handleCheckboxClick = (checked: boolean) => {
        onItemSelect([siteSystem], checked)
    }

    const handleOpenDetails = () => {
        openDetails && openDetails(siteSystem.entityId)
    }

    const intl = useIntl()
     
    const thresholdsTooltip = (warningValue: number, criticalValue: number, thresholdType: EThresholdType) => {
        return `${intl.formatMessage(i18nKeys.threshold_type_prefix)} ${intl.formatMessage(ThresholdTypeInfos[thresholdType].translationKey)}
        Warning: >${warningValue}%
    	Critical: >${criticalValue}%`
    }

    return (
        <Container>
            <InfoCard
                borderColor={borderColor}
                handleCheckboxClick={handleCheckboxClick}
                iconInfo={{ icon: stateInfo.icon, fill: stateInfo.color }}
                id={'siteSystem_' + siteSystem.id.toString()}
                isChecked={isChecked}
                isFiltered={isFiltered}
                selectAll={selectAll}
                title={siteSystem.host}
                overallState={siteSystemState}
                blockInfos={[
                    { state: siteSystem.cpuState, value: siteSystem.totalCpuInPercent, text: 'CPU', tooltip: thresholdsTooltip(siteSystem.cpuThresholdWarningInPercent, siteSystem.cpuThresholdCriticalInPercent, siteSystem.cpuThresholdType)},
                    { state: siteSystem.memoryState, value: siteSystem.totalMemoryInPercent, text: 'MEM.', tooltip: thresholdsTooltip(siteSystem.memoryThresholdWarningInPercent, siteSystem.memoryThresholdCriticalInPercent, siteSystem.memoryThresholdType)},
                    { state: siteSystem.diskState, value: siteSystem.highestDiskInPercent, text: 'DISK', tooltip: thresholdsTooltip(siteSystem.diskThresholdWarningInPercent, siteSystem.diskThresholdCriticalInPercent, siteSystem.diskThresholdType)},
                    { state: siteSystem.networkState, value: siteSystem.highestNetworkInPercent, text: 'NET', tooltip: thresholdsTooltip(siteSystem.networkThresholdWarningInPercent, siteSystem.networkThresholdCriticalInPercent, siteSystem.networkThresholdType)}
                ]}
                openDetails={handleOpenDetails}
                notificationPriority={siteSystem.notificationPriority}
                notificationType={siteSystem.notificationType}
            />
        </Container>
    )
}
export default SiteSystemInfoCard
