import React, { useCallback, useEffect, useRef, useState } from 'react'
import UploadManager from 'components/UploadManager/UploadManager'
import { EUploadState } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { useIntl } from 'react-intl'
import { setItems } from 'state/slices/uploadManagerSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TUploadManagerItem } from 'types/ui/uploadManager'
import { cancelTokenStore } from 'api/cancelTokenStore'

const UploadManagerContainer = () => {
    const dispatch = useAppDispatch()
    const items = useAppSelector(state => state.uploadManager.items)
    const intl = useIntl()

    const [variant, setVariant] = useState<'indeterminate' | 'determinate'>('determinate')
    const [totalProgress, setTotalProgress] = useState<number>(0)
    const [showItems, setShowItems] = useState<boolean>(false)

    const containerRef = useRef<HTMLDivElement>(null)

    const areThereItemsInProgress = useCallback(() => items.some(item => item.state === EUploadState.IN_PROGRESS), [items])

    const unloadHandler = useCallback((event: BeforeUnloadEvent) => {
        if (!areThereItemsInProgress()) {
            delete event.returnValue
        } else {
            event.preventDefault()
            event.returnValue = intl.formatMessage(i18nKeys.upload_manager_unload_message)
        }
    }, [areThereItemsInProgress, intl])

    useEffect(() => {
        document.addEventListener('mousedown', outsideClickHandler)
        window.addEventListener('beforeunload', unloadHandler)
    
        return () => {
            document.removeEventListener('mousedown', outsideClickHandler)
            window.removeEventListener('beforeunload', unloadHandler)
        }
    }, [unloadHandler])

    const calculateTotalProgress = useCallback(() => {
        const itemsInProgress = items.filter(item => item.state === EUploadState.IN_PROGRESS)
        if (!itemsInProgress.length) {
            setVariant('determinate')
            setTotalProgress(0)
            return
        }

        const computableItems = itemsInProgress.filter(item => item.lengthComputable)
        if (!computableItems.length) {
            setVariant('indeterminate')
            return
        }

        const totalPercent = computableItems.reduce((acc: number, current: TUploadManagerItem) => {
            acc += current.percentComplete
            return acc
        }, 0)

        const progress = totalPercent / computableItems.length
        setVariant('determinate')
        setTotalProgress(progress)
    }, [items])

    useEffect(() => {
        calculateTotalProgress()
    }, [calculateTotalProgress])

    const clearCompleted = () => {
        const notCompletedItems = items.filter(item => item.state === EUploadState.IN_PROGRESS)
        dispatch(setItems(notCompletedItems))
    }

    const handleCancelPress = (item: TUploadManagerItem) => {
        if (item.state === EUploadState.IN_PROGRESS) {
            const tokenId = item.cancelTokenId
            if (tokenId) {
                const cancelTokenSource = cancelTokenStore.get(tokenId)
                if (cancelTokenSource) {
                    cancelTokenSource.cancel()
                    cancelTokenStore.delete(tokenId)
                }
            }
        }

        const removeItemFromList = items.filter(storeItem => storeItem !== item)
        dispatch(setItems(removeItemFromList))
    }

    const toggleShowItems = () => setShowItems(prevtState => !prevtState)

    const outsideClickHandler = (event: MouseEvent) => {
        if (containerRef.current) {
            if (!containerRef.current.contains(event.target as Node)) {
                setShowItems(false)
            }
        }
    }

    return (
        <UploadManager
            clearCompleted={clearCompleted}
            calculateTotalProgress={calculateTotalProgress}
            handleCancelPress={handleCancelPress}
            items={items}
            toggleShowItems={toggleShowItems}
            containerRef={containerRef}
            totalProgress={totalProgress}
            showItems={showItems}
            variant={variant}
        />
    )
}

export default UploadManagerContainer