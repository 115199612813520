import { TChartEntry, ChartLegend, CustomPieChart, CardHeader, PieChartPositioner } from 'components/CustomControls/CustomCharts'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { EDataSyncItems, EPerformanceState } from 'enums/enums'
import useBackgroundService from 'hooks/useBackgroundService'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { getSiteSystemsPerformance } from 'state/slices/statisticSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import colors from 'style/colors'
import { TOverallState } from 'types/businessLogic/performance'
import { uid } from 'uid'

const SiteSystemElement = () => {
    const siteSystemsPerformance = useAppSelector(store => store.statistic.SiteSystemsPerformance)
    const dispatch = useAppDispatch()
    const intl = useIntl()
    const [identifier] = useState(uid())
    
    const loadData = useCallback(() => dispatch(getSiteSystemsPerformance()), [dispatch])

    useBackgroundService(EDataSyncItems.SITE_SYSTEMS_PERFORMANCE, loadData, identifier)

    const prepareData = (data: TOverallState): TChartEntry[] => {
        return [
            {
                id: 'HealthySiteSystem',
                name: i18nKeys.umbrella_dashboard_site_systems_healthy,
                value: data.healthy,
                color: colors.extra.SUCCESS,
                redirectTo: '/app/monitor/site-systems/' + EPerformanceState.HEALTHY
            },
            {
                id: 'WarningSiteSystem',
                name: i18nKeys.umbrella_dashboard_site_systems_warning,
                value: data.warning,
                color: colors.extra.WARNING,
                redirectTo: '/app/monitor/site-systems/' + EPerformanceState.WARNING
            },
            {
                id: 'ErrorSiteSystem',
                name: i18nKeys.umbrella_dashboard_site_systems_critical,
                value: data.critical,
                color: colors.extra.ALERT,
                redirectTo: '/app/monitor/site-systems/' + EPerformanceState.CRITICAL
            },
            {
                id: 'OfflineSiteSystem',
                name: i18nKeys.umbrella_dashboard_site_systems_offline,
                value: data.offline,
                color: colors.shades.DARK_GRAY_SHADE,
                redirectTo: '/app/monitor/site-systems/' + EPerformanceState.OFFLINE
            },
            {
                id: 'IgnoredSiteSystem',
                name: i18nKeys.umbrella_dashboard_site_systems_ignore,
                value: data.ignored,
                color: colors.brandColors.BLUE,
                redirectTo: '/app/monitor/site-systems/' + EPerformanceState.IGNORED
            }
        ]
    }

    const chartData = prepareData(siteSystemsPerformance)

    return (
        <>
            <CardHeader title={i18nKeys.umbrella_dashboard_site_systems} />
            <CustomTooltip title={intl.formatMessage(i18nKeys.umbrella_dashboard_site_systems)}>
                <>
                    <PieChartPositioner>
                        <CustomPieChart
                            tooltip={intl.formatMessage(i18nKeys.umbrella_dashboard_running_site_services)}
                            data={chartData}
                            chartInnerDesc={i18nKeys.umbrella_dashboard_running_site_services_total}
                            chartInnerNumber={(siteSystemsPerformance.healthy + siteSystemsPerformance.warning + siteSystemsPerformance.critical + siteSystemsPerformance.offline + siteSystemsPerformance.ignored)}
                        />
                    </PieChartPositioner>
                    <ChartLegend data={chartData} />
                </>
            </CustomTooltip>
        </>
    )
}

export default SiteSystemElement