import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormWrapper, FormContent, FormFooter, FormButtonGroup } from 'components/FormControls/FormControls'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import colors from 'style/colors'
import styled from 'styled-components'
import { TLanguages } from 'types/ui/translation'

const OuterContainer = styled.div`
    height: 100%;
    padding: 80px 80px 80px 0;
    display: grid;
    place-items: center;
`

const Container = styled.div`
    border: 2px dashed ${colors.shades.DARK_SHADE};
    cursor: pointer;
    outline: 0;
    display: flex;
    justify-content: center;
    place-items: center;
    align-items: center;
    padding: 100px;
`

const ThirdPartyContainer = styled.div`
    margin-top: 16px;

    span {
        font-weight: bold;
        font-size: 0.9rem;
        text-decoration: underline;
        
    }

    div {
        margin-top: 8px;
        border: 2px dashed ${colors.shades.DARK_SHADE};
        padding: 8px;
    }
`
type TProps = {
    language: TLanguages
    onSubmit: () => void
}

const EulaModal = (props: TProps) => {
    const { language, onSubmit } = props

    const eulaLinks = {
        en: '/eula/EULA_06_25_2019_EN.pdf',
        de: '/eula/EULA_06_25_2019_DE.pdf',
        fr: '/eula/EULA_06_25_2019_FR.pdf'
    }

    const thirdPartyLink = '/eula/Third_Party_Licenses(6625182.1).pdf'

    const renderEulaLink = (locale: TLanguages) => (
        <OuterContainer>
            <div>
                <a target='_blank' href={`${eulaLinks[locale] || eulaLinks.en}`} rel='noreferrer'>
                    <Container>
                        <TransMessage {...i18nKeys.umbrella_eula_file_text} />
                    </Container>
                </a>

                <ThirdPartyContainer>
                    <span>NOTICE RELATING TO OPEN SOURCE SOFTWARE:</span>
                    <div>This software contains various open source components. Please see the link below for a list of the applicable licenses and required attribution notices for such open source software components <a target='_blank' href={thirdPartyLink} rel='noreferrer'>LINK</a>.
                        In the event of any conflict or inconsistency between this End User License Agreement and the open source license applicable to such open source software component, the open source license will govern
                        nd prevail with respect to the applicable open source software component.</div>
                </ThirdPartyContainer>
            </div>
        </OuterContainer>
    )

    return (
        <FormWrapper>
            <FormContent>
                {renderEulaLink(language)}
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        id='eulaButton'
                        type='button'
                        endIcon={<Icon d={Icons.TICK} />}
                        onClick={onSubmit}
                    >
                        <TransMessage {...i18nKeys.umbrella_eula_button} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}

export default EulaModal