import React, { useCallback, useState } from 'react'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import styled from 'styled-components'
import { TGroup, TTableFetchState } from 'types/network'
import { TTableGroup, TTableRowData, TTableInfo } from 'types/ui/table'
import Toolbar from 'components/Toolbar/Toolbar'
import useAuthorization from 'hooks/useAuthorization'
import { EDataSyncItems, EProfileRights } from 'enums/enums'
import SelectionBox from 'components/Tables/SelectionBox'
import { i18nKeys } from 'i18n/keys'
import TransMessage from 'components/TransMessage/TransMessage'
import { Icons } from 'components/Icon/Icon'
import DeleteButton from 'components/Tables/DeleteButton'
import ColumnPreset from 'components/Tables/ColumnPreset'
import ColumnChooser from 'components/ColumnChooser/ColumnChooser'
import QuickActionMenu from 'components/QuickActionMenu/QuickActionMenu'
import QuickActionMenuItem from 'components/QuickActionMenu/QuickActionMenuItem'
import TableHelper from 'components/Tables/TableHelper'
import { TModalProps } from 'types/ui/modal'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import { useModal } from 'hooks/modalProvider'
import CreateUmbrellaRole from 'views/Admin/UmbrellaRoles/Modals/CreateUmbrellaRole'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`

type TProps = {
    data: TTableRowData[]
    groups: TTableGroup<TTableRowData>[]
    totalCount: number
    tableInfo: TTableInfo
    builtInRoleIds: string[]
    getTableData: (params: TTableFetchState) => void
    deleteUmbrellaRoles: (params: TModalProps) => void
    getGroupData: (params: TTableFetchState, group: TGroup) => void
}

const UmbrellaRoles = (props: TProps) => {
    const { tableInfo, data, totalCount, groups, builtInRoleIds, getTableData, getGroupData } = props
    const [identifier] = useState(uid())
    const {showModal, closeModal} = useModal()
    const colHandlerProps = useColumnsHandler(tableInfo)

    const enhanceTableProps = useEnhanceTable({
        groups
    })

    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
        enhanceTableProps.selectedGroups.forEach(group => {
            getGroupData(enhanceTableProps.fetchState, group)
        })
    }, [enhanceTableProps.fetchState, enhanceTableProps.selectedGroups, getGroupData, getTableData])

    const { toggleDisabled } = useBackgroundService(EDataSyncItems.UMBRELLA_ROLES, loadData, identifier)

    const onUmbrellaRolesDelete = useCallback(() => {
        props.deleteUmbrellaRoles({
            ids: enhanceTableProps.getSelectedIds() as string[],
            filters: enhanceTableProps.getSelectAllFilters()
        })
        enhanceTableProps.deselectAllRows()
    }, [enhanceTableProps, props])

    const authorize = useAuthorization()
    
    const isAuthorized = authorize(EProfileRights.IS_UMBRELLA_ADMIN)

    const disableToolbarButtons = !enhanceTableProps.selectionCount
    const rowCount = TableHelper.countDisplayedRows(data, !!enhanceTableProps.fetchState.global.groupBy, groups)
    const totalSelectedRows = enhanceTableProps.selectAll ? totalCount : enhanceTableProps.selectionCount

    const handleCreateUmbrellaRole = () => {
        showModal({
            content: <CreateUmbrellaRole onSubmit={closeModal} />,
            title: i18nKeys.create_umbrella_role_title,
            description: i18nKeys.create_umbrella_role_subtitle
        })
    }

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        {
                            isAuthorized && (
                                <>
                                    <SelectionBox
                                        id='selectedUmbrellaRolesCheckbox'
                                        rowCount={enhanceTableProps.selectAll ? totalSelectedRows : rowCount}
                                        onAllRowsSelect={checked => enhanceTableProps.onAllRowsSelect(props.data, checked)}
                                        messageKey={i18nKeys.umbrella_roles_list_toolbar_selected_roles}
                                        totalSelectedRows={totalSelectedRows}
                                        numSelectedRows={enhanceTableProps.selectAll ? totalSelectedRows : enhanceTableProps.selectionCount}
                                        disabled={enhanceTableProps.selectAll}
                                    />
                                    <DeleteButton
                                        id='deleteUmbrellaRolesBtn'
                                        title={<TransMessage {...i18nKeys.umbrella_roles_list_toolbar_delete_roles_tooltip} />}
                                        disabled={disableToolbarButtons}
                                        onClick={onUmbrellaRolesDelete}
                                    />
                                </>
                            )
                        }
                    </>
                }
                RightContent={
                    <>
                        <ColumnChooser
                            minColumns={1}
                            onToggleColumn={colHandlerProps.onToggleColumn}
                            columns={colHandlerProps.columns}
                        />
                        <ColumnPreset
                            tableId={tableInfo.id}
                            {...colHandlerProps}
                        />
                        <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} />
                    </>
                }
            />
                            
            {
                isAuthorized &&
                <QuickActionMenu>
                    <QuickActionMenuItem id='quickActionAddRole' icon={Icons.GEAR} textKey={i18nKeys.umbrella_roles_list_button_add_role_tooltip} onClick={handleCreateUmbrellaRole} />
                </QuickActionMenu>
            }

            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                disableSelectionForIds={builtInRoleIds}
                rows={data}
                groups={groups}
            />
        </ViewWrapper>
    )
}

export default UmbrellaRoles