import { Card, IconButton, Menu, MenuItem } from '@mui/material'
import { CustomButton } from 'components/CustomControls/CustomButton'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import React, { PropsWithChildren, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { TTranslationKey } from 'types/ui/translation'
import { i18nKeys } from 'i18n/keys'
import shadows from 'style/shadows'

const Wrapper = styled(Card)`
    height: 100%;
    padding: 10px;
    box-shadow: ${shadows.TERTIARY};
    border-radius: 8px;
`

const Header = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0px 20px;
`

const Content = styled.div`
    height: calc(100% - 40px);
    padding: 20px;
`

const RedirectButton = styled(CustomButton)`
    height: 40px;   
    margin-right: 20px;
    min-width: 100px;
`

const DragIcon = styled.span`
    cursor: grab;
    padding: 12px;
    
    &:active{
        cursor: grabbing;
    }
`

type TProps = {
    id: string
    index: number
    redirectTo?: string
    btnText?: TTranslationKey
    editMode: boolean
    elementIndex: number
    onDeleteCard?: (index: number) => void
    onReplaceCard?: (index: number) => void
    fullscreenMode: boolean
}

const DashboardElementWrapper = (props: PropsWithChildren<TProps>) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const handleClick = (element: HTMLButtonElement) => {
        setAnchorEl(element)
    }
    
    const handleClose = () => {
        setAnchorEl(null)
    }
    
    const handleReplaceCard = () => {
        if (props.onReplaceCard) {
            props.onReplaceCard(props.elementIndex)
            setAnchorEl(null)
        }
    }

    const handleDeleteCard = () => {
        if (props.onDeleteCard) {
            props.onDeleteCard(props.elementIndex)
            setAnchorEl(null)
        }
        
    }

    return (
        <Wrapper>
            <Header>
                { props.fullscreenMode === false && <>
                    <IconButton
                        onClick={(event) => handleClick(event.currentTarget)}
                        id={props.id + 'DotsBtn'}
                        size='large'>
                        <Icon d={Icons.DOTS} viewBox='0 0 16 4' />
                    </IconButton>
                    <Menu
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => handleReplaceCard()} id={`${props.index}replaceCard`}>
                            <TransMessage {...i18nKeys.umbrella_dashboard_replace_card} />
                        </MenuItem>
                        <MenuItem onClick={() => handleDeleteCard()} id={`${props.index}deleteCard`}>
                            <TransMessage {...i18nKeys.umbrella_dashboard_delete_card} />
                        </MenuItem>
                    </Menu>

                    {props.editMode && <DragIcon>
                        <Icon className='draggableHandle' d={Icons.MOVE_ICON} size={24} viewBox='0 0 16 4' />
                    </DragIcon>
                    }
                </>
                }
                { props.redirectTo &&
                    <NavLink id={`${props.id}Link`} to={props.redirectTo}>
                        <RedirectButton $secondary>
                            <TransMessage {...props.btnText} />
                        </RedirectButton>
                    </NavLink>
                }
            </Header>
            <Content>
                {props.children}
            </Content>
        </Wrapper>
    )
    
}

export default DashboardElementWrapper