import React, { useCallback, useEffect } from 'react'
import { getEMailConfigAsync, postEMailConfigAsync, testEMailConfigAsync } from 'state/slices/emailSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TEMailConfig } from 'types/businessLogic/misc'
import EMail, { TEMailConfigForm } from 'views/Admin/EMail/EMail'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { i18nKeys } from 'i18n/keys'
import { EEmailEncryptionMethod } from 'enums/enums'

const EMailContainer = () => {
    const dispatch = useAppDispatch()
    const emailConfig = useAppSelector(store => store.email)

    useEffect(() => {
        dispatch(getEMailConfigAsync())
    }, [dispatch])

    useBreadCrumbs([i18nKeys.breadcrumb_email])
    
    const handleEMailConfigSubmit = useCallback((formValues: TEMailConfigForm) : void => {

        const request : TEMailConfig = {
            ...formValues,
            encryption: formValues.encryptionOptions.value as EEmailEncryptionMethod
        }

        dispatch(postEMailConfigAsync(request))
    }, [dispatch])

    const handleTestEMailSubmit = useCallback((formValues: TEMailConfigForm) : void => {
        const request : TEMailConfig = {
            ...formValues,
            encryption: formValues.encryptionOptions.value as EEmailEncryptionMethod
        }

        dispatch(testEMailConfigAsync(request))
    }, [dispatch])

    if (!emailConfig.fetched) {
        return null
    }

    return <EMail emailConfig={emailConfig} handleEMailConfigSubmit={handleEMailConfigSubmit} handleTestEMailSubmit={handleTestEMailSubmit} />
}

export default EMailContainer