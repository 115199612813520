import CoreMemoryElement from 'components/DashboardElements/CoreMemoryElement'
import CoreRequestCountElement from 'components/DashboardElements/CoreRequestCountElement'
import CoreRequestDurationElement from 'components/DashboardElements/CoreRequestDurationElement'
import CoreCpuElement from 'components/DashboardElements/CoreCpuElement'
import MapElement from 'components/DashboardElements/MapElement'
import RecentSiteEventsElement from 'components/DashboardElements/RecentSiteEventsElement'
import RecentSiteJobsElement from 'components/DashboardElements/RecentSiteJobsElement'
import CamerasElement from 'components/DashboardElements/CamerasElement'
import SiteServicesElement from 'components/DashboardElements/SiteServicesElement'
import SiteApprovalsElement from 'components/DashboardElements/SiteApprovalsElement'
import SiteStatesElement from 'components/DashboardElements/SiteStatesElement'
import { ELicenseTypes } from 'enums/ELicenseTypes'
import { i18nKeys } from 'i18n/keys'
import { Layout } from 'react-grid-layout'
import images from 'style/images'
import { TTranslationKey } from 'types/ui/translation'
import SiteSystemElement from 'components/DashboardElements/SiteSystemElement'
import { EProfileRights } from 'enums/enums'

export enum EDashboardLayouts {
    OneByOne = 0,
    OneByTwo = 1,
    OneByThree = 2,
    OneBigTwoSmall = 3,
    TwoByTwo = 4,
    Five = 5,
    TwoByThree = 6,
    AdvancedLayout = 7
}

export enum EDashboardElements {
    Empty,
    SiteApprovals,
    Cameras,
    SiteServices,
    Map,
    RecentJobs,
    RecentSiteEvents,
    CoreCPU,
    CoreMemory,
    CoreRequestDuration,
    CoreRequestCount,
    SiteSystem,
    SiteStates
}

export type TDashboardLayout = {
    id: string
    image: string
    enum: EDashboardLayouts
}

export const DashboardLayouts: TDashboardLayout[] = [
    {
        id: 'oneByOne',
        image: images.dashboardLayout1,
        enum: EDashboardLayouts.OneByOne
    },
    {
        id: 'oneByTwo',
        image: images.dashboardLayout2,
        enum: EDashboardLayouts.OneByTwo
    },
    {
        id: 'oneByThree',
        image: '',
        enum: EDashboardLayouts.OneByThree
    },
    {
        id: 'oneBigTwoSmall',
        image: images.dashboardLayout3,
        enum: EDashboardLayouts.OneBigTwoSmall
    },
    {
        id: 'twoByTwo',
        image: images.dashboardLayout4,
        enum: EDashboardLayouts.TwoByTwo
    },
    {
        id: 'five',
        image: images.dashboardLayout5,
        enum: EDashboardLayouts.Five
    },
    {
        id: 'twoByThree',
        image: images.dashboardLayout6,
        enum: EDashboardLayouts.TwoByThree
    }
]

export type TDashboardLayoutMapping = {
    maxElements: number
    columns: number
    rows: number
    layout: Layout[] | ((length: number) => Layout[])
}

export const DashboardLayoutMappings: {[key in EDashboardLayouts]: TDashboardLayoutMapping} = {
    [EDashboardLayouts.OneByOne]: {
        maxElements: 1,
        columns: 1,
        rows: 1,
        layout: [
            {i: '0', x: 0, y: 0, h: 1, w:1}
        ]
    },
    [EDashboardLayouts.OneByTwo]: {
        maxElements: 2,
        columns: 2,
        rows: 1,
        layout: [
            {i: '0', x: 0, y: 0, h: 1, w:1},
            {i: '1', x: 1, y: 0, h: 1, w:1}
        ]
    },
    [EDashboardLayouts.OneByThree]: {
        maxElements: 3,
        columns: 3,
        rows: 1,
        layout: [
            {i: '0', x: 0, y: 0, h: 1, w:1},
            {i: '1', x: 1, y: 0, h: 1, w:1},
            {i: '2', x: 2, y: 0, h: 1, w:1}
        ]
    },
    [EDashboardLayouts.OneBigTwoSmall]: {
        maxElements: 3,
        columns: 3,
        rows: 2,
        layout: [
            {i: '0', x: 0, y: 0, h: 2, w:2},
            {i: '1', x: 2, y: 0, h: 1, w:1},
            {i: '2', x: 2, y: 1, h: 1, w:1}
        ]
    },
    [EDashboardLayouts.TwoByTwo]: {
        maxElements: 4,
        columns: 2,
        rows: 2,
        layout: [
            {i: '0', x: 0, y: 0, h: 1, w:1},
            {i: '1', x: 1, y: 0, h: 1, w:1},
            {i: '2', x: 0, y: 1, h: 1, w:1},
            {i: '3', x: 1, y: 1, h: 1, w:1}
        ]
    },
    [EDashboardLayouts.Five]: {
        maxElements: 5,
        columns: 3,
        rows: 2,
        layout: [
            {i: '0', x: 0, y: 0, h: 1, w:1.5},
            {i: '1', x: 1.5, y: 0, h: 1, w:1.5},
            {i: '2', x: 0, y: 1, h: 1, w:1},
            {i: '3', x: 1, y: 1, h: 1, w:1},
            {i: '4', x: 2, y: 1, h: 1, w:1}
        ]
    },
    [EDashboardLayouts.TwoByThree]: {
        maxElements: 6,
        columns: 3,
        rows: 2,
        layout: [
            {i: '0', x: 0, y: 0, h: 1, w: 1},
            {i: '1', x: 1, y: 0, h: 1, w: 1},
            {i: '2', x: 2, y: 0, h: 1, w: 1},
            {i: '3', x: 0, y: 1, h: 1, w: 1},
            {i: '4', x: 1, y: 1, h: 1, w: 1},
            {i: '5', x: 2, y: 1, h: 1, w: 1}
        ]
    },
    [EDashboardLayouts.AdvancedLayout]: {
        maxElements: 6,
        columns: 12,
        rows: 10,
        layout: (length: number) => [{i: '0', x: 0, y: 0, h: 5, w: 4, minW: 4, minH: 5},
            {i: '1', x: 4, y: 0, h: 5, w: 4, minW: 4, minH: 5},
            {i: '2', x: 8, y: 0, h: 5, w: 4, minW: 4, minH: 5},
            {i: '3', x: 0, y: 5, h: 5, w: 4, minW: 4, minH: 5},
            {i: '4', x: 4, y: 5, h: 5, w: 4, minW: 4, minH: 5},
            {i: '5', x: 8, y: 5, h: 5, w: 4, minW: 4, minH: 5}
        ].slice(0, length)
    }
}

export type TDashboardElementMapping = {
    id: string
    image: string
    enum: EDashboardElements
    element: () => JSX.Element
    redirectTo?: string
    btnText?: TTranslationKey
    license?: ELicenseTypes
    rights?: EProfileRights[]
    ignoreForSituatorExclusive?: boolean
}

export const DashboardElementMappings: {[key: number]: TDashboardElementMapping} = {
    [EDashboardElements.SiteStates]: {
        id: 'siteStates',
        image: images.SiteStates,
        enum: EDashboardElements.SiteStates,
        element: SiteStatesElement
    },
    [EDashboardElements.SiteSystem]: {
        id: 'siteStates',
        image: images.SiteSystem,
        enum: EDashboardElements.SiteSystem,
        element: SiteSystemElement,
        rights: [EProfileRights.HAS_MONITORING_RIGHT]
    },
    [EDashboardElements.Cameras]: {
        id: 'runningCameras',
        image: images.RunningCameras,
        enum: EDashboardElements.Cameras,
        element: CamerasElement,
        license: ELicenseTypes.MONITORING,
        ignoreForSituatorExclusive: true,
        rights: [EProfileRights.HAS_MONITORING_RIGHT]
    },
    [EDashboardElements.SiteServices]: {
        id: 'runningSiteServices',
        image: images.RunningSiteServices,
        enum: EDashboardElements.SiteServices,
        element: SiteServicesElement,
        license: ELicenseTypes.MONITORING,
        rights: [EProfileRights.HAS_MONITORING_RIGHT]
    },
    [EDashboardElements.SiteApprovals]: {
        id: 'siteApprovals',
        image: images.SiteApprovals,
        enum: EDashboardElements.SiteApprovals,
        element: SiteApprovalsElement,
        btnText: i18nKeys.umbrella_dashboard_choose_layout_site_approvals_tooltip
    },
    [EDashboardElements.CoreCPU]: {
        id: 'coreCPU',
        image: images.CoreCPU,
        enum: EDashboardElements.CoreCPU,
        element: CoreCpuElement,
        license: ELicenseTypes.MONITORING,
        rights: [EProfileRights.HAS_MONITORING_RIGHT, EProfileRights.IS_UMBRELLA_ADMIN]
    },
    [EDashboardElements.CoreMemory]: {
        id: 'coreMemory',
        image: images.CoreMemory,
        enum: EDashboardElements.CoreMemory,
        element: CoreMemoryElement,
        license: ELicenseTypes.MONITORING,
        rights: [EProfileRights.HAS_MONITORING_RIGHT, EProfileRights.IS_UMBRELLA_ADMIN]
    },
    [EDashboardElements.CoreRequestDuration]: {
        id: 'coreRequestDuration',
        image: images.CoreRequestDuration,
        enum: EDashboardElements.CoreRequestDuration,
        element: CoreRequestDurationElement,
        license: ELicenseTypes.MONITORING,
        rights: [EProfileRights.HAS_MONITORING_RIGHT, EProfileRights.IS_UMBRELLA_ADMIN]
    },
    [EDashboardElements.CoreRequestCount]: {
        id: 'coreRequestCount',
        image: images.CoreRequestCount,
        enum: EDashboardElements.CoreRequestCount,
        element: CoreRequestCountElement,
        license: ELicenseTypes.MONITORING,
        rights: [EProfileRights.HAS_MONITORING_RIGHT, EProfileRights.IS_UMBRELLA_ADMIN]
    },
    [EDashboardElements.Map]: {
        id: 'map',
        image: images.Map,
        enum: EDashboardElements.Map,
        element: MapElement,
        redirectTo: '/app/map',
        btnText: i18nKeys.sites_with_location_view_map
    },
    [EDashboardElements.RecentJobs]: {
        id: 'recentJobs',
        image: images.RecentJobs,
        enum: EDashboardElements.RecentJobs,
        element: RecentSiteJobsElement,
        redirectTo: '/app/site-jobs ',
        license: ELicenseTypes.CONFIGURATION,
        btnText: i18nKeys.recent_site_jobs_heading
    },
    [EDashboardElements.RecentSiteEvents]: {
        id: 'recentSiteEvents',
        image: images.RecentSiteEvents,
        enum: EDashboardElements.RecentSiteEvents,
        element: RecentSiteEventsElement,
        redirectTo: '/app/monitor/site-events',
        license: ELicenseTypes.MONITORING,
        btnText: i18nKeys.umbrella_dashboard_view_recent_site_events,
        rights: [EProfileRights.HAS_MONITORING_RIGHT]
    }
}

export enum EDashboardElementsCategory {
    UmbrellaCoreCharts = 0,
    Charts,
    Maps,
    Tables
}

export const DashboardElementsWithCategories = {
    [EDashboardElementsCategory.UmbrellaCoreCharts]: [
        DashboardElementMappings[EDashboardElements.CoreCPU],
        DashboardElementMappings[EDashboardElements.CoreMemory],
        DashboardElementMappings[EDashboardElements.CoreRequestDuration],
        DashboardElementMappings[EDashboardElements.CoreRequestCount]
    ],
    [EDashboardElementsCategory.Charts]: [
        DashboardElementMappings[EDashboardElements.SiteStates],
        DashboardElementMappings[EDashboardElements.Cameras],
        DashboardElementMappings[EDashboardElements.SiteServices],
        DashboardElementMappings[EDashboardElements.SiteSystem],
        DashboardElementMappings[EDashboardElements.SiteApprovals],
        DashboardElementMappings[EDashboardElements.CoreCPU],
        DashboardElementMappings[EDashboardElements.CoreMemory],
        DashboardElementMappings[EDashboardElements.CoreRequestDuration],
        DashboardElementMappings[EDashboardElements.CoreRequestCount]
    ],
    [EDashboardElementsCategory.Maps]: [
        DashboardElementMappings[EDashboardElements.Map]
    ],
    [EDashboardElementsCategory.Tables]: [
        DashboardElementMappings[EDashboardElements.RecentJobs],
        DashboardElementMappings[EDashboardElements.RecentSiteEvents]
    ]
}