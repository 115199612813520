import CustomAreaChart from 'components/CustomControls/CustomAreaChart'
import { CardHeader, CardWrapper } from 'components/CustomControls/CustomCharts'
import { i18nKeys } from 'i18n/keys'
import colors from 'style/colors'
import React, { useCallback, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'state/store'
import { getCoreMemoryAsync } from 'state/slices/statisticSlice'
import { AutoSizer } from 'react-virtualized'
import Container from 'components/Container/Container'
import { EDataSyncItems } from 'enums/enums'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'

const CoreMemoryElement = () => {
    const data = useAppSelector(store => store.statistic.coreMemory)
    const dispatch = useAppDispatch()
    const request = useRef({
        from: dayjs().subtract(1, 'days'),
        till: dayjs(),
        id: -1
    })

    const [identifier] = useState(uid())

    const loadData = useCallback(async () => {
        request.current = {
            from: dayjs().subtract(1, 'days'),
            till: dayjs(),
            id: -1
        }
        dispatch(getCoreMemoryAsync(request.current))
    }, [dispatch])

    useBackgroundService(EDataSyncItems.CORE_PERFORMANCE, loadData, identifier)

    const memoryData = data.map(item => ({
        value: item.memory,
        time: dayjs(item.time).unix()
    }))

    return (
        <>
            <CardHeader title={i18nKeys.umbrella_core_memory_usage} />
            <Container width='100%' height='100%'>
                <AutoSizer>
                    {({width, height}) => {
                        return (
                            <CardWrapper>
                                <CustomAreaChart
                                    unit=''
                                    data={memoryData}
                                    graphColor={colors.extra.WARNING}
                                    from={request.current.from}
                                    till={request.current.till}
                                    width={width}
                                    height={height}
                                />
                            </CardWrapper>
                        )
                    }}
                </AutoSizer>
            </Container>
            
        </>
    )
}

export default CoreMemoryElement
