import { Collapse, IconButton, Paper, Table, TableBody, TableCell, TableRow } from '@mui/material'
import Icon, { Icons } from 'components/Icon/Icon'
import CellTooltip from 'components/Tables/CellTooltip'
import CustomTableHead from 'components/Tables/CustomTableHead'
import CustomTablePagination from 'components/Tables/CustomTablePagination'
import DateHelper from 'helpers/DateHelper'
import { paginationConfig, TPagination, TUseEnhanceTable } from 'hooks/useEnhanceTable'
import TableFilters from 'components/Tables/TableFilters'
import TableHelper from 'components/Tables/TableHelper'
import React, { useEffect, useState } from 'react'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import fonts from 'style/fonts'
import shadows from 'style/shadows'
import styled, { css } from 'styled-components'
import { uid } from 'uid'
import { TTableGroup, TTableColumn, TTableRowData } from 'types/ui/table'
import { CustomCheckbox } from 'components/CustomControls/CustomCheckbox'
import { TAdvancedTablePropertyValue } from 'types/businessLogic/site'
import GeneralHelper from 'helpers/GeneralHelper'
import TableGroup from 'components/Tables/TableGroup'
import { Link } from 'react-router-dom'
import typographyScale from 'style/typographyScale'
import chroma from 'chroma-js'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import {TGroup} from 'types/network'
import AppConstants from 'helpers/AppConstants'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'

const CellWrapper = styled(TableCell)`
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    fill: ${colors.primary.DEFAULT_PRIMARY};
`
const LinkHover = styled(CellWrapper)`
    & > a:hover {
        color: ${colors.primary.DEFAULT_PRIMARY};
        transition: color 150ms ease-in-out;
    }
`
const CustomLink = styled(Link)`
    font-family: ${fonts.PRIMARY_BOLD};
    color: ${colors.primary.DARK_PRIMARY};
    text-decoration: underline;
`
const Wrapper = styled(Paper)`
    margin-bottom: 0;
    border-radius: ${borderRadius.SECONDARY};
    box-shadow: ${shadows.QUATRENARY};
`
const OverflowHider = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
`

const TableWrapper = styled.div<{maxHeight?: string}>`
    max-height: ${p => p.maxHeight ?? 'calc(100vh - 218px)'};
    overflow-x: auto;
    font-family: ${fonts.PRIMARY_REGULAR};
    table-layout: auto;
`
const TableWithFixedHeader = styled(Table)<{$showChildren?: boolean}>`
    & thead {
        .MuiTableCell-root {
            color: ${colors.shades.DARK_GRAY_SHADE};
            text-transform: uppercase;
            font-family: ${fonts.PRIMARY_MEDIUM};
            font-size: ${typographyScale.TYPE12};
            line-height: ${lineHeights.TYPE12LineHeight};
            letter-spacing: ${letterSpacings.TYPE12LS};
        }

        & > tr {
            & > th {
                width: 240px;
                min-width: 240px;
                font-family: ${fonts.PRIMARY_BOLD};
                padding-left: 10px;
                padding-right: 10px;

                &:nth-of-type(1) {
                    padding: 0 0 0 20px; 
                    width: 0%;
                    min-width: 0;
                }
                ${p => p.$showChildren && css`
                    &:nth-of-type(2) {
                        padding: 0px; 
                        width: 0%;
                        min-width: 0;
                    }
                `}
            }
        }
    }
    
    & > tbody > tr {
        &:nth-child(odd) {
            background-color: ${colors.shades.LIGHT_SHADE};
        }
        
        & > td {
            white-space: nowrap;
            color: ${colors.primary.DARK_PRIMARY};
            font-family: ${fonts.PRIMARY_REGULAR};
            font-size: ${typographyScale.TYPE14};
            line-height: ${lineHeights.TYPE14LineHeight};
            letter-spacing: ${letterSpacings.TYPE14LS};
            border-bottom: 0px;
            padding: 20px 10px;
            width: 240px;
            min-width: 240px;
            height: 80px;

            &:nth-of-type(1) {
                padding: 0 0 0 20px; 
                width: 0%;
                min-width: 80px;
            }
            ${p => p.$showChildren && css`
                &:nth-of-type(2) {
                    padding: 0px; 
                    width: 0%;
                    min-width: 80px;
                }
            `}
            &:last-of-type {
                padding-right: 20px;
            }
        }
    } 
`
const StyledTableRow = styled(TableRow)<{selected: boolean, $isSubRow?: boolean}>`
    background-color: ${p => p.$isSubRow ? chroma(colors.primary.DEFAULT_PRIMARY).alpha(0.2).css() : ''} !important;

    ${p => p.selected ? `background-color: ${chroma(colors.primary.DEFAULT_PRIMARY).alpha(0.2).css()} !important` : ''};
`

export type TAdvancedTableProps<TExtendedTableRowData extends TTableRowData> = TUseEnhanceTable<TExtendedTableRowData> & {
    maxHeight?: string
    count: number
    columns: TTableColumn[]
    rows: TExtendedTableRowData[]
    groups?: TTableGroup<TExtendedTableRowData>[]
    disableSelection?: boolean
    disableSelectionForIds?: string[]
    disabledCheckboxes?: boolean
    disableBackground?: boolean
    onOpenGroupChanged?: (group: TGroup) => void
    showChildren?: boolean
}

const AdvancedTable = <TExtendedTableRowData extends TTableRowData>(props: TAdvancedTableProps<TExtendedTableRowData>) => {
    const { maxHeight, count, groups, rows, selectedRows, columns, tableState, fetchState, selectAll, showChildren } = props
    const { disableSelection, disabledCheckboxes, disableBackground, disableSelectionForIds, onOpenGroupChanged } = props
    const { onSort, onGroup, onFilter, onChangeRowsPerPage, onChangePage, onRowsSelect } = props

    const [openSubRows, setOpenSubRows] = useState<(number | string)[]>([])

    useEffect(() => {
        if (!showChildren) {
            setOpenSubRows([])
        }
    }, [showChildren])

    const renderBoolCell = (value: boolean, propertyName: string, rowId: number | string) : JSX.Element => (
        <CellWrapper id={`${propertyName}${rowId}`} key={`tableCell${rowId}${propertyName}`}>
            <Icon
                size={value ? 16 : 24}
                d={value ? Icons.TICK : Icons.CLOSE}
                
            />
        </CellWrapper>
    )

    const renderLinkCell = (value: { to: string, text: string }, propertyName: string, rowId: number | string) : JSX.Element => (
        <LinkHover id={`${propertyName}${rowId}`} key={`tableCell${rowId}${propertyName}`}>
            <CellTooltip text={value.text}>
                <CustomLink to={value.to}>{value.text}</CustomLink>
            </CellTooltip>
        </LinkHover>
    )

    const renderDateCell = (value: string, propertyName: string, rowId: number | string) : JSX.Element => (
        <TableCell id={`${propertyName}${rowId}`} key={`tableCell${rowId}${propertyName}`}>
            <CellTooltip text={DateHelper.convertUTCToLocal(value, DateHelper.formats.YEAR_TO_SECONDS_PM_AM)}>
                <OverflowHider>{DateHelper.convertUTCToLocal(value, DateHelper.formats.YEAR_TO_SECONDS_PM_AM)}</OverflowHider>
            </CellTooltip>
        </TableCell>
    )

    const renderStringCell = (value: string | number, propertyName: string, rowId: number | string) : JSX.Element => (
        <CellWrapper id={`${propertyName}${rowId}`} key={`tableCell${rowId}${propertyName}`}>
            <CellTooltip text={value.toString()}>
                <OverflowHider>{value.toString()}</OverflowHider>
            </CellTooltip>
        </CellWrapper>
    )

    const renderCollectionCell = (value: (string | number)[], propertyName: string, rowId: number | string) : JSX.Element => {
        const val = typeof value === 'string' ? value : value.join(', ')
        return (
            <CellWrapper id={`${propertyName}${rowId}`} key={`tableCell${rowId}${propertyName}`}>
                <CellTooltip text={val}>
                    <OverflowHider>{val}</OverflowHider>
                </CellTooltip>
            </CellWrapper>
        )
    }

    const renderDateTimeCell = (value: string, propertyName: string, rowId: number | string) : JSX.Element => {
        const val = DateHelper.convertUTCToLocal(value, DateHelper.formats.YEAR_TO_SECONDS_PM_AM)
        return (
            <CellWrapper id={`${propertyName}${rowId}`} key={`tableCell${rowId}${propertyName}`}>
                <CellTooltip text={val}>
                    <OverflowHider>{val}</OverflowHider>
                </CellTooltip>
            </CellWrapper>
        )
    }

    const renderCell = (id: number | string, property?: TAdvancedTablePropertyValue) => {
        if (!property)
            return <TableCell key={uid()} />
        
        const value = property[GeneralHelper.getPropertyKey(property.propertyType)]

        if (value === undefined || value === null) {
            return <TableCell id={`${property.name}${id}`} key={uid()}>-</TableCell>
        }

        switch (property.propertyType) {
            case GeneralHelper.propertyTypes.bool.index:
                return renderBoolCell(value, property.name, id)
            case GeneralHelper.propertyTypes.link.index:
                return renderLinkCell(value, property.name, id)
            case GeneralHelper.propertyTypes.dateString.index:
                return renderDateCell(value, property.name, id)
            case GeneralHelper.propertyTypes.dateTime.index:
                return renderDateTimeCell(value, property.name, id)
            case GeneralHelper.propertyTypes.collection.index:
                return renderCollectionCell(value, property.name, id)
            case GeneralHelper.propertyTypes.customRender.index:
                return renderCustomCell(value, property.name, id)
            default:
                return renderStringCell(value, property.name, id)
        }
    }

    const renderCustomCell = (customRender: (propertyName: string, rowId: number | string) => JSX.Element, propertyName: string, rowId: number | string) : JSX.Element => (
        <CellWrapper id={`${propertyName}${rowId}`} key={`tableCell${rowId}${propertyName}`}>
            {
                customRender(propertyName, rowId)
            }
        </CellWrapper>
    )

    const renderRows = (filteredRows: TExtendedTableRowData[], displayedColumns: TTableColumn[], hasFilters: boolean) : JSX.Element[] => {
        
        const renderColumns = (row : TExtendedTableRowData) => {

            while (displayedColumns.length < AppConstants.other.TABEL_MIN_COLUMN_AMOUNT) {
                const id = uid()
                displayedColumns.push({
                    key: `placeholder_${id}`,
                    translationKey: '',
                })
            }

            const colElements = displayedColumns.map((column) => {
                const property = row.propertyValues.find(prop => prop.name === column.key)
                return renderCell(`${row.id}`, property)
            })

            return colElements
        }

        const hanldeToggleSubRows = (value : number | string) => {
            setOpenSubRows((oldState) => {
                const temp = [...oldState]
                const index = temp.indexOf(value)
                if (index !== -1) {
                    temp.splice(index, 1)
                } else {
                    temp.push(value)
                }

                return temp
            })
        }

        const renderChildExpandoCell = (entry : TExtendedTableRowData) => {
            if (showChildren) {
                if (entry.children && entry.children.length > 0) {
                    return (
                        <TableCell key={`expandoTableCell${entry.id}`}>
                            <IconButton
                                aria-label='expand row'
                                size='small'
                                onClick={() => hanldeToggleSubRows(entry.id)}
                            >
                                {openSubRows.includes(entry.id) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        </TableCell>
                    )
                }
                return <TableCell key={`expandoTableCell${entry.id}`} className='helper-cell' />
            }
        }

        const renderRow = (entry : TExtendedTableRowData, index: number) => {
            return (
                <StyledTableRow
                    key={`tableRow${entry.id}`}
                    className='tableRow'
                    $isSubRow={openSubRows.includes(entry.id)}
                    selected={(selectedRows.some(row => row.id === entry.id) || !!disabledCheckboxes || selectAll) && !disableBackground}>
                    {
                        (hasFilters || !disableSelection) && (
                            <TableCell>
                                {
                                    !disableSelection && (
                                        <CustomCheckbox
                                            data-testid={`row-selection-checkbox-${index}`}
                                            id={entry.id.toString()}
                                            disabled={(disableSelectionForIds ? disableSelectionForIds.includes(entry.id.toString()) : false) || !!disabledCheckboxes || selectAll}
                                            checked={selectedRows.some(elem => elem.id === entry.id) || !!disabledCheckboxes || selectAll}
                                            onChange={(_, checked: boolean) => onRowsSelect([entry], checked)}
                                        />
                                    )
                                }
                            </TableCell>
                        )
                    }
                    {renderChildExpandoCell(entry)}
                    {renderColumns(entry)}
                </StyledTableRow>
            )
        }

        const renderSubRows = (entry : TExtendedTableRowData) => {
            return (
                <StyledTableRow
                    key={`subTableRow${entry.id}`}
                    selected={false}
                    $isSubRow>
                    <TableCell />
                    <TableCell />
                    {renderColumns(entry)}
                </StyledTableRow>
            )
        }

        const result : JSX.Element[] = []

        filteredRows.forEach((entry, index) => {
            result.push(renderRow(entry, index))
            if (openSubRows.includes(entry.id)) {
                result.push(
                    <TableRow key={`collapseableTableCell${entry.id}`}>
                        <TableCell style={{ padding: 0 }} colSpan={columns.length + 2}>
                            <Collapse in={openSubRows.includes(entry.id)} timeout='auto' unmountOnExit sx={{borderBottom: `1px solid ${colors.shades.DARK_SHADE}`}}>
                                <TableWithFixedHeader $showChildren={showChildren}>
                                    <TableBody>
                                        {entry.children?.map((child) => {
                                            return renderSubRows(child as TExtendedTableRowData)
                                        })}
                                    </TableBody>
                                </TableWithFixedHeader>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )
            }
        })

        return result
    }

    const handleGroupOpendChanged = (state: boolean, group: TTableGroup<TExtendedTableRowData>) => {
        onOpenGroupChanged && onOpenGroupChanged({
            name: group.name,
            translationKey: group.translationKey!,
            count: group.count
        })
    }

    const renderGroups = (hasFilters: boolean, groupByIntlKey: string) : JSX.Element[] => {
        const cells = TableHelper.getActiveHeadCells(columns)

        const defaultPagination: TPagination = {
            page: paginationConfig.firstPageIndex,
            size: paginationConfig.rowsPerPage
        }

        return groups!.map(group => (
            <TableGroup
                key={group.name}
                group={group}
                pagination={fetchState.groups[group.name] ?? defaultPagination}
                groupByIntlKey={groupByIntlKey}
                onChangeRowsPerPage={(rowsPerPage: number) => onChangeRowsPerPage(rowsPerPage, group.name)}
                onChangePage={(page: number) => onChangePage(page, group.name)}
                onChangedOpen={state => handleGroupOpendChanged(state, group)}
            >
                {
                    group.data && renderRows(group.data, cells, hasFilters)
                }
            </TableGroup>)
        )
    }

    const cells = TableHelper.getActiveHeadCells(columns)
    const columnFilters = TableHelper.getColumnFilters(cells)
    const hasFilters = columnFilters.length > 0

    return (
        <Wrapper>
            <TableWrapper maxHeight={maxHeight}>
                <TableWithFixedHeader $showChildren={showChildren}>
                    <CustomTableHead
                        cells={cells}
                        sortedBy={fetchState.global.sortBy}
                        groupedBy={fetchState.global.groupBy}
                        order={fetchState.global.order}
                        onSort={onSort}
                        prependEmptyCell={hasFilters || !disableSelection}
                        onGroup={onGroup}
                        showChildren={showChildren}
                    >
                        {
                            hasFilters &&
                            <TableFilters showChildren={showChildren} filters={columnFilters} onChange={onFilter} activeFilters={fetchState.global.filters} />
                        }
                    </CustomTableHead>
                    <TableBody>
                        {
                            fetchState.global.groupBy &&
                            tableState.groupByIntlKey &&
                            groups ?
                                renderGroups(hasFilters, tableState.groupByIntlKey) :
                                renderRows(rows, cells, hasFilters)
                        }
                    </TableBody>
                </TableWithFixedHeader>
            </TableWrapper>
            <CustomTablePagination
                count={count}
                page={fetchState.global.page}
                rowsPerPage={fetchState.global.size}
                onChangeRowsPerPage={rowsPerPage => onChangeRowsPerPage(rowsPerPage)}
                onChangePage={page => onChangePage(page)} />
        </Wrapper>
    )
}

export default AdvancedTable