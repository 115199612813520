import colors from 'style/colors'
import styled from 'styled-components'
import React, { PropsWithChildren, useState } from 'react'
import { TTranslationKey } from 'types/ui/translation'
import TransMessage from 'components/TransMessage/TransMessage'
import { useController, UseControllerProps } from 'react-hook-form'
import { Collapse, FormControl, FormHelperText } from '@mui/material'
import { Icons } from 'components/Icon/Icon'
import { CustomInput } from 'components/CustomControls/CustomInput'
import fonts from 'style/fonts'
import shadows from 'style/shadows'
import { CollapseToggle, ToggleIcon } from 'components/AdvancedJobConfigFields/AdvancedJobConfigFields'
import { i18nKeys } from 'i18n/keys'
import lineHeights from 'style/lineHeights'
import letterSpacings from 'style/letterSpacings'
import typographyScale from 'style/typographyScale'
import { CustomCheckbox } from 'components/CustomControls/CustomCheckbox'

export const Instructions = styled.span<{danger?: boolean}>`
    color: ${p => p.danger ? colors.projectColors.ALERT : colors.shades.DEEP_SHADE};
    font-family: ${fonts.PRIMARY_REGULAR};
    font-size: ${typographyScale.TYPE14};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE14LS};
    display: block;

`
export const Relative = styled.div`
    position: relative;
`

export const InputWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
    position: relative;
    min-height: 56px;
`

const Title = styled.div`
    font-size: 32px;
    font-family: ${fonts.PRIMARY_HEAVY};
    line-height: 40px;
    letter-spacing: 1px;
`

const Description = styled.div`
    color: ${colors.primary.DARK_PRIMARY};
    display: inline-block;
    padding-top: 20px;
    font-family: ${fonts.PRIMARY_REGULAR};
`

export const FormWrapper = styled.form`
    overflow: hidden;
    display: flex;
    flex-flow: column;
    height: 100%;
`
export const FormContent = styled.div`
    padding-left: 80px;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 1 auto;
`

export const FormButtonGroup = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    button {
        margin-right: 56px;
        width: 250px;
    }
`

export const FormFooter = styled.div<{noIndent?: boolean}>`
    padding-left: 80px;
    padding-bottom: 16px;
    box-shadow: ${shadows.TERTIARY};
    background-color: white;
`

export const FormLabel = styled.label<{small?: boolean}>`
    color: ${colors.primary.DARK_PRIMARY};
    width: ${p => p.small ? 'inherit' : '300px'};
    font-size: ${p => p.small ? '12px' : '16px'};
    max-width: 300px;
    font-family: LatoSemibold;
    line-height: 24px;
    letter-spacing: 0.4px;
    padding-top: 16px;
`

export const FormInfo = styled.div`
    height: 42px;
    padding-top: 16px;
`

const Group = styled.div`
    padding-top: 25px;
`

const GroupContent = styled.div`
    padding-left: 25px;
    padding-bottom: 10px;
`

type TFormGroupProps = {
    title?: TTranslationKey
    collapsable?: boolean
}

export const FormGroup = (props: PropsWithChildren<TFormGroupProps>) => {
    
    const [areFieldsExpanded, setAreFieldsExpanded] = useState<boolean>(!props.collapsable)

    const toggleCollapse = () => {
        setAreFieldsExpanded(!areFieldsExpanded)
    }

    return (
        <Group>
            {
                props.collapsable ?
                    <CollapseToggle fullWidth={false}
                        onClick={toggleCollapse}>
                        { props.title && <h2>
                            <TransMessage {...props.title} />
                        </h2>}
                        <ToggleIcon
                            id='toggleAdvancedJobConfigurationIcon'
                            areFieldsExpanded={areFieldsExpanded}
                            d={Icons.CHEVRON}
                        />
                    </CollapseToggle> :
                    props.title && <h2>
                        <TransMessage {...props.title} />
                    </h2>
            }
            
            <Collapse in={areFieldsExpanded} unmountOnExit>
                <GroupContent>
                    {props.children}
                </GroupContent>
            </Collapse>
        </Group>
    )
}

const FormHeaderWrapper = styled.div`
    padding: 20px 0px 20px 40px;
    box-shadow: 0 10px 10px -10px rgb(0 0 0 / 10%);
    background-color: ${colors.shades.PURE_WHITE};
`

type TFormHeaderProps = {
    title: TTranslationKey
    description: TTranslationKey
}

export const FormHeader = (props: TFormHeaderProps) => {

    return (
        <FormHeaderWrapper>
            <Title>
                <TransMessage {...props.title} />
            </Title>
            <Description>
                <TransMessage {...props.description} />
            </Description>
        </FormHeaderWrapper>
       
    )
}

export const StyledFormHelperText = styled(FormHelperText)`
    color: red;
    padding-left: 15px;
    height: 20px;
    font-family: LatoMedium;
    font-size: 12px;
`

//#region FormControl

type TFormInputProps = UseControllerProps<any> & {
    type?: 'text' | 'password'
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
    debug?: boolean
    placeHolder?: string
    disabled?: boolean
    autoComplete?: string
    onReset?: (name: string, value: any) => void
    onChange?: (value: string) => void
}
  
export const FormInput = (props: TFormInputProps) => {
    const { field, fieldState } = useController(props)

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        field.onChange(event)
        if (props.onChange) {
            props.onChange(event.currentTarget.value)
        }
    }

    const handleReset = () => {
        props.onReset && props.onReset(field.name, props.defaultValue)
    }

    return (
        <>
            <FormControl>
                <CustomInput
                    error={fieldState.invalid}
                    id={field.name}
                    type={props.type ?? 'text' }
                    {...field}
                    value={field.value ?? ''}
                    label={props.placeHolder}
                    disabled={props.disabled}
                    startIcon={props.startIcon}
                    endIcon={props.endIcon}
                    autoComplete={props.autoComplete ?? 'off'}
                    onChange={handleOnChange}
                />
                {
                    props.onReset && props.defaultValue !== field.value &&
                        <FormControlResetButton
                            onClick={handleReset}>
                            <TransMessage {...i18nKeys.properties_form_button_reset} />
                        </FormControlResetButton>
                }
                <StyledFormHelperText id={`${field.name}-helper`}>
                    <span hidden={!fieldState.invalid}>
                        {fieldState.error && fieldState.error.message}
                    </span>
                </StyledFormHelperText>
            </FormControl>
            { props.debug && <>
                <p>{fieldState.isTouched && 'Touched'}</p>
                <p>{fieldState.isDirty && 'Dirty'}</p>
                <p>{fieldState.invalid ? 'invalid' : 'valid'}</p>
            </>
            }
        </>
    )
}
  
//#endregion

export const FormControlResetButton = styled.span`
    color: ${colors.primary.DARK_PRIMARY};
    font-family: ${fonts.PRIMARY_BOLD};
    font-size: 14px;
    margin-left: 24px;
    text-decoration: underline;
    cursor: pointer;
`

//#region FormCheckbox

type TFormCheckboxProps = UseControllerProps<any> & {
    debug?: boolean
    disabled?: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onReset?: (name: string, value: any) => void
    block?: boolean
    height?: string
}

const Spacer = styled.div<{block?: boolean, $height?: string}>`
    width: ${p => p.block ? '385px' : ''};
    height: ${p => p.$height ? p.$height : '56px' };
    display: flex;
    align-items: center;
`

export const FormCheckbox = (props: TFormCheckboxProps) => {
    const { field, fieldState } = useController(props)

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(event)
        if (props.onChange) {
            props.onChange(event)
        }
    }

    const handleReset = () => {
        props.onReset && props.onReset(field.name, props.defaultValue)
    }

    return (
        <>
            <Spacer block={props.block} $height={props.height}>
                <CustomCheckbox
                    {...field}
                    value={field.value ?? false}
                    checked={field.value ?? false}
                    indeterminate={field.value === null}
                    id={field.name}
                    onChange={handleOnChange}
                    disabled={props.disabled}
                />
            </Spacer>
            {
                props.onReset && props.defaultValue !== field.value &&
                            <FormControlResetButton
                                onClick={handleReset}
                            >
                                <TransMessage {...i18nKeys.properties_form_button_reset} />
                            </FormControlResetButton>
            }
            { props.debug && <>
                <p>{fieldState.isTouched && 'Touched'}</p>
                <p>{fieldState.isDirty && 'Dirty'}</p>
                <p>{fieldState.invalid ? 'invalid' : 'valid'}</p>
            </>
            }
        </>
    )
}

//#endregion
