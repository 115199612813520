import EnhancedTabs from 'components/Tabs/EnhancedTabs'
import { EMessageType, EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { siteCameraService } from 'services/siteCameraService'
import { showMessage } from 'state/slices/uiSlice'
import { TChangePropertyValuesRequest } from 'types/businessLogic/job'
import { TPropertyValue, TSiteEntity } from 'types/businessLogic/site'
import { TAdvancedJobConfigFields, TTabButton } from 'types/ui/tab'
import DetailsTab from 'views/Control/SiteCamera/SiteCameraDetails/Tabs/DetailsTab'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { pushItem, setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import { TUploadManagerItem } from 'types/ui/uploadManager'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'state/store'
import { useModal } from 'hooks/modalProvider'

type TProps = {
    siteCamera: TSiteEntity
}

const SiteCameraDetails = (props: TProps) => {
    const { siteCamera } = props
    
    const dispatch = useAppDispatch()
    const {closeModal} = useModal()
    const intl = useIntl()
    const authorize = useAuthorization()
    const isSiteAdmin = authorize(EProfileRights.IS_SITE_ADMIN)

    const tabButtons: TTabButton[] = [
        {
            id: 'detailsTabButton',
            label: i18nKeys.camera_details_tab_details
        }
    ]

    const onSubmit = async (values: TPropertyValue[], advancedJobConfig: TAdvancedJobConfigFields, comment: string) => {
        let uploadManagerItem : TUploadManagerItem
        
        try {
            const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_camera_change_properties, {count: values.length})

            uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

            const p: TChangePropertyValuesRequest = {
                changePropertyValues: GeneralHelper.transformPropertyValues([props.siteCamera.id], values),
                comment,
                ...advancedJobConfig
            }
            
            dispatch(pushItem(uploadManagerItem))
            await siteCameraService.changePropertyValues(p)

            dispatch(showMessage({message: i18nKeys.siteService_details_properties_update_success_message, type: EMessageType.SUCCESS}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id}))
        } catch {
            dispatch(showMessage({message: i18nKeys.siteService_details_properties_update_error_message, type: EMessageType.ERROR}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem!.id, isError: true}))
        }

        closeModal()
    }
    
    return (
        <EnhancedTabs
            readOnlyForm={!isSiteAdmin}
            subtitleKey={i18nKeys.camera_details_properties_title}
            propertyValues={siteCamera.propertyValues}
            onSubmit={onSubmit}
            numOfEntities={1}
            disableUpdateButtons={false}
            tabButtons={tabButtons}
            tabContent={[
                {
                    content: <DetailsTab submitted={closeModal} key='detailsTab' siteCamera={siteCamera} />
                }
            ]}
        />
    )
}

export default SiteCameraDetails