// eslint-disable-next-line import/no-anonymous-default-export
export default {
    PRIMARY: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
    SECONDARY: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    SECONDARY_BOTTOM_ONLY: '0 10px 10px -10px rgba(0, 0, 0, 0.1)',
    TERTIARY: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)',
    QUATRENARY: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14)',
    QUINARY: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
    SENARY: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    SEPTENARY: '0 2px 10px 0 rgba(0, 0, 0, 0.3)',
    OCTONARY: '0 2px 10px 0 rgba(0, 0, 0, 0.1)'
}