import { ELicenseTypes } from 'enums/ELicenseTypes'
import { EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import useLicense from 'hooks/useLicense'
import { Navigate } from 'react-router'
import { useAppSelector } from 'state/store'
import React from 'react'

export type TAuthGuardProps = {
    element: JSX.Element
    expectedRights?: EProfileRights[]
    expectedLicense?: ELicenseTypes
    excludeUsernames?: string[]
    ignoreForSituatorExclusive?: boolean
}

const AuthGuard = (props: TAuthGuardProps) => {
    const {isAuthenticated, loginReqStatus} = useAppSelector(store => store.auth)
    const {roles, profileReqStatus, username} = useAppSelector(store => store.profile)
    const { situatorExclusive } = useAppSelector(store => store.license)
    const checkLicense = useLicense()
    const checkAuth = useAuthorization()

    if (isAuthenticated) {
        if (loginReqStatus !== 'idle' || profileReqStatus !== 'idle') {
            return <> Try to login </>
        }
        
        if (roles.length === 0 && username !== 'administrator') {
            return <Navigate to='/norole' />
        }

        if (props.ignoreForSituatorExclusive && situatorExclusive) {
            return <Navigate to='/' />
        }

        if (props.expectedLicense) {
            const licensed = checkLicense(props.expectedLicense)
        
            if (!licensed) {
                return <Navigate to='/' />
            }
        }

        if (props.expectedRights) {
            const authorized = checkAuth(...props.expectedRights)

            if (!authorized) {
                return <Navigate to='/' />
            }
        }

        if (props.excludeUsernames) {
            if (props.excludeUsernames.includes(username) && username === 'administrator') {
                return <Navigate to='/app/admin/users' />
            } else if (props.excludeUsernames.includes(username)) {
                return <Navigate to='/' />
            }
        }

        return props.element
    }
    
    return <Navigate to='/' />
}

export default AuthGuard
