import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import Icon, { Icons } from 'components/Icon/Icon'
import CameraInfoCard from 'components/PagedCardList/CameraInfoCard'
import PagedCardGroup from 'components/PagedCardList/PagedCardGroup'
import PagedCardList from 'components/PagedCardList/PagedCardList'
import { SearchInput } from 'components/PagedCardList/SearchInput'
import { SearchGroup } from 'components/PagedCardList/SearchGroup'
import { SearchContainer } from 'components/PagedCardList/SearchContainer'
import useCardPage from 'hooks/useCardPage'
import SelectionBox from 'components/Tables/SelectionBox'
import Toolbar from 'components/Toolbar/Toolbar'
import TransMessage from 'components/TransMessage/TransMessage'
import { EDataSyncItems, EMessageType, ENotificationPriority, ENotificationType, EPerformanceState, EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import useBackgroundService from 'hooks/useBackgroundService'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { TCameraPerformance, TCamerasPerSite } from 'types/businessLogic/cameraPerformance'
import { TGetCardPageRequest, TOverallState, TPerformanceItem, TPerformanceItemsPerSite } from 'types/businessLogic/performance'
import { uid } from 'uid'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import SetNotificationPriority, { TSetNotificationPriorityValues } from 'views/Monitor/Modals/SetNotificationPriority/SetNotificationPriority'
import EditNotificationsIcon from '@mui/icons-material/EditNotifications'
import { useModal } from 'hooks/modalProvider'
import MonitoringCameraDetails from 'views/Monitor/Cameras/MonitoringCameraDetails/MonitoringCameraDetails'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import CameraPerformanceService from 'services/cameraPerformanceService'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
    height: calc(100vh - 154px);
`

const IconContainer = styled.div`
    margin-left: 16px;
`

type TProps = {
    allCamerasPerSite: TCamerasPerSite[]
    totalNumberCameras: number
    overallState: TOverallState
    handleFetchAction: (params: TGetCardPageRequest) => void
    preSelectedState?: EPerformanceState
    restartCameras: (cameras: TPerformanceItem[]) => void
    ignoreCameras: (cameras: TPerformanceItem[]) => void
}

const CamerasPerformance = (props: TProps) => {
    const { allCamerasPerSite, totalNumberCameras, overallState, preSelectedState } = props
    const { handleFetchAction, ignoreCameras, restartCameras } = props
    const cardPageProps = useCardPage('camera-performance-grid', preSelectedState !== undefined ? [preSelectedState] : [])
    const [identifier] = useState(uid())
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)
    const checkAuth = useAuthorization()
    const {showModal, isModalOpen} = useModal()
    const dispatch = useAppDispatch()

    const loadData = useCallback(() => {
        handleFetchAction(cardPageProps.fetchState)
    }, [cardPageProps.fetchState, handleFetchAction])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.CAMERA_PERFORMANCE, loadData, identifier)

    useEffect(() => {
        const hasSelectedRows = cardPageProps.selectionCount > 0
        setDisabled(identifier, isModalOpen || hasSelectedRows)
        setAutoupdatePaused(isModalOpen || hasSelectedRows)
    }, [cardPageProps.selectionCount, identifier, isModalOpen, setDisabled])

    const handleIgnoreAction = () => {
        ignoreCameras(cardPageProps.selectedItems)
        cardPageProps.deselectAll()
    }

    const handleRestartAction = () => {
        restartCameras(cardPageProps.selectedItems)
        cardPageProps.deselectAll()
    }

    const handleOpenDetails = (id: number) => {
        const performanceMeasure = allCamerasPerSite.flatMap(x => x.items).find(x => x.entityId === id)
            
        if (!performanceMeasure) return

        showModal({
            content: <MonitoringCameraDetails id={id} performanceMeasure={performanceMeasure as TCameraPerformance} />,
            width: '80%',
            title: ''
        })
    }

    const pagedCardGroupItemRenderer = (camera: TCameraPerformance, key: number, isChecked: boolean, isFiltered: boolean) : JSX.Element => {
        return (
            <CameraInfoCard
                {...cardPageProps}
                key={key}
                camera={camera}
                isChecked={isChecked}
                isFiltered={isFiltered}
                openDetails={handleOpenDetails}
            />
        )
    }
    
    const pagedCardListItemRenderer = (camerasPerSite: TPerformanceItemsPerSite, key: number, isParentChecked: boolean) : JSX.Element => {
        const cameraState: TOverallState = {
            healthy: camerasPerSite.items.filter(item => item.overallState === EPerformanceState.HEALTHY).length,
            critical: camerasPerSite.items.filter(item => item.overallState === EPerformanceState.CRITICAL).length,
            ignored: camerasPerSite.items.filter(item => item.overallState === EPerformanceState.IGNORED).length,
            offline: camerasPerSite.items.filter(item => item.overallState === EPerformanceState.OFFLINE).length,
            warning: camerasPerSite.items.filter(item => item.overallState === EPerformanceState.WARNING).length
        }
        
        return (
            <PagedCardGroup
                {...cardPageProps}
                itemRenderer={pagedCardGroupItemRenderer}
                performanceItemsPerSite={camerasPerSite}
                overallState={cameraState}
                key={key}
                isParentChecked={isParentChecked}
            />
        )
    }

    const searchFilters = () : JSX.Element => {
        return (
            <SearchGroup>
                <IconContainer>
                    <Icon d={Icons.SEARCH} />
                </IconContainer>
                <SearchContainer>
                    <div>
                        <TransMessage {...i18nKeys.site_systems_search_by_site} />
                    </div>
                    <div>
                        <SearchInput small onChange={event => cardPageProps.onFilterChange('Site', event.currentTarget.value)} />
                    </div>
                </SearchContainer>
                <SearchContainer>
                    <div>
                        <TransMessage {...i18nKeys.site_systems_search_by_name} />
                    </div>
                    <div>
                        <SearchInput small onChange={event => cardPageProps.onFilterChange('Name', event.currentTarget.value)} />
                    </div>
                </SearchContainer>
            </SearchGroup>
        )
    }

    const totalSelectedRows = cardPageProps.selectionCount
    const rowCount = allCamerasPerSite.flatMap(camera => camera.items).length
    const numSelectedRows = cardPageProps.selectionCount

    const hasRestartRight = checkAuth(EProfileRights.HAS_RESTART_SITE_ENTITIES_RIGHT)
    const hasIgnoreRight = checkAuth(EProfileRights.HAS_IGNORE_RIGHT)

    const disableToolbarButtons = cardPageProps.selectionCount === 0

    const getSetNotificationPriorityInitialValue = () : TSetNotificationPriorityValues => {
        let result = ENotificationPriority.HIGH
        
        if (cardPageProps.selectedItems.every(x => x.notificationPriority === cardPageProps.selectedItems[0].notificationPriority)) {
            result = cardPageProps.selectedItems[0].notificationPriority
        }

        let defaultResult = true

        if (cardPageProps.selectedItems.every(x => x.notificationType === cardPageProps.selectedItems[0].notificationType)) {
            defaultResult = cardPageProps.selectedItems[0].notificationType !== ENotificationType.ENTITY
        }

        return {
            notificationPriority: result,
            useDefault: defaultResult
        }
    }

    const handleSetNotificationPriority = () => {
        const ids = cardPageProps.selectedItems.map(x => (x.id))
        showModal({
            content: <SetNotificationPriority
                initialValue={getSetNotificationPriorityInitialValue()}
                onSubmit={async (values: TSetNotificationPriorityValues) => {
                    try {
                        await CameraPerformanceService.setNotificationPriority(ids, values.notificationPriority, values.useDefault)
                        dispatch(showMessage({message: i18nKeys.camera_performance_ignore_success, type: EMessageType.SUCCESS}))
                    } catch (err) {
                        dispatch(showMessage({message: i18nKeys.camera_performance_ignore_error, type: EMessageType.ERROR}))
                    }
                }}
                hideUseDefaultValue={false}
            />,
            title: i18nKeys.notification_priority_title,
            description: i18nKeys.notification_priority_description,
            onClose: () => cardPageProps.deselectAll()
        })
    }

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        <SelectionBox
                            id='selectedCamerasCheckbox'
                            rowCount={rowCount}
                            onAllRowsSelect={checked => cardPageProps.onAllSelect(allCamerasPerSite, checked)}
                            messageKey={i18nKeys.site_list_toolbar_selected_sites}
                            totalSelectedRows={totalSelectedRows}
                            numSelectedRows={cardPageProps.selectAll ? totalSelectedRows : cardPageProps.selectionCount}
                            disabled={cardPageProps.selectAll}
                        />

                        {
                            hasRestartRight &&
                            <CustomTooltip title={<TransMessage {...i18nKeys.restart_cameras_tooltip} />}>
                                <div>
                                    <CustomIconButton
                                        id='restartCamerasBtn'
                                        data-testid='restartCamerasBtn'
                                        disabled={disableToolbarButtons || !hasRestartRight}
                                        onClick={handleRestartAction}
                                        icon={Icons.RETRY}
                                    />
                                </div>
                            </CustomTooltip>
                        }

                        {
                            hasIgnoreRight &&
                            <CustomTooltip title={<TransMessage {...i18nKeys.camera_performance_tooltip_ignore} />}>
                                <div>
                                    <CustomIconButton
                                        id='ignoreCamerasBtn'
                                        data-testid='ignoreCamerasBtn'
                                        disabled={disableToolbarButtons}
                                        onClick={handleIgnoreAction}
                                        icon={Icons.EYE_HIDE}
                                    />
                                </div>
                            </CustomTooltip>
                        }
                        <CustomTooltip title={<TransMessage {...i18nKeys.site_list_toolbar_notification_priority_tooltip} />}>
                            <div>
                                <CustomIconButton
                                    id='setNotificationPriorityBtn'
                                    data-testid='setNotificationPriorityBtn'
                                    disabled={totalSelectedRows < 1}
                                    onClick={handleSetNotificationPriority}
                                    icon={<EditNotificationsIcon />}
                                />
                            </div>
                        </CustomTooltip>
                    </>
                }
                RightContent={
                    <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                }
            />

            <PagedCardList
                {...cardPageProps}
                itemRender={pagedCardListItemRenderer}
                searchFilters={searchFilters}
                items={allCamerasPerSite}
                totalCount={totalNumberCameras}
                overallState={overallState}
                isParentChecked={numSelectedRows > 0 && numSelectedRows === rowCount}
            />
        </ViewWrapper>
    )
}

export default CamerasPerformance
