import { CircularProgress, LinearProgress } from '@mui/material'
import { CustomButton } from 'components/CustomControls/CustomButton'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { EUploadManagerError, EUploadState } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useState } from 'react'
import EnumHelper from 'helpers/EnumHelper'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import fonts from 'style/fonts'
import shadows from 'style/shadows'
import styled from 'styled-components'
import { TUploadManagerItem } from 'types/ui/uploadManager'

const staticDimensionValues = {
    container: 490,
    progressBarsDifference: 104,
    noItemsHeight: 50
}

const Root = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 16px;
    cursor: pointer;
`
const IconsHolder = styled.div`
    width: 20px;
    height: 20px;
`
const SecondLoaderHolder = styled.div`
    opacity: .5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
`
const ItemsContaienr = styled.div`
    position: absolute;
    z-index: 2;
    top: 190%;
    left: 0;
    background-color: ${colors.shades.PURE_WHITE};
    height: auto;
    width: ${staticDimensionValues.container}px;
    padding: 19px 24px 24px 24px;
    box-shadow: ${shadows.QUINARY};
    border-bottom-left-radius: ${borderRadius.SECONDARY};
    border-bottom-right-radius: ${borderRadius.SECONDARY};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-height: ${staticDimensionValues.noItemsHeight}px;
    max-height: 400px;
    overflow-y: auto;
`
const SingleItem = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
`
const ItemName = styled.p`
    /* ...typography.small */
    font-size: 16px;
    width: ${staticDimensionValues.container - staticDimensionValues.progressBarsDifference}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
const LeftSide = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
`
const ProgressIconHolder = styled.div<{inProgress: boolean}>`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${p => p.inProgress ? colors.shades.DEEP_SHADE : colors.primary.DEFAULT_PRIMARY};
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
    & svg {
        fill: ${colors.shades.PURE_WHITE};
        align-self: center;
        transform: scale(0.7);
    }
`
const ProgressBarRoot = styled.div`
    height: 2px;
    margin-top: 8px;
    position: relative;
    width: ${staticDimensionValues.container - staticDimensionValues.progressBarsDifference}px;
    background-color: ${colors.projectColors.BG_COLOR};
`
const ProgressBarInner = styled.div`
    background-color: ${colors.primary.DEFAULT_PRIMARY};
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
`
const StateLabel = styled.span`
    font-family: ${fonts.PRIMARY_BOLD};
    width: ${staticDimensionValues.container - staticDimensionValues.progressBarsDifference}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
const ClearButton = styled(CustomButton)`
    width: 100%;
`
const NoItems = styled.div`
    height: ${staticDimensionValues.noItemsHeight}px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    & p {
        align-self: center;
        font-family: ${fonts.PRIMARY_SEMIBOLD};
        font-size: 18px;
    }
`
const StyledCircularProgress = styled(CircularProgress)`
  width: 24px;
  height: 24px;
  viewport-fit: inherit;
`

type TProps = {
    variant: 'indeterminate' | 'determinate'
    totalProgress: number
    showItems: boolean
    containerRef: React.Ref<HTMLDivElement>
    toggleShowItems: () => void
    items: TUploadManagerItem[]
    handleCancelPress: (item: TUploadManagerItem) => void
    calculateTotalProgress: () => void
    clearCompleted: () => void
}

export const createUploadManagerItemForJob = (name: string): TUploadManagerItem => ({
    id: 0,
    state: EUploadState.IN_PROGRESS,
    lengthComputable: false,
    percentComplete: 0,
    name
})

export const createUploadManagerItemForFirmwareUpdate = (name: string): TUploadManagerItem => ({
    id: 0,
    state: EUploadState.IN_PROGRESS,
    lengthComputable: true,
    percentComplete: 0,
    name
})

const UploadManager = (props: TProps) => {
    const {variant, totalProgress, showItems, items, containerRef} = props
    const { toggleShowItems, handleCancelPress, clearCompleted } = props

    const [showXIcon, setShowXIcon] = useState<{[key: string]: boolean}>({})
 
    const handleMouseEnter = (item: TUploadManagerItem) => {
        if (item.state !== EUploadState.DONE) return
        const newShowXIcon = {
            ...showXIcon,
            [item.id]: true
        }
        setShowXIcon(newShowXIcon)
    }

    const handleMouseLeave = (item: TUploadManagerItem) => {
        if (item.state !== EUploadState.DONE) return
        const newShowXIcon = {
            ...showXIcon,
            [item.id]: false
        }
        setShowXIcon(newShowXIcon)
    }

    const renderProgressText = (item: TUploadManagerItem) => {
        if (item.state === EUploadState.DONE) return <TransMessage {...i18nKeys.upload_manager_state_done} />

        if (item.state === EUploadState.ERROR) {
            if (!item.errorMessage) return <TransMessage {...i18nKeys.upload_manager_state_error} />
            
            if (typeof item.errorMessage === 'string') {
                return (
                    <>
                        <TransMessage {...i18nKeys.upload_manager_state_error} /> : {item.errorMessage}
                    </>
                )
            }

            if (typeof item.errorMessage in EUploadManagerError) return EnumHelper.translatedMessageEnumRenderer(item.errorMessage as EUploadManagerError)

            return item.errorMessage
        }

        if (item.state === EUploadState.IN_PROGRESS && item.lengthComputable) return `${item.percentComplete}%`

        return null
    }

    return (
        <Root ref={containerRef}>
            <IconsHolder onClick={toggleShowItems}>
                <StyledCircularProgress
                    color='primary'
                    size={20}
                    thickness={8}
                    variant={variant}
                    value={totalProgress}
                />
                <SecondLoaderHolder>
                    <StyledCircularProgress
                        color='primary'
                        size={20}
                        thickness={8}
                        variant={variant}
                        value={100}
                    />
                </SecondLoaderHolder>
            </IconsHolder>
            {
                showItems &&
                <ItemsContaienr>
                    {
                        items.map((item: TUploadManagerItem, index: number) => (
                            <SingleItem key={index}>
                                <LeftSide>
                                    <CustomTooltip title={item.name}>
                                        <ItemName> { item.name } </ItemName>
                                    </CustomTooltip>
                                    <ProgressBarRoot>
                                        {
                                            item.lengthComputable || item.state === EUploadState.DONE ?
                                                <ProgressBarInner id='progressLine' style={{width: `${item.percentComplete}%`}} /> :
                                                <LinearProgress id='uploadLinearProgress' />
                                        }
                                    </ProgressBarRoot>
                                    <StateLabel>{renderProgressText(item)}</StateLabel>
                                </LeftSide>
                                <ProgressIconHolder inProgress={item.state === EUploadState.IN_PROGRESS}
                                    onMouseEnter={() => handleMouseEnter(item)}
                                    onMouseLeave={() => handleMouseLeave(item)}
                                    onClick={() => handleCancelPress(item)}
                                >
                                    <Icon d={
                                        !showXIcon[item.id] &&
                                item.state === EUploadState.DONE ? Icons.TICK : Icons.CANCEL
                                    } />
                                </ProgressIconHolder>
                            </SingleItem>
                        ))
                    }
                    {
                        items.length ?
                            <ClearButton $secondary onClick={clearCompleted}>
                                <TransMessage {...i18nKeys.upload_manager_clear_completed_button} />
                            </ClearButton> :
                            <NoItems>
                                <p>
                                    <TransMessage {...i18nKeys.upload_manager_no_items} />
                                </p>
                            </NoItems>
                    }
                </ItemsContaienr>
            }
        </Root>
    )
}

export default UploadManager