import { CustomModal } from 'components/CustomControls/CustomModal'
import TransMessage from 'components/TransMessage/TransMessage'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useEffect, useState } from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import JobService from 'services/jobService'
import { cancelJobsAsync, clearGroups, clearJobs, getGroupDataAsync, getJobsAsync, retryJobsAsync } from 'state/slices/siteJobSlice'
import { pushItem } from 'state/slices/uploadManagerSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import styled from 'styled-components'
import { TCancelAndRetryJobs } from 'types/businessLogic/job'
import { TGroup, TTableFetchState } from 'types/network'
import { TTableRowData } from 'types/ui/table'
import SiteJobDetails from 'views/SiteJobs/JobDetails/SiteJobDetails'
import SiteJobs from 'views/SiteJobs/SiteJobs'
import { tableInfo } from 'views/SiteJobs/siteJobTableColumns'
import SiteJobTablePreparer from 'views/SiteJobs/siteJobTablePreparer'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { useIntl } from 'react-intl'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const SiteJobsContainer = () => {
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const { jobs, totalNumber, groups } = useAppSelector(state => state.siteJob)

    useBreadCrumbs([i18nKeys.breadcrumb_site_jobs])

    const [currentSiteJobDetailsId, setCurrentSiteJobDetailsId] = useState<number>()

    useEffect(() => {
        return () => {
            dispatch(clearJobs())
        }
    }, [dispatch])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams,
            translationKey: group.translationKey
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {

        if (!params.global.groupBy) {
            const overwrittenParams = {...params}
            if (overwrittenParams.global.order === 'none') {
                overwrittenParams.global.descending = true
            }
            const requestParams = GeneralHelper.buildRequestFromTableState(overwrittenParams)
            dispatch(getJobsAsync(requestParams))
        } else if (params.groupName) {
            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, params.groupName)
            dispatch(getGroupDataAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await JobService.getJobsGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }

            getGroups()
        }
    }, [dispatch])

    const prepareCancelAndRetryJobs = (selectedJobs: Array<TTableRowData & {job: string}>): TCancelAndRetryJobs[] =>
        selectedJobs.map(job => ({
            id: Number((job.id as string).replace('batchJob', '')),
            isBatchJob: job.job === 'BatchJob'
        }))

    const cancelJobs = (selectedJobs: Array<TTableRowData & {job: string}>) => {
        const preparedJobs = prepareCancelAndRetryJobs(selectedJobs)

        dispatch(cancelJobsAsync(preparedJobs))
    }

    const retryJobs = (selectedJobs: Array<TTableRowData & {job: string}>) => {
        const preparedJobs = prepareCancelAndRetryJobs(selectedJobs)

        const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_retry_jobs, {count: preparedJobs.length})
        const uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)
        
        dispatch(pushItem(uploadManagerItem))
        dispatch(retryJobsAsync({jobs: preparedJobs, uploadManagerItemId: uploadManagerItem.id}))
    }

    const preparedGroups = SiteJobTablePreparer.prepareGroups(groups, setCurrentSiteJobDetailsId)
    const data = SiteJobTablePreparer.prepareJobs(jobs, setCurrentSiteJobDetailsId)
        
    const renderModal = () => {
        const siteJobDetails = jobs.find(job => job.job !== 'BatchJob' && job.id === currentSiteJobDetailsId)

        if (!siteJobDetails) return

        return (
            <CustomModal
                open={true}
                onClose={() => setCurrentSiteJobDetailsId(undefined)}
                title={<TransMessage {...i18nKeys[siteJobDetails.translationKey]} />}
                description={siteJobDetails.about}
            >
                <SiteJobDetails jobId={siteJobDetails.id as number} switchToRelatedJob={setCurrentSiteJobDetailsId} />
            </CustomModal>
        )
    }

    return (
        <ViewContainer>
            <SiteJobs
                cancelJobs={cancelJobs}
                retryJobs={retryJobs}
                getTableData={getTableData}
                data={data}
                tableInfo={tableInfo}
                groups={preparedGroups}
                totalCount={totalNumber}
                isDetailOpen={!!currentSiteJobDetailsId}
                getGroupData={getGroupData}
            />
            {renderModal()}
        </ViewContainer>
    )
}

export default SiteJobsContainer