import { Grid, Input, MenuItem } from '@mui/material'
import { CustomSelect } from 'components/CustomControls/CustomSelect'
import Toolbar from 'components/Toolbar/Toolbar'
import dayjs from 'dayjs'
import { EMessageType } from 'enums/enums'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { i18nKeys } from 'i18n/keys'
import React, { useEffect, useState } from 'react'
import StatisticService from 'services/statisticService'
import { showMessage } from 'state/slices/uiSlice'
import { dispatch } from 'state/store'
import colors from 'style/colors'
import { TCoreInstances, TCoreStatistics } from 'types/statistic'
import { DateTimePicker } from './components/DateTimePicker'
import { TimeRange, TimeRangeButtonGroup } from './components/TimeRangeButtonGroup'
import { UmbrellaCoreMonitoringCard } from './components/UmbrellaCoreMonitoringCard'
import { ViewWrapper } from './UmbrellaCoreContainer.styled'

const UmbrellaCoreContainer = () => {
    const [coreIds, setCoreIds] = useState<TCoreInstances[]>([])
    const [data, setData] = useState<TCoreStatistics[]>([])
    const [selectedCoreId, setSelectedCoreId] = useState(0)
    const [timeRange, setTimeRange] = useState<TimeRange>({ from: dayjs().subtract(7, 'days'), till: dayjs() })
    
    useBreadCrumbs([i18nKeys.breadcrumb_umbrella_core])

    useEffect(() => {
        (async () => {
            try {
                const coreInstances = await StatisticService.getCoreInstances()
                setCoreIds(coreInstances)
                setSelectedCoreId(coreInstances[0].id)
            } catch {
                dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            if (selectedCoreId === 0 || !timeRange) return
        
            try {
                const response = await StatisticService.getCoreStatistics({
                    from: timeRange.from,
                    till: timeRange.till,
                    id: selectedCoreId
                })
                setData(response.performanceMeasures)
            } catch {
                dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
            }
        })()
    }, [selectedCoreId, timeRange])

    if (!selectedCoreId) {
        return null
    }
    
    const cpuData = data.map(item => ({
        time: dayjs(item.time).unix(),
        value: item.cpu * 100
    }))
    const memoryUsageData = data.map(item => ({
        time: dayjs(item.time).unix(),
        value: item.memory
    }))
    const requestCountData = data.map(item => ({
        time: dayjs(item.time).unix(),
        value: item.requestCount
    }))
    const requestDurationData = data.map(item => ({
        time: dayjs(item.time).unix(),
        value: item.requestDuration
    }))

    const getRequest = () => {
        return {
            ...timeRange,
            id: selectedCoreId
        }
    }

    const handleSetStartDate = (date: Date | null) => {
        if (date) {
            const params = {
                from: dayjs(date),
                till: timeRange.till
            }
            if (date > timeRange.till.toDate()) {
                params.till = dayjs(date).add(1, 'hour')
            }
            setTimeRange(params)
        }
    }

    const handleSetEndDate = (date: Date | null) => {
        if (date) {
            const params = {
                from: timeRange.from,
                till: dayjs(date)
            }
            if (date < timeRange.from.toDate()) {
                params.from = dayjs(date).subtract(1, 'hour')
            }
            setTimeRange(params)
        }
    }

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        <DateTimePicker label={i18nKeys.umbrella_core_from_date} value={timeRange.from.toDate()} onChange={handleSetStartDate} />
                        <DateTimePicker label={i18nKeys.umbrella_core_to_date} value={timeRange.till.toDate()} onChange={handleSetEndDate} />
                    </>
                }
                RightContent={
                    <>
                        <CustomSelect
                            value={selectedCoreId}
                            onChange={(e) => setSelectedCoreId(e.target.value as number)}
                            input={ <Input disableUnderline /> }
                        >
                            {coreIds.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                        </CustomSelect>
                        <TimeRangeButtonGroup onChange={setTimeRange} />
                    </>
                }
            />

            <Grid container spacing={2}>
                <UmbrellaCoreMonitoringCard title={i18nKeys.umbrella_core_cpu_usage} data={cpuData} graphColor={colors.extra.SUCCESS} unit='%' request={getRequest()} />
                <UmbrellaCoreMonitoringCard title={i18nKeys.umbrella_core_memory_usage} data={memoryUsageData} graphColor={colors.extra.WARNING} unit='' request={getRequest()} />
                <UmbrellaCoreMonitoringCard title={i18nKeys.umbrella_core_request_count} data={requestCountData} graphColor={colors.extra.BLUE} unit='' request={getRequest()} />
                <UmbrellaCoreMonitoringCard title={i18nKeys.umbrella_core_request_duration} data={requestDurationData} graphColor={colors.extra.ALERT} unit='' request={getRequest()} />
            </Grid>
        </ViewWrapper>
    )
}
export default UmbrellaCoreContainer
