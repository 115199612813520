import { EMessageType, EProfileRights } from 'enums/enums'
import ProfileService from 'services/profileService'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { showMessage } from './uiSlice'
import { TProfileDashboardLayout, TGridLayout } from 'types/businessLogic/profile'
import { i18nKeys } from 'i18n/keys'
import { EDashboardElements, EDashboardLayouts } from 'types/ui/dashboard'
import DashboardHelper from 'helpers/DashboardHelper'
import { RootState } from 'state/store'

export type TProfileState = {
    username: string
    lastLogin: string
    roles: string[]
    isAdUser: boolean
    rights: EProfileRights[]
    dashboardLayout: TProfileDashboardLayout
    gridLayouts: TGridLayout[]
    isNavBarCollapsed: boolean
    profileReqStatus: string
}

const initialState : TProfileState = {
    username: '',
    lastLogin: '',
    roles: [],
    isAdUser: false,
    rights: [],
    isNavBarCollapsed: false,
    dashboardLayout: {
        advancedData: '',
        layout: EDashboardLayouts.TwoByTwo,
        layoutElements: [
            { layoutElement: EDashboardElements.Empty },
            { layoutElement: EDashboardElements.Empty },
            { layoutElement: EDashboardElements.Empty },
            { layoutElement: EDashboardElements.Empty }
        ]
    },
    gridLayouts: [],
    profileReqStatus: ''
}

export const getProfileAsync = createAsyncThunk('profile/profileAsync', async (_, thunkApi) => {
    try {
        return await ProfileService.getProfile()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getDashboardLayoutAsync = createAsyncThunk('profile/getDashboardLayoutAsync', async (_, thunkApi) => {
    try {
        return await ProfileService.getDashboardLayout()
    } catch (err) {
        const store = thunkApi.getState() as RootState
        thunkApi.dispatch(setDefaultDashboard(DashboardHelper.buildDefaultLayout(store.license, store.profile.rights)))
        throw err
    }
})

export const postDashboardLayoutAsync = createAsyncThunk('profile/postDashboardLayoutAsync', async (layout: TProfileDashboardLayout, thunkApi) => {
    try {
        return await ProfileService.postDashboardLayout(layout)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const deleteDashboardLayoutAsync = createAsyncThunk('profile/deleteDashboardLayoutAsync', async (_, thunkApi) => {
    try {
        const store = thunkApi.getState() as RootState
        thunkApi.dispatch(setDefaultDashboard(DashboardHelper.buildDefaultLayout(store.license, store.profile.rights)))
        await ProfileService.deleteDashboardLayout()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getGridLayoutsAsync = createAsyncThunk('profile/getGridLayoutsAsync', async (_, thunkApi) => {
    try {
        return await ProfileService.getGridLayouts()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }

})

export const restoreGridLayoutsAsync = createAsyncThunk('profile/restoreGridLayoutsAsync', async (gridId: number, thunkApi) => {
    try {
        await ProfileService.restoreGridLayout(gridId)
        thunkApi.dispatch(showMessage({message: i18nKeys.grid_layout_restore_success, type: EMessageType.SUCCESS}))
        return gridId
    } catch (error) {
        thunkApi.dispatch(showMessage({message: i18nKeys.grid_layout_restore_error, type: EMessageType.ERROR}))
        throw error
    }
})

export const setGridLayoutAsync = createAsyncThunk('profile/setGridLayoutAsync', async (gridLayout: TGridLayout, thunkApi) => {
    try {
        await ProfileService.setGridLayout(gridLayout)
        thunkApi.dispatch(getGridLayoutsAsync())
        thunkApi.dispatch(showMessage({message: i18nKeys.grid_layout_save_success, type: EMessageType.SUCCESS}))
    } catch (error) {
        thunkApi.dispatch(showMessage({message: i18nKeys.grid_layout_save_error, type: EMessageType.ERROR}))
        throw error
    }
})

const slice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        setIsNavbarCollapsed: (state, action: PayloadAction<boolean>) => {
            state.isNavBarCollapsed = action.payload
        },
        setDashboardLayout: (state, action: PayloadAction<TProfileDashboardLayout>) => {
            state.dashboardLayout = action.payload
        },
        setDashboardElement: (state, action: PayloadAction<{position: number, element: EDashboardElements}>) => {
            if (state.dashboardLayout.layoutElements[action.payload.position]) {
                state.dashboardLayout.layoutElements[action.payload.position].layoutElement = action.payload.element
            } else {
                state.dashboardLayout.layoutElements[action.payload.position] = {
                    layoutElement: action.payload.element
                }
                if (state.dashboardLayout.layout === EDashboardLayouts.AdvancedLayout) {    //makes the function unpure - but it works 
                    state.dashboardLayout.advancedData = JSON.stringify(DashboardHelper.recalculateDashboardAdvancedData(action.payload.position, state.dashboardLayout.advancedData))
                }
            }
        },
        setDefaultDashboard: (state, action : PayloadAction<TProfileDashboardLayout>) => {
            state.dashboardLayout = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProfileAsync.fulfilled, (state, action) => {
            Object.assign(state, action.payload)
            state.profileReqStatus = 'idle'
        })
        builder.addCase(getDashboardLayoutAsync.fulfilled, (state, action) => {
            state.dashboardLayout = action.payload
        })
        builder.addCase(getGridLayoutsAsync.fulfilled, (state, action) => {
            state.gridLayouts = action.payload
        })
        builder.addCase(restoreGridLayoutsAsync.fulfilled, (state, action) => {
            const filteredColumnsLayout = state.gridLayouts.filter(layout => layout.id !== action.payload)
            state.gridLayouts = filteredColumnsLayout
        })
    }
})

export const {setDashboardLayout, setDashboardElement, setDefaultDashboard, setIsNavbarCollapsed} = slice.actions

export default slice.reducer
