import React from 'react'
import { TSite } from 'types/businessLogic/site'
import CommentsForm from 'components/CustomComment/CommentsForm'

export type TFormInputs = {
    comment: string
}

type TProps = {
    site: TSite
    onSubmit?: () => void
    readonly: boolean
}

const CommentsTab = (props: TProps) => {
    const {site, onSubmit, readonly} = props

    return (
        <CommentsForm
            customComments={site.customComments}
            siteIds={[site.id]}
            onSubmit={onSubmit}
            readonly={readonly}
        />
    )
}

export default CommentsTab