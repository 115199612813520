import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { Icons } from 'components/Icon/Icon'
import React from 'react'

type TProps = {
    id: string
    title: string | JSX.Element
    disabled: boolean
    onClick: () => void
}

const DeleteButton = (props: TProps) => {
    const {id, title, disabled, onClick} = props

    return (
        <CustomTooltip title={title}>
            <div>
                <CustomIconButton
                    disabled={disabled}
                    onClick={onClick}
                    id={id}
                    icon={Icons.TRASH}
                />
            </div>
        </CustomTooltip>
    )
}

export default DeleteButton