import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import SiteService from 'services/siteService'
import { TFetchParamsRequest } from 'types/network'
import { TSiteMin, TSite, TDeleteAllSitesRequest } from 'types/businessLogic/site'
import { showMessage } from 'state/slices/uiSlice'
import { i18nKeys } from 'i18n/keys'
import { EMessageType } from 'enums/enums'
import { TTableGroup } from 'types/ui/table'

export type TSiteState = {
    sites: TSite[]
    groups: TTableGroup<TSite>[]
    totalNumber: number
}

const initialState : TSiteState = {
    sites: [],
    groups: [],
    totalNumber: 0
}

export const getSitesAsync = createAsyncThunk('site/getSitesAsync', async (params: TFetchParamsRequest, thunkApi) => {
    try {
        return await SiteService.getSites(params)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const updateSiteAsync = createAsyncThunk('site/updateSiteAsync', async (params: TSiteMin[], thunkApi) => {
    try {
        await SiteService.updateSites(params)
        thunkApi.dispatch(showMessage({message: i18nKeys.site_details_site_update_success_message, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.site_details_site_update_error_message, type: EMessageType.ERROR}))
        throw err
    }
})

export const getGroupDataAsync = createAsyncThunk('site/getGroupDataAsync', async (params: TFetchParamsRequest & { translationKey?: string}, thunkApi) : Promise<TTableGroup<TSite>> => {
    try {
        const result = await SiteService.getSites(params)
        return {
            name: params.GroupName!,
            translationKey: params.translationKey,
            count: result.totalNumber,
            data: result.sites
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.site_details_site_update_error_message, type: EMessageType.ERROR}))
        throw err
    }
})

export const deleteSitesAsync = createAsyncThunk('site/deleteSitesAsync', async (params: number[], thunkApi) => {
    try {
        await SiteService.deleteSites(params)
        thunkApi.dispatch(showMessage({message: i18nKeys.site_list_toolbar_delete_sites_success, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.site_list_toolbar_delete_sites_error, type: EMessageType.ERROR}))
        throw err
    }
})

export const deleteAllSitesAsync = createAsyncThunk('site/deleteAllSitesAsync', async (request: TDeleteAllSitesRequest, thunkApi) => {
    try {
        await SiteService.deleteAllSites(request)
        thunkApi.dispatch(showMessage({message: i18nKeys.site_list_toolbar_delete_sites_success, type: EMessageType.SUCCESS}))
        return request
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.site_list_toolbar_delete_sites_error, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'site',
    initialState: initialState,
    reducers: {
        clearGroups: (state, action : PayloadAction<{ totalNumber: number, groups: TTableGroup<TSite>[] }>) => {
            action.payload.groups.forEach(item => {
                const oldItem = state.groups.find(x => x.name === item.name)
                if (oldItem) {
                    item.data = oldItem.data
                }
            })

            state.groups = action.payload.groups
            state.totalNumber = action.payload.totalNumber
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSitesAsync.fulfilled, (state, action) => {
                const { sites, totalNumber } = action.payload
                state.sites = sites
                state.totalNumber = totalNumber
            })
            .addCase(getGroupDataAsync.fulfilled, (state, action) => {
                const existingGroup = state.groups.find(p => p.name === action.payload.name)
                if (existingGroup) {
                    existingGroup.data = action.payload.data
                    existingGroup.count = action.payload.count
                } else {
                    state.groups.push(action.payload)
                }

                action.payload.data?.forEach(x => {
                    const index = state.sites.findIndex(y => y.id === x.id)
                    if (index === -1) {
                        state.sites.push(x)
                    } else {
                        state.sites[index] = x
                    }
                })
            })
    }
})

export const {clearGroups} = slice.actions

export default slice.reducer