import CustomIconToggleButton from 'components/CustomIconButton/CustomIconToggleButton'
import { Icons } from 'components/Icon/Icon'
import { i18nKeys } from 'i18n/keys'
import React from 'react'

type TProps = {
    onClick : () => void
    isToggled?: boolean
}

const LiveUpdateToggleButton = (props: TProps) => {
    
    return (
        <CustomIconToggleButton
            id='pauseAutoRefresh'
            data-testid='pauseAutoRefresh'
            defaultIcon={Icons.PAUSE_CIRCLE}
            toggledIcon={Icons.PLAY_CIRCLE}
            untoggledTooltip={i18nKeys.toolbar_auto_refresh}
            toggeledTooltip={i18nKeys.toolbar_auto_refresh_play}
            {...props}
        />
    )
}
export default LiveUpdateToggleButton