import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import SiteSystemPerformanceService from 'services/siteSystemPerformanceService'
import { showMessage } from 'state/slices/uiSlice'
import { TGetCardPageRequest, TOverallState } from 'types/businessLogic/performance'
import { TSiteSystemsPerSite } from 'types/businessLogic/siteSystem'

export type TSiteSystemPerformanceState = {
    allSiteSystemsPerSite: TSiteSystemsPerSite[]
    totalNumberSites: number
    overallState: TOverallState
}

const initialState: TSiteSystemPerformanceState = {
    allSiteSystemsPerSite: [],
    totalNumberSites: 0,
    overallState: {
        critical: 0,
        healthy: 0,
        ignored: 0,
        offline: 0,
        warning: 0
    }
}

export const fetchSiteSystemsAsync = createAsyncThunk('siteSystemPerformanceSlice/fetchSiteSystemsAsync', async (params: TGetCardPageRequest, thunkApi) => {
    try {
        const result = await Promise.all([
            SiteSystemPerformanceService.getSiteSystems(params.fetchParams, params.filterByStates),
            SiteSystemPerformanceService.getOverallStates()
        ])
        
        return {
            siteSystemsPerSite: result[0],
            overallStates: result[1]
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'siteSystemPerformanceSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSiteSystemsAsync.fulfilled, (state, action) => {
            if (action.payload) {
                const { overallStates, siteSystemsPerSite } = action.payload

                state.overallState = overallStates
                state.allSiteSystemsPerSite = siteSystemsPerSite.siteSystems
                state.totalNumberSites = siteSystemsPerSite.totalNumber
            }
        })
    }
})

export default slice.reducer