import axiosClient from 'api/axiosClient'
import { TAllCommonPropertiesRequest, TChangePropertyValuesRequest } from 'types/businessLogic/job'
import { TAdvancedTablePropertyValue, TCommonProperties, TProperty, TPropertyValue, TSiteEntity, TSiteEntityResponse } from 'types/businessLogic/site'
import { TFetchParamsRequest, TGroupParams, TGroupResponse } from 'types/network'
import { TTableColumnProperty } from 'types/ui/table'

export class SiteEntityService {
    protected _api = axiosClient()
    
    protected constructor(protected urlPrefix: string) {
        
    }

    public async getSiteEntityGroups(params: TGroupParams) {
        const response = await this._api.get<TGroupResponse>(`${this.urlPrefix}/groups/${params.groupBy}`, {
            params: {
                Descending: params.descending,
                Filters: params.filters,
                PageSize: params.pageSize,
                PageNumber: params.pageNumber
            }
        })
        return response.data
    }

    public async updateSiteEntity(id: number, labels: string[]) : Promise<void> {
        await this._api.post(`${this.urlPrefix}/Update/${id}`, { labels })
    }

    public async changePropertyValues(data: TChangePropertyValuesRequest) : Promise<void> {
        await this._api.post(`${this.urlPrefix}/ChangePropertyValues`, data)
    }

    public async changeAllPropertyValues(data: TChangePropertyValuesRequest) : Promise<void> {
        await this._api.post(`${this.urlPrefix}/All/ChangePropertyValues`, data)
    }

    public async getAllCommonProperties(data: TAllCommonPropertiesRequest) : Promise<TCommonProperties> {
        const response = await this._api.post<TCommonProperties>(`${this.urlPrefix}/All/CommonProperties`, {
            Filters: data.Filters
        })
        return response.data
    }

    public async getCommonProperties(ids: number[]) : Promise<TCommonProperties> {
        const response = await this._api.post<TCommonProperties>(`${this.urlPrefix}/CommonProperties`, {
            Ids: ids
        })
        return response.data
    }

    public async getSiteEntitiesProperties(): Promise<TTableColumnProperty[]> {
        const response = await this._api.get<TProperty[]>(`${this.urlPrefix}/Properties`)

        return response.data.map((property: TProperty) => ({
            type: property.propertyType,
            displayKey: property.displayKey,
            groupingKey: property.groupingKey,
            translationKey: property.translationKey
        }))
    }

    public async getSiteEntities(params: TFetchParamsRequest): Promise<{siteEntities: TSiteEntity[], total: number}> {
        const response = await this._api.get<{siteEntities: TSiteEntityResponse[], totalNumber: number}>(`${this.urlPrefix}`, {
            params: params
        })

        return {
            siteEntities: response.data.siteEntities.map(siteEntity => {
                return {
                    id: siteEntity.id,
                    labels: siteEntity.labels,
                    name: siteEntity.name,
                    siteName: siteEntity.siteName,
                    siteLabels: siteEntity.siteLabels,
                    propertyValues: SiteEntityService.transformPropertyValues(siteEntity.propertyValues)
                }
            }),
            total: response.data.totalNumber
        }
    }

    public async getSiteEntity(id: number): Promise<TSiteEntity> {
        const response = await this._api.get<TSiteEntity>(`${this.urlPrefix}/${id}`)
        return response.data
    }

    static transformPropertyValues(propertyValues: TPropertyValue[]): TAdvancedTablePropertyValue[] {
        return propertyValues.map(propertyValue => {
            return {
                name: propertyValue.name,
                propertyType: propertyValue.propertyType,
                longValue: propertyValue.longValue,
                doubleValue: propertyValue.doubleValue,
                boolValue: propertyValue.boolValue,
                stringValue: propertyValue.stringValue,
                dateTimeValue: propertyValue.dateTimeValue,
                collectionValue: propertyValue.collectionValue,
                groupName: propertyValue.groupName,
                readOnly: propertyValue.readOnly,
                translationKey: propertyValue.translationKey,
                restriction: propertyValue.restriction ?
                    {
                        restrictionType: propertyValue.restriction.restrictionType,
                        collection: propertyValue.restriction.collection,
                        min: propertyValue.restriction.min,
                        max: propertyValue.restriction.max
                    } :
                    undefined
            }
        })
    }
}