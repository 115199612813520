import { TChartEntry, ChartLegend, CustomPieChart, CardHeader, PieChartPositioner } from 'components/CustomControls/CustomCharts'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { EDataSyncItems, EPerformanceState } from 'enums/enums'
import useBackgroundService from 'hooks/useBackgroundService'
import { i18nKeys } from 'i18n/keys'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { getSiteServicesPerformance } from 'state/slices/statisticSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import colors from 'style/colors'
import { TOverallState } from 'types/businessLogic/performance'
import { uid } from 'uid'

const SiteServicesElement = () => {
    const siteServicesPerformance = useAppSelector(store => store.statistic.SiteServicesPerformance)
    const dispatch = useAppDispatch()
    const intl = useIntl()
    const [identifier] = useState(uid())
    
    useBackgroundService(EDataSyncItems.SITE_SERVICE_PERFORMANCE, () => dispatch(getSiteServicesPerformance()), identifier)

    const prepareData = (data: TOverallState): TChartEntry[] => {
        return [
            {
                id: 'HealthySiteServices',
                name: i18nKeys.umbrella_dashboard_running_site_services_healthy,
                value: data.healthy,
                color: colors.extra.SUCCESS,
                redirectTo: '/app/monitor/site-services/' + EPerformanceState.HEALTHY
            },
            {
                id: 'WarningSiteServices',
                name: i18nKeys.umbrella_dashboard_running_site_services_warning,
                value: data.warning,
                color: colors.extra.WARNING,
                redirectTo: '/app/monitor/site-services/' + EPerformanceState.WARNING
            },
            {
                id: 'ErrorSiteServices',
                name: i18nKeys.umbrella_dashboard_running_site_services_critical,
                value: data.critical,
                color: colors.extra.ALERT,
                redirectTo: '/app/monitor/site-services/' + EPerformanceState.CRITICAL
            },
            {
                id: 'OfflineSiteServices',
                name: i18nKeys.umbrella_dashboard_running_site_services_offline,
                value: data.offline,
                color: colors.shades.DARK_GRAY_SHADE,
                redirectTo: '/app/monitor/site-services/' + EPerformanceState.OFFLINE
            },
            {
                id: 'IgnoreSiteServices',
                name: i18nKeys.umbrella_dashboard_running_site_services_ignored,
                value: data.ignored,
                color: colors.brandColors.BLUE,
                redirectTo: '/app/monitor/site-services/' + EPerformanceState.IGNORED
            }
        ]
    }

    const chartData = prepareData(siteServicesPerformance)

    return (
        <>
            <CardHeader title={i18nKeys.umbrella_dashboard_running_site_services} />
            <CustomTooltip title={intl.formatMessage(i18nKeys.umbrella_dashboard_running_site_services)}>
                <>
                    <PieChartPositioner>
                        <CustomPieChart
                            tooltip={intl.formatMessage(i18nKeys.umbrella_dashboard_running_site_services)}
                            data={chartData}
                            chartInnerDesc={i18nKeys.umbrella_dashboard_running_site_services_total}
                            chartInnerNumber={(siteServicesPerformance.healthy + siteServicesPerformance.warning + siteServicesPerformance.critical + siteServicesPerformance.offline + siteServicesPerformance.ignored)}
                        />
                    </PieChartPositioner>
                    <ChartLegend data={chartData} />
                </>
            </CustomTooltip>
        </>
    )
}

export default SiteServicesElement