import { TChartEntry, ChartLegend, CustomPieChart, CardHeader, PieChartPositioner } from 'components/CustomControls/CustomCharts'
import useBackgroundService from 'hooks/useBackgroundService'
import { EDataSyncItems, ESiteState } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { getSiteStatesAsync } from 'state/slices/statisticSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import colors from 'style/colors'
import { TSiteStates } from 'types/statistic'
import { uid } from 'uid'

const SiteStatesElement = () => {
    const sites = useAppSelector(store => store.statistic.siteStates)
    const dispatch = useAppDispatch()
    const intl = useIntl()
    const [identifier] = useState(uid())
    
    useBackgroundService(EDataSyncItems.SITES, () => dispatch(getSiteStatesAsync()), identifier)

    const prepareNumber = (data: TSiteStates) => (
        data.gatewayHealthyCount + data.gatewayOfflineCount + data.gatewayUpdatingCount + data.gatewayVmsNotReachableCount + data.gatewayWarningCount + data.gatewayIgnoredCount
    )

    const prepareData = (data: TSiteStates): TChartEntry[] => {
        return [
            {
                id: 'HealthyGateway',
                name: i18nKeys.site_states_healthy,
                value: data.gatewayHealthyCount + data.gatewayUpdatingCount,
                color: colors.extra.SUCCESS,
                tooltip: intl.formatMessage(i18nKeys.site_states_healthy_tooltip, {
                    br: '\n',
                    countHealthy: data.gatewayHealthyCount,
                    countUpdating: data.gatewayUpdatingCount
                }),
                redirectTo : '/app/sites/' + ESiteState.HEALTHY
            },
            {
                id: 'WarningGateway',
                value: data.gatewayWarningCount,
                name: i18nKeys.site_states_warning,
                color: colors.extra.WARNING,
                redirectTo : '/app/sites/' + ESiteState.WARNING
            },
            {
                id: 'ErrorGateway',
                value: data.gatewayVmsNotReachableCount,
                name: i18nKeys.site_states_error,
                color: colors.extra.ALERT,
                redirectTo : '/app/sites/' + ESiteState.VMS_NOT_REACHABLE
            },
            {
                id: 'OfflineGateway',
                value: data.gatewayOfflineCount,
                name: i18nKeys.site_systems_chip_offline,
                color: colors.shades.DARK_GRAY_SHADE,
                redirectTo : '/app/sites/' + ESiteState.OFFLINE
            },
            {
                id: 'IgnoredGateway',
                value: data.gatewayIgnoredCount,
                name: i18nKeys.site_systems_chip_ignore,
                color: colors.brandColors.BLUE,
                redirectTo : '/app/sites/' + ESiteState.IGNORED
            }
        ]
    }

    const chartData = prepareData(sites)

    return (
        <>
            <CardHeader title={i18nKeys.site_states_heading} />
            <PieChartPositioner>
                <CustomPieChart
                    tooltip={intl.formatMessage(i18nKeys.site_state_chart_tooltip)}
                    data={chartData}
                    chartInnerDesc={i18nKeys.cameras_chart_total}
                    chartInnerNumber={prepareNumber(sites)}
                />
            </PieChartPositioner>
            <ChartLegend data={chartData} />
        </>
    )
}

export default SiteStatesElement
