import { CustomButton } from 'components/CustomControls/CustomButton'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { logoutAsync } from 'state/slices/authSlice'
import { dispatch } from 'state/store'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'

const Container = styled.div`
    padding: 40px;
`
const Description = styled.p`
    margin-bottom: 30px;
    font-family: ${fonts.PRIMARY_REGULAR};
    font-size: ${typographyScale.TYPE14};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE14LS};
`
const InvalidSessionModal = () => {
    const onClick = () => {
        dispatch(logoutAsync(true))
    }

    return (
        <Container>
            <Description>
                <TransMessage {...i18nKeys.invalid_session_text} />
            </Description>
            <CustomButton onClick={onClick}>
                <TransMessage {...i18nKeys.invalid_session_button_text} />
            </CustomButton>
        </Container>
    )
}

export default InvalidSessionModal