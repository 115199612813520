import { CustomModal } from 'components/CustomControls/CustomModal'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { loginAsync, loginWindowsAsync, logoutAsync } from 'state/slices/authSlice'
import { acceptEulaAsync, optionsAsync } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TLoginRequest } from 'types/auth'
import Login from 'views/Login/Login'
import EulaModal from 'views/Login/Modals/EulaModal'
import NoConnection from 'views/Login/NoConnection'

const LoginContainer = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { loginReqStatus, isAuthenticated } = useAppSelector(store => store.auth)
    const { version, isRememberMeAvailable, isAdLoginAvailable, isEulaAccepted, showEulaModal, language, fetched : uiInfosFetched } = useAppSelector(store => store.ui)
    
    useEffect(() => {
        if (isAuthenticated && isEulaAccepted) {
            navigate('/app/dashboard')
        }
    }, [isAuthenticated, isEulaAccepted, navigate])

    useEffect(() => {
        if (!uiInfosFetched) {
            dispatch(optionsAsync())
        }
    }, [dispatch, uiInfosFetched])

    const closeEULAModal = () => {
        dispatch(logoutAsync())
        //since the component does not refresh a manual options fetch is required
        dispatch(optionsAsync())
    }

    const renderModal = () => {
        const props = {onSubmit: () => {
            dispatch(acceptEulaAsync())
        }}

        if (isAuthenticated && showEulaModal) {
            return (
                <CustomModal
                    open={true}
                    onClose={closeEULAModal}
                    title={<TransMessage {...i18nKeys.umbrella_eula_title} />}
                    description={<TransMessage {...i18nKeys.umbrella_eula_text} />}
                >
                    <EulaModal language={language} onSubmit={props.onSubmit} />
                </CustomModal>
            )
        }
    }

    const handleLoginSubmit = (formValues: TLoginRequest) =>
        dispatch(loginAsync({...formValues}))

    const handleWindowsLogin = () =>
        dispatch(loginWindowsAsync())

    const collection = {
        status: loginReqStatus,
        isRememberMeAvailable,
        isAdLoginAvailable,
        version,
        handleLoginSubmit,
        handleWindowsLogin
    }

    if (isAuthenticated && isEulaAccepted) {
        return null
    }

    if (version === '-') {
        return <NoConnection />
    }

    return (
        <>
            <Login {...collection} />
            {renderModal()}
        </>
    )
}

export default LoginContainer