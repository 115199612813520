/* eslint-disable no-control-regex */

import { TIntlMessage } from 'components/TransMessage/TransMessage'
import { EPasswordValidationElements } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { FormState } from 'react-hook-form'
import LabelService from 'services/labelService'
import UmbrellaRolesService from 'services/umbrellaRolesService'
import UmbrellaUserService from 'services/umbrellaUserService'
import { TPasswordRequirements, TPasswordValidationResult } from 'types/businessLogic/validation'
import GeneralHelper from './GeneralHelper'

class Validation {
    static config = {
        MAX_PROFILE_IMAGE_FILE_SIZE: 5, // MB
        PORT_NUMBER_MIN: 1,
        PORT_NUMBER_MAX: 65535,
        NUMBER: /^\d+$/,
        SERVICE_NAME_REGEX: /^\w+$/,
        NO_WHITESPACE_START_END: /^[^\s]+(\s+[^\s]+)*$/,
        DIR_PATH: /^\s|["<>|*?\r\n\t\v\f\0]+|\s$/,
        CONTAINS_NUMBER: /\d/,
        CONTAINS_UPPERCASE: /[A-Z]/,
        CONTAINS_LOWERCASE: /[a-z]/,
        CONTAINS_NON_ALPHANUMERIC: /[^a-zA-Z\d\s:]/,
        // eslint-disable-next-line
        HOSTNAME: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/
    }

    static isValidDirPath(path?: string) {
        if (!path) return false
        return !new RegExp(Validation.config.DIR_PATH).test(path)
    }

    static isNumber = (value: string) => !isNaN(parseInt(value))

    static isValidPortNumber = (port?: number | string) : boolean => {
        if (!port) return false

        const portNumber = parseInt(port.toString())
        return portNumber >= Validation.config.PORT_NUMBER_MIN && portNumber <= Validation.config.PORT_NUMBER_MAX
    }

    static validateFileExt = (filename: string, acceptableFileExt: string[]) =>
        acceptableFileExt.some((ext: string) => filename.endsWith(ext))

    static isValidImage = (format: string) => {
        const validImageFormats = ['image/jpg', 'image/png', 'image/jpeg']
        return validImageFormats.some((validFormat: string) => validFormat === format)
    }

    static isEmpty = (value?: string) => value === '' || value === undefined || value === null

    static isValidImageSize = (sizeInBytes: number) =>
        (sizeInBytes / 1024 / 1024) < Validation.config.MAX_PROFILE_IMAGE_FILE_SIZE

    static checkPasswordLength = (value: string, passwordLength: number) => value.length >= passwordLength

    static containsNumber = (value: string) => RegExp(Validation.config.CONTAINS_NUMBER).test(value)

    static containsUppercase = (value: string) => RegExp(Validation.config.CONTAINS_UPPERCASE).test(value)

    static containsLowercase = (value: string) => RegExp(Validation.config.CONTAINS_LOWERCASE).test(value)

    static containsNonAlphanumeric = (value: string) => RegExp(Validation.config.CONTAINS_NON_ALPHANUMERIC).test(value)

    static checkUniqueCharacters = (value: string, uniqueNumber: number) => {
        const occurence: { [key: string]: number } = {}
        let character

        for (let i = 0; i < value.length; i++) {
            character = value.charAt(i)
            occurence[character] ? occurence[character]++ : occurence[character] = 1
        }

        return GeneralHelper.getObjectValues(occurence).filter((num: number) => num === 1).length >= uniqueNumber
    }

    static validatePassword = (value: string, requirements: TPasswordRequirements): TPasswordValidationResult => {
        const validationResult: TPasswordValidationResult = {}

        if (requirements.requiredLength) {
            validationResult[EPasswordValidationElements.REQUIRED_LENGTH] = {
                valid: Validation.checkPasswordLength(value, requirements.requiredLength),
                intlKey: {
                    ...i18nKeys.password_requirements_required_length,
                    values: {lengthNumber: requirements.requiredLength}
                }
            }
        }

        if (requirements.requireDigit) {
            validationResult[EPasswordValidationElements.REQUIRE_DIGIT] = {
                valid: Validation.containsNumber(value),
                intlKey: i18nKeys.password_requirements_require_digit
            }
        }

        if (requirements.requireUppercase) {
            validationResult[EPasswordValidationElements.REQUIRE_UPPERCASE] = {
                valid: Validation.containsUppercase(value),
                intlKey: i18nKeys.password_requirements_require_uppercase
            }
        }

        if (requirements.requireLowercase) {
            validationResult[EPasswordValidationElements.REQUIRE_LOWERCASE] = {
                valid: Validation.containsLowercase(value),
                intlKey: i18nKeys.password_requirements_require_lowercase
            }
        }

        if (requirements.requireNonAlphanumeric) {
            validationResult[EPasswordValidationElements.REQUIRE_NON_ALPHANUMERIC] = {
                valid: Validation.containsNonAlphanumeric(value),
                intlKey: i18nKeys.password_requirements_require_non_alphanumeric
            }
        }

        if (requirements.requiredUniqueChars) {
            validationResult[EPasswordValidationElements.REQUIRED_UNIQUE_CHARS] = {
                valid: Validation.checkUniqueCharacters(value, requirements.requiredUniqueChars),
                intlKey: {
                    ...i18nKeys.password_requirements_required_unique_characters,
                    values: {uniqueNumber: requirements.requiredUniqueChars}
                }
            }
        }

        return validationResult
    }

    static getPasswordValidationError = (password: string, passwordRequirements: TPasswordRequirements): TIntlMessage | undefined => {
        const validationResult = Validation.validatePassword(password, passwordRequirements)
        const errors = GeneralHelper.getObjectValues(validationResult)

        if (errors.length) {
            const error = errors.find(x => !x || !x.valid)
            
            if (error) return error.intlKey
        }
    }

    static isUmbrellaUserNameNotTaken = async (name?: string, defaultName?: string) => {
        if ((name && !defaultName) || (name && defaultName && name !== defaultName)) {
            const result = await UmbrellaUserService.userExists(name)
            return !result
        }
        return true
    }

    static isUmbrellaRoleNameNotTaken = async (name?: string, defaultName?: string) => {
        if ((name && !defaultName) || (name && defaultName && name !== defaultName)) {
            const result = await UmbrellaRolesService.roleExists(name)
            return !result
        }
        return true
    }

    static isLabelNameNotTaken = async (name?: string, defaultName?: string) => {
        if ((name && !defaultName) || (name && defaultName && name !== defaultName)) {
            const result = await LabelService.nameExists(name)
            return !result
        }
        return true
    }

    static isDynamicPropertiesFormStateValid = (formState : FormState<any>, ignoreComment: boolean = false) : boolean => {
        if (!formState.isValid) {
            return false
        }

        if (!formState.isDirty) {
            return false
        }

        if (formState.isValidating) {
            return false
        }

        if (formState.isSubmitting) {
            return false
        }

        if (ignoreComment) {
            const keys = Object.keys(formState.dirtyFields)
            return keys.includes('properties')
        }

        return true
    }
}

export default Validation