import { Paper } from '@mui/material'
import { TUseCardPage } from 'hooks/useCardPage'
import CustomTablePagination from 'components/Tables/CustomTablePagination'
import React from 'react'
import borderRadius from 'style/borderRadius'
import shadows from 'style/shadows'
import styled from 'styled-components'
import { TOverallState } from 'types/businessLogic/performance'
import SelectedStatesGroup from './SelectedStatesGroup'

const Wrapper = styled(Paper)`
    border-radius: ${borderRadius.SECONDARY};
    box-shadow: ${shadows.QUATRENARY};
    display: flex;
    flex-direction: column;
    max-height: 100%;
`

const StyledTableHead = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 8px;
    padding-right: 16px;
    border-bottom: 1px solid #C5C5C5;
`

const Content = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
`

type TProps = TUseCardPage & {
    totalCount: number
    overallState: TOverallState
    items?: any[]
    isParentChecked: boolean
    itemRender: (item: any, index: number, isParentChecked: boolean) => JSX.Element
    searchFilters: () => JSX.Element
    hideFooter?: boolean
}

const PagedCardList = (props: TProps) => {
    const { hideFooter, totalCount, overallState, items, fetchState, isParentChecked } = props
    const { itemRender, searchFilters, onStateFiltersChange, onChangePage, onChangeRowsPerPage } = props

    return (
        <Wrapper>
            <StyledTableHead>
                {
                    searchFilters()
                }
                <SelectedStatesGroup
                    onStateFiltersChange={onStateFiltersChange}
                    overallState={overallState}
                    preSelectedState={fetchState.filterByStates}
                />
            </StyledTableHead>

            <Content>
                {
                    items?.map((item, index) => itemRender(item, index, isParentChecked))
                }
            </Content>

            { !hideFooter && (
                <CustomTablePagination
                    count={totalCount}
                    page={fetchState.fetchParams.PageNumber}
                    rowsPerPage={fetchState.fetchParams.PageSize}
                    onChangeRowsPerPage={rowsPerPage => onChangeRowsPerPage(rowsPerPage)}
                    onChangePage={page => onChangePage(page)}
                />
            )}
            
        </Wrapper>
    )
}
export default PagedCardList