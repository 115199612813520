import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormCheckbox, FormContent, FormFooter, FormGroup, FormInput, FormLabel, FormWrapper, InputWrapper } from 'components/FormControls/FormControls'
import { FormDateTimePicker } from 'components/FormControls/FormDateTimePicker'
import { FormNumberInput } from 'components/FormControls/FormNumberInput'
import { FormSelect } from 'components/FormControls/FormSelect'
import { FormSlider } from 'components/FormControls/FormSlider'
import TransMessage from 'components/TransMessage/TransMessage'
import { ERestrctionType } from 'enums/enums'
import GeneralHelper from 'helpers/GeneralHelper'
import { i18nKeys } from 'i18n/keys'
import { useForm } from 'react-hook-form'
import { TAdvancedJobConfigFields, TPropertyValueForm } from 'types/ui/tab'
import { TSystemMessageFormInfo } from 'views/Sites/Modals/SystemMessages/TSystemMessageFormInfo'
import React, { useState } from 'react'
import AdvancedJobConfigFields from 'components/AdvancedJobConfigFields/AdvancedJobConfigFields'
import * as yup from 'yup'
import { useIntl } from 'react-intl'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppSelector } from 'state/store'

type Props = {
    formInfos: TSystemMessageFormInfo[]
    onSubmit?: (formInfoValues: TPropertyValueForm[], advancedJobConfig: TAdvancedJobConfigFields, comment: string) => void
}

type TFormInputs = {
    properties: TPropertyValueForm[]
    advancedJobConfig?: TAdvancedJobConfigFields
    comment: string
}

const SystemMessagesForm = (props: Props) => {
    const intl = useIntl()

    const isCommentMandatory = useAppSelector(state => state.ui.uiSettings.isCommentMandatoryForJobs)
    let propertyCounter = 0
    const validationSchema = (mandatoryComment: boolean) => yup.object().shape({
        comment: mandatoryComment ? yup.string().max(500).required(intl.formatMessage(i18nKeys.general_validation_required_field)) : yup.string().max(500)
    })
    
    const extractValues = (formInfos: TSystemMessageFormInfo[]) : TPropertyValueForm[] => {
        const result : TPropertyValueForm[] = []
        formInfos.forEach(item => {
            item.formValues.forEach(property => {
                property.groupedBy = item.name
                result.push(property)
            })
        })

        return result
    }

    const formProperties : TPropertyValueForm[] = extractValues(props.formInfos)
    const [initialValues] = useState<TPropertyValueForm[]>(formProperties)

    const { handleSubmit, formState, setValue, control, getValues, reset} = useForm<TFormInputs>({
        mode: 'onChange',
        defaultValues: {
            comment: '',
            properties: initialValues,
        },
        resolver: yupResolver(validationSchema(isCommentMandatory))
    })

    const handleReset = (name: string, value: any) => {
        const identifier = name.split('.')
        const val = {
            ...getValues(),
        } as any

        val[identifier[0]][identifier[1]][identifier[2]] = value

        reset(val)
    }

    const onSubmit = (values: TFormInputs) => {
        const updatedProperties = getUpdatedProperties(initialValues, values.properties)
        const advancedJobConfig = values.advancedJobConfig || {}
        const comment = values.comment || ''

        if (props.onSubmit) {
            props.onSubmit(updatedProperties, advancedJobConfig, comment)
        }
    }

    const getUpdatedProperties = (initialProperties: TPropertyValueForm[], updatedProperties: TPropertyValueForm[]) => {
        return updatedProperties.filter((prop: TPropertyValueForm, index: number) => {
            const initialProp = initialProperties[index]

            if (!initialProp) return false

            const key = GeneralHelper.getPropertyKey(initialProp.propertyType)
            
            if (prop[key] === null) return false

            if (key === 'stringValue' || key === 'collectionValue') {
                
                return JSON.stringify(initialProp[key]) !== JSON.stringify(prop[key])
            }
            return initialProp[key] !== prop[key]
        })
    }

    const renderFields = (items: TPropertyValueForm[]) => {
        return items.map((property, index) => {
            let content : JSX.Element = <></>

            if (property.restriction) {
                if (property.propertyType === GeneralHelper.propertyTypes.collection.index) {
                    content = <FormSelect disabled={property.readOnly} isMulti name={`properties.${propertyCounter}.collectionValue`} defaultValue={property.collectionValue} onReset={handleReset} control={control} options={property.restriction.collection} />
                } else {
                    switch (property.restriction.restrictionType) {
                        case ERestrctionType.COLLECTION:
                            
                            content = <FormSelect disabled={property.readOnly} name={`properties.${propertyCounter}.stringValue`} defaultValue={property.stringValue} onReset={handleReset} control={control} options={property.restriction.collection} />
                            break
                        case ERestrctionType.RANGE:
                            content = <FormSlider disabled={property.readOnly} onReset={handleReset} defaultValue={property.longValue} name={`properties.${propertyCounter}.longValue`} max={property.restriction.max} min={property.restriction.min} control={control} />
                            break
                    }
                }
            } else {
                switch (property.propertyType) {
                    case GeneralHelper.propertyTypes.bool.index:
                        content = <FormCheckbox disabled={property.readOnly} block name={`properties.${propertyCounter}.boolValue`} defaultValue={property.boolValue} onReset={handleReset} control={control} />
                        break
                    case GeneralHelper.propertyTypes.string.index:
                        content = <FormInput disabled={property.readOnly} name={`properties.${propertyCounter}.stringValue`} defaultValue={property.stringValue} onReset={handleReset} control={control} type='text' />
                        break
                    case GeneralHelper.propertyTypes.long.index:
                        content = <FormNumberInput disabled={property.readOnly} name={`properties.${propertyCounter}.longValue`} defaultValue={property.longValue} onReset={handleReset} control={control} />
                        break
                    case GeneralHelper.propertyTypes.dateTime.index:
                        content = <FormDateTimePicker disabled={property.readOnly} name={`properties.${propertyCounter}.dateTimeValue`} defaultValue={property.dateTimeValue} onReset={handleReset} control={control} />
                        break
                    case GeneralHelper.propertyTypes.collection.index:
                        const options = property.collectionValue ?? []
                        content = <FormSelect disabled={property.readOnly} isMulti name={`properties.${propertyCounter}.collectionValue`} defaultValue={property.collectionValue} onReset={handleReset} control={control} options={options} />
                        break
                }
            }

            return (
                <InputWrapper key={index}>
                    <FormLabel htmlFor={`properties.${propertyCounter++}`}>
                        <TransMessage {...i18nKeys[property.translationKey]} />
                    </FormLabel>
                    {content}
                </InputWrapper>
            )
        })
    }

    const renderEventGroup = () => {
        return props.formInfos.map((item, index) => {
            return (
                <FormGroup key={index} collapsable title={i18nKeys[item.name]}>
                    {renderFields(item.formValues)}
                </FormGroup>
            )
        })
    }

    return (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <FormContent>
                {renderEventGroup()}
                <FormGroup collapsable title={i18nKeys.advanced_job_configuration_title}>
                    <AdvancedJobConfigFields
                        formProps={formState}
                        setFieldValue={setValue}
                        control={control}
                    />
                </FormGroup>
                <FormGroup>
                    <InputWrapper>
                        <FormLabel htmlFor='comment'>
                            <TransMessage {...i18nKeys.job_configuration_comment} />
                        </FormLabel>
                        <FormInput
                            name='comment'
                            autoComplete='job-comment'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.system_messages_configuration_input_jobs_button} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}
export default SystemMessagesForm