import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { PropsWithChildren, useState} from 'react'
import ProfileService from 'services/profileService'
import { logoutAsync } from 'state/slices/authSlice'
import { setIsNavbarCollapsed } from 'state/slices/profileSlice'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TTranslationKey } from 'types/ui/translation'

import Layout from './Layout'

const LayoutContainer = (props: PropsWithChildren<unknown>) => {
    const dispatch = useAppDispatch()
    const {username, roles, lastLogin} = useAppSelector(store => store.profile)
    const licenseState = useAppSelector(store => store.license)
    const [refreshTimestamp, setRefreshTimestamp] = useState(new Date().getTime())
    
    if (!username) {
        return null
    }

    if (!licenseState.fetched) {
        return null
    }

    const handleLogout = () => {
        dispatch(logoutAsync())
    }

    const handleUploadImage = async (image: File) => {
        try {
            await ProfileService.uploadProfileImage(image)
            setRefreshTimestamp(new Date().getTime())
            dispatch(showMessage({
                message: i18nKeys.profile_drawer_upload_profile_image_success,
                type: EMessageType.SUCCESS
            }))
        } catch (err) {
            dispatch(showMessage({
                message: i18nKeys.profile_drawer_upload_profile_image_error,
                type: EMessageType.ERROR
            }))
        }
    }

    const handleOnError = (message: TTranslationKey) => {
        dispatch(showMessage({
            message: message,
            type: EMessageType.ERROR
        }))
    }

    const handleIsNavbarCollapsedChanged = (value: boolean) => {
        dispatch(setIsNavbarCollapsed(value))
    }

    return (
        <Layout
            refreshTimestamp={refreshTimestamp}
            onLogout={handleLogout}
            onUploadImage={handleUploadImage}
            onError={handleOnError}
            onIsNavbarCollapsedChanged={handleIsNavbarCollapsedChanged}
            username={username}
            roles={roles}
            lastLogin={lastLogin}
            license={licenseState}
        >
            {props.children}
        </Layout>
    )
}

export default LayoutContainer