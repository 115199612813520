import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import MapService from 'services/mapService'
import { showMessage } from 'state/slices/uiSlice'
import { TSiteMin } from 'types/businessLogic/site'

type TMapState = {
    lastUpdate: number
    markers: TSiteMin[]
}

const initialState: TMapState = {
    lastUpdate: 0,
    markers: []
}

export const getMapMarkerAsync = createAsyncThunk('map/getMapMarkerAsync', async (_, thunkApi) => {
    try {
        return await MapService.getMapMarker()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'map',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMapMarkerAsync.fulfilled, (state, action) => {
            if (JSON.stringify(state.markers) !== JSON.stringify(action.payload)) {
                state.markers = action.payload
                state.lastUpdate = Date.now()
            }
        })
    }
})

export default slice.reducer