import React from 'react'
import style from 'style'
import sizes from 'style/sizes'
import styled from 'styled-components'

const Root = styled.div`
    font-family: ${style.fonts.PRIMARY_REGULAR};
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 64px;
    z-index: 1;
    width: 100%;
`

export const ToolbarGroup = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`

const LeftContainer = styled.div`
    display: flex;
    align-items: center;
`

const RightContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: ${sizes.APPBAR_WITH_GRID_PADDING_RIGHT};
`

type TProps = {
    LeftContent?: JSX.Element
    RightContent?: JSX.Element
}

const Toolbar = (props: TProps) => {
    const { LeftContent, RightContent } = props
    return (
        <Root>
            <LeftContainer>
                {LeftContent}
            </LeftContainer>
            <RightContainer>
                {RightContent}
            </RightContainer>
        </Root>
    )
}
export default Toolbar