import { FormControl } from '@mui/material'
import { CustomInput } from 'components/CustomControls/CustomInput'
import { FormControlResetButton, StyledFormHelperText } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { useController, UseControllerProps } from 'react-hook-form'

type TFormInputProps = UseControllerProps<any> & {
    type?: 'text' | 'password'
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
    debug?: boolean
    placeHolder?: string
    disabled?: boolean
    autoComplete?: string
    onReset?: (name: string, value: any) => void
    min?: number
    max?: number
}
  
export const FormNumberInput = (props: TFormInputProps) => {
    const { field, fieldState } = useController(props)

    const handleReset = () => {
        props.onReset && props.onReset(field.name, props.defaultValue)
    }

    const handleOnBlur = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.currentTarget.value) {
            let value = Number(event.currentTarget.value)
            if (props.min) {
                value = Math.max(value, props.min)
            }
    
            if (props.max) {
                value = Math.min(props.max, value)
            }

            field.onChange(value)
        } else {
            field.onChange(props.defaultValue)
        }
    }

    return (
        <>
            <FormControl>
                <CustomInput
                    error={fieldState.invalid}
                    id={field.name}
                    name={field.name}
                    value={field.value}
                    inputRef={field.ref}
                    type='number'
                    label={props.placeHolder}
                    disabled={props.disabled}
                    startIcon={props.startIcon}
                    endIcon={props.endIcon}
                    autoComplete={props.autoComplete}
                    onChange={(event) => field.onChange(event.currentTarget.value)}
                    onBlur={handleOnBlur}
                />
                {
                    props.onReset && props.defaultValue !== field.value &&
                        <FormControlResetButton
                            onClick={handleReset}>
                            <TransMessage {...i18nKeys.properties_form_button_reset} />
                        </FormControlResetButton>
                }
                <StyledFormHelperText id={`${field.name}-helper`}>
                    <span hidden={!fieldState.invalid}>
                        {fieldState.error && fieldState.error.message}
                    </span>
                </StyledFormHelperText>
            </FormControl>
            { props.debug && <>
                <p>{fieldState.isTouched && 'Touched'}</p>
                <p>{fieldState.isDirty && 'Dirty'}</p>
                <p>{fieldState.invalid ? 'invalid' : 'valid'}</p>
            </>
            }
        </>
    )
}