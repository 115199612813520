import { Marker } from 'react-leaflet'
import { TSiteMin } from 'types/businessLogic/site'
import React, { PropsWithChildren } from 'react'
import {Icon as LeafletIcon} from 'leaflet'
import SiteHelper from 'helpers/SiteHelper'

const getIcon = (gateway: TSiteMin) => {
    const image = SiteHelper.getSiteMapMarker(gateway.state ?? -1)

    return new LeafletIcon({
        iconUrl: image,
        iconSize: [40, 40]
    })
}

type TProps = {
    gateway: TSiteMin
    onClick: () => void
}

const GatewayMarker = (props: PropsWithChildren<TProps>) => {
    const {children, gateway} = props
    
    if (gateway.longitude && gateway.latitude) {
        return (
            <Marker
                position={[gateway.latitude, gateway.longitude]}
                icon={getIcon(gateway)} {...props}
                eventHandlers={{
                    click: props.onClick
                }}
            >
                {children}
            </Marker>
        )
    }

    return null
}

export default GatewayMarker