import IntlProviderWrapper from 'components/Language/IntlProviderWrapper'
import React from 'react'
import RootRouter from 'router/RootRouter'
import colors from 'style/colors'
import type {} from '@mui/lab/themeAugmentation'
import {
    createTheme,
    CssBaseline,
    ThemeProvider
} from '@mui/material'

import MessageList from 'components/Message/MessageList'
import { ModalProvider } from 'hooks/modalProvider'

const theme = createTheme({
    components: {
    },
    typography: {
        fontFamily: ['LatoRegular', 'Roboto', 'Arial', 'sans-serif'].join(',')
    },
    palette: {
        primary: {
            main: colors.primary.DEFAULT_PRIMARY,
            light: colors.primary.LIGHT_PRIMARY,
            dark: colors.primary.DEFAULT_PRIMARY,
            contrastText: colors.shades.PURE_WHITE
        }
    }
})

const App = () => (
    <IntlProviderWrapper>
        <CssBaseline />
        <ThemeProvider theme={theme}>
            <ModalProvider>
                <RootRouter />
                <MessageList />
            </ModalProvider>
        </ThemeProvider>
    </IntlProviderWrapper>
)

export default App
