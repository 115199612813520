import { TIntlMessage } from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'

type JobsEnum = {
    name: string
    translation: TIntlMessage
}

class JobHelper {
    static jobsEnum: {[key: string]: JobsEnum} = {
        BatchJob: {
            name: 'BatchJob',
            translation:i18nKeys.site_job_batch_job
        },
        ChangeCameraPasswordJob: {
            name: 'ChangeCameraPasswordJob',
            translation:i18nKeys.site_job_change_camera_password
        },
        ChangeConnectionUserPasswordJob: {
            name: 'ChangeConnectionUserPasswordJob',
            translation:i18nKeys.site_job_change_connection_user_password
        },
        ChangeVMSUserPropertyJob: {
            name: 'ChangeVMSUserPropertyJob',
            translation:i18nKeys.site_job_change_vms_user_property
        },
        ChangeCameraPropertyJob: {
            name: 'ChangeCameraPropertyJob',
            translation:i18nKeys.site_job_change_vms_camera_property
        },
        ChangeVMSGroupPropertyJob: {
            name: 'ChangeVMSGroupPropertyJob',
            translation:i18nKeys.site_job_change_vms_group_property
        },
        ChangeVMSRuntimePropertyJob: {
            name: 'ChangeVMSRuntimePropertyJob',
            translation:i18nKeys.site_job_change_vms_runtime_property
        },
        ChangeVMSEventPropertyJob: {
            name: 'ChangeVMSEventPropertyJob',
            translation:i18nKeys.site_job_change_vms_event_property
        },
        ChangeVMSTimeProfilePropertyJob: {
            name: 'ChangeVMSTimeProfilePropertyJob',
            translation:i18nKeys.site_job_change_vms_timeprofile_property
        },
        ChangeVMSUserNameJob: {
            name: 'ChangeVMSUserNameJob',
            translation:i18nKeys.site_job_change_vms_user_name
        },
        ChangeVMSUserPasswordJob: {
            name: 'ChangeVMSUserPasswordJob',
            translation:i18nKeys.site_job_change_vms_user_password
        },
        CopyVMSUserJob: {
            name: 'CopyVMSUserJob',
            translation:i18nKeys.site_job_copy_vms_user
        },
        CreateVMSGroupJob: {
            name: 'CreateVMSGroupJob',
            translation:i18nKeys.site_job_create_vms_group
        },
        CreateVMSUserJob: {
            name: 'CreateVMSUserJob',
            translation:i18nKeys.site_job_create_vms_user
        },
        DeleteVMSGroupJob: {
            name: 'DeleteVMSGroupJob',
            translation:i18nKeys.site_job_delete_vms_group
        },
        DeleteVMSUserJob: {
            name: 'DeleteVMSUserJob',
            translation:i18nKeys.site_job_delete_vms_user
        },
        RestartGatewayJob: {
            name: 'RestartGatewayJob',
            translation:i18nKeys.site_job_restart_gateway
        },
        RestartVMSRuntimeJob: {
            name: 'RestartVMSRuntimeJob',
            translation:i18nKeys.site_job_restart_vms_runtime
        },
        StartVMSRuntimeJob: {
            name: 'StartVMSRuntimeJob',
            translation:i18nKeys.site_job_start_vms_runtime
        },
        StopVMSRuntimeJob: {
            name: 'StopVMSRuntimeJob',
            translation:i18nKeys.site_job_stop_vms_runtime
        },
        UpdateFirmwareCameraJob: {
            name: 'UpdateFirmwareCameraJob',
            translation:i18nKeys.site_job_update_firmware_camera
        },
        UpdateVMSLicenseJob: {
            name: 'UpdateVMSLicenseJob',
            translation:i18nKeys.site_job_update_vms_license
        },
        ChangeVMSEventConfigPropertyJob: {
            name: 'ChangeVMSEventConfigPropertyJob',
            translation:i18nKeys.site_job_change_vms_event_config_property
        }
    }
}

export default JobHelper