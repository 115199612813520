import React from 'react'
import CustomMap from 'components/CustomMap/CustomMap'
import MapLegend from 'components/CustomMap/MapLegend'
import styled from 'styled-components'

const LegendeWrapper = styled.div`
    z-index: 1000;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 600px;
`

type TProps = {
    onMarkerSelected?: (id: number) => void
}

const SitesMap = (props: TProps) => {
    return (
        <>
            <CustomMap onMarkerSelected={props.onMarkerSelected} />
            <LegendeWrapper>
                <MapLegend />
            </LegendeWrapper>
            
        </>
    )
}

export default SitesMap