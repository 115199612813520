import React from 'react'
import TransMessage from 'components/TransMessage/TransMessage'
import { ETableFilters } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'
import AppConstants from 'helpers/AppConstants'

const columnConfig: TTableColumnsConfig = {
    username: {
        key: 'UserName',
        translationKey: i18nKeys.umbrella_users_table_head_username.id,
        displayed: true,
        sortKey: 'UserName',
        filter: {
            key: 'UserName',
            type: ETableFilters.INPUT_TEXT
        }
    },
    builtIn: {
        key: 'BuiltIn',
        translationKey: i18nKeys.umbrella_users_table_head_built_in.id,
        displayed: true,
        sortKey: 'BuiltIn',
        groupKey: 'BuiltIn',
        filter: {
            key: 'BuiltIn',
            type: ETableFilters.DROPDOWN,
            dropdownItems: [
                {text: <TransMessage {...i18nKeys.dropdown_selection_none} />, value: ''},
                {text: <TransMessage {...i18nKeys.dropdown_selection_true} />, value: true},
                {text: <TransMessage {...i18nKeys.dropdown_selection_false} />, value: false}
            ]
        }
    },
    activeDirectory: {
        key: 'ActiveDirectory',
        translationKey: i18nKeys.umbrella_users_table_head_active_directory.id,
        displayed: true,
        sortKey: 'ActiveDirectory',
        groupKey: 'ActiveDirectory',
        filter: {
            key: 'ActiveDirectory',
            type: ETableFilters.DROPDOWN,
            dropdownItems: [
                {text: <TransMessage {...i18nKeys.dropdown_selection_none} />, value: ''},
                {text: <TransMessage {...i18nKeys.dropdown_selection_true} />, value: true},
                {text: <TransMessage {...i18nKeys.dropdown_selection_false} />, value: false}
            ]
        }
    },
    roles: {
        key: 'Roles',
        translationKey: i18nKeys.umbrella_users_table_head_roles.id,
        displayed: true
    },
    lastLogin: {
        key: 'LastLogin',
        translationKey: i18nKeys.umbrella_users_table_head_last_login.id,
        displayed: true,
        sortKey: 'LastLogin'
    }
}

const defaultLayout = [
    columnConfig.username.key,
    columnConfig.builtIn.key,
    columnConfig.activeDirectory.key,
    columnConfig.roles.key,
    columnConfig.lastLogin.key,
]

export const tableInfo: TTableInfo = {
    id: AppConstants.tableIds.UMBRELLA_USERS,
    columnConfig,
    defaultLayout
}