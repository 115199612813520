import {
    InputAdornment,
    InputLabel,
    OutlinedInput,
    OutlinedInputProps,
} from '@mui/material'
import Icon, { Icons } from 'components/Icon/Icon'
import React, { useState } from 'react'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import styled from 'styled-components'

type TStyledFormControlProps = {
  $startIcon?: boolean
  $small?: boolean
};

const StyledOutlinedInput = styled(OutlinedInput)<TStyledFormControlProps>`
    &:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${colors.primary.DEFAULT_PRIMARY};
    }

    &.Mui-focused:not(.Mui-error) {
        fill: ${colors.primary.DEFAULT_PRIMARY};
    }

    .MuiInputAdornment-positionEnd:hover {
        fill: ${colors.primary.DEFAULT_PRIMARY};
    }

    fill: ${colors.primary.LIGHT_PRIMARY};
    height: ${p => p.$small ? 32 : 56 }px;
    min-width: ${p => p.$small ? 220 : 380}px;
    width: 100%;
    padding: ${p => p.$small ? '0px 5px' : '0px 20px'};
    border-radius: ${p => p.$small ? borderRadius.QUATRENARY : borderRadius.SECONDARY};
    background-color: white;
    
    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }

    &.MuiInputBase-root.Mui-disabled {
        opacity: 0.4;
    }  

    .MuiOutlinedInput-input {
        color: #303030;
        height: calc(100% - 8px);
        padding: 0;
        font-size: 16px;
        font-family: LatoRegular;
        letter-spacing: 0.4px;

            &:disabled {
                opacity: 1;
            }
        }
`

const PasswordIcon = styled(Icon)`
    cursor: pointer;
`

const StyledInputLabel = styled(InputLabel)<{$startIcon?: boolean}>`
    font-family: LatoRegular;
    font-size: 16px;
    letter-spacing: 0.4px;

    &.Mui-focused {
      transform: translate(54px, 5px) scale(0.6);
    }

    &.MuiFormLabel-filled {
        transform: translate(54px, 5px) scale(0.6);
    }

    &:not(.Mui-focused):not(.MuiFormLabel-filled) {
      transform: ${(p) =>
        p.$startIcon ? 'translate(54px, 17px) scale(1)' : null};
    }
`

const StyledInputAdornment = styled(InputAdornment)`
    &.MuiInputAdornment-positionStart {
        margin-right: 0px;
    }
`

type TCustomInputProps = OutlinedInputProps & {
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  small?: boolean
};

const Input = (props: TCustomInputProps) => {
    const [showPassword, setShowPassword] = useState(false)
    const {startIcon, endIcon, label, small, id, type, ...rest} = props
    
    const renderEndAdornment = () => {
        if (type === 'password') {
            return (
                <InputAdornment
                    position='end'
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {showPassword ? (
                        <PasswordIcon
                            d={Icons.EYE_HIDE}
                            size={18}
                        />
                    ) : (
                        <PasswordIcon
                            d={Icons.EYE_SHOW}
                            size={18}
                        />
                    )}
                </InputAdornment>
            )
        } else {
            return <InputAdornment position='end'>{endIcon ?? <></>}</InputAdornment>
        }
    }

    return (
        <>
            {label && (
                <StyledInputLabel $startIcon={!!startIcon} variant='filled' htmlFor={id}>
                    {label}
                </StyledInputLabel>
            )}
            <StyledOutlinedInput
                $startIcon={!!startIcon}
                $small={small}
                {...rest}
                id={id}
                name={id}
                type={showPassword ? 'text' : type}
                startAdornment={<StyledInputAdornment position='start'>{startIcon ?? <></>}</StyledInputAdornment>}
                endAdornment={renderEndAdornment()}
            />
        </>
    )
}

export const CustomInput = React.forwardRef(
    (props: TCustomInputProps, ref: React.Ref<any>) => (
        <Input {...props} inputRef={ref} />
    )
)
