import avatarPlaceholderXL from 'assets/images/avatar-placeholder-xl.svg'
import avatarPlaceholderXS from 'assets/images/avatar-placeholder-xs.svg'
import dashboardLayout1 from 'assets/images/dashboard/layout-01.png'
import dashboardLayout2 from 'assets/images/dashboard/layout-02.png'
import dashboardLayout3 from 'assets/images/dashboard/layout-03.png'
import dashboardLayout4 from 'assets/images/dashboard/layout-04.png'
import dashboardLayout5 from 'assets/images/dashboard/layout-05.png'
import dashboardLayout6 from 'assets/images/dashboard/layout-06.png'
import CoreCPU from 'assets/images/dashboard/placeholders/CoreCPU.png'
import CoreMemory from 'assets/images/dashboard/placeholders/CoreMemory.png'
import CoreRequestCount from 'assets/images/dashboard/placeholders/CoreRequestCount.png'
import CoreRequestDuration from 'assets/images/dashboard/placeholders/CoreRequestDuration.png'
import Map from 'assets/images/dashboard/placeholders/Map.png'
import MostCriticalStates from 'assets/images/dashboard/placeholders/MostCriticalSites.png'
import RecentJobs from 'assets/images/dashboard/placeholders/RecentJobs.png'
import RecentSiteEvents from 'assets/images/dashboard/placeholders/RecentSiteEvents.png'
import RunningCameras from 'assets/images/dashboard/placeholders/RunningCameras.png'
import RunningSiteServices from 'assets/images/dashboard/placeholders/RunningSiteServices.png'
import SiteApprovals from 'assets/images/dashboard/placeholders/SiteApprovals.png'
import SiteStates from 'assets/images/dashboard/placeholders/SiteStates.png'
import selectedIcon from 'assets/images/dashboard/selected.png'
import logoSmall from 'assets/images/logo-small.png'
import logo from 'assets/images/logo.png'
import markerBlack from 'assets/images/markers/marker_black.svg'
import markerBlue from 'assets/images/markers/marker_blue.svg'
import markerGreen from 'assets/images/markers/marker_green.svg'
import markerGray from 'assets/images/markers/marker_gray.svg'
import markerOrange from 'assets/images/markers/marker_orange.svg'
import markerRed from 'assets/images/markers/marker_red.svg'
import qognifyLogo from 'assets/images/qognify-logo.png'
import logoGreen from 'assets/images/umbrella-logo-green.png'
import SiteSystem from 'assets/images/dashboard/placeholders/SiteSystems.png'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    logo,
    logoSmall,
    logoGreen,
    qognifyLogo,
    avatarPlaceholderXS,
    avatarPlaceholderXL,
    markerGreen,
    markerGray,
    markerOrange,
    markerRed,
    markerBlack,
    markerBlue,
    dashboardLayout1,
    dashboardLayout2,
    dashboardLayout3,
    dashboardLayout4,
    dashboardLayout5,
    dashboardLayout6,
    selectedIcon,
    SiteStates,
    CoreCPU,
    Map,
    RecentJobs,
    RunningCameras,
    SiteApprovals,
    CoreMemory,
    CoreRequestCount,
    CoreRequestDuration,
    RunningSiteServices,
    RecentSiteEvents,
    MostCriticalStates,
    SiteSystem: SiteSystem
}