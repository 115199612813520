import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { siteServiceService } from 'services/siteServiceService'
import { showMessage } from 'state/slices/uiSlice'
import { TSiteEntity, TSiteEntityUpdate } from 'types/businessLogic/site'
import { TFetchParamsRequest } from 'types/network'
import { TTableGroup, TTableColumnProperty } from 'types/ui/table'

export type TSiteServiceState = {
    groups: TTableGroup<TSiteEntity>[]
    siteServices: TSiteEntity[]
    totalNumber: number
    properties: TTableColumnProperty[]
}

const initialState : TSiteServiceState = {
    groups: [],
    siteServices: [],
    totalNumber: 0,
    properties: []
}

export const getSiteServicesAsync = createAsyncThunk('siteServices/getSiteServicesAsync', async (params: TFetchParamsRequest, thunkApi) => {
    try {
        return await siteServiceService.getSiteEntities(params)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getSiteServicesPropertiesAsync = createAsyncThunk('siteServices/getSiteServicesPropertiesAsync', async (_, thunkApi) => {
    try {
        return await siteServiceService.getSiteEntitiesProperties()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getGroupDataAsync = createAsyncThunk('siteServices/getGroupDataAsync', async (params: TFetchParamsRequest & { translationKey?: string}, thunkApi) : Promise<TTableGroup<TSiteEntity>> => {
    try {
        const result = await siteServiceService.getSiteEntities(params)
        return {
            name: params.GroupName!,
            translationKey: params.translationKey,
            count: result.total,
            data: result.siteEntities
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const updateSiteServiceAsync = createAsyncThunk('siteServices/updateSiteServiceAsync', async (params: TSiteEntityUpdate, thunkApi) => {
    try {
        await siteServiceService.updateSiteEntity(params.id, params.labels)
        thunkApi.dispatch(showMessage({message: i18nKeys.siteService_details_update_success_message, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.siteService_details_update_error_message, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'siteServices',
    initialState: initialState,
    reducers: {
        clearGroups: (state, action : PayloadAction<{ totalNumber: number, groups: TTableGroup<TSiteEntity>[] }>) => {
            action.payload.groups.forEach(item => {
                const oldItem = state.groups.find(x => x.name === item.name)
                if (oldItem) {
                    item.data = oldItem.data
                }
            })

            state.groups = action.payload.groups
            state.totalNumber = action.payload.totalNumber
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSiteServicesAsync.fulfilled, (state, action) => {
                const { siteEntities, total } = action.payload
                state.siteServices = siteEntities
                state.totalNumber = total
            })
            .addCase(getSiteServicesPropertiesAsync.fulfilled, (state, action) => {
                state.properties = action.payload
            })
            .addCase(getGroupDataAsync.fulfilled, (state, action) => {
                const existingGroup = state.groups.find(p => p.name === action.payload.name)
                if (existingGroup) {
                    existingGroup.data = action.payload.data
                    existingGroup.count = action.payload.count
                } else {
                    state.groups.push(action.payload)
                }

                action.payload.data?.forEach(x => {
                    const index = state.siteServices.findIndex(y => y.id === x.id)
                    if (index === -1) {
                        state.siteServices.push(x)
                    } else {
                        state.siteServices[index] = x
                    }
                })
            })
    }
})

export const {clearGroups} = slice.actions

export default slice.reducer