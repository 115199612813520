import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormWrapper, FormContent, FormButtonGroup, FormFooter, Instructions } from 'components/FormControls/FormControls'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import TransMessage from 'components/TransMessage/TransMessage'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { PropsWithChildren, useCallback } from 'react'
import { useIntl } from 'react-intl'
import SiteService from 'services/siteService'
import { showMessage } from 'state/slices/uiSlice'
import { pushItem, setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import styled from 'styled-components'
import { TCheckLicense } from 'types/businessLogic/site'
import { tableInfo } from 'views/Control/SiteLicenses/Modals/UpdateSiteLicense/siteLicensesTableColumns'
import SiteLicensesTablePreparer from 'views/Control/SiteLicenses/Modals/UpdateSiteLicense/siteLicensesTablePreparer'
import { useAppDispatch } from 'state/store'

const ViewWrapper = styled.div`
    padding-top: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
`

type TProps = {
    license: File
    sites: TCheckLicense[]
    handleClose: () => void
}

const ReviewSiteLicenses = (props: PropsWithChildren<TProps>) => {
    const {sites, license} = props
    const {handleClose} = props

    const dispatch = useAppDispatch()
    const intl = useIntl()

    const colHandlerProps = useColumnsHandler(tableInfo)

    const enhanceTableProps = useEnhanceTable({})

    const data = SiteLicensesTablePreparer.prepareSiteLicensesRows(sites)

    const handleLicenseUpdate = useCallback(async (event: React.MouseEvent) : Promise<void> => {
        event.preventDefault()

        const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_license_upload,
            {count: sites.length})
                
        const uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

        try {
            dispatch(pushItem(uploadManagerItem))
            await SiteService.uploadSiteLicense(license)
            dispatch(showMessage({message: i18nKeys.site_license_update_success_message, type: EMessageType.SUCCESS}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.site_license_update_error_message, type: EMessageType.ERROR}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id, isError: true}))
        }
        
        handleClose()
    }, [dispatch, handleClose, intl, license, sites.length])

    return (
        <FormWrapper>
            {props.children}
            <FormContent>
                <ViewWrapper>
                    <AdvancedTable
                        {...colHandlerProps}
                        {...enhanceTableProps}
                        count={sites.length}
                        disabledCheckboxes={true}
                        disableSelection={true}
                        disableBackground={true}
                        disableSelectionForIds={[]}
                        rows={data}
                    />
                </ViewWrapper>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        id='submitBtn'
                        type='submit'
                        onClick={handleLicenseUpdate}
                    >
                        <TransMessage {...i18nKeys.site_license_update_review_submit} />
                    </CustomButton>
                    <CustomButton
                        id='cancelEditCommentButton'
                        type='reset'
                        onClick={handleClose}
                        $secondary
                    >
                        <TransMessage {...i18nKeys.site_license_update_review_cancel} />
                    </CustomButton>
                </FormButtonGroup>
                <Instructions>
                    <TransMessage {...i18nKeys.site_license_update_review_job_info} />
                </Instructions>
            </FormFooter>
        </FormWrapper>
    )
}

export default ReviewSiteLicenses