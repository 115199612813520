import { selectionBoxOptions } from 'components/Tables/SelectionBox'
import TableHelper from 'components/Tables/TableHelper'
import React, { useState } from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TTableFetchState, TQueryFilter, TGroup } from 'types/network'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { debounce, SelectChangeEvent } from '@mui/material'
import appConstants from 'helpers/AppConstants'

export type TPagination = {
    page: number
    size: number
}

export type TUseEnhanceTableProps<TExtendedTableRowData extends TTableRowData> = {
    tableState?: Partial<TTableState<TExtendedTableRowData>>
    fetchState?: Partial<TTableFetchState>
    allowOnlyOneSelected?: boolean
    selectedRowsChangeCallBack?: (rows: TExtendedTableRowData[]) => void
    groups?: TTableGroup<TExtendedTableRowData>[]
    preselectedFilter? : string
    grouedView?: boolean
}

export type TUseEnhanceTable<TExtendedTableRowData extends TTableRowData> = {
    tableState: TTableState<TExtendedTableRowData>
    fetchState: TTableFetchState
    selectedGroups: TGroup[]
    deselectAllRows: () => void
    onAllRowsSelect: (rows: TExtendedTableRowData[], checked: boolean) => void
    getSelectedIds: () => (number | string)[]
    selectedRows: TExtendedTableRowData[]
    selectionCount: number
    isGroupedView: boolean
    selectAll: boolean
    setSelectAll: (selectAll: boolean) => void
    getSelectAllFilters: () => TQueryFilter[] | undefined
    switchSelectionMode: (event: SelectChangeEvent<unknown>, rows: TExtendedTableRowData[]) => void
    setGroupedView: (value: boolean) => void
    onFilter: (key: string, value: string) => void
    onGroup: (groupBy: string, groupByIntlKey: string) => void
    onRowsSelect: (entries: TExtendedTableRowData[], checked: boolean) => void
    onSort: (sortBy: string) => void

    onChangePage: (page: number, groupName?: string) => void
    onChangeRowsPerPage: (rowsPerPage: number, groupName?: string) => void
    onOpenGroupChanged: (group: TGroup) => void
}

export const paginationConfig: TPaginationConfig = {
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    firstPageIndex: 1
}

type TPaginationConfig = {
    rowsPerPage: number
    rowsPerPageOptions: number[]
    firstPageIndex: number
}

export type TTableState<TExtendedTableRowData extends TTableRowData> = {
    groupByIntlKey?: string
    orderIndex: number
    selectedRows: TExtendedTableRowData[]
    disabled: boolean

}

const useEnhanceTable = <TExtendedTableRowData extends TTableRowData>(props: TUseEnhanceTableProps<TExtendedTableRowData>) : TUseEnhanceTable<TExtendedTableRowData> => {
    const [tableState, setTableState] = useState<TTableState<TExtendedTableRowData>>({
        orderIndex: 0,
        selectedRows: new Array<TExtendedTableRowData>(),
        disabled: false,
        ...props.tableState
    })

    const [fetchState, setFetchState] = useState<TTableFetchState>({
        global: {
            filters: props.preselectedFilter ? { State: props.preselectedFilter } : {},
            order: appConstants.sort.NONE,
            page: paginationConfig.firstPageIndex,
            size: paginationConfig.rowsPerPage
        },
        groups: {},
        ...props.fetchState,
        groupedView: props.grouedView ?? false
    })

    const [selectAll, setSelectAll] = useState<boolean>(false)
    const [selectedGroups, setSelectedGroups] = useState<TGroup[]>([])
    
    const setGroupedView = (value: boolean) => {
        setFetchState(prevState => ({
            ...prevState,
            groupedView: value
        }))
    }

    const getSelectedIds = () : (number | string)[] => {
        if (selectAll) return []
        return tableState.selectedRows.map(row => row.id)
    }

    const getSelectAllFilters = () : TQueryFilter[] | undefined => {
        if (!selectAll || !fetchState.global.filters) return

        const filters = {...fetchState.global.filters}

        return Object.keys(filters).reduce((acc: TQueryFilter[], key: string) =>
            filters[key] === '' ? acc : [...acc, {
                property: key,
                value: filters[key],
                isExact: false
            }], [])
    }

    const deselectAllRows = () => {
        setSelectAll(false)
        setTableState(prevState => ({
            ...prevState,
            selectedRows: []
        }))
    }

    const onRowsSelect = (entries: TExtendedTableRowData[], checked: boolean) => {
        let selectedRows: TExtendedTableRowData[] = []

        if (entries.length > 1) {
            setTableState(prevState => ({
                ...prevState,
                selectedRows: checked ? entries : []
            }))
            return
        }

        if (props.allowOnlyOneSelected) {
            selectedRows = [entries[0]]
        } else {
            selectedRows = [...tableState.selectedRows]

            if (checked) {
                selectedRows = [...selectedRows, ...entries]
            } else {
                selectedRows = selectedRows.filter(row => entries.some(entry => entry.id !== row.id))
            }
        }

        setTableState(prevState => ({
            ...prevState,
            selectedRows
        }))

        props.selectedRowsChangeCallBack && props.selectedRowsChangeCallBack(selectedRows)
    }

    const onFilter = debounce((key: string, value: string) : void => {
        const filters = {
            ...fetchState.global.filters,
            [key]: value
        }

        if (value === '') delete filters[key]

        setFetchState(prevState => ({
            ...prevState,
            global: {
                ...prevState.global,
                filters
            },
            groupName: undefined
        }))
    }, 300)

    const onSort = (sortBy: string) => {
        const sortOrder = [appConstants.sort.ASCENDING, appConstants.sort.DESCENDING, appConstants.sort.NONE]
        const orderIndex = sortBy === fetchState.global.sortBy ? (tableState.orderIndex + 1) % sortOrder.length : 0
        const sortByKey = orderIndex !== sortOrder.length - 1 ? sortBy : undefined
        
        setTableState(prevState => ({
            ...prevState,
            orderIndex
        }))

        setFetchState(prevState => ({
            ...prevState,
            global: {
                ...prevState.global,
                sortBy: sortByKey,
                order: sortOrder[orderIndex],
                descending: GeneralHelper.isSortDescending(sortOrder[orderIndex]),
            },
            groupName: undefined
        }))
    }

    const onGroup = (groupBy: string, groupByIntlKey: string) => {
        setTableState(prevState => ({
            ...prevState,
            groupByIntlKey: prevState.groupByIntlKey && prevState.groupByIntlKey === groupByIntlKey ? undefined : groupByIntlKey
        }))

        setFetchState(prevState => ({
            ...prevState,
            global: {
                ...prevState.global,
                page: paginationConfig.firstPageIndex,
                groupBy: prevState.global.groupBy && prevState.global.groupBy === groupBy ? undefined : groupBy
            },
            groups: {},
            groupName: undefined
        }))

        setSelectedGroups([])
    }

    const onChangePage = (page: number, groupName?: string) => {
        page++

        if (!groupName || groupName === '') {
            setFetchState(prevState => ({
                ...prevState,
                groupName: undefined,
                global: {
                    ...prevState.global,
                    page
                }
            }))
        } else {
            setFetchState(prevState => ({
                ...prevState,
                groupName,
                groups: {
                    ...prevState.groups,
                    [groupName]: {
                        page,
                        size: prevState.groups[groupName] ? prevState.groups[groupName].size : paginationConfig.rowsPerPage
                    }
                }
            }))
        }
    }

    const onChangeRowsPerPage = (rowsPerPage: number, groupName?: string) => {
        if (!groupName || groupName === '') {
            setFetchState(prevState => ({
                ...prevState,
                groupName: undefined,
                global: {
                    ...prevState.global,
                    size: rowsPerPage
                }
            }))
        } else {
            setFetchState(prevState => ({
                ...prevState,
                groupName,
                groups: {
                    ...prevState.groups,
                    [groupName]: {
                        page: prevState.groups[groupName] ? prevState.groups[groupName].page : paginationConfig.firstPageIndex,
                        size: rowsPerPage
                    }
                }
            }))
        }
    }

    const onAllRowsSelect = (rows: TExtendedTableRowData[], checked: boolean) => {
        if (fetchState.global.groupBy) {
            const data = TableHelper.getEntitiesFromGroups<TExtendedTableRowData>(props.groups ?? [])
            onRowsSelect(data, checked)
        } else if (selectAll) {
            onRowsSelect([], checked)
        } else {
            onRowsSelect(rows, checked)
        }
    }

    const switchSelectionMode = (event: SelectChangeEvent<unknown>, rows: TExtendedTableRowData[]) => {
        const isSelectOverAllPagesActive = event.target.value === selectionBoxOptions.SELECT_ALL.value

        setSelectAll(isSelectOverAllPagesActive)
        onAllRowsSelect(rows, isSelectOverAllPagesActive)

    }

    const onOpenGroupChanged = (group: TGroup) => {
        const index = selectedGroups.findIndex(x => x.name === group.name)
        if (index !== -1) {
            selectedGroups.splice(index, 1)
            setSelectedGroups(selectedGroups)
        } else {
            setSelectedGroups([...selectedGroups, group])
        }
    }

    return {
        tableState,
        fetchState: fetchState,
        selectedGroups,
        selectedRows: Object.values(tableState.selectedRows),
        selectionCount: tableState.selectedRows.length,
        selectAll,
        isGroupedView: fetchState.groupedView!,

        setGroupedView,
        setSelectAll,
        getSelectAllFilters,
        onAllRowsSelect,
        deselectAllRows,
        getSelectedIds,
        switchSelectionMode,

        onFilter,
        onGroup,
        onRowsSelect,
        onSort,
        onChangePage,
        onChangeRowsPerPage,
        onOpenGroupChanged
    }
}

export default useEnhanceTable