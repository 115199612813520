import CircularLoader from 'components/CircularLoader/CircularLoader'
import EnhancedTabs from 'components/Tabs/EnhancedTabs'
import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType, EVMSEntityType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useState, useEffect } from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { siteCameraService } from 'services/siteCameraService'
import { SiteEntityService } from 'services/siteEntityService'
import { siteGroupService } from 'services/siteGroupService'
import { siteServiceService } from 'services/siteServiceService'
import { siteUserService } from 'services/siteUserService'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'
import { TAllCommonPropertiesRequest, TChangePropertyValuesRequest } from 'types/businessLogic/job'
import { TPropertyValue } from 'types/businessLogic/site'
import { TModalProps } from 'types/ui/modal'
import { TAdvancedJobConfigFields } from 'types/ui/tab'
import { TTranslationKey } from 'types/ui/translation'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { pushItem, setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import { TUploadManagerItem } from 'types/ui/uploadManager'
import { useIntl } from 'react-intl'
import { useModal } from 'hooks/modalProvider'

type TProps = TModalProps & {
    subtitleKey: TTranslationKey
    successKey: TTranslationKey
    errorKey: TTranslationKey
    uploadManagerKey: TTranslationKey
    entityType: EVMSEntityType
    totalCount: number
}

export type FormValues = {
    properties: TPropertyValue[]
}

const Heading = styled.h1`
    color: ${colors.primary.DARK_PRIMARY};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    font-family: ${fonts.PRIMARY_BOLD};
    font-size: ${typographyScale.TYPE24};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE16LS};
`

const setEntityService = (entityType: EVMSEntityType) : SiteEntityService => {
    switch (entityType) {
        case EVMSEntityType.VMS_GROUP:
            return siteGroupService
        case EVMSEntityType.VMS_USER:
            return siteUserService
        case EVMSEntityType.VMS_RUNTIME:
            return siteServiceService
        case EVMSEntityType.CAMERA:
        default:
            return siteCameraService
    }
}

const setUploadTranslationKeyForEntityType = (entityType: EVMSEntityType) : TTranslationKey => {
    switch (entityType) {
        case EVMSEntityType.VMS_GROUP:
            return i18nKeys.upload_manager_job_async_site_group_change_properties
        case EVMSEntityType.VMS_USER:
            return i18nKeys.upload_manager_job_async_site_users_properties
        case EVMSEntityType.VMS_RUNTIME:
            return i18nKeys.upload_manager_job_async_site_services_change_properties
        case EVMSEntityType.CAMERA:
        default:
            return i18nKeys.upload_manager_job_async_camera_change_properties
    }
}

const CommonPropertiesModal = (props: TProps) => {
    const { entityType, ids, filters, errorKey, successKey, totalCount } = props
    const selectAll = !!filters

    const intl = useIntl()
    const {closeModal} = useModal()
    const dispatch = useAppDispatch()
    const [properties, setProperties] = useState<TPropertyValue[]>([])
    const [propertiesFetched, setPropertiesFetched] = useState(false)
    const [entityService] = useState<SiteEntityService>(setEntityService(entityType))

    useEffect(() => {
        const fetchProperties = async () => {
            if (selectAll) {
                const request : TAllCommonPropertiesRequest = {
                    Filters: filters
                }
                const commonProperties = await entityService.getAllCommonProperties(request)
                setProperties(commonProperties.propertyValues)
            } else {
                const commonProperties = await entityService.getCommonProperties(ids as number[])
                setProperties(commonProperties.propertyValues)
            }

            setPropertiesFetched(true)
        }

        fetchProperties()
    }, [entityService, filters, ids, selectAll])

    const onSubmit = async (values: TPropertyValue[], advancedJobConfig: TAdvancedJobConfigFields, comment: string) => {
        let uploadManagerItem : TUploadManagerItem

        try {
            if (selectAll) {
                const numberofJobs = totalCount * values.length

                const uploadManagerItemText = intl.formatMessage(setUploadTranslationKeyForEntityType(entityType),
                    {count: advancedJobConfig.undoTimeEnabled ? numberofJobs * 2 : numberofJobs})
                        
                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const p: TChangePropertyValuesRequest = {
                    changePropertyValues: GeneralHelper.transformPropertyValues([], values),
                    comment,
                    filters,
                    ...advancedJobConfig
                }
    
                dispatch(pushItem(uploadManagerItem))
                await entityService.changeAllPropertyValues(p)
            } else {
                const numberofJobs = (ids as number[]).length * values.length

                const uploadManagerItemText = intl.formatMessage(setUploadTranslationKeyForEntityType(entityType),
                    {count: advancedJobConfig.undoTimeEnabled ? numberofJobs * 2 : numberofJobs})
                    
                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const p: TChangePropertyValuesRequest = {
                    changePropertyValues: GeneralHelper.transformPropertyValues(ids as number[], values),
                    comment,
                    ...advancedJobConfig
                }
                
                dispatch(pushItem(uploadManagerItem))
                await entityService.changePropertyValues(p)
            }

            closeModal()
            dispatch(showMessage({message: successKey, type: EMessageType.SUCCESS}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id}))
        } catch {
            dispatch(showMessage({message: errorKey, type: EMessageType.ERROR}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem!.id, isError: true}))
        }
    }

    if (!propertiesFetched) {
        return (
            <Heading>
                <CircularLoader size={200} color={colors.primary.DEFAULT_PRIMARY} />
            </Heading>
        )
    }
    if (!properties.length) {
        return (
            <Heading>
                <TransMessage {...i18nKeys.change_properties_multi_no_common_properties} />
            </Heading>
        )
    }

    return (
        <EnhancedTabs
            numOfEntities={ids.length}
            subtitleKey={props.subtitleKey}
            propertyValues={properties}
            onSubmit={onSubmit}
            tabButtons={[]}
            tabContent={[]}
            readOnlyForm={false}
            isChangeMultipleEntityProps
        />
    )
}

export default CommonPropertiesModal