import colors from 'style/colors'
import fonts from 'style/fonts'
import styled from 'styled-components'

const CustomLink = styled.div`
    font-family: ${fonts.PRIMARY_BOLD};
    color: ${colors.primary.DARK_PRIMARY};
    text-decoration: underline;
    cursor: pointer;

    :hover {
        color: ${colors.primary.DEFAULT_PRIMARY}
    }
`

export default CustomLink