import GeneralHelper from 'helpers/GeneralHelper'
import { TTableRowData } from 'types/ui/table'
import {tableInfo} from './ApiKeysTableColumns'
import React from 'react'
import { TApiKey } from 'types/businessLogic/misc'

class ApiKeysTablePreparer {
    public static prepareApiKeyRows = (apiKeys: TApiKey[]) : TTableRowData[] =>
        apiKeys.map(apiKey => ({
            ...apiKey,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.apiKey.key,
                    translationKey: tableInfo.columnConfig.apiKey.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: apiKey.apiKey
                },
                {
                    name: tableInfo.columnConfig.creator.key,
                    translationKey: tableInfo.columnConfig.creator.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: apiKey.creator
                },
                {
                    name: tableInfo.columnConfig.creationDate.key,
                    translationKey: tableInfo.columnConfig.creationDate.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.dateString.index,
                    [GeneralHelper.propertyTypes.dateString.key]: apiKey.creationDate
                }
            ]
        }))
}

export default ApiKeysTablePreparer