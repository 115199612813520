import { useModal } from 'hooks/modalProvider'
import { ELicenseTypes } from 'enums/ELicenseTypes'
import { EMessageType, EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import useLicense from 'hooks/useLicense'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import SiteService from 'services/siteService'
import SiteDetails from 'views/Sites/SiteDetails/SiteDetails'
import SiteMap from 'views/SitesMap/SitesMap'
import { showMessage } from 'state/slices/uiSlice'
import { dispatch } from 'state/store'

const SitesMapContainer = () => {
    useBreadCrumbs([i18nKeys.breadcrumb_map])

    const authorize = useAuthorization()
    const hasMonitoringRight = authorize(EProfileRights.HAS_MONITORING_RIGHT)
    const license = useLicense()
    const {showModal} = useModal()
    
    const handleMarkerSelected = async (id: number) => {
        try {
            const site = await SiteService.getSite(id)
        
            let preSelectedTab = 0
            if (hasMonitoringRight && license(ELicenseTypes.MONITORING)) {
                preSelectedTab = 2
            }
    
            showModal({
                content: <SiteDetails site={site} preselectedTab={preSelectedTab} />,
                title: site.name
            })
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
    }

    return (
        <>
            <SiteMap onMarkerSelected={handleMarkerSelected} />
        </>
    )
}

export default SitesMapContainer