import { ButtonBase } from '@mui/material'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import style from 'style'
import colors from 'style/colors'
import styled from 'styled-components'

const Wrapper = styled(ButtonBase)`
    font-family: ${style.fonts.PRIMARY_REGULAR};
    height: 100%;
    width: 100%;
    border: dashed 3px ${colors.shades.DEEP_SHADE};
    display: grid;
    border-radius: 8px;
    place-items: center;
    color: ${colors.shades.DEEP_SHADE};
    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &:hover {
        border-color: ${colors.primary.DEFAULT_PRIMARY};
        color: ${colors.primary.DEFAULT_PRIMARY};
    }

    & svg {
        margin-bottom: 16px;
    }

    fill: ${colors.primary.DEFAULT_PRIMARY};
`

type TProps = {
    onClick?: () => void
    fullscreen?: boolean
}

const EmptyChart = (props: TProps) => {
    if (props.fullscreen) {
        return <div />
    }
    
    return (
        <Wrapper onClick={props.onClick}>
            <div>
                <Icon d={Icons.ADD} viewBox='0 0 42 42' />
                <TransMessage {...i18nKeys.umbrella_dashboard_add_card} />
            </div>
        </Wrapper>
    )
}

export default EmptyChart