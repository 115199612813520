import AdvancedJobConfigFields from 'components/AdvancedJobConfigFields/AdvancedJobConfigFields'
import AdvancedSlider from 'components/CustomControls/AdvancedSlider'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormFooter, FormGroup, FormInput, FormLabel, FormWrapper, InputWrapper } from 'components/FormControls/FormControls'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import colors from 'style/colors'
import Dropdown from 'components/Dropdown/Dropdown'
import { defaultNewTimeRangeSizeSlider, defaultNewTimeRangeTimesDropdown, sliderRangeChangeTypes, sliderStepMs, TExtendedTimeProfileDay, TExtendedTimeRange, timeDropDownValues, timeProfileDaysEnum, timeStartAndEndValuesMs } from 'types/timeprofile'
import DropdownItem from 'components/Dropdown/DropdownItem'
import DateHelper from 'helpers/DateHelper'
import BoxedRangesGrid from 'components/BoxedRangesGrid/BoxedRangesGrid'
import { timeProfileHelper } from 'helpers/TimeProfileHelper'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { useAppSelector } from 'state/store'
import { yupResolver } from '@hookform/resolvers/yup'

const ToggleIcon = styled(Icon)<{expanded: boolean}>`
    align-self: center;
    opacity: 0.4;
    transform: ${p => p.expanded ? 'rotate(180deg)' : 'none'};
`

const DropdownWrapper = styled.div`
    width: 175px;
    margin-left: 16px;
    margin-right: 16px;
`

const StyledIcon = styled(Icon)`
    margin-right: 16px;
    cursor: pointer;
    fill: ${colors.primary.DEFAULT_PRIMARY};
`

const HeaderWrapper = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;
`

const SliderWrapper = styled.div`
    height: 24px;
    width: 700px;
    display: inline-block;
    padding-top: 5px;
`

type TProps = {
    isSiteAdmin: boolean
    values: TExtendedTimeProfileDay[]
    onSubmit: (values: any) => void
}

const SiteTimeProfileChanger = (props: TProps) => {
    const [expandSlider, SetExpandSlider] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)
    const [timeProfileDay, setTimeProfileDay] = useState<TExtendedTimeProfileDay[]>(props.values)
    const [initValues, setInitValues] = useState<TExtendedTimeProfileDay[] | undefined>(undefined)
    const isCommentMandatory = useAppSelector(state => state.ui.uiSettings.isCommentMandatoryForJobs)

    const intl = useIntl()

    const validationSchema = (mandatoryComment: boolean) => yup.object().shape({
        comment: mandatoryComment ? yup.string().max(500).required(intl.formatMessage(i18nKeys.general_validation_required_field)) : yup.string().max(500)
    })

    const { handleSubmit, formState, control, reset, setValue} = useForm<any>({
        mode: 'onChange',
        resolver: yupResolver(validationSchema(isCommentMandatory)),
        defaultValues: {
            comment: ''
        }
    })

    const { isValid } = formState

    useEffect(() => {
        setInitValues(JSON.parse(JSON.stringify(props.values)))
    }, [props.values])

    const resetForm = () => {
        setTimeProfileDay(initValues!)
        reset()
    }

    const didTimeProfilesChanged = () => {
        const timeRangesJson = JSON.stringify(timeProfileDay)
        const initValuesJson = JSON.stringify(initValues)

        return timeRangesJson !== initValuesJson
    }

    const handleSetTimeProfilesSubmit = async (values: any) => {
        
        values.stringValue = timeProfileHelper.timeProfileDayToJson(timeProfileDay)

        props.onSubmit(values)
    }

    const handleCancel = (index: number) => {
        if (selectedIndex !== undefined) {
            const temp = [...timeProfileDay]
            temp[selectedIndex].timeRanges.splice(index, 1)
            setTimeProfileDay(temp)
        }
    }

    const setStartTimeOfTimeRange = (index: number, start: number) => {
        if (selectedIndex !== undefined) {
            const temp = [...timeProfileDay]
            temp[selectedIndex].timeRanges[index].start = start
            temp[selectedIndex].timeRanges = timeProfileHelper.validateTimeRanges(temp[selectedIndex].timeRanges)
            setTimeProfileDay(temp)
        }
    }

    const setStopTimeOfTimeRange = (index: number, stop: number) => {
        if (selectedIndex !== undefined) {
            const temp = [...timeProfileDay]
            temp[selectedIndex].timeRanges[index].stop = stop
            temp[selectedIndex].timeRanges = timeProfileHelper.validateTimeRanges(temp[selectedIndex].timeRanges)
            setTimeProfileDay(temp)
        }
    }

    const handleSaveTimeRange = (index: number) => {
        if (selectedIndex !== undefined) {
            const temp = [...timeProfileDay]
            temp[selectedIndex].timeRanges[index].skipInCalculation = false
            temp[selectedIndex].timeRanges = timeProfileHelper.validateTimeRanges(temp[selectedIndex].timeRanges)
            setTimeProfileDay(temp)
        }
    }

    const handleAddNewTimeRange = () => {
        if (selectedIndex !== undefined) {
            const temp = [...timeProfileDay]
            temp[selectedIndex].timeRanges.push({
                start: defaultNewTimeRangeTimesDropdown.start,
                stop: defaultNewTimeRangeTimesDropdown.stop,
                skipInCalculation: true
            })
            temp[selectedIndex].timeRanges = timeProfileHelper.validateTimeRanges(temp[selectedIndex].timeRanges)
            setTimeProfileDay(temp)
        }
    }

    const renderTimeRange = (index: number, timeRange: TExtendedTimeRange) => {
        return (
            <InputWrapper key={index}>
                Starts:
                <DropdownWrapper>
                    <Dropdown
                        disabled={!props.isSiteAdmin}
                        onChange={(event) => {
                            setStartTimeOfTimeRange(index, event.target.value as number)
                        }}
                        value={timeRange.start}
                    >
                        {timeDropDownValues.map((item, dropdownIndex) => {
                            return (
                                <DropdownItem key={dropdownIndex} value={item}>
                                    {DateHelper.millisecondsToHoursAndMinutes(item)}
                                </DropdownItem>
                            )
                        })}
                    </Dropdown>
                </DropdownWrapper>
                Ends:
                <DropdownWrapper>
                    <Dropdown
                        disabled={!props.isSiteAdmin}
                        onChange={(event) => {
                            setStopTimeOfTimeRange(index, event.target.value as number)
                        }}
                        value={timeRange.stop}
                    >
                        {timeDropDownValues.map((item, dropdownIndex) => {
                            return (
                                <DropdownItem key={dropdownIndex} value={item}>
                                    {DateHelper.millisecondsToHoursAndMinutes(item)}
                                </DropdownItem>
                            )
                        })}
                    </Dropdown>
                </DropdownWrapper>
                { props.isSiteAdmin && (
                    <>
                        <StyledIcon
                            d={Icons.CANCEL}
                            onClick={() => handleCancel(index)}
                        />
                        {
                            timeRange.skipInCalculation && (
                                <StyledIcon
                                    d={Icons.TICK_SPECIAL}
                                    onClick={() => handleSaveTimeRange(index)}
                                />
                            )
                        }
                    </>
                )}
            </InputWrapper>
        )
    }

    const reduceData = (timeProfileInfo: TExtendedTimeProfileDay[]): [number, number][][] => {
        const result: [number, number][][] = []

        timeProfileInfo.forEach((item, index) => {
            result[index] = []
            item.timeRanges.forEach((range) => {
                if (!range.skipInCalculation) {
                    result[index].push([range.start, range.stop])
                }
            })
        })

        return result
    }

    const handleRowSelectChange = (index: number | undefined) => {
        setSelectedIndex(index)
    }

    //#region adv slider
   
    const [handleTouched, setHandleTouched] = useState(false)
    const [slidingStarted, setSlidingStarted] = useState(false)

    let rangeCreationQueue: TExtendedTimeRange | null = null

    const filterOverlappingRanges = (ranges: Array<TExtendedTimeRange>) => {
        const saveOriginalOrder = ranges.map((range: TExtendedTimeRange, index: number) => ({
            ...range,
            index
        }))
        const sortByValues = saveOriginalOrder.sort((a: TExtendedTimeRange, b: TExtendedTimeRange) =>
            a.start - b.start)

        const firstRealRangeIndex = sortByValues.findIndex((x: TExtendedTimeRange) => x.skipInCalculation !== true)
        // None of ranges are saved
        if (firstRealRangeIndex === -1) {
            return ranges
        }

        let lastRange = sortByValues[firstRealRangeIndex]
        const filteredRanges = sortByValues.slice(0, firstRealRangeIndex + 1)
        let lastUpdatedIndex = firstRealRangeIndex
        for (let i = firstRealRangeIndex + 1; i < sortByValues.length; i++) {
            if (sortByValues[i].skipInCalculation === true) {
                filteredRanges.push(sortByValues[i])
                continue
            }
            if (sortByValues[i].start <= lastRange.stop) {
                const newRange = {
                    start: lastRange.start,
                    stop: sortByValues[i].stop > lastRange.stop ? sortByValues[i].stop : lastRange.stop,
                    index: lastRange.index,
                    skipInCalculation: false
                }
                filteredRanges[lastUpdatedIndex] = newRange
                lastRange = newRange
                continue
            }
            lastUpdatedIndex++
            lastRange = sortByValues[i]
            filteredRanges.push(sortByValues[i])
        }

        // Restore back original order (as close as possible)
        return filteredRanges.sort((a: TExtendedTimeRange, b: TExtendedTimeRange) => a.index! - b.index!)
    }
    
    const validateRangeAfterSlide = (range: [number, number], changeType: string, oldRange: {start: number, stop: number}) => {
        const start = range[0]
        const stop = range[1]

        // Create new range if clicked on blank space
        if (!handleTouched && !slidingStarted) {
            if (changeType === sliderRangeChangeTypes.START && oldRange.start - start > sliderStepMs) {
                const newRangeTime = defaultNewTimeRangeSizeSlider
                range[0] = oldRange.start
                rangeCreationQueue = {
                    start,
                    stop: Math.min(timeStartAndEndValuesMs.MAX, start + newRangeTime)
                }
            } else if (changeType === sliderRangeChangeTypes.STOP && stop - oldRange.stop > sliderStepMs) {
                const newRangeTime = defaultNewTimeRangeSizeSlider
                range[1] = oldRange.stop
                rangeCreationQueue = {
                    start: Math.max(timeStartAndEndValuesMs.MIN, stop - newRangeTime),
                    stop
                }
            }
        }
        
        return range
    }

    const findChangedRangeAfterSlide = (newRanges: [number, number][], old: TExtendedTimeRange[]) => {
        for (let i = 0, correction = 0; i < old.length; i++) {
            if (old[i].skipInCalculation === true) {
                correction++
                continue
            }
            if (newRanges[i - correction][0] !== old[i].start) {
                return {
                    sliderIndex: i - correction,
                    realIndex: i,
                    type: sliderRangeChangeTypes.START
                }
            } else if (newRanges[i - correction][1] !== old[i].stop) {
                return {
                    sliderIndex: i - correction,
                    realIndex: i,
                    type: sliderRangeChangeTypes.STOP
                }
            }
        }
        return null
    }

    const handleOnChange = (ranges: [number, number][]) => {
        const data = [...timeProfileDay]
        const currentDay = data.find(x => x.timeProfileDay === selectedIndex)

        if (currentDay) {
            const change = findChangedRangeAfterSlide(ranges, currentDay.timeRanges)
            if (change) {
                const validatedRange = validateRangeAfterSlide(ranges[change.sliderIndex], change.type, currentDay.timeRanges[change.realIndex])
                currentDay.timeRanges[change.realIndex] = {
                    ...currentDay.timeRanges[change.realIndex],
                    start: validatedRange[0],
                    stop: validatedRange[1]
                }
            }

            if (rangeCreationQueue) {
                currentDay.timeRanges.push({...rangeCreationQueue, skipInCalculation: false})
            }
            
            currentDay.timeRanges = filterOverlappingRanges(currentDay.timeRanges)
            rangeCreationQueue = null
        }

        setTimeProfileDay(data)
    }

    const mergeTwoRangesWithinRanges = (first: number, second: number, ranges: [number, number][]) => {
        const start = Math.max(ranges[first][0], ranges[second][0])
        const stop = Math.min(ranges[first][1], ranges[second][1])
        ranges[first][1] = start
        ranges[second][0] = stop
        return ranges
    }

    const sliderRangesToMerge : number[] = []

    const slideStopHandler = (values: [number, number][]) => {
        setHandleTouched(false)
        setSlidingStarted(false)
        if (sliderRangesToMerge.length) {
            const start = sliderRangesToMerge[0]
            const stop = sliderRangesToMerge[1]
            handleOnChange(mergeTwoRangesWithinRanges(start, stop, values))
        }
    }

    const sliderData = () : [number, number][] => {
        return timeProfileDay[selectedIndex!].timeRanges.filter(x => x.skipInCalculation === false).map(x => ([x.start, x.stop]))
    }

    const handleClickOnEmptySlider = (event: React.MouseEvent<HTMLDivElement>) => {
        const pos = event.clientX - event.currentTarget.getBoundingClientRect().left
        const sliderWidthPx = 700
        const posIntoPercent = pos / sliderWidthPx
        const intoTimeValues = Math.floor(posIntoPercent * timeStartAndEndValuesMs.MAX)
        const closestStep = intoTimeValues - (intoTimeValues % sliderStepMs)

        const data = [...timeProfileDay]
        const currentDay = data.find(x => x.timeProfileDay === selectedIndex)
        if (currentDay) {
            if (currentDay.timeRanges.filter(x => x.skipInCalculation === false).length) {
                return
            }

            currentDay.timeRanges = [
                ...currentDay.timeRanges,
                {
                    start: closestStep,
                    stop: closestStep + sliderStepMs,
                    skipInCalculation: false
                }
            ]
            setTimeProfileDay(data)
        }
    }

    //#endregion adv slider end

    return (
        <FormWrapper onSubmit={handleSubmit(handleSetTimeProfilesSubmit)}>
            <FormContent>
                <BoxedRangesGrid
                    rowData={reduceData(timeProfileDay)}
                    onRowSelectChange={handleRowSelectChange}
                />
                {selectedIndex !== undefined && <>
                    <FormGroup>
                        <HeaderWrapper>
                            <h2>
                                <TransMessage {...timeProfileDaysEnum[selectedIndex].translation} />
                            </h2>
                        </HeaderWrapper>
                        
                        <SliderWrapper onMouseDown={handleClickOnEmptySlider}>
                            <AdvancedSlider
                                onChange={handleOnChange}
                                afterEveryChange={() => setSlidingStarted(true)}
                                onAfterChange={slideStopHandler}
                                onHandleTouch={() => setHandleTouched(true)}
                                min={timeStartAndEndValuesMs.MIN}
                                max={timeStartAndEndValuesMs.MAX}
                                step={sliderStepMs}
                                values={sliderData()}
                                disabled={!props.isSiteAdmin}
                                handleValueFormater={(value: number) => DateHelper.millisecondsToHoursAndMinutes(value)}
                            />
                        </SliderWrapper>

                        <ToggleIcon
                            id='toggleExpandSliderButton'
                            onClick={() => SetExpandSlider(!expandSlider)}
                            expanded={expandSlider}
                            d={Icons.CHEVRON}
                        />
                    </FormGroup>
                    {
                        expandSlider && (
                            <>
                                <FormGroup>
                                    { timeProfileDay[selectedIndex].timeRanges.map((timeProfile, index) => renderTimeRange(index, timeProfile)) }
                                </FormGroup>
                    
                                { props.isSiteAdmin && (
                                    <FormGroup>
                                        <CustomButton
                                            id='addNewTimeRangeButton'
                                            onClick={handleAddNewTimeRange}
                                            endIcon={<Icon d={Icons.ADD} size={14} viewBox='0 0 48 48' />}
                                        >
                                            <TransMessage {...i18nKeys.site_time_profile_time_range_add_new} />
                                        </CustomButton>
                                    </FormGroup>
                                )}
                            </>
                        )
                    }
                    
                </> }
                
                { props.isSiteAdmin && (
                    <>
                        <FormGroup collapsable title={i18nKeys.advanced_job_configuration_title}>
                            <AdvancedJobConfigFields
                                formProps={formState}
                                setFieldValue={setValue}
                                control={control}
                            />
                        </FormGroup>
                        <FormGroup title={i18nKeys.job_configuration_comment_title}>
                            <InputWrapper>
                                <FormLabel htmlFor='comment'>
                                    <TransMessage {...i18nKeys.job_configuration_comment} />
                                </FormLabel>
                                <FormInput
                                    name='comment'
                                    autoComplete='job-comment'
                                    control={control}
                                />
                            </InputWrapper>
                        </FormGroup>
                    </>
                )}
            </FormContent>

            { props.isSiteAdmin && (
                <FormFooter>
                    <FormButtonGroup>
                        <CustomButton
                            disabled={!isValid || !didTimeProfilesChanged()}
                            id='submitBtn'
                            type='submit'
                        >
                            <TransMessage {...i18nKeys.site_time_profile_change_property_job} />
                        </CustomButton>
                        <CustomButton
                            id='resetBtn'
                            onClick={() => resetForm()}
                            $secondary
                        >
                            <TransMessage {...i18nKeys.site_time_profile_reset} />
                        </CustomButton>
                    </FormButtonGroup>
                </FormFooter>
            ) }
        </FormWrapper>
    )
}
export default SiteTimeProfileChanger
