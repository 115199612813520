import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import UmbrellaRolesService from 'services/umbrellaRolesService'
import { showMessage } from 'state/slices/uiSlice'
import { dispatch } from 'state/store'
import { TCreateUmbrellaRoleRequest, TUmbrellaRoleDetail, TUpdateUmbrellaRoleRequest } from 'types/businessLogic/role'
import { TFetchParamsRequest } from 'types/network'
import { TTableGroup } from 'types/ui/table'

export type TUmbrellaRoleState = {
    umbrellaRoles: TUmbrellaRoleDetail[]
    groups: TTableGroup<TUmbrellaRoleDetail>[]
    totalNumber: number
    builtInRoleIds: string[]
}

const initialState : TUmbrellaRoleState = {
    umbrellaRoles: [],
    groups: [],
    totalNumber: 0,
    builtInRoleIds: []
}

export const getUmbrellaRolesAsync = createAsyncThunk('umbrellaRole/getUmbrellaRolesAsync', async (params: TFetchParamsRequest, thunkApi) => {
    try {
        const result = await UmbrellaRolesService.getRoles(params)

        const builtInRoleIds = result.roles.reduce((acc: string[], role) => {
            if (role.builtIn) {
                acc.push(role.id)
            }
            return acc
        }, [])
    
        return {
            ...result,
            builtInRoleIds
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const createUmbrellaRoleAsync = createAsyncThunk('umbrellaRole/createUmbrellaRoleAsync', async (params: TCreateUmbrellaRoleRequest) => {
    try {
        await UmbrellaRolesService.createRole(params)
        dispatch(showMessage({message: i18nKeys.create_umbrella_role_success_message, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        dispatch(showMessage({message: i18nKeys.create_umbrella_role_error_message, type: EMessageType.ERROR}))
    }
})

export const updateUmbrellaRoleAsync = createAsyncThunk('umbrellaRole/updateUmbrellaRoleAsync', async (params: TUpdateUmbrellaRoleRequest) => {
    try {
        await UmbrellaRolesService.updateRole(params.id, params)
        dispatch(showMessage({message: i18nKeys.umbrella_role_details_update_success_message, type: EMessageType.SUCCESS}))
    } catch (err) {
        dispatch(showMessage({message: i18nKeys.umbrella_role_details_update_error_message, type: EMessageType.ERROR}))
    }
})

export const deleteUmbrellaRoleAsync = createAsyncThunk('umbrellaRole/deleteUmbrellaRoleAsync', async (params: string[]) => {
    try {
        await UmbrellaRolesService.deleteRoles(params)
        dispatch(showMessage({message: i18nKeys.umbrella_roles_list_delete_roles_success, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        dispatch(showMessage({message: i18nKeys.umbrella_roles_list_delete_roles_error, type: EMessageType.ERROR}))
    }
})

export const getGroupDataAsync = createAsyncThunk('umbrellaRole/getGroupDataAsync', async (params: TFetchParamsRequest, thunkApi) : Promise<TTableGroup<TUmbrellaRoleDetail>> => {
    try {
        const result = await UmbrellaRolesService.getRoles(params)
        return {
            name: params.GroupName!,
            count: result.totalNumber,
            data: result.roles
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'umbrellaRole',
    initialState: initialState,
    reducers: {
        clearGroups: (state, action : PayloadAction<{ totalNumber: number, groups: TTableGroup<TUmbrellaRoleDetail>[] }>) => {
            action.payload.groups.forEach(item => {
                const oldItem = state.groups.find(x => x.name === item.name)
                if (oldItem) {
                    item.data = oldItem.data
                }
            })

            state.groups = action.payload.groups
            state.totalNumber = action.payload.totalNumber
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUmbrellaRolesAsync.fulfilled, (state, action) => {
                const { roles, totalNumber, builtInRoleIds } = action.payload
                state.umbrellaRoles = roles
                state.totalNumber = totalNumber
                state.builtInRoleIds = builtInRoleIds
            })
            .addCase(getGroupDataAsync.fulfilled, (state, action) => {
                const existingGroup = state.groups.find(p => p.name === action.payload.name)
                if (existingGroup) {
                    existingGroup.data = action.payload.data
                    existingGroup.count = action.payload.count
                } else {
                    state.groups.push(action.payload)
                }

                action.payload.data?.forEach(x => {
                    const index = state.umbrellaRoles.findIndex(y => y.id === x.id)
                    if (index === -1) {
                        state.umbrellaRoles.push(x)
                    } else {
                        state.umbrellaRoles[index] = x
                    }
                })
            })
    }
})

export const {clearGroups} = slice.actions

export default slice.reducer