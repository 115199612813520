import { IconButton } from '@mui/material'
import Icon from 'components/Icon/Icon'
import React from 'react'
import colors from 'style/colors'
import styled from 'styled-components'

type TProps = {
    id: string
    disabled?: boolean
    onClick: () => void
    icon: string | JSX.Element
    title?: string
}

const StyledIconButton = styled(IconButton)<{$disabled?: boolean}>`
    fill: ${p => p.$disabled ? colors.shades.DARK_SHADE : colors.primary.DARK_PRIMARY} !important;
    color: ${p => p.$disabled ? colors.shades.DARK_SHADE : colors.primary.DARK_PRIMARY} !important;
`

const CustomIconButton = (props: TProps) => {
    const {icon, disabled, title, ...rest} = props

    const renderComponent = () : JSX.Element => {
        if (typeof icon === 'string') {
            return <Icon d={icon} />
        } else {
            return icon
        }
    }

    return (
        <StyledIconButton {...rest} $disabled={disabled} disabled={disabled} title={title}>
            {renderComponent()}
        </StyledIconButton>
    )
}

export default CustomIconButton