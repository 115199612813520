import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { tableInfo } from 'views/Report/SiteInventory/siteInventoryReportsTableColumns'
import { TReport } from 'types/businessLogic/report'

class SiteInventoryReportsTablePreparer {
    public static prepareSiteReportRows = (siteReports: TReport[]) =>
        siteReports.map(siteReport => ({
            ...siteReport,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.filename.key,
                    translationKey: tableInfo.columnConfig.filename.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: siteReport.filename
                },
                {
                    name: tableInfo.columnConfig.createdAt.key,
                    translationKey: tableInfo.columnConfig.createdAt.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.dateString.index,
                    [GeneralHelper.propertyTypes.dateString.key]: siteReport.createdAt
                },
                {
                    name: tableInfo.columnConfig.siteName.key,
                    translationKey: tableInfo.columnConfig.siteName.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: siteReport.siteName
                }
            ]
        }))

    public static prepareGroups = (groups: TTableGroup<TReport>[]): TTableGroup<TTableRowData>[] => groups.map(group => ({
        ...group,
        data: group.data ? SiteInventoryReportsTablePreparer.prepareSiteReportRows(group.data) : undefined
    }))
}

export default SiteInventoryReportsTablePreparer