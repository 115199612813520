import React, { useEffect, useState } from 'react'
import { checkSessionAsync } from 'state/slices/authSlice'
import InvalidSessionModal from 'views/Layout/Modals/InvalidSessionModal'
import { CustomModal } from 'components/CustomControls/CustomModal'
import AppConstants from 'helpers/AppConstants'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import { useAppSelector, useAppDispatch } from 'state/store'

const SessionObserver = () => {
    const { invalidSessionCount, isAuthenticated } = useAppSelector(state => state.auth)
    const [showInvalidSessionModal, setShowInvalidSessionModal] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const sessionCheckIntervalId = window.setInterval(() => dispatch(checkSessionAsync()), AppConstants.other.INVALID_SESSION_TIMEOUT)

        if (!isAuthenticated) {
            clearInterval(sessionCheckIntervalId)
        }

        if (invalidSessionCount >= AppConstants.other.INVALID_SESSION_RETRIES) {
            setShowInvalidSessionModal(true)
        }

        if (showInvalidSessionModal && invalidSessionCount < AppConstants.other.INVALID_SESSION_RETRIES) {
            setShowInvalidSessionModal(false)
        }

        return () => {
            clearInterval(sessionCheckIntervalId)
        }
    }, [dispatch, invalidSessionCount, showInvalidSessionModal, isAuthenticated])

    return (
        <>
            {
                showInvalidSessionModal &&
                <CustomModal
                    open={true}
                    dynamicHeight
                    width='25%'
                    title={<TransMessage {...i18nKeys.invalid_session_title} />}
                >
                    <InvalidSessionModal />
                </CustomModal>
            }
        </>
    )
}

export default SessionObserver