import { EPerformanceState, ERecordingState, EVMSState } from 'enums/enums'
import { TPerformanceItem, TPerformanceItemsPerSite } from 'types/businessLogic/performance'

export type TPagedCamera = {
    results: TCameraPerformance[]
    currentPage: number
    pageCount: number
    pageSize: number
    recordCount: number
}

export type TCamerasPerSite = TPerformanceItemsPerSite

export type TCameraPerformance = TPerformanceItem & {
    cameraName: string
    vmsState: EVMSState
    recordingState: ERecordingState
    vmsPerformanceState: EPerformanceState
    recordingPerformanceState: EPerformanceState
    vmsStateCode?: string
}

export function isTCameraPerformance(item: TPerformanceItem): item is TCameraPerformance {
    return (item as TCameraPerformance).recordingState !== undefined
}

