import React, { useCallback } from 'react'
import { clearGroups, getGroupDataAsync, getSiteTimeProfilesAsync } from 'state/slices/siteTimeProfileSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGroup, TTableFetchState } from 'types/network'
import { tableInfo } from 'views/Control/SiteTimeProfiles/siteTimeProfilesTableColumns'
import SiteTimeProfile from './SiteTimeProfiles'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { TModalProps } from 'types/ui/modal'
import { siteTimeProfileService } from 'services/siteTimeProfileService'
import SiteTimeProfilesTablePreparer from 'views/Control/SiteTimeProfiles/siteTimeProfilesTablePreparer'
import SiteTimeProfileDetails from 'views/Control/SiteTimeProfiles/SiteTimeProfileDetails/SiteTimeProfileDetails'

import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { useModal } from 'hooks/modalProvider'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const SiteTimeProfilesContainer = () => {
    const dispatch = useAppDispatch()
    const { siteTimeProfiles, totalNumber, groups } = useAppSelector(state => state.siteTimeProfile)
    const {showModal} = useModal()
    
    useBreadCrumbs([i18nKeys.breadcrumb_site_time_profile])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams,
            translationKey: group.translationKey
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const requestParams = GeneralHelper.buildRequestFromTableState(params)
            dispatch(getSiteTimeProfilesAsync(requestParams))
        } else if (params.groupName) {
            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, params.groupName)
            dispatch(getGroupDataAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await siteTimeProfileService.getSiteEntityGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }

            getGroups()
        }
    }, [dispatch])

    const openSiteTimeProfileDetailsModal = (id: number) => {
        const siteTimeProfileDetails = siteTimeProfiles.find(siteTimeProfile => siteTimeProfile.id === id)
        const props : TModalProps = { ids: [id] }

        if (!siteTimeProfileDetails) return
                
        showModal({
            content: <SiteTimeProfileDetails siteTimeProfile={siteTimeProfileDetails} {...props} />,
            title: siteTimeProfileDetails.name,
            width: '43%'
        })
    }

    const data = SiteTimeProfilesTablePreparer.prepareSiteRows(siteTimeProfiles, openSiteTimeProfileDetailsModal)
    const preparedGroups = SiteTimeProfilesTablePreparer.prepareGroups(groups, openSiteTimeProfileDetailsModal)

    return (
        <ViewContainer>
            <SiteTimeProfile
                data={data}
                groups={preparedGroups}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                getTableData={getTableData}
                getGroupData={getGroupData}
            />
        </ViewContainer>
    )
}

export default SiteTimeProfilesContainer