import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TTableRowData } from 'types/ui/table'
import { TSiteEntity } from 'types/businessLogic/site'
import { tableInfo } from 'views/Control/SiteCamera/siteCamerasTableColumns'

class SiteCamerasTablePreparer {
    public static prepareSiteCameraRows = (cameras: TSiteEntity[]): TTableRowData[] =>
        cameras.map(siteCamera => ({
            ...siteCamera,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.name.key,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    readOnly: false,
                    [GeneralHelper.propertyTypes.string.key]: siteCamera.name
                },
                {
                    name: tableInfo.columnConfig.siteName.key,
                    translationKey: tableInfo.columnConfig.siteName.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: siteCamera.siteName
                },
                ...siteCamera.propertyValues
            ]
        }))
}

export default SiteCamerasTablePreparer