import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import AdvancedTable from 'components/Tables/AdvancedTable'
import Toolbar from 'components/Toolbar/Toolbar'
import { EDataSyncItems } from 'enums/enums'
import useBackgroundService from 'hooks/useBackgroundService'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { TTableFetchState } from 'types/network'
import { TTableInfo, TTableRowData } from 'types/ui/table'
import { uid } from 'uid'
import EditIcon from '@mui/icons-material/Edit'
import { useModal } from 'hooks/modalProvider'
import EditLabelModal from 'views/Admin/Labels/Modals/EditLabelModal'
import { i18nKeys } from 'i18n/keys'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`

type TProps = {
    data: TTableRowData[]
    totalCount: number
    tableInfo: TTableInfo
    getTableData: (params: TTableFetchState) => void
}

const Labels = (props: TProps) => {
    const { tableInfo, data, totalCount, getTableData } = props
    const [identifier] = useState(uid())
    const {showModal} = useModal()
    const colHandlerProps = useColumnsHandler(tableInfo)

    const enhanceTableProps = useEnhanceTable({
        groups : undefined
    })

    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
    }, [enhanceTableProps.fetchState, getTableData])

    const { toggleDisabled } = useBackgroundService(EDataSyncItems.LABEL, loadData, identifier)

    const disableToolbarButtons = enhanceTableProps.selectionCount !== 1
    const isAuthorized = true

    const handleEditLabel = () => {
        showModal({
            content: <EditLabelModal ids={enhanceTableProps.getSelectedIds() as number[]} />,
            title: i18nKeys.edit_label_title,
            description: i18nKeys.edit_label_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        {
                            isAuthorized && (
                                <>
                                    <CustomIconButton
                                        id='editLabelButton'
                                        icon={<EditIcon />}
                                        disabled={disableToolbarButtons}
                                        onClick={handleEditLabel}
                                        title='edit button'
                                    />
                                </>
                            )
                        
                        }
                    </>
                }
                RightContent={
                    <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} />
                }
            />
            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                rows={data}
            />
        </ViewWrapper>
    )
}
export default Labels
