import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import SettingsService from 'services/settingsService'
import { showMessage } from 'state/slices/uiSlice'
import { TSettings } from 'types/businessLogic/misc'

export type TSettingsState = TSettings & {
    fetched: boolean
    restored: boolean
}

const initialState : TSettingsState = {
    fetched: false,
    restored: false,
    deleteVmsEventsOlderThanInDays: 0,
    deleteCoreLogFilesOlderThanInDays: 0,
    provideHealthEndpoint: false,
    corePerformanceMeasureIntervalInMinutes: 0,
    deleteCorePerformanceMeasurementsIntervalInDays: 0,
    delayGatewayGetCoreVersionInSeconds: 0,
    heartBeatIntervalInSeconds: 0,
    numberOfHeartBeatsAllowedToMiss: 0,
    writeGatewayHeartbeatCacheIntervalInSeconds: 0,
    useSignalRforVmsEvent: false,
    vmsNetworkTimeoutInSeconds: 0,
    fetchRecordingsIntervalInMinutes: 0,
    systemPerformanceMeasureIntervalInMinutes: 0,
    deleteSystemPerformanceMeasurementsIntervalInDays: 0,
    deleteGatewayLogFilesOlderThanInDays: 0,
    displayUsernameInJobs: false,
    isCommentMandatoryForJobs: false,
    gatewayEnableSync: false,
    gatewayEnableEvents: false,
    gatewayEnablePerformance: false,
    gatewayEnableInfo: false,
    isRememberMeAvailable: false,
    cookiePersistenceDurationInHours: 0,
    slidingExpirationOfSessionCookie: false,
    validationIntervalInSeconds: 0,
    isActiveDirectoryLoginAvailable: false,
    domainNames: [],
    syncActiveDirectoryEmail: false,
    refreshActiveDirectoryCacheInMinutes: 0,
    requiredLength: 0,
    requiredUniqueChars: 0,
    requireDigit: false,
    requireUppercase: false,
    requireLowercase: false,
    requireNonAlphanumeric: false,
    licenseKey: '',
    csvStatisticsEnabled: false,
    elkStatisticsEnabled: false,
    liteDbStatisticsEnabled: false,
    deleteReportsWhenDownloading: false,
    deleteJobsOlderThanInDays: 0,
    cpuThresholdWarningInPercent : 70,
    cpuThresholdCriticalInPercent : 90,
    memoryThresholdWarningInPercent : 70,
    memoryThresholdCriticalInPercent : 90,
    diskThresholdWarningInPercent : 70,
    diskThresholdCriticalInPercent : 90,
    networkThresholdWarningInPercent : 70,
    networkThresholdCriticalInPercent : 90,
    notificationDelayNormalInSeconds: 10,
    notificationDelayLowInSeconds: 60
}

export const getSettingsAsync = createAsyncThunk('settings/getSettingsAsync', async () => {
    // no try/catch wrapper to prefent popups when user has no roles
    return await SettingsService.getSettings()
})

export const restoreDefaultSettingsAsync = createAsyncThunk('settings/restoreDefaultSettingsAsync', async (_, thunkApi) => {
    try {
        const result = await SettingsService.restoreDefaultSettings()
        thunkApi.dispatch(showMessage({message: i18nKeys.settings_configuration_update_success, type: EMessageType.SUCCESS}))
        return result
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.settings_configuration_update_error, type: EMessageType.ERROR}))
        throw err
    }
})

export const postSettingsAsync = createAsyncThunk('settings/postSettingsAsync', async (params: TSettings, thunkApi) => {
    try {
        await SettingsService.postSettings(params)
        thunkApi.dispatch(showMessage({message: i18nKeys.settings_configuration_update_success, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.settings_configuration_update_error, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSettingsAsync.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
                state.fetched = true
                state.restored = false
            })
            .addCase(postSettingsAsync.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
                state.fetched = true
                state.restored = false
            })
            .addCase(restoreDefaultSettingsAsync.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
                state.restored = true
            })
    }
})

export default slice.reducer