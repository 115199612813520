import TransMessage, { TIntlMessage } from 'components/TransMessage/TransMessage'
import React from 'react'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import styled from 'styled-components'

type TProps = {
    id?: string
    color: string
    textKey: TIntlMessage
}

const Container = styled.div`
    position: relative;
    min-width: 60px;
`
const Text = styled.span<{big?: boolean}>`
    padding-left: 18px;
    color: ${colors.primary.DARK_PRIMARY};
    font-family: ${p => p.big ? fonts.PRIMARY_REGULAR : fonts.PRIMARY_SEMIBOLD};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${p => p.big ? letterSpacings.TYPE16LS : letterSpacings.TYPE14LS};
    font-family: ${fonts.PRIMARY_REGULAR};
`
const Dot = styled.div<{color: string}>`
    position: absolute;
    left: 0px;
    bottom: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${p => p.color};
`

const StatusDot = (props: TProps) => {
    const {textKey, id, color} = props

    return (
        <Container>
            <Dot color={color} />
            <Text id={id}>
                <TransMessage {...textKey} />
            </Text>
        </Container>
    )
}

export default StatusDot