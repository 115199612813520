import axiosClient from 'api/axiosClient'
import { TFetchParamsRequest, TGroupParams, TGroupResponse } from 'types/network'
import { TCreateUmbrellaRoleRequest, TUmbrellaRoleDetail, TUmbrellaRoleActiveDirectoryGroups } from 'types/businessLogic/role'

class UmbrellaRolesService {
    private static _api = axiosClient()

    public static async getRoles(params: TFetchParamsRequest): Promise<{roles: TUmbrellaRoleDetail[], totalNumber: number}> {
        const response = await this._api.get<{roles: TUmbrellaRoleDetail[], totalNumber: number}>('Roles', {
            params: params
        })
        return response.data
    }
    
    public static async createRole(params: TCreateUmbrellaRoleRequest) {
        await this._api.post('Roles', params)
    }

    public static async deleteRoles(ids: string[]) {
        await this._api.delete('Roles', {
            data: {
                Ids: ids
            }
        })
    }

    public static async getRoleActiveDirectoryGroups(params: TFetchParamsRequest): Promise<TUmbrellaRoleActiveDirectoryGroups[]> {
        const response = await this._api.get<TUmbrellaRoleActiveDirectoryGroups[]>('Roles/GetActiveDirectoryGroups', {
            params: params
        })
        return response.data
    }
    
    public static async updateRole(id: string, params: TCreateUmbrellaRoleRequest) {
        await this._api.post(`Roles/${id}`, params)
    }

    public static async getRole(id: string) {
        const response = await this._api.get<TUmbrellaRoleDetail>(`Roles/${id}`)
        return response.data
    }

    public static async roleExists(name: string) {
        const response = await this._api.get<boolean>(encodeURI(`Roles/Exists/${name}`))
        return response.data
    }

    public static async getRolesGroups(params: TGroupParams) {
        const response = await this._api.get<TGroupResponse>(`Roles/groups/${params.groupBy}`, {
            params: {
                Descending: params.descending,
                Filters: params.filters,
                PageSize: params.pageSize,
                PageNumber: params.pageNumber
            }
        })
        return response.data
    }
}

export default UmbrellaRolesService
