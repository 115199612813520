import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import LicenseService from 'services/licenseService'
import { TLicense } from 'types/businessLogic/license'

export type TLicenseState = TLicense & {
    fetched: boolean
}

const initialState: TLicenseState = {
    fetched: false,
    cameraLicenses: 0,
    configuration: false,
    expirationDate: '',
    licensedCameras: 0,
    monitoring: false,
    report: false,
    unlicensedCameras: 0,
    machineCode: '',
    situatorExclusive: false,
    webApi: false
}

export const getLicenseAsync = createAsyncThunk('license/getLicenseAsync', async () => {
    // no try/catch wrapper to prefent popups when user has no roles
    return await LicenseService.getLicense()
})

const slice = createSlice({
    name: 'license',
    initialState: initialState,
    reducers: {
        setLicense: (state, action) => {
            Object.assign(state, action.payload)
            state.fetched = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLicenseAsync.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
                state.fetched = true
            })
            .addCase(getLicenseAsync.rejected, (state) => { // important case to prevent endless loop
                state.fetched = true
            })
    }
})

export const {setLicense} = slice.actions

export default slice.reducer