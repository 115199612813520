import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TEnumTranslationDictionary, TTableGroup, TTableRowData } from 'types/ui/table'
import { TUmbrellaRoleDetail } from 'types/businessLogic/role'
import { tableInfo } from 'views/Admin/UmbrellaRoles/UmbrellaRolesTableColumns'
import CustomLink from 'components/CustomControls/CustomLink'
import EnumHelper from 'helpers/EnumHelper'

class UmbrellaRolesTablePreparer {
    public static prepareUmbrellaRoleRows = (
        umbrellaRoles: TUmbrellaRoleDetail[],
        eventCategories: TEnumTranslationDictionary,
        entityTypes: TEnumTranslationDictionary,
        openUmbrellaRoleDetailsModal: (id: string) => void): TTableRowData[] =>
        umbrellaRoles.map(umbrellaRole => ({
            ...umbrellaRole,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.name.key,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: () =>
                        <CustomLink onClick={() => openUmbrellaRoleDetailsModal(umbrellaRole.id)}>{umbrellaRole.name}</CustomLink>
                },
                {
                    name: tableInfo.columnConfig.umbrellaAdmin.key,
                    translationKey: tableInfo.columnConfig.umbrellaAdmin.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.bool.index,
                    [GeneralHelper.propertyTypes.bool.key]: umbrellaRole.umbrellaAdmin
                },
                {
                    name: tableInfo.columnConfig.siteAdmin.key,
                    translationKey: tableInfo.columnConfig.siteAdmin.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.bool.index,
                    [GeneralHelper.propertyTypes.bool.key]: umbrellaRole.siteAdmin
                },
                {
                    name: tableInfo.columnConfig.builtIn.key,
                    translationKey: tableInfo.columnConfig.builtIn.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.bool.index,
                    [GeneralHelper.propertyTypes.bool.key]: umbrellaRole.builtIn
                },
                {
                    name: tableInfo.columnConfig.users.key,
                    translationKey: tableInfo.columnConfig.users.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: umbrellaRole.users.map(x => x.name)
                },
                {
                    name: tableInfo.columnConfig.sites.key,
                    translationKey: tableInfo.columnConfig.sites.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: umbrellaRole.sites.map(x => x.name)
                },
                {
                    name: tableInfo.columnConfig.activeDirectoryGroup.key,
                    translationKey: tableInfo.columnConfig.activeDirectoryGroup.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: umbrellaRole.activeDirectoryGroup?.name
                },
                {
                    name: tableInfo.columnConfig.siteEventCategories.key,
                    translationKey: tableInfo.columnConfig.siteEventCategories.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: () => {
                        return EnumHelper.renderEnumTranslationsTableCell(umbrellaRole.vmsEventCategorySubscription, eventCategories)
                    }
                },
                {
                    name: tableInfo.columnConfig.siteEntityTypes.key,
                    translationKey: tableInfo.columnConfig.siteEntityTypes.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: () => {
                        return EnumHelper.renderEnumTranslationsTableCell(umbrellaRole.vmsEntityTypeSubscription, entityTypes)
                    }
                },
                {
                    name: tableInfo.columnConfig.containsFilter.key,
                    translationKey: tableInfo.columnConfig.containsFilter.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: umbrellaRole.filter
                },
                {
                    name: tableInfo.columnConfig.sendEmail.key,
                    translationKey: tableInfo.columnConfig.sendEmail.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.bool.index,
                    [GeneralHelper.propertyTypes.bool.key]: umbrellaRole.sendEmail
                },
                {
                    name: tableInfo.columnConfig.sendEmailToAddGroup.key,
                    translationKey: tableInfo.columnConfig.sendEmailToAddGroup.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.bool.index,
                    [GeneralHelper.propertyTypes.bool.key]: umbrellaRole.sendEmailToAddGroup
                },
                {
                    name: tableInfo.columnConfig.siteLabels.key,
                    translationKey: tableInfo.columnConfig.siteLabels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: umbrellaRole.siteLabels.map(x => x.name) || []
                }
            ]
        }))

    public static prepareGroups = (
        groups: TTableGroup<TUmbrellaRoleDetail>[],
        eventCategories: TEnumTranslationDictionary,
        entityTypes: TEnumTranslationDictionary,
        openUmbrellaRoleDetailsModal: (id: string) => void): TTableGroup<TTableRowData>[] => groups.map(group => ({
        ...group,
        data: group.data ? UmbrellaRolesTablePreparer.prepareUmbrellaRoleRows(group.data, eventCategories, entityTypes, openUmbrellaRoleDetailsModal) : undefined
    }))
}

export default UmbrellaRolesTablePreparer