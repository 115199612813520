import borderRadius from './borderRadius'
import breakpoints from './breakpoints'
import colors from './colors'
import fonts from './fonts'
import fontWeights from './fontWeights'
import images from './images'
import letterSpacings from './letterSpacings'
import lineHeights from './lineHeights'
import shadows from './shadows'
import sizes from './sizes'
import typography from './typography'
import typographyScale from './typographyScale'
import zIndex from './zIndex'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    colors,
    sizes,
    zIndex,
    typographyScale,
    typography,
    lineHeights,
    fonts,
    fontWeights,
    shadows,
    letterSpacings,
    images,
    borderRadius,
    breakpoints
}