import React from 'react'
import { ETableFilters } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'
import AppConstants from 'helpers/AppConstants'

const columnConfig: TTableColumnsConfig = {
    name: {
        key: 'name',
        translationKey: i18nKeys.site_users_list_table_head_site_user.id,
        sortKey: 'Name',
        displayed: true,
        filter: {
            key: 'Name',
            type: ETableFilters.INPUT_TEXT
        }
    },
    siteName: {
        key: 'siteName',
        translationKey: i18nKeys.site_users_list_table_head_site.id,
        sortKey: 'SiteName',
        groupKey: 'SiteName',
        displayed: true,
        filter: {
            key: 'SiteName',
            type: ETableFilters.INPUT_TEXT
        }
    },
    labels: {
        key: 'labels',
        translationKey: i18nKeys.site_users_list_table_head_user_labels.id,
        displayed: true,
        filter: {
            key: 'Labels',
            type: ETableFilters.INPUT_TEXT
        }
    },
    siteLabels: {
        key: 'siteLabels',
        translationKey: i18nKeys.site_users_list_table_head_site_labels.id,
        displayed: true,
        filter: {
            key: 'SiteLabels',
            type: ETableFilters.INPUT_TEXT
        }
    }
}

const defaultLayout = [
    columnConfig.name.key,
    columnConfig.siteName.key,
    columnConfig.labels.key,
    columnConfig.siteLabels.key
]

export const tableInfo : TTableInfo = {
    id: AppConstants.tableIds.SITE_USERS,
    columnConfig,
    defaultLayout
    
}

