import { EMessageType } from 'enums/enums'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback } from 'react'
import SiteSystemPerformanceService from 'services/siteSystemPerformanceService'
import { fetchSiteSystemsAsync } from 'state/slices/siteSystemPerformanceSlice'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGetCardPageRequest, TPerformanceItem } from 'types/businessLogic/performance'
import SiteSystems from 'views/Monitor/SiteSystems/SiteSystems'
import { useParams } from 'react-router-dom'
import EnumHelper from 'helpers/EnumHelper'
import styled from 'styled-components'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const SiteSystemsContainer = () => {
    const dispatch = useAppDispatch()
    const { allSiteSystemsPerSite, totalNumberSites, overallState } = useAppSelector(state => state.siteSystemPerformance)
    const { filter } = useParams()

    useBreadCrumbs([i18nKeys.breadcrumb_site_systems])

    const handleFetchAction = useCallback((params: TGetCardPageRequest) => {
        dispatch(fetchSiteSystemsAsync(params))
    }, [dispatch])

    const handleIgnoredAction = useCallback(async (siteSystems: TPerformanceItem[]) => {
        try {
            const ids = siteSystems.map(siteSystem => siteSystem.entityId)
            await SiteSystemPerformanceService.setIgnoreSystems(ids)

            dispatch(showMessage({message: i18nKeys.site_systems_ignore_success, type: EMessageType.SUCCESS}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.site_systems_ignore_error, type: EMessageType.ERROR}))
        }
    }, [dispatch])

    return (
        <ViewContainer>
            <SiteSystems
                totalNumberSites={totalNumberSites}
                allSiteSystemsPerSite={allSiteSystemsPerSite}
                handleIgnoredAction={handleIgnoredAction}
                handleFetchAction={handleFetchAction}
                overallState={overallState}
                preSelectedState={EnumHelper.parseStringToEPerformanceState(filter)}
            />
        </ViewContainer>
    )
}

export default SiteSystemsContainer