import { EMessageType } from 'enums/enums'
import EnumHelper from 'helpers/EnumHelper'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import SiteServicePerformanceService from 'services/siteServicePerformanceService'
import { siteServiceService } from 'services/siteServiceService'
import { fetchSiteServicePerformanceAsync } from 'state/slices/siteServicePerformanceSlice'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import styled from 'styled-components'
import { TGetCardPageRequest, TPerformanceItem } from 'types/businessLogic/performance'
import SiteServicesPerformance from 'views/Monitor/SiteServices/SiteServicesPerformance'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
    height: calc(100vh - 154px);
`

const SiteServicesPerformanceContainer = () => {
    const { allSiteServicesPerSite, totalNumberSites, overallState } = useAppSelector(state => state.siteServicePerformance)
    const { filter } = useParams()
    const dispatch = useAppDispatch()

    useBreadCrumbs([i18nKeys.breadcrumb_site_service_performance])

    const handleFetchAction = useCallback((params: TGetCardPageRequest) => {
        dispatch(fetchSiteServicePerformanceAsync(params))
    }, [dispatch])

    const restartSiteServices = async (siteServices: TPerformanceItem[]) => {
        const ids = siteServices.map(siteService => siteService.entityId)

        try {
            await siteServiceService.restartServices(ids)
            dispatch(showMessage({message: i18nKeys.site_services_table_restart_service_job_created_success, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.site_services_table_restart_service_job_created_error, type: EMessageType.ERROR}))
        }
    }

    const ignoreSiteServices = async (siteServices: TPerformanceItem[]) => {
        const ids = siteServices.map(siteService => siteService.entityId)

        try {
            await SiteServicePerformanceService.ignoreSiteServices(ids)
            dispatch(showMessage({message: i18nKeys.site_service_performance_ignore_success, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.site_service_performance_ignore_error, type: EMessageType.ERROR}))
        }
    }

    const startSiteServices = async (siteServices: TPerformanceItem[]) => {
        const ids = siteServices.map(siteService => siteService.entityId)

        try {
            await siteServiceService.startServices(ids)
            dispatch(showMessage({message: i18nKeys.site_service_performance_start_success, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.site_service_performance_start_error, type: EMessageType.ERROR}))
        }
    }

    const stopSiteServices = async (siteServices: TPerformanceItem[]) => {
        const ids = siteServices.map(siteService => siteService.entityId)

        try {
            await siteServiceService.stopServices(ids)
            dispatch(showMessage({message: i18nKeys.site_service_performance_stop_success, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.site_service_performance_stop_error, type: EMessageType.ERROR}))
        }
    }

    return (
        <ViewWrapper>
            <SiteServicesPerformance
                totalNumberSites={totalNumberSites}
                allSiteServicesPerSite={allSiteServicesPerSite}
                handleFetchAction={handleFetchAction}
                overallState={overallState}
                preSelectedState={EnumHelper.parseStringToEPerformanceState(filter)}
                onRestartSiteServices={restartSiteServices}
                onIgnoreSiteServices={ignoreSiteServices}
                onStartSiteServices={startSiteServices}
                onStopSiteServices={stopSiteServices}
                showSetThresholdsSiteServices={true}
            />
        </ViewWrapper>
    )
}

export default SiteServicesPerformanceContainer