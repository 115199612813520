import { ETableFilters } from 'enums/enums'
import AppConstants from 'helpers/AppConstants'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'

const columnConfig: TTableColumnsConfig = {
    filename: {
        key: 'filename',
        translationKey: i18nKeys.audit_reports_table_head_filename.id,
        sortKey: 'Filename',
        displayed: true,
        filter: {
            key: 'Filename',
            type: ETableFilters.INPUT_TEXT
        }
    },
    siteName: {
        key: 'siteName',
        translationKey: i18nKeys.audit_reports_table_head_siteName.id,
        sortKey: 'SiteName',
        groupKey: 'SiteName',
        filter: {
            key: 'SiteName',
            type: ETableFilters.INPUT_TEXT
        },
        displayed: true
    },
    createdAt: {
        key: 'createdAt',
        translationKey: i18nKeys.audit_reports_table_head_createdAt.id,
        sortKey: 'CreatedAt',
        displayed: true
    },
    from: {
        key: 'from',
        translationKey: i18nKeys.audit_reports_table_head_from.id,
        sortKey: 'From',
        displayed: true
    },
    till: {
        key: 'till',
        translationKey: i18nKeys.audit_reports_table_head_till.id,
        sortKey: 'Till',
        displayed: true
    }
}

const defaultLayout = [
    columnConfig.filename.key,
    columnConfig.siteName.key,
    columnConfig.createdAt.key,
    columnConfig.from.key,
    columnConfig.till.key
]

export const tableInfo : TTableInfo = {
    id: AppConstants.tableIds.AUDIT_REPORTS,
    columnConfig,
    defaultLayout
}