import axiosClient from 'api/axiosClient'
import { TFetchParamsRequest, TGroupParams, TGroupResponse, TQueryFilter } from 'types/network'
import { TSiteMin, TPluginType, TSite, TDeploySite, TSiteAllReportsRequest, TSiteAuditRequest, TAllSiteAuditRequest, TSiteAllApproveRequest, TDeleteAllSitesRequest, TCheckLicenseResponse, TUpdateCommentsRequest, TCustomComment, TExportAllSitesRequest, TUpdateAllCommentsRequest, TDeployMonitoringPlugin, TSiteAllIgnoreRequest, SetThresholdsSitesRequest, SetAllThresholdsSitesRequest } from 'types/businessLogic/site'
import { TChangeAllUserPasswordJobRequest, TChangeUserPasswordJobRequest } from 'types/businessLogic/job'
import { AxiosResponse } from 'axios'
import { TSiteLabel } from 'types/businessLogic/misc'
import { ENotificationPriority } from 'enums/enums'

class SiteService {
    private static _api = axiosClient()

    public static async getSites(params: TFetchParamsRequest) {
        const response = await this._api.get<{sites: TSite[], totalNumber: number}>('Sites', {
            params
        })
        return response.data
    }

    public static async deploySite(params: TDeploySite) {
        const response = await this._api.post('Sites', params, {
            responseType: 'arraybuffer',
            timeout: 5 * 60 * 1000,
        })

        const blob = new Blob([response.data], {type: response.headers['content-type']})
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.href = url
        const contentDisposition = response.headers['content-disposition']
        let fileName = 'deploy.zip'
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+);/)
            if (fileNameMatch && fileNameMatch.length === 2)
                fileName = fileNameMatch[1]
        }
        link.setAttribute('download', fileName)
        link.click()
        window.URL.revokeObjectURL(url)
        link.remove()
    }

    public static async deployMonitoringPlugin(params: TDeployMonitoringPlugin) {
        const response = await this._api.post('Sites/MonitoringPlugin', params, {
            responseType: 'arraybuffer',
            timeout: 5 * 60 * 1000,
        })

        const blob = new Blob([response.data], {type: response.headers['content-type']})
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.href = url
        const contentDisposition = response.headers['content-disposition']
        let fileName = 'monitoring_plugin_deploy.zip'
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+);/)
            if (fileNameMatch && fileNameMatch.length === 2)
                fileName = fileNameMatch[1]
        }
        link.setAttribute('download', fileName)
        link.click()
        window.URL.revokeObjectURL(url)
        link.remove()
    }

    public static async deleteSites(ids: number[]) {
        await this._api.delete('Sites', {
            data: {
                Ids: ids
            }
        })
    }

    public static async getSite(id: number) {
        const response = await this._api.get<TSite>(`Sites/${id}`)
        return response.data
    }

    public static async updateSites(sites: TSiteMin[]) {
        await this._api.post('Sites/Update', { sites })
    }

    public static async getGroups(params: TGroupParams) {
        const response = await this._api.get<TGroupResponse>(`Sites/groups/${params.groupBy}`, {
            params: {
                Descending: params.descending,
                Filters: params.filters,
                PageSize: params.pageSize,
                PageNumber: params.pageNumber
            }
        })
        return response.data
    }

    public static async createSiteReports(siteIds: number[]) {
        await this._api.post('Sites/Report', {
            SiteIds: siteIds
        })
    }

    public static async createAllSitesReports(params: TSiteAllReportsRequest) {
        const response = await this._api.post('Sites/All/Report', params)
        return response.data
    }

    public static async createSiteAuditReport(params: TSiteAuditRequest) {
        const response = await this._api.post('Sites/AuditReport', params)
        return response.data
    }

    public static async createAllSiteAuditReport(params: TAllSiteAuditRequest) {
        const response = await this._api.post('Sites/All/AuditReport', params)
        return response.data
    }

    public static async getPluginTypes() {
        const response = await this._api.get<TPluginType[]>('Sites/Types')
        return response.data
    }

    public static async approveSites(ids: number[]) {
        const response = await this._api.post('Sites/Approve', {Ids: ids})
        return response.data
    }

    public static async approveAllSites(params: TSiteAllApproveRequest) {
        const response = await this._api.post('Sites/All/Approve', params)
        return response.data
    }

    public static async ignoreSites(ids: number[]) {
        const response = await this._api.post('Sites/ignore', {Ids: ids})
        return response.data
    }

    public static async ignoreAllSites(params: TSiteAllIgnoreRequest) {
        const response = await this._api.post('Sites/All/ignore', params)
        return response.data
    }

    public static async deleteAllSites(params: TDeleteAllSitesRequest) {
        await this._api.delete('Sites/All', {
            data: params
        })
    }

    public static async checkSiteLicense(license: File) : Promise<TCheckLicenseResponse> {
        const formData = new FormData()
        formData.append('license', license)
        const response = await this._api.post<TCheckLicenseResponse>('Sites/CheckLicense', formData, {
            headers: {'Content-Type': 'multipart/form-data'},
        })

        return response.data
    }

    public static async uploadSiteLicense(license: File) {
        const formData = new FormData()
        formData.append('license', license)
        await this._api.post('Sites/UploadLicense', formData, {
            headers: {'Content-Type': 'multipart/form-data'},
        })
    }

    public static async changeConnectionUserPassword(params: TChangeUserPasswordJobRequest) {
        const response = await this._api.post('Sites/ConnectionUserPassword', params)
        return response.data
    }
    
    public static async changeAllConnectionUserPassword(params: TChangeAllUserPasswordJobRequest) {
        const response = await this._api.post('Sites/All/ConnectionUserPassword', params)
        return response.data
    }

    public static async updateAllComments(params: TUpdateAllCommentsRequest) {
        const response = await this._api.post('Sites/All/Comments', params)
        return response.data
    }

    public static async updateComments(params: TUpdateCommentsRequest) {
        const response = await this._api.post('Sites/Comments', params)
        return response.data
    }

    public static async getAllCommonComments(filters?: TQueryFilter[]) : Promise<TCustomComment[]> {
        const response = await this._api.post<TCustomComment[]>('Sites/All/CommonComments', {
            Filters: filters
        })
        return response.data
    }

    public static async getCommonComments(ids: number[]) : Promise<TCustomComment[]> {
        const response = await this._api.post<TCustomComment[]>('Sites/CommonComments', {
            Ids: ids
        })
        return response.data
    }

    public static async sitesExport(ids: number[]) {
        const response = await this._api.post('Sites/Export', {
            Ids: ids
        })

        this.downloadSiteExportCsv(response)
    }

    public static async allSitesExport(params: TExportAllSitesRequest) {
        const response = await this._api.post('Sites/All/Export', params)

        this.downloadSiteExportCsv(response)
    }

    public static downloadSiteExportCsv(response: AxiosResponse<any>) {
        const blob = new Blob([response.data], {type: response.data.type})
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const contentDisposition = response.headers['content-disposition']
        let fileName = 'sites_export.csv'
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+);/)
            if (fileNameMatch && fileNameMatch.length === 2)
                fileName = fileNameMatch[1]
        }
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
    }

    public static async restartSites(siteIds: number[]) {
        await this._api.post('Sites/Restart', {
            Ids: siteIds
        })
    }

    public static async setThresholdsSites(request : SetThresholdsSitesRequest) {
        await this._api.post('Sites/Thresholds', {
            Ids: request.ids,
            CpuThresholdWarning : request.cpuThresholds[0],
            CpuThresholdCritical : request.cpuThresholds[1],
            CpuUseDefaultThresholds : request.cpuUseDefault ?? false,
            MemoryThresholdWarning : request.memoryThresholds[0],
            MemoryThresholdCritical : request.memoryThresholds[1],
            MemoryUseDefaultThresholds : request.memoryUseDefault ?? false,
            DiskThresholdWarning : request.diskThresholds[0],
            DiskThresholdCritical : request.diskThresholds[1],
            DiskUseDefaultThresholds : request.diskUseDefault ?? false,
            NetworkThresholdWarning: request.networkThresholds[0],
            NetworkThresholdCritical: request.networkThresholds[1],
            NetworkUseDefaultThresholds: request.networkUseDefault ?? false
        })
    }

    public static async allSetThresholdsSites(request : SetAllThresholdsSitesRequest) {
        await this._api.post('Sites/All/Thresholds', {
            Filters: request.filters,
            CpuThresholdWarning : request.cpuThresholds[0],
            CpuThresholdCritical : request.cpuThresholds[1],
            CpuUseDefaultThresholds : request.cpuUseDefault ?? false,
            MemoryThresholdWarning : request.memoryThresholds[0],
            MemoryThresholdCritical : request.memoryThresholds[1],
            MemoryUseDefaultThresholds : request.memoryUseDefault ?? false,
            DiskThresholdWarning : request.diskThresholds[0],
            DiskThresholdCritical : request.diskThresholds[1],
            DiskUseDefaultThresholds : request.diskUseDefault ?? false,
            NetworkThresholdWarning: request.networkThresholds[0],
            NetworkThresholdCritical: request.networkThresholds[1],
            NetworkUseDefaultThresholds: request.networkUseDefault ?? false
        })
    }

    public static async setNotificationPriority(ids: number[], notificationPriority: ENotificationPriority) {
        await this._api.post('Sites/NotificationPriority', { Ids: ids, NotificationPriority: notificationPriority })
    }

    public static async sallSetNotificationPriority(notificationPriority: ENotificationPriority, filters?: TQueryFilter[]) {
        await this._api.post('Sites/All/NotificationPriority', { Filters: filters, NotificationPriority: notificationPriority })
    }

    public static async getCommonLabels(siteIds : number[]) : Promise<TSiteLabel[]> {
        const response = await this._api.post<TSiteLabel[]>('Sites/CommonLabels', {
            Ids: siteIds
        })
        return response.data
    }

    public static async getAllCommonLabels(filters: TQueryFilter[] | undefined) : Promise<TSiteLabel[]> {
        const response = await this._api.post<TSiteLabel[]>('Sites/All/CommonLabels', {
            Filters: filters
        })
        return response.data
    }

    public static async setLabels(siteIds: number[], labelsToAdd: string[], labelsToDelete: string[], overrideAll: boolean) {
        await this._api.post<TSiteLabel[]>('Sites/SetLabels', {
            Ids: siteIds,
            LabelsToAdd: labelsToAdd,
            LabelsToDelete: labelsToDelete,
            OverrideAll: overrideAll
        })
    }

    public static async setAllLabels(filters: TQueryFilter[] | undefined, labelsToAdd: string[], labelsToDelete: string[], overrideAll: boolean) {
        await this._api.post<TSiteLabel[]>('Sites/All/SetLabels', {
            Filters: filters,
            LabelsToAdd: labelsToAdd,
            LabelsToDelete: labelsToDelete,
            OverrideAll: overrideAll
        })
    }
}

export default SiteService