import { Dayjs } from 'dayjs'
import { EPerformanceState } from 'enums/enums'
import { TCardPageSelectAllFilters, TPerformanceItem, TPerformanceItemsPerSite } from 'types/businessLogic/performance'

export type TPagedSiteSystem = {
    results: TSiteSystemPerformance[]
    currentPage: number
    pageCount: number
    pageSize: number
    recordCount: number
}

export type TSiteSystemsPerSite = TPerformanceItemsPerSite

export type TSiteSystemPerformance = TPerformanceItem & {
    highestNetworkInPercent: number
    networkState: EPerformanceState
}

export type TIgnoreSiteSystemParams = {
    ids: (number | string)[]
    filters?: TCardPageSelectAllFilters
}

export function isTSiteSystemPerformance(item: TPerformanceItem): item is TSiteSystemPerformance {
    return (item as TSiteSystemPerformance).networkState !== undefined
}

export type TSiteSystemStatistics = {
    cpu: number
    memory: number
    disks: TSiteSystemDisk[]
    networkAdapters: TSiteSystemNetwork[]
    isGap: boolean
    timestampUtc: Dayjs
}

export type TSiteSystemDisk = {
    name: string
    totalUsageInPercent: number
}

export type TSiteSystemNetwork = {
    name: string
    totalUsageInPercent: number
}

export type TSiteSystemDetailsResponse = {
    performanceMeasures: TSiteSystemStatistics[]
}

export type TAggregatedNetworkAdapters = {
    [key: string]: {
        time: number
        value: number
    }[]
}