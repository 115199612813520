import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import SiteServicePerformanceService from 'services/siteServicePerformanceService'
import { showMessage } from 'state/slices/uiSlice'
import { TGetCardPageRequest, TOverallState } from 'types/businessLogic/performance'
import { TSiteServicesPerSite } from 'types/businessLogic/siteServicePerformance'

export type TSiteServicePerformanceState = {
    allSiteServicesPerSite: TSiteServicesPerSite[]
    totalNumberSites: number
    overallState: TOverallState
}

const initialState: TSiteServicePerformanceState = {
    allSiteServicesPerSite: [],
    totalNumberSites: 0,
    overallState: {
        critical: 0,
        healthy: 0,
        ignored: 0,
        offline: 0,
        warning: 0
    }
}

export const fetchSiteServicePerformanceAsync = createAsyncThunk('siteServicePerformanceSlice/fetchSiteServicesAsync', async (params: TGetCardPageRequest, thunkApi) => {
    try {
        const result = await Promise.all([
            SiteServicePerformanceService.getSiteServices(params.fetchParams, params.filterByStates),
            SiteServicePerformanceService.getOverallStates()
        ])
    
        return {
            siteServicesPerSite: result[0],
            overallStates: result[1]
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'systemSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSiteServicePerformanceAsync.fulfilled, (state, action) => {
            if (action.payload) {
                const { overallStates, siteServicesPerSite } = action.payload

                state.overallState = overallStates
                state.allSiteServicesPerSite = siteServicesPerSite.siteServices
                state.totalNumberSites = siteServicesPerSite.totalNumber
            }
        })
    }
})

export default slice.reducer