import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TCameraRequest, TGroup, TTableFetchState } from 'types/network'
import SiteCameras from './SiteCameras'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { clearGroups, getGroupDataAsync, getSiteCamerasAsync, getSiteCamerasPropertiesAsync } from 'state/slices/siteCameraSlice'
import { siteCameraService } from 'services/siteCameraService'
import { tableInfo } from 'views/Control/SiteCamera/siteCamerasTableColumns'
import SiteCamerasTablePreparer from 'views/Control/SiteCamera/siteCamerasTablePreparer'
import SiteCameraDetails from 'views/Control/SiteCamera/SiteCameraDetails/SiteCameraDetails'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { useModal } from 'hooks/modalProvider'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const SiteCamerasContainer = () => {
    const dispatch = useAppDispatch()
    const {showModal} = useModal()
    
    const { siteCameras, totalNumber, groups, properties } = useAppSelector(state => state.siteCamera)

    useBreadCrumbs([i18nKeys.breadcrumb_cameras])
           
    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildCameraGroupDataRequestFromTableState([], params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams,
            translationKey: group.translationKey
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const requestParams = GeneralHelper.buildRequestFromTableState(params)
            dispatch(getSiteCamerasAsync(requestParams as TCameraRequest))
            dispatch(getSiteCamerasPropertiesAsync())
        } else if (params.groupName) {
            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, params.groupName)
            dispatch(getGroupDataAsync(requestParams as TCameraRequest))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await siteCameraService.getSiteEntityGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }
            getGroups()
        }
    }, [dispatch])

    const openSiteCameraDetailsModal = (id: number) => {
        const siteCameraDetails = siteCameras.find(siteGroup => siteGroup.id === id)

        if (!siteCameraDetails) return
                
        showModal({
            content: <SiteCameraDetails siteCamera={siteCameraDetails} />,
            title: siteCameraDetails.name,
            description: i18nKeys.camera_details_properties_title
        })
    }

    const data = SiteCamerasTablePreparer.prepareSiteCameraRows(siteCameras, openSiteCameraDetailsModal)
    const preparedGroups = SiteCamerasTablePreparer.prepareGroups(groups, openSiteCameraDetailsModal)
    
    return (
        <ViewContainer>
            <SiteCameras
                data={data}
                groups={preparedGroups}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                dynamicPropertiesColumns={properties}
                getTableData={getTableData}
                getGroupData={getGroupData}
            />
        </ViewContainer>
    )
}

export default SiteCamerasContainer