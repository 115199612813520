import { IconButton, Menu, MenuItem } from '@mui/material'
import { CustomButton } from 'components/CustomControls/CustomButton'
import Icon, { Icons } from 'components/Icon/Icon'
import Toolbar from 'components/Toolbar/Toolbar'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { deleteDashboardLayoutAsync, getDashboardLayoutAsync, postDashboardLayoutAsync, setDashboardElement, setDashboardLayout, setDefaultDashboard } from 'state/slices/profileSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { DashboardLayoutMappings, EDashboardElements, EDashboardLayouts } from 'types/ui/dashboard'
import ChooseLayout from 'views/Dashboard/Modals/ChooseLayout'
import ElementSelection from 'views/Dashboard/Modals/ElementSelection'
import styled from 'styled-components'
import Dashboard from './Dashboard'
import { Layout } from 'react-grid-layout'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import DashboardHelper from 'helpers/DashboardHelper'
import { useModal } from 'hooks/modalProvider'

const AddElementsButton = styled(CustomButton)`
    height: auto;
    padding: 0 24px;
    min-height: 32px;
    font-family: LatoSemibold;
    margin-left: 32px;
`

const DashboardContainer = () => {
    const dispatch = useAppDispatch()
    const { dashboardLayout, rights } = useAppSelector(store => store.profile)
    
    const [editMode, setEditMode] = useState(false)
    const [anchorMenuEl, setAnchorMenuEl] = useState<HTMLButtonElement | null>(null)
    const license = useAppSelector(store => store.license)
    const {showModal} = useModal()

    useEffect(() => {
        dispatch(getDashboardLayoutAsync())
    }, [dispatch])

    useBreadCrumbs([i18nKeys.breadcrumb_dashboard])
    
    const saveLayout = (layout: EDashboardLayouts, elements: EDashboardElements[]) => {
        while (elements.length < DashboardLayoutMappings[layout].maxElements) {
            elements.push(EDashboardElements.Empty)
        }

        while (elements.length > DashboardLayoutMappings[layout].maxElements) {
            elements.pop()
        }
        
        dispatch(setDashboardLayout({
            advancedData: '',
            layout: layout,
            layoutElements: elements.map(x => ({ layoutElement: x }))
        }))

        setEditMode(false)
    }

    const handleAddChart = (position: number, element : EDashboardElements) => {
        dispatch(setDashboardElement({
            position: position,
            element: element
        }))
    }

    const handleEditModeChanged = () => {
        if (dashboardLayout.layout !== EDashboardLayouts.AdvancedLayout) {
            dispatch(setDashboardLayout({
                advancedData: '',
                layout: EDashboardLayouts.AdvancedLayout,
                layoutElements: dashboardLayout.layoutElements.filter(x => x.layoutElement !== EDashboardElements.Empty)
            }))
        }
        
        setEditMode(prevState => !prevState)
    }

    const handleDeleteCard = (position: number) => {
        dispatch(setDashboardElement({
            position: position,
            element: EDashboardElements.Empty
        }))
    }

    const handleMenuClose = () => {
        setAnchorMenuEl(null)
    }

    const handleOpenMenu = (element: HTMLButtonElement) => {
        setAnchorMenuEl(element)
    }

    const handleMenuClick = (action: () => void) => {
        action()
        setAnchorMenuEl(null)
    }

    const isMapInLayout = () : boolean =>
        dashboardLayout.layoutElements.findIndex(x => x.layoutElement === EDashboardElements.Map) !== -1
    
    const handleLayoutChanged = (layout: Layout[]) => {
        if (dashboardLayout.layout === EDashboardLayouts.AdvancedLayout) {
            dispatch(setDashboardLayout({
                advancedData: JSON.stringify(layout),
                layout: EDashboardLayouts.AdvancedLayout,
                layoutElements: dashboardLayout.layoutElements.filter(x => x.layoutElement !== EDashboardElements.Empty)
            }))
        }
    }

    const openElemetsSelectionModal = (position: number) => {
        showModal({
            content: <ElementSelection
                hideMapOption={isMapInLayout()}
                onAddElement={element => handleAddChart(position, element)} />,
            title: i18nKeys.umbrella_dashboard_select_card,
        })
    }

    const openLayoutModal = () => {
        showModal({
            content: <ChooseLayout onSave={(e) => saveLayout(e, dashboardLayout.layoutElements.map(x => x.layoutElement))} />,
            title: i18nKeys.umbrella_dashboard_choose_layout,
            description: i18nKeys.umbrella_dashboard_choose_layout_description
        })
    }

    return <>
        <Toolbar
            LeftContent={
                <>
                    <CustomIconButton
                        onClick={() => handleEditModeChanged()}
                        id='dashboard-editing-mode'
                        icon={Icons.EDITING}
                    />
                
                    { editMode && <AddElementsButton onClick={() => openElemetsSelectionModal(dashboardLayout.layoutElements.length)}>
                        <TransMessage {...i18nKeys.umbrella_dashboard_advanced_layout_add_new_item} />
                    </AddElementsButton> }
                </>
            }
            RightContent={
                <>
                    <NavLink to='/fullscreen/dashboard' id='dashboard-fullscreen'>
                        <IconButton onClick={() => handleEditModeChanged()} size='large'>
                            <Icon d={Icons.FULL_SCREEN} />
                        </IconButton>
                    </NavLink>
                    <IconButton
                        id='chooseLayout'
                        onClick={openLayoutModal}
                        size='large'>
                        <Icon d={Icons.DASHBOARD} />
                    </IconButton>
                    <IconButton
                        id='columnPresetContainer'
                        onClick={(e) => handleOpenMenu(e.currentTarget)}
                        size='large'>
                        <Icon d={Icons.CONFIGURATION} />
                    </IconButton>
                
                    <Menu
                        open={Boolean(anchorMenuEl)}
                        anchorEl={anchorMenuEl}
                        onClose={handleMenuClose}
                    >
                        <MenuItem id='newLayoutButton' onClick={() => handleMenuClick(() => saveLayout(dashboardLayout.layout, []))}>
                            <TransMessage {...i18nKeys.umbrella_dashboard_new_layout} />
                        </MenuItem>
                        <MenuItem id='saveLayoutButton' onClick={() => handleMenuClick(() => dispatch(postDashboardLayoutAsync(dashboardLayout)))}>
                            <TransMessage {...i18nKeys.umbrella_dashboard_save_layout} />
                        </MenuItem>
                        <MenuItem id='deleteLayoutButton' onClick={() => handleMenuClick(() => dispatch(deleteDashboardLayoutAsync()))}>
                            <TransMessage {...i18nKeys.umbrella_dashboard_delete_layout} />
                        </MenuItem>
                        <hr />
                        <MenuItem id='showDefaultButton' onClick={() => handleMenuClick(() => dispatch(setDefaultDashboard(DashboardHelper.buildDefaultLayout(license, rights))))}>
                            <TransMessage {...i18nKeys.umbrella_dashboard_show_default} />
                        </MenuItem>
                    </Menu>
                </>
            }
        />

        <Dashboard
            editMode={editMode}
            dashboardLayout={dashboardLayout}
            onAddOrReplace={openElemetsSelectionModal}
            onDeleteCard={handleDeleteCard}
            onLayoutChanged={handleLayoutChanged}
        />
    </>
}

export default DashboardContainer