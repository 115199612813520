import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TFetchParamsRequest } from 'types/network'
import { showMessage } from 'state/slices/uiSlice'
import { i18nKeys } from 'i18n/keys'
import { EMessageType } from 'enums/enums'
import { TTableGroup } from 'types/ui/table'
import { TCancelAndRetryJobs, TCancelJobsRequest, TJob, TRetryJobsRequest } from 'types/businessLogic/job'
import JobService from 'services/jobService'
import { setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'

export type TSiteJobState = {
    jobs: TJob[]
    totalNumber: number
    groups: TTableGroup<TJob>[]
}

const initialState : TSiteJobState = {
    jobs: [],
    groups: [],
    totalNumber: 0
}

export const getJobsAsync = createAsyncThunk('siteJob/getJobsAsync', async (params: TFetchParamsRequest, thunkApi) => {
    try {
        return await JobService.getJobs(params)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getGroupDataAsync = createAsyncThunk('siteJob/getGroupDataAsync', async (params: TFetchParamsRequest & { translationKey?: string}, thunkApi) : Promise<TTableGroup<TJob>> => {
    try {
        const result = await JobService.getJobs(params)
        return {
            name: params.GroupName!,
            translationKey: params.translationKey,
            count: result.totalNumber,
            data: result.jobs
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const cancelJobsAsync = createAsyncThunk('siteJob/cancelJobAsync', async (params: TCancelAndRetryJobs[], thunkApi) => {
    try {
        const request: TCancelJobsRequest = {
            jobsToCancel: params
        }
        const result = await JobService.cancelJobs(request)
        thunkApi.dispatch(showMessage({message: i18nKeys.site_jobs_table_cancel_jobs_success, type: EMessageType.SUCCESS}))
        return result
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.site_jobs_table_cancel_jobs_error, type: EMessageType.ERROR}))
        throw err
    }
})

export const retryJobsAsync = createAsyncThunk('siteJob/retryJobsAsync', async (params: {jobs: TCancelAndRetryJobs[], uploadManagerItemId: string | number }, thunkApi) => {
    try {
        const request: TRetryJobsRequest = {
            retryJobs: params.jobs
        }
        const result = await JobService.retryJobs(request)
        thunkApi.dispatch(setDoneOrErrorOnItem({ id: params.uploadManagerItemId}))
        thunkApi.dispatch(showMessage({message: i18nKeys.site_jobs_table_retry_jobs_succes, type: EMessageType.SUCCESS}))
        return result
    } catch (err) {
        thunkApi.dispatch(setDoneOrErrorOnItem({id: params.uploadManagerItemId, isError: true}))
        thunkApi.dispatch(showMessage({message: i18nKeys.site_jobs_table_retry_jobs_error, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'siteJob',
    initialState: initialState,
    reducers: {
        clearJobs: (state) => {
            state.jobs = []
        },
        clearGroups: (state, action : PayloadAction<{ totalNumber: number, groups: TTableGroup<TJob>[] }>) => {
            action.payload.groups.forEach(item => {
                const oldItem = state.groups.find(x => x.name === item.name)
                if (oldItem) {
                    item.data = oldItem.data
                }
            })

            state.groups = action.payload.groups
            state.totalNumber = action.payload.totalNumber
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getJobsAsync.fulfilled, (state, action) => {
                const { jobs, totalNumber } = action.payload
                state.jobs = jobs
                state.totalNumber = totalNumber
            })
            .addCase(getGroupDataAsync.fulfilled, (state, action) => {
                const existingGroup = state.groups.find(p => p.name === action.payload.name)
                if (existingGroup) {
                    existingGroup.data = action.payload.data
                    existingGroup.count = action.payload.count
                } else {
                    state.groups.push(action.payload)
                }

                action.payload.data?.forEach(x => {
                    const index = state.jobs.findIndex(y => y.id === x.id)
                    if (index === -1) {
                        state.jobs.push(x)
                    } else {
                        state.jobs[index] = x
                    }
                })
            })
    }
})

export const {clearGroups, clearJobs} = slice.actions

export default slice.reducer