import { Slider, Stack } from '@mui/material'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import styled from 'styled-components'

const Info = styled.div`
    font-size: 0.9rem;
`

const Wrapper = styled.div`
    padding-top: 16px;
    min-width: 380px;
    min-height: 54px;
    display: flex;
    flex-direction: column;
`

type FromThresholdSliderProps = UseControllerProps<any> & {
    min?: number
    max?: number
    disabled?: boolean
    minDistance: number
}

export const FromThresholdSlider = (props: FromThresholdSliderProps) => {
    const { field } = useController(props)

    const handleOnChange = (event: Event, newValue: number | number[], activeThumb: number) => {
        if (!Array.isArray(newValue)) {
            return
        }
      
        if (activeThumb === 0) {
            const value = [Math.min(newValue[0], field.value[1] - props.minDistance), field.value[1]]
            field.onChange(value)
        } else {
            const value = [newValue[0], Math.max(newValue[1], field.value[0] + props.minDistance)]
            field.onChange(value)
        }
    }

    return (
        <Wrapper>
            <Slider
                step={1}
                id={field.name}
                disabled={props.disabled}
                min={props.min}
                max={props.max}
                onChange={handleOnChange}
                value={field.value}
                name={field.name}
                ref={field.ref}
                track={false}
                valueLabelDisplay='auto'
                disableSwap
            />
            <Stack direction='row' justifyContent='space-between'>
                <Info>
                    <TransMessage {...i18nKeys.settings_thresholds_slider_warning} /> {field.value[0]}
                </Info>
                <Info>
                    <TransMessage {...i18nKeys.settings_thresholds_slider_critical} /> {field.value[1]}
                </Info>
            </Stack>
        </Wrapper>
    )
}