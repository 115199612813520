import TransMessage from 'components/TransMessage/TransMessage'
import { EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormGroup, FormLabel, FormWrapper, FormFooter, InputWrapper } from 'components/FormControls/FormControls'
import { TSiteEntity, TSiteEntityUpdate } from 'types/businessLogic/site'
import { updateSiteTimeProfileAsync } from 'state/slices/siteTimeProfileSlice'
import LabelsHelper from 'helpers/LabelsHelper'
import { FormAsyncCreatableSelect } from 'components/FormControls/FormAsyncCreatableSelect'
import { debounce } from '@mui/material'
import { TSelectOptions } from 'types/ui/dropdown'
import { useModal } from 'hooks/modalProvider'

type TProps = {
    siteTimeProfile: TSiteEntity
}

type TSiteTimeProfileDetailsForm = {
    labels: TSelectOptions
}

const validationSchema = yup.object({
})

const DetailsTab = (props: TProps) => {

    const {siteTimeProfile} = props
    const dispatch = useDispatch()
    const {closeModal} = useModal()

    const onSubmit = (values: TSiteTimeProfileDetailsForm) => {
        const labels = values.labels.map(option => option.value.toString())

        const siteEntityToUpdate: TSiteEntityUpdate = {
            id: siteTimeProfile.id,
            labels
        }

        dispatch(updateSiteTimeProfileAsync(siteEntityToUpdate))
        closeModal()
    }

    const authorize = useAuthorization()
    const isSiteAdmin = authorize(EProfileRights.IS_SITE_ADMIN)

    const prepareInitialValues = (timeProfile: TSiteEntity) : TSiteTimeProfileDetailsForm => {
        return {
            labels: timeProfile.labels.map(label => {
                return {
                    label: label,
                    value: label
                }
            })
        }
    }

    const [initialValues] = useState<TSiteTimeProfileDetailsForm>(prepareInitialValues(siteTimeProfile))

    const { handleSubmit, formState, control } = useForm<TSiteTimeProfileDetailsForm>({
        mode: 'onChange',
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            LabelsHelper.loadLabels(inputText).then((options) => callback(options))
        }, 500), [])

    const { isValid, isValidating, isSubmitting, isDirty } = formState

    return (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <FormContent>
                <FormGroup>
                    <InputWrapper>
                        <FormLabel htmlFor='name'>
                            <TransMessage {...i18nKeys.site_time_profile_general_name} />
                        </FormLabel>
                        <FormLabel>
                            {siteTimeProfile.name || ''}
                        </FormLabel>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='siteName'>
                            <TransMessage {...i18nKeys.site_time_profile_general_site} />
                        </FormLabel>
                        <FormLabel>
                            {siteTimeProfile.siteName || ''}
                        </FormLabel>
                        
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='labels'>
                            <TransMessage {...i18nKeys.site_time_profile_general_labels} />
                        </FormLabel>
                        <FormAsyncCreatableSelect
                            name='labels'
                            control={control}
                            isMulti
                            loadOptions={loadOptions}
                            defaultOptions
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            {
                isSiteAdmin &&
                    <FormFooter>
                        <FormButtonGroup>
                            <CustomButton
                                disabled={!isValid || isValidating || isSubmitting || !isDirty}
                                id='submitBtn'
                                type='submit'
                            >
                                <TransMessage {...i18nKeys.site_time_profile_general_update_details} />
                            </CustomButton>
                        </FormButtonGroup>
                    </FormFooter>
            }
        </FormWrapper>
    )
}

export default DetailsTab