import React, { useCallback, useRef, useState } from 'react'
import { MapContainer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import leaflet from 'leaflet'
import 'react-leaflet-markercluster/dist/styles.min.css'
import { useAppDispatch, useAppSelector } from 'state/store'
import { getMapMarkerAsync } from 'state/slices/mapSlice'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { buildCluster, TClusterType} from 'components/CustomMap/Cluster'
import { AutoSizer, Size } from 'react-virtualized'
import { EDataSyncItems } from 'enums/enums'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import { CustomTileLayer } from './CustomTileLayer'
import { MarkerRenderer } from './MarkerRenderer'

const config = {
    DEFAULT_LATITUDE: 54.525961,
    DEFAULT_LONGITUDE: 15.255119,
    DEFAULT_ZOOM_LEVEL: 3
}

type CustomMapProps = {
    onMarkerSelected?: (id: number) => void
}

const CustomMap = ({ onMarkerSelected } : CustomMapProps) => {
    const { markers } = useAppSelector(store => store.map)
    const { language } = useAppSelector(store => store.ui)
    const [identifier] = useState(uid())

    const dispatch = useAppDispatch()
    
    const loadData = useCallback(() => {
        dispatch(getMapMarkerAsync())
    }, [dispatch])

    useBackgroundService(EDataSyncItems.SITES, loadData, identifier)
    
    const mapRef = useRef<HTMLDivElement>(null)

    const handleResize = (info: Size) => {
        if (mapRef.current) {
            (mapRef.current.children[0] as any).style.width = `${info.width}.px`;
            (mapRef.current.children[0] as any).style.height = `${info.height}.px`
        }
    }
    
    return (
        <AutoSizer onResize={handleResize}>
            {({width, height}) => {
                if (width > 0 && height > 0) {
                    return <div ref={mapRef}>
                        <MapContainer
                            center={[config.DEFAULT_LATITUDE, config.DEFAULT_LONGITUDE]}
                            zoom={config.DEFAULT_ZOOM_LEVEL}
                            scrollWheelZoom={true}
                            style={{
                                width: width,
                                height: height
                            }}
                        >
                            <CustomTileLayer language={language} />
                            <MarkerClusterGroup
                                showCoverageOnHover={false}
                                iconCreateFunction={(cluster: TClusterType) => leaflet.divIcon(buildCluster(cluster))}
                            >
                            
                                <MarkerRenderer markers={markers} onMarkerSelected={onMarkerSelected} />
                            </MarkerClusterGroup>
                        </MapContainer>
                    </div>
                }
            }}
        </AutoSizer>
    )
}

export default CustomMap
