import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EDataSyncItems } from 'enums/enums'

export type TDataSyncState = {
    items: EDataSyncItems[]
}

const initialState : TDataSyncState = {
    items: []
}

const slice = createSlice({
    name: 'dataSync',
    initialState: initialState,
    reducers: {
        addItem: (state, action: PayloadAction<EDataSyncItems>) => {
            if (!state.items.includes(action.payload)) {
                state.items.push(action.payload)
            }
        },
        removeItem: (state, action: PayloadAction<EDataSyncItems>) => {
            const index = state.items.indexOf(action.payload)
            if (index !== -1) {
                state.items.splice(index, 1)
            }
        }
    }
})

export const { addItem, removeItem } = slice.actions

export default slice.reducer