import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormWrapper, FormContent } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { logoutAsync } from 'state/slices/authSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import images from 'style/images'
import styled from 'styled-components'

const Wrapper = styled.div`
    background-color: ${colors.primary.DARK_PRIMARY};
    height: 100vh;
    width: 100vw;
    display: grid;
    place-items: center;
`

const CustomFormWrapper = styled(FormWrapper)`
    width: 524px;
    height: 500px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.shades.PURE_WHITE};
    padding: 24px 72px 64px 56px;
    margin-top: 60px;
    border-radius: ${borderRadius.SECONDARY};
    border-left: 16px solid ${colors.primary.DEFAULT_PRIMARY};
    margin-bottom: 103px;
`

const CustomFormContent = styled(FormContent)`
    display: grid;
    place-items: center;
    padding-left: 0px;
`

const QognifyImage = styled.img`
    max-width: 100px;
    margin-left: -20px;
`

const NoView = () => {
    const dispatch = useAppDispatch()
    const {isAuthenticated} = useAppSelector(store => store.auth)

    const handleBackToLogin = () => {
        dispatch(logoutAsync())
    }

    if (!isAuthenticated) {
        return <Navigate to='/' />
    }

    return (
        <Wrapper>
            <CustomFormWrapper>
                <QognifyImage src={images.qognifyLogo} alt='Qognify' />
                
                <CustomFormContent>
                    <div>
                        <TransMessage {...i18nKeys.norole_info_1} /><br />
                        <TransMessage {...i18nKeys.norole_info_2} />
                    </div>
                </CustomFormContent>
                <div>
                    <CustomButton
                        id='backToLoginBtn'
                        onClick={handleBackToLogin}
                        fullWidth
                    >
                        <TransMessage {...i18nKeys.norole_back_to_login} />
                    </CustomButton>
                </div>
            </CustomFormWrapper>
        </Wrapper>
    )
}

export default NoView