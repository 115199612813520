import React, { useCallback } from 'react'
import { clearGroups, deleteAllSiteUsersAsync, deleteSiteUsersAsync, getGroupDataAsync, getSiteUsersAsync, getSiteUsersPropertiesAsync } from 'state/slices/siteUserSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGroup, TTableFetchState } from 'types/network'
import { tableInfo } from 'views/Control/SiteUsers/siteUsersTableColumns'
import SiteUser from './SiteUsers'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { TModalProps } from 'types/ui/modal'
import { siteUserService } from 'services/siteUserService'
import SiteUsersTablePreparer from 'views/Control/SiteUsers/siteUsersTablePreparer'
import SiteUserDetails from 'views/Control/SiteUsers/SiteUserDetails/SiteUserDetails'
import { TJobRequest } from 'types/businessLogic/job'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { pushItem } from 'state/slices/uploadManagerSlice'
import { useIntl } from 'react-intl'
import { useModal } from 'hooks/modalProvider'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const SiteUsersContainer = () => {
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const {showModal} = useModal()

    const {siteUsers, groups, properties, totalNumber} = useAppSelector(state => state.siteUser)

    useBreadCrumbs([i18nKeys.breadcrumb_site_users])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams,
            translationKey: group.translationKey
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const requestParams = GeneralHelper.buildRequestFromTableState(params)
            dispatch(getSiteUsersAsync(requestParams))
            dispatch(getSiteUsersPropertiesAsync())
        } else if (params.groupName) {
            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, params.groupName)
            dispatch(getGroupDataAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await siteUserService.getSiteEntityGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }

            getGroups()
        }
    }, [dispatch])

    const openSiteUserDetailsModal = (id: number) => {
        const siteUserDetails = siteUsers.find(siteUser => siteUser.id === id)
        
        if (!siteUserDetails) {
            return
        }
        
        showModal({
            content: <SiteUserDetails siteEntity={siteUserDetails} />,
            title: siteUserDetails.name,
            description: i18nKeys.site_user_details_properties_title
        })
    }

    const data = SiteUsersTablePreparer.prepareSiteUserRows(siteUsers, openSiteUserDetailsModal)
    const preparedGroups = SiteUsersTablePreparer.prepareGroups(groups, openSiteUserDetailsModal)

    const deleteSiteUsers = (params: TModalProps) => {
        if (params.ids.length === 0 && params.filters) {
            const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_site_users_delete,
                {count: totalNumber})

            const uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

            const request: TJobRequest = {
                filters: params.filters
            }
            
            dispatch(pushItem(uploadManagerItem))
            dispatch(deleteAllSiteUsersAsync({request, uploadManagerItemId: uploadManagerItem.id}))
        } else {
            const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_site_users_delete,
                {count: (params.ids as number[]).length})
                    
            const uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

            dispatch(pushItem(uploadManagerItem))

            dispatch(deleteSiteUsersAsync({ids: params.ids as number[], uploadManagerItemId: uploadManagerItem.id}))
        }
    }

    return (
        <ViewContainer>
            <SiteUser
                data={data}
                groups={preparedGroups}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                dynamicPropertiesColumns={properties}
                getTableData={getTableData}
                deleteSiteUsers={(params: TModalProps) => deleteSiteUsers(params)}
                getGroupData={getGroupData}
            />
        </ViewContainer>
    )
}

export default SiteUsersContainer