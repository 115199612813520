import { yupResolver } from '@hookform/resolvers/yup'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormAsyncSelect } from 'components/FormControls/FormAsyncSelect'
import { FormWrapper, FormContent, FormButtonGroup, FormGroup, FormInput, FormLabel, FormFooter, InputWrapper } from 'components/FormControls/FormControls'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormHelper from 'helpers/FormHelper'
import Validation from 'helpers/Validation'
import UmbrellaRolesService from 'services/umbrellaRolesService'
import { updateUmbrellaUserAsync } from 'state/slices/umbrellaUserSlice'
import { useAppDispatch } from 'state/store'
import { TUmbrellaUser, TUpdateUmbrellaUserRequest } from 'types/businessLogic/user'
import { TQueryFilter } from 'types/network'
import * as yup from 'yup'
import { useIntl } from 'react-intl'
import { debounce } from '@mui/material'
import { TSelectOptions } from 'types/ui/dropdown'
import { useModal } from 'hooks/modalProvider'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

type TProps = {
    umbrellaUser: TUmbrellaUser
}

type TUpdateUmbrellaUserForm = {
    id: string
    name: string
    email?: string
    roles?: TSelectOptions
}

const UmbrellaUserDetails = (props: TProps) => {
    const {umbrellaUser} = props
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const {closeModal} = useModal()
    const [nameNotTaken, setNameNotTaken] = useState(true)

    const validationSchema = yup.object().shape({
        name: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)).test('name-not-taken', intl.formatMessage(i18nKeys.create_umbrella_user_error_unique_username), () => nameNotTaken),
        email: yup.string().email(intl.formatMessage(i18nKeys.create_user_input_email_not_matching_regex)).optional()
    })

    const loadRoles = async (inputValue: string) => {
        try {
            const filters : TQueryFilter[] = []

            if (inputValue.length > 0) {
                filters.push({
                    property: 'Name',
                    value: inputValue,
                    isExact: false
                })
            }
            const rolesResponse = await UmbrellaRolesService.getRoles({
                PageNumber: 1,
                PageSize: 20,
                Filters: filters
            })
    
            return rolesResponse.roles.map(umbrellaRole => {
                return {
                    value: umbrellaRole.name,
                    label: umbrellaRole.name
                }
            })
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const roleOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            loadRoles(inputText).then((options) => callback(options))
        }, 500), [])

    const handleUpdateUmbrellaUserSubmit = (formValues: TUpdateUmbrellaUserForm) => {
        FormHelper.removeEmptyFields(formValues)
        
        const updateUmbrellaUserRequest : TUpdateUmbrellaUserRequest = {
            id: formValues.id,
            name: formValues.name,
            email: formValues.email,
            roles: FormHelper.extractValuesFromOptionTypeBase(formValues.roles ?? [])
        }

        dispatch(updateUmbrellaUserAsync(updateUmbrellaUserRequest))

        closeModal()
    }

    const updateUmbrellaUserDefaultValues : TUpdateUmbrellaUserForm = {
        id: umbrellaUser.id,
        name: umbrellaUser.userName,
        roles: umbrellaUser.roles.map(umbrellaRole => {
            return {
                value: umbrellaRole,
                label: umbrellaRole
            }
        }),
        email: umbrellaUser.email ?? undefined
    }

    const { handleSubmit, formState, control, trigger } = useForm<TUpdateUmbrellaUserForm>({
        mode: 'onChange',
        defaultValues: updateUmbrellaUserDefaultValues,
        resolver: yupResolver(validationSchema)
    })
    
    const { isValid, isValidating, isSubmitting, isDirty } = formState
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleNameChange = useCallback(debounce((value: string) => {
        Validation.isUmbrellaUserNameNotTaken(value, umbrellaUser.userName).then(result => {
            setNameNotTaken(result)
            trigger('name')
        })
    }, 250), [])
    
    return (
        <FormWrapper onSubmit={handleSubmit(handleUpdateUmbrellaUserSubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_site_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='name'>
                            <TransMessage {...i18nKeys.create_user_input_username_label} />
                        </FormLabel>
                        <FormInput
                            name='name'
                            control={control}
                            autoComplete='nope'
                            disabled={umbrellaUser.builtIn}
                            onChange={handleNameChange}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='email'>
                            <TransMessage {...i18nKeys.create_user_input_email_label} />
                        </FormLabel>
                        <FormInput
                            name='email'
                            control={control}
                            disabled={umbrellaUser.builtIn}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='roles'>
                            <TransMessage {...i18nKeys.umbrella_user_form_label_roles} />
                        </FormLabel>
                        <FormAsyncSelect
                            name='roles'
                            control={control}
                            isMulti
                            defaultOptions
                            loadOptions={roleOptions}
                            disabled={umbrellaUser.builtIn}
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={umbrellaUser.builtIn || !isValid || isValidating || isSubmitting || !isDirty}
                        id='submitBtn'
                        type='submit'
                        endIcon={<Icon d={Icons.TICK} />}
                    >
                        <TransMessage {...i18nKeys.umbrella_user_details_update_button} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}

export default UmbrellaUserDetails
