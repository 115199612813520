import { yupResolver } from '@hookform/resolvers/yup'
import { debounce } from '@mui/material'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormFooter, FormInput, FormWrapper, InputWrapper, FormGroup, FormLabel } from 'components/FormControls/FormControls'
import { useModal } from 'hooks/modalProvider'
import TransMessage from 'components/TransMessage/TransMessage'
import Validation from 'helpers/Validation'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { updateLabelAsync } from 'state/slices/labelSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TModalProps } from 'types/ui/modal'
import * as yup from 'yup'

type TEditLabelForm = {
    id: number
    name: string
}

const EditLabelModal = (props: TModalProps) => {
    const dispatch = useAppDispatch()
    const intl = useIntl()
    const {closeModal} = useModal()
    const [nameNotTaken, setNameNotTaken] = useState(false)
    
    const label = useAppSelector(x => x.labels.labels.find(l => l.id === props.ids[0] as number))

    let defaultValues : TEditLabelForm | undefined = undefined

    if (label) {
        defaultValues = {
            id: label.id,
            name: label.name,
        }
    }
    
    const validationSchema = yup.object().shape({
        name: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)).test('name-not-taken', intl.formatMessage(i18nKeys.edit_label_error_unique_labelname), () => nameNotTaken),
    })

    const { handleSubmit, formState, control, trigger} = useForm<TEditLabelForm>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema, {
            strict: true
        })
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleNameChange = useCallback(debounce((value: string) => {
        Validation.isLabelNameNotTaken(value, '').then(result => {
            setNameNotTaken(result)
            trigger('name')
        })
    }, 250), [])

    const handleEditLabelSubmit = (formValues: TEditLabelForm) => {
        dispatch(updateLabelAsync(formValues))
        closeModal()
    }

    if (!label) {
        return <></>
    }

    const { isValid, isValidating, isSubmitting, isDirty } = formState

    return (
        <FormWrapper onSubmit={handleSubmit(handleEditLabelSubmit)}>
            <FormContent>
                <FormGroup>
                    <InputWrapper>
                        <FormLabel htmlFor='name'>
                            <TransMessage {...i18nKeys.edit_label_name_label} />
                        </FormLabel>
                        <FormInput name='name'
                            control={control}
                            onChange={handleNameChange}
                            defaultValue=''
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.edit_label_button_label} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}
export default EditLabelModal