import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'
import borderRadius from 'style/borderRadius'
import Icon, { Icons } from 'components/Icon/Icon'
import { TTranslationKey } from 'types/ui/translation'
import { i18nKeys } from 'i18n/keys'

const UploadImageButton = styled.div`
  width: 64px;
  height: 36px;
  background-color: ${colors.primary.DEFAULT_PRIMARY};
  border-radius: ${borderRadius.PRIMARY};
  position: relative;
  top: -28px;
  right: -264px;
  display: grid;
  place-items: center;
  fill: ${colors.shades.PURE_WHITE};
`

const isValidImage = (format: string) => {
    const validImageFormats = ['image/jpg', 'image/png', 'image/jpeg']
    return validImageFormats.some((validFormat: string) => validFormat === format)
}

const MAX_PROFILE_IMAGE_FILE_SIZE = 5 //MB

const isValidImageSize = (sizeInBytes: number) => {
    return (sizeInBytes / 1024 / 1024) < MAX_PROFILE_IMAGE_FILE_SIZE
}

type TProps = {
    onChange: (image: File) => void
    onError: (message: TTranslationKey) => void
}

const ImageUpload = (props: TProps) => {

    const ref = React.createRef<HTMLInputElement>()

    const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.bubbles = false
        event.preventDefault()
        ref.current?.click()
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event && event.target && event.target.files) {
            const image = event.target.files[0]

            if (!isValidImage(image.type)) {
                props.onError(i18nKeys.profile_drawer_upload_profile_image_invalid_format)
                return
            }

            if (!isValidImageSize(image.size)) {
                props.onError(i18nKeys.profile_drawer_upload_profile_image_size_limit)
                return
            }

            props.onChange(image)
        }
    }

    return (
        <>
            <input
                accept='image/jpeg,image/jpg,image/png'
                onChange={handleOnChange}
                ref={ref}
                type='file'
                style={{display: 'none'}} />
            <UploadImageButton onClick={handleOnClick}>
                <Icon d={Icons.UPLOAD} />
            </UploadImageButton>
        </>
    )
}

export default ImageUpload