import axiosClient from 'api/axiosClient'
import { TFetchParamsRequest, TGroupParams, TGroupResponse } from 'types/network'
import { TCreateUmbrellaUserRequest, TUpdateUmbrellaUserRequest, TUmbrellaUser } from 'types/businessLogic/user'

class UmbrellaUserService {
    private static _api = axiosClient()

    public static async getUsers(params: TFetchParamsRequest): Promise<{users: TUmbrellaUser[], totalNumber: number}> {
        const response = await this._api.get<{users: TUmbrellaUser[], totalNumber: number}>('Users', {
            params: params
        })
        return response.data
    }
    
    public static async getUser(id: string) {
        const response = await this._api.get<TUmbrellaUser>(`Users/${id}`)
        return response.data
    }

    public static async createUser(params: TCreateUmbrellaUserRequest) {
        await this._api.post('Users', params)
    }

    public static async deleteUsers(ids: string[]) {
        await this._api.delete('Users', {
            data: {
                Ids: ids
            }
        })
    }

    public static async updateUser(id: string, params: TUpdateUmbrellaUserRequest) {
        await this._api.post(`Users/${id}`, params)
    }

    public static async getUserGroups(params: TGroupParams) {
        const response = await this._api.get<TGroupResponse>(`Users/groups/${params.groupBy}`, {
            params: {
                Descending: params.descending,
                Filters: params.filters,
                PageSize: params.pageSize,
                PageNumber: params.pageNumber
            }
        })
        return response.data
    }
   
    public static async userExists(name: string) {
        const response = await this._api.get<boolean>(encodeURI(`Users/Exists/${name}`))
        return response.data
    }
}

export default UmbrellaUserService
