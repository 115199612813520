import CustomLink from 'components/CustomControls/CustomLink'
import StatusDot from 'components/StatusDot/StatusDot'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import SiteHelper from 'helpers/SiteHelper'
import { TJob } from 'types/businessLogic/job'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { tableInfo } from 'views/SiteJobs/siteJobTableColumns'
import DateHelper from 'helpers/DateHelper'

class SiteJobTablePreparer {

    static getRowId = (job: TJob): string =>
        job.job === 'BatchJob' ? `batchJob${job.id}` : job.id.toString()

    static prepareJobs = (jobs: TJob[], openJobDetailsModal: (jobId: number) => void): Array<TTableRowData & {job: string}> =>
        jobs.map(job => ({
            id: SiteJobTablePreparer.getRowId(job),
            job: job.job,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.name.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    propertyType: job.job === 'BatchJob' ? GeneralHelper.propertyTypes.link.index : GeneralHelper.propertyTypes.customRender.index,
                    ...SiteJobTablePreparer.getRenderer(job, openJobDetailsModal)
                },
                {
                    name: tableInfo.columnConfig.state.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.state.translationKey,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: (propertyName: string, rowId: string) => (
                        <StatusDot
                            id={`${propertyName}${rowId}`}
                            color={SiteHelper.getJobStateColor(job.state)}
                            textKey={SiteHelper.getJobStateIntlKey(job.state)}
                        />
                    )
                },
                {
                    name: tableInfo.columnConfig.createdBy.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.createdBy.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: job.createdBy
                },
                {
                    name: tableInfo.columnConfig.created.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.created.translationKey,
                    propertyType: GeneralHelper.propertyTypes.dateString.index,
                    [GeneralHelper.propertyTypes.dateString.key]: job.createdAt
                },
                {
                    name: tableInfo.columnConfig.updated.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.updated.translationKey,
                    propertyType: GeneralHelper.propertyTypes.dateString.index,
                    [GeneralHelper.propertyTypes.dateString.key]: job.updated
                },
                {
                    name: tableInfo.columnConfig.sites.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.sites.translationKey,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: job.sites
                },
                {
                    name: tableInfo.columnConfig.about.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.about.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: job.about
                },
                {
                    name: tableInfo.columnConfig.comment.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.comment.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: job.comment
                },
                {
                    name: tableInfo.columnConfig.message.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.message.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: job.message || ''
                },
                {
                    name: tableInfo.columnConfig.sequenceId.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.sequenceId.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: job.sequenceId
                },
                {
                    name: tableInfo.columnConfig.sequenceOrder.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.sequenceOrder.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: job.sequenceOrder
                },
                {
                    name: tableInfo.columnConfig.sequenceTotal.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.sequenceTotal.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: job.sequenceTotalCount
                },
                {
                    name: tableInfo.columnConfig.executionTime.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.executionTime.translationKey,
                    propertyType: GeneralHelper.propertyTypes.dateString.index,
                    [GeneralHelper.propertyTypes.dateString.key]: job.executionTime ? DateHelper.formatUtcDate(job.executionTime, DateHelper.formats.DATE_TIME_SECONDARY) : null
                },
                // {
                //     name: tableInfo.columnConfig.undoTime.key,
                //     readOnly: false,
                //     translationKey: tableInfo.columnConfig.undoTime.translationKey,
                //     propertyType: GeneralHelper.propertyTypes.dateString.index,
                //     [GeneralHelper.propertyTypes.dateString.key]: job.undoTime ? DateHelper.formatUtcDate(job.undoTime, DateHelper.formats.DATE_TIME_SECONDARY) : null
                // },
                {
                    name: tableInfo.columnConfig.retries.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.retries.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: job.retries
                }
            ]
        }))

    static prepareGroups = (groups: TTableGroup<TJob>[], openJobDetailsModal: (jobId: number) => void): TTableGroup<TTableRowData & {job: string}>[] =>
        groups.map(group => ({
            ...group,
            data: group.data ? SiteJobTablePreparer.prepareJobs(group.data, openJobDetailsModal) : undefined
        }))

    static getRenderer = (job: TJob, openJobDetailsModal: (jobId: number) => void) => {
        const renderBatchjobLinkProps = {
            [GeneralHelper.propertyTypes.link.key]: {
                to: `/app/site-jobs/${job.id}`,
                text: <TransMessage {...i18nKeys[job.translationKey]} />
            }
        }

        const renderJobDetailsModalProps = {
            [GeneralHelper.propertyTypes.customRender.key]: () =>
                <CustomLink onClick={() => openJobDetailsModal(job.id as number)}>
                    <TransMessage {...i18nKeys[job.translationKey]} />
                </CustomLink>
        }

        return job.job === 'BatchJob' ? renderBatchjobLinkProps : renderJobDetailsModalProps
    }
}

export default SiteJobTablePreparer