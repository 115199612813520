import { SiteEntityService } from 'services/siteEntityService'
import { TAllCreateSiteUsersRequest, TChangeAllUserPasswordJobRequest, TChangeUserNameJobRequest, TChangeUserPasswordJobRequest, TCopySiteUsersJobRequest, TCreateUserJobRequest as TCreateSiteUserJobRequest, TJobRequest } from 'types/businessLogic/job'

class SiteUserService extends SiteEntityService {
    constructor() {
        super('SiteUsers')
    }

    public async changeSiteUserPassword(jobRequest: TChangeUserPasswordJobRequest) {
        await this._api.post(`${this.urlPrefix}/ChangePassword`, jobRequest)
    }

    public async changeAllSiteUserPassword(jobRequest: TChangeAllUserPasswordJobRequest) {
        await this._api.post(`${this.urlPrefix}/All/ChangePassword`, jobRequest)
    }

    public async changeSiteUserName(jobRequest: TChangeUserNameJobRequest) {
        await this._api.post(`${this.urlPrefix}/ChangeName`, jobRequest)
    }

    public async createSiteUser(jobRequest: TCreateSiteUserJobRequest) {
        await this._api.post(`${this.urlPrefix}`, jobRequest)
    }

    public async createAllSiteUsers(params: TAllCreateSiteUsersRequest) {
        await this._api.post(`${this.urlPrefix}/All`, params)
    }

    public async deleteSiteUsers(ids: number[]) {
        await this._api.delete(`${this.urlPrefix}`, {
            data: {
                Ids: ids
            }
        })
    }

    public async deleteAllSiteUsers(jobRequest: TJobRequest) {
        await this._api.delete(`${this.urlPrefix}/All`, {data: jobRequest})
    }

    public async copySiteUsers(params: TCopySiteUsersJobRequest) {
        await this._api.post(`${this.urlPrefix}/Copy`, params)
    }
}

export const siteUserService = new SiteUserService()