import { TableRow, TableCell } from '@mui/material'
import { CardHeader, TableCard, TableInfoBlock } from 'components/CustomControls/CustomCharts'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import DateHelper from 'helpers/DateHelper'
import SiteEventsService from 'services/siteEventsService'
import { TRecentSiteEvent } from 'types/businessLogic/siteEvent'
import { TTranslationKey } from 'types/ui/translation'
import { EDataSyncItems, EMessageType } from 'enums/enums'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import { showMessage } from 'state/slices/uiSlice'
import { dispatch } from 'state/store'

const RecentSiteEventsElement = () => {
    const [events, setEvents] = useState<TRecentSiteEvent[]>([])

    const headers: TTranslationKey[] = [
        i18nKeys.recent_site_events_column_description,
        i18nKeys.recent_site_events_column_site,
        i18nKeys.recent_site_events_column_timestamp
    ]

    const [identifier] = useState(uid())

    const loadData = useCallback(async () => {
        try {
            const response = await SiteEventsService.getRecentSiteEvents(30)
            setEvents(response)
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
    }, [])

    useBackgroundService(EDataSyncItems.SITE_EVENTS, loadData, identifier)

    const renderEvents = () => {
        return events.map((event, index) => {
            return (
                <>
                    <TableRow key={index}>
                        <TableCell>
                            <CustomTooltip title={event.description} placement='top-start'>
                                <span>{event.description}</span>
                            </CustomTooltip>
                        </TableCell>
                        <TableCell>
                            <CustomTooltip title={event.site} placement='top-start'>
                                <span>{event.site}</span>
                            </CustomTooltip>
                        </TableCell>
                        <TableCell>
                            {DateHelper.formatDate(event.timestamp, DateHelper.formats.DATE_TIME_SECONDARY)}
                        </TableCell>
                    </TableRow>
                </>
            )
        })
    }

    const renderTable = () => {
        return (
            <>
                <CardHeader title={i18nKeys.umbrella_dashboard_recent_site_events} />
                <TableCard headers={headers}>
                    {renderEvents()}
                </TableCard>
            </>
        )
    }

    return (
        <>
            { events.length ? renderTable() : <TableInfoBlock> <TransMessage {...i18nKeys.umbrella_dashboard_recent_site_events_no_events} /> </TableInfoBlock> }
        </>
    )
}

export default RecentSiteEventsElement