import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType } from 'enums/enums'
import React, { useCallback } from 'react'
import { hideMessage } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import colors from 'style/colors'
import fonts from 'style/fonts'
import shadows from 'style/shadows'
import styled, { keyframes } from 'styled-components'
import { TMessage } from 'types/ui/message'

const Animation = keyframes`
    0%, 100% { transform: translateY(200px); }
    20%, 80% { transform: translateY(0); }
`
const Wrapper = styled.div<{bgColor: string}>`
    font-family: ${fonts.PRIMARY_REGULAR};
    background-color: ${p => p.bgColor};
    box-shadow: ${shadows.PRIMARY};
    width: 300px;
    display: inline;
    border-radius: 8px;
    padding: 16px;
    color: ${colors.shades.LIGHT_WHITE};
    animation: ${Animation} 3s ease;
`

const Message = (props: TMessage) => {
    const dispatch = useAppDispatch()

    const onAnimationEnd = useCallback(() => {
        dispatch(hideMessage(props.id!))
    }, [dispatch, props.id])

    const backgroundColor = props.type === EMessageType.SUCCESS ? colors.extra.SUCCESS : colors.extra.ALERT
    
    return (
        <Wrapper bgColor={backgroundColor} onAnimationEnd={onAnimationEnd}>
            <TransMessage {...props.message} />
        </Wrapper>)
}

export default Message