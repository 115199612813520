import React from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import CustomDatePicker from 'components/CustomControls/CustomDatePicker'
import { FormControl, TextField } from '@mui/material'
import borderRadius from 'style/borderRadius'
import styled from 'styled-components'
import { FormControlResetButton } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import colors from 'style/colors'

const StyledTextField = styled(TextField)`
    &:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${colors.primary.DEFAULT_PRIMARY};   
    }
    min-width: 380px;
   
    background-color: white;
    height: 56px;

    .MuiInputBase-root.Mui-disabled {
        opacity: 0.4;
    }

    .MuiOutlinedInput-input {
        color: #303030;
        height: 56px;
        padding: 0 0 0 24px;
        font-size: 16px;
        font-family: LatoRegular;
        line-height: 56px;
        letter-spacing: 0.4px;

        &:disabled {
            opacity: 0.4;
        }
  }

  .MuiOutlinedInput-root {
    border-radius: ${borderRadius.SECONDARY};
  }
`
 
type TFormDateTimePickerProps = UseControllerProps<any> & {
    debug?: boolean
    disabled?: boolean
    disablePast?: boolean
    disableFuture?: boolean
    onChange?: (date: Date | null) => void
    onReset?: (name: string, value: any) => void
    formatter?: (date: Date) => string
}

export const FormDateTimePicker = (props: TFormDateTimePickerProps) => {
    const { field, fieldState } = useController(props)

    const handleOnChange = (date: Date | null) => {
        if (props.onChange) {
            props.onChange(date)
        }

        if (props.formatter && date) {
            field.onChange(props.formatter(date))
        } else {
            field.onChange(date)
        }
    }
    
    const handleReset = () => {
        props.onReset && props.onReset(field.name, props.defaultValue)
    }

    return (
        <>
            <FormControl>
                <CustomDatePicker
                    renderInput={
                        (inputProps) => <StyledTextField InputLabelProps={{shrink: false}} variant='outlined' {...inputProps} />
                    }
                    {...field}
                    disabled={props.disabled}
                    disablePast={props.disablePast}
                    disableFuture={props.disableFuture}
                    onChange={handleOnChange}
                />
            </FormControl>
            {
                props.onReset && props.defaultValue !== field.value &&
                    <FormControlResetButton
                        onClick={handleReset}>
                        <TransMessage {...i18nKeys.properties_form_button_reset} />
                    </FormControlResetButton>
            }
            {
                props.debug &&
                <>
                    <p>{fieldState.isTouched && 'Touched'}</p>
                    <p>{fieldState.isDirty && 'Dirty'}</p>
                    <p>{fieldState.invalid ? 'invalid' : 'valid'}</p>
                </>
            }
        </>
    )
}