import AdvancedJobConfigFields from 'components/AdvancedJobConfigFields/AdvancedJobConfigFields'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { TPropertyValue } from 'types/businessLogic/site'
import { TAdvancedJobConfigFields, TPropertyValueForm, TTabFormInputs } from 'types/ui/tab'
import GeneralHelper from 'helpers/GeneralHelper'
import { useAppSelector } from 'state/store'
import { FormButtonGroup, FormCheckbox, FormContent, FormFooter, FormGroup, FormInput, FormLabel, FormWrapper, InputWrapper, Instructions } from 'components/FormControls/FormControls'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormSelect } from 'components/FormControls/FormSelect'
import { FormDateTimePicker } from 'components/FormControls/FormDateTimePicker'
import { FormSlider } from 'components/FormControls/FormSlider'
import { FormNumberInput } from 'components/FormControls/FormNumberInput'
import { ERestrctionType } from 'enums/enums'
import { useIntl } from 'react-intl'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Validation from 'helpers/Validation'
import FormHelper from 'helpers/FormHelper'

type TProps = {
    groupName: string
    onSubmit?: (values: TPropertyValue[], advancedJobConfig: TAdvancedJobConfigFields, comment: string) => void
    properties: TPropertyValue[]
    readOnlyForm: boolean
    disableUpdateButtons?: boolean
    isChangeMultipleEntityProps: boolean
    hideFooter?: boolean
}

const DynamicPropertiesTabForm = (props: TProps) => {
    const {properties, readOnlyForm} = props

    const intl = useIntl()

    const formProperties : TPropertyValueForm[] = FormHelper.convertToPropertyValueForm(properties)

    const isCommentMandatory = useAppSelector(state => state.ui.uiSettings.isCommentMandatoryForJobs)
    const [initialValues, setInitialValues] = useState<TPropertyValueForm[]>(formProperties)

    const validationSchema = (mandatoryComment: boolean) => yup.object().shape({
        comment: mandatoryComment ? yup.string().max(500).required(intl.formatMessage(i18nKeys.general_validation_required_field)) : yup.string().max(500)
    })
    
    const { handleSubmit, formState, reset, getValues, setValue, control } = useForm<TTabFormInputs>({
        mode: 'onChange',
        defaultValues: {
            properties: initialValues,
            comment: ''
        },
        resolver: yupResolver(validationSchema(isCommentMandatory))
    })

    const handleReset = (name: string, value: any) => {
        const identifier = name.split('.')
        const val = {
            ...getValues(),
        } as any

        val[identifier[0]][identifier[1]][identifier[2]] = value

        reset(val)
    }

    const getUpdatedProperties = (initialProperties: TPropertyValueForm[], updatedProperties: TPropertyValueForm[]) => {
        return updatedProperties.filter((prop: TPropertyValueForm, index: number) => {
            const initialProp = initialProperties[index]

            if (!initialProp) return false

            const key = GeneralHelper.getPropertyKey(initialProp.propertyType)
            
            if (prop[key] === null) return false

            if (key === 'stringValue') {
                
                return JSON.stringify(initialProp[key]) !== JSON.stringify(prop[key])
            }
            return initialProp[key] !== prop[key]
        })
    }

    const onSubmit = (values: TTabFormInputs) => {
        const updatedProperties = getUpdatedProperties(initialValues, values.properties)
        const advancedJobConfig = values.advancedJobConfig || {}
        const comment = values.comment || ''
        
        const sendValues = FormHelper.convertToPropertyValue(updatedProperties)

        if (props.onSubmit) {
            props.onSubmit(sendValues, advancedJobConfig, comment)
        }
        
        setInitialValues(values.properties)
    }

    const renderFields = (items: TPropertyValueForm[]) => {
        return items.map((property, index) => {

            let content : JSX.Element = <></>

            if (property.restriction) {
                if (property.propertyType === GeneralHelper.propertyTypes.collection.index) {
                    content = <FormSelect disabled={property.readOnly} isMulti name={`properties.${index}.collectionValue`} defaultValue={property.collectionValue} onReset={handleReset} control={control} options={property.restriction.collection} />
                } else {
                    switch (property.restriction.restrictionType) {
                        case ERestrctionType.COLLECTION:
                            
                            content = <FormSelect disabled={property.readOnly} name={`properties.${index}.stringValue`} defaultValue={property.stringValue} onReset={handleReset} control={control} options={property.restriction.collection} />
                            break
                        case ERestrctionType.RANGE:
                            content = <FormSlider disabled={property.readOnly} onReset={handleReset} defaultValue={property.longValue} name={`properties.${index}.longValue`} max={property.restriction.max} min={property.restriction.min} control={control} />
                            break
                    }
                }
            } else {
                switch (property.propertyType) {
                    case GeneralHelper.propertyTypes.bool.index:
                        content = <FormCheckbox disabled={property.readOnly} block name={`properties.${index}.boolValue`} defaultValue={property.boolValue} onReset={handleReset} control={control} />
                        break
                    case GeneralHelper.propertyTypes.string.index:
                        content = <FormInput disabled={property.readOnly} name={`properties.${index}.stringValue`} defaultValue={property.stringValue} onReset={handleReset} control={control} type='text' />
                        break
                    case GeneralHelper.propertyTypes.long.index:
                        content = <FormNumberInput disabled={property.readOnly} name={`properties.${index}.longValue`} defaultValue={property.longValue} onReset={handleReset} control={control} />
                        break
                    case GeneralHelper.propertyTypes.dateTime.index:
                        content = <FormDateTimePicker disabled={property.readOnly} name={`properties.${index}.dateTimeValue`} defaultValue={property.dateTimeValue} onReset={handleReset} control={control} />
                        break
                    case GeneralHelper.propertyTypes.collection.index:
                        const options = property.collectionValue ?? []
                        content = <FormSelect disabled={property.readOnly} isMulti name={`properties.${index}.collectionValue`} defaultValue={property.collectionValue} onReset={handleReset} control={control} options={options} />
                        break
                }
            }

            return (
                <InputWrapper key={index}>
                    <FormLabel htmlFor={`properties.${index}`}>
                        <TransMessage {...i18nKeys[property.translationKey]} />
                    </FormLabel>
                    {content}
                </InputWrapper>
            )
        })
    }

    return (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <FormContent>
                <FormGroup>
                    {
                        renderFields(initialValues)
                    }
                </FormGroup>
                {
                    !readOnlyForm && !props.disableUpdateButtons &&
                <>
                    <FormGroup collapsable title={i18nKeys.advanced_job_configuration_title}>
                        <AdvancedJobConfigFields
                            formProps={formState}
                            setFieldValue={setValue}
                            control={control}
                        />
                    </FormGroup>
                    <FormGroup>
                        <InputWrapper>
                            <FormLabel htmlFor='comment'>
                                <TransMessage {...i18nKeys.job_configuration_comment} />
                            </FormLabel>
                            <FormInput
                                name='comment'
                                autoComplete='job-comment'
                                control={control}
                            />
                        </InputWrapper>
                    </FormGroup>
                </>
                }
            </FormContent>
            {
                !props.hideFooter && <>
                    <FormFooter>
                        <FormButtonGroup>
                            <CustomButton
                                disabled={!Validation.isDynamicPropertiesFormStateValid(formState, true)}
                                id='submitBtn'
                                type='submit'
                            >
                                <TransMessage {...i18nKeys.properties_form_button_create_jobs} />
                            </CustomButton>
                            <CustomButton
                                id='resetBtn'
                                type='reset'
                                onClick={() => reset({
                                    properties: initialValues,
                                    comment: ''
                                })}
                                $secondary
                            >
                                <TransMessage {...i18nKeys.properties_form_button_reset_all} />
                            </CustomButton>
                        </FormButtonGroup>
                        <Instructions>
                            <TransMessage {...i18nKeys.properties_form_note} />
                        </Instructions>
                    </FormFooter>
                </>
            }
            
        </FormWrapper>
    )
}

export default DynamicPropertiesTabForm
