import { FormCheckbox } from 'components/FormControls/FormControls'
import { FormDateTimePicker } from 'components/FormControls/FormDateTimePicker'
import { FormNumberInput } from 'components/FormControls/FormNumberInput'
import Icon from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import dayjs from 'dayjs'
import { i18nKeys } from 'i18n/keys'
import React, { useEffect, useState } from 'react'
import { Control, FormState, UseFormSetValue } from 'react-hook-form'
import colors from 'style/colors'
import fonts from 'style/fonts'
import styled from 'styled-components'
import { TAdvancedJobConfigFields } from 'types/ui/tab'

export const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const CollapseToggle = styled.div<{fullWidth: boolean}>`
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 710px;
    //margin-bottom: 20px;
    cursor: pointer;
    ${p => p.fullWidth ? 'width: 100%;' : ''}
`
const Title = styled.label`
    color: ${colors.primary.DARK_PRIMARY};
    width: inherit;
    font-size: 16px;
    max-width: 300px;
    font-family: LatoSemibold;
    line-height: 24px;
    letter-spacing: 0.4px;
`
const InputWrapper = styled.div`
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 680px;
    max-width: 100%;
    margin-top: 20px;
`
const LabelAndCheckBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 260px;
    margin-right: 40px;
    font-family: ${fonts.PRIMARY_SEMIBOLD};
`
export const ToggleIcon = styled(Icon)<{areFieldsExpanded: boolean}>`
    align-self: center;
    opacity: 0.4;
    transform: ${p => p.areFieldsExpanded ? 'transform: rotate(180deg)' : 'none'};
`

type TProps<T> = {
    formProps: FormState<T>
    setFieldValue: UseFormSetValue<T>
    hideUndoField?: boolean
    setUndoState?: (value: boolean) => void
    fullWidth?: boolean
    control: Control<any>
}

const MINUTES_TO_ADD_EXECUTION_TIME = 10
const MINIMUM_MINUTES_DIFF_BETWEEN_EXECUTION_AND_UNDO = 10
const MINUTES_TO_ADD_UNDO_TIME = MINUTES_TO_ADD_EXECUTION_TIME + MINIMUM_MINUTES_DIFF_BETWEEN_EXECUTION_AND_UNDO

const createFutureDateFromString = (minutesToAdd: number) =>
    dayjs(new Date()).add(minutesToAdd, 'minutes').format(TIME_FORMAT)

const initialValues = {
    executionTime: createFutureDateFromString(MINUTES_TO_ADD_EXECUTION_TIME),
    undoTime: createFutureDateFromString(MINUTES_TO_ADD_UNDO_TIME),
    executionTimeEnabled: false,
    undoTimeEnabled: false,
    retries: 5,
    retriesEnabled: false,
}

const AdvancedJobConfigFields = <T, >(props: TProps<T>) => {
    const { setFieldValue } = props

    const [advancedConfig, setAdvancecConfig] = useState<TAdvancedJobConfigFields>(initialValues)

    useEffect(() => {
        setFieldValue('advancedJobConfig.executionTime' as any, initialValues.executionTime as any)
        setFieldValue('advancedJobConfig.executionTimeEnabled' as any, initialValues.executionTimeEnabled as any)
        setFieldValue('advancedJobConfig.retries' as any, initialValues.retries as any)
        setFieldValue('advancedJobConfig.retriesEnabled' as any, initialValues.retriesEnabled as any)
        setFieldValue('advancedJobConfig.undoTime' as any, initialValues.undoTime as any)
        setFieldValue('advancedJobConfig.undoTimeEnabled' as any, initialValues.undoTimeEnabled as any)
    }, [setFieldValue])

    useEffect(() => {
        setFieldValue('advancedJobConfig.executionTime' as any, advancedConfig.executionTime as any)
        setFieldValue('advancedJobConfig.retries' as any, advancedConfig.retries as any)
        setFieldValue('advancedJobConfig.undoTime' as any, advancedConfig.undoTime as any)
    }, [advancedConfig.executionTime, advancedConfig.retries, advancedConfig.undoTime, setFieldValue])

    const handleExecutionTimeChange = (date: Date | null) => {
        if (!date)
            return

        const newExecutionTime = dayjs(date).format(TIME_FORMAT)

        setAdvancecConfig(prev => ({
            ...prev,
            executionTime: newExecutionTime
        }))

        if (newExecutionTime > (advancedConfig.undoTime ?? '')) {

            const newUndoTime = dayjs(date).add(MINIMUM_MINUTES_DIFF_BETWEEN_EXECUTION_AND_UNDO, 'minutes').format(TIME_FORMAT)

            setAdvancecConfig(prev => ({
                ...prev,
                undoTime: newUndoTime
            }))
        }
    }

    const handleUndoTimeChange = (date: Date | null) => {
        if (!date)
            return

        const newUndoTime = dayjs(date).format(TIME_FORMAT)

        setAdvancecConfig(prev => ({
            ...prev,
            undoTime: newUndoTime
        }))

        if (advancedConfig.executionTime && advancedConfig.executionTime > newUndoTime) {
            const newExecutionDate = dayjs(newUndoTime)
                .subtract(MINIMUM_MINUTES_DIFF_BETWEEN_EXECUTION_AND_UNDO, 'minutes')
                .format(TIME_FORMAT)

            setAdvancecConfig(prev => ({
                ...prev,
                executionTime: newExecutionDate
            }))
        }
    }

    const toggleExecutionTimeChange = () => {
        setAdvancecConfig(prev => {
            return {
                ...prev,
                executionTimeEnabled: !prev.executionTimeEnabled
            }
        })
    }

    const toggleUndoTimeChange = () => {
        if (props.setUndoState) {
            props.setUndoState(!advancedConfig.undoTimeEnabled)
        }
        setAdvancecConfig(prev => ({
            ...prev,
            undoTimeEnabled: !prev.undoTimeEnabled
        }))
    }

    const toggleRetriesEnabled = () => {
        setAdvancecConfig(prev => ({
            ...prev,
            retriesEnabled: !prev.retriesEnabled
        }))
    }

    return (
        <>
            <InputWrapper>
                <LabelAndCheckBox>
                    <Title>
                        <TransMessage {...i18nKeys.advanced_job_configuration_execution_datetime} />
                    </Title>
                    <FormCheckbox
                        name='advancedJobConfig.executionTimeEnabled'
                        onChange={toggleExecutionTimeChange}
                        control={props.control} />
                </LabelAndCheckBox>
                <FormDateTimePicker
                    name='advancedJobConfig.executionTime'
                    control={props.control}
                    disabled={!advancedConfig.executionTimeEnabled}
                    disablePast
                    onChange={handleExecutionTimeChange}
                    formatter={(date) => (dayjs(date).format(TIME_FORMAT))}
                />
            </InputWrapper>
            {
                !props.hideUndoField &&
                <InputWrapper>
                    <LabelAndCheckBox>
                        <Title>
                            <TransMessage {...i18nKeys.advanced_job_configuration_undo_datetime} />
                        </Title>
                        <FormCheckbox
                            name='advancedJobConfig.undoTimeEnabled'
                            onChange={toggleUndoTimeChange}
                            control={props.control} />
                    </LabelAndCheckBox>
                    <FormDateTimePicker
                        name='advancedJobConfig.undoTime'
                        control={props.control}
                        disabled={!advancedConfig.undoTimeEnabled}
                        disablePast
                        onChange={handleUndoTimeChange}
                        formatter={(date) => (dayjs(date).format(TIME_FORMAT))}
                    />
                </InputWrapper>
            }
            <InputWrapper>
                <LabelAndCheckBox>
                    <Title>
                        <TransMessage {...i18nKeys.advanced_job_configuration_retry} />
                    </Title>
                    <FormCheckbox
                        name='advancedJobConfig.retriesEnabled'
                        onChange={toggleRetriesEnabled}
                        control={props.control} />
                </LabelAndCheckBox>
                <FormNumberInput
                    name='advancedJobConfig.retries'
                    disabled={!advancedConfig.retriesEnabled}
                    control={props.control}
                    min={1}
                    max={10}
                    defaultValue={5}
                />
            </InputWrapper>
        </>
    )
}

export default AdvancedJobConfigFields