import React, { useCallback } from 'react'
import { clearGroups, getGroupDataAsync, getSiteEventsAsync } from 'state/slices/siteEventsSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGroup, TTableFetchState } from 'types/network'
import { tableInfo } from 'views/Monitor/SiteEvents/siteEventsTableColumns'
import SiteEvents from './SiteEvents'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import SiteEventsService from 'services/siteEventsService'
import SiteEventsTablePreparer from 'views/Monitor/SiteEvents/siteEventsTablePreparer'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import SiteEventsDetails from 'views/Monitor/SiteEvents/SiteEventsDetails/SiteEventsDetails'
import { useModal } from 'hooks/modalProvider'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const SiteEventsContainer = () => {
    const dispatch = useAppDispatch()
    const { items, totalNumber, groups } = useAppSelector(state => state.siteEvents)
    const {showModal} = useModal()
    
    useBreadCrumbs([i18nKeys.breadcrumb_site_events])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams,
            translationKey: group.translationKey
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const overwrittenParams = {...params}
            if (!overwrittenParams.global.sortBy) {
                overwrittenParams.global.sortBy = 'Timestamp'
                overwrittenParams.global.descending = true
            }
            const requestParams = GeneralHelper.buildRequestFromTableState(overwrittenParams)
            dispatch(getSiteEventsAsync(requestParams))
        } else if (params.groupName) {
            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, params.groupName)
            dispatch(getGroupDataAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await SiteEventsService.getSiteEntityGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }

            getGroups()
        }
    }, [dispatch])

    const openSiteEventModal = useCallback((id: number) => {
        let siteEventDetails = items.find(siteEvent => siteEvent.id === id)
        if (!siteEventDetails) {
            for (let index = 0; index < items.length; index++) {
                const event = items[index]
                if (event) {
                    siteEventDetails = event.children?.find(e => e.id === id)
                    if (siteEventDetails) {
                        break
                    }
                }
            }
        }

        if (!siteEventDetails) {
            return
        }

        showModal({
            content: <SiteEventsDetails siteEvent={siteEventDetails} />,
            title: siteEventDetails.name,
            description: i18nKeys.site_time_profile_general_description
        })
    }, [items, showModal])

    const data = SiteEventsTablePreparer.prepareSiteRows(items, openSiteEventModal)
    const preparedGroups = SiteEventsTablePreparer.prepareGroups(groups, openSiteEventModal)
        
    return (
        <ViewContainer>
            <SiteEvents
                data={data}
                groups={preparedGroups}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                getTableData={getTableData}
                getGroupData={getGroupData}
            />
        </ViewContainer>
    )
}

export default SiteEventsContainer