import Copyright from 'components/Copyright/Copyright'
import TransMessage from 'components/TransMessage/TransMessage'
import Icon, { Icons } from 'components/Icon/Icon'
import LanguagePicker from 'components/LanguagePicker/LanguagePicker'
import { i18nKeys } from 'i18n/keys'
import { useForm } from 'react-hook-form'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import images from 'style/images'
import styled from 'styled-components'
import { TLoginRequest } from 'types/auth'
import { InputAdornment } from '@mui/material'
import { FormCheckbox, FormInput, FormLabel, FormWrapper, InputWrapper } from 'components/FormControls/FormControls'
import { useIntl } from 'react-intl'
import React from 'react'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.primary.DARK_PRIMARY};
`

type Props = {
    status: string
    isRememberMeAvailable: boolean
    isAdLoginAvailable: boolean
    version: string
    handleLoginSubmit: (formValues: TLoginRequest) => void
    handleWindowsLogin: () => void
}

const CustomFormWrapper = styled(FormWrapper)<{isAdLoginAvailable: boolean}>`
    width: 524px;
    height: ${p => p.isAdLoginAvailable ? 550 : 500}px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.shades.PURE_WHITE};
    padding: 24px 72px 64px 56px;
    margin-top: 60px;
    border-radius: ${borderRadius.SECONDARY};
    border-left: 16px solid ${colors.primary.DEFAULT_PRIMARY};
`

const QognifyImage = styled.img`
    max-width: 100px;
    margin-left: -20px;
`

const UmbrellaImage = styled.img`
    max-width: 202px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 56px;
    margin-top: 56px;
`
const InfoBox = styled.div`
    color: ${colors.shades.PURE_WHITE};
    width: 524px;
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const LoginButton = styled(CustomButton)`
    position: absolute;
    right: 0;
    width: 200px;
`

const CheckboxWrapper = styled.div`
    margin-left: -10px;
`

const Login = (props: Props) => {
    const { isRememberMeAvailable, isAdLoginAvailable, version } = props
    const { handleLoginSubmit, handleWindowsLogin } = props

    const intl = useIntl()

    const validationSchema = yup.object({
        username: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)),
        password: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field))
    })

    const { handleSubmit, formState, control } = useForm<TLoginRequest>({
        mode: 'onChange',
        defaultValues: {
            username: '',
            password: '',
            rememberMe: false
        },
        resolver: yupResolver(validationSchema)
    })

    const { isValid, isValidating, isSubmitting } = formState

    const usernameLabel = intl.formatMessage(i18nKeys.login_input_username_placeholder)
    const passwordLabel = intl.formatMessage(i18nKeys.login_input_password_placeholder)

    return (
        <CenterWrapper>
            <CustomFormWrapper onSubmit={handleSubmit(handleLoginSubmit)} isAdLoginAvailable={isAdLoginAvailable}>
                <QognifyImage src={images.qognifyLogo} alt='Qognify' />
                <UmbrellaImage src={images.logoGreen} alt='Umbrella' />

                <InputWrapper>
                    <FormInput
                        name='username'
                        control={control}
                        placeHolder={usernameLabel}
                        startIcon={
                            <InputAdornment position='start'>
                                <Icon d={Icons.EMAIL} size={20} />
                            </InputAdornment>
                        }
                    />
                </InputWrapper>
                <InputWrapper>
                    <FormInput
                        name='password'
                        placeHolder={passwordLabel}
                        control={control}
                        type='password'
                        startIcon={
                            <InputAdornment position='start'>
                                <Icon d={Icons.LOCK} size={20} />
                            </InputAdornment>
                        }
                    />
                </InputWrapper>
                <InputWrapper>
                    {
                        isRememberMeAvailable &&
                            <>
                                <CheckboxWrapper>
                                    <FormCheckbox
                                        name='rememberMe'
                                        control={control}
                                    />
                                </CheckboxWrapper>
                                <FormLabel small htmlFor='rememberMe'>
                                    <TransMessage {...i18nKeys.login_remember_me} />
                                </FormLabel>
                            </>
                    }
                    <LoginButton
                        disabled={!isValid || isValidating || isSubmitting}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.login_button_submit} />
                    </LoginButton>
                </InputWrapper>
                {
                    isAdLoginAvailable &&
                    <InputWrapper>
                        <CustomButton
                            endIcon={<Icon d={Icons.LOCK} />}
                            id='windowsLoginBtn'
                            onClick={handleWindowsLogin}
                            fullWidth
                        >
                            <TransMessage {...i18nKeys.login_button_windows_login} />
                        </CustomButton>
                    </InputWrapper>
                }
            </CustomFormWrapper>
            <InfoBox>
                <Copyright version={version} />
                <LanguagePicker iconColor={colors.shades.PURE_WHITE} />
            </InfoBox>
        </CenterWrapper>
    )
}

export default Login