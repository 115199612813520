import { Card, Grid } from '@mui/material'
import CustomAreaChart from 'components/CustomControls/CustomAreaChart'
import { CardWrapper } from 'components/CustomControls/CustomCharts'
import TransMessage from 'components/TransMessage/TransMessage'
import dayjs from 'dayjs'
import { EMessageType, EPerformanceState } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useEffect, useState } from 'react'
import { AutoSizer } from 'react-virtualized'
import SiteServicePerformanceService from 'services/siteServicePerformanceService'
import { siteServiceService } from 'services/siteServiceService'
import SiteSystemPerformanceService from 'services/siteSystemPerformanceService'
import { TSiteServicePerformanceState } from 'state/slices/siteServicePerformanceSlice'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import style from 'style'
import colors from 'style/colors'
import styled from 'styled-components'
import { TGetCardPageRequest, TOverallState, TPerformanceItem } from 'types/businessLogic/performance'
import { TAggregatedNetworkAdapters, TSiteSystemPerformance, TSiteSystemStatistics } from 'types/businessLogic/siteSystem'
import SiteServicesPerformance from 'views/Monitor/SiteServices/SiteServicesPerformance'
import DiskChart from 'views/Monitor/SiteSystems/SiteSystemDetails/DiskChart'
import NetworkChart from 'views/Monitor/SiteSystems/SiteSystemDetails/NetworkChart'

const ModalContainer = styled.div`
    font-family: ${style.fonts.PRIMARY_REGULAR};
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 40px;
    overflow-y: auto;
    width: 100%;
    height: 100%;

    .infoCard {
        width: 100%;
        height: 100%
    }

    .nodataCard {
        height: 400px;
        color: #303030;
        font-size: 20px;
        font-style: normal;
        align-self: center;
        font-family: ${style.fonts.PRIMARY_REGULAR};
        font-weight: 700;
        font-stretch: normal;
        letter-spacing: 0.6px;
        display: grid;
        place-items: center;
    }
`
const DiskListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 32px;
`
const ChartHeader = styled.h2`
    padding-top: 16px;
    padding-left: 32px;
    padding-bottom: 16px;
`
const Header = styled.h2`
    padding-top: 16px;
    padding-bottom: 16px;
`
const SiteSystemWrapper = styled.div`
    padding: 25px;
    margin-top: 16px;
    background-color: #f4f4f4;
`
const NetworkContainer = styled.div`
    margin-top: 8px;
    margin-bottom: 32px;
`

type TProps = {
    id: number
    siteSystem: TSiteSystemPerformance
}

const fromDay = dayjs().subtract(1, 'day')
const tillDay = dayjs()

const SiteSystemDetails = (props: TProps) => {
    const { id } = props

    const dispatch = useAppDispatch()
    const [data, setData] = useState<TSiteSystemStatistics[]>()
    
    const [siteServiceState, setSiteServiceState] = useState<TSiteServicePerformanceState>({
        allSiteServicesPerSite: [],
        totalNumberSites: 0,
        overallState: {
            critical: 0,
            healthy: 0,
            ignored: 0,
            offline: 0,
            warning: 0
        }
    })

    useEffect(() => {
        const fetchEntity = async () => {
            try {
                const details = await SiteSystemPerformanceService.getSiteSystemDetails(id, fromDay, tillDay)
                setData(details.performanceMeasures)
            } catch {
                dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
            }
        }

        fetchEntity()
    }, [dispatch, id])

    const handleFetchAction = useCallback(async (params: TGetCardPageRequest) => {
        try {
            const siteServices = await SiteServicePerformanceService.getSiteServicesBySystemId(id, params.fetchParams, params.filterByStates)
    
            const getOverallState = (items : TPerformanceItem[]) : TOverallState => {
                const result : TOverallState = {
                    critical: 0,
                    healthy: 0,
                    ignored: 0,
                    offline: 0,
                    warning: 0
                }
                
                items.forEach(item => {
                    switch (item.overallState) {
                        case EPerformanceState.HEALTHY:
                            result.healthy++
                            break
                        case EPerformanceState.WARNING:
                            result.warning++
                            break
                        case EPerformanceState.CRITICAL:
                            result.critical++
                            break
                        case EPerformanceState.OFFLINE:
                            result.offline++
                            break
                        case EPerformanceState.IGNORED:
                            result.ignored++
                            break
                    }
                })
                
                return result
            }
        
            const overallStates: TOverallState = getOverallState(siteServices.siteServices.flatMap(s => s.items))
        
            setSiteServiceState({
                overallState: overallStates,
                allSiteServicesPerSite: siteServices.siteServices,
                totalNumberSites: siteServices.totalNumber
            })
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }

    }, [dispatch, id])

    const restartSiteServices = async (services: TPerformanceItem[]) => {
        const ids = services.map(service => service.entityId)

        try {
            await siteServiceService.restartServices(ids)
            dispatch(showMessage({message: i18nKeys.site_services_table_restart_service_job_created_success, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.site_services_table_restart_service_job_created_error, type: EMessageType.ERROR}))
        }
    }

    const ignoreSiteServices = async (services: TPerformanceItem[]) => {
        const ids = services.map(service => service.entityId)

        try {
            await SiteServicePerformanceService.ignoreSiteServices(ids)
            dispatch(showMessage({message: i18nKeys.site_service_performance_ignore_success, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.site_service_performance_ignore_error, type: EMessageType.ERROR}))
        }
    }

    const startSiteServices = async (services: TPerformanceItem[]) => {
        const ids = services.map(service => service.entityId)

        try {
            await siteServiceService.startServices(ids)
            dispatch(showMessage({message: i18nKeys.site_service_performance_start_success, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.site_service_performance_start_error, type: EMessageType.ERROR}))
        }
    }

    const stopSiteServices = async (services: TPerformanceItem[]) => {
        const ids = services.map(service => service.entityId)

        try {
            await siteServiceService.stopServices(ids)
            dispatch(showMessage({message: i18nKeys.site_service_performance_stop_success, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.site_service_performance_stop_error, type: EMessageType.ERROR}))
        }
    }

    if (!data) {
        return <>Load data</>
    }

    const cpuData = data.map((item) => ({
        time: dayjs(item.timestampUtc).unix(),
        value: item.cpu
    }))
    const memoryUsageData = data.map(item => ({
        time: dayjs(item.timestampUtc).unix(),
        value: item.memory
    }))

    const networkAdapters = data.filter(d => !!d.networkAdapters).reduce((acc: TAggregatedNetworkAdapters, curr) => {
        if (!curr.networkAdapters) return acc

        curr.networkAdapters.forEach(adap => {
            if (!acc[adap.name]) {
                acc[adap.name] = [{
                    time: dayjs(curr.timestampUtc).unix(),
                    value: adap.totalUsageInPercent
                }]
            } else {
                acc[adap.name].push({
                    time: dayjs(curr.timestampUtc).unix(),
                    value: adap.totalUsageInPercent
                })
            }
        })
        return acc
    }, {})

    const diskUsage = data.filter(d => !d.isGap && !!d.disks).sort((a, b) => dayjs(b.timestampUtc).unix() - dayjs(a.timestampUtc).unix())[0]

    return (
        <ModalContainer>
            <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={6}>
                    <Card className='infoCard'>
                        <ChartHeader>
                            <TransMessage {...i18nKeys.umbrella_core_cpu_usage} />
                        </ChartHeader>
                        <AutoSizer disableHeight>
                            {({width}) => {
                                return (
                                    <CardWrapper>
                                        <CustomAreaChart
                                            unit='%'
                                            data={cpuData}
                                            graphColor={colors.extra.SUCCESS}
                                            width={width}
                                            height={300}
                                            from={fromDay}
                                            till={tillDay}
                                        />
                                    </CardWrapper>
                                )
                            }}
                        </AutoSizer>
                    </Card>
                </Grid>

                <Grid item sm={12} md={12} lg={6}>
                    <Card className='infoCard'>
                        <ChartHeader>
                            <TransMessage {...i18nKeys.umbrella_core_memory_usage} />
                        </ChartHeader>
                        <AutoSizer disableHeight>
                            {({width}) => {
                                return (
                                    <CardWrapper>
                                        <CustomAreaChart
                                            unit='%'
                                            data={memoryUsageData}
                                            graphColor={colors.extra.ALERT}
                                            width={width}
                                            height={300}
                                            from={fromDay}
                                            till={tillDay}
                                        />
                                    </CardWrapper>
                                )
                            }}
                        </AutoSizer>
                    </Card>
                </Grid>
            </Grid>

            <NetworkContainer>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12} lg={12}>
                        <NetworkChart
                            networkAdapters={networkAdapters}
                            fromDay={fromDay}
                            tillDay={tillDay}
                        />
                    </Grid>
                </Grid>
            </NetworkContainer>
            
            <Header>
                <TransMessage {...i18nKeys.site_systems_details_disk_usage} />
            </Header>

            <DiskListContainer>
                {
                    diskUsage && diskUsage.disks.length > 0 ?
                        diskUsage.disks.map(disk => <DiskChart key={`disk-${disk.name}`} disk={disk} />) :
                        <TransMessage {...i18nKeys.umbrella_core_no_data_to_show} />
                }
            </DiskListContainer>

            <Header>
                <TransMessage {...i18nKeys.site_systems_details_services_title} values={{count: siteServiceState.totalNumberSites}} />
            </Header>

            <SiteSystemWrapper>
                <SiteServicesPerformance
                    handleFetchAction={handleFetchAction}
                    totalNumberSites={siteServiceState.totalNumberSites}
                    allSiteServicesPerSite={siteServiceState.allSiteServicesPerSite}
                    overallState={siteServiceState.overallState}
                    onRestartSiteServices={restartSiteServices}
                    onIgnoreSiteServices={ignoreSiteServices}
                    onStartSiteServices={startSiteServices}
                    onStopSiteServices={stopSiteServices}
                    hideSystemFilters
                    disableAutoUpdate
                />
            </SiteSystemWrapper>
        </ModalContainer>
    )
}

export default SiteSystemDetails