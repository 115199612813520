import axiosClient from 'api/axiosClient'
import { TStatisticRequest } from 'types/network'
import { TCoreCPU, TCoreInstances, TCoreMemory, TCoreRequestCount, TCoreRequestDuration, TCoreStatistics, TPerformanceMeasureResponse, TSiteApprovals, TSiteStates } from 'types/statistic'

class StatisticService {
    private static _api = axiosClient()

    public static async getSiteStates(): Promise<TSiteStates> {
        const response = await this._api.get<TSiteStates>('Statistics/SiteStates')
        return response.data
    }

    public static async getSiteApprovals(): Promise<TSiteApprovals> {
        const response = await this._api.get<TSiteApprovals>('Statistics/SiteApprovals')
        return response.data
    }

    public static async getCoreMemory(request: TStatisticRequest): Promise<TPerformanceMeasureResponse<TCoreMemory>> {
        const response = await this._api.get<TPerformanceMeasureResponse<TCoreMemory>>('Statistics/CoreMemory', {
            params: {
                From : request.from.toISOString(),
                Till : request.till.toISOString(),
                Id: request.id
            }
        })
        return response.data
    }

    public static async getCoreCPU(request: TStatisticRequest): Promise<TPerformanceMeasureResponse<TCoreCPU>> {
        const response = await this._api.get<TPerformanceMeasureResponse<TCoreCPU>>('Statistics/CoreCPU', {
            params: {
                From : request.from.toISOString(),
                Till : request.till.toISOString(),
                Id: request.id
            }
        })
        return response.data
    }
    
    public static async getCoreRequestCount(request: TStatisticRequest): Promise<TPerformanceMeasureResponse<TCoreRequestCount>> {
        const response = await this._api.get<TPerformanceMeasureResponse<TCoreRequestCount>>('Statistics/CoreRequestCount', {
            params: {
                From : request.from.toISOString(),
                Till : request.till.toISOString(),
                Id: request.id
            }
        })
        return response.data
    }

    public static async getCoreRequestDuration(request: TStatisticRequest): Promise<TPerformanceMeasureResponse<TCoreRequestDuration>> {
        const response = await this._api.get<TPerformanceMeasureResponse<TCoreRequestDuration>>('Statistics/CoreRequestDuration', {
            params: {
                From : request.from.toISOString(),
                Till : request.till.toISOString(),
                Id: request.id
            }
        })
        return response.data
    }

    public static async getCoreStatistics(request: TStatisticRequest): Promise<TPerformanceMeasureResponse<TCoreStatistics>> {
        const response = await this._api.get<TPerformanceMeasureResponse<TCoreStatistics>>('Statistics/CoreStatistics', {
            params: {
                From : request.from.toISOString(),
                Till : request.till.toISOString(),
                Id: request.id
            }
        })
        return response.data
    }

    public static async getCoreInstances(): Promise<TCoreInstances[]> {
        const response = await this._api.get<TCoreInstances[]>('Statistics/CoreInstances')
        return response.data
    }
}

export default StatisticService