import TransMessage from 'components/TransMessage/TransMessage'
import { EPasswordValidationElements } from 'enums/enums'
import Validation from 'helpers/Validation'
import { i18nKeys } from 'i18n/keys'
import React, { useState, useEffect } from 'react'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'
import { TPasswordRequirements, TPasswordValidationResult } from 'types/businessLogic/validation'

const Rule = styled.p`
    font-size: ${typographyScale.TYPE12};
    line-height: ${lineHeights.TYPE12LineHeight};
    letter-spacing: ${letterSpacings.TYPE12LS};
    font-family: ${fonts.PRIMARY_REGULAR};
    color: ${colors.primary.DARK_PRIMARY};
    margin-top: 12px;
`

type TProps = {
    passwordRequirements: TPasswordRequirements
    password?: string
}

const PasswordRequirements = (props: TProps) => {
    const {password, passwordRequirements} = props
    
    const [validationResult, setValidationResult] = useState<TPasswordValidationResult>()

    useEffect(() => {
        if (password && passwordRequirements) {
            const result = Validation.validatePassword(password, passwordRequirements)
            
            setValidationResult(result)
        }
    }, [password, passwordRequirements])

    const getTextStyle = (requirementKey: EPasswordValidationElements, result?: TPasswordValidationResult) => {
        if (result && result[requirementKey]) {
            return {color: result[requirementKey]!.valid ? colors.extra.SUCCESS : colors.extra.ALERT}
        }
        return {}
    }

    return (
        <>
            {
                passwordRequirements.requireDigit &&
                <Rule
                    style={getTextStyle(EPasswordValidationElements.REQUIRE_DIGIT, validationResult)}>
                    <TransMessage {...i18nKeys.password_requirements_require_digit} />
                </Rule>
            }
            {
                passwordRequirements.requiredLength &&
                <Rule
                    style={getTextStyle(EPasswordValidationElements.REQUIRED_LENGTH, validationResult)}>
                    <TransMessage {...i18nKeys.password_requirements_required_length} values={{
                        lengthNumber: passwordRequirements.requiredLength
                    }} />
                </Rule>
            }
            {
                passwordRequirements.requireNonAlphanumeric &&
                <Rule
                    style={getTextStyle(EPasswordValidationElements.REQUIRE_NON_ALPHANUMERIC, validationResult)}>
                    <TransMessage {...i18nKeys.password_requirements_require_non_alphanumeric} />
                </Rule>
            }

            {
                passwordRequirements.requireLowercase &&
                <Rule
                    style={getTextStyle(EPasswordValidationElements.REQUIRE_LOWERCASE, validationResult)}>
                    <TransMessage {...i18nKeys.password_requirements_require_lowercase} />
                </Rule>
            }

            {
                passwordRequirements.requireUppercase &&
                <Rule
                    style={getTextStyle(EPasswordValidationElements.REQUIRE_UPPERCASE, validationResult)}>
                    <TransMessage {...i18nKeys.password_requirements_require_uppercase} />
                </Rule>
            }
            {
                passwordRequirements.requiredUniqueChars &&
                <Rule
                    style={getTextStyle(EPasswordValidationElements.REQUIRED_UNIQUE_CHARS, validationResult)}>
                    <TransMessage {...i18nKeys.password_requirements_required_unique_characters} values={{
                        uniqueNumber: passwordRequirements.requiredUniqueChars
                    }} />
                </Rule>
            }
        </>
    )
}

export default PasswordRequirements