import { useModal } from 'hooks/modalProvider'
import EnhancedTabs from 'components/Tabs/EnhancedTabs'
import TransMessage from 'components/TransMessage/TransMessage'
import { ELicenseTypes } from 'enums/ELicenseTypes'
import { EMessageType, EPerformanceState, EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import useLicense from 'hooks/useLicense'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import CameraPerformanceService from 'services/cameraPerformanceService'
import SiteServicePerformanceService from 'services/siteServicePerformanceService'
import SiteSystemPerformanceService from 'services/siteSystemPerformanceService'
import { dispatch, useAppSelector } from 'state/store'
import { TGetCardPageRequest, TOverallState, TPerformanceItem } from 'types/businessLogic/performance'
import { TSite } from 'types/businessLogic/site'
import { TTabButton } from 'types/ui/tab'
import CommentsTab from 'views/Sites/SiteDetails/Tabs/CommentsTab'
import DetailsTab from 'views/Sites/SiteDetails/Tabs/DetailsTab'
import IssuesTab from 'views/Sites/SiteDetails/Tabs/IssuesTab'
import { showMessage } from 'state/slices/uiSlice'

type TProps = {
    site: TSite
    preselectedTab?: number
}

const SiteDetails = (props: TProps) => {
    const { site, preselectedTab } = props
    const authorize = useAuthorization()
    const { situatorExclusive } = useAppSelector(state => state.license)
    const license = useLicense()
    const {closeModal} = useModal()
    const isSiteAdmin = authorize(EProfileRights.IS_SITE_ADMIN)
    const hasMonitoringRight = authorize(EProfileRights.HAS_MONITORING_RIGHT)

    const [cameraIssues, setCameraIssues] = useState<TPerformanceItem[]>([])
    const [siteSystemIssues, setSiteSystemIssues] = useState<TPerformanceItem[]>([])
    const [siteServiceIssues, setSiteServiceIssues] = useState<TPerformanceItem[]>([])
    const [overallState, setOverallState] = useState<TOverallState>({
        critical: 0,
        healthy: 0,
        ignored: 0,
        offline: 0,
        warning: 0
    })

    const tabButtons: TTabButton[] = [
        {
            id: 'detailsTabButton',
            label: i18nKeys.site_details_tab_details
        },
        {
            id: 'commentsTabButton',
            label: i18nKeys.site_details_tab_comments
        },
    ]

    const handleFetchAction = useCallback(async (params: TGetCardPageRequest) => {
        try {
            const result = await Promise.all([
                CameraPerformanceService.getCamerasBySide({ Id: site.id, Filters: params.fetchParams.Filters }, params.filterByStates),
                SiteSystemPerformanceService.getSiteSystemsBySide({ Id: site.id, Filters: params.fetchParams.Filters }, params.filterByStates),
                SiteServicePerformanceService.getSiteServicesBySide({ Id: site.id, Filters: params.fetchParams.Filters }, params.filterByStates)
            ])
    
            const newOverallState : TOverallState = {
                critical: 0,
                healthy: 0,
                ignored: 0,
                offline: 0,
                warning: 0
            }
    
            result.flat(2).forEach(x => {
                switch (x.overallState) {
                    case EPerformanceState.HEALTHY:
                        newOverallState.healthy++
                        break
                    case EPerformanceState.WARNING:
                        newOverallState.warning++
                        break
                    case EPerformanceState.CRITICAL:
                        newOverallState.critical++
                        break
                    case EPerformanceState.OFFLINE:
                        newOverallState.offline++
                        break
                    case EPerformanceState.IGNORED:
                        newOverallState.ignored++
                        break
                }
            })
    
            setOverallState(newOverallState)
            
            if (!situatorExclusive) {
                setCameraIssues(result[0])
            }
            
            setSiteSystemIssues(result[1])
            setSiteServiceIssues(result[2])
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
    }, [site.id, situatorExclusive])

    const tabContent = [
        {
            title:  isSiteAdmin ? <TransMessage {...i18nKeys.site_details_title} /> : <></>,
            content: <DetailsTab key='detailsTab' site={site} onSubmit={closeModal} />
        },
        {
            title: <TransMessage {...i18nKeys.site_details_comments_title} />,
            content: <CommentsTab readonly={!isSiteAdmin} key='commentsTab' site={site} onSubmit={closeModal} />
        }
    ]

    if (hasMonitoringRight && license(ELicenseTypes.MONITORING)) {
        tabButtons.push(
            {
                id: 'issuesTabButton',
                label: i18nKeys.site_details_tab_issues
            }
        )

        tabContent.push(
            {
                title: <TransMessage {...i18nKeys.site_details_issues_title} />,
                content: <IssuesTab
                    key='issuesTab'
                    cameraIssues={cameraIssues}
                    siteSystemIssues={siteSystemIssues}
                    siteServiceIssues={siteServiceIssues}
                    handleFetchAction={handleFetchAction}
                    overallState={overallState}
                />
            }
        )
    }

    return (
        <EnhancedTabs
            readOnlyForm={!isSiteAdmin}
            subtitleKey={i18nKeys.site_details_title}
            onSubmit={closeModal}
            numOfEntities={1}
            disableUpdateButtons={false}
            tabButtons={tabButtons}
            preselectedTab={preselectedTab}
            tabContent={tabContent}
        />
    )
}

export default SiteDetails
