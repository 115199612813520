import React, { PropsWithChildren } from 'react'
import Tooltip, { TooltipProps} from '@mui/material/Tooltip'

const CustomTooltip = React.forwardRef<HTMLDivElement, PropsWithChildren<TooltipProps>>((props, ref) => {
    const { children, ...rest } = props

    if (!props.title) {
        return <> {children} </>
    } else {
        return (
            <Tooltip
                {...rest}
                ref={ref}
                placement='top-start'
                disableFocusListener
            >
                {children}
            </Tooltip>
        )
    }
})

export default CustomTooltip