import { debounce } from '@mui/material'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormAsyncCreatableSelect } from 'components/FormControls/FormAsyncCreatableSelect'
import { FormButtonGroup, FormContent, FormFooter, FormGroup, FormLabel, FormWrapper, InputWrapper } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType } from 'enums/enums'
import DateHelper from 'helpers/DateHelper'
import EnumHelper from 'helpers/EnumHelper'
import LabelsHelper from 'helpers/LabelsHelper'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import SiteEventsService from 'services/siteEventsService'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import { TSiteEvent } from 'types/businessLogic/siteEvent'
import { TSelectOption, TSelectOptions } from 'types/ui/dropdown'

type TProps = {
    siteEvent : TSiteEvent
    isSiteAdmin: boolean
    onSubmit: () => void
}

type TFormValues = {
    labels: TSelectOptions
}

const DetailsTab = (props: TProps) => {
    const { siteEvent, isSiteAdmin, onSubmit } = props
    const dispatch = useAppDispatch()
    
    const [initialValues] = useState<TFormValues>({
        labels: siteEvent.labels.map<TSelectOption>(label => ({ label: label, value: label }))
    })

    const { handleSubmit, control, formState } = useForm<TFormValues>({
        mode: 'onChange',
        defaultValues: initialValues
    })

    const { isValid, isValidating, isSubmitting, isDirty } = formState

    const submit = async (formValues: TFormValues) => {
        const labels = formValues.labels.map(option => option.value)
        try {
            SiteEventsService.updateSiteEvent(siteEvent.id, labels)
            onSubmit()
            dispatch(showMessage({message: i18nKeys.site_event_details_updated_success_message, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.site_event_details_updated_error_message, type: EMessageType.ERROR}))
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            LabelsHelper.loadLabels(inputText).then((options) => callback(options))
        }, 500), [])

    return (
        <FormWrapper onSubmit={handleSubmit(submit)}>
            <FormContent>
                <FormGroup>
                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_events_table_head_event_name} />
                        </FormLabel>
                        <FormLabel>
                            {siteEvent.name || ''}
                        </FormLabel>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_events_details_event_category} />
                        </FormLabel>
                        <FormLabel>
                            <TransMessage {...EnumHelper.getSiteEventCatergoryName(siteEvent.eventCategory)} />
                        </FormLabel>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_events_details_entity_type} />
                        </FormLabel>
                        <FormLabel>
                            <TransMessage {...EnumHelper.getEntityTypeName(siteEvent.entityType)} />
                        </FormLabel>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_events_table_head_site_name} />
                        </FormLabel>
                        <FormLabel>
                            {siteEvent.siteName || ''}
                        </FormLabel>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_events_details_issue_resolved} />
                        </FormLabel>
                        <FormLabel>
                            {siteEvent.issueResolved || ''}
                        </FormLabel>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_events_details_timestamp} />
                        </FormLabel>
                        <FormLabel>
                            {DateHelper.convertUTCToLocal(siteEvent.timestamp, DateHelper.formats.YEAR_TO_SECONDS_PM_AM) || ''}
                        </FormLabel>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_events_details_description} />
                        </FormLabel>
                        <FormLabel>
                            {siteEvent.description || ''}
                        </FormLabel>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_events_table_head_labels} />
                        </FormLabel>
                        <FormAsyncCreatableSelect
                            name='labels'
                            disabled={!isSiteAdmin}
                            control={control}
                            isMulti
                            loadOptions={loadOptions}
                            defaultOptions
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            {
                isSiteAdmin &&
                    <FormFooter>
                        <FormButtonGroup>
                            <CustomButton
                                disabled={!isValid || isValidating || isSubmitting || !isDirty}
                                id='submitBtn'
                                type='submit'
                            >
                                <TransMessage {...i18nKeys.site_event_details_update_button} />
                            </CustomButton>
                        </FormButtonGroup>
                    </FormFooter>
            }
        </FormWrapper>
    )
}
export default DetailsTab