import { MenuProps, OutlinedInput, Select, SelectProps } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'

type TStyledSelectProps = {
    $small: boolean
    $noText: boolean
    $withTextEllipsis: boolean
    $disabled: boolean
    $iconColor?: string
}
const StyledSelect = styled(Select)<TStyledSelectProps>`
    border-radius: ${borderRadius.SECONDARY};
    height: ${p => p.$small ? 32 : 56 }px;

    .MuiSelect-root {
        //${'id'}Root
        padding: 0;
    }

    .MuiSelect-select {
        width: ${p => p.$withTextEllipsis ? '64px' : 'auto'};
        display: ${p => p.$withTextEllipsis ? 'block' : 'flex'};
        align-items: center;
        text-indent: ${p => p.$small ? 10 : 20}px;
        padding-right: 40px;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        height: 32px;
        color: ${colors.primary.DARK_PRIMARY};
        font-family: ${fonts.PRIMARY_REGULAR};
        font-size: ${typographyScale.TYPE16};
        line-height: ${lineHeights.TYPE14LineHeight};
        letter-spacing: ${letterSpacings.TYPE16LS};
        border-radius: ${p => p.$small ? borderRadius.QUATRENARY : borderRadius.SECONDARY};

        ${p => p.$noText ? 'font-size: 0;' : ''}
        ${p => p.$small ? 'max-height: 32px;' : ''}

        ${p => p.$withTextEllipsis ? 'margin-right: 8px;' : ''}
        ${p => p.$withTextEllipsis ? 'text-overflow: ellipsis;' : ''}
        ${p => p.$withTextEllipsis ? 'white-space: nowrap;' : ''}

        &:focus {
            border-Radius: ${borderRadius.SECONDARY};
        }
    }

    .MuiOutlinedInput-input {
        padding: 0;
        width: 100%;
        text-transform: none;
        height: 100%;
    }

    .MuiSelect-icon {
        color: ${p => p.$iconColor ? p.$iconColor : colors.primary.LIGHT_PRIMARY};
        right: ${p => p.$small ? 8 : 16}px;
    }
`
const StyledOutlinedInput = styled(OutlinedInput)<{$small?: boolean}>`
    width: 100%;
    padding-top: 0;

    &.MuiOutlinedInput-error {

    }

    &.MuiOutlinedInput-focused {
        & svg {
            fill: ${colors.primary.DEFAULT_PRIMARY};
        }
    }

    &.MuiOutlinedInput-disabled {
        cursor: default;
        opacity: 0.4;
    }

    .MuiOutlinedInput-notchedOutline {
        border-radius: ${p => p.$small ? borderRadius.QUATRENARY : borderRadius.SECONDARY};
        border-width: 1px;
    }
                
    &.MuiOutlinedInput-input {
        height: 24px;
        padding: 0px;
        padding-left: 0;
        color: ${colors.primary.DARK_PRIMARY};
        font-family: ${fonts.PRIMARY_REGULAR};
        font-size: ${typographyScale.TYPE16};
        line-height: ${lineHeights.TYPE14LineHeight};
        letter-spacing: ${letterSpacings.TYPE16LS};
        &::placeholder {
            color: ${colors.shades.DEEP_SHADE};
            opacity: 1;
        }
    }
`

type TProps = SelectProps & {
    small?: boolean
    customInput?: JSX.Element
    noText?: boolean
    withTextEllipsis?: boolean
    iconColor?: string
}

const Dropdown = (props: PropsWithChildren<TProps>) => {
    const {id, children, small, noText, disabled, withTextEllipsis, customInput, iconColor, ...rest} = props

    const menuProps: Partial<MenuProps> = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
        }
    }

    const input = customInput ? customInput : (
        <StyledOutlinedInput
            $small={small}
            id={id}
            name={id}
            type='text'
        />
    )

    return (
        <StyledSelect
            $disabled={!!disabled}
            $withTextEllipsis={!!withTextEllipsis}
            $noText={!!noText}
            $small={!!small}
            $iconColor={iconColor}
            MenuProps={menuProps}
            input={input}
            {...rest}
        >
            {children}
        </StyledSelect>
    )
}

export default Dropdown