import TransMessage, { TIntlMessage } from 'components/TransMessage/TransMessage'
import { EPerformanceState, EUploadManagerError, EVMSEntityType, EVMSEventCategory } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { TDropdownItem } from 'types/ui/dropdown'
import { TEnumTranslationDictionary } from 'types/ui/table'

export type TPropertyBase = {
    translation: TIntlMessage
}

export type TPropertyCollection<T> = {
    [key in string | number]: TPropertyBase & T
}

class EnumHelper {
    static translatedMessageEnumRenderer = (value: EUploadManagerError) : JSX.Element => {
        let translation: TIntlMessage | undefined

        switch (value) {
            case EUploadManagerError.ERROR_GENERAL:
                translation = i18nKeys.upload_manager_error_general
                break
            case EUploadManagerError.SIZE_TOO_LARGE:
                translation = i18nKeys.upload_manager_error_size_too_large
                break
            case EUploadManagerError.FILE_ALREADY_EXISTS:
                translation = i18nKeys.upload_manager_error_file_already_exists
                break
            default:
                break
        }

        if (!translation) return <></>

        return <TransMessage {...translation} />
    }

    static dropdownEnumRenderer = (translationEnum: Record<string, {translation: TIntlMessage}>) : TDropdownItem[] => {
        const dropdowns: TDropdownItem[] = [
            {
                text: <TransMessage {...i18nKeys.dropdown_selection_none} />,
                value: ''
            }
        ]

        for (const enumValue in translationEnum) {
            const dropdown = {
                text: <TransMessage {...translationEnum[enumValue].translation} />,
                value: enumValue
            }
            dropdowns.push(dropdown)
        }

        return dropdowns
    }

    static renderEnumTranslationsTableCell = (values: number[], enumDictionary: TEnumTranslationDictionary) => {
        const texts : any[] = []

        values.forEach(value => {
            const enumObject = enumDictionary[value]
            texts.push(enumObject.translation)
            texts.push(', ')
        })

        const filteredTexts = texts.slice(0, texts.length - 1)
        if (!filteredTexts.length)
            return '-'

        return filteredTexts
    }

    //#region SiteEvents Enum

    private static siteEventCategoryNames = {
        [EVMSEventCategory.UNKNOWN] : i18nKeys.site_event_category_unknown,
        [EVMSEventCategory.INFO] : i18nKeys.site_event_category_info,
        [EVMSEventCategory.WARNING] : i18nKeys.site_event_category_warning,
        [EVMSEventCategory.ERROR] : i18nKeys.site_event_category_error
    }
    
    private static entityType = {
        [EVMSEntityType.UNKNOWN]: i18nKeys.site_event_entity_type_unknown,
        [EVMSEntityType.CAMERA]: i18nKeys.site_event_entity_type_camera,
        [EVMSEntityType.VMS_RUNTIME]: i18nKeys.site_event_entity_type_runtime,
        [EVMSEntityType.VMS_USER]: i18nKeys.site_event_entity_type_user,
        [EVMSEntityType.VMS_GROUP]: i18nKeys.site_event_entity_type_group,
        [EVMSEntityType.SITE]: i18nKeys.site_event_entity_type_site,
        [EVMSEntityType.SYSTEM]: i18nKeys.site_event_entity_type_system
    }
    
    static getSiteEventCatergoryName = (value: EVMSEventCategory) => {
        return EnumHelper.siteEventCategoryNames[value]
    }

    static getEntityTypeName = (value: EVMSEntityType) => {
        return EnumHelper.entityType[value]
    }
    
    //#endregion SiteEvents Enum

    //#region EPerformanceState

    static parseStringToEPerformanceState(value: string | undefined) : EPerformanceState | undefined {
        if (!value || !value.trim()) {
            return undefined
        }
        
        const number = Number(value)

        if (isNaN(number)) {
            return undefined
        }

        return number as EPerformanceState
    }

    //#endregion
}

export default EnumHelper