import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import LabelService from 'services/labelService'
import { showMessage } from 'state/slices/uiSlice'
import { TSiteLabel } from 'types/businessLogic/misc'
import { TFetchParamsRequest, TUpdateLabelRequest } from 'types/network'

export type TLableState = {
    labels: TSiteLabel[]
    totalNumber: number
}

const initialState : TLableState = {
    labels: [],
    totalNumber: 0
}

export const getLabelsAsync = createAsyncThunk('labels/getLabelsAsync', async (params: TFetchParamsRequest, thunkApi) => {
    try {
        return await LabelService.getOverview(params)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const updateLabelAsync = createAsyncThunk('labels/updateLabelsAsync', async (params: TUpdateLabelRequest, thunkApi) => {
    try {
        return await LabelService.updateLabel(params)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'label',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLabelsAsync.fulfilled, (state, action) => {
                state.labels = action.payload.labels
                state.totalNumber = action.payload.totalNumber
            })
    }
})

export default slice.reducer