import colors from 'style/colors'
import styled from 'styled-components'
import React from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import Select from 'react-select'
import { FormControlResetButton } from 'components/FormControls/FormControls'
import { i18nKeys } from 'i18n/keys'
import TransMessage from 'components/TransMessage/TransMessage'
import { TSelectOptions } from 'types/ui/dropdown'
import style from 'style'

const StyledSelect = styled(Select)`
    font-family: ${style.fonts.PRIMARY_REGULAR};
    width: 380px;

    & .react-select__control--is-focused {
        border-color: ${colors.primary.DEFAULT_PRIMARY};
        box-shadow: 0 0 0 1px ${colors.primary.DEFAULT_PRIMARY};
    }

    & .react-select__control {
        border-radius: 8px;       
        &:hover {
            border-color: ${colors.primary.DEFAULT_PRIMARY};
            box-shadow: 0 0 0 1px ${colors.primary.DEFAULT_PRIMARY};
        }
    }

    & .react-select__option--is-focused {
        background-color: ${colors.shades.GRAY_SHADE};
    }

    & .react-select__option--is-selected {
        background-color: ${colors.primary.DEFAULT_PRIMARY};
    }

    & .react-select__input {
        background-color: white;
        width: 320px;
        height: 44px;
    }

    & .react-select__multi-value {
        background-color: ${colors.primary.DEFAULT_PRIMARY};
        color: ${colors.shades.PURE_WHITE};
        border-radius: 32px;
        padding-left: 8px;

        .react-select__multi-value__label {
            color: ${colors.shades.PURE_WHITE};
        }

        .react-select__multi-value__remove {
            &:hover {
                cursor: pointer;
                background-color: transparent;
                color: ${colors.shades.PURE_WHITE};
            }
        }
    }
`

type TProps = UseControllerProps<any> & {
    debug?: boolean
    options: TSelectOptions
    isMulti?: boolean
    disabled?: boolean
    placeholder?: string
    onReset?: (name: string, value: any) => void
}

export const FormSelect = (props: TProps) => {
    const { field, fieldState } = useController(props)

    const handleReset = () => {
        props.onReset && props.onReset(field.name, props.defaultValue)
    }

    const propsAreSet = props.defaultValue && field.value

    return (
        <>
            <StyledSelect
                {...field}
                isMulti={props.isMulti}
                id={props.name}
                className='react-select-container'
                classNamePrefix='react-select'
                options={props.options}
                isDisabled={props.disabled}
                placeholder={props.placeholder}
            />
            {
                props.onReset && propsAreSet &&
                props.defaultValue.value !== field.value.value &&
                <FormControlResetButton
                    onClick={handleReset}>
                    <TransMessage {...i18nKeys.properties_form_button_reset} />
                </FormControlResetButton>
            }
            {
                props.debug &&
                <>
                    <p>{fieldState.isTouched && 'Touched'}</p>
                    <p>{fieldState.isDirty && 'Dirty'}</p>
                    <p>{fieldState.invalid ? 'invalid' : 'valid'}</p>
                </>
            }
        </>
    )
}