import axiosClient from 'api/axiosClient'
import { mapHelper } from 'helpers/MapHelper'
import { updateSiteAsync } from 'state/slices/siteSlice'
import { dispatch } from 'state/store'
import { TSiteMin } from 'types/businessLogic/site'

class MapService {
    private static _api = axiosClient()
            
    public static async getMapMarker(): Promise<TSiteMin[]> {
        const response = await this._api.get<TSiteMin[]>('Maps')
        
        let sitesToUpdate : TSiteMin[] = []
        await mapHelper.parseLocations(response.data, (sites : TSiteMin[]) => {
            dispatch(updateSiteAsync(sites))
            sitesToUpdate = [...sitesToUpdate, ...sites]
        })
        
        return [...response.data, ...sitesToUpdate]
    }
}

export default MapService