import React, { useCallback } from 'react'
import { clearGroups, getGroupDataAsync, getSitesAsync } from 'state/slices/siteSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGroup, TTableFetchState } from 'types/network'
import { tableInfo } from 'views/Control/SiteLicenses/siteLicenseTableColumns'
import SiteLicense from './SiteLicense'
import SiteDetails from 'views/Sites/SiteDetails/SiteDetails'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { TModalProps } from 'types/ui/modal'
import SiteService from 'services/siteService'
import SiteLicenseTablePreparer from 'views/Control/SiteLicenses/siteLicenseTablePreparer'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { useModal } from 'hooks/modalProvider'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const SiteLicenseContainer = () => {
    const dispatch = useAppDispatch()
    const {showModal} = useModal()
    const { sites, totalNumber, groups } = useAppSelector(state => state.site)
    const pluginTypes = useAppSelector(state => state.pluginTypes.pluginTypes)

    useBreadCrumbs([i18nKeys.breadcrumb_update_site_licenses])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams,
            translationKey: group.translationKey
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const requestParams = GeneralHelper.buildRequestFromTableState(params)
            dispatch(getSitesAsync(requestParams))
        } else if (params.groupName) {
            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, params.groupName)
            dispatch(getGroupDataAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await SiteService.getGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }

            getGroups()
        }
    }, [dispatch])

    const openSiteDetailsModal = (id: number) => {
        const siteDetails = sites.find(site => site.id === id)

        if (!siteDetails) return

        showModal({
            content: <SiteDetails site={siteDetails} />,
            title: siteDetails.name,
            description: i18nKeys.site_details_title
        })
    }

    const data = SiteLicenseTablePreparer.prepareSiteRows(sites, pluginTypes, openSiteDetailsModal)
    const preparedGroups = SiteLicenseTablePreparer.prepareGroups(groups, pluginTypes, openSiteDetailsModal)
        
    const exportLicenseCsv = async (params: TModalProps) => {
        try {
            const selectAll = params.ids.length === 0
            if (selectAll && params.filters) {
                await SiteService.allSitesExport({
                    filters: params.filters
                })
            } else {
                await SiteService.sitesExport(params.ids as number[])
            }
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
    }

    return (
        <ViewContainer>
            <SiteLicense
                data={data}
                groups={preparedGroups}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                getTableData={getTableData}
                getGroupData={getGroupData}
                openExportSiteLicenses={exportLicenseCsv}
            />
        </ViewContainer>
    )
}

export default SiteLicenseContainer