import axiosClient from 'api/axiosClient'
import { TProfileDashboardLayout, TGridLayout, TProfile } from 'types/businessLogic/profile'
import { EProfileRights } from '../enums/enums'

export type TGridLayoutsResponse = {
    gridLayouts: TGridLayout[]
}

export type TProfileResponse = {
    username: string
    lastLogin: string
    roles: string[]
    displayName: string
    isUmbrellaAdmin: boolean
    isSiteAdmin: boolean
    hasReportsRight: boolean
    hasSiteMaintenanceReportRight: boolean
    hasSiteAuditReportRight: boolean
    isADUser: boolean
    isNavBarCollapsed: boolean
    hasApproveSitesRight: boolean
    hasDeploySitesRight: boolean
    hasDeleteSitesRight: boolean
    hasManageFirmwaresRight: boolean
    hasRestartSiteEntitiesRight: boolean
    hasMonitoringRight: boolean
    hasIgnoreRight: boolean
}

class ProfileService {
    private static _api = axiosClient()
        
    public static async getProfile(): Promise<TProfile> {
        const response = await this._api.get<TProfileResponse>('Profile')
        return this.tranformProfileResponse(response.data)
    }

    public static async getDashboardLayout(): Promise<TProfileDashboardLayout> {
        const response = await this._api.get<TProfileDashboardLayout>('Profile/DashboardLayouts')
        return response.data
    }

    public static async postDashboardLayout(layout: TProfileDashboardLayout): Promise<void> {
        await this._api.post('Profile/DashboardLayouts', layout)
    }

    public static async deleteDashboardLayout(): Promise<void> {
        await this._api.delete('Profile/DashboardLayouts')
    }

    public static async getGridLayouts(): Promise<TGridLayout[]> {
        const response = await this._api.get<TGridLayoutsResponse>('Profile/GridLayouts')
        return response.data.gridLayouts
    }

    public static async getGridLayout(id: number): Promise<TGridLayout> {
        const response = await this._api.get<TGridLayout>(`Profile/GridLayout/${id}`)
        return response.data
    }

    public static async restoreGridLayout(gridLayoutId: number): Promise<void> {
        await this._api.delete('Profile/GridLayouts', {
            data: {
                Ids: [gridLayoutId]
            }
        })
    }

    public static async setGridLayout(gridLayout: TGridLayout): Promise<void> {
        await this._api.post('Profile/GridLayout', gridLayout)
    }

    public static async uploadProfileImage(image : File): Promise<void> {
        const formData = new FormData()
        formData.append('image', image)
        await this._api.post('Profile/ProfilePicture', formData, {
            headers: {'Content-Type': 'multipart/form-data'},
        })
    }

    private static tranformProfileResponse (profile: TProfileResponse) : TProfile {
        return {
            username: profile.username || '',
            isAdUser: profile.isADUser || false,
            lastLogin: profile.lastLogin || '',
            roles: profile.roles || [],
            rights: this.mapRights(profile)
        }
    }
    
    private static mapRights (profile: TProfileResponse) : EProfileRights[] {
        const rights: EProfileRights[] = []
    
        if (profile.isSiteAdmin) rights.push(EProfileRights.IS_SITE_ADMIN)
        if (profile.isUmbrellaAdmin) rights.push(EProfileRights.IS_UMBRELLA_ADMIN)
        if (profile.hasApproveSitesRight) rights.push(EProfileRights.HAS_APPROVE_SITES_RIGHT)
        if (profile.hasDeleteSitesRight) rights.push(EProfileRights.HAS_DELETE_SITES_RIGHT)
        if (profile.hasDeploySitesRight) rights.push(EProfileRights.HAS_DEPLOY_SITES_RIGHT)
        if (profile.hasManageFirmwaresRight) rights.push(EProfileRights.HAS_MANAGE_FIRMWARES_RIGHT)
        if (profile.hasMonitoringRight) rights.push(EProfileRights.HAS_MONITORING_RIGHT)
        if (profile.hasReportsRight) rights.push(EProfileRights.HAS_REPORT_RIGHT)
        if (profile.hasRestartSiteEntitiesRight) rights.push(EProfileRights.HAS_RESTART_SITE_ENTITIES_RIGHT)
        if (profile.hasSiteAuditReportRight) rights.push(EProfileRights.HAS_SITE_AUDIT_REPORT_RIGHT)
        if (profile.hasSiteMaintenanceReportRight) rights.push(EProfileRights.HAS_SITE_MAINTENANCE_REPORT_RIGHT)
        if (profile.hasIgnoreRight) rights.push(EProfileRights.HAS_IGNORE_RIGHT)
    
        return rights
    }
}

export default ProfileService
