import { SiteEntityService } from 'services/siteEntityService'

class SiteServiceService extends SiteEntityService {
    constructor() {
        super('SiteServices')
    }

    public async restartServices(ids: number[]) {
        await this._api.post(`${this.urlPrefix}/Restart`, {
            Ids: ids
        })
    }

    public async startServices(ids: number[]) {
        await this._api.post(`${this.urlPrefix}/Start`, {
            Ids: ids
        })
    }

    public async stopServices(ids: number[]) {
        await this._api.post(`${this.urlPrefix}/Stop`, {
            Ids: ids
        })
    }
}

export const siteServiceService = new SiteServiceService()