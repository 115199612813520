import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TNotification } from 'types/businessLogic/siteEvent'

export type TNotificationState = {
    newItemsCount: number
    items: TNotification[]
}

const initialState : TNotificationState = {
    newItemsCount: 0,
    items: []
}

const slice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        addNotification: (state, action : PayloadAction<TNotification>) => {
            let temp = [action.payload, ...state.items]
            if (temp.length > 100) {
                temp = temp.slice(0, 100)
            }
            
            state.items = temp
            state.newItemsCount = temp.length
        },
        clearNotifications: (state) => {
            state.items = []
            state.newItemsCount = 0
        },
        resetItemsCount: (state) => {
            state.newItemsCount = 0
        }
    }
})

export const { addNotification, clearNotifications, resetItemsCount } = slice.actions

export default slice.reducer