import { useModal } from 'hooks/modalProvider'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { EMessageType, EProfileRights, EPropertyType } from 'enums/enums'
import { timeProfileHelper } from 'helpers/TimeProfileHelper'
import useAuthorization from 'hooks/useAuthorization'
import { i18nKeys } from 'i18n/keys'
import React, { useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { siteTimeProfileService } from 'services/siteTimeProfileService'
import { updateTimeProfileValue, updateAllTimeProfileValue } from 'state/slices/siteTimeProfileSlice'
import { showMessage } from 'state/slices/uiSlice'
import { pushItem, setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import { dispatch, useAppSelector } from 'state/store'
import { TAllCommonPropertiesRequest, TChangePropertyValuesRequest } from 'types/businessLogic/job'
import { TExtendedTimeProfileDay } from 'types/timeprofile'
import { TModalProps } from 'types/ui/modal'
import { TUploadManagerItem } from 'types/ui/uploadManager'
import SiteTimeProfileChanger from 'views/Control/SiteTimeProfiles/SiteTimeProfileChanger/SiteTimeProfileChanger'

type TProps = TModalProps & {
    selectAll?: boolean
    totalCount: number
}

const SiteTimeProfileChangerContainer = (props: TProps) => {
    const {selectAll, filters, ids, totalCount} = props
    const siteTimeProfiles = useAppSelector(state => state.siteTimeProfile.siteTimeProfiles)
    const [values, setValues] = useState<TExtendedTimeProfileDay[] | undefined>(undefined)

    const intl = useIntl()
    const {closeModal} = useModal()
    const authorize = useAuthorization()
    const isSiteAdmin = authorize(EProfileRights.IS_SITE_ADMIN)

    const loadData = useCallback(async (selectedIds: number[]) => {
        if (selectAll) {
            try {
                const request : TAllCommonPropertiesRequest = {
                    Filters: filters
                }
                const result = await siteTimeProfileService.getAllCommonProperties(request)
                setValues(timeProfileHelper.getTimeProfileInfo(result))
            } catch {
                dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
            }

        } else if (selectedIds.length < 2) {
            const timeProfile = siteTimeProfiles.find(x => x.id === selectedIds[0])
            if (timeProfile) {
                setValues(timeProfileHelper.getTimeProfileInfo(timeProfile))
            }
        } else {
            try {
                const result = await siteTimeProfileService.getCommonProperties(selectedIds)
                setValues(timeProfileHelper.getTimeProfileInfo(result))
            } catch {
                dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
            }
        }
    }, [filters, selectAll, siteTimeProfiles])

    useEffect(() => {
        loadData(ids as number[])
    }, [ids, loadData])

    const handleSubmit = async (formValues: any) => {
        let uploadManagerItem : TUploadManagerItem
        
        try {
            if (selectAll) {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_site_time_profile_change_properties,
                    {count: totalCount})
                        
                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const p: TChangePropertyValuesRequest = {
                    changePropertyValues: [{
                        propertyName: 'Profile',
                        propertyType: EPropertyType.JSON,
                        entityIds: ids,
                        stringValue: formValues.stringValue
                    }],
                    comment: formValues.comment,
                    filters,
                    ...formValues.advancedJobConfig
                }
    
                dispatch(pushItem(uploadManagerItem))
                await siteTimeProfileService.changeAllPropertyValues(p)
                dispatch(updateAllTimeProfileValue({ stringValue: formValues.stringValue }))
            } else {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_site_time_profile_change_properties,
                    {count: (ids as number[]).length})

                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const p: TChangePropertyValuesRequest = {
                    changePropertyValues: [{
                        propertyName: 'Profile',
                        propertyType: EPropertyType.JSON,
                        entityIds: ids,
                        stringValue: formValues.stringValue
                    }],
                    comment: formValues.comment,
                    ...formValues.advancedJobConfig
                }
                
                dispatch(pushItem(uploadManagerItem))
                await siteTimeProfileService.changePropertyValues(p)
                dispatch(updateTimeProfileValue({ ids: ids as number[], stringValue: formValues.stringValue }))
            }

            dispatch(showMessage({message: i18nKeys.site_time_profile_change_property_success, type: EMessageType.SUCCESS}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.site_time_profile_change_property_error, type: EMessageType.ERROR}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem!.id, isError: true}))
        }

        closeModal()
    }

    if (!values) return null

    return (
        <SiteTimeProfileChanger isSiteAdmin={isSiteAdmin} onSubmit={handleSubmit} values={values} />
    )
}
export default SiteTimeProfileChangerContainer