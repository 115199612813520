import { yupResolver } from '@hookform/resolvers/yup'
import AdvancedJobConfigFields from 'components/AdvancedJobConfigFields/AdvancedJobConfigFields'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormGroup, FormInput, FormLabel, FormWrapper, FormFooter, InputWrapper, Instructions } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { siteGroupService } from 'services/siteGroupService'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TAllCreateSiteGroupRequest, TCreateSiteGroupRequest, TJobRequest } from 'types/businessLogic/job'
import { TModalProps } from 'types/ui/modal'
import * as yup from 'yup'
import { useIntl } from 'react-intl'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { TUploadManagerItem } from 'types/ui/uploadManager'
import { pushItem, setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import { useModal } from 'hooks/modalProvider'

type TCreateSiteGroupForm = {
    advancedJobConfig: TJobRequest
    name: string
    comment?: string
}

type CreateSiteGroupModalProps = TModalProps & {
    totalCount: number
}

const CreateSiteGroup = (props: CreateSiteGroupModalProps) => {
    const {filters, ids, totalCount} = props

    const isSelectAll = !!filters

    const [isUndoEnabled, setIsUndoEnabled] = useState<boolean>(false)

    const intl = useIntl()
    const {closeModal} = useModal()

    const dispatch = useAppDispatch()
    const isCommentMandatory = useAppSelector(state => state.ui.uiSettings.isCommentMandatoryForJobs)
    
    const handleCreateSiteGroupSubmit = useCallback(async (formValues: TCreateSiteGroupForm) : Promise<void> => {
        let uploadManagerItem : TUploadManagerItem
        
        try {
            if (isSelectAll) {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_site_group_create,
                    {count: isUndoEnabled ? totalCount * 2 : totalCount})

                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const request : TAllCreateSiteGroupRequest = {
                    ...formValues.advancedJobConfig,
                    name: formValues.name,
                    comment: formValues.comment,
                    filters: filters
                }

                dispatch(pushItem(uploadManagerItem))
                await siteGroupService.createAllSiteGroup(request)
            } else {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_site_group_create,
                    {count: isUndoEnabled ? (ids as number[]).length * 2 : (ids as number[]).length})
                    
                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const request : TCreateSiteGroupRequest = {
                    ...formValues.advancedJobConfig,
                    name: formValues.name,
                    comment: formValues.comment,
                    gatewayIds: ids as number[]
                }

                dispatch(pushItem(uploadManagerItem))
                await siteGroupService.createSiteGroup(request)
            }
            dispatch(showMessage({message: i18nKeys.create_site_groups_success_message, type: EMessageType.SUCCESS}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id}))
        } catch (reason) {
            dispatch(showMessage({message: i18nKeys.create_site_groups_error_message, type: EMessageType.ERROR}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem!.id, isError: true}))
        }

        closeModal()
    }, [dispatch, filters, ids, intl, isSelectAll, isUndoEnabled, closeModal, totalCount])

    const defaultValues : TCreateSiteGroupForm = {
        advancedJobConfig: {},
        name: ''
    }
    
    const validationSchema = (mandatoryComment: boolean) => yup.object().shape({
        name: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)),
        comment: mandatoryComment ? yup.string().max(500).required(intl.formatMessage(i18nKeys.general_validation_required_field)) : yup.string().max(500)
    })

    const { handleSubmit, formState, control, setValue} = useForm<TCreateSiteGroupForm>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema(isCommentMandatory))
    })

    const { isValid, isValidating, isSubmitting, isDirty } = formState

    return (
        <FormWrapper onSubmit={handleSubmit(handleCreateSiteGroupSubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_site_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='name'>
                            <TransMessage {...i18nKeys.create_site_group_input_site_group} />
                        </FormLabel>
                        <FormInput
                            name='name'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup collapsable title={i18nKeys.advanced_job_configuration_title}>
                    <AdvancedJobConfigFields
                        formProps={formState}
                        setFieldValue={setValue}
                        setUndoState={setIsUndoEnabled}
                        control={control}
                    />
                </FormGroup>
                <FormGroup title={i18nKeys.job_configuration_comment_title}>
                    <InputWrapper>
                        <FormLabel htmlFor='comment'>
                            <TransMessage {...i18nKeys.job_configuration_comment} />
                        </FormLabel>
                        <FormInput
                            name='comment'
                            autoComplete='job-comment'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.create_site_group_submit_button} />
                    </CustomButton>
                </FormButtonGroup>
                <Instructions>
                    <TransMessage {...i18nKeys.create_site_group_instructions} />
                </Instructions>
            </FormFooter>
        </FormWrapper>
    )
}

export default CreateSiteGroup
