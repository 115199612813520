import { Card, Grid } from '@mui/material'
import CustomAreaChart, { TData } from 'components/CustomControls/CustomAreaChart'
import { CardWrapper } from 'components/CustomControls/CustomCharts'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import { AutoSizer } from 'react-virtualized'
import { TStatisticRequest } from 'types/network'
import { TTranslationKey } from 'types/ui/translation'
import { Header } from './UmbrellaCoreMonitoringCard.styled'
import React from 'react'

interface UmbrellaCoreMonitoringCardProps {
    title: TTranslationKey
    data: TData[]
    graphColor: string
    unit: string
    request: TStatisticRequest
}

export const UmbrellaCoreMonitoringCard = ({title, data, unit, graphColor, request} : UmbrellaCoreMonitoringCardProps) => {
    
    if (data.length === 0) {
        return (
            <Grid item sm={12} md={12} lg={6}>
                <Card className='nodataCard'>
                    <TransMessage {...i18nKeys.umbrella_core_no_data_to_show} />
                </Card>
            </Grid>
        )
    }
    
    return (
        <Grid item sm={12} md={12} lg={6}>
            <Card className='infoCard'>
                <Header>
                    <TransMessage {...title} />
                </Header>
                <AutoSizer disableHeight>
                    {({width}) => {
                        return (
                            <CardWrapper>
                                <CustomAreaChart
                                    unit={unit}
                                    data={data}
                                    graphColor={graphColor}
                                    width={width}
                                    height={400}
                                    from={request.from}
                                    till={request.till}
                                />
                            </CardWrapper>
                        )
                    }}
                </AutoSizer>
            </Card>
        </Grid>
    )
}