import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { siteUserService } from 'services/siteUserService'
import { showMessage } from 'state/slices/uiSlice'
import { setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import { TJobRequest } from 'types/businessLogic/job'
import { TSiteEntity, TSiteEntityUpdate } from 'types/businessLogic/site'
import { TFetchParamsRequest } from 'types/network'
import { TTableGroup, TTableColumnProperty } from 'types/ui/table'

export type TSiteUserState = {
    groups: TTableGroup<TSiteEntity>[]
    siteUsers: TSiteEntity[]
    totalNumber: number
    properties: TTableColumnProperty[]
}

const initialState : TSiteUserState = {
    groups: [],
    siteUsers: [],
    totalNumber: 0,
    properties: []
}

export const getSiteUsersAsync = createAsyncThunk('siteUser/getSiteUsersAsync', async (params: TFetchParamsRequest, thunkApi) => {
    try {
        return await siteUserService.getSiteEntities(params)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getSiteUsersPropertiesAsync = createAsyncThunk('siteUser/getSiteUserPropertiesAsync', async (_, thunkApi) => {
    try {
        return await siteUserService.getSiteEntitiesProperties()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getGroupDataAsync = createAsyncThunk('siteUser/getGroupDataAsync', async (params: TFetchParamsRequest & { translationKey?: string}, thunkApi) : Promise<TTableGroup<TSiteEntity>> => {
    try {
        const result = await siteUserService.getSiteEntities(params)
        return {
            name: params.GroupName!,
            translationKey: params.translationKey,
            count: result.total,
            data: result.siteEntities
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const updateSiteUserAsync = createAsyncThunk('siteUser/updateSiteUserAsync', async (params: TSiteEntityUpdate, thunkApi) => {
    try {
        await siteUserService.updateSiteEntity(params.id, params.labels)
        thunkApi.dispatch(showMessage({message: i18nKeys.site_user_details_user_update_success_message, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.site_user_details_user_update_error_message, type: EMessageType.ERROR}))
        throw err
    }
})

export const deleteSiteUsersAsync = createAsyncThunk('siteUser/deleteSiteUsersAsync', async (params: {ids: number[], uploadManagerItemId: string | number }, thunkApi) => {
    try {
        await siteUserService.deleteSiteUsers(params.ids)
        thunkApi.dispatch(setDoneOrErrorOnItem({ id: params.uploadManagerItemId }))
        thunkApi.dispatch(showMessage({message: i18nKeys.site_users_list_toolbar_delete_site_users_success, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        thunkApi.dispatch(setDoneOrErrorOnItem({ id: params.uploadManagerItemId, isError: true}))
        thunkApi.dispatch(showMessage({message: i18nKeys.site_users_list_toolbar_delete_site_users_error, type: EMessageType.ERROR}))
        throw err
    }
})

export const deleteAllSiteUsersAsync = createAsyncThunk('siteUser/deleteAllSiteUsersAsync', async (params: {request: TJobRequest, uploadManagerItemId: string | number }, thunkApi) => {
    try {
        await siteUserService.deleteAllSiteUsers(params.request)
        thunkApi.dispatch(setDoneOrErrorOnItem({ id: params.uploadManagerItemId }))
        thunkApi.dispatch(showMessage({message: i18nKeys.site_users_list_toolbar_delete_site_users_success, type: EMessageType.SUCCESS}))
        return params.request
    } catch (err) {
        thunkApi.dispatch(setDoneOrErrorOnItem({ id: params.uploadManagerItemId, isError: true}))
        thunkApi.dispatch(showMessage({message: i18nKeys.site_users_list_toolbar_delete_site_users_error, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'siteUser',
    initialState: initialState,
    reducers: {
        clearGroups: (state, action : PayloadAction<{ totalNumber: number, groups: TTableGroup<TSiteEntity>[] }>) => {
            action.payload.groups.forEach(item => {
                const oldItem = state.groups.find(x => x.name === item.name)
                if (oldItem) {
                    item.data = oldItem.data
                }
            })

            state.groups = action.payload.groups
            state.totalNumber = action.payload.totalNumber
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSiteUsersAsync.fulfilled, (state, action) => {
                const { siteEntities, total } = action.payload
                state.siteUsers = siteEntities
                state.totalNumber = total
            })
            .addCase(getSiteUsersPropertiesAsync.fulfilled, (state, action) => {
                state.properties = action.payload
            })
            .addCase(getGroupDataAsync.fulfilled, (state, action) => {
                const existingGroup = state.groups.find(p => p.name === action.payload.name)
                if (existingGroup) {
                    existingGroup.data = action.payload.data
                    existingGroup.count = action.payload.count
                } else {
                    state.groups.push(action.payload)
                }

                action.payload.data?.forEach(x => {
                    const index = state.siteUsers.findIndex(y => y.id === x.id)
                    if (index === -1) {
                        state.siteUsers.push(x)
                    } else {
                        state.siteUsers[index] = x
                    }
                })
            })
    }
})

export const {clearGroups} = slice.actions

export default slice.reducer