import { ERecordingState } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import colors from 'style/colors'
import { TTranslationKey } from 'types/ui/translation'

type TRecordingStateInfos = {
    [key in ERecordingState]: {
        translationKey: TTranslationKey
        color: string
    }
}

export const RecordingStateInfos: TRecordingStateInfos = {
    [ERecordingState.OK] : {
        translationKey: i18nKeys.recording_state_ok,
        color: colors.extra.SUCCESS,
    },
    [ERecordingState.LIMITED] : {
        translationKey: i18nKeys.recording_state_limited,
        color: colors.extra.WARNING,
    },
    [ERecordingState.ERROR] : {
        translationKey: i18nKeys.recording_state_error,
        color: colors.extra.ALERT,
    },
    [ERecordingState.UNKNOWN] : {
        translationKey: i18nKeys.recording_state_unknown,
        color: '#959595',
    },
    [ERecordingState.DISABLED] : {
        translationKey: i18nKeys.recording_state_disabled,
        color: colors.extra.SUCCESS,
    }
}