import Icon from 'components/Icon/Icon'
import { getPerformanceStateInfos } from 'components/PagedCardList/getPerformanceStateInfos'
import TransMessage from 'components/TransMessage/TransMessage'
import { EPerformanceState } from 'enums/enums'
import React, { useState } from 'react'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import styled from 'styled-components'

type TRootProps = {
    $color: string
    $active: boolean
}

const Root = styled.div<TRootProps>`
    min-width: 32px;
    height: 32px;
    margin: 0 8px 0 0;
    padding: 1px 1px 1px 1px;
    border: solid 1px ${p => p.$color};
    border-radius: 16px;
    font-family: ${fonts.PRIMARY_REGULAR};
    font-size: 11px;
    line-height: ${lineHeights.TYPE12LineHeight};
    letter-spacing: ${letterSpacings.TYPE12LS};
    display: grid;
    place-items: center;
    fill: ${p => p.$active ? colors.shades.PURE_WHITE : p.$color};
    color: ${p => p.$active ? colors.shades.PURE_WHITE : colors.shades.DARK_GRAY_SHADE };
    background-color: ${p => p.$active ? p.$color : colors.shades.PURE_WHITE };
    cursor: pointer;
`

const TooltipContentContainer = styled.div`
    
    display: grid;
    place-items: center;
`

const TextBlock = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    svg {
        margin-left: 8px;
    }
`

type TProps = {
    value: EPerformanceState
    count: number
    id: string
    onClick: (value: EPerformanceState)=> void
    preSelect?: boolean
    small?: boolean
}

const StateButton = (props: TProps) => {
    const {id, value, count, onClick, preSelect, small} = props
    const [active, setActive] = useState<boolean>(!!preSelect)
    const stateInfo = getPerformanceStateInfos(value)
    
    const handleClick = () => {
        setActive(!active)
        onClick(value)
    }
    
    if (small) {
        return (
            <CustomTooltip
                title={<>
                    <TransMessage {...stateInfo.translation} />  ({count})
                </>}
            >
                <Root id={id} $color={stateInfo.color} $active={active} onClick={handleClick}>
                    <TooltipContentContainer>
                        <Icon d={stateInfo.icon} size={18} />
                    </TooltipContentContainer>
                </Root>
            </CustomTooltip>
        )
    }

    return (
        <Root id={id} $color={stateInfo.color} $active={active} onClick={handleClick}>
            <TextBlock>
                <TransMessage {...stateInfo.translation} />  ({count})
                <Icon d={stateInfo.icon} size={18} />
            </TextBlock>
        </Root>
    )
}
export default StateButton
