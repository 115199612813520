import { i18nKeys } from 'i18n/keys'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'

export type TIntlMessage = {
    id?: string
    debugId?: string
    defaultMessage?: string
    values?: Record<string, ReactNode>
}

const TransMessage = (props: TIntlMessage) => {
    const intl = useIntl()

    if (!props.id || !i18nKeys[props.id])
        return <>{`${props.debugId || ''} Missing translation key!`}</>
    
    return <>{intl.formatMessage(props, props.values)}</>
}

export default TransMessage