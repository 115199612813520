import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const Root = styled.div<{$width: string, $height: string}>`
    width: ${p => p.$width};
    height: ${p => p.$height};
`

type TProps = {
    width: string
    height: string
}

const Container = (props: PropsWithChildren<TProps>) => {
    return (
        <Root $width={props.width} $height={props.height}>
            {props.children}
        </Root>
    )
}

export default Container