import { TableCell, TableRow } from '@mui/material'
import { CustomInput } from 'components/CustomControls/CustomInput'
import DropdownItem from 'components/Dropdown/DropdownItem'
import Dropdown from 'components/Dropdown/Dropdown'
import Icon, { Icons } from 'components/Icon/Icon'
import { ETableFilters } from 'enums/enums'
import React, { useState } from 'react'
import colors from 'style/colors'
import styled from 'styled-components'
import { TFilter } from 'types/ui/table'
import AppConstants from 'helpers/AppConstants'

type TProps = {
    filters: TFilter[]
    onChange: (key: string, value: string) => void
    activeFilters?: { [key: string]: string }
    showChildren?: boolean
}

const StyledTableCell = styled(TableCell)`
    padding-top: 16px;
    padding-bottom: 16px;
    fill: ${colors.shades.DEEP_SHADE};
`

const SearchIconWrapper = styled.div`
    width: 42px;
    height: 42px;
    display: grid;
    place-items: center;
`

const TableFilters = (props: TProps) => {
    const {filters, activeFilters, onChange, showChildren} = props

    const [filterValues, setFilterValues] = useState<{[key: string]: string}>({})

    const getFilterComponent = (filter: TFilter) : JSX.Element => {
        switch (filter.type) {
            case ETableFilters.INPUT_TEXT:
                const inputFilterValue = filterValues[filter.key] ?? ''
                return (
                    <CustomInput
                        small
                        id={`filter${filter.key}`}
                        data-testid={`filter${filter.key}`}
                        value={inputFilterValue}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                            setFilterValues(prevState => {
                                const tempFilters = {
                                    ...prevState,
                                    [filter.key]: e.target.value
                                }
                    
                                if (e.target.value === '') delete tempFilters[filter.key]

                                return tempFilters
                            })
                            onChange(filter.key, e.target.value)
                        }}
                    />
                )
            case ETableFilters.DROPDOWN:
                const dropdownFilterValue = activeFilters && activeFilters[filter.key] !== undefined ? activeFilters[filter.key] : ''
                return (
                    <Dropdown
                        small
                        iconColor={colors.primary.DEFAULT_PRIMARY}
                        value={dropdownFilterValue}
                        id={`filter${filter.key}`}
                        onChange={(event) => {
                            onChange(filter.key, event.target.value as string)
                        }}
                    >
                        {filter.dropdownItems && filter.dropdownItems.map((item, index) => (
                            <DropdownItem key={index} value={item.value as string | number}>{item.text}</DropdownItem>
                        ))}
                    </Dropdown>
                )
            default:
                return <></>
        }
    }

    const renderFilters = () => {

        while (filters.length < AppConstants.other.TABEL_MIN_COLUMN_AMOUNT) {
            filters.push({
                key: Date.now().toString(),
                type: ETableFilters.NONE
            })
        }

        return filters.map((filter, index) => (
            <StyledTableCell key={index} className='data-cell'>
                {filter && getFilterComponent(filter)}
            </StyledTableCell>
        ))
    
    }

    return (
        <TableRow>
            <StyledTableCell className='helper-cell'>
                <SearchIconWrapper>
                    <Icon
                        d={Icons.SEARCH}
                    />
                </SearchIconWrapper>
            </StyledTableCell>
            { showChildren && <StyledTableCell className='helper-cell' /> }
            {renderFilters()}
        </TableRow>
    )
}

export default TableFilters