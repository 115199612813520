import AuthService from 'services/authService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { showMessage } from './uiSlice'
import { i18nKeys } from 'i18n/keys'
import { TLoginRequest } from 'types/auth'
import { EMessageType } from 'enums/enums'
import { backgroundService } from 'services/backgroundService'
import { signalRService } from 'services/signalRService'
import { getProfileAsync } from 'state/slices/profileSlice'

export type TAuthState = {
    loginReqStatus: string
    isAuthenticated: boolean
    passwordChangeNeeded: boolean
    userRightsWhereChanged: boolean
    invalidSessionCount: number
}

const initialState : TAuthState = {
    loginReqStatus: '',
    isAuthenticated: false,
    passwordChangeNeeded: false,
    userRightsWhereChanged: false,
    invalidSessionCount: 0
}

export const loginAsync = createAsyncThunk('auth/loginAsync', async (param: TLoginRequest, thunkApi) => {
    try {
        const result = await AuthService.login(param)
        backgroundService.start()
        signalRService.start()
        thunkApi.dispatch(getProfileAsync())
        return result
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.login_request_error, type: EMessageType.ERROR}))
        throw err
    }
})

export const loginWindowsAsync = createAsyncThunk('auth/loginWindowsAsync', async (_, thunkApi) => {
    try {
        const result = await AuthService.loginWindows()
        backgroundService.start()
        signalRService.start()
        thunkApi.dispatch(getProfileAsync())
        return result
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.login_request_error, type: EMessageType.ERROR}))
        throw err
    }
})

export const logoutAsync = createAsyncThunk('auth/logoutAsync', async (ignoreBackendCall : boolean | undefined, thunkApi) => {
    try {
        backgroundService.stop()
        signalRService.stop()
    
        if (!ignoreBackendCall) {
            await AuthService.logout()
        }
        thunkApi.dispatch({ type: 'state/logout' })
    } catch {
        thunkApi.dispatch({ type: 'state/logout' })
    }
})

export const checkLoginAsync = createAsyncThunk('auth/checkLoginAsync', async (_, thunkApi) => {
    const result = await AuthService.checkLogin()
    backgroundService.start()
    signalRService.start()
    thunkApi.dispatch(getProfileAsync())
    return result
})

export const checkSessionAsync = createAsyncThunk('auth/checkSessionAsync', async () => {
    await AuthService.checkLogin()
})

const slice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.loginReqStatus = 'pending'
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.loginReqStatus = 'idle'
                state.isAuthenticated = action.payload.succeeded ?? false
                state.passwordChangeNeeded = action.payload.passwordChangeNeeded ?? false
            })
            .addCase(loginAsync.rejected, (state) => {
                state.loginReqStatus = 'rejected'
                state.isAuthenticated = false
            })
            .addCase(loginWindowsAsync.pending, (state) => {
                state.loginReqStatus = 'pending'
            })
            .addCase(loginWindowsAsync.fulfilled, (state, action) => {
                state.loginReqStatus = 'idle'
                state.isAuthenticated = action.payload.succeeded ?? false
            })
            .addCase(loginWindowsAsync.rejected, (state) => {
                state.loginReqStatus = 'rejected'
                state.isAuthenticated = false
            })
            .addCase(checkLoginAsync.pending, (state) => {
                state.loginReqStatus = 'pending'
            })
            .addCase(checkLoginAsync.fulfilled, (state, action) => {
                state.passwordChangeNeeded = action.payload.passwordChangeNeeded ?? false
                state.isAuthenticated = true
                state.loginReqStatus = 'idle'
            })
            .addCase(checkLoginAsync.rejected, (state) => {
                state.isAuthenticated = false
                state.loginReqStatus = 'idle'
            })
            .addCase(checkSessionAsync.rejected, state => {
                state.invalidSessionCount++
            })
            .addCase(checkSessionAsync.fulfilled, state => {
                state.invalidSessionCount = 0
            })
    }
})

export default slice.reducer
