import { EServiceState } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import colors from 'style/colors'

export const ServiceStateInfos = {
    [EServiceState.UNKNOWN] : {
        translationKey: i18nKeys.site_service_performance_service_state_offline,
        color: '#959595',
    },
    [EServiceState.STOPPED] : {
        translationKey: i18nKeys.site_service_performance_service_state_stopped,
        color: colors.extra.ALERT,
    },
    [EServiceState.RUNNING] : {
        translationKey: i18nKeys.site_service_performance_service_state_running,
        color: colors.extra.SUCCESS,
    },
    [EServiceState.PAUSED] : {
        translationKey: i18nKeys.site_service_performance_service_state_paused,
        color: colors.extra.ALERT,
    },
    [EServiceState.OFFLINE] : {
        translationKey: i18nKeys.site_service_performance_service_state_offline,
        color: '#959595',
    }
}