import React from 'react'
import FormHelper from 'helpers/FormHelper'
import { createUmbrellaRoleAsync } from 'state/slices/umbrellaRoleSlice'
import { useAppDispatch } from 'state/store'
import { TCreateUmbrellaRoleRequest } from 'types/businessLogic/role'
import UmbrellaRoleForm, { TUmbrellaRoleDetailsForm } from 'views/Admin/UmbrellaRoles/UmbrellaRoleForm'

type TProps = {
    onSubmit?: () => void
}

const CreateUmbrellaRole = (props: TProps) => {
    const { onSubmit } = props
    const dispatch = useAppDispatch()

    const handleCreateUmbrellaRoleSubmit = (formValues: TUmbrellaRoleDetailsForm) => {
        FormHelper.removeEmptyFields(formValues)
        
        const createUmbrellaRoleRequest : TCreateUmbrellaRoleRequest = {
            ...formValues,
            sites: FormHelper.extractValuesFromOptionTypeBase(formValues.sites),
            siteLabels: FormHelper.extractValuesFromOptionTypeBase(formValues.siteLabels),
            users: FormHelper.extractValuesFromOptionTypeBase(formValues.users),
            activeDirectoryDomainAndSid: formValues.activeDirectoryDomainAndSid?.value.toString() ?? undefined,
            filter: FormHelper.extractValuesFromOptionTypeBase(formValues.filter),
            vmsEntityTypeSubscription: FormHelper.extractValuesFromOptionTypeBase(formValues.vmsEntityTypeSubscription),
            vmsEventCategorySubscription: FormHelper.extractValuesFromOptionTypeBase(formValues.vmsEventCategorySubscription)
        }

        if (createUmbrellaRoleRequest.umbrellaAdmin) {
            createUmbrellaRoleRequest.sites = []
            createUmbrellaRoleRequest.siteLabels = []
        }

        dispatch(createUmbrellaRoleAsync(createUmbrellaRoleRequest))

        onSubmit && onSubmit()
    }
    
    return (
        <UmbrellaRoleForm
            handleUmbrellaRoleSubmit={handleCreateUmbrellaRoleSubmit}
        />
    )
}

export default CreateUmbrellaRole