import axiosClient from 'api/axiosClient'
import { TBatchJob, TCancelJobsRequest, TJob, TRecentJob, TRetryJobsRequest, TSingleJob } from 'types/businessLogic/job'
import { TFetchParamsRequest, TGroupParams, TGroupResponse } from 'types/network'

class JobService {
    private static _api = axiosClient()

    public static async getJobsForBatchJob(params: TFetchParamsRequest): Promise<{jobs: TJob[], totalNumber: number}> {
        const response = await this._api.get<{jobs: TJob[], totalNumber: number}>('Jobs/JobsForBatchJob', {params: params})
        return response.data
    }
    
    public static async getJobs(params: TFetchParamsRequest): Promise<{jobs: TJob[], totalNumber: number}> {
        const response = await this._api.get<{jobs: TJob[], totalNumber: number}>('Jobs', {params: params})
        return response.data
    }

    public static async getSingleJob(id: number): Promise<TSingleJob> {
        const response = await this._api.get<TSingleJob>('Jobs/SingleJob',
            {
                params: { id }
            }
        )
        return response.data
    }

    public static async getBatchJob(id: number): Promise<TBatchJob> {
        const response = await this._api.get<TBatchJob>('Jobs/BatchJob',
            {
                params: { id }
            }
        )
        return response.data
    }

    public static async getRecentJobs(amount: number): Promise<TRecentJob[]> {
        const response = await this._api.get<TRecentJob[]>('Jobs/RecentJobs', {
            params: { amount }
        })
        return response.data
    }

    public static async getJobsGroups(params: TGroupParams) {
        const response = await this._api.get<TGroupResponse>(`Jobs/groups/${params.groupBy}`, {
            params: {
                Descending: params.descending,
                Filters: params.filters,
                PageSize: params.pageSize,
                PageNumber: params.pageNumber
            }
        })
        return response.data
    }

    public static async getBatchJobsGroups(params: TGroupParams) {
        const response = await this._api.get<TGroupResponse>(`Jobs/JobsForBatchJob/groups/${params.groupBy}`, {
            params: {
                Descending: params.descending,
                Filters: params.filters,
                PageSize: params.pageSize,
                PageNumber: params.pageNumber
            }
        })
        return response.data
    }

    public static async cancelJobs(cancelJobRequest: TCancelJobsRequest) {
        await this._api.post('Jobs/CancelJobs', cancelJobRequest)
    }

    public static async retryJobs(retryJobRequest: TRetryJobsRequest) {
        await this._api.post('Jobs/RetryJobs', retryJobRequest)
    }
}

export default JobService