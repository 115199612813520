import { SiteEntityService } from 'services/siteEntityService'
import { TAllUpdateFirmwareJobRequest, TChangeAllCameraPasswordJobRequest, TChangeCameraPasswordJobRequest, TJobRequest, TUpdateFirmwareJobRequest } from 'types/businessLogic/job'
import { TCamerasRestartRequest } from 'types/businessLogic/misc'
import { TSiteEntity } from 'types/businessLogic/site'
import { TCameraRequest } from 'types/network'

class SiteCameraService extends SiteEntityService {
    constructor() {
        super('Cameras')
    }

    public async getSiteCameras(params: TCameraRequest): Promise<{siteEntities: TSiteEntity[], totalNumber: number}> {
        const response = await this._api.post<{siteEntities: TSiteEntity[], totalNumber: number}>('Cameras', params)
        return response.data
    }

    public async getSiteCameraMonitoring(id: number): Promise<TSiteEntity> {
        const response = await this._api.get<TSiteEntity>(`${this.urlPrefix}/Monitoring/${id}`)
        return response.data
    }

    public async restartCameras(request: TCamerasRestartRequest) {
        await this._api.post(`${this.urlPrefix}/Restart`, request)
    }

    public async restartAllCameras(jobRequest: TJobRequest) {
        await this._api.post(`${this.urlPrefix}/Restart/All`, jobRequest)
    }

    public async changeCameraPassword(jobRequest: TChangeCameraPasswordJobRequest) {
        await this._api.post(`${this.urlPrefix}/ChangeCameraPassword`, jobRequest)
    }

    public async changeAllCameraPassword(jobRequest: TChangeAllCameraPasswordJobRequest) {
        await this._api.post(`${this.urlPrefix}/All/ChangeCameraPassword`, jobRequest)
    }

    public async updateFirmware(jobRequest: TUpdateFirmwareJobRequest) {
        await this._api.post(`${this.urlPrefix}/UpdateFirmware`, jobRequest)
    }

    public async updateAllFirmware(jobRequest: TAllUpdateFirmwareJobRequest) {
        await this._api.post(`${this.urlPrefix}/All/UpdateFirmware`, jobRequest)
    }
}

export const siteCameraService = new SiteCameraService()