import { useCallback } from 'react'
import { useAppSelector } from 'state/store'
import { ELicenseTypes } from 'enums/ELicenseTypes'

const useLicense = () : (license: ELicenseTypes | undefined, ignoreForSituatorExclusive?: boolean) => boolean => {
    const { report, monitoring, configuration, webApi, situatorExclusive } = useAppSelector(state => state.license)

    return useCallback((license: ELicenseTypes | undefined, ignoreForSituatorExclusive?: boolean): boolean => {
        if (situatorExclusive && ignoreForSituatorExclusive) {
            return false
        }
        
        switch (license) {
            case ELicenseTypes.CONFIGURATION:
                return configuration
            case ELicenseTypes.MONITORING:
                return monitoring
            case ELicenseTypes.REPORT:
                return report
            case ELicenseTypes.WEBAPI:
                return webApi
            case undefined:
                return true
            default:
                return false
        }
    }, [report, monitoring, configuration, situatorExclusive, webApi])
}

export default useLicense