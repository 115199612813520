import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGroup, TTableFetchState } from 'types/network'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { clearGroups, getGroupDataAsync, getSiteServicesAsync, getSiteServicesPropertiesAsync } from 'state/slices/siteServiceSlice'
import { siteServiceService } from 'services/siteServiceService'
import SiteServicesTablePreparer from 'views/Control/SiteServices/siteServicesTablePreparer'
import { tableInfo } from 'views/Control/SiteServices/siteServicesTableColumns'
import SiteServiceDetails from 'views/Control/SiteServices/SiteServiceDetails/SiteServiceDetails'
import SiteServices from 'views/Control/SiteServices/SiteServices'

import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { useModal } from 'hooks/modalProvider'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const SiteServicesContainer = () => {
    const dispatch = useAppDispatch()
    const { siteServices, totalNumber, groups, properties } = useAppSelector(state => state.siteService)
    const {showModal} = useModal()
    useBreadCrumbs([i18nKeys.breadcrumb_site_services])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams,
            translationKey: group.translationKey
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const requestParams = GeneralHelper.buildRequestFromTableState(params)
            dispatch(getSiteServicesAsync(requestParams))
            dispatch(getSiteServicesPropertiesAsync())
        } else if (params.groupName) {
            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, params.groupName)
            dispatch(getGroupDataAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await siteServiceService.getSiteEntityGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }

            getGroups()
        }
    }, [dispatch])

    const openSiteGroupDetailsModal = (id: number) => {
        const siteServiceDetails = siteServices.find(siteGroup => siteGroup.id === id)

        if (!siteServiceDetails) return

        showModal({
            content: <SiteServiceDetails siteService={siteServiceDetails} />,
            title: siteServiceDetails.name,
            description: i18nKeys.siteService_details_title
        })
    }

    const data = SiteServicesTablePreparer.prepareSiteServiceRows(siteServices, openSiteGroupDetailsModal)
    const preparedGroups = SiteServicesTablePreparer.prepareGroups(groups, openSiteGroupDetailsModal)
        
    return (
        <ViewContainer>
            <SiteServices
                data={data}
                groups={preparedGroups}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                dynamicPropertiesColumns={properties}
                getTableData={getTableData}
                getGroupData={getGroupData}
            />

        </ViewContainer>
    )
}

export default SiteServicesContainer