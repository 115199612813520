import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'
import colors from 'style/colors'

type TProps = {
    size?: number
    thickness?: number
    color?: string
}

const CustomCircularProgress = styled(CircularProgress)<{$color: string}>`
    color: ${p => p.$color};
`

const defaultProps = {
    size: 17,
    thickness: 6
}

const CircularLoader = (props: TProps) => {
    return (
        <CustomCircularProgress
            $color={props.color ? props.color : colors.shades.PURE_WHITE}
            size={props.size ?? defaultProps.size}
            thickness={props.thickness ?? defaultProps.thickness}
        />)
}

export default CircularLoader