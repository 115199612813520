import { Step, StepLabel, Stepper } from '@mui/material'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'
import React from 'react'
import { TTranslationKey } from 'types/ui/translation'
import TransMessage from 'components/TransMessage/TransMessage'
import colors from 'style/colors'

const CustomStepLabel = styled(StepLabel)`
    .MuiStepLabel-label {
        font-family: ${fonts.PRIMARY_REGULAR};
        font-size: ${typographyScale.TYPE16};
        line-height: ${lineHeights.TYPE14LineHeight};
        letter-spacing: ${letterSpacings.TYPE16LS};
    }

    .MuiStepIcon-text {
            fill: ${colors.shades.PURE_WHITE};
            font-family: ${fonts.PRIMARY_REGULAR};
        }

    .MuiStepLabel-iconContainer {
        transform: scale(1.75);
    }
`

type TProps = {
    stepIndex: number
    steps: TTranslationKey[]
}

const CustomStepper = (props: TProps) => {
    const {stepIndex, steps} = props

    return (
        <Stepper
            activeStep={stepIndex} alternativeLabel
        >
            {steps.map((label) => (
                <Step key={label.id}>
                    <CustomStepLabel>
                        <TransMessage {...label} />
                    </CustomStepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

export default CustomStepper