import React from 'react'
import { TileLayer } from 'react-leaflet'
import { TLanguages } from 'types/ui/translation'

interface CustomTileLayerProps {
    language: TLanguages
}

const tileServer = {
    en: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    fr: 'https://a.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
    de: 'https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png'
}

export const CustomTileLayer = ({language} : CustomTileLayerProps) => {
    if (language === 'de') {
        return <TileLayer url={tileServer['de']} />
    }

    if (language === 'fr') {
        return <TileLayer url={tileServer['fr']} />
    }

    return <TileLayer url={tileServer['en']} />
}