import { EDataSyncItems } from 'enums/enums'
import { getState, dispatch } from 'state/store'
import { removeItem } from 'state/slices/dataSyncSlice'

const PULL_INTERVALL = 250

export type TSyncCallback = {
    disabled: boolean
    id: string
    syncItems: EDataSyncItems[]
    callback: () => void
}

class BackgroundService {
    private _interval: NodeJS.Timeout | undefined
    private _callbacks: TSyncCallback[] = []

    public start() {
        if (!this._interval) {
            this._interval = setInterval(() => this.intervalEllapsed(), PULL_INTERVALL)
        }
    }

    public stop() {
        if (this._interval) {
            clearInterval(this._interval)
            this._interval = undefined
            this._callbacks = []
        }
    }

    public registerWithSyncItem(syncItem: EDataSyncItems | EDataSyncItems[], callback: () => void, id: string) {
        const item = this._callbacks.find(x => x.id === id)
        
        let syncItemArray: EDataSyncItems[] = []
        if (Array.isArray(syncItem)) {
            syncItemArray = syncItem
        } else {
            syncItemArray = [syncItem]
        }

        if (item) {
            item.callback = callback
        } else {
            this._callbacks.push({
                disabled: false,
                id,
                syncItems: syncItemArray,
                callback
            })
        }
    }

    public unregister(id: string) {
        const index = this._callbacks.findIndex(x => x.id === id)
        if (index !== -1) {
            this._callbacks.splice(index, 1)
        }
    }

    public toggleDisabled(id: string) {
        const callback = this._callbacks.find(x => x.id === id)
        if (callback) {
            callback.disabled = !callback.disabled
        }
    }

    public setDisabled(id: string, value: boolean) {
        const callback = this._callbacks.find(x => x.id === id)
        if (callback) {
            callback.disabled = value
        }
    }

    private intervalEllapsed() {
        const items = getState().dataSync.items
        items.forEach(syncItem => {
            const filteredCallbacks = this._callbacks.filter(x => x.syncItems.includes(syncItem))
            filteredCallbacks.forEach(item => {
                if (!item.disabled) {
                    item.callback()
                }
            })
            dispatch(removeItem(syncItem))
        })
    }
}

export const backgroundService = new BackgroundService()