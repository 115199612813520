import { EMessageType } from 'enums/enums'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import { TTranslationKey } from 'types/ui/translation'

export interface SnackBarHook {
    success: (translationKey: TTranslationKey) => void
    error: (translationKey: TTranslationKey) => void
}

export const useSnackBar = () : SnackBarHook => {
    const dispatch = useAppDispatch()
    
    const success = (translationKey: TTranslationKey) => {
        dispatch(showMessage({message: translationKey, type: EMessageType.SUCCESS}))
    }

    const error = (translationKey: TTranslationKey) => {
        dispatch(showMessage({message: translationKey, type: EMessageType.ERROR}))
    }

    return {
        success,
        error
    }
}