import { useEffect } from 'react'
import { setBreadCrumbs } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import { TTranslationKey } from 'types/ui/translation'

const useBreadCrumbs = (input: (string | TTranslationKey)[]) => {
    const dispatch = useAppDispatch()
    
    useEffect(() => {
        dispatch(setBreadCrumbs(input))

        return () => {
            dispatch(setBreadCrumbs(['']))
        }
    }, [dispatch, input])
}

export default useBreadCrumbs