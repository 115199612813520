// eslint-disable-next-line import/no-anonymous-default-export
export default {
    primary: {
        DARK_PRIMARY: '#303030',
        DARKER_PRIMARY: '#5c7572',
        DEFAULT_PRIMARY: '#00b09c',
        DEFAULT_DISABLED: '#13948e',
        LIGHT_PRIMARY: '#c9e7e4'
    },
    brandColors: {
        BLUE: '#2c5697',
        RED: '#ba0c2f'
    },
    extra: {
        ALERT: '#ED5371',
        SUCCESS: '#3BC794',
        WARNING: '#FCAE69',
        BLUE: '#2E75B9',
        OFFLINE: '#89a0b3',
        VALIDATION_ERROR: '#f44336',
        EXTRA_GRAY: '#c6ccd4'
    },
    shades: {
        DEEP_SHADE: '#999999',
        DARK_SHADE: '#c2c0bf',
        LIGHT_SHADE: '#f0f0f0',
        MID_SHADE: '#e7e7e7',
        PURE_WHITE: '#FFFFFF',
        LIGHT_WHITE: '#fbfbfb',
        PURE_BLACK: '#000000',
        GRAY_SHADE: '#dddddd',
        DARK_GRAY_SHADE: '#737373'
    },
    projectColors: {
        BG_COLOR: '#f4f4f4',
        STROKE: '#8884d8',
        ALERT: '#e64666'
    }
}