import React from 'react'
import TransMessage from 'components/TransMessage/TransMessage'
import { EPlugin, ETableFilters } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'
import AppConstants from 'helpers/AppConstants'

const columnConfig: TTableColumnsConfig = {
    hostName: {
        translationKey: i18nKeys.plugin_list_table_head_hostname.id,
        key: 'hostName',
        sortKey: 'HostName',
        groupKey: 'HostName',
        displayed: true,
        filter: {
            key: 'HostName',
            type: ETableFilters.INPUT_TEXT
        }
    },
    siteName: {
        key: 'siteName',
        translationKey: i18nKeys.plugin_list_table_head_sitename.id,
        sortKey: 'SiteName',
        groupKey: 'SiteName',
        displayed: true,
        filter: {
            key: 'SiteName',
            type: ETableFilters.INPUT_TEXT
        }
    },
    siteLabels: {
        key: 'SiteLabels',
        translationKey: i18nKeys.site_groups_table_head_site_labels.id,
        displayed: true,
        filter: {
            key: 'SiteLabels',
            type: ETableFilters.INPUT_TEXT
        }
    },
    type: {
        key: 'type',
        translationKey: i18nKeys.site_list_table_head_type.id,
        sortKey: 'Type',
        groupKey: 'Type',
        displayed: false,
        filter: {
            key: 'Type',
            type: ETableFilters.DROPDOWN,
            dropdownItems: [
                {
                    text: <TransMessage {...i18nKeys.dropdown_selection_none} />,
                    value: ''
                },
                {
                    text: <TransMessage {...i18nKeys.plugin_type_monitoring} />,
                    value: EPlugin.Monitoring
                }
                // ,
                // {
                //     text: <TransMessage {...i18nKeys.plugin_type_vms} />,
                //     value: EPlugin.VMS
                // }
            ]
        }
    },
    host: {
        translationKey: i18nKeys.site_list_table_head_host.id,
        key: 'host',
        sortKey: 'Host',
        groupKey: 'Host',
        displayed: true,
        filter: {
            key: 'Host',
            type: ETableFilters.INPUT_TEXT
        }
    },
    port: {
        key: 'port',
        translationKey: i18nKeys.plugin_list_table_head_port.id,
        sortKey: 'Port',
        displayed: true
    }
}

const defaultLayout = [
    columnConfig.hostName.key,
    columnConfig.siteName.key,
    columnConfig.siteLabels.key,
    columnConfig.type.key,
    columnConfig.host.key,
    columnConfig.port.key
]

export const tableInfo : TTableInfo = {
    id: AppConstants.tableIds.PLUGINS,
    columnConfig,
    defaultLayout
}