import { getDashboardLayoutAsync } from 'state/slices/profileSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import Dashboard from 'views/Dashboard/Dashboard'
import React, { useEffect, useState } from 'react'
import Icon, { Icons } from 'components/Icon/Icon'
import styled from 'styled-components'
import colors from 'style/colors'
import zIndex from 'style/zIndex'
import borderRadius from 'style/borderRadius'
import { useNavigate } from 'react-router'

const ExitFullscreen = styled.div<{expanded: boolean}>`
    cursor: pointer;
    height: ${p => p.expanded ? 40 : 20}px;
    width: 40px;
    background-color: ${colors.primary.DEFAULT_PRIMARY};
    border-bottom-right-radius: ${borderRadius.PRIMARY};
    border-bottom-left-radius: ${borderRadius.PRIMARY};
    display: grid;
    place-items: center;
    position: fixed;
    right: 36px;
    top: 0px;
    z-index: ${zIndex.HIGH};
    transition: all .2s ease-out;
    fill: ${colors.shades.PURE_WHITE};
`

const ExitNotExpandedHandle = styled.div`
    display: block;
    width: 20px;
    height: 4px;
    border-radius: 2px;
    background-color: ${colors.shades.PURE_WHITE};
    align-self: center;
`

const FullscreenDashboardContainer = () => {
    const [expanded, setExpanded] = useState(false)
    const dispatch = useAppDispatch()
    const { dashboardLayout } = useAppSelector(store => store.profile)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getDashboardLayoutAsync())
    }, [dispatch])

    return (
        <>
            <ExitFullscreen
                expanded={expanded}
                onClick={() => navigate('/app/dashboard')}
                onMouseEnter={() => setExpanded(true)}
                onMouseLeave={() => setExpanded(false)}
            >
                {
                    expanded ? (
                        <Icon d={Icons.EXIT_FULL_SCREEN} />
                    ) : (
                        <ExitNotExpandedHandle />
                    )
                }
            </ExitFullscreen>
            <Dashboard
                dashboardLayout={dashboardLayout}
                fullscreenMode
                editMode={false}
            />
        </>
    )
    
}

export default FullscreenDashboardContainer