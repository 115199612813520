import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import styled from 'styled-components'

export const GridImage = styled.img<{active:boolean}>`
    width: 200px;
    object-fit: contain;
    border-radius: ${borderRadius.SECONDARY};
    border: solid 4px ${p => p.active ? colors.primary.DEFAULT_PRIMARY : colors.shades.MID_SHADE};
`

export const SelectedImage = styled.img`
    position: absolute;
    bottom: 12px;
    right: 12px;
    height: 32px;
    width: 32px;
`