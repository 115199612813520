import { IconButton } from '@mui/material'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import { KeyboardArrowLeft, KeyboardArrowRight, FirstPage, LastPage } from '@mui/icons-material'
import React from 'react'
import colors from 'style/colors'
import styled from 'styled-components'

const Root = styled.div`
    flex-shrink: 0;
    margin-left: 10px;
`
const CustomIconButton = styled(IconButton)`
    color: ${colors.shades.DEEP_SHADE};
`

const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage } = props

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onPageChange(event, 0)
    }

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onPageChange(event, page + 1)
    }

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onPageChange(event, getLastPage())
    }

    const getLastPage = () => Math.max(0, Math.ceil(count / rowsPerPage) - 1)

    const isBackButtonDisabled = () => page === 0

    const isNextButtonDisabled = () => page >= getLastPage()

    return (
        <Root>
            <CustomIconButton
                id='paginationFirstPageButton'
                onClick={handleFirstPageButtonClick}
                disabled={isBackButtonDisabled()}>
                <FirstPage />
            </CustomIconButton>
            <CustomIconButton
                id='paginationBackButton'
                onClick={handleBackButtonClick}
                disabled={isBackButtonDisabled()}>
                <KeyboardArrowLeft />
            </CustomIconButton>
            <CustomIconButton
                id='paginationNextButton'
                onClick={handleNextButtonClick}
                disabled={isNextButtonDisabled()}>
                <KeyboardArrowRight />
            </CustomIconButton>
            <CustomIconButton
                id='paginationLastPageButton'
                onClick={handleLastPageButtonClick}
                disabled={isNextButtonDisabled()}>
                <LastPage />
            </CustomIconButton>
        </Root>
    )
}

export default TablePaginationActions