import axiosClient from 'api/axiosClient'
import { TFetchParamsRequest, TGroupParams, TGroupResponse } from 'types/network'
import { TDeleteAllPluginsRequest, TPlugin, TPluginKindType } from 'types/businessLogic/plugin'

class PluginService {
    private static _api = axiosClient()

    public static async getPlugins(params: TFetchParamsRequest) {
        const response = await this._api.get<{plugins: TPlugin[], totalNumber: number}>('Plugins', {
            params
        })
        return response.data
    }

    public static async deletePlugins(ids: number[]) {
        await this._api.delete('Plugins', {
            data: {
                Ids: ids
            }
        })
    }

    public static async getGroups(params: TGroupParams) {
        const response = await this._api.get<TGroupResponse>(`Plugins/groups/${params.groupBy}`, {
            params: {
                Descending: params.descending,
                Filters: params.filters,
                PageSize: params.pageSize,
                PageNumber: params.pageNumber
            }
        })
        return response.data
    }

    public static async getPluginKindTypes() {
        const response = await this._api.get<TPluginKindType[]>('Plugins/Types')
        return response.data
    }

    public static async deleteAllPlugins(params: TDeleteAllPluginsRequest) {
        await this._api.delete('Plugins/All', {
            data: params
        })
    }
}

export default PluginService