import Icon, { Icons } from 'components/Icon/Icon'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'

const OuterContainer = styled.div`
    height: 100%;
    width: 380px;
`
  
const Container = styled.div`
    cursor: pointer;
    outline: 0;
    height: 100%;
    width: 100%;
    border: 2px dashed ${colors.shades.DARK_SHADE};
    display: grid;
    place-items: center;
  `

const InnerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    fill: ${colors.shades.DEEP_SHADE};
`

const UploadText = styled.p`
    margin-top: 16;
    color: ${colors.shades.DEEP_SHADE};
    font-family: ${fonts.PRIMARY_REGULAR};
    font-size: ${typographyScale.TYPE16};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE16LS};
`

type TProps = {
    onDrop: (acceptedFile: File[]) => void
}

const UploadNewFirmwareDropZone = (props: TProps) => {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxFiles: 1,
        onDrop: props.onDrop
    })
    
    return (
        <OuterContainer>
            <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                <input type='file' {...getInputProps()} />
                <InnerContainer>
                    <Icon
                        d={Icons.UPLOAD}
                        size={72}
                    />
                    <UploadText>
                        <FormattedMessage
                            {...i18nKeys.camera_firmware_modal_dropzone} />
                    </UploadText>
                </InnerContainer>
      
            </Container>
        </OuterContainer>
    )
}

export default UploadNewFirmwareDropZone