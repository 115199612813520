import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import React from 'react'
import { useAppSelector } from 'state/store'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'
import { TTranslationKey } from 'types/ui/translation'

const Wrapper = styled.div`
    width: 100%;
    padding-left: 24px;
    display: flex;
    justify-content: flex-start;
    color: ${colors.shades.DEEP_SHADE};
    fill: ${colors.shades.PURE_BLACK};
`
const BreadCrumbText = styled.div`
    font-family: ${fonts.PRIMARY_REGULAR};
    font-size: ${typographyScale.TYPE14};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE14LS};
`

const renderBreadCrumbs = (breadCrumbs: (string | TTranslationKey)[]) => {

    return breadCrumbs.map((breadCrumb, index) => {
        if (typeof breadCrumb === 'string') {
            return (
                <React.Fragment key={index}>
                    <BreadCrumbText>{breadCrumb}</BreadCrumbText>
                    {
                        index < breadCrumbs.length - 1 &&
                    <Icon d={Icons.ARROW_RIGHT} title={`arrow_${index}`} rotate={-90} opacity={0.3} />
                    }
                </React.Fragment>)
        }
        
        return (
            <React.Fragment key={index}>
                <BreadCrumbText>
                    <TransMessage {...breadCrumb} />
                </BreadCrumbText>
                {
                    index < breadCrumbs.length - 1 &&
                    <Icon d={Icons.ARROW_RIGHT} title={`arrow_${index}`} rotate={-90} opacity={0.3} />
                }
            </React.Fragment>)
    })
}

const BreadCrumbs = () => {
    const {breadcrumbs} = useAppSelector(store => store.ui)

    return <Wrapper>
        {renderBreadCrumbs(breadcrumbs)}
    </Wrapper>
}

export default BreadCrumbs