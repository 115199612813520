import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import SiteEventsService from 'services/siteEventsService'
import { showMessage } from 'state/slices/uiSlice'
import { TSiteEvent } from 'types/businessLogic/siteEvent'
import { TFetchParamsRequest } from 'types/network'
import { TTableGroup } from 'types/ui/table'

export type TSiteEventsState = {
    groups: TTableGroup<TSiteEvent>[]
    items: TSiteEvent[]
    totalNumber: number
    lastUpdate: number
}

const initialState: TSiteEventsState = {
    lastUpdate: 0,
    items: [],
    groups: [],
    totalNumber: 0
}

export const getSiteEventsAsync = createAsyncThunk('siteEvents/getSiteEventsAsync', async (params: TFetchParamsRequest, thunkApi) => {
    try {
        return await SiteEventsService.getSiteEvents(params)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getGroupDataAsync = createAsyncThunk('siteEvents/getGroupDataAsync', async (params: TFetchParamsRequest & { translationKey?: string}, thunkApi) : Promise<TTableGroup<TSiteEvent>> => {
    try {
        const result = await SiteEventsService.getSiteEvents(params)
        return {
            name: params.GroupName!,
            translationKey: params.translationKey,
            count: result.totalNumber,
            data: result.siteEvents
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'siteEvents',
    initialState: initialState,
    reducers: {
        clearGroups: (state, action : PayloadAction<{ totalNumber: number, groups: TTableGroup<TSiteEvent>[] }>) => {
            action.payload.groups.forEach(item => {
                const oldItem = state.groups.find(x => x.name === item.name)
                if (oldItem) {
                    item.data = oldItem.data
                }
            })

            state.groups = action.payload.groups
            state.totalNumber = action.payload.totalNumber
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSiteEventsAsync.fulfilled, (state, action) => {
                state.items = action.payload.siteEvents
                state.totalNumber = action.payload.totalNumber
                state.lastUpdate = Date.now()
            })
            .addCase(getGroupDataAsync.fulfilled, (state, action) => {
                const existingGroup = state.groups.find(p => p.name === action.payload.name)
                if (existingGroup) {
                    existingGroup.data = action.payload.data
                    existingGroup.count = action.payload.count
                } else {
                    state.groups.push(action.payload)
                }

                action.payload.data?.forEach(x => {
                    const index = state.items.findIndex(y => y.id === x.id)
                    if (index === -1) {
                        state.items.push(x)
                    } else {
                        state.items[index] = x
                    }
                })
            })
    }
})

export const {clearGroups} = slice.actions

export default slice.reducer