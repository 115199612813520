import Message from 'components/Message/Message'
import React from 'react'
import { useAppSelector } from 'state/store'
import zIndex from 'style/zIndex'
import styled from 'styled-components'

const Container = styled.div`
    position: fixed;
    bottom: 40px;
    right: 40px;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    z-index: ${zIndex.MESSAGES};
`

const MessageList = () => {
    const messages = useAppSelector(state => state.ui.messages)

    return (
        <Container>
            {
                messages.map(message =>
                    <Message key={message.id} {...message} />
                )
            }
        </Container>
    )
}

export default MessageList