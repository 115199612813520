import { ETableFilters } from 'enums/enums'
import AppConstants from 'helpers/AppConstants'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'

const columnConfig: TTableColumnsConfig = {
    apiKey: {
        key: 'ApiKey',
        translationKey: i18nKeys.apiKeys_table_head_apikey.id,
        displayed: true,
        sortKey: 'ApiKey',
        filter: {
            key: 'ApiKey',
            type: ETableFilters.INPUT_TEXT
        }
    },
    creator: {
        key: 'Creator',
        translationKey: i18nKeys.apiKeys_table_head_creator.id,
        displayed: true,
        sortKey: 'Creator',
        filter: {
            key: 'Creator',
            type: ETableFilters.INPUT_TEXT
        }
    },
    creationDate: {
        key: 'CreationDate',
        translationKey: i18nKeys.apiKeys_table_head_creationdate.id,
        displayed: true,
        sortKey: 'CreationDate'
    }
}

const defaultLayout = [
    columnConfig.apiKey.key,
    columnConfig.creator.key,
    columnConfig.creationDate.key
]

export const tableInfo: TTableInfo = {
    id: AppConstants.tableIds.API_KEYS,
    columnConfig,
    defaultLayout
}