import { FormLabel } from '@mui/material'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { InputWrapper } from 'components/FormControls/FormControls'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'
import { TCustomComment } from 'types/businessLogic/site'

const CustomFormLabel = styled(FormLabel)`
    word-break: break-all;
    color: ${colors.primary.DARK_PRIMARY};
    font-family: ${fonts.PRIMARY_REGULAR};
    font-size: ${typographyScale.TYPE16};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE16LS};
    width: 100%;
    max-width: 250px;

    &:first-child {
        font-family: ${fonts.PRIMARY_SEMIBOLD}
    }
`

const StyledIcon = styled(Icon)`
    margin-left: 16px;
    fill: ${colors.primary.DEFAULT_PRIMARY};
`

type TProps = TCustomComment & {
    onDelete: (id: number) => void
    onModify: (comment: TCustomComment) => void
    selected: boolean
    readonly?: boolean
}

const CommentItem = (props: TProps) => {
    const {id, title, description, onDelete, onModify, readonly} = props

    return (
        <InputWrapper>
            <CustomFormLabel>
                {title}
            </CustomFormLabel>
            <CustomFormLabel>
                {description}
            </CustomFormLabel>
            {
                !readonly && (
                    <>
                        <CustomTooltip title={
                            <TransMessage {...i18nKeys.site_details_comment_remove_comment} />}>
                            <div>
                                <StyledIcon
                                    d={Icons.CANCEL}
                                    onClick={() => onDelete(id)}
                                />
                            </div>
                        </CustomTooltip>
                        <CustomTooltip title={
                            <TransMessage {...i18nKeys.site_details_comment_update_comment} />}>
                            <div>
                                <StyledIcon
                                    d={Icons.EDIT}
                                    onClick={() => onModify(props)}
                                />
                            </div>
                        </CustomTooltip>
                    </>
                )
            }
        </InputWrapper>
    )
}

export default CommentItem