import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import TransMessage from 'components/TransMessage/TransMessage'
import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { Cell, Pie, PieChart } from 'recharts'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'
import { TTranslationKey } from 'types/ui/translation'

export type TChartEntry = {
    id: string
    name: TTranslationKey | string
    value: number
    color: string
    tooltip?: TTranslationKey | string
    redirectTo?: string
}

const emptyChartData = [{
    id: 'empty',
    name: 'empty',
    value: 1,
    color: colors.shades.LIGHT_SHADE
}]

//#region ------------ LegendDot ------------------------------------------------------------------------------------

const LegendDotWrapper = styled.div<{color: string}>`
    & > div {
        display: inline-block;
        margin-bottom: -1;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: ${p => p.color};
    }

    & span:first-of-type {
        padding-left: 8px;
        font-family: ${fonts.PRIMARY_BOLD};
        color: ${colors.primary.DARK_PRIMARY};
        font-size: ${typographyScale.TYPE20};
        line-height: ${lineHeights.TYPE20LineHeight};
        letter-spacing: ${letterSpacings.TYPE20LS};
    }

    & span:last-of-type {
        display: block;
        margin-top: -5px;
        font-family: ${fonts.PRIMARY_MEDIUM};
        color: ${colors.shades.DEEP_SHADE};
        font-size: ${typographyScale.TYPE12};
        line-height: ${lineHeights.TYPE12LineHeight};
        letter-spacing: ${letterSpacings.TYPE12LS};
    }
`

type TLegendDot = {
    value: string | number
    color: string
    name: TTranslationKey | string
}

const LegendDot = (props: TLegendDot) => {
    return (
        <LegendDotWrapper color={props.color}>
            <div />
            <span>{props.value}</span>
            <span>
                { props.name === typeof String && <div>{props.name}</div>}
                { props.name !== typeof String && <TransMessage {...props.name as TTranslationKey} />}
            </span>
        </LegendDotWrapper>
    )
}

//#endregion ------------ LegendDot ------------------------------------------------------------------------------------

//#region ------------ ChartLegend ------------------------------------------------------------------------------------

const ChartLegendWrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
`

type TChartLegendProps = {
    data: TChartEntry[]
}

export const ChartLegend = (props : TChartLegendProps) => {

    const renderLegendDot = (item: TChartEntry) => {
        if (item.redirectTo) {
            return (
                <Link id={item.id} to={item.redirectTo}>
                    <LegendDot {...item} />
                </Link>
            )
        } else {
            return (
                <div>
                    <LegendDot {...item} />
                </div>
            )
        }
    }

    return (
        <ChartLegendWrapper>
            {props.data.map((item: TChartEntry, index: number) => (
                <CustomTooltip key={index} title={item.tooltip ?? ''}>
                    {renderLegendDot(item)}
                </CustomTooltip>
            
            ))}
        </ChartLegendWrapper>
    )
}

//#endregion ------------ ChartLegend ------------------------------------------------------------------------------------

//#region ------------ CardHeader ------------------------------------------------------------------------------------

const WrapperCardHeader = styled.h2`
    position: absolute;
    top: 16px;
    left: 30px;
`

export const CardHeader = (props: {title: TTranslationKey}) => {
    return (
        <WrapperCardHeader>
            <TransMessage {...props.title} />
        </WrapperCardHeader>
    )
}

export const CardWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
`

export const DashboardCard = styled.div`
    height: 100%;
    width: 100%;
    background-color: pink;
`

//#endregion ------------ CardHeader ------------------------------------------------------------------------------------

//#region ------------ ChartCard ------------------------------------------------------------------------------------

type TCardProps = {
    title?: TTranslationKey
}

export const ChartCard = (props: PropsWithChildren<TCardProps>) => {
    return (
        <>
            {props.children}
        </>
    )
}

//#endregion ------------ ChartCard ------------------------------------------------------------------------------------

//#region ------------ CustomPieChart ------------------------------------------------------------------------------------

type TCustomPieChartProps = {
    data: TChartEntry[]
    large?: boolean
    chartInnerDesc?: TTranslationKey
    chartInnerNumber?: number
    tooltip? : string
}

const ChartDesc = styled.div`
    text-align: center;
    position: absolute;
    font-family: ${fonts.PRIMARY_REGULAR};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & span:first-of-type {
        display: block;
        color: ${colors.primary.DARK_PRIMARY};
        font-family: ${fonts.PRIMARY_HEAVY};
        font-size: ${typographyScale.TYPE32};
        line-height: ${lineHeights.TYPE32LineHeight};
        letter-spacing: ${letterSpacings.TYPE32LS};
    }
`

const PieWrapper = styled.div`
    position: relative;
    width: 210px;
    height: 210px;
`
export const PieChartPositioner = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

export const CustomPieChart = (props: TCustomPieChartProps) => {
    const {data, large} = props
    
    const radius = {
        INNER_SM: 92,
        OUTER_SM: 100,
        INNER_XL: 66,
        OUTER_XL: 100
    }

    const transformedData = (): TChartEntry[] => {
        const isEmpty = data.every((entry: TChartEntry) => !entry.value)
        return isEmpty ? emptyChartData : data
    }

    return (
        <>
            <CustomTooltip title={props.tooltip ?? ''}>
                <PieWrapper>
                    <ChartDesc>
                        <span>{props.chartInnerNumber}</span>
                        <span>
                            {
                                props.chartInnerDesc &&
                                <TransMessage {...props.chartInnerDesc} />
                            }
                        </span>
                    </ChartDesc>
                    <PieChart width={210} height={210}>
                        <Pie
                            dataKey='value'
                            blendStroke
                            data={transformedData() as any[]}
                            innerRadius={large ? radius.INNER_XL : radius.INNER_SM}
                            outerRadius={large ? radius.OUTER_XL : radius.OUTER_SM}>
                            {transformedData().map((entry: TChartEntry) => <Cell key={entry.id} fill={entry.color} />)}
                        </Pie>
                    </PieChart>
                </PieWrapper>
            </CustomTooltip>
        </>
    )
}

//#endregion ------------ CustomPieChart ------------------------------------------------------------------------------------

//#region ------------ TableCard ------------------------------------------------------------------------------------

export const TableInfoBlock = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    letter-spacing: 0.4px;
`

export const TableWrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;

    & .MuiTableCell-root {
        border: 0;
        padding: 20px 16px;
        color: #303030;
        font-size: 14px;
        font-family: LatoRegular;
        line-height: 24px;
        letter-spacing: 0.6px;
    }    
    & .header {
        color: #303030;
        font-size: 14px;
        font-family: LatoBold;
        line-height: 24px;
        letter-spacing: 0.6px;
    }

    & .desc {
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & .MuiTableRow-root {
        &:nth-of-type(even) {
            background-color: ${colors.shades.LIGHT_SHADE};
        }
    }
`

type TTableCardProps = TCardProps & {
    headers: TTranslationKey[]
}

export const TableCard = (props: PropsWithChildren<TTableCardProps>) => {
    const renderTableHead = () => {
        return (
            <TableRow>
                {props.headers.map((header, index) => {
                    return (
                        <TableCell key={index}>
                            <span className='header'>
                                <TransMessage {...header} />
                            </span>
                        </TableCell>
                    )
                })}
            </TableRow>
        )
    }

    return (
        <ChartCard title={props.title}>
            <TableWrapper>
                <Table>
                    <TableBody>
                        {renderTableHead()}
                        {props.children}
                    </TableBody>
                </Table>
            </TableWrapper>
        </ChartCard>
    )
}

//#endregion ------------ TableCard ------------------------------------------------------------------------------------