import axiosClient from 'api/axiosClient'
import { TFetchParamsRequest, TGroupParams, TGroupResponse } from 'types/network'
import { AxiosResponse } from 'axios'
import { TSiteAuditReport, TDeleteAllAuditReportsRequest, TDownloadAllAuditReportsRequest } from 'types/businessLogic/auditReport'

class AuditReportsService {
    private static _api = axiosClient()

    public static async getAuditReports(params: TFetchParamsRequest): Promise<{reports: TSiteAuditReport[], totalNumber: number}> {
        const response = await this._api.get<{reports: TSiteAuditReport[], totalNumber: number}>('AuditReports', {
            params: params
        })
        return response.data
    }
    
    public static async deleteAuditReports(ids: number[]) {
        await this._api.delete('AuditReports', {
            data: {
                Ids: ids
            }
        })
    }

    public static async deleteAllAuditReports(params: TDeleteAllAuditReportsRequest) {
        await this._api.delete('AuditReports/All', {
            data: params
        })
    }

    public static async downloadAuditReports(ids: number[]) {
        const response = await this._api.post('AuditReports/Download', {
            Ids: ids
        }, {
            responseType: 'arraybuffer',
            timeout: 5 * 60 * 1000,
        })

        this.downloadAuditReportZip(response)
    }

    public static async downloadAllAuditReports(params: TDownloadAllAuditReportsRequest) {
        const response = await this._api.post('AuditReports/All/Download', params, {
            responseType: 'arraybuffer',
            timeout: 5 * 60 * 1000,
        })
        this.downloadAuditReportZip(response)
    }

    public static async getAuditReportsGroups(params: TGroupParams) {
        const response = await this._api.get<TGroupResponse>(`AuditReports/groups/${params.groupBy}`, {
            params: {
                Descending: params.descending,
                Filters: params.filters,
                PageSize: params.pageSize,
                PageNumber: params.pageNumber
            }
        })
        return response.data
    }

    public static downloadAuditReportZip(response: AxiosResponse<any>) {
        const blob = new Blob([response.data], {type: response.headers['content-type']})
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.href = url
        const contentDisposition = response.headers['content-disposition']
        let fileName = 'site_audit_reports.zip'
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+);/)
            if (fileNameMatch && fileNameMatch.length === 2)
                fileName = fileNameMatch[1]
        }
        link.setAttribute('download', fileName)
        link.click()
        window.URL.revokeObjectURL(url)
        link.remove()
    }
}

export default AuditReportsService