import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import colors from 'style/colors'
import styled from 'styled-components'

const Root = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    background-color: ${colors.shades.PURE_WHITE};
    justify-content: space-between;
    
    & > div {
        display: flex;
    }
    & > div span {
        color: ${colors.primary.DARK_PRIMARY};
        font-size: 12px;
        font-family: LatoMedium;
        line-height: 24px;
        margin-left: 10px;
        letter-spacing: 0.6px;
    }
`

const IconWrapper = styled.div<{$color: string}>`
    fill: ${p => p.$color};
`

const MapLegend = () => {
    return (
        <Root>
            <IconWrapper $color={colors.extra.SUCCESS}>
                <Icon d={Icons.PIN} />
                <span><TransMessage {...i18nKeys.sites_with_location_healthy} /></span>
            </IconWrapper>
            <IconWrapper $color={colors.extra.WARNING}>
                <Icon d={Icons.PIN} />
                <span><TransMessage {...i18nKeys.sites_with_location_warning} /></span>
            </IconWrapper>
            <IconWrapper $color={colors.extra.ALERT}>
                <Icon d={Icons.PIN} />
                <span><TransMessage {...i18nKeys.sites_with_location_error} /></span>
            </IconWrapper>
            <IconWrapper $color={colors.shades.DARK_GRAY_SHADE}>
                <Icon d={Icons.PIN} />
                <span><TransMessage {...i18nKeys.sites_with_location_offline} /></span>
            </IconWrapper>
            <IconWrapper $color={colors.brandColors.BLUE}>
                <Icon d={Icons.PIN} />
                <span><TransMessage {...i18nKeys.sites_with_location_ignore} /></span>
            </IconWrapper>
        </Root>
    )
}
export default MapLegend