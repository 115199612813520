import axiosClient from 'api/axiosClient'
import { TApiKeysOverviewResponse, TFetchParamsRequest } from 'types/network'

class ApiKeyService {
    private static _api = axiosClient()

    public static async getOverview(params: TFetchParamsRequest) {
        const response = await this._api.get<TApiKeysOverviewResponse>('ApiKey/Overview', {
            params: params
        })
        return response.data
    }

    public static async deleteApiKeys(ids: number[]) {
        await this._api.delete('ApiKey', {
            data: {
                Ids: ids
            }
        })
    }

    public static async createApiKey() {
        await this._api.get('ApiKey/Create')
    }
}

export default ApiKeyService
