import { Select as MUISelect, SelectProps } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

export const CustomSelect = styled((props : SelectProps) => (
    <MUISelect {...props} MenuProps={{
        MenuListProps: {
            className : 'select-popup'
        }
    }} />)
)`
    font-family: LatoSemibold;
`