import React, { useCallback, useEffect } from 'react'
import { clearGroups, deleteAllSitesAsync, deleteSitesAsync, getGroupDataAsync, getSitesAsync } from 'state/slices/siteSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGroup, TTableFetchState } from 'types/network'
import { tableInfo } from 'views/Sites/siteTableColumns'
import Sites from './Sites'
import SiteDetails from 'views/Sites/SiteDetails/SiteDetails'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { TModalProps } from 'types/ui/modal'
import SiteService from 'services/siteService'
import SiteTablePreparer from 'views/Sites/siteTablePreparer'
import { TDeleteAllSitesRequest, TSiteAllApproveRequest, TSiteAllReportsRequest } from 'types/businessLogic/site'
import { EMessageType } from 'enums/enums'
import { showMessage } from 'state/slices/uiSlice'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { useParams } from 'react-router-dom'
import { useModal } from 'hooks/modalProvider'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const SitesContainer = () => {
    const dispatch = useAppDispatch()
    const { sites, totalNumber, groups } = useAppSelector(state => state.site)
    const { filter } = useParams()
    const pluginTypes = useAppSelector(state => state.pluginTypes.pluginTypes)
    const {showModal} = useModal()
    
    useBreadCrumbs([i18nKeys.breadcrumb_sites])

    useEffect(() => {
        
    }, [dispatch])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams,
            translationKey: group.translationKey
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const requestParams = GeneralHelper.buildRequestFromTableState(params)
            dispatch(getSitesAsync(requestParams))
        } else if (params.groupName) {
            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, params.groupName)
            dispatch(getGroupDataAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await SiteService.getGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }

            getGroups()
        }
    }, [dispatch])

    const openSiteDetailsModal = useCallback((id: number) => {
        const site = sites.find(x => x.id === id)
        if (site) {
            showModal({
                content: <SiteDetails site={site} />,
                title: site.name
            })
        }
    }, [showModal, sites])

    const deleteSites = (params: TModalProps) => {
        if (params.ids.length === 0 && params.filters) {
            const request: TDeleteAllSitesRequest = {
                filters: params.filters
            }
            
            dispatch(deleteAllSitesAsync(request))
        } else {
            dispatch(deleteSitesAsync(params.ids as number[]))
        }
    }

    const data = SiteTablePreparer.prepareSiteRows(sites, pluginTypes, openSiteDetailsModal)
    const preparedGroups = SiteTablePreparer.prepareGroups(groups, pluginTypes, openSiteDetailsModal)

    const approveSites = (params: TModalProps) => {
        try {
            if (params.ids.length === 0 && params.filters) {
                const request: TSiteAllApproveRequest = {
                    filters: params.filters
                }
                SiteService.approveAllSites(request)
            } else {
                SiteService.approveSites(params.ids as number[])
            }

            dispatch(showMessage({message: i18nKeys.site_list_approve_success, type: EMessageType.SUCCESS}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.site_list_approve_error, type: EMessageType.ERROR}))
        }
    }

    const ignoreSites = (params: TModalProps) => {
        try {
            if (params.ids.length === 0 && params.filters) {
                const request: TSiteAllReportsRequest = {
                    filters: params.filters
                }
                SiteService.ignoreAllSites(request)
            } else {
                SiteService.ignoreSites(params.ids as number[])
            }
            
            dispatch(showMessage({message: i18nKeys.site_list_ignore_success_message, type: EMessageType.SUCCESS}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.site_list_ignore_error_message, type: EMessageType.ERROR}))
        }
    }
    
    const restartSites = (params: TModalProps) => {
        try {
            SiteService.restartSites(params.ids as number[])
            dispatch(showMessage({message: i18nKeys.site_list_restart_update_success_message, type: EMessageType.SUCCESS}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.site_list_restart_error_message, type: EMessageType.ERROR}))
        }
    }

    const createSitesInventoryReport = (params: TModalProps) => {
        try {
            if (params.ids.length === 0 && params.filters) {
                const request: TSiteAllReportsRequest = {
                    filters: params.filters
                }
                SiteService.createAllSitesReports(request)
            } else {
                SiteService.createSiteReports(params.ids as number[])
            }

            dispatch(showMessage({message: i18nKeys.site_list_report_success, type: EMessageType.SUCCESS}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.site_list_report_error, type: EMessageType.ERROR}))
        }
    }

    return (
        <ViewContainer>
            <Sites
                preselectedFilter={filter}
                data={data}
                groups={preparedGroups}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                getTableData={getTableData}
                getGroupData={getGroupData}
                onDeleteSites={deleteSites}
                onApproveSites={approveSites}
                onCreateSitesInventoryReport={createSitesInventoryReport}
                onIgnoreSite={ignoreSites}
                onRestartSites={(params: TModalProps) => restartSites(params)}
            />
        </ViewContainer>
    )
}

export default SitesContainer
