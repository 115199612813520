import AuthGuard from 'components/PrivateRoute/AuthGuard'
import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AppRouter from 'router/AppRouter'
import { checkLoginAsync } from 'state/slices/authSlice'
import { getLanguageAsync, optionsAsync } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import ChangePasswordContainer from 'views/ChangePassword/ChangePasswordContainer'
import FullscreenDashboardContainer from 'views/Dashboard/FullscreenDashboardContainer'
import LoginContainer from 'views/Login/LoginContainer'
import NoRole from 'views/NoRole/NoRole'

const RootRouter = () => {
    const dispatch = useAppDispatch()
    
    useEffect(() => {
        dispatch(getLanguageAsync())
        dispatch(optionsAsync())
        dispatch(checkLoginAsync())
    }, [dispatch])
    
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<LoginContainer />} />
                <Route path='/login' element={<LoginContainer />} />
                <Route path='/norole' element={<NoRole />} />
                <Route path='/change-password' element={<ChangePasswordContainer />} />
                <Route path='/fullscreen/dashboard' element={<AuthGuard element={<FullscreenDashboardContainer />} />} />
                <Route path='/app/*' element={<AppRouter />} />
                <Route path='*' element={<LoginContainer />} />
            </Routes>
        </BrowserRouter>
    )
}

export default RootRouter
