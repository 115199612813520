import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

export const Icons = {
    CHEVRON: 'M8.175 9L12 12.709 15.825 9 17 10.142 12 15l-5-4.858z',
    WORLD: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z',
    EMAIL: 'M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z',
    LOCK: 'M18 9h-1V7c0-2.76-2.24-5-5-5S7 4.24 7 7v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V11c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V7c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z',
    EYE_SHOW: 'M12 5C7 5 2.73 8.11 1 12.5 2.73 16.89 7 20 12 20s9.27-3.11 11-7.5C21.27 8.11 17 5 12 5zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z',
    EYE_HIDE: 'M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46A11.804 11.804 0 0 0 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z',
    DASHBOARD: 'M10 19h5v-6h-5v6zm-6 0h5V6H4v13zm12 0h5v-6h-5v6zM10 6v6h11V6H10z',
    CONTROL_CENTRE: 'M20 18h4v2H0v-2h4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2l-.01 10c0 1.1-.89 2-1.99 2zm-4.94-6.61l.859.66a.19.19 0 0 1 .051.255l-.822 1.385a.206.206 0 0 1-.253.09l-1.023-.4c-.21.155-.442.29-.694.39l-.155 1.06c-.01.1-.097.17-.2.17h-1.646a.208.208 0 0 1-.206-.17l-.154-1.06c-.252-.1-.478-.23-.694-.39l-1.024.4c-.092.03-.2 0-.252-.09l-.823-1.385a.203.203 0 0 1 .052-.255l.87-.66c-.022-.125-.032-.26-.032-.39s.016-.265.036-.39l-.869-.66a.19.19 0 0 1-.051-.255l.822-1.385a.206.206 0 0 1 .253-.09l1.023.4c.21-.155.442-.29.694-.39l.155-1.06c.015-.1.097-.17.2-.17h1.646c.103 0 .19.07.206.17l.154 1.06c.252.1.478.23.694.39l1.024-.4c.092-.03.2 0 .252.09l.823 1.385a.203.203 0 0 1-.052.255l-.87.66c.022.125.032.255.032.39s-.01.265-.026.39zM12 12.5c.849 0 1.543-.675 1.543-1.5S12.849 9.5 12 9.5s-1.543.675-1.543 1.5.694 1.5 1.543 1.5zM4 6v10h16V6H4z',
    SITE: 'M20.66 18.084c.02-.17.034-.347.034-.53 0-.184-.014-.361-.041-.532l1.15-.898a.281.281 0 0 0 .069-.348l-1.09-1.886c-.068-.122-.21-.163-.333-.122l-1.355.545a3.989 3.989 0 0 0-.92-.531l-.204-1.444a.272.272 0 0 0-.272-.231H15.52a.265.265 0 0 0-.265.231l-.204 1.444c-.334.136-.64.32-.92.53l-1.354-.544a.27.27 0 0 0-.334.122l-1.09 1.886a.264.264 0 0 0 .069.348l1.15.898c-.027.17-.047.354-.047.531 0 .177.013.361.04.531l-1.15.9a.281.281 0 0 0-.068.346l1.09 1.886c.067.123.21.164.333.123l1.355-.545c.286.218.585.395.919.531l.204 1.444a.277.277 0 0 0 .272.231h2.18a.26.26 0 0 0 .265-.231l.204-1.444c.333-.136.64-.32.919-.53l1.355.544a.27.27 0 0 0 .333-.123l1.09-1.886a.264.264 0 0 0-.068-.347l-1.137-.899zm-4.051 1.512a2.048 2.048 0 0 1-2.043-2.043c0-1.123.92-2.042 2.043-2.042 1.123 0 2.042.919 2.042 2.042 0 1.124-.919 2.043-2.042 2.043zm-3.59-10.909l1.462 1.156a.34.34 0 0 1 .087.446l-1.4 2.425a.346.346 0 0 1-.43.158l-1.742-.7a5.267 5.267 0 0 1-1.182.683l-.262 1.856a.335.335 0 0 1-.342.297H6.41a.356.356 0 0 1-.35-.297l-.263-1.856a5.13 5.13 0 0 1-1.182-.683l-1.742.7a.356.356 0 0 1-.43-.158l-1.4-2.425a.362.362 0 0 1 .087-.446l1.48-1.156a4.4 4.4 0 0 1-.053-.683 4.4 4.4 0 0 1 .062-.683l-1.48-1.155a.34.34 0 0 1-.088-.447l1.401-2.425a.346.346 0 0 1 .43-.158l1.742.7a5.267 5.267 0 0 1 1.182-.682l.262-1.856A.34.34 0 0 1 6.41 1H9.21a.35.35 0 0 1 .35.298l.263 1.856a5.13 5.13 0 0 1 1.182.683l1.742-.7a.356.356 0 0 1 .43.157l1.4 2.425a.362.362 0 0 1-.087.447L13.01 7.32c.035.22.053.447.053.683 0 .237-.018.464-.044.683zm-5.21 1.944a2.634 2.634 0 0 0 2.627-2.627 2.634 2.634 0 0 0-2.627-2.626 2.634 2.634 0 0 0-2.626 2.626 2.634 2.634 0 0 0 2.626 2.627z',
    SITE_USERS: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 0 1-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 0 1-6 3.22z',
    SITE_JOBS: 'M20 7h-4V5c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 20c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V9c0-1.11-.89-2-2-2zm-6 0h-4V5h4v2z',
    CAMERAS: 'M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z',
    SITE_SERVICES: 'M12 1l9 4v6c0 5.55-3.84 10.74-9 12-5.16-1.26-9-6.45-9-12V5l9-4zm1.234 5h-2.468a.298.298 0 0 0-.301.255l-.232 1.59c-.378.15-.725.353-1.041.585l-1.535-.6a.31.31 0 0 0-.378.135l-1.235 2.078a.285.285 0 0 0 .078.382l1.303.99c-.03.188-.054.39-.054.585 0 .195.016.398.047.585l-1.304.99a.304.304 0 0 0-.077.383l1.234 2.077c.077.135.24.18.378.135l1.535-.6c.324.24.664.435 1.042.585l.231 1.59c.031.15.154.255.309.255h2.468c.155 0 .286-.105.301-.255l.232-1.59c.378-.15.725-.353 1.041-.585l1.535.6c.14.052.301 0 .378-.135l1.235-2.078a.285.285 0 0 0-.078-.382l-1.288-.99c.023-.188.039-.383.039-.585 0-.203-.016-.398-.047-.585l1.304-.99a.304.304 0 0 0 .077-.383L16.73 7.966a.318.318 0 0 0-.378-.135l-1.535.6a4.553 4.553 0 0 0-1.042-.585l-.231-1.59A.306.306 0 0 0 13.234 6zM12 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z',
    UMBRELLA_CORE: 'M11 4.05V2.5h2v1.55A10.007 10.007 0 0 1 21.8 12H13v7c0 1.886-1.114 3-3 3s-3-1.114-3-3h2c0 .781.219 1 1 1s1-.219 1-1v-7H2.2A10.007 10.007 0 0 1 11 4.05z',
    UMBRELLA_USER: 'M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z',
    UMBRELLA_ROLE: 'M22.036 10.5l.855.676c.082.062.103.17.052.262l-.82 1.42c-.052.092-.16.128-.251.092l-1.02-.41c-.21.159-.441.297-.692.4l-.154 1.086a.196.196 0 0 1-.2.174h-1.64a.208.208 0 0 1-.205-.174l-.154-1.087a3.003 3.003 0 0 1-.692-.4l-1.02.41c-.092.031-.2 0-.25-.092l-.82-1.42a.212.212 0 0 1 .05-.26l.867-.677a2.576 2.576 0 0 1-.031-.4c0-.133.015-.272.036-.4l-.866-.676a.199.199 0 0 1-.051-.262l.82-1.42c.05-.091.158-.127.25-.091l1.02.41c.21-.16.441-.298.692-.4l.154-1.087a.2.2 0 0 1 .2-.174h1.64c.103 0 .19.072.205.174l.154 1.087c.251.102.477.236.692.4l1.02-.41c.092-.031.2 0 .25.092l.82 1.42c.047.087.026.2-.05.26l-.867.677c.02.128.031.262.031.4s-.01.272-.025.4zm-3.05 1.138c.846 0 1.538-.692 1.538-1.538 0-.846-.692-1.537-1.538-1.537-.846 0-1.537.691-1.537 1.537s.691 1.538 1.537 1.538zM9 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm0 2c2.67 0 8 1.34 8 4v2H1v-2c0-2.66 5.33-4 8-4z',
    ADMINISTRATION: 'M21.738 18.479l-8.26-8.31c.817-2.1.363-4.567-1.362-6.302C10.301 2.041 7.577 1.675 5.4 2.68l3.903 3.927-2.723 2.74L2.585 5.42c-1.09 2.191-.635 4.931 1.18 6.758 1.725 1.735 4.176 2.191 6.263 1.37l8.26 8.31a.874.874 0 0 0 1.272 0l2.087-2.1c.454-.366.454-1.005.091-1.279z',
    MANUAL: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z',
    POWER_OFF: 'M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42A6.92 6.92 0 0 1 19 12c0 3.87-3.13 7-7 7A6.995 6.995 0 0 1 7.58 6.58L6.17 5.17A8.932 8.932 0 0 0 3 12a9 9 0 0 0 18 0c0-2.74-1.23-5.18-3.17-6.83z',
    PIN: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z',
    SORT: 'M5 17h5v-1.667H5V17zM5 7v1.667h15V7H5zm0 5.833h10v-1.666H5v1.666z',
    ARROW: 'M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8z',
    GEAR: 'M19.66 19.084c.02-.17.034-.347.034-.53 0-.184-.014-.361-.041-.532l1.15-.898a.281.281 0 0 0 .069-.348l-1.09-1.886c-.068-.122-.21-.163-.333-.122l-1.355.545a3.989 3.989 0 0 0-.92-.531l-.204-1.444a.272.272 0 0 0-.272-.231H14.52a.265.265 0 0 0-.265.231l-.204 1.444c-.334.136-.64.32-.92.53l-1.354-.544a.27.27 0 0 0-.334.122l-1.09 1.886a.264.264 0 0 0 .069.348l1.15.898c-.027.17-.047.354-.047.531 0 .177.013.361.04.531l-1.15.9a.281.281 0 0 0-.068.346l1.09 1.886c.067.123.21.164.333.123l1.355-.545c.286.218.585.395.919.531l.204 1.444a.277.277 0 0 0 .272.231h2.18a.26.26 0 0 0 .265-.231l.204-1.444c.333-.136.64-.32.919-.53l1.355.544a.27.27 0 0 0 .333-.123l1.09-1.886a.264.264 0 0 0-.068-.347l-1.137-.899zm-4.051 1.512a2.048 2.048 0 0 1-2.043-2.043c0-1.123.92-2.042 2.043-2.042 1.123 0 2.042.919 2.042 2.042 0 1.124-.919 2.043-2.042 2.043zm-3.59-10.909l1.462 1.156a.34.34 0 0 1 .087.446l-1.4 2.425a.346.346 0 0 1-.43.158l-1.742-.7a5.267 5.267 0 0 1-1.182.683l-.262 1.856a.335.335 0 0 1-.342.297H5.41a.356.356 0 0 1-.35-.297l-.263-1.856a5.13 5.13 0 0 1-1.182-.683l-1.742.7a.356.356 0 0 1-.43-.158l-1.4-2.425a.362.362 0 0 1 .087-.446l1.48-1.156a4.4 4.4 0 0 1-.053-.683 4.4 4.4 0 0 1 .062-.683L.138 7.166a.34.34 0 0 1-.088-.447l1.401-2.425a.346.346 0 0 1 .43-.158l1.742.7a5.267 5.267 0 0 1 1.182-.682l.262-1.856A.34.34 0 0 1 5.41 2H8.21a.35.35 0 0 1 .35.298l.263 1.856a5.13 5.13 0 0 1 1.182.683l1.742-.7a.356.356 0 0 1 .43.157l1.4 2.425a.362.362 0 0 1-.087.447L12.01 8.32c.035.22.053.447.053.683 0 .237-.018.464-.044.683zm-5.21 1.944a2.634 2.634 0 0 0 2.627-2.627 2.634 2.634 0 0 0-2.627-2.626 2.634 2.634 0 0 0-2.626 2.626 2.634 2.634 0 0 0 2.626 2.627zM24 8h-3v3h-2V8h-3V6h3V3h2v3h3v2z',
    CLOSE: 'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z',
    DOWNLOAD: 'M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z',
    UPLOAD: 'M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-8-5.17L8.41 9.41 7 8l5-5 5 5-1.41 1.41L13 6.83v9.67h-2V6.83z',
    COLUMNS: 'M10 20h5V4h-5v16zm-6 0h5V4H4v16zM16 4v16h5V4h-5z',
    CHECKBOX_UNCHECKED: 'M19 5v14H5V5h14zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z',
    TRASH: 'M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z',
    SEARCH: 'M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z',
    GROUP_INACTIVE: 'M4.5 4.5v15h15v-15h-15zm6.667 13.333h-5v-5h5v5zm0-6.666h-5v-5h5v5zm6.666 6.666h-5v-5h5v5zm0-6.666h-5v-5h5v5z',
    GROUP_ACTIVE: 'M4 4h7v7H4V4zm9 0h7v7h-7V4zm-9 9h7v7H4v-7zm9 0h7v7h-7v-7z',
    ADD_USER: 'M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z',
    TICK: 'M9.143 19.886L2 12.743l2.014-2.014 5.129 5.114L19.986 5 22 7.029z',
    CHANGE_PASSWORD: 'M13.571 3C18.778 3 23 7.222 23 12.429c0 5.206-4.222 9.428-9.429 9.428a9.441 9.441 0 0 1-5.74-1.948L9.317 18.4a7.328 7.328 0 0 0 11.587-5.971 7.328 7.328 0 0 0-7.334-7.334 7.328 7.328 0 0 0-7.333 7.334h3.143l-4.19 4.19L1 12.429h3.143C4.143 7.222 8.365 3 13.57 3zm4.04 7.334v6.285H10.43v-6.285h1.346v-.898a2.248 2.248 0 0 1 2.465-2.235c1.167.112 2.025 1.158 2.025 2.33v.803h1.346zm-3.591 4.04a.9.9 0 0 0 .898-.897.9.9 0 0 0-.898-.898.9.9 0 0 0-.898.898.9.9 0 0 0 .898.897zm-1.347-4.04h2.694v-.898c0-.745-.602-1.346-1.347-1.346s-1.347.601-1.347 1.346v.898z',
    CAMERA: 'M18 11.5V8c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z',
    CAMERA_OFF: 'M22 7.5l-4 4V8c0-.55-.45-1-1-1h-6.18L22 18.18V7.5zM4.27 3L3 4.27 5.73 7H5c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18L20.73 22 22 20.73 4.27 3z',
    SITE_SERVICE: 'M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 20H4v-4h4v4zm0-6H4v-4h4v4zm0-6H4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4z',
    SITE_SERVICE_OFF: 'M8 4v1.45l2 2V4h4v4h-3.45l2 2H14v1.45l2 2V10h4v4h-3.45l2 2H20v1.45l2 2V4c0-1.1-.9-2-2-2H4.55l2 2H8zm8 0h4v4h-4V4zM1.27 1.27L0 2.55l2 2V20c0 1.1.9 2 2 2h15.46l2 2 1.27-1.27L1.27 1.27zM10 12.55L11.45 14H10v-1.45zm-6-6L5.45 8H4V6.55zM8 20H4v-4h4v4zm0-6H4v-4h3.45l.55.55V14zm6 6h-4v-4h3.45l.55.54V20zm2 0v-1.46L17.46 20H16z',
    SITE_GROUPS: 'M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm-2.975 9.005a1.993 1.993 0 0 0 1.996-2c0-1.107-.888-2-1.996-2a1.999 1.999 0 1 0 0 4zM9 12c-1.331 0-4 .78-4 2.333V16h8v-1.667C13 12.78 10.331 12 9 12zm6.003-1A1.993 1.993 0 0 0 17 9c0-1.107-.888-2-1.997-2a1.999 1.999 0 1 0 0 4zm-.273 1c-.218 0-.467.013-.73.033.873.56 1.483 1.314 1.483 2.3V16H20v-1.667C20 12.78 16.484 12 14.73 12z',
    ADD_SITE_GROUP: 'M8 10H5V7H3v3H0v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86 0 1.07-.34 2.04-.9 2.86.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z',
    ADD_SITE_GROUP_SECONDARY: 'M8 3H5V0H3v3H0v2h3v3h2V5h3V3zm9.854 9.89a3.376 3.376 0 0 0 3.379-3.39 3.376 3.376 0 0 0-3.38-3.39c-.36 0-.711.057-1.027.158A5.576 5.576 0 0 1 17.843 9.5c0 1.21-.385 2.306-1.017 3.232.316.102.666.159 1.028.159zm-5.467 0a3.376 3.376 0 0 0 3.378-3.39 3.376 3.376 0 0 0-3.378-3.39 3.386 3.386 0 0 0-3.39 3.39 3.386 3.386 0 0 0 3.39 3.39zm6.663 1.624c.938.825 1.56 1.876 1.56 3.21v2.26H24v-2.26c0-1.74-2.678-2.814-4.95-3.21zM12 14c-2.333 0-7 1.2-7 3.6V20h14v-2.4c0-2.4-4.667-3.6-7-3.6z',
    ADD_ROLE: 'M22.036 10.5l.855.676c.082.062.103.17.052.262l-.82 1.42c-.052.092-.16.128-.251.092l-1.02-.41c-.21.159-.441.297-.692.4l-.154 1.086a.196.196 0 0 1-.2.174h-1.64a.208.208 0 0 1-.205-.174l-.154-1.087a3.003 3.003 0 0 1-.692-.4l-1.02.41c-.092.031-.2 0-.25-.092l-.82-1.42a.212.212 0 0 1 .05-.26l.867-.677a2.576 2.576 0 0 1-.031-.4c0-.133.015-.272.036-.4l-.866-.676a.199.199 0 0 1-.051-.262l.82-1.42c.05-.091.158-.127.25-.091l1.02.41c.21-.16.441-.298.692-.4l.154-1.087a.2.2 0 0 1 .2-.174h1.64c.103 0 .19.072.205.174l.154 1.087c.251.102.477.236.692.4l1.02-.41c.092-.031.2 0 .25.092l.82 1.42c.047.087.026.2-.05.26l-.867.677c.02.128.031.262.031.4s-.01.272-.025.4zm-3.05 1.138c.846 0 1.538-.692 1.538-1.538 0-.846-.692-1.537-1.538-1.537-.846 0-1.537.691-1.537 1.537s.691 1.538 1.537 1.538zM9 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm0 2c2.67 0 8 1.34 8 4v2H1v-2c0-2.66 5.33-4 8-4z',
    LICENSE: 'M12,1L3,5v6c0,5.5,3.8,10.7,9,12c5.2-1.3,9-6.5,9-12V5L12,1L12,1z M10,17l-4-4l1.4-1.4l2.6,2.6l6.6-6.6L18,9L10,17L10,17z',
    EDIT: 'M10 14.336l4.916-4.917 1.667 1.667-4.916 4.917H10v-1.667zm7.872-4.539l-.813.813-1.667-1.667.814-.813a.443.443 0 0 1 .626 0l1.04 1.04a.443.443 0 0 1 0 .627zM13.572 3C18.777 3 23 7.222 23 12.429c0 5.206-4.222 9.428-9.429 9.428a9.441 9.441 0 0 1-5.74-1.948L9.317 18.4a7.328 7.328 0 0 0 11.587-5.971 7.328 7.328 0 0 0-7.334-7.334 7.328 7.328 0 0 0-7.333 7.334h3.143l-4.19 4.19L1 12.429h3.143C4.143 7.222 8.365 3 13.57 3z',
    LICENSE_UPDATE: 'M12,1l9,4v6c0,5.5-3.8,10.7-9,12c-5.2-1.3-9-6.5-9-12V5L12,1z M16.2,7.8C15.1,6.7,13.7,6,12,6c-3.3,0-6,2.7-6,6s2.7,6,6,6c2.8,0,5.1-1.9,5.8-4.5h-1.6c-0.6,1.7-2.3,3-4.2,3c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5c1.2,0,2.4,0.5,3.2,1.3l-2.4,2.4H18V6L16.2,7.8z',
    UPDATE: 'M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-8-5.17L8.41 9.41 7 8l5-5 5 5-1.41 1.41L13 6.83v9.67h-2V6.83z',
    CLIP: 'M17.728 6.272a3.103 3.103 0 0 0-4.384 0l-2.829 2.829-1.343-1.344L12 4.93a5.002 5.002 0 0 1 7.071 0 5.002 5.002 0 0 1 0 7.071l-2.828 2.828-1.344-1.343 2.829-2.829a3.103 3.103 0 0 0 0-4.384zM15.536 9.88l-5.657 5.657-1.415-1.415 5.657-5.657 1.415 1.415zM4.929 12l2.828-2.828 1.344 1.343-2.829 2.829a3.103 3.103 0 0 0 0 4.384 3.103 3.103 0 0 0 4.384 0l2.829-2.829 1.343 1.344L12 19.07a5.002 5.002 0 0 1-7.071 0 5.002 5.002 0 0 1 0-7.071zM3 9V8h2v1H3zm0-5h6v1H3V4zm0 3V6h4v1H3z',
    CSV: 'M19.8,20.9l0-12.8H4.2l0,12.8H19.8z M9.3,3.4c-1,0-1.7,0.7-1.7,1.8c0,1,0.6,1.7,1.7,1.7c0.4,0,0.7-0.1,0.8-0.2l0.1-0.1L9.9,5.8L9.8,5.9C9.7,5.9,9.5,6,9.3,6C8.8,6,8.5,5.7,8.5,5.1c0-0.5,0.3-0.8,0.7-0.8c0.2,0,0.3,0,0.5,0.1l0.2,0.1l0.2-0.9L10,3.6C9.9,3.5,9.6,3.4,9.3,3.4z M11.7,5.5C12,5.7,12,5.8,12,5.8C12,5.9,11.9,6,11.7,6c-0.2,0-0.6-0.1-0.7-0.2l-0.2-0.1l-0.1,0.8l0.1,0.1c0.2,0.1,0.6,0.2,1,0.2c0.9,0,1.3-0.6,1.3-1.1c0-0.5-0.3-0.8-0.8-1.1c-0.4-0.2-0.4-0.2-0.4-0.3c0,0,0-0.2,0.2-0.2c0.2,0,0.5,0.1,0.6,0.1l0.2,0.1L13,3.7l-0.1-0.1c-0.2-0.1-0.6-0.2-0.9-0.2c-0.7,0-1.2,0.4-1.2,1.1C10.8,5,11.1,5.3,11.7,5.5zM14.5,3.5h-1.1l1,3.4h1l1-3.4h-1l-0.4,1.4C15,5,15,5.1,15,5.2c0-0.1-0.1-0.2-0.1-0.4L14.5,3.5z M20.2,2C20.6,2,21,2.4,21,2.8v18.4c0,0.5-0.4,0.8-0.8,0.8H3.8C3.4,22,3,21.6,3,21.2V2.8C3,2.4,3.4,2,3.8,2H20.2z M9.5,15.3H7.7L9.6,13l-1.8-2.2h1.8l0.9,1.2l0.9-1.2h1.8l-1.8,2.2l1.9,2.4h-1.8l-1-1.3L9.5,15.3z M6.5,16.5h2.9v1.9H6.5V16.5z M10.5,16.5h2.9v1.9h-2.9V16.5z M14.5,16.5h2.9v1.9h-2.9V16.5z M14.5,13.5h2.9v1.9h-2.9V13.5z M14.5,10.5h2.9v1.9h-2.9V10.5z',
    MONITORING_CENTRE: 'M20 4c1.05 0 1.918.82 1.994 1.851L22 6l-.01 10c0 1.05-.81 1.918-1.841 1.994L20 18h4v2H0v-2h4c-1.05 0-1.918-.82-1.994-1.851L2 16V6c0-1.05.82-1.918 1.851-1.994L4 4h16zm0 2H4v10h16V6zM10 9.75v4.083H8V9.75h2zM12.917 8v5.833h-2V8h2zm3 3.333v2.5h-2v-2.5h2z',
    NOTIFICATIONS: 'M12 21.5c1.1 0 2-.9 2-2h-4a2 2 0 0 0 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V3.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 4.86 6 7.42 6 10.5v5l-2 2v1h16v-1l-2-2z',
    CONFIGURATION: 'M17.728 12.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78l-.3-2.12a.4.4 0 0 0-.4-.34h-3.2c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78l-1.69 1.32c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM11.778 15c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z',
    CALENDAR: 'M16 2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-1V2h-2zm3 18H5V9h14v11z',
    SITE_EVENTS: 'M16 2v14H2V2h14zm1.1-2H.9C.4 0 0 .4 0 .9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V.9c0-.5-.5-.9-.9-.9zM8 4h6v2H8V4zm0 4h6v2H8V8zm0 4h6v2H8v-2zM4 4h2v2H4V4zm0 4h2v2H4V8zm0 4h2v2H4v-2z',
    INFO: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z',
    EMAIL_ALTERNATE: 'M18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V2l8 5 8-5v2z',
    RETRY: 'M13.6,3c-5.2,0-9.4,4.2-9.4,9.4H1l4.2,4.2l4.2-4.2H6.2c0-4.1,3.3-7.3,7.3-7.3s7.3,3.3,7.3,7.3s-3.3,7.3-7.3,7.3c-1.6,0-3-0.5-4.3-1.4l-1.5,1.5c1.6,1.2,3.6,1.9,5.7,1.9c5.2,0,9.4-4.2,9.4-9.4S18.8,3,13.6,3L13.6,3z',
    CANCEL: 'M19,6.4L17.6,5L12,10.6L6.4,5L5,6.4l5.6,5.6L5,17.6L6.4,19l5.6-5.6l5.6,5.6l1.4-1.4L13.4,12L19,6.4z',
    ADD: 'M42 24H24v18h-6V24H0v-6h18V0h6v18h18z',
    DOTS: 'M2 0C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM8 0C6.9 0 6 .9 6 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z',
    MOVE_ICON: 'M16 0H0v2h16V0zM0 6h16V4H0v2z',
    TICK_SECONDARY: 'M5.095 9.924L.333 5.162l1.343-1.343 3.42 3.41L12.323 0l1.343 1.352z',
    ARROW_RIGHT: 'M8.175 9L12 12.709 15.825 9 17 10.142 12 15l-5-4.858z',
    CAMERA_FIRMWARE: 'M17,7c0.5,0,1,0.4,1,1l0,0v3.5l4-4v11l-4-4V18c0,0.5-0.5,1-1,1l0,0H5c-0.6,0-1-0.5-1-1l0,0V8    c0-0.6,0.4-1,1-1l0,0H17z M11.8,9h-1.6C10.1,9,10,9.1,10,9.2l-0.2,1.1c-0.3,0.1-0.5,0.2-0.7,0.4l-1-0.4c-0.1,0-0.2,0-0.3,0.1    L7,11.7c-0.1,0.1,0,0.2,0.1,0.3L8,12.6c0,0.1,0,0.3,0,0.4s0,0.3,0,0.4l-0.9,0.7C7,14.1,7,14.2,7,14.3l0.8,1.4    c0.1,0.1,0.2,0.1,0.3,0.1l1-0.4c0.2,0.2,0.4,0.3,0.7,0.4l0.2,1.1c0,0.1,0.1,0.2,0.2,0.2h1.6c0.1,0,0.2-0.1,0.2-0.2l0.2-1.1    c0.3-0.1,0.5-0.2,0.7-0.4l1,0.4c0.1,0,0.2,0,0.3-0.1l0.8-1.4c0.1-0.1,0-0.2-0.1-0.3l-0.9-0.7c0-0.1,0-0.3,0-0.4s0-0.3,0-0.4    l0.9-0.7c0.1-0.1,0.1-0.2,0.1-0.3l-0.8-1.4c-0.1-0.1-0.2-0.1-0.3-0.1l-1,0.4c-0.2-0.2-0.4-0.3-0.7-0.4L12,9.2    C12,9.1,11.9,9,11.8,9z M11,12c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S10.4,12,11,12z',
    UPLOAD_FIRMWARE: 'M18 9l5 5.185-1.41 1.462L19 12.972V23h-2V12.972l-2.59 2.675L13 14.185 18 9zM8.21 0c.15 0 .281.09.332.226l.018.072.263 1.856c.343.14.659.314.96.52l.222.163 1.742-.7a.361.361 0 0 1 .386.096l.043.06 1.401 2.426a.366.366 0 0 1-.037.397l-.05.05-1.48 1.155c.035.22.053.447.053.683 0 .158-.008.311-.021.461l-.023.222 1.462 1.156c.12.09.163.238.118.377l-.03.07-1.401 2.424a.347.347 0 0 1-.36.177l-.07-.019-1.742-.7a5.43 5.43 0 0 1-.928.57l-.254.113-.262 1.856a.33.33 0 0 1-.269.29l-.073.007H5.41a.35.35 0 0 1-.33-.226l-.02-.071-.263-1.856a5.015 5.015 0 0 1-.96-.522l-.222-.161-1.742.7a.361.361 0 0 1-.386-.096l-.043-.062L.042 9.29a.366.366 0 0 1 .037-.396l.05-.05 1.48-1.156a4.4 4.4 0 0 1-.053-.683c0-.152.012-.307.03-.459l.032-.224-1.48-1.155a.334.334 0 0 1-.118-.378l.03-.069 1.401-2.425c.075-.135.221-.2.36-.177l.07.02 1.742.7a5.43 5.43 0 0 1 .928-.571l.254-.112.262-1.856a.34.34 0 0 1 .269-.29L5.409 0H8.21zm-1.4 4.378a2.634 2.634 0 0 0-2.627 2.626 2.634 2.634 0 0 0 2.626 2.627 2.634 2.634 0 0 0 2.627-2.627 2.634 2.634 0 0 0-2.627-2.626z',
    APPLY_FIRMWARE: 'M13.6,3c-5.2,0-9.4,4.3-9.4,9.5H1l4.2,4.2l4.2-4.2H6.2c0-4.1,3.3-7.4,7.3-7.4c4.1,0,7.3,3.3,7.3,7.4s-3.3,7.4-7.3,7.4   c-1.6,0-3-0.5-4.3-1.4L7.8,20c1.6,1.2,3.6,2,5.7,2c5.2,0,9.4-4.3,9.4-9.5S18.8,3,13.6,3z M9,13.7C9,13.8,9,13.9,9,14l0.9,1.6l0,0.1c0.1,0.1,0.2,0.1,0.2,0l1.2-0.5l0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.3l0.2,1.2l0,0.1   c0,0.1,0.1,0.1,0.2,0.1h1.9l0.1,0c0.1,0,0.2-0.1,0.2-0.2l0.2-1.2l0.2-0.1c0.2-0.1,0.4-0.2,0.6-0.3l1.2,0.5l0.1,0   c0.1,0,0.2,0,0.2-0.1L18,14l0-0.1c0-0.1,0-0.2-0.1-0.2l-1-0.7l0-0.1c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.4l1-0.7l0,0   c0-0.1,0.1-0.2,0-0.2L17,9.5l0-0.1c-0.1-0.1-0.2-0.1-0.2,0l-1.2,0.5l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.3l-0.2-1.2l0-0.1   c0-0.1-0.1-0.1-0.2-0.1h-1.9l-0.1,0c-0.1,0-0.1,0.1-0.2,0.2l-0.2,1.2L12,9.5c-0.2,0.1-0.4,0.2-0.6,0.3l-1.2-0.5l-0.1,0   c-0.1,0-0.2,0-0.2,0.1L9,11l0,0.1c0,0.1,0,0.2,0.1,0.2l1,0.7l0,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4L9,13.7L9,13.7z M13.5,10.8   c1,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7S12.5,10.8,13.5,10.8z',
    UMBRELLA_SITES: 'M19.66 17.084c.02-.17.034-.347.034-.53 0-.184-.014-.361-.041-.532l1.15-.898a.281.281 0 0 0 .069-.348l-1.09-1.886c-.068-.122-.21-.163-.333-.122l-1.355.545a3.989 3.989 0 0 0-.92-.531l-.204-1.444a.272.272 0 0 0-.272-.231H14.52a.265.265 0 0 0-.265.231l-.204 1.444c-.334.136-.64.32-.92.53l-1.354-.544a.27.27 0 0 0-.334.122l-1.09 1.886a.264.264 0 0 0 .069.348l1.15.898c-.027.17-.047.354-.047.531 0 .177.013.361.04.531l-1.15.9a.281.281 0 0 0-.068.346l1.09 1.886c.067.123.21.164.333.123l1.355-.545c.286.218.585.395.919.531l.204 1.444a.277.277 0 0 0 .272.231h2.18a.26.26 0 0 0 .265-.231l.204-1.444c.333-.136.64-.32.919-.53l1.355.544a.27.27 0 0 0 .333-.123l1.09-1.886a.264.264 0 0 0-.068-.347l-1.137-.899zm-4.051-2.573c1.123 0 2.042.919 2.042 2.042 0 1.124-.919 2.043-2.042 2.043a2.048 2.048 0 0 1-2.043-2.043c0-1.123.92-2.042 2.043-2.042zM8.21 0a.35.35 0 0 1 .35.298l.263 1.856a5.13 5.13 0 0 1 1.182.683l1.742-.7a.356.356 0 0 1 .43.157l1.4 2.425a.362.362 0 0 1-.087.447L12.01 6.32c.035.22.053.447.053.683 0 .237-.018.464-.044.683l1.462 1.156a.34.34 0 0 1 .087.446l-1.4 2.425a.346.346 0 0 1-.43.158l-1.742-.7a5.267 5.267 0 0 1-1.182.683l-.262 1.856a.335.335 0 0 1-.342.297H5.41a.356.356 0 0 1-.35-.297l-.263-1.856a5.13 5.13 0 0 1-1.182-.683l-1.742.7a.356.356 0 0 1-.43-.158L.043 9.29a.362.362 0 0 1 .087-.446l1.48-1.156a4.4 4.4 0 0 1-.053-.683 4.4 4.4 0 0 1 .062-.683L.138 5.166a.34.34 0 0 1-.088-.447l1.401-2.425c.088-.158.272-.219.43-.158l1.742.7a5.267 5.267 0 0 1 1.182-.682L5.067.298A.34.34 0 0 1 5.41 0zm-1.4 4.378a2.634 2.634 0 0 0-2.627 2.626 2.634 2.634 0 0 0 2.626 2.627 2.634 2.634 0 0 0 2.627-2.627 2.634 2.634 0 0 0-2.627-2.626z',
    SITE_TIME_PROFILE: 'M9.99 0C15.52 0 20 4.48 20 10S15.52 20 9.99 20C4.47 20 0 15.52 0 10S4.47 0 9.99 0zM10 2c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm.5 3v5.25l4.5 2.67-.75 1.23L9 11V5h1.5z',
    ICON_PLUS: 'M11.833 6.833L6.833 6.833 6.833 11.833 5.167 11.833 5.167 6.833 0.167 6.833 0.167 5.167 5.167 5.167 5.167 0.167 6.833 0.167 6.833 5.167 11.833 5.167z',
    SETTINGS: 'M19.7,13c0-0.3,0.1-0.6,0.1-1s0-0.7-0.1-1l2.2-1.6c0.2-0.1,0.2-0.4,0.1-0.6l-2.1-3.5c-0.1-0.2-0.4-0.3-0.6-0.2l-2.6,1c-0.5-0.4-1.1-0.7-1.7-1l-0.4-2.6c0-0.2-0.3-0.4-0.5-0.4H9.9C9.7,2,9.5,2.2,9.4,2.4L9.1,5.1c-0.6,0.2-1.2,0.6-1.7,1l-2.6-1C4.5,5,4.3,5.1,4.1,5.3L2.1,8.7C1.9,9,2,9.2,2.2,9.4L4.4,11c-0.1,0.3-0.1,0.7-0.1,1s0,0.7,0.1,1l-2.2,1.6c-0.2,0.1-0.2,0.4-0.1,0.6l2.1,3.5C4.2,19,4.5,19,4.7,19l2.6-1c0.5,0.4,1.1,0.7,1.7,1l0.4,2.7C9.5,21.8,9.7,22,9.9,22h4.1c0.3,0,0.5-0.2,0.5-0.4l0.4-2.7c0.6-0.2,1.2-0.6,1.7-1l2.6,1c0.2,0.1,0.5,0,0.6-0.2l2.1-3.5c0.1-0.2,0.1-0.5-0.1-0.6L19.7,13L19.7,13z M12,15.8c-2.1,0-3.9-1.7-3.9-3.8S9.9,8.2,12,8.2s3.9,1.7,3.9,3.8S14.1,15.8,12,15.8z',
    FULL_SCREEN: 'M 7,14 5,14 5,19 10,19 10,17 7,17 z M 5,10 7,10 7,7 10,7 10,5 5,5 z M 17,17 14,17 14,19 19,19 19,14 17,14 z M 14,5 14,7 17,7 17,10 19,10 19,5 z',
    EXIT_FULL_SCREEN: 'M 5,16 8,16 8,19 10,19 10,14 5,14 z M 8,8 5,8 5,10 10,10 10,5 8,5 z M 14,19 16,19 16,16 19,16 19,14 14,14 z M 16,8 16,5 14,5 14,10 19,10 19,8 z',
    EDITING: 'M23 15h-2v2h2v-2zm0-4h-2v2h2v-2zm0 8h-2v2c1 0 2-1 2-2zM15 3h-2v2h2V3zm8 4h-2v2h2V7zm-2-4v2h2c0-1-1-2-2-2zM3 21h8v-6H1v4c0 1.1.9 2 2 2zM3 7H1v2h2V7zm12 12h-2v2h2v-2zm4-16h-2v2h2V3zm0 16h-2v2h2v-2zM3 3C2 3 1 4 1 5h2V3zm0 8H1v2h2v-2zm8-8H9v2h2V3zM7 3H5v2h2V3z',
    TICK_SPECIAL: 'M 9.7,17.9 4,12.2 5.6,10.6 9.7,14.7 18.4,6 20,7.6     z',
    REPORT_CENTER: 'M 8.0000002,11 H 12 V 12 H 8.0000002 Z M 8.0000002,13 H 12 V 14 H 8.0000002 Z M 8.0000002,9.0000003 H 12 V 10 H 8.0000002 Z M 8.0000002,7.0000003 H 12 V 8.0000003 H 8.0000002 Z M 16,8.0000003 V 13 H 14 V 8.0000003 H 16 M 17,7.0000003 H 13 V 14 H 17 Z M 20,18 C 21.1,18 22,17.1 22,16 V 6 C 22,4.9 21.1,4 20,4 H 4 C 2.9,4 2,4.9 2,6 V 16 C 2,17.1 2.9,18 4,18 H 0 V 20 H 24 V 18 Z M 4,6 H 20 V 16 H 4 Z',
    REPORTS: 'M 5 3 C 3.89 3 3 3.89 3 5 L 3 19 C 3 20.11 3.89 21 5 21 L 19 21 C 20.11 21 21 20.11 21 19 L 21 5 C 21 3.89 20.11 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z M 6.8496094 7 A 0.61111116 0.62499998 0 0 0 6.2382812 7.625 L 6.2382812 8.875 A 0.61111116 0.62499998 0 0 0 6.8496094 9.5 L 8.0722656 9.5 A 0.61111116 0.62499998 0 0 0 8.6816406 8.875 L 8.6816406 7.625 A 0.61111116 0.62499998 0 0 0 8.0722656 7 L 6.8496094 7 z M 6.8496094 7.625 L 8.0722656 7.625 L 8.0722656 8.875 L 6.8496094 8.875 L 6.8496094 7.625 z M 9.2929688 7.625 L 9.2929688 8.875 L 17.238281 8.875 L 17.238281 7.625 L 9.2929688 7.625 z M 6.8496094 10.75 A 0.61111116 0.62499998 0 0 0 6.2382812 11.375 L 6.2382812 12.625 A 0.61111116 0.62499998 0 0 0 6.8496094 13.25 L 8.0722656 13.25 A 0.61111116 0.62499998 0 0 0 8.6816406 12.625 L 8.6816406 11.375 A 0.61111116 0.62499998 0 0 0 8.0722656 10.75 L 6.8496094 10.75 z M 6.8496094 11.375 L 8.0722656 11.375 L 8.0722656 12.625 L 6.8496094 12.625 L 6.8496094 11.375 z M 9.2929688 11.375 L 9.2929688 12.625 L 17.238281 12.625 L 17.238281 11.375 L 9.2929688 11.375 z M 6.8496094 14.5 A 0.61111116 0.62499998 0 0 0 6.2382812 15.125 L 6.2382812 16.375 A 0.61111116 0.62499998 0 0 0 6.8496094 17 L 8.0722656 17 A 0.61111116 0.62499998 0 0 0 8.6816406 16.375 L 8.6816406 15.125 A 0.61111116 0.62499998 0 0 0 8.0722656 14.5 L 6.8496094 14.5 z M 6.8496094 15.125 L 8.0722656 15.125 L 8.0722656 16.375 L 6.8496094 16.375 L 6.8496094 15.125 z M 9.2929688 15.125 L 9.2929688 16.375 L 17.238281 16.375 L 17.238281 15.125 L 9.2929688 15.125 z',
    AUDIT_REPORTS : 'M 5 3 C 3.89 3 3 3.89 3 5 L 3 19 C 3 20.11 3.89 21 5 21 L 19 21 C 20.11 21 21 20.11 21 19 L 21 5 C 21 3.89 20.11 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z M 11.955078 8 C 9.2278056 8 6.8987144 9.658667 5.9550781 12 C 6.8987144 14.341333 9.2278056 16 11.955078 16 C 14.68235 16 17.011442 14.341333 17.955078 12 C 17.011442 9.658667 14.68235 8 11.955078 8 z M 11.861328 9.3339844 A 2.7272727 2.6666666 0 0 1 11.955078 9.3339844 A 2.7272727 2.6666666 0 0 1 14.683594 12 A 2.7272727 2.6666666 0 0 1 11.955078 14.666016 A 2.7272727 2.6666666 0 0 1 9.2285156 12 A 2.7272727 2.6666666 0 0 1 11.861328 9.3339844 z M 11.955078 10.400391 A 1.6363637 1.6 0 0 0 10.318359 12 A 1.6363637 1.6 0 0 0 11.955078 13.599609 A 1.6363637 1.6 0 0 0 13.591797 12 A 1.6363637 1.6 0 0 0 11.955078 10.400391 z',
    DOWNLOAD_REPORT: 'M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z',
    CREATE_REPORT: 'M 5 3 C 3.89 3 3 3.89 3 5 L 3 19 C 3 20.11 3.89 21 5 21 L 19 21 C 20.11 21 21 20.11 21 19 L 21 5 C 21 3.89 20.11 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z M 6.8496094 7 A 0.61111116 0.62499998 0 0 0 6.2382812 7.625 L 6.2382812 8.875 A 0.61111116 0.62499998 0 0 0 6.8496094 9.5 L 8.0722656 9.5 A 0.61111116 0.62499998 0 0 0 8.6816406 8.875 L 8.6816406 7.625 A 0.61111116 0.62499998 0 0 0 8.0722656 7 L 6.8496094 7 z M 6.8496094 7.625 L 8.0722656 7.625 L 8.0722656 8.875 L 6.8496094 8.875 L 6.8496094 7.625 z M 9.2929688 7.625 L 9.2929688 8.875 L 17.238281 8.875 L 17.238281 7.625 L 9.2929688 7.625 z M 6.8496094 10.75 A 0.61111116 0.62499998 0 0 0 6.2382812 11.375 L 6.2382812 12.625 A 0.61111116 0.62499998 0 0 0 6.8496094 13.25 L 8.0722656 13.25 A 0.61111116 0.62499998 0 0 0 8.6816406 12.625 L 8.6816406 11.375 A 0.61111116 0.62499998 0 0 0 8.0722656 10.75 L 6.8496094 10.75 z M 6.8496094 11.375 L 8.0722656 11.375 L 8.0722656 12.625 L 6.8496094 12.625 L 6.8496094 11.375 z M 9.2929688 11.375 L 9.2929688 12.625 L 17.238281 12.625 L 17.238281 11.375 L 9.2929688 11.375 z M 6.8496094 14.5 A 0.61111116 0.62499998 0 0 0 6.2382812 15.125 L 6.2382812 16.375 A 0.61111116 0.62499998 0 0 0 6.8496094 17 L 8.0722656 17 A 0.61111116 0.62499998 0 0 0 8.6816406 16.375 L 8.6816406 15.125 A 0.61111116 0.62499998 0 0 0 8.0722656 14.5 L 6.8496094 14.5 z M 6.8496094 15.125 L 8.0722656 15.125 L 8.0722656 16.375 L 6.8496094 16.375 L 6.8496094 15.125 z M 9.2929688 15.125 L 9.2929688 16.375 L 17.238281 16.375 L 17.238281 15.125 L 9.2929688 15.125 z',
    CREATE_AUDIT_REPORT: 'M 5 3 C 3.89 3 3 3.89 3 5 L 3 19 C 3 20.11 3.89 21 5 21 L 19 21 C 20.11 21 21 20.11 21 19 L 21 5 C 21 3.89 20.11 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z M 11.955078 8 C 9.2278056 8 6.8987144 9.658667 5.9550781 12 C 6.8987144 14.341333 9.2278056 16 11.955078 16 C 14.68235 16 17.011442 14.341333 17.955078 12 C 17.011442 9.658667 14.68235 8 11.955078 8 z M 11.861328 9.3339844 A 2.7272727 2.6666666 0 0 1 11.955078 9.3339844 A 2.7272727 2.6666666 0 0 1 14.683594 12 A 2.7272727 2.6666666 0 0 1 11.955078 14.666016 A 2.7272727 2.6666666 0 0 1 9.2285156 12 A 2.7272727 2.6666666 0 0 1 11.861328 9.3339844 z M 11.955078 10.400391 A 1.6363637 1.6 0 0 0 10.318359 12 A 1.6363637 1.6 0 0 0 11.955078 13.599609 A 1.6363637 1.6 0 0 0 13.591797 12 A 1.6363637 1.6 0 0 0 11.955078 10.400391 z ',
    CREATE_CUSTOM_NOTE: 'M20,2A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H6L2,22V4C2,2.89 2.9,2 4,2H20M11,6V9H8V11H11V14H13V11H16V9H13V6H11Z',
    CHANGE_SITE_USER_NAME: 'M13.5 12C14.881 12 16 10.881 16 9.5 16 8.119 14.881 7 13.5 7 12.119 7 11 8.119 11 9.5 11 10.881 12.119 12 13.5 12ZM13.5 13.25C11.831 13.25 8.5 14.087 8.5 15.75V17H18.5V15.75C18.5 14.087 15.169 13.25 13.5 13.25ZM13.5 3C8.53 3 4.5 7.03 4.5 12H1.5L5.5 16 9.5 12H6.5C6.5 8.13 9.63 5 13.5 5 17.37 5 20.5 8.13 20.5 12 20.5 15.87 17.37 19 13.5 19 11.99 19 10.59 18.51 9.44 17.7L8.02 19.14C9.54 20.3 11.44 21 13.5 21 18.47 21 22.5 16.97 22.5 12 22.5 7.03 18.47 3 13.5 3Z',
    COPY_SITE_USER: 'M 15.684181,11.79139 C 16.589636,11.79139 17.31509,11.025676 17.31509,10.077104 17.31509,9.1285327 16.589636,8.3628184 15.684181,8.3628184 14.778728,8.3628184 14.047819,9.1285327 14.047819,10.077104 14.047819,11.025676 14.778728,11.79139 15.684181,11.79139 Z M 11.320545,11.79139 C 12.226,11.79139 12.951455,11.025676 12.951455,10.077104 12.951455,9.1285327 12.226,8.3628184 11.320545,8.3628184 10.41509,8.3628184 9.6841808,9.1285327 9.6841808,10.077104 9.6841808,11.025676 10.41509,11.79139 11.320545,11.79139 Z M 11.320545,12.934248 C 10.049635,12.934248 7.5023627,13.602819 7.5023627,14.934248 V 16.362819 H 15.138727 V 14.934248 C 15.138727,13.602819 12.591455,12.934248 11.320545,12.934248 Z M 15.684181,12.934248 C 15.526,12.934248 15.346,12.945676 15.15509,12.962819 15.787818,13.442819 16.229636,14.088533 16.229636,14.934248 V 16.362819 H 19.502364 V 14.934248 C 19.502364,13.602819 16.95509,12.934248 15.684181,12.934248 Z M 13.571,3 C 18.778,3 23,7.222 23,12.429 23,17.635 18.778,21.857 13.571,21.857 11.495561,21.856237 9.4782052,21.171602 7.831,19.909 L 9.317,18.4 C 14.170092,21.866114 20.910299,18.392757 20.904,12.429 20.907319,8.3771697 17.62183,5.0916811 13.57,5.095 9.5185602,5.0922343 6.2336818,8.3775607 6.237,12.429 H 9.38 L 5.19,16.619 1,12.429 H 4.143 C 4.143,7.222 8.365,3 13.57,3 Z',
    COPY: 'M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z',
    WARNING: 'M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z',
    OK: 'M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z',
    ERROR: 'M13 13H11V7H13M11 15H13V17H11M15.73 3H8.27L3 8.27V15.73L8.27 21H15.73L21 15.73V8.27L15.73 3Z',
    WIFI_OFF: 'M22.99 9C19.15 5.16 13.8 3.76 8.84 4.78l2.52 2.52c3.47-.17 6.99 1.05 9.63 3.7l2-2zm-4 4c-1.29-1.29-2.84-2.13-4.49-2.56l3.53 3.53.96-.97zM2 3.05L5.07 6.1C3.6 6.82 2.22 7.78 1 9l1.99 2c1.24-1.24 2.67-2.16 4.2-2.77l2.24 2.24C7.81 10.89 6.27 11.73 5 13v.01L6.99 15c1.36-1.36 3.14-2.04 4.92-2.06L18.98 20l1.27-1.26L3.29 1.79 2 3.05zM9 17l3 3 3-3c-1.65-1.66-4.34-1.66-6 0z',
    AUTO_REFRESH: 'M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z',
    PAUSE: 'M6 19h4V5H6v14zm8-14v14h4V5h-4z',
    PAUSE_CIRCLE: 'M9 16h2V8H9v8zm3-14C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-4h2V8h-2v8z',
    PLAY_CIRCLE: 'M10 16.5l6-4.5-6-4.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z',
    PLAY: 'M8 5v14l11-7z',
    STOP: 'M6 6h12v12H6z',
    PLUGINS: 'M20.5 11H19V7c0-1.1-.9-2-2-2h-4V3.5C13 2.12 11.88 1 10.5 1S8 2.12 8 3.5V5H4c-1.1 0-1.99.9-1.99 2v3.8H3.5c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7H2V20c0 1.1.9 2 2 2h3.8v-1.5c0-1.49 1.21-2.7 2.7-2.7 1.49 0 2.7 1.21 2.7 2.7V22H17c1.1 0 2-.9 2-2v-4h1.5c1.38 0 2.5-1.12 2.5-2.5S21.88 11 20.5 11z',
    SYSTEM_MESSAGES_CONFIG: 'M17.58,6.25l1.77,1.77L14.37,13H12.6v-1.77L17.58,6.25z M20.85,5.81l-1.06-1.06c-0.2-0.2-0.51-0.2-0.71,0l-0.85,0.85 l1.77,1.77l0.85-0.85C21.05,6.32,21.05,6,20.85,5.81z M18,12.2V17h2v2H4v-2h2v-7c0-2.79,1.91-5.14,4.5-5.8V3.5 C10.5,2.67,11.17,2,12,2s1.5,0.67,1.5,1.5v0.7c0.82,0.21,1.57,0.59,2.21,1.09l-1.43,1.43C13.64,6.26,12.85,6,12,6 c-2.21,0-4,1.79-4,4v7h8v-2.8L18,12.2z M10,20h4c0,1.1-0.9,2-2,2S10,21.1,10,20z'
}

type TIconProps = {
    d: string
    rotate?: number
    opacity?: number
    size?: number
    className?: string
    id?: string
    onClick? : () => void
    viewBox?: string
    title?: string
}

const SVG = styled.svg<{size: number}>`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
`

const Icon = (props: PropsWithChildren<TIconProps>) => {
    const handleOnClick = () => {
        if (props.onClick) {
            props.onClick()
        }
    }

    return <SVG onClick={() => handleOnClick()} id={props.id} className={props.className} size={props.size || 24} transform={`rotate(${props.rotate || 0})`} opacity={props.opacity || 1} viewBox={props.viewBox ?? '0 0 24 24'}>
        {props.title && <title>{props.title}</title>}
        <path d={props.d} />
    </SVG>
}

export default Icon
