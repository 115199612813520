import React, { useState } from 'react'
import TransMessage from 'components/TransMessage/TransMessage'
import styled from 'styled-components'
import TabsHeader from 'components/Tabs/TabsHeader'
import DynamicPropertiesTabForm from 'components/Tabs/DynamicPropertiesTabForm'
import { TPropertyValue } from 'types/businessLogic/site'
import { TAdvancedJobConfigFields, TTab, TTabButton } from 'types/ui/tab'
import GeneralHelper from 'helpers/GeneralHelper'
import { Tab } from '@mui/material'
import colors from 'style/colors'
import fonts from 'style/fonts'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { TTranslationKey } from 'types/ui/translation'

type TProps = {
    propertyValues?: TPropertyValue[]
    onSubmit?: (values: TPropertyValue[], advancedJobConfig: TAdvancedJobConfigFields, comment: string) => void
    subtitleKey: TTranslationKey
    tabButtons: TTabButton[]
    tabContent: TTab[]
    numOfEntities: number
    readOnlyForm: boolean
    disableUpdateButtons?: boolean
    isChangeMultipleEntityProps?: boolean
    withoutMarginAfterTabs?: boolean
    hideFooter?: boolean
    preselectedTab?: number
}

const Wrapper = styled.div`
    height: calc(100% - 155px);    
`

const StyledTab = styled(Tab)<{$isSelected?: boolean}>`
    background: ${p => p.$isSelected ? colors.primary.DEFAULT_PRIMARY : colors.shades.LIGHT_SHADE};
    color: ${p => p.$isSelected ? colors.shades.PURE_WHITE : colors.primary.DARK_PRIMARY} !important; 
    width: auto;
    padding: 0 20px;
    box-sizing: border-box;
    height: 40px;
    opacity: 1;
    border-radius: 20px;
    margin-right: 24px;
    text-align: center;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${fonts.PRIMARY_HEAVY};
    font-size: 16px;
`

const EnhancedTabs = (props: TProps) => {
    const {propertyValues, isChangeMultipleEntityProps,
        tabButtons, tabContent, readOnlyForm, preselectedTab} = props

    const [activeTab, setActiveTab] = useState<number>(preselectedTab ?? 0)
    const groups = GeneralHelper.prepareTabFormGroups(propertyValues ?? [])

    return (
        <>
            <TabsHeader
                activeTab={activeTab}
                isChangeMultipleEntityProps={!!isChangeMultipleEntityProps}
                onTabChange={tabId => setActiveTab(tabId)}
                subtitle={tabContent[activeTab]?.title}
            >
                {
                    tabButtons.map((tb, index) => (
                        <CustomTooltip key={index} title={<TransMessage {...tb.label} />}>
                            <StyledTab
                                $isSelected={activeTab === index}
                                id={tb.id}
                                key={tb.id}
                                label={<TransMessage {...tb.label} />}
                            />
                        </CustomTooltip>
                    ))
                }
                {
                    Object.keys(groups).map((key, index) =>
                        <StyledTab
                            $isSelected={activeTab === tabButtons.length + index}
                            key={`tabButton${key}`}
                            id={`tabButton${key}`}
                            label={key}
                        />
                    )
                }
            </TabsHeader>

            <Wrapper>
                {tabContent[activeTab]?.content}

                {
                    Object.keys(groups).map((key, index) =>
                        activeTab === (index + tabContent.length) &&
                        <DynamicPropertiesTabForm
                            key={`enhanced-tab-property-${key}-${index}`}
                            isChangeMultipleEntityProps
                            readOnlyForm={readOnlyForm}
                            groupName={key}
                            properties={groups[key]}
                            onSubmit={props.onSubmit}
                            hideFooter={props.hideFooter}
                            disableUpdateButtons={props.disableUpdateButtons}
                        />
                    )
                }
            </Wrapper>
            
        </>
    )
}

export default EnhancedTabs
