import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { siteTimeProfileService} from 'services/siteTimeProfileService'
import { showMessage } from 'state/slices/uiSlice'
import { TSiteEntity, TSiteEntityUpdate } from 'types/businessLogic/site'
import { TFetchParamsRequest } from 'types/network'
import { TTableGroup } from 'types/ui/table'

export type TSiteTimeProfileState = {
    groups: TTableGroup<TSiteEntity>[]
    siteTimeProfiles: TSiteEntity[]
    totalNumber: number
}

const initialState : TSiteTimeProfileState = {
    groups: [],
    siteTimeProfiles: [],
    totalNumber: 0
}

export const getSiteTimeProfilesAsync = createAsyncThunk('siteTimeProfile/getSiteTimeProfilesAsync', async (params: TFetchParamsRequest, thunkApi) => {
    try {
        return await siteTimeProfileService.getSiteEntities(params)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getGroupDataAsync = createAsyncThunk('siteTimeProfile/getGroupDataAsync', async (params: TFetchParamsRequest & { translationKey?: string}, thunkApi) : Promise<TTableGroup<TSiteEntity>> => {
    try {
        const result = await siteTimeProfileService.getSiteEntities(params)
        return {
            name: params.GroupName!,
            translationKey: params.translationKey,
            count: result.total,
            data: result.siteEntities
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const updateSiteTimeProfileAsync = createAsyncThunk('siteTimeProfile/updateSiteTimeProfileAsync', async (params: TSiteEntityUpdate, thunkApi) => {
    try {
        await siteTimeProfileService.updateSiteEntity(params.id, params.labels)
        thunkApi.dispatch(showMessage({message: i18nKeys.site_user_details_update_success_message, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.site_user_details_update_error_message, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'siteTimeProfile',
    initialState: initialState,
    reducers: {
        clearGroups: (state, action : PayloadAction<{ totalNumber: number, groups: TTableGroup<TSiteEntity>[] }>) => {
            action.payload.groups.forEach(item => {
                const oldItem = state.groups.find(x => x.name === item.name)
                if (oldItem) {
                    item.data = oldItem.data
                }
            })

            state.groups = action.payload.groups
            state.totalNumber = action.payload.totalNumber
        },
        updateTimeProfileValue: (state, action: PayloadAction<{ids: number[], stringValue: string}>) => {
            for (const id of action.payload.ids) {
                const item = state.siteTimeProfiles.find(x => x.id === id)
                if (item) {
                    item.propertyValues[0].stringValue = action.payload.stringValue
                }
            }
        },
        updateAllTimeProfileValue: (state, action: PayloadAction<{stringValue: string}>) => {
            state.siteTimeProfiles.forEach(item => {
                item.propertyValues[0].stringValue = action.payload.stringValue
            })
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSiteTimeProfilesAsync.fulfilled, (state, action) => {
                const { siteEntities, total } = action.payload
                state.siteTimeProfiles = siteEntities
                state.totalNumber = total
            })
            .addCase(getGroupDataAsync.fulfilled, (state, action) => {
                const existingGroup = state.groups.find(p => p.name === action.payload.name)
                if (existingGroup) {
                    existingGroup.data = action.payload.data
                    existingGroup.count = action.payload.count
                } else {
                    state.groups.push(action.payload)
                }
            
                action.payload.data?.forEach(x => {
                    const index = state.siteTimeProfiles.findIndex(y => y.id === x.id)
                    if (index === -1) {
                        state.siteTimeProfiles.push(x)
                    } else {
                        state.siteTimeProfiles[index] = x
                    }
                })
            })
    }
})

export const {clearGroups, updateTimeProfileValue, updateAllTimeProfileValue} = slice.actions

export default slice.reducer