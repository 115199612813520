import React from 'react'
import { ETableFilters } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'
import AppConstants from 'helpers/AppConstants'

const columnConfig: TTableColumnsConfig = {
    name: {
        key: 'name',
        translationKey: i18nKeys.camera_firmware_table_filename.id,
        sortKey: 'Name',
        displayed: true,
        filter: {
            key: 'Name',
            type: ETableFilters.INPUT_TEXT
        }
    },
    manufacturer: {
        key: 'manufacturer',
        translationKey: i18nKeys.camera_firmware_table_manufacturer.id,
        sortKey: 'Manufacturer',
        displayed: true,
        filter: {
            key: 'Manufacturer',
            type: ETableFilters.INPUT_TEXT
        }
    },
    comment: {
        key: 'comment',
        translationKey: i18nKeys.camera_firmware_table_comment.id,
        sortKey: 'Comment',
        displayed: true,
        filter: {
            key: 'Comment',
            type: ETableFilters.INPUT_TEXT
        }
    },
    supportedTypes: {
        key: 'supportedTypes',
        translationKey: i18nKeys.camera_firmware_table_supported_types.id,
        sortKey: 'SupportedTypes',
        displayed: true,
        filter: {
            key: 'SupportedTypes',
            type: ETableFilters.INPUT_TEXT
        }
    },
    uploadedAt: {
        key: 'uploadedAt',
        translationKey: i18nKeys.camera_firmware_table_uploaded_at.id,
        sortKey: 'UploadedAt',
        displayed: true
    }
}

const defaultLayout = [
    columnConfig.name.key,
    columnConfig.manufacturer.key,
    columnConfig.comment.key,
    columnConfig.supportedTypes.key,
    columnConfig.uploadedAt.key
]

export const tableInfo: TTableInfo = {
    id: AppConstants.tableIds.CAMERA_FIRMWARES,
    columnConfig,
    defaultLayout
}