import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import DateHelper from 'helpers/DateHelper'
import SiteHelper from 'helpers/SiteHelper'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import fonts from 'style/fonts'
import styled from 'styled-components'
import { TJobState } from 'types/businessLogic/job'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useIntl } from 'react-intl'

dayjs.extend(duration)

const SubTitle = styled.span`
    text-transform: uppercase;
    display: block;
    font-family: ${fonts.PRIMARY_BOLD};
    font-size: ${typographyScale.TYPE12};
    line-height: ${lineHeights.TYPE12LineHeight};
    letter-spacing: ${letterSpacings.TYPE12LS};
`
const Title = styled.span`
    display: block;
    text-transform: uppercase;
    font-family: ${fonts.PRIMARY_BOLD};
    font-size: ${typographyScale.TYPE20};
    line-height: ${lineHeights.TYPE20LineHeight};
    letter-spacing: ${letterSpacings.TYPE20LS};
`
const Description = styled.span`
    display: block;
    font-family: ${fonts.PRIMARY_BOLD};
    font-size: ${typographyScale.TYPE12};
    line-height: ${lineHeights.TYPE12LineHeight};
    letter-spacing: ${letterSpacings.TYPE12LS};
`
const StatusCardWrapper = styled.div<{invert?: boolean, bgColor?: string}>`
    min-width: 170px;
    min-height: 104px;
    border-radius: ${borderRadius.SECONDARY};
    background-color: ${p => p.bgColor ?? 'transparent'};
    color: ${p => p.invert ? colors.shades.PURE_WHITE : colors.primary.DARK_PRIMARY};
    padding: 0 20px;
    justify-content: center;
    margin-right: 24px;
    margin-bottom: 54px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    ${p => p.invert ? '' : `border: 1px solid ${colors.shades.DARK_SHADE};`}
`
const Container = styled.div`
    display: flex;
    flex-direction: row;
`

type TProps = {
    createdAt: string
    lastState?: TJobState
    lastStateDate?: string
}

const SiteJobCard = (props: TProps) => {
    const {createdAt, lastState, lastStateDate} = props

    const intl = useIntl()

    const bgColor = lastState ? SiteHelper.getJobStateColor(lastState.state) : 'transparent'

    const getDuration = () : string => {
        const startDate = lastStateDate
        const endDate = createdAt

        const milliseconds = dayjs(startDate).diff(dayjs(endDate))

        const dayjsDuration = dayjs.duration(milliseconds)
        const days = dayjsDuration.days()
        const hours = dayjsDuration.hours()
        const minutes = dayjsDuration.minutes()
        const seconds = dayjsDuration.seconds()

        let formattedString = ''

        if (days > 0) {
            formattedString += `${days} ${intl.formatMessage(i18nKeys.general_datetime_days)}, `
        }
        if (hours > 0 || days > 0) {
            formattedString += `${hours} ${intl.formatMessage(i18nKeys.general_datetime_hours)}, `
        }
        if (minutes > 0 || hours > 0 || days > 0) {
            formattedString += `${minutes} ${intl.formatMessage(i18nKeys.general_datetime_minutes)}, `
        }
        formattedString += `${seconds} ${intl.formatMessage(i18nKeys.general_datetime_seconds)}`

        return formattedString
    }

    return (
        <Container>
            <StatusCardWrapper id='createdCard'>
                <SubTitle>
                    <TransMessage {...i18nKeys.site_job_details_card_created} />
                </SubTitle>
                <Title>
                    {DateHelper.formatDate(createdAt, DateHelper.formats.TIME_PRIMARY)}
                </Title>
                <Description>
                    {DateHelper.formatDate(createdAt, DateHelper.formats.DATE_PRIMARY)}
                </Description>
            </StatusCardWrapper>

            <StatusCardWrapper id='statusCard' invert bgColor={bgColor}>
                <SubTitle>
                    <TransMessage {...i18nKeys.site_job_details_card_status} />
                </SubTitle>
                <Title>
                    {lastState && <TransMessage {...SiteHelper.getJobStateIntlKey(lastState.state)} />}
                </Title>
                <Description>
                    {lastStateDate && <TransMessage {...i18nKeys.site_job_details_card_duration} values={{duration: getDuration()}} />}
                </Description>
            </StatusCardWrapper>
        </Container>
    )
}

export default SiteJobCard