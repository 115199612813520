import axiosClient from 'api/axiosClient'
import { TSiteLabel } from 'types/businessLogic/misc'
import { TFetchParamsRequest, TLabelsOverviewResponse, TUpdateLabelRequest } from 'types/network'

class LabelService {
    private static _api = axiosClient()

    public static async getLabels(): Promise<string[]> {
        const response = await this._api.get<string[]>('Labels')
        return response.data
    }

    public static async getLabelsPaginate(params: TFetchParamsRequest): Promise<string[]> {
        const response = await this._api.get<string[]>('Labels/Page', {
            params: params
        })
        return response.data
    }

    public static async getSiteLabels(params: TFetchParamsRequest): Promise<TSiteLabel[]> {
        const response = await this._api.get<TSiteLabel[]>('Labels/SiteLabels', {
            params: params
        })
        return response.data
    }

    public static async getOverview(params: TFetchParamsRequest) {
        const response = await this._api.get<TLabelsOverviewResponse>('Labels/Overview', {
            params: params
        })
        return response.data
    }

    public static async updateLabel(request: TUpdateLabelRequest) {
        await this._api.post('Labels/Update', request)
    }

    public static async nameExists(name: string) {
        const response = await this._api.get<boolean>(encodeURI(`Labels/Exists/${name}`))
        return response.data
    }
}

export default LabelService
