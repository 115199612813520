import AdvancedJobConfigFields from 'components/AdvancedJobConfigFields/AdvancedJobConfigFields'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormFooter, FormGroup, FormInput, FormLabel, FormWrapper, InputWrapper } from 'components/FormControls/FormControls'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { PropsWithChildren, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import GeneralHelper from 'helpers/GeneralHelper'
import { getSiteCamerasAsync } from 'state/slices/siteCameraSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import styled from 'styled-components'
import { TCameraRequest } from 'types/network'
import { TModalProps } from 'types/ui/modal'
import { tableInfo } from 'views/Control/SiteCamera/Modals/UpdateFirmware/siteCamerasTableColumns'
import SiteCamerasTablePreparer from 'views/Control/SiteCamera/Modals/UpdateFirmware/siteCamerasTablePreparer'
import { TJobRequest } from 'types/businessLogic/job'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const ViewWrapper = styled.div`
    padding-top: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
`

type TProps = TModalProps & {
    handleClose: () => void
    handleSubmitButton: (formValues: TUpdateFirmwareJobForm) => Promise<void>
}

export type TUpdateFirmwareJobForm = {
    advancedJobConfig: TJobRequest
    comment?: string
}

const ConfirmUpdate = (props: PropsWithChildren<TProps>) => {
    const {ids, filters} = props
    const {handleClose, handleSubmitButton} = props
    const isCommentMandatory = useAppSelector(state => state.ui.uiSettings.isCommentMandatoryForJobs)

    const dispatch = useAppDispatch()
    const { cameras, totalCount } = useAppSelector(state => ({
        cameras: state.siteCamera.siteCameras,
        totalCount: state.siteCamera.totalNumber
    }))
    
    const colHandlerProps = useColumnsHandler(tableInfo)

    const enhanceTableProps = useEnhanceTable({})

    useEffect(() => {
        const requestParams = GeneralHelper.buildRequestFromTableState(enhanceTableProps.fetchState)
        const cameraRequest : TCameraRequest = {
            ...requestParams,
            ids: ids as number[],
            Filters: filters
        }
        dispatch(getSiteCamerasAsync(cameraRequest))
    }, [dispatch, enhanceTableProps.fetchState, filters, ids])

    const data = SiteCamerasTablePreparer.prepareSiteCameraRows(cameras)
    const intl = useIntl()

    const validationSchema = (mandatoryComment: boolean) => yup.object().shape({
        comment: mandatoryComment ? yup.string().max(500).required(intl.formatMessage(i18nKeys.general_validation_required_field)) : yup.string().max(500)
    })

    const { handleSubmit, formState, control, setValue} = useForm<TUpdateFirmwareJobForm>({
        mode: 'onChange',
        resolver: yupResolver(validationSchema(isCommentMandatory))
    })
    
    return (
        <FormWrapper onSubmit={handleSubmit(formValues => handleSubmitButton(formValues))}>
            {props.children}
            <FormContent>
                <ViewWrapper>
                    <AdvancedTable
                        {...colHandlerProps}
                        {...enhanceTableProps}
                        count={totalCount}
                        disabledCheckboxes={true}
                        disableSelection={true}
                        disableBackground={true}
                        disableSelectionForIds={[]}
                        rows={data}
                        
                    />
                </ViewWrapper>
                <FormGroup collapsable title={i18nKeys.advanced_job_configuration_title}>
                    <AdvancedJobConfigFields
                        formProps={formState}
                        setFieldValue={setValue}
                        hideUndoField
                        control={control}
                    />
                </FormGroup>
                <FormGroup title={i18nKeys.job_configuration_comment_title}>
                    <InputWrapper>
                        <FormLabel htmlFor='comment'>
                            <TransMessage {...i18nKeys.job_configuration_comment} />
                        </FormLabel>
                        <FormInput
                            name='comment'
                            autoComplete='job-comment'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.apply_firmware_modal_create_jobs} />
                    </CustomButton>
                    <CustomButton
                        id='cancelButton'
                        type='button'
                        onClick={handleClose}
                        $secondary
                    >
                        <TransMessage {...i18nKeys.apply_firmware_modal_cancel} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}

export default ConfirmUpdate