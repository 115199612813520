import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthService from 'services/authService'
import { checkLoginAsync } from 'state/slices/authSlice'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TChangePasswordRequest } from 'types/auth'
import ChangePassword from 'views/ChangePassword/ChangePassword'

const ChangePasswordContainer = () => {
    const { version } = useAppSelector(store => store.ui)
    const { isAuthenticated } = useAppSelector(store => store.auth)
    const { isAdUser } = useAppSelector(store => store.profile)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuthenticated || isAdUser) {
            navigate('/')
        }
    }, [isAuthenticated, isAdUser, navigate])

    const handleChangePasswordSubmit = useCallback(async (formValues: TChangePasswordRequest) => {
        try {
            await AuthService.changePassword(formValues)
            await dispatch(checkLoginAsync())
            navigate('app/dashboard')
        } catch (err) {
            dispatch(showMessage({
                message : i18nKeys.change_password_single_error_message,
                type: EMessageType.ERROR
            }))
        }

    }, [dispatch, navigate])

    if (!isAuthenticated || isAdUser) {
        return null
    }

    return (
        <ChangePassword
            version={version}
            onChangePasswordSubmit={handleChangePasswordSubmit}
        />
    )
}

export default ChangePasswordContainer