import { Card } from '@mui/material'
import { CardHeader, TChartEntry, ChartLegend, CustomPieChart, PieChartPositioner } from 'components/CustomControls/CustomCharts'
import { FormHeader, FormLabel, FormWrapper, InputWrapper } from 'components/FormControls/FormControls'
import { i18nKeys } from 'i18n/keys'
import { TLicense } from 'types/businessLogic/license'
import styled from 'styled-components'
import colors from 'style/colors'
import TransMessage from 'components/TransMessage/TransMessage'
import { CustomCheckbox } from 'components/CustomControls/CustomCheckbox'
import { StatusCard } from 'components/StatusCard/StatusCard'
import LicenseHelper from 'helpers/LicenseHelper'
import DateHelper from 'helpers/DateHelper'
import React, { useState } from 'react'
import shadows from 'style/shadows'
import { CustomInput } from 'components/CustomControls/CustomInput'
import { useIntl } from 'react-intl'
import { CustomButton } from 'components/CustomControls/CustomButton'

const StyledCard = styled(Card)`
    height: 400px;
    width: 500px;
    padding: 10px;
    margin: 10px;
    box-shadow: ${shadows.TERTIARY};
    border-radius: 8px;
    position: relative;
`

const StyledCardGroup = styled.div`
    display: flex;
    margin-left: 32px;
    margin-top: 32px;
`

const ModulesList = styled.ul`
    padding-top: 64px;
    width: 100%;
    list-style: none;
    padding-inline-start: 40px;

    & li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

const StatusCardGroup = styled.div`
    display: flex;
    width: 100%;
    margin-top: 16px;
    margin-left: 24px;
`

const LicenseInfoGroup = styled.div`
    margin-top: 16px;
    margin-left: 48px;
`

const LicenseKeyInput = styled(CustomInput)`
    width: 300px;
    margin-right: 16px;
`

const StyledFormLabel = styled(FormLabel)`
    font-family: LatoBold;
`

const InfoBlock = styled.div`
    color: ${colors.extra.ALERT};
`

type TProps = {
    license : TLicense
    onUpdateLicense: (license: string) => void
}

const License = (props: TProps) => {
    const { license, onUpdateLicense } = props
    const intl = useIntl()
    const [licenseKey, setLicenseKey] = useState<string>('')

    const prepareLicenseData = (): TChartEntry[] => {
        return [
            {
                id: 'LicensedCameras',
                name: i18nKeys.licenses_chart_licensed_cameras,
                value: license.licensedCameras,
                color: colors.extra.SUCCESS
            },
            {
                id: 'UnlicensedCameras',
                name: i18nKeys.licenses_chart_unlicensed_cameras,
                value: license.cameraLicenses - license.licensedCameras,
                color: colors.shades.DEEP_SHADE
            }
        ]
    }

    const handleApplyLicenseKey = () => {
        onUpdateLicense(licenseKey)
        setLicenseKey('')
    }

    return (
        <FormWrapper>
            <FormHeader
                title={i18nKeys.license_overview_main_title}
                description={i18nKeys.license_overview_sub_title}
            />

            <StyledCardGroup>
                <StyledCard>
                    <CardHeader title={i18nKeys.licenses_chart_heading} />
                    <PieChartPositioner>
                        <CustomPieChart
                            data={prepareLicenseData()}
                            chartInnerDesc={i18nKeys.licenses_chart_in_use_total}
                            chartInnerNumber={license.cameraLicenses}
                        />
                    </PieChartPositioner>
                    <ChartLegend data={prepareLicenseData()} />
                </StyledCard>
                <StyledCard>
                    <CardHeader title={i18nKeys.license_modules_title} />
                    <ModulesList>
                        <li>
                            <TransMessage {...i18nKeys.license_modules_configuration} />
                            <CustomCheckbox
                                id='license_modules_configuration'
                                checked={license.configuration}
                            />
                        </li>
                        <li>
                            <TransMessage {...i18nKeys.license_modules_monitoring} />
                            <CustomCheckbox
                                id='license_modules_monitoring'
                                checked={license.monitoring}
                            />
                        </li>
                        <li>
                            <TransMessage {...i18nKeys.license_modules_reporting} />
                            <CustomCheckbox
                                id='license_modules_reporting'
                                checked={license.report}
                            />
                        </li>
                        <li>
                            <TransMessage {...i18nKeys.license_modules_webapi} />
                            <CustomCheckbox
                                id='license_modules_webApi'
                                checked={license.webApi}
                            />
                        </li>
                        <li>
                            <TransMessage {...i18nKeys.license_modules_situatorExclusive} />
                            <CustomCheckbox
                                id='license_modules_situator'
                                checked={license.situatorExclusive}
                            />
                        </li>
                    </ModulesList>
                </StyledCard>
            </StyledCardGroup>

            <StatusCardGroup>
                <StatusCard
                    id='unlicensed_cameras'
                    title={i18nKeys.license_overview_unlicensed_cameras}
                    value={license.unlicensedCameras.toString()}
                    backgroundColor={license.unlicensedCameras > 0 ? colors.extra.WARNING : 'transparent'}
                    textColor={license.unlicensedCameras > 0 ? colors.shades.PURE_WHITE : colors.primary.DARK_PRIMARY}
                />
                <StatusCard
                    id='expiration_date'
                    title={i18nKeys.license_overview_expiration_date}
                    value={DateHelper.formatDate(license.expirationDate, DateHelper.formats.DATE_PRIMARY)}
                    backgroundColor={LicenseHelper.willLicenseExpireSoon(license.expirationDate) ? colors.extra.WARNING : colors.primary.DEFAULT_PRIMARY}
                    textColor={colors.shades.PURE_WHITE}
                />
            </StatusCardGroup>
            
            <LicenseInfoGroup>
                <InputWrapper>
                    <StyledFormLabel>
                        <TransMessage {...i18nKeys.license_machine_code_label} />
                    </StyledFormLabel>
                    <FormLabel> {license.machineCode} </FormLabel>
                </InputWrapper>
                <InputWrapper>
                    <StyledFormLabel htmlFor='licenseKeyInput'>
                        <TransMessage {...i18nKeys.license_apply_new_license_label} />
                    </StyledFormLabel>
                    <LicenseKeyInput
                        name='licenseKeyInput'
                        id='licenseKeyInput'
                        value={licenseKey}
                        onChange={(event) => setLicenseKey(event.currentTarget.value)}
                        placeholder={intl.formatMessage(i18nKeys.license_apply_new_license_placeholder)}
                    />
                    <CustomButton
                        id='updateLicenseButton'
                        onClick={handleApplyLicenseKey}
                        disabled={!licenseKey}
                    >
                        Apply
                    </CustomButton>
                </InputWrapper>
                <InfoBlock>
                    <TransMessage {...i18nKeys.license_apply_new_license_info} />
                </InfoBlock>
            </LicenseInfoGroup>

        </FormWrapper>
    )
}

export default License