import React from 'react'
import { TSiteSystemDisk } from 'types/businessLogic/siteSystem'
import styled from 'styled-components'
import style from 'style'

const ItemWrapper = styled.div`
    margin-bottom: 20;
    width: 50%;
`
const ProgressArea = styled.div`
    width: 100%;
    align-self: flex-start;
    padding-left: 25px;
    padding-top: 6px;
    padding-right: 24px;
    text-align: left;
`
const Title = styled.h3`
    color: ${style.colors.primary.DARK_PRIMARY};
    display: flex;
`
const ProgressPercentTextContainer = styled.span`
    margin-left: auto;
`
const ProgressBarRoot = styled.div`
    width: 100%;
    height: 10px;
    margin-top: 8px;
    position: relative;
    background-color: ${style.colors.extra.EXTRA_GRAY};
`
const ProgressBarInner = styled.div`
    background-color: ${style.colors.primary.DEFAULT_PRIMARY};
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: inherit;
`

type Props = {
    disk: TSiteSystemDisk
}

const DiskChart = (props: Props) => {
    const { disk } = props

    return (
        <ItemWrapper>
            <ProgressArea>
                <Title>
                    {disk.name}
                    <ProgressPercentTextContainer id='progressPercentage'>
                        {`${disk.totalUsageInPercent}%`}
                    </ProgressPercentTextContainer>
                </Title>
                <ProgressBarRoot>
                    <ProgressBarInner id='progressLine' style={{width: `${disk.totalUsageInPercent}%`}} />
                </ProgressBarRoot>
            </ProgressArea>
        </ItemWrapper>
    )
}

export default DiskChart