import { Button, ButtonProps} from '@mui/material'
import chroma from 'chroma-js'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'

type TCustomButton = ButtonProps & {
    $secondary?: boolean
    uppercase?: boolean
    fromControl?: boolean
  }
  
export const CustomButton = styled(Button)<TCustomButton>`  
    background-color: ${p => p.$secondary ? 'transparent' : colors.primary.DEFAULT_PRIMARY};
    border-radius: ${borderRadius.SECONDARY};
    color: ${p => p.$secondary ? colors.primary.DARK_PRIMARY : colors.shades.PURE_WHITE};
    font-family: ${fonts.PRIMARY_BOLD};
    height: 56px;
    padding: 0px 24px;
    min-width: ${p => p.fromControl ? 380 : 200 }px;
    text-transform: ${p => p.uppercase ? 'uppercase' : 'none'};
    font-size: ${typographyScale.TYPE14};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE14LS};
    border: ${p => p.$secondary ? `solid 1px ${colors.shades.DARK_SHADE}` : ''};
    fill: ${colors.shades.PURE_WHITE};
    justify-content: ${p => p.endIcon ? 'space-between' : ''};
    :hover {
      transform: background-color 150ms linear;
      background-color: ${p => p.$secondary ? colors.shades.LIGHT_SHADE : chroma(colors.primary.DEFAULT_PRIMARY).darken(0.10).css()}
    }
  
    :disabled {
      opacity: 1;
      color: ${colors.shades.PURE_WHITE};
      background-color: ${colors.primary.DEFAULT_DISABLED};
  
      .MuiButton-label {
        opacity: 0.3
      }
    }
  
    .MuiButton-label {
      justify-content: ${p => p.endIcon ? 'space-between' : 'inherit'};
    }
`