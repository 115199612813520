import NotificationManager from 'components/NotificationManager/NotificationManager'
import React from 'react'
import { clearNotifications, resetItemsCount } from 'state/slices/notificationSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { useNavigate } from 'react-router-dom'

const NotificationManagerContainer = () => {
    const { items, newItemsCount } = useAppSelector(state => state.notification)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const handleShowEvents = () => {
        dispatch(resetItemsCount())
    }

    const handleClearEvents = () => {
        dispatch(clearNotifications())
    }

    const handleGotoMonitorEvents = () => {
        navigate('/app/monitor/site-events')
    }

    return <NotificationManager
        items={items}
        newItemsCount={newItemsCount}
        onShowEvents={handleShowEvents}
        onClearEvents={handleClearEvents}
        onGotoMonitorEvents={handleGotoMonitorEvents}
    />
}

export default NotificationManagerContainer