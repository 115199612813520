import axiosClient from 'api/axiosClient'
import { ENotificationPriority, EPerformanceState } from 'enums/enums'
import { TCamerasPerSite } from 'types/businessLogic/cameraPerformance'
import { TOverallState, TPerformanceItem } from 'types/businessLogic/performance'
import { TFetchParamsByIdRequest, TFetchParamsRequest } from 'types/network'

class CameraPerformanceService {
    private static _api = axiosClient()

    public static async getOverallStates() {
        const response = await this._api.get<TOverallState>('CameraPerformance/OverallStates')
        return response.data
    }
    
    public static async getCameras(params: TFetchParamsRequest, filterByStates: EPerformanceState[]) {
        const response = await this._api.get<{cameras: TCamerasPerSite[], totalNumber: number}>('CameraPerformance', { params: { ...params, FilterByStates : filterByStates }})
        return response.data
    }

    public static async getCamerasBySide(params: TFetchParamsByIdRequest, filterByStates: EPerformanceState[]) {
        try {
            const response = await this._api.get<TPerformanceItem[]>('CameraPerformance/Site', { params: { ...params, FilterByStates : filterByStates }})
            return response.data
        } catch (error) {
            return []
        }
    }

    public static async ignoreCameras(ids: number[]) {
        await this._api.post('CameraPerformance/Ignore', { Ids: ids })
    }

    public static async setNotificationPriority(ids: number[], notificationPriority: ENotificationPriority, useDefault: boolean) {
        await this._api.post('CameraPerformance/NotificationPriority', {
            Ids: ids,
            NotificationPriority: notificationPriority,
            UseDefaultNotificationPriority: useDefault
        })
    }
}

export default CameraPerformanceService