import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import CameraPerformanceService from 'services/cameraPerformanceService'
import { showMessage } from 'state/slices/uiSlice'
import { TCamerasPerSite } from 'types/businessLogic/cameraPerformance'
import { TGetCardPageRequest, TOverallState } from 'types/businessLogic/performance'

export type TCameraPerformanceState = {
    allCamerasPerSite: TCamerasPerSite[]
    totalNumberCameras: number
    overallState: TOverallState
}

const initialState: TCameraPerformanceState = {
    allCamerasPerSite: [],
    totalNumberCameras: 0,
    overallState: {
        critical: 0,
        healthy: 0,
        ignored: 0,
        offline: 0,
        warning: 0
    }
}

export const fetchCameraPerformanceAsync = createAsyncThunk('cameraPerformanceSlice/fetchCameraPerformanceAsync', async (params: TGetCardPageRequest, thunkApi) => {
    try {
        const result = await Promise.all([
            CameraPerformanceService.getCameras(params.fetchParams, params.filterByStates),
            CameraPerformanceService.getOverallStates()
        ])
    
        return {
            camerasPerSite: result[0],
            overallStates: result[1]
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'cameraSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCameraPerformanceAsync.fulfilled, (state, action) => {
            if (action.payload) {
                const { overallStates, camerasPerSite } = action.payload

                state.overallState = overallStates
                state.allCamerasPerSite = camerasPerSite.cameras
                state.totalNumberCameras = camerasPerSite.totalNumber
            }
        })
    }
})

export default slice.reducer