import { yupResolver } from '@hookform/resolvers/yup'
import AdvancedJobConfigFields from 'components/AdvancedJobConfigFields/AdvancedJobConfigFields'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormWrapper, FormContent, FormInput, FormLabel, InputWrapper, FormButtonGroup, Instructions, FormFooter, FormGroup, FormCheckbox } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TChangeAllUserPasswordJobRequest, TChangeUserPasswordJobRequest, TJobRequest } from 'types/businessLogic/job'
import { TModalProps } from 'types/ui/modal'
import * as yup from 'yup'
import colors from 'style/colors'
import fonts from 'style/fonts'
import styled from 'styled-components'
import { siteUserService } from 'services/siteUserService'
import { useIntl } from 'react-intl'
import { TUploadManagerItem } from 'types/ui/uploadManager'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { pushItem, setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import { useModal } from 'hooks/modalProvider'

const LabelAndCheckBox = styled.div`
    display: flex;
    align-items: center;
    min-width: 260px;
    margin-right: 40px;
    font-family: ${fonts.PRIMARY_SEMIBOLD};
`
const Title = styled.label`
    color: ${colors.primary.DARK_PRIMARY};
    width: 'inherit';
    font-size: '16px';
    max-width: 300px;
    font-family: LatoSemibold;
    line-height: 24px;
    letter-spacing: 0.4px;
    width: 300px;
`

type TChangeUserPasswordJobForm = {
    password: string
    secondPassword: string
    advancedJobConfig: TJobRequest
    comment?: string
}

type ChangeSiteUserPasswordModalProps = TModalProps & {
    totalCount: number
}

const ChangeSiteUserPassword = (props: ChangeSiteUserPasswordModalProps) => {
    const {filters, ids, totalCount} = props
    const isSelectAll = !!filters

    const intl = useIntl()
    const {closeModal} = useModal()
    const dispatch = useAppDispatch()
    const isCommentMandatory = useAppSelector(state => state.ui.uiSettings.isCommentMandatoryForJobs)

    const [isIgnoreSecondPasswordEnabled, setisIgnoreSecondPasswordEnabled] = useState<boolean>(false)
    
    const handleChangeSiteUserPasswordSubmit = useCallback(async (formValues: TChangeUserPasswordJobForm) : Promise<void> => {
        let uploadManagerItem : TUploadManagerItem
        
        try {
            if (isSelectAll) {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_site_users_change_password, {count: totalCount})
                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const request: TChangeAllUserPasswordJobRequest = {
                    ...formValues.advancedJobConfig,
                    filters: filters,
                    password: formValues.password,
                    secondPassword: formValues.secondPassword,
                    comment: formValues.comment
                }

                dispatch(pushItem(uploadManagerItem))
                siteUserService.changeAllSiteUserPassword(request)
            } else {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_site_users_change_password, {count: (ids as number[]).length})
                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const request: TChangeUserPasswordJobRequest = {
                    ...formValues.advancedJobConfig,
                    ids: ids as number[],
                    password: formValues.password,
                    secondPassword: formValues.secondPassword,
                    comment: formValues.comment
                }

                dispatch(pushItem(uploadManagerItem))
                siteUserService.changeSiteUserPassword(request)
            }
            dispatch(showMessage({message: i18nKeys.change_password_modal_success_message, type: EMessageType.SUCCESS}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.change_password_modal_error_message, type: EMessageType.ERROR}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem!.id, isError: true}))
        }

        closeModal()
    }, [dispatch, filters, ids, intl, isSelectAll, closeModal, totalCount])

    const defaultValues : TChangeUserPasswordJobForm = {
        password: '',
        secondPassword: '',
        advancedJobConfig: {}
    }

    const toggleIgnoreSecondPassword = () => {
        setisIgnoreSecondPasswordEnabled(prev => (!prev))
    }

    const validationSchema = (mandatoryComment: boolean) => yup.object().shape({
        password: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)),
        secondPassword: yup.string().when('ignoreSecondPassword', {
            is: false,
            then: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field))
        }),
        comment: mandatoryComment ? yup.string().max(500).required(intl.formatMessage(i18nKeys.general_validation_required_field)) : yup.string().max(500)
    })

    const { handleSubmit, formState, control, setValue} = useForm<TChangeUserPasswordJobForm>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema(isCommentMandatory))
    })
    const { isValid, isValidating, isSubmitting, isDirty } = formState
    
    return (
        <FormWrapper onSubmit={handleSubmit(handleChangeSiteUserPasswordSubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_site_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='password'>
                            <TransMessage {...i18nKeys.change_password_modal_input_password_label} />
                        </FormLabel>
                        <FormInput
                            name='password'
                            control={control}
                            type='password'
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='secondPassword'>
                            <TransMessage {...i18nKeys.change_second_password_modal_input_password_label} />
                        </FormLabel>
                        <FormInput
                            name='secondPassword'
                            disabled={isIgnoreSecondPasswordEnabled}
                            control={control}
                            type='password'
                        />
                    </InputWrapper>
                    <LabelAndCheckBox>
                        <Title>
                            <TransMessage {...i18nKeys.change_password_ignore_second_password} />
                        </Title>
                        <FormCheckbox
                            name='ignoreSecondPassword'
                            control={control}
                            onChange={toggleIgnoreSecondPassword}
                            defaultValue={false}
                        />
                    </LabelAndCheckBox>
                </FormGroup>
                <FormGroup collapsable title={i18nKeys.advanced_job_configuration_title}>
                    <AdvancedJobConfigFields
                        formProps={formState}
                        setFieldValue={setValue}
                        hideUndoField
                        control={control}
                    />
                </FormGroup>
                <FormGroup title={i18nKeys.job_configuration_comment_title}>
                    <InputWrapper>
                        <FormLabel htmlFor='comment'>
                            <TransMessage {...i18nKeys.job_configuration_comment} />
                        </FormLabel>
                        <FormInput
                            name='comment'
                            autoComplete='job-comment'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.change_site_connection_user_modal_submit_button} />
                    </CustomButton>
                </FormButtonGroup>
                <Instructions>
                    <TransMessage {...i18nKeys.change_site_connection_user_modal_form_description} />
                </Instructions>
            </FormFooter>
        </FormWrapper>
    )
}

export default ChangeSiteUserPassword