import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { TSiteEntity } from 'types/businessLogic/site'
import { tableInfo } from 'views/Control/SiteServices/siteServicesTableColumns'
import CustomLink from 'components/CustomControls/CustomLink'

class SiteServicesTablePreparer {
    public static prepareSiteServiceRows = (siteServices: TSiteEntity[], openSiteServiceDetailsModal: (id: number) => void): TTableRowData[] =>
        siteServices.map(siteService => ({
            ...siteService,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.name.key,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    readOnly: false,
                    [GeneralHelper.propertyTypes.customRender.key]: () =>
                        <CustomLink onClick={() => openSiteServiceDetailsModal(siteService.id)}>{siteService.name}</CustomLink>
                },
                {
                    name: tableInfo.columnConfig.siteName.key,
                    translationKey: tableInfo.columnConfig.siteName.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: siteService.siteName
                },
                {
                    name: tableInfo.columnConfig.labels.key,
                    translationKey: tableInfo.columnConfig.labels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: siteService.labels
                },
                {
                    name: tableInfo.columnConfig.siteLabels.key,
                    translationKey: tableInfo.columnConfig.siteLabels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: siteService.siteLabels
                },
                ...siteService.propertyValues
            ]
        }))

    public static prepareGroups = (groups: TTableGroup<TSiteEntity>[], openSiteServiceDetailsModal: (id: number) => void): TTableGroup<TTableRowData>[] => groups.map(group => ({
        ...group,
        data: group.data ? SiteServicesTablePreparer.prepareSiteServiceRows(group.data, openSiteServiceDetailsModal) : undefined
    }))
}

export default SiteServicesTablePreparer