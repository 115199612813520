import LabelService from 'services/labelService'
import { TQueryFilter } from 'types/network'

class LabelsHelper {
    static loadLabels = async (inputText: string) => {
        const filters : TQueryFilter[] = []

        if (inputText.length > 0) {
            filters.push({
                property: 'Name',
                value: inputText,
                isExact: false
            })
        }

        try {
            const labels = await LabelService.getLabelsPaginate({
                PageNumber: 1,
                PageSize: 20,
                Filters: filters
            })
            return labels.map(label => {
                return {
                    value: label,
                    label: label
                }
            })
        // eslint-disable-next-line no-empty
        } catch (error) {
            
        }
    }

    static loadlabelsWithId = async (inputText: string) => {
        const filters : TQueryFilter[] = []

        if (inputText.length > 0) {
            filters.push({
                property: 'Name',
                value: inputText,
                isExact: false
            })
        }

        try {
            const response = await LabelService.getOverview({
                PageNumber: 1,
                PageSize: 20,
                Filters: filters
            })
            return response.labels.map(label => {
                return {
                    label: label.name,
                    value: label.id
                }
            })
        // eslint-disable-next-line no-empty
        } catch (error) {
            
        }
    }
}

export default LabelsHelper