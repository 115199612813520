import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TSiteEntity } from 'types/businessLogic/site'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { tableInfo } from 'views/Control/SiteTimeProfiles/siteTimeProfilesTableColumns'
import CustomLink from 'components/CustomControls/CustomLink'

class SiteTimeProfilesTablePreparer {
    public static prepareSiteRows = (siteTimeProfiles: TSiteEntity[], openSiteTimeProfileDetailsModal: (id: number) => void): TTableRowData[] =>
        siteTimeProfiles.map(siteTimeProfile => ({
            ...siteTimeProfile,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.name.key,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    readOnly: false,
                    [GeneralHelper.propertyTypes.customRender.key]: () =>
                        <CustomLink onClick={() => openSiteTimeProfileDetailsModal(siteTimeProfile.id)}>{siteTimeProfile.name}</CustomLink>
                },
                {
                    name: tableInfo.columnConfig.siteName.key,
                    translationKey: tableInfo.columnConfig.siteName.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: siteTimeProfile.siteName
                },
                {
                    name: tableInfo.columnConfig.labels.key,
                    translationKey: tableInfo.columnConfig.labels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: siteTimeProfile.labels
                },
                {
                    name: tableInfo.columnConfig.siteLabels.key,
                    translationKey: tableInfo.columnConfig.siteLabels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: siteTimeProfile.siteLabels
                },
                // ...siteTimeProfile.propertyValues
            ]
        }))

    public static prepareGroups = (groups: TTableGroup<TSiteEntity>[], openSiteTimeProfileDetailsModal: (id: number) => void): TTableGroup<TTableRowData>[] => groups.map(group => ({
        ...group,
        data: group.data ? SiteTimeProfilesTablePreparer.prepareSiteRows(group.data, openSiteTimeProfileDetailsModal) : undefined
    }))
}

export default SiteTimeProfilesTablePreparer