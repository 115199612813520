import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EUploadState } from 'enums/enums'
import { TUploadManagerItem } from 'types/ui/uploadManager'

export type TUploadMangerState = {
    popupOpened: boolean
    items: TUploadManagerItem[]
    index: number
}

const initialState : TUploadMangerState = {
    popupOpened: false,
    items: [],
    index: 0
}

const slice = createSlice({
    name: 'uploadManager',
    initialState: initialState,
    reducers: {
        setPopupOpenedState: (state, action: PayloadAction<boolean>) => {
            state.popupOpened = action.payload
        },
        setItems: (state, action: PayloadAction<TUploadManagerItem[]>) => {
            state.items = action.payload
        },
        clearItems: (state) => {
            state.items = []
        },
        setIndex: (state, action: PayloadAction<number>) => {
            state.index = action.payload
        },
        pushItem: (state, action: PayloadAction<TUploadManagerItem>) => {
            const newId = state.index + 1
            state.index = newId

            action.payload.id = newId

            state.items = [
                ...state.items,
                action.payload
            ]
        },
        updateProgress: (state, action: PayloadAction<{id: number | string, percentage: number}>) => {
            const { id, percentage } = action.payload
            const currentItem = state.items.find(item => item.id === id)

            if (!currentItem) return

            currentItem.percentComplete = percentage
        },
        setDoneOrErrorOnItem: (state, action: PayloadAction<{id: number | string, isError?: boolean, errorMessage?: number | string}>) => {
            const { id, isError, errorMessage } = action.payload

            const currentItem = state.items.find(item => item.id === id)

            if (!currentItem) return

            if (isError) {
                currentItem.errorMessage = errorMessage
                currentItem.state = EUploadState.ERROR
                currentItem.percentComplete = 100
                currentItem.lengthComputable = true
            } else {
                currentItem.state = EUploadState.DONE
                currentItem.percentComplete = 100
            }
        }
    }
})

export const {setPopupOpenedState, setItems, clearItems, setIndex, pushItem, updateProgress, setDoneOrErrorOnItem} = slice.actions

export default slice.reducer