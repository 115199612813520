import { TUseCardPage } from 'hooks/useCardPage'
import { ServiceStateInfos } from 'components/PagedCardList/ServiceStateInfos'
import { EPerformanceState, EServiceState, EThresholdType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { TSiteServicePerformance } from 'types/businessLogic/siteServicePerformance'
import { VmsStateInfos } from 'components/PagedCardList/VmsStateInfos'
import { getPerformanceStateInfos } from 'components/PagedCardList/getPerformanceStateInfos'
import InfoCard, { TInfoTable } from 'components/PagedCardList/InfoCard'
import { ThresholdTypeInfos } from 'components/PagedCardList/ThresholdTypeInfos'

const Container = styled.div`
    width: 264px;
`

type TProps = TUseCardPage & {
    siteService: TSiteServicePerformance
    isChecked: boolean
    isFiltered: boolean
}

const SiteServiceInfoCard = (props: TProps) => {
    const { isChecked, isFiltered, selectAll, siteService, onItemSelect} = props

    const siteServiceState = siteService.overallState
    const stateInfo = getPerformanceStateInfos(siteServiceState, siteService.isSiteIgnored)
    const borderColor = isFiltered ? stateInfo.color : '#c6ccd4'

    const handleCheckboxClick = (checked: boolean) => {
        onItemSelect([siteService], checked)
    }

    const intl = useIntl()

    const hostText = intl.formatMessage(i18nKeys.site_service_performance_host)

    const serviceStateText = intl.formatMessage(i18nKeys.site_service_performance_service_state_label)
    const serviceStateTextTooltip = intl.formatMessage(i18nKeys.site_service_performance_service_state_tooltip)

    const vmsStateText = intl.formatMessage(i18nKeys.site_service_performance_vms_state_label)
    const vmsStateTextTooltip = intl.formatMessage(i18nKeys.site_service_performance_vms_state_tooltip)

    const serviceStateInfo = ServiceStateInfos[siteService.serviceState]
    const vmsStateInfo = VmsStateInfos[siteService.vmsState]

    let serviceStateValue = intl.formatMessage(serviceStateInfo.translationKey)
    let vmsStateValue = intl.formatMessage(vmsStateInfo.translationKey)

    let serviceStateValueColor = serviceStateInfo.color
    let vmsStateValueColor = vmsStateInfo.color

    let networkValue = siteService.networkUsageMbitPerSecond < 0 ? '-' : siteService.networkUsageMbitPerSecond + ' Mbit/s'

    let vmsStateCodeTooltip = siteService.vmsStateCode

    if (siteServiceState === EPerformanceState.IGNORED) {
        serviceStateValue = '-'
        vmsStateValue = '-'

        vmsStateCodeTooltip = undefined

        serviceStateValueColor = '#959595'
        vmsStateValueColor = '#959595'

        networkValue = '-'
    }
    
    const getSiteServiceInfoCard = () => {
        
        const thresholdsTooltip = (warningValue: number, criticalValue: number, thresholdType: EThresholdType) => {
            return `${intl.formatMessage(i18nKeys.threshold_type_prefix)} ${intl.formatMessage(ThresholdTypeInfos[thresholdType].translationKey)}
            Warning: >${warningValue}%
            Critical: >${criticalValue}%`
        }

        const tableInfo : TInfoTable[] = [
            {color: '#959595', value: siteService.host, text: hostText},
            {color: vmsStateValueColor, value: vmsStateValue, text: vmsStateText, tooltip: vmsStateCodeTooltip, labelTooltip: vmsStateTextTooltip}
        ]
            
        const blockInfo = []

        if (siteService.serviceState !== EServiceState.UNKNOWN) {
            blockInfo.push({ state: siteService.cpuState, value: siteService.totalCpuInPercent, text: 'CPU', tooltip: thresholdsTooltip(siteService.cpuThresholdWarningInPercent, siteService.cpuThresholdCriticalInPercent, siteService.cpuThresholdType)})
            blockInfo.push({ state: siteService.memoryState, value: siteService.totalMemoryInPercent, text: 'MEM.', tooltip: thresholdsTooltip(siteService.memoryThresholdWarningInPercent, siteService.memoryThresholdCriticalInPercent, siteService.memoryThresholdType)})

            tableInfo.splice(1, 0, {color: serviceStateValueColor, value: serviceStateValue, text: serviceStateText, labelTooltip: serviceStateTextTooltip})
            tableInfo.push({color: '#959595', value: networkValue, text: 'Network'})
        }

        return (
            <Container>
                <InfoCard
                    height={siteService.serviceState === EServiceState.UNKNOWN ? '238px' : undefined}
                    borderColor={borderColor}
                    handleCheckboxClick={handleCheckboxClick}
                    iconInfo={{ icon: stateInfo.icon, fill: stateInfo.color }}
                    id={'siteService_' + siteService.id.toString()}
                    isChecked={isChecked}
                    isFiltered={isFiltered}
                    selectAll={selectAll}
                    title={siteService.serviceName}
                    overallState={siteServiceState}
                    tableInfos={tableInfo}
                    blockInfos={blockInfo}
                    notificationPriority={siteService.notificationPriority}
                    notificationType={siteService.notificationType}
                />
            </Container>
        )
    }
    
    return getSiteServiceInfoCard()
    
}
export default SiteServiceInfoCard
