import { CustomInput } from 'components/CustomControls/CustomInput'
import styled from 'styled-components'

export const SearchInput = styled(CustomInput)`
    width: 254px;
    margin-left: 16px;

    .MuiInputBase-input {
        padding-top: 0px;
    }
`
