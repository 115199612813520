import TransMessage from 'components/TransMessage/TransMessage'
import Icon from 'components/Icon/Icon'
import useAuthorization from 'hooks/useAuthorization'
import { TTranslationKey } from 'types/ui/translation'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { EProfileRights } from 'enums/enums'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import styled from 'styled-components'

type TProps = {
    id: string
    to: string
    label: TTranslationKey
    icon: string | JSX.Element
    rights: EProfileRights[]
    activeColor?: string
    rel?: string
    target?: string
}

const Wrapper = styled.div`
    fill: ${colors.primary.LIGHT_PRIMARY};

    & > a {
        cursor: pointer;
        padding: 12px 8px 12px 16px;
        margin: 0 auto 8px auto;
        min-height: 32px;
        border-radius: ${borderRadius.SECONDARY};
        width: calc(100% - 16px);
        color: ${colors.primary.LIGHT_PRIMARY};
        fill: ${colors.primary.LIGHT_PRIMARY};
        font-size: 14px;
        font-family: LatoMedium;
        line-height: 24px;
        letter-spacing: 0.6px;
        display: flex;
        align-items: center;

        & > svg {
            min-width: 24px;
        }
        & > span {
            padding-left: 32px;
        }
            
        &:hover {
            color: ${colors.shades.PURE_WHITE};

            & > svg {
                fill: ${colors.shades.PURE_WHITE};
            }
        }
    }
    
`

const ListItem = (props: TProps) => {
    const checkAuth = useAuthorization()

    const renderIcon = () => {
        if (typeof props.icon === 'string') {
            return <Icon d={props.icon} />
        }

        return props.icon
    }

    if (checkAuth(...(props.rights))) {
        return <Wrapper>
            <NavLink
                to={props.to}
                id={props.id}
                rel={props.rel}
                target={props.target}
                style={({isActive}) => isActive ? {
                    backgroundColor: props.activeColor ?? colors.primary.DEFAULT_PRIMARY,
                    color: colors.shades.PURE_WHITE
                } : {}}
            >
                {renderIcon()}
                <span>
                    <TransMessage {...props.label} />
                </span>
            </NavLink>
        </Wrapper>
    } else {
        return <></>
    }
}

export default ListItem