import React from 'react'
import { Icons } from 'components/Icon/Icon'
import { i18nKeys } from 'i18n/keys'
import { EProfileRights } from 'enums/enums'
import { TAppRoutes } from 'types/routing'
import { ELicenseTypes } from 'enums/ELicenseTypes'
import LabelIcon from '@mui/icons-material/Label'
import { VpnKey } from '@mui/icons-material'

const AppRoutes: TAppRoutes = {
    root: {
        prefix: '/app/',
        rights: [],
        icon: '',
        label: i18nKeys.sidenav_umbrella_core,
        routes: [
            {
                id: 'dashboard',
                to: '/app/dashboard',
                icon: Icons.DASHBOARD,
                label: i18nKeys.sidenav_dashboard,
                rights: []
            },
            {
                id: 'map',
                to: '/app/map',
                icon: Icons.PIN,
                label: i18nKeys.sidenav_map,
                rights: [],
                ignoreForSituatorExclusive: true
            },
            {
                id: 'sites',
                to: '/app/sites',
                icon: Icons.SITE,
                label: i18nKeys.sidenav_sites,
                rights: []
            },
            {
                id: 'plugins',
                to: '/app/plugins',
                icon: Icons.PLUGINS,
                label: i18nKeys.sidenav_plugins,
                rights: []
            },
            {
                id: 'site-jobs',
                to: '/app/site-jobs',
                icon: Icons.SITE_JOBS,
                label: i18nKeys.sidenav_site_jobs,

                rights: []
            }
        ]
    },
    control: {
        prefix: '/app/control/',
        rights: [],
        icon: Icons.CONTROL_CENTRE,
        label: i18nKeys.sidenav_control_centre,
        license: ELicenseTypes.CONFIGURATION,
        routes: [
            {
                id: 'control-site-users',
                to: '/app/control/site-users',
                icon: Icons.SITE_USERS,
                label: i18nKeys.sidenav_site_users,
                rights: [],
                ignoreForSituatorExclusive: true
            },
            {
                id: 'control-site-groups',
                to: '/app/control/site-groups',
                icon: Icons.SITE_GROUPS,
                label: i18nKeys.sidenav_site_groups,
                rights: [],
                ignoreForSituatorExclusive: true
            },
            {
                id: 'control-cameras',
                to: '/app/control/cameras',
                icon: Icons.CAMERAS,
                label: i18nKeys.sidenav_cameras,
                rights: [],
                ignoreForSituatorExclusive: true
            },
            {
                id: 'control-camera-firmware',
                to: '/app/control/camera-firmware',
                icon: Icons.CAMERA_FIRMWARE,
                label: i18nKeys.sidenav_camera_firmwares,
                rights: [],
                ignoreForSituatorExclusive: true
            },
            {
                id: 'control-site-services',
                to: '/app/control/site-services',
                icon: Icons.SITE_SERVICES,
                label: i18nKeys.sidenav_site_services,
                rights: []
            },
            {
                id: 'control-license-update',
                to: '/app/control/license-update',
                icon: Icons.LICENSE_UPDATE,
                label: i18nKeys.sidenav_site_license_update,
                rights: [],
                ignoreForSituatorExclusive: true
            },
            {
                id: 'control-time-profile',
                to: '/app/control/site-time-profile',
                icon: Icons.SITE_TIME_PROFILE,
                label: i18nKeys.sidenav_site_time_profile,
                rights: [],
                ignoreForSituatorExclusive: true
            }
        ]
    },
    monitor: {
        prefix: '/app/monitor/',
        rights: [EProfileRights.HAS_MONITORING_RIGHT],
        icon: Icons.MONITORING_CENTRE,
        label: i18nKeys.sidenav_monitoring_centre,
        license: ELicenseTypes.MONITORING,
        routes: [
            {
                id: 'monitor-umbrella-core',
                to: '/app/monitor/umbrella-core',
                icon: Icons.UMBRELLA_CORE,
                label: i18nKeys.sidenav_umbrella_core,
                rights: [EProfileRights.IS_UMBRELLA_ADMIN]
            },
            {
                id: 'monitor-site-systems',
                to: '/app/monitor/site-systems',
                icon: Icons.DASHBOARD,
                label: i18nKeys.sidenav_site_systems,
                rights: []
            },
            {
                id: 'monitor-monitor-cameras',
                to: '/app/monitor/cameras',
                icon: Icons.CAMERA,
                label: i18nKeys.sidenav_cameras,
                rights: [],
                ignoreForSituatorExclusive: true
            },
            {
                id: 'monitor-site-services',
                to: '/app/monitor/site-services',
                icon: Icons.SITE_SERVICES,
                label: i18nKeys.sidenav_site_services,
                rights: []
            },
            {
                id: 'monitor-site-events',
                to: '/app/monitor/site-events',
                icon: Icons.SITE_EVENTS,
                label: i18nKeys.sidenav_site_events,
                rights: []
            }
        ]
    },
    report: {
        prefix: '/app/report/',
        rights: [EProfileRights.HAS_REPORT_RIGHT],
        icon: Icons.REPORT_CENTER,
        label: i18nKeys.sidenav_report_center,
        license: ELicenseTypes.REPORT,
        ignoreForSituatorExclusive: true,
        routes: [
            {
                id: 'report-reports',
                to: '/app/report/reports',
                icon: Icons.REPORTS,
                label: i18nKeys.sidenav_reports,
                rights: [EProfileRights.HAS_SITE_MAINTENANCE_REPORT_RIGHT]
            },
            {
                id: 'report-audit-reports',
                to: '/app/report/audit_reports',
                icon: Icons.AUDIT_REPORTS,
                label: i18nKeys.sidenav_audit_reports,
                rights: [EProfileRights.HAS_SITE_AUDIT_REPORT_RIGHT]
            }
        ]
    },
    admin: {
        prefix: '/app/admin',
        rights: [EProfileRights.IS_UMBRELLA_ADMIN],
        icon: Icons.ADMINISTRATION,
        label: i18nKeys.sidenav_administration,
        routes: [
            {
                id: 'admin-user',
                to: '/app/admin/users',
                icon: Icons.UMBRELLA_USER,
                label: i18nKeys.sidenav_administration_users,
                rights: []
            },
            {
                id: 'admin-roles',
                to: '/app/admin/roles',
                icon: Icons.UMBRELLA_ROLE,
                label: i18nKeys.sidenav_administration_roles,
                rights: []
            },
            {
                id: 'admin-labels',
                to: '/app/admin/labels',
                icon: <LabelIcon />,
                label: i18nKeys.sidenav_administration_labels,
                rights: []
            },
            {
                id: 'admin-apiKeys',
                to: '/app/admin/apiKeys',
                icon: <VpnKey />,
                label: i18nKeys.sidenav_administration_apiKeys,
                rights: [],
                license: ELicenseTypes.WEBAPI
            },
            {
                id: 'admin-license',
                to: '/app/admin/license',
                icon: Icons.LICENSE,
                label: i18nKeys.sidenav_administration_license,
                rights: []
            },
            {
                id: 'admin-settings',
                to: '/app/admin/settings',
                icon: Icons.SETTINGS,
                label: i18nKeys.sidenav_settings,
                rights: []
            },
            {
                id: 'admin-email',
                to: '/app/admin/email',
                icon: Icons.EMAIL_ALTERNATE,
                label: i18nKeys.sidenav_email,
                rights: [],
                license: ELicenseTypes.MONITORING
            }
        ]
    }
}

export default AppRoutes