import { TableCell, TableHead, TableRow } from '@mui/material'
import CustomTableHeadCell from 'components/Tables/CustomTableHeadCell'
import TransMessage from 'components/TransMessage/TransMessage'
import AppConstants from 'helpers/AppConstants'
import { i18nKeys } from 'i18n/keys'
import React, { PropsWithChildren } from 'react'
import { TTableColumn } from 'types/ui/table'

type TProps = {
    cells: TTableColumn[]
    sortedBy?: string
    groupedBy?: string
    order: string
    onSort: (sortBy: string) => void
    prependEmptyCell: boolean
    onGroup: (groupBy: string, groupByIntlKey: string) => void
    showChildren?: boolean
}

const CustomTableHead = (props: PropsWithChildren<TProps>) => {
    const {cells, groupedBy, sortedBy, order, children, prependEmptyCell, showChildren} = props
    const { onGroup, onSort } = props

    const mapCells = () => {

        while (cells.length < AppConstants.other.TABEL_MIN_COLUMN_AMOUNT) {
            cells.push({
                key: Date.now().toString(),
                translationKey: ''
            })
        }

        return cells.map((cell: TTableColumn, index: number) => {
            let name: string | JSX.Element = ''
            
            if (cell.translationKey) {
                name = <TransMessage debugId={cell.key} {...i18nKeys[cell.translationKey]} />
            }
            
            return <CustomTableHeadCell
                key={index}
                groupActive={!!groupedBy && (cell.groupKey === groupedBy)}
                sortActive={!!sortedBy && (cell.sortKey === sortedBy)}
                groupKey={cell.groupKey}
                groupByIntlKey={cell.translationKey}
                onGroup={onGroup}
                sortKey={cell.sortKey}
                onSort={onSort}
                order={order}
                name={name}
            />
        })
    }

    return (
        <TableHead>
            <TableRow>
                {prependEmptyCell && <TableCell />}
                {showChildren && <TableCell />}
                {mapCells()}
            </TableRow>
            {children}
        </TableHead>
    )
}

export default CustomTableHead