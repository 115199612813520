import React from 'react'
import { ETableFilters } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'
import AppConstants from 'helpers/AppConstants'

const columnConfig: TTableColumnsConfig = {
    name: {
        key: 'Name',
        translationKey: i18nKeys.cameras_table_head_camera_name.id,
        sortKey: 'Name',
        displayed: true,
        filter: {
            key: 'Name',
            type: ETableFilters.INPUT_TEXT
        }
    },
    siteName: {
        key: 'SiteName',
        translationKey: i18nKeys.cameras_table_head_site_name.id,
        sortKey: 'SiteName',
        groupKey: 'SiteName',
        displayed: true,
        filter: {
            key: 'SiteName',
            type: ETableFilters.INPUT_TEXT
        }
    },
    firmware: {
        key: 'Firmware',
        translationKey: i18nKeys.property_device_firmware.id,
        displayed: true,
        filter: {
            key: 'Firmware',
            type: ETableFilters.INPUT_TEXT
        }
    },
    model: {
        key: 'Model',
        translationKey: i18nKeys.property_device_model.id,
        displayed: true,
        filter: {
            key: 'Model',
            type: ETableFilters.INPUT_TEXT
        }
    }
}

const defaultLayout = [
    columnConfig.name.key,
    columnConfig.siteName.key,
    columnConfig.firmware.key,
    columnConfig.model.key
]

export const tableInfo: TTableInfo = {
    id: AppConstants.tableIds.APPLY_FIRMWARE_CAMERAS,
    columnConfig,
    defaultLayout
}