import Icon, { Icons } from 'components/Icon/Icon'
import { TLanguages } from 'types/ui/translation'
import React from 'react'
import { setLanguageAsync } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import styled from 'styled-components'
import { FormControl, Input, InputAdornment, MenuItem, SelectChangeEvent, SvgIcon } from '@mui/material'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import { CustomSelect } from 'components/CustomControls/CustomSelect'
import colors from 'style/colors'

const languages = [
    {
        id: 'en',
        value: 'en',
        label: <TransMessage {...i18nKeys.language_picker_selection_english} />
    },
    {
        id: 'de',
        value: 'de',
        label: <TransMessage {...i18nKeys.language_picker_selection_german} />
    },
    {
        id: 'fr',
        value: 'fr',
        label: <TransMessage {...i18nKeys.language_picker_selection_french} />
    }
]

const StyledFormControl = styled(FormControl)<{$iconColor?: string}>`
    fill: ${p => p.$iconColor};
    color: inherit;
    font-family: ${fonts.PRIMARY_SEMIBOLD};
    font-size: 12px;
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE14LS};
    
    .MuiSelect-icon {
        fill: ${p => p.$iconColor};    
    }

    .MuiSelect-select {
        padding-right: 32px;
    }

    & > div {
        color: inherit;
    }
`
const StyledMenuitem = styled(MenuItem)`
    min-width: 142px;
    padding-top: 11px;
    padding-bottom: 11px;

    &.Mui-selected {
        color: ${colors.primary.DEFAULT_PRIMARY};
    }
`

type TProps = {
    iconColor?: string
}

const LanguagePicker = (props: TProps) => {
    const {language} = useAppSelector(state => state.ui)
    const dispatch = useAppDispatch()

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value = (event.target.value as TLanguages)
        dispatch(setLanguageAsync(value))
    }

    return (
        <StyledFormControl id='languageSelector' $iconColor={props.iconColor}>
            <CustomSelect
                value={language}
                onChange={handleChange}
                IconComponent={(componentProps) => (
                    <SvgIcon {...componentProps}>
                        <path d={Icons.ARROW_RIGHT} />
                    </SvgIcon>
                )}
                input={
                    <Input disableUnderline
                        startAdornment={
                            <InputAdornment position='start'>
                                <Icon
                                    d={Icons.WORLD}
                                    size={20}
                                />
                            </InputAdornment>
                        }
                    />
                }
                inputProps={{ 'aria-label': 'Without label' }}
            >
                {languages.map((lang, index) => <StyledMenuitem id={`selectionItem${lang.value}`} key={index} value={lang.value}>{lang.label}</StyledMenuitem>)}
            </CustomSelect>
        </StyledFormControl>
    )
}

export default LanguagePicker
