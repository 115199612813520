import axiosClient from 'api/axiosClient'
import { TEMailConfig } from 'types/businessLogic/misc'

class EMailService {
    private static _api = axiosClient()

    public static async getEMailConfig(): Promise<TEMailConfig> {
        const response = await this._api.get<TEMailConfig>('EMail')
        return response.data
    }

    public static async postEMailConfig(emailConfig: TEMailConfig): Promise<void> {
        await this._api.post('EMail', emailConfig)
    }

    public static async testEMailConfig(emailConfig: TEMailConfig): Promise<void> {
        await this._api.post('EMail/Test', emailConfig)
    }
}

export default EMailService