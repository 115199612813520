import CustomDatePicker from 'components/CustomControls/CustomDatePicker'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { TTranslationKey } from 'types/ui/translation'
import React from 'react'

interface DateTimePickerProps {
    label: TTranslationKey
    value?: Date
    onChange: (value: Date | null) => void
}

export const DateTimePicker = ({label, value, onChange} : DateTimePickerProps) => {
    return (
        <>
            <div className='label'>
                <TransMessage {...label} />
            </div>
            <Icon d={Icons.CALENDAR} title='calender-icon' />
            <CustomDatePicker value={value} onChange={onChange} disableFuture />
        </>
    )
}