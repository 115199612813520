import { TableCell, TableRow } from '@mui/material'
import Icon, { Icons } from 'components/Icon/Icon'
import CustomTablePagination from 'components/Tables/CustomTablePagination'
import { TPagination } from 'hooks/useEnhanceTable'
import { i18nKeys } from 'i18n/keys'
import React, { PropsWithChildren, useState } from 'react'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { useIntl } from 'react-intl'

const Root = styled(TableRow)`
    cursor: pointer;
    height: 64px;
`
const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    fill: ${colors.primary.DEFAULT_PRIMARY};
`
const Title = styled.span`
    color: ${colors.primary.DARK_PRIMARY};
    font-family: ${fonts.PRIMARY_REGULAR};
    font-size: ${typographyScale.TYPE14};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE14LS};
    > strong {
        font-family: ${fonts.PRIMARY_BOLD};
    }
`
const PaginationCell = styled(TableCell)`
    padding: 0 !important;
`

type TProps = {
    group: TTableGroup<TTableRowData>
    groupByIntlKey: string
    pagination: TPagination
    onChangePage: (page: number) => void
    onChangeRowsPerPage: (rowsPerPage: number) => void
    onChangedOpen?: (open: boolean) => void
}

const TableGroup = (props: PropsWithChildren<TProps>) => {
    const { group, children, pagination, groupByIntlKey, onChangedOpen } = props
    const {onChangeRowsPerPage, onChangePage} = props

    const [open, setOpen] = useState<boolean>(false)

    const intl = useIntl()

    const groupedByMessage = intl.formatMessage(i18nKeys[groupByIntlKey])

    let groupMessage
    if (group.translationKey && i18nKeys[group.translationKey]) {
        groupMessage = intl.formatMessage(i18nKeys[group.translationKey])
    } else if (group.name) {
        groupMessage = group.name
    } else {
        groupMessage = intl.formatMessage(i18nKeys.general_no) + ' ' + groupedByMessage
    }

    const handleOpen = () => {
        onChangedOpen && onChangedOpen(!open)
        setOpen(prevState => !prevState)
    }

    return (
        <>
            <Root onClick={handleOpen}>
                <TableCell colSpan={99999}>
                    <Content>
                        <Title>
                            <strong>{groupedByMessage}: </strong>{groupMessage}{` (${group.count})`}
                        </Title>
                        <Icon
                            rotate={open ? 180 : 0}
                            d={Icons.CHEVRON}
                        />
                    </Content>
                </TableCell>
            </Root>
            {open && children}
            {
                open &&
                <TableRow>
                    <PaginationCell colSpan={9999}>
                        <CustomTablePagination
                            count={group.count}
                            page={pagination.page}
                            rowsPerPage={pagination.size}
                            onChangeRowsPerPage={onChangeRowsPerPage}
                            onChangePage={onChangePage}
                        />
                    </PaginationCell>
                </TableRow>
            }
        </>
    )
}

export default TableGroup