import { SiteEntityService } from 'services/siteEntityService'
import { TAllCreateSiteGroupRequest, TCreateSiteGroupRequest, TJobRequest } from 'types/businessLogic/job'

class SiteGroupService extends SiteEntityService {
    constructor() {
        super('SiteGroups')
    }

    public async createSiteGroup(params: TCreateSiteGroupRequest) {
        await this._api.post(`${this.urlPrefix}`, params)
    }

    public async deleteSiteGroups(ids: number[]) {
        await this._api.delete(`${this.urlPrefix}`, {
            data: {
                Ids: ids
            }
        })
    }
    
    public async createAllSiteGroup(params: TAllCreateSiteGroupRequest) {
        await this._api.post(`${this.urlPrefix}/All`, params)
    }

    public async deleteAllSiteGroups(jobRequest: TJobRequest) {
        await this._api.delete(`${this.urlPrefix}/All`, {data: jobRequest})
    }
}

export const siteGroupService = new SiteGroupService()