import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGroup, TTableFetchState } from 'types/network'
import SiteGroups from './SiteGroups'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { TModalProps } from 'types/ui/modal'
import { siteGroupService } from 'services/siteGroupService'
import { clearGroups, deleteAllSiteGroupsAsync, deleteSiteGroupsAsync, getGroupDataAsync, getSiteGroupsAsync, getSiteGroupsPropertiesAsync } from 'state/slices/siteGroupSlice'
import SiteGroupsTablePreparer from 'views/Control/SiteGroups/siteGroupsTablePreparer'
import SiteGroupDetails from 'views/Control/SiteGroups/SiteGroupDetails/SiteGroupDetails'
import { tableInfo } from 'views/Control/SiteGroups/siteGroupsTableColumns'
import { TJobRequest } from 'types/businessLogic/job'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { pushItem } from 'state/slices/uploadManagerSlice'
import { useIntl } from 'react-intl'
import { useModal } from 'hooks/modalProvider'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const SiteGroupsContainer = () => {
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const {showModal} = useModal()
    const { siteGroups, totalNumber, groups, properties } = useAppSelector(state => state.siteGroup)

    useBreadCrumbs([i18nKeys.breadcrumb_site_groups])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams,
            translationKey: group.translationKey
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const requestParams = GeneralHelper.buildRequestFromTableState(params)
            dispatch(getSiteGroupsAsync(requestParams))
            dispatch(getSiteGroupsPropertiesAsync())
        } else if (params.groupName) {
            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, params.groupName)
            dispatch(getGroupDataAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await siteGroupService.getSiteEntityGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }

            getGroups()
        }
    }, [dispatch])

    const openSiteGroupDetailsModal = (id: number) => {
        const siteGroupDetails = siteGroups.find(siteGroup => siteGroup.id === id)

        if (!siteGroupDetails) return
                
        showModal({
            content: <SiteGroupDetails siteGroup={siteGroupDetails} />,
            title: siteGroupDetails.name,
            description: i18nKeys.site_group_details_properties_title
        })
    }

    const data = SiteGroupsTablePreparer.prepareSiteGroupRows(siteGroups, openSiteGroupDetailsModal)
    const preparedGroups = SiteGroupsTablePreparer.prepareGroups(groups, openSiteGroupDetailsModal)

    const deleteSiteGroups = (params: TModalProps) => {
        if (params.ids.length === 0 && params.filters) {
            const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_site_group_delete, {count: totalNumber})
            const uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)
            
            const request: TJobRequest = {
                filters: params.filters
            }
            
            dispatch(pushItem(uploadManagerItem))
            dispatch(deleteAllSiteGroupsAsync({request, uploadManagerItemId: uploadManagerItem.id}))
        } else {
            const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_site_group_delete, {count: (params.ids as number[]).length})
            const uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

            dispatch(pushItem(uploadManagerItem))
            dispatch(deleteSiteGroupsAsync({ids: params.ids as number[], uploadManagerItemId: uploadManagerItem.id}))
        }
    }

    return (
        <ViewContainer>
            <SiteGroups
                data={data}
                groups={preparedGroups}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                dynamicPropertiesColumns={properties}
                getTableData={getTableData}
                ondeleteSiteGroups={(params: TModalProps) => deleteSiteGroups(params)}
                getGroupData={getGroupData}
            />
        </ViewContainer>
    )
}

export default SiteGroupsContainer