import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormWrapper } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import styled from 'styled-components'

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.primary.DARK_PRIMARY};
`
const CustomFormWrapper = styled(FormWrapper)`
    width: 524px;
    height: 320px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.shades.PURE_WHITE};
    padding: 24px 72px 64px 56px;
    margin-top: 60px;
    border-radius: ${borderRadius.SECONDARY};
    border-left: 16px solid ${colors.primary.DEFAULT_PRIMARY};
`
const Title = styled.h1`
    margin-bottom: 20px;
`
const Description = styled.p`
    margin-bottom: 50px;
`

const NoConnection = () => (
    <CenterWrapper>
        <CustomFormWrapper>
            <Title>
                <TransMessage {...i18nKeys.login_no_connection_title} />
            </Title>
            <Description>
                <TransMessage {...i18nKeys.login_no_connection_description} />
            </Description>
            <CustomButton id='retryBtn' type='submit'>
                <TransMessage {...i18nKeys.login_no_connection_button} />
            </CustomButton>
        </CustomFormWrapper>
    </CenterWrapper>
)

export default NoConnection