import { i18nKeys } from 'i18n/keys'
import { TTranslationKey } from 'types/ui/translation'

export enum daysOfWeek {
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY,
    HOLIDAY
}

export const timeStartAndEndValuesMs = {
    MIN: 0,
    MAX: 86400000
}

// 15 minutes
export const sliderStepMs = 900000

export const sliderRangeChangeTypes = {
    START: 'START',
    STOP: 'STOP'
}

export const sliderDirections = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT'
}

/*
    This features will be discussed in future
 */
export const siteTimeProfileFeatureFlags = {
    // This one is not implemented at all
    allowMultipleDaysSelected: false,
    // Slider functionality is implemented
    useSliderOnlyForDisplaying: false,
    // Implemented but prone to bugs
    sliderAllowMovingRangeInMiddle: true
}

export const dayOrders = [
    daysOfWeek.MONDAY,
    daysOfWeek.TUESDAY,
    daysOfWeek.WEDNESDAY,
    daysOfWeek.THURSDAY,
    daysOfWeek.FRIDAY,
    daysOfWeek.SATURDAY,
    daysOfWeek.SUNDAY,
    daysOfWeek.HOLIDAY
]

export const defaultNewTimeRangeTimesDropdown = {
    start: 28800000, // 8:00h
    stop: 61200000 // 17:00h
}

export const defaultNewTimeRangeSizeSlider = sliderStepMs * 2 // 30 minutes

export const timeDropDownValues = new Array((timeStartAndEndValuesMs.MAX / sliderStepMs) + 1)
    .fill(null).map((_: null, index: number) => index * sliderStepMs)

export const timeProfileDaysEnum: { [key: number] : { translation: TTranslationKey, shortTranslation: TTranslationKey, value: number } } = {
    [daysOfWeek.MONDAY]: {
        translation: i18nKeys.site_time_profile_day_monday,
        shortTranslation: i18nKeys.site_time_profile_day_monday_short,
        value: daysOfWeek.MONDAY
    },
    [daysOfWeek.TUESDAY]: {
        translation: i18nKeys.site_time_profile_day_tuesday,
        shortTranslation: i18nKeys.site_time_profile_day_tuesday_short,
        value: daysOfWeek.TUESDAY
    },
    [daysOfWeek.WEDNESDAY]: {
        translation: i18nKeys.site_time_profile_day_wednesday,
        shortTranslation: i18nKeys.site_time_profile_day_wednesday_short,
        value: daysOfWeek.WEDNESDAY
    },
    [daysOfWeek.THURSDAY]: {
        translation: i18nKeys.site_time_profile_day_thursday,
        shortTranslation: i18nKeys.site_time_profile_day_thursday_short,
        value: daysOfWeek.THURSDAY
    },
    [daysOfWeek.FRIDAY]: {
        translation: i18nKeys.site_time_profile_day_friday,
        shortTranslation: i18nKeys.site_time_profile_day_friday_short,
        value: daysOfWeek.FRIDAY
    },
    [daysOfWeek.SATURDAY]: {
        translation: i18nKeys.site_time_profile_day_saturday,
        shortTranslation: i18nKeys.site_time_profile_day_saturday_short,
        value: daysOfWeek.SATURDAY
    },
    [daysOfWeek.SUNDAY]: {
        translation: i18nKeys.site_time_profile_day_sunday,
        shortTranslation: i18nKeys.site_time_profile_day_sunday_short,
        value: daysOfWeek.SUNDAY
    },
    [daysOfWeek.HOLIDAY]: {
        translation: i18nKeys.site_time_profile_day_bank_holiday,
        shortTranslation: i18nKeys.site_time_profile_day_bank_holiday_short,
        value: daysOfWeek.HOLIDAY
    }
}

export type TExtendedTimeProfileDay = {
    timeProfileDay: number
    timeRanges: TExtendedTimeRange[]
}

export type TExtendedTimeRange = TTimeRange & {
    skipInCalculation?: boolean
    skipInTableAndSliderRender?: boolean
    index?: number
}

export type TTimeRange = {
    start: number
    stop: number
}