import CircularLoader from 'components/CircularLoader/CircularLoader'
import { CustomCheckbox } from 'components/CustomControls/CustomCheckbox'
import { RecordingStateInfos } from 'components/PagedCardList/RecordingStateInfos'
import { VmsStateInfos } from 'components/PagedCardList/VmsStateInfos'
import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType, EPropertyType, ERecordingState, EVMSState } from 'enums/enums'
import DateHelper from 'helpers/DateHelper'
import useCameraState, { TUseCameraStateProps } from 'hooks/useCameraState'
import { i18nKeys } from 'i18n/keys'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { siteCameraService } from 'services/siteCameraService'
import { showMessage } from 'state/slices/uiSlice'
import { dispatch } from 'state/store'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'
import { TCameraPerformance } from 'types/businessLogic/cameraPerformance'
import { TSiteEntity } from 'types/businessLogic/site'
import { TTranslationKey } from 'types/ui/translation'

const Container = styled.div`
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 40px;
    overflow-y: auto;
    width: 100%;
    height: 100%;
`

const TitleContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: ${colors.extra.EXTRA_GRAY};
    font-family: ${fonts.PRIMARY_SEMIBOLD};
    font-size: ${typographyScale.TYPE16};
    line-height: ${lineHeights.TYPE12LineHeight};
    letter-spacing: ${letterSpacings.TYPE14LS};
`

const LeftContainer = styled.div`
    align-items: center;
    display: flex;
`

const RightContainer = styled.div`
    display: flex;
    align-items: center;
`

const Name = styled.h1`
    color: ${colors.primary.DARK_PRIMARY};
    font-family: ${fonts.PRIMARY_BOLD};
    font-size: ${typographyScale.TYPE24};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE16LS};
    border-right: 2px solid black;
    padding-right: 8px;
    margin-right: 8px;
`

const HeaderLine = styled.div`
    width: 100%;
    height: 1px;
    margin-bottom: 48px;
    padding: 0 25px 0 25px;
    background-color: #000000;
`

const InfoRow = styled.div<{ $highlight?: boolean, $color: string }>`
    width: 100%;
    height: 56px;
    padding-left: 16px;
    font-size: 14px;
    background-color: ${p => p.$highlight ? '#f0f0f0' : ''};
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.shades.GRAY_SHADE};
    margin-bottom: 16px;

    span:first-child {
        font-weight: bold;
        width: 170px;
        margin-right: 30px;
    }

    span:last-child {
        color: ${p => p.$color};
        font-weight: ${p => p.$highlight ? 'bold' : ''};
    }
`

const CustomH3 = styled.h3`
    margin-top: 52px;
`
const ColumnContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 24px;

    div : {
        border: 2px solid deeppink;
    }
`

type TProps = {
    id: number
    performanceMeasure: TCameraPerformance
}

const MonitoringCameraDetails = (props: TProps) => {
    const { id, performanceMeasure } = props

    const [entity, setEntity] = useState<TSiteEntity>()
    const [entityFetched, setEntityFetched] = useState<boolean>()

    useEffect(() => {
        const fetchEntity = async () => {
            try {
                const camera = await siteCameraService.getSiteCameraMonitoring(id)
                setEntity(camera)
            } catch {
                dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
            }
        }

        fetchEntity()
        setEntityFetched(true)
    }, [id])

    const intl = useIntl()

    const recordingStateInfo = RecordingStateInfos[performanceMeasure.recordingState]
    const vmsStateInfo = VmsStateInfos[performanceMeasure.vmsState]
    const vmsStateAndCode = performanceMeasure.vmsStateCode

    const vmsProps: TUseCameraStateProps = {
        disabled: performanceMeasure.recordingState === ERecordingState.DISABLED || performanceMeasure.vmsState === EVMSState.DISABLED,
        overallState: performanceMeasure.overallState,
        stateInfo: vmsStateInfo,
        tooltip: vmsStateAndCode
    }

    const recordingProps: TUseCameraStateProps = {
        disabled: performanceMeasure.recordingState === ERecordingState.DISABLED || performanceMeasure.vmsState === EVMSState.DISABLED,
        overallState: performanceMeasure.overallState,
        stateInfo: recordingStateInfo
    }

    const vmsState = useCameraState(vmsProps)
    const recordingState = useCameraState(recordingProps)

    if (!entity) return <CircularLoader />
    if (!entityFetched) {
        return (
            <TransMessage {...i18nKeys.change_properties_multi_no_common_properties} />
        )
    }

    const getColor = (text: string, translationKey: TTranslationKey) : string => {
        if (translationKey === i18nKeys.property_general_status) {
            switch (text) {
                case 'Disabled':
                case 'OK': {
                    return colors.extra.SUCCESS
                }
                default: return colors.extra.ALERT
            }
        }
        
        if (translationKey === i18nKeys.property_recording_statistics_recording_state) {
            switch (text) {
                case 'OK': {
                    return colors.extra.SUCCESS
                }
                case 'LIMITED': {
                    return colors.extra.WARNING
                }
                case 'ERROR': {
                    return colors.extra.ALERT
                }
            }
        }

        return ''
    }

    const renderRow = (translationKey: TTranslationKey, text: string, color: string, highlight?: boolean) => {
        const label = intl.formatMessage(translationKey)
        
        return (
            <InfoRow $highlight={highlight} $color={color}>
                <span>{label}:</span>
                <span>{text}</span>
            </InfoRow>
        )
    }

    const renderInfoRow = (translationKey: TTranslationKey, highlight?: boolean) => {
        const label = intl.formatMessage(translationKey)
        const item = entity.propertyValues.find(x => x.translationKey === translationKey.id)

        let propValue = '-'
        let color = ''

        if (item) {
            if (item?.propertyType === EPropertyType.STRING) {
                propValue = item.stringValue!
            } else if (item?.propertyType === EPropertyType.DATETIME) {
                propValue = DateHelper.formatDate(item.dateTimeValue!, DateHelper.formats.DATE_TIME_SECONDARY)
            }

            color = getColor(propValue, translationKey)
        }
        
        return (
            <InfoRow $highlight={highlight} $color={color}>
                <span>{label}:</span>
                <span>{propValue}</span>
            </InfoRow>
        )
    }

    const enabled = entity.propertyValues.find(x => x.translationKey === i18nKeys.property_general_enabled.id)?.boolValue

    return (
        <Container>
            <TitleContainer>
                <LeftContainer>
                    <Name>
                        {entity.name}
                    </Name>
                    {entity.siteName}
                </LeftContainer>
                <RightContainer>
                    {intl.formatMessage(i18nKeys.property_general_enabled)}
                    <CustomCheckbox
                        checked={enabled}
                        disabled={true}
                    />
                </RightContainer>
            </TitleContainer>
            
            <HeaderLine />
            
            {renderRow(i18nKeys.property_general_status, vmsState.toolTipValue ?? vmsState.value, vmsState.color, true)}
            {renderInfoRow(i18nKeys.property_device_manufacturer)}
            {renderInfoRow(i18nKeys.property_device_model)}
            {renderInfoRow(i18nKeys.property_device_host)}
            {renderInfoRow(i18nKeys.property_device_firmware)}
            {renderInfoRow(i18nKeys.property_recording_statistics_devicemanager)}

            <CustomH3> {intl.formatMessage(i18nKeys.camera_performance_recording_statistics)}: </CustomH3>

            {renderRow(i18nKeys.property_recording_statistics_recording_state, recordingState.value, recordingState.color, true)}
            <ColumnContainer>
                <div>
                    
                    {renderInfoRow(i18nKeys.property_recording_statistics_standard_max_timeperiod)}
                    {renderInfoRow(i18nKeys.property_recording_statistics_alarm_max_timeperiod)}
                    {renderInfoRow(i18nKeys.property_recording_statistics_timestamp)}
                </div>

                <div>
                    {renderInfoRow(i18nKeys.property_recording_statistics_standard_actual_timeperiod)}
                    {renderInfoRow(i18nKeys.property_recording_statistics_alarm_actual_timeperiod)}
                </div>
            </ColumnContainer>
            
        </Container>
    )
}

export default MonitoringCameraDetails
