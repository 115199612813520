import { TTranslationContainer } from '../types/ui/translation'

export const translations: TTranslationContainer = {
    en: {
        general_no: 'No',
        login_input_username_placeholder: 'Username',
        login_input_password_placeholder: 'Password',
        login_remember_me: 'Keep me signed in',
        login_button_submit: 'Login',
        login_button_windows_login: 'Login Using Windows Authentication',
        login_request_error: 'Failed to login',
        login_user_rights_were_changed: 'You were logged out because your rights changed',
        login_no_connection_title: 'No Connection',
        login_no_connection_description: 'It appears that there no connection could be established. Please try again ',
        login_no_connection_button: 'Retry',
        language_picker_selection_english: 'English',
        language_picker_selection_german: 'Deutsch',
        language_picker_selection_french: 'Français',
        sidenav_dashboard: 'Dashboard',
        sidenav_map: 'Map',
        sidenav_control_centre: 'Site Administration',
        sidenav_sites: 'Sites',
        sidenav_site_users: 'Users',
        sidenav_site_groups: 'Groups',
        sidenav_cameras: 'Cameras',
        sidenav_site_services: 'Services',
        sidenav_site_jobs: 'Jobs',
        sidenav_monitoring_centre: 'Monitoring',
        sidenav_umbrella_core: 'Umbrella Core',
        sidenav_administration: 'Administration',
        sidenav_administration_users: 'Umbrella Users',
        sidenav_administration_roles: 'Umbrella Roles',
        sidenav_manual: 'Manual',
        sidenav_administration_license: 'License',
        sidenav_site_license_update: 'Licenses',
        camera_firmware_core: 'Notifications',
        sidenav_site_events: 'Events',
        sidenav_email: 'E-Mail',
        sidenav_settings: 'Settings',
        sidenav_camera_firmwares: 'Camera Firmwares',
        sidenav_site_time_profile: 'Time Profiles',
        sidenav_report_center: 'Reports',
        sidenav_reports: 'Inventory Reports',
        sidenav_audit_reports: 'Audit Reports',
        sidenav_site_systems: 'Systems',
        sidenav_access_control_card_holders: 'Cardholders',
        sidenav_access_control_doors: 'Doors',
        sidenav_plugins: 'Plugins',
        sidenav_administration_labels: 'Labels',
        sidenav_administration_apiKeys: 'API Keys',
        profile_drawer_profile_information: 'Profile information:',
        profile_drawer_role: 'Roles:',
        profile_drawer_last_login: 'Last login:',
        profile_drawer_logout_button: 'Log Out',
        profile_drawer_change_password_button: 'Change Password',
        profile_drawer_upload_profile_image_error: 'Failed to upload a profile picture',
        profile_drawer_upload_profile_image_success: 'Profile picture successfully uploaded',
        profile_drawer_upload_profile_image_invalid_format: 'Please provide valid image (jpg or png)',
        profile_drawer_upload_profile_image_size_limit: 'Maximum image file size is 5MB',
        site_states_heading: 'Sites Health',
        site_states_total: 'Total',
        site_states_healthy: 'Healthy',
        site_states_warning: 'Warning',
        site_states_error: 'Critical',
        site_states_offline: 'Offline',
        site_states_ignored: 'Ignored',
        site_states_updating: 'Updating',
        site_states_vms_not_reachable: 'Connection issues',
        site_states_error_tooltip: '{countNotReachable} Not Reachable {br} {countOffline} Offline',
        site_states_healthy_tooltip: '{countHealthy} Healthy {br} {countUpdating} Updating',
        site_state_chart_tooltip: 'This diagram shows the ratio of the total number of sites to the sites with Healthy, Warning, Error status. ',
        site_approvals_heading: 'Site Approvals',
        site_approvals_total: 'Total',
        site_approvals_approved: 'Approved',
        site_approvals_waiting_for_approval: 'Waiting for approval',
        site_approvals_chart_tooltip: 'This diagram shows the ratio of the total number of sites to the sites that are not approved.',
        cameras_chart_total: 'Total',
        cameras_chart_heading: 'Cameras Health',
        cameras_chart_warning: 'Warning',
        cameras_chart_healthy: 'Healthy',
        cameras_chart_critical: 'Critical',
        cameras_chart_offline: 'Offline',
        cameras_chart_ignore: 'Ignored',
        cameras_chart_tooltip: 'This diagram shows the ratio of the total number of cameras to the number of cameras that are in a warning state.',
        umbrella_dashboard_site_systems: 'Systems Health',
        umbrella_dashboard_site_systems_healthy: 'Healthy',
        umbrella_dashboard_site_systems_warning: 'Warning',
        umbrella_dashboard_site_systems_critical: 'Critical',
        umbrella_dashboard_site_systems_offline: 'Offline',
        umbrella_dashboard_site_systems_ignore: 'Ignored',
        sites_with_location_heading: 'Locations',
        sites_with_location_view_map: 'View Map',
        sites_with_location_healthy: 'Healthy',
        sites_with_location_error: 'Critical',
        sites_with_location_warning: 'Warning',
        sites_with_location_offline: 'Offline',
        sites_with_location_ignore: 'Ignored',
        recent_site_jobs_heading: 'Recent Jobs',
        recent_site_jobs_view_jobs: 'View Jobs',
        recent_site_jobs_no_jobs: 'No jobs',
        recent_site_jobs_description_column_name: 'JOB DESCRIPTION',
        recent_site_jobs_state_column_name: 'STATE',
        recent_site_jobs_created_at_column_name: 'CREATED',
        site_list_approved: 'Approved',
        site_list_unapproved: 'Unapproved',
        site_list_approve_button: 'Approve',
        site_list_table_head_name: 'Name',
        site_list_table_head_location: 'Location',
        site_list_table_head_state: 'State',
        site_list_table_head_server_version: 'Server version',
        site_list_table_head_server_id: 'Server ID',
        site_list_table_head_labels: 'Labels',
        site_list_table_head_approved: 'Approved',
        site_list_table_head_type: 'Type',
        site_list_table_head_version: 'Version',
        site_list_table_head_client_version: 'Client version',
        site_list_table_head_last_heartbeat: 'Last heartbeat',
        site_list_table_head_ip: 'IP',
        site_list_table_head_host: 'Host',
        site_list_table_head_machine_code: 'Machine Code',
        site_list_sort_tooltip: 'Sort',
        site_list_group_tooltip: 'Group',
        site_list_approve_success: 'Sites successfully approved',
        site_list_approve_error: 'Failed to approve Sites',
        site_list_toolbar_selected_sites: '{count} selected',
        site_list_toolbar_delete_sites_tooltip: 'Delete sites',
        site_list_toolbar_delete_sites_success: 'Selected sites succesfully deleted',
        site_list_toolbar_delete_sites_error: 'Failed to delete sites',
        site_list_toolbar_create_user_tooltip: 'Add User',
        site_list_toolbar_create_card_holder_tooltip: 'Add Card Holder',
        site_list_toolbar_create_site_group_tooltip: 'Add Group',
        site_list_group_sitestate_healthy: 'Healthy',
        site_list_group_sitestate_offline: 'Offline',
        site_list_group_sitestate_warning: 'Warning',
        site_list_group_sitestate_updating: 'Updating',
        site_list_group_sitestate_noconnection: 'No Connection',
        site_list_group_sitestate_ignored: 'Ignored',
        site_list_connection_user_password: 'Change password for connection user',
        site_list_table_head_approve_multiple_sites: 'Approve Sites',
        site_list_table_head_create_report: 'Create Inventory Report',
        site_list_report_success: 'Successfully created report task',
        site_list_report_error: 'Failed to generate report task',
        site_list_table_head_create_audit_report: 'Create Audit Report',
        site_list_set_custom_comments_multiple_sites: 'Set custom comment(s) for site(s)',
        site_list_table_head_ignore_site: 'Ignore Site',
        site_list_restart_site: 'Restart Site Connection',
        site_list_restart_error_message: 'Failed to create jobs',
        site_list_restart_update_success_message: 'Job(s) successfully created',
        site_list_ignore_success_message: 'Successful',
        site_list_ignore_error_message: 'Failed',
        plugin_list_toolbar_delete_plugins_tooltip: 'Delete plugins',
        plugin_list_toolbar_delete_plugins_success: 'Selected plugins succesfully deleted',
        plugin_list_toolbar_delete_plugins_error: 'Failed to delete plugins',
        plugin_list_table_head_sitename: 'Site Name',
        plugin_list_table_head_port: 'Port',
        plugin_list_table_head_hostname: 'Host Name',
        plugin_list_table_head_ignoredMonitoring: 'Ignored',
        site_list_toolbar_threshold_configuration: 'Configure Monitoring Thresholds',
        site_list_toolbar_system_messages_configuration: 'System Messages Configuration',
        site_list_toolbar_edit_labels: 'Edit Labels',
        site_list_toolbar_notification_priority_tooltip: 'Edit Notification Prioriy',
        worst_performance_sites_title: 'Most critical Sites',
        worst_performance_sites_btn: 'View Sites',
        worst_performance_no_data: 'No critical sites',
        worst_performance_site_name_column: 'SITE NAME',
        worst_performance_cpu_usage_column: 'CPU USAGE',
        worst_performance_memory_usage_column: 'MEMORY USAGE',
        recent_site_events_column_description: 'DESCRIPTION',
        recent_site_events_column_site: 'SITE',
        recent_site_events_column_timestamp: 'DATE',
        umbrella_dashboard_running_site_services: 'Services Health',
        umbrella_dashboard_running_site_services_tooltip: 'Services Health',
        umbrella_dashboard_running_site_services_total: 'Total',
        umbrella_dashboard_running_site_services_critical: 'Critical',
        umbrella_dashboard_running_site_services_warning: 'Warning',
        umbrella_dashboard_running_site_services_healthy: 'Healthy',
        umbrella_dashboard_running_site_services_offline: 'Offline',
        umbrella_dashboard_running_site_services_ignored: 'Ignored',
        change_site_connection_user_modal_title: 'Change Password for Connection User',
        change_site_connection_user_modal_description: 'You are about to change the password of the user for the Site connection.',
        change_site_connection_user_modal_new_password_input_label: 'New Password:',
        change_site_connection_user_modal_submit_button: 'Create \'Change password\' job(s)',
        change_site_connection_user_modal_form_description: 'Changing password will not be done immediately. First you need to create a job in order to change user password.',
        change_site_connection_user_job_created_success: 'Created Change Connection User password job(s)',
        change_site_connection_user_job_created_error: 'Error in creating Change Connection User password job(s)',
        change_site_user_name_modal_title: 'Change Username',
        change_site_user_modal_description: 'Change Username for selected Users',
        change_site_user_modal_username_input_label: 'New Username:',
        change_site_user_modal_submit_button: 'Create \'Change Username\' job(s)',
        change_site_conneciton_user_job_created_success: 'Created \'Change Username\' job(s)',
        change_site_conneciton_user_job_created_error: 'Error in creating \'Change Username\' job(s)',
        copy_site_user_modal_title: 'Copy User',
        copy_site_user_modal_description: 'Copy Users for selected Sites. Please add a new username and password for the new User',
        copy_site_user_modal_username_input_label: 'New Username:',
        copy_site_user_modal_password_input_label: 'New Password:',
        copy_site_user_modal_submit_button: 'Create \'Copy User\' job(s)',
        site_users_list_copy_tooltip: 'Copy User',
        site_users_list_copy_invalid_tooltip: 'You can only copy one User per Site at once',
        copy_site_conneciton_user_job_created_success: 'Created \'Copy User\' job(s)',
        copy_site_conneciton_user_job_created_error: 'Error in creating \'Copy User\' job(s)',
        site_users_list_table_head_site_user: 'User',
        site_users_list_table_head_site: 'Site',
        site_users_list_table_head_user_labels: 'User Labels',
        site_users_list_table_head_site_labels: 'Site Labels',
        site_users_list_toolbar_selected_users: '{count} selected',
        site_users_list_toolbar_delete_site_users_tooltip: 'Delete users',
        site_users_list_toolbar_delete_site_users_success: 'Jobs for deleting users successfully created',
        site_users_list_toolbar_delete_site_users_error: 'Failed to create jobs to delete users',
        site_users_list_toolbar_change_password_tooltip: 'Change password',
        site_users_list_toolbar_change_properties_tooltip: 'Change properties of selected users',
        site_users_list_add_site_user_button: 'Add User',
        site_users_list_change_name_tooltip: 'Change Username',
        site_users_list_change_name_invalid_tooltip: 'You can only change one User\'s name per Site at once',
        deploy_site_title: 'Deploy a Site',
        deploy_site_floating_button_tooltip: 'Deploy a Site',
        deploy_site_description: 'To deploy a new Site fill out this form and download the deploy zip package. The package includes an Site Installer and all necessary configuration files. Follow the instructions on the Site Installer Console Window.',
        deploy_site_required_parameters_label: 'Required parameters:',
        deploy_site_optional_site_parameters_label: 'Optional site parameters:',
        deploy_site_vms_parameters_label: 'Site connection parameters:',
        deploy_site_input_core_host_label: 'Core Host:*',
        deploy_site_input_core_port_label: 'Core Port:*',
        deploy_site_input_https_label: 'HTTPS:',
        deploy_site_input_as_a_service_label: 'Windows service:',
        deploy_site_input_service_name_label: 'Windows service name:*',
        deploy_site_input_working_dir_label: 'Working directory:*',
        deploy_site_input_working_dir_tooltip: 'Service path should be an absolute path and the folder should not exist yet, but will be created by the Gateway installer.',
        deploy_site_input_plugin_type_label: 'Plugin type:*',
        deploy_site_input_name_label: 'Name:',
        deploy_site_input_location_label: 'Location:',
        deploy_site_input_labels_label: 'Labels:',
        deploy_site_input_vms_host_label: 'Host:*',
        deploy_site_input_vms_port_label: 'Port:*',
        deploy_site_input_vms_user_name_label: 'Username:*',
        deploy_site_input_vms_password_label: 'Password:*',
        deploy_site_download_installer_button: 'Download Installer',
        deploy_site_instructions: 'Execute the Umbrella.Gateway.Installer.exe to deploy the Site',
        deploy_site_input_core_host_placeholder: 'Core Host',
        deploy_site_input_core_port_placeholder: 'Core Port',
        deploy_site_input_service_name_placeholder: 'Windows service name',
        deploy_site_input_working_dir_placeholder: 'Working directory',
        deploy_site_input_plugin_type_placeholder: 'Plugin Type',
        deploy_site_input_name_placeholder: 'Name',
        deploy_site_input_location_placeholder: 'Location',
        deploy_site_input_labels_placeholder: 'Labels',
        deploy_site_input_vms_host_placeholder: 'Host',
        deploy_site_input_vms_port_placeholder: 'Port',
        deploy_site_input_vms_user_name_placeholder: 'Username',
        deploy_site_input_vms_password_placeholder: 'Password',
        deploy_site_input_validation_port: 'Invalid port number (1 - 65535)',
        deploy_site_input_validation_name: 'Invalid site name',
        deploy_site_input_validation_location: 'Invalid site location',
        deploy_site_input_validation_working_dir: 'Invalid working directory',
        deploy_site_input_validation_service_name: 'Invalid service name (only letters, numbers and underscore are allowed)',
        deploy_site_input_validation_host: 'Invalid host name',
        deploy_site_success_message: 'Successfully downloaded deployment package',
        deploy_site_error_message: 'Failed to create deployment package',
        deploy_site_input_branchless: 'Branchless',
        deploy_site_input_branchless_tooltip: 'By selecting the branchless mode you only need to install one Umbrella Gateway to the Site installation main core and you will manage the whole Site installation and all its branches. But the Site host must be the one of the Site installation main core.',
        create_user_title_preselected_sites: 'Add new user to selected sites',
        create_user_title: 'Add new user',
        create_user_description: 'Add new user to selected sites',
        create_user_input_sites_label: 'Sites:',
        create_user_input_sites_placeholder: 'Select Sites...',
        create_user_input_username_label: 'Username:',
        create_user_sites_jobs_button: 'Create \'Add user\' job(s)',
        create_user_instructions: 'Adding users will not be done immediately. Jobs will be created that will be transmitted to the sites and processed',
        create_user_input_password_label: 'Password:',
        create_user_success_message: 'Job(s) successfully created',
        create_user_error_message: 'Failed to create jobs',
        create_user_input_email_not_matching_regex: 'Not a valid E-Mail address',
        create_user_input_email_placeholder: 'E-Mail',
        create_user_input_email_label: 'E-Mail',
        change_password_modal_title: 'Change Password ',
        change_password_modal_description: 'Change Password for the selected users',
        change_password_modal_input_password_label: 'Password:',
        change_second_password_modal_input_password_label: 'Second Password (optional):',
        change_password_modal_button: 'Create \'Change password\' job(s)',
        change_password_modal_success_message: 'Job(s) successfully created',
        change_password_modal_error_message: 'Failed to create jobs for changing passwords',
        change_password_ignore_second_password: 'Ignore second password:',
        change_camera_password_modal_title: 'Change Camera Password',
        change_camera_password_modal_description: 'Change Password for the selected cameras',
        change_camera_password_modal_input_password_label: 'Password:',
        change_camera_password_modal_button: 'Create \'Change camera password\' job(s)',
        change_camera_password_modal_success_message: 'Job(s) successfully created',
        change_camera_password_modal_error_message: 'Failed to create jobs for changing camera passwords',
        create_card_holder_title_preselected_sites: 'Add new card holder to selected sites',
        create_card_holder_title: 'Add new card holder',
        create_card_holder_description: 'Add new card holder to selected sites',
        create_card_holder_input_sites_label: 'Sites:',
        create_card_holder_input_sites_placeholder: 'Select Sites...',
        create_card_holder_input_firstname_label: 'First name:',
        create_card_holder_input_lastname_label: 'Last name:',
        create_card_holder_input_cardnumber_label: 'Card number:',
        create_card_holder_input_start_date_label: 'Start date:',
        create_card_holder_input_end_date_label: 'End date:',
        create_card_holder_sites_jobs_button: 'Create \'Add card holder\' job(s)',
        create_card_holder_instructions: 'Adding card holders will not be done immediately. Jobs will be created that will be transmitted to the sites and processed',
        create_card_holder_success_message: 'Job(s) successfully created',
        create_card_holder_error_message: 'Failed to create jobs',
        system_messages_configuration_modal_title: 'System Messages Configuration',
        system_messages_configuration_modal_description: 'Change configuration for System Messages ',
        system_messages_configuration_input_jobs_button: 'Create Job(s)',
        siteServices_table_head_siteService_name: 'Service Name',
        siteServices_table_head_site_name: 'Site Name',
        siteServices_table_head_labels: 'Labels',
        siteServices_table_head_site_labels: 'Site Labels',
        siteServices_table_toolbar_selected_siteServices: '{count} selected',
        siteServices_table_toolbar_change_properties_tooltip: 'Change properties of selected services',
        site_services_table_toolbar_restart_service: 'Restart Service',
        site_services_table_restart_service_job_created_success: 'Created restart Service job',
        site_services_table_restart_service_job_created_error: 'Error in creating restart Service job',
        site_services_restart_tooltip: 'Restart Services',
        site_service_performance_tooltip_start: 'Start Services',
        site_service_performance_tooltip_stop: 'Stop Services',
        cameras_table_head_camera_name: 'Camera Name',
        cameras_table_head_site_name: 'Site Name',
        cameras_table_head_labels: 'Labels',
        cameras_table_head_site_labels: 'Site Labels',
        cameras_table_toolbar_selected_cameras: '{count} selected',
        cameras_table_toolbar_change_properties_tooltip: 'Change properties of selected cameras',
        cameras_table_toolbar_change_password_tooltip: 'Change password of selected cameras',
        camera_table_toolbar_apply_firmware_tooltip: 'Firmware Update',
        apply_firmware_modal_step_one: 'Select Firmware',
        apply_firmware_modal_step_two: 'Confirm Update',
        apply_firmware_modal_step_one_title: 'Choose New Firmware',
        apply_firmware_modal_step_one_description: 'Please make sure that the cameras you selected support this firmware!',
        apply_firmware_modal_next_button: 'Next',
        apply_firmware_modal_step_two_title: 'Firmware Update',
        apply_firmware_modal_step_two_description: 'Listed cameras will be updated to {filename}.',
        apply_firmware_modal_create_jobs: 'Create Job(s)',
        apply_firmware_modal_cancel: 'Cancel',
        apply_firmware_create_job_success: 'Succesfully created job(s)',
        apply_firmware_create_job_error: 'Error in creating jobs',
        general_validation_required_field: 'This field is mandatory',
        general_validation_invalid_field: 'Invalid field value',
        site_plugin_type_fake: 'Test Plugin',
        site_plugin_type_cayugar10: 'Cayuga R10 Plugin',
        site_plugin_type_cayugar11: 'Cayuga R11 Plugin',
        site_plugin_type_cayugar12: 'Cayuga R12 Plugin',
        site_plugin_type_cayugar13: 'Cayuga R13 Plugin',
        site_plugin_type_cayugar14: 'Cayuga R14 Plugin',
        site_plugin_type_cayugar15: 'Cayuga R15 Plugin',
        site_plugin_type_cayugar16: 'Cayuga R16 Plugin',
        site_plugin_type_cayugar17: 'Cayuga R17 Plugin',
        site_plugin_type_situator: 'Qognify Situator Plugin',
        site_plugin_type_qognifyvms71: 'Qognify VMS 7.1 Plugin',
        plugin_type_monitoring: 'Monitoring',
        plugin_type_vms: 'Site installation',
        multiselect_create_label: 'Create "{value}"',
        dropdown_selection_none: 'None',
        dropdown_selection_true: 'Yes',
        dropdown_selection_false: 'No',
        toolbar_auto_refresh: 'Pause Live Updates',
        toolbar_auto_refresh_play: 'Start Live Updates',
        table_pagination_rows_per_page: 'Rows per page:',
        table_pagination_displayed_rows: '{from}-{to} of {count}',
        site_details_title: 'Below you can change the Site parameters according to your preferences.',
        site_details_label_placeholder: 'Select labels...',
        site_details_username_label: 'Name:',
        site_details_label_label: 'Label:',
        site_details_location_label: 'Location:',
        site_details_ip_label: 'IP:',
        site_details_host_label: 'Host:',
        site_details_type_label: 'Type:',
        site_details_state_label: 'State:',
        site_details_last_heartbeat_label: 'Last Heartbeat:',
        site_details_vms_server_version_label: 'Server Version:',
        site_details_vms_client_version_label: 'Client Version:',
        site_details_version_label: 'Version:',
        site_details_vms_server_id_label: 'Server Id:',
        site_details_machine_code_label: 'Machine Code:',
        site_details_update_button: 'Update',
        site_details_reset_all_button: 'Reset all',
        site_details_site_update_success_message: 'Site successfully updated',
        site_details_site_update_error_message: 'Failed to update site',
        site_details_fetch_site_details_error: 'Failed to get site details',
        site_details_name_error_message: 'Enter valid site name',
        site_details_location_error_message: 'Invalid location',
        site_details_tab_details: 'Details',
        site_details_tab_comments: 'Comments',
        site_details_tab_issues: 'Issues',
        site_details_issues_title: 'Below you can take a look at the issues of this Site',
        site_details_issues_start_tooltip: 'Start',
        site_details_issues_stop_tooltip: 'Stop',
        site_details_issues_restart_tooltip: 'Restart',
        site_details_issues_ignore_tooltip: 'Ignore',
        site_details_issues_cameras: 'Cameras',
        site_details_issues_systems: 'Systems',
        site_details_issues_services: 'Services',
        site_details_comment_update_success_message: 'Comment successfully updated',
        site_details_comment_update_error_message: 'Failed to update comment',
        site_details_comment_title: 'Title',
        site_details_comment_description: 'Description',
        site_details_comment_update_comment: 'Update Comment',
        site_details_comment_add_comment: 'Add Comment',
        site_details_comment_update_comments: 'Update',
        site_details_comment_max_comments_reached: 'Maximum reached',
        site_details_comment_reset_comments: 'Reset all',
        site_details_comments_title: 'Below you can change or add comments to the Site',
        site_details_comment_remove_comment: 'Remove Comment',
        site_details_comment_clear_comment: 'Clear',
        site_details_comment_list_comments_group: 'Comments',
        property_general_enabled: 'Enabled',
        property_general_status: 'Status',
        property_general_statuscode: 'Status code',
        property_general_statustimestamp: 'Status timestamp',
        property_device_model: 'Model',
        property_device_manufacturer: 'Manufacturer',
        property_device_host: 'Host',
        property_device_firmware: 'Firmware',
        property_videostream_fps_01: 'Stream_Framerate.01',
        property_videostream_fps_02: 'Stream_Framerate.02',
        property_videostream_fps_03: 'Stream_Framerate.03',
        property_videostream_fps_04: 'Stream_Framerate.04',
        property_videostream_fps_05: 'Stream_Framerate.05',
        property_videostream_fps_06: 'Stream_Framerate.06',
        property_videostream_resolution_01: 'Stream_Resolution.01',
        property_videostream_resolution_02: 'Stream_Resolution.02',
        property_videostream_resolution_03: 'Stream_Resolution.03',
        property_videostream_resolution_04: 'Stream_Resolution.04',
        property_videostream_resolution_05: 'Stream_Resolution.05',
        property_videostream_resolution_06: 'Stream_Resolution.06',
        property_videostream_videocodec_01: 'Stream_VideoCodec.01',
        property_videostream_videocodec_02: 'Stream_VideoCodec.02',
        property_videostream_videocodec_03: 'Stream_VideoCodec.03',
        property_videostream_videocodec_04: 'Stream_VideoCodec.04',
        property_videostream_videocodec_05: 'Stream_VideoCodec.05',
        property_videostream_videocodec_06: 'Stream_VideoCodec.06',
        property_groups_users: 'Users',
        property_rights_activate_active_directory_support: 'Activate Active Directory support',
        property_rights_user_must_use_secure_password: 'User must use a secure password',
        property_rights_user_may_change_own_password: 'User may change own password',
        property_rights_use_secure_password_for_exports: 'Use secure password for exports',
        property_rights_comment_necessary_for_changing_to_archive_mode: 'Comment necessary for changing to archive mode',
        property_rights_user_must_change_password_regularly: 'User must change password regularly (days)',
        property_rights_limit_archive_access: 'Limit archive access (minutes)',
        property_rights_can_manually_record: 'Show Recording Button',
        property_global_rights_camera_live: 'View Live Video',
        property_global_rights_camera_recorded: 'View Recorded Video',
        property_global_rights_camera_recorded_delete: 'Delete recordings',
        property_global_rights_camera_write_protection: 'Overwrite protection',
        property_global_rights_camera_ptz: 'Camera PTZ',
        property_global_rights_camera_ptz_priority: 'Camera lock',
        property_global_rights_camera_preset: 'Use Camera Presets',
        property_global_rights_camera_preset_create: 'Create camera presets',
        property_global_rights_camera_export_seetec: 'Export camera',
        property_global_rights_camera_export_avi: 'Export camera (AVI)',
        property_global_rights_user_privacy_zones_right: 'Privacy masking',
        property_global_rights_mpeg_audio: 'MPEG audio',
        property_global_rights_reference_image_create: 'Create reference image',
        property_global_rights_intrusion_detection: 'Intrusion detection',
        property_global_rights_map_use: 'Map',
        property_global_rights_layer_use: 'Layer',
        property_global_rights_button_use: 'Button',
        property_global_rights_report_mode: 'Report Mode',
        property_global_rights_report_mode_templates: 'Edit report templates',
        property_global_rights_counting_data_interpretation: 'Count analysis',
        property_global_rights_lp_group_use: 'Use license plate group',
        property_global_rights_lp_group_change: 'Change license plate group',
        property_global_rights_event_interface: 'Use event interface',
        property_global_rights_access_control_use_data_editor: 'Access control data editor',
        property_global_rights_use_event_interface_commands: 'Use event interface commands',
        property_events_sourceid: 'Source ID',
        property_events_causeid: 'Cause ID',
        property_events_sourcename: 'Source',
        property_events_causename: 'Cause',
        property_events_value: 'Value',
        property_events_related_positive_event: 'Related Resolve Event',
        property_events_affected_negative_events: 'Related Issue Events',
        property_events_description: 'Description',
        property_recording_standard_recording_enabled: 'Standard Recording Enabled',
        property_recording_standard_recording_period: 'Standard Recording Period',
        property_recording_standard_recording_timelimit: 'Standard Recording Time Limit (minutes)',
        property_recording_alarm_recording_enabled: 'Alarm Recording Enabled',
        property_recording_alarm_recording_timelimit: 'Alarm Recording Time Limit (minutes)',
        property_recording_alarm_recording_stream: 'Alarm Recording Stream',
        property_recording_alarm_recording_prebuffer: 'Alarm Recording Pre-Alarm Buffer (seconds)',
        property_recording_alarm_recording_postduration: 'Alarm Recording Post Duration (seconds)',
        property_recording_statistics_treshold_enabled: 'Enable tresholds',
        property_recording_statistics_maximum_packetloss: 'Maximum packet loss (%)',
        property_recording_statistics_standard_recording_framerate_delta: 'Standard Recording Frame rate delta (fps)',
        property_recording_statistics_standard_recording_maximum_frames_dropped: 'Standard Recording Max. frames dropped',
        property_recording_statistics_alarm_recording_framerate_delta: 'Alarm Recording Frame rate delta (fps)',
        property_recording_statistics_alarm_recording_maximum_frames_dropped: 'Alarm Recording Max. frames dropped',
        property_recording_statistics_notifications: 'Enable MDB Statistics Notifications',
        property_recording_statistics_recording_state: 'Status of Recording',
        property_recording_statistics_standard_max_timeperiod: 'Standard Recording Max. time period',
        property_recording_statistics_alarm_max_timeperiod: 'Alarm Recording Max. time period',
        property_recording_statistics_standard_actual_timeperiod: 'Standard Recording Actual time period',
        property_recording_statistics_alarm_actual_timeperiod: 'Alarm Recording Actual time period',
        property_recording_statistics_standard_oldest_frametime: 'Standard Recording Oldest frame time',
        property_recording_statistics_alarm_oldest_frametime: 'Alarm Recording Oldest frame time',
        property_recording_statistics_timestamp: 'Recording Statistics Timestamp',
        property_recording_statistics_devicemanager: 'Device Manager',
        property_runtime_runslocal: 'Runs Locally',
        property_runtime_memoryUsage: 'Memory Usage (MB)',
        property_runtime_diskUsage: 'Disk Usage (GB)',
        property_runtime_cpuUsage: 'CPU Usage (%)',
        property_timeprofile_profile: 'Time Profile',
        property_device_firmware_upgrade: 'Firmware Update Support',
        property_device_password_modify: 'Change Camera Password Support',
        property_device_description: 'Description',
        property_device_videoserver_id: 'Video Server Id',
        property_device_videoserver_description: 'Video Server Description',
        property_administrative_rights_video: 'Cameras',
        property_administrative_rights_other_hardware: 'Other Hardware',
        property_administrative_event_interfaces: 'Event Interfaces',
        property_administrative_user: 'User',
        property_administrative_time_pattern: 'Time management',
        property_administrative_company_calendar: 'Company calendars',
        property_administrative_rights_alarm: 'Alarms',
        property_administrative_rights_layer: 'Layers',
        property_administrative_rights_map: 'Maps',
        property_administrative_rights_button: 'Buttons',
        property_administrative_rights_patrol: 'Patrols',
        property_administrative_rights_sequence: 'Sequences',
        property_administrative_rights_videowall: 'Video walls',
        property_administrative_rights_LPR: 'License plate groups',
        property_administrative_rights_server: 'Server',
        property_administrative_rights_system: 'System',
        property_administrative_rights_website: 'Web pages',
        property_administrative_access_control: 'Access Control',
        property_runtime_host: 'Host',
        property_runtime_serviceName: 'Service Name',
        property_cardholder_base_card_number: 'Base Card Number',
        property_cardholder_employee_number: 'Employee Number',
        property_door_state: 'Status',
        site_user_details_tab_details: 'Details',
        site_user_details_title: 'Below you can change the user parameters according to your preferences.',
        site_user_details_user_name: 'Username:',
        site_user_details_site: 'Site:',
        site_user_details_label: 'Label:',
        site_user_details_update_button: 'Update Details',
        site_user_details_user_update_success_message: 'User successfully updated',
        site_user_details_user_update_error_message: 'Failed to update user',
        site_user_details_user_properties_update_success_message: 'Job(s) successfully created',
        site_user_details_user_properties_update_error_message: 'Failed to create jobs',
        site_user_details_properties_title: 'Below you can change the user properties according to your preferences.',
        site_user_details_administrative_properties_title: 'Only administrative rights from the branch where the entity is located at are considered here.',
        siteService_details_tab_details: 'Details',
        siteService_details_title: 'Below you can change the service parameters according to your preferences.',
        siteService_details_properties_title: 'Below you can change the service properties according to your preferences.',
        siteService_details_siteService_name: 'Name:',
        siteService_details_siteService_site: 'Site:',
        siteService_details_siteService_label: 'Label:',
        siteService_details_update_button: 'Update Details',
        siteService_details_update_success_message: 'Service successfully updated',
        siteService_details_update_error_message: 'Failed to update service',
        siteService_details_properties_update_error_message: 'Failed to create jobs',
        siteService_details_properties_update_success_message: 'Job(s) successfully created',
        camera_details_tab_details: 'Details',
        camera_details_title: 'Below you can change the camera parameters according to your preferences.',
        camera_details_properties_title: 'Below you can change the camera properties according to your preferences.',
        camera_details_camera_name: 'Name:',
        camera_details_camera_site: 'Site:',
        camera_details_camera_label: 'Label:',
        camera_details_update_button: 'Update Details',
        camera_details_update_success_message: 'Camera successfully updated',
        camera_details_update_error_message: 'Failed to update camera',
        camera_details_properties_update_error_message: 'Failed to create jobs',
        camera_details_properties_update_success_message: 'Job(s) successfully created',
        properties_form_button_reset_all: 'Reset all',
        properties_form_button_create_jobs: 'Create \'Change Property\' Job(s)',
        properties_form_note: 'Selected changes will not be done immediately. They will be applied when the created jobs are processed. ',
        properties_form_button_reset: 'Reset',
        site_jobs_status_done: 'Successful',
        site_jobs_status_failed: 'Failed',
        site_jobs_status_to_do: 'To Do',
        site_jobs_status_transmitted: 'Transmitted',
        site_jobs_status_canceled: 'Canceled',
        site_jobs_table_head_name: 'Name',
        site_jobs_table_head_state: 'State',
        site_jobs_table_head_createdBy: 'Created By',
        site_jobs_table_head_created: 'Created',
        site_jobs_table_head_updated: 'Updated',
        site_jobs_table_head_sites: 'Sites',
        site_jobs_table_head_about: 'About',
        site_jobs_table_head_message: 'Message',
        site_jobs_table_head_comment: 'Comment',
        site_jobs_table_group_jobstate_todo: 'To Do',
        site_jobs_table_group_jobstate_transmitted: 'Transmitted',
        site_jobs_table_group_jobstate_canceled: 'Canceled',
        site_jobs_table_group_jobstate_done: 'Successful',
        site_jobs_table_group_jobstate_failed: 'Failed',
        site_jobs_table_toolbar_selected_count: '{count} selected',
        site_jobs_table_toolbar_retry_job: 'Retry selected jobs',
        site_jobs_table_toolbar_cancel_job: 'Cancel selected jobs',
        site_jobs_table_cancel_jobs_success: 'Succesfully canceled selected jobs',
        site_jobs_table_cancel_jobs_error: 'Error in canceleling selected jobs',
        site_jobs_table_retry_jobs_succes: 'Succesfully in retrying  jobs',
        site_jobs_table_retry_jobs_error: 'Error in retrying  jobs',
        site_job_details_description: 'Description:',
        site_job_details_comment: 'Comment:',
        site_job_details_type: 'Type:',
        site_job_details_site: 'Site:',
        site_job_details_createdBy: 'Created By:',
        site_job_details_created: 'Created:',
        site_job_details_transmitted: 'Transmitted:',
        site_job_details_canceled: 'Canceled:',
        site_job_details_failed: 'Failed:',
        site_job_details_message: 'Message:',
        site_job_details_card_created: 'Created',
        site_job_details_card_status: 'Status',
        site_job_details_card_duration: 'Duration: {duration}',
        batch_job_details_title: 'Batch Job',
        batch_job_details_chart_legend_failed: 'Failed ({value})',
        batch_job_details_chart_legend_done: 'Successful ({value})',
        batch_job_details_chart_legend_todo: 'To Do ({value})',
        batch_job_details_chart_legend_transmitted: 'Transmitted ({value})',
        batch_job_details_chart_legend_canceled: 'Canceled ({value})',
        batch_job_details_group_jobstate_todo: 'To Do',
        batch_job_details_group_jobstate_transmitted: 'Transmitted',
        batch_job_details_group_jobstate_canceled: 'Canceled',
        batch_job_details_group_jobstate_done: 'Successful',
        batch_job_details_group_jobstate_failed: 'Failed',
        job_configuration_comment: 'Comment:',
        job_configuration_comment_title: 'Job Comment:',
        create_umbrella_user_title: 'Add New Umbrella User',
        create_umbrella_user_subtitle: 'Add a new Umbrella user and select roles that user should belong to.',
        create_umbrella_user_submit_button: 'Add Umbrella User',
        create_umbrella_user_success_message: 'Umbrella user successfully added',
        create_umbrella_user_error_message: 'Failed to add a new Umbrella user',
        create_umbrella_user_error_unique_username: 'Username already exists!',
        umbrella_user_form_label_username: 'Username:',
        umbrella_user_form_label_password: 'Password:',
        umbrella_user_form_label_roles: 'Roles:',
        umbrella_user_form_placeholder_roles: 'Select Roles',
        umbrella_users_table_head_username: 'Username',
        umbrella_users_table_head_built_in: 'Built In',
        umbrella_users_table_head_active_directory: 'Active Directory',
        umbrella_users_table_head_roles: 'Roles',
        umbrella_users_table_head_last_login: 'Last Login',
        umbrella_users_add_user_button: 'Add User',
        umbrella_users_list_toolbar_delete_users_tooltip: 'Delete Users',
        umbrella_users_list_toolbar_selected_users: '{count} selected',
        umbrella_users_list_delete_users_success: 'User/s successfully deleted',
        umbrella_users_list_delete_users_error: 'Failed to remove users',
        site_groups_table_head_site_group: 'Group',
        site_groups_table_head_sites: 'Sites',
        site_groups_table_head_labels: 'Labels',
        site_groups_table_head_site_labels: 'Site Labels',
        site_groups_toolbar_delete_site_group_tooltip: 'Delete Site groups',
        site_groups_toolbar_selected_site_groups: '{count} selected',
        site_groups_toolbar_change_properties_tooltip: 'Change properties of selected groups',
        site_groups_add_site_group_button: 'Add group',
        site_groups_list_toolbar_delete_site_groups_success: 'Jobs for deleting groups successfully created',
        site_groups_list_toolbar_delete_site_groups_error: 'Failed to create jobs to delete groups users',
        umbrella_roles_table_head_name: 'Name',
        umbrella_roles_table_head_umbrella_admin: 'Umbrella Admin',
        umbrella_roles_table_head_site_admin: 'Site Admin',
        umbrella_roles_table_head_built_in: 'Built In',
        umbrella_roles_table_head_users: 'Users',
        umbrella_roles_table_head_sites: 'Sites',
        umbrella_roles_table_head_active_directory_group: 'Active Directory Group',
        umbrella_roles_table_head_entity_type: 'Entity type',
        umbrella_roles_table_head_event_category: 'Event category',
        umbrella_roles_table_head_event_type: 'Event type',
        umbrella_roles_table_head_event_filter: 'Filter',
        umbrella_roles_table_head_event_email: 'E-Mail',
        umbrella_roles_table_head_contains_filter: 'Contains filter',
        umbrella_roles_table_head_send_email: 'E-Mail',
        umbrella_roles_table_head_site_labels: 'Site Labels',
        umbrella_roles_list_toolbar_selected_roles: '{count} selected',
        umbrella_roles_list_toolbar_delete_roles_tooltip: 'Delete Umbrella Role',
        umbrella_roles_list_button_add_role_tooltip: 'Add Umbrella Role',
        umbrella_roles_list_delete_roles_success: 'Role/s successfully deleted',
        umbrella_roles_list_delete_roles_error: 'Failed to remove roles',
        create_site_group_title: 'Add New Group',
        create_site_group_subtitle: 'Add a new group with the specified name for the selected Sites.',
        create_site_group_input_site_group: 'Group:',
        create_site_group_input_sites: 'Sites:',
        create_site_group_input_sites_placeholder: 'Select sites',
        create_site_group_submit_button: 'Create \'Add Group\' job(s)',
        create_site_group_instructions: 'Adding group will not be done immediately. A job will be created and executed as soon as possible.',
        create_site_groups_success_message: 'Job(s) successfully created',
        create_site_groups_error_message: 'Failed to create jobs',
        create_umbrella_role_title: 'Create New Umbrella Role',
        create_umbrella_role_subtitle: 'Create a new role with the desired parameters.',
        create_umbrella_role_submit_button: 'Create Umbrella Role',
        create_umbrella_role_success_message: 'Role successfully created',
        create_umbrella_role_error_message: 'Failed to create role',
        umbrella_role_form_label_role_name: 'Role Name:',
        umbrella_role_form_label_umbrella_administrator: 'Umbrella Administrator:',
        umbrella_role_form_label_site_administrator: 'Site Administrator:',
        umbrella_role_form_label_users: 'Users:',
        umbrella_role_form_placeholder_users: 'Select Users',
        umbrella_role_form_label_sites: 'Sites:',
        umbrella_role_form_label_site_labels: 'Site labels:',
        umbrella_role_form_placeholder_sites: 'Select Sites',
        umbrella_role_form_placeholder_site_labels: 'Site labels',
        umbrella_role_form_label_active_directory: 'Active Directory Group:',
        umbrella_role_form_placeholder_active_directory: 'Select Active Directory Group',
        umbrella_role_form_error_unique_role: 'Role already exists!',
        umbrella_role_form_admin_rights_include_all_sites: 'Umbrella admin rights include all Sites',
        umbrella_role_form_rights_reports_default: 'Reports:',
        umbrella_role_form_rights_reports_site_maintenance: 'Inventory Reports:',
        umbrella_role_form_rights_reports_site_audit: 'Audit Reports:',
        umbrella_role_form_rights_approve_gateway: 'Approve Sites:',
        umbrella_role_form_rights_deploy_gateway: 'Deploy Sites:',
        umbrella_role_form_rights_delete_gateway: 'Delete Sites:',
        umbrella_role_form_rights_upload_firmware: 'Upload Firmwares:',
        umbrella_role_form_rights_restart_camera_and_sites: 'Restart Cameras / Services:',
        umbrella_role_form_rights_monitoring: 'Monitoring:',
        umbrella_role_form_rights_ignore: 'Ignore:',
        umbrella_role_details_title: 'Umbrella Role Details',
        umbrella_role_details_title_built_in_role: 'Umbrella Role Details',
        umbrella_role_details_subtitle: 'Details for Umbrella Role {role}',
        umbrella_role_details_subtitle_built_in_role: 'Details for Umbrella Role {role} - can\'t be edited',
        umbrella_role_details_update_success_message: 'Role successfully updated',
        umbrella_role_details_update_error_message: 'Failed to update role',
        site_group_details_update_success_message: 'Group successfully updated',
        site_group_details_update_error_message: 'Failed to update group',
        site_group_details_title: 'Below you can change the groups parameters according to your preferences.',
        site_group_details_site_group: 'Group:',
        site_group_details_site: 'Site:',
        site_group_details_labels: 'Labels:',
        site_group_details_update_button: 'Update Details',
        site_group_details_tab_details: 'Details',
        site_group_details_properties_title: 'Below you can change the groups properties according to your preferences.',
        site_group_details_properties_update_success_message: 'Job(s) successfully created',
        site_group_details_properties_update_error_message: 'Failed to create jobs',
        umbrella_user_details_title: '{name}\'s Details',
        umbrella_user_details_title_built_in_user: '{name}\'s Details',
        umbrella_user_details_subtitle: 'Below you can edit {name}\'s details',
        umbrella_user_details_subtitle_built_in_user: '{name}\'s details can\'t be edited ',
        umbrella_user_details_label_username: 'Username:',
        umbrella_user_details_label_roles: 'Roles:',
        umbrella_user_details_placeholder_roles: 'Select roles',
        umbrella_user_details_update_button: 'Update Details',
        umbrella_user_details_update_success_message: 'User successfully updated',
        umbrella_user_details_update_error_message: 'Failed to update user',
        password_requirements_require_digit: 'Requires number',
        password_requirements_required_length: 'At least {lengthNumber} characters long',
        password_requirements_require_non_alphanumeric: 'Requires non alphanumeric character',
        password_requirements_require_uppercase: 'Requires uppercase letter',
        password_requirements_require_lowercase: 'Requires lowercase letter',
        password_requirements_required_unique_characters: 'Requires {uniqueNumber} unique characters',
        password_requirements_title: 'Password Requirements',
        change_properties_multi_no_common_properties: 'No common properties found',
        change_properties_multi_no_value_placeholder: 'Enter value...',
        change_properties_multi_success_message: 'Job(s) successfully created',
        change_properties_multi_error_message: 'Failed to create jobs',
        change_properties_multiple_users_title: 'Change Properties of Selected Users',
        change_properties_multiple_users_subtitle: 'Below you can change the user properties according to your preferences.',
        change_properties_multiple_site_groups_title: 'Change Properties of Selected Groups',
        change_properties_multiple_site_groups_subtitle: 'Below you can change the group properties according to your preferences.',
        change_properties_multiple_cameras_title: 'Change Properties of Selected Cameras',
        change_properties_multiple_cameras_subtitle: 'Below you can change the Camera properties according to your preferences.',
        change_properties_multiple_siteServices_title: 'Change Properties of Selected services',
        change_properties_multiple_siteServices_subtitle: 'Below you can change the services properties according to your preferences.',
        change_properties_multiple_access_control_cardholders_title: 'Change Properties of Selected Card Holders',
        change_properties_multiple_access_control_cardholders_subtitle: 'Below you can change the group properties according to your preferences.',
        change_properties_multiple_access_control_doors_title: 'Change Properties of Selected Doors',
        change_properties_multiple_access_control_doors_subtitle: 'Below you can change the group properties according to your preferences.',
        change_password_description: 'Please enter a new password.',
        change_password_input_current_password_placeholder: 'Current Password',
        change_password_input_new_password_placeholder: 'New Password',
        change_password_submit_button: 'Save',
        change_password_single_error_message: 'Failed to change password',
        license_warning_unlicensed_cameras: '{count} unlicensed cameras detected in the system.',
        license_warning_expiration_date: 'Your license expires in {days} days! Please renew your license.',
        license_overview_main_title: 'License Management',
        license_overview_sub_title: 'An overview of the Umbrella license.',
        license_overview_unlicensed_cameras: 'Unlicensed cameras',
        license_overview_expiration_date: 'Expiration date',
        licenses_chart_in_use_total: 'Total',
        licenses_chart_heading: 'Licenses',
        licenses_chart_licensed_cameras: 'In use',
        licenses_chart_unlicensed_cameras: 'Unused',
        license_modules_title: 'Licensed modules',
        license_modules_configuration: 'Site Administration',
        license_modules_monitoring: 'Monitoring',
        license_modules_reporting: 'Reports',
        license_machine_code_label: 'Machine code:',
        license_apply_new_license_label: 'Apply license key:*',
        license_apply_new_license_placeholder: 'Enter license key',
        license_apply_new_license_success_message: 'License successfully updated',
        license_apply_new_license_error_message: 'Failed to update license',
        license_apply_new_license_info: '* Update the license key will restart the Umbrella Core immediately',
        license_modules_situatorExclusive: 'Situator',
        license_modules_webapi: 'Web API',
        site_license_update_toolbar_update_licenses: 'Update Licenses',
        site_license_update_toolbar_export_csv: 'Export in CSV',
        site_license_update_upload_file_title: 'Update Licenses',
        site_license_update_upload_file_subtitle: 'Upload your file or simply drag & drop file below.',
        site_license_update_upload_file_box_title: 'Upload or Drag & Drop File',
        site_license_update_upload_file_ext_restriction: 'You can upload only zip or key file.',
        site_license_update_upload_file_check_error: 'Invalid license file provided',
        site_license_update_review_subtitle: 'Below you can find list of sites where the licenses will be applied.',
        site_license_update_review_submit: 'Create Job(s)',
        site_license_update_review_cancel: 'Cancel',
        site_license_update_review_job_info: 'Selected changes will not be done immediately. First you need to create jobs.',
        site_license_update_success_message: 'Job(s) successfully created',
        site_license_update_error_message: 'Failed to create jobs',
        site_license_step_one: 'Upload File',
        site_license_step_two: 'Confirm Update',
        quick_action_menu_add_site_user: 'Add User',
        quick_action_menu_add_site_group: 'Add Group',
        site_event_type_status_changed: 'Status Changed',
        site_event_type_system_message: 'System Message',
        site_event_entity_type_camera: 'Camera',
        site_event_entity_type_runtime: 'Service',
        site_event_entity_type_user: 'User',
        site_event_entity_type_group: 'Group',
        site_event_entity_type_access_control_cardholder: 'Card Holder',
        site_event_entity_type_access_control_door: 'Door',
        site_event_entity_type_unknown: 'Unknown',
        site_event_entity_type_site: 'Site',
        site_event_entity_type_system: 'System',
        site_event_category_info: 'Info',
        site_event_category_error: 'Error',
        site_event_category_warning: 'Warning',
        site_event_category_unknown: 'Unknown',
        advanced_job_configuration_title: 'Advanced Job Configuration:',
        advanced_job_configuration_execution_datetime: 'Execution (Site timezone):',
        advanced_job_configuration_undo_datetime: 'Undo:',
        advanced_job_configuration_retry: 'Retries:',
        advanced_job_configuration_datepicker_ok: 'OK',
        advanced_job_configuration_datepicker_cancel: 'CANCEL',
        grid_layout_save: 'Save layout',
        grid_layout_restore: 'Restore default',
        grid_layout_save_success: 'Layout saved',
        grid_layout_restore_success: 'Layout restored',
        grid_layout_save_error: 'Error in saving layout',
        grid_layout_restore_error: 'Error in restoring layout',
        site_events_table_head_event_name: 'Event Name:',
        site_events_table_head_site_name: 'Site Name:',
        site_events_table_head_labels: 'Labels',
        site_events_table_head_site_labels: 'Site Labels',
        site_events_table_head_event_type: 'Event Type',
        site_events_table_head_entity_type: 'Entity Type',
        site_events_table_head_event_category: 'Event Category',
        site_events_table_head_issue_resolved: 'Issue resolved',
        site_events_table_head_timestamp: 'Timestamp',
        site_events_table_head_description: 'Description',
        site_events_details_title: '{name}\'s Details',
        site_events_details_subtitle: 'Below you can change event parameters according to your preferences',
        site_events_details_event_type: 'Event Type:',
        site_events_details_entity_type: 'Entity Type:',
        site_events_details_event_category: 'Event Category:',
        site_events_details_issue_resolved: 'Issue resolved:',
        site_events_details_timestamp: 'Timestamp:',
        site_events_details_description: 'Description:',
        site_events_details_details_tab: 'Details',
        site_event_details_updated_success_message: 'Successfully updated',
        site_event_details_updated_error_message: 'Error',
        site_event_details_update_button: 'Update Event',
        umbrella_core_from_date: 'From:',
        umbrella_core_to_date: 'To:',
        umbrella_core_last_hour: 'Last Hour',
        umbrella_core_last_day: 'Last Day',
        umbrella_core_last_week: 'Last Week',
        umbrella_core_cpu_usage: 'CPU Usage (%)',
        umbrella_core_memory_usage: 'Memory Usage (MB)',
        umbrella_core_request_count: 'Request Count',
        umbrella_core_request_duration: 'Request Duration (ms)',
        umbrella_core_no_data_to_show: 'No data to show',
        email_configuration_title: 'E-Mail configuration',
        email_configuration_description: 'Configure communication with the SMTP-Server and send a Test E-mail to validate settings.',
        email_configuration_smtp_address: 'SMTP Host',
        email_configuration_smtp_port: 'SMTP Port',
        email_configuration_username: 'Username',
        email_configuration_password: 'Password',
        email_configuration_sender_email: 'Sender E-mail',
        email_configuration_encryption_method: 'Encryption method',
        email_configuration_subject: 'Subject',
        email_configuration_test: 'Test',
        email_configuration_encryption_null: 'No encryption',
        email_configuration_encryption_ssl: 'SSL/TLS',
        email_configuration_submit: 'Update',
        email_configuration_reset_fields: 'Reset all',
        email_configuration_update_success: 'E-Mail settings updated successfully',
        email_configuration_update_error: 'Error in updating E-Mail settings',
        email_configuration_test_success: 'Test E-Mail successful',
        email_configuration_test_error: 'Test E-Mail failed',
        email_configuration_test_receiver_title: 'Test Receiver',
        email_configuration_test_receiver_label: 'Test receiver',
        email_configuration_password_checkbox: 'Set Password',
        email_configuration_host_error: 'Host must no be empty',
        email_configuration_port_error: 'Port must not be empty and be in range 1 - 65535',
        email_configuration_username_error: 'Username must not be empty',
        email_configuration_password_error: 'Password must not be empty',
        email_configuration_sender_email_error: 'Sender E-Mail must not be empty',
        email_configuration_encryption_method_error: 'Encryption method must not be empty',
        email_configuration_subject_error: 'Subject must not be empty',
        email_configuration_test_validation_error: 'Invalid E-Mail address',
        umbrella_role_form_site_events_notifications: 'Events Notifications:',
        umbrella_role_form_site_event_categories: 'Event Categories:',
        umbrella_role_form_placeholder_site_event_categories: 'Select Event Categories',
        umbrella_role_form_site_event_types: 'Event Types:',
        umbrella_role_form_placeholder_site_event_types: 'Select Event Types',
        umbrella_role_form_site_entity_types: 'Entity Types:',
        umbrella_role_form_placeholder_site_entity_types: 'Select Entity Types',
        umbrella_role_form_contains_filter: 'Contains Filter:',
        umbrella_role_form_placeholder_contains_filter: 'Select filter',
        umbrella_role_form_tooltip_contains_filter: 'Use this to filter Description or Source with case invariant tags. If you enter multiple tags they are treated as OR.',
        umbrella_role_form_send_email: 'Send E-Mail:',
        umbrella_role_form_send_email_to_ad_group: 'Send E-Mails to AD Group:',
        umbrella_no_notifications: 'No notifications to show',
        umbrella_notification_table_column_type: 'Type',
        umbrella_notification_table_column_source: 'Source',
        umbrella_notification_table_column_site: 'Site',
        umbrella_notification_table_column_timestamp: 'Timestamp',
        umbrella_notification_table_column_resolved: 'Resolved',
        umbrella_notification_table_column_category: 'Category',
        umbrella_notification_table_clear_notifications: 'Clear Notifications',
        umbrella_notification_table_column_description: 'Description',
        umbrella_notification_table_site_events: 'Events',
        umbrella_dashboard_new_layout: 'New Layout',
        umbrella_dashboard_save_layout: 'Save Layout',
        umbrella_dashboard_delete_layout: 'Delete Layout',
        umbrella_dashboard_show_default: 'Show Default',
        umbrella_dashboard_choose_layout: 'Choose Layout',
        umbrella_dashboard_choose_layout_description: 'Customize your Dashboard by selecting your prefered layout.',
        umbrella_dashboard_choose_layout_save_button: 'Select',
        umbrella_dashboard_add_card: 'Add Card',
        umbrella_dashboard_select_card: 'Select Card',
        umbrella_dashboard_select_card_description: 'Select a Card to add it to the placeholder',
        umbrella_dashboard_chart_tab_button: 'Charts',
        umbrella_dashboard_map_tab_button: 'Maps',
        umbrella_dashboard_table_tab_button: 'Tables',
        umbrella_dashboard_replace_card: 'Replace Card',
        umbrella_dashboard_delete_card: 'Delete Card',
        umbrella_dashboard_recent_site_events: 'Recent Events',
        umbrella_dashboard_recent_site_events_no_events: 'No Events',
        umbrella_dashboard_view_recent_site_events: 'View Events',
        umbrella_dashboard_choose_layout_site_states_tooltip: 'Site States',
        umbrella_dashboard_choose_layout_running_cameras_tooltip: 'Running Cameras',
        umbrella_dashboard_choose_layout_running_site_services_tooltip: 'Running Services',
        umbrella_dashboard_choose_layout_site_approvals_tooltip: 'Site Approvals',
        umbrella_dashboard_choose_layout_core_cpu_tooltip: 'Umbrella Core CPU',
        umbrella_dashboard_choose_layout_core_memory_tooltip: 'Umbrella Core Memory',
        umbrella_dashboard_choose_layout_core_request_duration_tooltip: 'Umbrella Core Request Duration',
        umbrella_dashboard_choose_layout_core_request_count_tooltip: 'Umbrella Core Request Count',
        umbrella_dashboard_choose_layout_map_tooltip: 'Map',
        umbrella_dashboard_choose_layout_recent_jobs_tooltip: 'Recent Jobs',
        umbrella_dashboard_choose_layout_recent_site_events_tooltip: 'Recent Events',
        umbrella_dashboard_advanced_layout_add_new_item: 'ADD ITEM',
        umbrella_eula_title: 'EULA and third party license Agreement',
        umbrella_eula_text: 'Please read the agreement carefully. If you continue and click Accept, you agree to the EULA and the third party license terms and conditions.',
        umbrella_eula_button: 'Accept',
        umbrella_eula_file_text: 'Click here to read EULA Agreement',
        breadcrumb_dashboard: 'Dashboard',
        breadcrumb_control_centre: 'Site Administration',
        breadcrumb_sites: 'Sites',
        breadcrumb_map: 'Map',
        breadcrumb_site_users: 'Users',
        breadcrumb_site_groups: 'Groups',
        breadcrumb_access_control_cardholders: 'Card Holders',
        breadcrumb_access_control_doors: 'Doors',
        breadcrumb_site_services: 'Services',
        breadcrumb_cameras: 'Cameras',
        breadcrumb_site_jobs: 'Jobs',
        breadcrumb_update_site_licenses: 'Licenses',
        breadcrumb_administration: 'Administration',
        breadcrumb_umbrella_users: 'Umbrella Users',
        breadcrumb_umbrella_roles: 'Umbrella Roles',
        breadcrumb_administration_license: 'Umbrella License',
        breadcrumb_administration_license_update: 'License',
        breadcrumb_monitor_centre: 'Monitoring',
        breadcrumb_umbrella_core: 'Umbrella Core',
        breadcrumb_site_events: 'Events',
        breadcrumb_email: 'E-Mail',
        breadcrumb_camera_firmware: 'Camera Firmwares',
        breadcrumb_administration_settings: 'Settings',
        breadcrumb_site_time_profile: 'Time Profiles',
        breadcrumb_report_center: 'Reports',
        breadcrumb_reports: 'Inventory Reports',
        breadcrumb_audit_reports: 'Audit Reports',
        breadcrumb_site_systems: 'Systems',
        breadcrumb_site_service_performance: 'Services',
        breadcrumb_camera_performance: 'Cameras',
        breadcrumb_plugins: 'Plugins',
        breadcrumb_labels: 'Labels',
        breadcrumb_apiKeys: 'API Keys',
        site_job_details_execution_time: 'Execution time (Site timezone):',
        site_job_details_retries: 'Retries:',
        site_job_details_sequence_id: 'Sequence ID:',
        site_job_details_sequence_order: 'Sequence Order:',
        site_job_details_sequence_total: 'Sequence Total:',
        site_job_related_undo_job: 'RELATED UNDO JOB:',
        site_job_card_title: 'JOB:',
        site_jobs_table_head_sequence: 'Sequence ID',
        site_jobs_table_head_sequence_order: 'Sequence Order',
        site_jobs_table_head_sequence_total: 'Sequence Total',
        site_jobs_table_head_execution_time: 'Execution time (Site timezone)',
        site_jobs_table_head_undo_time: 'Undo Time (Site timezone)',
        site_jobs_table_head_retries: 'Retries',
        restart_cameras_success_snack_message: 'Jobs successfully created to restart cameras',
        restart_cameras_error_snack_message: 'Failed to create jobs to restart cameras',
        restart_cameras_tooltip: 'Restart cameras',
        license_root_title: 'License Management',
        license_root_description: 'No license key! Use this code to request a license:',
        license_root_apply_license_key: 'Apply license key:',
        license_root_input_placeholder: 'Enter license key',
        license_root_submit_button_text: 'Apply',
        license_root_problem_license_valid: 'License valid',
        license_root_problem_license_invalid: 'License invalid',
        license_root_problem_machine_code_missing: 'Machine code is missing',
        license_root_problem_no_modules: 'No modules licensed',
        license_root_key_update_success: 'Successfully updated key',
        license_root_key_update_fail: 'Error in updating key',
        camera_firmware_modal_title: 'Upload New Firmware',
        camera_firmware_modal_description: 'The Firmware will be stored on the Umbrella Core Server.',
        camera_firmware_modal_manfucturer: 'Manufacturer:*',
        camera_firmware_modal_supported_model: 'Supported Model:*',
        camera_firmware_modal_comment: 'Comment:',
        camera_firmware_modal_dropzone: 'Upload or Drag & Drop File',
        camera_firmware_modal_upload_btn: 'Upload',
        camera_firmware_modal_upload_success: 'Successfully uploaded new firmware',
        camera_firmware_modal_upload_error: 'Error in uploading new firmware',
        camera_firmware_table_filename: 'File name',
        camera_firmware_table_manufacturer: 'Manufacturer',
        camera_firmware_table_comment: 'Comment',
        camera_firmware_table_supported_types: 'Supported types',
        camera_firmware_table_uploaded_at: 'Uploaded at',
        camera_firmware_table_delete_firmwares: 'Delete firmware',
        camera_firmware_table_selected_count: '{count} selected',
        camera_firmware_table_upload_modal_button: 'Upload new firmware',
        camera_firmware_modal_no_file_selected: 'No file selected',
        camera_firmware_upload_snackbar_title: 'Uploading Firmware',
        camera_firmware_upload_snackbar_button_error: 'Error',
        camera_firmware_upload_snackbar_button: 'Done',
        camera_firmware_deleted_success: 'Sucessfully deleted selected firmwares',
        camera_firmware_deleted_error: 'Error in deleting slected firmwares',
        camera_firmware_modal_file_name: 'File name:',
        settings_configuration_title: 'General Settings',
        settings_configuration_description: 'Below You can edit General Settings',
        settings_ad_use_ad_cache: 'Use Active Directory Cache:*',
        settings_ad_refresh_ad_cache_minutes: 'Refresh Active Directory Cache (in minutes):*',
        settings_general_provide_health_endpoint: 'Provide health endpoint:*',
        settings_general_delete_vms_events_days: 'Delete Site events older than (in days):',
        settings_general_core_performance_measure_interval: 'Performance Measure Interval (in minutes):',
        settings_password_require_digit: 'Require digit:*',
        settings_password_required_length: 'Required length:*',
        settings_password_require_non_alphanumeric: 'Require non alphanumeric:*',
        settings_password_require_uppercase: 'Require uppercase:*',
        settings_password_require_lowercase: 'Require lowercase:*',
        settings_password_required_unique_chars: 'Required unique chars:*',
        settings_login_is_remember_me_available: 'Is remember me available:*',
        settings_login_cookie_persistence_duration: 'Cookie persistence duration (in hours):*',
        settings_login_sliding_expiration_session_cookie: 'Sliding expiration of Session Cookie:*',
        settings_login_validation_interval_seconds: 'Validation Interval (in seconds):*',
        settings_ad_is_ad_login_available: 'Is Active Directory login available:*',
        settings_ad_sync_active_directory_email: 'Synchronize Active Directory Email:*',
        settings_ad_domain_names: 'Domain names:*',
        settings_license_license_key: 'License key:',
        settings_gateway_heartbeat_interval_seconds: 'Heartbeat interval (in seconds):',
        settings_gateway_use_signalr_for_vms_event: 'Use SignalR for Site events:',
        settings_gateway_vms_network_timeout_seconds: 'Site network timeout (in seoncds):',
        settings_gateway_fetch_recordings_interval_minutes: 'Fetch recordings interval (in minutes):',
        settings_gateway_delete_site_performance_measurements_interval: 'Delete Performance Measurements Interval (in days):',
        settings_general_delete_core_performance_measurements_interval: 'Delete Performance Measurements Interval (in days):',
        settings_gateway_site_performance_measure_interval: 'Performance Measure Interval (in minutes):',
        settings_configuration_submit: 'Update',
        settings_configuration_reset_fields: 'Reset all',
        settings_configuration_restore_default: 'Restore default',
        settings_configuration_update_success: 'General Settings updated successfully',
        settings_configuration_update_error: 'Error in updating General Settings',
        settings_configuration_resart_information: '* Changes to these values will restart the Umbrella Core immediately',
        settings_general_delete_log_days: 'Delete Logfiles older than (in days):*',
        settings_gateway_delete_log_days: 'Delete Logfiles older than (in days):',
        settings_expert_writeheartbeatcache: 'Heartbeat cache write interval (in seconds):*',
        settings_expert_allowedmissedheartbeats: 'Number of allowed missed heartbeats:*',
        settings_expert_delaygatewayupdate: 'Delay gateway update interval (in seconds):*',
        settings_general_display_username_jobs: 'Display Usernames in Jobs:*',
        settings_general_is_comment_mandatory: 'Make Job Comment mandatory:*',
        settings_general_delete_reports_when_downloading: 'Delete reports when downloaded:*',
        settings_general_delete_jobs_after_day_interval: 'Delete jobs older than (in days):',
        settings_gateway_delete_system_performance_measurements_interval: 'Delete Performance Measurements Interval (in days):',
        settings_gateway_system_performance_measure_interval: 'Performance Measure Interval (in minutes):',
        settings_general_thresholds_cpu: 'Thresholds CPU (%):',
        settings_general_thresholds_memory: 'Thresholds Memory (%):',
        settings_general_thresholds_disk: 'Thresholds Disk (%):',
        settings_general_thresholds_network: 'Thresholds Network (%):',
        settings_notifications_priority_low_delay: 'Delay low priority notifications (in seconds):',
        settings_notifications_priority_normal_delay: 'Delay normal priority notifications (in seconds):',
        settings_thresholds_slider_warning: 'Warning',
        settings_thresholds_slider_critical: 'Critical',
        settings_group_password: 'PASSWORD SETTINGS:',
        settings_group_login: 'LOGIN SETTINGS:',
        settings_group_license: 'LICENSE:',
        settings_group_gateway: 'GATEWAY SETTINGS:',
        settings_group_core: 'CORE SETTINGS:',
        settings_group_core_expert: 'CORE EXPERT SETTINGS:',
        settings_group_active_directory: 'ACTIVE DIRECTORY SETTINGS:',
        settings_group_notification: 'NOTIFICATION SETTINGS:',
        settings_general_delete_vms_events_error: 'Must be in range 1 - 365',
        settings_ad_refresh_ad_cache_error: 'Must be in range 5 - 120',
        settings_general_core_performance_measure_interval_error: 'Must be in range 1 - 60',
        settings_gateway_site_performance_measure_interval_error: 'Must be in range 1 - 60',
        settings_password_required_length_error: 'Must be in range 1 - 100',
        settings_password_required_unique_chars_error: 'Cannot be less than 0 or greater than Required Length',
        settings_login_cookie_persistence_duration_error: 'Must be in range 0 - 2160',
        settings_login_validation_interval_error: 'Must be in range 0 - 300',
        settings_ad_domain_names_error: 'Cannot be empty if Active Directory is enabled',
        settings_gateway_heartbeat_interval_error: 'Must be in range 5 - 3600',
        settings_gateway_vms_network_timeout_error: 'Must be in range 5 - 120',
        settings_gateway_fetch_recordings_interval_error: 'Must be in range 5 - 120',
        settings_gateway_delete_site_performance_measurements_interval_error: 'Must be in range 1 - 365',
        settings_general_delete_core_performance_measurements_interval_error: 'Must be in range 1 - 365',
        settings_general_delete_log_days_error: 'Must be in range 1 - 365',
        settings_gateway_delete_log_days_error: 'Must be in range 1 - 365',
        settings_expert_writeheartbeatcache_error: 'Must be in range 10 - 60',
        settings_expert_allowedmissedheartbeats_error: 'Must be in range 3 - 10',
        settings_expert_delaygatewayupdate_error: 'Must be in range 0 - 30',
        settings_general_delete_jobs_after_day_interval_error: 'Must be in range 1 - 365',
        umbrella_sites_table_site_name: 'Site name',
        umbrella_sites_table_cpu_usage: 'CPU Usage (%)',
        umbrella_sites_table_ram_usage: 'Memory Usage (GB)',
        umbrella_sites_table_site_labels: 'Labels',
        umbrella_sites_table_state_healthy: 'Healthy',
        umbrella_sites_table_state_warning: 'Warning',
        umbrella_sites_table_state_critical: 'Critical',
        umbrella_sites_cpu_usage: 'CPU Usage (%)',
        umbrella_sites_ram_usage: 'Memory Usage (GB)',
        umbrella_sites_no_data_to_show: 'No data to show',
        umbrella_sites_disk_usage: 'Disk Space Usage (%)',
        umbrella_sites_services_performance: 'Services Performance',
        upload_manager_state_done: 'Done',
        upload_manager_state_error: 'Error',
        upload_manager_clear_completed_button: 'Clear completed',
        upload_manager_no_items: 'No items',
        upload_manager_error_general: 'Error',
        upload_manager_error_file_already_exists: 'File already exists',
        upload_manager_error_size_too_large: 'File is too large',
        upload_manager_type_firmware_upload: 'Firmware upload: {name}',
        upload_manager_unload_message: 'Last actions are still in progress (check progress manager)',
        upload_manager_job_async_camera_restart: 'Create restart camera job ({count})',
        upload_manager_job_async_change_camera_password: 'Create change camera password job ({count})',
        upload_manager_job_async_camera_firmware_update: 'Create apply camera firmware job ({count})',
        upload_manager_job_async_camera_change_properties: 'Create change camera properties job ({count})',
        upload_manager_job_async_site_group_create: 'Create new create group job ({count})',
        upload_manager_job_async_site_group_delete: 'Create new delete group job ({count})',
        upload_manager_job_async_site_group_change_properties: 'Create change groups properties job ({count})',
        upload_manager_job_async_license_upload: 'Create new upload license job ({count})',
        upload_manager_job_async_sites_connection_user_password: 'Create change connection user password job ({count})',
        upload_manager_job_async_site_services_restart: 'Create restart service job ({count})',
        upload_manager_job_async_site_services_change_properties: 'Create change services properties job ({count})',
        upload_manager_job_async_site_users_change_password: 'Create change user password job ({count})',
        upload_manager_job_async_site_users_create: 'Create new create user job ({count})',
        upload_manager_job_async_site_users_delete: 'Create new delete user job ({count})',
        upload_manager_job_async_site_users_properties: 'Create change users properties job ({count})',
        upload_manager_job_async_retry_jobs: 'Create retry job ({count})',
        upload_manager_job_async_site_time_profile_change_properties: 'Create change time profile properties job ({count})',
        upload_manager_job_async_site_user_change_name: 'Create change user name job ({count})',
        upload_manager_job_async_site_user_copy_user: 'Create copy user job ({count})',
        upload_manager_job_async_access_control_cardholders_change_properties: 'Create change card holder properties job ({count})',
        upload_manager_job_async_access_control_doors_change_properties: 'Create change door properties job ({count})',
        upload_manager_job_async_access_control_cardholders_create: 'Create new card holder job ({count})',
        upload_manager_job_async_change_system_message_config: 'Create change system message config job {{count}}',
        site_time_profiles_list_add_time_profile_button: 'Add Time Profile',
        site_time_profiles_list_table_head_time_profile: 'Time Profile',
        site_time_profiles_list_table_head_site: 'Site',
        site_time_profiles_list_table_head_site_labels: 'Site Labels',
        site_time_profiles_list_table_head_labels: 'Labels',
        site_time_profiles_table_toolbar_change_time_profiles_tooltip: 'Change Time Profile',
        site_time_profiles_table_toolbar_selected_time_profiles: '{count} selected',
        site_time_profile_day_monday: 'Monday',
        site_time_profile_day_monday_short: 'Mon',
        site_time_profile_day_tuesday: 'Tuesday',
        site_time_profile_day_tuesday_short: 'Tue',
        site_time_profile_day_wednesday: 'Wednesday',
        site_time_profile_day_wednesday_short: 'Wed',
        site_time_profile_day_thursday: 'Thursday',
        site_time_profile_day_thursday_short: 'Thr',
        site_time_profile_day_friday: 'Friday',
        site_time_profile_day_friday_short: 'Fri',
        site_time_profile_day_saturday: 'Saturday',
        site_time_profile_day_saturday_short: 'Sat',
        site_time_profile_day_sunday: 'Sunday',
        site_time_profile_day_sunday_short: 'Sun',
        site_time_profile_day_bank_holiday: 'Bank holiday',
        site_time_profile_day_bank_holiday_short: 'Bank',
        site_time_profile_hours: 'Hours',
        site_time_profile_time_range: 'TIME RANGE {index}:',
        site_time_profile_time_range_starts: 'Starts:',
        site_time_profile_time_range_ends: 'Ends:',
        site_time_profile_time_range_add_new: 'Add new time range',
        site_time_profile_change_property_job: 'Create Change Property Job(s)',
        site_time_profile_reset: 'Reset All',
        site_time_profile_info_desc: 'Selected changes will not be done immediately. First you need to create jobs.',
        site_time_profile_general_tab: 'Details',
        site_time_profile_profile_tab: 'Profile',
        site_time_profile_general_title: 'Change Time Profile',
        site_time_profile_general_description: 'Bellow You can change the Time Profile parameters according to your preferences.',
        site_time_profile_general_name: 'Name:',
        site_time_profile_general_site: 'Site:',
        site_time_profile_general_labels: 'Labels:',
        site_time_profile_general_update_details: 'Update Details',
        site_time_profile_save_current_editing_range: 'Apply Changes',
        site_time_profile_save_details_general_success: 'Successfully updated time profile',
        site_time_profile_save_details_general_error: 'Error in updating time profile',
        site_time_profile_change_property_success: 'Created Change Property Job(s)',
        site_time_profile_change_property_error: 'Failed to create Change Property Job(s)',
        site_time_profile_change_multiple_title: 'Time Profile Multiple Configuration',
        site_time_profile_change_multiple_description: 'When configurating multiple time profiles you always start with an empty time profile when the selected time profiles do not match.',
        site_time_profile_common_properties_error: 'Failed to check for common properties',
        site_time_profile_remove_time_range: 'Remove time range',
        site_time_profile_slider_overlap_warning: 'Time ranges are overlapping. They will be merged into one time range',
        site_job_update_vms_license: 'Update License',
        site_job_update_firmware_camera: 'Update Camera Firmware',
        site_job_restart_vms_runtime: 'Restart Service',
        site_job_start_vms_runtime: 'Start Service',
        site_job_stop_vms_runtime: 'Stop Service',
        site_job_delete_vms_user: 'Delete User',
        site_job_delete_vms_group: 'Delete Group',
        site_job_create_vms_user: 'Create User',
        site_job_create_vms_group: 'Create Group',
        site_job_change_vms_user_password: 'Change User Password',
        site_job_change_connection_user_password: 'Change Connection User Password',
        site_job_change_camera_password: 'Change Camera Password',
        site_job_cancel_job: 'Cancel Job',
        site_job_batch_job: 'Batch Job',
        site_job_change_vms_user_property: 'Change User Property',
        site_job_change_vms_camera_property: 'Change Camera Property',
        site_job_change_vms_group_property: 'Change Group Property',
        site_job_change_vms_runtime_property: 'Change Service Property',
        site_job_change_vms_event_property: 'Change Event Property',
        site_job_change_vms_timeprofile_property: 'Change Time Profile Property',
        site_job_change_vms_user_name: 'Rename User',
        site_job_copy_vms_user: 'Copy User',
        site_job_restart_gateway: 'Restart Site Connection',
        site_job_create_accesscontrol_cardholder: 'Create Card Holder',
        site_job_change_vms_event_config_property: 'Change Event Configuration Property',
        selectionbox_toolbar_select_mode_page: 'Only current page',
        selectionbox_toolbar_select_mode_selectAll: 'All Pages',
        selectionbox_siteselector_include_all_sites: 'Select All mode includes all Sites',
        reports_table_head_filename: 'Filename',
        reports_table_head_createdAt: 'Created At',
        reports_table_head_createdBy: 'Created By',
        reports_table_head_comment: 'Comment',
        reports_table_head_siteName: 'Sitename',
        reports_download_success_snack_message: 'Job(s) successfully created to download reports',
        reports_download_error_snack_message: 'Failed to create jobs to download reports',
        reports_table_toolbar_download_tooltip: 'Download selected reports',
        reports_table_toolbar_delete_tooltip: 'Delete selected reports',
        reports_delete_success_snack_message: 'Selected reports succesfully deleted',
        reports_delete_error_snack_message: 'Failed to delete reports',
        create_audit_report_modal_title: 'Create Audit Report',
        create_audit_report_modal_description: 'Create Audit Report for selected timerange',
        create_audit_report_modal_start_label: 'Start date',
        create_audit_report_modal_end_label: 'End date',
        create_audit_report_modal_submit_button: 'Request Audit Report',
        create_audit_report_modale_error: 'End date can not be before start date',
        create_audit_report_success_snack_message: 'Job(s) successfully created to create audit reports',
        create_audit_report_failed_snack_message: 'Failed to create jobs to create audit reports',
        audit_reports_table_head_filename: 'Filename',
        audit_reports_table_head_createdAt: 'Created At',
        audit_reports_table_head_createdBy: 'Created By',
        audit_reports_table_head_comment: 'Comment',
        audit_reports_table_head_siteName: 'Sitename',
        audit_reports_table_head_from: 'From',
        audit_reports_table_head_till: 'Till',
        custom_comments_modal_title: 'Custom comments',
        custom_comments_modal_description: 'Custom comments description',
        copy_to_clipboard_button_tooltip: 'Copy to Clipboard',
        site_systems_chip_healthy: 'Healthy',
        site_systems_chip_warning: 'Warning',
        site_systems_chip_critical: 'Critical',
        site_systems_chip_offline: 'Offline',
        site_systems_chip_ignore: 'Ignored',
        site_systems_search_by_site: 'Filter by Site',
        site_systems_search_by_system: 'Filter by Site System',
        site_systems_search_by_name: 'Filter by Name',
        site_systems_ignore_success: 'Successful',
        site_systems_ignore_error: 'Failed',
        site_systems_tooltip_ignore: 'Ignore selected systems',
        site_systems_details_net_usage: 'Net Usage (%)',
        site_systems_details_disk_usage: 'Disk Usage (%)',
        site_systems_details_description: 'Performance details regarding this system and its services',
        site_systems_details_no_disk_data: 'No Data',
        site_systems_details_services_title: 'Services ({count})',
        general_datetime_days: 'days',
        general_datetime_hours: 'hours',
        general_datetime_minutes: 'minutes',
        general_datetime_seconds: 'seconds',
        deploy_monitoring_plugin_title: 'Create Monitoring Plugin Installer',
        deploy_monitoring_plugin_floating_button_tooltip: 'Create Monitoring Plugin Installer',
        deploy_monitoring_plugin_description: 'To deploy a new monitoring plugin fill out this form and download the monitoring plugin deploy zip package. The package includes an Monitoring Plugin Installer and all necessary configuration files. Follow the instructions on the Monitoring Plugin Installer Console Window.',
        deploy_monitoring_plugin_warning_label: 'WARNING',
        deploy_monitoring_plugin_input_warning_label: 'Install additional Umbrella Monitoring Plugins only on systems where no other Umbrella Monitoring Plugin is running. Each Umbrella Gateway has an Umbrella Monitoring Plugin included and systems running an Umbrella Gateway do not require another Umbrella Monitoring Plugin.',
        deploy_monitoring_plugin_required_parameters_label: 'Required parameters:',
        deploy_monitoring_plugin_input_gateway_host_label: 'Gateway Host:*',
        deploy_monitoring_plugin_input_gateway_port_label: 'Gateway Port:*',
        deploy_monitoring_plugin_input_plugin_host_label: 'Plugin Host:*',
        deploy_monitoring_plugin_input_plugin_port_label: 'Plugin Port:*',
        deploy_monitoring_plugin_input_as_a_service_label: 'Windows service:',
        deploy_monitoring_plugin_input_service_name_label: 'Windows service name:*',
        deploy_monitoring_plugin_input_working_dir_label: 'Working directory:*',
        deploy_monitoring_plugin_input_working_dir_tooltip: 'Service path should be an absolute path and the folder should not exist yet, but will be created by the Monitoring Plugin installer.',
        deploy_monitoring_plugin_download_installer_button: 'Download Installer',
        deploy_monitoring_plugin_instructions: 'Execute the Umbrella.Monitoring.Plugin.Installer.exe to deploy the monitoring plugin',
        deploy_monitoring_plugin_input_gateway_host_placeholder: 'Gateway Host',
        deploy_monitoring_plugin_input_gateway_port_placeholder: 'Gateway Port',
        deploy_monitoring_plugin_input_plugin_host_placeholder: 'Plugin Host',
        deploy_monitoring_plugin_input_plugin_port_placeholder: 'Plugin Port',
        deploy_monitoring_plugin_input_service_name_placeholder: 'Windows service name',
        deploy_monitoring_plugin_input_working_dir_placeholder: 'Working directory',
        deploy_monitoring_plugin_input_validation_port: 'Invalid port number (1 - 65535)',
        deploy_monitoring_plugin_input_validation_working_dir: 'Invalid working directory',
        deploy_monitoring_plugin_input_validation_service_name: 'Invalid service name (only letters, numbers and underscore are allowed)',
        deploy_monitoring_plugin_input_validation_host: 'Invalid host name',
        deploy_monitoring_plugin_success_message: 'Successfully downloaded deployment package',
        deploy_monitoring_plugin_error_message: 'Failed to create deployment package',
        site_service_performance_service_state_label: 'Service State',
        site_service_performance_vms_state_tooltip: 'The logical state from the Site plugin',
        site_service_performance_service_state_tooltip: 'The operating system service state',
        site_service_performance_vms_state_label: 'State',
        site_service_performance_service_state_stopped: 'STOPPED',
        site_service_performance_service_state_running: 'RUNNING',
        site_service_performance_service_state_paused: 'PAUSED',
        site_service_performance_service_state_offline: 'UNKNOWN',
        site_service_performance_host: 'Host',
        vms_state_ok: 'OK',
        vms_state_error: 'ERROR',
        vms_state_offline: 'UNKNOWN',
        vms_state_disabled: 'DISABLED',
        site_service_performance_ignore_success: 'Successful',
        site_service_performance_ignore_error: 'Failed',
        site_service_performance_tooltip_ignore: 'Ignore selected services',
        site_service_performance_start_success: 'Successful',
        site_service_performance_start_error: 'Failed',
        site_service_performance_stop_success: 'Successful',
        site_service_performance_stop_error: 'Failed',
        camera_performance_recording_state_label: 'Recording State',
        camera_performance_vms_state_label: 'State',
        camera_performance_vms_state_tooltip: 'The operating system service state',
        camera_performance_recording_state_tooltip: 'The recording state from the Site plugin',
        recording_state_ok: 'OK',
        recording_state_limited: 'LIMITED',
        recording_state_error: 'ERROR',
        recording_state_unknown: 'UNKNOWN',
        recording_state_disabled: 'DISABLED',
        camera_performance_ignore_success: 'Successful',
        camera_performance_ignore_error: 'Failed',
        camera_performance_tooltip_ignore: 'Ignore selected cameras',
        camera_performance_recording_statistics: 'Recording Statistics',
        norole_back_to_login: 'Back to login',
        norole_info_1: 'The selected user has no assigned role(s)',
        norole_info_2: 'Please contact your administrator',
        invalid_session_title: 'Connection Lost',
        invalid_session_text: 'It appears that the Umbrella Core is currently unreachable. Please login again to begin a new session.',
        invalid_session_button_text: 'Back to Login',
        access_control_cardholders_table_head_cardholder: 'Card Holder',
        access_control_cardholders_table_head_sites: 'Sites',
        access_control_cardholders_table_head_labels: 'Labels',
        access_control_cardholders_table_head_site_labels: 'Site Labels',
        access_control_cardholders_toolbar_selected_cardholder: '{count} selected',
        access_control_cardholders_toolbar_change_properties_tooltip: 'Change properties of selected card holders',
        access_control_cardholders_details_update_success_message: 'Card Holder successfully updated',
        access_control_cardholders_details_update_error_message: 'Failed to update card holder',
        access_control_cardholders_details_title: 'Below you can change the card holder parameters according to your preferences.',
        access_control_cardholders_details_cardholder: 'Card Holder:',
        access_control_cardholders_details_site: 'Site:',
        access_control_cardholders_details_labels: 'Labels:',
        access_control_cardholders_details_update_button: 'Update Details',
        access_control_cardholders_details_tab_details: 'Details',
        access_control_cardholders_details_properties_title: 'Below you can change the card holder properties according to your preferences.',
        access_control_cardholders_details_properties_update_success_message: 'Job(s) successfully created',
        access_control_cardholders_details_properties_update_error_message: 'Failed to create jobs',
        access_control_doors_table_head_door: 'Door',
        access_control_doors_table_head_sites: 'Sites',
        access_control_doors_table_head_labels: 'Labels',
        access_control_doors_table_head_site_labels: 'Site Labels',
        access_control_doors_toolbar_selected_door: '{count} selected',
        access_control_doors_toolbar_change_properties_tooltip: 'Change properties of selected doors',
        access_control_doors_details_update_success_message: 'Door successfully updated',
        access_control_doors_details_update_error_message: 'Failed to update door',
        access_control_doors_details_title: 'Below you can change the Door parameters according to your preferences.',
        access_control_doors_details_door: 'Door:',
        access_control_doors_details_site: 'Site:',
        access_control_doors_details_labels: 'Labels:',
        access_control_doors_details_update_button: 'Update Details',
        access_control_doors_details_tab_details: 'Details',
        access_control_doors_details_properties_title: 'Below you can change the door properties according to your preferences.',
        access_control_doors_details_properties_update_success_message: 'Job(s) successfully created',
        access_control_doors_details_properties_update_error_message: 'Failed to create jobs',
        property_systemmessages_name_dbloadwarning: 'The configuration and event database is almost full',
        property_systemmessages_name_dbloademergencyshutdown: 'The configuration and event database is full',
        property_systemmessages_name_dbcyclicbackupfailed: 'Cyclical backup of configuration and event database failed',
        property_systemmessages_name_reinvalidstatus: 'Service monitoring check failed',
        property_systemmessages_name_licencechangedsucceeded: 'License file changed successfully',
        property_systemmessages_name_licencechangedfailed: 'Error changing the license file',
        property_systemmessages_name_mdbcannotmountzone: 'Can not mount MDB zone',
        property_systemmessages_name_mdbnomorezonespace: 'MDB zone is full',
        property_systemmessages_name_mdbcannotstartmds: 'Can not start MDB',
        property_systemmessages_name_servertimechanged: 'System time changed',
        property_systemmessages_name_cmcannotstart: 'Can not start DM',
        property_systemmessages_name_cmdeviceconnectivityproblem: 'Device connectivity problem',
        property_systemmessages_name_videoloss: 'Loss of video signal at encoder deteceted ( if supported by encoder )',
        property_systemmessages_name_tampering: 'Tampering alarm ( if supported by encoder )',
        property_systemmessages_name_donglemissing: 'Can not find LPR dongle',
        property_systemmessages_name_avexportjobfailed: 'AV export failed',
        property_systemmessages_name_avexportjobold: 'AV export more than two hours old',
        property_systemmessages_name_avexportjobolder: 'AV export more than two four old',
        property_systemmessages_name_mdbzonealmostfull: 'MDB Zone almost full',
        property_systemmessages_name_systemalertsbadsignal: 'Failure of image analysis due to poor light',
        property_systemmessages_name_systemalertsunknownview: 'Failed to analyze image due to distortion',
        property_systemmessages_name_systemalertsrestoredview: 'Restoration of failed image analysis',
        property_systemmessages_name_coresubunreachable: 'Sub Core unreachable',
        property_systemmessages_name_mdblaststandardimagetooold: 'The last archive image is older than configured',
        property_systemmessages_name_flatfileloggingcannotwritesummaryfile: 'FlatFileLogging can not write summary file',
        property_systemmessages_name_flatfileloggingcannotwritealarmfile: 'FlatFileLogging can not write alarm file',
        property_systemmessages_name_vavideolost: 'VA service hast lost video signale',
        property_systemmessages_name_vavideoavailable: 'VA service video signal recovered',
        property_systemmessages_name_vathirdpartycommunicationlost: 'VA communication with third-party system lost',
        property_systemmessages_name_vathirdpartycommunicationavailable: 'VA communication with third-party system restored',
        property_systemmessages_name_restart: 'Services have been started/restarted',
        property_systemmessages_name_restop: 'Services have been stopped',
        property_systemmessages_name_dmthresholdsvaluesexceeded: 'Threshhold values for DM statistic have been exceeded',
        property_systemmessages_name_mdbdtatisticsnotavailable: 'MDB statistics are not available',
        property_systemmessages_name_mdbautomatedvideobackupstarted: 'MDB automated video backup started',
        property_systemmessages_name_mdbautomatedvideobackupcompleted: 'MDB automated video backup completed',
        property_systemmessages_name_mdbautomatedvideobackupinterrupted: 'MDB automated video backup interrupted',
        property_systemmessages_name_edgestorageimportssuccessful: 'Edge storage import was successfull',
        property_systemmessages_name_edgestorageimportinterrupted: 'Edge storage import was interrupted',
        property_systemmessages_name_coreslavereachableagain: 'Sub Core reachable again',
        property_systemmessages_name_userdeactivatedafterfailedloginattempts: 'User deactivated',
        property_systemmessages_name_cmdeviceconnectivityproblemresolved: 'Device connectivity problem resolved',
        property_systemmessages_connection_user_subscribed: 'Subscribe to event:',
        property_systemmessages_deadtime: 'Deadtime (seconds): ',
        property_systemmessages_affected_service: 'Affected Services:',
        configure_threshold_sites_title: 'Configure thresholds for selected Site(s)',
        configure_threshold_services_title: 'Configure thresholds for selected Service(s)',
        configure_threshold_systems_title: 'Configure thresholds for selected System(s)',
        configure_threshold_sites_description: 'You are about to change the thresholds for the selected sites. You can either set custom thresholds for the selected sites or use the default values from the settings.',
        configure_threshold_services_description: 'You are about to change the thresholds for the selected services. You can either set custom thresholds for the selected services or use the default values. These default values will be either from the settings or from the thresholds set for the related site.',
        configure_threshold_systems_description: 'You are about to change the thresholds for the selected systems. You can either set custom thresholds for the selected systems or use the default values. These default values will be either from the settings or from the thresholds set for the related site.',
        configure_threshold_cpu: 'Thresholds CPU (%):',
        configure_threshold_memory: 'Thresholds Memory (%):',
        configure_threshold_disk: 'Thresholds Disk (%):',
        configure_threshold_network: 'Thresholds Network (%):',
        configure_threshold_use_default_label: 'Use default values',
        configure_threshold_button_label: 'Update',
        threshold_type_unknown: 'Unknown',
        threshold_type_entity: 'Entity',
        threshold_type_site: 'Site',
        threshold_type_settings: 'Settings',
        threshold_type_prefix: 'Source:',
        labels_table_head_name: 'Name',
        apiKeys_table_head_apikey: 'API Key',
        apiKeys_table_head_creator: 'Creator',
        apiKeys_table_head_creationdate: 'Creation Date',
        apiKeys_create: 'Create API Key',
        apikey_created_success: 'API Key successfully created',
        apikey_created_error: 'Failed to create API Key',
        apikey_delete_sucess: 'API Key successfully deleted',
        apikey_delete_error: 'Failed to delete API Key',
        apiKey_delete_tooltip: 'Delete Api Key(s)',
        edit_label_title: 'Change the name of a label',
        edit_label_description: 'You are about to change the name of a label. ',
        edit_label_name_label: 'Name:',
        edit_label_button_label: 'Update',
        edit_label_error_unique_labelname: 'Label name is already taken',
        multiconfig_labels_title: 'Change common labels',
        multiconfig_labels_description: 'Here you can edit labels for the selected Sites.',
        multiconfig_labels_labels_label: 'Labels:',
        multiconfig_labels_button_label: 'Update',
        multiconfig_labels_overwrite_label: 'Override:',
        multiconfig_labels_success_message: 'Label(s) successfully updated',
        multiconfig_labels_error_message: 'Failed to update label(s)',
        multiconfig_label_override_warning_title: 'Warning',
        multiconfig_label_override_warning_message: 'Attention! You activated override mode. This will remove all existing labels and then apply the selected labels above.',
        notification_priority_title: 'Change notification priority',
        notification_priority_description: 'Here you can change the desired notification level for the selected entities',
        notification_priority_priority_label: 'Notification priority:',
        notification_priority_high: 'High',
        notification_priority_normal: 'Normal',
        notification_priority_low: 'Low',
        site_events_group_resolved_tooltip_group: 'Group resolved issues',
        site_events_group_resolved_tooltip_ungroup: 'Show flat list',
        error_failed_request: 'Request Failed'
    },
    de: {
        general_no: 'Kein(e)',
        login_input_username_placeholder: 'Benutzername',
        login_input_password_placeholder: 'Passwort',
        login_remember_me: 'Angemeldet bleiben',
        login_button_submit: 'Login',
        login_button_windows_login: 'Login mit Windows Authentifizierung',
        login_request_error: 'Login fehlgeschlagen',
        login_user_rights_were_changed: 'Sie wurden ausgeloggt weil sich ihre Rechte geändert haben',
        login_no_connection_title: 'Keine Verbindung',
        login_no_connection_description: 'Es konnte keine Verbindung hergestellt werden. Bitte versuchen Sie es erneut.',
        login_no_connection_button: 'Erneut versuchen',
        language_picker_selection_english: 'English',
        language_picker_selection_german: 'Deutsch',
        language_picker_selection_french: 'Français',
        sidenav_dashboard: 'Übersicht',
        sidenav_map: 'Karte',
        sidenav_control_centre: 'Standortadministration',
        sidenav_sites: 'Standorte',
        sidenav_site_users: 'Benutzer',
        sidenav_site_groups: 'Gruppen',
        sidenav_cameras: 'Kameras',
        sidenav_site_services: 'Dienste',
        sidenav_site_jobs: 'Jobs',
        sidenav_monitoring_centre: 'Monitoring',
        sidenav_umbrella_core: 'Umbrella Core',
        sidenav_administration: 'Administration',
        sidenav_administration_users: 'Umbrella Benutzer',
        sidenav_administration_roles: 'Umbrella Rollen',
        sidenav_manual: 'Handbuch',
        sidenav_administration_license: 'Lizenz',
        sidenav_site_license_update: 'Lizenzen',
        camera_firmware_core: 'Benachrichtigungen',
        sidenav_site_events: 'Events',
        sidenav_email: 'E-Mail',
        sidenav_settings: 'Einstellungen',
        sidenav_camera_firmwares: 'Kamerafirmwares',
        sidenav_site_time_profile: 'Zeitprofile',
        sidenav_report_center: 'Berichte',
        sidenav_reports: 'Inventarberichte',
        sidenav_audit_reports: 'Auditberichte',
        sidenav_site_systems: 'Systeme',
        sidenav_access_control_card_holders: 'Kartenhalter',
        sidenav_access_control_doors: 'Türen',
        sidenav_plugins: 'Plugins',
        sidenav_administration_labels: 'Labels',
        sidenav_administration_apiKeys: 'API Keys',
        profile_drawer_profile_information: 'Profilübersicht:',
        profile_drawer_role: 'Rollen:',
        profile_drawer_last_login: 'Zuletzt angemeldet:',
        profile_drawer_logout_button: 'Abmelden',
        profile_drawer_change_password_button: 'Passwort ändern',
        profile_drawer_upload_profile_image_error: 'Hochladen eines Profilbildes fehlgeschlagen',
        profile_drawer_upload_profile_image_success: 'Profilbild erfolgreich hochgeladen',
        profile_drawer_upload_profile_image_invalid_format: 'Bitte ein gültiges Bildformat verwenden (jpg oder png)',
        profile_drawer_upload_profile_image_size_limit: 'Maximale Profilbildgröße beträgt 5 MB',
        site_states_heading: 'Standortstatus',
        site_states_total: 'Gesamtzahl',
        site_states_healthy: 'Optimal',
        site_states_warning: 'Störung',
        site_states_error: 'Kritisch',
        site_states_offline: 'Offline',
        site_states_ignored: 'Ignoriert',
        site_states_updating: 'Aktualisierung',
        site_states_vms_not_reachable: 'Verbindungsprobleme',
        site_states_error_tooltip: '{countNotReachable} nicht erreichbar {br} {countOffline} Offline',
        site_states_healthy_tooltip: '{countHealthy} Optimal {br} {countUpdating} Aktualisierung',
        site_state_chart_tooltip: 'Dieses Diagramm zeigt das Verhältnis der Gesamtzahl der Standorte zu den Anlagen mit dem Status Optimal, Störung, Fehler.',
        site_approvals_heading: 'Standortbestätigung',
        site_approvals_total: 'Gesamtzahl',
        site_approvals_approved: 'Bestätigt',
        site_approvals_waiting_for_approval: 'Unbestätigt',
        site_approvals_chart_tooltip: 'Dieses Diagramm zeigt das Verhältnis der Gesamtzahl der Standorte zu den Anlagen die noch nicht bestätigt wurden.',
        cameras_chart_total: 'Gesamtzahl',
        cameras_chart_heading: 'Kamerastatus',
        cameras_chart_warning: 'Störung',
        cameras_chart_healthy: 'Optimal',
        cameras_chart_critical: 'Kritisch',
        cameras_chart_offline: 'Offline',
        cameras_chart_ignore: 'Ignoriert',
        cameras_chart_tooltip: 'Dieses Diagramm zeigt das Verhältnis der Gesamtzahl der Kameras zu den Kameras die sich im Status Warnung befinden.',
        umbrella_dashboard_site_systems: 'Systemstatus',
        umbrella_dashboard_site_systems_healthy: 'Optimal',
        umbrella_dashboard_site_systems_warning: 'Störung',
        umbrella_dashboard_site_systems_critical: 'Kritisch',
        umbrella_dashboard_site_systems_offline: 'Offline',
        umbrella_dashboard_site_systems_ignore: 'Ignoriert',
        sites_with_location_heading: 'Karte',
        sites_with_location_view_map: 'Karte anzeigen',
        sites_with_location_healthy: 'Optimal',
        sites_with_location_error: 'Kritisch',
        sites_with_location_warning: 'Warnung',
        sites_with_location_offline: 'Offline',
        sites_with_location_ignore: 'Ignoriert',
        recent_site_jobs_heading: 'Letzte Jobs',
        recent_site_jobs_view_jobs: 'Zeige Jobs',
        recent_site_jobs_no_jobs: 'Keine Jobs',
        recent_site_jobs_description_column_name: 'BESCHREIBUNG',
        recent_site_jobs_state_column_name: 'ZUSTAND',
        recent_site_jobs_created_at_column_name: 'ERSTELLT',
        site_list_approved: 'Bestätigt',
        site_list_unapproved: 'Unbestätigt',
        site_list_approve_button: 'Bestätigen',
        site_list_table_head_name: 'Name',
        site_list_table_head_location: 'Adresse',
        site_list_table_head_state: 'Zustand',
        site_list_table_head_server_version: 'Serverversion',
        site_list_table_head_server_id: 'Server ID',
        site_list_table_head_labels: 'Labels',
        site_list_table_head_approved: 'Bestätigt',
        site_list_table_head_type: 'Typ',
        site_list_table_head_version: 'Version',
        site_list_table_head_client_version: 'Clientversion',
        site_list_table_head_last_heartbeat: 'Letzter Ping',
        site_list_table_head_ip: 'IP',
        site_list_table_head_host: 'Host',
        site_list_table_head_machine_code: 'Machine Code',
        site_list_sort_tooltip: 'Sortieren',
        site_list_group_tooltip: 'Gruppieren',
        site_list_approve_success: 'Standorte erfolgreich bestätigt',
        site_list_approve_error: 'Bestätigen von Standorten fehlgeschlagen',
        site_list_toolbar_selected_sites: '{count} ausgewählt',
        site_list_toolbar_delete_sites_tooltip: 'Lösche Standorte',
        site_list_toolbar_delete_sites_success: 'Standorte wurden erfolgreich gelöscht',
        site_list_toolbar_delete_sites_error: 'Standort löschen fehlgeschlagen',
        site_list_toolbar_create_user_tooltip: 'Benutzer hinzufügen',
        site_list_toolbar_create_card_holder_tooltip: 'Kartenhalter hinzufügen',
        site_list_toolbar_create_site_group_tooltip: 'Gruppe hinzufügen',
        site_list_group_sitestate_healthy: 'Optimal',
        site_list_group_sitestate_offline: 'Offline',
        site_list_group_sitestate_warning: 'Störung',
        site_list_group_sitestate_updating: 'Wird aktualisiert',
        site_list_group_sitestate_noconnection: 'Keine Verbindung',
        site_list_group_sitestate_ignored: 'Ignoriert',
        site_list_connection_user_password: 'Passwort des Verbindungsbenutzers ändern',
        site_list_table_head_approve_multiple_sites: 'Standorte bestätigen',
        site_list_table_head_create_report: 'Inventarbericht erstellen',
        site_list_report_success: 'Reportauftrag erfolgreich erstellt',
        site_list_report_error: 'Reportauftrag erstellen fehlgeschlagen',
        site_list_table_head_create_audit_report: 'Auditbericht erstellen',
        site_list_set_custom_comments_multiple_sites: 'Kommentare für Standort(e) setzen',
        site_list_table_head_ignore_site: 'Standort ignorieren',
        site_list_restart_site: 'Standortverbindung neustarten',
        site_list_restart_error_message: 'Erstellen der Jobs fehlgeschlagen',
        site_list_restart_update_success_message: 'Job(s) erfolgreich erstellt',
        site_list_ignore_success_message: 'Erfolgreich',
        site_list_ignore_error_message: 'Fehlgeschlagen',
        plugin_list_toolbar_delete_plugins_tooltip: 'Lösche Plugins',
        plugin_list_toolbar_delete_plugins_success: 'Plugins wurden erfolgreich gelöscht',
        plugin_list_toolbar_delete_plugins_error: 'Plugins löschen fehlgeschlagen',
        plugin_list_table_head_sitename: 'Standortname',
        plugin_list_table_head_port: 'Port',
        plugin_list_table_head_hostname: 'Hostname',
        plugin_list_table_head_ignoredMonitoring: 'Ignoriert',
        site_list_toolbar_threshold_configuration: 'Monitoring Schwellwerte konfigurieren',
        site_list_toolbar_system_messages_configuration: 'System Nachrichten Konfiguration',
        site_list_toolbar_edit_labels: 'Labels editieren',
        site_list_toolbar_notification_priority_tooltip: 'Notification Priorität ändern',
        worst_performance_sites_title: 'Kritische Standorte',
        worst_performance_sites_btn: 'Standorte',
        worst_performance_no_data: 'Keine problematischen Standorte',
        worst_performance_site_name_column: 'STANDORTNAME',
        worst_performance_cpu_usage_column: 'CPU VERBRAUCH',
        worst_performance_memory_usage_column: 'SPEICHERVERBRAUCH',
        recent_site_events_column_description: 'BESCHREIBUNG',
        recent_site_events_column_site: 'STANDORT',
        recent_site_events_column_timestamp: 'DATUM',
        umbrella_dashboard_running_site_services: 'Dienststatus',
        umbrella_dashboard_running_site_services_tooltip: 'Dienststatus',
        umbrella_dashboard_running_site_services_total: 'Gesamtzahl',
        umbrella_dashboard_running_site_services_critical: 'Kritisch',
        umbrella_dashboard_running_site_services_warning: 'Störung',
        umbrella_dashboard_running_site_services_healthy: 'Optimal',
        umbrella_dashboard_running_site_services_offline: 'Offline',
        umbrella_dashboard_running_site_services_ignored: 'Ignoriert',
        change_site_connection_user_modal_title: 'Password des Verbindungsbenutzers ändern',
        change_site_connection_user_modal_description: 'Sie sind dabei das Passwort des Verbindungsbenutzers zum Standort zu ändern.',
        change_site_connection_user_modal_new_password_input_label: 'Neues Passwort:',
        change_site_connection_user_modal_submit_button: 'Erzeuge \'Passwort ändern\' Job(s)',
        change_site_connection_user_modal_form_description: 'Das Passwort wird nicht sofort geändert. Ein Job wird erstellt und ausgeführt.',
        change_site_connection_user_job_created_success: 'Verbindungsbenutzerpasswort ändern\' Job(s) erfolgreich erstellt',
        change_site_connection_user_job_created_error: 'Fehler beim Erstellen der Job(s)',
        change_site_user_name_modal_title: 'Benutzer umbenennen',
        change_site_user_modal_description: 'Ausgewählte Benutzer umbenennen',
        change_site_user_modal_username_input_label: 'Neuer Benutzername:',
        change_site_user_modal_submit_button: 'Erzeuge \'Standortbenutzer umbenennen\' Job(s)',
        change_site_conneciton_user_job_created_success: 'Standortbenutzer umbenennen\' Job(s) erfolgreich erstellt',
        change_site_conneciton_user_job_created_error: 'Fehler beim Erstellen der Job(s)',
        copy_site_user_modal_title: 'Standortbenutzer kopieren',
        copy_site_user_modal_description: 'Benutzer für ausgewählte Standorte kopieren. Bitte geben Sie einen neuen Benutzername und Passwort für den neuen Benutzer an.',
        copy_site_user_modal_username_input_label: 'Neuer Benutzername:',
        copy_site_user_modal_password_input_label: 'Neues Passwort:',
        copy_site_user_modal_submit_button: 'Erzeuge \'Benutzer kopieren\' Job(s)',
        site_users_list_copy_tooltip: 'Benutzer kopieren',
        site_users_list_copy_invalid_tooltip: 'Sie können nur einen Benutzer pro Standort kopieren',
        copy_site_conneciton_user_job_created_success: 'Benutzer kopieren\' Job(s) erfolgreich erstellt',
        copy_site_conneciton_user_job_created_error: 'Fehler beim Erstellen der Job(s)',
        site_users_list_table_head_site_user: 'Benutzer',
        site_users_list_table_head_site: 'Standort',
        site_users_list_table_head_user_labels: 'Benutzerlabels',
        site_users_list_table_head_site_labels: 'Standortlabels',
        site_users_list_toolbar_selected_users: '{count} ausgewählt',
        site_users_list_toolbar_delete_site_users_tooltip: 'Benutzer löschen',
        site_users_list_toolbar_delete_site_users_success: 'Jobs zum Löschen von Benutzern erfolgreich angelegt',
        site_users_list_toolbar_delete_site_users_error: 'Jobs zum Löschen vonBenutzern konnten nicht angelegt werden',
        site_users_list_toolbar_change_password_tooltip: 'Passwort ändern',
        site_users_list_toolbar_change_properties_tooltip: 'Parameter für ausgewählte Benutzer ändern',
        site_users_list_add_site_user_button: 'Benutzer hinzufügen',
        site_users_list_change_name_tooltip: 'Benutzernamen ändern',
        site_users_list_change_name_invalid_tooltip: 'Sie können nur Benutzernamen eines einzigen Standorts gleichzeitig ändern',
        deploy_site_title: 'Standort ausrollen',
        deploy_site_floating_button_tooltip: 'Standort ausrollen',
        deploy_site_description: 'Füllen Sie das Formular aus und laden Sie das ZIP-Archiv herunter, um einen neuen Standort auszurollen. Das Paket entählt einen Installer und alle benötigten Konfigurationsdateien. Folgen Sie den Anweisungen der Konsolenanwendung',
        deploy_site_required_parameters_label: 'Pflichtfelder',
        deploy_site_optional_site_parameters_label: 'Optionale Felder',
        deploy_site_vms_parameters_label: 'Standort Verbindungsparameter:',
        deploy_site_input_core_host_label: 'Core Host:*',
        deploy_site_input_core_port_label: 'Core Port:*',
        deploy_site_input_https_label: 'HTTPS:',
        deploy_site_input_as_a_service_label: 'Windows Dienst:',
        deploy_site_input_service_name_label: 'Windows Dienstname:*',
        deploy_site_input_working_dir_label: 'Arbeitsverzeichnis:*',
        deploy_site_input_working_dir_tooltip: 'Der Pfad muss ein absoluter Pfad sein und darf noch nicht existieren, dieser wird vom Gateway Installer erstellt.',
        deploy_site_input_plugin_type_label: 'Plugintyp:*',
        deploy_site_input_name_label: 'Name:',
        deploy_site_input_location_label: 'Adresse:',
        deploy_site_input_labels_label: 'Labels:',
        deploy_site_input_vms_host_label: 'Host:*',
        deploy_site_input_vms_port_label: 'Port:*',
        deploy_site_input_vms_user_name_label: 'Benutzername:*',
        deploy_site_input_vms_password_label: 'Passwort:*',
        deploy_site_download_installer_button: 'Installer herunterladen',
        deploy_site_instructions: 'Führen Sie Umbrella.Gateway.Installer.exe aus, um den Standort zu installieren',
        deploy_site_input_core_host_placeholder: 'Core Host',
        deploy_site_input_core_port_placeholder: 'Core Port',
        deploy_site_input_service_name_placeholder: 'Windows Dienstname',
        deploy_site_input_working_dir_placeholder: 'Arbeitsverzeichnis',
        deploy_site_input_plugin_type_placeholder: 'Plugintyp',
        deploy_site_input_name_placeholder: 'Name',
        deploy_site_input_location_placeholder: 'Adresse',
        deploy_site_input_labels_placeholder: 'Labels',
        deploy_site_input_vms_host_placeholder: 'Host',
        deploy_site_input_vms_port_placeholder: 'Port',
        deploy_site_input_vms_user_name_placeholder: 'Benutzername',
        deploy_site_input_vms_password_placeholder: 'Passwort',
        deploy_site_input_validation_port: 'Ungültiger Port (1 - 65535)',
        deploy_site_input_validation_name: 'Ungültiger Name',
        deploy_site_input_validation_location: 'Ungültige Adresse',
        deploy_site_input_validation_working_dir: 'Ungültiges Arbeitsverzeichnis',
        deploy_site_input_validation_service_name: 'Ungültiger Dienstname (nur Buchstaben, Zahlen und Unterstrich erlaubt)',
        deploy_site_input_validation_host: 'Ungültiger Hostname',
        deploy_site_success_message: 'Installationspaket erfolgreich heruntergeladen',
        deploy_site_error_message: 'Fehler beim Erstellen des Installationspaketes',
        deploy_site_input_branchless: 'Filialenunabhängig',
        deploy_site_input_branchless_tooltip: 'Wenn man den Modus Filialenunabhängig auswählt, genügt ein Umbrella Gateway für den Standort Main Core um die gesamte Standort Installation und all ihre Filialen zu verwalten. Der eingetragene Standort Host muss dem Standort Core Main entsprechen.',
        create_user_title_preselected_sites: 'Füge neuen Benutzer zu ausgewählten Standort hinzu',
        create_user_title: 'Benutzer hinzufügen',
        create_user_description: 'Füge neuen Benutzer zu ausgewählten Standort hinzu',
        create_user_input_sites_label: 'Standorte:',
        create_user_input_sites_placeholder: 'Standorte auswählen...',
        create_user_input_username_label: 'Benutzername:',
        create_user_sites_jobs_button: 'Erzeuge \'Benutzer hinzufügen\' Job(s)',
        create_user_instructions: 'Benutzer werden nicht sofort erstellt. Es werden Jobs erzeugt, die zu den Standort übertragen und verarbeitet werden.',
        create_user_input_password_label: 'Passwort:',
        create_user_success_message: 'Job(s) erfolgreich erstellt',
        create_user_error_message: 'Erstellen der Jobs fehlgeschlagen',
        create_user_input_email_not_matching_regex: 'Keine gültige E-Mail Adresse',
        create_user_input_email_placeholder: 'E-Mail',
        create_user_input_email_label: 'E-Mail',
        change_password_modal_title: 'Passwort ändern',
        change_password_modal_description: 'Passwort für ausgewählte Benutzer ändern',
        change_password_modal_input_password_label: 'Passwort:',
        change_second_password_modal_input_password_label: 'Zweites Passwort (optional):',
        change_password_modal_button: 'Erzeuge \'Passwort ändern\' Job(s)',
        change_password_modal_success_message: 'Job(s) erfolgreich erstellt',
        change_password_modal_error_message: 'Jobs für Passwort ändern konnten nicht erstellt werden',
        change_password_ignore_second_password: 'Zweites Passwort ignorieren:',
        change_camera_password_modal_title: 'Kamerapasswort ändern',
        change_camera_password_modal_description: 'Passwort für ausgewählte Kameras ändern',
        change_camera_password_modal_input_password_label: 'Passwort:',
        change_camera_password_modal_button: 'Erzeuge \'Kamerapasswort ändern\' Job(s)',
        change_camera_password_modal_success_message: 'Job(s) erfolgreich erstellt',
        change_camera_password_modal_error_message: 'Jobs für Kamerapasswort ändern konnten nicht erstellt werden',
        create_card_holder_title_preselected_sites: 'Neue Kartenhalter zu den ausgewählten Standorten hinzufügen',
        create_card_holder_title: 'Neuen Kartenhalter hinzufügen',
        create_card_holder_description: 'Neue Kartenhalter zu den ausgewählten Standorten hinzufügen',
        create_card_holder_input_sites_label: 'Standorte:',
        create_card_holder_input_sites_placeholder: 'Ausgewählte Standorte:',
        create_card_holder_input_firstname_label: 'Vorname:',
        create_card_holder_input_lastname_label: 'Nachname:',
        create_card_holder_input_cardnumber_label: 'Kartennummer:',
        create_card_holder_input_start_date_label: 'Beginn:',
        create_card_holder_input_end_date_label: 'Ende:',
        create_card_holder_sites_jobs_button: 'Kartenhalter erstellen Job erzeugen',
        create_card_holder_instructions: 'Kartenhalter werden nicht sofort hinzugefügt. Es werden Jobs erzeugt, die auf die Standorte übertragen und dort ausgeführt werden',
        create_card_holder_success_message: 'Job(s) erfolgreich erstellt',
        create_card_holder_error_message: 'Job(s) erstellen fehlgeschlagen',
        system_messages_configuration_modal_title: 'System Nachrichten Konfiguration',
        system_messages_configuration_modal_description: 'Konfiguation der System Nachrichten ändern',
        system_messages_configuration_input_jobs_button: 'Erzeuge Job(s)',
        siteServices_table_head_siteService_name: 'Dienstname',
        siteServices_table_head_site_name: 'Standortname',
        siteServices_table_head_labels: 'Labels',
        siteServices_table_head_site_labels: 'Standortlabels',
        siteServices_table_toolbar_selected_siteServices: '{count} ausgewählt',
        siteServices_table_toolbar_change_properties_tooltip: 'Eigenschaften der ausgewählten Dienste ändern',
        site_services_table_toolbar_restart_service: 'Dienst neustarten',
        site_services_table_restart_service_job_created_success: 'Dienst neustarten Jobs wurden erstellt',
        site_services_table_restart_service_job_created_error: 'Fehler beim Erstellen der Jobs',
        site_services_restart_tooltip: 'Dienste neustarten',
        site_service_performance_tooltip_start: 'Dienste starten',
        site_service_performance_tooltip_stop: 'Dienste stoppen',
        cameras_table_head_camera_name: 'Kameraname',
        cameras_table_head_site_name: 'Standortname',
        cameras_table_head_labels: 'Labels',
        cameras_table_head_site_labels: 'Standortlabels',
        cameras_table_toolbar_selected_cameras: '{count} ausgewählt',
        cameras_table_toolbar_change_properties_tooltip: 'Eigenschaften der ausgewählten Kameras ändern',
        cameras_table_toolbar_change_password_tooltip: 'Passwort der ausgewählten Kameras ändern',
        camera_table_toolbar_apply_firmware_tooltip: 'Firmware aktualisieren',
        apply_firmware_modal_step_one: 'Firmware auswählen',
        apply_firmware_modal_step_two: 'Bestätigen',
        apply_firmware_modal_step_one_title: 'Neue Firmware auswählen',
        apply_firmware_modal_step_one_description: 'Bitte stellen sie sicher, dass die ausgewählten Kameras die neue Firmware unterstützen',
        apply_firmware_modal_next_button: 'Weiter',
        apply_firmware_modal_step_two_title: 'Firmware aktualisieren',
        apply_firmware_modal_step_two_description: 'Die ausgewählten Kameras werden aktualisiert mit {filename}.',
        apply_firmware_modal_create_jobs: 'Job(s) erstellen',
        apply_firmware_modal_cancel: 'Abbrechen',
        apply_firmware_create_job_success: 'Job(s) erfolgreich erstellt',
        apply_firmware_create_job_error: 'Jobs erstellen fehlgeschlagen',
        general_validation_required_field: 'Pflichtfeld',
        general_validation_invalid_field: 'Ungültiger Wert',
        site_plugin_type_fake: 'Test Plugin',
        site_plugin_type_cayugar10: 'Cayuga R10 Plugin',
        site_plugin_type_cayugar11: 'Cayuga R11 Plugin',
        site_plugin_type_cayugar12: 'Cayuga R12 Plugin',
        site_plugin_type_cayugar13: 'Cayuga R13 Plugin',
        site_plugin_type_cayugar14: 'Cayuga R14 Plugin',
        site_plugin_type_cayugar15: 'Cayuga R15 Plugin',
        site_plugin_type_cayugar16: 'Cayuga R16 Plugin',
        site_plugin_type_cayugar17: 'Cayuga R17 Plugin',
        site_plugin_type_situator: 'Qognify Situator Plugin',
        site_plugin_type_qognifyvms71: 'Qognify VMS 7.1 Plugin',
        plugin_type_monitoring: 'Monitoring',
        plugin_type_vms: 'Standort Installation',
        multiselect_create_label: 'Erstelle "{value}"',
        dropdown_selection_none: 'Keine',
        dropdown_selection_true: 'Ja',
        dropdown_selection_false: 'Nein',
        toolbar_auto_refresh: 'Live Updates pausieren',
        toolbar_auto_refresh_play: 'Live Updates starten',
        table_pagination_rows_per_page: 'Zeilen pro Seite',
        table_pagination_displayed_rows: '{from}-{to} von {count}',
        site_details_title: 'Hier können Sie die Parameter des Standortes konfigurieren.',
        site_details_label_placeholder: 'Labels auswählen...',
        site_details_username_label: 'Name:',
        site_details_label_label: 'Label:',
        site_details_location_label: 'Adresse:',
        site_details_ip_label: 'IP:',
        site_details_host_label: 'Host:',
        site_details_type_label: 'Typ:',
        site_details_state_label: 'Zustand:',
        site_details_last_heartbeat_label: 'Letzter Ping:',
        site_details_vms_server_version_label: 'Serverversion:',
        site_details_vms_client_version_label: 'Clientversion:',
        site_details_version_label: 'Version:',
        site_details_vms_server_id_label: 'Server Id:',
        site_details_machine_code_label: 'Machine Code:',
        site_details_update_button: 'Aktualisieren',
        site_details_reset_all_button: 'Alle zurücksetzen',
        site_details_site_update_success_message: 'Standort erfolgreich aktualisiert',
        site_details_site_update_error_message: 'Standortaktualisierung fehlgeschlagen',
        site_details_fetch_site_details_error: 'Standortdetails holen fehlgeschlagen',
        site_details_name_error_message: 'Standortname eintragen',
        site_details_location_error_message: 'Ungültige Adresse',
        site_details_tab_details: 'Details',
        site_details_tab_comments: 'Kommentare',
        site_details_tab_issues: 'Probleme',
        site_details_issues_title: 'Hier können Sie die Probleme dieses Standortes anschauen.',
        site_details_issues_start_tooltip: 'Starten',
        site_details_issues_stop_tooltip: 'Stoppen',
        site_details_issues_restart_tooltip: 'Neustarten',
        site_details_issues_ignore_tooltip: 'Ignorieren',
        site_details_issues_cameras: 'Kameras',
        site_details_issues_systems: 'Systeme',
        site_details_issues_services: 'Dienste',
        site_details_comment_update_success_message: 'Kommentar erfolgreich aktualisiert',
        site_details_comment_update_error_message: 'Kommentaraktualisierung fehlgeschlagen',
        site_details_comment_title: 'Titel',
        site_details_comment_description: 'Beschreibung',
        site_details_comment_update_comment: 'Kommentar aktualisieren',
        site_details_comment_add_comment: 'Kommentar hinzufügen',
        site_details_comment_update_comments: 'Aktualisieren',
        site_details_comment_max_comments_reached: 'Maximum erreicht',
        site_details_comment_reset_comments: 'Alle zurücksetzen',
        site_details_comments_title: 'Hier können Sie die Kommentare des Standortes ändern oder hinzufügen.',
        site_details_comment_remove_comment: 'Kommentar löschen',
        site_details_comment_clear_comment: 'Löschen',
        site_details_comment_list_comments_group: 'Kommentare',
        property_general_enabled: 'Aktiviert',
        property_general_status: 'Status',
        property_general_statuscode: 'Statuscode',
        property_general_statustimestamp: 'Status Zeitstempel',
        property_device_model: 'Modell',
        property_device_manufacturer: 'Hersteller',
        property_device_host: 'Host',
        property_device_firmware: 'Firmware',
        property_videostream_fps_01: 'Stream_Framerate.01',
        property_videostream_fps_02: 'Stream_Framerate.02',
        property_videostream_fps_03: 'Stream_Framerate.03',
        property_videostream_fps_04: 'Stream_Framerate.04',
        property_videostream_fps_05: 'Stream_Framerate.05',
        property_videostream_fps_06: 'Stream_Framerate.06',
        property_videostream_resolution_01: 'Stream_Resolution.01',
        property_videostream_resolution_02: 'Stream_Resolution.02',
        property_videostream_resolution_03: 'Stream_Resolution.03',
        property_videostream_resolution_04: 'Stream_Resolution.04',
        property_videostream_resolution_05: 'Stream_Resolution.05',
        property_videostream_resolution_06: 'Stream_Resolution.06',
        property_videostream_videocodec_01: 'Stream_VideoCodec.01',
        property_videostream_videocodec_02: 'Stream_VideoCodec.02',
        property_videostream_videocodec_03: 'Stream_VideoCodec.03',
        property_videostream_videocodec_04: 'Stream_VideoCodec.04',
        property_videostream_videocodec_05: 'Stream_VideoCodec.05',
        property_videostream_videocodec_06: 'Stream_VideoCodec.06',
        property_groups_users: 'Benutzer',
        property_rights_activate_active_directory_support: 'Active Directory Unterstützung aktivieren',
        property_rights_user_must_use_secure_password: 'Benutzer muss ein sicheres Passwort verwenden',
        property_rights_user_may_change_own_password: 'Benutzer darf eigenes Passwort ändern',
        property_rights_use_secure_password_for_exports: 'Sicheres Passwort für Exporte verwenden',
        property_rights_comment_necessary_for_changing_to_archive_mode: 'Wechsel in den Archivmodus erfodert Kommentar',
        property_rights_user_must_change_password_regularly: 'Benutzer muss sein Passwort regelmäßig ändern (Tage)',
        property_rights_limit_archive_access: 'Archivzugriff einschränken (Minuten)',
        property_rights_can_manually_record: 'Aufnahmeschalter anzeigen',
        property_global_rights_camera_live: 'Livevideo anzeigen',
        property_global_rights_camera_recorded: 'Videoaufzeichnungen anzeigen',
        property_global_rights_camera_recorded_delete: 'Aufzeichnungen löschen',
        property_global_rights_camera_write_protection: 'Überschreibschutz',
        property_global_rights_camera_ptz: 'Kamera-PTZ',
        property_global_rights_camera_ptz_priority: 'Kamerasperre',
        property_global_rights_camera_preset: 'Kamera-Presets verwenden',
        property_global_rights_camera_preset_create: 'Kamera-Presets anlegen',
        property_global_rights_camera_export_seetec: 'Kamera exportieren',
        property_global_rights_camera_export_avi: 'Kamera exportieren (AVI)',
        property_global_rights_user_privacy_zones_right: 'Privacy-Masking',
        property_global_rights_mpeg_audio: 'MPEG-Audio',
        property_global_rights_reference_image_create: 'Referenzbild erstellen',
        property_global_rights_intrusion_detection: 'Einbruchmeldeanlage',
        property_global_rights_map_use: 'Lageplan',
        property_global_rights_layer_use: 'Ansicht',
        property_global_rights_button_use: 'Button',
        property_global_rights_report_mode: 'Ereignisauswertung',
        property_global_rights_report_mode_templates: 'Berichtvorlagen bearbeiten',
        property_global_rights_counting_data_interpretation: 'Zahlungsauswertung',
        property_global_rights_lp_group_use: 'Kennzeichengruppe verwenden',
        property_global_rights_lp_group_change: 'Kennzeichengruppe ändern',
        property_global_rights_event_interface: 'Zutrittskontrolle',
        property_global_rights_access_control_use_data_editor: 'Editor für Zutrittskontrolldaten',
        property_global_rights_use_event_interface_commands: 'Triggerkommandos für Zutrittskontrolle',
        property_events_sourceid: 'Quelle ID',
        property_events_causeid: 'Ursache ID',
        property_events_sourcename: 'Quelle',
        property_events_causename: 'Ursache',
        property_events_value: 'Wert',
        property_events_related_positive_event: 'Zusammenhängendes Positiv Event',
        property_events_affected_negative_events: 'Zusammenhängende Negativ Events',
        property_events_description: 'Beschreibung',
        property_recording_standard_recording_enabled: 'Standardaufzeichnung aktiviert',
        property_recording_standard_recording_period: 'Standardaufzeichnung Aufnahmezeitraum',
        property_recording_standard_recording_timelimit: 'Standardaufzeichnung Zeitbegrenzung (Minuten)',
        property_recording_alarm_recording_enabled: 'Alarmaufzeichnung aktiviert',
        property_recording_alarm_recording_timelimit: 'Alarmaufzeichnung Zeitbegrenzung (Minuten)',
        property_recording_alarm_recording_stream: 'Alarmaufzeichnung Stream',
        property_recording_alarm_recording_prebuffer: 'Alarmaufzeichnung Voralarm-Puffer (Sekunden)',
        property_recording_alarm_recording_postduration: 'Alarmaufzeichnung Nachalarm Dauer (Sekunden)',
        property_recording_statistics_treshold_enabled: 'Schwellwerte aktivieren',
        property_recording_statistics_maximum_packetloss: 'Max. Paketverlust (%)',
        property_recording_statistics_standard_recording_framerate_delta: 'Standardaufzeichnung Bildrate Delta (fps)',
        property_recording_statistics_standard_recording_maximum_frames_dropped: 'Standardaufzeichnung Max. Anz. ausgelassener Bilder',
        property_recording_statistics_alarm_recording_framerate_delta: 'Alarmaufzeichnung Bildrate Delta (fps)',
        property_recording_statistics_alarm_recording_maximum_frames_dropped: 'Alarmaufzeichnung Max. Anz. ausgelassener Bilder',
        property_recording_statistics_notifications: 'MDB-Statistik-Benachrichtigungen aktivieren',
        property_recording_statistics_recording_state: 'Status der Aufzeichnung',
        property_recording_statistics_standard_max_timeperiod: 'Standardaufzeichnung Max. Zeitraum',
        property_recording_statistics_alarm_max_timeperiod: 'Alarmaufzeichnung Max. Zeitraum',
        property_recording_statistics_standard_actual_timeperiod: 'Standardaufzeichnung Tatsächlicher Zeitraum',
        property_recording_statistics_alarm_actual_timeperiod: 'Alarmaufzeichnung Tatsächlicher Zeitraum',
        property_recording_statistics_standard_oldest_frametime: 'Standardaufzeichnung ältester Bildzeitstempel',
        property_recording_statistics_alarm_oldest_frametime: 'Alarmaufzeichnung ältester Bildzeitstempel',
        property_recording_statistics_timestamp: 'Aufzeichnungsstatistik Zeitstempel',
        property_recording_statistics_devicemanager: 'Device Manager',
        property_runtime_runslocal: 'Läuft lokal',
        property_runtime_memoryUsage: 'Speicherverbrauch (MB)',
        property_runtime_diskUsage: 'Festplattenspeicherverbrauch (GB)',
        property_runtime_cpuUsage: 'CPU Verbrauch (%)',
        property_timeprofile_profile: 'Zeitprofile',
        property_device_firmware_upgrade: 'Unterstützt Firmwareaktualisierung',
        property_device_password_modify: 'Unterstützt Kamerapasswort ändern',
        property_device_description: 'Beschreibung',
        property_device_videoserver_id: 'Videoserver Id',
        property_device_videoserver_description: 'Videoserver Beschreibung',
        property_administrative_rights_video: 'Kameras',
        property_administrative_rights_other_hardware: 'Sonstige Hardware',
        property_administrative_event_interfaces: 'Event Interfaces',
        property_administrative_user: 'Benutzer',
        property_administrative_time_pattern: 'Zeitverwaltung',
        property_administrative_company_calendar: 'Betriebskalender',
        property_administrative_rights_alarm: 'Alarme',
        property_administrative_rights_layer: 'Ansichten',
        property_administrative_rights_map: 'Lagerpläne',
        property_administrative_rights_button: 'Buttons',
        property_administrative_rights_patrol: 'Wächterrundgänge',
        property_administrative_rights_sequence: 'Sequenzen',
        property_administrative_rights_videowall: 'Videowände',
        property_administrative_rights_LPR: 'Kennzeichengruppen',
        property_administrative_rights_server: 'Server',
        property_administrative_rights_system: 'System',
        property_administrative_rights_website: 'Webseiten',
        property_administrative_access_control: 'Zutrittskontrolle',
        property_runtime_host: 'Host',
        property_runtime_serviceName: 'Servicename',
        property_cardholder_base_card_number: 'Kartennummer',
        property_cardholder_employee_number: 'Angestelltennummer',
        property_door_state: 'Status',
        site_user_details_tab_details: 'Details',
        site_user_details_title: 'Hier können Sie die Benutzerparameter konfigurieren.',
        site_user_details_user_name: 'Benutzername:',
        site_user_details_site: 'Standort:',
        site_user_details_label: 'Label:',
        site_user_details_update_button: 'Details aktualisieren',
        site_user_details_user_update_success_message: 'Benutzer erfolgreich aktualisiert',
        site_user_details_user_update_error_message: 'Benutzeraktualisierung fehlgeschlagen',
        site_user_details_user_properties_update_success_message: 'Job(s) erfolgreich erstellt',
        site_user_details_user_properties_update_error_message: 'Erstellen der Jobs fehlgeschlagen',
        site_user_details_properties_title: 'Hier können Sie die SBenutzereigenschaften konfigurieren.',
        site_user_details_administrative_properties_title: 'Es werden nur die administrativen Rechte der Filiale berücksichtigt, in der sich der Benutzer befindet.',
        siteService_details_tab_details: 'Details',
        siteService_details_title: 'Hier können Sie die Dienstparameter konfigurieren.',
        siteService_details_properties_title: 'Hier können Sie die Diensteigenschaften konfigurieren.',
        siteService_details_siteService_name: 'Name:',
        siteService_details_siteService_site: 'Standort:',
        siteService_details_siteService_label: 'Label:',
        siteService_details_update_button: 'Details aktualisieren',
        siteService_details_update_success_message: 'Dienst erfolgreich aktualisiert',
        siteService_details_update_error_message: 'Dienstaktualisierung fehlgeschlagen',
        siteService_details_properties_update_error_message: 'Erstellen der Jobs fehlgeschlagen',
        siteService_details_properties_update_success_message: 'Job(s) erfolgreich erstellt',
        camera_details_tab_details: 'Details',
        camera_details_title: 'Hier können Sie die Kameraparameter konfigurieren.',
        camera_details_properties_title: 'Hier können Sie die Kameraeigenschaften konfigurieren.',
        camera_details_camera_name: 'Name:',
        camera_details_camera_site: 'Standort:',
        camera_details_camera_label: 'Label:',
        camera_details_update_button: 'Details aktualisieren',
        camera_details_update_success_message: 'Kamera erfolgreich aktualisiert',
        camera_details_update_error_message: 'Kameraaktualisierung fehlgeschlagen',
        camera_details_properties_update_error_message: 'Erstellen der Jobs fehlgeschlagen',
        camera_details_properties_update_success_message: 'Job(s) erfolgreich erstellt',
        properties_form_button_reset_all: 'Alles zurücksetzen',
        properties_form_button_create_jobs: 'Erzeuge Änderungsjob(s)',
        properties_form_note: 'Alle Änderungen werden erst beim Ausführen der Jobs durchgeführt.',
        properties_form_button_reset: 'Zurücksetzen',
        site_jobs_status_done: 'Erfolgreich',
        site_jobs_status_failed: 'Fehlgeschlagen',
        site_jobs_status_to_do: 'Nicht begonnen',
        site_jobs_status_transmitted: 'Übertragen',
        site_jobs_status_canceled: 'Abgebrochen',
        site_jobs_table_head_name: 'Name',
        site_jobs_table_head_state: 'Status',
        site_jobs_table_head_createdBy: 'Erstellt von',
        site_jobs_table_head_created: 'Erstellt',
        site_jobs_table_head_updated: 'Aktualisiert',
        site_jobs_table_head_sites: 'Standorte',
        site_jobs_table_head_about: 'Beschreibung',
        site_jobs_table_head_message: 'Ergebnis',
        site_jobs_table_head_comment: 'Kommentar',
        site_jobs_table_group_jobstate_todo: 'Nicht begonnen',
        site_jobs_table_group_jobstate_transmitted: 'Übertragen',
        site_jobs_table_group_jobstate_canceled: 'Abgebrochen',
        site_jobs_table_group_jobstate_done: 'Erfolgreich',
        site_jobs_table_group_jobstate_failed: 'Fehlgeschlagen',
        site_jobs_table_toolbar_selected_count: '{count} ausgewählt',
        site_jobs_table_toolbar_retry_job: 'Ausgewählte Jobs wiederholen',
        site_jobs_table_toolbar_cancel_job: 'Ausgewählte Jobs abbrechen',
        site_jobs_table_cancel_jobs_success: 'Jobs erfolgreich abgebrochen',
        site_jobs_table_cancel_jobs_error: 'Jobs abbrechen fehlgeschlagen',
        site_jobs_table_retry_jobs_succes: 'Jobs erfolgreich wiederholt',
        site_jobs_table_retry_jobs_error: 'Jobs wiederholen fehlgeschlagen',
        site_job_details_description: 'Beschreibung:',
        site_job_details_comment: 'Kommentar:',
        site_job_details_type: 'Typ:',
        site_job_details_site: 'Standort:',
        site_job_details_createdBy: 'Erstellt von:',
        site_job_details_created: 'Erstellt:',
        site_job_details_transmitted: 'Übertragen:',
        site_job_details_canceled: 'Abgebrochen:',
        site_job_details_failed: 'Fehlgeschlagen:',
        site_job_details_message: 'Ergebnis:',
        site_job_details_card_created: 'Erstellt',
        site_job_details_card_status: 'Status',
        site_job_details_card_duration: 'Dauer: {duration}',
        batch_job_details_title: 'Batch Job',
        batch_job_details_chart_legend_failed: 'Fehlgeschlagen ({value})',
        batch_job_details_chart_legend_done: 'Erfolgreich ({value})',
        batch_job_details_chart_legend_todo: 'Nicht begonnen ({value})',
        batch_job_details_chart_legend_transmitted: 'Übertragen ({value})',
        batch_job_details_chart_legend_canceled: 'Abgebrochen ({value})',
        batch_job_details_group_jobstate_todo: 'Nicht begonnen',
        batch_job_details_group_jobstate_transmitted: 'Übertragen',
        batch_job_details_group_jobstate_canceled: 'Abgebrochen',
        batch_job_details_group_jobstate_done: 'Erfolgreich',
        batch_job_details_group_jobstate_failed: 'Fehlgeschlagen',
        job_configuration_comment: 'Kommentar:',
        job_configuration_comment_title: 'Job Kommentar',
        create_umbrella_user_title: 'Neuen Umbrella Benutzer hinzufügen',
        create_umbrella_user_subtitle: 'Neuen Umbrella Benutzer hinzufügen und Rollen zuweisen',
        create_umbrella_user_submit_button: 'Umbrella Benutzer hinzufügen',
        create_umbrella_user_success_message: 'Umbrella Benutzer erfolgreich hinzufgefügt',
        create_umbrella_user_error_message: 'Umbrella Benutzer hinzufügen fehlgeschlagen',
        create_umbrella_user_error_unique_username: 'Benutzername existiert bereits!',
        umbrella_user_form_label_username: 'Benutzername:',
        umbrella_user_form_label_password: 'Passwort:',
        umbrella_user_form_label_roles: 'Rollen:',
        umbrella_user_form_placeholder_roles: 'Rollen auswählen',
        umbrella_users_table_head_username: 'Benutzername',
        umbrella_users_table_head_built_in: 'Integriert',
        umbrella_users_table_head_active_directory: 'Active Directory',
        umbrella_users_table_head_roles: 'Rollen',
        umbrella_users_table_head_last_login: 'Letzter Login:',
        umbrella_users_add_user_button: 'Benutzer hinzufügen',
        umbrella_users_list_toolbar_delete_users_tooltip: 'Benutzer löschen',
        umbrella_users_list_toolbar_selected_users: '{count} ausgewählt',
        umbrella_users_list_delete_users_success: 'Benutzer erfolgreich gelöscht',
        umbrella_users_list_delete_users_error: 'Benutzer löschen fehlgeschlagen',
        site_groups_table_head_site_group: 'Gruppe',
        site_groups_table_head_sites: 'Standorte',
        site_groups_table_head_labels: 'Labels',
        site_groups_table_head_site_labels: 'Standortlabels',
        site_groups_toolbar_delete_site_group_tooltip: 'Standortgruppe löschen',
        site_groups_toolbar_selected_site_groups: '{count} ausgewählt',
        site_groups_toolbar_change_properties_tooltip: 'Eigenschaften von ausgewählten Gruppen ändern',
        site_groups_add_site_group_button: 'Gruppe hinzufügen',
        site_groups_list_toolbar_delete_site_groups_success: 'Jobs für das Löschen von Gruppen erfolgreich erstellt',
        site_groups_list_toolbar_delete_site_groups_error: 'Jobs erstellen für das Löschen von Standortgruppen fehlgeschlagen',
        umbrella_roles_table_head_name: 'Name',
        umbrella_roles_table_head_umbrella_admin: 'Umbrella Admin',
        umbrella_roles_table_head_site_admin: 'Standort Admin',
        umbrella_roles_table_head_built_in: 'Integriert',
        umbrella_roles_table_head_users: 'Benutzer',
        umbrella_roles_table_head_sites: 'Standorte',
        umbrella_roles_table_head_active_directory_group: 'Active Directory Gruppe',
        umbrella_roles_table_head_entity_type: 'Entitytyp',
        umbrella_roles_table_head_event_category: 'Eventkategorie',
        umbrella_roles_table_head_event_type: 'Eventtyp',
        umbrella_roles_table_head_event_filter: 'Filter',
        umbrella_roles_table_head_event_email: 'E-Mail',
        umbrella_roles_table_head_contains_filter: 'Filter',
        umbrella_roles_table_head_send_email: 'E-Mail',
        umbrella_roles_table_head_site_labels: 'Standortlabels',
        umbrella_roles_list_toolbar_selected_roles: '{count} ausgewählt',
        umbrella_roles_list_toolbar_delete_roles_tooltip: 'Umbrella Rolle löschen',
        umbrella_roles_list_button_add_role_tooltip: 'Umbrella Rolle hinzufügen',
        umbrella_roles_list_delete_roles_success: 'Rolle/n erfolgreich gelöscht',
        umbrella_roles_list_delete_roles_error: 'Rollen löschen fehlgeschlagen',
        create_site_group_title: 'Neue Gruppe hinzufügen',
        create_site_group_subtitle: 'Hinzufügen einer neuen Gruppe für die ausgewählten Standorte.',
        create_site_group_input_site_group: 'Gruppe:',
        create_site_group_input_sites: 'Standort:',
        create_site_group_input_sites_placeholder: 'Standorte auswählen',
        create_site_group_submit_button: 'Erzeuge \'Gruppe hinzufügen\' Job(s)',
        create_site_group_instructions: 'Die Gruppe wird erst beim Ausführen des Jobs hinzufgefügt.',
        create_site_groups_success_message: 'Job(s) erfolgreich erstellt',
        create_site_groups_error_message: 'Erstellen der Jobs fehlgeschlagen',
        create_umbrella_role_title: 'Neue Umbrella Rolle hinzufügen',
        create_umbrella_role_subtitle: 'Hier kann eine neue Rolle mit den gewünschten Parametern hinzugefügt werden.',
        create_umbrella_role_submit_button: 'Umbrella Rolle hinzufügen',
        create_umbrella_role_success_message: 'Rolle erfolgreich hinzugefügt',
        create_umbrella_role_error_message: 'Rolle hinzufügen fehlgeschlagen',
        umbrella_role_form_label_role_name: 'Name:',
        umbrella_role_form_label_umbrella_administrator: 'Umbrella Administrator:',
        umbrella_role_form_label_site_administrator: 'Standort Administrator:',
        umbrella_role_form_label_users: 'Benutzer:',
        umbrella_role_form_placeholder_users: 'Benutzer auswählen',
        umbrella_role_form_label_sites: 'Standorte:',
        umbrella_role_form_label_site_labels: 'Standortlabels:',
        umbrella_role_form_placeholder_sites: 'Standorte auswählen',
        umbrella_role_form_placeholder_site_labels: 'Standortlabels',
        umbrella_role_form_label_active_directory: 'Active Directory Gruppe:',
        umbrella_role_form_placeholder_active_directory: 'Active Directory Gruppe auswählen',
        umbrella_role_form_error_unique_role: 'Rolle existiert bereits!',
        umbrella_role_form_admin_rights_include_all_sites: 'Umbrella Adminrechte beinhalten alle Standorte',
        umbrella_role_form_rights_reports_default: 'Berichte:',
        umbrella_role_form_rights_reports_site_maintenance: 'Inventarberichte:',
        umbrella_role_form_rights_reports_site_audit: 'Auditberichte:',
        umbrella_role_form_rights_approve_gateway: 'Standort freigeben:',
        umbrella_role_form_rights_deploy_gateway: 'Standort ausrollen:',
        umbrella_role_form_rights_delete_gateway: 'Standort löschen:',
        umbrella_role_form_rights_upload_firmware: 'Firmware hochladen:',
        umbrella_role_form_rights_restart_camera_and_sites: 'Neustarten von Kameras / Diensten:',
        umbrella_role_form_rights_monitoring: 'Monitoring:',
        umbrella_role_form_rights_ignore: 'Ignorieren:',
        umbrella_role_details_title: 'Umbrella Rolle',
        umbrella_role_details_title_built_in_role: 'Umbrella Rolle',
        umbrella_role_details_subtitle: 'Details einer Umbrella Rolle {role}',
        umbrella_role_details_subtitle_built_in_role: 'Details einer Umbrella Role {role} - Automatisch angelegte Rollen können nicht editiert werden',
        umbrella_role_details_update_success_message: 'Rolle erfolgreich aktualisiert',
        umbrella_role_details_update_error_message: 'Aktualisieren der Rolle fehlgeschlagen',
        site_group_details_update_success_message: 'Gruppe erfolgreich aktualisiert',
        site_group_details_update_error_message: 'Gruppenaktualisierung fehlgeschlagen',
        site_group_details_title: 'Hier können Sie die Gruppenparameter konfigurieren.',
        site_group_details_site_group: 'Gruppe:',
        site_group_details_site: 'Standort:',
        site_group_details_labels: 'Labels:',
        site_group_details_update_button: 'Details aktualisieren',
        site_group_details_tab_details: 'Details',
        site_group_details_properties_title: 'Hier können Sie die Gruppenparameter konfigurieren',
        site_group_details_properties_update_success_message: 'Job(s) erfolgreich erstellt',
        site_group_details_properties_update_error_message: 'Jobs erstellen fehlgeschlagen',
        umbrella_user_details_title: '{name}s Details',
        umbrella_user_details_title_built_in_user: '{name}s Details',
        umbrella_user_details_subtitle: 'Hier können die Details von {name} geändert werden',
        umbrella_user_details_subtitle_built_in_user: '{name}s Details. Details eines automatisch angelegten Umbrella Benutzers können nicht editiert werden',
        umbrella_user_details_label_username: 'Benutzername:',
        umbrella_user_details_label_roles: 'Rollen:',
        umbrella_user_details_placeholder_roles: 'Rollen auswählen',
        umbrella_user_details_update_button: 'Details aktualisieren',
        umbrella_user_details_update_success_message: 'Benutzer erfolgreich aktualisiert',
        umbrella_user_details_update_error_message: 'Benutzer aktualisieren fehlgeschlagen',
        password_requirements_require_digit: 'Erfordert eine Zahl',
        password_requirements_required_length: 'Erfordert eine Länge von {lengthNumber} Zeichen',
        password_requirements_require_non_alphanumeric: 'Erfodert mindestens ein nicht alpha-numerisches Zeichen',
        password_requirements_require_uppercase: 'Erfodert mindestes einen Großbuchstaben',
        password_requirements_require_lowercase: 'Erfodert mindestens einen Kleinbuchstaben',
        password_requirements_required_unique_characters: 'Erfodert {uniqueNumber} einzigartige Zeichen',
        password_requirements_title: 'Passwortanforderungen',
        change_properties_multi_no_common_properties: 'Keine gemeinsamen Eigenschaften gefunden',
        change_properties_multi_no_value_placeholder: 'Wert eingeben...',
        change_properties_multi_success_message: 'Job(s) erfolgreich erstellt',
        change_properties_multi_error_message: 'Erstellen der Jobs fehlgeschlagen',
        change_properties_multiple_users_title: 'Parameter für ausgewählte Benutzer ändern',
        change_properties_multiple_users_subtitle: 'Hier können Sie die Parameter der ausgewählten Benutzer anpassen.',
        change_properties_multiple_site_groups_title: 'Eigenschaften von ausgewählten Gruppen ändern',
        change_properties_multiple_site_groups_subtitle: 'Hier können Sie die Parameter der ausgewählten Bruppen anpassen',
        change_properties_multiple_cameras_title: 'Eigenschaften von ausgewählten Kameras ändern',
        change_properties_multiple_cameras_subtitle: 'Hier können Sie die Parameter der ausgewählten Kameras anpassen',
        change_properties_multiple_siteServices_title: 'Eigenschaften von ausgewählten Diensten ändern',
        change_properties_multiple_siteServices_subtitle: 'Hier können Sie die Parameter der ausgewählten Diensten anpassen',
        change_properties_multiple_access_control_cardholders_title: 'Eigenschäften der ausgewählten Kartenhaltern ändern',
        change_properties_multiple_access_control_cardholders_subtitle: 'Hier können sie die Eigenschaften der Kartenhalter anpassen',
        change_properties_multiple_access_control_doors_title: 'Eigenschaften der ausgewählten Türen ändern',
        change_properties_multiple_access_control_doors_subtitle: 'Hier können sie die Eigenschaften der Türen anpassen',
        change_password_description: 'Bitte ändern Sie ihr Passwort.',
        change_password_input_current_password_placeholder: 'Aktuelles Passwort',
        change_password_input_new_password_placeholder: 'Neues Passwort',
        change_password_submit_button: 'Speichern',
        change_password_single_error_message: 'Passwort ändern fehlgeschlagen',
        license_warning_unlicensed_cameras: '{count} unlizensierte Kameras im System gefunden.',
        license_warning_expiration_date: 'Ihre Umbrellalizenz läuft in {days} Tagen ab! Bitte erneuern Sie ihre Lizenz.',
        license_overview_main_title: 'Lizenzmanagement',
        license_overview_sub_title: 'Übersicht der Umbrellalizenz',
        license_overview_unlicensed_cameras: 'Unlizensierte Kameras',
        license_overview_expiration_date: 'Ablaufdatum',
        licenses_chart_in_use_total: 'Gesamtanzahl',
        licenses_chart_heading: 'Lizenzen',
        licenses_chart_licensed_cameras: 'In Verwendung',
        licenses_chart_unlicensed_cameras: 'Unbenutzt',
        license_modules_title: 'Lizenzierte Module',
        license_modules_configuration: 'Standortadministration',
        license_modules_monitoring: 'Monitoring',
        license_modules_reporting: 'Berichte',
        license_machine_code_label: 'Machine Code:',
        license_apply_new_license_label: 'Lizenzschlüssel einspielen:*',
        license_apply_new_license_placeholder: 'Lizenzschlüssel eingeben',
        license_apply_new_license_success_message: 'Lizenz erfolgreich aktualisiert',
        license_apply_new_license_error_message: 'Lizenzupdate fehlgeschlagen',
        license_apply_new_license_info: '* Änderungen am Lizenzschlüssel starten den Umbrella Core sofort neu',
        license_modules_situatorExclusive: 'Situator',
        license_modules_webapi: 'Web API',
        site_license_update_toolbar_update_licenses: 'Lizenz aktualisieren',
        site_license_update_toolbar_export_csv: 'CSV Export',
        site_license_update_upload_file_title: 'Lizenz aktualisieren',
        site_license_update_upload_file_subtitle: 'Laden Sie die Datei hier hoch oder ziehen Sie sie einfach in diesen Bereich.',
        site_license_update_upload_file_box_title: 'Datei hochladen oder hier her ziehen',
        site_license_update_upload_file_ext_restriction: 'Es kann nur eine zip oder key Datei hochgeladen werden.',
        site_license_update_upload_file_check_error: 'Ungültige Lizenzdatei hochgeladen',
        site_license_update_review_subtitle: 'Für folgende Standorte werden die Lizenzen aktualisiert.',
        site_license_update_review_submit: 'Erzeuge Job(s)',
        site_license_update_review_cancel: 'Abbrechen',
        site_license_update_review_job_info: 'Alle Änderungen werden erst beim Ausführen der Jobs durchgeführt.',
        site_license_update_success_message: 'Job(s) erfolgreich erstellt',
        site_license_update_error_message: 'Jobs erstellen fehlgeschlagen',
        site_license_step_one: 'Datei hochladen',
        site_license_step_two: 'Bestätigen',
        quick_action_menu_add_site_user: 'Benutzer hinzufügen',
        quick_action_menu_add_site_group: 'Gruppe hinzufügen',
        site_event_type_status_changed: 'Status geändert',
        site_event_type_system_message: 'System Nachricht',
        site_event_entity_type_camera: 'Kamera',
        site_event_entity_type_runtime: 'Dienst',
        site_event_entity_type_user: 'Benutzer',
        site_event_entity_type_group: 'Gruppe',
        site_event_entity_type_access_control_cardholder: 'Kartenhalter',
        site_event_entity_type_access_control_door: 'Tür',
        site_event_entity_type_unknown: 'Unbekannt',
        site_event_entity_type_site: 'Standort',
        site_event_entity_type_system: 'System',
        site_event_category_info: 'Info',
        site_event_category_error: 'Fehler',
        site_event_category_warning: 'Warnung',
        site_event_category_unknown: 'Unbekannt',
        advanced_job_configuration_title: 'Erweiterte Jobkonfiguration:',
        advanced_job_configuration_execution_datetime: 'Ausführung (Standortzeitzone):',
        advanced_job_configuration_undo_datetime: 'Rückgängigmachen:',
        advanced_job_configuration_retry: 'Versuche:',
        advanced_job_configuration_datepicker_ok: 'OK',
        advanced_job_configuration_datepicker_cancel: 'ABBRECHEN',
        grid_layout_save: 'Layout speichern',
        grid_layout_restore: 'Standard wiederherstellen',
        grid_layout_save_success: 'Layout gespeichert',
        grid_layout_restore_success: 'Layout wieder hergestellt',
        grid_layout_save_error: 'Layout speichern fehlgeschlagen',
        grid_layout_restore_error: 'Layout wiederhestellen fehlgeschlagen',
        site_events_table_head_event_name: 'Eventname:',
        site_events_table_head_site_name: 'Standortname:',
        site_events_table_head_labels: 'Labels',
        site_events_table_head_site_labels: 'Standortlabels',
        site_events_table_head_event_type: 'Eventtyp',
        site_events_table_head_entity_type: 'Entitytyp',
        site_events_table_head_event_category: 'Eventkategorie',
        site_events_table_head_issue_resolved: 'Problem behoben',
        site_events_table_head_timestamp: 'Zeitstempel',
        site_events_table_head_description: 'Beschreibung',
        site_events_details_title: '{name}s Details',
        site_events_details_subtitle: 'Hier können Sie die Parameter des ausgewählten Events anpassen',
        site_events_details_event_type: 'Eventtyp:',
        site_events_details_entity_type: 'Entitytyp:',
        site_events_details_event_category: 'Eventkategorie:',
        site_events_details_issue_resolved: 'Problem behoben:',
        site_events_details_timestamp: 'Zeitstempel:',
        site_events_details_description: 'Beschreibung:',
        site_events_details_details_tab: 'Details',
        site_event_details_updated_success_message: 'Erfolgreich aktualisiert',
        site_event_details_updated_error_message: 'Fehler',
        site_event_details_update_button: 'Event aktualisieren',
        umbrella_core_from_date: 'Von:',
        umbrella_core_to_date: 'Bis:',
        umbrella_core_last_hour: 'Letzte Stunde',
        umbrella_core_last_day: 'Letzter Tag',
        umbrella_core_last_week: 'Letzte Woche',
        umbrella_core_cpu_usage: 'CPU Verbrauch (%)',
        umbrella_core_memory_usage: 'Speicherverbrauch (MB)',
        umbrella_core_request_count: 'Requests',
        umbrella_core_request_duration: 'Requestdauer (ms)',
        umbrella_core_no_data_to_show: 'Keine Daten verfügbar',
        email_configuration_title: 'E-Mail Einstellungen',
        email_configuration_description: 'Ändern Sie die Einstellungen um mit einem SMTP-Server zu kommunizieren und senden Sie eine Test E-Mail um die Einstellungen zu validieren.',
        email_configuration_smtp_address: 'SMTP Host',
        email_configuration_smtp_port: 'SMTP Port',
        email_configuration_username: 'Benutzername',
        email_configuration_password: 'Passwort',
        email_configuration_sender_email: 'Absender E-Mail Adresse',
        email_configuration_encryption_method: 'Verschlüsselung',
        email_configuration_subject: 'Betreff',
        email_configuration_test: 'Test',
        email_configuration_encryption_null: 'Keine Verschlüsselung',
        email_configuration_encryption_ssl: 'SSL/TLS',
        email_configuration_submit: 'Speichern',
        email_configuration_reset_fields: 'Zurücksetzen',
        email_configuration_update_success: 'E-Mail Einstellungen erfolgreich gespeichert',
        email_configuration_update_error: 'E-Mail Einstellungen speichern fehlgeschlagen',
        email_configuration_test_success: 'Test E-Mail erfolgreich',
        email_configuration_test_error: 'Test E-Mail fehlgeschlagen',
        email_configuration_test_receiver_title: 'Test Empfänger',
        email_configuration_test_receiver_label: 'Test Empfänger',
        email_configuration_password_checkbox: 'Passwort setzen',
        email_configuration_host_error: 'Host darf nicht leer sein',
        email_configuration_port_error: 'Port muss zwischen 1 - 65535 gesetzt sein',
        email_configuration_username_error: 'Benutzername darf nicht leer sein',
        email_configuration_password_error: 'Passwort darf nicht leer sein',
        email_configuration_sender_email_error: 'Absender E-Mail Adresse darf nicht leer sein',
        email_configuration_encryption_method_error: 'Verschlüsselung darf nicht leer sein',
        email_configuration_subject_error: 'Betreff darf nicht leer sein',
        email_configuration_test_validation_error: 'Ungültige E-Mail Adresse',
        umbrella_role_form_site_events_notifications: 'Eventbenachrichtungen:',
        umbrella_role_form_site_event_categories: 'Eventkategorien:',
        umbrella_role_form_placeholder_site_event_categories: 'Ausgewählte Eventkategorien',
        umbrella_role_form_site_event_types: 'Eventtypen:',
        umbrella_role_form_placeholder_site_event_types: 'Ausgewählte Eventtypen',
        umbrella_role_form_site_entity_types: 'Entitätstypen:',
        umbrella_role_form_placeholder_site_entity_types: 'Ausgewählte Entitätstypen',
        umbrella_role_form_contains_filter: 'Filter:',
        umbrella_role_form_placeholder_contains_filter: 'Filter eingeben',
        umbrella_role_form_tooltip_contains_filter: 'Nutzen Sie die Filteroption um die Beschreibung und die Quelle ohne Berücksichtigung auf Größ-/Kleinschreibung zu filtern. Mehrere Filter werden mit ODER verknüpft.',
        umbrella_role_form_send_email: 'E-Mail Benachrichtigung:',
        umbrella_role_form_send_email_to_ad_group: 'E-Mail Benachrichtigungen an die AD Gruppe:',
        umbrella_no_notifications: 'Keine Benachrichtigungen vorhanden',
        umbrella_notification_table_column_type: 'Typ',
        umbrella_notification_table_column_source: 'Quelle',
        umbrella_notification_table_column_site: 'Standort',
        umbrella_notification_table_column_timestamp: 'Zeitstempel',
        umbrella_notification_table_column_resolved: 'Behoben',
        umbrella_notification_table_column_category: 'Kategorie',
        umbrella_notification_table_clear_notifications: 'Benachrichtungen entfernen',
        umbrella_notification_table_column_description: 'Beschreibung',
        umbrella_notification_table_site_events: 'Events',
        umbrella_dashboard_new_layout: 'Neues Layout',
        umbrella_dashboard_save_layout: 'Layout speichern',
        umbrella_dashboard_delete_layout: 'Layout löschen',
        umbrella_dashboard_show_default: 'Standard anzeigen',
        umbrella_dashboard_choose_layout: 'Layout auswählen',
        umbrella_dashboard_choose_layout_description: 'Dashboardlayout auswählen.',
        umbrella_dashboard_choose_layout_save_button: 'Auswählen',
        umbrella_dashboard_add_card: 'Element hinzufügen',
        umbrella_dashboard_select_card: 'Element auswählen',
        umbrella_dashboard_select_card_description: 'Element auswählen und dem Layout hinzufügen',
        umbrella_dashboard_chart_tab_button: 'Diagramme',
        umbrella_dashboard_map_tab_button: 'Karten',
        umbrella_dashboard_table_tab_button: 'Tabellen',
        umbrella_dashboard_replace_card: 'Element ersetzen',
        umbrella_dashboard_delete_card: 'Element entfernen',
        umbrella_dashboard_recent_site_events: 'Letzte Eignisse',
        umbrella_dashboard_recent_site_events_no_events: 'Keine Standortereignisse',
        umbrella_dashboard_view_recent_site_events: 'Zeige Standortereignisse',
        umbrella_dashboard_choose_layout_site_states_tooltip: 'Standortzustände',
        umbrella_dashboard_choose_layout_running_cameras_tooltip: 'Laufende Kameras',
        umbrella_dashboard_choose_layout_running_site_services_tooltip: 'Laufende Dienste',
        umbrella_dashboard_choose_layout_site_approvals_tooltip: 'Standortbestätigungen',
        umbrella_dashboard_choose_layout_core_cpu_tooltip: 'Umbrella Core CPU',
        umbrella_dashboard_choose_layout_core_memory_tooltip: 'Umbrella Core Speicher',
        umbrella_dashboard_choose_layout_core_request_duration_tooltip: 'Umbrella Core Requestdauer',
        umbrella_dashboard_choose_layout_core_request_count_tooltip: 'Umbrella Core Requestzähler',
        umbrella_dashboard_choose_layout_map_tooltip: 'Karte',
        umbrella_dashboard_choose_layout_recent_jobs_tooltip: 'Letzte Jobs',
        umbrella_dashboard_choose_layout_recent_site_events_tooltip: 'Letzte Ereignisse',
        umbrella_dashboard_advanced_layout_add_new_item: 'ELEMENT HINZUFÜGEN',
        umbrella_eula_title: 'EULA und Drittanbietervereinbarungen',
        umbrella_eula_text: 'Bitte lesen Sie die EULA Vereinbarung. Wenn Sie auf akzeptieren klicken, stimmen Sie den EULA Bedingungen und Konditionen sowie den Drittanbietervereinbarungen zu. ',
        umbrella_eula_button: 'Akzeptieren',
        umbrella_eula_file_text: '',
        breadcrumb_dashboard: 'Übersicht',
        breadcrumb_control_centre: 'Standortadministration',
        breadcrumb_sites: 'Standorte',
        breadcrumb_map: 'Karte',
        breadcrumb_site_users: 'Benutzer',
        breadcrumb_site_groups: 'Gruppen',
        breadcrumb_access_control_cardholders: 'Kartenhalter',
        breadcrumb_access_control_doors: 'Türen',
        breadcrumb_site_services: 'Dienste',
        breadcrumb_cameras: 'Kameras',
        breadcrumb_site_jobs: 'Jobs',
        breadcrumb_update_site_licenses: 'Liizenz',
        breadcrumb_administration: 'Administration',
        breadcrumb_umbrella_users: 'Umbrella Benutzer',
        breadcrumb_umbrella_roles: 'Umbrella Rollen',
        breadcrumb_administration_license: 'Umbrella Lizenz',
        breadcrumb_administration_license_update: 'Lizenz',
        breadcrumb_monitor_centre: 'Monitoring',
        breadcrumb_umbrella_core: 'Umbrella Core',
        breadcrumb_site_events: 'Ereignisse',
        breadcrumb_email: 'E-Mail',
        breadcrumb_camera_firmware: 'Kamerafirmwares',
        breadcrumb_administration_settings: 'Einstellungen',
        breadcrumb_site_time_profile: 'Zeitprofile',
        breadcrumb_report_center: 'Berichte',
        breadcrumb_reports: 'Inventarberichte',
        breadcrumb_audit_reports: 'Auditberichte',
        breadcrumb_site_systems: 'Systeme',
        breadcrumb_site_service_performance: 'Dienste',
        breadcrumb_camera_performance: 'Kameras',
        breadcrumb_plugins: 'Plugins',
        breadcrumb_labels: 'Labels',
        breadcrumb_apiKeys: 'API Keys',
        site_job_details_execution_time: 'Ausführung (Standortzeitzone):',
        site_job_details_retries: 'Versuche:',
        site_job_details_sequence_id: 'Sequenz ID:',
        site_job_details_sequence_order: 'Sequenzreihenfolge:',
        site_job_details_sequence_total: 'Sequenzlänge:',
        site_job_related_undo_job: 'UMKEHRJOB:',
        site_job_card_title: 'JOB:',
        site_jobs_table_head_sequence: 'Sequenz ID',
        site_jobs_table_head_sequence_order: 'Sequenzreihenfolge',
        site_jobs_table_head_sequence_total: 'Sequenzlänge',
        site_jobs_table_head_execution_time: 'Ausführung (Standortzeitzone)',
        site_jobs_table_head_undo_time: 'Umkehrzeit (Standortzeitzone)',
        site_jobs_table_head_retries: 'Versuche',
        restart_cameras_success_snack_message: 'Jobs erfolgreich angelegt um Kameras neuzustarten',
        restart_cameras_error_snack_message: 'Jobs um Kameras neuzustarten konnten nicht angelegt werden.',
        restart_cameras_tooltip: 'Kameras neustarten',
        license_root_title: 'Lizenz Management',
        license_root_description: 'Keine Lizenz vorhanden! Nutzen sie diesen Code um eine Lizenz anzufordern:',
        license_root_apply_license_key: 'Lizenz übernehmen:',
        license_root_input_placeholder: 'Lizenz eintragen',
        license_root_submit_button_text: 'Übernehmen',
        license_root_problem_license_valid: 'Lizenz valide',
        license_root_problem_license_invalid: 'Lizenz nicht valide',
        license_root_problem_machine_code_missing: 'Machine Code fehlt',
        license_root_problem_no_modules: 'Keine Module lizensiert',
        license_root_key_update_success: 'Lizenz erfolgreich übernommen',
        license_root_key_update_fail: 'Lizenz übernehmen fehlgeschlagen',
        camera_firmware_modal_title: 'Kamera Firmware hochladen',
        camera_firmware_modal_description: 'Die Firmware wird auf dem Umbrella Core gespeichert.',
        camera_firmware_modal_manfucturer: 'Hersteller:*',
        camera_firmware_modal_supported_model: 'Unterstützte Modelle:*',
        camera_firmware_modal_comment: 'Kommentar:',
        camera_firmware_modal_dropzone: 'Upload oder Drag & Drop',
        camera_firmware_modal_upload_btn: 'Upload',
        camera_firmware_modal_upload_success: 'Firmware erfolgreich hochgeladen',
        camera_firmware_modal_upload_error: 'Firmware hochladen fehlgeschlagen',
        camera_firmware_table_filename: 'Dateiname',
        camera_firmware_table_manufacturer: 'Hersteller',
        camera_firmware_table_comment: 'Kommentar',
        camera_firmware_table_supported_types: 'Unterstützte Modelle',
        camera_firmware_table_uploaded_at: 'Hochgeladen am',
        camera_firmware_table_delete_firmwares: 'Firmware löschen',
        camera_firmware_table_selected_count: '{count} ausgewählt',
        camera_firmware_table_upload_modal_button: 'Kamera Firmware hochladen',
        camera_firmware_modal_no_file_selected: 'Keine Datei ausgewählt',
        camera_firmware_upload_snackbar_title: 'Firmware hochladen',
        camera_firmware_upload_snackbar_button_error: 'Fehler',
        camera_firmware_upload_snackbar_button: 'Erledigt',
        camera_firmware_deleted_success: 'Ausgwählte Firmwares erfolgreich gelöscht',
        camera_firmware_deleted_error: 'Firmwares löschen fehlgeschlagen',
        camera_firmware_modal_file_name: 'Dateiname:',
        settings_configuration_title: 'Einstellungen',
        settings_configuration_description: 'Hier können sie die Umbrella Core Einstellungen editieren',
        settings_ad_use_ad_cache: 'Active Directory Cache aktivieren:*',
        settings_ad_refresh_ad_cache_minutes: 'Active Directory Cache Refresh Intervall (in Minuten):*',
        settings_general_provide_health_endpoint: 'Health Endpoint aktivieren:*',
        settings_general_delete_vms_events_days: 'Intervall für Standort Ereignisse löschen (in Tagen):',
        settings_general_core_performance_measure_interval: 'Performancemessintervall (in Minuten):',
        settings_password_require_digit: 'Mindestens eine Zahl erforderlich:*',
        settings_password_required_length: 'Minimale Länge:*',
        settings_password_require_non_alphanumeric: 'Nicht alphanumerische Zeichen notwendig:*',
        settings_password_require_uppercase: 'Großbuchstabe erforderlich:*',
        settings_password_require_lowercase: 'Kleinbuchstabe erforderlich:*',
        settings_password_required_unique_chars: 'Anzahl einzigartiger Zeichen:*',
        settings_login_is_remember_me_available: 'Angemeldet bleiben:*',
        settings_login_cookie_persistence_duration: 'Zeitraum für Cookie Gültigkeit (in Stunden):*',
        settings_login_sliding_expiration_session_cookie: 'Cookie Ablaufschiebefenster:*',
        settings_login_validation_interval_seconds: 'Cookie Validierungsinterval (in Sekunden):*',
        settings_ad_is_ad_login_available: 'Active Directory aktiviert:*',
        settings_ad_sync_active_directory_email: 'Active Directory E-Mail synchronisieren:*',
        settings_ad_domain_names: 'Domänen:*',
        settings_license_license_key: 'Lizenz:',
        settings_gateway_heartbeat_interval_seconds: 'Heartbeatintervall (in Sekunden):',
        settings_gateway_use_signalr_for_vms_event: 'Standort Ereignisse mit SignalR übertragen:',
        settings_gateway_vms_network_timeout_seconds: 'Standort Timeout (in Sekunden):',
        settings_gateway_fetch_recordings_interval_minutes: 'Intervall für Aufnahmestatistiken (in Minuten):',
        settings_gateway_delete_site_performance_measurements_interval: 'Intervall für Performancedaten löschen (in Tagen):',
        settings_general_delete_core_performance_measurements_interval: 'Intervall für Performancedaten löschen (in Tagen):',
        settings_gateway_site_performance_measure_interval: 'Performanceintervall (in Minuten):',
        settings_configuration_submit: 'Aktualisieren',
        settings_configuration_reset_fields: 'Alle zurücksetzen',
        settings_configuration_restore_default: 'Werkeinstellungen zurücksetzen',
        settings_configuration_update_success: 'Einstellungen erfolgreich aktualisiert',
        settings_configuration_update_error: 'Einstellungen aktualisieren fehlgeschlagen',
        settings_configuration_resart_information: '* Änderungen an diesen Einstellungen starten den Umbrella Core sofort neu',
        settings_general_delete_log_days: 'Logfiles älter als löschen (in Tagen):*',
        settings_gateway_delete_log_days: 'Logfiles älter als löschen (in Tagen):',
        settings_expert_writeheartbeatcache: 'Heartbeat Cache-Schreib-Intervall (in Sekunden):*',
        settings_expert_allowedmissedheartbeats: 'Anzahl verpasster Heartbeats:*',
        settings_expert_delaygatewayupdate: 'Gatewayaktualisierungsverzögerungsintervall (in Sekunden):*',
        settings_general_display_username_jobs: 'Benutzernamen in Jobs anzeigen:*',
        settings_general_is_comment_mandatory: 'Job Kommentar ist verfplichtend:*',
        settings_general_delete_reports_when_downloading: 'Reports nach dem downloaden löschen:*',
        settings_general_delete_jobs_after_day_interval: 'Intervall um Jobs zu löschen (in Tagen):',
        settings_gateway_delete_system_performance_measurements_interval: 'Intervall für Performancedaten löschen (in Tagen):',
        settings_gateway_system_performance_measure_interval: 'Performanceintervall (in Minuten):',
        settings_general_thresholds_cpu: 'Schwellwerte CPU (%):',
        settings_general_thresholds_memory: 'Schwellwerte RAM (%):',
        settings_general_thresholds_disk: 'Schwellwerte Festplatte (%):',
        settings_general_thresholds_network: 'Schwellwerte Netzwerk (%):',
        settings_notifications_priority_low_delay: 'Verzögerung für Notifications mit niedriger Priorität (in Sekunden):',
        settings_notifications_priority_normal_delay: 'Verzögerung für Notifications mit normaler Priorität (in Sekunden):',
        settings_thresholds_slider_warning: 'Warnung',
        settings_thresholds_slider_critical: 'Kritisch',
        settings_group_password: 'PASSWORT EINSTELLUNGEN:',
        settings_group_login: 'LOGIN EINSTELLUNGEN:',
        settings_group_license: 'LIZENZ EINSTELLUNGEN:',
        settings_group_gateway: 'GATEWAY EINSTELLUNGEN:',
        settings_group_core: 'CORE EINSTELLUNGEN:',
        settings_group_core_expert: 'CORE EXPERTEN EINSTELLUNGEN:',
        settings_group_active_directory: 'ACTIVE DIRECTORY EINSTELLUNGEN:',
        settings_group_notification: 'NOTIFICATION EINSTELLUNGEN:',
        settings_general_delete_vms_events_error: 'Gültiger Bereich: 1 - 365',
        settings_ad_refresh_ad_cache_error: 'Gültiger Bereich: 5 - 120',
        settings_general_core_performance_measure_interval_error: 'Gültiger Bereich: 1 - 60',
        settings_gateway_site_performance_measure_interval_error: 'Gültiger Bereich: 1 - 60',
        settings_password_required_length_error: 'Gültiger Bereich: 1 - 100',
        settings_password_required_unique_chars_error: 'Mindestens 0 oder höchstens Mindestlänge',
        settings_login_cookie_persistence_duration_error: 'Gültiger Bereich: 0 - 2160',
        settings_login_validation_interval_error: 'Gültiger Bereich: 0 - 300',
        settings_ad_domain_names_error: 'Liste darf nicht leer sein wenn AD aktiviert ist',
        settings_gateway_heartbeat_interval_error: 'Gültiger Bereich: 5 - 3600',
        settings_gateway_vms_network_timeout_error: 'Gültiger Bereich: 5 - 120',
        settings_gateway_fetch_recordings_interval_error: 'Gültiger Bereich: 5 - 120',
        settings_gateway_delete_site_performance_measurements_interval_error: 'Gültiger Bereich: 1 - 365',
        settings_general_delete_core_performance_measurements_interval_error: 'Gültiger Bereich: 1 - 365',
        settings_general_delete_log_days_error: 'Gültiger Bereich: 1 - 365',
        settings_gateway_delete_log_days_error: 'Gültiger Bereich: 1 - 365',
        settings_expert_writeheartbeatcache_error: 'Gültiger Bereich: 10 - 60',
        settings_expert_allowedmissedheartbeats_error: 'Gültiger Bereich: 3 - 10',
        settings_expert_delaygatewayupdate_error: 'Gültiger Bereich: 0 - 30',
        settings_general_delete_jobs_after_day_interval_error: 'Gültiger Bereich: 1 - 365',
        umbrella_sites_table_site_name: 'Standortname',
        umbrella_sites_table_cpu_usage: 'CPU Verbrauch (%)',
        umbrella_sites_table_ram_usage: 'Speicherverbrauch (GB)',
        umbrella_sites_table_site_labels: 'Labels',
        umbrella_sites_table_state_healthy: 'Optimal',
        umbrella_sites_table_state_warning: 'Störung',
        umbrella_sites_table_state_critical: 'Kritisch',
        umbrella_sites_cpu_usage: 'CPU Verbrauch (%)',
        umbrella_sites_ram_usage: 'Speicherverbrauch (GB)',
        umbrella_sites_no_data_to_show: 'Keine Daten verfügbar',
        umbrella_sites_disk_usage: 'Festplattenspeicherverbrauch (%)',
        umbrella_sites_services_performance: 'Diensteleistung',
        upload_manager_state_done: 'Erledigt',
        upload_manager_state_error: 'Fehler',
        upload_manager_clear_completed_button: 'Abgeschlossene Aktionen entfernen',
        upload_manager_no_items: 'Keine Elemente vorhanden',
        upload_manager_error_general: 'Fehler',
        upload_manager_error_file_already_exists: 'Datei existiert bereits',
        upload_manager_error_size_too_large: 'Datei ist zu groß',
        upload_manager_type_firmware_upload: 'Firmware hochgeladen: {name}',
        upload_manager_unload_message: 'Es werden noch Aktionen ausgeführt (Siehe Fortschrittsmanager)',
        upload_manager_job_async_camera_restart: 'Erstelle Kameraneustarten Jobs ({count})',
        upload_manager_job_async_change_camera_password: 'Erstelle Kamerapasswort ändern Jobs ({count})',
        upload_manager_job_async_camera_firmware_update: 'Erstelle Kamerafirmware aktualisieren Jobs ({count})',
        upload_manager_job_async_camera_change_properties: 'Erstelle Kameraeigenschaften ändern Jobs ({count})',
        upload_manager_job_async_site_group_create: 'Erstelle Neue Gruppe erstellen Jobs ({count})',
        upload_manager_job_async_site_group_delete: 'Erstelle Gruppe löschen Jobs ({count})',
        upload_manager_job_async_site_group_change_properties: 'Erstelle Gruppeeigenschaften ändern Jobs ({count})',
        upload_manager_job_async_license_upload: 'Erstelle Standortlizenz aktualisieren Jobs ({count})',
        upload_manager_job_async_sites_connection_user_password: 'Erstelle Standortverbindungsbenutzerpasswort ändern Jobs ({count})',
        upload_manager_job_async_site_services_restart: 'Erstelle Dienst neustarten Jobs ({count})',
        upload_manager_job_async_site_services_change_properties: 'Erstelle Diensteigenschaften ändern Jobs ({count})',
        upload_manager_job_async_site_users_change_password: 'Erstelle Benutzerpasswort ändern Jobs ({count})',
        upload_manager_job_async_site_users_create: 'Erstelle Benutzer anlegen Jobs ({count})',
        upload_manager_job_async_site_users_delete: 'Erstelle Benutzer löschen Jobs ({count})',
        upload_manager_job_async_site_users_properties: 'Erstelle Benutzereigenschaften ändern Jobs ({count})',
        upload_manager_job_async_retry_jobs: 'Erstelle Jobs zum wiederholen ({count})',
        upload_manager_job_async_site_time_profile_change_properties: 'Erstelle Zeitprofileigenschaften ändern Jobs ({count})',
        upload_manager_job_async_site_user_change_name: 'Erstelle Benutzer Name ändern Jobs ({count})',
        upload_manager_job_async_site_user_copy_user: 'Erstelle Benutzer kopieren Jobs ({count})',
        upload_manager_job_async_access_control_cardholders_change_properties: 'Erstelle Kartenhaltereigenschaften ändern Jobs ({count})',
        upload_manager_job_async_access_control_doors_change_properties: 'Erstelle Türeneigenschaften ändern Jobs ({count})',
        upload_manager_job_async_access_control_cardholders_create: 'Erstelle neue Kartenhalter Jobs ({count})',
        upload_manager_job_async_change_system_message_config: 'Erstelle Systembenachrichtungenkonfiguration ändern Jobs {{count}}',
        site_time_profiles_list_add_time_profile_button: 'Zeitprofil erstellen',
        site_time_profiles_list_table_head_time_profile: 'Zeitprofil',
        site_time_profiles_list_table_head_site: 'Standort',
        site_time_profiles_list_table_head_site_labels: 'Standortlabels',
        site_time_profiles_list_table_head_labels: 'Labels',
        site_time_profiles_table_toolbar_change_time_profiles_tooltip: 'Zeitprofil ändern',
        site_time_profiles_table_toolbar_selected_time_profiles: '{count} ausgewählt',
        site_time_profile_day_monday: 'Montag',
        site_time_profile_day_monday_short: 'Mo',
        site_time_profile_day_tuesday: 'Dienstag',
        site_time_profile_day_tuesday_short: 'Di',
        site_time_profile_day_wednesday: 'Mittwoch',
        site_time_profile_day_wednesday_short: 'Mi',
        site_time_profile_day_thursday: 'Donnerstag',
        site_time_profile_day_thursday_short: 'Do',
        site_time_profile_day_friday: 'Freitag',
        site_time_profile_day_friday_short: 'Fr',
        site_time_profile_day_saturday: 'Samstag',
        site_time_profile_day_saturday_short: 'Sa',
        site_time_profile_day_sunday: 'Sonntag',
        site_time_profile_day_sunday_short: 'So',
        site_time_profile_day_bank_holiday: 'Feiertag',
        site_time_profile_day_bank_holiday_short: 'Feiertag',
        site_time_profile_hours: 'Stunden',
        site_time_profile_time_range: 'ZEITBEREICH {index}:',
        site_time_profile_time_range_starts: 'Start:',
        site_time_profile_time_range_ends: 'Ende:',
        site_time_profile_time_range_add_new: 'Neuer Zeitbereich',
        site_time_profile_change_property_job: 'Job(s) erstellen',
        site_time_profile_reset: 'Alles zurücksetzen',
        site_time_profile_info_desc: 'Alle Änderungen werden erst beim Ausführen der Jobs durchgeführt.',
        site_time_profile_general_tab: 'Details',
        site_time_profile_profile_tab: 'Profil',
        site_time_profile_general_title: 'Zeitprofil ändern',
        site_time_profile_general_description: 'Hier können sie das Zeitprofil anpassen',
        site_time_profile_general_name: 'Name:',
        site_time_profile_general_site: 'Standort:',
        site_time_profile_general_labels: 'Labels:',
        site_time_profile_general_update_details: 'Details aktualisieren',
        site_time_profile_save_current_editing_range: 'Speichern',
        site_time_profile_save_details_general_success: 'Zeitprofil erfolgreich aktualisiert',
        site_time_profile_save_details_general_error: 'Zeitprofil aktualisieren fehlgeschlagen',
        site_time_profile_change_property_success: 'Job(s) erstellt',
        site_time_profile_change_property_error: 'Job(s) erstellen fehlgeschlagen',
        site_time_profile_change_multiple_title: 'Zeitprofile konfigurieren',
        site_time_profile_change_multiple_description: 'Beim Konfigurieren mehrerer Zeitprofile beginnen sie immer mit einer leeren Zeitprofil Schablone, wenn die ausgewählten Zeitprofile nicht übereinstimmen.',
        site_time_profile_common_properties_error: 'Fehler bei der Überprüfung der gemeinsamen Eigenschaften',
        site_time_profile_remove_time_range: 'Zeitprofil löschen',
        site_time_profile_slider_overlap_warning: 'Zeitprofile überlappen sich, sie werden zu einem Zeitprofil zusammengefasst',
        site_job_update_vms_license: 'Lizenz aktualisieren',
        site_job_update_firmware_camera: 'Kamerafirmware aktualisieren',
        site_job_restart_vms_runtime: 'Dienst neustarten',
        site_job_start_vms_runtime: 'Dienst starten',
        site_job_stop_vms_runtime: 'Dienst stoppen',
        site_job_delete_vms_user: 'Benutzer löschen',
        site_job_delete_vms_group: 'Gruppe löschen',
        site_job_create_vms_user: 'Benutzer erstellen',
        site_job_create_vms_group: 'Gruppe erstellen',
        site_job_change_vms_user_password: 'Benutzerpasswort ändern',
        site_job_change_connection_user_password: 'Verbindungsbenutzerpasswort ändern',
        site_job_change_camera_password: 'Kamerapasswort ändern',
        site_job_cancel_job: 'Jobabbruch',
        site_job_batch_job: 'Jobbündel',
        site_job_change_vms_user_property: 'Benutzereigenschaften ändern',
        site_job_change_vms_camera_property: 'Kameraeigenschaften ändern',
        site_job_change_vms_group_property: 'Gruppeneigenschaften ändern',
        site_job_change_vms_runtime_property: 'Diensteeigenschaften ändern',
        site_job_change_vms_event_property: 'Ereigniseigentschaften ändern',
        site_job_change_vms_timeprofile_property: 'Zeitprofileigenschaften ändern',
        site_job_change_vms_user_name: 'Benutzer umbenennen',
        site_job_copy_vms_user: 'Benutzer kopieren',
        site_job_restart_gateway: 'Standortverbindung neustarten',
        site_job_create_accesscontrol_cardholder: 'Kartenhalter erstellen',
        site_job_change_vms_event_config_property: 'Ereignis-Konfigrationseigentschaften ändern',
        selectionbox_toolbar_select_mode_page: 'Nur aktuelle Seite',
        selectionbox_toolbar_select_mode_selectAll: 'Alle Seiten',
        selectionbox_siteselector_include_all_sites: 'Alle Standorte ausgewählt',
        reports_table_head_filename: 'Dateiname',
        reports_table_head_createdAt: 'Erstellt am',
        reports_table_head_createdBy: 'Erstellt von',
        reports_table_head_comment: 'Kommentar',
        reports_table_head_siteName: 'Standortname',
        reports_download_success_snack_message: 'Job erfolgreich erstellt',
        reports_download_error_snack_message: 'Job(s) erstellen fehlgeschlagen',
        reports_table_toolbar_download_tooltip: 'Ausgewählte Berichte herunterladen',
        reports_table_toolbar_delete_tooltip: 'Ausgewählte Berichte löschen',
        reports_delete_success_snack_message: 'Ausgewählte Berichte erfolgreich gelöscht',
        reports_delete_error_snack_message: 'Löschen der Berichte fehlgeschlagen',
        create_audit_report_modal_title: 'Auditbericht erstellen',
        create_audit_report_modal_description: 'Auditbericht für ausgewählten Zeitraum erstellen',
        create_audit_report_modal_start_label: 'Startdatum',
        create_audit_report_modal_end_label: 'Enddatum',
        create_audit_report_modal_submit_button: 'Auditbericht anfordern',
        create_audit_report_modale_error: 'Das Enddatum muss sich vor dem Startdatum befinden',
        create_audit_report_success_snack_message: 'Job erfolgreich erstellt',
        create_audit_report_failed_snack_message: 'Job(s) erstellen fehlgeschlagen',
        audit_reports_table_head_filename: 'Dateiname',
        audit_reports_table_head_createdAt: 'Erstellt am',
        audit_reports_table_head_createdBy: 'Erstellt von',
        audit_reports_table_head_comment: 'Kommentar',
        audit_reports_table_head_siteName: 'Standortname',
        audit_reports_table_head_from: 'Von',
        audit_reports_table_head_till: 'Bis',
        custom_comments_modal_title: 'Kommentare',
        custom_comments_modal_description: 'Kommentare Beschreibung',
        copy_to_clipboard_button_tooltip: 'In Zwischenablage kopieren',
        site_systems_chip_healthy: 'Optimal',
        site_systems_chip_warning: 'Störung',
        site_systems_chip_critical: 'Kritisch',
        site_systems_chip_offline: 'Offline',
        site_systems_chip_ignore: 'Ignoriert',
        site_systems_search_by_site: 'Filter nach Standort',
        site_systems_search_by_system: 'Filter nach System',
        site_systems_search_by_name: 'Filter nach Namen',
        site_systems_ignore_success: 'Erfolgreich',
        site_systems_ignore_error: 'Fehlgeschlagen',
        site_systems_tooltip_ignore: 'Markierte Systeme ignorieren',
        site_systems_details_net_usage: 'Netzwerkauslastung (%)',
        site_systems_details_disk_usage: 'Festplattenspeicherverbrauch (%)',
        site_systems_details_description: 'Performancedetails zu diesem System und seinen Diensten',
        site_systems_details_no_disk_data: 'Keine Daten',
        site_systems_details_services_title: 'Dienste ({count})',
        general_datetime_days: 'Tage',
        general_datetime_hours: 'Stunden',
        general_datetime_minutes: 'Minuten',
        general_datetime_seconds: 'Sekunden',
        deploy_monitoring_plugin_title: 'Monitoring Plugin Installer erstellen',
        deploy_monitoring_plugin_floating_button_tooltip: 'Monitoring Plugin Installer erstellen',
        deploy_monitoring_plugin_description: 'Füllen Sie das Formular aus und laden Sie das ZIP-Archiv herunter, um einen neues Monitoring Plugin auszurollen. Das Paket entählt einen Installer und alle benötigten Konfigurationsdateien. Folgen Sie den Anweisungen der Konsolenanwendung',
        deploy_monitoring_plugin_warning_label: 'WARNUNG',
        deploy_monitoring_plugin_input_warning_label: 'Installieren sie zusätzliche Umbrella Monitoring Plugins nur auf Systemen, auf denen nicht bereits ein Umbrella Monitoring Plugin läuft. Jedes Umbrella Gateway beinhaltet bereits ein Umbrella Monitoring Plugin und es wird kein zusätzliches benötigt.',
        deploy_monitoring_plugin_required_parameters_label: 'Pflichtfelder',
        deploy_monitoring_plugin_input_gateway_host_label: 'Gateway Host:*',
        deploy_monitoring_plugin_input_gateway_port_label: 'Gateway Port:*',
        deploy_monitoring_plugin_input_plugin_host_label: 'Plugin Host:*',
        deploy_monitoring_plugin_input_plugin_port_label: 'Plugin Port:*',
        deploy_monitoring_plugin_input_as_a_service_label: 'Windows Dienst:',
        deploy_monitoring_plugin_input_service_name_label: 'Windows Dienstname:*',
        deploy_monitoring_plugin_input_working_dir_label: 'Arbeitsverzeichnis:*',
        deploy_monitoring_plugin_input_working_dir_tooltip: 'Der Pfad muss ein absoluter Pfad sein und darf noch nicht existieren, dieser wird vom Monitoring Plugin Installer erstellt.',
        deploy_monitoring_plugin_download_installer_button: 'Installer herunterladen',
        deploy_monitoring_plugin_instructions: 'Führen Sie Umbrella.Monitoring.Plugin.Installer.exe aus, um das Monitoring Plugin zu installieren',
        deploy_monitoring_plugin_input_gateway_host_placeholder: 'Gateway Host',
        deploy_monitoring_plugin_input_gateway_port_placeholder: 'Gateway Port',
        deploy_monitoring_plugin_input_plugin_host_placeholder: 'Plugin Host',
        deploy_monitoring_plugin_input_plugin_port_placeholder: 'Plugin Port',
        deploy_monitoring_plugin_input_service_name_placeholder: 'Windows Dienstname',
        deploy_monitoring_plugin_input_working_dir_placeholder: 'Arbeitsverzeichnis',
        deploy_monitoring_plugin_input_validation_port: 'Ungültiger Port (1 - 65535)',
        deploy_monitoring_plugin_input_validation_working_dir: 'Ungültiges Arbeitsverzeichnis',
        deploy_monitoring_plugin_input_validation_service_name: 'Ungültiger Dienstname (nur Buchstaben, Zahlen und Unterstrich erlaubt)',
        deploy_monitoring_plugin_input_validation_host: 'Ungültiger Hostname',
        deploy_monitoring_plugin_success_message: 'Installationspaket erfolgreich heruntergeladen',
        deploy_monitoring_plugin_error_message: 'Fehler beim Erstellen des Installationspaketes',
        site_service_performance_service_state_label: 'Dienststatus',
        site_service_performance_vms_state_tooltip: 'Der logische Status des Standortplugins',
        site_service_performance_service_state_tooltip: 'Der Betriebssystemstatus',
        site_service_performance_vms_state_label: 'Status',
        site_service_performance_service_state_stopped: 'GESTOPPT',
        site_service_performance_service_state_running: 'AUSGEFÜHRT',
        site_service_performance_service_state_paused: 'PAUSIERT',
        site_service_performance_service_state_offline: 'UNBEKANNT',
        site_service_performance_host: 'Host',
        vms_state_ok: 'OK',
        vms_state_error: 'FEHLER',
        vms_state_offline: 'UNBEKANNT',
        vms_state_disabled: 'DEAKTIVIERT',
        site_service_performance_ignore_success: 'Erfolgreich',
        site_service_performance_ignore_error: 'Fehlgeschlagen',
        site_service_performance_tooltip_ignore: 'Markierte Dienste ignorieren',
        site_service_performance_start_success: 'Erfolgreich',
        site_service_performance_start_error: 'Fehlgeschlagen',
        site_service_performance_stop_success: 'Erfolgreich',
        site_service_performance_stop_error: 'Fehlgeschlagen',
        camera_performance_recording_state_label: 'Aufnahmestatus',
        camera_performance_vms_state_label: 'Dienststatus',
        camera_performance_vms_state_tooltip: 'Der logische Status des Standortplugins',
        camera_performance_recording_state_tooltip: 'Der Betriebssystemstatus',
        recording_state_ok: 'OK',
        recording_state_limited: 'EINGESCHRÄNKT',
        recording_state_error: 'FEHLER',
        recording_state_unknown: 'UNBEKANNT',
        recording_state_disabled: 'DEAKTIVIERT',
        camera_performance_ignore_success: 'Erfolgreich',
        camera_performance_ignore_error: 'Fehlgeschlagen',
        camera_performance_tooltip_ignore: 'Markierte Kameras ignorieren',
        camera_performance_recording_statistics: 'Aufnahmestatistiken',
        norole_back_to_login: 'Zurück zum Login',
        norole_info_1: 'Der ausgewählte Benutzer besitzt keine Rolle(n)',
        norole_info_2: 'Bitte kontaktieren Sie ihren Administrator',
        invalid_session_title: 'Verbindung fehlgeschlagen',
        invalid_session_text: 'Der Umbrella Core ist nicht mehr erreichbar. Bitte loggen sie sich erneut ein',
        invalid_session_button_text: 'Zurück zum Login',
        access_control_cardholders_table_head_cardholder: 'Kartenhalter',
        access_control_cardholders_table_head_sites: 'Standorte',
        access_control_cardholders_table_head_labels: 'Labels',
        access_control_cardholders_table_head_site_labels: 'Standortlabels',
        access_control_cardholders_toolbar_selected_cardholder: '{count} ausgewählt',
        access_control_cardholders_toolbar_change_properties_tooltip: 'Kartenhaltereigenschaften ändern',
        access_control_cardholders_details_update_success_message: 'Kartenhalter erfolgreich updaten',
        access_control_cardholders_details_update_error_message: 'Kartenhalter updaten fehlgeschlagen',
        access_control_cardholders_details_title: 'Hier können sie die Eigenschaften der Kartenhalter ändern',
        access_control_cardholders_details_cardholder: 'Kartenhalter:',
        access_control_cardholders_details_site: 'Standort:',
        access_control_cardholders_details_labels: 'Labels:',
        access_control_cardholders_details_update_button: 'Update Details',
        access_control_cardholders_details_tab_details: 'Details',
        access_control_cardholders_details_properties_title: 'Hier können sie die Eigenschaften der Kartenhalter ändern',
        access_control_cardholders_details_properties_update_success_message: 'Job(s) erfolgreich erstellt',
        access_control_cardholders_details_properties_update_error_message: 'Job(s) erstellen fehlgeschlagen',
        access_control_doors_table_head_door: 'Tür',
        access_control_doors_table_head_sites: 'Standort',
        access_control_doors_table_head_labels: 'Labels',
        access_control_doors_table_head_site_labels: 'Standortlabels',
        access_control_doors_toolbar_selected_door: '{count} ausgewählt',
        access_control_doors_toolbar_change_properties_tooltip: 'Eigenschäften der ausgewählten Türen ändern',
        access_control_doors_details_update_success_message: 'Türen erfolgreich upgedated',
        access_control_doors_details_update_error_message: 'Türen updaten fehlgeschlagen',
        access_control_doors_details_title: 'Hier können sie die Eigenschaften der Türen ändern',
        access_control_doors_details_door: 'Tür:',
        access_control_doors_details_site: 'Standort:',
        access_control_doors_details_labels: 'Labels:',
        access_control_doors_details_update_button: 'Update Details',
        access_control_doors_details_tab_details: 'Details',
        access_control_doors_details_properties_title: 'Hier können sie die Eigenschaften der Türen ändern',
        access_control_doors_details_properties_update_success_message: 'Job(s) erfolgreich erstellt',
        access_control_doors_details_properties_update_error_message: 'Job(s) erstellen fehlgeschlagen',
        property_systemmessages_name_dbloadwarning: '',
        property_systemmessages_name_dbloademergencyshutdown: '',
        property_systemmessages_name_dbcyclicbackupfailed: '',
        property_systemmessages_name_reinvalidstatus: '',
        property_systemmessages_name_licencechangedsucceeded: '',
        property_systemmessages_name_licencechangedfailed: '',
        property_systemmessages_name_mdbcannotmountzone: '',
        property_systemmessages_name_mdbnomorezonespace: '',
        property_systemmessages_name_mdbcannotstartmds: '',
        property_systemmessages_name_servertimechanged: '',
        property_systemmessages_name_cmcannotstart: '',
        property_systemmessages_name_cmdeviceconnectivityproblem: '',
        property_systemmessages_name_videoloss: '',
        property_systemmessages_name_tampering: '',
        property_systemmessages_name_donglemissing: '',
        property_systemmessages_name_avexportjobfailed: '',
        property_systemmessages_name_avexportjobold: '',
        property_systemmessages_name_avexportjobolder: '',
        property_systemmessages_name_mdbzonealmostfull: '',
        property_systemmessages_name_systemalertsbadsignal: '',
        property_systemmessages_name_systemalertsunknownview: '',
        property_systemmessages_name_systemalertsrestoredview: '',
        property_systemmessages_name_coresubunreachable: '',
        property_systemmessages_name_mdblaststandardimagetooold: '',
        property_systemmessages_name_flatfileloggingcannotwritesummaryfile: '',
        property_systemmessages_name_flatfileloggingcannotwritealarmfile: '',
        property_systemmessages_name_vavideolost: '',
        property_systemmessages_name_vavideoavailable: '',
        property_systemmessages_name_vathirdpartycommunicationlost: '',
        property_systemmessages_name_vathirdpartycommunicationavailable: '',
        property_systemmessages_name_restart: '',
        property_systemmessages_name_restop: '',
        property_systemmessages_name_dmthresholdsvaluesexceeded: '',
        property_systemmessages_name_mdbdtatisticsnotavailable: '',
        property_systemmessages_name_mdbautomatedvideobackupstarted: '',
        property_systemmessages_name_mdbautomatedvideobackupcompleted: '',
        property_systemmessages_name_mdbautomatedvideobackupinterrupted: '',
        property_systemmessages_name_edgestorageimportssuccessful: '',
        property_systemmessages_name_edgestorageimportinterrupted: '',
        property_systemmessages_name_coreslavereachableagain: '',
        property_systemmessages_name_userdeactivatedafterfailedloginattempts: '',
        property_systemmessages_name_cmdeviceconnectivityproblemresolved: '',
        property_systemmessages_connection_user_subscribed: '',
        property_systemmessages_deadtime: '',
        property_systemmessages_affected_service: '',
        configure_threshold_sites_title: 'Schwellwerte für ausgewählte Standorte konfigurieren',
        configure_threshold_services_title: 'Schwellwerte für ausgewählte Dienste konfigurieren',
        configure_threshold_systems_title: 'Schwellwerte für ausgewählte Systeme konfigurieren',
        configure_threshold_sites_description: 'Sie sind dabei die Schwellwerte für ausgewählte Standorte zu ändern. Sie können benutzerdefinierte Schwellwerte setzen oder die Werte der Umbrella Einstellungen übernehmen.',
        configure_threshold_services_description: 'Sie sind dabei die Schwellwerte für ausgewählte Dienste zu ändern. Sie können benutzerdefinierte Schwellwerte setzen oder die Werte der Umbrella Einstellungen oder der Standorte übernehmen.',
        configure_threshold_systems_description: 'Sie sind dabei die Schwellwerte für ausgewählte Systeme zu ändern. Sie können benutzerdefinierte Schwellwerte setzen oder die Werte der Umbrella Einstellungen oder der Standorte übernehmen.',
        configure_threshold_cpu: 'Schwellwerte CPU (%):',
        configure_threshold_memory: 'Schwellwerte RAM (%):',
        configure_threshold_disk: 'Schwellwerte Festplatte (%):',
        configure_threshold_network: 'Schwellwerte Netzwerk (%):',
        configure_threshold_use_default_label: 'Standardwerte verwenden',
        configure_threshold_button_label: 'Update',
        threshold_type_unknown: 'Unbekannt',
        threshold_type_entity: 'Entity',
        threshold_type_site: 'Standort',
        threshold_type_settings: 'Einstellungen',
        threshold_type_prefix: 'Quelle:',
        labels_table_head_name: 'Name',
        apiKeys_table_head_apikey: 'API Key',
        apiKeys_table_head_creator: 'Ersteller',
        apiKeys_table_head_creationdate: 'Erstellungsdatum',
        apiKeys_create: 'API Key erstellen',
        apikey_created_success: 'API Key erfolgreich erstellen',
        apikey_created_error: 'Fehler beim erstellen des API Key',
        apikey_delete_sucess: 'API Key erfolgreich gelöscht',
        apikey_delete_error: 'Fehler beim löschen des API Key',
        apiKey_delete_tooltip: 'API Key(s) löschen',
        edit_label_title: 'Name des Labels ändern',
        edit_label_description: 'Sie sind dabei dieses Label zu ändern',
        edit_label_name_label: 'Name:',
        edit_label_button_label: 'Update',
        edit_label_error_unique_labelname: 'Label existiert bereits',
        multiconfig_labels_title: 'Gemeinsame Labels ändern',
        multiconfig_labels_description: 'Hier können sie die Labels der ausgewählten Standorte ändern',
        multiconfig_labels_labels_label: 'Labels:',
        multiconfig_labels_button_label: 'Update',
        multiconfig_labels_overwrite_label: 'Überschreiben:',
        multiconfig_labels_success_message: 'Label(s) erfolgreich geändert',
        multiconfig_labels_error_message: 'Label(s) ändern fehlgeschlagen',
        multiconfig_label_override_warning_title: 'Warnung',
        multiconfig_label_override_warning_message: 'Achtung! Sie haben den Überschreibungsmodus aktiviert. Alle Labels der asugwählten Elemente werden entfernt und durch die aktuelle Auswahl überschrieben.',
        notification_priority_title: 'Benachrichtigungspriorität ändern',
        notification_priority_description: 'Hier können sie die Benachrichtigungspriorität für die ausgewählten Elemente ändern',
        notification_priority_priority_label: 'Benachrichtigungspriorität:',
        notification_priority_high: 'Hoch',
        notification_priority_normal: 'Normal',
        notification_priority_low: 'Niedrig',
        site_events_group_resolved_tooltip_group: 'Abgeschlossene Events gruppieren',
        site_events_group_resolved_tooltip_ungroup: 'Flache Liste anzeigen',
        error_failed_request: 'Fehlerhafte Anfrage'
    },
    fr: {
        general_no: 'Non',
        login_input_username_placeholder: 'Nom d\'utilisateur',
        login_input_password_placeholder: 'Mot de passe',
        login_remember_me: 'Se souvenir de moi',
        login_button_submit: 'Login',
        login_button_windows_login: 'Login type authentification Windows',
        login_request_error: 'Echec du login',
        login_user_rights_were_changed: 'Vous avez été déconnecté car vos droits ont changés',
        login_no_connection_title: 'Pas de connexion',
        login_no_connection_description: 'Il semble qu\'aucune connexion n\'ait pu être établie. Veuillez réessayer',
        login_no_connection_button: 'Réessayer',
        language_picker_selection_english: 'English',
        language_picker_selection_german: 'Deutsch',
        language_picker_selection_french: 'Français',
        sidenav_dashboard: 'Tableau de bord',
        sidenav_map: 'Plans',
        sidenav_control_centre: 'Administration de site',
        sidenav_sites: 'Sites',
        sidenav_site_users: 'Utilisateurs',
        sidenav_site_groups: 'Groupes',
        sidenav_cameras: 'Caméras',
        sidenav_site_services: 'Services',
        sidenav_site_jobs: 'Tâches',
        sidenav_monitoring_centre: 'Monitoring',
        sidenav_umbrella_core: 'Core Umbrella',
        sidenav_administration: 'Administration',
        sidenav_administration_users: 'Utilisateurs Umbrella',
        sidenav_administration_roles: 'Rôles Umbrella',
        sidenav_manual: 'Manuel',
        sidenav_administration_license: 'Licence',
        sidenav_site_license_update: 'Licences',
        camera_firmware_core: 'Notifications',
        sidenav_site_events: 'Événements',
        sidenav_email: 'E-Mail',
        sidenav_settings: 'Paramètres',
        sidenav_camera_firmwares: 'Firmware Caméra',
        sidenav_site_time_profile: 'Profils horaires',
        sidenav_report_center: 'Rapports',
        sidenav_reports: 'Rapports d\'inventaire',
        sidenav_audit_reports: 'Rapports d\'audit',
        sidenav_site_systems: 'Systèmes',
        sidenav_access_control_card_holders: 'Titulaires de la carte',
        sidenav_access_control_doors: 'Portes',
        sidenav_plugins: 'Plugins',
        sidenav_administration_labels: 'Étiquettes',
        sidenav_administration_apiKeys: '',
        profile_drawer_profile_information: 'Information de profil',
        profile_drawer_role: 'Rôles:',
        profile_drawer_last_login: 'Dernier login:',
        profile_drawer_logout_button: 'Déconnexion',
        profile_drawer_change_password_button: 'Changement de mot de passe',
        profile_drawer_upload_profile_image_error: 'Echec de l\'envoi d\'une image de profil',
        profile_drawer_upload_profile_image_success: 'Envoi de l\'image d\'un profil effectué',
        profile_drawer_upload_profile_image_invalid_format: 'Veuillez fournir une image valide (jpg ou png)',
        profile_drawer_upload_profile_image_size_limit: 'Taille de l\'image maximum 5Mo',
        site_states_heading: 'Santé des sites',
        site_states_total: 'Total',
        site_states_healthy: 'Sain',
        site_states_warning: 'Avertissement',
        site_states_error: 'Critique',
        site_states_offline: 'Hors-ligne',
        site_states_ignored: 'Ignoré',
        site_states_updating: 'Actualisation',
        site_states_vms_not_reachable: 'Problèmes de connexion',
        site_states_error_tooltip: '{countNotReachable} Non joignable {br} {countOffline} Hors-ligne',
        site_states_healthy_tooltip: '{countHealthy} Sain {br} {countUpdating} Actualisation',
        site_state_chart_tooltip: 'Ce diagramme montre le rapport entre le nombre total de sites et les sites avec statut Sain, Avertissement et Erreur.',
        site_approvals_heading: 'Approbations de site',
        site_approvals_total: 'Total',
        site_approvals_approved: 'Approuvé',
        site_approvals_waiting_for_approval: 'En attente d\'approbation',
        site_approvals_chart_tooltip: 'Diagramme d\'approbation de sites',
        cameras_chart_total: 'Total',
        cameras_chart_heading: 'Santé des caméras',
        cameras_chart_warning: 'Avertissement',
        cameras_chart_healthy: 'Sain',
        cameras_chart_critical: 'Critique',
        cameras_chart_offline: 'Hors ligne',
        cameras_chart_ignore: 'Ignoré',
        cameras_chart_tooltip: 'Ce diagramme affiche le rapport entre le nombre total de caméras et le nombre de caméras en avertissement',
        umbrella_dashboard_site_systems: 'Santé des systèmes',
        umbrella_dashboard_site_systems_healthy: 'Sain',
        umbrella_dashboard_site_systems_warning: 'Avertissement',
        umbrella_dashboard_site_systems_critical: 'Critique',
        umbrella_dashboard_site_systems_offline: 'Hors ligne',
        umbrella_dashboard_site_systems_ignore: 'Ignoré',
        sites_with_location_heading: 'Localisation',
        sites_with_location_view_map: 'Visualiser le plan',
        sites_with_location_healthy: 'Sain',
        sites_with_location_error: 'Critique',
        sites_with_location_warning: 'Avertissement',
        sites_with_location_offline: 'Hors ligne',
        sites_with_location_ignore: 'Ignoré',
        recent_site_jobs_heading: 'Tâches récentes',
        recent_site_jobs_view_jobs: 'Visualiser les tâches',
        recent_site_jobs_no_jobs: 'Pas de tâche',
        recent_site_jobs_description_column_name: 'DESCRIPTION DE TÂCHE',
        recent_site_jobs_state_column_name: 'STATUT',
        recent_site_jobs_created_at_column_name: 'CRÉÉ',
        site_list_approved: 'Approuvé',
        site_list_unapproved: 'Refusé',
        site_list_approve_button: 'Approuver',
        site_list_table_head_name: 'Nom',
        site_list_table_head_location: 'Localisation',
        site_list_table_head_state: 'Statut',
        site_list_table_head_server_version: 'Version de serveur',
        site_list_table_head_server_id: 'ID de serveur',
        site_list_table_head_labels: 'Libellé',
        site_list_table_head_approved: 'Approuvé',
        site_list_table_head_type: 'Type',
        site_list_table_head_version: 'Version',
        site_list_table_head_client_version: 'Version de client',
        site_list_table_head_last_heartbeat: 'Dernier écho',
        site_list_table_head_ip: 'IP',
        site_list_table_head_host: 'Hôte',
        site_list_table_head_machine_code: 'Code machine',
        site_list_sort_tooltip: 'Trier',
        site_list_group_tooltip: 'Grouper',
        site_list_approve_success: 'Site approuvé avec succès',
        site_list_approve_error: 'Echec d\'approbation de site',
        site_list_toolbar_selected_sites: '{count} sélectionnés',
        site_list_toolbar_delete_sites_tooltip: 'Effacer sites',
        site_list_toolbar_delete_sites_success: 'Sites sélectionnés effacés avec succès',
        site_list_toolbar_delete_sites_error: 'Echec d\'effacement de sites',
        site_list_toolbar_create_user_tooltip: 'Ajouter un utilisateur',
        site_list_toolbar_create_card_holder_tooltip: 'Ajouter un titulaire de carte',
        site_list_toolbar_create_site_group_tooltip: 'Ajouter un groupe',
        site_list_group_sitestate_healthy: 'Sain',
        site_list_group_sitestate_offline: 'Hors-ligne',
        site_list_group_sitestate_warning: 'Avertissement',
        site_list_group_sitestate_updating: 'Actualisation',
        site_list_group_sitestate_noconnection: 'Pas de connexion',
        site_list_group_sitestate_ignored: 'Ignoré',
        site_list_connection_user_password: 'Changer le mot de passe pour la connexion utilisateur',
        site_list_table_head_approve_multiple_sites: 'Sites approuvés',
        site_list_table_head_create_report: 'Créer un rapport d\'inventaire',
        site_list_report_success: 'Tâche de rapport créée avec succès',
        site_list_report_error: 'Echec de création de tâche de rapport',
        site_list_table_head_create_audit_report: 'Créer un rapport d\'audit',
        site_list_set_custom_comments_multiple_sites: 'Définir un (des) commentaire(s) personnalisé(s) pour le(s) site(s)',
        site_list_table_head_ignore_site: 'Ignorer le site',
        site_list_restart_site: 'Redémarrer la connexion au site',
        site_list_restart_error_message: 'La création de la tâche a échoué',
        site_list_restart_update_success_message: 'Job(s) créé avec succès',
        site_list_ignore_success_message: 'Réussi',
        site_list_ignore_error_message: 'Échoué',
        plugin_list_toolbar_delete_plugins_tooltip: 'Supprimer les plugins',
        plugin_list_toolbar_delete_plugins_success: 'Plugins sélectionnés supprimés avec succès',
        plugin_list_toolbar_delete_plugins_error: 'Échec de suppression des plugins',
        plugin_list_table_head_sitename: 'Nom du site',
        plugin_list_table_head_port: 'Port',
        plugin_list_table_head_hostname: 'Nom de l\'hôte',
        plugin_list_table_head_ignoredMonitoring: 'Ignoré',
        site_list_toolbar_threshold_configuration: 'Configurer les seuils de surveillance',
        site_list_toolbar_system_messages_configuration: 'Configuration des messages système',
        site_list_toolbar_edit_labels: 'Modifier les étiquettes',
        site_list_toolbar_notification_priority_tooltip: 'Modifier la priorité des notifications',
        worst_performance_sites_title: 'Sites les plus critiques',
        worst_performance_sites_btn: 'Visualiser les sites',
        worst_performance_no_data: 'Aucun sites critiques',
        worst_performance_site_name_column: 'NOM DU SITE',
        worst_performance_cpu_usage_column: 'UTILISATION CPU',
        worst_performance_memory_usage_column: 'UTILISATION MÉMOIRE',
        recent_site_events_column_description: 'DESCRIPTION',
        recent_site_events_column_site: 'SITE',
        recent_site_events_column_timestamp: 'DATE',
        umbrella_dashboard_running_site_services: 'Santé des services',
        umbrella_dashboard_running_site_services_tooltip: 'Santé des services',
        umbrella_dashboard_running_site_services_total: 'Total',
        umbrella_dashboard_running_site_services_critical: 'Critique',
        umbrella_dashboard_running_site_services_warning: 'Avertissement',
        umbrella_dashboard_running_site_services_healthy: 'Sain',
        umbrella_dashboard_running_site_services_offline: 'Hors ligne',
        umbrella_dashboard_running_site_services_ignored: 'Ignoré',
        change_site_connection_user_modal_title: 'Changer le mot de passe pour la connexion utilisateur',
        change_site_connection_user_modal_description: 'Vous êtes sur le point de modifier le mot de passe de l\'utilisateur de connexion au Site.',
        change_site_connection_user_modal_new_password_input_label: 'Nouveau Mot de Passe:',
        change_site_connection_user_modal_submit_button: 'Créer une tâche \'Change password\'',
        change_site_connection_user_modal_form_description: 'Le changement de mot de passe ne sera pas effectué immédiatement. Vous devez d\'abord créer une tâche afin de modifier le mot de passe de l\'utilisateur.',
        change_site_connection_user_job_created_success: 'Tâche(s) de changement de changement de mot de passe de l\'utilisateur créée(s)',
        change_site_connection_user_job_created_error: 'Erreur lors de la création de tâche(s) de changement de mot de passe de l\'utilisateur',
        change_site_user_name_modal_title: 'Modifier le nom d\'utilisateur',
        change_site_user_modal_description: 'Modifier le nom d\'utilisateur pour les utilisateurs sélectionnés',
        change_site_user_modal_username_input_label: 'Nouveau nom d\'utilisateur:',
        change_site_user_modal_submit_button: 'Créer une ou plusieurs tâche(s) \'Changer le nom d\'utilisateur\'',
        change_site_conneciton_user_job_created_success: 'Tâche(s) "Changer le nom d\'utilisateur" créé(s)',
        change_site_conneciton_user_job_created_error: 'Echec de création de tâche(s) "Changer le nom d\'utilisateur"',
        copy_site_user_modal_title: 'Copier l\'utilisateur',
        copy_site_user_modal_description: 'Copier les utilisateurs pour les sites sélectionnés. Veuillez ajouter un nouveau nom d\'utilisateur et mot de passe pour le nouvel utilisateur',
        copy_site_user_modal_username_input_label: 'Nouveau nom d\'utilisateur:',
        copy_site_user_modal_password_input_label: 'Nouveau mot de passe:',
        copy_site_user_modal_submit_button: 'Créer une ou des tâches \'Copier l\'utilisateur\'',
        site_users_list_copy_tooltip: 'Copier l\'utilisateur',
        site_users_list_copy_invalid_tooltip: 'Vous ne pouvez copier qu\'un seul utilisateur par site à la fois',
        copy_site_conneciton_user_job_created_success: 'Tâche(s) \'Copier l\'utilisateur\' créée(s)',
        copy_site_conneciton_user_job_created_error: 'Erreur lors de la création des tâches \'Copier l\'utilisateur\'',
        site_users_list_table_head_site_user: 'Utilisateur',
        site_users_list_table_head_site: 'Site',
        site_users_list_table_head_user_labels: 'Étiquettes d\'utilisateur',
        site_users_list_table_head_site_labels: 'Libellé de site',
        site_users_list_toolbar_selected_users: '{count} sélectionnés',
        site_users_list_toolbar_delete_site_users_tooltip: 'Supprimer les utilisateurs',
        site_users_list_toolbar_delete_site_users_success: 'Tâches de suppression d\'utilisateurs créées avec succès',
        site_users_list_toolbar_delete_site_users_error: 'Échec de la création de tâches pour supprimer les utilisateurs',
        site_users_list_toolbar_change_password_tooltip: 'Modification de mot de passe',
        site_users_list_toolbar_change_properties_tooltip: 'Modifier les propriétés des utilisateurs sélectionnés',
        site_users_list_add_site_user_button: 'Ajouter un utilisateur',
        site_users_list_change_name_tooltip: 'Modifier le nom d\'utilisateur',
        site_users_list_change_name_invalid_tooltip: 'Vous ne pouvez modifier qu\'un seul nom d\'utilisateur par site à la fois',
        deploy_site_title: 'Déployer un site',
        deploy_site_floating_button_tooltip: 'Déployer un site',
        deploy_site_description: 'Pour déployer un nouveau site, remplissez ce formulaire et téléchargez le paquet de déploiement zip. Le paquet comprend un programme d\'installation de site et tous les fichiers de configuration nécessaires. Suivez les instructions de la fenêtre de la console d\'installation du site.',
        deploy_site_required_parameters_label: 'Paramètres de site requis:',
        deploy_site_optional_site_parameters_label: 'Paramètres de site facultatifs:',
        deploy_site_vms_parameters_label: 'Paramètres de connexion au site :',
        deploy_site_input_core_host_label: 'Nom d\'hôte du Core:*',
        deploy_site_input_core_port_label: 'Port du Core:*',
        deploy_site_input_https_label: 'HTTPS:',
        deploy_site_input_as_a_service_label: 'Service Windows:',
        deploy_site_input_service_name_label: 'Nom de service Windows:',
        deploy_site_input_working_dir_label: 'Répertoire de travail:*',
        deploy_site_input_working_dir_tooltip: 'Le chemin d\'accès au service doit être un chemin absolu et le dossier ne doit pas encore exister, mais sera créé par l\'installateur du Gateway.',
        deploy_site_input_plugin_type_label: 'Type de Plugin:*',
        deploy_site_input_name_label: 'Nom:',
        deploy_site_input_location_label: 'Adresse:',
        deploy_site_input_labels_label: 'Libellés:',
        deploy_site_input_vms_host_label: 'Hôte :*',
        deploy_site_input_vms_port_label: 'Port :*',
        deploy_site_input_vms_user_name_label: 'Nom d’utilisateur :*',
        deploy_site_input_vms_password_label: 'Mot de passe :*',
        deploy_site_download_installer_button: 'Télécharger l\'installateur',
        deploy_site_instructions: 'Exécutez Umbrella.Gateway.Installer.exe pour déployer le site.',
        deploy_site_input_core_host_placeholder: 'Nom d\'hôte du Core',
        deploy_site_input_core_port_placeholder: 'Port du Core',
        deploy_site_input_service_name_placeholder: 'Nom du service Windows',
        deploy_site_input_working_dir_placeholder: 'Répertoire de travail',
        deploy_site_input_plugin_type_placeholder: 'Type de Plugin',
        deploy_site_input_name_placeholder: 'Nom',
        deploy_site_input_location_placeholder: 'Adresse',
        deploy_site_input_labels_placeholder: 'Libellés:',
        deploy_site_input_vms_host_placeholder: 'Hôte',
        deploy_site_input_vms_port_placeholder: 'Port',
        deploy_site_input_vms_user_name_placeholder: 'Nom d\'utilisateur',
        deploy_site_input_vms_password_placeholder: 'Mot de passe',
        deploy_site_input_validation_port: 'Numéro de port invalide (1 - 65535)',
        deploy_site_input_validation_name: 'Nom de site invalide',
        deploy_site_input_validation_location: 'Adresse invalide',
        deploy_site_input_validation_working_dir: 'Répertoire de travail invalide',
        deploy_site_input_validation_service_name: 'Nom de service invalide (seules les lettres, chiffres et traits de soulignement sont autorisés)',
        deploy_site_input_validation_host: 'Nom d\'hôte invalide',
        deploy_site_success_message: 'Paquet d\'installation téléchargé avec succès',
        deploy_site_error_message: 'Erreur lors de la création du paquet d\'installation',
        deploy_site_input_branchless: 'Sans branche',
        deploy_site_input_branchless_tooltip: 'En sélectionnant le mode sans branche, vous n\'avez besoin d\'installer qu\'une seule Passerelle Umbrella sur le noyau principal de l\'installation du site et vous gérerez l\'ensemble de l\'installation du site et toutes ses branches. Mais l\'hébergeur du site doit être celui du noyau principal de l\'installation du site.',
        create_user_title_preselected_sites: 'Ajouter un nouvel utilisateur aux sites sélectionnés',
        create_user_title: 'Ajouter un nouvel utilisateur',
        create_user_description: 'Ajouter un nouvel utilisateur aux sites sélectionnés',
        create_user_input_sites_label: 'Sites:',
        create_user_input_sites_placeholder: 'Sélectionnez les sites...',
        create_user_input_username_label: 'Nom d´utilisateur:',
        create_user_sites_jobs_button: 'Créer une ou des tâches « Ajouter un utilisateur »',
        create_user_instructions: 'L\'ajout d\'utilisateurs ne se fera pas immédiatement. Des tâches seront créées qui seront transmis aux sites et traitées',
        create_user_input_password_label: 'Mot de passe:',
        create_user_success_message: 'Tâche(s) créée\'s) avec succès',
        create_user_error_message: 'Création des tâches échouée',
        create_user_input_email_not_matching_regex: 'Adresse email invalide',
        create_user_input_email_placeholder: 'E-Mail',
        create_user_input_email_label: 'E-Mail',
        change_password_modal_title: 'Changer le mot de passe',
        change_password_modal_description: 'Modifier le mot de passe pour les utilisateurs sélectionnés',
        change_password_modal_input_password_label: 'Mot de passe:',
        change_second_password_modal_input_password_label: 'Deuxième mot de passe (optionnel):',
        change_password_modal_button: 'Créer une tâche de changement de mot de passe',
        change_password_modal_success_message: 'Tâche(s) créée(s) avec succès',
        change_password_modal_error_message: 'Échec de la création de tâches pour changer les mots de passe',
        change_password_ignore_second_password: 'Ignorer le deuxième mot de passe:',
        change_camera_password_modal_title: 'Changer le mot de passe caméra',
        change_camera_password_modal_description: 'Changer le mot de passe des caméras sélectionnées',
        change_camera_password_modal_input_password_label: 'Mot de passe:',
        change_camera_password_modal_button: 'Créer tâche(s) \'Change camera password\'',
        change_camera_password_modal_success_message: 'Tâche(s) créée(s) avec succès',
        change_camera_password_modal_error_message: 'Échec de la création de tâches de modification des mots de passe des caméras',
        create_card_holder_title_preselected_sites: 'Ajouter un nouveau titulaire de carte aux sites sélectionnés',
        create_card_holder_title: 'Ajouter un nouveau titulaire de carte',
        create_card_holder_description: 'Ajouter un nouveau titulaire de carte aux sites sélectionnés',
        create_card_holder_input_sites_label: 'Sites :',
        create_card_holder_input_sites_placeholder: 'Sélectionner les sites...',
        create_card_holder_input_firstname_label: 'Prénom :',
        create_card_holder_input_lastname_label: 'Nom de famille :',
        create_card_holder_input_cardnumber_label: 'Numéro de carte :',
        create_card_holder_input_start_date_label: 'Date de début :',
        create_card_holder_input_end_date_label: 'Date de fin :',
        create_card_holder_sites_jobs_button: 'Créer une (des) tâche(s) "Ajouter un titulaire de carte"',
        create_card_holder_instructions: 'L\'ajout de titulaires de cartes ne se fera pas immédiatement. Des tâches seront créées qui seront transmis aux sites et traitées',
        create_card_holder_success_message: 'Tâche(s) créée(s) avec succès',
        create_card_holder_error_message: 'Échec de création des tâches',
        system_messages_configuration_modal_title: 'Configuration des messages système',
        system_messages_configuration_modal_description: 'Modifier la configuration des messages système ',
        system_messages_configuration_input_jobs_button: 'Créer une (des) tâche(s)',
        siteServices_table_head_siteService_name: 'Nom du service',
        siteServices_table_head_site_name: 'Nom du site',
        siteServices_table_head_labels: 'Libellés',
        siteServices_table_head_site_labels: 'Libellés de site',
        siteServices_table_toolbar_selected_siteServices: '{count} sélectionnés',
        siteServices_table_toolbar_change_properties_tooltip: 'Modifier les propriétés des services sélectionnés',
        site_services_table_toolbar_restart_service: 'Redémarrer le service',
        site_services_table_restart_service_job_created_success: 'Tâche de redémarrage de service créée',
        site_services_table_restart_service_job_created_error: 'Erreur lors de la création de la tâche de redémarrage de service',
        site_services_restart_tooltip: 'Redémarrer les services',
        site_service_performance_tooltip_start: 'Démarrer les services',
        site_service_performance_tooltip_stop: 'Arrêter les services',
        cameras_table_head_camera_name: 'Nom de la caméra',
        cameras_table_head_site_name: 'Nom du site',
        cameras_table_head_labels: 'Libellés',
        cameras_table_head_site_labels: 'Libellés de site',
        cameras_table_toolbar_selected_cameras: '{count} selectionné(s)',
        cameras_table_toolbar_change_properties_tooltip: 'Modifier les propriétés des caméras sélectionnées',
        cameras_table_toolbar_change_password_tooltip: 'Modifier le mot de passe des caméras sélectionnées',
        camera_table_toolbar_apply_firmware_tooltip: 'Mise à jour Firmware',
        apply_firmware_modal_step_one: 'Sélectionner Firmware',
        apply_firmware_modal_step_two: 'Confirmer la mise à jour',
        apply_firmware_modal_step_one_title: 'Selectionner nouveau Firmware',
        apply_firmware_modal_step_one_description: 'Veuillez vous assurer que les caméras que vous avez sélectionnées sont compatibles avec ce Firmware !',
        apply_firmware_modal_next_button: 'Suivant',
        apply_firmware_modal_step_two_title: 'Mise à jour Firmware',
        apply_firmware_modal_step_two_description: 'Les caméras listées seront mises à jour vers {filename}.',
        apply_firmware_modal_create_jobs: 'Créer tâche(s)',
        apply_firmware_modal_cancel: 'Annuler',
        apply_firmware_create_job_success: 'Tâche(s) créée(s) avec succès',
        apply_firmware_create_job_error: 'Erreur de création de tâches',
        general_validation_required_field: 'Champ obligatoire',
        general_validation_invalid_field: 'Valeur de champ invalide',
        site_plugin_type_fake: 'Plugin test',
        site_plugin_type_cayugar10: 'Plugin Cayuga R10',
        site_plugin_type_cayugar11: 'Plugin Cayuga R11',
        site_plugin_type_cayugar12: 'Plugin Cayuga R12',
        site_plugin_type_cayugar13: 'Plugin Cayuga R13',
        site_plugin_type_cayugar14: 'Plugin Cayuga R14',
        site_plugin_type_cayugar15: 'Plugin Cayuga R15',
        site_plugin_type_cayugar16: 'Plugin Cayuga R16',
        site_plugin_type_cayugar17: 'Plugin Cayuga R17',
        site_plugin_type_situator: 'Qognify Situator Plugin',
        site_plugin_type_qognifyvms71: 'Plugin Qognify VMS 7.1',
        plugin_type_monitoring: 'Surveillance',
        plugin_type_vms: 'Installation du site',
        multiselect_create_label: 'Créer "{value}"',
        dropdown_selection_none: 'Aucun',
        dropdown_selection_true: 'Oui',
        dropdown_selection_false: 'Non',
        toolbar_auto_refresh: 'Suspendre les mises à jour en direct',
        toolbar_auto_refresh_play: 'Démarrer la mise à jour en direct',
        table_pagination_rows_per_page: 'Lignes par page',
        table_pagination_displayed_rows: '{from}-{to} à {count}',
        site_details_title: 'Vous pouvez modifier les paramètres de site en fonction de vos préférences ci-dessous.',
        site_details_label_placeholder: 'Sélectionnez les libellés...',
        site_details_username_label: 'Nom:',
        site_details_label_label: 'Libellé:',
        site_details_location_label: 'Adresse:',
        site_details_ip_label: 'IP:',
        site_details_host_label: 'Hôte:',
        site_details_type_label: 'Type:',
        site_details_state_label: 'Etat:',
        site_details_last_heartbeat_label: 'Dernier ping:',
        site_details_vms_server_version_label: 'Version du serveur:',
        site_details_vms_client_version_label: 'Version du client:',
        site_details_version_label: 'Version:',
        site_details_vms_server_id_label: 'Identifiant du serveur:',
        site_details_machine_code_label: 'Code machine:',
        site_details_update_button: 'Mise à jour:',
        site_details_reset_all_button: 'Tout réinitialiser',
        site_details_site_update_success_message: 'Site mis à jour avec succès',
        site_details_site_update_error_message: 'La mise à jour du site a échoué',
        site_details_fetch_site_details_error: 'L\'obtention des détails du site a échoué',
        site_details_name_error_message: 'Entrez un nom de site valide',
        site_details_location_error_message: 'Adresse invalide',
        site_details_tab_details: 'Détails',
        site_details_tab_comments: 'Commentaires',
        site_details_tab_issues: 'Problèmes',
        site_details_issues_title: 'Ci-dessous vous pouvez voir les problèmes de ce site',
        site_details_issues_start_tooltip: 'Démarrage',
        site_details_issues_stop_tooltip: 'Arrêter',
        site_details_issues_restart_tooltip: 'Redémarrer',
        site_details_issues_ignore_tooltip: 'Ignorer',
        site_details_issues_cameras: 'Caméras',
        site_details_issues_systems: 'Systèmes',
        site_details_issues_services: 'Services',
        site_details_comment_update_success_message: 'Commentaire mis à jour avec succès',
        site_details_comment_update_error_message: 'Echec de la mise à jour du commentaire',
        site_details_comment_title: 'Titre',
        site_details_comment_description: 'Description',
        site_details_comment_update_comment: 'Mettre à jour le commentaire',
        site_details_comment_add_comment: 'Ajouter commentaire',
        site_details_comment_update_comments: 'Actualiser',
        site_details_comment_max_comments_reached: 'Maximum atteint',
        site_details_comment_reset_comments: 'Réinitialiser tout',
        site_details_comments_title: 'Vous pouvez modifier ou ajouter des commentaires sur le site ci-dessous',
        site_details_comment_remove_comment: 'Supprimer commentaire',
        site_details_comment_clear_comment: 'Effacer',
        site_details_comment_list_comments_group: 'Commentaires',
        property_general_enabled: 'Activé',
        property_general_status: 'Statut',
        property_general_statuscode: 'Code du status',
        property_general_statustimestamp: 'Horodatage du statut',
        property_device_model: 'Modèle',
        property_device_manufacturer: 'Fabricant',
        property_device_host: 'Hôte',
        property_device_firmware: 'Firmware',
        property_videostream_fps_01: 'Stream_Framerate.01',
        property_videostream_fps_02: 'Stream_Framerate.02',
        property_videostream_fps_03: 'Stream_Framerate.03',
        property_videostream_fps_04: 'Stream_Framerate.04',
        property_videostream_fps_05: 'Stream_Framerate.05',
        property_videostream_fps_06: 'Stream_Framerate.06',
        property_videostream_resolution_01: 'Stream_Resolution.01',
        property_videostream_resolution_02: 'Stream_Resolution.02',
        property_videostream_resolution_03: 'Stream_Resolution.03',
        property_videostream_resolution_04: 'Stream_Resolution.04',
        property_videostream_resolution_05: 'Stream_Resolution.05',
        property_videostream_resolution_06: 'Stream_Resolution.06',
        property_videostream_videocodec_01: 'Stream_VideoCodec.01',
        property_videostream_videocodec_02: 'Stream_VideoCodec.02',
        property_videostream_videocodec_03: 'Stream_VideoCodec.03',
        property_videostream_videocodec_04: 'Stream_VideoCodec.04',
        property_videostream_videocodec_05: 'Stream_VideoCodec.05',
        property_videostream_videocodec_06: 'Stream_VideoCodec.06',
        property_groups_users: 'Utilisateurs',
        property_rights_activate_active_directory_support: 'Activer le support de l\'Active Directory',
        property_rights_user_must_use_secure_password: 'L\'utilisateur doit utiliser un mot de passe sécurisé',
        property_rights_user_may_change_own_password: 'L\'utilisateur peut changer son propre mot de passe',
        property_rights_use_secure_password_for_exports: 'Utiliser un mot de passe sécurisé pour les exportations',
        property_rights_comment_necessary_for_changing_to_archive_mode: 'Commentaire nécessaire pour passer en mode archive',
        property_rights_user_must_change_password_regularly: 'L\'utilisateur doit changer de mot de passe régulièrement (jours)',
        property_rights_limit_archive_access: 'Limiter l\'accès aux archives (minutes)',
        property_rights_can_manually_record: 'Afficher le bouton d\'enregistrement',
        property_global_rights_camera_live: 'Voir la vidéo en direct',
        property_global_rights_camera_recorded: 'Voir la vidéo enregistrée',
        property_global_rights_camera_recorded_delete: 'Supprimer les enregistrements',
        property_global_rights_camera_write_protection: 'Activer la protection contre l\'écrasement',
        property_global_rights_camera_ptz: 'Caméra PTZ',
        property_global_rights_camera_ptz_priority: 'Verrouillage de la caméra',
        property_global_rights_camera_preset: 'Utiliser les préréglages de la Caméra',
        property_global_rights_camera_preset_create: 'Créer des préréglages de caméra',
        property_global_rights_camera_export_seetec: 'Export de la Caméra',
        property_global_rights_camera_export_avi: 'Export de la Caméra (AVI)',
        property_global_rights_user_privacy_zones_right: 'Masque privatif',
        property_global_rights_mpeg_audio: 'MPEG audio',
        property_global_rights_reference_image_create: 'Créer image de référence',
        property_global_rights_intrusion_detection: 'Détection d\'intrusion',
        property_global_rights_map_use: 'Plan du site',
        property_global_rights_layer_use: 'Vues',
        property_global_rights_button_use: 'Boutons',
        property_global_rights_report_mode: 'Analyse d´évenement',
        property_global_rights_report_mode_templates: 'Modifier les modèles de rapport',
        property_global_rights_counting_data_interpretation: 'Comptage',
        property_global_rights_lp_group_use: 'Utiliser le groupe de plaques d\'immatriculation',
        property_global_rights_lp_group_change: 'Changer le groupe de plaques d\'immatriculation',
        property_global_rights_event_interface: 'Utiliser l\'interface d\'événement',
        property_global_rights_access_control_use_data_editor: 'Editeur de données de contrôle d\'accès',
        property_global_rights_use_event_interface_commands: 'Utiliser les commandes d\'interface d\'événement',
        property_events_sourceid: 'ID Source',
        property_events_causeid: 'ID Cause',
        property_events_sourcename: 'Source',
        property_events_causename: 'Cause',
        property_events_value: 'Valeur',
        property_events_related_positive_event: 'Événement de résolution associé',
        property_events_affected_negative_events: 'Événements de problèmes associés',
        property_events_description: 'Description',
        property_recording_standard_recording_enabled: 'Enregistrement Standard Activé',
        property_recording_standard_recording_period: 'Période d\'enregistrement Standard',
        property_recording_standard_recording_timelimit: 'Limite de temps de l\'enregistrement standard (minutes)',
        property_recording_alarm_recording_enabled: 'Enregistrement d\'alarme Activé',
        property_recording_alarm_recording_timelimit: 'Limite de temps de l\'enregistrement d\'alarme (minutes)',
        property_recording_alarm_recording_stream: 'Flux d\'enregistrement d\'alarme',
        property_recording_alarm_recording_prebuffer: 'Durée de pré-alarme max (s)',
        property_recording_alarm_recording_postduration: 'Durèe maximum de post-alarme (s)',
        property_recording_statistics_treshold_enabled: 'Activer les seuils',
        property_recording_statistics_maximum_packetloss: 'Perte de paquets max (%)',
        property_recording_statistics_standard_recording_framerate_delta: 'Delta de fréquence d\'images de l\'enregistrement Standard (fps)',
        property_recording_statistics_standard_recording_maximum_frames_dropped: 'Perte d\'images maximum de l\'enregistrement Standard',
        property_recording_statistics_alarm_recording_framerate_delta: 'Delta de fréquence d\'images de l\'enregistrement d\'alarme (fps)',
        property_recording_statistics_alarm_recording_maximum_frames_dropped: 'Perte d\'images maximum de l\'enregistrement d\'alarme',
        property_recording_statistics_notifications: 'Activer les notifications des statistiques de MDB',
        property_recording_statistics_recording_state: 'Statut de l\'enregistrement',
        property_recording_statistics_standard_max_timeperiod: 'Période d\'enregistrement Standard max.',
        property_recording_statistics_alarm_max_timeperiod: 'Période d\'enregistrement d\'alarme max.',
        property_recording_statistics_standard_actual_timeperiod: 'Période d\'enregistrement Standard actuel',
        property_recording_statistics_alarm_actual_timeperiod: 'Période d\'enregistrement d\'alarme actuel',
        property_recording_statistics_standard_oldest_frametime: 'Horodatage de la plus ancienne image de l\'enregistrement standard',
        property_recording_statistics_alarm_oldest_frametime: 'Horodatage de la plus ancienne image de l\'enregistrement d\'alarme',
        property_recording_statistics_timestamp: 'Statistiques d\'horodatage des enregistrements',
        property_recording_statistics_devicemanager: 'Device Manager',
        property_runtime_runslocal: 'Fonctionne localement',
        property_runtime_memoryUsage: 'Utilisation Mémoire (MB)',
        property_runtime_diskUsage: 'Utilisation disque (GB)',
        property_runtime_cpuUsage: 'Utilisation CPU (%)',
        property_timeprofile_profile: 'Horaire',
        property_device_firmware_upgrade: 'Support de la mise à jour de Firmware',
        property_device_password_modify: 'Support du changement de mot de passe de caméra',
        property_device_description: 'Description',
        property_device_videoserver_id: 'Identifiant du serveur vidéo',
        property_device_videoserver_description: 'Description du serveur vidéo',
        property_administrative_rights_video: 'Caméras',
        property_administrative_rights_other_hardware: 'Autre matériel',
        property_administrative_event_interfaces: 'Interfaces d\'évènement',
        property_administrative_user: 'Utilisateur',
        property_administrative_time_pattern: 'Gestion des horaires',
        property_administrative_company_calendar: 'Calendriers industriels',
        property_administrative_rights_alarm: 'Alarmes',
        property_administrative_rights_layer: 'Vues',
        property_administrative_rights_map: 'Plans du site',
        property_administrative_rights_button: 'Boutons',
        property_administrative_rights_patrol: 'Tours de garde',
        property_administrative_rights_sequence: 'Patrouille',
        property_administrative_rights_videowall: 'Murs vidéo',
        property_administrative_rights_LPR: 'Groupe de plaques d\'immatriculations',
        property_administrative_rights_server: 'Serveur',
        property_administrative_rights_system: 'Système',
        property_administrative_rights_website: 'Pages Web',
        property_administrative_access_control: 'Contrôle d\'accès',
        property_runtime_host: 'Hôte',
        property_runtime_serviceName: 'Nom du service',
        property_cardholder_base_card_number: 'Numéro de carte de base',
        property_cardholder_employee_number: 'Numéro d\'employé',
        property_door_state: 'Statut',
        site_user_details_tab_details: 'Détails',
        site_user_details_title: 'Ci-dessous, vous pouvez modifier les paramètres de l\'utilisateur en fonction de vos préférences.',
        site_user_details_user_name: 'Nom d´utilisateur:',
        site_user_details_site: 'Site:',
        site_user_details_label: 'Libellé:',
        site_user_details_update_button: 'Détails de la mise à jour',
        site_user_details_user_update_success_message: 'Utilisateur mis à jour avec succès',
        site_user_details_user_update_error_message: 'La mise à jour de l\'utilisateur a échoué',
        site_user_details_user_properties_update_success_message: 'Job(s) créé avec succès',
        site_user_details_user_properties_update_error_message: 'La création des tâches a échoué',
        site_user_details_properties_title: 'Ci-dessous, vous pouvez modifier les propriétés de l\'utilisateur en fonction de vos préférences.',
        site_user_details_administrative_properties_title: 'Seuls les droits administratifs de la succursale où est située l\'entité sont pris en compte ici.',
        siteService_details_tab_details: 'Détails',
        siteService_details_title: 'Ci-dessous, vous pouvez modifier les paramètres du service en fonction de vos préférences.',
        siteService_details_properties_title: 'Ci-dessous, vous pouvez modifier les propriétés du service en fonction de vos préférences.',
        siteService_details_siteService_name: 'Nom:',
        siteService_details_siteService_site: 'Site:',
        siteService_details_siteService_label: 'Libellé:',
        siteService_details_update_button: 'Détails de la mise à jour',
        siteService_details_update_success_message: 'Service mis à jour avec succès',
        siteService_details_update_error_message: 'Échec de la mise à jour du service',
        siteService_details_properties_update_error_message: 'La création des tâches a échoué',
        siteService_details_properties_update_success_message: 'Job(s) créé avec succès',
        camera_details_tab_details: 'Détails',
        camera_details_title: 'Vous pouvez modifier les paramètres de la caméra selon vos préférences ci-dessous.',
        camera_details_properties_title: 'Vous pouvez modifier les propriétés de la caméra selon vos préférences ci-dessous.',
        camera_details_camera_name: 'Nom:',
        camera_details_camera_site: 'Site:',
        camera_details_camera_label: 'Libellé:',
        camera_details_update_button: 'Détails de la mise à jour',
        camera_details_update_success_message: 'Caméra mise à jour avec succès',
        camera_details_update_error_message: 'La mise à jour de la caméra a echoué',
        camera_details_properties_update_error_message: 'La création de la tâche a échoué',
        camera_details_properties_update_success_message: 'Job(s) créé avec succès',
        properties_form_button_reset_all: 'Tout réinitialiser',
        properties_form_button_create_jobs: 'Créer des tâches de modification de propriétés',
        properties_form_note: 'Les changements sélectionnés ne seront pas effectués immédiatement. L\'application sera effective lors de l\'exécution des tâches créées.',
        properties_form_button_reset: 'Reinitialiser',
        site_jobs_status_done: 'Réussi',
        site_jobs_status_failed: 'Echoué',
        site_jobs_status_to_do: 'À faire',
        site_jobs_status_transmitted: 'Transmis',
        site_jobs_status_canceled: 'Annuler',
        site_jobs_table_head_name: 'Nom',
        site_jobs_table_head_state: 'Statut',
        site_jobs_table_head_createdBy: 'Créé par',
        site_jobs_table_head_created: 'Créé',
        site_jobs_table_head_updated: 'Actualisé',
        site_jobs_table_head_sites: 'Sites',
        site_jobs_table_head_about: 'A propos de',
        site_jobs_table_head_message: 'Message',
        site_jobs_table_head_comment: 'Commentaire',
        site_jobs_table_group_jobstate_todo: 'À faire',
        site_jobs_table_group_jobstate_transmitted: 'Transmis',
        site_jobs_table_group_jobstate_canceled: 'Annulé',
        site_jobs_table_group_jobstate_done: 'Réussi',
        site_jobs_table_group_jobstate_failed: 'Echoué',
        site_jobs_table_toolbar_selected_count: '{count} sélectionné(s)',
        site_jobs_table_toolbar_retry_job: 'Retenter les tâches sélectionnées',
        site_jobs_table_toolbar_cancel_job: 'Annuler les tâches sélectionnées',
        site_jobs_table_cancel_jobs_success: 'Tâches sélectionnées annulées avec succès',
        site_jobs_table_cancel_jobs_error: 'Echec d\'annulation des tâches sélectionnées',
        site_jobs_table_retry_jobs_succes: 'Retentative d\'exécution de tâches réussie',
        site_jobs_table_retry_jobs_error: 'Echec de la retentative d\'exécution de tâches',
        site_job_details_description: 'Déscription:',
        site_job_details_comment: 'Commentaire:',
        site_job_details_type: 'Type:',
        site_job_details_site: 'Site:',
        site_job_details_createdBy: 'Créé par:',
        site_job_details_created: 'Créé:',
        site_job_details_transmitted: 'Tramsmis:',
        site_job_details_canceled: 'Annulé',
        site_job_details_failed: 'Echoué:',
        site_job_details_message: 'Message:',
        site_job_details_card_created: 'Créé',
        site_job_details_card_status: 'Status',
        site_job_details_card_duration: 'Durée: {duration}',
        batch_job_details_title: 'Lot de tâches',
        batch_job_details_chart_legend_failed: 'Echoué ({value})',
        batch_job_details_chart_legend_done: 'Reussi ({value})',
        batch_job_details_chart_legend_todo: 'À faire  ({value})',
        batch_job_details_chart_legend_transmitted: 'Transmis ({value})',
        batch_job_details_chart_legend_canceled: 'Annulé ({value})',
        batch_job_details_group_jobstate_todo: 'À faire',
        batch_job_details_group_jobstate_transmitted: 'Transmis',
        batch_job_details_group_jobstate_canceled: 'Annulé',
        batch_job_details_group_jobstate_done: 'Réussi',
        batch_job_details_group_jobstate_failed: 'Echoué',
        job_configuration_comment: 'Commentaire:',
        job_configuration_comment_title: 'Commentaire sur la tâche:',
        create_umbrella_user_title: 'Ajouter un nouvel utilisateur Umbrella',
        create_umbrella_user_subtitle: 'Ajoutez un nouvel utilisateur Umbrella et sélectionnez les rôles auxquels cet utilisateur doit appartenir.',
        create_umbrella_user_submit_button: 'Ajouter un utilisateur Umbrella',
        create_umbrella_user_success_message: 'Utilisateur Umbrella ajouté avec succès',
        create_umbrella_user_error_message: 'L\'ajout d\'un nouvel utilisateur Umbrella a échoué',
        create_umbrella_user_error_unique_username: 'Ce nom d\'utilisateur existe déjà!',
        umbrella_user_form_label_username: 'Nom d´utilisateur:',
        umbrella_user_form_label_password: 'Mot de passe:',
        umbrella_user_form_label_roles: 'Rôles:',
        umbrella_user_form_placeholder_roles: 'Sélectionner des rôles',
        umbrella_users_table_head_username: 'Nom d´utilisateur',
        umbrella_users_table_head_built_in: 'Intégré',
        umbrella_users_table_head_active_directory: 'Active Directory',
        umbrella_users_table_head_roles: 'Rôles',
        umbrella_users_table_head_last_login: 'Dernier Login:',
        umbrella_users_add_user_button: 'Ajouter un utilisateur',
        umbrella_users_list_toolbar_delete_users_tooltip: 'Supprimer un utilisateur',
        umbrella_users_list_toolbar_selected_users: '{count} sélectionné(s)',
        umbrella_users_list_delete_users_success: 'Utilisateur(s) supprimé(s) avec succès',
        umbrella_users_list_delete_users_error: 'La suppression des utilisateurs a échoué',
        site_groups_table_head_site_group: 'Groupe',
        site_groups_table_head_sites: 'Sites',
        site_groups_table_head_labels: 'Libellés',
        site_groups_table_head_site_labels: 'Libellés de site',
        site_groups_toolbar_delete_site_group_tooltip: 'Supprimer des groupes de sites',
        site_groups_toolbar_selected_site_groups: '{count} sélectionnés',
        site_groups_toolbar_change_properties_tooltip: 'Modifier les propriétés des groupes sélectionnés',
        site_groups_add_site_group_button: 'Ajouter un groupe',
        site_groups_list_toolbar_delete_site_groups_success: 'Tâches de suppression de groupes créées avec succès',
        site_groups_list_toolbar_delete_site_groups_error: 'Échec de la création de tâches pour supprimer des groupes d\'utilisateurs',
        umbrella_roles_table_head_name: 'Nom',
        umbrella_roles_table_head_umbrella_admin: 'Admin Umbrella',
        umbrella_roles_table_head_site_admin: 'Admin de site',
        umbrella_roles_table_head_built_in: 'Intégré',
        umbrella_roles_table_head_users: 'Utilisateurs',
        umbrella_roles_table_head_sites: 'Sites',
        umbrella_roles_table_head_active_directory_group: 'Groupe Active Directory',
        umbrella_roles_table_head_entity_type: 'Type d\'entité',
        umbrella_roles_table_head_event_category: 'Catégorie d\'évènement',
        umbrella_roles_table_head_event_type: 'Type d\'évènement',
        umbrella_roles_table_head_event_filter: 'Filtre',
        umbrella_roles_table_head_event_email: 'E-Mail',
        umbrella_roles_table_head_contains_filter: 'Filtre contenu',
        umbrella_roles_table_head_send_email: 'E-Mail',
        umbrella_roles_table_head_site_labels: 'Libellés de site',
        umbrella_roles_list_toolbar_selected_roles: '{count} sélectionnés',
        umbrella_roles_list_toolbar_delete_roles_tooltip: 'Supprimer le rôle Umbrella',
        umbrella_roles_list_button_add_role_tooltip: 'Ajouter le rôle Umbrella',
        umbrella_roles_list_delete_roles_success: 'Rôle(s) supprimé(s) avec succès',
        umbrella_roles_list_delete_roles_error: 'Échec de suppression des rôles',
        create_site_group_title: 'Ajouter un nouveau groupe',
        create_site_group_subtitle: 'Ajoutez un nouveau groupe avec le nom spécifié pour les sites sélectionnés.',
        create_site_group_input_site_group: 'Groupe:',
        create_site_group_input_sites: 'Sites:',
        create_site_group_input_sites_placeholder: 'Sites séléctionnés',
        create_site_group_submit_button: 'Créer une ou des tâches « Ajouter un groupe »',
        create_site_group_instructions: 'L\'ajout de groupe ne se fera pas immédiatement. Une tâche sera créée et exécutée dans les plus brefs délais.',
        create_site_groups_success_message: 'Job(s) créé(s) avec succès',
        create_site_groups_error_message: 'Échéc de création des tâches',
        create_umbrella_role_title: 'Créer un nouveau rôle Umbrella',
        create_umbrella_role_subtitle: 'Créez un nouveau rôle avec les paramètres souhaités.',
        create_umbrella_role_submit_button: 'Créer un rôle Umbrella',
        create_umbrella_role_success_message: 'Rôle créé avec succès',
        create_umbrella_role_error_message: 'Échec de la création du rôle',
        umbrella_role_form_label_role_name: 'Nom du Role:',
        umbrella_role_form_label_umbrella_administrator: 'Administrateur Umbrella:',
        umbrella_role_form_label_site_administrator: 'Administrateur de site:',
        umbrella_role_form_label_users: 'Utilisateurs:',
        umbrella_role_form_placeholder_users: 'Utilisateurs séléctionnés',
        umbrella_role_form_label_sites: 'Sites:',
        umbrella_role_form_label_site_labels: 'Libellés de site:',
        umbrella_role_form_placeholder_sites: 'Sites séléctionnés:',
        umbrella_role_form_placeholder_site_labels: 'Libellés de site',
        umbrella_role_form_label_active_directory: 'Groupe Active Directory:',
        umbrella_role_form_placeholder_active_directory: 'Sélectionner un groupe Active Directory',
        umbrella_role_form_error_unique_role: 'Le rôle existe déjà!',
        umbrella_role_form_admin_rights_include_all_sites: 'Les droits administrateur Umbrella incluent tous les sites',
        umbrella_role_form_rights_reports_default: 'Rapports:',
        umbrella_role_form_rights_reports_site_maintenance: 'Rapports d\'inventaire:',
        umbrella_role_form_rights_reports_site_audit: 'Rapports d\'audit:',
        umbrella_role_form_rights_approve_gateway: 'Approbation de sites:',
        umbrella_role_form_rights_deploy_gateway: 'Deploiement de sites:',
        umbrella_role_form_rights_delete_gateway: 'Effacement de sites:',
        umbrella_role_form_rights_upload_firmware: 'Chargement Micrologiciel:',
        umbrella_role_form_rights_restart_camera_and_sites: 'Redémarrer les caméras/services:',
        umbrella_role_form_rights_monitoring: 'Surveillance:',
        umbrella_role_form_rights_ignore: 'Ignorer:',
        umbrella_role_details_title: 'Détails du rôle Umbrella',
        umbrella_role_details_title_built_in_role: 'Détails du rôle Umbrella',
        umbrella_role_details_subtitle: 'Détails pour le rôle Umbrella {role}',
        umbrella_role_details_subtitle_built_in_role: 'Détails pour le rôle Umbrella {role} - ne peut pas être édité',
        umbrella_role_details_update_success_message: 'Mise à jour du rôle avec succès',
        umbrella_role_details_update_error_message: 'La mise à jour du rôle á echouée',
        site_group_details_update_success_message: 'Groupe mis à jour avec succès',
        site_group_details_update_error_message: 'Échec de la mise à jour du groupe',
        site_group_details_title: 'Ci-dessous, vous pouvez modifier les paramètres des groupes en fonction de vos préférences.',
        site_group_details_site_group: 'Groupe:',
        site_group_details_site: 'Site:',
        site_group_details_labels: 'Libellés:',
        site_group_details_update_button: 'Détails de mise à jour',
        site_group_details_tab_details: 'Détails',
        site_group_details_properties_title: 'Ci-dessous, vous pouvez modifier les propriétés des groupes en fonction de vos préférences.',
        site_group_details_properties_update_success_message: 'Job(s) créé(s) avec succès',
        site_group_details_properties_update_error_message: 'Échéc de création de tâches',
        umbrella_user_details_title: 'Détails de {name}',
        umbrella_user_details_title_built_in_user: 'Détails de {name}',
        umbrella_user_details_subtitle: 'Ci-dessous, vous pouvez modifier les détails de {name}',
        umbrella_user_details_subtitle_built_in_user: 'Ci-dessous, vous ne pouvez pas modifier les détails de {name}',
        umbrella_user_details_label_username: 'Nom d´utilisateur:',
        umbrella_user_details_label_roles: 'Rôles:',
        umbrella_user_details_placeholder_roles: 'Rôles séléctionnés:',
        umbrella_user_details_update_button: 'Détails de la mise à jour',
        umbrella_user_details_update_success_message: 'Utilisateur mis à jour avec succès',
        umbrella_user_details_update_error_message: 'La mise à jour de l\'utilisateur a échoué',
        password_requirements_require_digit: 'Nécessite un chiffre',
        password_requirements_required_length: 'Au moins {lengthNumber} caractères',
        password_requirements_require_non_alphanumeric: 'Nécessite au moins un caractère non alphanumérique',
        password_requirements_require_uppercase: 'Nécessite au moins une lettre majuscule',
        password_requirements_require_lowercase: 'Nécessite au moins une lettre minuscule',
        password_requirements_required_unique_characters: 'Nécessite au moins {uniqueNumber} caractères uniques',
        password_requirements_title: 'Exigences de mot de passe',
        change_properties_multi_no_common_properties: 'Aucune propriété commune trouvée',
        change_properties_multi_no_value_placeholder: 'Entrez la valeur…',
        change_properties_multi_success_message: 'Job(s) créé(s) avec succès',
        change_properties_multi_error_message: 'Échéc de création des tâches',
        change_properties_multiple_users_title: 'Modifier les propriétés des utilisateurs sélectionnés',
        change_properties_multiple_users_subtitle: 'Ci-dessous, vous pouvez modifier les propriétés de l\'utilisateur en fonction de vos préférences.',
        change_properties_multiple_site_groups_title: 'Modifier les propriétés des groupes sélectionnés',
        change_properties_multiple_site_groups_subtitle: 'Ci-dessous, vous pouvez modifier les propriétés du groupe en fonction de vos préférences.',
        change_properties_multiple_cameras_title: 'Modifier les propriétés des caméras sélectionnées',
        change_properties_multiple_cameras_subtitle: 'Vous pouvez modifier les propriétés de caméra selon vos préférences ci-dessous.',
        change_properties_multiple_siteServices_title: 'Modifier les propriétés des services sélectionnés',
        change_properties_multiple_siteServices_subtitle: 'Ci-dessous, vous pouvez modifier les propriétés des services en fonction de vos préférences.',
        change_properties_multiple_access_control_cardholders_title: 'Modifier les propriétés des titulaires de carte sélectionnés',
        change_properties_multiple_access_control_cardholders_subtitle: 'Ci-dessous, vous pouvez modifier les propriétés du groupe en fonction de vos préférences.',
        change_properties_multiple_access_control_doors_title: 'Modifier les propriétés des portes sélectionnées',
        change_properties_multiple_access_control_doors_subtitle: 'Ci-dessous, vous pouvez modifier les propriétés du groupe en fonction de vos préférences.',
        change_password_description: 'Veuillez saisir un nouveau mot de passe.',
        change_password_input_current_password_placeholder: 'Mot de passe actuel',
        change_password_input_new_password_placeholder: 'Nouveau mot de passe',
        change_password_submit_button: 'Sauvegarder',
        change_password_single_error_message: 'Impossible de changer le mot de passe',
        license_warning_unlicensed_cameras: '{count} caméras sans licence détectées dans le système.',
        license_warning_expiration_date: 'Votre licence expire dans {days} jours! Veuillez renouveler votre licence.',
        license_overview_main_title: 'Gestion des licences',
        license_overview_sub_title: 'Un aperçu de la licence Umbrella.',
        license_overview_unlicensed_cameras: 'Caméras sans licence',
        license_overview_expiration_date: 'Date d\'expiration',
        licenses_chart_in_use_total: 'Total',
        licenses_chart_heading: 'Licences',
        licenses_chart_licensed_cameras: 'Utilisé',
        licenses_chart_unlicensed_cameras: 'Non utilisé',
        license_modules_title: 'Modules avec licences',
        license_modules_configuration: 'Administration de site',
        license_modules_monitoring: 'Monitoring',
        license_modules_reporting: 'Rapports',
        license_machine_code_label: 'Code Machine:',
        license_apply_new_license_label: 'Appliquer une clé de licence:*',
        license_apply_new_license_placeholder: 'Entrer la clé de licence',
        license_apply_new_license_success_message: 'Clé de licence mise à jour avec succès',
        license_apply_new_license_error_message: 'Échéc de mise à jour de la clé de licence',
        license_apply_new_license_info: '* La mise à jour de la clé de licence redémarrera Umbrella Core immédiatement',
        license_modules_situatorExclusive: 'Situator',
        license_modules_webapi: 'Web API',
        site_license_update_toolbar_update_licenses: 'Mettre à jour les licences',
        site_license_update_toolbar_export_csv: 'Export en CSV',
        site_license_update_upload_file_title: 'Mettre à jour les licences',
        site_license_update_upload_file_subtitle: 'Téléchargez votre fichier ou faites simplement glisser un fichier ci-dessous.',
        site_license_update_upload_file_box_title: 'Télécharger ou glisser-déposer un fichier',
        site_license_update_upload_file_ext_restriction: 'Vous ne pouvez télécharger que des fichiers zip ou key.',
        site_license_update_upload_file_check_error: 'Fichier de licence fourni non valide',
        site_license_update_review_subtitle: 'Vous trouverez ci-dessous une liste des sites sur lesquels les licences seront appliquées.',
        site_license_update_review_submit: 'Créer des tâches',
        site_license_update_review_cancel: 'Annuler',
        site_license_update_review_job_info: 'Les modifications sélectionnées ne seront pas effectuées immédiatement. Vous devez d\'abord créer des tâches.',
        site_license_update_success_message: 'Job(s) créé avec succès',
        site_license_update_error_message: 'Impossible de créer des tâches',
        site_license_step_one: 'Télécharger un fichier',
        site_license_step_two: 'Confirmer la mise à jour',
        quick_action_menu_add_site_user: 'Ajouter un utilisateur',
        quick_action_menu_add_site_group: 'Ajouter un groupe',
        site_event_type_status_changed: 'Statut modifié',
        site_event_type_system_message: 'Message système',
        site_event_entity_type_camera: 'Caméra',
        site_event_entity_type_runtime: 'Service',
        site_event_entity_type_user: 'Utilisateur',
        site_event_entity_type_group: 'Groupe',
        site_event_entity_type_access_control_cardholder: 'Titulaire de carte',
        site_event_entity_type_access_control_door: 'Porte',
        site_event_entity_type_unknown: 'Inconnu',
        site_event_entity_type_site: '',
        site_event_entity_type_system: '',
        site_event_category_info: 'Info',
        site_event_category_error: 'Erreur',
        site_event_category_warning: 'Avertissement',
        site_event_category_unknown: 'Inconnu',
        advanced_job_configuration_title: 'Configuration de Tâche Avance:',
        advanced_job_configuration_execution_datetime: 'Exécution (fuseau horaire de site):',
        advanced_job_configuration_undo_datetime: 'Annuler:',
        advanced_job_configuration_retry: 'Retenter:',
        advanced_job_configuration_datepicker_ok: 'OK',
        advanced_job_configuration_datepicker_cancel: 'ANNULER',
        grid_layout_save: 'Sauvegarder la disposition',
        grid_layout_restore: 'Restaurer les paramètres par défaut',
        grid_layout_save_success: 'Disposition sauvegardée',
        grid_layout_restore_success: 'Mise en page restaurée',
        grid_layout_save_error: 'Sauvegarde de la disposition échouée',
        grid_layout_restore_error: 'Erreur lors de la restauration de la mise en page',
        site_events_table_head_event_name: 'Nom de l\'événement:',
        site_events_table_head_site_name: 'Nom du site:',
        site_events_table_head_labels: 'Libellés',
        site_events_table_head_site_labels: 'Libellés de site',
        site_events_table_head_event_type: 'Type d\'évènement',
        site_events_table_head_entity_type: 'Type d\'entité',
        site_events_table_head_event_category: 'Catégorie d\'évènement',
        site_events_table_head_issue_resolved: 'Problème résolu',
        site_events_table_head_timestamp: 'Horodatage',
        site_events_table_head_description: 'Description',
        site_events_details_title: 'Détails de {name}',
        site_events_details_subtitle: 'Ci-dessous, vous pouvez modifier les paramètres de l\'événement en fonction de vos préférences',
        site_events_details_event_type: 'Type d\'évènement:',
        site_events_details_entity_type: 'Type d\'entité:',
        site_events_details_event_category: 'Catégorie d\'évènement:',
        site_events_details_issue_resolved: 'Problème résolu:',
        site_events_details_timestamp: 'Horodatage:',
        site_events_details_description: 'Description:',
        site_events_details_details_tab: 'Détails',
        site_event_details_updated_success_message: 'Actualisation avec succès',
        site_event_details_updated_error_message: 'Erreur',
        site_event_details_update_button: 'Mettre à jour l\'événement',
        umbrella_core_from_date: 'De:',
        umbrella_core_to_date: 'À:',
        umbrella_core_last_hour: 'Dernière heure',
        umbrella_core_last_day: 'Dernier jour',
        umbrella_core_last_week: 'Dernière semaine',
        umbrella_core_cpu_usage: 'Utilisation CPU (%)',
        umbrella_core_memory_usage: 'Utilisation Mémoire (MB)',
        umbrella_core_request_count: 'Total requête',
        umbrella_core_request_duration: 'Durée requête (ms)',
        umbrella_core_no_data_to_show: 'Pas de données à afficher',
        email_configuration_title: 'Configuration E-Mail',
        email_configuration_description: 'Configuration de la communication avec le serveur SMTP et envoi d\'un E-Mail de test pour valider les réglages',
        email_configuration_smtp_address: 'Hôte SMTP',
        email_configuration_smtp_port: 'Port SMTP',
        email_configuration_username: 'Nom d\'utilisateur',
        email_configuration_password: 'Mot de passe',
        email_configuration_sender_email: 'E-Mail expéditeur',
        email_configuration_encryption_method: 'Méthode d\'encryption',
        email_configuration_subject: 'Sujet',
        email_configuration_test: 'Test',
        email_configuration_encryption_null: 'Pas d\'encryption',
        email_configuration_encryption_ssl: 'SSL/TLS',
        email_configuration_submit: 'Mise à jour',
        email_configuration_reset_fields: 'Réinitialiser tout',
        email_configuration_update_success: 'Mise à jour des réglages E-Mail avec succès',
        email_configuration_update_error: 'Mise à jour des réglages E-Mail échoué\r\n',
        email_configuration_test_success: 'Réussite de l\'envoi de l\'E-Mail test',
        email_configuration_test_error: 'Échec de l\'envoi de l\'E-Mail test',
        email_configuration_test_receiver_title: 'Test réception',
        email_configuration_test_receiver_label: 'Test réception',
        email_configuration_password_checkbox: 'Définir le mot de passe',
        email_configuration_host_error: 'L\'hôte ne doit pas être vide',
        email_configuration_port_error: 'Le port ne peut pas être vide et doit être compris entre 1 - 65535',
        email_configuration_username_error: 'Le nom d\'utilisateur ne peut pas être vide',
        email_configuration_password_error: 'Le mot de passe ne peut pas être vide',
        email_configuration_sender_email_error: 'L\'adresse email de l\'expéditeur ne peut pas être vide',
        email_configuration_encryption_method_error: 'La méthode d\'encryption ne peut pas être vide',
        email_configuration_subject_error: 'Le sujet ne peut pas être vide',
        email_configuration_test_validation_error: 'Adresse E-Mail invalide',
        umbrella_role_form_site_events_notifications: 'Notifications d\'événements:',
        umbrella_role_form_site_event_categories: 'Catégories d\'événements:',
        umbrella_role_form_placeholder_site_event_categories: 'Sélectionner les catégories d\'événements',
        umbrella_role_form_site_event_types: 'Types d\'événements:',
        umbrella_role_form_placeholder_site_event_types: 'Sélectionner les types d\'événements',
        umbrella_role_form_site_entity_types: 'Types d\'entité:',
        umbrella_role_form_placeholder_site_entity_types: 'Sélectionner les types d\'entité',
        umbrella_role_form_contains_filter: 'Filtre contenu:',
        umbrella_role_form_placeholder_contains_filter: 'Sélectionner un filtre',
        umbrella_role_form_tooltip_contains_filter: 'Utilisez ceci pour filtrer la description ou la source avec des balises invariantes de casse. Si vous saisissez plusieurs balises, elles sont traitées en OU logique.',
        umbrella_role_form_send_email: 'Envoyer un E-Mail:',
        umbrella_role_form_send_email_to_ad_group: 'Envoyer des e-mails au groupe AD:',
        umbrella_no_notifications: 'Pas de notifications à afficher',
        umbrella_notification_table_column_type: 'Type',
        umbrella_notification_table_column_source: 'Source',
        umbrella_notification_table_column_site: 'Site',
        umbrella_notification_table_column_timestamp: 'Horodatage',
        umbrella_notification_table_column_resolved: 'Résolu',
        umbrella_notification_table_column_category: 'Catégorie',
        umbrella_notification_table_clear_notifications: 'Effacer les notifications',
        umbrella_notification_table_column_description: 'Description',
        umbrella_notification_table_site_events: 'Événements',
        umbrella_dashboard_new_layout: 'Nouvelle disposition',
        umbrella_dashboard_save_layout: 'Sauvegarder la disposition',
        umbrella_dashboard_delete_layout: 'Effacer la disposition',
        umbrella_dashboard_show_default: 'Afficher par défaut',
        umbrella_dashboard_choose_layout: 'Choisir la disposition',
        umbrella_dashboard_choose_layout_description: 'Personnaliser le tableau de bord en sélectionnant la disposition choisie',
        umbrella_dashboard_choose_layout_save_button: 'Sélectionner',
        umbrella_dashboard_add_card: 'Ajouter une carte',
        umbrella_dashboard_select_card: 'Sélectionner une carte',
        umbrella_dashboard_select_card_description: 'Sélectionner une carte pour l\'ajouter à l\'espace réservé',
        umbrella_dashboard_chart_tab_button: 'Diagrammes',
        umbrella_dashboard_map_tab_button: 'Plans',
        umbrella_dashboard_table_tab_button: 'Tables',
        umbrella_dashboard_replace_card: 'Remplacer une carte',
        umbrella_dashboard_delete_card: 'Effacer une carte',
        umbrella_dashboard_recent_site_events: 'Événements récents',
        umbrella_dashboard_recent_site_events_no_events: 'Pas d\'évènements',
        umbrella_dashboard_view_recent_site_events: 'Visualiser les évènements',
        umbrella_dashboard_choose_layout_site_states_tooltip: 'Statut de site',
        umbrella_dashboard_choose_layout_running_cameras_tooltip: 'Caméras fonctionnelles',
        umbrella_dashboard_choose_layout_running_site_services_tooltip: 'Services en cours d\'exécution',
        umbrella_dashboard_choose_layout_site_approvals_tooltip: 'Approbations de site',
        umbrella_dashboard_choose_layout_core_cpu_tooltip: 'CPU Umbrella Core',
        umbrella_dashboard_choose_layout_core_memory_tooltip: 'Mémoire Umbrella Core',
        umbrella_dashboard_choose_layout_core_request_duration_tooltip: 'Durée de requête d\'Umbrella Core',
        umbrella_dashboard_choose_layout_core_request_count_tooltip: 'Nombre de requête d\'Umbrella Core',
        umbrella_dashboard_choose_layout_map_tooltip: 'Localisation',
        umbrella_dashboard_choose_layout_recent_jobs_tooltip: 'Tâches récentes',
        umbrella_dashboard_choose_layout_recent_site_events_tooltip: 'Événements récents',
        umbrella_dashboard_advanced_layout_add_new_item: 'AJOUTER UN ÉLÉMENT',
        umbrella_eula_title: '',
        umbrella_eula_text: '',
        umbrella_eula_button: '',
        umbrella_eula_file_text: '',
        breadcrumb_dashboard: 'Tableau de bord',
        breadcrumb_control_centre: 'Administration de site',
        breadcrumb_sites: 'Sites',
        breadcrumb_map: 'Localisation',
        breadcrumb_site_users: 'Utilisateurs',
        breadcrumb_site_groups: 'Groupes',
        breadcrumb_access_control_cardholders: 'Titulaires de la carte',
        breadcrumb_access_control_doors: 'Portes',
        breadcrumb_site_services: 'Services',
        breadcrumb_cameras: 'Caméras',
        breadcrumb_site_jobs: 'Tâches',
        breadcrumb_update_site_licenses: 'Licences',
        breadcrumb_administration: 'Administration',
        breadcrumb_umbrella_users: 'Utilisateurs Umbrella',
        breadcrumb_umbrella_roles: 'Rôles Umbrella',
        breadcrumb_administration_license: 'Licence',
        breadcrumb_administration_license_update: 'Licence',
        breadcrumb_monitor_centre: 'Monitoring',
        breadcrumb_umbrella_core: 'Core Umbrella',
        breadcrumb_site_events: 'Événements',
        breadcrumb_email: 'E-Mail',
        breadcrumb_camera_firmware: 'Firmware Camera',
        breadcrumb_administration_settings: 'Paramètres',
        breadcrumb_site_time_profile: 'Profils horaires',
        breadcrumb_report_center: 'Rapports',
        breadcrumb_reports: 'Rapports d\'inventaire',
        breadcrumb_audit_reports: 'Rapports d\'audit',
        breadcrumb_site_systems: 'Systèmes',
        breadcrumb_site_service_performance: 'Services',
        breadcrumb_camera_performance: 'Caméras',
        breadcrumb_plugins: 'Plugins',
        breadcrumb_labels: 'Étiquettes',
        breadcrumb_apiKeys: '',
        site_job_details_execution_time: 'Temps d\'exécution (Fuseau horaire du site):',
        site_job_details_retries: 'Nombre de tentatives:',
        site_job_details_sequence_id: 'ID de séquence:',
        site_job_details_sequence_order: 'Ordre de séquence:',
        site_job_details_sequence_total: 'Total de séquence:',
        site_job_related_undo_job: 'TÂCHE D\'ANNULATION LIÉE ',
        site_job_card_title: 'TÂCHE:',
        site_jobs_table_head_sequence: 'ID de séquence',
        site_jobs_table_head_sequence_order: 'Ordre de séquence',
        site_jobs_table_head_sequence_total: 'Total de séquence',
        site_jobs_table_head_execution_time: 'Temps d\'exécution (Fuseau horaire du site) ',
        site_jobs_table_head_undo_time: 'Heure d\'annulation (Fuseau horaire du site)',
        site_jobs_table_head_retries: 'Tentatives',
        restart_cameras_success_snack_message: 'Création de tâches pour redémarrer les caméras réussie',
        restart_cameras_error_snack_message: 'Echec de la création de tâches pour redémarrer les caméras',
        restart_cameras_tooltip: 'Redémarrer les caméras',
        license_root_title: '',
        license_root_description: 'Gestion de licence',
        license_root_apply_license_key: 'Pas de clé de licence! Utiliser ce code pour effectuer une requête de licence:',
        license_root_input_placeholder: 'Appliquer la clé de licence:',
        license_root_submit_button_text: 'Entrer la clé de licence',
        license_root_problem_license_valid: 'Licence valide',
        license_root_problem_license_invalid: 'Licence invalide',
        license_root_problem_machine_code_missing: 'Code machine manquant',
        license_root_problem_no_modules: 'Pas de modules licenciés',
        license_root_key_update_success: 'Succès de la mise à jour de la clé ',
        license_root_key_update_fail: 'Erreur de la mise à jour de la clé',
        camera_firmware_modal_title: 'Charger nouveau Firmware',
        camera_firmware_modal_description: 'Le Firmware sera stocké sur le serveur Umbrella Core',
        camera_firmware_modal_manfucturer: 'Fabricant:*',
        camera_firmware_modal_supported_model: 'Modèle supporté:*',
        camera_firmware_modal_comment: 'Commentaire:',
        camera_firmware_modal_dropzone: 'Charger ou copier/coller un fichier',
        camera_firmware_modal_upload_btn: 'Charger',
        camera_firmware_modal_upload_success: 'Nouveau Firmware chargé avec succès',
        camera_firmware_modal_upload_error: 'Erreur de chargement de nouveau Firmware',
        camera_firmware_table_filename: 'Nom de fichier',
        camera_firmware_table_manufacturer: 'Fabricant',
        camera_firmware_table_comment: 'Commentaire',
        camera_firmware_table_supported_types: 'Types supportés',
        camera_firmware_table_uploaded_at: 'Chargé à',
        camera_firmware_table_delete_firmwares: 'Effacer Firmware',
        camera_firmware_table_selected_count: '{count} sélectionné(s)',
        camera_firmware_table_upload_modal_button: 'Charger nouveau Firmware',
        camera_firmware_modal_no_file_selected: 'Pas de fichier sélectionné',
        camera_firmware_upload_snackbar_title: 'Chargement Firmware',
        camera_firmware_upload_snackbar_button_error: 'Erreur',
        camera_firmware_upload_snackbar_button: 'Effectué',
        camera_firmware_deleted_success: 'Effacement des Firmware sélectionnés avec succès',
        camera_firmware_deleted_error: 'Erreur d\'effacement des Firmware sélectionnés',
        camera_firmware_modal_file_name: 'Nom de fichier:',
        settings_configuration_title: 'Paramètres généraux',
        settings_configuration_description: 'Ci-dessous vous pouvez éditer les paramètres généraux',
        settings_ad_use_ad_cache: 'Utiliser le cache Active Directory:*',
        settings_ad_refresh_ad_cache_minutes: 'Rafraichir le cache Active Directory (en minutes):*',
        settings_general_provide_health_endpoint: 'Fournir un point de santé:*',
        settings_general_delete_vms_events_days: 'Supprimer les événements du site antérieurs à (en jours) :',
        settings_general_core_performance_measure_interval: 'Intervalle de mesure de performance (en minutes):',
        settings_password_require_digit: 'Nécessite un chiffre:*',
        settings_password_required_length: 'Nécessite une longueur minimale:*',
        settings_password_require_non_alphanumeric: 'Nécessite des caractères non alphanumériques:*',
        settings_password_require_uppercase: 'Nécessite une majuscule:*',
        settings_password_require_lowercase: 'Nécessite une minuscule:*',
        settings_password_required_unique_chars: 'Nécessite des caractères uniques:*',
        settings_login_is_remember_me_available: 'Se souvenir de moi disponible:*',
        settings_login_cookie_persistence_duration: 'Durée de maintien des Cookie (en heures):*',
        settings_login_sliding_expiration_session_cookie: 'Fenêtre d\'expiration de la session du Cookie:*',
        settings_login_validation_interval_seconds: 'Intervalle de validation (en secondes):*',
        settings_ad_is_ad_login_available: 'Authentification Active Directory disponible:*',
        settings_ad_sync_active_directory_email: 'Synchroniser Email Active Directory:*',
        settings_ad_domain_names: 'Noms de domaines:*',
        settings_license_license_key: 'Clé de licence:',
        settings_gateway_heartbeat_interval_seconds: 'Intervalle du message d\'état (en secondes):',
        settings_gateway_use_signalr_for_vms_event: 'Utiliser SignalR pour les événements de site :',
        settings_gateway_vms_network_timeout_seconds: 'Délai d\'expiration du réseau du site (en secondes) :',
        settings_gateway_fetch_recordings_interval_minutes: 'Intervalle de récupération des enregistrements (en minutes):',
        settings_gateway_delete_site_performance_measurements_interval: 'Intervalle d\'effacement des mesures de performance (en jours):',
        settings_general_delete_core_performance_measurements_interval: 'Intervalle d\'effacement des mesures de performance (en jours):',
        settings_gateway_site_performance_measure_interval: 'Intervalle de mesure de performance (en minutes):',
        settings_configuration_submit: 'Actualiser',
        settings_configuration_reset_fields: 'Tout remettre à zéro',
        settings_configuration_restore_default: 'Paramètres par défaut',
        settings_configuration_update_success: 'Paramètres généraux actualisés avec succès',
        settings_configuration_update_error: 'Échec d\'actualisation des paramètres généraux',
        settings_configuration_resart_information: '* changement de ces valeurs redémarrera Umbrella Core immédiatement',
        settings_general_delete_log_days: 'Effacer les fichiers Log plus ancien que (en jours):*',
        settings_gateway_delete_log_days: 'Effacer les fichiers Log plus ancien que (en jours):',
        settings_expert_writeheartbeatcache: 'Intervalle d\'écriture du cache du Heartbeat (en secondes):*',
        settings_expert_allowedmissedheartbeats: 'Nombre de Heartbeats manqués autorisés:*',
        settings_expert_delaygatewayupdate: 'Délai de mise à jour de la passerelle (en secondes):*',
        settings_general_display_username_jobs: 'Afficher les noms d\'utilisateur dans les tâches:*',
        settings_general_is_comment_mandatory: 'Rendre le commentaire sur la tâche obligatoire:*',
        settings_general_delete_reports_when_downloading: 'Supprimer les rapports lorsqu\'ils sont téléchargés:*',
        settings_general_delete_jobs_after_day_interval: 'Supprimez les tâches plus anciennes que (en jours):',
        settings_gateway_delete_system_performance_measurements_interval: 'Intervalle d\'effacement des mesures de performance (en jours):',
        settings_gateway_system_performance_measure_interval: 'Intervalle de mesure de performance (en minutes):',
        settings_general_thresholds_cpu: 'Seuil d\'avertissement de surveillance (%)',
        settings_general_thresholds_memory: 'Seuil critique de surveillance (%)',
        settings_general_thresholds_disk: 'Retarder les notifications de faible priorité (en secondes) :',
        settings_general_thresholds_network: 'Retarder les notifications de priorité normale (en secondes) :',
        settings_notifications_priority_low_delay: '',
        settings_notifications_priority_normal_delay: '',
        settings_thresholds_slider_warning: '',
        settings_thresholds_slider_critical: '',
        settings_group_password: 'RÉGLAGES DU MOT DE PASSE:',
        settings_group_login: 'RÉGLAGES DU LOGIN:',
        settings_group_license: 'RÉGLAGES DE LICENCE:',
        settings_group_gateway: 'RÉGLAGES DE GATEWAY:',
        settings_group_core: 'RÉGLAGES DE CORE:',
        settings_group_core_expert: 'RÉGLAGES DE CORE EXPERT:',
        settings_group_active_directory: 'RÉGLAGES DE ACTIVE DIRECTORY:',
        settings_group_notification: 'PARAMÈTRES DE NOTIFICATION :',
        settings_general_delete_vms_events_error: 'Doit être dans la plage 1 - 365',
        settings_ad_refresh_ad_cache_error: 'Doit être dans la plage 5 - 120',
        settings_general_core_performance_measure_interval_error: 'Doit être dans la plage 1 - 60',
        settings_gateway_site_performance_measure_interval_error: 'Doit être dans la plage 1 - 60',
        settings_password_required_length_error: 'Doit être dans la plage 1 - 100',
        settings_password_required_unique_chars_error: 'Ne peut être moins que 0 ou supérieur à la longueur requise',
        settings_login_cookie_persistence_duration_error: 'Doit être dans la plage 1 - 365',
        settings_login_validation_interval_error: 'Doit être dans la plage 1 - 365',
        settings_ad_domain_names_error: 'Ne peut être vide si Active Directory est activé',
        settings_gateway_heartbeat_interval_error: 'Doit être dans la plage 1 - 365',
        settings_gateway_vms_network_timeout_error: 'Doit être dans la plage 1 - 365',
        settings_gateway_fetch_recordings_interval_error: 'Doit être dans la plage 1 - 365',
        settings_gateway_delete_site_performance_measurements_interval_error: 'Doit être dans la plage 1 - 365',
        settings_general_delete_core_performance_measurements_interval_error: 'Doit être dans la plage 1 - 365',
        settings_general_delete_log_days_error: 'Doit être dans la plage 1 - 365',
        settings_gateway_delete_log_days_error: 'Doit être dans la plage 1 - 365',
        settings_expert_writeheartbeatcache_error: 'Doit être dans la plage 10 - 60',
        settings_expert_allowedmissedheartbeats_error: 'Doit être dans la plage 3 - 10',
        settings_expert_delaygatewayupdate_error: 'Doit être dans la plage 0 - 30',
        settings_general_delete_jobs_after_day_interval_error: 'Doit être dans la plage 1 - 365',
        umbrella_sites_table_site_name: 'Nom du site',
        umbrella_sites_table_cpu_usage: 'Utilisation CPU (%)',
        umbrella_sites_table_ram_usage: 'Utilisation mémoire (Go)',
        umbrella_sites_table_site_labels: 'Libellés',
        umbrella_sites_table_state_healthy: 'Sain',
        umbrella_sites_table_state_warning: 'Avertissement',
        umbrella_sites_table_state_critical: 'Critique',
        umbrella_sites_cpu_usage: 'Utilisation CPU (%)',
        umbrella_sites_ram_usage: 'Utilisation mémoire (Go)',
        umbrella_sites_no_data_to_show: 'Pas de données à afficher',
        umbrella_sites_disk_usage: 'Utilisation espace de stockage (%)',
        umbrella_sites_services_performance: 'Rendement des services',
        upload_manager_state_done: 'Effectué',
        upload_manager_state_error: 'Erreur',
        upload_manager_clear_completed_button: 'Effacement effectué',
        upload_manager_no_items: 'Pas d\'éléments',
        upload_manager_error_general: 'Erreur',
        upload_manager_error_file_already_exists: 'Fichier existant',
        upload_manager_error_size_too_large: 'Fichier trop grand',
        upload_manager_type_firmware_upload: 'Envoi Firmware: {name}',
        upload_manager_unload_message: 'Dernières actions en cours (vérifier le gestionnaire de tâches)',
        upload_manager_job_async_camera_restart: 'Créer une tâche de redémarrage de caméra ({count})',
        upload_manager_job_async_change_camera_password: 'Créer une tâche de changement de mot de passe de caméra ({count})',
        upload_manager_job_async_camera_firmware_update: 'Créer une tâche de mise à jour de Firmware ({count})',
        upload_manager_job_async_camera_change_properties: 'Créer une tâche de changement des propriétés de caméra ({count})',
        upload_manager_job_async_site_group_create: 'Créer une nouvelle tâche de création de groupe ({count})',
        upload_manager_job_async_site_group_delete: 'Créer une nouvelle tâche de suppression de groupe ({count})',
        upload_manager_job_async_site_group_change_properties: 'Créer une tâche de modification des propriétés des groupes ({count})',
        upload_manager_job_async_license_upload: 'Créer une tâche de mise à jour de licence ({count})',
        upload_manager_job_async_sites_connection_user_password: 'Créer une tâche de changement de mot de passe de connexion d\'utilisateur ({count})',
        upload_manager_job_async_site_services_restart: 'Créer une tâche de redémarrage de service ({count})',
        upload_manager_job_async_site_services_change_properties: 'Créer une tâche de modification des propriétés des services ({count})',
        upload_manager_job_async_site_users_change_password: 'Créer une tâche de modification du mot de passe utilisateur ({count})',
        upload_manager_job_async_site_users_create: 'Créer une nouvelle tâche de création d\'utilisateur ({count})',
        upload_manager_job_async_site_users_delete: 'Créer une nouvelle tâche de suppression d\'utilisateur ({count})',
        upload_manager_job_async_site_users_properties: 'Créer une tâche de modification des propriétés des utilisateurs ({count})',
        upload_manager_job_async_retry_jobs: 'Créer une tâche de nouvelle tentative ({count})',
        upload_manager_job_async_site_time_profile_change_properties: 'Créer une tâche de modification des propriétés du profil de temps ({count})',
        upload_manager_job_async_site_user_change_name: 'Créer une tâche de changement de nom d\'utilisateur ({count})',
        upload_manager_job_async_site_user_copy_user: 'Créer une tâche de copie d’utilisateur ({count})',
        upload_manager_job_async_access_control_cardholders_change_properties: 'Créer une tâche de modification des propriétés du titulaire de la carte ({count})',
        upload_manager_job_async_access_control_doors_change_properties: 'Créer une tâche de modification des propriétés de porte ({count})',
        upload_manager_job_async_access_control_cardholders_create: 'Créer une nouvelle tâche de titulaire de carte ({count})',
        upload_manager_job_async_change_system_message_config: 'Créer une tâche de configuration de message de modification du système {{count}}',
        site_time_profiles_list_add_time_profile_button: 'Ajouter un horaire',
        site_time_profiles_list_table_head_time_profile: 'Horaire',
        site_time_profiles_list_table_head_site: 'Site',
        site_time_profiles_list_table_head_site_labels: 'Libellés de site',
        site_time_profiles_list_table_head_labels: 'Libellés',
        site_time_profiles_table_toolbar_change_time_profiles_tooltip: 'Changer l\'horaire',
        site_time_profiles_table_toolbar_selected_time_profiles: '{count} selectionné(s)',
        site_time_profile_day_monday: 'Lundi',
        site_time_profile_day_monday_short: 'Lu',
        site_time_profile_day_tuesday: 'Mardi',
        site_time_profile_day_tuesday_short: 'Ma',
        site_time_profile_day_wednesday: 'Mercredi',
        site_time_profile_day_wednesday_short: 'Me',
        site_time_profile_day_thursday: 'Jeudi',
        site_time_profile_day_thursday_short: 'Je',
        site_time_profile_day_friday: 'Vendredi',
        site_time_profile_day_friday_short: 'Ve',
        site_time_profile_day_saturday: 'Samedi',
        site_time_profile_day_saturday_short: 'Sa',
        site_time_profile_day_sunday: 'Dimanche',
        site_time_profile_day_sunday_short: 'Di',
        site_time_profile_day_bank_holiday: 'Jour férié',
        site_time_profile_day_bank_holiday_short: 'Jour férié',
        site_time_profile_hours: 'Heures',
        site_time_profile_time_range: 'PLAGE HORAIRE {index}:',
        site_time_profile_time_range_starts: 'Démarrage:',
        site_time_profile_time_range_ends: 'Fin:',
        site_time_profile_time_range_add_new: 'Ajouter nouvelle plage horaire',
        site_time_profile_change_property_job: 'Créer une tâche de changement de propriété',
        site_time_profile_reset: 'Réinitialiser tout',
        site_time_profile_info_desc: 'Les changements sélectionnés ne seront pas effectués immédiatement. Vous devez d\'abord créer des tâches.',
        site_time_profile_general_tab: 'Détails',
        site_time_profile_profile_tab: 'Profil',
        site_time_profile_general_title: 'Changer l\'horaire',
        site_time_profile_general_description: 'Ci-dessous Vous pouvez modifier les paramètres de l\'horaire en fonction de vos préférences.',
        site_time_profile_general_name: 'Nom:',
        site_time_profile_general_site: 'Site:',
        site_time_profile_general_labels: 'Libellés:',
        site_time_profile_general_update_details: 'Mettre à jour les détails',
        site_time_profile_save_current_editing_range: 'Appliquer les changements',
        site_time_profile_save_details_general_success: 'Profil horaire mis à jour avec succès',
        site_time_profile_save_details_general_error: 'Erreur lors de la mise à jour du profil horaire',
        site_time_profile_change_property_success: 'Tâche(s) de changement de propriété créée(s)',
        site_time_profile_change_property_error: 'Echec de création de la  ou des tâche(s) de changement de propriété',
        site_time_profile_change_multiple_title: 'Configuration multiple de profil horaire',
        site_time_profile_change_multiple_description: 'Lorsque vous configurez plusieurs profils horaires, vous commencez toujours par un profil horaire vide lorsque les profils horaires sélectionnés ne correspondent pas.',
        site_time_profile_common_properties_error: 'Echec de vérification des propriétés communes',
        site_time_profile_remove_time_range: 'Supprimer la plage horaire',
        site_time_profile_slider_overlap_warning: 'Les plages horaire se superposent. Elles seront regroupées en une seule plage horaire',
        site_job_update_vms_license: 'Mettre à jour la licence',
        site_job_update_firmware_camera: 'Mettre à jour le Firmare caméra',
        site_job_restart_vms_runtime: 'Redémarrer le service',
        site_job_start_vms_runtime: 'Démarrer le service',
        site_job_stop_vms_runtime: 'Arrêter le service',
        site_job_delete_vms_user: 'Supprimer l\'utilisateur',
        site_job_delete_vms_group: 'Supprimer le groupe',
        site_job_create_vms_user: 'Créer un utilisateur',
        site_job_create_vms_group: 'Créer un groupe',
        site_job_change_vms_user_password: 'Modifier le mot de passe utilisateur',
        site_job_change_connection_user_password: 'Modifier le mot de passe de connexion de l\'utilisateur',
        site_job_change_camera_password: 'Changer le mot de passe caméra',
        site_job_cancel_job: 'Annuler la tâche',
        site_job_batch_job: 'Tâche en lot',
        site_job_change_vms_user_property: 'Modifier la propriété de l\'utilisateur',
        site_job_change_vms_camera_property: 'Changer la propriété de caméra',
        site_job_change_vms_group_property: 'Modifier la propriété du groupe',
        site_job_change_vms_runtime_property: 'Modifier la propriété du service',
        site_job_change_vms_event_property: 'Modifier la propriété de l\'événement',
        site_job_change_vms_timeprofile_property: 'Modifier la propriété du profil horaire',
        site_job_change_vms_user_name: 'Renommer l\'utilisateur',
        site_job_copy_vms_user: 'Copier l\'utilisateur',
        site_job_restart_gateway: 'Redémarrer la connexion au site',
        site_job_create_accesscontrol_cardholder: 'Créer un titulaire de carte',
        site_job_change_vms_event_config_property: 'Modifier la propriété de configuration d\'événement',
        selectionbox_toolbar_select_mode_page: 'Seulement page actuelle',
        selectionbox_toolbar_select_mode_selectAll: 'Toutes les pages',
        selectionbox_siteselector_include_all_sites: 'Mode tout sélectionnés inclut tous les sites',
        reports_table_head_filename: 'Nom de fichier',
        reports_table_head_createdAt: 'Créé à',
        reports_table_head_createdBy: 'Créé par',
        reports_table_head_comment: 'Commentaire',
        reports_table_head_siteName: 'Nom du site',
        reports_download_success_snack_message: 'Tâche(s) de téléchargement de rapport créée(s) avec succès',
        reports_download_error_snack_message: 'Echec de création de tâche(s) de téléchargement de rapport',
        reports_table_toolbar_download_tooltip: 'Téléchargment des rapports sélectionnés',
        reports_table_toolbar_delete_tooltip: 'Effacer les rapports sélectionnés',
        reports_delete_success_snack_message: 'Rapports séléctionnés effacés avec succès',
        reports_delete_error_snack_message: 'Echec de suppression des rapports',
        create_audit_report_modal_title: 'Créer un rapport d\'audit',
        create_audit_report_modal_description: 'Créer un rapport d\'audit pour la période sélectionnée',
        create_audit_report_modal_start_label: 'Date de début',
        create_audit_report_modal_end_label: 'Date de fin',
        create_audit_report_modal_submit_button: 'Demander un rapport d\'audit',
        create_audit_report_modale_error: 'La date de fin ne peut être avant la date de début',
        create_audit_report_success_snack_message: 'Tâche(s) de création de rapport d\'audit créé(s) avec succès',
        create_audit_report_failed_snack_message: 'Echec de création de tâches de rapports d\'audit',
        audit_reports_table_head_filename: 'Nom de fichier',
        audit_reports_table_head_createdAt: 'Créé à',
        audit_reports_table_head_createdBy: 'Créé par',
        audit_reports_table_head_comment: 'Commentaire',
        audit_reports_table_head_siteName: 'Nom de site',
        audit_reports_table_head_from: 'De',
        audit_reports_table_head_till: 'À',
        custom_comments_modal_title: 'Commentaires personnalisés',
        custom_comments_modal_description: 'Description de commentaires personnalisés',
        copy_to_clipboard_button_tooltip: 'Copier dans le presse-papier',
        site_systems_chip_healthy: 'Sain',
        site_systems_chip_warning: 'Avertissement',
        site_systems_chip_critical: 'Critique',
        site_systems_chip_offline: 'Hors ligne',
        site_systems_chip_ignore: 'Ignoré',
        site_systems_search_by_site: 'Filtrer par site',
        site_systems_search_by_system: 'Filtrer par système de site',
        site_systems_search_by_name: 'Filtrer par nom',
        site_systems_ignore_success: 'Réussi',
        site_systems_ignore_error: 'Échoué',
        site_systems_tooltip_ignore: 'Ignorer les systèmes sélectionnés',
        site_systems_details_net_usage: 'Utilisation nette (%)',
        site_systems_details_disk_usage: 'Utilisation du disque (%)',
        site_systems_details_description: 'Détails des performances concernant ce système et ses services',
        site_systems_details_no_disk_data: 'No Data',
        site_systems_details_services_title: 'Services ({count})',
        general_datetime_days: 'jours',
        general_datetime_hours: 'heures',
        general_datetime_minutes: 'minutes',
        general_datetime_seconds: 'secondes',
        deploy_monitoring_plugin_title: 'Créer un programme d\'installation du plug-in de surveillance',
        deploy_monitoring_plugin_floating_button_tooltip: 'Créer un programme d\'installation du plug-in de surveillance',
        deploy_monitoring_plugin_description: 'Pour déployer un nouveau plug-in de surveillance, remplir ce formulaire et télécharger le package zip de déploiement du plug-in de surveillance. Le package comprend un programme d\'installation du plug-in de surveillance et tous les fichiers de configuration nécessaires. Suivez les instructions de la fenêtre de la console du programme d\'installation du plug-in de surveillance.',
        deploy_monitoring_plugin_warning_label: 'AVERTISSEMENT',
        deploy_monitoring_plugin_input_warning_label: 'Installez des plug-ins de surveillance Umbrella supplémentaires uniquement sur les systèmes sur lesquels aucun autre plug-in de surveillance Umbrella n\'est en cours d\'exécution. Chaque passerelle Umbrella a un plugin de surveillance Umbrella inclus et les systèmes exécutant une passerelle Umbrella ne nécessitent pas un autre plug-in de surveillance Umbrella.',
        deploy_monitoring_plugin_required_parameters_label: 'Paramètres requis:',
        deploy_monitoring_plugin_input_gateway_host_label: 'Hôte de passerelle:*',
        deploy_monitoring_plugin_input_gateway_port_label: 'Port de passerelle:*',
        deploy_monitoring_plugin_input_plugin_host_label: 'Hôte du plugin:*',
        deploy_monitoring_plugin_input_plugin_port_label: 'Port de plug-in:*',
        deploy_monitoring_plugin_input_as_a_service_label: 'Service Windows:',
        deploy_monitoring_plugin_input_service_name_label: 'Nom du service Windows:*',
        deploy_monitoring_plugin_input_working_dir_label: 'Répertoire de travail:*',
        deploy_monitoring_plugin_input_working_dir_tooltip: 'Le chemin du service doit être un chemin absolu et le dossier ne doit pas encore exister, mais sera créé par le programme d\'installation du plug-in de surveillance.',
        deploy_monitoring_plugin_download_installer_button: 'Télécharger le programme d\'installation',
        deploy_monitoring_plugin_instructions: 'Exécutez Umbrella.Monitoring.Plugin.Installer.exe pour déployer le plugin de surveillance',
        deploy_monitoring_plugin_input_gateway_host_placeholder: 'Hôte de passerelle',
        deploy_monitoring_plugin_input_gateway_port_placeholder: 'Port de passerelle',
        deploy_monitoring_plugin_input_plugin_host_placeholder: 'Hôte de plugin',
        deploy_monitoring_plugin_input_plugin_port_placeholder: 'Port de plug-in',
        deploy_monitoring_plugin_input_service_name_placeholder: 'Nom du service Windows',
        deploy_monitoring_plugin_input_working_dir_placeholder: 'Répertoire de travail',
        deploy_monitoring_plugin_input_validation_port: 'Numéro de port non valide (1 - 65535)',
        deploy_monitoring_plugin_input_validation_working_dir: 'Répertoire de travail invalide',
        deploy_monitoring_plugin_input_validation_service_name: 'Nom de service non valide (seuls les lettres, les chiffres et le trait de soulignement sont autorisés)',
        deploy_monitoring_plugin_input_validation_host: 'Nom d\'hôte invalide',
        deploy_monitoring_plugin_success_message: 'Package de déploiement téléchargé avec succès',
        deploy_monitoring_plugin_error_message: 'Échec de la création du package de déploiement',
        site_service_performance_service_state_label: 'État de service',
        site_service_performance_vms_state_tooltip: 'L\'état logique du plugin de Site',
        site_service_performance_service_state_tooltip: 'L\'état de service du système d\'exploitation',
        site_service_performance_vms_state_label: 'État',
        site_service_performance_service_state_stopped: 'ARRÊTÉ',
        site_service_performance_service_state_running: 'EXECUTION EN COURS',
        site_service_performance_service_state_paused: 'EN PAUSE',
        site_service_performance_service_state_offline: 'INCONNU',
        site_service_performance_host: 'Hôte',
        vms_state_ok: 'OK',
        vms_state_error: 'ERREUR',
        vms_state_offline: 'INCONNU',
        vms_state_disabled: 'DÉSACTIVÉ',
        site_service_performance_ignore_success: 'Réussi',
        site_service_performance_ignore_error: 'Échoué',
        site_service_performance_tooltip_ignore: 'Ignorer les services sélectionnés',
        site_service_performance_start_success: 'Réussi',
        site_service_performance_start_error: 'Échoué',
        site_service_performance_stop_success: 'Réussi',
        site_service_performance_stop_error: 'Échoué',
        camera_performance_recording_state_label: 'État de l\'enregistrement',
        camera_performance_vms_state_label: 'État',
        camera_performance_vms_state_tooltip: 'L\'état de service du système d\'exploitation',
        camera_performance_recording_state_tooltip: 'L\'état d\'enregistrement du plugin de Site',
        recording_state_ok: 'OK',
        recording_state_limited: 'LIMITÉ',
        recording_state_error: 'ERREUR',
        recording_state_unknown: 'INCONNU',
        recording_state_disabled: 'DÉSACTIVÉ',
        camera_performance_ignore_success: 'Réussi',
        camera_performance_ignore_error: 'Échoué',
        camera_performance_tooltip_ignore: 'Ignorer les caméras sélectionnées',
        camera_performance_recording_statistics: 'Statistiques d’enregistrement',
        norole_back_to_login: 'Retour à la page de connexion',
        norole_info_1: 'L\'utilisateur sélectionné n\'a pas de rôle(s) assigné(s)',
        norole_info_2: 'Veuillez contacter votre administrateur',
        invalid_session_title: 'Connexion perdue',
        invalid_session_text: 'Il semble que Umbrella Core soit actuellement inaccessible. Veuillez vous connecter à nouveau pour commencer une nouvelle session.',
        invalid_session_button_text: 'Revenir au Login',
        access_control_cardholders_table_head_cardholder: 'Titulaire de carte',
        access_control_cardholders_table_head_sites: 'Sites',
        access_control_cardholders_table_head_labels: 'Étiquettes',
        access_control_cardholders_table_head_site_labels: 'Étiquettes de site',
        access_control_cardholders_toolbar_selected_cardholder: '{count} sélectionné(s)',
        access_control_cardholders_toolbar_change_properties_tooltip: 'Modifier les propriétés des titulaires de carte sélectionnés',
        access_control_cardholders_details_update_success_message: 'Titulaire de carte mis à jour avec succès',
        access_control_cardholders_details_update_error_message: 'Échec de la mise à jour du titulaire de carte',
        access_control_cardholders_details_title: 'Ci-dessous, vous pouvez modifier les paramètres du titulaire de carte en fonction de vos préférences.',
        access_control_cardholders_details_cardholder: 'Titulaire de carte :',
        access_control_cardholders_details_site: 'Site :',
        access_control_cardholders_details_labels: 'Étiquette :',
        access_control_cardholders_details_update_button: 'Mettre à jour les détails',
        access_control_cardholders_details_tab_details: 'Détails',
        access_control_cardholders_details_properties_title: 'Ci-dessous, vous pouvez modifier les propriétés du titulaire de carte en fonction de vos préférences.',
        access_control_cardholders_details_properties_update_success_message: 'Tâche(s) créée(s) avec succès',
        access_control_cardholders_details_properties_update_error_message: 'Échec de création des tâches',
        access_control_doors_table_head_door: 'Porte',
        access_control_doors_table_head_sites: 'Sites',
        access_control_doors_table_head_labels: 'Étiquettes',
        access_control_doors_table_head_site_labels: 'Étiquettes de site',
        access_control_doors_toolbar_selected_door: '{count} sélectionné(s)',
        access_control_doors_toolbar_change_properties_tooltip: 'Modifier les propriétés des portes sélectionnées',
        access_control_doors_details_update_success_message: 'Porte mise à jour avec succès',
        access_control_doors_details_update_error_message: 'Échec de la mise à jour de la porte',
        access_control_doors_details_title: 'Ci-dessous, vous pouvez modifier les paramètres de la porte en fonction de vos préférences.',
        access_control_doors_details_door: 'Porte :',
        access_control_doors_details_site: 'Site :',
        access_control_doors_details_labels: 'Étiquette :',
        access_control_doors_details_update_button: 'Mettre à jour les détails',
        access_control_doors_details_tab_details: 'Détails',
        access_control_doors_details_properties_title: 'Ci-dessous, vous pouvez modifier les propriétés de la porte en fonction de vos préférences.',
        access_control_doors_details_properties_update_success_message: 'Tâche(s) créée(s) avec succès',
        access_control_doors_details_properties_update_error_message: 'Échec de création des tâches',
        property_systemmessages_name_dbloadwarning: 'La base de données de configuration et d\'événements est presque pleine',
        property_systemmessages_name_dbloademergencyshutdown: 'La base de données de configuration et des événements est pleine',
        property_systemmessages_name_dbcyclicbackupfailed: 'La sauvegarde cyclique de la base de données de configuration et des événements a échoué',
        property_systemmessages_name_reinvalidstatus: 'Le test de surveillance du service a échoué',
        property_systemmessages_name_licencechangedsucceeded: 'Modification du fichier de licence terminée',
        property_systemmessages_name_licencechangedfailed: 'Erreur lors de la modification du fichier de licence',
        property_systemmessages_name_mdbcannotmountzone: 'Impossible de monter la zone MDB',
        property_systemmessages_name_mdbnomorezonespace: 'La zone MDB est pleine',
        property_systemmessages_name_mdbcannotstartmds: 'Impossible de démarrer MDB',
        property_systemmessages_name_servertimechanged: 'Temps du système modifié',
        property_systemmessages_name_cmcannotstart: 'Impossible de démarrer DM',
        property_systemmessages_name_cmdeviceconnectivityproblem: 'Problème de connectivité de l\'appareil',
        property_systemmessages_name_videoloss: 'Perte du signal vidéo détectée au niveau du codeur (si prise en charge par le codeur)',
        property_systemmessages_name_tampering: 'Alarme d\'altération (si prise en charge par le codeur)',
        property_systemmessages_name_donglemissing: 'Dongle LPR introuvable',
        property_systemmessages_name_avexportjobfailed: 'Échec de l\'exportation AV',
        property_systemmessages_name_avexportjobold: 'L\'exportation AV remonte à plus de deux heures',
        property_systemmessages_name_avexportjobolder: 'L\'exportation AV remonte à plus de quatre heures',
        property_systemmessages_name_mdbzonealmostfull: 'Zone MDB presque pleine',
        property_systemmessages_name_systemalertsbadsignal: 'Échec de l\'analyse d\'image en raison d\'une faible luminosité',
        property_systemmessages_name_systemalertsunknownview: 'Échec de l\'analyse de l\'image en raison d\'une distorsion',
        property_systemmessages_name_systemalertsrestoredview: 'Restauration de l\'analyse d\'images en panne',
        property_systemmessages_name_coresubunreachable: 'Sous-service de base inaccessible',
        property_systemmessages_name_mdblaststandardimagetooold: 'La dernière image archivée remonte à plus longtemps que la valeur configurée',
        property_systemmessages_name_flatfileloggingcannotwritesummaryfile: 'FlatFileLogging ne peut pas écrire de fichier récapitulatif',
        property_systemmessages_name_flatfileloggingcannotwritealarmfile: 'FlatFileLogging ne peut pas écrire de fichier d’alarme',
        property_systemmessages_name_vavideolost: 'Le service VA a perdu le signal vidéo',
        property_systemmessages_name_vavideoavailable: 'Signal vidéo du service VA récupéré',
        property_systemmessages_name_vathirdpartycommunicationlost: 'La communication VA avec un système tiers a été perdue',
        property_systemmessages_name_vathirdpartycommunicationavailable: 'La communication VA avec un système tiers restaurée',
        property_systemmessages_name_restart: 'Les services ont été démarrés/redémarrés',
        property_systemmessages_name_restop: 'Les services ont été stoppés',
        property_systemmessages_name_dmthresholdsvaluesexceeded: 'Les valeurs seuils pour la statistique DM ont été dépassées',
        property_systemmessages_name_mdbdtatisticsnotavailable: 'Les statistiques MDB ne sont pas disponibles',
        property_systemmessages_name_mdbautomatedvideobackupstarted: 'La sauvegarde vidéo automatisée MDB a commencé',
        property_systemmessages_name_mdbautomatedvideobackupcompleted: 'Sauvegarde vidéo automatisée MDB terminée',
        property_systemmessages_name_mdbautomatedvideobackupinterrupted: 'Sauvegarde vidéo automatisée MDB interrompue',
        property_systemmessages_name_edgestorageimportssuccessful: 'Importation du stockage Edge réussi',
        property_systemmessages_name_edgestorageimportinterrupted: 'Interruption de l\'importation du stockage Edge',
        property_systemmessages_name_coreslavereachableagain: 'Sous-service de base accessible à nouveau',
        property_systemmessages_name_userdeactivatedafterfailedloginattempts: 'Utilisateur désactivé',
        property_systemmessages_name_cmdeviceconnectivityproblemresolved: 'Problème de connectivité de l\'appareil résolu',
        property_systemmessages_connection_user_subscribed: 'S’abonner à l’événement :',
        property_systemmessages_deadtime: 'Temps mort (secondes) : ',
        property_systemmessages_affected_service: 'Services affectés :',
        configure_threshold_sites_title: 'Configurer les seuils pour le(s) site(s) sélectionné(s)',
        configure_threshold_services_title: 'Configurer les seuils pour le(s) service(s) sélectionné(s)',
        configure_threshold_systems_title: 'Configurer les seuils pour le(s) système(s) sélectionné(s)',
        configure_threshold_sites_description: 'Vous êtes sur le point de modifier les seuils des sites sélectionnés. Vous pouvez soit définir des seuils personnalisés pour les sites sélectionnés, soit utiliser les valeurs par défaut des paramètres.',
        configure_threshold_services_description: 'Vous êtes sur le point de modifier les seuils des services sélectionnés. Vous pouvez soit définir des seuils personnalisés pour les services sélectionnés, soit utiliser les valeurs par défaut des paramètres. Ces valeurs par défaut proviendront soit des paramètres, soit des seuils définis pour le site concerné.',
        configure_threshold_systems_description: 'Vous êtes sur le point de modifier les seuils des systèmes sélectionnés. Vous pouvez soit définir des seuils personnalisés pour les systèmes sélectionnés, soit utiliser les valeurs par défaut des paramètres. Ces valeurs par défaut proviendront soit des paramètres, soit des seuils définis pour le site concerné.',
        configure_threshold_cpu: 'Seuil d\'avertissement de surveillance (%) :',
        configure_threshold_memory: 'Seuil critique de surveillance (%) :',
        configure_threshold_disk: 'Utiliser les valeurs par défaut :',
        configure_threshold_network: 'Mise à jour',
        configure_threshold_use_default_label: 'La valeur ne peut pas être supérieure à la valeur du seuil critique',
        configure_threshold_button_label: 'La valeur ne peut pas être inférieure à la valeur du seuil d’avertissement',
        threshold_type_unknown: 'Inconnu',
        threshold_type_entity: 'Entité',
        threshold_type_site: 'Site',
        threshold_type_settings: 'Réglages',
        threshold_type_prefix: 'Source :',
        labels_table_head_name: 'Nom',
        apiKeys_table_head_apikey: '',
        apiKeys_table_head_creator: '',
        apiKeys_table_head_creationdate: '',
        apiKeys_create: '',
        apikey_created_success: '',
        apikey_created_error: '',
        apikey_delete_sucess: '',
        apikey_delete_error: '',
        apiKey_delete_tooltip: '',
        edit_label_title: 'Modifier le nom d\'une étiquette',
        edit_label_description: 'Vous êtes sur le point de modifier le nom d\'une étiquette. ',
        edit_label_name_label: 'Nom :',
        edit_label_button_label: 'Mise à jour',
        edit_label_error_unique_labelname: 'Le nom de l’étiquette est déjà pris',
        multiconfig_labels_title: 'Modifier les étiquettes communes',
        multiconfig_labels_description: 'Ici, vous pouvez modifier les étiquettes des sites sélectionnés.',
        multiconfig_labels_labels_label: 'Étiquette :',
        multiconfig_labels_button_label: 'Mise à jour',
        multiconfig_labels_overwrite_label: 'Outrepasser :',
        multiconfig_labels_success_message: 'Étiquette(s) mise(s) à jour avec succès',
        multiconfig_labels_error_message: 'Échec de la mise à jour de(s) étiquette(s)',
        multiconfig_label_override_warning_title: 'Avertissement',
        multiconfig_label_override_warning_message: 'Attention ! Vous avez activé le mode prioritaire. Cela supprimera toutes les étiquettes existantes, puis appliquera les étiquettes sélectionnées ci-dessus.',
        notification_priority_title: 'Modifier la priorité des notifications',
        notification_priority_description: 'Ici, vous pouvez modifier le niveau de notification souhaité pour les entités sélectionnées',
        notification_priority_priority_label: 'Priorité des notifications :',
        notification_priority_high: 'Haute',
        notification_priority_normal: 'Normale',
        notification_priority_low: 'Basse',
        site_events_group_resolved_tooltip_group: 'Grouper les problèmes résolus',
        site_events_group_resolved_tooltip_ungroup: 'Afficher la liste plate',
        error_failed_request: 'Ometelle Au Fromage'
    }
}