import { yupResolver } from '@hookform/resolvers/yup'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormCheckbox, FormContent, FormGroup, FormHeader, FormInput, FormLabel, FormWrapper, FormFooter, InputWrapper, Instructions } from 'components/FormControls/FormControls'
import { FormCreatableSelect } from 'components/FormControls/FormCreatableSelect'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import { useForm } from 'react-hook-form'
import { TSettings } from 'types/businessLogic/misc'
import * as yup from 'yup'
import React, { useEffect } from 'react'
import { FormNumberInput } from 'components/FormControls/FormNumberInput'
import { useIntl } from 'react-intl'
import { TSelectOptions } from 'types/ui/dropdown'
import { FromThresholdSlider } from 'components/FormControls/FormThreshholdSlider'

type TProps = {
    settings: TSettingsForm
    settingsRestored: boolean
    handleSettingsSubmit: (formValues: TSettingsForm) => void
    handleRestoreDefaultSettingsSubmit: () => void
}

export type TSettingsForm = TSettings & {
    domainNamesOptions: TSelectOptions
    cpuThresholds: number[]
    memoryThresholds: number[]
    diskThresholds: number[]
    networkThresholds: number[]
}

const Settings = (props: TProps) => {
    const {settings} = props
    const {handleSettingsSubmit, handleRestoreDefaultSettingsSubmit} = props
    const intl = useIntl()
    
    const domainOptions = settings.domainNamesOptions

    const settings_general_delete_vms_events_error = intl.formatMessage(i18nKeys.settings_general_delete_vms_events_error)
    const settings_general_core_performance_measure_interval_error = intl.formatMessage(i18nKeys.settings_general_core_performance_measure_interval_error)
    const settings_general_delete_core_performance_measurements_interval_error = intl.formatMessage(i18nKeys.settings_general_delete_core_performance_measurements_interval_error)
    const settings_password_required_unique_chars_error = intl.formatMessage(i18nKeys.settings_password_required_unique_chars_error)
    const settings_general_delete_log_days_error = intl.formatMessage(i18nKeys.settings_general_delete_log_days_error)
    const settings_general_delete_jobs_after_day_interval_error = intl.formatMessage(i18nKeys.settings_general_delete_jobs_after_day_interval_error)
    const settings_expert_writeheartbeatcache_error = intl.formatMessage(i18nKeys.settings_expert_writeheartbeatcache_error)
    const settings_expert_allowedmissedheartbeats_error = intl.formatMessage(i18nKeys.settings_expert_allowedmissedheartbeats_error)
    const settings_expert_delaygatewayupdate_error = intl.formatMessage(i18nKeys.settings_expert_delaygatewayupdate_error)
    const settings_gateway_heartbeat_interval_error = intl.formatMessage(i18nKeys.settings_gateway_heartbeat_interval_error)
    const settings_gateway_vms_network_timeout_error = intl.formatMessage(i18nKeys.settings_gateway_vms_network_timeout_error)
    const settings_gateway_fetch_recordings_interval_error = intl.formatMessage(i18nKeys.settings_gateway_fetch_recordings_interval_error)
    const settings_gateway_site_performance_measure_interval_error = intl.formatMessage(i18nKeys.settings_gateway_site_performance_measure_interval_error)
    const settings_gateway_delete_site_performance_measurements_interval_error = intl.formatMessage(i18nKeys.settings_gateway_delete_site_performance_measurements_interval_error)
    const settings_gateway_delete_log_days_error = intl.formatMessage(i18nKeys.settings_gateway_delete_log_days_error)
    const settings_login_cookie_persistence_duration_error = intl.formatMessage(i18nKeys.settings_login_cookie_persistence_duration_error)
    const settings_login_validation_interval_error = intl.formatMessage(i18nKeys.settings_login_validation_interval_error)
    const settings_ad_refresh_ad_cache_error = intl.formatMessage(i18nKeys.settings_ad_refresh_ad_cache_error)
    const settings_password_required_length_error = intl.formatMessage(i18nKeys.settings_password_required_length_error)
        
    const validationSchema = yup.object().shape({
        deleteVmsEventsOlderThanInDays: yup.number()
            .typeError(settings_general_delete_vms_events_error)
            .required()
            .min(1, settings_general_delete_vms_events_error)
            .max(365, settings_general_delete_vms_events_error),
        provideHealthEndpoint: yup.boolean().required(),
        corePerformanceMeasureIntervalInMinutes: yup.number()
            .typeError(settings_general_core_performance_measure_interval_error)
            .required()
            .min(1, settings_general_core_performance_measure_interval_error)
            .max(60, settings_general_core_performance_measure_interval_error),
        deleteCorePerformanceMeasurementsIntervalInDays: yup.number()
            .typeError(settings_general_delete_core_performance_measurements_interval_error)
            .required()
            .min(1, settings_general_delete_core_performance_measurements_interval_error)
            .max(365, settings_general_delete_core_performance_measurements_interval_error),
        deleteCoreLogFilesOlderThanInDays: yup.number()
            .typeError(settings_general_delete_log_days_error)
            .required()
            .min(1, settings_general_delete_log_days_error)
            .max(365, settings_general_delete_log_days_error),
        deleteJobsOlderThanInDays: yup.number()
            .typeError(settings_general_delete_jobs_after_day_interval_error)
            .required()
            .min(1, settings_general_delete_jobs_after_day_interval_error)
            .max(365, settings_general_delete_jobs_after_day_interval_error),
        writeGatewayHeartbeatCacheIntervalInSeconds: yup.number()
            .typeError(settings_expert_writeheartbeatcache_error)
            .required()
            .min(10, settings_expert_writeheartbeatcache_error)
            .max(60, settings_expert_writeheartbeatcache_error),
        numberOfHeartBeatsAllowedToMiss: yup.number()
            .typeError(settings_expert_allowedmissedheartbeats_error)
            .required()
            .min(3, settings_expert_allowedmissedheartbeats_error)
            .max(10, settings_expert_allowedmissedheartbeats_error),
        delayGatewayGetCoreVersionInSeconds: yup.number()
            .typeError(settings_expert_delaygatewayupdate_error)
            .required()
            .min(0, settings_expert_delaygatewayupdate_error)
            .max(30, settings_expert_delaygatewayupdate_error),
        heartBeatIntervalInSeconds: yup.number()
            .typeError(settings_gateway_heartbeat_interval_error)
            .required()
            .min(5, settings_gateway_heartbeat_interval_error)
            .max(3600, settings_gateway_heartbeat_interval_error),
        useSignalRforVmsEvent: yup.boolean().required(),
        vmsNetworkTimeoutInSeconds: yup.number()
            .typeError(settings_gateway_vms_network_timeout_error)
            .required()
            .min(5, settings_gateway_vms_network_timeout_error)
            .max(120, settings_gateway_vms_network_timeout_error),
        fetchRecordingsIntervalInMinutes: yup.number()
            .typeError(settings_gateway_fetch_recordings_interval_error)
            .required()
            .min(5, settings_gateway_fetch_recordings_interval_error)
            .max(120, settings_gateway_fetch_recordings_interval_error),
        systemPerformanceMeasureIntervalInMinutes: yup.number()
            .typeError(settings_gateway_site_performance_measure_interval_error)
            .required()
            .min(1, settings_gateway_site_performance_measure_interval_error)
            .max(60, settings_gateway_site_performance_measure_interval_error),
        deleteSystemPerformanceMeasurementsIntervalInDays: yup.number()
            .typeError(settings_gateway_delete_site_performance_measurements_interval_error)
            .required()
            .min(1, settings_gateway_delete_site_performance_measurements_interval_error)
            .max(365, settings_gateway_delete_site_performance_measurements_interval_error),
        deleteGatewayLogFilesOlderThanInDays: yup.number()
            .typeError(settings_gateway_delete_log_days_error)
            .required()
            .min(1, settings_gateway_delete_log_days_error)
            .max(365, settings_gateway_delete_log_days_error),
    
        isRememberMeAvailable: yup.boolean().required(),
        cookiePersistenceDurationInHours: yup.number()
            .typeError(settings_login_cookie_persistence_duration_error)
            .required()
            .min(0, settings_login_cookie_persistence_duration_error)
            .max(2160, settings_login_cookie_persistence_duration_error),
        slidingExpirationOfSessionCookie: yup.boolean().required(),
        validationIntervalInSeconds: yup.number()
            .typeError(settings_login_validation_interval_error)
            .required()
            .min(0, settings_login_validation_interval_error)
            .max(300, settings_login_validation_interval_error),
    
        isActiveDirectoryLoginAvailable: yup.boolean().required(),
        syncActiveDirectoryEmail: yup.boolean().required(),
        
        refreshActiveDirectoryCacheInMinutes: yup.number()
            .typeError(settings_ad_refresh_ad_cache_error)
            .required()
            .min(5, settings_ad_refresh_ad_cache_error)
            .max(1440, settings_ad_refresh_ad_cache_error),
    
        requiredLength: yup.number()
            .typeError(settings_password_required_length_error)
            .required()
            .min(1, settings_password_required_length_error)
            .max(100, settings_password_required_length_error),
        requiredUniqueChars: yup.number()
            .typeError(settings_password_required_unique_chars_error)
            .required()
            .min(0, settings_password_required_unique_chars_error),
        requireDigit: yup.boolean().required(),
        requireUppercase: yup.boolean().required(),
        requireLowercase: yup.boolean().required(),
        requireNonAlphanumeric: yup.boolean().required(),
        domainNamesOptions: yup.array().when('isActiveDirectoryLoginAvailable', {
            is: true,
            then: yup.array().min(1, intl.formatMessage(i18nKeys.settings_ad_domain_names_error))
        })
    })

    const { handleSubmit, formState, reset, control, trigger } = useForm<TSettingsForm>({
        mode: 'onChange',
        defaultValues: settings,
        resolver: yupResolver(validationSchema)
    })

    const { isValid, isValidating, isSubmitting, isDirty } = formState

    useEffect(() => {
        reset(settings)
    }, [settings, reset])

    return (
        <FormWrapper onSubmit={handleSubmit(handleSettingsSubmit)}>
            <FormHeader title={i18nKeys.settings_configuration_title} description={i18nKeys.settings_configuration_description} />
            <FormContent>
                <FormGroup title={i18nKeys.settings_group_core}>
                    <InputWrapper>
                        <FormLabel htmlFor='deleteVmsEventsOlderThanInDays'>
                            <TransMessage {...i18nKeys.settings_general_delete_vms_events_days} />
                        </FormLabel>
                        <FormNumberInput
                            name='deleteVmsEventsOlderThanInDays'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='provideHealthEndpoint'>
                            <TransMessage {...i18nKeys.settings_general_provide_health_endpoint} />
                        </FormLabel>
                        <FormCheckbox
                            name='provideHealthEndpoint'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='corePerformanceMeasureIntervalInMinutes'>
                            <TransMessage {...i18nKeys.settings_general_core_performance_measure_interval} />
                        </FormLabel>
                        <FormNumberInput
                            name='corePerformanceMeasureIntervalInMinutes'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='deleteCorePerformanceMeasurementsIntervalInDays'>
                            <TransMessage {...i18nKeys.settings_general_delete_core_performance_measurements_interval} />
                        </FormLabel>
                        <FormNumberInput
                            name='deleteCorePerformanceMeasurementsIntervalInDays'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='displayUsernameInJobs'>
                            <TransMessage {...i18nKeys.settings_general_display_username_jobs} />
                        </FormLabel>
                        <FormCheckbox
                            name='displayUsernameInJobs'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='isCommentMandatoryForJobs'>
                            <TransMessage {...i18nKeys.settings_general_is_comment_mandatory} />
                        </FormLabel>
                        <FormCheckbox
                            name='isCommentMandatoryForJobs'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='deleteReportsWhenDownloading'>
                            <TransMessage {...i18nKeys.settings_general_delete_reports_when_downloading} />
                        </FormLabel>
                        <FormCheckbox
                            name='deleteReportsWhenDownloading'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='deleteJobsOlderThanInDays'>
                            <TransMessage {...i18nKeys.settings_general_delete_jobs_after_day_interval} />
                        </FormLabel>
                        <FormNumberInput
                            name='deleteJobsOlderThanInDays'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='deleteCoreLogFilesOlderThanInDays'>
                            <TransMessage {...i18nKeys.settings_general_delete_log_days} />
                        </FormLabel>
                        <FormNumberInput
                            name='deleteCoreLogFilesOlderThanInDays'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='notificationDelayNormalInSeconds'>
                            <TransMessage {...i18nKeys.settings_notifications_priority_normal_delay} />
                        </FormLabel>
                        <FormInput
                            name='notificationDelayNormalInSeconds'
                            control={control} />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='notificationDelayLowInSeconds'>
                            <TransMessage {...i18nKeys.settings_notifications_priority_low_delay} />
                        </FormLabel>
                        <FormInput
                            name='notificationDelayLowInSeconds'
                            control={control} />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='cpuThresholds'>
                            <TransMessage {...i18nKeys.settings_general_thresholds_cpu
                            } />
                        </FormLabel>
                        <FromThresholdSlider
                            name='cpuThresholds'
                            control={control}
                            min={1}
                            max={100}
                            minDistance={5}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='memoryThresholds'>
                            <TransMessage {...i18nKeys.settings_general_thresholds_memory} />
                        </FormLabel>
                        <FromThresholdSlider
                            name='memoryThresholds'
                            control={control}
                            min={1}
                            max={100}
                            minDistance={5}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='diskThresholds'>
                            <TransMessage {...i18nKeys.settings_general_thresholds_disk} />
                        </FormLabel>
                        <FromThresholdSlider
                            name='diskThresholds'
                            control={control}
                            min={1}
                            max={100}
                            minDistance={5}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='networkThresholds'>
                            <TransMessage {...i18nKeys.settings_general_thresholds_network} />
                        </FormLabel>
                        <FromThresholdSlider
                            name='networkThresholds'
                            control={control}
                            min={1}
                            max={100}
                            minDistance={5}
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup collapsable title={i18nKeys.settings_group_core_expert}>
                    <InputWrapper>
                        <FormLabel htmlFor='writeGatewayHeartbeatCacheIntervalInSeconds'>
                            <TransMessage {...i18nKeys.settings_expert_writeheartbeatcache} />
                        </FormLabel>
                        <FormNumberInput
                            name='writeGatewayHeartbeatCacheIntervalInSeconds'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='numberOfHeartBeatsAllowedToMiss'>
                            <TransMessage {...i18nKeys.settings_expert_allowedmissedheartbeats} />
                        </FormLabel>
                        <FormNumberInput
                            name='numberOfHeartBeatsAllowedToMiss'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='delayGatewayGetCoreVersionInSeconds'>
                            <TransMessage {...i18nKeys.settings_expert_delaygatewayupdate} />
                        </FormLabel>
                        <FormNumberInput
                            name='delayGatewayGetCoreVersionInSeconds'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup title={i18nKeys.settings_group_gateway}>
                    <InputWrapper>
                        <FormLabel htmlFor='heartBeatIntervalInSeconds'>
                            <TransMessage {...i18nKeys.settings_gateway_heartbeat_interval_seconds} />
                        </FormLabel>
                        <FormNumberInput
                            name='heartBeatIntervalInSeconds'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='useSignalRforVmsEvent'>
                            <TransMessage {...i18nKeys.settings_gateway_use_signalr_for_vms_event} />
                        </FormLabel>
                        <FormCheckbox
                            name='useSignalRforVmsEvent'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='vmsNetworkTimeoutInSeconds'>
                            <TransMessage {...i18nKeys.settings_gateway_vms_network_timeout_seconds} />
                        </FormLabel>
                        <FormNumberInput
                            name='vmsNetworkTimeoutInSeconds'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='fetchRecordingsIntervalInMinutes'>
                            <TransMessage {...i18nKeys.settings_gateway_fetch_recordings_interval_minutes} />
                        </FormLabel>
                        <FormNumberInput
                            name='fetchRecordingsIntervalInMinutes'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='systemPerformanceMeasureIntervalInMinutes'>
                            <TransMessage {...i18nKeys.settings_gateway_system_performance_measure_interval} />
                        </FormLabel>
                        <FormNumberInput
                            name='systemPerformanceMeasureIntervalInMinutes'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='deleteSystemPerformanceMeasurementsIntervalInDays'>
                            <TransMessage {...i18nKeys.settings_gateway_delete_system_performance_measurements_interval} />
                        </FormLabel>
                        <FormNumberInput
                            name='deleteSystemPerformanceMeasurementsIntervalInDays'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='deleteGatewayLogFilesOlderThanInDays'>
                            <TransMessage {...i18nKeys.settings_gateway_delete_log_days} />
                        </FormLabel>
                        <FormNumberInput
                            name='deleteGatewayLogFilesOlderThanInDays'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup title={i18nKeys.settings_group_login}>
                    <InputWrapper>
                        <FormLabel htmlFor='isRememberMeAvailable'>
                            <TransMessage {...i18nKeys.settings_login_is_remember_me_available} />
                        </FormLabel>
                        <FormCheckbox
                            name='isRememberMeAvailable'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='cookiePersistenceDurationInHours'>
                            <TransMessage {...i18nKeys.settings_login_cookie_persistence_duration} />
                        </FormLabel>
                        <FormNumberInput
                            name='cookiePersistenceDurationInHours'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='slidingExpirationOfSessionCookie'>
                            <TransMessage {...i18nKeys.settings_login_sliding_expiration_session_cookie} />
                        </FormLabel>
                        <FormCheckbox
                            name='slidingExpirationOfSessionCookie'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='validationIntervalInSeconds'>
                            <TransMessage {...i18nKeys.settings_login_validation_interval_seconds} />
                        </FormLabel>
                        <FormNumberInput
                            name='validationIntervalInSeconds'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup title={i18nKeys.settings_group_active_directory}>
                    <InputWrapper>
                        <FormLabel htmlFor='isActiveDirectoryLoginAvailable'>
                            <TransMessage {...i18nKeys.settings_ad_is_ad_login_available} />
                        </FormLabel>
                        <FormCheckbox
                            name='isActiveDirectoryLoginAvailable'
                            control={control}
                            onChange={() => {
                                trigger('domainNamesOptions')
                            }}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='domainNamesOptions'>
                            <TransMessage {...i18nKeys.settings_ad_domain_names} />
                        </FormLabel>
                        <FormCreatableSelect
                            name='domainNamesOptions'
                            control={control}
                            isMulti
                            options={domainOptions}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='syncActiveDirectoryEmail'>
                            <TransMessage {...i18nKeys.settings_ad_sync_active_directory_email} />
                        </FormLabel>
                        <FormCheckbox
                            name='syncActiveDirectoryEmail'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='refreshActiveDirectoryCacheInMinutes'>
                            <TransMessage {...i18nKeys.settings_ad_refresh_ad_cache_minutes} />
                        </FormLabel>
                        <FormNumberInput
                            name='refreshActiveDirectoryCacheInMinutes'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup title={i18nKeys.settings_group_password}>
                    <InputWrapper>
                        <FormLabel htmlFor='requiredLength'>
                            <TransMessage {...i18nKeys.settings_password_required_length} />
                        </FormLabel>
                        <FormNumberInput
                            name='requiredLength'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='requiredUniqueChars'>
                            <TransMessage {...i18nKeys.settings_password_required_unique_chars} />
                        </FormLabel>
                        <FormNumberInput
                            name='requiredUniqueChars'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='requireDigit'>
                            <TransMessage {...i18nKeys.settings_password_require_digit} />
                        </FormLabel>
                        <FormCheckbox
                            name='requireDigit'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='requireUppercase'>
                            <TransMessage {...i18nKeys.settings_password_require_uppercase} />
                        </FormLabel>
                        <FormCheckbox
                            name='requireUppercase'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='requireLowercase'>
                            <TransMessage {...i18nKeys.settings_password_require_lowercase} />
                        </FormLabel>
                        <FormCheckbox
                            name='requireLowercase'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='requireNonAlphanumeric'>
                            <TransMessage {...i18nKeys.settings_password_require_non_alphanumeric} />
                        </FormLabel>
                        <FormCheckbox
                            name='requireNonAlphanumeric'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup title={i18nKeys.settings_group_license}>
                    <InputWrapper>
                        <FormLabel htmlFor='licenseKey'>
                            <TransMessage {...i18nKeys.settings_license_license_key} />
                        </FormLabel>
                        <FormInput
                            disabled
                            name='licenseKey'
                            control={control} />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!props.settingsRestored && (!isValid || isValidating || isSubmitting || !isDirty)}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.settings_configuration_submit} />
                    </CustomButton>
                    <CustomButton
                        id='resetBtn'
                        type='reset'
                        onClick={() => reset(settings)}
                        $secondary
                    >
                        <TransMessage {...i18nKeys.settings_configuration_reset_fields} />
                    </CustomButton>
                    <CustomButton
                        id='restoreBtm'
                        type='button'
                        onClick={handleRestoreDefaultSettingsSubmit}
                        $secondary
                    >
                        <TransMessage {...i18nKeys.settings_configuration_restore_default} />
                    </CustomButton>
                </FormButtonGroup>
                <Instructions danger>
                    <TransMessage {...i18nKeys.settings_configuration_resart_information} />
                </Instructions>
            </FormFooter>
        </FormWrapper>
    )
}

export default Settings