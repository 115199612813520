import React from 'react'
import { ETableFilters, EVMSEntityType, EVMSEventCategory } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'
import TransMessage from 'components/TransMessage/TransMessage'
import AppConstants from 'helpers/AppConstants'

const columnConfig: TTableColumnsConfig = {
    // This property is actually dynamic, but backend guys guarantee it will always appear, so key is pascal case
    sourceName: {
        key: 'SourceName',
        translationKey: i18nKeys.property_events_sourcename.id,
        groupKey: 'SourceName',
        displayed: true,
        filter: {
            key: 'SourceName',
            type: ETableFilters.INPUT_TEXT
        }
    },
    siteName: {
        key: 'SiteName',
        translationKey: i18nKeys.site_events_table_head_site_name.id,
        sortKey: 'SiteName',
        groupKey: 'SiteName',
        displayed: true,
        filter: {
            key: 'SiteName',
            type: ETableFilters.INPUT_TEXT
        }
    },
    timeStamp: {
        key: 'Timestamp',
        sortKey: 'Timestamp',
        translationKey: i18nKeys.site_events_table_head_timestamp.id,
        displayed: true
    },
    // issueResolved: {
    //     key: 'IssueResolved',
    //     translationKey: i18nKeys.site_events_table_head_issue_resolved.id,
    //     displayed: true,
    //     groupKey: 'IssueResolved',
    //     sortKey: 'IssueResolved',
    //     filter: {
    //         key: 'IssueResolved',
    //         type: ETableFilters.DROPDOWN,
    //         dropdownItems: [
    //             {text: <TransMessage {...i18nKeys.dropdown_selection_none} />, value: ''},
    //             {text: <TransMessage {...i18nKeys.dropdown_selection_true} />, value: true},
    //             {text: <TransMessage {...i18nKeys.dropdown_selection_false} />, value: false}
    //         ]
    //     }
    // },
    eventCategory: {
        key: 'EventCategory',
        translationKey: i18nKeys.site_events_table_head_event_category.id,
        displayed: true,
        sortKey: 'EventCategory',
        groupKey: 'EventCategory',
        filter: {
            key: 'EventCategory',
            type: ETableFilters.DROPDOWN,
            dropdownItems: [
                {
                    text: <TransMessage {...i18nKeys.dropdown_selection_none} />,
                    value: ''
                },
                {
                    text: <TransMessage {...i18nKeys.site_event_category_info} />,
                    value: EVMSEventCategory.INFO
                },
                {
                    text: <TransMessage {...i18nKeys.site_event_category_warning} />,
                    value: EVMSEventCategory.WARNING
                },
                {
                    text: <TransMessage {...i18nKeys.site_event_category_error} />,
                    value: EVMSEventCategory.ERROR
                },
                {
                    text: <TransMessage {...i18nKeys.site_event_category_unknown} />,
                    value: EVMSEventCategory.UNKNOWN
                }
            ]
        }
    },
    description: {
        key: 'Description',
        translationKey: i18nKeys.site_events_table_head_description.id,
        displayed: true,
        sortKey: 'Description',
        groupKey: 'Description',
        filter: {
            key: 'Description',
            type: ETableFilters.INPUT_TEXT
        }
    },
    entityType: {
        key: 'EntityType',
        translationKey: i18nKeys.site_events_table_head_entity_type.id,
        displayed: false,
        groupKey: 'EntityType',
        sortKey: 'EntityType',
        filter: {
            key: 'EntityType',
            type: ETableFilters.DROPDOWN,
            dropdownItems: [
                {
                    text: <TransMessage {...i18nKeys.dropdown_selection_none} />,
                    value: ''
                },
                {
                    text: <TransMessage {...i18nKeys.site_event_entity_type_camera} />,
                    value: EVMSEntityType.CAMERA
                },
                {
                    text: <TransMessage {...i18nKeys.site_event_entity_type_runtime} />,
                    value: EVMSEntityType.VMS_RUNTIME
                },
                {
                    text: <TransMessage {...i18nKeys.site_event_entity_type_user} />,
                    value: EVMSEntityType.VMS_USER
                },
                {
                    text: <TransMessage {...i18nKeys.site_event_entity_type_group} />,
                    value: EVMSEntityType.VMS_GROUP
                },
                {
                    text: <TransMessage {...i18nKeys.site_event_entity_type_site} />,
                    value: EVMSEntityType.SITE
                },
                {
                    text: <TransMessage {...i18nKeys.site_event_entity_type_system} />,
                    value: EVMSEntityType.SYSTEM
                }
            ]
        }
    },
    name: {
        key: 'Name',
        translationKey: i18nKeys.site_events_table_head_event_name.id,
        sortKey: 'Name',
        displayed: false,
        groupKey: 'Name',
        filter: {
            key: 'Name',
            type: ETableFilters.INPUT_TEXT
        }
    },
    labels: {
        key: 'Labels',
        translationKey: i18nKeys.site_events_table_head_labels.id,
        displayed: false,
        filter: {
            key: 'Labels',
            type: ETableFilters.INPUT_TEXT
        }
    },
    siteLabels: {
        key: 'SiteLabels',
        translationKey: i18nKeys.site_events_table_head_site_labels.id,
        displayed: false,
        filter: {
            key: 'SiteLabels',
            type: ETableFilters.INPUT_TEXT
        }
    }
}

const defaultLayout = [
    columnConfig.sourceName.key,
    columnConfig.siteName.key,
    columnConfig.timeStamp.key,
    columnConfig.eventCategory.key,
    columnConfig.description.key,
]

export const tableInfo : TTableInfo = {
    id: AppConstants.tableIds.SITE_EVENTS,
    columnConfig,
    defaultLayout
}