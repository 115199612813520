import { Checkbox } from '@mui/material'
import Icon, { Icons } from 'components/Icon/Icon'
import React from 'react'
import colors from 'style/colors'
import styled from 'styled-components'

export const CustomCheckbox = styled(Checkbox).attrs({
    icon: <Icon d={Icons.CHECKBOX_UNCHECKED} />
})`

    &.MuiCheckbox-root {
        fill: ${p => p.disabled ? '#E8E8E8' : colors.shades.DEEP_SHADE};
        

        .MuiSvgIcon-root   {
            fill: ${p => p.disabled ? '#E8E8E8' : colors.primary.DEFAULT_PRIMARY};
        }
    }
`