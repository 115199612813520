import { TTranslationKeyContainer } from '../types/ui/translation'

export const i18nKeys: TTranslationKeyContainer = {
    general_no: {
        id: 'general_no',
        defaultMessage: 'No'
    },
    login_input_username_placeholder: {
        id: 'login_input_username_placeholder',
        defaultMessage: 'Username'
    },
    login_input_password_placeholder: {
        id: 'login_input_password_placeholder',
        defaultMessage: 'Password'
    },
    login_remember_me: {
        id: 'login_remember_me',
        defaultMessage: 'Keep me signed in'
    },
    login_button_submit: {
        id: 'login_button_submit',
        defaultMessage: 'Login'
    },
    login_button_windows_login: {
        id: 'login_button_windows_login',
        defaultMessage: 'Login Using Windows Authentication'
    },
    login_request_error: {
        id: 'login_request_error',
        defaultMessage: 'Failed to login'
    },
    login_user_rights_were_changed: {
        id: 'login_user_rights_were_changed',
        defaultMessage: 'You were logged out because your rights changed'
    },
    login_no_connection_title: {
        id: 'login_no_connection_title',
        defaultMessage: 'No Connection'
    },
    login_no_connection_description: {
        id: 'login_no_connection_description',
        defaultMessage: 'It appears that there no connection could be established. Please try again '
    },
    login_no_connection_button: {
        id: 'login_no_connection_button',
        defaultMessage: 'Retry'
    },
    language_picker_selection_english: {
        id: 'language_picker_selection_english',
        defaultMessage: 'English'
    },
    language_picker_selection_german: {
        id: 'language_picker_selection_german',
        defaultMessage: 'Deutsch'
    },
    language_picker_selection_french: {
        id: 'language_picker_selection_french',
        defaultMessage: 'Français'
    },
    sidenav_dashboard: {
        id: 'sidenav_dashboard',
        defaultMessage: 'Dashboard'
    },
    sidenav_map: {
        id: 'sidenav_map',
        defaultMessage: 'Map'
    },
    sidenav_control_centre: {
        id: 'sidenav_control_centre',
        defaultMessage: 'Site Administration'
    },
    sidenav_sites: {
        id: 'sidenav_sites',
        defaultMessage: 'Sites'
    },
    sidenav_site_users: {
        id: 'sidenav_site_users',
        defaultMessage: 'Users'
    },
    sidenav_site_groups: {
        id: 'sidenav_site_groups',
        defaultMessage: 'Groups'
    },
    sidenav_cameras: {
        id: 'sidenav_cameras',
        defaultMessage: 'Cameras'
    },
    sidenav_site_services: {
        id: 'sidenav_site_services',
        defaultMessage: 'Services'
    },
    sidenav_site_jobs: {
        id: 'sidenav_site_jobs',
        defaultMessage: 'Jobs'
    },
    sidenav_monitoring_centre: {
        id: 'sidenav_monitoring_centre',
        defaultMessage: 'Monitoring'
    },
    sidenav_umbrella_core: {
        id: 'sidenav_umbrella_core',
        defaultMessage: 'Umbrella Core'
    },
    sidenav_administration: {
        id: 'sidenav_administration',
        defaultMessage: 'Administration'
    },
    sidenav_administration_users: {
        id: 'sidenav_administration_users',
        defaultMessage: 'Umbrella Users'
    },
    sidenav_administration_roles: {
        id: 'sidenav_administration_roles',
        defaultMessage: 'Umbrella Roles'
    },
    sidenav_manual: {
        id: 'sidenav_manual',
        defaultMessage: 'Manual'
    },
    sidenav_administration_license: {
        id: 'sidenav_administration_license',
        defaultMessage: 'License'
    },
    sidenav_site_license_update: {
        id: 'sidenav_site_license_update',
        defaultMessage: 'Licenses'
    },
    camera_firmware_core: {
        id: 'camera_firmware_core',
        defaultMessage: 'Notifications'
    },
    sidenav_site_events: {
        id: 'sidenav_site_events',
        defaultMessage: 'Events'
    },
    sidenav_email: {
        id: 'sidenav_email',
        defaultMessage: 'E-Mail'
    },
    sidenav_settings: {
        id: 'sidenav_settings',
        defaultMessage: 'Settings'
    },
    sidenav_camera_firmwares: {
        id: 'sidenav_camera_firmwares',
        defaultMessage: 'Camera Firmwares'
    },
    sidenav_site_time_profile: {
        id: 'sidenav_site_time_profile',
        defaultMessage: 'Time Profiles'
    },
    sidenav_report_center: {
        id: 'sidenav_report_center',
        defaultMessage: 'Reports'
    },
    sidenav_reports: {
        id: 'sidenav_reports',
        defaultMessage: 'Inventory Reports'
    },
    sidenav_audit_reports: {
        id: 'sidenav_audit_reports',
        defaultMessage: 'Audit Reports'
    },
    sidenav_site_systems: {
        id: 'sidenav_site_systems',
        defaultMessage: 'Systems'
    },
    sidenav_access_control_card_holders: {
        id: 'sidenav_access_control_card_holders',
        defaultMessage: 'Cardholders'
    },
    sidenav_access_control_doors: {
        id: 'sidenav_access_control_doors',
        defaultMessage: 'Doors'
    },
    sidenav_plugins: {
        id: 'sidenav_plugins',
        defaultMessage: 'Plugins'
    },
    sidenav_administration_labels: {
        id: 'sidenav_administration_labels',
        defaultMessage: 'Labels'
    },
    sidenav_administration_apiKeys: {
        id: 'sidenav_administration_apiKeys',
        defaultMessage: 'API Keys'
    },
    profile_drawer_profile_information: {
        id: 'profile_drawer_profile_information',
        defaultMessage: 'Profile information:'
    },
    profile_drawer_role: {
        id: 'profile_drawer_role',
        defaultMessage: 'Roles:'
    },
    profile_drawer_last_login: {
        id: 'profile_drawer_last_login',
        defaultMessage: 'Last login:'
    },
    profile_drawer_logout_button: {
        id: 'profile_drawer_logout_button',
        defaultMessage: 'Log Out'
    },
    profile_drawer_change_password_button: {
        id: 'profile_drawer_change_password_button',
        defaultMessage: 'Change Password'
    },
    profile_drawer_upload_profile_image_error: {
        id: 'profile_drawer_upload_profile_image_error',
        defaultMessage: 'Failed to upload a profile picture'
    },
    profile_drawer_upload_profile_image_success: {
        id: 'profile_drawer_upload_profile_image_success',
        defaultMessage: 'Profile picture successfully uploaded'
    },
    profile_drawer_upload_profile_image_invalid_format: {
        id: 'profile_drawer_upload_profile_image_invalid_format',
        defaultMessage: 'Please provide valid image (jpg or png)'
    },
    profile_drawer_upload_profile_image_size_limit: {
        id: 'profile_drawer_upload_profile_image_size_limit',
        defaultMessage: 'Maximum image file size is 5MB'
    },
    site_states_heading: {
        id: 'site_states_heading',
        defaultMessage: 'Sites Health'
    },
    site_states_total: {
        id: 'site_states_total',
        defaultMessage: 'Total'
    },
    site_states_healthy: {
        id: 'site_states_healthy',
        defaultMessage: 'Healthy'
    },
    site_states_warning: {
        id: 'site_states_warning',
        defaultMessage: 'Warning'
    },
    site_states_error: {
        id: 'site_states_error',
        defaultMessage: 'Critical'
    },
    site_states_offline: {
        id: 'site_states_offline',
        defaultMessage: 'Offline'
    },
    site_states_ignored: {
        id: 'site_states_ignored',
        defaultMessage: 'Ignored'
    },
    site_states_updating: {
        id: 'site_states_updating',
        defaultMessage: 'Updating'
    },
    site_states_vms_not_reachable: {
        id: 'site_states_vms_not_reachable',
        defaultMessage: 'Connection issues'
    },
    site_states_error_tooltip: {
        id: 'site_states_error_tooltip',
        defaultMessage: '{countNotReachable} Not Reachable {br} {countOffline} Offline'
    },
    site_states_healthy_tooltip: {
        id: 'site_states_healthy_tooltip',
        defaultMessage: '{countHealthy} Healthy {br} {countUpdating} Updating'
    },
    site_state_chart_tooltip: {
        id: 'site_state_chart_tooltip',
        defaultMessage: 'This diagram shows the ratio of the total number of sites to the sites with Healthy, Warning, Error status. '
    },
    site_approvals_heading: {
        id: 'site_approvals_heading',
        defaultMessage: 'Site Approvals'
    },
    site_approvals_total: {
        id: 'site_approvals_total',
        defaultMessage: 'Total'
    },
    site_approvals_approved: {
        id: 'site_approvals_approved',
        defaultMessage: 'Approved'
    },
    site_approvals_waiting_for_approval: {
        id: 'site_approvals_waiting_for_approval',
        defaultMessage: 'Waiting for approval'
    },
    site_approvals_chart_tooltip: {
        id: 'site_approvals_chart_tooltip',
        defaultMessage: 'This diagram shows the ratio of the total number of sites to the sites that are not approved.'
    },
    cameras_chart_total: {
        id: 'cameras_chart_total',
        defaultMessage: 'Total'
    },
    cameras_chart_heading: {
        id: 'cameras_chart_heading',
        defaultMessage: 'Cameras Health'
    },
    cameras_chart_warning: {
        id: 'cameras_chart_warning',
        defaultMessage: 'Warning'
    },
    cameras_chart_healthy: {
        id: 'cameras_chart_healthy',
        defaultMessage: 'Healthy'
    },
    cameras_chart_critical: {
        id: 'cameras_chart_critical',
        defaultMessage: 'Critical'
    },
    cameras_chart_offline: {
        id: 'cameras_chart_offline',
        defaultMessage: 'Offline'
    },
    cameras_chart_ignore: {
        id: 'cameras_chart_ignore',
        defaultMessage: 'Ignored'
    },
    cameras_chart_tooltip: {
        id: 'cameras_chart_tooltip',
        defaultMessage: 'This diagram shows the ratio of the total number of cameras to the number of cameras that are in a warning state.'
    },
    umbrella_dashboard_site_systems: {
        id: 'umbrella_dashboard_site_systems',
        defaultMessage: 'Systems Health'
    },
    umbrella_dashboard_site_systems_healthy: {
        id: 'umbrella_dashboard_site_systems_healthy',
        defaultMessage: 'Healthy'
    },
    umbrella_dashboard_site_systems_warning: {
        id: 'umbrella_dashboard_site_systems_warning',
        defaultMessage: 'Warning'
    },
    umbrella_dashboard_site_systems_critical: {
        id: 'umbrella_dashboard_site_systems_critical',
        defaultMessage: 'Critical'
    },
    umbrella_dashboard_site_systems_offline: {
        id: 'umbrella_dashboard_site_systems_offline',
        defaultMessage: 'Offline'
    },
    umbrella_dashboard_site_systems_ignore: {
        id: 'umbrella_dashboard_site_systems_ignore',
        defaultMessage: 'Ignored'
    },
    sites_with_location_heading: {
        id: 'sites_with_location_heading',
        defaultMessage: 'Locations'
    },
    sites_with_location_view_map: {
        id: 'sites_with_location_view_map',
        defaultMessage: 'View Map'
    },
    sites_with_location_healthy: {
        id: 'sites_with_location_healthy',
        defaultMessage: 'Healthy'
    },
    sites_with_location_error: {
        id: 'sites_with_location_error',
        defaultMessage: 'Critical'
    },
    sites_with_location_warning: {
        id: 'sites_with_location_warning',
        defaultMessage: 'Warning'
    },
    sites_with_location_offline: {
        id: 'sites_with_location_offline',
        defaultMessage: 'Offline'
    },
    sites_with_location_ignore: {
        id: 'sites_with_location_ignore',
        defaultMessage: 'Ignored'
    },
    recent_site_jobs_heading: {
        id: 'recent_site_jobs_heading',
        defaultMessage: 'Recent Jobs'
    },
    recent_site_jobs_view_jobs: {
        id: 'recent_site_jobs_view_jobs',
        defaultMessage: 'View Jobs'
    },
    recent_site_jobs_no_jobs: {
        id: 'recent_site_jobs_no_jobs',
        defaultMessage: 'No jobs'
    },
    recent_site_jobs_description_column_name: {
        id: 'recent_site_jobs_description_column_name',
        defaultMessage: 'JOB DESCRIPTION'
    },
    recent_site_jobs_state_column_name: {
        id: 'recent_site_jobs_state_column_name',
        defaultMessage: 'STATE'
    },
    recent_site_jobs_created_at_column_name: {
        id: 'recent_site_jobs_created_at_column_name',
        defaultMessage: 'CREATED'
    },
    site_list_approved: {
        id: 'site_list_approved',
        defaultMessage: 'Approved'
    },
    site_list_unapproved: {
        id: 'site_list_unapproved',
        defaultMessage: 'Unapproved'
    },
    site_list_approve_button: {
        id: 'site_list_approve_button',
        defaultMessage: 'Approve'
    },
    site_list_table_head_name: {
        id: 'site_list_table_head_name',
        defaultMessage: 'Name'
    },
    site_list_table_head_location: {
        id: 'site_list_table_head_location',
        defaultMessage: 'Location'
    },
    site_list_table_head_state: {
        id: 'site_list_table_head_state',
        defaultMessage: 'State'
    },
    site_list_table_head_server_version: {
        id: 'site_list_table_head_server_version',
        defaultMessage: 'Server version'
    },
    site_list_table_head_server_id: {
        id: 'site_list_table_head_server_id',
        defaultMessage: 'Server ID'
    },
    site_list_table_head_labels: {
        id: 'site_list_table_head_labels',
        defaultMessage: 'Labels'
    },
    site_list_table_head_approved: {
        id: 'site_list_table_head_approved',
        defaultMessage: 'Approved'
    },
    site_list_table_head_type: {
        id: 'site_list_table_head_type',
        defaultMessage: 'Type'
    },
    site_list_table_head_version: {
        id: 'site_list_table_head_version',
        defaultMessage: 'Version'
    },
    site_list_table_head_client_version: {
        id: 'site_list_table_head_client_version',
        defaultMessage: 'Client version'
    },
    site_list_table_head_last_heartbeat: {
        id: 'site_list_table_head_last_heartbeat',
        defaultMessage: 'Last heartbeat'
    },
    site_list_table_head_ip: {
        id: 'site_list_table_head_ip',
        defaultMessage: 'IP'
    },
    site_list_table_head_host: {
        id: 'site_list_table_head_host',
        defaultMessage: 'Host'
    },
    site_list_table_head_machine_code: {
        id: 'site_list_table_head_machine_code',
        defaultMessage: 'Machine Code'
    },
    site_list_sort_tooltip: {
        id: 'site_list_sort_tooltip',
        defaultMessage: 'Sort'
    },
    site_list_group_tooltip: {
        id: 'site_list_group_tooltip',
        defaultMessage: 'Group'
    },
    site_list_approve_success: {
        id: 'site_list_approve_success',
        defaultMessage: 'Sites successfully approved'
    },
    site_list_approve_error: {
        id: 'site_list_approve_error',
        defaultMessage: 'Failed to approve Sites'
    },
    site_list_toolbar_selected_sites: {
        id: 'site_list_toolbar_selected_sites',
        defaultMessage: '{count} selected'
    },
    site_list_toolbar_delete_sites_tooltip: {
        id: 'site_list_toolbar_delete_sites_tooltip',
        defaultMessage: 'Delete sites'
    },
    site_list_toolbar_delete_sites_success: {
        id: 'site_list_toolbar_delete_sites_success',
        defaultMessage: 'Selected sites succesfully deleted'
    },
    site_list_toolbar_delete_sites_error: {
        id: 'site_list_toolbar_delete_sites_error',
        defaultMessage: 'Failed to delete sites'
    },
    site_list_toolbar_create_user_tooltip: {
        id: 'site_list_toolbar_create_user_tooltip',
        defaultMessage: 'Add User'
    },
    site_list_toolbar_create_card_holder_tooltip: {
        id: 'site_list_toolbar_create_card_holder_tooltip',
        defaultMessage: 'Add Card Holder'
    },
    site_list_toolbar_create_site_group_tooltip: {
        id: 'site_list_toolbar_create_site_group_tooltip',
        defaultMessage: 'Add Group'
    },
    site_list_group_sitestate_healthy: {
        id: 'site_list_group_sitestate_healthy',
        defaultMessage: 'Healthy'
    },
    site_list_group_sitestate_offline: {
        id: 'site_list_group_sitestate_offline',
        defaultMessage: 'Offline'
    },
    site_list_group_sitestate_warning: {
        id: 'site_list_group_sitestate_warning',
        defaultMessage: 'Warning'
    },
    site_list_group_sitestate_updating: {
        id: 'site_list_group_sitestate_updating',
        defaultMessage: 'Updating'
    },
    site_list_group_sitestate_noconnection: {
        id: 'site_list_group_sitestate_noconnection',
        defaultMessage: 'No Connection'
    },
    site_list_group_sitestate_ignored: {
        id: 'site_list_group_sitestate_ignored',
        defaultMessage: 'Ignored'
    },
    site_list_connection_user_password: {
        id: 'site_list_connection_user_password',
        defaultMessage: 'Change password for connection user'
    },
    site_list_table_head_approve_multiple_sites: {
        id: 'site_list_table_head_approve_multiple_sites',
        defaultMessage: 'Approve Sites'
    },
    site_list_table_head_create_report: {
        id: 'site_list_table_head_create_report',
        defaultMessage: 'Create Inventory Report'
    },
    site_list_report_success: {
        id: 'site_list_report_success',
        defaultMessage: 'Successfully created report task'
    },
    site_list_report_error: {
        id: 'site_list_report_error',
        defaultMessage: 'Failed to generate report task'
    },
    site_list_table_head_create_audit_report: {
        id: 'site_list_table_head_create_audit_report',
        defaultMessage: 'Create Audit Report'
    },
    site_list_set_custom_comments_multiple_sites: {
        id: 'site_list_set_custom_comments_multiple_sites',
        defaultMessage: 'Set custom comment(s) for site(s)'
    },
    site_list_table_head_ignore_site: {
        id: 'site_list_table_head_ignore_site',
        defaultMessage: 'Ignore Site'
    },
    site_list_restart_site: {
        id: 'site_list_restart_site',
        defaultMessage: 'Restart Site Connection'
    },
    site_list_restart_error_message: {
        id: 'site_list_restart_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    site_list_restart_update_success_message: {
        id: 'site_list_restart_update_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    site_list_ignore_success_message: {
        id: 'site_list_ignore_success_message',
        defaultMessage: 'Successful'
    },
    site_list_ignore_error_message: {
        id: 'site_list_ignore_error_message',
        defaultMessage: 'Failed'
    },
    plugin_list_toolbar_delete_plugins_tooltip: {
        id: 'plugin_list_toolbar_delete_plugins_tooltip',
        defaultMessage: 'Delete plugins'
    },
    plugin_list_toolbar_delete_plugins_success: {
        id: 'plugin_list_toolbar_delete_plugins_success',
        defaultMessage: 'Selected plugins succesfully deleted'
    },
    plugin_list_toolbar_delete_plugins_error: {
        id: 'plugin_list_toolbar_delete_plugins_error',
        defaultMessage: 'Failed to delete plugins'
    },
    plugin_list_table_head_sitename: {
        id: 'plugin_list_table_head_sitename',
        defaultMessage: 'Site Name'
    },
    plugin_list_table_head_port: {
        id: 'plugin_list_table_head_port',
        defaultMessage: 'Port'
    },
    plugin_list_table_head_hostname: {
        id: 'plugin_list_table_head_hostname',
        defaultMessage: 'Host Name'
    },
    plugin_list_table_head_ignoredMonitoring: {
        id: 'plugin_list_table_head_ignoredMonitoring',
        defaultMessage: 'Ignored'
    },
    site_list_toolbar_threshold_configuration: {
        id: 'site_list_toolbar_threshold_configuration',
        defaultMessage: 'Configure Monitoring Thresholds'
    },
    site_list_toolbar_system_messages_configuration: {
        id: 'site_list_toolbar_system_messages_configuration',
        defaultMessage: 'System Messages Configuration'
    },
    site_list_toolbar_edit_labels: {
        id: 'site_list_toolbar_edit_labels',
        defaultMessage: 'Edit Labels'
    },
    site_list_toolbar_notification_priority_tooltip: {
        id: 'site_list_toolbar_notification_priority_tooltip',
        defaultMessage: 'Edit Notification Prioriy'
    },
    worst_performance_sites_title: {
        id: 'worst_performance_sites_title',
        defaultMessage: 'Most critical Sites'
    },
    worst_performance_sites_btn: {
        id: 'worst_performance_sites_btn',
        defaultMessage: 'View Sites'
    },
    worst_performance_no_data: {
        id: 'worst_performance_no_data',
        defaultMessage: 'No critical sites'
    },
    worst_performance_site_name_column: {
        id: 'worst_performance_site_name_column',
        defaultMessage: 'SITE NAME'
    },
    worst_performance_cpu_usage_column: {
        id: 'worst_performance_cpu_usage_column',
        defaultMessage: 'CPU USAGE'
    },
    worst_performance_memory_usage_column: {
        id: 'worst_performance_memory_usage_column',
        defaultMessage: 'MEMORY USAGE'
    },
    recent_site_events_column_description: {
        id: 'recent_site_events_column_description',
        defaultMessage: 'DESCRIPTION'
    },
    recent_site_events_column_site: {
        id: 'recent_site_events_column_site',
        defaultMessage: 'SITE'
    },
    recent_site_events_column_timestamp: {
        id: 'recent_site_events_column_timestamp',
        defaultMessage: 'DATE'
    },
    umbrella_dashboard_running_site_services: {
        id: 'umbrella_dashboard_running_site_services',
        defaultMessage: 'Services Health'
    },
    umbrella_dashboard_running_site_services_tooltip: {
        id: 'umbrella_dashboard_running_site_services_tooltip',
        defaultMessage: 'Services Health'
    },
    umbrella_dashboard_running_site_services_total: {
        id: 'umbrella_dashboard_running_site_services_total',
        defaultMessage: 'Total'
    },
    umbrella_dashboard_running_site_services_critical: {
        id: 'umbrella_dashboard_running_site_services_critical',
        defaultMessage: 'Critical'
    },
    umbrella_dashboard_running_site_services_warning: {
        id: 'umbrella_dashboard_running_site_services_warning',
        defaultMessage: 'Warning'
    },
    umbrella_dashboard_running_site_services_healthy: {
        id: 'umbrella_dashboard_running_site_services_healthy',
        defaultMessage: 'Healthy'
    },
    umbrella_dashboard_running_site_services_offline: {
        id: 'umbrella_dashboard_running_site_services_offline',
        defaultMessage: 'Offline'
    },
    umbrella_dashboard_running_site_services_ignored: {
        id: 'umbrella_dashboard_running_site_services_ignored',
        defaultMessage: 'Ignored'
    },
    change_site_connection_user_modal_title: {
        id: 'change_site_connection_user_modal_title',
        defaultMessage: 'Change Password for Connection User'
    },
    change_site_connection_user_modal_description: {
        id: 'change_site_connection_user_modal_description',
        defaultMessage: 'You are about to change the password of the user for the Site connection.'
    },
    change_site_connection_user_modal_new_password_input_label: {
        id: 'change_site_connection_user_modal_new_password_input_label',
        defaultMessage: 'New Password:'
    },
    change_site_connection_user_modal_submit_button: {
        id: 'change_site_connection_user_modal_submit_button',
        defaultMessage: 'Create \'Change password\' job(s)'
    },
    change_site_connection_user_modal_form_description: {
        id: 'change_site_connection_user_modal_form_description',
        defaultMessage: 'Changing password will not be done immediately. First you need to create a job in order to change user password.'
    },
    change_site_connection_user_job_created_success: {
        id: 'change_site_connection_user_job_created_success',
        defaultMessage: 'Created Change Connection User password job(s)'
    },
    change_site_connection_user_job_created_error: {
        id: 'change_site_connection_user_job_created_error',
        defaultMessage: 'Error in creating Change Connection User password job(s)'
    },
    change_site_user_name_modal_title: {
        id: 'change_site_user_name_modal_title',
        defaultMessage: 'Change Username'
    },
    change_site_user_modal_description: {
        id: 'change_site_user_modal_description',
        defaultMessage: 'Change Username for selected Users'
    },
    change_site_user_modal_username_input_label: {
        id: 'change_site_user_modal_username_input_label',
        defaultMessage: 'New Username:'
    },
    change_site_user_modal_submit_button: {
        id: 'change_site_user_modal_submit_button',
        defaultMessage: 'Create \'Change Username\' job(s)'
    },
    change_site_conneciton_user_job_created_success: {
        id: 'change_site_conneciton_user_job_created_success',
        defaultMessage: 'Created \'Change Username\' job(s)'
    },
    change_site_conneciton_user_job_created_error: {
        id: 'change_site_conneciton_user_job_created_error',
        defaultMessage: 'Error in creating \'Change Username\' job(s)'
    },
    copy_site_user_modal_title: {
        id: 'copy_site_user_modal_title',
        defaultMessage: 'Copy User'
    },
    copy_site_user_modal_description: {
        id: 'copy_site_user_modal_description',
        defaultMessage: 'Copy Users for selected Sites. Please add a new username and password for the new User'
    },
    copy_site_user_modal_username_input_label: {
        id: 'copy_site_user_modal_username_input_label',
        defaultMessage: 'New Username:'
    },
    copy_site_user_modal_password_input_label: {
        id: 'copy_site_user_modal_password_input_label',
        defaultMessage: 'New Password:'
    },
    copy_site_user_modal_submit_button: {
        id: 'copy_site_user_modal_submit_button',
        defaultMessage: 'Create \'Copy User\' job(s)'
    },
    site_users_list_copy_tooltip: {
        id: 'site_users_list_copy_tooltip',
        defaultMessage: 'Copy User'
    },
    site_users_list_copy_invalid_tooltip: {
        id: 'site_users_list_copy_invalid_tooltip',
        defaultMessage: 'You can only copy one User per Site at once'
    },
    copy_site_conneciton_user_job_created_success: {
        id: 'copy_site_conneciton_user_job_created_success',
        defaultMessage: 'Created \'Copy User\' job(s)'
    },
    copy_site_conneciton_user_job_created_error: {
        id: 'copy_site_conneciton_user_job_created_error',
        defaultMessage: 'Error in creating \'Copy User\' job(s)'
    },
    site_users_list_table_head_site_user: {
        id: 'site_users_list_table_head_site_user',
        defaultMessage: 'User'
    },
    site_users_list_table_head_site: {
        id: 'site_users_list_table_head_site',
        defaultMessage: 'Site'
    },
    site_users_list_table_head_user_labels: {
        id: 'site_users_list_table_head_user_labels',
        defaultMessage: 'User Labels'
    },
    site_users_list_table_head_site_labels: {
        id: 'site_users_list_table_head_site_labels',
        defaultMessage: 'Site Labels'
    },
    site_users_list_toolbar_selected_users: {
        id: 'site_users_list_toolbar_selected_users',
        defaultMessage: '{count} selected'
    },
    site_users_list_toolbar_delete_site_users_tooltip: {
        id: 'site_users_list_toolbar_delete_site_users_tooltip',
        defaultMessage: 'Delete users'
    },
    site_users_list_toolbar_delete_site_users_success: {
        id: 'site_users_list_toolbar_delete_site_users_success',
        defaultMessage: 'Jobs for deleting users successfully created'
    },
    site_users_list_toolbar_delete_site_users_error: {
        id: 'site_users_list_toolbar_delete_site_users_error',
        defaultMessage: 'Failed to create jobs to delete users'
    },
    site_users_list_toolbar_change_password_tooltip: {
        id: 'site_users_list_toolbar_change_password_tooltip',
        defaultMessage: 'Change password'
    },
    site_users_list_toolbar_change_properties_tooltip: {
        id: 'site_users_list_toolbar_change_properties_tooltip',
        defaultMessage: 'Change properties of selected users'
    },
    site_users_list_add_site_user_button: {
        id: 'site_users_list_add_site_user_button',
        defaultMessage: 'Add User'
    },
    site_users_list_change_name_tooltip: {
        id: 'site_users_list_change_name_tooltip',
        defaultMessage: 'Change Username'
    },
    site_users_list_change_name_invalid_tooltip: {
        id: 'site_users_list_change_name_invalid_tooltip',
        defaultMessage: 'You can only change one User\'s name per Site at once'
    },
    deploy_site_title: {
        id: 'deploy_site_title',
        defaultMessage: 'Deploy a Site'
    },
    deploy_site_floating_button_tooltip: {
        id: 'deploy_site_floating_button_tooltip',
        defaultMessage: 'Deploy a Site'
    },
    deploy_site_description: {
        id: 'deploy_site_description',
        defaultMessage: 'To deploy a new Site fill out this form and download the deploy zip package. The package includes an Site Installer and all necessary configuration files. Follow the instructions on the Site Installer Console Window.'
    },
    deploy_site_required_parameters_label: {
        id: 'deploy_site_required_parameters_label',
        defaultMessage: 'Required parameters:'
    },
    deploy_site_optional_site_parameters_label: {
        id: 'deploy_site_optional_site_parameters_label',
        defaultMessage: 'Optional site parameters:'
    },
    deploy_site_vms_parameters_label: {
        id: 'deploy_site_vms_parameters_label',
        defaultMessage: 'Site connection parameters:'
    },
    deploy_site_input_core_host_label: {
        id: 'deploy_site_input_core_host_label',
        defaultMessage: 'Core Host:*'
    },
    deploy_site_input_core_port_label: {
        id: 'deploy_site_input_core_port_label',
        defaultMessage: 'Core Port:*'
    },
    deploy_site_input_https_label: {
        id: 'deploy_site_input_https_label',
        defaultMessage: 'HTTPS:'
    },
    deploy_site_input_as_a_service_label: {
        id: 'deploy_site_input_as_a_service_label',
        defaultMessage: 'Windows service:'
    },
    deploy_site_input_service_name_label: {
        id: 'deploy_site_input_service_name_label',
        defaultMessage: 'Windows service name:*'
    },
    deploy_site_input_working_dir_label: {
        id: 'deploy_site_input_working_dir_label',
        defaultMessage: 'Working directory:*'
    },
    deploy_site_input_working_dir_tooltip: {
        id: 'deploy_site_input_working_dir_tooltip',
        defaultMessage: 'Service path should be an absolute path and the folder should not exist yet, but will be created by the Gateway installer.'
    },
    deploy_site_input_plugin_type_label: {
        id: 'deploy_site_input_plugin_type_label',
        defaultMessage: 'Plugin type:*'
    },
    deploy_site_input_name_label: {
        id: 'deploy_site_input_name_label',
        defaultMessage: 'Name:'
    },
    deploy_site_input_location_label: {
        id: 'deploy_site_input_location_label',
        defaultMessage: 'Location:'
    },
    deploy_site_input_labels_label: {
        id: 'deploy_site_input_labels_label',
        defaultMessage: 'Labels:'
    },
    deploy_site_input_vms_host_label: {
        id: 'deploy_site_input_vms_host_label',
        defaultMessage: 'Host:*'
    },
    deploy_site_input_vms_port_label: {
        id: 'deploy_site_input_vms_port_label',
        defaultMessage: 'Port:*'
    },
    deploy_site_input_vms_user_name_label: {
        id: 'deploy_site_input_vms_user_name_label',
        defaultMessage: 'Username:*'
    },
    deploy_site_input_vms_password_label: {
        id: 'deploy_site_input_vms_password_label',
        defaultMessage: 'Password:*'
    },
    deploy_site_download_installer_button: {
        id: 'deploy_site_download_installer_button',
        defaultMessage: 'Download Installer'
    },
    deploy_site_instructions: {
        id: 'deploy_site_instructions',
        defaultMessage: 'Execute the Umbrella.Gateway.Installer.exe to deploy the Site'
    },
    deploy_site_input_core_host_placeholder: {
        id: 'deploy_site_input_core_host_placeholder',
        defaultMessage: 'Core Host'
    },
    deploy_site_input_core_port_placeholder: {
        id: 'deploy_site_input_core_port_placeholder',
        defaultMessage: 'Core Port'
    },
    deploy_site_input_service_name_placeholder: {
        id: 'deploy_site_input_service_name_placeholder',
        defaultMessage: 'Windows service name'
    },
    deploy_site_input_working_dir_placeholder: {
        id: 'deploy_site_input_working_dir_placeholder',
        defaultMessage: 'Working directory'
    },
    deploy_site_input_plugin_type_placeholder: {
        id: 'deploy_site_input_plugin_type_placeholder',
        defaultMessage: 'Plugin Type'
    },
    deploy_site_input_name_placeholder: {
        id: 'deploy_site_input_name_placeholder',
        defaultMessage: 'Name'
    },
    deploy_site_input_location_placeholder: {
        id: 'deploy_site_input_location_placeholder',
        defaultMessage: 'Location'
    },
    deploy_site_input_labels_placeholder: {
        id: 'deploy_site_input_labels_placeholder',
        defaultMessage: 'Labels'
    },
    deploy_site_input_vms_host_placeholder: {
        id: 'deploy_site_input_vms_host_placeholder',
        defaultMessage: 'Host'
    },
    deploy_site_input_vms_port_placeholder: {
        id: 'deploy_site_input_vms_port_placeholder',
        defaultMessage: 'Port'
    },
    deploy_site_input_vms_user_name_placeholder: {
        id: 'deploy_site_input_vms_user_name_placeholder',
        defaultMessage: 'Username'
    },
    deploy_site_input_vms_password_placeholder: {
        id: 'deploy_site_input_vms_password_placeholder',
        defaultMessage: 'Password'
    },
    deploy_site_input_validation_port: {
        id: 'deploy_site_input_validation_port',
        defaultMessage: 'Invalid port number (1 - 65535)'
    },
    deploy_site_input_validation_name: {
        id: 'deploy_site_input_validation_name',
        defaultMessage: 'Invalid site name'
    },
    deploy_site_input_validation_location: {
        id: 'deploy_site_input_validation_location',
        defaultMessage: 'Invalid site location'
    },
    deploy_site_input_validation_working_dir: {
        id: 'deploy_site_input_validation_working_dir',
        defaultMessage: 'Invalid working directory'
    },
    deploy_site_input_validation_service_name: {
        id: 'deploy_site_input_validation_service_name',
        defaultMessage: 'Invalid service name (only letters, numbers and underscore are allowed)'
    },
    deploy_site_input_validation_host: {
        id: 'deploy_site_input_validation_host',
        defaultMessage: 'Invalid host name'
    },
    deploy_site_success_message: {
        id: 'deploy_site_success_message',
        defaultMessage: 'Successfully downloaded deployment package'
    },
    deploy_site_error_message: {
        id: 'deploy_site_error_message',
        defaultMessage: 'Failed to create deployment package'
    },
    deploy_site_input_branchless: {
        id: 'deploy_site_input_branchless',
        defaultMessage: 'Branchless'
    },
    deploy_site_input_branchless_tooltip: {
        id: 'deploy_site_input_branchless_tooltip',
        defaultMessage: 'By selecting the branchless mode you only need to install one Umbrella Gateway to the Site installation main core and you will manage the whole Site installation and all its branches. But the Site host must be the one of the Site installation main core.'
    },
    create_user_title_preselected_sites: {
        id: 'create_user_title_preselected_sites',
        defaultMessage: 'Add new user to selected sites'
    },
    create_user_title: {
        id: 'create_user_title',
        defaultMessage: 'Add new user'
    },
    create_user_description: {
        id: 'create_user_description',
        defaultMessage: 'Add new user to selected sites'
    },
    create_user_input_sites_label: {
        id: 'create_user_input_sites_label',
        defaultMessage: 'Sites:'
    },
    create_user_input_sites_placeholder: {
        id: 'create_user_input_sites_placeholder',
        defaultMessage: 'Select Sites...'
    },
    create_user_input_username_label: {
        id: 'create_user_input_username_label',
        defaultMessage: 'Username:'
    },
    create_user_sites_jobs_button: {
        id: 'create_user_sites_jobs_button',
        defaultMessage: 'Create \'Add user\' job(s)'
    },
    create_user_instructions: {
        id: 'create_user_instructions',
        defaultMessage: 'Adding users will not be done immediately. Jobs will be created that will be transmitted to the sites and processed'
    },
    create_user_input_password_label: {
        id: 'create_user_input_password_label',
        defaultMessage: 'Password:'
    },
    create_user_success_message: {
        id: 'create_user_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    create_user_error_message: {
        id: 'create_user_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    create_user_input_email_not_matching_regex: {
        id: 'create_user_input_email_not_matching_regex',
        defaultMessage: 'Not a valid E-Mail address'
    },
    create_user_input_email_placeholder: {
        id: 'create_user_input_email_placeholder',
        defaultMessage: 'E-Mail'
    },
    create_user_input_email_label: {
        id: 'create_user_input_email_label',
        defaultMessage: 'E-Mail'
    },
    change_password_modal_title: {
        id: 'change_password_modal_title',
        defaultMessage: 'Change Password '
    },
    change_password_modal_description: {
        id: 'change_password_modal_description',
        defaultMessage: 'Change Password for the selected users'
    },
    change_password_modal_input_password_label: {
        id: 'change_password_modal_input_password_label',
        defaultMessage: 'Password:'
    },
    change_second_password_modal_input_password_label: {
        id: 'change_second_password_modal_input_password_label',
        defaultMessage: 'Second Password (optional):'
    },
    change_password_modal_button: {
        id: 'change_password_modal_button',
        defaultMessage: 'Create \'Change password\' job(s)'
    },
    change_password_modal_success_message: {
        id: 'change_password_modal_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    change_password_modal_error_message: {
        id: 'change_password_modal_error_message',
        defaultMessage: 'Failed to create jobs for changing passwords'
    },
    change_password_ignore_second_password: {
        id: 'change_password_ignore_second_password',
        defaultMessage: 'Ignore second password:'
    },
    change_camera_password_modal_title: {
        id: 'change_camera_password_modal_title',
        defaultMessage: 'Change Camera Password'
    },
    change_camera_password_modal_description: {
        id: 'change_camera_password_modal_description',
        defaultMessage: 'Change Password for the selected cameras'
    },
    change_camera_password_modal_input_password_label: {
        id: 'change_camera_password_modal_input_password_label',
        defaultMessage: 'Password:'
    },
    change_camera_password_modal_button: {
        id: 'change_camera_password_modal_button',
        defaultMessage: 'Create \'Change camera password\' job(s)'
    },
    change_camera_password_modal_success_message: {
        id: 'change_camera_password_modal_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    change_camera_password_modal_error_message: {
        id: 'change_camera_password_modal_error_message',
        defaultMessage: 'Failed to create jobs for changing camera passwords'
    },
    create_card_holder_title_preselected_sites: {
        id: 'create_card_holder_title_preselected_sites',
        defaultMessage: 'Add new card holder to selected sites'
    },
    create_card_holder_title: {
        id: 'create_card_holder_title',
        defaultMessage: 'Add new card holder'
    },
    create_card_holder_description: {
        id: 'create_card_holder_description',
        defaultMessage: 'Add new card holder to selected sites'
    },
    create_card_holder_input_sites_label: {
        id: 'create_card_holder_input_sites_label',
        defaultMessage: 'Sites:'
    },
    create_card_holder_input_sites_placeholder: {
        id: 'create_card_holder_input_sites_placeholder',
        defaultMessage: 'Select Sites...'
    },
    create_card_holder_input_firstname_label: {
        id: 'create_card_holder_input_firstname_label',
        defaultMessage: 'First name:'
    },
    create_card_holder_input_lastname_label: {
        id: 'create_card_holder_input_lastname_label',
        defaultMessage: 'Last name:'
    },
    create_card_holder_input_cardnumber_label: {
        id: 'create_card_holder_input_cardnumber_label',
        defaultMessage: 'Card number:'
    },
    create_card_holder_input_start_date_label: {
        id: 'create_card_holder_input_start_date_label',
        defaultMessage: 'Start date:'
    },
    create_card_holder_input_end_date_label: {
        id: 'create_card_holder_input_end_date_label',
        defaultMessage: 'End date:'
    },
    create_card_holder_sites_jobs_button: {
        id: 'create_card_holder_sites_jobs_button',
        defaultMessage: 'Create \'Add card holder\' job(s)'
    },
    create_card_holder_instructions: {
        id: 'create_card_holder_instructions',
        defaultMessage: 'Adding card holders will not be done immediately. Jobs will be created that will be transmitted to the sites and processed'
    },
    create_card_holder_success_message: {
        id: 'create_card_holder_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    create_card_holder_error_message: {
        id: 'create_card_holder_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    system_messages_configuration_modal_title: {
        id: 'system_messages_configuration_modal_title',
        defaultMessage: 'System Messages Configuration'
    },
    system_messages_configuration_modal_description: {
        id: 'system_messages_configuration_modal_description',
        defaultMessage: 'Change configuration for System Messages '
    },
    system_messages_configuration_input_jobs_button: {
        id: 'system_messages_configuration_input_jobs_button',
        defaultMessage: 'Create Job(s)'
    },
    siteServices_table_head_siteService_name: {
        id: 'siteServices_table_head_siteService_name',
        defaultMessage: 'Service Name'
    },
    siteServices_table_head_site_name: {
        id: 'siteServices_table_head_site_name',
        defaultMessage: 'Site Name'
    },
    siteServices_table_head_labels: {
        id: 'siteServices_table_head_labels',
        defaultMessage: 'Labels'
    },
    siteServices_table_head_site_labels: {
        id: 'siteServices_table_head_site_labels',
        defaultMessage: 'Site Labels'
    },
    siteServices_table_toolbar_selected_siteServices: {
        id: 'siteServices_table_toolbar_selected_siteServices',
        defaultMessage: '{count} selected'
    },
    siteServices_table_toolbar_change_properties_tooltip: {
        id: 'siteServices_table_toolbar_change_properties_tooltip',
        defaultMessage: 'Change properties of selected services'
    },
    site_services_table_toolbar_restart_service: {
        id: 'site_services_table_toolbar_restart_service',
        defaultMessage: 'Restart Service'
    },
    site_services_table_restart_service_job_created_success: {
        id: 'site_services_table_restart_service_job_created_success',
        defaultMessage: 'Created restart Service job'
    },
    site_services_table_restart_service_job_created_error: {
        id: 'site_services_table_restart_service_job_created_error',
        defaultMessage: 'Error in creating restart Service job'
    },
    site_services_restart_tooltip: {
        id: 'site_services_restart_tooltip',
        defaultMessage: 'Restart Services'
    },
    site_service_performance_tooltip_start: {
        id: 'site_service_performance_tooltip_start',
        defaultMessage: 'Start Services'
    },
    site_service_performance_tooltip_stop: {
        id: 'site_service_performance_tooltip_stop',
        defaultMessage: 'Stop Services'
    },
    cameras_table_head_camera_name: {
        id: 'cameras_table_head_camera_name',
        defaultMessage: 'Camera Name'
    },
    cameras_table_head_site_name: {
        id: 'cameras_table_head_site_name',
        defaultMessage: 'Site Name'
    },
    cameras_table_head_labels: {
        id: 'cameras_table_head_labels',
        defaultMessage: 'Labels'
    },
    cameras_table_head_site_labels: {
        id: 'cameras_table_head_site_labels',
        defaultMessage: 'Site Labels'
    },
    cameras_table_toolbar_selected_cameras: {
        id: 'cameras_table_toolbar_selected_cameras',
        defaultMessage: '{count} selected'
    },
    cameras_table_toolbar_change_properties_tooltip: {
        id: 'cameras_table_toolbar_change_properties_tooltip',
        defaultMessage: 'Change properties of selected cameras'
    },
    cameras_table_toolbar_change_password_tooltip: {
        id: 'cameras_table_toolbar_change_password_tooltip',
        defaultMessage: 'Change password of selected cameras'
    },
    camera_table_toolbar_apply_firmware_tooltip: {
        id: 'camera_table_toolbar_apply_firmware_tooltip',
        defaultMessage: 'Firmware Update'
    },
    apply_firmware_modal_step_one: {
        id: 'apply_firmware_modal_step_one',
        defaultMessage: 'Select Firmware'
    },
    apply_firmware_modal_step_two: {
        id: 'apply_firmware_modal_step_two',
        defaultMessage: 'Confirm Update'
    },
    apply_firmware_modal_step_one_title: {
        id: 'apply_firmware_modal_step_one_title',
        defaultMessage: 'Choose New Firmware'
    },
    apply_firmware_modal_step_one_description: {
        id: 'apply_firmware_modal_step_one_description',
        defaultMessage: 'Please make sure that the cameras you selected support this firmware!'
    },
    apply_firmware_modal_next_button: {
        id: 'apply_firmware_modal_next_button',
        defaultMessage: 'Next'
    },
    apply_firmware_modal_step_two_title: {
        id: 'apply_firmware_modal_step_two_title',
        defaultMessage: 'Firmware Update'
    },
    apply_firmware_modal_step_two_description: {
        id: 'apply_firmware_modal_step_two_description',
        defaultMessage: 'Listed cameras will be updated to {filename}.'
    },
    apply_firmware_modal_create_jobs: {
        id: 'apply_firmware_modal_create_jobs',
        defaultMessage: 'Create Job(s)'
    },
    apply_firmware_modal_cancel: {
        id: 'apply_firmware_modal_cancel',
        defaultMessage: 'Cancel'
    },
    apply_firmware_create_job_success: {
        id: 'apply_firmware_create_job_success',
        defaultMessage: 'Succesfully created job(s)'
    },
    apply_firmware_create_job_error: {
        id: 'apply_firmware_create_job_error',
        defaultMessage: 'Error in creating jobs'
    },
    general_validation_required_field: {
        id: 'general_validation_required_field',
        defaultMessage: 'This field is mandatory'
    },
    general_validation_invalid_field: {
        id: 'general_validation_invalid_field',
        defaultMessage: 'Invalid field value'
    },
    site_plugin_type_fake: {
        id: 'site_plugin_type_fake',
        defaultMessage: 'Test Plugin'
    },
    site_plugin_type_cayugar10: {
        id: 'site_plugin_type_cayugar10',
        defaultMessage: 'Cayuga R10 Plugin'
    },
    site_plugin_type_cayugar11: {
        id: 'site_plugin_type_cayugar11',
        defaultMessage: 'Cayuga R11 Plugin'
    },
    site_plugin_type_cayugar12: {
        id: 'site_plugin_type_cayugar12',
        defaultMessage: 'Cayuga R12 Plugin'
    },
    site_plugin_type_cayugar13: {
        id: 'site_plugin_type_cayugar13',
        defaultMessage: 'Cayuga R13 Plugin'
    },
    site_plugin_type_cayugar14: {
        id: 'site_plugin_type_cayugar14',
        defaultMessage: 'Cayuga R14 Plugin'
    },
    site_plugin_type_cayugar15: {
        id: 'site_plugin_type_cayugar15',
        defaultMessage: 'Cayuga R15 Plugin'
    },
    site_plugin_type_cayugar16: {
        id: 'site_plugin_type_cayugar16',
        defaultMessage: 'Cayuga R16 Plugin'
    },
    site_plugin_type_cayugar17: {
        id: 'site_plugin_type_cayugar17',
        defaultMessage: 'Cayuga R17 Plugin'
    },
    site_plugin_type_situator: {
        id: 'site_plugin_type_situator',
        defaultMessage: 'Qognify Situator Plugin'
    },
    site_plugin_type_qognifyvms71: {
        id: 'site_plugin_type_qognifyvms71',
        defaultMessage: 'Qognify VMS 7.1 Plugin'
    },
    plugin_type_monitoring: {
        id: 'plugin_type_monitoring',
        defaultMessage: 'Monitoring'
    },
    plugin_type_vms: {
        id: 'plugin_type_vms',
        defaultMessage: 'Site installation'
    },
    multiselect_create_label: {
        id: 'multiselect_create_label',
        defaultMessage: 'Create "{value}"'
    },
    dropdown_selection_none: {
        id: 'dropdown_selection_none',
        defaultMessage: 'None'
    },
    dropdown_selection_true: {
        id: 'dropdown_selection_true',
        defaultMessage: 'Yes'
    },
    dropdown_selection_false: {
        id: 'dropdown_selection_false',
        defaultMessage: 'No'
    },
    toolbar_auto_refresh: {
        id: 'toolbar_auto_refresh',
        defaultMessage: 'Pause Live Updates'
    },
    toolbar_auto_refresh_play: {
        id: 'toolbar_auto_refresh_play',
        defaultMessage: 'Start Live Updates'
    },
    table_pagination_rows_per_page: {
        id: 'table_pagination_rows_per_page',
        defaultMessage: 'Rows per page:'
    },
    table_pagination_displayed_rows: {
        id: 'table_pagination_displayed_rows',
        defaultMessage: '{from}-{to} of {count}'
    },
    site_details_title: {
        id: 'site_details_title',
        defaultMessage: 'Below you can change the Site parameters according to your preferences.'
    },
    site_details_label_placeholder: {
        id: 'site_details_label_placeholder',
        defaultMessage: 'Select labels...'
    },
    site_details_username_label: {
        id: 'site_details_username_label',
        defaultMessage: 'Name:'
    },
    site_details_label_label: {
        id: 'site_details_label_label',
        defaultMessage: 'Label:'
    },
    site_details_location_label: {
        id: 'site_details_location_label',
        defaultMessage: 'Location:'
    },
    site_details_ip_label: {
        id: 'site_details_ip_label',
        defaultMessage: 'IP:'
    },
    site_details_host_label: {
        id: 'site_details_host_label',
        defaultMessage: 'Host:'
    },
    site_details_type_label: {
        id: 'site_details_type_label',
        defaultMessage: 'Type:'
    },
    site_details_state_label: {
        id: 'site_details_state_label',
        defaultMessage: 'State:'
    },
    site_details_last_heartbeat_label: {
        id: 'site_details_last_heartbeat_label',
        defaultMessage: 'Last Heartbeat:'
    },
    site_details_vms_server_version_label: {
        id: 'site_details_vms_server_version_label',
        defaultMessage: 'Server Version:'
    },
    site_details_vms_client_version_label: {
        id: 'site_details_vms_client_version_label',
        defaultMessage: 'Client Version:'
    },
    site_details_version_label: {
        id: 'site_details_version_label',
        defaultMessage: 'Version:'
    },
    site_details_vms_server_id_label: {
        id: 'site_details_vms_server_id_label',
        defaultMessage: 'Server Id:'
    },
    site_details_machine_code_label: {
        id: 'site_details_machine_code_label',
        defaultMessage: 'Machine Code:'
    },
    site_details_update_button: {
        id: 'site_details_update_button',
        defaultMessage: 'Update'
    },
    site_details_reset_all_button: {
        id: 'site_details_reset_all_button',
        defaultMessage: 'Reset all'
    },
    site_details_site_update_success_message: {
        id: 'site_details_site_update_success_message',
        defaultMessage: 'Site successfully updated'
    },
    site_details_site_update_error_message: {
        id: 'site_details_site_update_error_message',
        defaultMessage: 'Failed to update site'
    },
    site_details_fetch_site_details_error: {
        id: 'site_details_fetch_site_details_error',
        defaultMessage: 'Failed to get site details'
    },
    site_details_name_error_message: {
        id: 'site_details_name_error_message',
        defaultMessage: 'Enter valid site name'
    },
    site_details_location_error_message: {
        id: 'site_details_location_error_message',
        defaultMessage: 'Invalid location'
    },
    site_details_tab_details: {
        id: 'site_details_tab_details',
        defaultMessage: 'Details'
    },
    site_details_tab_comments: {
        id: 'site_details_tab_comments',
        defaultMessage: 'Comments'
    },
    site_details_tab_issues: {
        id: 'site_details_tab_issues',
        defaultMessage: 'Issues'
    },
    site_details_issues_title: {
        id: 'site_details_issues_title',
        defaultMessage: 'Below you can take a look at the issues of this Site'
    },
    site_details_issues_start_tooltip: {
        id: 'site_details_issues_start_tooltip',
        defaultMessage: 'Start'
    },
    site_details_issues_stop_tooltip: {
        id: 'site_details_issues_stop_tooltip',
        defaultMessage: 'Stop'
    },
    site_details_issues_restart_tooltip: {
        id: 'site_details_issues_restart_tooltip',
        defaultMessage: 'Restart'
    },
    site_details_issues_ignore_tooltip: {
        id: 'site_details_issues_ignore_tooltip',
        defaultMessage: 'Ignore'
    },
    site_details_issues_cameras: {
        id: 'site_details_issues_cameras',
        defaultMessage: 'Cameras'
    },
    site_details_issues_systems: {
        id: 'site_details_issues_systems',
        defaultMessage: 'Systems'
    },
    site_details_issues_services: {
        id: 'site_details_issues_services',
        defaultMessage: 'Services'
    },
    site_details_comment_update_success_message: {
        id: 'site_details_comment_update_success_message',
        defaultMessage: 'Comment successfully updated'
    },
    site_details_comment_update_error_message: {
        id: 'site_details_comment_update_error_message',
        defaultMessage: 'Failed to update comment'
    },
    site_details_comment_title: {
        id: 'site_details_comment_title',
        defaultMessage: 'Title'
    },
    site_details_comment_description: {
        id: 'site_details_comment_description',
        defaultMessage: 'Description'
    },
    site_details_comment_update_comment: {
        id: 'site_details_comment_update_comment',
        defaultMessage: 'Update Comment'
    },
    site_details_comment_add_comment: {
        id: 'site_details_comment_add_comment',
        defaultMessage: 'Add Comment'
    },
    site_details_comment_update_comments: {
        id: 'site_details_comment_update_comments',
        defaultMessage: 'Update'
    },
    site_details_comment_max_comments_reached: {
        id: 'site_details_comment_max_comments_reached',
        defaultMessage: 'Maximum reached'
    },
    site_details_comment_reset_comments: {
        id: 'site_details_comment_reset_comments',
        defaultMessage: 'Reset all'
    },
    site_details_comments_title: {
        id: 'site_details_comments_title',
        defaultMessage: 'Below you can change or add comments to the Site'
    },
    site_details_comment_remove_comment: {
        id: 'site_details_comment_remove_comment',
        defaultMessage: 'Remove Comment'
    },
    site_details_comment_clear_comment: {
        id: 'site_details_comment_clear_comment',
        defaultMessage: 'Clear'
    },
    site_details_comment_list_comments_group: {
        id: 'site_details_comment_list_comments_group',
        defaultMessage: 'Comments'
    },
    property_general_enabled: {
        id: 'property_general_enabled',
        defaultMessage: 'Enabled'
    },
    property_general_status: {
        id: 'property_general_status',
        defaultMessage: 'Status'
    },
    property_general_statuscode: {
        id: 'property_general_statuscode',
        defaultMessage: 'Status code'
    },
    property_general_statustimestamp: {
        id: 'property_general_statustimestamp',
        defaultMessage: 'Status timestamp'
    },
    property_device_model: {
        id: 'property_device_model',
        defaultMessage: 'Model'
    },
    property_device_manufacturer: {
        id: 'property_device_manufacturer',
        defaultMessage: 'Manufacturer'
    },
    property_device_host: {
        id: 'property_device_host',
        defaultMessage: 'Host'
    },
    property_device_firmware: {
        id: 'property_device_firmware',
        defaultMessage: 'Firmware'
    },
    property_videostream_fps_01: {
        id: 'property_videostream_fps_01',
        defaultMessage: 'Stream_Framerate.01'
    },
    property_videostream_fps_02: {
        id: 'property_videostream_fps_02',
        defaultMessage: 'Stream_Framerate.02'
    },
    property_videostream_fps_03: {
        id: 'property_videostream_fps_03',
        defaultMessage: 'Stream_Framerate.03'
    },
    property_videostream_fps_04: {
        id: 'property_videostream_fps_04',
        defaultMessage: 'Stream_Framerate.04'
    },
    property_videostream_fps_05: {
        id: 'property_videostream_fps_05',
        defaultMessage: 'Stream_Framerate.05'
    },
    property_videostream_fps_06: {
        id: 'property_videostream_fps_06',
        defaultMessage: 'Stream_Framerate.06'
    },
    property_videostream_resolution_01: {
        id: 'property_videostream_resolution_01',
        defaultMessage: 'Stream_Resolution.01'
    },
    property_videostream_resolution_02: {
        id: 'property_videostream_resolution_02',
        defaultMessage: 'Stream_Resolution.02'
    },
    property_videostream_resolution_03: {
        id: 'property_videostream_resolution_03',
        defaultMessage: 'Stream_Resolution.03'
    },
    property_videostream_resolution_04: {
        id: 'property_videostream_resolution_04',
        defaultMessage: 'Stream_Resolution.04'
    },
    property_videostream_resolution_05: {
        id: 'property_videostream_resolution_05',
        defaultMessage: 'Stream_Resolution.05'
    },
    property_videostream_resolution_06: {
        id: 'property_videostream_resolution_06',
        defaultMessage: 'Stream_Resolution.06'
    },
    property_videostream_videocodec_01: {
        id: 'property_videostream_videocodec_01',
        defaultMessage: 'Stream_VideoCodec.01'
    },
    property_videostream_videocodec_02: {
        id: 'property_videostream_videocodec_02',
        defaultMessage: 'Stream_VideoCodec.02'
    },
    property_videostream_videocodec_03: {
        id: 'property_videostream_videocodec_03',
        defaultMessage: 'Stream_VideoCodec.03'
    },
    property_videostream_videocodec_04: {
        id: 'property_videostream_videocodec_04',
        defaultMessage: 'Stream_VideoCodec.04'
    },
    property_videostream_videocodec_05: {
        id: 'property_videostream_videocodec_05',
        defaultMessage: 'Stream_VideoCodec.05'
    },
    property_videostream_videocodec_06: {
        id: 'property_videostream_videocodec_06',
        defaultMessage: 'Stream_VideoCodec.06'
    },
    property_groups_users: {
        id: 'property_groups_users',
        defaultMessage: 'Users'
    },
    property_rights_activate_active_directory_support: {
        id: 'property_rights_activate_active_directory_support',
        defaultMessage: 'Activate Active Directory support'
    },
    property_rights_user_must_use_secure_password: {
        id: 'property_rights_user_must_use_secure_password',
        defaultMessage: 'User must use a secure password'
    },
    property_rights_user_may_change_own_password: {
        id: 'property_rights_user_may_change_own_password',
        defaultMessage: 'User may change own password'
    },
    property_rights_use_secure_password_for_exports: {
        id: 'property_rights_use_secure_password_for_exports',
        defaultMessage: 'Use secure password for exports'
    },
    property_rights_comment_necessary_for_changing_to_archive_mode: {
        id: 'property_rights_comment_necessary_for_changing_to_archive_mode',
        defaultMessage: 'Comment necessary for changing to archive mode'
    },
    property_rights_user_must_change_password_regularly: {
        id: 'property_rights_user_must_change_password_regularly',
        defaultMessage: 'User must change password regularly (days)'
    },
    property_rights_limit_archive_access: {
        id: 'property_rights_limit_archive_access',
        defaultMessage: 'Limit archive access (minutes)'
    },
    property_rights_can_manually_record: {
        id: 'property_rights_can_manually_record',
        defaultMessage: 'Show Recording Button'
    },
    property_global_rights_camera_live: {
        id: 'property_global_rights_camera_live',
        defaultMessage: 'View Live Video'
    },
    property_global_rights_camera_recorded: {
        id: 'property_global_rights_camera_recorded',
        defaultMessage: 'View Recorded Video'
    },
    property_global_rights_camera_recorded_delete: {
        id: 'property_global_rights_camera_recorded_delete',
        defaultMessage: 'Delete recordings'
    },
    property_global_rights_camera_write_protection: {
        id: 'property_global_rights_camera_write_protection',
        defaultMessage: 'Overwrite protection'
    },
    property_global_rights_camera_ptz: {
        id: 'property_global_rights_camera_ptz',
        defaultMessage: 'Camera PTZ'
    },
    property_global_rights_camera_ptz_priority: {
        id: 'property_global_rights_camera_ptz_priority',
        defaultMessage: 'Camera lock'
    },
    property_global_rights_camera_preset: {
        id: 'property_global_rights_camera_preset',
        defaultMessage: 'Use Camera Presets'
    },
    property_global_rights_camera_preset_create: {
        id: 'property_global_rights_camera_preset_create',
        defaultMessage: 'Create camera presets'
    },
    property_global_rights_camera_export_seetec: {
        id: 'property_global_rights_camera_export_seetec',
        defaultMessage: 'Export camera'
    },
    property_global_rights_camera_export_avi: {
        id: 'property_global_rights_camera_export_avi',
        defaultMessage: 'Export camera (AVI)'
    },
    property_global_rights_user_privacy_zones_right: {
        id: 'property_global_rights_user_privacy_zones_right',
        defaultMessage: 'Privacy masking'
    },
    property_global_rights_mpeg_audio: {
        id: 'property_global_rights_mpeg_audio',
        defaultMessage: 'MPEG audio'
    },
    property_global_rights_reference_image_create: {
        id: 'property_global_rights_reference_image_create',
        defaultMessage: 'Create reference image'
    },
    property_global_rights_intrusion_detection: {
        id: 'property_global_rights_intrusion_detection',
        defaultMessage: 'Intrusion detection'
    },
    property_global_rights_map_use: {
        id: 'property_global_rights_map_use',
        defaultMessage: 'Map'
    },
    property_global_rights_layer_use: {
        id: 'property_global_rights_layer_use',
        defaultMessage: 'Layer'
    },
    property_global_rights_button_use: {
        id: 'property_global_rights_button_use',
        defaultMessage: 'Button'
    },
    property_global_rights_report_mode: {
        id: 'property_global_rights_report_mode',
        defaultMessage: 'Report Mode'
    },
    property_global_rights_report_mode_templates: {
        id: 'property_global_rights_report_mode_templates',
        defaultMessage: 'Edit report templates'
    },
    property_global_rights_counting_data_interpretation: {
        id: 'property_global_rights_counting_data_interpretation',
        defaultMessage: 'Count analysis'
    },
    property_global_rights_lp_group_use: {
        id: 'property_global_rights_lp_group_use',
        defaultMessage: 'Use license plate group'
    },
    property_global_rights_lp_group_change: {
        id: 'property_global_rights_lp_group_change',
        defaultMessage: 'Change license plate group'
    },
    property_global_rights_event_interface: {
        id: 'property_global_rights_event_interface',
        defaultMessage: 'Use event interface'
    },
    property_global_rights_access_control_use_data_editor: {
        id: 'property_global_rights_access_control_use_data_editor',
        defaultMessage: 'Access control data editor'
    },
    property_global_rights_use_event_interface_commands: {
        id: 'property_global_rights_use_event_interface_commands',
        defaultMessage: 'Use event interface commands'
    },
    property_events_sourceid: {
        id: 'property_events_sourceid',
        defaultMessage: 'Source ID'
    },
    property_events_causeid: {
        id: 'property_events_causeid',
        defaultMessage: 'Cause ID'
    },
    property_events_sourcename: {
        id: 'property_events_sourcename',
        defaultMessage: 'Source'
    },
    property_events_causename: {
        id: 'property_events_causename',
        defaultMessage: 'Cause'
    },
    property_events_value: {
        id: 'property_events_value',
        defaultMessage: 'Value'
    },
    property_events_related_positive_event: {
        id: 'property_events_related_positive_event',
        defaultMessage: 'Related Resolve Event'
    },
    property_events_affected_negative_events: {
        id: 'property_events_affected_negative_events',
        defaultMessage: 'Related Issue Events'
    },
    property_events_description: {
        id: 'property_events_description',
        defaultMessage: 'Description'
    },
    property_recording_standard_recording_enabled: {
        id: 'property_recording_standard_recording_enabled',
        defaultMessage: 'Standard Recording Enabled'
    },
    property_recording_standard_recording_period: {
        id: 'property_recording_standard_recording_period',
        defaultMessage: 'Standard Recording Period'
    },
    property_recording_standard_recording_timelimit: {
        id: 'property_recording_standard_recording_timelimit',
        defaultMessage: 'Standard Recording Time Limit (minutes)'
    },
    property_recording_alarm_recording_enabled: {
        id: 'property_recording_alarm_recording_enabled',
        defaultMessage: 'Alarm Recording Enabled'
    },
    property_recording_alarm_recording_timelimit: {
        id: 'property_recording_alarm_recording_timelimit',
        defaultMessage: 'Alarm Recording Time Limit (minutes)'
    },
    property_recording_alarm_recording_stream: {
        id: 'property_recording_alarm_recording_stream',
        defaultMessage: 'Alarm Recording Stream'
    },
    property_recording_alarm_recording_prebuffer: {
        id: 'property_recording_alarm_recording_prebuffer',
        defaultMessage: 'Alarm Recording Pre-Alarm Buffer (seconds)'
    },
    property_recording_alarm_recording_postduration: {
        id: 'property_recording_alarm_recording_postduration',
        defaultMessage: 'Alarm Recording Post Duration (seconds)'
    },
    property_recording_statistics_treshold_enabled: {
        id: 'property_recording_statistics_treshold_enabled',
        defaultMessage: 'Enable tresholds'
    },
    property_recording_statistics_maximum_packetloss: {
        id: 'property_recording_statistics_maximum_packetloss',
        defaultMessage: 'Maximum packet loss (%)'
    },
    property_recording_statistics_standard_recording_framerate_delta: {
        id: 'property_recording_statistics_standard_recording_framerate_delta',
        defaultMessage: 'Standard Recording Frame rate delta (fps)'
    },
    property_recording_statistics_standard_recording_maximum_frames_dropped: {
        id: 'property_recording_statistics_standard_recording_maximum_frames_dropped',
        defaultMessage: 'Standard Recording Max. frames dropped'
    },
    property_recording_statistics_alarm_recording_framerate_delta: {
        id: 'property_recording_statistics_alarm_recording_framerate_delta',
        defaultMessage: 'Alarm Recording Frame rate delta (fps)'
    },
    property_recording_statistics_alarm_recording_maximum_frames_dropped: {
        id: 'property_recording_statistics_alarm_recording_maximum_frames_dropped',
        defaultMessage: 'Alarm Recording Max. frames dropped'
    },
    property_recording_statistics_notifications: {
        id: 'property_recording_statistics_notifications',
        defaultMessage: 'Enable MDB Statistics Notifications'
    },
    property_recording_statistics_recording_state: {
        id: 'property_recording_statistics_recording_state',
        defaultMessage: 'Status of Recording'
    },
    property_recording_statistics_standard_max_timeperiod: {
        id: 'property_recording_statistics_standard_max_timeperiod',
        defaultMessage: 'Standard Recording Max. time period'
    },
    property_recording_statistics_alarm_max_timeperiod: {
        id: 'property_recording_statistics_alarm_max_timeperiod',
        defaultMessage: 'Alarm Recording Max. time period'
    },
    property_recording_statistics_standard_actual_timeperiod: {
        id: 'property_recording_statistics_standard_actual_timeperiod',
        defaultMessage: 'Standard Recording Actual time period'
    },
    property_recording_statistics_alarm_actual_timeperiod: {
        id: 'property_recording_statistics_alarm_actual_timeperiod',
        defaultMessage: 'Alarm Recording Actual time period'
    },
    property_recording_statistics_standard_oldest_frametime: {
        id: 'property_recording_statistics_standard_oldest_frametime',
        defaultMessage: 'Standard Recording Oldest frame time'
    },
    property_recording_statistics_alarm_oldest_frametime: {
        id: 'property_recording_statistics_alarm_oldest_frametime',
        defaultMessage: 'Alarm Recording Oldest frame time'
    },
    property_recording_statistics_timestamp: {
        id: 'property_recording_statistics_timestamp',
        defaultMessage: 'Recording Statistics Timestamp'
    },
    property_recording_statistics_devicemanager: {
        id: 'property_recording_statistics_devicemanager',
        defaultMessage: 'Device Manager'
    },
    property_runtime_runslocal: {
        id: 'property_runtime_runslocal',
        defaultMessage: 'Runs Locally'
    },
    property_runtime_memoryUsage: {
        id: 'property_runtime_memoryUsage',
        defaultMessage: 'Memory Usage (MB)'
    },
    property_runtime_diskUsage: {
        id: 'property_runtime_diskUsage',
        defaultMessage: 'Disk Usage (GB)'
    },
    property_runtime_cpuUsage: {
        id: 'property_runtime_cpuUsage',
        defaultMessage: 'CPU Usage (%)'
    },
    property_timeprofile_profile: {
        id: 'property_timeprofile_profile',
        defaultMessage: 'Time Profile'
    },
    property_device_firmware_upgrade: {
        id: 'property_device_firmware_upgrade',
        defaultMessage: 'Firmware Update Support'
    },
    property_device_password_modify: {
        id: 'property_device_password_modify',
        defaultMessage: 'Change Camera Password Support'
    },
    property_device_description: {
        id: 'property_device_description',
        defaultMessage: 'Description'
    },
    property_device_videoserver_id: {
        id: 'property_device_videoserver_id',
        defaultMessage: 'Video Server Id'
    },
    property_device_videoserver_description: {
        id: 'property_device_videoserver_description',
        defaultMessage: 'Video Server Description'
    },
    property_administrative_rights_video: {
        id: 'property_administrative_rights_video',
        defaultMessage: 'Cameras'
    },
    property_administrative_rights_other_hardware: {
        id: 'property_administrative_rights_other_hardware',
        defaultMessage: 'Other Hardware'
    },
    property_administrative_event_interfaces: {
        id: 'property_administrative_event_interfaces',
        defaultMessage: 'Event Interfaces'
    },
    property_administrative_user: {
        id: 'property_administrative_user',
        defaultMessage: 'User'
    },
    property_administrative_time_pattern: {
        id: 'property_administrative_time_pattern',
        defaultMessage: 'Time management'
    },
    property_administrative_company_calendar: {
        id: 'property_administrative_company_calendar',
        defaultMessage: 'Company calendars'
    },
    property_administrative_rights_alarm: {
        id: 'property_administrative_rights_alarm',
        defaultMessage: 'Alarms'
    },
    property_administrative_rights_layer: {
        id: 'property_administrative_rights_layer',
        defaultMessage: 'Layers'
    },
    property_administrative_rights_map: {
        id: 'property_administrative_rights_map',
        defaultMessage: 'Maps'
    },
    property_administrative_rights_button: {
        id: 'property_administrative_rights_button',
        defaultMessage: 'Buttons'
    },
    property_administrative_rights_patrol: {
        id: 'property_administrative_rights_patrol',
        defaultMessage: 'Patrols'
    },
    property_administrative_rights_sequence: {
        id: 'property_administrative_rights_sequence',
        defaultMessage: 'Sequences'
    },
    property_administrative_rights_videowall: {
        id: 'property_administrative_rights_videowall',
        defaultMessage: 'Video walls'
    },
    property_administrative_rights_LPR: {
        id: 'property_administrative_rights_LPR',
        defaultMessage: 'License plate groups'
    },
    property_administrative_rights_server: {
        id: 'property_administrative_rights_server',
        defaultMessage: 'Server'
    },
    property_administrative_rights_system: {
        id: 'property_administrative_rights_system',
        defaultMessage: 'System'
    },
    property_administrative_rights_website: {
        id: 'property_administrative_rights_website',
        defaultMessage: 'Web pages'
    },
    property_administrative_access_control: {
        id: 'property_administrative_access_control',
        defaultMessage: 'Access Control'
    },
    property_runtime_host: {
        id: 'property_runtime_host',
        defaultMessage: 'Host'
    },
    property_runtime_serviceName: {
        id: 'property_runtime_serviceName',
        defaultMessage: 'Service Name'
    },
    property_cardholder_base_card_number: {
        id: 'property_cardholder_base_card_number',
        defaultMessage: 'Base Card Number'
    },
    property_cardholder_employee_number: {
        id: 'property_cardholder_employee_number',
        defaultMessage: 'Employee Number'
    },
    property_door_state: {
        id: 'property_door_state',
        defaultMessage: 'Status'
    },
    site_user_details_tab_details: {
        id: 'site_user_details_tab_details',
        defaultMessage: 'Details'
    },
    site_user_details_title: {
        id: 'site_user_details_title',
        defaultMessage: 'Below you can change the user parameters according to your preferences.'
    },
    site_user_details_user_name: {
        id: 'site_user_details_user_name',
        defaultMessage: 'Username:'
    },
    site_user_details_site: {
        id: 'site_user_details_site',
        defaultMessage: 'Site:'
    },
    site_user_details_label: {
        id: 'site_user_details_label',
        defaultMessage: 'Label:'
    },
    site_user_details_update_button: {
        id: 'site_user_details_update_button',
        defaultMessage: 'Update Details'
    },
    site_user_details_user_update_success_message: {
        id: 'site_user_details_user_update_success_message',
        defaultMessage: 'User successfully updated'
    },
    site_user_details_user_update_error_message: {
        id: 'site_user_details_user_update_error_message',
        defaultMessage: 'Failed to update user'
    },
    site_user_details_user_properties_update_success_message: {
        id: 'site_user_details_user_properties_update_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    site_user_details_user_properties_update_error_message: {
        id: 'site_user_details_user_properties_update_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    site_user_details_properties_title: {
        id: 'site_user_details_properties_title',
        defaultMessage: 'Below you can change the user properties according to your preferences.'
    },
    site_user_details_administrative_properties_title: {
        id: 'site_user_details_administrative_properties_title',
        defaultMessage: 'Only administrative rights from the branch where the entity is located at are considered here.'
    },
    siteService_details_tab_details: {
        id: 'siteService_details_tab_details',
        defaultMessage: 'Details'
    },
    siteService_details_title: {
        id: 'siteService_details_title',
        defaultMessage: 'Below you can change the service parameters according to your preferences.'
    },
    siteService_details_properties_title: {
        id: 'siteService_details_properties_title',
        defaultMessage: 'Below you can change the service properties according to your preferences.'
    },
    siteService_details_siteService_name: {
        id: 'siteService_details_siteService_name',
        defaultMessage: 'Name:'
    },
    siteService_details_siteService_site: {
        id: 'siteService_details_siteService_site',
        defaultMessage: 'Site:'
    },
    siteService_details_siteService_label: {
        id: 'siteService_details_siteService_label',
        defaultMessage: 'Label:'
    },
    siteService_details_update_button: {
        id: 'siteService_details_update_button',
        defaultMessage: 'Update Details'
    },
    siteService_details_update_success_message: {
        id: 'siteService_details_update_success_message',
        defaultMessage: 'Service successfully updated'
    },
    siteService_details_update_error_message: {
        id: 'siteService_details_update_error_message',
        defaultMessage: 'Failed to update service'
    },
    siteService_details_properties_update_error_message: {
        id: 'siteService_details_properties_update_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    siteService_details_properties_update_success_message: {
        id: 'siteService_details_properties_update_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    camera_details_tab_details: {
        id: 'camera_details_tab_details',
        defaultMessage: 'Details'
    },
    camera_details_title: {
        id: 'camera_details_title',
        defaultMessage: 'Below you can change the camera parameters according to your preferences.'
    },
    camera_details_properties_title: {
        id: 'camera_details_properties_title',
        defaultMessage: 'Below you can change the camera properties according to your preferences.'
    },
    camera_details_camera_name: {
        id: 'camera_details_camera_name',
        defaultMessage: 'Name:'
    },
    camera_details_camera_site: {
        id: 'camera_details_camera_site',
        defaultMessage: 'Site:'
    },
    camera_details_camera_label: {
        id: 'camera_details_camera_label',
        defaultMessage: 'Label:'
    },
    camera_details_update_button: {
        id: 'camera_details_update_button',
        defaultMessage: 'Update Details'
    },
    camera_details_update_success_message: {
        id: 'camera_details_update_success_message',
        defaultMessage: 'Camera successfully updated'
    },
    camera_details_update_error_message: {
        id: 'camera_details_update_error_message',
        defaultMessage: 'Failed to update camera'
    },
    camera_details_properties_update_error_message: {
        id: 'camera_details_properties_update_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    camera_details_properties_update_success_message: {
        id: 'camera_details_properties_update_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    properties_form_button_reset_all: {
        id: 'properties_form_button_reset_all',
        defaultMessage: 'Reset all'
    },
    properties_form_button_create_jobs: {
        id: 'properties_form_button_create_jobs',
        defaultMessage: 'Create \'Change Property\' Job(s)'
    },
    properties_form_note: {
        id: 'properties_form_note',
        defaultMessage: 'Selected changes will not be done immediately. They will be applied when the created jobs are processed. '
    },
    properties_form_button_reset: {
        id: 'properties_form_button_reset',
        defaultMessage: 'Reset'
    },
    site_jobs_status_done: {
        id: 'site_jobs_status_done',
        defaultMessage: 'Successful'
    },
    site_jobs_status_failed: {
        id: 'site_jobs_status_failed',
        defaultMessage: 'Failed'
    },
    site_jobs_status_to_do: {
        id: 'site_jobs_status_to_do',
        defaultMessage: 'To Do'
    },
    site_jobs_status_transmitted: {
        id: 'site_jobs_status_transmitted',
        defaultMessage: 'Transmitted'
    },
    site_jobs_status_canceled: {
        id: 'site_jobs_status_canceled',
        defaultMessage: 'Canceled'
    },
    site_jobs_table_head_name: {
        id: 'site_jobs_table_head_name',
        defaultMessage: 'Name'
    },
    site_jobs_table_head_state: {
        id: 'site_jobs_table_head_state',
        defaultMessage: 'State'
    },
    site_jobs_table_head_createdBy: {
        id: 'site_jobs_table_head_createdBy',
        defaultMessage: 'Created By'
    },
    site_jobs_table_head_created: {
        id: 'site_jobs_table_head_created',
        defaultMessage: 'Created'
    },
    site_jobs_table_head_updated: {
        id: 'site_jobs_table_head_updated',
        defaultMessage: 'Updated'
    },
    site_jobs_table_head_sites: {
        id: 'site_jobs_table_head_sites',
        defaultMessage: 'Sites'
    },
    site_jobs_table_head_about: {
        id: 'site_jobs_table_head_about',
        defaultMessage: 'About'
    },
    site_jobs_table_head_message: {
        id: 'site_jobs_table_head_message',
        defaultMessage: 'Message'
    },
    site_jobs_table_head_comment: {
        id: 'site_jobs_table_head_comment',
        defaultMessage: 'Comment'
    },
    site_jobs_table_group_jobstate_todo: {
        id: 'site_jobs_table_group_jobstate_todo',
        defaultMessage: 'To Do'
    },
    site_jobs_table_group_jobstate_transmitted: {
        id: 'site_jobs_table_group_jobstate_transmitted',
        defaultMessage: 'Transmitted'
    },
    site_jobs_table_group_jobstate_canceled: {
        id: 'site_jobs_table_group_jobstate_canceled',
        defaultMessage: 'Canceled'
    },
    site_jobs_table_group_jobstate_done: {
        id: 'site_jobs_table_group_jobstate_done',
        defaultMessage: 'Successful'
    },
    site_jobs_table_group_jobstate_failed: {
        id: 'site_jobs_table_group_jobstate_failed',
        defaultMessage: 'Failed'
    },
    site_jobs_table_toolbar_selected_count: {
        id: 'site_jobs_table_toolbar_selected_count',
        defaultMessage: '{count} selected'
    },
    site_jobs_table_toolbar_retry_job: {
        id: 'site_jobs_table_toolbar_retry_job',
        defaultMessage: 'Retry selected jobs'
    },
    site_jobs_table_toolbar_cancel_job: {
        id: 'site_jobs_table_toolbar_cancel_job',
        defaultMessage: 'Cancel selected jobs'
    },
    site_jobs_table_cancel_jobs_success: {
        id: 'site_jobs_table_cancel_jobs_success',
        defaultMessage: 'Succesfully canceled selected jobs'
    },
    site_jobs_table_cancel_jobs_error: {
        id: 'site_jobs_table_cancel_jobs_error',
        defaultMessage: 'Error in canceleling selected jobs'
    },
    site_jobs_table_retry_jobs_succes: {
        id: 'site_jobs_table_retry_jobs_succes',
        defaultMessage: 'Succesfully in retrying  jobs'
    },
    site_jobs_table_retry_jobs_error: {
        id: 'site_jobs_table_retry_jobs_error',
        defaultMessage: 'Error in retrying  jobs'
    },
    site_job_details_description: {
        id: 'site_job_details_description',
        defaultMessage: 'Description:'
    },
    site_job_details_comment: {
        id: 'site_job_details_comment',
        defaultMessage: 'Comment:'
    },
    site_job_details_type: {
        id: 'site_job_details_type',
        defaultMessage: 'Type:'
    },
    site_job_details_site: {
        id: 'site_job_details_site',
        defaultMessage: 'Site:'
    },
    site_job_details_createdBy: {
        id: 'site_job_details_createdBy',
        defaultMessage: 'Created By:'
    },
    site_job_details_created: {
        id: 'site_job_details_created',
        defaultMessage: 'Created:'
    },
    site_job_details_transmitted: {
        id: 'site_job_details_transmitted',
        defaultMessage: 'Transmitted:'
    },
    site_job_details_canceled: {
        id: 'site_job_details_canceled',
        defaultMessage: 'Canceled:'
    },
    site_job_details_failed: {
        id: 'site_job_details_failed',
        defaultMessage: 'Failed:'
    },
    site_job_details_message: {
        id: 'site_job_details_message',
        defaultMessage: 'Message:'
    },
    site_job_details_card_created: {
        id: 'site_job_details_card_created',
        defaultMessage: 'Created'
    },
    site_job_details_card_status: {
        id: 'site_job_details_card_status',
        defaultMessage: 'Status'
    },
    site_job_details_card_duration: {
        id: 'site_job_details_card_duration',
        defaultMessage: 'Duration: {duration}'
    },
    batch_job_details_title: {
        id: 'batch_job_details_title',
        defaultMessage: 'Batch Job'
    },
    batch_job_details_chart_legend_failed: {
        id: 'batch_job_details_chart_legend_failed',
        defaultMessage: 'Failed ({value})'
    },
    batch_job_details_chart_legend_done: {
        id: 'batch_job_details_chart_legend_done',
        defaultMessage: 'Successful ({value})'
    },
    batch_job_details_chart_legend_todo: {
        id: 'batch_job_details_chart_legend_todo',
        defaultMessage: 'To Do ({value})'
    },
    batch_job_details_chart_legend_transmitted: {
        id: 'batch_job_details_chart_legend_transmitted',
        defaultMessage: 'Transmitted ({value})'
    },
    batch_job_details_chart_legend_canceled: {
        id: 'batch_job_details_chart_legend_canceled',
        defaultMessage: 'Canceled ({value})'
    },
    batch_job_details_group_jobstate_todo: {
        id: 'batch_job_details_group_jobstate_todo',
        defaultMessage: 'To Do'
    },
    batch_job_details_group_jobstate_transmitted: {
        id: 'batch_job_details_group_jobstate_transmitted',
        defaultMessage: 'Transmitted'
    },
    batch_job_details_group_jobstate_canceled: {
        id: 'batch_job_details_group_jobstate_canceled',
        defaultMessage: 'Canceled'
    },
    batch_job_details_group_jobstate_done: {
        id: 'batch_job_details_group_jobstate_done',
        defaultMessage: 'Successful'
    },
    batch_job_details_group_jobstate_failed: {
        id: 'batch_job_details_group_jobstate_failed',
        defaultMessage: 'Failed'
    },
    job_configuration_comment: {
        id: 'job_configuration_comment',
        defaultMessage: 'Comment:'
    },
    job_configuration_comment_title: {
        id: 'job_configuration_comment_title',
        defaultMessage: 'Job Comment:'
    },
    create_umbrella_user_title: {
        id: 'create_umbrella_user_title',
        defaultMessage: 'Add New Umbrella User'
    },
    create_umbrella_user_subtitle: {
        id: 'create_umbrella_user_subtitle',
        defaultMessage: 'Add a new Umbrella user and select roles that user should belong to.'
    },
    create_umbrella_user_submit_button: {
        id: 'create_umbrella_user_submit_button',
        defaultMessage: 'Add Umbrella User'
    },
    create_umbrella_user_success_message: {
        id: 'create_umbrella_user_success_message',
        defaultMessage: 'Umbrella user successfully added'
    },
    create_umbrella_user_error_message: {
        id: 'create_umbrella_user_error_message',
        defaultMessage: 'Failed to add a new Umbrella user'
    },
    create_umbrella_user_error_unique_username: {
        id: 'create_umbrella_user_error_unique_username',
        defaultMessage: 'Username already exists!'
    },
    umbrella_user_form_label_username: {
        id: 'umbrella_user_form_label_username',
        defaultMessage: 'Username:'
    },
    umbrella_user_form_label_password: {
        id: 'umbrella_user_form_label_password',
        defaultMessage: 'Password:'
    },
    umbrella_user_form_label_roles: {
        id: 'umbrella_user_form_label_roles',
        defaultMessage: 'Roles:'
    },
    umbrella_user_form_placeholder_roles: {
        id: 'umbrella_user_form_placeholder_roles',
        defaultMessage: 'Select Roles'
    },
    umbrella_users_table_head_username: {
        id: 'umbrella_users_table_head_username',
        defaultMessage: 'Username'
    },
    umbrella_users_table_head_built_in: {
        id: 'umbrella_users_table_head_built_in',
        defaultMessage: 'Built In'
    },
    umbrella_users_table_head_active_directory: {
        id: 'umbrella_users_table_head_active_directory',
        defaultMessage: 'Active Directory'
    },
    umbrella_users_table_head_roles: {
        id: 'umbrella_users_table_head_roles',
        defaultMessage: 'Roles'
    },
    umbrella_users_table_head_last_login: {
        id: 'umbrella_users_table_head_last_login',
        defaultMessage: 'Last Login'
    },
    umbrella_users_add_user_button: {
        id: 'umbrella_users_add_user_button',
        defaultMessage: 'Add User'
    },
    umbrella_users_list_toolbar_delete_users_tooltip: {
        id: 'umbrella_users_list_toolbar_delete_users_tooltip',
        defaultMessage: 'Delete Users'
    },
    umbrella_users_list_toolbar_selected_users: {
        id: 'umbrella_users_list_toolbar_selected_users',
        defaultMessage: '{count} selected'
    },
    umbrella_users_list_delete_users_success: {
        id: 'umbrella_users_list_delete_users_success',
        defaultMessage: 'User/s successfully deleted'
    },
    umbrella_users_list_delete_users_error: {
        id: 'umbrella_users_list_delete_users_error',
        defaultMessage: 'Failed to remove users'
    },
    site_groups_table_head_site_group: {
        id: 'site_groups_table_head_site_group',
        defaultMessage: 'Group'
    },
    site_groups_table_head_sites: {
        id: 'site_groups_table_head_sites',
        defaultMessage: 'Sites'
    },
    site_groups_table_head_labels: {
        id: 'site_groups_table_head_labels',
        defaultMessage: 'Labels'
    },
    site_groups_table_head_site_labels: {
        id: 'site_groups_table_head_site_labels',
        defaultMessage: 'Site Labels'
    },
    site_groups_toolbar_delete_site_group_tooltip: {
        id: 'site_groups_toolbar_delete_site_group_tooltip',
        defaultMessage: 'Delete Site groups'
    },
    site_groups_toolbar_selected_site_groups: {
        id: 'site_groups_toolbar_selected_site_groups',
        defaultMessage: '{count} selected'
    },
    site_groups_toolbar_change_properties_tooltip: {
        id: 'site_groups_toolbar_change_properties_tooltip',
        defaultMessage: 'Change properties of selected groups'
    },
    site_groups_add_site_group_button: {
        id: 'site_groups_add_site_group_button',
        defaultMessage: 'Add group'
    },
    site_groups_list_toolbar_delete_site_groups_success: {
        id: 'site_groups_list_toolbar_delete_site_groups_success',
        defaultMessage: 'Jobs for deleting groups successfully created'
    },
    site_groups_list_toolbar_delete_site_groups_error: {
        id: 'site_groups_list_toolbar_delete_site_groups_error',
        defaultMessage: 'Failed to create jobs to delete groups users'
    },
    umbrella_roles_table_head_name: {
        id: 'umbrella_roles_table_head_name',
        defaultMessage: 'Name'
    },
    umbrella_roles_table_head_umbrella_admin: {
        id: 'umbrella_roles_table_head_umbrella_admin',
        defaultMessage: 'Umbrella Admin'
    },
    umbrella_roles_table_head_site_admin: {
        id: 'umbrella_roles_table_head_site_admin',
        defaultMessage: 'Site Admin'
    },
    umbrella_roles_table_head_built_in: {
        id: 'umbrella_roles_table_head_built_in',
        defaultMessage: 'Built In'
    },
    umbrella_roles_table_head_users: {
        id: 'umbrella_roles_table_head_users',
        defaultMessage: 'Users'
    },
    umbrella_roles_table_head_sites: {
        id: 'umbrella_roles_table_head_sites',
        defaultMessage: 'Sites'
    },
    umbrella_roles_table_head_active_directory_group: {
        id: 'umbrella_roles_table_head_active_directory_group',
        defaultMessage: 'Active Directory Group'
    },
    umbrella_roles_table_head_entity_type: {
        id: 'umbrella_roles_table_head_entity_type',
        defaultMessage: 'Entity type'
    },
    umbrella_roles_table_head_event_category: {
        id: 'umbrella_roles_table_head_event_category',
        defaultMessage: 'Event category'
    },
    umbrella_roles_table_head_event_type: {
        id: 'umbrella_roles_table_head_event_type',
        defaultMessage: 'Event type'
    },
    umbrella_roles_table_head_event_filter: {
        id: 'umbrella_roles_table_head_event_filter',
        defaultMessage: 'Filter'
    },
    umbrella_roles_table_head_event_email: {
        id: 'umbrella_roles_table_head_event_email',
        defaultMessage: 'E-Mail'
    },
    umbrella_roles_table_head_contains_filter: {
        id: 'umbrella_roles_table_head_contains_filter',
        defaultMessage: 'Contains filter'
    },
    umbrella_roles_table_head_send_email: {
        id: 'umbrella_roles_table_head_send_email',
        defaultMessage: 'E-Mail'
    },
    umbrella_roles_table_head_site_labels: {
        id: 'umbrella_roles_table_head_site_labels',
        defaultMessage: 'Site Labels'
    },
    umbrella_roles_list_toolbar_selected_roles: {
        id: 'umbrella_roles_list_toolbar_selected_roles',
        defaultMessage: '{count} selected'
    },
    umbrella_roles_list_toolbar_delete_roles_tooltip: {
        id: 'umbrella_roles_list_toolbar_delete_roles_tooltip',
        defaultMessage: 'Delete Umbrella Role'
    },
    umbrella_roles_list_button_add_role_tooltip: {
        id: 'umbrella_roles_list_button_add_role_tooltip',
        defaultMessage: 'Add Umbrella Role'
    },
    umbrella_roles_list_delete_roles_success: {
        id: 'umbrella_roles_list_delete_roles_success',
        defaultMessage: 'Role/s successfully deleted'
    },
    umbrella_roles_list_delete_roles_error: {
        id: 'umbrella_roles_list_delete_roles_error',
        defaultMessage: 'Failed to remove roles'
    },
    create_site_group_title: {
        id: 'create_site_group_title',
        defaultMessage: 'Add New Group'
    },
    create_site_group_subtitle: {
        id: 'create_site_group_subtitle',
        defaultMessage: 'Add a new group with the specified name for the selected Sites.'
    },
    create_site_group_input_site_group: {
        id: 'create_site_group_input_site_group',
        defaultMessage: 'Group:'
    },
    create_site_group_input_sites: {
        id: 'create_site_group_input_sites',
        defaultMessage: 'Sites:'
    },
    create_site_group_input_sites_placeholder: {
        id: 'create_site_group_input_sites_placeholder',
        defaultMessage: 'Select sites'
    },
    create_site_group_submit_button: {
        id: 'create_site_group_submit_button',
        defaultMessage: 'Create \'Add Group\' job(s)'
    },
    create_site_group_instructions: {
        id: 'create_site_group_instructions',
        defaultMessage: 'Adding group will not be done immediately. A job will be created and executed as soon as possible.'
    },
    create_site_groups_success_message: {
        id: 'create_site_groups_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    create_site_groups_error_message: {
        id: 'create_site_groups_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    create_umbrella_role_title: {
        id: 'create_umbrella_role_title',
        defaultMessage: 'Create New Umbrella Role'
    },
    create_umbrella_role_subtitle: {
        id: 'create_umbrella_role_subtitle',
        defaultMessage: 'Create a new role with the desired parameters.'
    },
    create_umbrella_role_submit_button: {
        id: 'create_umbrella_role_submit_button',
        defaultMessage: 'Create Umbrella Role'
    },
    create_umbrella_role_success_message: {
        id: 'create_umbrella_role_success_message',
        defaultMessage: 'Role successfully created'
    },
    create_umbrella_role_error_message: {
        id: 'create_umbrella_role_error_message',
        defaultMessage: 'Failed to create role'
    },
    umbrella_role_form_label_role_name: {
        id: 'umbrella_role_form_label_role_name',
        defaultMessage: 'Role Name:'
    },
    umbrella_role_form_label_umbrella_administrator: {
        id: 'umbrella_role_form_label_umbrella_administrator',
        defaultMessage: 'Umbrella Administrator:'
    },
    umbrella_role_form_label_site_administrator: {
        id: 'umbrella_role_form_label_site_administrator',
        defaultMessage: 'Site Administrator:'
    },
    umbrella_role_form_label_users: {
        id: 'umbrella_role_form_label_users',
        defaultMessage: 'Users:'
    },
    umbrella_role_form_placeholder_users: {
        id: 'umbrella_role_form_placeholder_users',
        defaultMessage: 'Select Users'
    },
    umbrella_role_form_label_sites: {
        id: 'umbrella_role_form_label_sites',
        defaultMessage: 'Sites:'
    },
    umbrella_role_form_label_site_labels: {
        id: 'umbrella_role_form_label_site_labels',
        defaultMessage: 'Site labels:'
    },
    umbrella_role_form_placeholder_sites: {
        id: 'umbrella_role_form_placeholder_sites',
        defaultMessage: 'Select Sites'
    },
    umbrella_role_form_placeholder_site_labels: {
        id: 'umbrella_role_form_placeholder_site_labels',
        defaultMessage: 'Site labels'
    },
    umbrella_role_form_label_active_directory: {
        id: 'umbrella_role_form_label_active_directory',
        defaultMessage: 'Active Directory Group:'
    },
    umbrella_role_form_placeholder_active_directory: {
        id: 'umbrella_role_form_placeholder_active_directory',
        defaultMessage: 'Select Active Directory Group'
    },
    umbrella_role_form_error_unique_role: {
        id: 'umbrella_role_form_error_unique_role',
        defaultMessage: 'Role already exists!'
    },
    umbrella_role_form_admin_rights_include_all_sites: {
        id: 'umbrella_role_form_admin_rights_include_all_sites',
        defaultMessage: 'Umbrella admin rights include all Sites'
    },
    umbrella_role_form_rights_reports_default: {
        id: 'umbrella_role_form_rights_reports_default',
        defaultMessage: 'Reports:'
    },
    umbrella_role_form_rights_reports_site_maintenance: {
        id: 'umbrella_role_form_rights_reports_site_maintenance',
        defaultMessage: 'Inventory Reports:'
    },
    umbrella_role_form_rights_reports_site_audit: {
        id: 'umbrella_role_form_rights_reports_site_audit',
        defaultMessage: 'Audit Reports:'
    },
    umbrella_role_form_rights_approve_gateway: {
        id: 'umbrella_role_form_rights_approve_gateway',
        defaultMessage: 'Approve Sites:'
    },
    umbrella_role_form_rights_deploy_gateway: {
        id: 'umbrella_role_form_rights_deploy_gateway',
        defaultMessage: 'Deploy Sites:'
    },
    umbrella_role_form_rights_delete_gateway: {
        id: 'umbrella_role_form_rights_delete_gateway',
        defaultMessage: 'Delete Sites:'
    },
    umbrella_role_form_rights_upload_firmware: {
        id: 'umbrella_role_form_rights_upload_firmware',
        defaultMessage: 'Upload Firmwares:'
    },
    umbrella_role_form_rights_restart_camera_and_sites: {
        id: 'umbrella_role_form_rights_restart_camera_and_sites',
        defaultMessage: 'Restart Cameras / Services:'
    },
    umbrella_role_form_rights_monitoring: {
        id: 'umbrella_role_form_rights_monitoring',
        defaultMessage: 'Monitoring:'
    },
    umbrella_role_form_rights_ignore: {
        id: 'umbrella_role_form_rights_ignore',
        defaultMessage: 'Ignore:'
    },
    umbrella_role_details_title: {
        id: 'umbrella_role_details_title',
        defaultMessage: 'Umbrella Role Details'
    },
    umbrella_role_details_title_built_in_role: {
        id: 'umbrella_role_details_title_built_in_role',
        defaultMessage: 'Umbrella Role Details'
    },
    umbrella_role_details_subtitle: {
        id: 'umbrella_role_details_subtitle',
        defaultMessage: 'Details for Umbrella Role {role}'
    },
    umbrella_role_details_subtitle_built_in_role: {
        id: 'umbrella_role_details_subtitle_built_in_role',
        defaultMessage: 'Details for Umbrella Role {role} - can\'t be edited'
    },
    umbrella_role_details_update_success_message: {
        id: 'umbrella_role_details_update_success_message',
        defaultMessage: 'Role successfully updated'
    },
    umbrella_role_details_update_error_message: {
        id: 'umbrella_role_details_update_error_message',
        defaultMessage: 'Failed to update role'
    },
    site_group_details_update_success_message: {
        id: 'site_group_details_update_success_message',
        defaultMessage: 'Group successfully updated'
    },
    site_group_details_update_error_message: {
        id: 'site_group_details_update_error_message',
        defaultMessage: 'Failed to update group'
    },
    site_group_details_title: {
        id: 'site_group_details_title',
        defaultMessage: 'Below you can change the groups parameters according to your preferences.'
    },
    site_group_details_site_group: {
        id: 'site_group_details_site_group',
        defaultMessage: 'Group:'
    },
    site_group_details_site: {
        id: 'site_group_details_site',
        defaultMessage: 'Site:'
    },
    site_group_details_labels: {
        id: 'site_group_details_labels',
        defaultMessage: 'Labels:'
    },
    site_group_details_update_button: {
        id: 'site_group_details_update_button',
        defaultMessage: 'Update Details'
    },
    site_group_details_tab_details: {
        id: 'site_group_details_tab_details',
        defaultMessage: 'Details'
    },
    site_group_details_properties_title: {
        id: 'site_group_details_properties_title',
        defaultMessage: 'Below you can change the groups properties according to your preferences.'
    },
    site_group_details_properties_update_success_message: {
        id: 'site_group_details_properties_update_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    site_group_details_properties_update_error_message: {
        id: 'site_group_details_properties_update_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    umbrella_user_details_title: {
        id: 'umbrella_user_details_title',
        defaultMessage: '{name}\'s Details'
    },
    umbrella_user_details_title_built_in_user: {
        id: 'umbrella_user_details_title_built_in_user',
        defaultMessage: '{name}\'s Details'
    },
    umbrella_user_details_subtitle: {
        id: 'umbrella_user_details_subtitle',
        defaultMessage: 'Below you can edit {name}\'s details'
    },
    umbrella_user_details_subtitle_built_in_user: {
        id: 'umbrella_user_details_subtitle_built_in_user',
        defaultMessage: '{name}\'s details can\'t be edited '
    },
    umbrella_user_details_label_username: {
        id: 'umbrella_user_details_label_username',
        defaultMessage: 'Username:'
    },
    umbrella_user_details_label_roles: {
        id: 'umbrella_user_details_label_roles',
        defaultMessage: 'Roles:'
    },
    umbrella_user_details_placeholder_roles: {
        id: 'umbrella_user_details_placeholder_roles',
        defaultMessage: 'Select roles'
    },
    umbrella_user_details_update_button: {
        id: 'umbrella_user_details_update_button',
        defaultMessage: 'Update Details'
    },
    umbrella_user_details_update_success_message: {
        id: 'umbrella_user_details_update_success_message',
        defaultMessage: 'User successfully updated'
    },
    umbrella_user_details_update_error_message: {
        id: 'umbrella_user_details_update_error_message',
        defaultMessage: 'Failed to update user'
    },
    password_requirements_require_digit: {
        id: 'password_requirements_require_digit',
        defaultMessage: 'Requires number'
    },
    password_requirements_required_length: {
        id: 'password_requirements_required_length',
        defaultMessage: 'At least {lengthNumber} characters long'
    },
    password_requirements_require_non_alphanumeric: {
        id: 'password_requirements_require_non_alphanumeric',
        defaultMessage: 'Requires non alphanumeric character'
    },
    password_requirements_require_uppercase: {
        id: 'password_requirements_require_uppercase',
        defaultMessage: 'Requires uppercase letter'
    },
    password_requirements_require_lowercase: {
        id: 'password_requirements_require_lowercase',
        defaultMessage: 'Requires lowercase letter'
    },
    password_requirements_required_unique_characters: {
        id: 'password_requirements_required_unique_characters',
        defaultMessage: 'Requires {uniqueNumber} unique characters'
    },
    password_requirements_title: {
        id: 'password_requirements_title',
        defaultMessage: 'Password Requirements'
    },
    change_properties_multi_no_common_properties: {
        id: 'change_properties_multi_no_common_properties',
        defaultMessage: 'No common properties found'
    },
    change_properties_multi_no_value_placeholder: {
        id: 'change_properties_multi_no_value_placeholder',
        defaultMessage: 'Enter value...'
    },
    change_properties_multi_success_message: {
        id: 'change_properties_multi_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    change_properties_multi_error_message: {
        id: 'change_properties_multi_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    change_properties_multiple_users_title: {
        id: 'change_properties_multiple_users_title',
        defaultMessage: 'Change Properties of Selected Users'
    },
    change_properties_multiple_users_subtitle: {
        id: 'change_properties_multiple_users_subtitle',
        defaultMessage: 'Below you can change the user properties according to your preferences.'
    },
    change_properties_multiple_site_groups_title: {
        id: 'change_properties_multiple_site_groups_title',
        defaultMessage: 'Change Properties of Selected Groups'
    },
    change_properties_multiple_site_groups_subtitle: {
        id: 'change_properties_multiple_site_groups_subtitle',
        defaultMessage: 'Below you can change the group properties according to your preferences.'
    },
    change_properties_multiple_cameras_title: {
        id: 'change_properties_multiple_cameras_title',
        defaultMessage: 'Change Properties of Selected Cameras'
    },
    change_properties_multiple_cameras_subtitle: {
        id: 'change_properties_multiple_cameras_subtitle',
        defaultMessage: 'Below you can change the Camera properties according to your preferences.'
    },
    change_properties_multiple_siteServices_title: {
        id: 'change_properties_multiple_siteServices_title',
        defaultMessage: 'Change Properties of Selected services'
    },
    change_properties_multiple_siteServices_subtitle: {
        id: 'change_properties_multiple_siteServices_subtitle',
        defaultMessage: 'Below you can change the services properties according to your preferences.'
    },
    change_properties_multiple_access_control_cardholders_title: {
        id: 'change_properties_multiple_access_control_cardholders_title',
        defaultMessage: 'Change Properties of Selected Card Holders'
    },
    change_properties_multiple_access_control_cardholders_subtitle: {
        id: 'change_properties_multiple_access_control_cardholders_subtitle',
        defaultMessage: 'Below you can change the group properties according to your preferences.'
    },
    change_properties_multiple_access_control_doors_title: {
        id: 'change_properties_multiple_access_control_doors_title',
        defaultMessage: 'Change Properties of Selected Doors'
    },
    change_properties_multiple_access_control_doors_subtitle: {
        id: 'change_properties_multiple_access_control_doors_subtitle',
        defaultMessage: 'Below you can change the group properties according to your preferences.'
    },
    change_password_description: {
        id: 'change_password_description',
        defaultMessage: 'Please enter a new password.'
    },
    change_password_input_current_password_placeholder: {
        id: 'change_password_input_current_password_placeholder',
        defaultMessage: 'Current Password'
    },
    change_password_input_new_password_placeholder: {
        id: 'change_password_input_new_password_placeholder',
        defaultMessage: 'New Password'
    },
    change_password_submit_button: {
        id: 'change_password_submit_button',
        defaultMessage: 'Save'
    },
    change_password_single_error_message: {
        id: 'change_password_single_error_message',
        defaultMessage: 'Failed to change password'
    },
    license_warning_unlicensed_cameras: {
        id: 'license_warning_unlicensed_cameras',
        defaultMessage: '{count} unlicensed cameras detected in the system.'
    },
    license_warning_expiration_date: {
        id: 'license_warning_expiration_date',
        defaultMessage: 'Your license expires in {days} days! Please renew your license.'
    },
    license_overview_main_title: {
        id: 'license_overview_main_title',
        defaultMessage: 'License Management'
    },
    license_overview_sub_title: {
        id: 'license_overview_sub_title',
        defaultMessage: 'An overview of the Umbrella license.'
    },
    license_overview_unlicensed_cameras: {
        id: 'license_overview_unlicensed_cameras',
        defaultMessage: 'Unlicensed cameras'
    },
    license_overview_expiration_date: {
        id: 'license_overview_expiration_date',
        defaultMessage: 'Expiration date'
    },
    licenses_chart_in_use_total: {
        id: 'licenses_chart_in_use_total',
        defaultMessage: 'Total'
    },
    licenses_chart_heading: {
        id: 'licenses_chart_heading',
        defaultMessage: 'Licenses'
    },
    licenses_chart_licensed_cameras: {
        id: 'licenses_chart_licensed_cameras',
        defaultMessage: 'In use'
    },
    licenses_chart_unlicensed_cameras: {
        id: 'licenses_chart_unlicensed_cameras',
        defaultMessage: 'Unused'
    },
    license_modules_title: {
        id: 'license_modules_title',
        defaultMessage: 'Licensed modules'
    },
    license_modules_configuration: {
        id: 'license_modules_configuration',
        defaultMessage: 'Site Administration'
    },
    license_modules_monitoring: {
        id: 'license_modules_monitoring',
        defaultMessage: 'Monitoring'
    },
    license_modules_reporting: {
        id: 'license_modules_reporting',
        defaultMessage: 'Reports'
    },
    license_machine_code_label: {
        id: 'license_machine_code_label',
        defaultMessage: 'Machine code:'
    },
    license_apply_new_license_label: {
        id: 'license_apply_new_license_label',
        defaultMessage: 'Apply license key:*'
    },
    license_apply_new_license_placeholder: {
        id: 'license_apply_new_license_placeholder',
        defaultMessage: 'Enter license key'
    },
    license_apply_new_license_success_message: {
        id: 'license_apply_new_license_success_message',
        defaultMessage: 'License successfully updated'
    },
    license_apply_new_license_error_message: {
        id: 'license_apply_new_license_error_message',
        defaultMessage: 'Failed to update license'
    },
    license_apply_new_license_info: {
        id: 'license_apply_new_license_info',
        defaultMessage: '* Update the license key will restart the Umbrella Core immediately'
    },
    license_modules_situatorExclusive: {
        id: 'license_modules_situatorExclusive',
        defaultMessage: 'Situator'
    },
    license_modules_webapi: {
        id: 'license_modules_webapi',
        defaultMessage: 'Web API'
    },
    site_license_update_toolbar_update_licenses: {
        id: 'site_license_update_toolbar_update_licenses',
        defaultMessage: 'Update Licenses'
    },
    site_license_update_toolbar_export_csv: {
        id: 'site_license_update_toolbar_export_csv',
        defaultMessage: 'Export in CSV'
    },
    site_license_update_upload_file_title: {
        id: 'site_license_update_upload_file_title',
        defaultMessage: 'Update Licenses'
    },
    site_license_update_upload_file_subtitle: {
        id: 'site_license_update_upload_file_subtitle',
        defaultMessage: 'Upload your file or simply drag & drop file below.'
    },
    site_license_update_upload_file_box_title: {
        id: 'site_license_update_upload_file_box_title',
        defaultMessage: 'Upload or Drag & Drop File'
    },
    site_license_update_upload_file_ext_restriction: {
        id: 'site_license_update_upload_file_ext_restriction',
        defaultMessage: 'You can upload only zip or key file.'
    },
    site_license_update_upload_file_check_error: {
        id: 'site_license_update_upload_file_check_error',
        defaultMessage: 'Invalid license file provided'
    },
    site_license_update_review_subtitle: {
        id: 'site_license_update_review_subtitle',
        defaultMessage: 'Below you can find list of sites where the licenses will be applied.'
    },
    site_license_update_review_submit: {
        id: 'site_license_update_review_submit',
        defaultMessage: 'Create Job(s)'
    },
    site_license_update_review_cancel: {
        id: 'site_license_update_review_cancel',
        defaultMessage: 'Cancel'
    },
    site_license_update_review_job_info: {
        id: 'site_license_update_review_job_info',
        defaultMessage: 'Selected changes will not be done immediately. First you need to create jobs.'
    },
    site_license_update_success_message: {
        id: 'site_license_update_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    site_license_update_error_message: {
        id: 'site_license_update_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    site_license_step_one: {
        id: 'site_license_step_one',
        defaultMessage: 'Upload File'
    },
    site_license_step_two: {
        id: 'site_license_step_two',
        defaultMessage: 'Confirm Update'
    },
    quick_action_menu_add_site_user: {
        id: 'quick_action_menu_add_site_user',
        defaultMessage: 'Add User'
    },
    quick_action_menu_add_site_group: {
        id: 'quick_action_menu_add_site_group',
        defaultMessage: 'Add Group'
    },
    site_event_type_status_changed: {
        id: 'site_event_type_status_changed',
        defaultMessage: 'Status Changed'
    },
    site_event_type_system_message: {
        id: 'site_event_type_system_message',
        defaultMessage: 'System Message'
    },
    site_event_entity_type_camera: {
        id: 'site_event_entity_type_camera',
        defaultMessage: 'Camera'
    },
    site_event_entity_type_runtime: {
        id: 'site_event_entity_type_runtime',
        defaultMessage: 'Service'
    },
    site_event_entity_type_user: {
        id: 'site_event_entity_type_user',
        defaultMessage: 'User'
    },
    site_event_entity_type_group: {
        id: 'site_event_entity_type_group',
        defaultMessage: 'Group'
    },
    site_event_entity_type_access_control_cardholder: {
        id: 'site_event_entity_type_access_control_cardholder',
        defaultMessage: 'Card Holder'
    },
    site_event_entity_type_access_control_door: {
        id: 'site_event_entity_type_access_control_door',
        defaultMessage: 'Door'
    },
    site_event_entity_type_unknown: {
        id: 'site_event_entity_type_unknown',
        defaultMessage: 'Unknown'
    },
    site_event_entity_type_site: {
        id: 'site_event_entity_type_site',
        defaultMessage: 'Site'
    },
    site_event_entity_type_system: {
        id: 'site_event_entity_type_system',
        defaultMessage: 'System'
    },
    site_event_category_info: {
        id: 'site_event_category_info',
        defaultMessage: 'Info'
    },
    site_event_category_error: {
        id: 'site_event_category_error',
        defaultMessage: 'Error'
    },
    site_event_category_warning: {
        id: 'site_event_category_warning',
        defaultMessage: 'Warning'
    },
    site_event_category_unknown: {
        id: 'site_event_category_unknown',
        defaultMessage: 'Unknown'
    },
    advanced_job_configuration_title: {
        id: 'advanced_job_configuration_title',
        defaultMessage: 'Advanced Job Configuration:'
    },
    advanced_job_configuration_execution_datetime: {
        id: 'advanced_job_configuration_execution_datetime',
        defaultMessage: 'Execution (Site timezone):'
    },
    advanced_job_configuration_undo_datetime: {
        id: 'advanced_job_configuration_undo_datetime',
        defaultMessage: 'Undo:'
    },
    advanced_job_configuration_retry: {
        id: 'advanced_job_configuration_retry',
        defaultMessage: 'Retries:'
    },
    advanced_job_configuration_datepicker_ok: {
        id: 'advanced_job_configuration_datepicker_ok',
        defaultMessage: 'OK'
    },
    advanced_job_configuration_datepicker_cancel: {
        id: 'advanced_job_configuration_datepicker_cancel',
        defaultMessage: 'CANCEL'
    },
    grid_layout_save: {
        id: 'grid_layout_save',
        defaultMessage: 'Save layout'
    },
    grid_layout_restore: {
        id: 'grid_layout_restore',
        defaultMessage: 'Restore default'
    },
    grid_layout_save_success: {
        id: 'grid_layout_save_success',
        defaultMessage: 'Layout saved'
    },
    grid_layout_restore_success: {
        id: 'grid_layout_restore_success',
        defaultMessage: 'Layout restored'
    },
    grid_layout_save_error: {
        id: 'grid_layout_save_error',
        defaultMessage: 'Error in saving layout'
    },
    grid_layout_restore_error: {
        id: 'grid_layout_restore_error',
        defaultMessage: 'Error in restoring layout'
    },
    site_events_table_head_event_name: {
        id: 'site_events_table_head_event_name',
        defaultMessage: 'Event Name:'
    },
    site_events_table_head_site_name: {
        id: 'site_events_table_head_site_name',
        defaultMessage: 'Site Name:'
    },
    site_events_table_head_labels: {
        id: 'site_events_table_head_labels',
        defaultMessage: 'Labels'
    },
    site_events_table_head_site_labels: {
        id: 'site_events_table_head_site_labels',
        defaultMessage: 'Site Labels'
    },
    site_events_table_head_event_type: {
        id: 'site_events_table_head_event_type',
        defaultMessage: 'Event Type'
    },
    site_events_table_head_entity_type: {
        id: 'site_events_table_head_entity_type',
        defaultMessage: 'Entity Type'
    },
    site_events_table_head_event_category: {
        id: 'site_events_table_head_event_category',
        defaultMessage: 'Event Category'
    },
    site_events_table_head_issue_resolved: {
        id: 'site_events_table_head_issue_resolved',
        defaultMessage: 'Issue resolved'
    },
    site_events_table_head_timestamp: {
        id: 'site_events_table_head_timestamp',
        defaultMessage: 'Timestamp'
    },
    site_events_table_head_description: {
        id: 'site_events_table_head_description',
        defaultMessage: 'Description'
    },
    site_events_details_title: {
        id: 'site_events_details_title',
        defaultMessage: '{name}\'s Details'
    },
    site_events_details_subtitle: {
        id: 'site_events_details_subtitle',
        defaultMessage: 'Below you can change event parameters according to your preferences'
    },
    site_events_details_event_type: {
        id: 'site_events_details_event_type',
        defaultMessage: 'Event Type:'
    },
    site_events_details_entity_type: {
        id: 'site_events_details_entity_type',
        defaultMessage: 'Entity Type:'
    },
    site_events_details_event_category: {
        id: 'site_events_details_event_category',
        defaultMessage: 'Event Category:'
    },
    site_events_details_issue_resolved: {
        id: 'site_events_details_issue_resolved',
        defaultMessage: 'Issue resolved:'
    },
    site_events_details_timestamp: {
        id: 'site_events_details_timestamp',
        defaultMessage: 'Timestamp:'
    },
    site_events_details_description: {
        id: 'site_events_details_description',
        defaultMessage: 'Description:'
    },
    site_events_details_details_tab: {
        id: 'site_events_details_details_tab',
        defaultMessage: 'Details'
    },
    site_event_details_updated_success_message: {
        id: 'site_event_details_updated_success_message',
        defaultMessage: 'Successfully updated'
    },
    site_event_details_updated_error_message: {
        id: 'site_event_details_updated_error_message',
        defaultMessage: 'Error'
    },
    site_event_details_update_button: {
        id: 'site_event_details_update_button',
        defaultMessage: 'Update Event'
    },
    umbrella_core_from_date: {
        id: 'umbrella_core_from_date',
        defaultMessage: 'From:'
    },
    umbrella_core_to_date: {
        id: 'umbrella_core_to_date',
        defaultMessage: 'To:'
    },
    umbrella_core_last_hour: {
        id: 'umbrella_core_last_hour',
        defaultMessage: 'Last Hour'
    },
    umbrella_core_last_day: {
        id: 'umbrella_core_last_day',
        defaultMessage: 'Last Day'
    },
    umbrella_core_last_week: {
        id: 'umbrella_core_last_week',
        defaultMessage: 'Last Week'
    },
    umbrella_core_cpu_usage: {
        id: 'umbrella_core_cpu_usage',
        defaultMessage: 'CPU Usage (%)'
    },
    umbrella_core_memory_usage: {
        id: 'umbrella_core_memory_usage',
        defaultMessage: 'Memory Usage (MB)'
    },
    umbrella_core_request_count: {
        id: 'umbrella_core_request_count',
        defaultMessage: 'Request Count'
    },
    umbrella_core_request_duration: {
        id: 'umbrella_core_request_duration',
        defaultMessage: 'Request Duration (ms)'
    },
    umbrella_core_no_data_to_show: {
        id: 'umbrella_core_no_data_to_show',
        defaultMessage: 'No data to show'
    },
    email_configuration_title: {
        id: 'email_configuration_title',
        defaultMessage: 'E-Mail configuration'
    },
    email_configuration_description: {
        id: 'email_configuration_description',
        defaultMessage: 'Configure communication with the SMTP-Server and send a Test E-mail to validate settings.'
    },
    email_configuration_smtp_address: {
        id: 'email_configuration_smtp_address',
        defaultMessage: 'SMTP Host'
    },
    email_configuration_smtp_port: {
        id: 'email_configuration_smtp_port',
        defaultMessage: 'SMTP Port'
    },
    email_configuration_username: {
        id: 'email_configuration_username',
        defaultMessage: 'Username'
    },
    email_configuration_password: {
        id: 'email_configuration_password',
        defaultMessage: 'Password'
    },
    email_configuration_sender_email: {
        id: 'email_configuration_sender_email',
        defaultMessage: 'Sender E-mail'
    },
    email_configuration_encryption_method: {
        id: 'email_configuration_encryption_method',
        defaultMessage: 'Encryption method'
    },
    email_configuration_subject: {
        id: 'email_configuration_subject',
        defaultMessage: 'Subject'
    },
    email_configuration_test: {
        id: 'email_configuration_test',
        defaultMessage: 'Test'
    },
    email_configuration_encryption_null: {
        id: 'email_configuration_encryption_null',
        defaultMessage: 'No encryption'
    },
    email_configuration_encryption_ssl: {
        id: 'email_configuration_encryption_ssl',
        defaultMessage: 'SSL/TLS'
    },
    email_configuration_submit: {
        id: 'email_configuration_submit',
        defaultMessage: 'Update'
    },
    email_configuration_reset_fields: {
        id: 'email_configuration_reset_fields',
        defaultMessage: 'Reset all'
    },
    email_configuration_update_success: {
        id: 'email_configuration_update_success',
        defaultMessage: 'E-Mail settings updated successfully'
    },
    email_configuration_update_error: {
        id: 'email_configuration_update_error',
        defaultMessage: 'Error in updating E-Mail settings'
    },
    email_configuration_test_success: {
        id: 'email_configuration_test_success',
        defaultMessage: 'Test E-Mail successful'
    },
    email_configuration_test_error: {
        id: 'email_configuration_test_error',
        defaultMessage: 'Test E-Mail failed'
    },
    email_configuration_test_receiver_title: {
        id: 'email_configuration_test_receiver_title',
        defaultMessage: 'Test Receiver'
    },
    email_configuration_test_receiver_label: {
        id: 'email_configuration_test_receiver_label',
        defaultMessage: 'Test receiver'
    },
    email_configuration_password_checkbox: {
        id: 'email_configuration_password_checkbox',
        defaultMessage: 'Set Password'
    },
    email_configuration_host_error: {
        id: 'email_configuration_host_error',
        defaultMessage: 'Host must no be empty'
    },
    email_configuration_port_error: {
        id: 'email_configuration_port_error',
        defaultMessage: 'Port must not be empty and be in range 1 - 65535'
    },
    email_configuration_username_error: {
        id: 'email_configuration_username_error',
        defaultMessage: 'Username must not be empty'
    },
    email_configuration_password_error: {
        id: 'email_configuration_password_error',
        defaultMessage: 'Password must not be empty'
    },
    email_configuration_sender_email_error: {
        id: 'email_configuration_sender_email_error',
        defaultMessage: 'Sender E-Mail must not be empty'
    },
    email_configuration_encryption_method_error: {
        id: 'email_configuration_encryption_method_error',
        defaultMessage: 'Encryption method must not be empty'
    },
    email_configuration_subject_error: {
        id: 'email_configuration_subject_error',
        defaultMessage: 'Subject must not be empty'
    },
    email_configuration_test_validation_error: {
        id: 'email_configuration_test_validation_error',
        defaultMessage: 'Invalid E-Mail address'
    },
    umbrella_role_form_site_events_notifications: {
        id: 'umbrella_role_form_site_events_notifications',
        defaultMessage: 'Events Notifications:'
    },
    umbrella_role_form_site_event_categories: {
        id: 'umbrella_role_form_site_event_categories',
        defaultMessage: 'Event Categories:'
    },
    umbrella_role_form_placeholder_site_event_categories: {
        id: 'umbrella_role_form_placeholder_site_event_categories',
        defaultMessage: 'Select Event Categories'
    },
    umbrella_role_form_site_event_types: {
        id: 'umbrella_role_form_site_event_types',
        defaultMessage: 'Event Types:'
    },
    umbrella_role_form_placeholder_site_event_types: {
        id: 'umbrella_role_form_placeholder_site_event_types',
        defaultMessage: 'Select Event Types'
    },
    umbrella_role_form_site_entity_types: {
        id: 'umbrella_role_form_site_entity_types',
        defaultMessage: 'Entity Types:'
    },
    umbrella_role_form_placeholder_site_entity_types: {
        id: 'umbrella_role_form_placeholder_site_entity_types',
        defaultMessage: 'Select Entity Types'
    },
    umbrella_role_form_contains_filter: {
        id: 'umbrella_role_form_contains_filter',
        defaultMessage: 'Contains Filter:'
    },
    umbrella_role_form_placeholder_contains_filter: {
        id: 'umbrella_role_form_placeholder_contains_filter',
        defaultMessage: 'Select filter'
    },
    umbrella_role_form_tooltip_contains_filter: {
        id: 'umbrella_role_form_tooltip_contains_filter',
        defaultMessage: 'Use this to filter Description or Source with case invariant tags. If you enter multiple tags they are treated as OR.'
    },
    umbrella_role_form_send_email: {
        id: 'umbrella_role_form_send_email',
        defaultMessage: 'Send E-Mail:'
    },
    umbrella_role_form_send_email_to_ad_group: {
        id: 'umbrella_role_form_send_email_to_ad_group',
        defaultMessage: 'Send E-Mails to AD Group:'
    },
    umbrella_no_notifications: {
        id: 'umbrella_no_notifications',
        defaultMessage: 'No notifications to show'
    },
    umbrella_notification_table_column_type: {
        id: 'umbrella_notification_table_column_type',
        defaultMessage: 'Type'
    },
    umbrella_notification_table_column_source: {
        id: 'umbrella_notification_table_column_source',
        defaultMessage: 'Source'
    },
    umbrella_notification_table_column_site: {
        id: 'umbrella_notification_table_column_site',
        defaultMessage: 'Site'
    },
    umbrella_notification_table_column_timestamp: {
        id: 'umbrella_notification_table_column_timestamp',
        defaultMessage: 'Timestamp'
    },
    umbrella_notification_table_column_resolved: {
        id: 'umbrella_notification_table_column_resolved',
        defaultMessage: 'Resolved'
    },
    umbrella_notification_table_column_category: {
        id: 'umbrella_notification_table_column_category',
        defaultMessage: 'Category'
    },
    umbrella_notification_table_clear_notifications: {
        id: 'umbrella_notification_table_clear_notifications',
        defaultMessage: 'Clear Notifications'
    },
    umbrella_notification_table_column_description: {
        id: 'umbrella_notification_table_column_description',
        defaultMessage: 'Description'
    },
    umbrella_notification_table_site_events: {
        id: 'umbrella_notification_table_site_events',
        defaultMessage: 'Events'
    },
    umbrella_dashboard_new_layout: {
        id: 'umbrella_dashboard_new_layout',
        defaultMessage: 'New Layout'
    },
    umbrella_dashboard_save_layout: {
        id: 'umbrella_dashboard_save_layout',
        defaultMessage: 'Save Layout'
    },
    umbrella_dashboard_delete_layout: {
        id: 'umbrella_dashboard_delete_layout',
        defaultMessage: 'Delete Layout'
    },
    umbrella_dashboard_show_default: {
        id: 'umbrella_dashboard_show_default',
        defaultMessage: 'Show Default'
    },
    umbrella_dashboard_choose_layout: {
        id: 'umbrella_dashboard_choose_layout',
        defaultMessage: 'Choose Layout'
    },
    umbrella_dashboard_choose_layout_description: {
        id: 'umbrella_dashboard_choose_layout_description',
        defaultMessage: 'Customize your Dashboard by selecting your prefered layout.'
    },
    umbrella_dashboard_choose_layout_save_button: {
        id: 'umbrella_dashboard_choose_layout_save_button',
        defaultMessage: 'Select'
    },
    umbrella_dashboard_add_card: {
        id: 'umbrella_dashboard_add_card',
        defaultMessage: 'Add Card'
    },
    umbrella_dashboard_select_card: {
        id: 'umbrella_dashboard_select_card',
        defaultMessage: 'Select Card'
    },
    umbrella_dashboard_select_card_description: {
        id: 'umbrella_dashboard_select_card_description',
        defaultMessage: 'Select a Card to add it to the placeholder'
    },
    umbrella_dashboard_chart_tab_button: {
        id: 'umbrella_dashboard_chart_tab_button',
        defaultMessage: 'Charts'
    },
    umbrella_dashboard_map_tab_button: {
        id: 'umbrella_dashboard_map_tab_button',
        defaultMessage: 'Maps'
    },
    umbrella_dashboard_table_tab_button: {
        id: 'umbrella_dashboard_table_tab_button',
        defaultMessage: 'Tables'
    },
    umbrella_dashboard_replace_card: {
        id: 'umbrella_dashboard_replace_card',
        defaultMessage: 'Replace Card'
    },
    umbrella_dashboard_delete_card: {
        id: 'umbrella_dashboard_delete_card',
        defaultMessage: 'Delete Card'
    },
    umbrella_dashboard_recent_site_events: {
        id: 'umbrella_dashboard_recent_site_events',
        defaultMessage: 'Recent Events'
    },
    umbrella_dashboard_recent_site_events_no_events: {
        id: 'umbrella_dashboard_recent_site_events_no_events',
        defaultMessage: 'No Events'
    },
    umbrella_dashboard_view_recent_site_events: {
        id: 'umbrella_dashboard_view_recent_site_events',
        defaultMessage: 'View Events'
    },
    umbrella_dashboard_choose_layout_site_states_tooltip: {
        id: 'umbrella_dashboard_choose_layout_site_states_tooltip',
        defaultMessage: 'Site States'
    },
    umbrella_dashboard_choose_layout_running_cameras_tooltip: {
        id: 'umbrella_dashboard_choose_layout_running_cameras_tooltip',
        defaultMessage: 'Running Cameras'
    },
    umbrella_dashboard_choose_layout_running_site_services_tooltip: {
        id: 'umbrella_dashboard_choose_layout_running_site_services_tooltip',
        defaultMessage: 'Running Services'
    },
    umbrella_dashboard_choose_layout_site_approvals_tooltip: {
        id: 'umbrella_dashboard_choose_layout_site_approvals_tooltip',
        defaultMessage: 'Site Approvals'
    },
    umbrella_dashboard_choose_layout_core_cpu_tooltip: {
        id: 'umbrella_dashboard_choose_layout_core_cpu_tooltip',
        defaultMessage: 'Umbrella Core CPU'
    },
    umbrella_dashboard_choose_layout_core_memory_tooltip: {
        id: 'umbrella_dashboard_choose_layout_core_memory_tooltip',
        defaultMessage: 'Umbrella Core Memory'
    },
    umbrella_dashboard_choose_layout_core_request_duration_tooltip: {
        id: 'umbrella_dashboard_choose_layout_core_request_duration_tooltip',
        defaultMessage: 'Umbrella Core Request Duration'
    },
    umbrella_dashboard_choose_layout_core_request_count_tooltip: {
        id: 'umbrella_dashboard_choose_layout_core_request_count_tooltip',
        defaultMessage: 'Umbrella Core Request Count'
    },
    umbrella_dashboard_choose_layout_map_tooltip: {
        id: 'umbrella_dashboard_choose_layout_map_tooltip',
        defaultMessage: 'Map'
    },
    umbrella_dashboard_choose_layout_recent_jobs_tooltip: {
        id: 'umbrella_dashboard_choose_layout_recent_jobs_tooltip',
        defaultMessage: 'Recent Jobs'
    },
    umbrella_dashboard_choose_layout_recent_site_events_tooltip: {
        id: 'umbrella_dashboard_choose_layout_recent_site_events_tooltip',
        defaultMessage: 'Recent Events'
    },
    umbrella_dashboard_advanced_layout_add_new_item: {
        id: 'umbrella_dashboard_advanced_layout_add_new_item',
        defaultMessage: 'ADD ITEM'
    },
    umbrella_eula_title: {
        id: 'umbrella_eula_title',
        defaultMessage: 'EULA and third party license Agreement'
    },
    umbrella_eula_text: {
        id: 'umbrella_eula_text',
        defaultMessage: 'Please read the agreement carefully. If you continue and click Accept, you agree to the EULA and the third party license terms and conditions.'
    },
    umbrella_eula_button: {
        id: 'umbrella_eula_button',
        defaultMessage: 'Accept'
    },
    umbrella_eula_file_text: {
        id: 'umbrella_eula_file_text',
        defaultMessage: 'Click here to read EULA Agreement'
    },
    breadcrumb_dashboard: {
        id: 'breadcrumb_dashboard',
        defaultMessage: 'Dashboard'
    },
    breadcrumb_control_centre: {
        id: 'breadcrumb_control_centre',
        defaultMessage: 'Site Administration'
    },
    breadcrumb_sites: {
        id: 'breadcrumb_sites',
        defaultMessage: 'Sites'
    },
    breadcrumb_map: {
        id: 'breadcrumb_map',
        defaultMessage: 'Map'
    },
    breadcrumb_site_users: {
        id: 'breadcrumb_site_users',
        defaultMessage: 'Users'
    },
    breadcrumb_site_groups: {
        id: 'breadcrumb_site_groups',
        defaultMessage: 'Groups'
    },
    breadcrumb_access_control_cardholders: {
        id: 'breadcrumb_access_control_cardholders',
        defaultMessage: 'Card Holders'
    },
    breadcrumb_access_control_doors: {
        id: 'breadcrumb_access_control_doors',
        defaultMessage: 'Doors'
    },
    breadcrumb_site_services: {
        id: 'breadcrumb_site_services',
        defaultMessage: 'Services'
    },
    breadcrumb_cameras: {
        id: 'breadcrumb_cameras',
        defaultMessage: 'Cameras'
    },
    breadcrumb_site_jobs: {
        id: 'breadcrumb_site_jobs',
        defaultMessage: 'Jobs'
    },
    breadcrumb_update_site_licenses: {
        id: 'breadcrumb_update_site_licenses',
        defaultMessage: 'Licenses'
    },
    breadcrumb_administration: {
        id: 'breadcrumb_administration',
        defaultMessage: 'Administration'
    },
    breadcrumb_umbrella_users: {
        id: 'breadcrumb_umbrella_users',
        defaultMessage: 'Umbrella Users'
    },
    breadcrumb_umbrella_roles: {
        id: 'breadcrumb_umbrella_roles',
        defaultMessage: 'Umbrella Roles'
    },
    breadcrumb_administration_license: {
        id: 'breadcrumb_administration_license',
        defaultMessage: 'Umbrella License'
    },
    breadcrumb_administration_license_update: {
        id: 'breadcrumb_administration_license_update',
        defaultMessage: 'License'
    },
    breadcrumb_monitor_centre: {
        id: 'breadcrumb_monitor_centre',
        defaultMessage: 'Monitoring'
    },
    breadcrumb_umbrella_core: {
        id: 'breadcrumb_umbrella_core',
        defaultMessage: 'Umbrella Core'
    },
    breadcrumb_site_events: {
        id: 'breadcrumb_site_events',
        defaultMessage: 'Events'
    },
    breadcrumb_email: {
        id: 'breadcrumb_email',
        defaultMessage: 'E-Mail'
    },
    breadcrumb_camera_firmware: {
        id: 'breadcrumb_camera_firmware',
        defaultMessage: 'Camera Firmwares'
    },
    breadcrumb_administration_settings: {
        id: 'breadcrumb_administration_settings',
        defaultMessage: 'Settings'
    },
    breadcrumb_site_time_profile: {
        id: 'breadcrumb_site_time_profile',
        defaultMessage: 'Time Profiles'
    },
    breadcrumb_report_center: {
        id: 'breadcrumb_report_center',
        defaultMessage: 'Reports'
    },
    breadcrumb_reports: {
        id: 'breadcrumb_reports',
        defaultMessage: 'Inventory Reports'
    },
    breadcrumb_audit_reports: {
        id: 'breadcrumb_audit_reports',
        defaultMessage: 'Audit Reports'
    },
    breadcrumb_site_systems: {
        id: 'breadcrumb_site_systems',
        defaultMessage: 'Systems'
    },
    breadcrumb_site_service_performance: {
        id: 'breadcrumb_site_service_performance',
        defaultMessage: 'Services'
    },
    breadcrumb_camera_performance: {
        id: 'breadcrumb_camera_performance',
        defaultMessage: 'Cameras'
    },
    breadcrumb_plugins: {
        id: 'breadcrumb_plugins',
        defaultMessage: 'Plugins'
    },
    breadcrumb_labels: {
        id: 'breadcrumb_labels',
        defaultMessage: 'Labels'
    },
    breadcrumb_apiKeys: {
        id: 'breadcrumb_apiKeys',
        defaultMessage: 'API Keys'
    },
    site_job_details_execution_time: {
        id: 'site_job_details_execution_time',
        defaultMessage: 'Execution time (Site timezone):'
    },
    site_job_details_retries: {
        id: 'site_job_details_retries',
        defaultMessage: 'Retries:'
    },
    site_job_details_sequence_id: {
        id: 'site_job_details_sequence_id',
        defaultMessage: 'Sequence ID:'
    },
    site_job_details_sequence_order: {
        id: 'site_job_details_sequence_order',
        defaultMessage: 'Sequence Order:'
    },
    site_job_details_sequence_total: {
        id: 'site_job_details_sequence_total',
        defaultMessage: 'Sequence Total:'
    },
    site_job_related_undo_job: {
        id: 'site_job_related_undo_job',
        defaultMessage: 'RELATED UNDO JOB:'
    },
    site_job_card_title: {
        id: 'site_job_card_title',
        defaultMessage: 'JOB:'
    },
    site_jobs_table_head_sequence: {
        id: 'site_jobs_table_head_sequence',
        defaultMessage: 'Sequence ID'
    },
    site_jobs_table_head_sequence_order: {
        id: 'site_jobs_table_head_sequence_order',
        defaultMessage: 'Sequence Order'
    },
    site_jobs_table_head_sequence_total: {
        id: 'site_jobs_table_head_sequence_total',
        defaultMessage: 'Sequence Total'
    },
    site_jobs_table_head_execution_time: {
        id: 'site_jobs_table_head_execution_time',
        defaultMessage: 'Execution time (Site timezone)'
    },
    site_jobs_table_head_undo_time: {
        id: 'site_jobs_table_head_undo_time',
        defaultMessage: 'Undo Time (Site timezone)'
    },
    site_jobs_table_head_retries: {
        id: 'site_jobs_table_head_retries',
        defaultMessage: 'Retries'
    },
    restart_cameras_success_snack_message: {
        id: 'restart_cameras_success_snack_message',
        defaultMessage: 'Jobs successfully created to restart cameras'
    },
    restart_cameras_error_snack_message: {
        id: 'restart_cameras_error_snack_message',
        defaultMessage: 'Failed to create jobs to restart cameras'
    },
    restart_cameras_tooltip: {
        id: 'restart_cameras_tooltip',
        defaultMessage: 'Restart cameras'
    },
    license_root_title: {
        id: 'license_root_title',
        defaultMessage: 'License Management'
    },
    license_root_description: {
        id: 'license_root_description',
        defaultMessage: 'No license key! Use this code to request a license:'
    },
    license_root_apply_license_key: {
        id: 'license_root_apply_license_key',
        defaultMessage: 'Apply license key:'
    },
    license_root_input_placeholder: {
        id: 'license_root_input_placeholder',
        defaultMessage: 'Enter license key'
    },
    license_root_submit_button_text: {
        id: 'license_root_submit_button_text',
        defaultMessage: 'Apply'
    },
    license_root_problem_license_valid: {
        id: 'license_root_problem_license_valid',
        defaultMessage: 'License valid'
    },
    license_root_problem_license_invalid: {
        id: 'license_root_problem_license_invalid',
        defaultMessage: 'License invalid'
    },
    license_root_problem_machine_code_missing: {
        id: 'license_root_problem_machine_code_missing',
        defaultMessage: 'Machine code is missing'
    },
    license_root_problem_no_modules: {
        id: 'license_root_problem_no_modules',
        defaultMessage: 'No modules licensed'
    },
    license_root_key_update_success: {
        id: 'license_root_key_update_success',
        defaultMessage: 'Successfully updated key'
    },
    license_root_key_update_fail: {
        id: 'license_root_key_update_fail',
        defaultMessage: 'Error in updating key'
    },
    camera_firmware_modal_title: {
        id: 'camera_firmware_modal_title',
        defaultMessage: 'Upload New Firmware'
    },
    camera_firmware_modal_description: {
        id: 'camera_firmware_modal_description',
        defaultMessage: 'The Firmware will be stored on the Umbrella Core Server.'
    },
    camera_firmware_modal_manfucturer: {
        id: 'camera_firmware_modal_manfucturer',
        defaultMessage: 'Manufacturer:*'
    },
    camera_firmware_modal_supported_model: {
        id: 'camera_firmware_modal_supported_model',
        defaultMessage: 'Supported Model:*'
    },
    camera_firmware_modal_comment: {
        id: 'camera_firmware_modal_comment',
        defaultMessage: 'Comment:'
    },
    camera_firmware_modal_dropzone: {
        id: 'camera_firmware_modal_dropzone',
        defaultMessage: 'Upload or Drag & Drop File'
    },
    camera_firmware_modal_upload_btn: {
        id: 'camera_firmware_modal_upload_btn',
        defaultMessage: 'Upload'
    },
    camera_firmware_modal_upload_success: {
        id: 'camera_firmware_modal_upload_success',
        defaultMessage: 'Successfully uploaded new firmware'
    },
    camera_firmware_modal_upload_error: {
        id: 'camera_firmware_modal_upload_error',
        defaultMessage: 'Error in uploading new firmware'
    },
    camera_firmware_table_filename: {
        id: 'camera_firmware_table_filename',
        defaultMessage: 'File name'
    },
    camera_firmware_table_manufacturer: {
        id: 'camera_firmware_table_manufacturer',
        defaultMessage: 'Manufacturer'
    },
    camera_firmware_table_comment: {
        id: 'camera_firmware_table_comment',
        defaultMessage: 'Comment'
    },
    camera_firmware_table_supported_types: {
        id: 'camera_firmware_table_supported_types',
        defaultMessage: 'Supported types'
    },
    camera_firmware_table_uploaded_at: {
        id: 'camera_firmware_table_uploaded_at',
        defaultMessage: 'Uploaded at'
    },
    camera_firmware_table_delete_firmwares: {
        id: 'camera_firmware_table_delete_firmwares',
        defaultMessage: 'Delete firmware'
    },
    camera_firmware_table_selected_count: {
        id: 'camera_firmware_table_selected_count',
        defaultMessage: '{count} selected'
    },
    camera_firmware_table_upload_modal_button: {
        id: 'camera_firmware_table_upload_modal_button',
        defaultMessage: 'Upload new firmware'
    },
    camera_firmware_modal_no_file_selected: {
        id: 'camera_firmware_modal_no_file_selected',
        defaultMessage: 'No file selected'
    },
    camera_firmware_upload_snackbar_title: {
        id: 'camera_firmware_upload_snackbar_title',
        defaultMessage: 'Uploading Firmware'
    },
    camera_firmware_upload_snackbar_button_error: {
        id: 'camera_firmware_upload_snackbar_button_error',
        defaultMessage: 'Error'
    },
    camera_firmware_upload_snackbar_button: {
        id: 'camera_firmware_upload_snackbar_button',
        defaultMessage: 'Done'
    },
    camera_firmware_deleted_success: {
        id: 'camera_firmware_deleted_success',
        defaultMessage: 'Sucessfully deleted selected firmwares'
    },
    camera_firmware_deleted_error: {
        id: 'camera_firmware_deleted_error',
        defaultMessage: 'Error in deleting slected firmwares'
    },
    camera_firmware_modal_file_name: {
        id: 'camera_firmware_modal_file_name',
        defaultMessage: 'File name:'
    },
    settings_configuration_title: {
        id: 'settings_configuration_title',
        defaultMessage: 'General Settings'
    },
    settings_configuration_description: {
        id: 'settings_configuration_description',
        defaultMessage: 'Below You can edit General Settings'
    },
    settings_ad_use_ad_cache: {
        id: 'settings_ad_use_ad_cache',
        defaultMessage: 'Use Active Directory Cache:*'
    },
    settings_ad_refresh_ad_cache_minutes: {
        id: 'settings_ad_refresh_ad_cache_minutes',
        defaultMessage: 'Refresh Active Directory Cache (in minutes):*'
    },
    settings_general_provide_health_endpoint: {
        id: 'settings_general_provide_health_endpoint',
        defaultMessage: 'Provide health endpoint:*'
    },
    settings_general_delete_vms_events_days: {
        id: 'settings_general_delete_vms_events_days',
        defaultMessage: 'Delete Site events older than (in days):'
    },
    settings_general_core_performance_measure_interval: {
        id: 'settings_general_core_performance_measure_interval',
        defaultMessage: 'Performance Measure Interval (in minutes):'
    },
    settings_password_require_digit: {
        id: 'settings_password_require_digit',
        defaultMessage: 'Require digit:*'
    },
    settings_password_required_length: {
        id: 'settings_password_required_length',
        defaultMessage: 'Required length:*'
    },
    settings_password_require_non_alphanumeric: {
        id: 'settings_password_require_non_alphanumeric',
        defaultMessage: 'Require non alphanumeric:*'
    },
    settings_password_require_uppercase: {
        id: 'settings_password_require_uppercase',
        defaultMessage: 'Require uppercase:*'
    },
    settings_password_require_lowercase: {
        id: 'settings_password_require_lowercase',
        defaultMessage: 'Require lowercase:*'
    },
    settings_password_required_unique_chars: {
        id: 'settings_password_required_unique_chars',
        defaultMessage: 'Required unique chars:*'
    },
    settings_login_is_remember_me_available: {
        id: 'settings_login_is_remember_me_available',
        defaultMessage: 'Is remember me available:*'
    },
    settings_login_cookie_persistence_duration: {
        id: 'settings_login_cookie_persistence_duration',
        defaultMessage: 'Cookie persistence duration (in hours):*'
    },
    settings_login_sliding_expiration_session_cookie: {
        id: 'settings_login_sliding_expiration_session_cookie',
        defaultMessage: 'Sliding expiration of Session Cookie:*'
    },
    settings_login_validation_interval_seconds: {
        id: 'settings_login_validation_interval_seconds',
        defaultMessage: 'Validation Interval (in seconds):*'
    },
    settings_ad_is_ad_login_available: {
        id: 'settings_ad_is_ad_login_available',
        defaultMessage: 'Is Active Directory login available:*'
    },
    settings_ad_sync_active_directory_email: {
        id: 'settings_ad_sync_active_directory_email',
        defaultMessage: 'Synchronize Active Directory Email:*'
    },
    settings_ad_domain_names: {
        id: 'settings_ad_domain_names',
        defaultMessage: 'Domain names:*'
    },
    settings_license_license_key: {
        id: 'settings_license_license_key',
        defaultMessage: 'License key:'
    },
    settings_gateway_heartbeat_interval_seconds: {
        id: 'settings_gateway_heartbeat_interval_seconds',
        defaultMessage: 'Heartbeat interval (in seconds):'
    },
    settings_gateway_use_signalr_for_vms_event: {
        id: 'settings_gateway_use_signalr_for_vms_event',
        defaultMessage: 'Use SignalR for Site events:'
    },
    settings_gateway_vms_network_timeout_seconds: {
        id: 'settings_gateway_vms_network_timeout_seconds',
        defaultMessage: 'Site network timeout (in seoncds):'
    },
    settings_gateway_fetch_recordings_interval_minutes: {
        id: 'settings_gateway_fetch_recordings_interval_minutes',
        defaultMessage: 'Fetch recordings interval (in minutes):'
    },
    settings_gateway_delete_site_performance_measurements_interval: {
        id: 'settings_gateway_delete_site_performance_measurements_interval',
        defaultMessage: 'Delete Performance Measurements Interval (in days):'
    },
    settings_general_delete_core_performance_measurements_interval: {
        id: 'settings_general_delete_core_performance_measurements_interval',
        defaultMessage: 'Delete Performance Measurements Interval (in days):'
    },
    settings_gateway_site_performance_measure_interval: {
        id: 'settings_gateway_site_performance_measure_interval',
        defaultMessage: 'Performance Measure Interval (in minutes):'
    },
    settings_configuration_submit: {
        id: 'settings_configuration_submit',
        defaultMessage: 'Update'
    },
    settings_configuration_reset_fields: {
        id: 'settings_configuration_reset_fields',
        defaultMessage: 'Reset all'
    },
    settings_configuration_restore_default: {
        id: 'settings_configuration_restore_default',
        defaultMessage: 'Restore default'
    },
    settings_configuration_update_success: {
        id: 'settings_configuration_update_success',
        defaultMessage: 'General Settings updated successfully'
    },
    settings_configuration_update_error: {
        id: 'settings_configuration_update_error',
        defaultMessage: 'Error in updating General Settings'
    },
    settings_configuration_resart_information: {
        id: 'settings_configuration_resart_information',
        defaultMessage: '* Changes to these values will restart the Umbrella Core immediately'
    },
    settings_general_delete_log_days: {
        id: 'settings_general_delete_log_days',
        defaultMessage: 'Delete Logfiles older than (in days):*'
    },
    settings_gateway_delete_log_days: {
        id: 'settings_gateway_delete_log_days',
        defaultMessage: 'Delete Logfiles older than (in days):'
    },
    settings_expert_writeheartbeatcache: {
        id: 'settings_expert_writeheartbeatcache',
        defaultMessage: 'Heartbeat cache write interval (in seconds):*'
    },
    settings_expert_allowedmissedheartbeats: {
        id: 'settings_expert_allowedmissedheartbeats',
        defaultMessage: 'Number of allowed missed heartbeats:*'
    },
    settings_expert_delaygatewayupdate: {
        id: 'settings_expert_delaygatewayupdate',
        defaultMessage: 'Delay gateway update interval (in seconds):*'
    },
    settings_general_display_username_jobs: {
        id: 'settings_general_display_username_jobs',
        defaultMessage: 'Display Usernames in Jobs:*'
    },
    settings_general_is_comment_mandatory: {
        id: 'settings_general_is_comment_mandatory',
        defaultMessage: 'Make Job Comment mandatory:*'
    },
    settings_general_delete_reports_when_downloading: {
        id: 'settings_general_delete_reports_when_downloading',
        defaultMessage: 'Delete reports when downloaded:*'
    },
    settings_general_delete_jobs_after_day_interval: {
        id: 'settings_general_delete_jobs_after_day_interval',
        defaultMessage: 'Delete jobs older than (in days):'
    },
    settings_gateway_delete_system_performance_measurements_interval: {
        id: 'settings_gateway_delete_system_performance_measurements_interval',
        defaultMessage: 'Delete Performance Measurements Interval (in days):'
    },
    settings_gateway_system_performance_measure_interval: {
        id: 'settings_gateway_system_performance_measure_interval',
        defaultMessage: 'Performance Measure Interval (in minutes):'
    },
    settings_general_thresholds_cpu: {
        id: 'settings_general_thresholds_cpu',
        defaultMessage: 'Thresholds CPU (%):'
    },
    settings_general_thresholds_memory: {
        id: 'settings_general_thresholds_memory',
        defaultMessage: 'Thresholds Memory (%):'
    },
    settings_general_thresholds_disk: {
        id: 'settings_general_thresholds_disk',
        defaultMessage: 'Thresholds Disk (%):'
    },
    settings_general_thresholds_network: {
        id: 'settings_general_thresholds_network',
        defaultMessage: 'Thresholds Network (%):'
    },
    settings_notifications_priority_low_delay: {
        id: 'settings_notifications_priority_low_delay',
        defaultMessage: 'Delay low priority notifications (in seconds):'
    },
    settings_notifications_priority_normal_delay: {
        id: 'settings_notifications_priority_normal_delay',
        defaultMessage: 'Delay normal priority notifications (in seconds):'
    },
    settings_thresholds_slider_warning: {
        id: 'settings_thresholds_slider_warning',
        defaultMessage: 'Warning'
    },
    settings_thresholds_slider_critical: {
        id: 'settings_thresholds_slider_critical',
        defaultMessage: 'Critical'
    },
    settings_group_password: {
        id: 'settings_group_password',
        defaultMessage: 'PASSWORD SETTINGS:'
    },
    settings_group_login: {
        id: 'settings_group_login',
        defaultMessage: 'LOGIN SETTINGS:'
    },
    settings_group_license: {
        id: 'settings_group_license',
        defaultMessage: 'LICENSE:'
    },
    settings_group_gateway: {
        id: 'settings_group_gateway',
        defaultMessage: 'GATEWAY SETTINGS:'
    },
    settings_group_core: {
        id: 'settings_group_core',
        defaultMessage: 'CORE SETTINGS:'
    },
    settings_group_core_expert: {
        id: 'settings_group_core_expert',
        defaultMessage: 'CORE EXPERT SETTINGS:'
    },
    settings_group_active_directory: {
        id: 'settings_group_active_directory',
        defaultMessage: 'ACTIVE DIRECTORY SETTINGS:'
    },
    settings_group_notification: {
        id: 'settings_group_notification',
        defaultMessage: 'NOTIFICATION SETTINGS:'
    },
    settings_general_delete_vms_events_error: {
        id: 'settings_general_delete_vms_events_error',
        defaultMessage: 'Must be in range 1 - 365'
    },
    settings_ad_refresh_ad_cache_error: {
        id: 'settings_ad_refresh_ad_cache_error',
        defaultMessage: 'Must be in range 5 - 120'
    },
    settings_general_core_performance_measure_interval_error: {
        id: 'settings_general_core_performance_measure_interval_error',
        defaultMessage: 'Must be in range 1 - 60'
    },
    settings_gateway_site_performance_measure_interval_error: {
        id: 'settings_gateway_site_performance_measure_interval_error',
        defaultMessage: 'Must be in range 1 - 60'
    },
    settings_password_required_length_error: {
        id: 'settings_password_required_length_error',
        defaultMessage: 'Must be in range 1 - 100'
    },
    settings_password_required_unique_chars_error: {
        id: 'settings_password_required_unique_chars_error',
        defaultMessage: 'Cannot be less than 0 or greater than Required Length'
    },
    settings_login_cookie_persistence_duration_error: {
        id: 'settings_login_cookie_persistence_duration_error',
        defaultMessage: 'Must be in range 0 - 2160'
    },
    settings_login_validation_interval_error: {
        id: 'settings_login_validation_interval_error',
        defaultMessage: 'Must be in range 0 - 300'
    },
    settings_ad_domain_names_error: {
        id: 'settings_ad_domain_names_error',
        defaultMessage: 'Cannot be empty if Active Directory is enabled'
    },
    settings_gateway_heartbeat_interval_error: {
        id: 'settings_gateway_heartbeat_interval_error',
        defaultMessage: 'Must be in range 5 - 3600'
    },
    settings_gateway_vms_network_timeout_error: {
        id: 'settings_gateway_vms_network_timeout_error',
        defaultMessage: 'Must be in range 5 - 120'
    },
    settings_gateway_fetch_recordings_interval_error: {
        id: 'settings_gateway_fetch_recordings_interval_error',
        defaultMessage: 'Must be in range 5 - 120'
    },
    settings_gateway_delete_site_performance_measurements_interval_error: {
        id: 'settings_gateway_delete_site_performance_measurements_interval_error',
        defaultMessage: 'Must be in range 1 - 365'
    },
    settings_general_delete_core_performance_measurements_interval_error: {
        id: 'settings_general_delete_core_performance_measurements_interval_error',
        defaultMessage: 'Must be in range 1 - 365'
    },
    settings_general_delete_log_days_error: {
        id: 'settings_general_delete_log_days_error',
        defaultMessage: 'Must be in range 1 - 365'
    },
    settings_gateway_delete_log_days_error: {
        id: 'settings_gateway_delete_log_days_error',
        defaultMessage: 'Must be in range 1 - 365'
    },
    settings_expert_writeheartbeatcache_error: {
        id: 'settings_expert_writeheartbeatcache_error',
        defaultMessage: 'Must be in range 10 - 60'
    },
    settings_expert_allowedmissedheartbeats_error: {
        id: 'settings_expert_allowedmissedheartbeats_error',
        defaultMessage: 'Must be in range 3 - 10'
    },
    settings_expert_delaygatewayupdate_error: {
        id: 'settings_expert_delaygatewayupdate_error',
        defaultMessage: 'Must be in range 0 - 30'
    },
    settings_general_delete_jobs_after_day_interval_error: {
        id: 'settings_general_delete_jobs_after_day_interval_error',
        defaultMessage: 'Must be in range 1 - 365'
    },
    umbrella_sites_table_site_name: {
        id: 'umbrella_sites_table_site_name',
        defaultMessage: 'Site name'
    },
    umbrella_sites_table_cpu_usage: {
        id: 'umbrella_sites_table_cpu_usage',
        defaultMessage: 'CPU Usage (%)'
    },
    umbrella_sites_table_ram_usage: {
        id: 'umbrella_sites_table_ram_usage',
        defaultMessage: 'Memory Usage (GB)'
    },
    umbrella_sites_table_site_labels: {
        id: 'umbrella_sites_table_site_labels',
        defaultMessage: 'Labels'
    },
    umbrella_sites_table_state_healthy: {
        id: 'umbrella_sites_table_state_healthy',
        defaultMessage: 'Healthy'
    },
    umbrella_sites_table_state_warning: {
        id: 'umbrella_sites_table_state_warning',
        defaultMessage: 'Warning'
    },
    umbrella_sites_table_state_critical: {
        id: 'umbrella_sites_table_state_critical',
        defaultMessage: 'Critical'
    },
    umbrella_sites_cpu_usage: {
        id: 'umbrella_sites_cpu_usage',
        defaultMessage: 'CPU Usage (%)'
    },
    umbrella_sites_ram_usage: {
        id: 'umbrella_sites_ram_usage',
        defaultMessage: 'Memory Usage (GB)'
    },
    umbrella_sites_no_data_to_show: {
        id: 'umbrella_sites_no_data_to_show',
        defaultMessage: 'No data to show'
    },
    umbrella_sites_disk_usage: {
        id: 'umbrella_sites_disk_usage',
        defaultMessage: 'Disk Space Usage (%)'
    },
    umbrella_sites_services_performance: {
        id: 'umbrella_sites_services_performance',
        defaultMessage: 'Services Performance'
    },
    upload_manager_state_done: {
        id: 'upload_manager_state_done',
        defaultMessage: 'Done'
    },
    upload_manager_state_error: {
        id: 'upload_manager_state_error',
        defaultMessage: 'Error'
    },
    upload_manager_clear_completed_button: {
        id: 'upload_manager_clear_completed_button',
        defaultMessage: 'Clear completed'
    },
    upload_manager_no_items: {
        id: 'upload_manager_no_items',
        defaultMessage: 'No items'
    },
    upload_manager_error_general: {
        id: 'upload_manager_error_general',
        defaultMessage: 'Error'
    },
    upload_manager_error_file_already_exists: {
        id: 'upload_manager_error_file_already_exists',
        defaultMessage: 'File already exists'
    },
    upload_manager_error_size_too_large: {
        id: 'upload_manager_error_size_too_large',
        defaultMessage: 'File is too large'
    },
    upload_manager_type_firmware_upload: {
        id: 'upload_manager_type_firmware_upload',
        defaultMessage: 'Firmware upload: {name}'
    },
    upload_manager_unload_message: {
        id: 'upload_manager_unload_message',
        defaultMessage: 'Last actions are still in progress (check progress manager)'
    },
    upload_manager_job_async_camera_restart: {
        id: 'upload_manager_job_async_camera_restart',
        defaultMessage: 'Create restart camera job ({count})'
    },
    upload_manager_job_async_change_camera_password: {
        id: 'upload_manager_job_async_change_camera_password',
        defaultMessage: 'Create change camera password job ({count})'
    },
    upload_manager_job_async_camera_firmware_update: {
        id: 'upload_manager_job_async_camera_firmware_update',
        defaultMessage: 'Create apply camera firmware job ({count})'
    },
    upload_manager_job_async_camera_change_properties: {
        id: 'upload_manager_job_async_camera_change_properties',
        defaultMessage: 'Create change camera properties job ({count})'
    },
    upload_manager_job_async_site_group_create: {
        id: 'upload_manager_job_async_site_group_create',
        defaultMessage: 'Create new create group job ({count})'
    },
    upload_manager_job_async_site_group_delete: {
        id: 'upload_manager_job_async_site_group_delete',
        defaultMessage: 'Create new delete group job ({count})'
    },
    upload_manager_job_async_site_group_change_properties: {
        id: 'upload_manager_job_async_site_group_change_properties',
        defaultMessage: 'Create change groups properties job ({count})'
    },
    upload_manager_job_async_license_upload: {
        id: 'upload_manager_job_async_license_upload',
        defaultMessage: 'Create new upload license job ({count})'
    },
    upload_manager_job_async_sites_connection_user_password: {
        id: 'upload_manager_job_async_sites_connection_user_password',
        defaultMessage: 'Create change connection user password job ({count})'
    },
    upload_manager_job_async_site_services_restart: {
        id: 'upload_manager_job_async_site_services_restart',
        defaultMessage: 'Create restart service job ({count})'
    },
    upload_manager_job_async_site_services_change_properties: {
        id: 'upload_manager_job_async_site_services_change_properties',
        defaultMessage: 'Create change services properties job ({count})'
    },
    upload_manager_job_async_site_users_change_password: {
        id: 'upload_manager_job_async_site_users_change_password',
        defaultMessage: 'Create change user password job ({count})'
    },
    upload_manager_job_async_site_users_create: {
        id: 'upload_manager_job_async_site_users_create',
        defaultMessage: 'Create new create user job ({count})'
    },
    upload_manager_job_async_site_users_delete: {
        id: 'upload_manager_job_async_site_users_delete',
        defaultMessage: 'Create new delete user job ({count})'
    },
    upload_manager_job_async_site_users_properties: {
        id: 'upload_manager_job_async_site_users_properties',
        defaultMessage: 'Create change users properties job ({count})'
    },
    upload_manager_job_async_retry_jobs: {
        id: 'upload_manager_job_async_retry_jobs',
        defaultMessage: 'Create retry job ({count})'
    },
    upload_manager_job_async_site_time_profile_change_properties: {
        id: 'upload_manager_job_async_site_time_profile_change_properties',
        defaultMessage: 'Create change time profile properties job ({count})'
    },
    upload_manager_job_async_site_user_change_name: {
        id: 'upload_manager_job_async_site_user_change_name',
        defaultMessage: 'Create change user name job ({count})'
    },
    upload_manager_job_async_site_user_copy_user: {
        id: 'upload_manager_job_async_site_user_copy_user',
        defaultMessage: 'Create copy user job ({count})'
    },
    upload_manager_job_async_access_control_cardholders_change_properties: {
        id: 'upload_manager_job_async_access_control_cardholders_change_properties',
        defaultMessage: 'Create change card holder properties job ({count})'
    },
    upload_manager_job_async_access_control_doors_change_properties: {
        id: 'upload_manager_job_async_access_control_doors_change_properties',
        defaultMessage: 'Create change door properties job ({count})'
    },
    upload_manager_job_async_access_control_cardholders_create: {
        id: 'upload_manager_job_async_access_control_cardholders_create',
        defaultMessage: 'Create new card holder job ({count})'
    },
    upload_manager_job_async_change_system_message_config: {
        id: 'upload_manager_job_async_change_system_message_config',
        defaultMessage: 'Create change system message config job {{count}}'
    },
    site_time_profiles_list_add_time_profile_button: {
        id: 'site_time_profiles_list_add_time_profile_button',
        defaultMessage: 'Add Time Profile'
    },
    site_time_profiles_list_table_head_time_profile: {
        id: 'site_time_profiles_list_table_head_time_profile',
        defaultMessage: 'Time Profile'
    },
    site_time_profiles_list_table_head_site: {
        id: 'site_time_profiles_list_table_head_site',
        defaultMessage: 'Site'
    },
    site_time_profiles_list_table_head_site_labels: {
        id: 'site_time_profiles_list_table_head_site_labels',
        defaultMessage: 'Site Labels'
    },
    site_time_profiles_list_table_head_labels: {
        id: 'site_time_profiles_list_table_head_labels',
        defaultMessage: 'Labels'
    },
    site_time_profiles_table_toolbar_change_time_profiles_tooltip: {
        id: 'site_time_profiles_table_toolbar_change_time_profiles_tooltip',
        defaultMessage: 'Change Time Profile'
    },
    site_time_profiles_table_toolbar_selected_time_profiles: {
        id: 'site_time_profiles_table_toolbar_selected_time_profiles',
        defaultMessage: '{count} selected'
    },
    site_time_profile_day_monday: {
        id: 'site_time_profile_day_monday',
        defaultMessage: 'Monday'
    },
    site_time_profile_day_monday_short: {
        id: 'site_time_profile_day_monday_short',
        defaultMessage: 'Mon'
    },
    site_time_profile_day_tuesday: {
        id: 'site_time_profile_day_tuesday',
        defaultMessage: 'Tuesday'
    },
    site_time_profile_day_tuesday_short: {
        id: 'site_time_profile_day_tuesday_short',
        defaultMessage: 'Tue'
    },
    site_time_profile_day_wednesday: {
        id: 'site_time_profile_day_wednesday',
        defaultMessage: 'Wednesday'
    },
    site_time_profile_day_wednesday_short: {
        id: 'site_time_profile_day_wednesday_short',
        defaultMessage: 'Wed'
    },
    site_time_profile_day_thursday: {
        id: 'site_time_profile_day_thursday',
        defaultMessage: 'Thursday'
    },
    site_time_profile_day_thursday_short: {
        id: 'site_time_profile_day_thursday_short',
        defaultMessage: 'Thr'
    },
    site_time_profile_day_friday: {
        id: 'site_time_profile_day_friday',
        defaultMessage: 'Friday'
    },
    site_time_profile_day_friday_short: {
        id: 'site_time_profile_day_friday_short',
        defaultMessage: 'Fri'
    },
    site_time_profile_day_saturday: {
        id: 'site_time_profile_day_saturday',
        defaultMessage: 'Saturday'
    },
    site_time_profile_day_saturday_short: {
        id: 'site_time_profile_day_saturday_short',
        defaultMessage: 'Sat'
    },
    site_time_profile_day_sunday: {
        id: 'site_time_profile_day_sunday',
        defaultMessage: 'Sunday'
    },
    site_time_profile_day_sunday_short: {
        id: 'site_time_profile_day_sunday_short',
        defaultMessage: 'Sun'
    },
    site_time_profile_day_bank_holiday: {
        id: 'site_time_profile_day_bank_holiday',
        defaultMessage: 'Bank holiday'
    },
    site_time_profile_day_bank_holiday_short: {
        id: 'site_time_profile_day_bank_holiday_short',
        defaultMessage: 'Bank'
    },
    site_time_profile_hours: {
        id: 'site_time_profile_hours',
        defaultMessage: 'Hours'
    },
    site_time_profile_time_range: {
        id: 'site_time_profile_time_range',
        defaultMessage: 'TIME RANGE {index}:'
    },
    site_time_profile_time_range_starts: {
        id: 'site_time_profile_time_range_starts',
        defaultMessage: 'Starts:'
    },
    site_time_profile_time_range_ends: {
        id: 'site_time_profile_time_range_ends',
        defaultMessage: 'Ends:'
    },
    site_time_profile_time_range_add_new: {
        id: 'site_time_profile_time_range_add_new',
        defaultMessage: 'Add new time range'
    },
    site_time_profile_change_property_job: {
        id: 'site_time_profile_change_property_job',
        defaultMessage: 'Create Change Property Job(s)'
    },
    site_time_profile_reset: {
        id: 'site_time_profile_reset',
        defaultMessage: 'Reset All'
    },
    site_time_profile_info_desc: {
        id: 'site_time_profile_info_desc',
        defaultMessage: 'Selected changes will not be done immediately. First you need to create jobs.'
    },
    site_time_profile_general_tab: {
        id: 'site_time_profile_general_tab',
        defaultMessage: 'Details'
    },
    site_time_profile_profile_tab: {
        id: 'site_time_profile_profile_tab',
        defaultMessage: 'Profile'
    },
    site_time_profile_general_title: {
        id: 'site_time_profile_general_title',
        defaultMessage: 'Change Time Profile'
    },
    site_time_profile_general_description: {
        id: 'site_time_profile_general_description',
        defaultMessage: 'Bellow You can change the Time Profile parameters according to your preferences.'
    },
    site_time_profile_general_name: {
        id: 'site_time_profile_general_name',
        defaultMessage: 'Name:'
    },
    site_time_profile_general_site: {
        id: 'site_time_profile_general_site',
        defaultMessage: 'Site:'
    },
    site_time_profile_general_labels: {
        id: 'site_time_profile_general_labels',
        defaultMessage: 'Labels:'
    },
    site_time_profile_general_update_details: {
        id: 'site_time_profile_general_update_details',
        defaultMessage: 'Update Details'
    },
    site_time_profile_save_current_editing_range: {
        id: 'site_time_profile_save_current_editing_range',
        defaultMessage: 'Apply Changes'
    },
    site_time_profile_save_details_general_success: {
        id: 'site_time_profile_save_details_general_success',
        defaultMessage: 'Successfully updated time profile'
    },
    site_time_profile_save_details_general_error: {
        id: 'site_time_profile_save_details_general_error',
        defaultMessage: 'Error in updating time profile'
    },
    site_time_profile_change_property_success: {
        id: 'site_time_profile_change_property_success',
        defaultMessage: 'Created Change Property Job(s)'
    },
    site_time_profile_change_property_error: {
        id: 'site_time_profile_change_property_error',
        defaultMessage: 'Failed to create Change Property Job(s)'
    },
    site_time_profile_change_multiple_title: {
        id: 'site_time_profile_change_multiple_title',
        defaultMessage: 'Time Profile Multiple Configuration'
    },
    site_time_profile_change_multiple_description: {
        id: 'site_time_profile_change_multiple_description',
        defaultMessage: 'When configurating multiple time profiles you always start with an empty time profile when the selected time profiles do not match.'
    },
    site_time_profile_common_properties_error: {
        id: 'site_time_profile_common_properties_error',
        defaultMessage: 'Failed to check for common properties'
    },
    site_time_profile_remove_time_range: {
        id: 'site_time_profile_remove_time_range',
        defaultMessage: 'Remove time range'
    },
    site_time_profile_slider_overlap_warning: {
        id: 'site_time_profile_slider_overlap_warning',
        defaultMessage: 'Time ranges are overlapping. They will be merged into one time range'
    },
    site_job_update_vms_license: {
        id: 'site_job_update_vms_license',
        defaultMessage: 'Update License'
    },
    site_job_update_firmware_camera: {
        id: 'site_job_update_firmware_camera',
        defaultMessage: 'Update Camera Firmware'
    },
    site_job_restart_vms_runtime: {
        id: 'site_job_restart_vms_runtime',
        defaultMessage: 'Restart Service'
    },
    site_job_start_vms_runtime: {
        id: 'site_job_start_vms_runtime',
        defaultMessage: 'Start Service'
    },
    site_job_stop_vms_runtime: {
        id: 'site_job_stop_vms_runtime',
        defaultMessage: 'Stop Service'
    },
    site_job_delete_vms_user: {
        id: 'site_job_delete_vms_user',
        defaultMessage: 'Delete User'
    },
    site_job_delete_vms_group: {
        id: 'site_job_delete_vms_group',
        defaultMessage: 'Delete Group'
    },
    site_job_create_vms_user: {
        id: 'site_job_create_vms_user',
        defaultMessage: 'Create User'
    },
    site_job_create_vms_group: {
        id: 'site_job_create_vms_group',
        defaultMessage: 'Create Group'
    },
    site_job_change_vms_user_password: {
        id: 'site_job_change_vms_user_password',
        defaultMessage: 'Change User Password'
    },
    site_job_change_connection_user_password: {
        id: 'site_job_change_connection_user_password',
        defaultMessage: 'Change Connection User Password'
    },
    site_job_change_camera_password: {
        id: 'site_job_change_camera_password',
        defaultMessage: 'Change Camera Password'
    },
    site_job_cancel_job: {
        id: 'site_job_cancel_job',
        defaultMessage: 'Cancel Job'
    },
    site_job_batch_job: {
        id: 'site_job_batch_job',
        defaultMessage: 'Batch Job'
    },
    site_job_change_vms_user_property: {
        id: 'site_job_change_vms_user_property',
        defaultMessage: 'Change User Property'
    },
    site_job_change_vms_camera_property: {
        id: 'site_job_change_vms_camera_property',
        defaultMessage: 'Change Camera Property'
    },
    site_job_change_vms_group_property: {
        id: 'site_job_change_vms_group_property',
        defaultMessage: 'Change Group Property'
    },
    site_job_change_vms_runtime_property: {
        id: 'site_job_change_vms_runtime_property',
        defaultMessage: 'Change Service Property'
    },
    site_job_change_vms_event_property: {
        id: 'site_job_change_vms_event_property',
        defaultMessage: 'Change Event Property'
    },
    site_job_change_vms_timeprofile_property: {
        id: 'site_job_change_vms_timeprofile_property',
        defaultMessage: 'Change Time Profile Property'
    },
    site_job_change_vms_user_name: {
        id: 'site_job_change_vms_user_name',
        defaultMessage: 'Rename User'
    },
    site_job_copy_vms_user: {
        id: 'site_job_copy_vms_user',
        defaultMessage: 'Copy User'
    },
    site_job_restart_gateway: {
        id: 'site_job_restart_gateway',
        defaultMessage: 'Restart Site Connection'
    },
    site_job_create_accesscontrol_cardholder: {
        id: 'site_job_create_accesscontrol_cardholder',
        defaultMessage: 'Create Card Holder'
    },
    site_job_change_vms_event_config_property: {
        id: 'site_job_change_vms_event_config_property',
        defaultMessage: 'Change Event Configuration Property'
    },
    selectionbox_toolbar_select_mode_page: {
        id: 'selectionbox_toolbar_select_mode_page',
        defaultMessage: 'Only current page'
    },
    selectionbox_toolbar_select_mode_selectAll: {
        id: 'selectionbox_toolbar_select_mode_selectAll',
        defaultMessage: 'All Pages'
    },
    selectionbox_siteselector_include_all_sites: {
        id: 'selectionbox_siteselector_include_all_sites',
        defaultMessage: 'Select All mode includes all Sites'
    },
    reports_table_head_filename: {
        id: 'reports_table_head_filename',
        defaultMessage: 'Filename'
    },
    reports_table_head_createdAt: {
        id: 'reports_table_head_createdAt',
        defaultMessage: 'Created At'
    },
    reports_table_head_createdBy: {
        id: 'reports_table_head_createdBy',
        defaultMessage: 'Created By'
    },
    reports_table_head_comment: {
        id: 'reports_table_head_comment',
        defaultMessage: 'Comment'
    },
    reports_table_head_siteName: {
        id: 'reports_table_head_siteName',
        defaultMessage: 'Sitename'
    },
    reports_download_success_snack_message: {
        id: 'reports_download_success_snack_message',
        defaultMessage: 'Job(s) successfully created to download reports'
    },
    reports_download_error_snack_message: {
        id: 'reports_download_error_snack_message',
        defaultMessage: 'Failed to create jobs to download reports'
    },
    reports_table_toolbar_download_tooltip: {
        id: 'reports_table_toolbar_download_tooltip',
        defaultMessage: 'Download selected reports'
    },
    reports_table_toolbar_delete_tooltip: {
        id: 'reports_table_toolbar_delete_tooltip',
        defaultMessage: 'Delete selected reports'
    },
    reports_delete_success_snack_message: {
        id: 'reports_delete_success_snack_message',
        defaultMessage: 'Selected reports succesfully deleted'
    },
    reports_delete_error_snack_message: {
        id: 'reports_delete_error_snack_message',
        defaultMessage: 'Failed to delete reports'
    },
    create_audit_report_modal_title: {
        id: 'create_audit_report_modal_title',
        defaultMessage: 'Create Audit Report'
    },
    create_audit_report_modal_description: {
        id: 'create_audit_report_modal_description',
        defaultMessage: 'Create Audit Report for selected timerange'
    },
    create_audit_report_modal_start_label: {
        id: 'create_audit_report_modal_start_label',
        defaultMessage: 'Start date'
    },
    create_audit_report_modal_end_label: {
        id: 'create_audit_report_modal_end_label',
        defaultMessage: 'End date'
    },
    create_audit_report_modal_submit_button: {
        id: 'create_audit_report_modal_submit_button',
        defaultMessage: 'Request Audit Report'
    },
    create_audit_report_modale_error: {
        id: 'create_audit_report_modale_error',
        defaultMessage: 'End date can not be before start date'
    },
    create_audit_report_success_snack_message: {
        id: 'create_audit_report_success_snack_message',
        defaultMessage: 'Job(s) successfully created to create audit reports'
    },
    create_audit_report_failed_snack_message: {
        id: 'create_audit_report_failed_snack_message',
        defaultMessage: 'Failed to create jobs to create audit reports'
    },
    audit_reports_table_head_filename: {
        id: 'audit_reports_table_head_filename',
        defaultMessage: 'Filename'
    },
    audit_reports_table_head_createdAt: {
        id: 'audit_reports_table_head_createdAt',
        defaultMessage: 'Created At'
    },
    audit_reports_table_head_createdBy: {
        id: 'audit_reports_table_head_createdBy',
        defaultMessage: 'Created By'
    },
    audit_reports_table_head_comment: {
        id: 'audit_reports_table_head_comment',
        defaultMessage: 'Comment'
    },
    audit_reports_table_head_siteName: {
        id: 'audit_reports_table_head_siteName',
        defaultMessage: 'Sitename'
    },
    audit_reports_table_head_from: {
        id: 'audit_reports_table_head_from',
        defaultMessage: 'From'
    },
    audit_reports_table_head_till: {
        id: 'audit_reports_table_head_till',
        defaultMessage: 'Till'
    },
    custom_comments_modal_title: {
        id: 'custom_comments_modal_title',
        defaultMessage: 'Custom comments'
    },
    custom_comments_modal_description: {
        id: 'custom_comments_modal_description',
        defaultMessage: 'Custom comments description'
    },
    copy_to_clipboard_button_tooltip: {
        id: 'copy_to_clipboard_button_tooltip',
        defaultMessage: 'Copy to Clipboard'
    },
    site_systems_chip_healthy: {
        id: 'site_systems_chip_healthy',
        defaultMessage: 'Healthy'
    },
    site_systems_chip_warning: {
        id: 'site_systems_chip_warning',
        defaultMessage: 'Warning'
    },
    site_systems_chip_critical: {
        id: 'site_systems_chip_critical',
        defaultMessage: 'Critical'
    },
    site_systems_chip_offline: {
        id: 'site_systems_chip_offline',
        defaultMessage: 'Offline'
    },
    site_systems_chip_ignore: {
        id: 'site_systems_chip_ignore',
        defaultMessage: 'Ignored'
    },
    site_systems_search_by_site: {
        id: 'site_systems_search_by_site',
        defaultMessage: 'Filter by Site'
    },
    site_systems_search_by_system: {
        id: 'site_systems_search_by_system',
        defaultMessage: 'Filter by Site System'
    },
    site_systems_search_by_name: {
        id: 'site_systems_search_by_name',
        defaultMessage: 'Filter by Name'
    },
    site_systems_ignore_success: {
        id: 'site_systems_ignore_success',
        defaultMessage: 'Successful'
    },
    site_systems_ignore_error: {
        id: 'site_systems_ignore_error',
        defaultMessage: 'Failed'
    },
    site_systems_tooltip_ignore: {
        id: 'site_systems_tooltip_ignore',
        defaultMessage: 'Ignore selected systems'
    },
    site_systems_details_net_usage: {
        id: 'site_systems_details_net_usage',
        defaultMessage: 'Net Usage (%)'
    },
    site_systems_details_disk_usage: {
        id: 'site_systems_details_disk_usage',
        defaultMessage: 'Disk Usage (%)'
    },
    site_systems_details_description: {
        id: 'site_systems_details_description',
        defaultMessage: 'Performance details regarding this system and its services'
    },
    site_systems_details_no_disk_data: {
        id: 'site_systems_details_no_disk_data',
        defaultMessage: 'No Data'
    },
    site_systems_details_services_title: {
        id: 'site_systems_details_services_title',
        defaultMessage: 'Services ({count})'
    },
    general_datetime_days: {
        id: 'general_datetime_days',
        defaultMessage: 'days'
    },
    general_datetime_hours: {
        id: 'general_datetime_hours',
        defaultMessage: 'hours'
    },
    general_datetime_minutes: {
        id: 'general_datetime_minutes',
        defaultMessage: 'minutes'
    },
    general_datetime_seconds: {
        id: 'general_datetime_seconds',
        defaultMessage: 'seconds'
    },
    deploy_monitoring_plugin_title: {
        id: 'deploy_monitoring_plugin_title',
        defaultMessage: 'Create Monitoring Plugin Installer'
    },
    deploy_monitoring_plugin_floating_button_tooltip: {
        id: 'deploy_monitoring_plugin_floating_button_tooltip',
        defaultMessage: 'Create Monitoring Plugin Installer'
    },
    deploy_monitoring_plugin_description: {
        id: 'deploy_monitoring_plugin_description',
        defaultMessage: 'To deploy a new monitoring plugin fill out this form and download the monitoring plugin deploy zip package. The package includes an Monitoring Plugin Installer and all necessary configuration files. Follow the instructions on the Monitoring Plugin Installer Console Window.'
    },
    deploy_monitoring_plugin_warning_label: {
        id: 'deploy_monitoring_plugin_warning_label',
        defaultMessage: 'WARNING'
    },
    deploy_monitoring_plugin_input_warning_label: {
        id: 'deploy_monitoring_plugin_input_warning_label',
        defaultMessage: 'Install additional Umbrella Monitoring Plugins only on systems where no other Umbrella Monitoring Plugin is running. Each Umbrella Gateway has an Umbrella Monitoring Plugin included and systems running an Umbrella Gateway do not require another Umbrella Monitoring Plugin.'
    },
    deploy_monitoring_plugin_required_parameters_label: {
        id: 'deploy_monitoring_plugin_required_parameters_label',
        defaultMessage: 'Required parameters:'
    },
    deploy_monitoring_plugin_input_gateway_host_label: {
        id: 'deploy_monitoring_plugin_input_gateway_host_label',
        defaultMessage: 'Gateway Host:*'
    },
    deploy_monitoring_plugin_input_gateway_port_label: {
        id: 'deploy_monitoring_plugin_input_gateway_port_label',
        defaultMessage: 'Gateway Port:*'
    },
    deploy_monitoring_plugin_input_plugin_host_label: {
        id: 'deploy_monitoring_plugin_input_plugin_host_label',
        defaultMessage: 'Plugin Host:*'
    },
    deploy_monitoring_plugin_input_plugin_port_label: {
        id: 'deploy_monitoring_plugin_input_plugin_port_label',
        defaultMessage: 'Plugin Port:*'
    },
    deploy_monitoring_plugin_input_as_a_service_label: {
        id: 'deploy_monitoring_plugin_input_as_a_service_label',
        defaultMessage: 'Windows service:'
    },
    deploy_monitoring_plugin_input_service_name_label: {
        id: 'deploy_monitoring_plugin_input_service_name_label',
        defaultMessage: 'Windows service name:*'
    },
    deploy_monitoring_plugin_input_working_dir_label: {
        id: 'deploy_monitoring_plugin_input_working_dir_label',
        defaultMessage: 'Working directory:*'
    },
    deploy_monitoring_plugin_input_working_dir_tooltip: {
        id: 'deploy_monitoring_plugin_input_working_dir_tooltip',
        defaultMessage: 'Service path should be an absolute path and the folder should not exist yet, but will be created by the Monitoring Plugin installer.'
    },
    deploy_monitoring_plugin_download_installer_button: {
        id: 'deploy_monitoring_plugin_download_installer_button',
        defaultMessage: 'Download Installer'
    },
    deploy_monitoring_plugin_instructions: {
        id: 'deploy_monitoring_plugin_instructions',
        defaultMessage: 'Execute the Umbrella.Monitoring.Plugin.Installer.exe to deploy the monitoring plugin'
    },
    deploy_monitoring_plugin_input_gateway_host_placeholder: {
        id: 'deploy_monitoring_plugin_input_gateway_host_placeholder',
        defaultMessage: 'Gateway Host'
    },
    deploy_monitoring_plugin_input_gateway_port_placeholder: {
        id: 'deploy_monitoring_plugin_input_gateway_port_placeholder',
        defaultMessage: 'Gateway Port'
    },
    deploy_monitoring_plugin_input_plugin_host_placeholder: {
        id: 'deploy_monitoring_plugin_input_plugin_host_placeholder',
        defaultMessage: 'Plugin Host'
    },
    deploy_monitoring_plugin_input_plugin_port_placeholder: {
        id: 'deploy_monitoring_plugin_input_plugin_port_placeholder',
        defaultMessage: 'Plugin Port'
    },
    deploy_monitoring_plugin_input_service_name_placeholder: {
        id: 'deploy_monitoring_plugin_input_service_name_placeholder',
        defaultMessage: 'Windows service name'
    },
    deploy_monitoring_plugin_input_working_dir_placeholder: {
        id: 'deploy_monitoring_plugin_input_working_dir_placeholder',
        defaultMessage: 'Working directory'
    },
    deploy_monitoring_plugin_input_validation_port: {
        id: 'deploy_monitoring_plugin_input_validation_port',
        defaultMessage: 'Invalid port number (1 - 65535)'
    },
    deploy_monitoring_plugin_input_validation_working_dir: {
        id: 'deploy_monitoring_plugin_input_validation_working_dir',
        defaultMessage: 'Invalid working directory'
    },
    deploy_monitoring_plugin_input_validation_service_name: {
        id: 'deploy_monitoring_plugin_input_validation_service_name',
        defaultMessage: 'Invalid service name (only letters, numbers and underscore are allowed)'
    },
    deploy_monitoring_plugin_input_validation_host: {
        id: 'deploy_monitoring_plugin_input_validation_host',
        defaultMessage: 'Invalid host name'
    },
    deploy_monitoring_plugin_success_message: {
        id: 'deploy_monitoring_plugin_success_message',
        defaultMessage: 'Successfully downloaded deployment package'
    },
    deploy_monitoring_plugin_error_message: {
        id: 'deploy_monitoring_plugin_error_message',
        defaultMessage: 'Failed to create deployment package'
    },
    site_service_performance_service_state_label: {
        id: 'site_service_performance_service_state_label',
        defaultMessage: 'Service State'
    },
    site_service_performance_vms_state_tooltip: {
        id: 'site_service_performance_vms_state_tooltip',
        defaultMessage: 'The logical state from the Site plugin'
    },
    site_service_performance_service_state_tooltip: {
        id: 'site_service_performance_service_state_tooltip',
        defaultMessage: 'The operating system service state'
    },
    site_service_performance_vms_state_label: {
        id: 'site_service_performance_vms_state_label',
        defaultMessage: 'State'
    },
    site_service_performance_service_state_stopped: {
        id: 'site_service_performance_service_state_stopped',
        defaultMessage: 'STOPPED'
    },
    site_service_performance_service_state_running: {
        id: 'site_service_performance_service_state_running',
        defaultMessage: 'RUNNING'
    },
    site_service_performance_service_state_paused: {
        id: 'site_service_performance_service_state_paused',
        defaultMessage: 'PAUSED'
    },
    site_service_performance_service_state_offline: {
        id: 'site_service_performance_service_state_offline',
        defaultMessage: 'UNKNOWN'
    },
    site_service_performance_host: {
        id: 'site_service_performance_host',
        defaultMessage: 'Host'
    },
    vms_state_ok: {
        id: 'vms_state_ok',
        defaultMessage: 'OK'
    },
    vms_state_error: {
        id: 'vms_state_error',
        defaultMessage: 'ERROR'
    },
    vms_state_offline: {
        id: 'vms_state_offline',
        defaultMessage: 'UNKNOWN'
    },
    vms_state_disabled: {
        id: 'vms_state_disabled',
        defaultMessage: 'DISABLED'
    },
    site_service_performance_ignore_success: {
        id: 'site_service_performance_ignore_success',
        defaultMessage: 'Successful'
    },
    site_service_performance_ignore_error: {
        id: 'site_service_performance_ignore_error',
        defaultMessage: 'Failed'
    },
    site_service_performance_tooltip_ignore: {
        id: 'site_service_performance_tooltip_ignore',
        defaultMessage: 'Ignore selected services'
    },
    site_service_performance_start_success: {
        id: 'site_service_performance_start_success',
        defaultMessage: 'Successful'
    },
    site_service_performance_start_error: {
        id: 'site_service_performance_start_error',
        defaultMessage: 'Failed'
    },
    site_service_performance_stop_success: {
        id: 'site_service_performance_stop_success',
        defaultMessage: 'Successful'
    },
    site_service_performance_stop_error: {
        id: 'site_service_performance_stop_error',
        defaultMessage: 'Failed'
    },
    camera_performance_recording_state_label: {
        id: 'camera_performance_recording_state_label',
        defaultMessage: 'Recording State'
    },
    camera_performance_vms_state_label: {
        id: 'camera_performance_vms_state_label',
        defaultMessage: 'State'
    },
    camera_performance_vms_state_tooltip: {
        id: 'camera_performance_vms_state_tooltip',
        defaultMessage: 'The operating system service state'
    },
    camera_performance_recording_state_tooltip: {
        id: 'camera_performance_recording_state_tooltip',
        defaultMessage: 'The recording state from the Site plugin'
    },
    recording_state_ok: {
        id: 'recording_state_ok',
        defaultMessage: 'OK'
    },
    recording_state_limited: {
        id: 'recording_state_limited',
        defaultMessage: 'LIMITED'
    },
    recording_state_error: {
        id: 'recording_state_error',
        defaultMessage: 'ERROR'
    },
    recording_state_unknown: {
        id: 'recording_state_unknown',
        defaultMessage: 'UNKNOWN'
    },
    recording_state_disabled: {
        id: 'recording_state_disabled',
        defaultMessage: 'DISABLED'
    },
    camera_performance_ignore_success: {
        id: 'camera_performance_ignore_success',
        defaultMessage: 'Successful'
    },
    camera_performance_ignore_error: {
        id: 'camera_performance_ignore_error',
        defaultMessage: 'Failed'
    },
    camera_performance_tooltip_ignore: {
        id: 'camera_performance_tooltip_ignore',
        defaultMessage: 'Ignore selected cameras'
    },
    camera_performance_recording_statistics: {
        id: 'camera_performance_recording_statistics',
        defaultMessage: 'Recording Statistics'
    },
    norole_back_to_login: {
        id: 'norole_back_to_login',
        defaultMessage: 'Back to login'
    },
    norole_info_1: {
        id: 'norole_info_1',
        defaultMessage: 'The selected user has no assigned role(s)'
    },
    norole_info_2: {
        id: 'norole_info_2',
        defaultMessage: 'Please contact your administrator'
    },
    invalid_session_title: {
        id: 'invalid_session_title',
        defaultMessage: 'Connection Lost'
    },
    invalid_session_text: {
        id: 'invalid_session_text',
        defaultMessage: 'It appears that the Umbrella Core is currently unreachable. Please login again to begin a new session.'
    },
    invalid_session_button_text: {
        id: 'invalid_session_button_text',
        defaultMessage: 'Back to Login'
    },
    access_control_cardholders_table_head_cardholder: {
        id: 'access_control_cardholders_table_head_cardholder',
        defaultMessage: 'Card Holder'
    },
    access_control_cardholders_table_head_sites: {
        id: 'access_control_cardholders_table_head_sites',
        defaultMessage: 'Sites'
    },
    access_control_cardholders_table_head_labels: {
        id: 'access_control_cardholders_table_head_labels',
        defaultMessage: 'Labels'
    },
    access_control_cardholders_table_head_site_labels: {
        id: 'access_control_cardholders_table_head_site_labels',
        defaultMessage: 'Site Labels'
    },
    access_control_cardholders_toolbar_selected_cardholder: {
        id: 'access_control_cardholders_toolbar_selected_cardholder',
        defaultMessage: '{count} selected'
    },
    access_control_cardholders_toolbar_change_properties_tooltip: {
        id: 'access_control_cardholders_toolbar_change_properties_tooltip',
        defaultMessage: 'Change properties of selected card holders'
    },
    access_control_cardholders_details_update_success_message: {
        id: 'access_control_cardholders_details_update_success_message',
        defaultMessage: 'Card Holder successfully updated'
    },
    access_control_cardholders_details_update_error_message: {
        id: 'access_control_cardholders_details_update_error_message',
        defaultMessage: 'Failed to update card holder'
    },
    access_control_cardholders_details_title: {
        id: 'access_control_cardholders_details_title',
        defaultMessage: 'Below you can change the card holder parameters according to your preferences.'
    },
    access_control_cardholders_details_cardholder: {
        id: 'access_control_cardholders_details_cardholder',
        defaultMessage: 'Card Holder:'
    },
    access_control_cardholders_details_site: {
        id: 'access_control_cardholders_details_site',
        defaultMessage: 'Site:'
    },
    access_control_cardholders_details_labels: {
        id: 'access_control_cardholders_details_labels',
        defaultMessage: 'Labels:'
    },
    access_control_cardholders_details_update_button: {
        id: 'access_control_cardholders_details_update_button',
        defaultMessage: 'Update Details'
    },
    access_control_cardholders_details_tab_details: {
        id: 'access_control_cardholders_details_tab_details',
        defaultMessage: 'Details'
    },
    access_control_cardholders_details_properties_title: {
        id: 'access_control_cardholders_details_properties_title',
        defaultMessage: 'Below you can change the card holder properties according to your preferences.'
    },
    access_control_cardholders_details_properties_update_success_message: {
        id: 'access_control_cardholders_details_properties_update_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    access_control_cardholders_details_properties_update_error_message: {
        id: 'access_control_cardholders_details_properties_update_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    access_control_doors_table_head_door: {
        id: 'access_control_doors_table_head_door',
        defaultMessage: 'Door'
    },
    access_control_doors_table_head_sites: {
        id: 'access_control_doors_table_head_sites',
        defaultMessage: 'Sites'
    },
    access_control_doors_table_head_labels: {
        id: 'access_control_doors_table_head_labels',
        defaultMessage: 'Labels'
    },
    access_control_doors_table_head_site_labels: {
        id: 'access_control_doors_table_head_site_labels',
        defaultMessage: 'Site Labels'
    },
    access_control_doors_toolbar_selected_door: {
        id: 'access_control_doors_toolbar_selected_door',
        defaultMessage: '{count} selected'
    },
    access_control_doors_toolbar_change_properties_tooltip: {
        id: 'access_control_doors_toolbar_change_properties_tooltip',
        defaultMessage: 'Change properties of selected doors'
    },
    access_control_doors_details_update_success_message: {
        id: 'access_control_doors_details_update_success_message',
        defaultMessage: 'Door successfully updated'
    },
    access_control_doors_details_update_error_message: {
        id: 'access_control_doors_details_update_error_message',
        defaultMessage: 'Failed to update door'
    },
    access_control_doors_details_title: {
        id: 'access_control_doors_details_title',
        defaultMessage: 'Below you can change the Door parameters according to your preferences.'
    },
    access_control_doors_details_door: {
        id: 'access_control_doors_details_door',
        defaultMessage: 'Door:'
    },
    access_control_doors_details_site: {
        id: 'access_control_doors_details_site',
        defaultMessage: 'Site:'
    },
    access_control_doors_details_labels: {
        id: 'access_control_doors_details_labels',
        defaultMessage: 'Labels:'
    },
    access_control_doors_details_update_button: {
        id: 'access_control_doors_details_update_button',
        defaultMessage: 'Update Details'
    },
    access_control_doors_details_tab_details: {
        id: 'access_control_doors_details_tab_details',
        defaultMessage: 'Details'
    },
    access_control_doors_details_properties_title: {
        id: 'access_control_doors_details_properties_title',
        defaultMessage: 'Below you can change the door properties according to your preferences.'
    },
    access_control_doors_details_properties_update_success_message: {
        id: 'access_control_doors_details_properties_update_success_message',
        defaultMessage: 'Job(s) successfully created'
    },
    access_control_doors_details_properties_update_error_message: {
        id: 'access_control_doors_details_properties_update_error_message',
        defaultMessage: 'Failed to create jobs'
    },
    property_systemmessages_name_dbloadwarning: {
        id: 'property_systemmessages_name_dbloadwarning',
        defaultMessage: 'The configuration and event database is almost full'
    },
    property_systemmessages_name_dbloademergencyshutdown: {
        id: 'property_systemmessages_name_dbloademergencyshutdown',
        defaultMessage: 'The configuration and event database is full'
    },
    property_systemmessages_name_dbcyclicbackupfailed: {
        id: 'property_systemmessages_name_dbcyclicbackupfailed',
        defaultMessage: 'Cyclical backup of configuration and event database failed'
    },
    property_systemmessages_name_reinvalidstatus: {
        id: 'property_systemmessages_name_reinvalidstatus',
        defaultMessage: 'Service monitoring check failed'
    },
    property_systemmessages_name_licencechangedsucceeded: {
        id: 'property_systemmessages_name_licencechangedsucceeded',
        defaultMessage: 'License file changed successfully'
    },
    property_systemmessages_name_licencechangedfailed: {
        id: 'property_systemmessages_name_licencechangedfailed',
        defaultMessage: 'Error changing the license file'
    },
    property_systemmessages_name_mdbcannotmountzone: {
        id: 'property_systemmessages_name_mdbcannotmountzone',
        defaultMessage: 'Can not mount MDB zone'
    },
    property_systemmessages_name_mdbnomorezonespace: {
        id: 'property_systemmessages_name_mdbnomorezonespace',
        defaultMessage: 'MDB zone is full'
    },
    property_systemmessages_name_mdbcannotstartmds: {
        id: 'property_systemmessages_name_mdbcannotstartmds',
        defaultMessage: 'Can not start MDB'
    },
    property_systemmessages_name_servertimechanged: {
        id: 'property_systemmessages_name_servertimechanged',
        defaultMessage: 'System time changed'
    },
    property_systemmessages_name_cmcannotstart: {
        id: 'property_systemmessages_name_cmcannotstart',
        defaultMessage: 'Can not start DM'
    },
    property_systemmessages_name_cmdeviceconnectivityproblem: {
        id: 'property_systemmessages_name_cmdeviceconnectivityproblem',
        defaultMessage: 'Device connectivity problem'
    },
    property_systemmessages_name_videoloss: {
        id: 'property_systemmessages_name_videoloss',
        defaultMessage: 'Loss of video signal at encoder deteceted ( if supported by encoder )'
    },
    property_systemmessages_name_tampering: {
        id: 'property_systemmessages_name_tampering',
        defaultMessage: 'Tampering alarm ( if supported by encoder )'
    },
    property_systemmessages_name_donglemissing: {
        id: 'property_systemmessages_name_donglemissing',
        defaultMessage: 'Can not find LPR dongle'
    },
    property_systemmessages_name_avexportjobfailed: {
        id: 'property_systemmessages_name_avexportjobfailed',
        defaultMessage: 'AV export failed'
    },
    property_systemmessages_name_avexportjobold: {
        id: 'property_systemmessages_name_avexportjobold',
        defaultMessage: 'AV export more than two hours old'
    },
    property_systemmessages_name_avexportjobolder: {
        id: 'property_systemmessages_name_avexportjobolder',
        defaultMessage: 'AV export more than two four old'
    },
    property_systemmessages_name_mdbzonealmostfull: {
        id: 'property_systemmessages_name_mdbzonealmostfull',
        defaultMessage: 'MDB Zone almost full'
    },
    property_systemmessages_name_systemalertsbadsignal: {
        id: 'property_systemmessages_name_systemalertsbadsignal',
        defaultMessage: 'Failure of image analysis due to poor light'
    },
    property_systemmessages_name_systemalertsunknownview: {
        id: 'property_systemmessages_name_systemalertsunknownview',
        defaultMessage: 'Failed to analyze image due to distortion'
    },
    property_systemmessages_name_systemalertsrestoredview: {
        id: 'property_systemmessages_name_systemalertsrestoredview',
        defaultMessage: 'Restoration of failed image analysis'
    },
    property_systemmessages_name_coresubunreachable: {
        id: 'property_systemmessages_name_coresubunreachable',
        defaultMessage: 'Sub Core unreachable'
    },
    property_systemmessages_name_mdblaststandardimagetooold: {
        id: 'property_systemmessages_name_mdblaststandardimagetooold',
        defaultMessage: 'The last archive image is older than configured'
    },
    property_systemmessages_name_flatfileloggingcannotwritesummaryfile: {
        id: 'property_systemmessages_name_flatfileloggingcannotwritesummaryfile',
        defaultMessage: 'FlatFileLogging can not write summary file'
    },
    property_systemmessages_name_flatfileloggingcannotwritealarmfile: {
        id: 'property_systemmessages_name_flatfileloggingcannotwritealarmfile',
        defaultMessage: 'FlatFileLogging can not write alarm file'
    },
    property_systemmessages_name_vavideolost: {
        id: 'property_systemmessages_name_vavideolost',
        defaultMessage: 'VA service hast lost video signale'
    },
    property_systemmessages_name_vavideoavailable: {
        id: 'property_systemmessages_name_vavideoavailable',
        defaultMessage: 'VA service video signal recovered'
    },
    property_systemmessages_name_vathirdpartycommunicationlost: {
        id: 'property_systemmessages_name_vathirdpartycommunicationlost',
        defaultMessage: 'VA communication with third-party system lost'
    },
    property_systemmessages_name_vathirdpartycommunicationavailable: {
        id: 'property_systemmessages_name_vathirdpartycommunicationavailable',
        defaultMessage: 'VA communication with third-party system restored'
    },
    property_systemmessages_name_restart: {
        id: 'property_systemmessages_name_restart',
        defaultMessage: 'Services have been started/restarted'
    },
    property_systemmessages_name_restop: {
        id: 'property_systemmessages_name_restop',
        defaultMessage: 'Services have been stopped'
    },
    property_systemmessages_name_dmthresholdsvaluesexceeded: {
        id: 'property_systemmessages_name_dmthresholdsvaluesexceeded',
        defaultMessage: 'Threshhold values for DM statistic have been exceeded'
    },
    property_systemmessages_name_mdbdtatisticsnotavailable: {
        id: 'property_systemmessages_name_mdbdtatisticsnotavailable',
        defaultMessage: 'MDB statistics are not available'
    },
    property_systemmessages_name_mdbautomatedvideobackupstarted: {
        id: 'property_systemmessages_name_mdbautomatedvideobackupstarted',
        defaultMessage: 'MDB automated video backup started'
    },
    property_systemmessages_name_mdbautomatedvideobackupcompleted: {
        id: 'property_systemmessages_name_mdbautomatedvideobackupcompleted',
        defaultMessage: 'MDB automated video backup completed'
    },
    property_systemmessages_name_mdbautomatedvideobackupinterrupted: {
        id: 'property_systemmessages_name_mdbautomatedvideobackupinterrupted',
        defaultMessage: 'MDB automated video backup interrupted'
    },
    property_systemmessages_name_edgestorageimportssuccessful: {
        id: 'property_systemmessages_name_edgestorageimportssuccessful',
        defaultMessage: 'Edge storage import was successfull'
    },
    property_systemmessages_name_edgestorageimportinterrupted: {
        id: 'property_systemmessages_name_edgestorageimportinterrupted',
        defaultMessage: 'Edge storage import was interrupted'
    },
    property_systemmessages_name_coreslavereachableagain: {
        id: 'property_systemmessages_name_coreslavereachableagain',
        defaultMessage: 'Sub Core reachable again'
    },
    property_systemmessages_name_userdeactivatedafterfailedloginattempts: {
        id: 'property_systemmessages_name_userdeactivatedafterfailedloginattempts',
        defaultMessage: 'User deactivated'
    },
    property_systemmessages_name_cmdeviceconnectivityproblemresolved: {
        id: 'property_systemmessages_name_cmdeviceconnectivityproblemresolved',
        defaultMessage: 'Device connectivity problem resolved'
    },
    property_systemmessages_connection_user_subscribed: {
        id: 'property_systemmessages_connection_user_subscribed',
        defaultMessage: 'Subscribe to event:'
    },
    property_systemmessages_deadtime: {
        id: 'property_systemmessages_deadtime',
        defaultMessage: 'Deadtime (seconds): '
    },
    property_systemmessages_affected_service: {
        id: 'property_systemmessages_affected_service',
        defaultMessage: 'Affected Services:'
    },
    configure_threshold_sites_title: {
        id: 'configure_threshold_sites_title',
        defaultMessage: 'Configure thresholds for selected Site(s)'
    },
    configure_threshold_services_title: {
        id: 'configure_threshold_services_title',
        defaultMessage: 'Configure thresholds for selected Service(s)'
    },
    configure_threshold_systems_title: {
        id: 'configure_threshold_systems_title',
        defaultMessage: 'Configure thresholds for selected System(s)'
    },
    configure_threshold_sites_description: {
        id: 'configure_threshold_sites_description',
        defaultMessage: 'You are about to change the thresholds for the selected sites. You can either set custom thresholds for the selected sites or use the default values from the settings.'
    },
    configure_threshold_services_description: {
        id: 'configure_threshold_services_description',
        defaultMessage: 'You are about to change the thresholds for the selected services. You can either set custom thresholds for the selected services or use the default values. These default values will be either from the settings or from the thresholds set for the related site.'
    },
    configure_threshold_systems_description: {
        id: 'configure_threshold_systems_description',
        defaultMessage: 'You are about to change the thresholds for the selected systems. You can either set custom thresholds for the selected systems or use the default values. These default values will be either from the settings or from the thresholds set for the related site.'
    },
    configure_threshold_cpu: {
        id: 'configure_threshold_cpu',
        defaultMessage: 'Thresholds CPU (%):'
    },
    configure_threshold_memory: {
        id: 'configure_threshold_memory',
        defaultMessage: 'Thresholds Memory (%):'
    },
    configure_threshold_disk: {
        id: 'configure_threshold_disk',
        defaultMessage: 'Thresholds Disk (%):'
    },
    configure_threshold_network: {
        id: 'configure_threshold_network',
        defaultMessage: 'Thresholds Network (%):'
    },
    configure_threshold_use_default_label: {
        id: 'configure_threshold_use_default_label',
        defaultMessage: 'Use default values'
    },
    configure_threshold_button_label: {
        id: 'configure_threshold_button_label',
        defaultMessage: 'Update'
    },
    threshold_type_unknown: {
        id: 'threshold_type_unknown',
        defaultMessage: 'Unknown'
    },
    threshold_type_entity: {
        id: 'threshold_type_entity',
        defaultMessage: 'Entity'
    },
    threshold_type_site: {
        id: 'threshold_type_site',
        defaultMessage: 'Site'
    },
    threshold_type_settings: {
        id: 'threshold_type_settings',
        defaultMessage: 'Settings'
    },
    threshold_type_prefix: {
        id: 'threshold_type_prefix',
        defaultMessage: 'Source:'
    },
    labels_table_head_name: {
        id: 'labels_table_head_name',
        defaultMessage: 'Name'
    },
    apiKeys_table_head_apikey: {
        id: 'apiKeys_table_head_apikey',
        defaultMessage: 'API Key'
    },
    apiKeys_table_head_creator: {
        id: 'apiKeys_table_head_creator',
        defaultMessage: 'Creator'
    },
    apiKeys_table_head_creationdate: {
        id: 'apiKeys_table_head_creationdate',
        defaultMessage: 'Creation Date'
    },
    apiKeys_create: {
        id: 'apiKeys_create',
        defaultMessage: 'Create API Key'
    },
    apikey_created_success: {
        id: 'apikey_created_success',
        defaultMessage: 'API Key successfully created'
    },
    apikey_created_error: {
        id: 'apikey_created_error',
        defaultMessage: 'Failed to create API Key'
    },
    apikey_delete_sucess: {
        id: 'apikey_delete_sucess',
        defaultMessage: 'API Key successfully deleted'
    },
    apikey_delete_error: {
        id: 'apikey_delete_error',
        defaultMessage: 'Failed to delete API Key'
    },
    apiKey_delete_tooltip: {
        id: 'apiKey_delete_tooltip',
        defaultMessage: 'Delete Api Key(s)'
    },
    edit_label_title: {
        id: 'edit_label_title',
        defaultMessage: 'Change the name of a label'
    },
    edit_label_description: {
        id: 'edit_label_description',
        defaultMessage: 'You are about to change the name of a label. '
    },
    edit_label_name_label: {
        id: 'edit_label_name_label',
        defaultMessage: 'Name:'
    },
    edit_label_button_label: {
        id: 'edit_label_button_label',
        defaultMessage: 'Update'
    },
    edit_label_error_unique_labelname: {
        id: 'edit_label_error_unique_labelname',
        defaultMessage: 'Label name is already taken'
    },
    multiconfig_labels_title: {
        id: 'multiconfig_labels_title',
        defaultMessage: 'Change common labels'
    },
    multiconfig_labels_description: {
        id: 'multiconfig_labels_description',
        defaultMessage: 'Here you can edit labels for the selected Sites.'
    },
    multiconfig_labels_labels_label: {
        id: 'multiconfig_labels_labels_label',
        defaultMessage: 'Labels:'
    },
    multiconfig_labels_button_label: {
        id: 'multiconfig_labels_button_label',
        defaultMessage: 'Update'
    },
    multiconfig_labels_overwrite_label: {
        id: 'multiconfig_labels_overwrite_label',
        defaultMessage: 'Override:'
    },
    multiconfig_labels_success_message: {
        id: 'multiconfig_labels_success_message',
        defaultMessage: 'Label(s) successfully updated'
    },
    multiconfig_labels_error_message: {
        id: 'multiconfig_labels_error_message',
        defaultMessage: 'Failed to update label(s)'
    },
    multiconfig_label_override_warning_title: {
        id: 'multiconfig_label_override_warning_title',
        defaultMessage: 'Warning'
    },
    multiconfig_label_override_warning_message: {
        id: 'multiconfig_label_override_warning_message',
        defaultMessage: 'Attention! You activated override mode. This will remove all existing labels and then apply the selected labels above.'
    },
    notification_priority_title: {
        id: 'notification_priority_title',
        defaultMessage: 'Change notification priority'
    },
    notification_priority_description: {
        id: 'notification_priority_description',
        defaultMessage: 'Here you can change the desired notification level for the selected entities'
    },
    notification_priority_priority_label: {
        id: 'notification_priority_priority_label',
        defaultMessage: 'Notification priority:'
    },
    notification_priority_high: {
        id: 'notification_priority_high',
        defaultMessage: 'High'
    },
    notification_priority_normal: {
        id: 'notification_priority_normal',
        defaultMessage: 'Normal'
    },
    notification_priority_low: {
        id: 'notification_priority_low',
        defaultMessage: 'Low'
    },
    site_events_group_resolved_tooltip_group: {
        id: 'site_events_group_resolved_tooltip_group',
        defaultMessage: 'Group resolved issues'
    },
    site_events_group_resolved_tooltip_ungroup: {
        id: 'site_events_group_resolved_tooltip_ungroup',
        defaultMessage: 'Show flat list'
    },
    error_failed_request: {
        id: 'error_failed_request',
        defaultMessage: 'Request Failed'
    }
}