import { EProfileRights } from 'enums/enums'
import { Layout } from 'react-grid-layout'
import { TLicense } from 'types/businessLogic/license'
import { TLayoutElement, TProfileDashboardLayout } from 'types/businessLogic/profile'
import { EDashboardLayouts, EDashboardElements } from 'types/ui/dashboard'

class DashboardHelper {
    public static recalculateDashboardAdvancedData(position: number, advancedData: string) : Layout[] {
        let data: Layout[] = []
        if (advancedData) {
            data = [...JSON.parse(advancedData)]
        }
                
        if (data) {
            const defaultNewElementWidth = 4
            const defaultNewElementHeight = 5
                
            let farthestYAxisElements = [...data].sort((a: Layout, b: Layout) => b.y - a.y)
            
            farthestYAxisElements = farthestYAxisElements.filter((layout: Layout) => layout.y === farthestYAxisElements[0].y)
            farthestYAxisElements.sort((a: Layout, b: Layout) => (b.y + b.h) - (a.y + a.h))
            farthestYAxisElements.sort((a: Layout, b: Layout) => b.x - a.x)

            const farthestXYElement = farthestYAxisElements[0]
            
            if (!farthestXYElement) {
                data[position] = {
                    w: defaultNewElementWidth, h: defaultNewElementHeight, x: 0, y: 0, i: position.toString(),
                    minW: defaultNewElementWidth, minH: defaultNewElementHeight
                }
            } else {
                const firstFreeXAxisSpot = farthestXYElement.x + farthestXYElement.w
                const maximumRowWidth = 12
                const isLastElement = Number(farthestXYElement.i) === data.length - 1
    
                let newElementY = farthestXYElement.y
                let newElementX = firstFreeXAxisSpot
    
                if (newElementX + defaultNewElementWidth > maximumRowWidth || !isLastElement) {
                    newElementY = newElementY + farthestXYElement.h
                    newElementX = 0
                }
                data[position] = {
                    w: defaultNewElementWidth, h: defaultNewElementHeight, x: newElementX, y: newElementY, i: position.toString(),
                    minW: defaultNewElementWidth, minH: defaultNewElementHeight
                }
            }
            
        }

        return data
    }

    public static buildDefaultLayout(licenseInfo: TLicense, rights: EProfileRights[]) : TProfileDashboardLayout {
        let layout = EDashboardLayouts.OneByOne
        let layoutElements : TLayoutElement[] = []

        if (licenseInfo.situatorExclusive) {
            layout = EDashboardLayouts.TwoByTwo
            layoutElements = [
                { layoutElement: EDashboardElements.SiteStates },
                { layoutElement: EDashboardElements.SiteServices },
                { layoutElement: EDashboardElements.RecentSiteEvents },
                { layoutElement: EDashboardElements.RecentJobs }
            ]
        } else {
            if (licenseInfo.monitoring && rights.includes(EProfileRights.HAS_MONITORING_RIGHT)) {
                layout = EDashboardLayouts.Five
                layoutElements = [
                    { layoutElement: EDashboardElements.Map },
                    { layoutElement: EDashboardElements.RecentSiteEvents },
                    { layoutElement: EDashboardElements.SiteStates },
                    { layoutElement: EDashboardElements.SiteServices },
                    { layoutElement: EDashboardElements.Cameras }
                ]

                if (licenseInfo.configuration) {
                    layoutElements[1] = { layoutElement: EDashboardElements.RecentJobs }
                }
            } else if (licenseInfo.configuration) {
                layout = EDashboardLayouts.TwoByTwo
                layoutElements = [
                    { layoutElement: EDashboardElements.Map },
                    { layoutElement: EDashboardElements.RecentJobs },
                    { layoutElement: EDashboardElements.SiteStates },
                    { layoutElement: EDashboardElements.SiteApprovals }
                ]
            } else if (licenseInfo.report) {
                layout = EDashboardLayouts.OneByTwo
                layoutElements = [
                    { layoutElement: EDashboardElements.Map },
                    { layoutElement: EDashboardElements.SiteStates },
                    { layoutElement: EDashboardElements.SiteApprovals }
                ]
            }
        }

        return {
            advancedData: '',
            layout: layout,
            layoutElements: layoutElements
        }
    }
}

export default DashboardHelper