import { yupResolver } from '@hookform/resolvers/yup'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormAsyncSelect } from 'components/FormControls/FormAsyncSelect'
import { FormWrapper, FormContent, FormGroup, InputWrapper, FormLabel, FormInput, FormCheckbox, Relative, FormFooter, FormButtonGroup, FormInfo } from 'components/FormControls/FormControls'
import { FormCreatableSelect } from 'components/FormControls/FormCreatableSelect'
import FormRightsList from 'components/FormControls/FormRightsList'
import { FormSelect } from 'components/FormControls/FormSelect'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import Validation from 'helpers/Validation'
import LabelService from 'services/labelService'
import SiteService from 'services/siteService'
import UmbrellaRolesService from 'services/umbrellaRolesService'
import UmbrellaUserService from 'services/umbrellaUserService'
import { TUmbrellaRoleDetail } from 'types/businessLogic/role'
import { TQueryFilter } from 'types/network'
import * as yup from 'yup'
import { debounce } from '@mui/material'
import colors from 'style/colors'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import IconWrapper from 'components/Icon/IconWrapper'
import { TSelectOption, TSelectOptions } from 'types/ui/dropdown'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'
import { dispatch } from 'state/store'

type TProps = {
    umbrellaRole?: TUmbrellaRoleDetail
    handleUmbrellaRoleSubmit: (formValues: TUmbrellaRoleDetailsForm) => void
}

export type TUmbrellaRoleDetailsForm = {
    id?: string
    name: string
    umbrellaAdmin: boolean
    siteAdmin: boolean
    hasReportsRight: boolean
    hasSiteMaintenanceReportRight: boolean
    hasSiteAuditReportRight: boolean
    users: TSelectOptions
    sites: TSelectOptions
    siteLabels: TSelectOptions
    activeDirectoryDomainAndSid?: TSelectOption
    vmsEventCategorySubscription: TSelectOptions
    vmsEntityTypeSubscription: TSelectOptions
    filter: TSelectOptions
    sendEmail: boolean
    sendEmailToAddGroup: boolean
    hasApproveSitesRight: boolean
    hasDeploySitesRight: boolean
    hasDeleteSitesRight: boolean
    hasManageFirmwaresRight: boolean
    hasRestartSiteEntitiesRight: boolean
    hasMonitoringRight: boolean
    hasIgnoreRight: boolean
}

const UmbrellaRoleForm = (props: TProps) => {
    const {umbrellaRole} = props
    const {handleUmbrellaRoleSubmit} = props
    const [nameNotTaken, setNameNotTaken] = useState(true)
    const intl = useIntl()

    const validationSchema = yup.object().shape({
        name: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)).test('name-not-taken', intl.formatMessage(i18nKeys.umbrella_role_form_error_unique_role), () => nameNotTaken)
    })

    const eventCategoryOptions: TSelectOptions = useMemo(() => [
        {value: 0, label: intl.formatMessage({...i18nKeys.site_event_category_info})},
        {value: 1, label: intl.formatMessage({...i18nKeys.site_event_category_warning})},
        {value: 2, label: intl.formatMessage({...i18nKeys.site_event_category_error})}
    ], [intl])

    const entityTypeOptions: TSelectOptions = useMemo(() => [
        {value: 0, label: intl.formatMessage({...i18nKeys.site_event_entity_type_camera})},
        {value: 1, label: intl.formatMessage({...i18nKeys.site_event_entity_type_runtime})},
        {value: 2, label: intl.formatMessage({...i18nKeys.site_event_entity_type_user})},
        {value: 3, label: intl.formatMessage({...i18nKeys.site_event_entity_type_group})},
        {value: 4, label: intl.formatMessage({...i18nKeys.site_event_entity_type_site})},
        {value: 5, label: intl.formatMessage({...i18nKeys.site_event_entity_type_system})}
    ], [intl])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const siteOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            loadSites(inputText).then((options) => callback(options))
        }, 500), [])

    const loadADGroups = async (inputValue: string) => {
        const filters : TQueryFilter[] = []

        if (inputValue.length > 0) {
            filters.push({
                property: 'Name',
                value: inputValue,
                isExact: false
            })
        }

        try {
            const adGroups = await UmbrellaRolesService.getRoleActiveDirectoryGroups({
                PageNumber: 1,
                PageSize: 20,
                Filters: filters
            })

            return adGroups.map(adGroup => {
                return {
                    value: adGroup.sid,
                    label: adGroup.name
                }
            })
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const adgroupsOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            loadADGroups(inputText).then((options) => callback(options))
        }, 500), [])

    const loadUmbrellaUsers = async (inputValue: string) => {
        const filters : TQueryFilter[] = [{
            property: 'ActiveDirectory',
            value: 'false',
            isExact: false
        }]

        if (inputValue.length > 0) {
            filters.push({
                property: 'UserName',
                value: inputValue,
                isExact: false
            })
        }

        try {
            const umbrellaUsersResponse = await UmbrellaUserService.getUsers({
                PageNumber: 1,
                PageSize: 20,
                Filters: filters
            })
    
            const users = umbrellaUsersResponse.users
            const indexAdministrator = users.findIndex(user => user.userName === 'administrator')
            if (indexAdministrator !== -1) {
                users.splice(indexAdministrator, 1)
            }

            return users.map(user => {
                return {
                    value: user.id,
                    label: user.userName
                }
            })
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const umbrellaUserOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            loadUmbrellaUsers(inputText).then((options) => callback(options))
        }, 500), [])

    const loadSiteLabels = async (inputValue: string) => {
        const filters : TQueryFilter[] = []

        if (inputValue.length > 0) {
            filters.push({
                property: 'Name',
                value: inputValue,
                isExact: false
            })
        }
        
        try {
            const siteLabels = await LabelService.getSiteLabels({
                PageNumber: 1,
                PageSize: 20,
                Filters: filters
            })

            return siteLabels.map(siteLabel => {
                return {
                    value: siteLabel.id,
                    label: siteLabel.name
                }
            })
        } catch (error) {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const siteLabelsOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            loadSiteLabels(inputText).then((options) => callback(options))
        }, 500), [])

    let umbrellaRoleDefaults : TUmbrellaRoleDetailsForm = {
        name: '',
        umbrellaAdmin: false,
        siteAdmin: false,
        hasReportsRight: false,
        hasSiteMaintenanceReportRight: false,
        hasSiteAuditReportRight: false,
        users: [],
        sites: [],
        siteLabels: [],
        activeDirectoryDomainAndSid: undefined,
        vmsEventCategorySubscription: [],
        vmsEntityTypeSubscription: [],
        filter: [],
        sendEmail: false,
        sendEmailToAddGroup: false,
        hasApproveSitesRight: false,
        hasDeploySitesRight: false,
        hasDeleteSitesRight: false,
        hasManageFirmwaresRight: false,
        hasRestartSiteEntitiesRight: false,
        hasMonitoringRight: false,
        hasIgnoreRight: false
    }

    if (umbrellaRole) {
        umbrellaRoleDefaults = {
            ...umbrellaRole,
            vmsEventCategorySubscription: umbrellaRole.vmsEventCategorySubscription.map(cat => {
                return {
                    label: eventCategoryOptions.find(option => option.value === cat)!.label,
                    value: cat
                }
            }),
            vmsEntityTypeSubscription: umbrellaRole.vmsEntityTypeSubscription.map(entityType => {
                return {
                    label: entityTypeOptions.find(option => option.value === entityType)!.label,
                    value: entityType
                }
            }),
            activeDirectoryDomainAndSid: umbrellaRole.activeDirectoryGroup ? {
                value: umbrellaRole.activeDirectoryGroup.sid,
                label: umbrellaRole.activeDirectoryGroup.name
            } : undefined,
            users: umbrellaRole.users.map(user => {
                return {
                    value: user.id,
                    label: user.name
                }
            }),
            sites: umbrellaRole?.umbrellaAdmin ?
                [{
                    label: intl.formatMessage(i18nKeys.umbrella_role_form_admin_rights_include_all_sites),
                    value: ''
                }] :
                umbrellaRole.sites.map(site => {
                    return {
                        value: site.id!,
                        label: site.name
                    }
                }),
            siteLabels: umbrellaRole?.umbrellaAdmin ?
                [{
                    label: intl.formatMessage(i18nKeys.umbrella_role_form_admin_rights_include_all_sites),
                    value: ''
                }] : umbrellaRole.siteLabels.map(siteLabel => {
                    return {
                        value: siteLabel.id,
                        label: siteLabel.name
                    }
                }),
            filter: umbrellaRole.filter.map(filterString => {
                return {
                    value: filterString,
                    label: filterString
                }
            })
        }
    }

    const { handleSubmit, formState, reset, control, setValue, watch, trigger } = useForm<TUmbrellaRoleDetailsForm>({
        mode: 'onChange',
        defaultValues: umbrellaRoleDefaults,
        resolver: yupResolver(validationSchema)
    })

    const watchIsUmbrellaAdmin = watch('umbrellaAdmin')

    const { isValid, isValidating, isSubmitting, isDirty } = formState
        
    const loadSites = async (inputValue: string) => {
        const filters : TQueryFilter[] = []

        if (inputValue.length > 0) {
            filters.push({
                property: 'Name',
                value: inputValue,
                isExact: false
            })
        }
        
        try {
            const sitesResponse = await SiteService.getSites({
                PageNumber: 1,
                PageSize: 20,
                Filters: filters
            })

            return sitesResponse.sites.map(site => {
                return {
                    value: site.id,
                    label: site.name
                }
            })
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleNameChange = useCallback(debounce((value: string) => {
        Validation.isUmbrellaRoleNameNotTaken(value, '').then(result => {
            setNameNotTaken(result)
            trigger('name')
        })
    }, 250), [])

    return (
        <FormWrapper onSubmit={handleSubmit(handleUmbrellaRoleSubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_site_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='name'>
                            <TransMessage {...i18nKeys.umbrella_role_form_label_role_name} />
                        </FormLabel>
                        <FormInput
                            name='name'
                            control={control}
                            disabled={umbrellaRole?.builtIn}
                            onChange={handleNameChange}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='activeDirectoryDomainAndSid'>
                            <TransMessage {...i18nKeys.umbrella_role_form_label_active_directory} />
                        </FormLabel>
                        <FormAsyncSelect
                            name='activeDirectoryDomainAndSid'
                            control={control}
                            loadOptions={adgroupsOptions}
                            disabled={umbrellaRole?.builtIn}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='umbrellaAdmin'>
                            <TransMessage {...i18nKeys.umbrella_role_form_label_umbrella_administrator} />
                        </FormLabel>
                        <FormCheckbox
                            name='umbrellaAdmin'
                            control={control}
                            disabled={umbrellaRole?.builtIn}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const value: TSelectOptions = event.target.checked ? [{
                                    label: intl.formatMessage(i18nKeys.umbrella_role_form_admin_rights_include_all_sites),
                                    value: ''
                                }] : []

                                setValue('sites' as keyof TUmbrellaRoleDetailsForm, value)
                                setValue('siteLabels' as keyof TUmbrellaRoleDetailsForm, value)
                            }}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormRightsList
                            disabled={umbrellaRole?.builtIn}
                            setValue={setValue}
                            defaultvalue={umbrellaRoleDefaults.siteAdmin}
                            parent={{
                                control: control,
                                id:'siteAdmin',
                                label:{...i18nKeys.umbrella_role_form_label_site_administrator}
                            }}
                            listItems={
                                [
                                    { control: control,
                                        id:'hasApproveSitesRight',
                                        label:{...i18nKeys.umbrella_role_form_rights_approve_gateway}
                                    },
                                    { control: control,
                                        id:'hasDeploySitesRight',
                                        label:{...i18nKeys.umbrella_role_form_rights_deploy_gateway}
                                    },
                                    { control: control,
                                        id:'hasDeleteSitesRight',
                                        label:{...i18nKeys.umbrella_role_form_rights_delete_gateway}
                                    },
                                    { control: control,
                                        id:'hasManageFirmwaresRight',
                                        label:{...i18nKeys.umbrella_role_form_rights_upload_firmware}
                                    }
                                ]
                            }
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormRightsList
                            disabled={umbrellaRole?.builtIn}
                            setValue={setValue}
                            defaultvalue={umbrellaRoleDefaults.hasReportsRight}
                            parent={{
                                control: control,
                                id:'hasReportsRight',
                                label:{...i18nKeys.umbrella_role_form_rights_reports_default}
                            }}
                            listItems={
                                [
                                    { control: control,
                                        id:'hasSiteMaintenanceReportRight',
                                        label:{...i18nKeys.umbrella_role_form_rights_reports_site_maintenance}
                                    },
                                    { control: control,
                                        id:'hasSiteAuditReportRight',
                                        label:{...i18nKeys.umbrella_role_form_rights_reports_site_audit}
                                    }
                                ]
                            }
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormRightsList
                            disabled={umbrellaRole?.builtIn}
                            setValue={setValue}
                            defaultvalue={umbrellaRoleDefaults.hasMonitoringRight}
                            parent={{
                                control: control,
                                id:'hasMonitoringRight',
                                label:{...i18nKeys.umbrella_role_form_rights_monitoring}
                            }}
                            listItems={
                                [
                                    { control: control,
                                        id:'hasRestartSiteEntitiesRight',
                                        label:{...i18nKeys.umbrella_role_form_rights_restart_camera_and_sites}
                                    },
                                    { control: control,
                                        id:'hasIgnoreRight',
                                        label:{...i18nKeys.umbrella_role_form_rights_ignore}
                                    }
                                ]
                            }
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='sites'>
                            <TransMessage {...i18nKeys.umbrella_role_form_label_sites} />
                        </FormLabel>
                        <FormAsyncSelect
                            name='sites'
                            control={control}
                            isMulti
                            loadOptions={siteOptions}
                            defaultOptions
                            disabled={umbrellaRole?.builtIn || watchIsUmbrellaAdmin}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='siteLabels'>
                            <TransMessage {...i18nKeys.umbrella_role_form_label_site_labels} />
                        </FormLabel>
                        <FormAsyncSelect
                            name='siteLabels'
                            control={control}
                            isMulti
                            loadOptions={siteLabelsOptions}
                            defaultOptions
                            disabled={umbrellaRole?.builtIn || watchIsUmbrellaAdmin}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='users'>
                            <TransMessage {...i18nKeys.umbrella_role_form_label_users} />
                        </FormLabel>
                        <FormAsyncSelect
                            name='users'
                            control={control}
                            isMulti
                            loadOptions={umbrellaUserOptions}
                            defaultOptions
                            disabled={umbrellaRole?.builtIn}
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup title={i18nKeys.umbrella_role_form_site_events_notifications}>
                    <InputWrapper>
                        <FormLabel htmlFor='vmsEventCategorySubscription'>
                            <TransMessage {...i18nKeys.umbrella_role_form_site_event_categories} />
                        </FormLabel>
                        <FormSelect
                            name='vmsEventCategorySubscription'
                            control={control}
                            isMulti
                            options={eventCategoryOptions}
                            disabled={umbrellaRole?.builtIn}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='vmsEntityTypeSubscription'>
                            <TransMessage {...i18nKeys.umbrella_role_form_site_entity_types} />
                        </FormLabel>
                        <FormSelect
                            name='vmsEntityTypeSubscription'
                            control={control}
                            isMulti
                            options={entityTypeOptions}
                            disabled={umbrellaRole?.builtIn}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='filter'>
                            <TransMessage {...i18nKeys.umbrella_role_form_contains_filter} />
                        </FormLabel>
                        <Relative>
                            <FormCreatableSelect
                                name='filter'
                                control={control}
                                isMulti
                                options={[]}
                                disabled={umbrellaRole?.builtIn}
                            />
                        </Relative>
                        <FormInfo>
                            <CustomTooltip title={intl.formatMessage({...i18nKeys.umbrella_role_form_tooltip_contains_filter})}>
                                <IconWrapper color={colors.primary.DEFAULT_PRIMARY} margin='0px 0px 0px 10px'>
                                    <Icon d={Icons.INFO} size={16} />
                                </IconWrapper>
                            </CustomTooltip>
                        </FormInfo>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='sendEmail'>
                            <TransMessage {...i18nKeys.umbrella_role_form_send_email} />
                        </FormLabel>
                        <FormCheckbox
                            name='sendEmail'
                            control={control}
                            disabled={umbrellaRole?.builtIn}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='sendEmailToAddGroup'>
                            <TransMessage {...i18nKeys.umbrella_role_form_send_email_to_ad_group} />
                        </FormLabel>
                        <FormCheckbox
                            name='sendEmailToAddGroup'
                            control={control}
                            disabled={umbrellaRole?.builtIn}
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                {
                    umbrellaRole ?
                        <FormButtonGroup>
                            <CustomButton
                                disabled={!isValid || isValidating || isSubmitting || !isDirty}
                                id='submitBtn'
                                type='submit'
                            >
                                <TransMessage {...i18nKeys.site_details_update_button} />
                            </CustomButton>
                            <CustomButton
                                id='resetBtn'
                                type='reset'
                                onClick={() => reset()}
                                $secondary
                            >
                                <TransMessage {...i18nKeys.site_details_reset_all_button} />
                            </CustomButton>
                        </FormButtonGroup>
                        :
                        <FormButtonGroup>
                            <CustomButton
                                disabled={!isValid || isValidating || isSubmitting || !isDirty}
                                id='submitBtn'
                                type='submit'
                                endIcon={<Icon d={Icons.TICK} />}
                            >
                                <TransMessage {...i18nKeys.create_umbrella_role_submit_button} />
                            </CustomButton>
                        </FormButtonGroup>
                }
                
            </FormFooter>
        </FormWrapper>
    )
}

export default UmbrellaRoleForm