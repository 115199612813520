import Icon, { Icons } from 'components/Icon/Icon'
import { i18nKeys } from 'i18n/keys'
import React, { PropsWithChildren } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'

const OuterContainer = styled.div`
    height: 100%;
    padding: 80px;
`
  
const Container = styled.div`
    cursor: pointer;
    outline: 0;
    height: calc(100% - 104px);
    width: 100%;
    border: 2px dashed ${colors.shades.DARK_SHADE};
    display: flex;
    place-items: center;
  `

const InnerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    fill: ${colors.shades.DEEP_SHADE};
`

const UploadText = styled.p`
    margin-top: 16;
    color: ${colors.shades.DEEP_SHADE};
    font-family: ${fonts.PRIMARY_REGULAR};
    font-size: ${typographyScale.TYPE16};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE16LS};
`

const InfoText = styled.p`
    margin-top: 75;
    color: ${colors.shades.DEEP_SHADE};
    font-family: ${fonts.PRIMARY_SEMIBOLD};
    font-size: ${typographyScale.TYPE14};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE14LS};
`

type TProps = {
    onDrop: (acceptedFiles: File[]) => void
}

const UploadSiteLicenses = (props: PropsWithChildren<TProps>) => {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxFiles: 1,
        onDrop: props.onDrop
    })
    
    return (
        <>
            {props.children}
            <OuterContainer>
                <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                    <input {...getInputProps()} />
                    <InnerContainer>
                        <Icon
                            d={Icons.UPLOAD}
                            size={72}
                        />
                        <UploadText>
                            <FormattedMessage
                                {...i18nKeys.site_license_update_upload_file_box_title} />
                        </UploadText>
                        <InfoText>
                            <FormattedMessage
                                {...i18nKeys.site_license_update_upload_file_ext_restriction} />
                        </InfoText>
                    </InnerContainer>
      
                </Container>
            </OuterContainer>
        </>
       
    )
}

export default UploadSiteLicenses