import { CardHeader } from 'components/CustomControls/CustomCharts'
import CustomMap from 'components/CustomMap/CustomMap'
import MapLegend from 'components/CustomMap/MapLegend'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`

const MapWrapper = styled.div`
    height: calc(100% - 60px);
`

const MapElement = () => {
    return <>
        <CardHeader title={i18nKeys.sites_with_location_heading} />
        <Wrapper>
            <MapWrapper>
                <CustomMap />
            </MapWrapper>
            <MapLegend />
        </Wrapper>
    </>
}

export default MapElement