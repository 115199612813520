import { useModal } from 'hooks/modalProvider'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useEffect, useState } from 'react'
import SiteService from 'services/siteService'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import { TSiteLabel } from 'types/businessLogic/misc'
import { TModalProps } from 'types/ui/modal'
import EditLabelsForm from 'views/Sites/Modals/EditlabelsModal/EditLabelsForm'

type EditLabelsProps = TModalProps

const EditLabels = (props : EditLabelsProps) => {
    const [labels, setLabels] = useState<TSiteLabel[]>([])
    const [fetched, setFetched] = useState(false)
    const dispatch = useAppDispatch()
    const {closeModal} = useModal()
    const selectAll = props.ids.length === 0

    const loadData = useCallback(async () => {
        try {
            let result: TSiteLabel[] = []

            if (selectAll) {
                result = await SiteService.getAllCommonLabels(props.filters)
            } else {
                result = await SiteService.getCommonLabels(props.ids as number[])
            }
            
            setLabels(result)
            setFetched(true)
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }

    }, [dispatch, props.filters, props.ids, selectAll])

    useEffect(() => {
        if (!fetched) {
            loadData()
        }
    }, [fetched, loadData])
    
    const handleOnSubmit = async (labelsToAdd: TSiteLabel[], labelsToRemove: TSiteLabel[], override: boolean) => {
        try {
            if (selectAll) {
                await SiteService.setAllLabels(props.filters, labelsToAdd.map(x => x.name), labelsToRemove.map(x => x.name), override)
            } else {
                await SiteService.setLabels(props.ids as number[], labelsToAdd.map(x => x.name), labelsToRemove.map(x => x.name), override)
            }
            closeModal()
            dispatch(showMessage({message: i18nKeys.multiconfig_labels_success_message, type: EMessageType.SUCCESS}))
        } catch {
            dispatch(showMessage({message: i18nKeys.multiconfig_labels_error_message, type: EMessageType.ERROR}))
        }
    }

    if (!fetched) {
        return (
            <>Loading...</>
        )
    }

    return (
        <>
            <EditLabelsForm
                labels={labels}
                onSubmit={handleOnSubmit}
            />
        </>
    )
}
export default EditLabels