import React, { useCallback } from 'react'
import { clearGroups, deleteAllPluginsAsync, deletePluginsAsync, getGroupDataAsync, getPluginsAsync } from 'state/slices/pluginSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGroup, TTableFetchState } from 'types/network'
import { tableInfo } from 'views/Plugins/pluginsTableColumns'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { TModalProps } from 'types/ui/modal'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { useParams } from 'react-router-dom'
import Plugins from 'views/Plugins/Plugins'
import PluginsTablePreparer from 'views/Plugins/pluginsTablePreparer'
import { TDeleteAllPluginsRequest } from 'types/businessLogic/plugin'
import PluginService from 'services/pluginService'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const PluginsContainer = () => {
    const dispatch = useAppDispatch()
    const { plugins, totalNumber, groups } = useAppSelector(state => state.plugins)
    const pluginTypes = useAppSelector(state => state.pluginsKind.types)
    const { filter } = useParams()
    
    useBreadCrumbs([i18nKeys.breadcrumb_plugins])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams,
            translationKey: group.translationKey
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const requestParams = GeneralHelper.buildRequestFromTableState(params)
            dispatch(getPluginsAsync(requestParams))
        } else if (params.groupName) {
            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, params.groupName)
            dispatch(getGroupDataAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await PluginService.getGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }
            getGroups()
        }
    }, [dispatch])

    const deletePlugins = (params: TModalProps) => {
        if (params.ids.length === 0 && params.filters) {
            const request: TDeleteAllPluginsRequest = {
                filters: params.filters
            }
            
            dispatch(deleteAllPluginsAsync(request))
        } else {
            dispatch(deletePluginsAsync(params.ids as number[]))
        }
    }

    const data = PluginsTablePreparer.preparePluginRows(plugins, pluginTypes)
    const preparedGroups = PluginsTablePreparer.prepareGroups(groups, pluginTypes)
        
    return (
        <ViewContainer>
            <Plugins
                preselectedFilter={filter}
                data={data}
                groups={preparedGroups}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                getTableData={getTableData}
                getGroupData={getGroupData}
                onDeletePlugins={deletePlugins}
            />
        </ViewContainer>
    )
}

export default PluginsContainer
