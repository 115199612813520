import { TChartEntry, ChartLegend, CustomPieChart, CardHeader, PieChartPositioner } from 'components/CustomControls/CustomCharts'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { EDataSyncItems } from 'enums/enums'
import useBackgroundService from 'hooks/useBackgroundService'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { getSiteApprovalsAsync } from 'state/slices/statisticSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import colors from 'style/colors'
import { TSiteApprovals } from 'types/statistic'
import { uid } from 'uid'

const SiteApprovalsElement = () => {
    const siteApprovals = useAppSelector(store => store.statistic.siteApprovals)
    const dispatch = useAppDispatch()
    const intl = useIntl()
    const [identifier] = useState(uid())

    const loadData = useCallback(() => dispatch(getSiteApprovalsAsync()), [dispatch])

    useBackgroundService(EDataSyncItems.SITES, loadData, identifier)

    const prepareData = (data: TSiteApprovals): TChartEntry[] => {
        return [
            {
                id: 'ApprovedGateway',
                name: i18nKeys.site_approvals_approved,
                value: data.gatewayApprovedCount,
                color: colors.extra.SUCCESS
            },
            {
                id: 'UnapprovedGateway',
                value: data.gatewayUnapprovedCount,
                name: i18nKeys.site_approvals_waiting_for_approval,
                color: colors.extra.WARNING
            }
        ]
    }

    const chartData = prepareData(siteApprovals)

    return (
        <>
            <CardHeader title={i18nKeys.site_approvals_heading} />
            <CustomTooltip title={intl.formatMessage(i18nKeys.site_approvals_heading)}>
                <>
                    <PieChartPositioner>
                        <CustomPieChart
                            data={chartData}
                            chartInnerDesc={i18nKeys.site_approvals_total}
                            chartInnerNumber={(siteApprovals.gatewayApprovedCount + siteApprovals.gatewayUnapprovedCount)}
                        />
                    </PieChartPositioner>
                    <ChartLegend data={chartData} />
                </>
            </CustomTooltip>
        </>
    )
}

export default SiteApprovalsElement