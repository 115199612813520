import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { tableInfo } from 'views/Report/SiteAudit/siteAuditReportsTableColumns'
import { TSiteAuditReport } from 'types/businessLogic/auditReport'

class SiteAuditReportsTablePreparer {
    public static prepareSiteAuditReportRows = (siteAuditReports: TSiteAuditReport[]) =>
        siteAuditReports.map(siteAuditReport => ({
            ...siteAuditReport,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.filename.key,
                    translationKey: tableInfo.columnConfig.filename.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: siteAuditReport.filename
                },
                {
                    name: tableInfo.columnConfig.createdAt.key,
                    translationKey: tableInfo.columnConfig.createdAt.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.dateString.index,
                    [GeneralHelper.propertyTypes.dateString.key]: siteAuditReport.createdAt
                },
                {
                    name: tableInfo.columnConfig.siteName.key,
                    translationKey: tableInfo.columnConfig.siteName.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: siteAuditReport.siteName
                },
                {
                    name: tableInfo.columnConfig.from.key,
                    translationKey: tableInfo.columnConfig.from.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.dateString.index,
                    [GeneralHelper.propertyTypes.dateString.key]: siteAuditReport.from
                },
                {
                    name: tableInfo.columnConfig.till.key,
                    translationKey: tableInfo.columnConfig.till.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.dateString.index,
                    [GeneralHelper.propertyTypes.dateString.key]: siteAuditReport.till
                }
            ]
        }))

    public static prepareGroups = (groups: TTableGroup<TSiteAuditReport>[]): TTableGroup<TTableRowData>[] => groups.map(group => ({
        ...group,
        data: group.data ? SiteAuditReportsTablePreparer.prepareSiteAuditReportRows(group.data) : undefined
    }))
}

export default SiteAuditReportsTablePreparer