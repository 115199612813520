import { useAppDispatch, useAppSelector } from 'state/store'
import { TLicense } from 'types/businessLogic/license'
import License from 'views/Admin/License/License'
import React from 'react'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { i18nKeys } from 'i18n/keys'
import LicenseService from 'services/licenseService'
import { EMessageType } from 'enums/enums'
import { showMessage } from 'state/slices/uiSlice'

const LicenseContainer = () => {
    const license = useAppSelector<TLicense>(store => store.license)
    const dispatch = useAppDispatch()

    useBreadCrumbs([i18nKeys.breadcrumb_administration_license])

    const handleUpdateLicense = async (licenseKey: string) => {
        try {
            await LicenseService.updateLicenseKey(licenseKey)
            dispatch(showMessage({message: i18nKeys.license_apply_new_license_success_message, type: EMessageType.SUCCESS}))

        } catch {
            dispatch(showMessage({message: i18nKeys.license_apply_new_license_error_message, type: EMessageType.ERROR}))
        }
    }

    return (
        <License license={license} onUpdateLicense={handleUpdateLicense} />
    )
}

export default LicenseContainer