import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import DayJsUtils from '@date-io/dayjs'
import dayjs from 'dayjs'
import { TextField } from '@mui/material'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput'

const StyledTextField = styled(TextField)`
    .MuiInput-formControl {
        margin: 0;
    }

    .MuiInputBase-input {
        color: #303030;
        height: 24px;
        cursor: pointer;
        outline: none !important;
        font-size: 16px;
        min-width: 100px;
        box-sizing: border-box;
        font-family: LatoRegular;
        line-height: 24;
        padding-left: 10px;
        letter-spacing: 0;
    }

    .MuiFormHelperText-root  {
        height: 0;
        margin: 0;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0;
    }
`

const maskMap : {[key: string]: string} = {
    fr: '__/__/____',
    en: '__/__/____',
    de: '__.__.____',
}

type TProps = {
    value?: Date | null
    onChange: (date: Date | null) => void
    disableFuture?: boolean
    disablePast?: boolean
    disabled?: boolean
    renderInput?: (props: MuiTextFieldProps) => React.ReactElement<any, string | React.JSXElementConstructor<any>>
    min?: Date | null
}

const CustomDatePicker = forwardRef<HTMLInputElement, TProps>((props, ref) => {
    const {value, onChange, renderInput, min: maxDateTime, ...rest} = props
    
    const intl = useIntl()
    const locale = intl.locale

    let renderInputTemp
    
    if (!renderInput) {
        renderInputTemp = (fieldProps: MuiTextFieldProps) => <StyledTextField {...fieldProps} />
    } else {
        renderInputTemp = renderInput
    }

    return (
        <LocalizationProvider dateLibInstance={dayjs} dateAdapter={DayJsUtils} locale={locale}>
            <MobileDateTimePicker
                {...rest}
                mask={maskMap[locale]}
                value={value}
                onChange={() => {}}
                onAccept={(date) => onChange(date as Date)}
                label=' '
                ref={ref}
                cancelText={intl.formatMessage(i18nKeys.advanced_job_configuration_datepicker_cancel)}
                okText={intl.formatMessage(i18nKeys.advanced_job_configuration_datepicker_ok)}
                minDateTime={maxDateTime}
                renderInput={renderInputTemp}
                disabled={props.disabled}
                InputProps={{
                    endAdornment: null
                }}
            />
        </LocalizationProvider>
    )
})

export default CustomDatePicker