import GeneralHelper from 'helpers/GeneralHelper'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback } from 'react'
import { getLabelsAsync } from 'state/slices/labelSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import styled from 'styled-components'
import { TTableFetchState } from 'types/network'
import Labels from 'views/Admin/Labels/Labels'
import LabelsTablePreparer from 'views/Admin/Labels/LabelsTablePreparer'
import {tableInfo} from './LabelsTableColumns'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const LabelsContainer = () => {
    const dispatch = useAppDispatch()
    const {labels, totalNumber} = useAppSelector(store => store.labels)

    const getTableData = useCallback((params: TTableFetchState) => {
        const requestParams = GeneralHelper.buildRequestFromTableState(params)
        dispatch(getLabelsAsync(requestParams))
    }, [dispatch])

    useBreadCrumbs([i18nKeys.breadcrumb_labels])

    const data = LabelsTablePreparer.prepareLabelsRows(labels)

    if (!data) {
        return <></>
    }

    return (
        <ViewContainer>
            <Labels
                data={data}
                totalCount={totalNumber}
                getTableData={getTableData}
                tableInfo={tableInfo}
            />
        </ViewContainer>
    )
}
export default LabelsContainer