import React from 'react'
import TransMessage from 'components/TransMessage/TransMessage'
import { ETableFilters } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'
import AppConstants from 'helpers/AppConstants'

const columnConfig: TTableColumnsConfig = {
    name: {
        key: 'Name',
        translationKey: i18nKeys.umbrella_roles_table_head_name.id,
        displayed: true,
        sortKey: 'Name',
        filter: {
            key: 'Name',
            type: ETableFilters.INPUT_TEXT
        }
    },
    umbrellaAdmin: {
        key: 'UmbrellaAdmin',
        translationKey: i18nKeys.umbrella_roles_table_head_umbrella_admin.id,
        displayed: true,
        sortKey: 'UmbrellaAdmin',
        groupKey: 'UmbrellaAdmin',
        filter: {
            key: 'UmbrellaAdmin',
            type: ETableFilters.DROPDOWN,
            dropdownItems: [
                {text: <TransMessage {...i18nKeys.dropdown_selection_none} />, value: ''},
                {text: <TransMessage {...i18nKeys.dropdown_selection_true} />, value: true},
                {text: <TransMessage {...i18nKeys.dropdown_selection_false} />, value: false}
            ]
        }
    },
    siteAdmin: {
        key: 'SiteAdmin',
        translationKey: i18nKeys.umbrella_roles_table_head_site_admin.id,
        displayed: true,
        sortKey: 'SiteAdmin',
        groupKey: 'SiteAdmin',
        filter: {
            key: 'SiteAdmin',
            type: ETableFilters.DROPDOWN,
            dropdownItems: [
                {text: <TransMessage {...i18nKeys.dropdown_selection_none} />, value: ''},
                {text: <TransMessage {...i18nKeys.dropdown_selection_true} />, value: true},
                {text: <TransMessage {...i18nKeys.dropdown_selection_false} />, value: false}
            ]
        }
    },
    builtIn: {
        key: 'BuiltIn',
        translationKey: i18nKeys.umbrella_roles_table_head_built_in.id,
        displayed: true,
        sortKey: 'BuiltIn',
        groupKey: 'BuiltIn',
        filter: {
            key: 'BuiltIn',
            type: ETableFilters.DROPDOWN,
            dropdownItems: [
                {text: <TransMessage {...i18nKeys.dropdown_selection_none} />, value: ''},
                {text: <TransMessage {...i18nKeys.dropdown_selection_true} />, value: true},
                {text: <TransMessage {...i18nKeys.dropdown_selection_false} />, value: false}
            ]
        }
    },
    users: {
        key: 'Users',
        translationKey: i18nKeys.umbrella_roles_table_head_users.id,
        displayed: true
    },
    sites: {
        key: 'Sites',
        translationKey: i18nKeys.umbrella_roles_table_head_sites.id,
        displayed: true
    },
    activeDirectoryGroup: {
        key: 'ActiveDirectoryGroup',
        translationKey: i18nKeys.umbrella_roles_table_head_active_directory_group.id,
        displayed: true
    },
    siteEntityTypes: {
        key: 'VMSEntityTypeSubscription',
        translationKey: i18nKeys.umbrella_roles_table_head_entity_type.id,
        displayed: false
    },
    siteEventCategories: {
        key: 'VMSEventCategorySubscription',
        translationKey: i18nKeys.umbrella_roles_table_head_event_category.id,
        displayed: false
    },
    containsFilter: {
        key: 'Filter',
        translationKey: i18nKeys.umbrella_roles_table_head_contains_filter.id,
        displayed: false
    },
    sendEmail: {
        key: 'SendEmail',
        translationKey: i18nKeys.umbrella_roles_table_head_send_email.id,
        displayed: false
    },
    sendEmailToAddGroup: {
        key: 'SendEmailToAddGroup',
        translationKey: i18nKeys.umbrella_role_form_send_email_to_ad_group.id,
        displayed: false
    },
    siteLabels: {
        key: 'SiteLabels',
        translationKey: i18nKeys.umbrella_roles_table_head_site_labels.id,
        displayed: false
    }
}

const defaultLayout = [
    columnConfig.name.key,
    columnConfig.umbrellaAdmin.key,
    columnConfig.siteAdmin.key,
    columnConfig.builtIn.key,
    columnConfig.users.key,
    columnConfig.sites.key,
    columnConfig.activeDirectoryGroup.key
]

export const tableInfo: TTableInfo = {
    id: AppConstants.tableIds.UMBRELLA_ROLES,
    columnConfig,
    defaultLayout
}