import styled from 'styled-components'
import { TProfileDashboardLayout } from 'types/businessLogic/profile'
import RGL, { Layout } from 'react-grid-layout'
import { DashboardElementMappings, DashboardLayoutMappings, EDashboardElements, EDashboardLayouts } from 'types/ui/dashboard'
import React, { useCallback, useState } from 'react'
import DashboardElementWrapper from 'components/DashboardElements/DashboardElementWrapper'
import EmptyChart from 'components/DashboardElements/EmptyChart'
import { AutoSizer } from 'react-virtualized'

const GridWrapper = styled.div<{fullscreenMode : boolean}>`
    height: calc(100vh - ${p => p.fullscreenMode ? '25px' : '130px'}); 
    overflow-x: hidden; 
    padding-right: 25px;
    padding-left: 25px;
    padding-top: ${p => p.fullscreenMode ? '25px' : '0px'};
`

type TProps = {
    onAddOrReplace?: (positon: number) => void
    onDeleteCard?: (positon: number) => void
    dashboardLayout: TProfileDashboardLayout
    fullscreenMode?: boolean
    editMode: boolean
    onLayoutChanged?: (layout: Layout[]) => void
}

const Dashboard = (props: TProps) => {
    const layoutInfo = DashboardLayoutMappings[props.dashboardLayout.layout]
    const [gridHeight, setGridHeight] = useState(0)
    
    // fixes issue on component first mount and rendering of the grid cards when height is 0
    const onGridRefChanged = useCallback((node: HTMLDivElement) => {
        if (node !== null) {
            setGridHeight(node.clientHeight)
        }
    }, [])

    const renderItems = () => {
        return props.dashboardLayout.layoutElements.map((item, index) => {
            if (item.layoutElement === EDashboardElements.Empty) {
                return <div key={index.toString()}>
                    <EmptyChart fullscreen={props.fullscreenMode} onClick={() => props.onAddOrReplace && props.onAddOrReplace(index)} />
                </div>
            } else {
                const hit = DashboardElementMappings[item.layoutElement]
                if (hit) {
                    const Element = hit.element
                    return <div key={index.toString()}>
                        <DashboardElementWrapper
                            id={hit.id}
                            index={index}
                            btnText={hit.btnText}
                            redirectTo={hit.redirectTo}
                            elementIndex={index}
                            editMode={props.editMode}
                            onDeleteCard={props.onDeleteCard}
                            fullscreenMode={props.fullscreenMode ?? false}
                            onReplaceCard={props.onAddOrReplace}
                        >
                            <Element />
                        </DashboardElementWrapper>
                    </div>
                } else {
                    return <div key={index.toString()}>
                        <EmptyChart fullscreen={props.fullscreenMode} onClick={() => props.onAddOrReplace && props.onAddOrReplace(index)} />
                    </div>
                }
            }
        })
    }

    const applyLayout = (): Layout[] => {
        if (props.dashboardLayout.layout === EDashboardLayouts.AdvancedLayout) {
            if (props.dashboardLayout.advancedData) {
                return JSON.parse(props.dashboardLayout.advancedData) as Layout[]
            }
            return (layoutInfo.layout as (length: number) => Layout[])(props.dashboardLayout.layoutElements.length)
        } else {
            return layoutInfo.layout as Layout[]
        }
    }

    return <>
        <GridWrapper data-testid='dashboard-grid-wrapper' fullscreenMode={props.fullscreenMode ?? false} ref={onGridRefChanged}>
            <AutoSizer>
                {({width}) => {
                    return (
                        <RGL
                            draggableHandle='.draggableHandle'
                            layout={applyLayout()}
                            cols={layoutInfo.columns}
                            compactType='horizontal'
                            rowHeight={(gridHeight / layoutInfo.rows) - 30}
                            style={{
                                height: gridHeight - (props.fullscreenMode ? 25 : 0)
                            }}
                            isDraggable={props.editMode}
                            isResizable={props.editMode}
                            width={width}
                            onLayoutChange={props.onLayoutChanged}
                        >
                            {renderItems()}
                        </RGL>
                    )
                }}
            </AutoSizer>
        </GridWrapper>
    </>
}

export default Dashboard