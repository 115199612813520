import axiosClient from 'api/axiosClient'
import { Dayjs } from 'dayjs'
import { ENotificationPriority, EPerformanceState } from 'enums/enums'
import { TOverallState, TPerformanceItem } from 'types/businessLogic/performance'
import { TFetchParamsByIdRequest, TFetchParamsRequest } from 'types/network'
import { TSiteSystemDetailsResponse, TSiteSystemsPerSite } from 'types/businessLogic/siteSystem'
import { SetThresholdsSitesRequest } from 'types/businessLogic/site'

class SiteSystemPerformanceService {
    private static _api = axiosClient()

    public static async getOverallStates() {
        const response = await this._api.get<TOverallState>('SiteSystemPerformance/OverallStates')
        return response.data
    }
    
    public static async getSiteSystems(params: TFetchParamsRequest, filterByStates: EPerformanceState[]) {
        const response = await this._api.get<{siteSystems: TSiteSystemsPerSite[], totalNumber: number}>('SiteSystemPerformance', { params: { ...params, FilterByStates : filterByStates }})
        return response.data
    }

    public static async getSiteSystemsBySide(params: TFetchParamsByIdRequest, filterByStates: EPerformanceState[]) {
        const response = await this._api.get<TPerformanceItem[]>('SiteSystemPerformance/Site', { params: { ...params, FilterByStates : filterByStates }})
        return response.data
    }

    public static async setIgnoreSystems(ids: number[]) {
        await this._api.post('SiteSystemPerformance/Ignore', {
            Ids: ids
        })
    }

    public static async getSiteSystemDetails(id: number, from: Dayjs, till: Dayjs) {
        const response = await this._api.get<TSiteSystemDetailsResponse>('SiteSystemPerformance/Details', { params: {
            Id: id,
            From: from.toISOString(),
            Till: till.toISOString()
        }})
        return response.data
    }

    public static async setThresholdsSiteSystems(request : SetThresholdsSitesRequest) {
        await this._api.post('SiteSystemPerformance/Thresholds', {
            Ids: request.ids,
            CpuThresholdWarning : request.cpuThresholds[0],
            CpuThresholdCritical : request.cpuThresholds[1],
            CpuUseDefaultThresholds : request.cpuUseDefault ?? false,
            MemoryThresholdWarning : request.memoryThresholds[0],
            MemoryThresholdCritical : request.memoryThresholds[1],
            MemoryUseDefaultThresholds : request.memoryUseDefault ?? false,
            DiskThresholdWarning : request.diskThresholds[0],
            DiskThresholdCritical : request.diskThresholds[1],
            DiskUseDefaultThresholds : request.diskUseDefault ?? false,
            NetworkThresholdWarning: request.networkThresholds[0],
            NetworkThresholdCritical: request.networkThresholds[1],
            NetworkUseDefaultThresholds: request.networkUseDefault ?? false
        })
    }

    public static async setNotificationPriority(ids: number[], notificationPriority: ENotificationPriority, useDefault: boolean) {
        await this._api.post('SiteSystemPerformance/NotificationPriority', {
            Ids: ids,
            NotificationPriority: notificationPriority,
            UseDefaultNotificationPriority: useDefault
        })
    }
}

export default SiteSystemPerformanceService
