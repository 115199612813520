import axios, { AxiosInstance } from 'axios'
import jsConvert from 'js-convert-case'

let instance: AxiosInstance | undefined

const axiosClient = () : AxiosInstance => {
    if (!instance) {
        instance = axios.create({
            baseURL: '/ClientApi/',
            timeout: 15000,
        })
    }

    instance.interceptors.response.use((response) => {
        const temp = {...response}

        if (temp.headers['content-type'] && temp.headers['content-type'].includes('application/json')) {
            if (Array.isArray(temp.data)) {
                if (temp.data.length === 0) {
                    return temp
                }
    
                if (typeof temp.data[0] !== 'object') {
                    return temp
                }
                
                const transformedData: any[] = []
                temp.data.forEach(item => transformedData.push(jsConvert.camelKeys(item, { recursive: true, recursiveInArray: true })))
                temp.data = transformedData
            } else if (typeof temp.data === 'object') {
                const transformedData = jsConvert.camelKeys(response.data, { recursive: true, recursiveInArray: true })
                temp.data = transformedData
            }
        }

        return temp
    }, (error) => {
        return Promise.reject(error)
    })

    return instance
}

export default axiosClient
