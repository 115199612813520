import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit'
import authSlice from 'state/slices/authSlice'
import profileSlice from 'state/slices/profileSlice'
import uiSlice from 'state/slices/uiSlice'
import siteSlice from 'state/slices/siteSlice'
import siteUserSlice from 'state/slices/siteUserSlice'
import siteCameraSlice from 'state/slices/siteCameraSlice'
import siteGroupSlice from 'state/slices/siteGroupSlice'
import siteServiceSlice from 'state/slices/siteServiceSlice'
import siteTimeProfileSlice from 'state/slices/siteTimeProfileSlice'
import mapSlice from 'state/slices/mapSlice'
import pluginTypeSlice from 'state/slices/pluginTypeSlice'
import emailSlice from 'state/slices/emailSlice'
import statisticSlice from 'state/slices/statisticSlice'
import settingsSlice from 'state/slices/settingsSlice'
import licenseSlice from 'state/slices/licenseSlice'
import siteJobSlice from 'state/slices/siteJobSlice'
import uploadManagerSlice from 'state/slices/uploadManagerSlice'
import cameraFirmwareSlice from 'state/slices/cameraFirmwareSlice'
import umbrellaUserSlice from 'state/slices/umbrellaUserSlice'
import umbrellaRoleSlice from 'state/slices/umbrellaRoleSlice'
import siteEventsSlice from 'state/slices/siteEventsSlice'
import siteAuditReportsSlice from 'state/slices/siteAuditReportsSlice'
import siteInventoryReportsSlice from 'state/slices/siteInventoryReportsSlice'
import dataSyncSlice from 'state/slices/dataSyncSlice'
import notificationSlice from 'state/slices/notificationSlice'
import siteServicePerformanceSlice from 'state/slices/siteServicePerformanceSlice'
import siteSystemPerformanceSlice from 'state/slices/siteSystemPerformanceSlice'
import cameraPerformanceSlice from 'state/slices/cameraPerformanceSlice'
import pluginSlice from 'state/slices/pluginSlice'
import pluginKindTypeSlice from 'state/slices/pluginKindTypeSlice'
import labelSlice from 'state/slices/labelSlice'
import apiKeySlice from 'state/slices/apieKeySlice'

const combinedReducer = combineReducers({
    auth: authSlice,
    profile: profileSlice,
    ui: uiSlice,
    site: siteSlice,
    siteUser: siteUserSlice,
    siteCamera: siteCameraSlice,
    siteGroup: siteGroupSlice,
    siteService: siteServiceSlice,
    siteTimeProfile: siteTimeProfileSlice,
    siteSystemPerformance: siteSystemPerformanceSlice,
    siteEvents: siteEventsSlice,
    siteAuditReports: siteAuditReportsSlice,
    siteInventoryReports: siteInventoryReportsSlice,
    pluginTypes: pluginTypeSlice,
    map: mapSlice,
    email: emailSlice,
    statistic: statisticSlice,
    settings: settingsSlice,
    license: licenseSlice,
    uploadManager: uploadManagerSlice,
    siteJob: siteJobSlice,
    cameraFirmware: cameraFirmwareSlice,
    umbrellaUser: umbrellaUserSlice,
    umbrellaRole: umbrellaRoleSlice,
    dataSync: dataSyncSlice,
    notification: notificationSlice,
    siteServicePerformance: siteServicePerformanceSlice,
    cameraPerformance: cameraPerformanceSlice,
    plugins: pluginSlice,
    pluginsKind: pluginKindTypeSlice,
    labels: labelSlice,
    apiKeys: apiKeySlice
})

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
    if (action.type === 'state/logout') {
        state = undefined
    }
    return combinedReducer(state, action)
}
export const store = configureStore({
    reducer: rootReducer
})

export const dispatch = store.dispatch
export const getState = store.getState

export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store
export type RootState = ReturnType<typeof combinedReducer>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector