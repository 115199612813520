import axiosClient from 'api/axiosClient'
import { TFetchParamsRequest } from 'types/network'
import { TFirmware } from 'types/businessLogic/firmware'
import { TUploadFirmwareRequest } from 'types/businessLogic/job'
import { CancelToken } from 'axios'

class FirmwaresService {
    private static _api = axiosClient()

    public static async getFirmwares(params: TFetchParamsRequest): Promise<{firmwares: TFirmware[], totalNumber: number}> {
        const response = await this._api.get<{firmwares: TFirmware[], totalNumber: number}>('Firmwares', {
            params: params
        })
        return response.data
    }

    public static async uploadFirmware(request: TUploadFirmwareRequest, onProgress: (percentage: number) => void, cancelToken: CancelToken) : Promise<void> {
        const formData = new FormData()
        formData.append('Manufacturer', request.manufacturer)
        formData.append('Comment', request.comment)
        formData.append('SupportedTypes', request.supportedTypes.join(','))
        formData.append('Firmware', request.firmware)
        await this._api.post('Firmwares', formData, {
            timeout: 5 * 60 * 1000,
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                onProgress && onProgress(percentCompleted)
            },
            cancelToken: cancelToken,
            headers: {'Content-Type': 'multipart/form-data'},
        })
    }
    
    public static async deleteFirmwares(ids: number[]) {
        await this._api.delete('Firmwares', {
            data: {
                Ids: ids
            }
        })
    }
}

export default FirmwaresService
