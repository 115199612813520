import React from 'react'
import colors from 'style/colors'
import styled from 'styled-components'

const Wrapper = styled.div`
    font-size: 12px;
    font-family: LatoRegular;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: ${colors.shades.PURE_WHITE};
`

type Props = {
    version: string
}

const Copyright = (props: Props) => {
    return <Wrapper>
        <div>© {(new Date()).getFullYear()} - Qognify Umbrella Core</div>
        <div>{props.version}</div>
    </Wrapper>
}

export default Copyright