import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TTableRowData } from 'types/ui/table'
import { TFirmware } from 'types/businessLogic/firmware'
import { tableInfo } from 'views/Control/CameraFirmwares/CameraFirmwaresTableColumns'

class CameraFirmwaresTablePreparer {
    public static prepareCameraFirmwaresRows = (cameraFirmwares: TFirmware[]): TTableRowData[] =>
        cameraFirmwares.map(cameraFirmware => ({
            ...cameraFirmware,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.name.key,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    readOnly: false,
                    [GeneralHelper.propertyTypes.string.key]: cameraFirmware.name
                },
                {
                    name: tableInfo.columnConfig.manufacturer.key,
                    translationKey: tableInfo.columnConfig.manufacturer.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: cameraFirmware.manufacturer
                },
                {
                    name: tableInfo.columnConfig.comment.key,
                    translationKey: tableInfo.columnConfig.comment.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: cameraFirmware.comment
                },
                {
                    name: tableInfo.columnConfig.supportedTypes.key,
                    translationKey: tableInfo.columnConfig.supportedTypes.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: cameraFirmware.supportedTypes ?
                        cameraFirmware.supportedTypes.split(',') : []
                },
                {
                    name: tableInfo.columnConfig.uploadedAt.key,
                    translationKey: tableInfo.columnConfig.uploadedAt.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.dateString.index,
                    [GeneralHelper.propertyTypes.dateString.key]: cameraFirmware.uploadedAt
                }
            ]
        }))
}

export default CameraFirmwaresTablePreparer