import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { EPerformanceState } from 'enums/enums'
import { TOverallState } from 'types/businessLogic/performance'
import StateButton from 'components/PagedCardList/StateButton'

export const Root = styled.div`
    display: flex;
    align-items: center;
    margin-top: 32px;
    height: 32px;
`

type TProps = {
    preSelectedState?: EPerformanceState | EPerformanceState[]
    overallState: TOverallState
    onStateFiltersChange: (value: EPerformanceState) => void
}

const SelectedStatesGroup = (props: TProps) => {
    const { preSelectedState, overallState, onStateFiltersChange } = props
    const [useSmallIcons, setUseSmallIcons] = useState(false)

    useEffect(() => {
        
        const getSize = () => {
            return {
                width: window.innerWidth,
                height: window.innerHeight,
            }
        }

        const handleResize = () => setUseSmallIcons(getSize().width < 1850)
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const checkPreSelect = (value: EPerformanceState) => {
        if (preSelectedState !== undefined) {
            if (Array.isArray(preSelectedState)) {
                return preSelectedState.includes(value)
            } else {
                return preSelectedState === value
            }
        }

        return false
    }

    return (
        <Root>
            <StateButton id='healthyFilterButton' value={EPerformanceState.HEALTHY} preSelect={checkPreSelect(EPerformanceState.HEALTHY)} count={overallState.healthy} onClick={onStateFiltersChange} small={useSmallIcons} />
            <StateButton id='warningFilterButton' value={EPerformanceState.WARNING} preSelect={checkPreSelect(EPerformanceState.WARNING)} count={overallState.warning} onClick={onStateFiltersChange} small={useSmallIcons} />
            <StateButton id='criticalFilterButton' value={EPerformanceState.CRITICAL} preSelect={checkPreSelect(EPerformanceState.CRITICAL)} count={overallState.critical} onClick={onStateFiltersChange} small={useSmallIcons} />
            <StateButton id='offlineFilterButton' value={EPerformanceState.OFFLINE} preSelect={checkPreSelect(EPerformanceState.OFFLINE)} count={overallState.offline} onClick={onStateFiltersChange} small={useSmallIcons} />
            <StateButton id='ignoredFilterButton' value={EPerformanceState.IGNORED} preSelect={checkPreSelect(EPerformanceState.IGNORED)} count={overallState.ignored} onClick={onStateFiltersChange} small={useSmallIcons} />
        </Root>
    )
}
export default SelectedStatesGroup
