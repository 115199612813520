import React, { useCallback, useEffect, useRef, useState } from 'react'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import styled from 'styled-components'
import { TGroup, TTableFetchState } from 'types/network'
import { TTableGroup, TTableRowData, TTableInfo } from 'types/ui/table'
import Toolbar from 'components/Toolbar/Toolbar'
import ColumnPreset from 'components/Tables/ColumnPreset'
import ColumnChooser from 'components/ColumnChooser/ColumnChooser'
import { EDataSyncItems } from 'enums/enums'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import { useModal } from 'hooks/modalProvider'
import { IconButton } from '@mui/material'
import { FormatAlignJustify, FormatAlignRight } from '@mui/icons-material'
import colors from 'style/colors'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { i18nKeys } from 'i18n/keys'
import { useIntl } from 'react-intl'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`

const StyledIconButton = styled(IconButton)<{$open: boolean}>`
    margin-left: 10px;
    margin-right: 10px;
    fill: ${colors.primary.DARKER_PRIMARY};
`

type TProps = {
    data: TTableRowData[]
    groups: TTableGroup<TTableRowData>[]
    totalCount: number
    tableInfo: TTableInfo
    getTableData: (params: TTableFetchState) => void
    getGroupData: (params: TTableFetchState, group: TGroup) => void
}

const SiteEvents = (props: TProps) => {
    const { tableInfo, data, totalCount, groups, getGroupData, getTableData } = props
    const [identifier] = useState(uid())
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)
    const {isModalOpen} = useModal()
    const colHandlerProps = useColumnsHandler(tableInfo)

    const enhanceTableProps = useEnhanceTable({
        groups,
        grouedView: true
    })

    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
        enhanceTableProps.selectedGroups.forEach(group => {
            getGroupData(enhanceTableProps.fetchState, group)
        })
    }, [getTableData, enhanceTableProps.fetchState, enhanceTableProps.selectedGroups, getGroupData])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.SITE_EVENTS, loadData, identifier)

    useEffect(() => {
        const hasSelectedRows = enhanceTableProps.selectionCount > 0
        setDisabled(identifier, isModalOpen || hasSelectedRows)
        setAutoupdatePaused(isModalOpen || hasSelectedRows)
    }, [enhanceTableProps.selectionCount, identifier, isModalOpen, setDisabled])

    const [open, setOpen] = useState<boolean>(false)
    const buttonRef = useRef<HTMLButtonElement>(null)

    const handleToggle = () => {
        setOpen(prevState => !prevState)
        enhanceTableProps.setGroupedView(!enhanceTableProps.isGroupedView)
    }

    const intl = useIntl()

    return (
        <ViewWrapper>
            <Toolbar
                RightContent={
                    <>
                        {
                            enhanceTableProps.isGroupedView ?
                                <CustomTooltip title={intl.formatMessage(i18nKeys.site_events_group_resolved_tooltip_ungroup)}>
                                    <StyledIconButton
                                        id='groupResolved'
                                        $open={open}
                                        ref={buttonRef}
                                        onClick={handleToggle}
                                    >
                                        <FormatAlignJustify />
                                    </StyledIconButton>
                                </CustomTooltip> :
                                <CustomTooltip title={intl.formatMessage(i18nKeys.site_events_group_resolved_tooltip_group)}>
                                    <StyledIconButton
                                        id='groupResolved'
                                        $open={open}
                                        ref={buttonRef}
                                        onClick={handleToggle}
                                    >
                                        <FormatAlignRight />
                                    </StyledIconButton>
                                </CustomTooltip>
                        }
                        
                        <ColumnChooser
                            minColumns={1}
                            {...colHandlerProps}
                        />
                        <ColumnPreset
                            tableId={tableInfo.id}
                            {...colHandlerProps}
                        />
                        <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                    </>
                }
            />

            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                disableSelectionForIds={[]}
                rows={data}
                groups={groups}
                disableSelection={true}
                showChildren={enhanceTableProps.isGroupedView}
            />
        </ViewWrapper>
    )
}

export default SiteEvents
