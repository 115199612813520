import { ButtonBase, Grid } from '@mui/material'
import React, { useState } from 'react'
import {TDashboardLayout, DashboardLayouts, EDashboardLayouts} from 'types/ui/dashboard'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { CustomButton } from 'components/CustomControls/CustomButton'
import TransMessage from 'components/TransMessage/TransMessage'
import images from 'style/images'
import { GridImage, SelectedImage } from 'views/Dashboard/Modals/common'
import { FormButtonGroup, FormContent, FormWrapper, FormFooter } from 'components/FormControls/FormControls'
import { useModal } from 'hooks/modalProvider'

const StyledGrid = styled(Grid)`
    padding-top: 56px;
`

type TProps = {
    onSave: (layout: EDashboardLayouts) => void
}

const ChooseLayout = (props: TProps) => {
    const [selectedLayout, setSelectedLayout] = useState(-1)
    const {closeModal} = useModal()

    const handleSelectLayout = () => {
        props.onSave(selectedLayout)
        closeModal()
    }

    return <FormWrapper>
        <FormContent>
            <StyledGrid direction='row' container spacing={4}>
                {DashboardLayouts.filter(
                    (item: TDashboardLayout) => item.image).map((item: TDashboardLayout, i: number) =>
                    <Grid key={'dashboardLayout' + i} item sm={4} md={4} lg={4}>
                        <ButtonBase id={item.id} onClick={() => setSelectedLayout(item.enum)}>
                            <GridImage src={item.image} active={selectedLayout === item.enum} alt={item.id} />
                            {selectedLayout === item.enum && <SelectedImage src={images.selectedIcon} />}
                        </ButtonBase>
                    </Grid>
                )}
            </StyledGrid>
        </FormContent>
        <FormFooter>
            <FormButtonGroup>
                <CustomButton onClick={() => handleSelectLayout()} id='select'>
                    <TransMessage {...i18nKeys.umbrella_dashboard_choose_layout_save_button} />
                </CustomButton>
            </FormButtonGroup>
        </FormFooter>
    </FormWrapper>
}

export default ChooseLayout