import axiosClient from 'api/axiosClient'
import { ENotificationPriority, EPerformanceState } from 'enums/enums'
import { TOverallState, TPerformanceItem } from 'types/businessLogic/performance'
import { SetThresholdsSitesRequest } from 'types/businessLogic/site'
import { TSiteServicesPerSite } from 'types/businessLogic/siteServicePerformance'
import { TFetchParamsByIdRequest, TFetchParamsRequest } from 'types/network'

class SiteServicePerformanceService {
    private static _api = axiosClient()

    public static async getOverallStates() {
        const response = await this._api.get<TOverallState>('SiteServicePerformance/OverallStates')
        return response.data
    }
    
    public static async getSiteServices(params: TFetchParamsRequest, filterByStates: EPerformanceState[]) {
        const response = await this._api.get<{siteServices: TSiteServicesPerSite[], totalNumber: number}>('SiteServicePerformance', { params: { ...params, FilterByStates: filterByStates }})
        return response.data
    }

    public static async getSiteServicesBySystemId(id: number, params: TFetchParamsRequest, filterByStates: EPerformanceState[]) {
        const response = await this._api.get<{siteServices: TSiteServicesPerSite[], totalNumber: number}>('SiteServicePerformance/SiteSystem', { params: {Id: id, Filters: params.Filters, FilterByStates: filterByStates }})
        return response.data
    }

    public static async getSiteServicesBySide(params: TFetchParamsByIdRequest, filterByStates: EPerformanceState[]) {
        const response = await this._api.get<TPerformanceItem[]>('SiteServicePerformance/Site', { params: { ...params, FilterByStates : filterByStates }})
        return response.data
    }

    public static async ignoreSiteServices(ids: number[]) {
        await this._api.post('SiteServicePerformance/Ignore', { Ids: ids })
    }

    public static async setThresholdsSiteServices(request : SetThresholdsSitesRequest) {
        await this._api.post('SiteServicePerformance/Thresholds', {
            Ids: request.ids,
            CpuThresholdWarning : request.cpuThresholds[0],
            CpuThresholdCritical : request.cpuThresholds[1],
            CpuUseDefaultThresholds : request.cpuUseDefault ?? false,
            MemoryThresholdWarning : request.memoryThresholds[0],
            MemoryThresholdCritical : request.memoryThresholds[1],
            MemoryUseDefaultThresholds : request.memoryUseDefault ?? false,
            DiskThresholdWarning : request.diskThresholds[0],
            DiskThresholdCritical : request.diskThresholds[1],
            DiskUseDefaultThresholds : request.diskUseDefault ?? false,
            NetworkThresholdWarning: request.networkThresholds[0],
            NetworkThresholdCritical: request.networkThresholds[1],
            NetworkUseDefaultThresholds: request.networkUseDefault ?? false
        })
    }

    public static async setNotificationPriority(ids: number[], notificationPriority: ENotificationPriority, useDefault: boolean) {
        await this._api.post('SiteServicePerformance/NotificationPriority', {
            Ids: ids,
            NotificationPriority: notificationPriority,
            UseDefaultNotificationPriority: useDefault
        })
    }
}

export default SiteServicePerformanceService