import TransMessage from 'components/TransMessage/TransMessage'
import { ETableFilters } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TFilter, TTableGroup, TTableColumn, TTableColumnProperty, TTableRowData } from 'types/ui/table'

class TableHelper {
    static getEntityIds = (data: TTableRowData[], grouped: boolean, groups?: TTableGroup<TTableRowData>[]): (number | string)[] => {
        if (grouped && !!groups) {
            return groups.reduce((acc: (number | string)[], group: TTableGroup<TTableRowData>) => [...acc, ...(group.data?.map(entity => entity.id) ?? [])], [])
        } else {
            return data.map(entity => entity.id)
        }
    }

    static getEntitiesFromGroups = <TExtendedTableRowData extends TTableRowData = TTableRowData>(groups: TTableGroup<TExtendedTableRowData>[]) =>
        groups.reduce((data: TExtendedTableRowData[], group: TTableGroup<TExtendedTableRowData>) =>
            data.concat(...(group.data ?? [])), [])

    static countSelectedRows = (selectedRowIds: (number | string)[], rowIds: (number | string)[]) => {
        return selectedRowIds.reduce((acc: number, selectedRowId: number | string) =>
            rowIds.includes(selectedRowId) ? acc + 1 : acc, 0)
    }

    static countDisplayedRows = (data: TTableRowData[], grouped: boolean, groups?: TTableGroup<TTableRowData>[]) => {
        return grouped && groups ? groups.reduce((acc, group) => acc + (group.data?.length ?? 0), 0) : data.length
    }

    static getActiveHeadCells = (columns: TTableColumn[]): TTableColumn[] => columns.filter(column => column.displayed)

    static getColumnFilters = (columns: TTableColumn[]): TFilter[] => columns.map(p => p.filter ?? { key: '', type: ETableFilters.NONE})

    static getFilterConfig = (propertyType: number, key: string) : TFilter | undefined => {
        switch (propertyType) {
            case GeneralHelper.propertyTypes.bool.index:
                return {
                    key,
                    type: ETableFilters.DROPDOWN,
                    dropdownItems: [
                        {text: <TransMessage {...i18nKeys.dropdown_selection_none} />, value: ''},
                        {text: <TransMessage {...i18nKeys.dropdown_selection_true} />, value: true},
                        {text: <TransMessage {...i18nKeys.dropdown_selection_false} />, value: false}
                    ]
                }
            case GeneralHelper.propertyTypes.dateTime.index:
                return undefined
            default:
                return {key, type: ETableFilters.INPUT_TEXT}
        }
    }

    static prepareTableColumns = (properties: TTableColumnProperty[]): TTableColumn[] =>
        properties.map((prop: TTableColumnProperty) : TTableColumn => ({
            key: prop.displayKey,
            translationKey: prop.translationKey,
            groupKey: prop.groupingKey,
            displayed: false,
            filter: TableHelper.getFilterConfig(prop.type, prop.groupingKey)
        }))
}

export default TableHelper