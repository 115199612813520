import leaflet, { Layer, Marker } from 'leaflet'
import { useMap, Tooltip } from 'react-leaflet'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'
import React from 'react'
import { TSiteMin } from 'types/businessLogic/site'
import GatewayMarker from './GatewayMarker'

const MarkerTooltip = styled(Tooltip)`
    .block {
        margin-top: 8px;
        margin-bottom: 8px;
        max-width: 150px;
        font-family: ${fonts.PRIMARY_BOLD};
        font-size: ${typographyScale.TYPE14};
        line-height: ${lineHeights.TYPE14LineHeight};
        letter-spacing: ${letterSpacings.TYPE16LS};
    }

    .title {
        display: block;
        white-space: normal;
        color: ${colors.shades.DARK_SHADE};
    }

    .subtitle {
        display: block;
        white-space: normal;
        color: ${colors.primary.DARK_PRIMARY};
        text-overflow: ellipsis;
        overflow: hidden;
    }
`

interface MarkerRendererProps {
    markers : TSiteMin[]
    onMarkerSelected?: (id: number) => void
}

export const MarkerRenderer = ({ markers, onMarkerSelected } : MarkerRendererProps) => {
    const layers : Layer[] = []
    const map = useMap()
    
    markers.forEach(x => {
        if (x.latitude !== undefined && x.longitude !== undefined) {
            layers.push(new Marker([x.latitude, x.longitude]))
        }
    })

    if (layers.length > 0) {
        const group : leaflet.FeatureGroup<TSiteMin> = leaflet.featureGroup(layers)
        map.fitBounds(group.getBounds(), { maxZoom: 10 })
    }

    const renderMarkers = () => markers.map((marker, index) => {
        return <GatewayMarker
            key={index}
            gateway={marker}
            onClick={() => onMarkerSelected && onMarkerSelected(marker.id)}
        >
            <MarkerTooltip

                direction='bottom'
                offset={[0, 20]}
            >
                <div className='block'>
                    <span className='title'>Name</span>
                    <span className='subtitle'>{marker.name}</span>
                </div>
                <div className='block'>
                    <span className='title'>Location</span>
                    <span className='subtitle'>{marker.location ? marker.location : '-'}</span>
                </div>
                <div className='block'>
                    <span className='title'>Labels</span>
                    <span className='subtitle'>
                        {marker.labels.join(',')}
                    </span>
                </div>
            </MarkerTooltip>
        </GatewayMarker>
    })

    return (
        <>{renderMarkers()}</>
    )
}