import GeneralHelper from 'helpers/GeneralHelper'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { useSnackBar } from 'hooks/useSnackBar'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback } from 'react'
import ApiKeyService from 'services/ApiKeyService'
import { getApiKeysAsync } from 'state/slices/apieKeySlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import styled from 'styled-components'
import { TTableFetchState } from 'types/network'
import { TModalProps } from 'types/ui/modal'
import ApiKeys from 'views/Admin/ApiKeys/ApiKeys'
import ApiKeysTablePreparer from 'views/Admin/ApiKeys/ApiKeysTablePreparer'
import {tableInfo} from './ApiKeysTableColumns'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const ApiKeysContainer = () => {
    const dispatch = useAppDispatch()
    const {success, error} = useSnackBar()
    
    const {apiKeys, totalNumber} = useAppSelector(store => store.apiKeys)

    const getTableData = useCallback((params: TTableFetchState) => {
        const requestParams = GeneralHelper.buildRequestFromTableState(params)
        dispatch(getApiKeysAsync(requestParams))
    }, [dispatch])

    useBreadCrumbs([i18nKeys.breadcrumb_apiKeys])

    const createApiKey = async () => {
        try {
            await ApiKeyService.createApiKey()
            success(i18nKeys.apikey_created_success)
        } catch (err) {
            error(i18nKeys.apikey_created_error)
        }
    }

    const deleteApiKeys = async (params: TModalProps) => {
        try {
            await ApiKeyService.deleteApiKeys(params.ids as number[])
            success(i18nKeys.apikey_delete_sucess)
        } catch (err) {
            error(i18nKeys.apikey_delete_error)
        }
    }

    const data = ApiKeysTablePreparer.prepareApiKeyRows(apiKeys)

    if (!data) {
        return <></>
    }

    return (
        <ViewContainer>
            <ApiKeys
                data={data}
                totalCount={totalNumber}
                getTableData={getTableData}
                tableInfo={tableInfo}
                onDeleteApiKeys={deleteApiKeys}
                onCreateApiKey={createApiKey}
            />
        </ViewContainer>
    )
}
export default ApiKeysContainer