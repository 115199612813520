import { Button } from '@mui/material'
import TransMessage from 'components/TransMessage/TransMessage'
import dayjs, { Dayjs } from 'dayjs'
import { i18nKeys } from 'i18n/keys'
import React, { useState } from 'react'
import { ButtonGroupWrapper } from './TimeRangeButtonGroup.styled'

export interface TimeRange {
    from: Dayjs
    till: Dayjs
}

interface TimeRangeButtonGroupProsps {
    onChange : (value: TimeRange) => void
}

export const TimeRangeButtonGroup = ({onChange} : TimeRangeButtonGroupProsps) => {
    const [selectedIndex, setSelectedIndex] = useState(2)
    
    const setActiveClassName = (value: number) => {
        return selectedIndex === value ? 'active' : ''
    }

    const handleOnClickButton = (value: TimeRange, index: number) => {
        onChange(value)
        setSelectedIndex(index)
    }

    const predefinedRanges = [
        {
            label: i18nKeys.umbrella_core_last_hour,
            getRange: () => ({
                from: dayjs().subtract(1, 'hour'),
                till: dayjs()
            })
        },
        {
            label: i18nKeys.umbrella_core_last_day,
            getRange: () => ({
                from: dayjs().subtract(1, 'days'),
                till: dayjs()
            })
        },
        {
            label: i18nKeys.umbrella_core_last_week,
            getRange: () => ({
                from: dayjs().subtract(1, 'week'),
                till: dayjs()
            })
        }
    ]

    return (
        <ButtonGroupWrapper>
            {predefinedRanges.map((ranges, index) => (
                <Button sx={{ overflow: 'hidden' }} key={index} className={setActiveClassName(index)} onClick={() => handleOnClickButton(ranges.getRange(), index)}>
                    <TransMessage {...ranges.label} />
                </Button>
            ))}
        </ButtonGroupWrapper>
    )
}