import ColumnChooser from 'components/ColumnChooser/ColumnChooser'
import { CustomPieChart, TChartEntry } from 'components/CustomControls/CustomCharts'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { Icons } from 'components/Icon/Icon'
import AdvancedTable from 'components/Tables/AdvancedTable'
import ColumnPreset from 'components/Tables/ColumnPreset'
import SelectionBox from 'components/Tables/SelectionBox'
import TableHelper from 'components/Tables/TableHelper'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import Toolbar from 'components/Toolbar/Toolbar'
import TransMessage from 'components/TransMessage/TransMessage'
import { EDataSyncItems, EJobState, EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import { i18nKeys } from 'i18n/keys'
import SiteHelper from 'helpers/SiteHelper'
import React, { useCallback, useEffect, useState } from 'react'
import colors from 'style/colors'
import fonts from 'style/fonts'
import styled from 'styled-components'
import { TBatchJob } from 'types/businessLogic/job'
import { TTableFetchState } from 'types/network'
import { TTableGroup, TTableRowData, TTableInfo } from 'types/ui/table'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`
const Content = styled.div`
    margin-left: 120px;
    margin-top: 70px;
`
const Heading = styled.h1`
    font-family: ${fonts.PRIMARY_HEAVY};
    font-size: ${typographyScale.TYPE32};
    line-height: ${lineHeights.TYPE32LineHeight};
    letter-spacing: ${letterSpacings.TYPE32LS};
    color: ${colors.primary.DARK_PRIMARY};
    padding-bottom: 24px;
`
const Description = styled.p`
    font-family: ${fonts.PRIMARY_REGULAR};
    font-size: ${typographyScale.TYPE16};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE16LS};
    color: ${colors.primary.DARK_PRIMARY};
`
const ChartContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 48px;
`
const Legend = styled.div`
    margin-left: 80px;
`
const LegendItem = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    padding-top: 12px;
`
const LegendBox = styled.div`
    width: 16px;
    height: 16px;
`
const LegendText = styled.div`
    font-family: ${fonts.PRIMARY_SEMIBOLD};
    font-size: ${typographyScale.TYPE14};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE14LS};
    padding-left: 24px;
    color: ${colors.shades.DEEP_SHADE};
`
const PieChartContainer = styled.div`
    position: relative;
`
const BatchJobTableSection = styled.div`
    position: relative;
    width: 100%;
`

type TProps = {
    data: Array<TTableRowData & {job: string}>
    groups: TTableGroup<TTableRowData & {job: string}>[]
    totalCount: number
    tableInfo: TTableInfo
    batchJobId: number
    batchJob: TBatchJob
    chartData: TChartEntry[]
    getBatchJobDetails: (params: TTableFetchState) => void
    cancelJobs: (selectedRows: Array<TTableRowData & {job: string}>) => void
    retryJobs: (selectedRows: Array<TTableRowData & {job: string}>) => void
    isDetailOpen: boolean
    getBatchJob: () => void
}

const BatchJobDetails = (props: TProps) => {
    const {batchJob, chartData, tableInfo, groups, totalCount, data, getBatchJobDetails, getBatchJob, isDetailOpen} = props
    const [identifier] = useState(uid())
    const authorize = useAuthorization()
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)
 
    const colHandlerProps = useColumnsHandler(tableInfo)

    const enhanceTableProps = useEnhanceTable<TTableRowData & {job: string}>({
        groups
    })

    const loadData = useCallback(() => {
        getBatchJobDetails(enhanceTableProps.fetchState)
        getBatchJob()
    }, [enhanceTableProps.fetchState, getBatchJobDetails, getBatchJob])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.JOBS, loadData, identifier)

    useEffect(() => {
        getBatchJobDetails(enhanceTableProps.fetchState)
    }, [enhanceTableProps.fetchState, getBatchJobDetails])

    useEffect(() => {
        const hasSelectedRows = enhanceTableProps.selectionCount > 0
        setDisabled(identifier, isDetailOpen || hasSelectedRows)
        setAutoupdatePaused(isDetailOpen || hasSelectedRows)
    }, [enhanceTableProps.selectionCount, identifier, isDetailOpen, setDisabled])

    const cancelJobs = () => {
        props.cancelJobs(enhanceTableProps.selectedRows)
        enhanceTableProps.deselectAllRows()
    }

    const retryJobs = () => {
        props.retryJobs(enhanceTableProps.selectedRows)
        enhanceTableProps.deselectAllRows()
    }

    const disableToolbarButtons = !enhanceTableProps.selectionCount
    const rowCount = TableHelper.countDisplayedRows(data, !!enhanceTableProps.fetchState.global.groupBy, groups)
    const totalSelectedRows = enhanceTableProps.selectAll ? totalCount : enhanceTableProps.selectionCount

    const isToolbarAuthorized = authorize(EProfileRights.IS_SITE_ADMIN)

    return (
        <ViewWrapper>
            <Content>
                <Heading>
                    <TransMessage {...i18nKeys.batch_job_details_title} />
                </Heading>
                <Description>
                    {batchJob.description}
                </Description>
                <ChartContainer>
                    <PieChartContainer>
                        <CustomPieChart data={chartData} />
                    </PieChartContainer>
                    <Legend>
                        <LegendItem>
                            <LegendBox style={{backgroundColor: SiteHelper.getJobStateColor(EJobState.FAILED)}} />
                            <LegendText id='countFailed'>
                                <TransMessage
                                    {...i18nKeys.batch_job_details_chart_legend_failed}
                                    values={{value: batchJob.countFailed}}
                                />
                            </LegendText>
                        </LegendItem>
                        <LegendItem>
                            <LegendBox style={{backgroundColor: SiteHelper.getJobStateColor(EJobState.DONE)}} />
                            <LegendText id='countDone'>
                                <TransMessage
                                    {...i18nKeys.batch_job_details_chart_legend_done}
                                    values={{value: batchJob.countDone}}
                                />
                            </LegendText>
                        </LegendItem>
                        <LegendItem>
                            <LegendBox style={{backgroundColor: SiteHelper.getJobStateColor(EJobState.TODO)}} />
                            <LegendText id='countTodo'>
                                <TransMessage
                                    {...i18nKeys.batch_job_details_chart_legend_todo}
                                    values={{value: batchJob.countTodo}}
                                />
                            </LegendText>
                        </LegendItem>
                        <LegendItem>
                            <LegendBox style={{backgroundColor: SiteHelper.getJobStateColor(EJobState.CANCELED)}} />
                            <LegendText id='countCanceled'>
                                <TransMessage
                                    {...i18nKeys.batch_job_details_chart_legend_canceled}
                                    values={{value: batchJob.countCanceled}}
                                />
                            </LegendText>
                        </LegendItem>
                        <LegendItem>
                            <LegendBox style={{ backgroundColor: SiteHelper.getJobStateColor(EJobState.TRANSMITTED)}} />
                            <LegendText id='countTransmitted'>
                                <TransMessage
                                    {...i18nKeys.batch_job_details_chart_legend_transmitted}
                                    values={{value: batchJob.countTransmitted}}
                                />
                            </LegendText>
                        </LegendItem>
                    </Legend>
                </ChartContainer>
            </Content>

            <BatchJobTableSection>
                <Toolbar
                    LeftContent={
                        <>
                            {
                                isToolbarAuthorized && (
                                    <>
                                        <SelectionBox
                                            id='selectedSiteJobsCheckBox'
                                            rowCount={enhanceTableProps.selectAll ? totalSelectedRows : rowCount}
                                            onAllRowsSelect={checked => enhanceTableProps.onAllRowsSelect(props.data, checked)}
                                            messageKey={i18nKeys.site_jobs_table_toolbar_selected_count}
                                            totalSelectedRows={totalSelectedRows}
                                            numSelectedRows={enhanceTableProps.selectAll ? totalSelectedRows : enhanceTableProps.selectionCount}
                                            switchSelectionMode={event => enhanceTableProps.switchSelectionMode(event, props.data)}
                                            disabled={enhanceTableProps.selectAll}
                                        />
                                        <CustomTooltip title={<TransMessage {...i18nKeys.site_jobs_table_toolbar_retry_job} />}>
                                            <div>
                                                <CustomIconButton
                                                    id='retryJobsBtn'
                                                    disabled={disableToolbarButtons}
                                                    onClick={retryJobs}
                                                    icon={Icons.RETRY}
                                                />
                                            </div>
                                        </CustomTooltip>
                                        <CustomTooltip title={<TransMessage {...i18nKeys.site_jobs_table_toolbar_cancel_job} />}>
                                            <div>
                                                <CustomIconButton
                                                    id='cancelJobsBtn'
                                                    disabled={disableToolbarButtons}
                                                    onClick={cancelJobs}
                                                    icon={Icons.CANCEL}
                                                />
                                            </div>
                                        </CustomTooltip>
                                    </>
                                )
                            }
                        </>
                    }
                    RightContent={
                        <>
                            <ColumnChooser
                                {...colHandlerProps}
                            />
                            <ColumnPreset
                                tableId={tableInfo.id}
                                {...colHandlerProps}
                            />
                            <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                        </>
                    }
                />

                <AdvancedTable
                    {...colHandlerProps}
                    {...enhanceTableProps}
                    maxHeight='49vh'
                    count={totalCount}
                    rows={data}
                    groups={groups}
                />
            </BatchJobTableSection>
            
        </ViewWrapper>
    )
}

export default BatchJobDetails
