import React, { useCallback, useEffect, useState } from 'react'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import styled from 'styled-components'
import { TTableFetchState, TGroup } from 'types/network'
import { TTableGroup, TTableRowData, TTableInfo } from 'types/ui/table'
import Toolbar from 'components/Toolbar/Toolbar'
import useAuthorization from 'hooks/useAuthorization'
import { EDataSyncItems, EProfileRights } from 'enums/enums'
import SelectionBox from 'components/Tables/SelectionBox'
import { i18nKeys } from 'i18n/keys'
import useLicense from 'hooks/useLicense'
import { ELicenseTypes } from 'enums/ELicenseTypes'
import TransMessage from 'components/TransMessage/TransMessage'
import DeleteButton from 'components/Tables/DeleteButton'
import ColumnPreset from 'components/Tables/ColumnPreset'
import ColumnChooser from 'components/ColumnChooser/ColumnChooser'
import TableHelper from 'components/Tables/TableHelper'
import { TModalProps } from 'types/ui/modal'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`

type TProps = {
    data: TTableRowData[]
    groups: TTableGroup<TTableRowData>[]
    totalCount: number
    tableInfo: TTableInfo
    getTableData: (params: TTableFetchState) => void
    getGroupData: (params: TTableFetchState, group: TGroup) => void
    onDeletePlugins: (params: TModalProps) => void
    preselectedFilter? : string
}

const Plugins = (props: TProps) => {
    const [identifier] = useState(uid())
    const { tableInfo, data, totalCount, groups, preselectedFilter, getTableData, getGroupData } = props
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)
    
    const colHandlerProps = useColumnsHandler(tableInfo)

    const enhanceTableProps = useEnhanceTable({
        groups
    })

    useEffect(() => {
        if (preselectedFilter) {
            enhanceTableProps.fetchState.global.filters = {
                State: preselectedFilter
            }
        }
    })

    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
        enhanceTableProps.selectedGroups.forEach(group => {
            getGroupData(enhanceTableProps.fetchState, group)
        })
    }, [enhanceTableProps.fetchState, enhanceTableProps.selectedGroups, getGroupData, getTableData])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.PLUGIN, loadData, identifier)

    useEffect(() => {
        const hasSelectedRows = enhanceTableProps.selectionCount > 0
        setDisabled(identifier, hasSelectedRows)
        setAutoupdatePaused(hasSelectedRows)
    }, [enhanceTableProps.selectionCount, identifier, setDisabled])

    const buildModalProps = useCallback(() : TModalProps => {
        enhanceTableProps.deselectAllRows()
        return {
            ids: enhanceTableProps.getSelectedIds() as number[],
            filters: enhanceTableProps.getSelectAllFilters()
        }
    }, [enhanceTableProps])

    const onPluginsDelete = useCallback(() => {
        props.onDeletePlugins(buildModalProps())
    }, [buildModalProps, props])

    const authorize = useAuthorization()
    const license = useLicense()

    const actionsAuthorized = authorize(EProfileRights.IS_SITE_ADMIN)
    const deleteSitesAuthorized = authorize(EProfileRights.HAS_DELETE_SITES_RIGHT)
    
    const actionsLicensed = license(ELicenseTypes.CONFIGURATION)

    const disableToolbarButtons = !enhanceTableProps.selectionCount
    const rowCount = TableHelper.countDisplayedRows(data, !!enhanceTableProps.fetchState.global.groupBy, groups)
    const totalSelectedRows = enhanceTableProps.selectAll ? totalCount : enhanceTableProps.selectionCount

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        {
                            (actionsAuthorized) && (
                                <SelectionBox
                                    id='selectedPluginsCheckbox'
                                    rowCount={enhanceTableProps.selectAll ? totalSelectedRows : rowCount}
                                    onAllRowsSelect={checked => enhanceTableProps.onAllRowsSelect(props.data, checked)}
                                    messageKey={i18nKeys.site_list_toolbar_selected_sites}
                                    totalSelectedRows={totalSelectedRows}
                                    numSelectedRows={enhanceTableProps.selectAll ? totalSelectedRows : enhanceTableProps.selectionCount}
                                    switchSelectionMode={event => enhanceTableProps.switchSelectionMode(event, props.data)}
                                    disabled={enhanceTableProps.selectAll}
                                />
                            )
                        }
                        {
                            actionsAuthorized && actionsLicensed && deleteSitesAuthorized &&
                                <DeleteButton
                                    id='deletePluginsBtn'
                                    title={<TransMessage {...i18nKeys.plugin_list_toolbar_delete_plugins_tooltip} />}
                                    disabled={disableToolbarButtons}
                                    onClick={onPluginsDelete}
                                />
                        }
                    </>
                }
                RightContent={
                    <>
                        <ColumnChooser
                            minColumns={1}
                            {...colHandlerProps}
                        />
                        <ColumnPreset
                            tableId={tableInfo.id}
                            {...colHandlerProps}
                        />
                        <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                    </>
                }
            />
            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                rows={data}
                groups={groups}
            />
        </ViewWrapper>
    )
}

export default Plugins
