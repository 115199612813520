import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { TUmbrellaUser } from 'types/businessLogic/user'
import { tableInfo } from 'views/Admin/UmbrellaUser/UmbrellaUserTableColumns'
import CustomLink from 'components/CustomControls/CustomLink'

class UmbrellaUserTablePreparer {
    public static prepareUmbrellaUserRows = (umbrellaUsers: TUmbrellaUser[], openUmbrellaUserDetailsModal: (id: string) => void): TTableRowData[] =>
        umbrellaUsers.map(umbrellaUser => ({
            ...umbrellaUser,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.username.key,
                    translationKey: tableInfo.columnConfig.username.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: () =>
                        <CustomLink onClick={() => openUmbrellaUserDetailsModal(umbrellaUser.id)}>{umbrellaUser.userName}</CustomLink>
                },
                {
                    name: tableInfo.columnConfig.builtIn.key,
                    translationKey: tableInfo.columnConfig.builtIn.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.bool.index,
                    [GeneralHelper.propertyTypes.bool.key]: umbrellaUser.builtIn
                },
                {
                    name: tableInfo.columnConfig.activeDirectory.key,
                    translationKey: tableInfo.columnConfig.activeDirectory.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.bool.index,
                    [GeneralHelper.propertyTypes.bool.key]: umbrellaUser.activeDirectory
                },
                {
                    name: tableInfo.columnConfig.roles.key,
                    translationKey: tableInfo.columnConfig.roles.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: umbrellaUser.roles
                },
                {
                    name: tableInfo.columnConfig.lastLogin.key,
                    translationKey: tableInfo.columnConfig.lastLogin.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.dateString.index,
                    [GeneralHelper.propertyTypes.dateString.key]: umbrellaUser.lastLogin
                }
            ]
        }))

    public static prepareGroups = (
        groups: TTableGroup<TUmbrellaUser>[],
        openUmbrellaUserDetailsModal: (id: string) => void): TTableGroup<TTableRowData>[] => groups.map(group => ({
        ...group,
        data: group.data ? UmbrellaUserTablePreparer.prepareUmbrellaUserRows(group.data, openUmbrellaUserDetailsModal) : undefined
    }))
}

export default UmbrellaUserTablePreparer