import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useState } from 'react'
import Validation from 'helpers/Validation'
import SiteService from 'services/siteService'
import { showMessage } from 'state/slices/uiSlice'
import { TCheckLicense } from 'types/businessLogic/site'
import ReviewSiteLicenses from 'views/Control/SiteLicenses/Modals/UpdateSiteLicense/ReviewSiteLicenses'
import UploadSiteLicenses from 'views/Control/SiteLicenses/Modals/UpdateSiteLicense/UploadSiteLicenses'
import CustomStepper from 'components/CustomStepper/CustomStepper'
import { useAppDispatch } from 'state/store'
import { useModal } from 'hooks/modalProvider'

type UpdateSiteLicenseState = {
    license: File
    sites: TCheckLicense[]
}

const UpdateSiteLicenses = () => {
    const {closeModal} = useModal()
    const dispatch = useAppDispatch()
    const [reviewSiteLicenses, setReviewSiteLicenses] = useState<UpdateSiteLicenseState | undefined>(undefined)

    const onDrop = (async (acceptedFiles: File[]) => {
        
        if (acceptedFiles.length === 0) {
            dispatch(showMessage({
                message : i18nKeys.site_license_update_upload_file_ext_restriction,
                type: EMessageType.ERROR
            }))
            return
        }
        
        const licenseFile = acceptedFiles[0]

        if (!Validation.validateFileExt(licenseFile.name, ['.zip', '.key'])) {
            dispatch(showMessage({
                message : i18nKeys.site_license_update_upload_file_ext_restriction,
                type: EMessageType.ERROR
            }))
            return
        }

        try {
            const response = await SiteService.checkSiteLicense(licenseFile)
            setReviewSiteLicenses({
                license: licenseFile,
                sites: response.sites
            })
        } catch {
            dispatch(showMessage({
                message : i18nKeys.site_license_update_upload_file_check_error,
                type: EMessageType.ERROR
            }))
        }
    })

    const steps = [
        i18nKeys.site_license_step_one,
        i18nKeys.site_license_step_two
    ]

    return (
        reviewSiteLicenses ?
            <ReviewSiteLicenses
                sites={reviewSiteLicenses.sites}
                license={reviewSiteLicenses.license}
                handleClose={closeModal}
            >
                <CustomStepper
                    stepIndex={1}
                    steps={steps}
                />
            </ReviewSiteLicenses> :
            <UploadSiteLicenses onDrop={onDrop}>
                <CustomStepper
                    stepIndex={0}
                    steps={steps}
                />
            </UploadSiteLicenses>
    )
}

export default UpdateSiteLicenses