import { Tabs } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import style from 'style'
import colors from 'style/colors'
import zIndex from 'style/zIndex'
import styled from 'styled-components'

const CustomTabs = styled(Tabs)`
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 40px;
    padding-right: 40px;

    .MuiTabs-indicator {
        display: none;
    }
    .MuiTabs-scroller {
        overflow-x: auto !important;
        overflow-y: hidden !important;      
    }
    .MuiTabs-flexContainer {
        
        padding-bottom: 10px;
    }
`

const Container = styled.div`
    position: sticky;
    min-height: 120px;
`
const FixedTitleWrapperModal = styled.div`
    z-index: ${zIndex.DEFAULT};
`
const TabsWrapper = styled.div`
    background-color: white;
    box-shadow: 0 10px 10px -10px rgb(0 0 0 / 10%);
    padding-bottom: 20px;

    .MuiTabs-root {
        background-color: white;
    }

    & header {
        background-color: transparent;
        box-shadow: none;
        border: 0;
    }
`
const SecondaryTitleWrapper = styled.div`
    font-family: ${style.fonts.PRIMARY_REGULAR};
    color: ${colors.primary.DARK_PRIMARY};
    padding-top: 20px;
    padding-left: 40px;
`

type TProps = {
    activeTab: number
    subtitle?: JSX.Element
    isChangeMultipleEntityProps: boolean
    onTabChange: (activeTab: number) => void
}

const TabsHeader = (props: PropsWithChildren<TProps>) => {
    const { subtitle, activeTab, children } = props

    return (
        <Container>
            <FixedTitleWrapperModal>
                <TabsWrapper>
                    <CustomTabs
                        value={activeTab}
                        onChange={(_, value) => props.onTabChange(value)}
                    >
                        {children}
                    </CustomTabs>
                    <SecondaryTitleWrapper>
                        {subtitle}
                    </SecondaryTitleWrapper>
                </TabsWrapper>
            </FixedTitleWrapperModal>
        </Container>
    )
}

export default TabsHeader