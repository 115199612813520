import React from 'react'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'
import AppConstants from 'helpers/AppConstants'

const columnConfig: TTableColumnsConfig = {
    name: {
        key: 'name',
        translationKey: i18nKeys.site_list_table_head_name.id,
        displayed: true,
    },
    labels: {
        key: 'labels',
        translationKey: i18nKeys.site_list_table_head_labels.id,
        displayed: true,
    }
}

const defaultLayout = [
    columnConfig.name.key,
    columnConfig.labels.key,
]

export const tableInfo : TTableInfo = {
    id: AppConstants.tableIds.SITE_LICENCES,
    defaultLayout,
    columnConfig
}