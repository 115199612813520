import { Input, SelectChangeEvent } from '@mui/material'
import { CustomCheckbox } from 'components/CustomControls/CustomCheckbox'
import DropdownItem from 'components/Dropdown/DropdownItem'
import Dropdown from 'components/Dropdown/Dropdown'
import TransMessage, { TIntlMessage } from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useState } from 'react'
import colors from 'style/colors'
import fonts from 'style/fonts'
import styled from 'styled-components'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'

type TProps = {
    id: string
    numSelectedRows: number
    totalSelectedRows: number
    rowCount: number
    messageKey: TIntlMessage
    onAllRowsSelect: (checked: boolean) => void
    switchSelectionMode?: (event: SelectChangeEvent<unknown>) => void
    disabled: boolean
}

type TSelectionBoxOptions = {
    [key: string]: TSelectionBoxOption
}

type TSelectionBoxOption = {
    value: string
    translation: TIntlMessage
}

export const selectionBoxOptions: TSelectionBoxOptions = {
    SELECT_CURRENT_PAGE: {
        value: 'select-current-page',
        translation: i18nKeys.selectionbox_toolbar_select_mode_page
    },
    SELECT_ALL: {
        value: 'select-all',
        translation: i18nKeys.selectionbox_toolbar_select_mode_selectAll
    }
}

const Container = styled.div`
    display: flex;
    align-items: center;
`
const Selected = styled.span`
    margin-right: 4px;
    font-family: ${fonts.PRIMARY_SEMIBOLD};
    font-size: ${typographyScale.TYPE16};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE16LS};
    color: ${colors.primary.DARK_PRIMARY};
`

const SelectionBox = (props: TProps) => {
    const { id, numSelectedRows, totalSelectedRows, rowCount, messageKey, disabled } = props
    const { onAllRowsSelect, switchSelectionMode } = props
    const [value, setValue] = useState(selectionBoxOptions.SELECT_CURRENT_PAGE.value)

    const handleOnChange = (event: SelectChangeEvent<unknown>) => {
        setValue(event.target.value as string)
        switchSelectionMode && switchSelectionMode(event)
    }

    if (numSelectedRows === 0 && value !== selectionBoxOptions.SELECT_CURRENT_PAGE.value) {
        setValue(selectionBoxOptions.SELECT_CURRENT_PAGE.value)
    }

    return (
        <Container>
            <CustomCheckbox
                id={id}
                indeterminate={numSelectedRows > 0 && numSelectedRows < rowCount}
                checked={numSelectedRows > 0 && numSelectedRows === rowCount}
                onChange={(_, checked) => onAllRowsSelect(checked)}
                disabled={disabled}
            />
            
            <Selected id='selectedSitesCount'>
                <TransMessage {...messageKey} values={{count: totalSelectedRows}} />
            </Selected>
            {
                !!switchSelectionMode &&
                <Dropdown
                    iconColor={colors.primary.DARK_PRIMARY}
                    small
                    noText
                    onChange={handleOnChange}
                    value={value}
                    customInput={<Input value='' id='select-all-rows-dropdown' disableUnderline />}
                >
                    {
                        Object.keys(selectionBoxOptions).map((item, index) => (
                            <DropdownItem key={index} value={selectionBoxOptions[item].value}>
                                <TransMessage {...selectionBoxOptions[item].translation} />
                            </DropdownItem>
                        ))
                    }
                </Dropdown>
            }
        </Container>
    )
}

export default SelectionBox