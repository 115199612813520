import React, { ReactElement, ReactNode, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { getLicenseAsync } from 'state/slices/licenseSlice'
import { getPluginTypesAsync } from 'state/slices/pluginTypeSlice'
import { getPluginKindTypesAsync } from 'state/slices/pluginKindTypeSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import EMailContainer from 'views/Admin/EMail/EMailContainer'
import LicenseContainer from 'views/Admin/License/LicenseContainer'
import SettingsContainer from 'views/Admin/Settings/SettingsContainer'
import UmbrellaRolesContainer from 'views/Admin/UmbrellaRoles/UmbrellaRolesContainer'
import UmbrellaUsersContainer from 'views/Admin/UmbrellaUser/UmbrellaUsersContainer'
import CameraFirmwaresContainer from 'views/Control/CameraFirmwares/CameraFirmwaresContainer'
import SiteCamerasContainer from 'views/Control/SiteCamera/SiteCamerasContainer'
import SiteGroupsContainer from 'views/Control/SiteGroups/SiteGroupsContainer'
import BatchJobContainer from 'views/SiteJobs/BatchJob/BatchJobContainer'
import SiteJobsContainer from 'views/SiteJobs/SiteJobsContainer'
import SiteLicenseContainer from 'views/Control/SiteLicenses/SiteLicenseContainer'
import SitesContainer from 'views/Sites/SitesContainer'
import SiteServicesContainer from 'views/Control/SiteServices/SiteServicesContainer'
import SiteTimeProfilesContainer from 'views/Control/SiteTimeProfiles/SiteTimeProfilesContainer'
import SiteUsersContainer from 'views/Control/SiteUsers/SiteUsersContainer'
import DashboardContainer from 'views/Dashboard/DashboardContainer'
import LayoutContainer from 'views/Layout/LayoutContainer'
import SiteEventsContainer from 'views/Monitor/SiteEvents/SiteEventsContainer'
import SiteSystemsContainer from 'views/Monitor/SiteSystems/SiteSystemsContainer'
import UmbrellaCoreContainer from 'views/Monitor/UmbrellaCore/UmbrellaCoreContainer'
import SiteAuditReportsContainer from 'views/Report/SiteAudit/SiteAuditReportsContainer'
import SiteInventoryReportsContainer from 'views/Report/SiteInventory/SiteInventoryReportsContainer'
import SitesMapContainer from 'views/SitesMap/SitesMapContainer'
import CamerasPerformanceContainer from 'views/Monitor/Cameras/CamerasPerformanceContainer'
import SiteServicesPerformanceContainer from 'views/Monitor/SiteServices/SiteServicesPerformanceContainer'
import { ELicenseTypes } from 'enums/ELicenseTypes'
import { EProfileRights } from 'enums/enums'
import { checkLoginAsync } from 'state/slices/authSlice'
import AuthGuard, { TAuthGuardProps } from 'components/PrivateRoute/AuthGuard'
import PluginsContainer from 'views/Plugins/PluginsContainer'
import LabelsContainer from 'views/Admin/Labels/LabelsContainer'
import ApiKeysContainer from 'views/Admin/ApiKeys/ApiKeysContainer'

const AppRouter = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { isAuthenticated, loginReqStatus, passwordChangeNeeded } = useAppSelector(store => store.auth)
    const isEulaAccepted = useAppSelector(store => store.ui.isEulaAccepted)

    useEffect(() => {
        dispatch(getLicenseAsync())
        dispatch(getPluginTypesAsync())
        dispatch(getPluginKindTypesAsync())
    }, [dispatch])

    useEffect(() => {
        if (loginReqStatus === '') {
            dispatch(checkLoginAsync())
        }
    }, [dispatch, loginReqStatus])

    useEffect(() => {
        if (isEulaAccepted === false) {
            navigate('/')
            return
        }

        if (passwordChangeNeeded) {
            navigate('/change-password')
        }
    }, [isEulaAccepted, navigate, passwordChangeNeeded])

    if (loginReqStatus !== 'idle') {
        return <> Try to login... </>
    }

    if (!isAuthenticated) {
        return <Navigate to='/login' />
    }

    if (isEulaAccepted === false || passwordChangeNeeded) {
        return null
    }

    const getProtectedElement = (params : TAuthGuardProps) : JSX.Element => {
        return (
            <>
                <AuthGuard
                    {...params}
                >
                    {params.element}
                </AuthGuard>
            </>
        )
    }

    return (
        <LayoutContainer>
            <Routes>
                <Route path='/dashboard' element={getProtectedElement({
                    element: <DashboardContainer />,
                    excludeUsernames: ['administrator']
                })} />

                <Route path='/sites' element={getProtectedElement({
                    element: <SitesContainer />,
                    excludeUsernames: ['administrator']
                })} />
                <Route path='/plugins' element={getProtectedElement({
                    element: <PluginsContainer />,
                    excludeUsernames: ['administrator']
                })} />
                <Route path='/sites/:filter' element={getProtectedElement({
                    element: <SitesContainer />,
                    excludeUsernames: ['administrator']
                })} />
                <Route path='/map' element={getProtectedElement({
                    element: <SitesMapContainer />,
                    excludeUsernames: ['administrator'],
                    ignoreForSituatorExclusive: true
                })} />
                <Route path='/site-jobs' element={getProtectedElement({
                    element: <SiteJobsContainer />,
                    excludeUsernames: ['administrator']
                })} />
                <Route path='/site-jobs/:id' element={getProtectedElement({
                    element: <BatchJobContainer />,
                    excludeUsernames: ['administrator']
                })} />

                <Route path='/control/site-users' element={getProtectedElement({
                    element: <SiteUsersContainer />,
                    expectedLicense: ELicenseTypes.CONFIGURATION,
                    excludeUsernames: ['administrator'],
                    ignoreForSituatorExclusive: true
                })} />
                <Route path='/control/site-groups' element={getProtectedElement({
                    element: <SiteGroupsContainer />,
                    expectedLicense: ELicenseTypes.CONFIGURATION,
                    excludeUsernames: ['administrator'],
                    ignoreForSituatorExclusive: true
                })} />
                <Route path='/control/cameras' element={getProtectedElement({
                    element: <SiteCamerasContainer />,
                    expectedLicense: ELicenseTypes.CONFIGURATION,
                    excludeUsernames: ['administrator'],
                    ignoreForSituatorExclusive: true
                })} />
                <Route path='/control/site-services' element={getProtectedElement({
                    element: <SiteServicesContainer />,
                    expectedLicense: ELicenseTypes.CONFIGURATION,
                    excludeUsernames: ['administrator']
                })} />
                <Route path='/control/site-time-profile' element={getProtectedElement({
                    element: <SiteTimeProfilesContainer />,
                    expectedLicense: ELicenseTypes.CONFIGURATION,
                    excludeUsernames: ['administrator'],
                    ignoreForSituatorExclusive: true
                })} />
                <Route path='/control/camera-firmware' element={getProtectedElement({
                    element: <CameraFirmwaresContainer />,
                    expectedLicense: ELicenseTypes.CONFIGURATION,
                    excludeUsernames: ['administrator'],
                    ignoreForSituatorExclusive: true
                })} />
                <Route path='/control/license-update' element={getProtectedElement({
                    element: <SiteLicenseContainer />,
                    expectedLicense: ELicenseTypes.CONFIGURATION,
                    excludeUsernames: ['administrator'],
                    ignoreForSituatorExclusive: true
                })} />
                <Route path='/monitor/site-systems' element={getProtectedElement({
                    element: <SiteSystemsContainer />,
                    expectedLicense: ELicenseTypes.MONITORING,
                    expectedRights: [EProfileRights.HAS_MONITORING_RIGHT],
                    excludeUsernames: ['administrator']
                })} />
                <Route path='/monitor/site-systems/:filter' element={getProtectedElement({
                    element: <SiteSystemsContainer />,
                    expectedLicense: ELicenseTypes.MONITORING,
                    expectedRights: [EProfileRights.HAS_MONITORING_RIGHT],
                    excludeUsernames: ['administrator']
                })} />
                <Route path='/monitor/umbrella-core' element={getProtectedElement({
                    element: <UmbrellaCoreContainer />,
                    expectedLicense: ELicenseTypes.MONITORING,
                    expectedRights: [EProfileRights.HAS_MONITORING_RIGHT, EProfileRights.IS_UMBRELLA_ADMIN],
                    excludeUsernames: ['administrator']
                })} />
                <Route path='/monitor/cameras' element={getProtectedElement({
                    element: <CamerasPerformanceContainer />,
                    expectedLicense: ELicenseTypes.MONITORING,
                    expectedRights: [EProfileRights.HAS_MONITORING_RIGHT],
                    excludeUsernames: ['administrator'],
                    ignoreForSituatorExclusive: true
                })} />
                <Route path='/monitor/cameras/:filter' element={getProtectedElement({
                    element: <CamerasPerformanceContainer />,
                    expectedLicense: ELicenseTypes.MONITORING,
                    expectedRights: [EProfileRights.HAS_MONITORING_RIGHT],
                    excludeUsernames: ['administrator']
                })} />
                <Route path='/monitor/site-services' element={getProtectedElement({
                    element: <SiteServicesPerformanceContainer />,
                    expectedLicense: ELicenseTypes.MONITORING,
                    expectedRights: [EProfileRights.HAS_MONITORING_RIGHT],
                    excludeUsernames: ['administrator']
                })} />
                <Route path='/monitor/site-services/:filter' element={getProtectedElement({
                    element: <SiteServicesPerformanceContainer />,
                    expectedLicense: ELicenseTypes.MONITORING,
                    expectedRights: [EProfileRights.HAS_MONITORING_RIGHT],
                    excludeUsernames: ['administrator']
                })} />
                <Route path='/monitor/site-events' element={getProtectedElement({
                    element: <SiteEventsContainer />,
                    expectedLicense: ELicenseTypes.MONITORING,
                    expectedRights: [EProfileRights.HAS_MONITORING_RIGHT],
                    excludeUsernames: ['administrator']
                })} />

                <Route path='/report/audit_reports' element={getProtectedElement({
                    element: <SiteAuditReportsContainer />,
                    expectedLicense: ELicenseTypes.REPORT,
                    expectedRights: [EProfileRights.HAS_REPORT_RIGHT, EProfileRights.HAS_SITE_AUDIT_REPORT_RIGHT],
                    excludeUsernames: ['administrator'],
                    ignoreForSituatorExclusive: true
                })} />
                <Route path='/report/reports' element={getProtectedElement({
                    element: <SiteInventoryReportsContainer />,
                    expectedLicense: ELicenseTypes.REPORT,
                    expectedRights: [EProfileRights.HAS_REPORT_RIGHT, EProfileRights.HAS_SITE_MAINTENANCE_REPORT_RIGHT],
                    excludeUsernames: ['administrator'],
                    ignoreForSituatorExclusive: true
                })} />

                <Route path='/admin/settings' element={getProtectedElement({
                    element: <SettingsContainer />,
                    expectedRights: [EProfileRights.IS_UMBRELLA_ADMIN],
                })} />
                <Route path='/admin/email' element={getProtectedElement({
                    element: <EMailContainer />,
                    expectedRights: [EProfileRights.IS_UMBRELLA_ADMIN],
                })} />
                <Route path='/admin/license' element={getProtectedElement({
                    element: <LicenseContainer />,
                    expectedRights: [EProfileRights.IS_UMBRELLA_ADMIN],
                })} />
                <Route path='/admin/users' element={getProtectedElement({
                    element: <UmbrellaUsersContainer />,
                    expectedRights: [EProfileRights.IS_UMBRELLA_ADMIN],
                })} />
                <Route path='/admin/roles' element={getProtectedElement({
                    element: <UmbrellaRolesContainer />,
                    expectedRights: [EProfileRights.IS_UMBRELLA_ADMIN],
                })} />
                <Route path='/admin/labels' element={getProtectedElement({
                    element: <LabelsContainer />,
                    expectedRights: [EProfileRights.IS_UMBRELLA_ADMIN],
                })} />
                <Route path='/admin/apiKeys' element={getProtectedElement({
                    element: <ApiKeysContainer />,
                    expectedLicense: ELicenseTypes.WEBAPI,
                    expectedRights: [EProfileRights.IS_UMBRELLA_ADMIN],
                })} />
            </Routes>
        </LayoutContainer>
    )
}

export default AppRouter