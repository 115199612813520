import React, { useCallback, useEffect, useState } from 'react'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import styled from 'styled-components'
import { TTableFetchState, TGroup } from 'types/network'
import { TTableGroup, TTableRowData, TTableInfo } from 'types/ui/table'
import Toolbar from 'components/Toolbar/Toolbar'
import useAuthorization from 'hooks/useAuthorization'
import { EDataSyncItems, EMessageType, ENotificationPriority, EProfileRights, ESetThresholdType } from 'enums/enums'
import SelectionBox from 'components/Tables/SelectionBox'
import { i18nKeys } from 'i18n/keys'
import useLicense from 'hooks/useLicense'
import { ELicenseTypes } from 'enums/ELicenseTypes'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import TransMessage from 'components/TransMessage/TransMessage'
import { Icons } from 'components/Icon/Icon'
import DeleteButton from 'components/Tables/DeleteButton'
import ColumnPreset from 'components/Tables/ColumnPreset'
import ColumnChooser from 'components/ColumnChooser/ColumnChooser'
import QuickActionMenu from 'components/QuickActionMenu/QuickActionMenu'
import QuickActionMenuItem from 'components/QuickActionMenu/QuickActionMenuItem'
import TableHelper from 'components/Tables/TableHelper'
import { TModalProps } from 'types/ui/modal'
import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import { useAppDispatch, useAppSelector } from 'state/store'
import { EditRoadOutlined, Label } from '@mui/icons-material'
import { useModal } from 'hooks/modalProvider'
import DeploySite from 'views/Sites/Modals/DeploySite'
import DeployMonitoringPlugin from 'views/Sites/Modals/DeployMonitoringPlugin'
import { TSite } from 'types/businessLogic/site'
import ChangeConnectionUserPassword from 'views/Sites/Modals/ChangeConnectionUserPassword'
import CreateSiteUser from 'views/Sites/Modals/CreateSiteUser'
import CreateSiteGroup from 'views/Sites/Modals/CreateSiteGroup'
import SetCustomComments from 'views/Sites/Modals/SetCustomComments'
import SystemMessagesModal from 'views/Sites/Modals/SystemMessages/SystemMessagesModal'
import SetMonitoringThresholds from 'views/Monitor/Modals/SetMonitoringThresholds/SetMonitoringThresholds'
import EditLabels from 'views/Sites/Modals/EditlabelsModal/EditLabels'
import CreateSiteAuditReport from 'views/Sites/Modals/CreateSiteAuditReport'
import SiteService from 'services/siteService'
import { showMessage } from 'state/slices/uiSlice'
import { TSetMonitoringThresholdsFormValues } from 'views/Monitor/Modals/SetMonitoringThresholds/SetMonitoringThresholdsForm'
import SetNotificationPriority, { TSetNotificationPriorityValues } from 'views/Monitor/Modals/SetNotificationPriority/SetNotificationPriority'
import EditNotificationsIcon from '@mui/icons-material/EditNotifications'
import colors from 'style/colors'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`
const DividerBar = styled.div`
    height: 35px;
    width: 2px;
    background: ${colors.shades.GRAY_SHADE};
    margin: 0 5px;
    border-radius: 1px;
`

type TProps = {
    data: TTableRowData[]
    groups: TTableGroup<TTableRowData>[]
    totalCount: number
    tableInfo: TTableInfo
    getTableData: (params: TTableFetchState) => void
    getGroupData: (params: TTableFetchState, group: TGroup) => void
    onDeleteSites: (params: TModalProps) => void
    onApproveSites: (params: TModalProps) => void
    onCreateSitesInventoryReport: (params: TModalProps) => void
    onIgnoreSite: (params: TModalProps) => void
    onRestartSites: (params: TModalProps) => void
    preselectedFilter? : string
}

const Sites = (props: TProps) => {
    const [identifier] = useState(uid())
    const { tableInfo, data, totalCount, groups, preselectedFilter, getTableData, getGroupData } = props
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)
    
    const { situatorExclusive } = useAppSelector(store => store.license)

    const colHandlerProps = useColumnsHandler(tableInfo)

    const enhanceTableProps = useEnhanceTable({
        groups,
        preselectedFilter: preselectedFilter
    })

    const dispatch = useAppDispatch()
    const {showModal} = useModal()

    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
        enhanceTableProps.selectedGroups.forEach(group => {
            getGroupData(enhanceTableProps.fetchState, group)
        })
    }, [enhanceTableProps.fetchState, enhanceTableProps.selectedGroups, getGroupData, getTableData])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.SITES, loadData, identifier)
    
    useEffect(() => {
        const hasSelectedRows = enhanceTableProps.selectionCount > 0
        setDisabled(identifier, hasSelectedRows)
        setAutoupdatePaused(hasSelectedRows)
    }, [enhanceTableProps.selectionCount, identifier, setDisabled])

    const buildModalProps = useCallback(() : TModalProps => {
        return {
            ids: enhanceTableProps.getSelectedIds() as number[],
            filters: enhanceTableProps.getSelectAllFilters()
        }
    }, [enhanceTableProps])

    const onSitesDelete = useCallback(() => {
        props.onDeleteSites(buildModalProps())
        enhanceTableProps.deselectAllRows()
    }, [buildModalProps, enhanceTableProps, props])

    const approveSites = useCallback(() => {
        props.onApproveSites(buildModalProps())
        enhanceTableProps.deselectAllRows()
    }, [buildModalProps, enhanceTableProps, props])

    const generateSiteReport = useCallback(() => {
        props.onCreateSitesInventoryReport(buildModalProps())
        enhanceTableProps.deselectAllRows()
    }, [buildModalProps, enhanceTableProps, props])

    const onIgnoreSite = useCallback(() => {
        props.onIgnoreSite(buildModalProps())
        enhanceTableProps.deselectAllRows()
    }, [buildModalProps, enhanceTableProps, props])
    
    const restartSites = useCallback(() => {
        props.onRestartSites(buildModalProps())
        enhanceTableProps.deselectAllRows()
    }, [buildModalProps, enhanceTableProps, props])

    const authorize = useAuthorization()
    const license = useLicense()

    const actionsAuthorized = authorize(EProfileRights.IS_SITE_ADMIN)
    const monitoringActionsAuthorized = authorize(EProfileRights.HAS_MONITORING_RIGHT)
    const deleteSitesAuthorized = authorize(EProfileRights.HAS_DELETE_SITES_RIGHT)
    const approveSitesAuthorized = authorize(EProfileRights.HAS_APPROVE_SITES_RIGHT)
    const maintenanceReportAuthorized = authorize(EProfileRights.HAS_SITE_MAINTENANCE_REPORT_RIGHT)
    const auditReportAuthorized = authorize(EProfileRights.HAS_SITE_AUDIT_REPORT_RIGHT)
    const restartSiteAuthorized = authorize(EProfileRights.HAS_RESTART_SITE_ENTITIES_RIGHT)
    const deploySiteAuthorized = authorize(EProfileRights.HAS_DEPLOY_SITES_RIGHT)
    const ignoreSiteAuthorized = authorize(EProfileRights.HAS_IGNORE_RIGHT)

    const actionsLicensed = license(ELicenseTypes.CONFIGURATION)
    const reportLicensed = license(ELicenseTypes.REPORT)
    const monitoringLicensed = license(ELicenseTypes.MONITORING)

    const disableToolbarButtons = !enhanceTableProps.selectionCount
    const rowCount = TableHelper.countDisplayedRows(data, !!enhanceTableProps.fetchState.global.groupBy, groups)
    const totalSelectedRows = enhanceTableProps.selectAll ? totalCount : enhanceTableProps.selectionCount

    const disableMonitoringPluginAction = enhanceTableProps.selectAll || totalSelectedRows !== 1
    const disableRestartAction = enhanceTableProps.selectAll || totalSelectedRows === 0

    const getSite = (id: string | number) : TSite => {
        const row = data.find(site => site.id === id)
        if (!row) {
            throw new Error('site not found')
        }

        return row as any
    }

    const getSetNotificationPriorityInitialValue = () : TSetNotificationPriorityValues => {
        const selectedSites = enhanceTableProps.selectedRows as unknown[] as TSite[]

        let result = ENotificationPriority.HIGH

        if (selectedSites.every(x => x.notificationPriority === selectedSites[0].notificationPriority)) {
            result = selectedSites[0].notificationPriority
        }

        return {
            notificationPriority: result,
            useDefault: false
        }
    }

    const handleSetNotificationPriority = () => {
        const ids = buildModalProps().ids as number[]
        const filters = buildModalProps().filters
        showModal({
            content: <SetNotificationPriority
                initialValue={getSetNotificationPriorityInitialValue()}
                onSubmit={async (values: TSetNotificationPriorityValues) => {
                    try {
                        if (ids.length === 0) {
                            await SiteService.sallSetNotificationPriority(values.notificationPriority, filters)
                        } else {
                            await SiteService.setNotificationPriority(ids, values.notificationPriority)
                        }
                        dispatch(showMessage({message: i18nKeys.site_service_performance_ignore_success, type: EMessageType.SUCCESS}))
                    } catch (err) {
                        dispatch(showMessage({message: i18nKeys.site_service_performance_ignore_error, type: EMessageType.ERROR}))
                    }
                }}
                hideUseDefaultValue={true}
            />,
            title: i18nKeys.notification_priority_title,
            description: i18nKeys.notification_priority_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const handleSetThresholds = () => {
        const filters = buildModalProps().filters
        const ids = buildModalProps().ids as number[]
        
        const handleOnSubmit = async (formValues: TSetMonitoringThresholdsFormValues) => {
            try {
                if (ids.length === 0) {
                    await SiteService.allSetThresholdsSites({ filters, ...formValues })
                } else {
                    await SiteService.setThresholdsSites({ ids, ...formValues })
                }
                dispatch(showMessage({message: i18nKeys.site_service_performance_start_success, type: EMessageType.SUCCESS}))
            } catch {
                dispatch(showMessage({message: i18nKeys.site_service_performance_start_error, type: EMessageType.ERROR}))
            }
        }
        
        showModal({
            content: <SetMonitoringThresholds
                ids={ids as number[]}
                type={ESetThresholdType.SITES}
                onSubmit={handleOnSubmit}
            />,
            title: i18nKeys.configure_threshold_sites_title,
            description: i18nKeys.configure_threshold_sites_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }
    
    const handleEditLabelAction = () => {
        const { ids, filters } = buildModalProps()
        showModal({
            content: <EditLabels
                ids={ids as number[]}
                filters={filters}
            />,
            title: i18nKeys.multiconfig_labels_title,
            description: i18nKeys.multiconfig_labels_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const handleSystemMessageConfiguration = () => {
        showModal({
            content: <SystemMessagesModal
                {...buildModalProps()}
            />,
            title: i18nKeys.system_messages_configuration_modal_title,
            description: i18nKeys.system_messages_configuration_modal_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const handleCreateCustomNote = () => {
        showModal({
            content: <SetCustomComments
                {...buildModalProps()}
            />,
            title: i18nKeys.custom_comments_modal_title,
            description: i18nKeys.custom_comments_modal_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const handleSiteAuditReport = () => {
        showModal({
            content: <CreateSiteAuditReport
                {...buildModalProps()}
            />,
            title: i18nKeys.create_audit_report_modal_title,
            description: i18nKeys.create_audit_report_modal_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const handleCreateSiteGroup = () => {
        const modalProps = buildModalProps()
        const title = modalProps.ids.length === 0 ? i18nKeys.create_user_title_preselected_sites : i18nKeys.create_site_group_title
        showModal({
            content: <CreateSiteGroup
                {...modalProps}
                totalCount={totalCount}
            />,
            title: title,
            description: i18nKeys.create_site_group_subtitle,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const handleCreateSiteUser = () => {
        const modalProps = buildModalProps()
        const title = modalProps.ids.length === 0 ? i18nKeys.create_user_title_preselected_sites : i18nKeys.create_user_title
        showModal({
            content: <CreateSiteUser
                {...modalProps}
                totalCount={totalCount}
            />,
            title: title,
            description: i18nKeys.create_user_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const handleDeployMonitoringPluginModal = () => {
        const host = getSite(enhanceTableProps.getSelectedIds()[0]).host
        if (host) {
            showModal({
                content: <DeployMonitoringPlugin
                    {...buildModalProps()}
                    host={host === 'Unknown' ? '' : host}
                />,
                title: i18nKeys.deploy_monitoring_plugin_title,
                description: i18nKeys.deploy_monitoring_plugin_description,
                onClose: () => enhanceTableProps.deselectAllRows()
            })
        }
    }

    const handleDeploySite = () => {
        showModal({
            content: <DeploySite />,
            title: i18nKeys.deploy_site_title,
            description: i18nKeys.deploy_site_description
        })
    }

    const handleChangePasswordModal = () => {
        showModal({
            content: <ChangeConnectionUserPassword
                {...buildModalProps()}
                totalCount={totalCount}
            />,
            title: i18nKeys.change_site_connection_user_modal_title,
            description: i18nKeys.change_site_connection_user_modal_description,
            onClose: () => enhanceTableProps.deselectAllRows()
        })
    }

    const allowRenderSelectionBox = actionsAuthorized || maintenanceReportAuthorized || auditReportAuthorized
    const allowRenderApproveSite = approveSitesAuthorized
    const allowRenderDelete = actionsAuthorized && actionsLicensed && deleteSitesAuthorized
    const allowRenderRestart = restartSiteAuthorized
    const allowRenderIgnore = ignoreSiteAuthorized && monitoringLicensed
    
    const allowRenderCreateUser = actionsAuthorized && actionsLicensed && !situatorExclusive
    const allowRenderChangePassword = actionsAuthorized && actionsLicensed
    const allowRenderSystemMessages = actionsAuthorized && monitoringLicensed

    const allowRenderGenerateSiteReport = maintenanceReportAuthorized && reportLicensed && !situatorExclusive
    const allowRenderAuditReport = auditReportAuthorized && reportLicensed && !situatorExclusive
    const allowRenderSetComments = actionsAuthorized && !situatorExclusive

    const allowRenderMonitoringActions = actionsAuthorized && monitoringActionsAuthorized && monitoringLicensed

    const allowRenderPluginPackage = deploySiteAuthorized && monitoringLicensed

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        {
                            allowRenderSelectionBox && (
                                <SelectionBox
                                    id='selectedSitesCheckbox'
                                    rowCount={enhanceTableProps.selectAll ? totalSelectedRows : rowCount}
                                    onAllRowsSelect={checked => enhanceTableProps.onAllRowsSelect(props.data, checked)}
                                    messageKey={i18nKeys.site_list_toolbar_selected_sites}
                                    totalSelectedRows={totalSelectedRows}
                                    numSelectedRows={enhanceTableProps.selectAll ? totalSelectedRows : enhanceTableProps.selectionCount}
                                    switchSelectionMode={event => enhanceTableProps.switchSelectionMode(event, props.data)}
                                    disabled={enhanceTableProps.selectAll}
                                />
                            )
                        }
                        {
                            allowRenderApproveSite && (
                                <CustomTooltip title={<TransMessage {...i18nKeys.site_list_table_head_approve_multiple_sites} />}>
                                    <div>
                                        <CustomIconButton
                                            id='approveMultipleSitesBtn'
                                            disabled={disableToolbarButtons}
                                            onClick={approveSites}
                                            icon={Icons.TICK}
                                        />
                                    </div>
                                </CustomTooltip>)
                        }
                        {
                            allowRenderDelete && (
                                <DeleteButton
                                    id='deleteSitesBtn'
                                    title={<TransMessage {...i18nKeys.site_list_toolbar_delete_sites_tooltip} />}
                                    disabled={disableToolbarButtons}
                                    onClick={onSitesDelete}
                                />)
                        }
                        {
                            allowRenderRestart && (
                                <CustomTooltip title={<TransMessage {...i18nKeys.site_list_restart_site} />}>
                                    <div>
                                        <CustomIconButton
                                            id='restartGateway'
                                            disabled={disableRestartAction}
                                            onClick={restartSites}
                                            icon={Icons.RETRY}
                                        />
                                    </div>
                                </CustomTooltip>)
                        }
                        {
                            allowRenderIgnore && (
                                <CustomTooltip title={<TransMessage {...i18nKeys.site_list_table_head_ignore_site} />}>
                                    <div>
                                        <CustomIconButton
                                            id='ignoreSite'
                                            disabled={disableToolbarButtons}
                                            onClick={onIgnoreSite}
                                            icon={Icons.EYE_HIDE}
                                        />
                                    </div>
                                </CustomTooltip>)
                        }

                        {
                            actionsAuthorized && (
                                <>
      
                                    <CustomTooltip title={<TransMessage {...i18nKeys.site_list_toolbar_edit_labels} />}>
                                        <div>
                                            <CustomIconButton
                                                id='editLabelsBtn'
                                                data-testid='editLabelsBtn'
                                                disabled={disableToolbarButtons}
                                                onClick={handleEditLabelAction}
                                                icon={<Label />}
                                            />
                                        </div>
                                    </CustomTooltip>
    
                                    <DividerBar />
                                </>
                            )
                        }

                        {
                            allowRenderCreateUser && (
                                <>
                                    <CustomTooltip title={<TransMessage {...i18nKeys.site_list_toolbar_create_user_tooltip} />}>
                                        <div>
                                            <CustomIconButton
                                                id='createUserBtn'
                                                disabled={disableToolbarButtons}
                                                onClick={handleCreateSiteUser}
                                                icon={Icons.ADD_USER}
                                            />
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip title={<TransMessage {...i18nKeys.site_list_toolbar_create_site_group_tooltip} />}>
                                        <div>
                                            <CustomIconButton
                                                id='createSiteGroupBtn'
                                                disabled={disableToolbarButtons}
                                                onClick={handleCreateSiteGroup}
                                                icon={Icons.ADD_SITE_GROUP_SECONDARY}
                                            />
                                        </div>
                                    </CustomTooltip>
                                </>)
                        }
                        {
                            allowRenderChangePassword && (
                                <CustomTooltip title={<TransMessage {...i18nKeys.site_list_connection_user_password} />}>
                                    <div>
                                        <CustomIconButton
                                            id='changePasswordBtn'
                                            disabled={disableToolbarButtons}
                                            onClick={handleChangePasswordModal}
                                            icon={Icons.CHANGE_PASSWORD}
                                        />
                                    </div>
                                </CustomTooltip>)
                        }

                        {
                            allowRenderSystemMessages && (
                                <CustomTooltip title={<TransMessage {...i18nKeys.site_list_toolbar_system_messages_configuration} />}>
                                    <div>
                                        <CustomIconButton
                                            id='systemMessagesConfiguration'
                                            disabled={disableToolbarButtons}
                                            onClick={handleSystemMessageConfiguration}
                                            icon={Icons.SYSTEM_MESSAGES_CONFIG}
                                        />
                                    </div>
                                </CustomTooltip>)
                        }

                        {
                            (allowRenderCreateUser || allowRenderChangePassword || allowRenderSystemMessages) &&
                            <DividerBar />
                        }

                        {
                            allowRenderGenerateSiteReport && (
                                <CustomTooltip title={<TransMessage {...i18nKeys.site_list_table_head_create_report} />}>
                                    <div>
                                        <CustomIconButton
                                            id='generateSiteReport'
                                            disabled={disableToolbarButtons}
                                            onClick={generateSiteReport}
                                            icon={Icons.CREATE_REPORT}
                                        />
                                    </div>
                                </CustomTooltip>)
                        }
                        {
                            allowRenderAuditReport && (
                                <CustomTooltip title={<TransMessage {...i18nKeys.site_list_table_head_create_audit_report} />}>
                                    <div>
                                        <CustomIconButton
                                            id='generateAuditReport'
                                            disabled={disableToolbarButtons}
                                            onClick={handleSiteAuditReport}
                                            icon={Icons.CREATE_AUDIT_REPORT}
                                        />
                                    </div>
                                </CustomTooltip>)
                        }
                        {
                            allowRenderSetComments && (
                                <CustomTooltip title={<TransMessage {...i18nKeys.site_list_set_custom_comments_multiple_sites} />}>
                                    <div>
                                        <CustomIconButton
                                            id='SetCommentsBtn'
                                            disabled={disableToolbarButtons}
                                            onClick={handleCreateCustomNote}
                                            icon={Icons.CREATE_CUSTOM_NOTE}
                                        />
                                    </div>
                                </CustomTooltip>
                            )
                        }

                        {
                            (allowRenderGenerateSiteReport || allowRenderAuditReport || allowRenderSetComments) &&
                            <DividerBar />
                        }

                        {
                            allowRenderMonitoringActions && (
                                <>
                                    <CustomTooltip title={<TransMessage {...i18nKeys.site_list_toolbar_threshold_configuration} />}>
                                        <div>
                                            <CustomIconButton
                                                id='setThresholdsSiteSystemsBtn'
                                                data-testid='setThresholdsSiteSystemsBtn'
                                                disabled={disableToolbarButtons}
                                                onClick={handleSetThresholds}
                                                icon={<EditRoadOutlined />}
                                            />
                                        </div>
                                    </CustomTooltip>

                                    <CustomTooltip title={<TransMessage {...i18nKeys.site_list_toolbar_notification_priority_tooltip} />}>
                                        <div>
                                            <CustomIconButton
                                                id='setNotificationPriorityBtn'
                                                data-testid='setNotificationPriorityBtn'
                                                disabled={totalSelectedRows < 1}
                                                onClick={handleSetNotificationPriority}
                                                icon={<EditNotificationsIcon />}
                                            />
                                        </div>
                                    </CustomTooltip>
                                </>
                            )
                        }

                        {
                            allowRenderPluginPackage &&
                            <DividerBar />
                        }
                        
                        {
                            allowRenderPluginPackage && (
                                <CustomTooltip title={<TransMessage {...i18nKeys.deploy_monitoring_plugin_floating_button_tooltip} />}>
                                    <div>
                                        <CustomIconButton
                                            id='createMonitoringPluginPackage'
                                            disabled={disableMonitoringPluginAction}
                                            onClick={handleDeployMonitoringPluginModal}
                                            icon={Icons.GEAR}
                                        />
                                    </div>
                                </CustomTooltip>)
                        }
                    </>
                }
                RightContent={
                    <>
                        <ColumnChooser
                            minColumns={1}
                            {...colHandlerProps}
                        />
                        <ColumnPreset
                            tableId={tableInfo.id}
                            {...colHandlerProps}
                        />
                        <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                    </>
                }
            />
            
            {
                deploySiteAuthorized && !situatorExclusive && (
                    <QuickActionMenu>
                        <QuickActionMenuItem id='quickActionDeploySite' icon={Icons.GEAR} textKey={i18nKeys.deploy_site_floating_button_tooltip} onClick={handleDeploySite} />
                    </QuickActionMenu>
                )
                
            }
            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                rows={data}
                groups={groups}
            />
        </ViewWrapper>
    )
}

export default Sites
