import React, { useCallback, useState } from 'react'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import styled from 'styled-components'
import { TGroup, TTableFetchState } from 'types/network'
import { TTableGroup, TTableRowData, TTableInfo } from 'types/ui/table'
import Toolbar from 'components/Toolbar/Toolbar'
import useAuthorization from 'hooks/useAuthorization'
import { EDataSyncItems, EProfileRights } from 'enums/enums'
import SelectionBox from 'components/Tables/SelectionBox'
import { i18nKeys } from 'i18n/keys'
import TransMessage from 'components/TransMessage/TransMessage'
import { Icons } from 'components/Icon/Icon'
import DeleteButton from 'components/Tables/DeleteButton'
import QuickActionMenu from 'components/QuickActionMenu/QuickActionMenu'
import QuickActionMenuItem from 'components/QuickActionMenu/QuickActionMenuItem'
import TableHelper from 'components/Tables/TableHelper'
import { TModalProps } from 'types/ui/modal'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import { useModal } from 'hooks/modalProvider'
import CreateUmbrellaUser from 'views/Admin/UmbrellaUser/Modals/CreateUmbrellaUser'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`

type TProps = {
    data: TTableRowData[]
    groups: TTableGroup<TTableRowData>[]
    totalCount: number
    tableInfo: TTableInfo
    builtInusers: string[]
    getTableData: (params: TTableFetchState) => void
    deleteUmbrellaUsers: (params: TModalProps) => void
    getGroupData: (params: TTableFetchState, group: TGroup) => void
}

const UmbrellaUsers = (props: TProps) => {
    const { tableInfo, data, groups, totalCount, getTableData, getGroupData } = props
    const [identifier] = useState(uid())
    const {showModal} = useModal()

    const colHandlerProps = useColumnsHandler(tableInfo)
    const enhanceTableProps = useEnhanceTable({
        groups
    })

    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
        enhanceTableProps.selectedGroups.forEach(group => {
            getGroupData(enhanceTableProps.fetchState, group)
        })
    }, [enhanceTableProps.fetchState, enhanceTableProps.selectedGroups, getGroupData, getTableData])

    const { toggleDisabled } = useBackgroundService(EDataSyncItems.UMBRELLA_USERS, loadData, identifier)

    const onUmbrellaUsersDelete = useCallback(() => {
        props.deleteUmbrellaUsers({
            ids: enhanceTableProps.getSelectedIds() as string[],
            filters: enhanceTableProps.getSelectAllFilters()
        })
        enhanceTableProps.deselectAllRows()
    }, [enhanceTableProps, props])

    const authorize = useAuthorization()

    const isAuthorized = authorize(EProfileRights.IS_UMBRELLA_ADMIN)

    const disableToolbarButtons = !enhanceTableProps.selectionCount
    const rowCount = TableHelper.countDisplayedRows(data, !!enhanceTableProps.fetchState.global.groupBy)
    const totalSelectedRows = enhanceTableProps.selectAll ? totalCount : enhanceTableProps.selectionCount

    const handleCreateUmbrellaUser = () => {
        showModal({
            content: <CreateUmbrellaUser />,
            title: i18nKeys.create_umbrella_user_title,
            description: i18nKeys.create_umbrella_user_subtitle
        })
    }

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        {
                            isAuthorized && (
                                <>
                                    <SelectionBox
                                        id='selectedUmbrellaUsersCheckbox'
                                        rowCount={enhanceTableProps.selectAll ? totalSelectedRows : rowCount}
                                        onAllRowsSelect={checked => enhanceTableProps.onAllRowsSelect(props.data, checked)}
                                        messageKey={i18nKeys.umbrella_users_list_toolbar_selected_users}
                                        totalSelectedRows={totalSelectedRows}
                                        numSelectedRows={enhanceTableProps.selectAll ? totalSelectedRows : enhanceTableProps.selectionCount}
                                        disabled={enhanceTableProps.selectAll}
                                    />
                                    <DeleteButton
                                        id='deleteUmbrellaUsersBtn'
                                        title={<TransMessage {...i18nKeys.umbrella_users_list_toolbar_delete_users_tooltip} />}
                                        disabled={disableToolbarButtons}
                                        onClick={onUmbrellaUsersDelete}
                                    />
                                </>
                            )
                        
                        }
                    </>
                }
                RightContent={
                    <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} />
                }
            />
                           
            {
                isAuthorized &&
                <QuickActionMenu>
                    <QuickActionMenuItem id='quickActionAddUser' icon={Icons.GEAR} textKey={i18nKeys.umbrella_users_add_user_button} onClick={handleCreateUmbrellaUser} />
                </QuickActionMenu>
            }

            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                disableSelectionForIds={props.builtInusers}
                rows={data}
                groups={groups}
            />
        </ViewWrapper>
    )
}

export default UmbrellaUsers
