import React, { useCallback, useEffect } from 'react'
import FormHelper from 'helpers/FormHelper'
import { getSettingsAsync, postSettingsAsync, restoreDefaultSettingsAsync } from 'state/slices/settingsSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import Settings, { TSettingsForm } from 'views/Admin/Settings/Settings'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { i18nKeys } from 'i18n/keys'
import { TSettings } from 'types/businessLogic/misc'

const convertSettingToSettingsForm = (settings: TSettings) : TSettingsForm => {
    return {
        ...settings,
        domainNamesOptions: settings.domainNames.map(domain => {
            return {
                value: domain,
                label: domain
            }
        }),
        cpuThresholds : [settings.cpuThresholdWarningInPercent, settings.cpuThresholdCriticalInPercent],
        memoryThresholds : [settings.memoryThresholdWarningInPercent, settings.memoryThresholdCriticalInPercent],
        diskThresholds : [settings.diskThresholdWarningInPercent, settings.diskThresholdCriticalInPercent],
        networkThresholds : [settings.networkThresholdWarningInPercent, settings.networkThresholdCriticalInPercent]
    }
}

const convertSettingsFormToSettings = (formValues: TSettingsForm) : TSettings => {
    return {
        ...formValues,
        cpuThresholdWarningInPercent: formValues.cpuThresholds[0],
        cpuThresholdCriticalInPercent: formValues.cpuThresholds[1],
        memoryThresholdWarningInPercent: formValues.memoryThresholds[0],
        memoryThresholdCriticalInPercent: formValues.memoryThresholds[1],
        diskThresholdWarningInPercent: formValues.diskThresholds[0],
        diskThresholdCriticalInPercent: formValues.diskThresholds[1],
        networkThresholdWarningInPercent: formValues.networkThresholds[0],
        networkThresholdCriticalInPercent: formValues.networkThresholds[1]
    }
}

const SettingsContainer = () => {
    const dispatch = useAppDispatch()
    const settings = useAppSelector(store => store.settings)

    useEffect(() => {
        dispatch(getSettingsAsync())
    }, [dispatch])

    useBreadCrumbs([i18nKeys.breadcrumb_administration_settings])
    
    const handleSettingsSubmit = useCallback((formValues: TSettingsForm) : void => {
        formValues.domainNames = FormHelper.extractValuesFromOptionTypeBase(formValues.domainNamesOptions)
        FormHelper.removeEmptyFields(formValues)
        const newSettings = convertSettingsFormToSettings(formValues)
        dispatch(postSettingsAsync({...newSettings}))
    }, [dispatch])

    const handleRestoreDefaultSettingsSubmit = useCallback(() : void => {
        dispatch(restoreDefaultSettingsAsync())
    }, [dispatch])

    if (!settings.fetched) {
        return null
    }

    const settingsFrom = convertSettingToSettingsForm(settings as TSettings)

    const collection = {
        settings: settingsFrom,
        settingsRestored: settings.restored,
        handleSettingsSubmit,
        handleRestoreDefaultSettingsSubmit
    }

    return <Settings {...collection} />
}

export default SettingsContainer