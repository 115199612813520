import { ClickAwayListener, Paper, Popper } from '@mui/material'
import { CustomCheckbox } from 'components/CustomControls/CustomCheckbox'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import React from 'react'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import shadows from 'style/shadows'
import typographyScale from 'style/typographyScale'
import zIndex from 'style/zIndex'
import styled from 'styled-components'
import { TDropdownCheckboxOption } from 'types/ui/dropdown'

const sizes = {
    DROPDOWN_SMALL: 208,
    DROPDOWN_MEDIUM: 258
}

const CheckboxWrapper = styled.div<{small: boolean}>`
    max-width: ${p => p.small ? sizes.DROPDOWN_SMALL : sizes.DROPDOWN_MEDIUM - 40}px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const Label = styled.label`
    color: ${colors.primary.DARK_PRIMARY};
    font-family: ${fonts.PRIMARY_REGULAR};
    font-size: ${typographyScale.TYPE16};
    line-height: ${lineHeights.TYPE14LineHeight};
    letter-spacing: ${letterSpacings.TYPE16LS};
    cursor: pointer;
`
const StyledPopper = styled(Popper)`
    z-index: ${zIndex.HIGHEST};
`
const PaperWrapper = styled(Paper)`
    border-radius: ${borderRadius.SECONDARY};
    box-shadow: ${shadows.QUINARY};
    padding: 20px 8px 20px 8px;
`
const StyledPaper = styled(Paper)<{$small: boolean}>`
    width: ${p => p.$small ? sizes.DROPDOWN_SMALL : sizes.DROPDOWN_MEDIUM}px;
    max-height: 432px;
    overflow-y: auto;
    box-shadow: none;
`

type TProps = {
    id: string
    onChange: (key: string, checked: boolean) => void
    open: boolean
    onClose: (event: MouseEvent | TouchEvent) => void
    anchorEl: HTMLButtonElement | null
    small?: boolean
    options: TDropdownCheckboxOption[]
}

const DropdownCheckbox = (props: TProps) => {
    const { open, onClose, anchorEl, options, small } = props

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        props.onChange(event.target.id, checked)
    }

    return (
        <StyledPopper
            open={open}
            anchorEl={anchorEl}
            placement='bottom-start'>
            <ClickAwayListener onClickAway={onClose}>
                <PaperWrapper>
                    <StyledPaper $small={!!small}>
                        {
                            options.map(option => (
                                <CheckboxWrapper key={option.key} small={!!small}>
                                    <CustomCheckbox
                                        name={option.key}
                                        id={option.key}
                                        checked={option.checked}
                                        disabled={option.disabled}
                                        onChange={handleChange}
                                    />
                                    <CustomTooltip title={option.name}>
                                        <Label htmlFor={option.key}>
                                            {option.name}
                                        </Label>
                                    </CustomTooltip>
                                </CheckboxWrapper>
                            ))
                        }
                    </StyledPaper>
                </PaperWrapper>
            </ClickAwayListener>
        </StyledPopper>
    )
}

export default DropdownCheckbox