import { MenuItem } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DropdownAlternate from 'components/Dropdown/DropdownAlternate'
import Icon, { Icons } from 'components/Icon/Icon'
import { TUseColumnsHandler } from 'hooks/useColumnsHandler'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useRef, useState } from 'react'
import colors from 'style/colors'
import styled from 'styled-components'

type TProps = TUseColumnsHandler & {
    tableId: string
}

const StyledMenuItem = styled(MenuItem)<{$fade: boolean}>`
    ${p => p.$fade ? 'opacity: .4;' : ''};
`
const StyledMenuItemRestore = styled(MenuItem)<{$fade: boolean}>`
    border-bottom-width: 2px;
    border-bottom-color: ${colors.primary.DARK_PRIMARY};

    ${p => p.$fade ? 'opacity: .4;' : ''};
`

const StyledIconButton = styled(IconButton)<{$menuOpened: boolean}>`
    margin-left: 10px;
    margin-right: 10px;
    fill: ${p => p.$menuOpened ? colors.primary.DEFAULT_PRIMARY : colors.primary.DARK_PRIMARY};
`

const ColumnPreset = (props: TProps) => {
    const [menuOpened, setMenuOpened] = useState<boolean>(false)
    const ref = useRef<HTMLButtonElement>(null)

    const toggleMenuOpened = () => {
        setMenuOpened(!menuOpened)
    }

    const closeMenu = (event?: MouseEvent | TouchEvent) => {
        if (ref.current && event && ref.current.contains(event.currentTarget as Node)) return

        setMenuOpened(false)
    }

    const saveHandler = () => {
        props.saveGridLayout()
        closeMenu()
    }

    const restoreLayoutHandler = () => {
        props.restoreDefaultLayout()
        closeMenu()
    }

    return (
        <>
            <StyledIconButton
                id='columnPresetContainerIcon'
                $menuOpened={menuOpened}
                ref={ref}
                onClick={toggleMenuOpened}
            >
                <Icon d={Icons.CONFIGURATION} />
            </StyledIconButton>
            <DropdownAlternate
                id='columnPresetContainerDropdown'
                open={menuOpened}
                onClose={closeMenu}
                anchorEl={ref?.current}
            >
                <StyledMenuItem
                    id='saveColumnsLayoutButton'
                    onClick={saveHandler}
                    disabled={!props.gridLayoutChanged}
                    $fade={!props.gridLayoutChanged}
                >
                    <TransMessage {...i18nKeys.grid_layout_save} />
                </StyledMenuItem>
                <StyledMenuItemRestore
                    id='restoreColumnsLayoutButton'
                    onClick={restoreLayoutHandler}
                    disabled={props.gridLayoutDefault}
                    $fade={props.gridLayoutDefault}
                >
                    <TransMessage {...i18nKeys.grid_layout_restore} />
                </StyledMenuItemRestore>
            </DropdownAlternate>
        </>
    )
}

export default ColumnPreset