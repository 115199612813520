import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import Icon, { Icons } from 'components/Icon/Icon'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import CameraInfoCard from 'components/PagedCardList/CameraInfoCard'
import PagedCardGroup from 'components/PagedCardList/PagedCardGroup'
import PagedCardList from 'components/PagedCardList/PagedCardList'
import { SearchContainer } from 'components/PagedCardList/SearchContainer'
import { SearchGroup } from 'components/PagedCardList/SearchGroup'
import { SearchInput } from 'components/PagedCardList/SearchInput'
import SiteServiceInfoCard from 'components/PagedCardList/SiteServiceInfoCard'
import SiteSystemInfoCard from 'components/PagedCardList/SiteSystemInfoCard'
import SelectionBox from 'components/Tables/SelectionBox'
import Toolbar from 'components/Toolbar/Toolbar'
import TransMessage from 'components/TransMessage/TransMessage'
import { EDataSyncItems, EMessageType, EPerformanceState, EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import useBackgroundService from 'hooks/useBackgroundService'
import useCardPage from 'hooks/useCardPage'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useEffect, useState } from 'react'
import CameraPerformanceService from 'services/cameraPerformanceService'
import { siteCameraService } from 'services/siteCameraService'
import SiteServicePerformanceService from 'services/siteServicePerformanceService'
import { siteServiceService } from 'services/siteServiceService'
import SiteSystemPerformanceService from 'services/siteSystemPerformanceService'
import { showMessage } from 'state/slices/uiSlice'
import { dispatch } from 'state/store'
import styled from 'styled-components'
import { isTCameraPerformance } from 'types/businessLogic/cameraPerformance'
import { TGetCardPageRequest, TOverallState, TPerformanceItem, TPerformanceItemsPerSite } from 'types/businessLogic/performance'
import { isTSiteServicePerformance } from 'types/businessLogic/siteServicePerformance'
import { isTSiteSystemPerformance } from 'types/businessLogic/siteSystem'
import { uid } from 'uid'

const Root = styled.div`
    width: 100%;
    height: calc(100% - 32px);
    padding: 16px;
`

const IconContainer = styled.div`
    margin-left: 16px;
`

// workaround because we need the gatewayId to macht group/children
const fixGatewayId = (item: TPerformanceItem, newId: number) : TPerformanceItem => {
    item.gatewayId = newId

    return item
}

const getFilteredEntitieIds = (items: TPerformanceItem[]) : { cameraIds: number[], hostIds: number[], serviceIds: number[] } => {
    const result : { cameraIds: number[], hostIds: number[], serviceIds: number[] } = {
        cameraIds: [],
        hostIds: [],
        serviceIds: []
    }

    items.forEach(x => {
        if (isTCameraPerformance(x)) {
            result.cameraIds.push(x.entityId)
        }

        if (isTSiteServicePerformance(x)) {
            result.serviceIds.push(x.entityId)
        }

        if (isTSiteSystemPerformance(x)) {
            result.hostIds.push(x.entityId)
        }
    })

    return result
}

const getOverallState = (items : TPerformanceItem[]) : TOverallState => {
    const result : TOverallState = {
        critical: 0,
        healthy: 0,
        ignored: 0,
        offline: 0,
        warning: 0
    }

    items.forEach(item => {
        switch (item.overallState) {
            case EPerformanceState.HEALTHY:
                result.healthy++
                break
            case EPerformanceState.WARNING:
                result.warning++
                break
            case EPerformanceState.CRITICAL:
                result.critical++
                break
            case EPerformanceState.OFFLINE:
                result.offline++
                break
            case EPerformanceState.IGNORED:
                result.ignored++
                break
        }
    })

    return result
}

type TProps = {
    cameraIssues: TPerformanceItem[]
    siteSystemIssues: TPerformanceItem[]
    siteServiceIssues: TPerformanceItem[]
    handleFetchAction: (params: TGetCardPageRequest) => void
    overallState: TOverallState
}

const syncItems = [EDataSyncItems.CAMERA_PERFORMANCE, EDataSyncItems.SITE_SERVICE_PERFORMANCE, EDataSyncItems.SITE_SYSTEMS_PERFORMANCE]

const IssuesTab = (props: TProps) => {
    const { overallState, siteServiceIssues, siteSystemIssues, cameraIssues, handleFetchAction } = props
    const [items, setItems] = useState<TPerformanceItemsPerSite[]>([])
    const cardPageProps = useCardPage('issues-tab', [EPerformanceState.WARNING, EPerformanceState.CRITICAL])
    const checkAuth = useAuthorization()
    const hasRestartRight = checkAuth(EProfileRights.HAS_RESTART_SITE_ENTITIES_RIGHT)
    const numSelectedRows = cardPageProps.selectionCount
    const rowCount = items.flatMap(x => x.items).length
    const totalSelectedRows = cardPageProps.selectionCount
    const disableToolbarButtons = cardPageProps.selectionCount === 0
    const [identifier] = useState(uid())
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)

    useEffect(() => {
        const temp = []
        if (cameraIssues.length > 0) {
            temp.push({
                gatewayId: 1,
                gatewayName: i18nKeys.site_details_issues_cameras,
                items: cameraIssues.map(x => fixGatewayId(x, 1))
            })
        }

        if (siteSystemIssues.length > 0) {
            temp.push({
                gatewayId: 2,
                gatewayName: i18nKeys.site_details_issues_systems,
                items: siteSystemIssues.map(x => fixGatewayId(x, 2))
            })
        }

        if (siteServiceIssues.length > 0) {
            temp.push({
                gatewayId: 3,
                gatewayName: i18nKeys.site_details_issues_services,
                items: siteServiceIssues.map(x => fixGatewayId(x, 3))
            })
        }
        setItems(temp)
    }, [siteServiceIssues, siteSystemIssues, cameraIssues])

    const loadData = useCallback(() => {
        handleFetchAction(cardPageProps.fetchState)
    }, [cardPageProps.fetchState, handleFetchAction])

    const { toggleDisabled, setDisabled } = useBackgroundService(syncItems, loadData, identifier)

    useEffect(() => {
        const hasSelectedRows = cardPageProps.selectionCount > 0
        setDisabled(identifier, hasSelectedRows)
        setAutoupdatePaused(hasSelectedRows)
    }, [cardPageProps.selectionCount, identifier, setDisabled])

    const handleRestartAction = async () => {
        const result = getFilteredEntitieIds(cardPageProps.selectedItems)
        if (result.cameraIds.length) {
            try {
                await siteCameraService.restartCameras({ ids: result.cameraIds })
            } catch {
                dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
            }
        }

        if (result.serviceIds.length) {
            try {
                await siteServiceService.restartServices(result.serviceIds)
            } catch {
                dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
            }
        }
        cardPageProps.deselectAll()
    }

    const handleStartAction = () => {
        try {
            const result = getFilteredEntitieIds(cardPageProps.selectedItems)
            siteServiceService.startServices(result.serviceIds)
            cardPageProps.deselectAll()
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
 
    }

    const handleStopAction = () => {
        try {
            const result = getFilteredEntitieIds(cardPageProps.selectedItems)
            siteServiceService.stopServices(result.serviceIds)
            cardPageProps.deselectAll()
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }

    }

    const handleIgnoreAction = () => {
        try {
            const result = getFilteredEntitieIds(cardPageProps.selectedItems)
            SiteServicePerformanceService.ignoreSiteServices(result.serviceIds)
            CameraPerformanceService.ignoreCameras(result.cameraIds)
            SiteSystemPerformanceService.setIgnoreSystems(result.hostIds)
            cardPageProps.deselectAll()
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }
    }

    const checkButton = useCallback(() : { restart: boolean, start: boolean, stop: boolean } => {

        if (disableToolbarButtons || !hasRestartRight) {
            return {
                restart: true,
                start: true,
                stop: true
            }
        }
        
        let siteSystemFound = false
        let cameraFound = false
        cardPageProps.selectedItems.forEach(item => {
            if (isTSiteSystemPerformance(item)) {
                siteSystemFound = true
            }

            if (isTCameraPerformance(item)) {
                cameraFound = true
            }
        })

        return {
            restart: siteSystemFound,
            start: siteSystemFound || cameraFound,
            stop: siteSystemFound || cameraFound
        }
    }, [cardPageProps.selectedItems, disableToolbarButtons, hasRestartRight])

    const cardGroupItemRenderer = (item: TPerformanceItem, key: number, isChecked: boolean, isFiltered: boolean) : JSX.Element => {
        if (isTCameraPerformance(item)) {
            return (
                <CameraInfoCard
                    {...cardPageProps}
                    key={key}
                    camera={item}
                    isChecked={isChecked}
                    isFiltered={isFiltered}
                />
            )
        }

        if (isTSiteServicePerformance(item)) {
            return (
                <SiteServiceInfoCard
                    {...cardPageProps}
                    key={key}
                    siteService={item}
                    isChecked={isChecked}
                    isFiltered={isFiltered}
                />
            )
        }

        if (isTSiteSystemPerformance(item)) {
            return (
                <SiteSystemInfoCard
                    {...cardPageProps}
                    key={key}
                    siteSystem={item}
                    isChecked={isChecked}
                    isFiltered={isFiltered}
                />
            )
        }

        return <> Unknown TPerformanceItem </>
    }

    const cardListItemRender = (item: TPerformanceItemsPerSite, index: number, isParentChecked: boolean) : JSX.Element => {
        return (
            <PagedCardGroup
                {...cardPageProps}
                itemRenderer={cardGroupItemRenderer}
                performanceItemsPerSite={item}
                overallState={getOverallState(item.items)}
                key={index}
                isParentChecked={isParentChecked}
                startOpen={true}
            />
        )
    }

    const searchFilters = () : JSX.Element => {
        return (
            <SearchGroup>
                <IconContainer>
                    <Icon d={Icons.SEARCH} />
                </IconContainer>
                <SearchContainer>
                    <div>
                        <TransMessage {...i18nKeys.site_systems_search_by_name} />
                    </div>
                    <div>
                        <SearchInput small onChange={event => cardPageProps.onFilterChange('Name', event.currentTarget.value)} />
                    </div>
                </SearchContainer>
            </SearchGroup>
        )
    }

    const buttonDisableState = checkButton()

    return (
        <Root>
            <Toolbar
                LeftContent={
                    <>
                        <SelectionBox
                            id='selectedCamerasCheckbox'
                            rowCount={rowCount}
                            onAllRowsSelect={checked => cardPageProps.onAllSelect(items, checked)}
                            messageKey={i18nKeys.site_list_toolbar_selected_sites}
                            totalSelectedRows={totalSelectedRows}
                            numSelectedRows={cardPageProps.selectAll ? totalSelectedRows : cardPageProps.selectionCount}
                            disabled={false}
                        />
                        {
                            hasRestartRight && (
                                <>
                                    <CustomTooltip title={<TransMessage {...i18nKeys.site_details_issues_start_tooltip} />}>
                                        <div>
                                            <CustomIconButton
                                                id='startBtn'
                                                data-testid='startBtn'
                                                disabled={buttonDisableState.start}
                                                onClick={handleStartAction}
                                                icon={Icons.PLAY}
                                            />
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip title={<TransMessage {...i18nKeys.site_details_issues_stop_tooltip} />}>
                                        <div>
                                            <CustomIconButton
                                                id='stopBtn'
                                                data-testid='stopBtn'
                                                disabled={buttonDisableState.stop}
                                                onClick={handleStopAction}
                                                icon={Icons.STOP}
                                            />
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip title={<TransMessage {...i18nKeys.site_details_issues_restart_tooltip} />}>
                                        <div>
                                            <CustomIconButton
                                                id='restartBtn'
                                                data-testid='restartBtn'
                                                disabled={buttonDisableState.restart}
                                                onClick={handleRestartAction}
                                                icon={Icons.RETRY}
                                            />
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip title={<TransMessage {...i18nKeys.site_details_issues_ignore_tooltip} />}>
                                        <div>
                                            <CustomIconButton
                                                id='ignoreBtn'
                                                data-testid='ignoreBtn'
                                                disabled={buttonDisableState.restart}
                                                onClick={handleIgnoreAction}
                                                icon={Icons.EYE_HIDE}
                                            />
                                        </div>
                                    </CustomTooltip>
                                </>
                            )
                            
                        }
                    </>
                }
                RightContent={
                    <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                }
            />

            <PagedCardList
                {...cardPageProps}
                isParentChecked={numSelectedRows > 0 && numSelectedRows === rowCount}
                itemRender={cardListItemRender}
                searchFilters={searchFilters}
                items={items}
                totalCount={totalSelectedRows}
                overallState={overallState}
                hideFooter={true}
            />

        </Root>
    )
    
}
export default IssuesTab