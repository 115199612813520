import { EThresholdType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'

export const ThresholdTypeInfos = {
    [EThresholdType.UNKNOWN] : {
        translationKey: i18nKeys.threshold_type_unknown
    },
    [EThresholdType.ENTITY] : {
        translationKey: i18nKeys.threshold_type_entity
    },
    [EThresholdType.SITE] : {
        translationKey: i18nKeys.threshold_type_site
    },
    [EThresholdType.SETTINGS] : {
        translationKey: i18nKeys.threshold_type_settings
    }
}