import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormFooter, FormWrapper } from 'components/FormControls/FormControls'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { PropsWithChildren, useEffect } from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { getCameraFirmwaresAsync } from 'state/slices/cameraFirmwareSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import styled from 'styled-components'
import { tableInfo } from 'views/Control/SiteCamera/Modals/UpdateFirmware/CameraFirmwaresTableColumns'
import CameraFirmwaresTablePreparer from 'views/Control/SiteCamera/Modals/UpdateFirmware/CameraFirmwaresTablePreparer'

const ViewWrapper = styled.div`
    padding-top: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
`

type TProps = {
    handleNext: (firmwareName: string) => void
}

const SelectFirmware = (props: PropsWithChildren<TProps>) => {
    const {handleNext} = props

    const dispatch = useAppDispatch()
    const { cameraFirmwares, totalCount } = useAppSelector(state => ({
        cameraFirmwares: state.cameraFirmware.cameraFirmwares,
        totalCount: state.cameraFirmware.totalNumber
    }))
    
    const colHandlerProps = useColumnsHandler(tableInfo)

    const enhanceTableProps = useEnhanceTable({
        allowOnlyOneSelected: true
    })
    
    useEffect(() => {
        const requestParams = GeneralHelper.buildRequestFromTableState(enhanceTableProps.fetchState)
        dispatch(getCameraFirmwaresAsync(requestParams))
    }, [dispatch, enhanceTableProps.fetchState])

    const handleNextButton = () => {
        const firmware = cameraFirmwares.find(x => x.id === enhanceTableProps.getSelectedIds()[0] as number)
        handleNext(firmware!.name)
    }

    const data = CameraFirmwaresTablePreparer.prepareCameraFirmwaresRows(cameraFirmwares)

    const disableButton = !enhanceTableProps.selectionCount && !enhanceTableProps.selectAll

    return (
        <FormWrapper>
            {props.children}
            <FormContent>
                <ViewWrapper>
                    <AdvancedTable
                        {...colHandlerProps}
                        {...enhanceTableProps}
                        count={totalCount}
                        disabledCheckboxes={false}
                        disableSelection={false}
                        disableBackground={false}
                        disableSelectionForIds={[]}
                        rows={data}
                        
                    />
                </ViewWrapper>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        id='submitBtn'
                        type='button'
                        disabled={disableButton}
                        onClick={handleNextButton}
                    >
                        <TransMessage {...i18nKeys.apply_firmware_modal_next_button} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}

export default SelectFirmware