import { ENotificationPriority } from 'enums/enums'
import { useModal } from 'hooks/modalProvider'
import React from 'react'
import SetNotificationPriorityForm, { NotificationPriorityOptions, TSetNotificationPriorityFormValues } from 'views/Monitor/Modals/SetNotificationPriority/SetNotificationPriorityForm'

export type TSetNotificationPriorityValues = {
    notificationPriority: ENotificationPriority
    useDefault: boolean
}

type SetNotificationPriorityProps = {
    initialValue: TSetNotificationPriorityValues
    hideUseDefaultValue: boolean
    onSubmit: (formValues: TSetNotificationPriorityValues) => void
}

const SetNotificationPriority = ({initialValue, hideUseDefaultValue, onSubmit}: SetNotificationPriorityProps) => {
    const {closeModal} = useModal()
    
    const handleOnSubmit = (formValues: TSetNotificationPriorityFormValues) => {
        
        const result : TSetNotificationPriorityValues = {
            notificationPriority: formValues.notificationPriority.value as ENotificationPriority,
            useDefault: formValues.useDefault
        }
        
        onSubmit(result)
        closeModal()
    }

    const initialFormValues : TSetNotificationPriorityFormValues = {
        notificationPriority: NotificationPriorityOptions.find(x => x.value === initialValue.notificationPriority) ?? NotificationPriorityOptions[0],
        useDefault: initialValue.useDefault
    }
    
    return (
        <>
            <SetNotificationPriorityForm
                onSubmit={handleOnSubmit}
                initialValue={initialFormValues}
                hideUseDefaultValue={hideUseDefaultValue}
            />
        </>
    )
}
export default SetNotificationPriority