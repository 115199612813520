import React, { useCallback, useEffect, useState } from 'react'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import { TCustomComment } from 'types/businessLogic/site'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormGroup, FormLabel, InputWrapper } from 'components/FormControls/FormControls'
import { Control } from 'react-hook-form'
import { TFormInputs } from 'views/Sites/SiteDetails/Tabs/CommentsTab'
import styled from 'styled-components'
import { OutlinedInput } from '@mui/material'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
	
const InputField = styled(OutlinedInput)`
    &:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${colors.primary.DEFAULT_PRIMARY};
    }

    height: 56px;
    width: 380px;
    min-width: 380px;
    padding: 0px 20px;
    border-radius: ${borderRadius.SECONDARY};
    background-color: white;

    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }

    .MuiOutlinedInput-input {
        color: #303030;
        height: 24px;
        padding: 0;
        font-size: 16px;
        font-family: LatoRegular;
        line-height: 24px;
        letter-spacing: 0.4px;
    }
`

type TProps = {
    addComment: (comment: TCustomComment) => void
    modifyComment: (comment: TCustomComment) => void
    clearComment: () => void
    editComment?: TCustomComment
    disableUpdate: boolean
    control: Control<TFormInputs>
}

const CommentInput = (props: TProps) => {
    const [comment, setComment] = useState({id: -performance.now(), title: '', description: ''})

    const {editComment, disableUpdate} = props
    const {addComment, modifyComment, clearComment} = props

    useEffect(() => {
        if (editComment) {
            setComment(editComment)
        } else {
            setComment({
                id: -performance.now(),
                title: '',
                description: ''
            })
        }
    }, [editComment])

    const isEditCommentEqualToComment = useCallback(() => editComment && editComment.id === comment.id, [comment.id, editComment])

    const onClick = useCallback(() => {
        isEditCommentEqualToComment() ? modifyComment(comment) : addComment(comment)
        setComment({
            id: -performance.now(),
            title: '',
            description: ''
        })
    }, [addComment, comment, isEditCommentEqualToComment, modifyComment])

    const onCancelClick = useCallback(() => {
        setComment({
            id: -performance.now(),
            title: '',
            description: ''
        })
        clearComment()
    }, [clearComment])

    const onTitleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setComment({
            title: event.currentTarget.value,
            description: comment.description,
            id: comment.id
        })
    }, [comment])

    const onDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setComment({
            title: comment.title,
            description: event.currentTarget.value,
            id: comment.id
        })
    }, [comment])

    const isEditCommentContentSame = () =>
        editComment &&
        editComment.title === comment.title &&
        editComment.description === comment.description

    const validateComment = () =>
        comment.title !== '' && comment.description !== '' &&
        (isEditCommentEqualToComment() ? !isEditCommentContentSame() : true)

    const renderButtonTransMessage = () => {
        if (disableUpdate) {
            return <TransMessage {...i18nKeys.site_details_comment_max_comments_reached} />
        } else {
            if (isEditCommentEqualToComment()) {
                return <TransMessage {...i18nKeys.site_details_comment_update_comment} />
            } else {
                return <TransMessage {...i18nKeys.site_details_comment_add_comment} />
            }
        }
    }

    return (
        <>
            <FormGroup title={isEditCommentEqualToComment() ? i18nKeys.site_details_comment_update_comment : i18nKeys.site_details_comment_add_comment}>
                <InputWrapper>
                    <FormLabel htmlFor='site-comment-title'>
                        <TransMessage {...i18nKeys.site_details_comment_title} />
                    </FormLabel>
                    <InputField
                        name='site-comment-title'
                        onChange={onTitleChange}
                        value={comment.title}
                    />
                </InputWrapper>
                <InputWrapper>
                    <FormLabel htmlFor='site-comment-description'>
                        <TransMessage {...i18nKeys.site_details_comment_description} />
                    </FormLabel>
                    <InputField
                        name='site-comment-description'
                        onChange={onDescriptionChange}
                        value={comment.description}
                    />
                </InputWrapper>
            </FormGroup>
            <FormButtonGroup>
                <CustomButton
                    disabled={disableUpdate || !validateComment()}
                    id='editCommentButton'
                    type='button'
                    onClick={onClick}
                >
                    { renderButtonTransMessage() }
                </CustomButton>
                <CustomButton
                    id='cancelEditCommentButton'
                    type='reset'
                    onClick={onCancelClick}
                    $secondary
                >
                    <TransMessage {...i18nKeys.site_details_comment_clear_comment} />
                </CustomButton>
            </FormButtonGroup>
        </>
    )
}

export default CommentInput