import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import React, { PropsWithChildren } from 'react'

type Props = {
    text: string
    maxTextLength?: number
}

const CellTooltip = (props: PropsWithChildren<Props>) => {
    const {text, children, maxTextLength} = props

    if (text.length <= (maxTextLength ?? 0)) return null

    return (
        <CustomTooltip
            title={text}
            placement='top-start'
        >
            <div>
                {children}
            </div>
        </CustomTooltip>
    )
}

export default CellTooltip