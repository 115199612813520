import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Toolbar from 'components/Toolbar/Toolbar'
import PagedCardGroup from 'components/PagedCardList/PagedCardGroup'
import SiteSystemInfoCard from 'components/PagedCardList/SiteSystemInfoCard'
import SelectionBox from 'components/Tables/SelectionBox'
import { i18nKeys } from 'i18n/keys'
import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import Icon, { Icons } from 'components/Icon/Icon'
import { TSiteSystemPerformance, TSiteSystemsPerSite } from 'types/businessLogic/siteSystem'
import useCardPage from 'hooks/useCardPage'
import PagedCardList from 'components/PagedCardList/PagedCardList'
import { SearchInput } from 'components/PagedCardList/SearchInput'
import { SearchGroup } from 'components/PagedCardList/SearchGroup'
import { SearchContainer } from 'components/PagedCardList/SearchContainer'
import { EDataSyncItems, EMessageType, ENotificationPriority, ENotificationType, EPerformanceState, EProfileRights, ESetThresholdType } from 'enums/enums'
import { TGetCardPageRequest, TOverallState, TPerformanceItem, TPerformanceItemsPerSite } from 'types/businessLogic/performance'
import TransMessage from 'components/TransMessage/TransMessage'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import useAuthorization from 'hooks/useAuthorization'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import EditRoadOutlined from '@mui/icons-material/EditRoadOutlined'
import EditNotificationsIcon from '@mui/icons-material/EditNotifications'
import { useModal } from 'hooks/modalProvider'
import SetNotificationPriority, { TSetNotificationPriorityValues } from 'views/Monitor/Modals/SetNotificationPriority/SetNotificationPriority'
import SiteSystemPerformanceService from 'services/siteSystemPerformanceService'
import SetMonitoringThresholds from 'views/Monitor/Modals/SetMonitoringThresholds/SetMonitoringThresholds'
import SiteSystemDetails from 'views/Monitor/SiteSystems/SiteSystemDetails/SiteSystemDetails'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import { TSetMonitoringThresholdsFormValues } from 'views/Monitor/Modals/SetMonitoringThresholds/SetMonitoringThresholdsForm'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
    height: calc(100vh - 154px);
`
const IconContainer = styled.div`
    margin-left: 16px;
`

type TProps = {
    allSiteSystemsPerSite: TSiteSystemsPerSite[]
    totalNumberSites: number
    overallState: TOverallState
    handleIgnoredAction: (siteSystems: TPerformanceItem[]) => void
    handleFetchAction: (params: TGetCardPageRequest) => void
    preSelectedState?: EPerformanceState
}

const SiteSystems = (props: TProps) => {
    const dispatch = useAppDispatch()
    
    const { allSiteSystemsPerSite, totalNumberSites, overallState, preSelectedState } = props
    const { handleIgnoredAction, handleFetchAction } = props
    const cardPageProps = useCardPage('site-systems-grid', preSelectedState !== undefined ? [preSelectedState] : [])
    const [identifier] = useState(uid())
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)
    const {showModal} = useModal()
    
    const loadData = useCallback(() => {
        handleFetchAction(cardPageProps.fetchState)
    }, [cardPageProps.fetchState, handleFetchAction])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.SITE_SYSTEMS_PERFORMANCE, loadData, identifier)

    useEffect(() => {
        const hasSelectedRows = cardPageProps.selectionCount > 0
        setDisabled(identifier, hasSelectedRows)
        setAutoupdatePaused(hasSelectedRows)
    }, [cardPageProps.selectionCount, identifier, setDisabled])

    const onIgnoreClick = () => {
        handleIgnoredAction(cardPageProps.selectedItems)
        cardPageProps.deselectAll()
    }

    const handleSetThresholdsAction = () => {
        const ids = cardPageProps.selectedItems.map(x => (x.id))
        
        const handleOnSubmit = async (formValues: TSetMonitoringThresholdsFormValues) => {
            try {
                if (ids.length !== 0) {
                    await SiteSystemPerformanceService.setThresholdsSiteSystems({ids, ...formValues})
                }
                dispatch(showMessage({message: i18nKeys.site_service_performance_start_success, type: EMessageType.SUCCESS}))
            } catch {
                dispatch(showMessage({message: i18nKeys.site_service_performance_start_error, type: EMessageType.ERROR}))
            }
        }
        
        showModal({
            content: <SetMonitoringThresholds
                ids={ids}
                type={ESetThresholdType.SYSTEMS}
                onSubmit={handleOnSubmit}
            />,
            title: i18nKeys.configure_threshold_systems_title,
            description: i18nKeys.configure_threshold_systems_description,
            onClose: () => cardPageProps.deselectAll()
        })
    }

    const getSetNotificationPriorityInitialValue = () : TSetNotificationPriorityValues => {
        let result = ENotificationPriority.HIGH
        
        if (cardPageProps.selectedItems.every(x => x.notificationPriority === cardPageProps.selectedItems[0].notificationPriority)) {
            result = cardPageProps.selectedItems[0].notificationPriority
        }

        let defaultResult = true

        if (cardPageProps.selectedItems.every(x => x.notificationType === cardPageProps.selectedItems[0].notificationType)) {
            defaultResult = cardPageProps.selectedItems[0].notificationType !== ENotificationType.ENTITY
        }

        return {
            notificationPriority: result,
            useDefault: defaultResult
        }
    }

    const handleSetNotificationPriority = () => {
        const ids = cardPageProps.selectedItems.map(x => (x.id))
        showModal({
            content: <SetNotificationPriority
                initialValue={getSetNotificationPriorityInitialValue()}
                onSubmit={async (values: TSetNotificationPriorityValues) => {
                    try {
                        await SiteSystemPerformanceService.setNotificationPriority(ids, values.notificationPriority, values.useDefault)
                        dispatch(showMessage({message: i18nKeys.site_systems_ignore_success, type: EMessageType.SUCCESS}))
                    } catch (err) {
                        dispatch(showMessage({message: i18nKeys.site_systems_ignore_error, type: EMessageType.ERROR}))
                    }
                }}
                hideUseDefaultValue={false}
            />,
            title: i18nKeys.notification_priority_title,
            description: i18nKeys.notification_priority_description,
            onClose: () => cardPageProps.deselectAll()
        })
    }

    const handleOpenDetails = (id: number) => {
        const performanceMeasure = allSiteSystemsPerSite.flatMap(x => x.items).find(x => x.entityId === id)
        if (!performanceMeasure) return

        showModal({
            content: <SiteSystemDetails
                id={id}
                siteSystem={performanceMeasure as TSiteSystemPerformance}
            />,
            title: performanceMeasure.host,
            description: i18nKeys.site_systems_details_description,
            width: '80%'
        })
    }

    const pagedCardGroupItemRenderer = (siteSystem: TSiteSystemPerformance, key: number, isChecked: boolean, isFiltered: boolean) : JSX.Element => {
        return (
            <SiteSystemInfoCard
                {...cardPageProps}
                key={key}
                siteSystem={siteSystem}
                isChecked={isChecked}
                isFiltered={isFiltered}
                openDetails={handleOpenDetails}
            />
        )
    }
    
    const pagedCardListItemRenderer = (siteSystemsPerSite: TPerformanceItemsPerSite, key: number, isParentChecked: boolean) : JSX.Element => {
        const siteSystemState: TOverallState = {
            healthy: siteSystemsPerSite.items.filter(siteSystem => siteSystem.overallState === EPerformanceState.HEALTHY).length,
            critical: siteSystemsPerSite.items.filter(siteSystem => siteSystem.overallState === EPerformanceState.CRITICAL).length,
            ignored: siteSystemsPerSite.items.filter(siteSystem => siteSystem.overallState === EPerformanceState.IGNORED).length,
            offline: siteSystemsPerSite.items.filter(siteSystem => siteSystem.overallState === EPerformanceState.OFFLINE).length,
            warning: siteSystemsPerSite.items.filter(siteSystem => siteSystem.overallState === EPerformanceState.WARNING).length
        }
        
        return (
            <PagedCardGroup
                {...cardPageProps}
                itemRenderer={pagedCardGroupItemRenderer}
                performanceItemsPerSite={siteSystemsPerSite}
                overallState={siteSystemState}
                key={key}
                isParentChecked={isParentChecked}
            />
        )
    }

    const searchFilters = () : JSX.Element => {
        return (
            <SearchGroup>
                <IconContainer>
                    <Icon d={Icons.SEARCH} />
                </IconContainer>
                <SearchContainer>
                    <div>
                        <TransMessage {...i18nKeys.site_systems_search_by_site} />
                    </div>
                    <div>
                        <SearchInput small onChange={event => cardPageProps.onFilterChange('Site', event.currentTarget.value)} />
                    </div>
                </SearchContainer>
                <SearchContainer>
                    <div>
                        <TransMessage {...i18nKeys.site_systems_search_by_system} />
                    </div>
                    <div>
                        <SearchInput small onChange={event => cardPageProps.onFilterChange('System', event.currentTarget.value)} />
                    </div>
                </SearchContainer>
            </SearchGroup>
        )
    }

    const totalSelectedRows = cardPageProps.selectionCount
    const rowCount = allSiteSystemsPerSite.flatMap(site => site.items).length
    const numSelectedRows = cardPageProps.selectionCount

    const checkAuth = useAuthorization()
    const hasIgnoreRight = checkAuth(EProfileRights.HAS_IGNORE_RIGHT)
    
    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        <SelectionBox
                            id='selectedSiteSystemsCheckbox'
                            rowCount={rowCount}
                            onAllRowsSelect={checked => cardPageProps.onAllSelect(allSiteSystemsPerSite, checked)}
                            messageKey={i18nKeys.site_list_toolbar_selected_sites}
                            totalSelectedRows={totalSelectedRows}
                            numSelectedRows={cardPageProps.selectAll ? totalSelectedRows : cardPageProps.selectionCount}
                            disabled={cardPageProps.selectAll}
                        />
                        {
                            hasIgnoreRight &&
                            <CustomTooltip title={<TransMessage {...i18nKeys.site_systems_tooltip_ignore} />}>
                                <div>
                                    <CustomIconButton
                                        id='ignoreSiteSystemButton'
                                        data-testid='ignoreSiteSystemButton'
                                        disabled={totalSelectedRows < 1}
                                        onClick={onIgnoreClick}
                                        icon={Icons.EYE_HIDE}
                                    />
                                </div>
                            </CustomTooltip>
                        }
                        <CustomTooltip title={<TransMessage {...i18nKeys.site_list_toolbar_threshold_configuration} />}>
                            <div>
                                <CustomIconButton
                                    id='setThresholdsSiteSystemsBtn'
                                    data-testid='setThresholdsSiteSystemsBtn'
                                    disabled={totalSelectedRows < 1}
                                    onClick={handleSetThresholdsAction}
                                    icon={<EditRoadOutlined />}
                                />
                            </div>
                        </CustomTooltip>
                        <CustomTooltip title={<TransMessage {...i18nKeys.site_list_toolbar_notification_priority_tooltip} />}>
                            <div>
                                <CustomIconButton
                                    id='setNotificationPriorityBtn'
                                    data-testid='setNotificationPriorityBtn'
                                    disabled={totalSelectedRows < 1}
                                    onClick={handleSetNotificationPriority}
                                    icon={<EditNotificationsIcon />}
                                />
                            </div>
                        </CustomTooltip>
                    </>
                }
                RightContent={
                    <>
                        <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                    </>
                }
            />

            <PagedCardList
                {...cardPageProps}
                itemRender={pagedCardListItemRenderer}
                searchFilters={searchFilters}
                items={allSiteSystemsPerSite}
                totalCount={totalNumberSites}
                overallState={overallState}
                isParentChecked={numSelectedRows > 0 && numSelectedRows === rowCount}
            />
        </ViewWrapper>
    )
}

export default SiteSystems
