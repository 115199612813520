import { useModal } from 'hooks/modalProvider'
import EnhancedTabs from 'components/Tabs/EnhancedTabs'
import TransMessage from 'components/TransMessage/TransMessage'
import { EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { TSiteEvent } from 'types/businessLogic/siteEvent'
import { TTabButton } from 'types/ui/tab'
import DetailsTab from 'views/Monitor/SiteEvents/SiteEventsDetails/DetailsTab'

type TProps = {
    siteEvent: TSiteEvent
}

const SiteEventsDetails = (props: TProps) => {
    const {siteEvent} = props
    const authorize = useAuthorization()
    const isSiteAdmin = authorize(EProfileRights.IS_SITE_ADMIN)
    const {closeModal} = useModal()

    const tabButtons: TTabButton[] = [
        {
            id: 'detailsTabButton',
            label: i18nKeys.site_events_details_details_tab
        }
    ]

    const handleDetailsTabSubmit = () => {
        closeModal()
    }

    return (
        <EnhancedTabs
            readOnlyForm={true}
            subtitleKey={i18nKeys.site_time_profile_general_description}
            numOfEntities={1}
            propertyValues={siteEvent.propertyValues}
            disableUpdateButtons={true}
            tabButtons={tabButtons}
            hideFooter
            tabContent={[
                {
                    title: <TransMessage {...i18nKeys.site_time_profile_general_description} />,
                    content: <DetailsTab onSubmit={handleDetailsTabSubmit} key='detailsTab' isSiteAdmin={isSiteAdmin} siteEvent={siteEvent} />
                }
            ]}
        />
    )
}
export default SiteEventsDetails