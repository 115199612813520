import React, { useCallback, useEffect, useState } from 'react'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import styled from 'styled-components'
import { TTableFetchState } from 'types/network'
import { TTableInfo, TTableRowData } from 'types/ui/table'
import Toolbar from 'components/Toolbar/Toolbar'
import useAuthorization from 'hooks/useAuthorization'
import { EDataSyncItems, EProfileRights } from 'enums/enums'
import SelectionBox from 'components/Tables/SelectionBox'
import { i18nKeys } from 'i18n/keys'
import useLicense from 'hooks/useLicense'
import { ELicenseTypes } from 'enums/ELicenseTypes'
import TransMessage from 'components/TransMessage/TransMessage'
import { Icons } from 'components/Icon/Icon'
import DeleteButton from 'components/Tables/DeleteButton'
import ColumnPreset from 'components/Tables/ColumnPreset'
import ColumnChooser from 'components/ColumnChooser/ColumnChooser'
import QuickActionMenu from 'components/QuickActionMenu/QuickActionMenu'
import QuickActionMenuItem from 'components/QuickActionMenu/QuickActionMenuItem'
import TableHelper from 'components/Tables/TableHelper'
import { TModalProps } from 'types/ui/modal'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import { useModal } from 'hooks/modalProvider'
import UploadNewCameraFirmware from 'views/Control/CameraFirmwares/Modals/UploadNewCameraFirmware'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`

type TProps = {
    data: TTableRowData[]
    totalCount: number
    tableInfo: TTableInfo
    getTableData: (params: TTableFetchState) => void
    deleteCameraFirmwares: (params: TModalProps) => void
}

const CameraFirmwares = (props: TProps) => {
    const { tableInfo, data, totalCount, getTableData } = props
    const [identifier] = useState(uid())
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)

    const {showModal} = useModal()
    const colHandlerProps = useColumnsHandler(tableInfo)
    const enhanceTableProps = useEnhanceTable({
        groups: []
    })

    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
    }, [enhanceTableProps.fetchState, getTableData])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.FIRMWARES, loadData, identifier)

    useEffect(() => {
        const hasSelectedRows = enhanceTableProps.selectionCount > 0
        setDisabled(identifier, hasSelectedRows)
        setAutoupdatePaused(hasSelectedRows)
    }, [enhanceTableProps.selectionCount, identifier, setDisabled])

    const onCameraFirmwaresDelete = useCallback(() => {
        props.deleteCameraFirmwares({
            ids: enhanceTableProps.getSelectedIds() as number[],
            filters: enhanceTableProps.getSelectAllFilters()
        })
        enhanceTableProps.deselectAllRows()
    }, [enhanceTableProps, props])

    const authorize = useAuthorization()
    const license = useLicense()

    const actionsAuthorized = authorize(EProfileRights.IS_SITE_ADMIN)
    const quickMenuAuthorized = authorize(EProfileRights.HAS_MANAGE_FIRMWARES_RIGHT)
    const actionsLicensed = license(ELicenseTypes.CONFIGURATION)

    const manageFirmwaresAuthorized = authorize(EProfileRights.HAS_MANAGE_FIRMWARES_RIGHT)

    const disableToolbarButtons = !enhanceTableProps.selectionCount
    const rowCount = TableHelper.countDisplayedRows(data, !!enhanceTableProps.fetchState.global.groupBy)
    const totalSelectedRows = enhanceTableProps.selectAll ? totalCount : enhanceTableProps.selectionCount

    const handleUploadNewFirmware = () => {
        showModal({
            content: <UploadNewCameraFirmware />,
            title: i18nKeys.camera_firmware_modal_title,
            description: i18nKeys.camera_firmware_modal_description
        })
    }

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        {
                            actionsAuthorized && actionsLicensed && manageFirmwaresAuthorized && (
                                <>
                                    <SelectionBox
                                        id='selectedCameraFirmwaresCheckbox'
                                        rowCount={enhanceTableProps.selectAll ? totalSelectedRows : rowCount}
                                        onAllRowsSelect={checked => enhanceTableProps.onAllRowsSelect(props.data, checked)}
                                        messageKey={i18nKeys.camera_firmware_table_selected_count}
                                        totalSelectedRows={totalSelectedRows}
                                        numSelectedRows={enhanceTableProps.selectAll ? totalSelectedRows : enhanceTableProps.selectionCount}
                                        disabled={enhanceTableProps.selectAll}
                                    />
                   
                                    <DeleteButton
                                        id='deleteCameraFirmwaresBtn'
                                        title={<TransMessage {...i18nKeys.camera_firmware_table_delete_firmwares} />}
                                        disabled={disableToolbarButtons}
                                        onClick={onCameraFirmwaresDelete}
                                    />
                                </>
                            )
                        
                        }
                    </>
                }
                RightContent={
                    <>
                        <ColumnChooser
                            minColumns={1}
                            {...colHandlerProps}
                        />
                        <ColumnPreset
                            tableId={tableInfo.id}
                            {...colHandlerProps}
                        />
                        <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                    </>
                }
            />

            {
                quickMenuAuthorized &&
                <QuickActionMenu>
                    <QuickActionMenuItem
                        id='quickActionUploadCameraFirmware'
                        icon={Icons.GEAR}
                        textKey={i18nKeys.camera_firmware_table_upload_modal_button}
                        onClick={handleUploadNewFirmware} />
                </QuickActionMenu>
            }

            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                rows={data}
            />
        </ViewWrapper>
    )
}

export default CameraFirmwares