import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import FirmwaresService from 'services/firmwaresService'
import { showMessage } from 'state/slices/uiSlice'
import { TFirmware } from 'types/businessLogic/firmware'
import { TFetchParamsRequest } from 'types/network'

export type TCameraFirmwareState = {
    cameraFirmwares: TFirmware[]
    totalNumber: number
}

const initialState : TCameraFirmwareState = {
    cameraFirmwares: [],
    totalNumber: 0
}

export const getCameraFirmwaresAsync = createAsyncThunk('cameraFirmware/getCameraFirmwaresAsync', async (params: TFetchParamsRequest, thunkApi) => {
    try {
        return await FirmwaresService.getFirmwares(params)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const deleteCameraFirmwaresAsync = createAsyncThunk('cameraFirmware/deleteCameraFirmwaresAsync', async (params: number[], thunkApi) => {
    try {
        await FirmwaresService.deleteFirmwares(params)
        thunkApi.dispatch(showMessage({message: i18nKeys.camera_firmware_deleted_success, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.camera_firmware_deleted_error, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'cameraFirmware',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCameraFirmwaresAsync.fulfilled, (state, action) => {
                const { firmwares, totalNumber } = action.payload
                state.cameraFirmwares = firmwares
                state.totalNumber = totalNumber
            })
    }
})

export default slice.reducer