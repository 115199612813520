import Copyright from 'components/Copyright/Copyright'
import TransMessage from 'components/TransMessage/TransMessage'
import Icon, { Icons } from 'components/Icon/Icon'
import LanguagePicker from 'components/LanguagePicker/LanguagePicker'
import { i18nKeys } from 'i18n/keys'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import images from 'style/images'
import styled from 'styled-components'
import { TChangePasswordRequest } from 'types/auth'
import { FormWrapper, InputWrapper, FormInput } from 'components/FormControls/FormControls'
import { useIntl } from 'react-intl'
import { CustomButton } from 'components/CustomControls/CustomButton'
import PasswordRequirements from 'components/PasswordRequirements/PasswordRequirements'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import { TPasswordRequirements } from 'types/businessLogic/validation'
import Validation from 'helpers/Validation'
import AuthService from 'services/authService'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { EMessageType } from 'enums/enums'
import { showMessage } from 'state/slices/uiSlice'
import { dispatch } from 'state/store'

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.primary.DARK_PRIMARY};
`
const QognifyImage = styled.img`
    max-width: 100px;
    margin-left: -20px;
`

const UmbrellaImage = styled.img`
    max-width: 202px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 28px;
    margin-top: 56px;
`
const InfoBox = styled.div`
    color: ${colors.shades.PURE_WHITE};
    width: 524px;
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const CustomFormWrapper = styled(FormWrapper)`
    width: 524px;
    height: 850px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.shades.PURE_WHITE};
    padding: 24px 72px 64px 56px;
    margin-top: 60px;
    border-radius: ${borderRadius.SECONDARY};
    border-left: 16px solid ${colors.primary.DEFAULT_PRIMARY};

    & #password_description {
        padding-top: 24px;
        padding-bottom: 56px;
    }
`

const LoginButton = styled(CustomButton)`
    width: 200px;
`

const PasswordTitle = styled.div`
    font-family: ${fonts.PRIMARY_BOLD};
    font-size: ${typographyScale.TYPE20};
    line-height: ${lineHeights.TYPE20LineHeight};
    letter-spacing: ${letterSpacings.TYPE20LS};
    color: ${colors.primary.DARK_PRIMARY};
    margin-bottom: 12px;
`

type Props = {
    version: string
    onChangePasswordSubmit: (formValues: TChangePasswordRequest) => void
}

const ChangePassword = (props: Props) => {
    const { version } = props
    const { onChangePasswordSubmit } = props
    const intl = useIntl()

    const [password, setPassword] = useState<string>()
    const [isPasswordvalid, setIsPasswordValid] = useState<boolean>(false)
    const [passwordRequirements, setPasswordRequirements] = useState<TPasswordRequirements>()

    useEffect(() => {
        const getPasswordRequirements = async () => {
            try {
                const result = await AuthService.getPasswordSettings()
                setPasswordRequirements(result)
            } catch {
                dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
            }
        }

        getPasswordRequirements()
    }, [])

    const handlePasswordChange = (value: string) => {
        if (passwordRequirements) {
            const validationResult = Validation.getPasswordValidationError(value, passwordRequirements)
            setIsPasswordValid(validationResult === undefined)
        }
        setPassword(value)
    }

    const validationSchema = yup.object().shape({
        currentPassword: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)),
        newPassword: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field))
    })

    const { handleSubmit, formState, control } = useForm<TChangePasswordRequest>({
        mode: 'onChange',
        resolver: yupResolver(validationSchema)
    })

    const { isValid, isValidating, isSubmitting } = formState

    const oldPasswordLabel = intl.formatMessage(i18nKeys.change_password_input_current_password_placeholder)
    const newPasswordLabel = intl.formatMessage(i18nKeys.change_password_input_new_password_placeholder)

    return (
        <CenterWrapper>
            
            <CustomFormWrapper onSubmit={handleSubmit(onChangePasswordSubmit)}>
                <QognifyImage src={images.qognifyLogo} alt='Qognify' />
                <UmbrellaImage src={images.logoGreen} alt='Umbrella' />

                <div id='password_description'>
                    <TransMessage {...i18nKeys.change_password_description} />
                </div>
                
                <InputWrapper>
                    <FormInput
                        name='currentPassword'
                        placeHolder={oldPasswordLabel}
                        control={control}
                        type='password'
                        rules={{required: true}}
                        startIcon={<Icon d={Icons.LOCK} size={20} />}
                    />
                </InputWrapper>

                <InputWrapper>
                    <FormInput
                        name='newPassword'
                        placeHolder={newPasswordLabel}
                        control={control}
                        type='password'
                        rules={{required: true}}
                        startIcon={<Icon d={Icons.LOCK} size={20} />}
                        onChange={handlePasswordChange}
                    />
                </InputWrapper>
                
                <InputWrapper>
                    <LoginButton
                        disabled={!isValid || isValidating || isSubmitting || !isPasswordvalid}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.change_password_submit_button} />
                    </LoginButton>
                </InputWrapper>
                {
                    passwordRequirements &&
                    <>
                        <PasswordTitle>{intl.formatMessage(i18nKeys.password_requirements_title)}</PasswordTitle>
                        <PasswordRequirements
                            passwordRequirements={passwordRequirements}
                            password={password}
                        />
                    </>
                }
            </CustomFormWrapper>
            <InfoBox>
                <Copyright version={version} />
                <LanguagePicker iconColor={colors.shades.PURE_WHITE} />
            </InfoBox>
        </CenterWrapper>
    )
}

export default ChangePassword
