import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import LicenseHelper from 'helpers/LicenseHelper'
import colors from 'style/colors'
import shadows from 'style/shadows'
import zIndex from 'style/zIndex'
import styled from 'styled-components'
import { TLicense } from 'types/businessLogic/license'

const Wrapper = styled.div`
    background-color: ${colors.extra.WARNING};
    box-shadow: ${shadows.PRIMARY};
    width: calc(100vw / 2);
    display: grid;
    border-radius: 8px;
    place-content: center;
    color: ${colors.shades.LIGHT_WHITE};
    position: absolute;
    margin-top: 25px;
    z-index: ${zIndex.MESSAGES};
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    transform: translateX(calc(100vw / 2 - 100vw / 5));
`

const CloseIcon = styled(Icon).attrs({
    d: Icons.CLOSE
})`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    fill: ${colors.shades.LIGHT_WHITE};
`

type TProps = {
    license : TLicense
    onClose: () => void
}

const LicensePopover = (props: TProps) => {
    const {license, onClose} = props
    
    const daysUntilExpiration = LicenseHelper.getDaysUntilLicenseExpiration(license.expirationDate)
    const licenseWillExpireSoon = LicenseHelper.willLicenseExpireSoon(license.expirationDate)

    return (
        <Wrapper>
            <CloseIcon onClick={onClose} />
            {
                licenseWillExpireSoon &&
                <p><TransMessage {...i18nKeys.license_warning_expiration_date} values={{days: daysUntilExpiration}} /></p>
            }
            {
                license.unlicensedCameras !== 0 &&
                <p><TransMessage {...i18nKeys.license_warning_unlicensed_cameras} values={{count: license.unlicensedCameras}} /></p>
            }
        </Wrapper>
    )
}

export default LicensePopover