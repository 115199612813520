import { ETableFilters } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import EnumHelper from 'helpers/EnumHelper'
import SiteHelper from 'helpers/SiteHelper'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'
import AppConstants from 'helpers/AppConstants'
import JobHelper from 'helpers/JobHelper'

const columnConfig: TTableColumnsConfig = {
    name: {
        key: 'Job',
        translationKey: i18nKeys.site_jobs_table_head_name.id,
        sortKey: 'Job',
        groupKey: 'Job',
        displayed: true,
        filter: {
            key: 'Job',
            type: ETableFilters.DROPDOWN,
            dropdownItems: EnumHelper.dropdownEnumRenderer(JobHelper.jobsEnum)
        }
    },
    state: {
        key: 'state',
        translationKey: i18nKeys.site_jobs_table_head_state.id,
        sortKey: 'State',
        groupKey: 'State',
        displayed: true,
        filter: {
            key: 'State',
            type: ETableFilters.DROPDOWN,
            dropdownItems: EnumHelper.dropdownEnumRenderer(SiteHelper.jobStateEnum)
        }
    },
    createdBy: {
        key: 'createdBy',
        sortKey: 'CreatedBy',
        groupKey: 'CreatedBy',
        translationKey: i18nKeys.site_jobs_table_head_createdBy.id,
        displayed: true,
        filter: {
            key: 'CreatedBy',
            type: ETableFilters.INPUT_TEXT
        }
    },
    created: {
        key: 'createdAt',
        sortKey: 'CreatedAt',
        translationKey: i18nKeys.site_jobs_table_head_created.id,
        displayed: true
    },
    updated: {
        key: 'updated',
        sortKey: 'Updated',
        translationKey: i18nKeys.site_jobs_table_head_updated.id,
        displayed: false
    },
    sites: {
        key: 'sites',
        translationKey: i18nKeys.site_jobs_table_head_sites.id,
        displayed: true,
        sortKey: 'Sites',
        filter: {
            key: 'Sites',
            type: ETableFilters.INPUT_TEXT
        }
    },
    about: {
        key: 'about',
        translationKey: i18nKeys.site_jobs_table_head_about.id,
        displayed: true,
        sortKey: 'About',
        filter: {
            key: 'About',
            type: ETableFilters.INPUT_TEXT
        }
    },
    comment: {
        key: 'comment',
        translationKey: i18nKeys.site_jobs_table_head_comment.id,
        displayed: false,
        filter: {
            key: 'Comment',
            type: ETableFilters.INPUT_TEXT
        }
    },
    message: {
        key: 'message',
        translationKey: i18nKeys.site_jobs_table_head_message.id,
        displayed: false,
        sortKey: 'Message',
        groupKey: 'Message',
        filter: {
            key: 'Message',
            type: ETableFilters.INPUT_TEXT
        }
    },
    sequenceId: {
        key: 'sequenceId',
        translationKey: i18nKeys.site_jobs_table_head_sequence.id,
        displayed: false,
        groupKey: 'SequenceID',
        filter: {
            key: 'SequenceID',
            type: ETableFilters.INPUT_TEXT
        }
    },
    sequenceOrder: {
        key: 'sequenceOrder',
        translationKey: i18nKeys.site_jobs_table_head_sequence_order.id,
        displayed: false
    },
    sequenceTotal: {
        key: 'sequenceTotalCount',
        translationKey: i18nKeys.site_jobs_table_head_sequence_total.id,
        displayed: false
    },
    executionTime: {
        key: 'executionTime',
        translationKey: i18nKeys.site_jobs_table_head_execution_time.id,
        displayed: false,
        sortKey: 'ExecutionTime'
    },
    // undoTime: {
    //     key: 'undoTime',
    //     translationKey: i18nKeys.site_jobs_table_head_undo_time.id,
    //     displayed: false,
    //     sortKey: 'UndoTime'
    // },
    retries: {
        key: 'retries',
        translationKey: i18nKeys.site_jobs_table_head_retries.id,
        displayed: false
    }
}

const defaultLayout = [
    columnConfig.name.key,
    columnConfig.state.key,
    columnConfig.createdBy.key,
    columnConfig.created.key,
    columnConfig.sites.key,
    columnConfig.about.key
]

export const tableInfo : TTableInfo = {
    id: AppConstants.tableIds.SITE_JOBS,
    columnConfig,
    defaultLayout
}