import { EVMSState } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import colors from 'style/colors'

export const VmsStateInfos = {
    [EVMSState.UNKNOWN] : {
        translationKey: i18nKeys.vms_state_offline,
        color: '#959595',
    },
    [EVMSState.OK] : {
        translationKey: i18nKeys.vms_state_ok,
        color: colors.extra.SUCCESS,
    },
    [EVMSState.ERROR] : {
        translationKey: i18nKeys.vms_state_error,
        color: colors.extra.ALERT,
    },
    [EVMSState.OFFLINE] : {
        translationKey: i18nKeys.vms_state_offline,
        color: '#959595',
    },
    [EVMSState.DISABLED] : {
        translationKey: i18nKeys.vms_state_disabled,
        color: colors.extra.SUCCESS,
    }
}