import EnhancedTabs from 'components/Tabs/EnhancedTabs'
import TransMessage from 'components/TransMessage/TransMessage'
import { EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { TSiteEntity } from 'types/businessLogic/site'
import { TTabButton } from 'types/ui/tab'
import DetailsTab from 'views/Control/SiteTimeProfiles/SiteTimeProfileDetails/Tabs/DetailsTab'
import { TModalProps } from 'types/ui/modal'
import SiteTimeProfileChangerContainer from 'views/Control/SiteTimeProfiles/SiteTimeProfileChanger/SiteTimeProfileChangerContainer'

type TProps = TModalProps & {
    siteTimeProfile: TSiteEntity
}

const SiteTimeProfileDetails = (props: TProps) => {
    const { siteTimeProfile } = props
        
    const authorize = useAuthorization()
    const isSiteAdmin = authorize(EProfileRights.IS_SITE_ADMIN)
    
    const tabButtons: TTabButton[] = [
        {
            id: 'detailsTabButton',
            label: i18nKeys.site_group_details_tab_details
        },
        {
            id: 'siteTimeProfileButton',
            label: i18nKeys.site_time_profile_profile_tab
        }
    ]

    return (
        <EnhancedTabs
            readOnlyForm={!isSiteAdmin}
            subtitleKey={i18nKeys.site_time_profile_general_description}
            numOfEntities={1}
            disableUpdateButtons={false}
            tabButtons={tabButtons}
            tabContent={[
                {
                    title: <TransMessage {...i18nKeys.site_time_profile_general_description} />,
                    content: <DetailsTab key='detailsTab' siteTimeProfile={siteTimeProfile} />
                },
                {
                    content: <SiteTimeProfileChangerContainer {...props} key='siteTimeProfileTab' totalCount={1} />
                }
            ]}
        />
    )
}

export default SiteTimeProfileDetails