import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { TSiteEvent } from 'types/businessLogic/siteEvent'
import { tableInfo } from 'views/Monitor/SiteEvents/siteEventsTableColumns'
import TransMessage from 'components/TransMessage/TransMessage'
import EnumHelper from 'helpers/EnumHelper'
import CustomLink from 'components/CustomControls/CustomLink'

class SiteEventsTablePreparer {
    public static prepareSiteRows = (siteEvents: TSiteEvent[], openSiteEventDetailsModal: (id: number) => void): TTableRowData[] =>
        siteEvents.map(siteEvent => ({
            ...siteEvent,
            children: SiteEventsTablePreparer.prepareSiteRows(siteEvent.children ?? [], openSiteEventDetailsModal),
            propertyValues: [
                {
                    name: tableInfo.columnConfig.sourceName.key,
                    translationKey: tableInfo.columnConfig.sourceName.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: () =>
                        <CustomLink onClick={() => openSiteEventDetailsModal(siteEvent.id)}>
                            {siteEvent.propertyValues.find(x => x.name === 'SourceName')?.stringValue}
                        </CustomLink>
                },
                {
                    name: tableInfo.columnConfig.siteName.key,
                    translationKey: tableInfo.columnConfig.siteName.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: siteEvent.siteName
                },
                {
                    name: tableInfo.columnConfig.timeStamp.key,
                    translationKey: tableInfo.columnConfig.timeStamp.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.dateTime.index,
                    [GeneralHelper.propertyTypes.dateTime.key]: siteEvent.timestamp
                },
                // {
                //     name: tableInfo.columnConfig.issueResolved.key,
                //     translationKey: tableInfo.columnConfig.issueResolved.translationKey,
                //     readOnly: false,
                //     propertyType: GeneralHelper.propertyTypes.string.index,
                //     [GeneralHelper.propertyTypes.string.key]: siteEvent.issueResolved.toString()
                // },
                {
                    name: tableInfo.columnConfig.name.key,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    readOnly: false,
                    [GeneralHelper.propertyTypes.string.key]: siteEvent.name
                },
                {
                    name: tableInfo.columnConfig.labels.key,
                    translationKey: tableInfo.columnConfig.labels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: siteEvent.labels
                },
                {
                    name: tableInfo.columnConfig.siteLabels.key,
                    translationKey: tableInfo.columnConfig.siteLabels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: siteEvent.siteLabels
                },
                {
                    name: tableInfo.columnConfig.entityType.key,
                    translationKey: tableInfo.columnConfig.entityType.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: () => <TransMessage {...EnumHelper.getEntityTypeName(siteEvent.entityType)} />
                },
                {
                    name: tableInfo.columnConfig.eventCategory.key,
                    translationKey: tableInfo.columnConfig.eventCategory.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: () => <TransMessage {...EnumHelper.getSiteEventCatergoryName(siteEvent.eventCategory)} />
                },
                {
                    name: tableInfo.columnConfig.description.key,
                    translationKey: tableInfo.columnConfig.description.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: siteEvent.description
                },
                ...siteEvent.propertyValues
            ]
        }))

    public static prepareGroups = (groups: TTableGroup<TSiteEvent>[], openSiteEventDetailsModal: (id: number) => void): TTableGroup<TTableRowData>[] => groups.map(group => ({
        ...group,
        data: group.data ? SiteEventsTablePreparer.prepareSiteRows(group.data, openSiteEventDetailsModal) : undefined
    }))
}

export default SiteEventsTablePreparer