import React, { useCallback, useEffect, useState } from 'react'
import ColumnChooser from 'components/ColumnChooser/ColumnChooser'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { Icons } from 'components/Icon/Icon'
import AdvancedTable from 'components/Tables/AdvancedTable'
import SelectionBox from 'components/Tables/SelectionBox'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import Toolbar from 'components/Toolbar/Toolbar'
import TransMessage from 'components/TransMessage/TransMessage'
import { EDataSyncItems, EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import { i18nKeys } from 'i18n/keys'
import styled from 'styled-components'
import { TTableFetchState, TGroup } from 'types/network'
import { TTableGroup, TTableRowData, TTableInfo } from 'types/ui/table'
import TableHelper from 'components/Tables/TableHelper'
import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import ColumnPreset from 'components/Tables/ColumnPreset'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'

type TProps = {
    data: Array<TTableRowData & {job: string}>
    groups: TTableGroup<TTableRowData & {job: string}>[]
    totalCount: number
    tableInfo: TTableInfo
    getTableData: (params: TTableFetchState) => void
    getGroupData: (params: TTableFetchState, group: TGroup) => void
    cancelJobs: (selectedRows: Array<TTableRowData & {job: string}>) => void
    retryJobs: (selectedRows: Array<TTableRowData & {job: string}>) => void
    isDetailOpen: boolean
}
const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`

const SiteJobs = (props: TProps) => {
    const { groups, tableInfo, totalCount, data, isDetailOpen, getGroupData, getTableData } = props
    const [identifier] = useState(uid())
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)

    const colHandlerProps = useColumnsHandler(tableInfo)

    const enhanceTableProps = useEnhanceTable<TTableRowData & {job: string}>({
        groups
    })
    
    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
        enhanceTableProps.selectedGroups.forEach(group => {
            getGroupData(enhanceTableProps.fetchState, group)
        })
    }, [enhanceTableProps.fetchState, enhanceTableProps.selectedGroups, getGroupData, getTableData])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.JOBS, loadData, identifier)

    useEffect(() => {
        const hasSelectedRows = enhanceTableProps.selectionCount > 0
        setDisabled(identifier, isDetailOpen || hasSelectedRows)
        setAutoupdatePaused(isDetailOpen || hasSelectedRows)
    }, [enhanceTableProps.selectionCount, identifier, isDetailOpen, setDisabled])

    const authorize = useAuthorization()

    const cancelJobs = () => {
        props.cancelJobs(enhanceTableProps.selectedRows)
        enhanceTableProps.deselectAllRows()
    }

    const retryJobs = () => {
        props.retryJobs(enhanceTableProps.selectedRows)
        enhanceTableProps.deselectAllRows()
    }

    const disableToolbarButtons = !enhanceTableProps.selectionCount
    const rowCount = TableHelper.countDisplayedRows(data, !!enhanceTableProps.fetchState.global.groupBy, groups)
    const totalSelectedRows = enhanceTableProps.selectAll ? totalCount : enhanceTableProps.selectionCount

    const isToolbarAuthorized = authorize(EProfileRights.IS_SITE_ADMIN)

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        {
                            isToolbarAuthorized && (
                                <>
                                    <SelectionBox
                                        id='selectedSiteJobsCheckBox'
                                        rowCount={enhanceTableProps.selectAll ? totalSelectedRows : rowCount}
                                        onAllRowsSelect={checked => enhanceTableProps.onAllRowsSelect(props.data, checked)}
                                        messageKey={i18nKeys.site_jobs_table_toolbar_selected_count}
                                        totalSelectedRows={totalSelectedRows}
                                        numSelectedRows={enhanceTableProps.selectAll ? totalSelectedRows : enhanceTableProps.selectionCount}
                                        switchSelectionMode={event => enhanceTableProps.switchSelectionMode(event, props.data)}
                                        disabled={enhanceTableProps.selectAll}
                                    />
                                    <CustomTooltip
                                        title={<TransMessage {...i18nKeys.site_jobs_table_toolbar_retry_job} />}>
                                        <div>
                                            <CustomIconButton
                                                id='retryJobsBtn'
                                                disabled={disableToolbarButtons}
                                                onClick={() => retryJobs()}
                                                icon={Icons.RETRY}
                                            />
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip
                                        title={<TransMessage {...i18nKeys.site_jobs_table_toolbar_cancel_job} />}>
                                        <div>
                                            <CustomIconButton
                                                id='cancelJobsBtn'
                                                disabled={disableToolbarButtons}
                                                onClick={() => cancelJobs()}
                                                icon={Icons.CANCEL}
                                            />
                                        </div>
                                    </CustomTooltip>
                                </>
                            )
                        }
                    </>
                }
                RightContent={
                    <>
                        <ColumnChooser
                            {...colHandlerProps}
                        />
                        <ColumnPreset
                            tableId={tableInfo.id}
                            {...colHandlerProps}
                        />
                        <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                    </>
                }
            />

            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                rows={data}
                groups={groups}
            />
        </ViewWrapper>
    )
}

export default SiteJobs