import React, { useCallback, useEffect, useState } from 'react'
import AdvancedTable from 'components/Tables/AdvancedTable'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import styled from 'styled-components'
import { TGroup, TTableFetchState } from 'types/network'
import { TTableGroup, TTableRowData, TTableInfo } from 'types/ui/table'
import Toolbar from 'components/Toolbar/Toolbar'
import useAuthorization from 'hooks/useAuthorization'
import { EDataSyncItems, EProfileRights } from 'enums/enums'
import SelectionBox from 'components/Tables/SelectionBox'
import { i18nKeys } from 'i18n/keys'
import useLicense from 'hooks/useLicense'
import { ELicenseTypes } from 'enums/ELicenseTypes'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import TransMessage from 'components/TransMessage/TransMessage'
import { Icons } from 'components/Icon/Icon'
import DeleteButton from 'components/Tables/DeleteButton'
import ColumnPreset from 'components/Tables/ColumnPreset'
import ColumnChooser from 'components/ColumnChooser/ColumnChooser'
import TableHelper from 'components/Tables/TableHelper'
import { TModalProps } from 'types/ui/modal'
import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import useBackgroundService from 'hooks/useBackgroundService'
import { uid } from 'uid'
import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`

type TProps = {
    data: TTableRowData[]
    groups: TTableGroup<TTableRowData>[]
    totalCount: number
    tableInfo: TTableInfo
    getTableData: (params: TTableFetchState) => void
    onReportsDelete: (params: TModalProps) => void
    onDownloadReports: (params: TModalProps) => void
    getGroupData: (params: TTableFetchState, group: TGroup) => void
}

const SiteAuditReport = (props: TProps) => {
    const { tableInfo, data, totalCount, groups, getTableData, getGroupData, onReportsDelete } = props
    const [identifier] = useState(uid())
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)
    
    const colHandlerProps = useColumnsHandler(tableInfo)

    const enhanceTableProps = useEnhanceTable({
        groups
    })

    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
        enhanceTableProps.selectedGroups.forEach(group => {
            getGroupData(enhanceTableProps.fetchState, group)
        })
    }, [enhanceTableProps.fetchState, enhanceTableProps.selectedGroups, getGroupData, getTableData])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.AUDIT_REPORTS, loadData, identifier)

    useEffect(() => {
        const hasSelectedRows = enhanceTableProps.selectionCount > 0
        setDisabled(identifier, hasSelectedRows)
        setAutoupdatePaused(hasSelectedRows)
    }, [enhanceTableProps.selectionCount, identifier, setDisabled])

    const handleReportsDelete = useCallback(() => {
        onReportsDelete({
            ids: enhanceTableProps.getSelectedIds() as number[],
            filters: enhanceTableProps.getSelectAllFilters()
        })
        enhanceTableProps.deselectAllRows()
    }, [enhanceTableProps, onReportsDelete])

    const onDownloadReports = useCallback(() => {
        props.onDownloadReports({
            ids: enhanceTableProps.getSelectedIds() as number[],
            filters: enhanceTableProps.getSelectAllFilters()
        })
        enhanceTableProps.deselectAllRows()
    }, [enhanceTableProps, props])

    const authorize = useAuthorization()
    const license = useLicense()

    const selectionBoxAuthorized = authorize(EProfileRights.HAS_SITE_AUDIT_REPORT_RIGHT)
    const actionsAuthorized = selectionBoxAuthorized
    const reportLicensed = license(ELicenseTypes.REPORT)

    const disableToolbarButtons = !enhanceTableProps.selectionCount
    const rowCount = TableHelper.countDisplayedRows(data, !!enhanceTableProps.fetchState.global.groupBy, groups)
    const totalSelectedRows = enhanceTableProps.selectAll ? totalCount : enhanceTableProps.selectionCount

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        {
                            selectionBoxAuthorized && actionsAuthorized && reportLicensed && (
                                <>
                                    <SelectionBox
                                        id='selectedReportsCheckbox'
                                        rowCount={enhanceTableProps.selectAll ? totalSelectedRows : rowCount}
                                        onAllRowsSelect={checked => enhanceTableProps.onAllRowsSelect(props.data, checked)}
                                        messageKey={i18nKeys.site_list_toolbar_selected_sites}
                                        totalSelectedRows={totalSelectedRows}
                                        numSelectedRows={enhanceTableProps.selectAll ? totalSelectedRows : enhanceTableProps.selectionCount}
                                        switchSelectionMode={event => enhanceTableProps.switchSelectionMode(event, props.data)}
                                        disabled={enhanceTableProps.selectAll}
                                    />

                                    <CustomTooltip
                                        title={<TransMessage {...i18nKeys.reports_table_toolbar_download_tooltip}
                                        />}>
                                        <div>
                                            <CustomIconButton
                                                id='downloadReportsPasswordBtn'
                                                disabled={disableToolbarButtons}
                                                onClick={onDownloadReports}
                                                icon={Icons.DOWNLOAD_REPORT}
                                            />
                                        </div>
                                    </CustomTooltip>

                                    <DeleteButton
                                        id='deleteReportsBtn'
                                        title={<TransMessage {...i18nKeys.reports_table_toolbar_delete_tooltip} />}
                                        disabled={disableToolbarButtons}
                                        onClick={handleReportsDelete}
                                    />
                                </>
                            )
                        }
                    </>
                }
                RightContent={
                    <>
                        <ColumnChooser
                            minColumns={1}
                            {...colHandlerProps}
                        />
                        <ColumnPreset
                            tableId={tableInfo.id}
                            {...colHandlerProps}
                        />
                        <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                    </>
                }
            />

            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                disableSelectionForIds={[]}
                rows={data}
                groups={groups}
            />
        </ViewWrapper>
    )
}

export default SiteAuditReport