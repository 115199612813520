import chroma from 'chroma-js'
import { Icons } from 'components/Icon/Icon'
import ListItem from 'components/NavigationList/ListItem'
import ListMenuItem from 'components/NavigationList/ListMenuItem'
import { i18nKeys } from 'i18n/keys'
import colors from 'style/colors'
import styled from 'styled-components'
import React from 'react'
import { TRouteGroup } from 'types/routing'
import AppRoutes from 'router/AppRoutes'
import useLicense from 'hooks/useLicense'
import { useAppSelector } from 'state/store'

const Wrapper = styled.div`
    white-space: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    color: white;
    padding-top: 8px;
    max-height: calc(100% - 185px) ;
`

const sublistActiveColor = chroma(colors.primary.DARKER_PRIMARY).alpha(0.3).css()

type TProps = {
    collapsed: boolean
}

const NavigationList = (props: TProps) => {
    const checkLicense = useLicense()
    const { situatorExclusive } = useAppSelector(store => store.license)

    const renderItems = (routesGroup: TRouteGroup) => {
        return routesGroup.routes.map((item, index) => {
            if (checkLicense(item.license, item.ignoreForSituatorExclusive)) {
                return (
                    <ListItem
                        key={index}
                        id={item.id}
                        rights={item.rights}
                        to={item.to}
                        icon={item.icon}
                        label={item.label}
                        activeColor={sublistActiveColor}
                    />
                )
            } else {
                return <></>
            }
        })
    }

    const renderSubmenu = (routesGroup: TRouteGroup, id: string) => {
        if (checkLicense(routesGroup.license, routesGroup.ignoreForSituatorExclusive)) {
            return (
                <ListMenuItem
                    id={id}
                    to={routesGroup.prefix}
                    rights={routesGroup.rights}
                    icon={routesGroup.icon}
                    label={routesGroup.label}
                    collapsed={props.collapsed}
                >
                    {renderItems(routesGroup)}
                </ListMenuItem>
            )
        } else {
            return <></>
        }
            
    }

    const { username } = useAppSelector(store => store.profile)

    return (
        <>
            {username === 'administrator' ?
                <Wrapper>
                    {renderSubmenu(AppRoutes.admin, 'admin')}
                    <ListItem
                        rights={[]}
                        id='manual'
                        to='/manual'
                        icon={Icons.MANUAL}
                        label={i18nKeys.sidenav_manual}
                        target='_blank'
                        rel='noopener noreferrer'
                    />
                </Wrapper> :
                <Wrapper>
                    {renderItems(AppRoutes.root)}
                    {renderSubmenu(AppRoutes.control, 'control')}
                    {renderSubmenu(AppRoutes.monitor, 'monitor')}
                    {renderSubmenu(AppRoutes.report, 'report')}
                    {renderSubmenu(AppRoutes.admin, 'admin')}
                    {
                        !situatorExclusive && (
                            <ListItem
                                rights={[]}
                                id='manual'
                                to='/manual'
                                icon={Icons.MANUAL}
                                label={i18nKeys.sidenav_manual}
                                target='_blank'
                                rel='noopener noreferrer'
                            />
                        )
                    }
                    
                </Wrapper>
            }
        </>
    )
    
}

export default NavigationList
