import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { logoutAsync } from 'state/slices/authSlice'
import { addItem } from 'state/slices/dataSyncSlice'
import { setLicense } from 'state/slices/licenseSlice'
import { addNotification } from 'state/slices/notificationSlice'
import { showMessage } from 'state/slices/uiSlice'
import { dispatch } from 'state/store'
import { TLicense } from 'types/businessLogic/license'
import { TNotification } from 'types/businessLogic/siteEvent'
import { TDataChange } from 'types/network'

const socketEvents = {
    licenseNotification: 'LicenseNotification',
    siteEventsNotification: 'SiteEventsNotification',
    logOutNotification: 'LogoutNotification',
    dataChangeNotification: 'DataChangeNotification'
}

class SignalRService {
    private _hubConnection: HubConnection
    
    public constructor() {
        this._hubConnection = new HubConnectionBuilder().withUrl('/hubs/client/notification').withAutomaticReconnect().build()
        this._hubConnection.on(socketEvents.logOutNotification, () => {
            dispatch(logoutAsync())
            dispatch(showMessage({message: i18nKeys.login_user_rights_were_changed, type: EMessageType.SUCCESS}))
        })
        this._hubConnection.on(socketEvents.licenseNotification, (license: TLicense) => dispatch(setLicense(license)))
        this._hubConnection.on(socketEvents.siteEventsNotification, (notification: TNotification) => dispatch(addNotification(notification)))
        this._hubConnection.on(socketEvents.dataChangeNotification, (data: TDataChange) => dispatch(addItem(data.changedType)))
    }

    public async start() {
        try {
            if (this._hubConnection.state === HubConnectionState.Disconnected) {
                await this._hubConnection.start()
            }
        } catch (error) {
            // no logging
        }
    }

    public stop() {
        if (this._hubConnection.state !== HubConnectionState.Disconnected) {
            this._hubConnection.stop()
        }
    }
}

export const signalRService = new SignalRService()