import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import PluginService from 'services/pluginService'
import { TPluginKindType } from 'types/businessLogic/plugin'

export type TPluginKindTypeState = {
    types: TPluginKindType[]
    fetched: boolean
}

const initialState : TPluginKindTypeState = {
    types: [],
    fetched: false
}

export const getPluginKindTypesAsync = createAsyncThunk('pluginKindType/getPluginKindTypesAsync', async () => {
    // no try/catch wrapper to prefent popups when user has no roles
    return await PluginService.getPluginKindTypes()
})

const slice = createSlice({
    name: 'pluginKindType',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPluginKindTypesAsync.fulfilled, (state, action) => {
                state.types = action.payload
                state.fetched = true
            })
    }
})

export default slice.reducer