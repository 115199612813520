import { yupResolver } from '@hookform/resolvers/yup'
import AdvancedJobConfigFields from 'components/AdvancedJobConfigFields/AdvancedJobConfigFields'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormWrapper, FormContent, FormInput, FormLabel, InputWrapper, FormButtonGroup, FormFooter, FormGroup } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TCopySiteUsersJobRequest, TJobRequest } from 'types/businessLogic/job'
import { TModalProps } from 'types/ui/modal'
import * as yup from 'yup'
import { siteUserService } from 'services/siteUserService'
import { useIntl } from 'react-intl'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { pushItem, setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import { TUploadManagerItem } from 'types/ui/uploadManager'
import { useModal } from 'hooks/modalProvider'

type TCopySiteUsersJobForm = {
    advancedJobConfig: TJobRequest
    newName: string
    newPassword: string
    comment?: string
}

const CopySiteUser = (props: TModalProps) => {
    const [isUndoEnabled, setIsUndoEnabled] = useState<boolean>(false)
    const {ids} = props

    const intl = useIntl()
    const {closeModal} = useModal()
    const dispatch = useAppDispatch()
    const isCommentMandatory = useAppSelector(state => state.ui.uiSettings.isCommentMandatoryForJobs)
    
    const handleCopySiteUserNameSubmit = useCallback(async (formValues: TCopySiteUsersJobForm) : Promise<void> => {
        let uploadManagerItem : TUploadManagerItem
        
        try {
            const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_site_user_copy_user,
                {count: isUndoEnabled ? (ids as number[]).length * 2 : (ids as number[]).length})
                    
            uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

            const request : TCopySiteUsersJobRequest = {
                ids: ids as number[],
                newName: formValues.newName,
                newPassword: formValues.newPassword,
                ...formValues.advancedJobConfig,
                comment: formValues.comment
            }

            dispatch(pushItem(uploadManagerItem))
            await siteUserService.copySiteUsers(request)
            
            dispatch(showMessage({message: i18nKeys.copy_site_conneciton_user_job_created_success, type: EMessageType.SUCCESS}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.copy_site_conneciton_user_job_created_error, type: EMessageType.ERROR}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem!.id, isError: true}))
        }

        closeModal()
    }, [dispatch, ids, intl, isUndoEnabled, closeModal])

    const defaultValues : TCopySiteUsersJobForm = {
        newName: '',
        newPassword: '',
        advancedJobConfig: {}
    }

    const validationSchema = (mandatoryComment: boolean) => yup.object().shape({
        newName: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)),
        newPassword: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)),
        comment: mandatoryComment ? yup.string().max(500).required(intl.formatMessage(i18nKeys.general_validation_required_field)) : yup.string().max(500)
    })

    const { handleSubmit, formState, control, setValue} = useForm<TCopySiteUsersJobForm>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema(isCommentMandatory))
    })
    const { isValid, isValidating, isSubmitting, isDirty } = formState

    return (
        <FormWrapper onSubmit={handleSubmit(handleCopySiteUserNameSubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_site_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='newName'>
                            <TransMessage {...i18nKeys.copy_site_user_modal_username_input_label} />
                        </FormLabel>
                        <FormInput
                            name='newName'
                            control={control}
                            type='text'
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='newPassword'>
                            <TransMessage {...i18nKeys.copy_site_user_modal_password_input_label} />
                        </FormLabel>
                        <FormInput
                            name='newPassword'
                            control={control}
                            type='password'
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup collapsable title={i18nKeys.advanced_job_configuration_title}>
                    <AdvancedJobConfigFields
                        formProps={formState}
                        setFieldValue={setValue}
                        setUndoState={setIsUndoEnabled}
                        control={control}
                    />
                </FormGroup>
                <FormGroup title={i18nKeys.job_configuration_comment_title}>
                    <InputWrapper>
                        <FormLabel htmlFor='comment'>
                            <TransMessage {...i18nKeys.job_configuration_comment} />
                        </FormLabel>
                        <FormInput
                            name='comment'
                            autoComplete='job-comment'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.copy_site_user_modal_submit_button} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}

export default CopySiteUser