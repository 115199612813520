import { Card, Input, MenuItem } from '@mui/material'
import Container from 'components/Container/Container'
import CustomAreaChart from 'components/CustomControls/CustomAreaChart'
import { CardWrapper } from 'components/CustomControls/CustomCharts'
import { CustomSelect } from 'components/CustomControls/CustomSelect'
import TransMessage from 'components/TransMessage/TransMessage'
import { Dayjs } from 'dayjs'
import { i18nKeys } from 'i18n/keys'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { AutoSizer } from 'react-virtualized'
import colors from 'style/colors'
import styled from 'styled-components'
import { TAggregatedNetworkAdapters } from 'types/businessLogic/siteSystem'

const Header = styled.h2`
    padding-top: 16px;
    padding-left: 32px;
    padding-bottom: 16px;
`
const SelectContainer = styled.div`
    display: flex;
    place-self: flex-end;
    padding-right: 2rem;
    justify-content: space-between;
`

type Props = {
    networkAdapters: TAggregatedNetworkAdapters
    fromDay: Dayjs
    tillDay: Dayjs
}

const NetworkChart = (props: Props) => {
    const { networkAdapters, fromDay, tillDay } = props

    const {formatMessage} = useIntl()

    const networkAdaptersIds = Object.keys(networkAdapters)
    const noDataText = formatMessage(i18nKeys.site_systems_details_no_disk_data)
    const defaultValue = networkAdaptersIds.length > 0 ? networkAdaptersIds[0] : noDataText

    const [activeNetworkAdapter, setNetworkAdapter] = useState<string | undefined>(defaultValue)

    const networkData = networkAdapters[activeNetworkAdapter ?? ''] ?? []

    return (
        <Container width='100%' height='100%'>
            <Card className='infoCard'>
                <SelectContainer>
                    <Header>
                        <TransMessage {...i18nKeys.site_systems_details_net_usage} />
                    </Header>
                    <CustomSelect
                        value={activeNetworkAdapter}
                        onChange={e => setNetworkAdapter(e.target.value as string)}
                        input={ <Input disableUnderline /> }
                    >
                        {
                            networkAdaptersIds.length > 0 ?
                                networkAdaptersIds.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>) :
                                <MenuItem value={noDataText}>{noDataText}</MenuItem>
                        }
                    </CustomSelect>
                </SelectContainer>
               
                <AutoSizer disableHeight>
                    {({width}) => {
                        return (
                            <CardWrapper>
                                <CustomAreaChart
                                    unit='%'
                                    data={networkData}
                                    graphColor={colors.extra.ALERT}
                                    width={width}
                                    height={300}
                                    from={fromDay}
                                    till={tillDay}
                                />
                            </CardWrapper>
                        )
                    }}
                </AutoSizer>
            </Card>
        </Container>
    )
}

export default NetworkChart