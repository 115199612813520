import { ETableFilters } from 'enums/enums'
import AppConstants from 'helpers/AppConstants'
import { i18nKeys } from 'i18n/keys'
import { TTableColumnsConfig, TTableInfo } from 'types/ui/table'

const columnConfig: TTableColumnsConfig = {
    name: {
        key: 'Name',
        translationKey: i18nKeys.labels_table_head_name.id,
        displayed: true,
        sortKey: 'Name',
        filter: {
            key: 'Name',
            type: ETableFilters.INPUT_TEXT
        }
    }
}

const defaultLayout = [
    columnConfig.name.key,
]

export const tableInfo: TTableInfo = {
    id: AppConstants.tableIds.LABELS,
    columnConfig,
    defaultLayout
}