import React, { useCallback } from 'react'
import { clearGroups, deleteAllSiteInventoryReportsAsync, deleteSiteInventoryReportsAsync, getGroupDataAsync, getSiteInventoryReportsAsync } from 'state/slices/siteInventoryReportsSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGroup, TTableFetchState } from 'types/network'
import { tableInfo } from 'views/Report/SiteInventory/siteInventoryReportsTableColumns'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { TModalProps } from 'types/ui/modal'
import { TDeleteAllReportsRequest, TDownloadAllReportsRequest } from 'types/businessLogic/report'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'
import SiteInventoryReportsTablePreparer from 'views/Report/SiteInventory/SiteInventoryReportsTablePreparer'
import ReportsService from 'services/reportsService'
import SiteInventoryReport from 'views/Report/SiteInventory/SiteInventoryReport'
import useBreadCrumbs from 'hooks/useBreadCrumbs'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const SiteInventoryReportsContainer = () => {
    const dispatch = useAppDispatch()
    const { items, totalNumber, groups } = useAppSelector(state => state.siteInventoryReports)

    useBreadCrumbs([i18nKeys.breadcrumb_reports])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams,
            translationKey: group.translationKey
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const requestParams = GeneralHelper.buildRequestFromTableState(params)
            dispatch(getSiteInventoryReportsAsync(requestParams))
        } else if (params.groupName) {
            const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, params.groupName)
            dispatch(getGroupDataAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)
                const result = await ReportsService.getReportsGroups(groupingParams)
                dispatch(clearGroups(result))
            }

            getGroups()
        }
    }, [dispatch])

    const deleteReports = (params: TModalProps) => {
        if (params.ids.length === 0 && params.filters) {
            const request: TDeleteAllReportsRequest = {
                filters: params.filters
            }
            
            dispatch(deleteAllSiteInventoryReportsAsync(request))
        } else {
            dispatch(deleteSiteInventoryReportsAsync(params.ids as number[]))
        }
    }

    const downloadReports = (params: TModalProps) => {
        try {
            if (params.ids.length === 0 && params.filters) {
                const request: TDownloadAllReportsRequest = {
                    filters: params.filters
                }
                ReportsService.downloadAllReports(request)
            } else {
                ReportsService.downloadReports(params.ids as number[])
            }

            dispatch(showMessage({message: i18nKeys.reports_download_success_snack_message, type: EMessageType.SUCCESS}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.reports_download_error_snack_message, type: EMessageType.ERROR}))
        }
    }

    const data = SiteInventoryReportsTablePreparer.prepareSiteReportRows(items)
    const preparedGroups = SiteInventoryReportsTablePreparer.prepareGroups(groups)
        
    return (
        <ViewContainer>
            <SiteInventoryReport
                data={data}
                groups={preparedGroups}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                getTableData={getTableData}
                onReportsDelete={deleteReports}
                onDownloadReports={downloadReports}
                getGroupData={getGroupData}
            />
        </ViewContainer>
    )
}

export default SiteInventoryReportsContainer