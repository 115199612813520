import { Collapse, FormControl } from '@mui/material'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import { useController, UseControllerProps } from 'react-hook-form'
import colors from 'style/colors'
import fonts from 'style/fonts'
import styled from 'styled-components'
import React, { useState } from 'react'
import { CustomInput } from 'components/CustomControls/CustomInput'
import Icon, { Icons } from 'components/Icon/Icon'
import CustomSlider from 'components/CustomControls/CustomSlider'
import { FormControlResetButton, StyledFormHelperText } from 'components/FormControls/FormControls'

const FieldWrapper = styled.div`
    min-width: 380px;
    min-height: 54px;
    display: flex;
    flex-direction: column;
`
const SliderManualInput = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 380px;
    margin-bottom: 20px;
`
const SliderValue = styled.div<{changeMultiple: boolean}>`
    color: ${colors.primary.DARK_PRIMARY};
    font-size: 14px;
    font-family: ${fonts.PRIMARY_SEMIBOLD};
    text-align: right;
    margin-top: -20px;
    width: 340px;
    min-height: 24px;
`
const CollectionContainer = styled(FormControl)`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 54px;
`

type TFieldProps = UseControllerProps<any> & {
    min?: number
    max?: number
    disabled?: boolean
    onReset?: (name: string, value: any) => void
    onError?: () => void
}

export const FormSlider = (props: TFieldProps) => {
    const { field, fieldState } = useController(props)
    const [isManualInputExpanded, setManualInputExpandState] = useState(false)

    const iconClickHandler = () => !props.disabled && setManualInputExpandState(!isManualInputExpanded)

    const handleReset = () => {
        props.onReset && props.onReset(field.name, props.defaultValue)
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        field.onChange(event.currentTarget.value)
    }

    const handleOnBlur = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.currentTarget.value) {
            let value = Number(event.currentTarget.value)
            if (props.min) {
                value = Math.max(value, props.min)
            }
    
            if (props.max) {
                value = Math.min(props.max, value)
            }

            field.onChange(value)
        } else {
            field.onChange(props.defaultValue)
        }
    }

    const handleSliderOnChange = (value : number) => {
        field.onChange(value)
        if (fieldState.invalid) {
            props.onError && props.onError()
        }
        
    }

    return (
        <>
            <FieldWrapper>
                <CollectionContainer>
                    <CustomSlider
                        step={1}
                        id={field.name}
                        disabled={props.disabled}
                        min={props.min}
                        max={props.max}
                        onInputChange={handleSliderOnChange}
                        value={field.value}
                        name={field.name}
                        ref={field.ref}
                    />
                    <Icon d={Icons.CHEVRON} rotate={isManualInputExpanded ? 180 : 0} onClick={iconClickHandler} opacity={.4} />
                    {
                        props.onReset && props.defaultValue !== field.value &&
                            <FormControlResetButton
                                onClick={handleReset}>
                                <TransMessage {...i18nKeys.properties_form_button_reset} />
                            </FormControlResetButton>
                    }
                </CollectionContainer>
                <SliderValue
                    id={field.name}
                    changeMultiple={false}
                >
                    {field.value === null ? '-' : field.value}
                </SliderValue>
                <StyledFormHelperText id={`${field.name}-helper`}>
                    <span hidden={!fieldState.invalid}>
                        {fieldState.error && fieldState.error.message}
                    </span>
                </StyledFormHelperText>
                <SliderManualInput>
                    <Collapse in={isManualInputExpanded} unmountOnExit>
                        <CustomInput
                            type='number'
                            id={field.name}
                            {...field}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                        />
                    </Collapse>
                </SliderManualInput>
            </FieldWrapper>
            
        </>
    )
}