import TransMessage from 'components/TransMessage/TransMessage'
import { EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormGroup, FormLabel, FormWrapper, FormFooter, InputWrapper } from 'components/FormControls/FormControls'
import { TSiteEntity, TSiteEntityUpdate } from 'types/businessLogic/site'
import { updateSiteGroupAsync } from 'state/slices/siteGroupSlice'
import LabelsHelper from 'helpers/LabelsHelper'
import { FormAsyncCreatableSelect } from 'components/FormControls/FormAsyncCreatableSelect'
import { debounce } from '@mui/material'
import { TSelectOptions } from 'types/ui/dropdown'

type TProps = {
    siteGroup: TSiteEntity
    submitted: () => void
}

type TSiteGroupDetailsForm = {
    labels: TSelectOptions
}

const validationSchema = yup.object({
})

const DetailsTab = (props: TProps) => {
    const {siteGroup} = props

    const dispatch = useDispatch()

    const onSubmit = (values: TSiteGroupDetailsForm) => {
        const labels = values.labels.map(option => option.value.toString())

        const siteEntityToUpdate: TSiteEntityUpdate = {
            id: siteGroup.id,
            labels
        }

        dispatch(updateSiteGroupAsync(siteEntityToUpdate))

        props.submitted && props.submitted()
    }

    const authorize = useAuthorization()
    const isSiteAdmin = authorize(EProfileRights.IS_SITE_ADMIN)

    const prepareInitialValues = (siteCamera: TSiteEntity) : TSiteGroupDetailsForm => {
        return {
            labels: siteCamera.labels.map(label => {
                return {
                    label: label,
                    value: label
                }
            })
        }
    }

    const [initialValues] = useState<TSiteGroupDetailsForm>(prepareInitialValues(siteGroup))

    const { handleSubmit, formState, control } = useForm<TSiteGroupDetailsForm>({
        mode: 'onChange',
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            LabelsHelper.loadLabels(inputText).then((options) => callback(options))
        }, 500), [])

    const { isValid, isValidating, isSubmitting, isDirty } = formState

    return (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <FormContent>
                <FormGroup>
                    <InputWrapper>
                        <FormLabel htmlFor='name'>
                            <TransMessage {...i18nKeys.site_group_details_site_group} />
                        </FormLabel>
                        <FormLabel>
                            {siteGroup.name || ''}
                        </FormLabel>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='siteName'>
                            <TransMessage {...i18nKeys.site_group_details_site} />
                        </FormLabel>
                        <FormLabel>
                            {siteGroup.siteName || ''}
                        </FormLabel>
                        
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='labels'>
                            <TransMessage {...i18nKeys.site_group_details_labels} />
                        </FormLabel>
                        <FormAsyncCreatableSelect
                            name='labels'
                            control={control}
                            isMulti
                            loadOptions={loadOptions}
                            defaultOptions
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            {
                isSiteAdmin &&
                    <FormFooter>
                        <FormButtonGroup>
                            <CustomButton
                                disabled={!isValid || isValidating || isSubmitting || !isDirty}
                                id='submitBtn'
                                type='submit'
                            >
                                <TransMessage {...i18nKeys.site_group_details_update_button} />
                            </CustomButton>
                        </FormButtonGroup>
                    </FormFooter>
            }
        </FormWrapper>
    )
}

export default DetailsTab