import { yupResolver } from '@hookform/resolvers/yup'
import AdvancedJobConfigFields from 'components/AdvancedJobConfigFields/AdvancedJobConfigFields'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormWrapper, FormContent, FormInput, FormLabel, InputWrapper, FormButtonGroup, Instructions, FormFooter, FormGroup } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import SiteService from 'services/siteService'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TChangeAllUserPasswordJobRequest, TChangeUserPasswordJobRequest, TJobRequest } from 'types/businessLogic/job'
import { TModalProps } from 'types/ui/modal'
import * as yup from 'yup'
import { useIntl } from 'react-intl'
import { TUploadManagerItem } from 'types/ui/uploadManager'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { pushItem, setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import { useModal } from 'hooks/modalProvider'

type TChangeUserPasswordJobForm = {
    password: string
    secondPassword: string
    advancedJobConfig: TJobRequest
    comment?: string
}

type ChangeConnectionUserPasswordModalProps = TModalProps & {
    totalCount: number
}

const ChangeConnectionUserPassword = (props: ChangeConnectionUserPasswordModalProps) => {
    const {filters, ids, totalCount} = props
    const isSelectAll = !!filters
    
    const intl = useIntl()
    const {closeModal} = useModal()
    const dispatch = useAppDispatch()
    const isCommentMandatory = useAppSelector(state => state.ui.uiSettings.isCommentMandatoryForJobs)
    
    const handleChangeConnectionUserPasswordSubmit = useCallback(async (formValues: TChangeUserPasswordJobForm) : Promise<void> => {
        let uploadManagerItem : TUploadManagerItem
        
        try {
            if (isSelectAll) {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_sites_connection_user_password, {count: totalCount})
                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const request: TChangeAllUserPasswordJobRequest = {
                    ...formValues.advancedJobConfig,
                    filters: filters,
                    password: formValues.password,
                    secondPassword: formValues.secondPassword,
                    comment: formValues.comment
                }

                dispatch(pushItem(uploadManagerItem))
                await SiteService.changeAllConnectionUserPassword(request)
            } else {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_sites_connection_user_password, {count: (ids as number[]).length})
                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const request: TChangeUserPasswordJobRequest = {
                    ...formValues.advancedJobConfig,
                    ids: ids as number[],
                    password: formValues.password,
                    secondPassword: formValues.secondPassword,
                    comment: formValues.comment
                }

                dispatch(pushItem(uploadManagerItem))
                await SiteService.changeConnectionUserPassword(request)
            }
            dispatch(showMessage({message: i18nKeys.change_site_connection_user_job_created_success, type: EMessageType.SUCCESS}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.change_site_connection_user_job_created_error, type: EMessageType.ERROR}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem!.id, isError: true}))
        }

        closeModal()
    }, [dispatch, filters, ids, intl, isSelectAll, closeModal, totalCount])

    const defaultValues : TChangeUserPasswordJobForm = {
        password: '',
        secondPassword: '',
        advancedJobConfig: {}
    }

    const validationSchema = (mandatoryComment: boolean) => yup.object().shape({
        password: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)),
        comment: mandatoryComment ? yup.string().max(500).required(intl.formatMessage(i18nKeys.general_validation_required_field)) : yup.string().max(500)
    })

    const { handleSubmit, formState, control, setValue} = useForm<TChangeUserPasswordJobForm>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema(isCommentMandatory))
    })

    const { isValid, isValidating, isSubmitting, isDirty } = formState
    
    return (
        <FormWrapper onSubmit={handleSubmit(handleChangeConnectionUserPasswordSubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_site_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='password'>
                            <TransMessage {...i18nKeys.change_site_connection_user_modal_new_password_input_label} />
                        </FormLabel>
                        <FormInput
                            name='password'
                            control={control}
                            type='password'
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup collapsable title={i18nKeys.advanced_job_configuration_title}>
                    <AdvancedJobConfigFields
                        formProps={formState}
                        setFieldValue={setValue}
                        hideUndoField
                        control={control}
                    />
                </FormGroup>
                <FormGroup title={i18nKeys.job_configuration_comment_title}>
                    <InputWrapper>
                        <FormLabel htmlFor='comment'>
                            <TransMessage {...i18nKeys.job_configuration_comment} />
                        </FormLabel>
                        <FormInput
                            name='comment'
                            autoComplete='job-comment'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.change_site_connection_user_modal_submit_button} />
                    </CustomButton>
                </FormButtonGroup>
                <Instructions>
                    <TransMessage {...i18nKeys.change_site_connection_user_modal_form_description} />
                </Instructions>
            </FormFooter>
        </FormWrapper>
    )
}

export default ChangeConnectionUserPassword