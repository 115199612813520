import { CustomModal } from 'components/CustomControls/CustomModal'
import TransMessage from 'components/TransMessage/TransMessage'
import React, { createContext, FunctionComponent, ReactElement, useContext, useState } from 'react'
import { TTranslationKey } from 'types/ui/translation'

type TShowModalProps = {
    title: TTranslationKey | string
    description?: TTranslationKey | string
    content: ReactElement
    width?: string
    onClose?: () => void
}

type TModalContext = {
    showModal: (props: TShowModalProps) => void
    closeModal: () => void
    updateHeader: (title: TTranslationKey | string, description?: TTranslationKey | string) => void
    isModalOpen: boolean
}

const modalProvider = createContext<TModalContext>({
    showModal: () => {},
    closeModal: () => {},
    updateHeader: () => {},
    isModalOpen: false
})

export const useModal = () => {
    return useContext(modalProvider)
}

export const ModalProvider : FunctionComponent = ({children}) => {
    const [open, setOpen] = useState(false)
    const [state, setState] = useState<TShowModalProps>()

    const showModal = (props: TShowModalProps) => {
        setState(props)
        setOpen(true)
    }

    const closeModal = () => {
        state?.onClose && state?.onClose()
        setOpen(false)
        setState(undefined)
    }

    const updateHeader = (title: TTranslationKey | string, description?: TTranslationKey | string) => {
        if (state) {
            if (state.title !== title || state.description !== description) {
                setState({
                    ...state,
                    title,
                    description
                })
            }
        }
    }

    const value = {
        showModal,
        closeModal,
        updateHeader,
        isModalOpen: open
    }

    const transform = (param: TTranslationKey | string | undefined) => {
        if (!param) {
            return undefined
        }
        
        if (typeof param === 'string') {
            return param
        }

        return <TransMessage {...param} />
    }

    return <modalProvider.Provider value={value}>
        {
            state && (
                <CustomModal
                    open={open}
                    onClose={closeModal}
                    title={transform(state.title)}
                    width={state.width}
                    description={transform(state.description)}
                >
                    {state.content}
                </CustomModal>
            )
        }
        {children}
    </modalProvider.Provider>
}

