import React, { MutableRefObject } from 'react'
import {Range} from 'rc-slider'
import 'rc-slider/assets/index.css'
import styled from 'styled-components'
import colors from 'style/colors'
import { sliderDirections } from 'types/timeprofile'
import borderRadius from 'style/borderRadius'
import fonts from 'style/fonts'
import typographyScale from 'style/typographyScale'
import lineHeights from 'style/lineHeights'
import letterSpacings from 'style/letterSpacings'

const deflattenIntoNSize = (arr: number[], n: number) => {
    const copyOfArray = [...arr]
    const splitArray = []
    while (copyOfArray.length) {
        splitArray.push(copyOfArray.splice(0, n))
    }
    return splitArray
}

const StyledRange = styled(Range)``

const Handle = styled.div`
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: ${colors.primary.DEFAULT_PRIMARY};
    border-radius: 50%;
    top: 0;
    transform: translate(-50%, 0);
    &:hover > div {
        opacity: 1 !important;
        transform: translate(-50%, -58px) scale(1) !important;
    }
`

type Props = {
    min: number
    max: number
    values: [number, number][]
    defaultValues?: [number, number][]
    step?: number
    vertical?: boolean
    railStyle?: any
    trackStyle?: any
    handleValueFormater?: (value: number, isActive: boolean) => number | string | JSX.Element
    onChange?: (value: [number, number][]) => void
    onBeforeChange?: (value: [number, number][]) => void
    onAfterChange?: (value: [number, number][]) => void
    disabled?: boolean
    defaultStyles?: any
    disabledStyles?: any
    showBothEndTooltips?: boolean
    onHandleTouch?: (index: number) => void
    afterEveryChange?: () => void
    onDirectionChange?: (val: string) => void
    activeTrackStyle?: any
    activeHandleStyle?: any
}

const defaultToolTipYTranslatePx = 58

type HandleProps = {
    value: number
    offset: number
    index: number
}

type State = {
    activeHandle?: number
    trackStyle: any[]
}

class AdvancedSlider extends React.Component<Props, State> {
    calculateCount = () => {
        if (this.props.values && this.props.values.length) {
            return this.props.values.length + 1
        }
        if (this.props.defaultValues && this.props.defaultValues.length) {
            return this.props.defaultValues.length + 1
        }
        return 1
    }

    getTrackStyle = () : any => {
        const styles = []
        const {trackStyle, railStyle, activeTrackStyle} = this.props
        const activeState = activeTrackStyle ? activeTrackStyle : {backgroundColor: colors.extra.ALERT, height: 4}
        const filledState = trackStyle ? trackStyle : {backgroundColor: colors.primary.DEFAULT_PRIMARY, height: 2}
        const emptyState = railStyle ? railStyle : {backgroundColor: colors.extra.EXTRA_GRAY, height: 2}
        for (let i = 0, track = 0; i < (this.calculateCount() + 1) * 2; i++) {
            if (i % 2 === 0) {
                if (!this.state || this.state.activeHandle === undefined) {
                    styles.push(filledState)
                } else {
                    const style = track === this.state.activeHandle - (this.state.activeHandle % 2) ?
                        activeState : filledState
                    styles.push(style)
                    
                }
            } else {
                if (!this.state || this.state.activeHandle === undefined) {
                    styles.push(emptyState)
                } else {
                    const style = track === this.state.activeHandle - (this.state.activeHandle % 2) ?
                        activeState : emptyState
                    styles.push(style)
                }
            }
            track++
        }
        return styles
    }

    defaultActiveHandleStyle = {
        height: 16,
        width: 16,
        backgroundColor: colors.extra.ALERT
    }

    activeHandleRef: React.Ref<HTMLDivElement> = React.createRef<HTMLDivElement>()

    state : State = {
        activeHandle: undefined,
        trackStyle: this.getTrackStyle()
    }

    lastX: number | null = null
    lastDirection = null

    handleDirection = (e: MouseEvent) => {
        if (!this.props.onDirectionChange) return
        if (this.lastX === null) {
            this.lastX = e.pageX
            return
        }
        const newX = e.pageX
        if (newX > this.lastX && this.lastDirection !== sliderDirections.RIGHT) {
            this.props.onDirectionChange(sliderDirections.RIGHT)
        } else if (newX < this.lastX && this.lastDirection !== sliderDirections.LEFT) {
            this.props.onDirectionChange(sliderDirections.LEFT)
        }
        this.lastX = newX
    }

    componentDidMount() {
        document.addEventListener('mousemove', this.handleDirection)
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleDirection)
    }

    lastTouchedValues: number[] = []

    originalDefaultValuesOrder: number[] = []
    originalValuesOrder: number[] = []

    parseValuesOrDefaultValues = (arr: [number, number][], areDefaultValues: boolean) => {
        const valuesOrderRef = areDefaultValues ? this.originalDefaultValuesOrder : this.originalValuesOrder
        valuesOrderRef.splice(0)
        if (!arr) {
            return null
        }

        const sorted = [...arr].sort((a: [number, number], b: [number, number]) => a[0] - b[0])
        const order = arr.map((curr: [number, number]) => sorted.findIndex((x: [number, number]) =>
            curr[0] === x[0] && curr[1] === x[1]))

        valuesOrderRef.push(...order)
        return arr.flat().sort((a: number, b: number) => a - b)
    }

    componentDidUpdate(prevProps: Props, prevState: State): void {
        // Recalculate track style if need
        if (this.props.trackStyle !== prevProps.trackStyle ||
            this.state.activeHandle !== prevState.activeHandle ||
            (this.props.values && this.props.values.length) !== (prevProps.values && prevProps.values.length) ||
            // eslint-disable-next-line max-len
            (this.props.defaultValues && this.props.defaultValues.length) !== (prevProps.defaultValues && prevProps.defaultValues.length)
        ) {
            this.setState({trackStyle: this.getTrackStyle()})
        }

        // If new range added by clicking on blank space, remove focus from old one
        if ((this.props.values && this.props.values.length) !== (prevProps.values && prevProps.values.length) &&
            this.state.activeHandle !== null) {
            this.loseFocusFromHandle()
            this.setActiveHandle(undefined)
        }
    }

    triggerMouseEventOnHandle = (type: string) => {
        if (this.activeHandleRef) {
            const myRef = (this.activeHandleRef as MutableRefObject<HTMLDivElement>)
            if (myRef.current) {
                const eventProperties = {
                    view: window,
                    bubbles: true,
                    cancelable: true
                }
                const mouseUpEvent = new MouseEvent(type, eventProperties)
                myRef.current.dispatchEvent(mouseUpEvent)
            }
        }
        
    }

    loseFocusFromHandle = () => this.triggerMouseEventOnHandle('mouseup')

    setActiveHandle = (index: number | undefined) => {
        this.setState({activeHandle: index})
    }

    isActiveHandle = (index: number, checkPure?: boolean, forceNonPure?: boolean) => {
        if (this.state.activeHandle === undefined) return false
        if (this.state.activeHandle === index) return true
        if ((this.props.showBothEndTooltips !== true || checkPure === true) && !forceNonPure) return false
        if (this.state.activeHandle % 2 === 0 && this.state.activeHandle + 1 === index) return true
        if (this.state.activeHandle % 2 !== 0 && this.state.activeHandle - 1 === index) return true
        return false
    }

    defaultToolTip = (value: string | number | JSX.Element, index: number) => (
        <div
            style={{
                position: 'relative',
                wordBreak: 'break-word',
                whiteSpace: 'pre-line',
                textAlign: 'center',
                borderRadius: borderRadius.SECONDARY,
                color: colors.shades.PURE_WHITE,
                backgroundColor: colors.primary.DARK_PRIMARY,
                fontFamily: fonts.PRIMARY_MEDIUM,
                fontSize: typographyScale.TYPE12,
                lineHeight: lineHeights.TYPE12LineHeight,
                letterSpacing: letterSpacings.TYPE12LS,
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,
                boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.15)',
                minWidth: 67,
                width: 'max-content',
                height: 40,
                transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                opacity: this.isActiveHandle(index) ? 1 : 0,
                transform:
                    `translate(-50%, -${defaultToolTipYTranslatePx}px) scale(${this.isActiveHandle(index) ? 1 : 0})`
            }}
            // className={this.props.classes.tooltip}
        >
            {value}
        </div>
    )

    defaultHandlerMouseDownHandler = (index: number) => {
        if (this.props.disabled) {
            return
        }
        
        this.setActiveHandle(index)
        this.props.onHandleTouch && this.props.onHandleTouch(index)
    }

    defaultHandle = ({value, offset, index}: HandleProps) => {
        const isActivePure = this.isActiveHandle(index, true)
        const isActive = this.isActiveHandle(index, undefined, true)
        const formatedValue = this.props.handleValueFormater ? this.props.handleValueFormater(value, isActivePure) : value
        const {activeHandleStyle} = this.props
        const activeStyle = isActive ? (activeHandleStyle ? activeHandleStyle : this.defaultActiveHandleStyle) : {}
        return (
            <Handle
                key={index}
                ref={this.isActiveHandle(index, true) ? this.activeHandleRef : null}
                onMouseDown={() => this.defaultHandlerMouseDownHandler(index)}
                style={{left: `${offset}%`, ...activeStyle}}
            >
                {this.defaultToolTip(formatedValue, index)}
            </Handle>
        )
    }

    calculateActiveHandle = (values: number[]) => {
        const len = values.length > this.lastTouchedValues.length ? values.length : this.lastTouchedValues.length
        for (let i = 0; i < len; i++) {
            if (values[i] !== this.lastTouchedValues[i]) {
                return i
            }
        }
        return undefined
    }

    prepareValuesOnChange = (values: number[]) => {
        const deflattened = deflattenIntoNSize(values, 2)
        const originalOrderReference = this.originalValuesOrder || this.originalDefaultValuesOrder
        const restoredToOriginalOrder = []
        for (let i = 0; i < originalOrderReference.length; i++) {
            // eslint-disable-next-line no-sequences
            restoredToOriginalOrder[i] = deflattened[(originalOrderReference[i], originalOrderReference[i])]
        }
        return restoredToOriginalOrder
    }

    onAfterChange = (values: number[]) => {
        this.setActiveHandle(undefined)
        if (this.props.onAfterChange) {
            const preparedValues = this.prepareValuesOnChange(values) as [number, number][]
            this.props.onAfterChange(preparedValues)
        }
    }

    onBeforeChange = (values: number[]) => {
        this.lastTouchedValues = values
        if (this.props.onBeforeChange) {
            const preparedValues = this.prepareValuesOnChange(values) as [number, number][]
            this.props.onBeforeChange(preparedValues)
        }
    }

    onChange = (values: number[]) => {
        if (!this.state.activeHandle) {
            this.setActiveHandle(this.calculateActiveHandle(values))
        }
        if (this.props.onChange) {
            const preparedValues = this.prepareValuesOnChange(values) as [number, number][]
            this.props.onChange(preparedValues)
        }
        if (this.props.afterEveryChange) {
            this.props.afterEveryChange()
        }
        this.lastTouchedValues = values
    }

    render() {
        const {
            min,
            max,
            values,
            defaultValues,
            step,
            vertical,
            railStyle,
            disabled
        } = this.props

        const flattenedDefaultValues = defaultValues && defaultValues.length ? this.parseValuesOrDefaultValues(defaultValues, true) : defaultValues
        const flattenedValues = values && values.length ? this.parseValuesOrDefaultValues(values, false) : values
        return (
            <StyledRange
                disabled={disabled}
                // className={cx(classes.root, {[classes.disabledStyles]: disabled})}
                allowCross={false}
                count={this.calculateCount()}
                defaultValue={flattenedDefaultValues as number[]}
                value={flattenedValues as number[]}
                max={max}
                min={min}
                step={step}
                vertical={vertical}
                handle={this.defaultHandle}
                trackStyle={this.state.trackStyle}
                railStyle={railStyle || {backgroundColor: colors.extra.EXTRA_GRAY, height: 2}}
                onAfterChange={this.onAfterChange}
                onBeforeChange={this.onBeforeChange}
                onChange={this.onChange} />
        )
    }
}

export default AdvancedSlider