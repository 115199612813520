import { yupResolver } from '@hookform/resolvers/yup'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormHeader, FormInput, FormLabel, FormWrapper, FormFooter, InputWrapper, Relative, FormGroup, FormCheckbox } from 'components/FormControls/FormControls'
import { FormNumberInput } from 'components/FormControls/FormNumberInput'
import { FormSelect } from 'components/FormControls/FormSelect'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import Validation from 'helpers/Validation'
import styled from 'styled-components'
import { TEMailConfig } from 'types/businessLogic/misc'
import * as yup from 'yup'
import { TSelectOption, TSelectOptions } from 'types/ui/dropdown'

const TestButton = styled(CustomButton)`
    position: absolute;
    right: 8px;
    top: 12px;
    height: auto;
    min-height: 32px;
    width: 100px;
    min-width: 100px;
    padding: 0 24px;
`

type TProps = {
    emailConfig: TEMailConfig
    handleEMailConfigSubmit: (formValues: TEMailConfigForm) => void
    handleTestEMailSubmit: (formValues: TEMailConfigForm) => void
}

export type TEMailConfigForm = TEMailConfig & {
    encryptionOptions: TSelectOption
}

const EMail = (props: TProps) => {
    const {emailConfig} = props
    const {handleEMailConfigSubmit, handleTestEMailSubmit} = props

    const [isPasswordDisabled, setIsPasswordDisabled] = useState<boolean>(true)

    const intl = useIntl()
  
    const encryptionOptions: TSelectOptions = useMemo(() => [
        {value: 0, label: intl.formatMessage(i18nKeys.email_configuration_encryption_null)},
        {value: 1, label: intl.formatMessage(i18nKeys.email_configuration_encryption_ssl)}
    ], [intl])
   
    const defaultEMailconfig : TEMailConfigForm = useMemo(() => ({
        ...emailConfig,
        encryptionOptions: {
            label: encryptionOptions.find(option => option.value === emailConfig.encryption)!.label,
            value: emailConfig.encryption
        }
    }), [emailConfig, encryptionOptions])

    const validationSchema = yup.object().shape({
        host: yup.string().required(intl.formatMessage(i18nKeys.email_configuration_host_error)),
        port: yup.number().typeError(intl.formatMessage(i18nKeys.general_validation_required_field)).required().test('port', intl.formatMessage(i18nKeys.email_configuration_port_error), Validation.isValidPortNumber),
        // username: yup.string().optional(intl.formatMessage(i18nKeys.email_configuration_username_error)),
        // password: yup.string().optional(intl.formatMessage(i18nKeys.email_configuration_password_error)),
        sender: yup.string().email(intl.formatMessage(i18nKeys.email_configuration_sender_email_error)).required(intl.formatMessage(i18nKeys.general_validation_required_field)),
        subject: yup.string().required(intl.formatMessage(i18nKeys.email_configuration_subject_error))
    })

    const toggleDisablePassword = () => {
        setIsPasswordDisabled(prev => (!prev))
    }

    const { handleSubmit, getValues, formState, reset, control } = useForm<TEMailConfigForm>({
        mode: 'onChange',
        defaultValues: defaultEMailconfig,
        resolver: yupResolver(validationSchema),
    })

    const { isValid, isValidating, isSubmitting, isDirty } = formState

    useEffect(() => {
        reset(defaultEMailconfig)
    }, [defaultEMailconfig, reset])

    return (
        <FormWrapper onSubmit={handleSubmit(handleEMailConfigSubmit)}>
            <FormHeader title={i18nKeys.email_configuration_title} description={i18nKeys.email_configuration_description} />
            
            <FormContent>
                <FormGroup>
                    <InputWrapper>
                        <FormLabel htmlFor='host'>
                            <TransMessage {...i18nKeys.email_configuration_smtp_address} />
                        </FormLabel>
                        <FormInput
                            name='host'
                            control={control}
                            defaultValue=''
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='port'>
                            <TransMessage {...i18nKeys.email_configuration_smtp_port} />
                        </FormLabel>
                        <FormNumberInput
                            name='port'
                            control={control}
                            defaultValue={0}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='username'>
                            <TransMessage {...i18nKeys.email_configuration_username} />
                        </FormLabel>
                        <FormInput
                            name='username'
                            control={control}
                            autoComplete='nope'
                            defaultValue=''
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='password'>
                            <TransMessage {...i18nKeys.email_configuration_password} />
                        </FormLabel>
                        <FormInput
                            name='password'
                            control={control}
                            type='password'
                            autoComplete='new-password'
                            disabled={isPasswordDisabled}
                            defaultValue=''
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='setPassword'>
                            <TransMessage {...i18nKeys.email_configuration_password_checkbox} />
                        </FormLabel>
                        <FormCheckbox
                            name='setPassword'
                            control={control}
                            onChange={toggleDisablePassword}
                            defaultValue={false}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='sender'>
                            <TransMessage {...i18nKeys.email_configuration_sender_email} />
                        </FormLabel>
                        <FormInput
                            name='sender'
                            control={control}
                            defaultValue=''
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='encryptionOptions'>
                            <TransMessage {...i18nKeys.email_configuration_encryption_method} />
                        </FormLabel>
                        <FormSelect
                            name='encryptionOptions'
                            control={control}
                            options={encryptionOptions}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='subject'>
                            <TransMessage {...i18nKeys.email_configuration_subject} />
                        </FormLabel>
                        <FormInput
                            name='subject'
                            control={control}
                            defaultValue=''
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='receiver'>
                            <TransMessage {...i18nKeys.email_configuration_test_receiver_label} />
                        </FormLabel>
                        <Relative>
                            <FormInput
                                name='receiver'
                                control={control}
                                defaultValue=''
                            />
                            <TestButton
                                id='testBtn'
                                type='button'
                                onClick={() => {
                                    const formValues = getValues()
                                    handleTestEMailSubmit(formValues)
                                }}
                            >
                                <TransMessage {...i18nKeys.email_configuration_test} />
                            </TestButton>
                        </Relative>
                    </InputWrapper>
                </FormGroup>
            </FormContent>

            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.email_configuration_submit} />
                    </CustomButton>
                    <CustomButton
                        id='resetBtn'
                        type='reset'
                        onClick={() => reset(defaultEMailconfig)}
                        $secondary
                    >
                        <TransMessage {...i18nKeys.email_configuration_reset_fields} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}

export default EMail