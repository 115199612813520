import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormCheckbox, FormContent, FormFooter, FormGroup, FormLabel, FormWrapper, InputWrapper } from 'components/FormControls/FormControls'
import { FormSelect } from 'components/FormControls/FormSelect'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TSelectOption, TSelectOptions } from 'types/ui/dropdown'
import { Stack } from '@mui/material'
import { ENotificationPriority } from 'enums/enums'

export const NotificationPriorityOptions : TSelectOptions = [
    {value: ENotificationPriority.HIGH, label: 'High'},
    {value: ENotificationPriority.NORMAL, label: 'Normal'},
    {value: ENotificationPriority.LOW, label: 'Low'},
]

export type TSetNotificationPriorityFormValues = {
    notificationPriority: TSelectOption
    useDefault: boolean
}

type SetNotificationPriorityFormProps = {
    onSubmit: (formValues: TSetNotificationPriorityFormValues) => void
    initialValue: TSetNotificationPriorityFormValues
    hideUseDefaultValue: boolean
}

const SetNotificationPriorityForm = ({onSubmit, initialValue, hideUseDefaultValue} : SetNotificationPriorityFormProps) => {
    const { handleSubmit, control, trigger, watch} = useForm<TSetNotificationPriorityFormValues>({
        mode: 'onChange',
        defaultValues: initialValue,
    })
    
    const useDefaultValue = watch('useDefault')

    const handleFormSubmit = (formValues: TSetNotificationPriorityFormValues) => {
        onSubmit(formValues)
    }

    return (
        <FormWrapper onSubmit={handleSubmit(handleFormSubmit)}>
            <FormContent>
                <FormGroup>
                    <InputWrapper>
                        <FormLabel htmlFor='notificationPriority'>
                            <TransMessage {...i18nKeys.notification_priority_priority_label} />
                        </FormLabel>
                        <Stack>
                            <FormSelect
                                name='notificationPriority'
                                control={control}
                                options={NotificationPriorityOptions}
                                disabled={useDefaultValue}
                            />
                            {
                                !hideUseDefaultValue && (
                                    <Stack direction='row' alignItems='center'>
                                        <FormCheckbox
                                            name='useDefault'
                                            control={control}
                                            onChange={() => {
                                                trigger('notificationPriority')
                                            }}
                                        />
                                        <label htmlFor='useDefault'>
                                            <TransMessage {...i18nKeys.configure_threshold_use_default_label} />
                                        </label>
                                    </Stack>
                                )
                            }
                        </Stack>
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.configure_threshold_button_label} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}
export default SetNotificationPriorityForm