import CustomStepper from 'components/CustomStepper/CustomStepper'
import { useModal } from 'hooks/modalProvider'
import { createUploadManagerItemForFirmwareUpdate } from 'components/UploadManager/UploadManager'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { siteCameraService } from 'services/siteCameraService'
import { showMessage } from 'state/slices/uiSlice'
import { pushItem, setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import { useAppDispatch } from 'state/store'
import { TAllUpdateFirmwareJobRequest, TUpdateFirmwareJobRequest } from 'types/businessLogic/job'
import { TModalProps } from 'types/ui/modal'
import { TUploadManagerItem } from 'types/ui/uploadManager'
import ConfirmUpdate, { TUpdateFirmwareJobForm } from 'views/Control/SiteCamera/Modals/UpdateFirmware/ConfirmUpdate'
import SelectFirmware from 'views/Control/SiteCamera/Modals/UpdateFirmware/SelectFirmware'

type TProps = TModalProps & {
    totalCount: number
}

const UpdateFirmware = (props: TProps) => {
    const {ids, filters, totalCount} = props

    const isSelectAll = !!filters

    const intl = useIntl()
    const {closeModal, updateHeader} = useModal()
    const dispatch = useAppDispatch()
    const [selectedFirmware, setSelectedFirmware] = useState<string | undefined>(undefined)
    
    const handleNext = useCallback((firmwareName: string) : void => {
        setSelectedFirmware(firmwareName)
    }, [])

    const handleSubmitButton = useCallback(async (formValues: TUpdateFirmwareJobForm) : Promise<void> => {
        let uploadManagerItem : TUploadManagerItem
        
        try {
            if (isSelectAll) {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_camera_firmware_update, {count: totalCount})
                uploadManagerItem = createUploadManagerItemForFirmwareUpdate(uploadManagerItemText)

                const request : TAllUpdateFirmwareJobRequest = {
                    ...formValues.advancedJobConfig,
                    firmwareName: selectedFirmware!,
                    filters: filters,
                    comment: formValues.comment
                }

                dispatch(pushItem(uploadManagerItem))
                await siteCameraService.updateAllFirmware(request)
            } else {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_camera_firmware_update, {count: (ids as number[]).length})
                uploadManagerItem = createUploadManagerItemForFirmwareUpdate(uploadManagerItemText)

                const request : TUpdateFirmwareJobRequest = {
                    ...formValues.advancedJobConfig,
                    firmwareName: selectedFirmware!,
                    cameraIds: ids as number[],
                    comment: formValues.comment
                }

                dispatch(pushItem(uploadManagerItem))
                await siteCameraService.updateFirmware(request)
            }
            dispatch(showMessage({message: i18nKeys.apply_firmware_create_job_success, type: EMessageType.SUCCESS}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id}))
        } catch (reason) {
            dispatch(showMessage({message: i18nKeys.apply_firmware_create_job_error, type: EMessageType.ERROR}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem!.id, isError: true}))
        }

        closeModal()
    }, [dispatch, filters, ids, intl, isSelectAll, closeModal, selectedFirmware, totalCount])

    const steps = [
        i18nKeys.apply_firmware_modal_step_one,
        i18nKeys.apply_firmware_modal_step_two
    ]

    const stepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 0:
                updateHeader(i18nKeys.apply_firmware_modal_step_one_title, i18nKeys.apply_firmware_modal_step_one_description)
                return <SelectFirmware
                    handleNext={handleNext}
                >
                    <CustomStepper
                        stepIndex={stepIndex}
                        steps={steps}
                    />
                </SelectFirmware>
            case 1:
                updateHeader(i18nKeys.apply_firmware_modal_step_two_title, intl.formatMessage(i18nKeys.apply_firmware_modal_step_two_description, {filename: selectedFirmware}))
                return <ConfirmUpdate
                    {...props}
                    handleClose={closeModal}
                    handleSubmitButton={handleSubmitButton}
                >
                    <CustomStepper
                        stepIndex={stepIndex}
                        steps={steps}
                    />
                </ConfirmUpdate>
        }
    }

    return (
        <>
            {stepContent(selectedFirmware ? 1 : 0)}
        </>
    )
}

export default UpdateFirmware