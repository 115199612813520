import { TUseCardPage } from 'hooks/useCardPage'
import { ERecordingState, EVMSState } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { VmsStateInfos } from 'components/PagedCardList/VmsStateInfos'
import { TCameraPerformance } from 'types/businessLogic/cameraPerformance'
import { RecordingStateInfos } from 'components/PagedCardList/RecordingStateInfos'
import InfoCard from 'components/PagedCardList/InfoCard'
import useCameraState, { TUseCameraStateProps } from 'hooks/useCameraState'
import { getPerformanceStateInfos } from 'components/PagedCardList/getPerformanceStateInfos'
import colors from 'style/colors'

const Container = styled.div`
    width: 264px;
`

type TProps = TUseCardPage & {
    camera: TCameraPerformance
    isChecked: boolean
    isFiltered: boolean
    openDetails?: (id: number) => void
}

const CameraInfoCard = (props: TProps) => {
    const { isChecked, isFiltered, selectAll, camera, onItemSelect, openDetails} = props

    const cameraState = camera.overallState
    const stateInfo = getPerformanceStateInfos(cameraState, camera.isSiteIgnored)
    const borderColor = isFiltered ? stateInfo.color : colors.extra.EXTRA_GRAY

    const handleCheckboxClick = (checked: boolean) => {
        onItemSelect([camera], checked)
    }
 
    const intl = useIntl()

    const recordingStateText = intl.formatMessage(i18nKeys.camera_performance_recording_state_label)
    const recordingStateTextTooltip = intl.formatMessage(i18nKeys.camera_performance_recording_state_tooltip)

    const vmsStateText = intl.formatMessage(i18nKeys.camera_performance_vms_state_label)
    const vmsStateTextTooltip = intl.formatMessage(i18nKeys.camera_performance_vms_state_tooltip)

    const recordingStateInfo = RecordingStateInfos[camera.recordingState]
    const vmsStateInfo = VmsStateInfos[camera.vmsState]

    const vmsStateCodeTooltip = camera.vmsStateCode

    const vmsProps: TUseCameraStateProps = {
        disabled: camera.recordingState === ERecordingState.DISABLED || camera.vmsState === EVMSState.DISABLED,
        overallState: camera.overallState,
        stateInfo: vmsStateInfo,
        tooltip: vmsStateCodeTooltip
    }

    const recordingProps: TUseCameraStateProps = {
        disabled: camera.recordingState === ERecordingState.DISABLED || camera.vmsState === EVMSState.DISABLED,
        overallState: camera.overallState,
        stateInfo: recordingStateInfo
    }

    const handleOpenDetails = () => {
        openDetails && openDetails(camera.entityId)
    }

    const vmsState = useCameraState(vmsProps)
    const recordingState = useCameraState(recordingProps)
   
    return (
        <Container>
            <InfoCard
                borderColor={borderColor}
                handleCheckboxClick={handleCheckboxClick}
                iconInfo={{ icon: stateInfo.icon, fill: stateInfo.color }}
                id={'camera_' + camera.id.toString()}
                isChecked={isChecked}
                isFiltered={isFiltered}
                selectAll={selectAll}
                title={camera.cameraName}
                overallState={cameraState}
                tableInfos={[
                    {color: recordingState.color, value: recordingState.value, text: recordingStateText, labelTooltip: recordingStateTextTooltip},
                    {color: vmsState.color, value: vmsState.value, text: vmsStateText, tooltip: vmsState.toolTipValue, labelTooltip: vmsStateTextTooltip}
                ]}
                openDetails={handleOpenDetails}
                notificationPriority={camera.notificationPriority}
                notificationType={camera.notificationType}
            />
        </Container>
    )
}
export default CameraInfoCard
