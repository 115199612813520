import appConstants from 'helpers/AppConstants'
import { paginationConfig } from 'hooks/useEnhanceTable'
import { TPropertyChange } from 'types/businessLogic/job'
import { TPropertyValue } from 'types/businessLogic/site'
import { TCameraRequest, TFetchParamsRequest, TGroupParams, TQueryFilter, TTableFetchState } from 'types/network'

export type TTabFormGroup = {
    [key: string]: TPropertyValue[]
}

class GeneralHelper {
    static entityTypes = {
        SITE_GROUPS: 'SiteGroups',
        SITE_USERS: 'SiteUsers',
        UMBRELLA_USERS: 'Users',
        CAMERAS: 'Cameras',
        SITE_SERVICES: 'SiteServices',
        SITES: 'Site'
    }

    static propertyTypes = {
        bool: {
            index: 0,
            key: 'boolValue'
        },
        string: {
            index: 1,
            key: 'stringValue'
        },
        long: {
            index: 2,
            key: 'longValue'
        },
        collection: {
            index: 3,
            key: 'collectionValue'
        },
        dateTime: {
            index: 4,
            key: 'dateTimeValue'
        },
        siteTime: {
            index: 5,
            key: 'stringValue'
        },
        double: {
            index: 6,
            key: 'doubleValue'
        },
        link: { // This is custom property type, we don't get this from the backend
            index: 7,
            key: 'linkValue'
        },
        dateString: { // This is custom property type, we don't get this from the backend
            index: 8,
            key: 'dateStringValue'
        },
        customRender: { // This is custom property type, we don't get this from the backend
            index: 9,
            key: 'customRender'
        }
    }

    static getPropertyValue(prop: TPropertyValue) : any {
        return prop[GeneralHelper.getPropertyKey(prop.propertyType)]
    }

    static getPropertyKey(propertyType: number): string {
        switch (propertyType) {
            case GeneralHelper.propertyTypes.bool.index:
                return GeneralHelper.propertyTypes.bool.key
            case GeneralHelper.propertyTypes.string.index:
                return GeneralHelper.propertyTypes.string.key
            case GeneralHelper.propertyTypes.long.index:
                return GeneralHelper.propertyTypes.long.key
            case GeneralHelper.propertyTypes.dateTime.index:
                return GeneralHelper.propertyTypes.dateTime.key
            case GeneralHelper.propertyTypes.collection.index:
                return GeneralHelper.propertyTypes.collection.key
            case GeneralHelper.propertyTypes.link.index:
                return GeneralHelper.propertyTypes.link.key
            case GeneralHelper.propertyTypes.dateString.index:
                return GeneralHelper.propertyTypes.dateString.key
            case GeneralHelper.propertyTypes.customRender.index:
                return GeneralHelper.propertyTypes.customRender.key
            case GeneralHelper.propertyTypes.double.index:
                return GeneralHelper.propertyTypes.double.key
            default:
                return GeneralHelper.propertyTypes.string.key
        }
    }

    static getObjectValues = <T>(obj: {[key: string]: T}): T[] =>
        Object.keys(obj).map((key: string) => obj[key])

    static isSortAscending = (order?: string): boolean =>
        GeneralHelper.isSorted(order) ? order === appConstants.sort.ASCENDING : false

    static isSortDescending = (order?: string): boolean =>
        GeneralHelper.isSorted(order) ? order === appConstants.sort.DESCENDING : false

    static isSorted = (order?: string) => order !== appConstants.sort.NONE

    static createQueryFilters = (filters: {[key: string]: string}, groupBy: string | undefined): TQueryFilter[] => {
        return Object.keys(filters).reduce((acc: TQueryFilter[], key: string) =>
            filters[key] === '' ? acc : [...acc, {
                property: key,
                value: filters[key],
                isExact: key === groupBy
            }], [])
    }

    static prepareTabFormGroups = (properties: TPropertyValue[]) => {
        const tabFormGroups: TTabFormGroup = {}
        
        properties.forEach(prop => {
            if (prop.groupName) {
                if (tabFormGroups[prop.groupName]) {
                    tabFormGroups[prop.groupName].push(prop)
                } else {
                    tabFormGroups[prop.groupName] = [prop]
                }
            }
        })

        return tabFormGroups
    }

    static buildGroupingRequestFromTableState = (params: TTableFetchState) : TGroupParams => {
        return {
            groupBy: params.global.groupBy!,
            descending: params.global.descending,
            filters: GeneralHelper.createQueryFilters(params.global.filters ?? {}, params.global.groupBy),
            pageNumber: params.global.page,
            pageSize: params.global.size
        }
    }

    static buildGroupDataRequestFromTableState = (params: TTableFetchState, groupName: string) : TFetchParamsRequest => {
        const filters = {
            ...params.global.filters,
            [params.global.groupBy!]: groupName
        }

        const queryFilters = params.global.filters ? GeneralHelper.createQueryFilters(filters, params.global.groupBy) : undefined
        const pageNumber = params.groups[groupName] ? params.groups[groupName].page : paginationConfig.firstPageIndex
        const pageSize = params.groups[groupName] ? params.groups[groupName].size : paginationConfig.rowsPerPage

        return {
            SortBy: params.global.sortBy,
            Descending: params.global.descending,
            Filters: queryFilters,
            PageNumber: pageNumber,
            PageSize: pageSize,
            GroupName: groupName,
            GroupedView: params.groupedView
        }
    }

    static buildRequestFromTableState = (params: TTableFetchState) : TFetchParamsRequest => {
        const queryFilters = params.global.filters ? GeneralHelper.createQueryFilters(params.global.filters, params.global.groupBy) : undefined

        return {
            SortBy: params.global.sortBy,
            Descending: params.global.descending,
            Filters: queryFilters,
            PageNumber: params.global.page,
            PageSize: params.global.size,
            GroupedView: params.groupedView
        }
    }

    static buildCameraRequestFromTableState = (ids: number[], params: TTableFetchState) : TCameraRequest => {
        const queryFilters = params.global.filters ? GeneralHelper.createQueryFilters(params.global.filters, params.global.groupBy) : undefined

        return {
            SortBy: params.global.sortBy,
            Descending: params.global.descending,
            Filters: queryFilters,
            PageNumber: params.global.page,
            PageSize: params.global.size,
            GroupedView: params.groupedView,
            ids: ids
        }
    }

    static buildCameraGroupDataRequestFromTableState = (ids: number[], params: TTableFetchState, groupName: string) : TCameraRequest => {
        const filters = {
            ...params.global.filters,
            [params.global.groupBy!]: groupName
        }

        const queryFilters = params.global.filters ? GeneralHelper.createQueryFilters(filters, params.global.groupBy) : undefined
        const pageNumber = params.groups[groupName] ? params.groups[groupName].page : paginationConfig.firstPageIndex
        const pageSize = params.groups[groupName] ? params.groups[groupName].size : paginationConfig.rowsPerPage

        return {
            SortBy: params.global.sortBy,
            Descending: params.global.descending,
            Filters: queryFilters,
            PageNumber: pageNumber,
            PageSize: pageSize,
            GroupName: groupName,
            GroupedView: params.groupedView,
            ids: ids,
        }
    }

    static transformPropertyValues = (ids: number[], properties: TPropertyValue[]) : TPropertyChange[] => {
        return properties.map(propVal => ({
            entityIds: ids,
            propertyName: propVal.name,
            propertyType: propVal.propertyType,
            boolValue: propVal.boolValue,
            stringValue: propVal.stringValue,
            longValue: propVal.longValue,
            doubleValue: propVal.doubleValue,
            dateTimeValue: propVal.dateTimeValue,
            updateCollectionValues:  (propVal.collectionValue && propVal.collectionValue.length > 0) ? propVal.collectionValue?.split(',') : []
        }))
    }
}

export default GeneralHelper