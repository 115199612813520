import { EDataSyncItems } from 'enums/enums'
import { useEffect } from 'react'
import { backgroundService } from 'services/backgroundService'

const useBackgroundService = (syncItem: EDataSyncItems | EDataSyncItems[], callback: () => void, identifier: string) => {
    useEffect(() => {
        backgroundService.registerWithSyncItem(syncItem, callback, identifier)
        callback()
    }, [callback, identifier, syncItem])

    useEffect(() => {
        return () => {
            backgroundService.unregister(identifier)
        }
    }, [identifier])

    const toggleDisabled = (id: string) => {
        backgroundService.toggleDisabled(id)
    }

    const setDisabled = (id: string, value: boolean) => {
        backgroundService.setDisabled(id, value)
    }

    return {
        toggleDisabled,
        setDisabled
    }
}

export default useBackgroundService