import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TTableFetchState } from 'types/network'
import CameraFirmwares from './CameraFirmwares'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { TModalProps } from 'types/ui/modal'
import { deleteCameraFirmwaresAsync, getCameraFirmwaresAsync } from 'state/slices/cameraFirmwareSlice'
import CameraFirmwaresTablePreparer from 'views/Control/CameraFirmwares/CameraFirmwaresTablePreparer'
import { tableInfo } from 'views/Control/CameraFirmwares/CameraFirmwaresTableColumns'

import useBreadCrumbs from 'hooks/useBreadCrumbs'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const CameraFirmwaresContainer = () => {
    const dispatch = useAppDispatch()
    const { cameraFirmwares, totalNumber } = useAppSelector(state => state.cameraFirmware)

    useBreadCrumbs([i18nKeys.breadcrumb_camera_firmware])
    
    const getTableData = useCallback((params: TTableFetchState) => {
        const requestParams = GeneralHelper.buildRequestFromTableState(params)
        dispatch(getCameraFirmwaresAsync(requestParams))
    }, [dispatch])

    const data = CameraFirmwaresTablePreparer.prepareCameraFirmwaresRows(cameraFirmwares)
    
    const deleteCameraFirmwares = (params: TModalProps) => {
        dispatch(deleteCameraFirmwaresAsync(params.ids as number[]))
    }

    return (
        <ViewContainer>
            <CameraFirmwares
                data={data}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                getTableData={getTableData}
                deleteCameraFirmwares={(params: TModalProps) => deleteCameraFirmwares(params)}
            />
        </ViewContainer>
    )
}

export default CameraFirmwaresContainer