import { Icons } from 'components/Icon/Icon'
import { EPerformanceState } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import colors from 'style/colors'
import { TTranslationKey } from 'types/ui/translation'

type TPerformanceStateInfos = {
    color: string
    translation: TTranslationKey
    icon: string
}

const PerformanceStateInfos : {[key:number] : TPerformanceStateInfos} = {
    [EPerformanceState.HEALTHY] : {
        color: colors.extra.SUCCESS,
        translation: i18nKeys.site_systems_chip_healthy,
        icon: Icons.OK
    },
    [EPerformanceState.WARNING] : {
        color: colors.extra.WARNING,
        translation: i18nKeys.site_systems_chip_warning,
        icon: Icons.WARNING
    },
    [EPerformanceState.CRITICAL] : {
        color: colors.extra.ALERT,
        translation: i18nKeys.site_systems_chip_critical,
        icon: Icons.ERROR
    },
    [EPerformanceState.OFFLINE] : {
        color: colors.extra.EXTRA_GRAY,
        translation: i18nKeys.site_systems_chip_offline,
        icon: Icons.WIFI_OFF
    },
    [EPerformanceState.IGNORED] : {
        color: colors.extra.BLUE,
        translation: i18nKeys.site_systems_chip_ignore,
        icon: Icons.EYE_HIDE
    }
}

export const getPerformanceStateInfos = (state: EPerformanceState, isSiteIgnored?: boolean) : TPerformanceStateInfos => {
    if (isSiteIgnored) {
        return PerformanceStateInfos[EPerformanceState.IGNORED]
    }

    return PerformanceStateInfos[state]
}