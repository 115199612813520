import { TChartEntry, ChartLegend, CustomPieChart, CardHeader, PieChartPositioner } from 'components/CustomControls/CustomCharts'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { EDataSyncItems, EPerformanceState } from 'enums/enums'
import useBackgroundService from 'hooks/useBackgroundService'
import { i18nKeys } from 'i18n/keys'
import React, { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { getCamerasPerformance } from 'state/slices/statisticSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import colors from 'style/colors'
import { TOverallState } from 'types/businessLogic/performance'
import { uid } from 'uid'

const CamerasElement = () => {
    const cameraStates = useAppSelector(store => store.statistic.camerasPerformance)
    const dispatch = useAppDispatch()
    const intl = useIntl()
    const [identifier] = useState(uid())
    
    const loadData = useCallback(() => dispatch(getCamerasPerformance()), [dispatch])

    useBackgroundService(EDataSyncItems.CAMERA_PERFORMANCE, loadData, identifier)

    const prepareData = (data: TOverallState): TChartEntry[] => {
        return [
            {
                id: 'CamerasHealthy',
                name: i18nKeys.cameras_chart_healthy,
                value: data.healthy,
                color: colors.extra.SUCCESS,
                redirectTo: '/app/monitor/cameras/' + EPerformanceState.HEALTHY
            },
            {
                id: 'CamerasWarning',
                value: data.warning,
                name: i18nKeys.cameras_chart_warning,
                color: colors.extra.WARNING,
                redirectTo: '/app/monitor/cameras/' + EPerformanceState.WARNING
            },
            {
                id: 'CamerasCritical',
                value: data.critical,
                name: i18nKeys.cameras_chart_critical,
                color: colors.extra.ALERT,
                redirectTo: '/app/monitor/cameras/' + EPerformanceState.CRITICAL
            },
            {
                id: 'CamerasOffline',
                value: data.offline,
                name: i18nKeys.cameras_chart_offline,
                color: colors.shades.DARK_GRAY_SHADE,
                redirectTo: '/app/monitor/cameras/' + EPerformanceState.OFFLINE
            },
            {
                id: 'CamerasIgnore',
                value: data.ignored,
                name: i18nKeys.cameras_chart_ignore,
                color: colors.brandColors.BLUE,
                redirectTo: '/app/monitor/cameras/' + EPerformanceState.IGNORED
            }
        ]
    }

    const chartData = prepareData(cameraStates)
    
    return (
        <>
            <CardHeader title={i18nKeys.cameras_chart_heading} />
            <CustomTooltip title={intl.formatMessage(i18nKeys.cameras_chart_heading)}>
                <>
                    <PieChartPositioner>
                        <CustomPieChart
                            tooltip={intl.formatMessage(i18nKeys.cameras_chart_tooltip)}
                            data={chartData}
                            chartInnerDesc={i18nKeys.cameras_chart_total}
                            chartInnerNumber={(cameraStates.healthy + cameraStates.warning + cameraStates.critical + cameraStates.offline + cameraStates.ignored)}
                        />
                    </PieChartPositioner>
                    <ChartLegend data={chartData} />
                </>
            </CustomTooltip>
        </>
    )
}

export default CamerasElement