import DateHelper from 'helpers/DateHelper'
import TransMessage from 'components/TransMessage/TransMessage'
import { EProfileRights } from 'enums/enums'
import useAuthorization from 'hooks/useAuthorization'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TSite, TSiteMin } from 'types/businessLogic/site'
import SiteHelper from 'helpers/SiteHelper'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Validation from 'helpers/Validation'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'state/store'
import { updateSiteAsync } from 'state/slices/siteSlice'
import StatusDot from 'components/StatusDot/StatusDot'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormGroup, FormInput, FormLabel, FormWrapper, FormFooter, InputWrapper } from 'components/FormControls/FormControls'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Icons } from 'components/Icon/Icon'
import LabelsHelper from 'helpers/LabelsHelper'
import CustomIconButton from 'components/CustomIconButton/CustomIconButton'
import { FormAsyncCreatableSelect } from 'components/FormControls/FormAsyncCreatableSelect'
import { debounce } from '@mui/material'
import { TSelectOptions } from 'types/ui/dropdown'

type TProps = {
    site: TSite
    onSubmit?: () => void
}

type TSiteDetailsForm = {
    labels: TSelectOptions
    name: string
    location: string
}

const MachineCodeLabel = styled(FormLabel)`
    text-overflow: ellipsis;
    overflow: hidden;
`
const IconContainer = styled.div`
    margin-left: 20px;
`

const DetailsTab = (props: TProps) => {
    const {site, onSubmit} = props

    const dispatch = useDispatch()
    const pluginTypes = useAppSelector(state => state.pluginTypes.pluginTypes)
    const pluginType = SiteHelper.findPluginType(pluginTypes, site.type)
    const pluginTypeString = (pluginType && pluginType.translationKey) ? <TransMessage {...i18nKeys[pluginType.translationKey]} /> : site.type

    const formSubmit = (values: TSiteDetailsForm) => {
        const name = values.name
        const labels = values.labels.map(option => option.value.toString())
        const location = Validation.isEmpty(values.location) ? '' : values.location

        const siteToUpdate: TSiteMin = {
            id: site.id,
            name,
            labels,
            location
        }

        dispatch(updateSiteAsync([siteToUpdate]))

        onSubmit && onSubmit()
    }

    const copyMachineCode = () => {
        navigator.clipboard
            .writeText(site.machineCode)
            .then(() => {
            // ignore callback
            })

    }

    const authorize = useAuthorization()
    const isSiteAdmin = authorize(EProfileRights.IS_SITE_ADMIN)

    const prepareInitialValues = (value: TSite) : TSiteDetailsForm => {
        return {
            labels: value.labels.map(label => {
                return {
                    label: label,
                    value: label
                }
            }),
            name: value.name,
            location: value.location
        }
    }

    const [initialValues] = useState<TSiteDetailsForm>(prepareInitialValues(site))

    const intl = useIntl()

    const validationSchema = yup.object({
        name: yup.string().required(intl.formatMessage(i18nKeys.site_details_name_error_message)),
    })

    const { handleSubmit, formState, reset, control } = useForm<TSiteDetailsForm>({
        mode: 'onChange',
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema)
    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            LabelsHelper.loadLabels(inputText).then((options) => callback(options))
        }, 500), [])

    const { isValid, isValidating, isSubmitting, isDirty } = formState

    return (
        <FormWrapper onSubmit={handleSubmit(formSubmit)}>
            <FormContent>
                <FormGroup>
                    <InputWrapper>
                        <FormLabel htmlFor='name'>
                            <TransMessage {...i18nKeys.site_details_username_label} />
                        </FormLabel>
                        <FormInput
                            name='name'
                            control={control}
                            disabled={!isSiteAdmin}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='labels'>
                            <TransMessage {...i18nKeys.site_details_label_label} />
                        </FormLabel>
                        <FormAsyncCreatableSelect
                            name='labels'
                            control={control}
                            isMulti
                            loadOptions={loadOptions}
                            defaultOptions
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='location'>
                            <TransMessage {...i18nKeys.site_details_location_label} />
                        </FormLabel>
                        <FormInput
                            name='location'
                            control={control}
                        />
                    </InputWrapper>

                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_details_host_label} />
                        </FormLabel>
                        <FormLabel>
                            {site.host || ''}
                        </FormLabel>
                    </InputWrapper>

                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_details_ip_label} />
                        </FormLabel>
                        <FormLabel>
                            {site.ip}
                        </FormLabel>
                    </InputWrapper>
                        
                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_details_type_label} />
                        </FormLabel>
                        <FormLabel>
                            {pluginTypeString}
                        </FormLabel>
                    </InputWrapper>

                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_details_state_label} />
                        </FormLabel>
                        <FormLabel>
                            <StatusDot
                                color={SiteHelper.getSiteStateColor(site.state)}
                                textKey={SiteHelper.getSiteStateIntlKey(site.state)}
                            />
                        </FormLabel>
                    </InputWrapper>

                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_details_last_heartbeat_label} />
                        </FormLabel>
                        <FormLabel>
                            {DateHelper.formatDate(site.lastHeartBeat, DateHelper.formats.DATE_TIME_SECONDARY)}
                        </FormLabel>
                    </InputWrapper>

                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_details_vms_server_version_label} />
                        </FormLabel>
                        <FormLabel>
                            {site.vmsServerVersion}
                        </FormLabel>
                    </InputWrapper>

                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_details_vms_client_version_label} />
                        </FormLabel>
                        <FormLabel>
                            {site.vmsClientVersion}
                        </FormLabel>
                    </InputWrapper>

                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_details_version_label} />
                        </FormLabel>
                        <FormLabel>
                            {site.version}
                        </FormLabel>
                    </InputWrapper>
                        
                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_details_vms_server_id_label} />
                        </FormLabel>
                        <FormLabel>
                            {site.vmsServerId}
                        </FormLabel>
                    </InputWrapper>
                          
                    <InputWrapper>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_details_machine_code_label} />
                        </FormLabel>
                        <MachineCodeLabel>
                            {site.machineCode}
                        </MachineCodeLabel>
                        <IconContainer>
                            {
                                site.machineCode !== 'Unknown' &&
                                <CustomIconButton id='siteDetailsCopy' icon={Icons.COPY} onClick={() => copyMachineCode()} />
                            }
                        </IconContainer>
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            {
                isSiteAdmin &&
                    <FormFooter>
                        <FormButtonGroup>
                            <CustomButton
                                disabled={!isValid || isValidating || isSubmitting || !isDirty}
                                id='submitBtn'
                                type='submit'
                            >
                                <TransMessage {...i18nKeys.site_details_update_button} />
                            </CustomButton>
                            <CustomButton
                                id='resetBtn'
                                type='reset'
                                onClick={() => reset()}
                                $secondary
                            >
                                <TransMessage {...i18nKeys.site_details_reset_all_button} />
                            </CustomButton>
                        </FormButtonGroup>
                    </FormFooter>
            }

        </FormWrapper>
    )
}

export default DetailsTab