import SiteHelper from 'helpers/SiteHelper'
import { TSite } from 'types/businessLogic/site'
import { renderToString } from 'react-dom/server'
import React from 'react'
import styled from 'styled-components'
import fonts from 'style/fonts'
import 'react-leaflet-markercluster/dist/styles.min.css'
import 'leaflet/dist/leaflet.css'
import colors from 'style/colors'

export type TClusterType = {
    getChildCount(): number
    getAllChildMarkers(): any[]
}

const colorWeight : {[key: string] : number} = {
    [colors.shades.DARK_GRAY_SHADE] : 0,
    [colors.brandColors.BLUE] : 1,
    [colors.extra.SUCCESS] : 2,
    [colors.extra.WARNING] : 3,
    [colors.extra.ALERT] : 4
}

const getClusterColors = (cluster: TClusterType) => {
    const x = cluster.getAllChildMarkers()
    const gateways: TSite[] = x.map(
        (marker: any) => {
            return marker.options.gateway
        })

    const colorValues: string[] = []

    gateways.forEach(gateway => {
        const newColor = SiteHelper.getSiteMapClusterColor(gateway.state)
        if (!colorValues.includes(newColor)) {
            colorValues.push(newColor)
        }
    })

    return colorValues.sort((a, b) => {
        return colorWeight[a] - colorWeight[b]
    })
}

const pullOutColor = (values: Array<string>) => values.pop() || 'transparent'

const BaseCluster = styled.div<{$size: number}>`
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    height: ${p => p.$size}px !important;
    width: ${p => p.$size}px !important;
`

const Text = styled.span`
    color: white;
    font-family: ${fonts.PRIMARY_BOLD};
    font-size: 10px;
`

const Cluster = (props: {cluster: TClusterType}) => {
    const clusterColors = getClusterColors(props.cluster)
    const size = (clusterColors.length || 1) * 10
    
    const buildCluster = (clusterSize: number) => {
        if (clusterSize === 0) {
            return <Text> {props.cluster.getChildCount()} </Text>
        }

        return (
            <BaseCluster $size={clusterSize + 10} style={{ backgroundColor: pullOutColor(clusterColors)}}>
                {
                    buildCluster(clusterSize - 10)
                }
            </BaseCluster>
        )
    }

    return (
        buildCluster(size)
    )
}

export const buildCluster = (cluster: TClusterType) => {
    return {
        className: 'cluster',
        html: renderToString(<Cluster cluster={cluster} />)
    }
}