import LiveUpdateToggleButton from 'components/LiveUpdateToggleButton/LiveUpdateToggleButton'
import AdvancedTable from 'components/Tables/AdvancedTable'
import Toolbar from 'components/Toolbar/Toolbar'
import { EDataSyncItems, EProfileRights } from 'enums/enums'
import useBackgroundService from 'hooks/useBackgroundService'
import useColumnsHandler from 'hooks/useColumnsHandler'
import useEnhanceTable from 'hooks/useEnhanceTable'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { TTableFetchState } from 'types/network'
import { TTableInfo, TTableRowData } from 'types/ui/table'
import { uid } from 'uid'
import { i18nKeys } from 'i18n/keys'
import DeleteButton from 'components/Tables/DeleteButton'
import TransMessage from 'components/TransMessage/TransMessage'
import useAuthorization from 'hooks/useAuthorization'
import { TModalProps } from 'types/ui/modal'
import QuickActionMenu from 'components/QuickActionMenu/QuickActionMenu'
import QuickActionMenuItem from 'components/QuickActionMenu/QuickActionMenuItem'
import { VpnKey } from '@mui/icons-material'
import SelectionBox from 'components/Tables/SelectionBox'
import TableHelper from 'components/Tables/TableHelper'

const ViewWrapper = styled.div`
    padding: 0 25px 0 25px;
    background-color: #f4f4f4;
`

type TProps = {
    data: TTableRowData[]
    totalCount: number
    tableInfo: TTableInfo
    getTableData: (params: TTableFetchState) => void
    onDeleteApiKeys: (params: TModalProps) => void
    onCreateApiKey: () => void
}

const ApiKeys = (props: TProps) => {
    const { tableInfo, data, totalCount, getTableData } = props
    const [identifier] = useState(uid())
    const colHandlerProps = useColumnsHandler(tableInfo)
    const [autoupdatePaused, setAutoupdatePaused] = useState(false)

    const enhanceTableProps = useEnhanceTable({
        groups : undefined
    })

    const loadData = useCallback(() => {
        getTableData(enhanceTableProps.fetchState)
    }, [enhanceTableProps.fetchState, getTableData])

    const { toggleDisabled, setDisabled } = useBackgroundService(EDataSyncItems.API_KEYS, loadData, identifier)

    useEffect(() => {
        const hasSelectedRows = enhanceTableProps.selectionCount > 0
        setDisabled(identifier, hasSelectedRows)
        setAutoupdatePaused(hasSelectedRows)
    }, [enhanceTableProps.selectionCount, identifier, setDisabled])

    const disableToolbarButtons = !enhanceTableProps.selectionCount

    const rowCount = TableHelper.countDisplayedRows(data, !!enhanceTableProps.fetchState.global.groupBy, undefined)
    const totalSelectedRows = enhanceTableProps.selectAll ? totalCount : enhanceTableProps.selectionCount

    const authorize = useAuthorization()
    const isAuthorized = authorize(EProfileRights.IS_UMBRELLA_ADMIN)

    const buildModalProps = useCallback(() : TModalProps => {
        return {
            ids: enhanceTableProps.getSelectedIds() as number[],
            filters: enhanceTableProps.getSelectAllFilters()
        }
    }, [enhanceTableProps])

    const onSitesDelete = useCallback(() => {
        props.onDeleteApiKeys(buildModalProps())
        enhanceTableProps.deselectAllRows()
    }, [buildModalProps, enhanceTableProps, props])

    const onCreate = () => {
        props.onCreateApiKey()
    }

    return (
        <ViewWrapper>
            <Toolbar
                LeftContent={
                    <>
                        {
                            isAuthorized && (
                                <SelectionBox
                                    id='selectedSitesCheckbox'
                                    rowCount={enhanceTableProps.selectAll ? totalSelectedRows : rowCount}
                                    onAllRowsSelect={checked => enhanceTableProps.onAllRowsSelect(props.data, checked)}
                                    messageKey={i18nKeys.site_list_toolbar_selected_sites}
                                    totalSelectedRows={totalSelectedRows}
                                    numSelectedRows={enhanceTableProps.selectAll ? totalSelectedRows : enhanceTableProps.selectionCount}
                                    disabled={enhanceTableProps.selectAll}
                                />
                            )
                        }
                        {
                            isAuthorized && (
                                <>
                                    <DeleteButton
                                        id='deleteApiKeysBtn'
                                        title={<TransMessage {...i18nKeys.apiKey_delete_tooltip} />}
                                        disabled={disableToolbarButtons}
                                        onClick={onSitesDelete}
                                    />
                                </>
                            )
                        
                        }
                    </>
                }
                RightContent={
                    <LiveUpdateToggleButton onClick={() => toggleDisabled(identifier)} isToggled={autoupdatePaused} />
                }
            />

            {
                isAuthorized && (
                    <QuickActionMenu>
                        <QuickActionMenuItem id='quickActioCreateApiKey' icon={<VpnKey />} textKey={i18nKeys.apiKeys_create} onClick={onCreate} />
                    </QuickActionMenu>
                )
                
            }

            <AdvancedTable
                {...colHandlerProps}
                {...enhanceTableProps}
                count={totalCount}
                rows={data}
            />
        </ViewWrapper>
    )
}
export default ApiKeys
