import React from 'react'
import GeneralHelper from 'helpers/GeneralHelper'
import { TCheckLicense } from 'types/businessLogic/site'
import { TTableRowData } from 'types/ui/table'
import { tableInfo } from 'views/Sites/siteTableColumns'

class SiteLicensesTablePreparer {
    public static prepareSiteLicensesRows = (sites: TCheckLicense[]): TTableRowData[] =>
        sites.map((site, index) => ({
            id: `${site.name}${index}`,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.name.key,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    readOnly: false,
                    [GeneralHelper.propertyTypes.string.key]: site.name
                },
                {
                    name: tableInfo.columnConfig.labels.key,
                    translationKey: tableInfo.columnConfig.labels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: site.labels
                }
            ]
        }))
}

export default SiteLicensesTablePreparer