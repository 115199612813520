import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { siteCameraService } from 'services/siteCameraService'
import { showMessage } from 'state/slices/uiSlice'
import { TSiteEntity, TSiteEntityUpdate } from 'types/businessLogic/site'
import { TCameraRequest } from 'types/network'
import { TTableGroup, TTableColumnProperty } from 'types/ui/table'

export type TSiteCameraState = {
    groups: TTableGroup<TSiteEntity>[]
    siteCameras: TSiteEntity[]
    totalNumber: number
    properties: TTableColumnProperty[]
}

const initialState : TSiteCameraState = {
    groups: [],
    siteCameras: [],
    totalNumber: 0,
    properties: []
}

export const getSiteCamerasAsync = createAsyncThunk('siteCameras/getSiteCamerasAsync', async (params: TCameraRequest, thunkApi) => {
    try {
        return await siteCameraService.getSiteCameras(params)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getSiteCamerasPropertiesAsync = createAsyncThunk('siteCameras/getSiteCamerasPropertiesAsync', async (_, thunkApi) => {
    try {
        return await siteCameraService.getSiteEntitiesProperties()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getGroupDataAsync = createAsyncThunk('siteCameras/getGroupDataAsync', async (params: TCameraRequest & { translationKey?: string}, thunkApi) : Promise<TTableGroup<TSiteEntity>> => {
    try {
        const result = await siteCameraService.getSiteCameras(params)
        return {
            name: params.GroupName!,
            translationKey: params.translationKey,
            count: result.totalNumber,
            data: result.siteEntities
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const updateSiteCameraAsync = createAsyncThunk('siteCameras/updateSiteCameraAsync', async (params: TSiteEntityUpdate, thunkApi) => {
    try {
        await siteCameraService.updateSiteEntity(params.id, params.labels)
        thunkApi.dispatch(showMessage({message: i18nKeys.camera_details_update_success_message, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.camera_details_update_error_message, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'siteCameras',
    initialState: initialState,
    reducers: {
        clearGroups: (state, action : PayloadAction<{ totalNumber: number, groups: TTableGroup<TSiteEntity>[] }>) => {
            action.payload.groups.forEach(item => {
                const oldItem = state.groups.find(x => x.name === item.name)
                if (oldItem) {
                    item.data = oldItem.data
                }
            })

            state.groups = action.payload.groups
            state.totalNumber = action.payload.totalNumber
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSiteCamerasAsync.fulfilled, (state, action) => {
                const { siteEntities, totalNumber } = action.payload
                state.siteCameras = siteEntities
                state.totalNumber = totalNumber
            })
            .addCase(getSiteCamerasPropertiesAsync.fulfilled, (state, action) => {
                state.properties = action.payload
            })
            .addCase(getGroupDataAsync.fulfilled, (state, action) => {
                const existingGroup = state.groups.find(p => p.name === action.payload.name)
                if (existingGroup) {
                    existingGroup.data = action.payload.data
                    existingGroup.count = action.payload.count
                } else {
                    state.groups.push(action.payload)
                }
                
                action.payload.data?.forEach(x => {
                    const index = state.siteCameras.findIndex(y => y.id === x.id)
                    if (index === -1) {
                        state.siteCameras.push(x)
                    } else {
                        state.siteCameras[index] = x
                    }
                })
            })
    }
})

export const {clearGroups} = slice.actions

export default slice.reducer