import { yupResolver } from '@hookform/resolvers/yup'
import AdvancedJobConfigFields from 'components/AdvancedJobConfigFields/AdvancedJobConfigFields'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormWrapper, FormContent, FormInput, FormLabel, InputWrapper, FormButtonGroup, FormFooter, FormGroup } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TModalProps } from 'types/ui/modal'
import * as yup from 'yup'
import { TChangeAllCameraPasswordJobRequest, TChangeCameraPasswordJobRequest, TJobRequest } from 'types/businessLogic/job'
import { siteCameraService } from 'services/siteCameraService'
import { useIntl } from 'react-intl'
import { TUploadManagerItem } from 'types/ui/uploadManager'
import { pushItem, setDoneOrErrorOnItem } from 'state/slices/uploadManagerSlice'
import { createUploadManagerItemForJob } from 'components/UploadManager/UploadManager'
import { useModal } from 'hooks/modalProvider'

type TChangeCameraPasswordJobForm = {
    password: string
    advancedJobConfig: TJobRequest
    comment?: string
}

type ChangePasswordModalProps = TModalProps & {
    totalCount: number
}

const ChangePassword = (props: ChangePasswordModalProps) => {
    const {ids, filters, totalCount} = props
    const isCommentMandatory = useAppSelector(state => state.ui.uiSettings.isCommentMandatoryForJobs)
    const isSelectAll = !!filters
    
    const intl = useIntl()
    const {closeModal} = useModal()
    const dispatch = useAppDispatch()

    const handleChangeCameraPasswordSubmit = useCallback(async (formValues: TChangeCameraPasswordJobForm) : Promise<void> => {
        let uploadManagerItem : TUploadManagerItem
        
        try {
            if (isSelectAll) {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_change_camera_password, {count: totalCount})
                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const request : TChangeAllCameraPasswordJobRequest = {
                    ...formValues.advancedJobConfig,
                    password: formValues.password,
                    filters: filters,
                    comment: formValues.comment
                }

                dispatch(pushItem(uploadManagerItem))
                await siteCameraService.changeAllCameraPassword(request)
            } else {
                const uploadManagerItemText = intl.formatMessage(i18nKeys.upload_manager_job_async_change_camera_password,
                    {count: (ids as number[]).length})
                    
                uploadManagerItem = createUploadManagerItemForJob(uploadManagerItemText)

                const request : TChangeCameraPasswordJobRequest = {
                    ...formValues.advancedJobConfig,
                    password: formValues.password,
                    ids: ids as number[],
                    comment: formValues.comment
                }

                dispatch(pushItem(uploadManagerItem))
                await siteCameraService.changeCameraPassword(request)
            }

            dispatch(showMessage({message: i18nKeys.change_site_conneciton_user_job_created_success, type: EMessageType.SUCCESS}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem.id}))
        } catch (reason) {
            dispatch(showMessage({message: i18nKeys.change_site_conneciton_user_job_created_error, type: EMessageType.ERROR}))
            dispatch(setDoneOrErrorOnItem({ id: uploadManagerItem!.id, isError: true}))
        }

        closeModal()
    }, [dispatch, filters, ids, intl, isSelectAll, closeModal, totalCount])

    const defaultValues : TChangeCameraPasswordJobForm = {
        password: '',
        advancedJobConfig: {}
    }
    
    const validationSchema = (mandatoryComment: boolean) => (yup.object().shape({
        password: yup.string().required(intl.formatMessage(i18nKeys.general_validation_required_field)),
        comment: mandatoryComment ? yup.string().max(500).required(intl.formatMessage(i18nKeys.general_validation_required_field)) : yup.string().max(500)
    }))

    const { handleSubmit, formState, control, setValue} = useForm<TChangeCameraPasswordJobForm>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema(isCommentMandatory))
    })
    const { isValid, isValidating, isSubmitting, isDirty } = formState
    
    return (
        <FormWrapper onSubmit={handleSubmit(handleChangeCameraPasswordSubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_site_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='password'>
                            <TransMessage {...i18nKeys.change_camera_password_modal_input_password_label} />
                        </FormLabel>
                        <FormInput
                            name='password'
                            control={control}
                            type='password'
                        />
                    </InputWrapper>
                </FormGroup>
                <FormGroup collapsable title={i18nKeys.advanced_job_configuration_title}>
                    <AdvancedJobConfigFields
                        formProps={formState}
                        setFieldValue={setValue}
                        control={control}
                        hideUndoField
                    />
                </FormGroup>
                <FormGroup title={i18nKeys.job_configuration_comment_title}>
                    <InputWrapper>
                        <FormLabel htmlFor='comment'>
                            <TransMessage {...i18nKeys.job_configuration_comment} />
                        </FormLabel>
                        <FormInput
                            name='comment'
                            autoComplete='job-comment'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.change_password_modal_button} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}

export default ChangePassword