import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import CameraPerformanceService from 'services/cameraPerformanceService'
import SiteServicePerformanceService from 'services/siteServicePerformanceService'
import SiteSystemPerformanceService from 'services/siteSystemPerformanceService'
import StatisticService from 'services/statisticService'
import { showMessage } from 'state/slices/uiSlice'
import { TOverallState } from 'types/businessLogic/performance'
import { TStatisticRequest } from 'types/network'
import { TCoreCPU, TCoreMemory, TCoreRequestCount, TCoreRequestDuration, TSiteApprovals, TSiteStates } from 'types/statistic'

export type TStatisticState = {
    camerasPerformance: TOverallState
    siteStates: TSiteStates
    SiteServicesPerformance: TOverallState
    siteApprovals: TSiteApprovals
    SiteSystemsPerformance: TOverallState
    coreCpu: TCoreCPU[]
    coreMemory: TCoreMemory[]
    coreRequestDuration: TCoreRequestDuration[]
    coreRequestCount: TCoreRequestCount[]
}

const initialState: TStatisticState = {
    camerasPerformance: {
        critical: 0,
        healthy: 0,
        ignored: 0,
        offline: 0,
        warning: 0
    },
    SiteServicesPerformance: {
        critical: 0,
        healthy: 0,
        ignored: 0,
        offline: 0,
        warning: 0
    },
    SiteSystemsPerformance: {
        critical: 0,
        healthy: 0,
        ignored: 0,
        offline: 0,
        warning: 0
    },
    siteStates: {
        gatewayHealthyCount: 0,
        gatewayWarningCount: 0,
        gatewayOfflineCount: 0,
        gatewayVmsNotReachableCount: 0,
        gatewayUpdatingCount: 0,
        gatewayIgnoredCount: 0
    },
    siteApprovals: {
        gatewayApprovedCount: 0,
        gatewayUnapprovedCount: 0
    },
    coreCpu: [],
    coreMemory: [],
    coreRequestDuration:[],
    coreRequestCount: []
}

export const getSiteStatesAsync = createAsyncThunk('statistic/getSiteStatesAsync', async (_, thunkApi) => {
    try {
        return await StatisticService.getSiteStates()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getSiteApprovalsAsync = createAsyncThunk('statistic/getSiteApprovalsAsync', async (_, thunkApi) => {
    try {
        return await StatisticService.getSiteApprovals()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getCoreCPUAsync = createAsyncThunk('statistic/getCoreCPUAsync', async (param: TStatisticRequest, thunkApi) => {
    try {
        return await StatisticService.getCoreCPU(param)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getCoreMemoryAsync = createAsyncThunk('statistic/getCoreMemoryAsync', async (param: TStatisticRequest, thunkApi) => {
    try {
        return await StatisticService.getCoreMemory(param)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getCoreRequestDurationAsync = createAsyncThunk('statistic/getCoreRequestDurationAsync', async (param: TStatisticRequest, thunkApi) => {
    try {
        return await StatisticService.getCoreRequestDuration(param)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getCoreRequestCountAsync = createAsyncThunk('statistic/getCoreRequestCountAsync', async (param: TStatisticRequest, thunkApi) => {
    try {
        return await StatisticService.getCoreRequestCount(param)
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getSiteServicesPerformance = createAsyncThunk('statistic/getSiteServicesPerformance', async (_, thunkApi) => {
    try {
        return await SiteServicePerformanceService.getOverallStates()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getCamerasPerformance = createAsyncThunk('statistic/getCamerasPerformance', async (_, thunkApi) => {
    try {
        return await CameraPerformanceService.getOverallStates()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const getSiteSystemsPerformance = createAsyncThunk('statistic/getSiteSystemsPerformance', async (_, thunkApi) => {
    try {
        return await SiteSystemPerformanceService.getOverallStates()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'statistic',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCamerasPerformance.fulfilled, (state, action) => {
            Object.assign(state.camerasPerformance, action.payload)
        })
        builder.addCase(getSiteStatesAsync.fulfilled, (state, action) => {
            Object.assign(state.siteStates, action.payload)
        })
        builder.addCase(getSiteServicesPerformance.fulfilled, (state, action) => {
            Object.assign(state.SiteServicesPerformance, action.payload)
        })
        builder.addCase(getSiteSystemsPerformance.fulfilled, (state, action) => {
            Object.assign(state.SiteSystemsPerformance, action.payload)
        })
        builder.addCase(getSiteApprovalsAsync.fulfilled, (state, action) => {
            Object.assign(state.siteApprovals, action.payload)
        })
        builder.addCase(getCoreCPUAsync.fulfilled, (state, action) => {
            state.coreCpu = action.payload.performanceMeasures
        })
        builder.addCase(getCoreMemoryAsync.fulfilled, (state, action) => {
            state.coreMemory = action.payload.performanceMeasures
        })
        builder.addCase(getCoreRequestDurationAsync.fulfilled, (state, action) => {
            state.coreRequestDuration = action.payload.performanceMeasures
        })
        builder.addCase(getCoreRequestCountAsync.fulfilled, (state, action) => {
            state.coreRequestCount = action.payload.performanceMeasures
        })
    }
})

export default slice.reducer