import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EEmailEncryptionMethod, EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import EMailService from 'services/emailService'
import { showMessage } from 'state/slices/uiSlice'
import { TEMailConfig } from 'types/businessLogic/misc'

export type TEMailState = TEMailConfig & {
    fetched: boolean
}

export const initialState : TEMailState = {
    fetched: false,
    host: '',
    port: 0,
    username: '',
    password: '',
    setPassword: false,
    sender: '',
    subject: '',
    encryption: EEmailEncryptionMethod.NONE
}

export const getEMailConfigAsync = createAsyncThunk('email/getEMailAsync', async (_, thunkApi) => {
    try {
        return await EMailService.getEMailConfig()
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }

})

export const postEMailConfigAsync = createAsyncThunk('email/postEMailConfigAsync', async (params: TEMailConfig, thunkApi) => {
    try {
        await EMailService.postEMailConfig(params)
        thunkApi.dispatch(showMessage({message: i18nKeys.email_configuration_update_success, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.email_configuration_update_error, type: EMessageType.ERROR}))
        throw err
    }
})

export const testEMailConfigAsync = createAsyncThunk('email/testEMailConfigAsync', async (params: TEMailConfig, thunkApi) => {
    try {
        await EMailService.testEMailConfig(params)
        thunkApi.dispatch(showMessage({message: i18nKeys.email_configuration_test_success, type: EMessageType.SUCCESS}))
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.email_configuration_test_error, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'email',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEMailConfigAsync.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
                state.fetched = true
            })
            .addCase(postEMailConfigAsync.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
                state.fetched = true
            })
    }
})

export default slice.reducer