import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TFetchParamsRequest } from 'types/network'
import UmbrellaUserService from 'services/umbrellaUserService'
import { TCreateUmbrellaUserRequest, TUmbrellaUser, TUpdateUmbrellaUserRequest } from 'types/businessLogic/user'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { showMessage } from 'state/slices/uiSlice'
import { dispatch } from 'state/store'
import { TTableGroup } from 'types/ui/table'

export type TUmbrellaUserState = {
    umbrellaUsers: TUmbrellaUser[]
    groups: TTableGroup<TUmbrellaUser>[]
    totalNumber: number
    builtInUserIds: string[]
}

const initialState : TUmbrellaUserState = {
    umbrellaUsers: [],
    groups: [],
    totalNumber: 0,
    builtInUserIds: []
}

export const getUmbrellaUsersAsync = createAsyncThunk('umbrellaUser/getUmbrellaUsersAsync', async (params: TFetchParamsRequest, thunkApi) => {
    try {
        const result = await UmbrellaUserService.getUsers(params)

        const builtInUserIds = result.users.reduce((acc: string[], user) => {
            if (user.builtIn) {
                acc.push(user.id)
            }
            return acc
        }, [])
    
        return {
            ...result,
            builtInUserIds
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

export const createUmbrellaUserAsync = createAsyncThunk('umbrellaUser/createUmbrellaUserAsync', async (params: TCreateUmbrellaUserRequest) => {
    try {
        await UmbrellaUserService.createUser(params)
        dispatch(showMessage({message: i18nKeys.create_umbrella_user_success_message, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        dispatch(showMessage({message: i18nKeys.create_umbrella_user_error_message, type: EMessageType.ERROR}))
    }
})

export const updateUmbrellaUserAsync = createAsyncThunk('umbrellaUser/updateUmbrellaUserAsync', async (params: TUpdateUmbrellaUserRequest) => {
    try {
        await UmbrellaUserService.updateUser(params.id, params)
        dispatch(showMessage({message: i18nKeys.site_user_details_user_update_success_message, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        dispatch(showMessage({message: i18nKeys.site_user_details_user_update_error_message, type: EMessageType.ERROR}))
    }
})

export const deleteUmbrellaUsersAsync = createAsyncThunk('umbrellaUser/deleteUmbrellaUsersAsync', async (params: string[]) => {
    try {
        await UmbrellaUserService.deleteUsers(params)
        dispatch(showMessage({message: i18nKeys.umbrella_users_list_delete_users_success, type: EMessageType.SUCCESS}))
        return params
    } catch (err) {
        dispatch(showMessage({message: i18nKeys.umbrella_users_list_delete_users_error, type: EMessageType.ERROR}))
    }
})

export const getGroupDataAsync = createAsyncThunk('umbrellaUser/getGroupDataAsync', async (params: TFetchParamsRequest, thunkApi) : Promise<TTableGroup<TUmbrellaUser>> => {
    try {
        const result = await UmbrellaUserService.getUsers(params)
        return {
            name: params.GroupName!,
            count: result.totalNumber,
            data: result.users
        }
    } catch (err) {
        thunkApi.dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        throw err
    }
})

const slice = createSlice({
    name: 'umbrellaUser',
    initialState: initialState,
    reducers: {
        clearGroups: (state, action : PayloadAction<{ totalNumber: number, groups: TTableGroup<TUmbrellaUser>[] }>) => {
            action.payload.groups.forEach(item => {
                const oldItem = state.groups.find(x => x.name === item.name)
                if (oldItem) {
                    item.data = oldItem.data
                }
            })

            state.groups = action.payload.groups
            state.totalNumber = action.payload.totalNumber
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUmbrellaUsersAsync.fulfilled, (state, action) => {
                const { users, totalNumber, builtInUserIds } = action.payload
                state.umbrellaUsers = users
                state.totalNumber = totalNumber
                state.builtInUserIds = builtInUserIds
            })
            .addCase(getGroupDataAsync.fulfilled, (state, action) => {
                const existingGroup = state.groups.find(p => p.name === action.payload.name)
                if (existingGroup) {
                    existingGroup.data = action.payload.data
                    existingGroup.count = action.payload.count
                } else {
                    state.groups.push(action.payload)
                }

                action.payload.data?.forEach(x => {
                    const index = state.umbrellaUsers.findIndex(y => y.id === x.id)
                    if (index === -1) {
                        state.umbrellaUsers.push(x)
                    } else {
                        state.umbrellaUsers[index] = x
                    }
                })
            })
    }
})

export const {clearGroups} = slice.actions

export default slice.reducer