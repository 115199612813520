import styled from 'styled-components'

interface Props {
    color?: string
    margin?: string
}

const IconWrapper = styled.div<Props>`
    display: grid;
    place-items: center;
    margin: ${p => p.margin};
    fill: ${p => p.color};
    height: 100%;
`

export default IconWrapper