import { debounce } from '@mui/material'
import { selectionBoxOptions } from 'components/Tables/SelectionBox'
import { paginationConfig } from 'hooks/useEnhanceTable'
import { EPerformanceState } from 'enums/enums'
import React, { useState } from 'react'
import { TCardPageSelectAllFilters, TGetCardPageRequest, TPerformanceItem, TPerformanceItemsPerSite } from 'types/businessLogic/performance'
import { TQueryFilter } from 'types/network'

export type TCardFilter = {
    [key: string]: string
}

export type TUseCardPage = {
    id?: string
    fetchState: TGetCardPageRequest
    selectedItems: TPerformanceItem[]
    selectionCount: number
    getSelectedIds: () => (number | string)[]
    selectAll: boolean
    getSelectAllFilters: () => TCardPageSelectAllFilters | undefined
    onAllSelect: (sites: TPerformanceItemsPerSite[], checked: boolean) => void
    deselectAll: () => void
    switchSelectionMode: (event: React.ChangeEvent<{name?: string, value: unknown}>, sites: TPerformanceItemsPerSite[]) => void
    onFilterChange: (property: string, value: string) => void
    onStateFiltersChange: (value: EPerformanceState) => void
    onItemSelect: (items: TPerformanceItem[], checked: boolean) => void
    onSiteSelect: (sites: TPerformanceItemsPerSite[], checked: boolean) => void
    onChangePage: (value: number) => void
    onChangeRowsPerPage: (value: number) => void
}

const useCardPage = (id?: string, preselectedState?: EPerformanceState[]) : TUseCardPage => {
    const [pageState, setPageState] = useState<TPerformanceItem[]>([])

    const [fetchState, setFetchState] = useState<TGetCardPageRequest>({
        filterByStates: preselectedState ? preselectedState : [],
        fetchParams: {
            PageNumber: paginationConfig.firstPageIndex,
            PageSize: paginationConfig.rowsPerPage,
            Filters: []
        }
    })

    const [selectAll, setSelectAll] = useState<boolean>(false)

    const getSelectedIds = () : (number | string)[] => {
        if (selectAll) {
            return []
        }
        return pageState.map(item => item.id)
    }
    
    const getSearchFilter = (): TQueryFilter[] | undefined => {
        return [...fetchState.fetchParams.Filters!]
    }

    const getSelectAllFilters = () : TCardPageSelectAllFilters | undefined => {
        if (!selectAll) return

        return {
            filterByStates: fetchState.filterByStates,
            filters: getSearchFilter()
        }
    }

    const deselectAll = () => {
        setPageState([])
    }

    const onFilterChange = debounce((property: string, value: string) => {
        setFetchState((prevState) => {
            const newState = {...prevState}
            
            newState.fetchParams.PageNumber = 1

            const index = newState.fetchParams.Filters?.findIndex(x => x.property === property) ?? -1
            if (index !== -1) {
                newState.fetchParams.Filters?.splice(index, 1)
            }

            if (value !== '') {
                newState.fetchParams.Filters = [...newState.fetchParams.Filters!, { property, value, isExact: false }]
            }

            return newState
        })
    }, 250)

    const onStateFiltersChange = (value: EPerformanceState) => {
        setFetchState((prevState) => {
            const newState = {...prevState}
            
            const index = prevState.filterByStates.indexOf(value)

            if (index === -1) {
                newState.filterByStates.push(value)
            } else {
                newState.filterByStates.splice(index, 1)
            }
            
            newState.fetchParams.PageNumber = 1
            return newState
        })
    }
    
    const onChangePage = (value: number) => {
        value++
        setFetchState((prevState) => {
            const newState = {...prevState}
            newState.fetchParams.PageNumber = value
            return newState
        })
    }

    const onChangeRowsPerPage = (value: number) => {
        setFetchState((prevState) => {
            const newState = {...prevState}
            newState.fetchParams.PageSize = value
            newState.fetchParams.PageNumber = 1
            return newState
        })
    }

    const onItemSelect = (entries: TPerformanceItem[], checked: boolean) => {
        setPageState((prevState) => {
            let selectedItems = [...prevState]
                        
            // First remove the incoming entries, if they should be added its the best way to get rid of duplicates
            selectedItems = selectedItems.filter(row => entries.some(entry => entry.id !== row.id || entry.type !== row.type))

            if (checked) {
                selectedItems = [...selectedItems, ...entries]
            }

            return selectedItems
        })
    }

    const onSiteSelect = (sites: TPerformanceItemsPerSite[], checked: boolean) => {
        if (sites.length > 1) {
            if (checked) {
                setPageState(sites.flatMap(site => site.items))
            } else {
                setPageState([])
            }
        } else {
            setPageState((prevState) => {
                let selectedItems = [...prevState]
                
                // First remove the incoming entries, if they should be added its the best way to get rid of duplicates
                selectedItems = selectedItems.filter(item => sites.some(site => site.gatewayId !== item.gatewayId))
    
                if (checked) {
                    const incomingItems = sites.flatMap(site => site.items)
                    selectedItems = [...selectedItems, ...incomingItems]
                }
    
                return selectedItems
            })
        }
    }

    const onAllSelect = (sites: TPerformanceItemsPerSite[], checked: boolean) => {
        if (selectAll) {
            onSiteSelect([], checked)
        } else {
            onSiteSelect(sites, checked)
        }
    }

    const switchSelectionMode = (event: React.ChangeEvent<{name?: string, value: unknown}>, itemsPerSite: TPerformanceItemsPerSite[]) => {
        const isSelectOverAllPagesActive = event.target.value === selectionBoxOptions.SELECT_ALL.value

        setSelectAll(isSelectOverAllPagesActive)
        onAllSelect(itemsPerSite, isSelectOverAllPagesActive)
    }

    return {
        id,
        fetchState,
        selectedItems: pageState,
        selectionCount: pageState.length,

        selectAll,
        getSelectAllFilters,
        onAllSelect,
        deselectAll,
        getSelectedIds,
        switchSelectionMode,

        onFilterChange,
        onStateFiltersChange,
        onItemSelect,
        onSiteSelect,
        onChangePage,
        onChangeRowsPerPage
    }
}

export default useCardPage
