import colors from 'style/colors'
import fonts from 'style/fonts'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import typographyScale from 'style/typographyScale'
import styled from 'styled-components'

export const SearchContainer = styled.div`
    fill: ${colors.shades.DEEP_SHADE};
    display: flex;
    flex-direction: column;
    margin-left: 16px;

    & > div:first-child {
        width: 254px;
        margin-left: 16px;
        color: ${colors.shades.DARK_GRAY_SHADE};
        text-transform: uppercase;
        font-family: ${fonts.PRIMARY_MEDIUM};
        font-size: ${typographyScale.TYPE12};
        line-height: ${lineHeights.TYPE12LineHeight};
        letter-spacing: ${letterSpacings.TYPE12LS};
    }

    div {
        height: 32px;
        display: flex;
        align-items: center;
    }
`
