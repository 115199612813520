import { EPropertyType, ERestrctionType } from 'enums/enums'
import { TPropertyValue, TRestrictionType } from 'types/businessLogic/site'
import { TSelectOption, TSelectOptions } from 'types/ui/dropdown'
import { TPropertyValueForm, TRestrictionTypeForm } from 'types/ui/tab'

class FormHelper {
    static removeEmptyFields = (data: Record<string, unknown>) => {
        Object.keys(data).forEach(key => {
            if (data[key] === '' || data[key] === null || data[key] === undefined) {
                delete data[key]
            }
        })
    }

    static extractValuesFromOptionTypeBase = <T extends string | number>(formValue: TSelectOptions) : T[] => formValue.map(x => x.value as T)

    static convertToPropertyValue = (properties: TPropertyValueForm[]) : TPropertyValue[] => {
        return properties.map(property => {
        
            let newCollectionValue : string | undefined = undefined
            if (property.propertyType === EPropertyType.COLLECTION && property.collectionValue) {
                newCollectionValue = property.collectionValue?.map(x => x.value as string).join(',')
            }

            let newStringValue : string | undefined = undefined

            if (property.restriction && property.propertyType !== EPropertyType.COLLECTION && property.restriction.restrictionType === ERestrctionType.COLLECTION) {
                newStringValue = (property.stringValue as TSelectOption).value.toString()
            } else {
                newStringValue = property.stringValue as string ?? undefined
            }

            let newRestrictionvalue : TRestrictionType | undefined = undefined

            if (property.restriction?.restrictionType === 1) {
                newRestrictionvalue = {
                    restrictionType: property.restriction.restrictionType,
                    min: property.restriction.min,
                    max: property.restriction.max,
                    collection: property.restriction.collection.map(x => x.value as string)
                }
            } else if (property.restriction?.restrictionType === 0) {
                newRestrictionvalue = {
                    restrictionType: property.restriction.restrictionType,
                    min: property.restriction.min,
                    max: property.restriction.max,
                    collection: []
                }
            }
            
            return {
                ...property,
                collectionValue: newCollectionValue,
                restriction: newRestrictionvalue,
                stringValue: newStringValue
            }
        })
    }

    static convertToPropertyValueForm = (properties: TPropertyValue[]) : TPropertyValueForm[] => {
        return properties.map(property => {
            let newCollectionValue : TSelectOptions | undefined = undefined
            if (property.propertyType === EPropertyType.COLLECTION && property.collectionValue) {
                
                if (property.collectionValue.length === 0) {
                    newCollectionValue = []
                } else {
                    newCollectionValue = property.collectionValue.split(',').map(x => {
                        return {
                            label: x,
                            value: x
                        }
                    })
                }
            }
    
            let newStringvalue : string | TSelectOption | undefined = property.stringValue
    
            if (property.restriction && property.propertyType !== EPropertyType.COLLECTION && property.restriction.restrictionType === ERestrctionType.COLLECTION) {
                newStringvalue = {
                    label: property.stringValue!,
                    value: property.stringValue!
                }
            }
            
            let newRestrictionvalue : TRestrictionTypeForm | undefined = undefined
    
            if (property.restriction?.restrictionType === 1) {
                newRestrictionvalue = {
                    restrictionType: property.restriction.restrictionType,
                    min: property.restriction.min,
                    max: property.restriction.max,
                    collection: property.restriction.collection.map(x => {
                        return {
                            value: x,
                            label: x
                        }
                    })
                }
            } else if (property.restriction?.restrictionType === 0) {
                newRestrictionvalue = {
                    restrictionType: property.restriction.restrictionType,
                    min: property.restriction.min,
                    max: property.restriction.max,
                    collection: []
                }
            }
    
            return {
                ...property,
                stringValue: newStringvalue,
                collectionValue: newCollectionValue,
                restriction: newRestrictionvalue
            }
        })
    }
}

export default FormHelper