import React, { PropsWithChildren } from 'react'
import { IntlConfig, IntlProvider } from 'react-intl'
import { useAppSelector } from 'state/store'

import { translations } from '../../i18n/translations'

const IntlProviderWrapper = (props: PropsWithChildren<Record<never, never>>) => {
    const { language } = useAppSelector(state => state.ui)

    const config: IntlConfig = {
        locale: language,
        defaultLocale: 'en',
        messages: translations[language]
    }

    return (
        <IntlProvider {...config}>
            {props.children}
        </IntlProvider>
    )
}

export default IntlProviderWrapper