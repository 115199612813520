import { OpenStreetMapProvider } from 'leaflet-geosearch'
import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider'
import { SearchArgument, SearchResult } from 'leaflet-geosearch/dist/providers/provider'
import { TCoordinate } from 'types/businessLogic/map'
import { TSiteMin } from 'types/businessLogic/site'

export type GeosearchProvider = {
    search(options: SearchArgument): Promise<SearchResult<RawResult>[]>
}

class MapHelper {
    public static readonly NOT_FOUND_LOCATIONS_KEY: string = 'NOT_FOUND_LOCATIONS'

    constructor(private _unknownLocations: string[], private _searchProvider: GeosearchProvider) {
        this.isRunning = false
    }

    private isRunning : boolean

    public async parseLocations(items: TSiteMin[], updateCallback: (sites: TSiteMin[]) => void) : Promise<void> {
        if (this.isRunning) {
            return
        }

        this.isRunning = true
        const sitesWithoutLocation = items.filter(site => (typeof site.latitude !== 'number' && typeof site.longitude !== 'number'))
        const locations = [...new Set(sitesWithoutLocation.map(item => item.location))]

        const coordinates: TCoordinate[] = []
        for (const location of locations) {
            const result = await this.getCoordinats(location)
            if (result) {
                coordinates.push(result)
            }
        }
        
        let result: TSiteMin[] = []
        sitesWithoutLocation.forEach(site => {
            const coords = coordinates.find(x => x?.location === site.location)
            if (coords) {
                const temp = {...site}
                temp.latitude = coords.latitude
                temp.longitude = coords.longitude
                result.push(temp)

                if (result.length === 10) {
                    updateCallback(result)
                    result = []
                }
            }
        })

        if (result.length > 0) {
            updateCallback(result)
        }

        this.isRunning = false
    }

    public async getCoordinats(location: string) : Promise<TCoordinate | undefined> {
        if (this._unknownLocations.includes(location)) {
            return
        }

        const result = await this._searchProvider.search({ query: location })
        if (!result || !result.length) {
            this._unknownLocations.push(location)
            localStorage.setItem(MapHelper.NOT_FOUND_LOCATIONS_KEY, JSON.stringify(this._unknownLocations))
            return
        }

        return {
            location,
            latitude: result[0].y,
            longitude: result[0].x
        }
    }
}

export default MapHelper

export const mapHelper = new MapHelper(
    JSON.parse(localStorage.getItem(MapHelper.NOT_FOUND_LOCATIONS_KEY) ?? '[]'),
    new OpenStreetMapProvider()
)