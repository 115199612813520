import { useCallback } from 'react'
import { EProfileRights } from 'enums/enums'
import { useAppSelector } from 'state/store'

const useAuthorization = () : (...rights: EProfileRights[]) => boolean => {
    const profile = useAppSelector(state => state.profile)

    return useCallback((...rights: EProfileRights[]) : boolean => {
        for (const right of rights) {
            if (!profile.rights.includes(right)) {
                return false
            }
        }

        return true
    }, [profile])
}

export default useAuthorization