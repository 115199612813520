import CircularLoader from 'components/CircularLoader/CircularLoader'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormFooter, FormGroup, FormWrapper } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { TCustomComment, TUpdateAllCommentsRequest, TUpdateCommentsRequest } from 'types/businessLogic/site'
import CommentInput from 'components/CustomComment/CommentInput'
import CommentItem from 'components/CustomComment/CommentItem'
import { TQueryFilter } from 'types/network'
import SiteService from 'services/siteService'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

export type TFormInputs = {
    comment: string
}

type TProps = {
    siteIds: number[]
    filters?: TQueryFilter[]
    customComments: TCustomComment[]
    onSubmit?: () => void
    readonly?: boolean
}

const CommentsForm = (props: TProps) => {
    const dispatch = useDispatch()

    const {siteIds, customComments, filters, onSubmit, readonly} = props

    const isSelectAll = !!filters

    const [initialCommentList] = useState<TCustomComment[]>(customComments)
    const [commentList, setCommentList] = useState<TCustomComment[]>(customComments)
    const [hasChanged, setHasChanged] = useState<boolean>(false)
    const [commentToEdit, setCommentToEdit] = useState<TCustomComment | undefined>(undefined)

    const mapCommentList = useCallback(() => {
        return commentList.map(comment => {
            return {
                description: comment.description,
                title: comment.title,
                id: comment.id > 0 ? comment.id : 0
            }
        })
    }, [commentList])

    const submitCommentList = useCallback(async () => {
        try {
            if (isSelectAll) {
                const request: TUpdateAllCommentsRequest = {
                    filters: filters,
                    comments: mapCommentList()
                }
                await SiteService.updateAllComments(request)
            } else {
                const request: TUpdateCommentsRequest = {
                    siteIds: siteIds,
                    comments: mapCommentList()
                }
                await SiteService.updateComments(request)
            }
            dispatch(showMessage({message: i18nKeys.site_details_comment_update_success_message, type: EMessageType.SUCCESS}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.site_details_comment_update_error_message, type: EMessageType.ERROR}))
        }

        onSubmit && onSubmit()
    }, [dispatch, filters, isSelectAll, mapCommentList, onSubmit, siteIds])

    const reset = () => {
        setCommentList(initialCommentList)
        setHasChanged(false)
    }

    const modifyComment = (modifiedComment: TCustomComment) => {
        const newCommentList = [...commentList]
        const oldCommentIndex = newCommentList.findIndex(comment => comment.id === modifiedComment.id)

        newCommentList[oldCommentIndex] = {
            title: modifiedComment.title,
            description: modifiedComment.description,
            id: modifiedComment.id
        }

        setCommentList(newCommentList)
        setHasChanged(true)
        setCommentToEdit(undefined)
    }

    const deleteComment = (id: number) => {
        setCommentList(commentList.filter((comment: TCustomComment) => comment.id !== id))
        setHasChanged(true)
        if (id === (commentToEdit ? commentToEdit.id : 0)) {
            setCommentToEdit(undefined)
        }
    }

    const addComment = useCallback((comment: TCustomComment) => {
        if (commentList.length < 10) {
            const newCommentList = [...commentList]
            newCommentList.push(comment)
    
            setCommentList(newCommentList)
            setHasChanged(true)
        }
    }, [commentList])

    const editComment = useCallback((comment: TCustomComment) => {
        setCommentToEdit(comment)
    }, [])

    const clearComment = useCallback((() => {
        setCommentToEdit(undefined)
    }), [])

    const { handleSubmit, formState, control } = useForm<TFormInputs>()
    const { isSubmitting } = formState

    return (
        <>
            <FormWrapper onSubmit={handleSubmit(submitCommentList)}>
                
                <FormContent>
                    {
                        !readonly && (
                            <CommentInput {...props}
                                addComment={addComment}
                                disableUpdate={commentList.length >= 10}
                                modifyComment={modifyComment}
                                editComment={commentToEdit}
                                clearComment={clearComment}
                                control={control}
                            />
                        )
                    }
                    <FormGroup title={i18nKeys.site_details_comment_list_comments_group}>
                        {
                            commentList.map((comment: TCustomComment) =>
                                <CommentItem
                                    {...comment}
                                    selected={comment.id === (commentToEdit ? commentToEdit.id : 0)}
                                    key={comment.id}
                                    onDelete={deleteComment}
                                    onModify={editComment}
                                    readonly={readonly}
                                />)
                        }
                    </FormGroup>
                </FormContent>
                {
                    !readonly && (
                        <FormFooter>
                            <FormButtonGroup>
                                <CustomButton
                                    disabled={!hasChanged || isSubmitting}
                                    id='updateComments'
                                    type='submit'
                                >
                                    {isSubmitting ? <CircularLoader /> : <TransMessage {...i18nKeys.site_details_comment_update_comments} />}
                                </CustomButton>
                                <CustomButton
                                    id='resetComments'
                                    type='reset'
                                    onClick={reset}
                                    $secondary
                                >
                                    <TransMessage {...i18nKeys.site_details_comment_reset_comments} />
                                </CustomButton>
                            </FormButtonGroup>
                        </FormFooter>
                    )
                }
            </FormWrapper>
        </>
    )
}

export default CommentsForm