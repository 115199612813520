import React from 'react'
import TransMessage, { TIntlMessage } from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import colors from 'style/colors'
import images from 'style/images'
import { TSiteMin, TPluginType, TSite } from 'types/businessLogic/site'
import { ExtendedSiteLabelResponse, TCreatableOption, TDropdownItem, TSelectOption } from 'types/ui/dropdown'
import { EJobState, ESiteState } from 'enums/enums'

type SitesEnumBase = {
    stateColor: string
    translation: TIntlMessage
}

type SiteEnumExtended = SitesEnumBase & {
    mapMarker: string
    mapClusterColor: string
}
class SiteHelper {
    static jobStateEnum: {[key: string]: SitesEnumBase} = {
        [EJobState.CANCELED]: {
            stateColor: colors.extra.WARNING,
            translation: i18nKeys.site_jobs_status_canceled
        },
        [EJobState.TODO]: {
            stateColor: colors.brandColors.BLUE,
            translation: i18nKeys.site_jobs_status_to_do
        },
        [EJobState.TRANSMITTED]: {
            stateColor: colors.extra.OFFLINE,
            translation: i18nKeys.site_jobs_status_transmitted
        },
        [EJobState.DONE]: {
            stateColor: colors.extra.SUCCESS,
            translation: i18nKeys.site_jobs_status_done
        },
        [EJobState.FAILED]: {
            stateColor: colors.extra.ALERT,
            translation: i18nKeys.site_jobs_status_failed
        }
    }

    static siteStateEnum: {[key: string]: SiteEnumExtended} = {
        [ESiteState.HEALTHY]: {
            mapMarker: images.markerGreen,
            mapClusterColor: colors.extra.SUCCESS,
            stateColor: colors.extra.SUCCESS,
            translation: i18nKeys.site_states_healthy
        },
        [ESiteState.WARNING]: {
            mapMarker: images.markerOrange,
            mapClusterColor: colors.extra.WARNING,
            stateColor: colors.extra.WARNING,
            translation: i18nKeys.site_states_warning
        },
        [ESiteState.OFFLINE]: {
            mapMarker: images.markerGray,
            mapClusterColor: colors.shades.DARK_GRAY_SHADE,
            stateColor: colors.shades.DARK_GRAY_SHADE,
            translation: i18nKeys.site_states_offline
        },
        [ESiteState.UPDATING]: {
            mapMarker: images.markerGreen,
            mapClusterColor: colors.extra.SUCCESS,
            stateColor: colors.extra.SUCCESS,
            translation: i18nKeys.site_states_updating
        },
        [ESiteState.VMS_NOT_REACHABLE]: {
            mapMarker: images.markerRed,
            mapClusterColor: colors.extra.ALERT,
            stateColor: colors.extra.ALERT,
            translation: i18nKeys.site_states_vms_not_reachable
        },
        [ESiteState.IGNORED]: {
            mapMarker: images.markerBlue,
            mapClusterColor: colors.brandColors.BLUE,
            stateColor: colors.brandColors.BLUE,
            translation: i18nKeys.site_states_ignored
        }
    }

    static getSiteMapMarker(state: number) {
        return SiteHelper.siteStateEnum[state] ? SiteHelper.siteStateEnum[state].mapMarker : images.markerGray
    }

    static getSiteMapClusterColor(state: number) {
        return SiteHelper.siteStateEnum[state] ? SiteHelper.siteStateEnum[state].mapClusterColor : colors.shades.DEEP_SHADE
    }

    static getSiteStateColor(state: number): string {
        return SiteHelper.siteStateEnum[state] ? SiteHelper.siteStateEnum[state].stateColor : colors.shades.DEEP_SHADE
    }

    static getSiteStateIntlKey(state: number): TIntlMessage {
        return SiteHelper.siteStateEnum[state] ? SiteHelper.siteStateEnum[state].translation : i18nKeys.site_states_offline
    }

    static getJobStateColor(state: number): string {
        return SiteHelper.jobStateEnum[state] ? SiteHelper.jobStateEnum[state].stateColor : colors.extra.WARNING
    }

    static getJobStateIntlKey(state: number): TIntlMessage {
        return SiteHelper.jobStateEnum[state] ? SiteHelper.jobStateEnum[state].translation : i18nKeys.site_jobs_status_to_do
    }

    static findSiteGateway = (siteGateways: TSiteMin[], id: number) : TSiteMin | undefined =>
        siteGateways.find((gateway: TSiteMin) => gateway.id === id)

    static findPluginType = (pluginTypes: TPluginType[], pluginType: number): TPluginType | undefined =>
        pluginTypes.find(plugin => plugin.pluginType === pluginType)

    static mapDropdownLabelOptions = (labels: string[] = []): TCreatableOption[] =>
        labels.map(label => ({label, value: label}))

    static prepareSitesSelectOptions = (sites: TSite[]): TSelectOption[] =>
        sites.map(site => ({
            label: site.name,
            value: site.id
        }))

    static prepareSiteLabelOptions = (labels: ExtendedSiteLabelResponse[]): TSelectOption[] =>
        labels.map(label => ({
            label: label.name,
            value: label.id
        }))

    static preparePluginDropdownOptions = (pluginTypes: TPluginType[]): TDropdownItem[] => {
        const emptyDropDownElement : TDropdownItem = {
            text: <TransMessage {...i18nKeys.dropdown_selection_none} />,
            value: ''
        }
        
        const dropdownOptions = [emptyDropDownElement]

        const pluginDropdownOptions = pluginTypes.map(pluginType => ({
            value: pluginType.pluginType,
            text: <TransMessage {...i18nKeys[pluginType.translationKey]} />
        }))

        return dropdownOptions.concat(pluginDropdownOptions)
    }
}

export default SiteHelper