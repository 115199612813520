export enum EProfileRights {
    IS_SITE_ADMIN,
    IS_UMBRELLA_ADMIN,
    HAS_REPORT_RIGHT,
    HAS_SITE_AUDIT_REPORT_RIGHT,
    HAS_SITE_MAINTENANCE_REPORT_RIGHT,
    HAS_APPROVE_SITES_RIGHT,
    HAS_DEPLOY_SITES_RIGHT,
    HAS_DELETE_SITES_RIGHT,
    HAS_MANAGE_FIRMWARES_RIGHT,
    HAS_RESTART_SITE_ENTITIES_RIGHT,
    HAS_MONITORING_RIGHT,
    DEBUG_DUMMY,
    HAS_IGNORE_RIGHT
}

export enum EServiceState {
    UNKNOWN = -1,
    STOPPED,
    RUNNING,
    PAUSED,
    OFFLINE
}

export enum EVMSState {
    UNKNOWN = -1,
    OK,
    ERROR,
    OFFLINE,
    DISABLED
}

export enum ERecordingState {
    OK,
    LIMITED,
    ERROR,
    UNKNOWN,
    DISABLED
}

export enum EPerformanceState {
    IGNORED = -1,
    HEALTHY,
    OFFLINE,
    WARNING,
    CRITICAL
}

export enum ENotificationType {
    UNKNOWN,
    ENTITY,
    SITE
}

export enum EThresholdType {
    UNKNOWN,
    ENTITY,
    SITE,
    SETTINGS
}

export enum ETableFilters {
    NONE,
    INPUT_TEXT,
    DROPDOWN
}

export enum EMessageType {
    NONE,
    SUCCESS,
    ERROR
}

export enum EEmailEncryptionMethod {
    NONE,
    SSL
}

export enum EPropertyType {
    BOOLEAN,
    STRING,
    LONG,
    COLLECTION,
    DATETIME,
    JSON,
    DOUBLE
}

export enum ESortDirection {
    NONE,
    ASCENDING,
    DESCENDING
}

export enum EJobState {
    TODO,
    TRANSMITTED,
    DONE,
    FAILED,
    CANCELED
}

export enum ESiteState {
    HEALTHY,
    WARNING,
    OFFLINE,
    UPDATING,
    VMS_NOT_REACHABLE,
    IGNORED
}

export enum EVMSEventCategory {
    UNKNOWN = -1,
    INFO,
    WARNING,
    ERROR
}

export enum EVMSEntityType {
    UNKNOWN = -1,
    CAMERA,
    VMS_RUNTIME,
    VMS_USER,
    VMS_GROUP,
    SITE,
    SYSTEM
}

export enum EPluginType {
    Fake,
    CayugaR10,
    CayugaR11,
    CayugaR12,
    CayugaR13,
    CayugaR14,
    CayugaR15,
    CayugaR16,
    CayugaR17,
    Situator,
    QognifyVMS71
}

export enum EPlugin {
    VMS,
    Monitoring
}

export enum EUploadState {
    ERROR,
    IN_PROGRESS,
    DONE
}

export enum EUploadManagerError {
    ERROR_GENERAL,
    SIZE_TOO_LARGE,
    FILE_ALREADY_EXISTS
}

export enum ERestrctionType {
    RANGE,
    COLLECTION
}

export enum EPasswordValidationElements {
    REQUIRE_DIGIT,
    REQUIRE_NON_ALPHANUMERIC,
    REQUIRE_UPPERCASE,
    REQUIRE_LOWERCASE,
    REQUIRED_LENGTH,
    REQUIRED_UNIQUE_CHARS
}

// THIS ENUM NEEDS TO BE IN SYNC WITH BACKEND
export enum EDataSyncItems {
    NONE = -1,
    SITES,
    EMAIL,
    SETTINGS,
    UMBRELLA_USERS,
    UMBRELLA_ROLES,
    PROFILES,
    JOBS,
    API_KEYS,
    AUDIT_REPORTS,
    INVENTORY_REPORTS,
    FIRMWARES,
    SITE_EVENTS,
    SITE_CAMERAS,
    SITE_USERS,
    SITE_GROUPS,
    SITE_SERVICES,
    SITE_TIME_PROFILES,
    SITE_SYSTEMS_PERFORMANCE,
    CORE_PERFORMANCE,
    PLUGIN,
    SITE_SERVICE_PERFORMANCE,
    CAMERA_PERFORMANCE,
    VMS_EVENT_CONFIGURATION,
    LABEL
}

export enum ECheckboxState {
    UNCHECKED,
    INDETERMINATE,
    CHECKED
}

export enum EIssueItem {
    HOST,
    SERVICES,
    CAMERAS
}

export enum ENotificationPriority {
    HIGH,
    NORMAL,
    LOW
}

export enum ESetThresholdType {
    SITES,
    SERVICES,
    SYSTEMS
}