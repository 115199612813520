import React, { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGroup, TTableFetchState } from 'types/network'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { TModalProps } from 'types/ui/modal'
import { clearGroups, deleteUmbrellaRoleAsync, getGroupDataAsync, getUmbrellaRolesAsync } from 'state/slices/umbrellaRoleSlice'
import UmbrellaRolesService from 'services/umbrellaRolesService'
import UmbrellaRolesTablePreparer from 'views/Admin/UmbrellaRoles/UmbrellaRolesTablePreparer'
import { tableInfo } from 'views/Admin/UmbrellaRoles/UmbrellaRolesTableColumns'
import { useIntl } from 'react-intl'
import UmbrellaRoleDetails from 'views/Admin/UmbrellaRoles/UmbrellaRoleDetails/UmbrellaRoleDetails'
import UmbrellaRoles from 'views/Admin/UmbrellaRoles/UmbrellaRoles'
import { EMessageType, EVMSEntityType, EVMSEventCategory } from 'enums/enums'
import { TEnumTranslationDictionary } from 'types/ui/table'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import { useModal } from 'hooks/modalProvider'
import { showMessage } from 'state/slices/uiSlice'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const UmbrellaRolesContainer = () => {
    const dispatch = useAppDispatch()
    const { umbrellaRoles, totalNumber, groups, builtInRoleIds } = useAppSelector(state => state.umbrellaRole)
    const {showModal, closeModal} = useModal()

    useBreadCrumbs([i18nKeys.breadcrumb_umbrella_roles])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const requestParams = GeneralHelper.buildRequestFromTableState(params)
            dispatch(getUmbrellaRolesAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await UmbrellaRolesService.getRolesGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }

            getGroups()
        }
    }, [dispatch])

    const openUmbrellaRoleDetailsModal = (id: string) => {
        const umbrellaRole = umbrellaRoles.find(x => x.id === id)

        if (!umbrellaRole) return

        const isBuiltIn = umbrellaRole.builtIn
        const title = isBuiltIn ?
            intl.formatMessage(i18nKeys.umbrella_role_details_title_built_in_role) :
            intl.formatMessage(i18nKeys.umbrella_role_details_title)

        const description = isBuiltIn ?
            intl.formatMessage(i18nKeys.umbrella_role_details_subtitle_built_in_role, {role: umbrellaRole.name}) :
            intl.formatMessage(i18nKeys.umbrella_role_details_subtitle, {role: umbrellaRole.name})

        showModal({
            content: <UmbrellaRoleDetails umbrellaRole={umbrellaRole} onSubmit={closeModal} />,
            title,
            description
        })
    }

    const deleteUmbrellaRoles = (params: TModalProps) => {
        if (params.ids.length > 0) {
            dispatch(deleteUmbrellaRoleAsync(params.ids as string[]))
        }
    }

    const intl = useIntl()

    const eventCategories : TEnumTranslationDictionary = useMemo(() => ({
        [EVMSEventCategory.UNKNOWN]: { translation: intl.formatMessage(i18nKeys.site_event_category_unknown)},
        [EVMSEventCategory.INFO]: { translation: intl.formatMessage(i18nKeys.site_event_category_info)},
        [EVMSEventCategory.WARNING]: { translation: intl.formatMessage(i18nKeys.site_event_category_warning)},
        [EVMSEventCategory.ERROR]: { translation: intl.formatMessage(i18nKeys.site_event_category_error)}
    }), [intl])

    const entityTypes : TEnumTranslationDictionary = useMemo(() => ({
        [EVMSEntityType.UNKNOWN]: { translation: intl.formatMessage(i18nKeys.site_event_entity_type_unknown)},
        [EVMSEntityType.CAMERA]: { translation: intl.formatMessage(i18nKeys.site_event_entity_type_camera)},
        [EVMSEntityType.VMS_RUNTIME]: { translation: intl.formatMessage(i18nKeys.site_event_entity_type_runtime)},
        [EVMSEntityType.VMS_USER]: { translation: intl.formatMessage(i18nKeys.site_event_entity_type_user)},
        [EVMSEntityType.VMS_GROUP]: { translation: intl.formatMessage(i18nKeys.site_event_entity_type_group)}
    }), [intl])

    const data = UmbrellaRolesTablePreparer.prepareUmbrellaRoleRows(umbrellaRoles, eventCategories, entityTypes, openUmbrellaRoleDetailsModal)
    const preparedGroups = UmbrellaRolesTablePreparer.prepareGroups(groups, eventCategories, entityTypes, openUmbrellaRoleDetailsModal)
    
    return (
        <ViewContainer>
            <UmbrellaRoles
                data={data}
                groups={preparedGroups}
                totalCount={totalNumber}
                tableInfo={tableInfo}
                builtInRoleIds={builtInRoleIds}
                getTableData={getTableData}
                deleteUmbrellaRoles={(params: TModalProps) => deleteUmbrellaRoles(params)}
                getGroupData={getGroupData}
            />
        </ViewContainer>
    )
}

export default UmbrellaRolesContainer
