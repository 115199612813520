import { ENotificationType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'

export const NotificationTypeInfos = {
    [ENotificationType.UNKNOWN] : {
        translationKey: i18nKeys.threshold_type_unknown
    },
    [ENotificationType.ENTITY] : {
        translationKey: i18nKeys.threshold_type_entity
    },
    [ENotificationType.SITE] : {
        translationKey: i18nKeys.threshold_type_site
    }
}