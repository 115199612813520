import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormButtonGroup, FormContent, FormGroup, FormLabel, FormWrapper, FormFooter, InputWrapper, FormCheckbox } from 'components/FormControls/FormControls'
import { FromThresholdSlider } from 'components/FormControls/FormThreshholdSlider'
import TransMessage from 'components/TransMessage/TransMessage'
import { ESetThresholdType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

export type TSetMonitoringThresholdsFormValues = {
    cpuThresholds: number[]
    memoryThresholds: number[]
    diskThresholds: number[]
    networkThresholds: number[]
    cpuUseDefault: boolean
    memoryUseDefault: boolean
    networkUseDefault: boolean
    diskUseDefault: boolean
}

export type TSetMonitoringThresholdsModalProps = {
    type: ESetThresholdType
    cpuThresholds: number[]
    memoryThresholds: number[]
    diskThresholds: number[]
    networkThresholds: number[]
    cpuUseDefault: boolean
    memoryUseDefault: boolean
    networkUseDefault: boolean
    diskUseDefault: boolean
    onSubmit?: (formValues: TSetMonitoringThresholdsFormValues) => void
}

const SetMonitoringThresholdsForm = (props: TSetMonitoringThresholdsModalProps) => {
    const {onSubmit, cpuThresholds, memoryThresholds, diskThresholds, networkThresholds, cpuUseDefault, memoryUseDefault, networkUseDefault, diskUseDefault} = props

    const handleSetMonitoringThresholdsSubmit = useCallback(async (formValues: TSetMonitoringThresholdsFormValues) : Promise<void> => {
        onSubmit && onSubmit(formValues)
    }, [onSubmit])

    const defaultValues : TSetMonitoringThresholdsFormValues = useMemo(() => ({
        cpuThresholds,
        diskThresholds,
        memoryThresholds,
        networkThresholds,
        cpuUseDefault,
        memoryUseDefault,
        networkUseDefault,
        diskUseDefault
    }), [cpuThresholds, cpuUseDefault, diskThresholds, diskUseDefault, memoryThresholds, memoryUseDefault, networkThresholds, networkUseDefault])

    const validationSchema = yup.object().shape({
        
    })

    const { handleSubmit, formState, control, trigger, watch, reset} = useForm<TSetMonitoringThresholdsFormValues>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema, {
            strict: true
        })
    })

    useEffect(() => {
        reset(defaultValues)
    }, [reset, defaultValues])

    const { isDirty, isValid, isValidating, isSubmitting } = formState

    const cpuUseDefaultValue = watch('cpuUseDefault')
    const memoryUseDefaultValue = watch('memoryUseDefault')
    const diskUseDefaultValue = watch('diskUseDefault')
    const networkUseDefaultValue = watch('networkUseDefault')

    return (
        <FormWrapper onSubmit={handleSubmit(handleSetMonitoringThresholdsSubmit)}>
            <FormContent>
                <FormGroup>
                    <InputWrapper>
                        <FormLabel htmlFor='cpuThresholds'>
                            <TransMessage {...i18nKeys.configure_threshold_cpu} />
                        </FormLabel>
                        <Stack>
                            <FromThresholdSlider
                                name='cpuThresholds'
                                control={control}
                                min={1}
                                max={100}
                                minDistance={5}
                                disabled={cpuUseDefaultValue}
                            />
                            <Stack direction='row' alignItems='center'>
                                <FormCheckbox
                                    name='cpuUseDefault'
                                    control={control}
                                    onChange={() => {
                                        trigger('cpuThresholds')
                                    }}
                                />
                                <label htmlFor='cpuUseDefault'>
                                    <TransMessage {...i18nKeys.configure_threshold_use_default_label} />
                                </label>
                            </Stack>
                        </Stack>
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='memoryThresholds'>
                            <TransMessage {...i18nKeys.configure_threshold_memory} />
                        </FormLabel>
                        <Stack>
                            <FromThresholdSlider
                                name='memoryThresholds'
                                control={control}
                                min={1}
                                max={100}
                                minDistance={5}
                                disabled={memoryUseDefaultValue}
                            />
                            <Stack direction='row' alignItems='center'>
                                <FormCheckbox
                                    name='memoryUseDefault'
                                    control={control}
                                    onChange={() => {
                                        trigger('memoryThresholds')
                                    }}
                                />
                                <label htmlFor='memoryUseDefault'>
                                    <TransMessage {...i18nKeys.configure_threshold_use_default_label} />
                                </label>
                            </Stack>
                        </Stack>
                    </InputWrapper>
                    {
                        props.type !== ESetThresholdType.SERVICES && (
                            <InputWrapper>
                                <FormLabel htmlFor='diskThresholds'>
                                    <TransMessage {...i18nKeys.configure_threshold_disk} />
                                </FormLabel>
                                <Stack>
                                    <FromThresholdSlider
                                        name='diskThresholds'
                                        control={control}
                                        min={1}
                                        max={100}
                                        minDistance={5}
                                        disabled={diskUseDefaultValue}
                                    />
                                    <Stack direction='row' alignItems='center'>
                                        <FormCheckbox
                                            name='diskUseDefault'
                                            control={control}
                                            onChange={() => {
                                                trigger('diskThresholds')
                                            }}
                                        />
                                        <label htmlFor='diskUseDefault'>
                                            <TransMessage {...i18nKeys.configure_threshold_use_default_label} />
                                        </label>
                                    </Stack>
                                </Stack>
                            </InputWrapper>
                        )
                    }
                    {
                        props.type !== ESetThresholdType.SERVICES && (
                            <InputWrapper>
                                <FormLabel htmlFor='networkThresholds'>
                                    <TransMessage {...i18nKeys.configure_threshold_network} />
                                </FormLabel>
                                <Stack>
                                    <FromThresholdSlider
                                        name='networkThresholds'
                                        control={control}
                                        min={1}
                                        max={100}
                                        minDistance={5}
                                        disabled={networkUseDefaultValue}
                                    />
                                    <Stack direction='row' alignItems='center'>
                                        <FormCheckbox
                                            name='networkUseDefault'
                                            control={control}
                                            onChange={() => {
                                                trigger('networkThresholds')
                                            }}
                                        />
                                        <label htmlFor='networkUseDefault'>
                                            <TransMessage {...i18nKeys.configure_threshold_use_default_label} />
                                        </label>
                                    </Stack>
                                </Stack>
                            </InputWrapper>
                        )
                    }
                    {/* <InputWrapper>
                        <FormLabel htmlFor='useDefaults'>
                            <TransMessage {...i18nKeys.configure_threshold_use_default_label} />
                        </FormLabel>
                        <FormCheckbox
                            name='useDefaults'
                            control={control}
                            onChange={() => {
                                trigger('thresholdsCPU')
                                trigger('thresholdsMemory')
                                trigger('thresholdsDisk')
                                trigger('thresholdsNetwork')
                            }}
                        />
                    </InputWrapper> */}
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty}
                        id='submitBtn'
                        type='submit'
                    >
                        <TransMessage {...i18nKeys.configure_threshold_button_label} />
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}

export default SetMonitoringThresholdsForm