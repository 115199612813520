import { UseControllerProps, useController } from 'react-hook-form'
import colors from 'style/colors'
import React from 'react'
import styled from 'styled-components'
import CreatableSelect from 'react-select/creatable'
import { StyledFormHelperText } from 'components/FormControls/FormControls'
import { FormControl } from '@mui/material'
import { TSelectOptions } from 'types/ui/dropdown'

const StyledCreatableFormSelect = styled(CreatableSelect)`
    width: 380px;

    & .react-select__control--is-focused {
        border-color: ${colors.primary.DEFAULT_PRIMARY};
        box-shadow: 0 0 0 1px ${colors.primary.DEFAULT_PRIMARY};
    }

    & .react-select__control {
        border-radius: 8px;       
        &:hover {
            border-color: ${colors.primary.DEFAULT_PRIMARY};
            box-shadow: 0 0 0 1px ${colors.primary.DEFAULT_PRIMARY};
        }
    }

    & .react-select__option--is-focused {
        background-color: ${colors.shades.GRAY_SHADE};
    }

    & .react-select__option--is-selected {
        background-color: ${colors.primary.DEFAULT_PRIMARY};
    }

    & .react-select__input {
        background-color: white;
        width: 320px;
        height: 44px;
    }

    & .react-select__multi-value {
        background-color: ${colors.primary.DEFAULT_PRIMARY};
        color: ${colors.shades.PURE_WHITE};
        border-radius: 32px;
        padding-left: 8px;

        .react-select__multi-value__label {
            color: ${colors.shades.PURE_WHITE};
        }

        .react-select__multi-value__remove {
            &:hover {
                cursor: pointer;
                background-color: transparent;
                color: ${colors.shades.PURE_WHITE};
            }
        }
    }
`

type TProps = UseControllerProps<any> & {
    debug?: boolean
    options: TSelectOptions
    isMulti?: boolean
    disabled?: boolean
    placeholder?: string
    error?: string
}

export const FormCreatableSelect = (props: TProps) => {
    const { field, fieldState } = useController(props)
    
    return (
        <>
            <FormControl>
                <StyledCreatableFormSelect
                    {...field}
                    isMulti={props.isMulti}
                    id={props.name}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    options={props.options}
                    isDisabled={props.disabled}
                    placeholder={props.placeholder}
                />
                <StyledFormHelperText id={`${field.name}-helper`}>
                    <span hidden={!fieldState.invalid}>
                        {fieldState.error && fieldState.error.message}
                    </span>
                </StyledFormHelperText>
            </FormControl>
            {
                props.debug &&
                <>
                    <p>{fieldState.isTouched && 'Touched'}</p>
                    <p>{fieldState.isDirty && 'Dirty'}</p>
                    <p>{fieldState.invalid ? 'invalid' : 'valid'}</p>
                </>
            }
        </>
    )
}
