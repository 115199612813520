import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { TableWrapper } from 'components/CustomControls/CustomCharts'
import { FormButtonGroup } from 'components/FormControls/FormControls'
import Icon, { Icons } from 'components/Icon/Icon'
import TransMessage from 'components/TransMessage/TransMessage'
import DateHelper from 'helpers/DateHelper'
import EnumHelper from 'helpers/EnumHelper'
import { i18nKeys } from 'i18n/keys'
import React, { useState } from 'react'
import colors from 'style/colors'
import fonts from 'style/fonts'
import zIndex from 'style/zIndex'
import styled from 'styled-components'
import { TNotification } from 'types/businessLogic/siteEvent'

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: ${zIndex.HIGH};
`

const IconContainer = styled.div`
    width: 40px;
    height: 32px;
    margin-left: 16px;
    position: relative;
    cursor: pointer;
`

const NotificationIcon = styled(Icon).attrs({
    size: 26,
    d: Icons.NOTIFICATIONS
})`
    fill: ${colors.shades.GRAY_SHADE};
    margin: 3px;
`

const Counter = styled.div`
    display: grid;
    place-items: center;
    width: 25px;
    height: 17px;
    padding: 1px;
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    background-color: ${colors.extra.ALERT};   
    font-family: ${fonts.PRIMARY_MEDIUM};
    font-size: 12px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: 0.5;
    text-align: center;
    color: ${colors.shades.PURE_WHITE};
    position: absolute;
    right: -5px;
    top: 0;
    z-index: ${zIndex.MEDIUM};
`

const ItemContainer = styled.div`
    position: absolute;
    top: 57px;
    width: 1200px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 16px;
    z-index: ${zIndex.HIGHEST};
`

const TableBlock = styled.div`
    height: 352px;
    overflow-x: hidden;
    overflow-y: scroll;
`

const InfoBlock = styled.div`
    height: 90px;
    font-family: LatoBold;
    line-height: 16px;
    font-stretch: normal;
    font-size: 18px;
    color: #303030;
    display: grid;
    place-items: center;
`

type TProps = {
    newItemsCount: number
    items: TNotification[]
    onShowEvents: () => void
    onClearEvents: () => void
    onGotoMonitorEvents: () => void
}

const NotificationManager = (props: TProps) => {
    const {
        items,
        newItemsCount,
        onShowEvents,
        onClearEvents,
        onGotoMonitorEvents
    } = props

    const [showPopup, setShowPopup] = useState(false)

    const renderHeader = () => {

        const headers = [
            i18nKeys.umbrella_notification_table_column_source,
            i18nKeys.umbrella_notification_table_column_site,
            i18nKeys.umbrella_notification_table_column_timestamp,
            i18nKeys.umbrella_notification_table_column_category,
            i18nKeys.umbrella_notification_table_column_description
        ]

        return (
            <TableRow>
                {headers.map((header, index) => {
                    return (
                        <TableCell key={index}>
                            <span className='header'>
                                <TransMessage {...header} />
                            </span>
                        </TableCell>
                    )
                })}
            </TableRow>
        )
    }

    const renderItems = () => {
        return items.map((item, index) => {
            return (
                <TableRow key={index}>
                    <TableCell>
                        {item.source}
                    </TableCell>
                    <TableCell>
                        {item.siteName}
                    </TableCell>
                    <TableCell>
                        {DateHelper.formatDate(item.timestamp, DateHelper.formats.DATE_TIME_SECONDARY)}
                    </TableCell>
                    <TableCell>
                        <TransMessage {...EnumHelper.getSiteEventCatergoryName(item.eventCategory)} />
                    </TableCell>
                    <TableCell>
                        {item.description}
                    </TableCell>
                </TableRow>
            )
        })
    }

    const handleClick = () => {
        if (!showPopup && newItemsCount > 0) {
            onShowEvents()
        }
        setShowPopup(!showPopup)
    }

    const handleClearEventsClick = () => {
        setShowPopup(false)
        onClearEvents()
    }

    const handleGotoMonitorEventsClick = () => {
        setShowPopup(false)
        onGotoMonitorEvents()
    }

    const renderBell = () => {
        if (newItemsCount > 0) {
            if (newItemsCount > 99) {
                return <Counter>99+</Counter>
            }
            return <Counter>{newItemsCount}</Counter>
        }
    }

    return (
        <>
            {
                showPopup &&
                < >
                    <Wrapper onClick={() => setShowPopup(!showPopup)} />
                    <ItemContainer>
                        {
                            items.length > 0 &&
                            <>
                                <TableBlock>
                                    <TableWrapper>
                                        <Table stickyHeader>
                                            <TableHead>
                                                {renderHeader()}
                                            </TableHead>
                                            <TableBody>
                                                {renderItems()}
                                            </TableBody>
                                        </Table>
                                    </TableWrapper>
                                </TableBlock>
                                <FormButtonGroup>
                                    <CustomButton id='gotoMonitorEvents' onClick={handleGotoMonitorEventsClick}>
                                        <TransMessage {...i18nKeys.umbrella_notification_table_site_events} />
                                    </CustomButton>
                                    <CustomButton $secondary id='clearEvents' onClick={handleClearEventsClick}>
                                        <TransMessage {...i18nKeys.umbrella_notification_table_clear_notifications} />
                                    </CustomButton>
                                </FormButtonGroup>
                            </>
                        }
                        {
                            items.length === 0 && <InfoBlock>
                                <TransMessage {...i18nKeys.umbrella_no_notifications} />
                            </InfoBlock>
                        }
                    </ItemContainer>
                </>
                
            }

            <IconContainer title='notificationManager' id='notificationManager' onClick={handleClick}>
                {
                    renderBell()
                }
                <NotificationIcon title='notificationIcon' />
            </IconContainer>
        </>
    )
}

export default NotificationManager