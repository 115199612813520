import React, { useEffect, useState } from 'react'
import { TModalProps } from 'types/ui/modal'
import CommentsForm from 'components/CustomComment/CommentsForm'
import { TCustomComment } from 'types/businessLogic/site'
import SiteService from 'services/siteService'
import { useModal } from 'hooks/modalProvider'
import { EMessageType } from 'enums/enums'
import { i18nKeys } from 'i18n/keys'
import { showMessage } from 'state/slices/uiSlice'
import { dispatch } from 'state/store'

const SetCustomComments = (props: TModalProps) => {
    const {ids, filters} = props
    const isSelectAll = !!filters
    const [commonComments, setCommonComments] = useState<TCustomComment[] | undefined>(undefined)
    const {closeModal} = useModal()

    useEffect(() => {
        if (isSelectAll) {
            const getComments = async () => {
                try {
                    const result = await SiteService.getAllCommonComments(filters)
                    let counter = 1
                    result.forEach(comment => comment.id = -(counter++))
                    setCommonComments(result)
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }
            getComments()
        } else {
            const getComments = async () => {
                try {
                    const result = await SiteService.getCommonComments(ids as number[])
                    let counter = 1
                    result.forEach(comment => comment.id = -(counter++))
                    setCommonComments(result)
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }
            getComments()
        }
    }, [ids, filters, isSelectAll])

    if (!commonComments) {
        return null
    }

    return (
        <CommentsForm
            customComments={commonComments}
            siteIds={ids as number[]}
            filters={filters}
            onSubmit={closeModal}
        />
    )
}

export default SetCustomComments