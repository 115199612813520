import { CancelTokenSource } from 'axios'
import { uid } from 'uid'

class CancelTokenStore {
    private readonly cancelTokens: Map<string, CancelTokenSource> = new Map()

    add = (source: CancelTokenSource) : string => {
        const tokenId = uid()
        this.cancelTokens.set(tokenId, source)
        return tokenId
    }

    get = (tokenId: string) => this.cancelTokens.get(tokenId)

    delete = (tokenId: string) => this.cancelTokens.delete(tokenId)
}

export const cancelTokenStore = new CancelTokenStore()