import axiosClient from 'api/axiosClient'
import { TLicense } from 'types/businessLogic/license'

class LicenseService {
    private static _api = axiosClient()

    public static async getLicense(): Promise<TLicense> {
        const response = await this._api.get<TLicense>('License')
        return response.data
    }

    public static async updateLicenseKey(licenseKey : string): Promise<void> {
        await this._api.post<TLicense>('License/Update', {
            LicenseKey: licenseKey
        })
    }
}

export default LicenseService