import React from 'react'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import GeneralHelper from 'helpers/GeneralHelper'
import SiteHelper from 'helpers/SiteHelper'
import { TPluginType, TSite } from 'types/businessLogic/site'
import { TTableGroup, TTableRowData } from 'types/ui/table'
import { tableInfo } from 'views/Sites/siteTableColumns'
import StatusDot from 'components/StatusDot/StatusDot'
import CustomLink from 'components/CustomControls/CustomLink'

class SiteTablePreparer {
    public static prepareSiteRows = (sites: TSite[], pluginTypes: TPluginType[], openSiteDetailsModal: (id: number) => void): TTableRowData[] =>
        sites.map(site => ({
            ...site,
            propertyValues: [
                {
                    name: tableInfo.columnConfig.name.key,
                    translationKey: tableInfo.columnConfig.name.translationKey,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    readOnly: false,
                    [GeneralHelper.propertyTypes.customRender.key]: () =>
                        <CustomLink onClick={() => openSiteDetailsModal(site.id)}>{site.name}</CustomLink>
                },
                {
                    name: tableInfo.columnConfig.location.key,
                    translationKey: tableInfo.columnConfig.location.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: site.location
                },
                {
                    name: tableInfo.columnConfig.state.key,
                    readOnly: false,
                    translationKey: tableInfo.columnConfig.state.translationKey,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: (propertyName: string, rowId: string) => (
                        <StatusDot
                            id={`${propertyName}${rowId}`}
                            color={SiteHelper.getSiteStateColor(site.state)}
                            textKey={SiteHelper.getSiteStateIntlKey(site.state)}
                        />
                    )
                },
                {
                    name: tableInfo.columnConfig.serverVersion.key,
                    translationKey: tableInfo.columnConfig.serverVersion.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: site.vmsServerVersion
                },
                {
                    name: tableInfo.columnConfig.serverId.key,
                    translationKey: tableInfo.columnConfig.serverId.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: site.vmsServerId
                },
                {
                    name: tableInfo.columnConfig.machineCode.key,
                    translationKey: tableInfo.columnConfig.machineCode.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: site.machineCode
                },
                {
                    name: tableInfo.columnConfig.labels.key,
                    translationKey: tableInfo.columnConfig.labels.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.collection.index,
                    [GeneralHelper.propertyTypes.collection.key]: site.labels
                },
                {
                    name: tableInfo.columnConfig.approved.key,
                    translationKey: tableInfo.columnConfig.approved.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: () =>
                        site.approved ? <TransMessage {...i18nKeys.site_list_approved} /> : <TransMessage {...i18nKeys.site_list_unapproved} />
                },
                {
                    name: tableInfo.columnConfig.type.key,
                    translationKey: tableInfo.columnConfig.type.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.customRender.index,
                    [GeneralHelper.propertyTypes.customRender.key]: () => {
                        const pluginType = SiteHelper.findPluginType(pluginTypes, site.type)
                        return (pluginType && pluginType.translationKey) ? <TransMessage {...i18nKeys[pluginType.translationKey]} /> : site.type
                    }
                },
                {
                    name: tableInfo.columnConfig.version.key,
                    translationKey: tableInfo.columnConfig.version.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: site.version
                },
                {
                    name: tableInfo.columnConfig.clientVersion.key,
                    translationKey: tableInfo.columnConfig.clientVersion.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: site.vmsClientVersion
                },
                {
                    name: tableInfo.columnConfig.lastHeartBeat.key,
                    translationKey: tableInfo.columnConfig.lastHeartBeat.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.dateString.index,
                    [GeneralHelper.propertyTypes.dateString.key]: site.lastHeartBeat
                },
                {
                    name: tableInfo.columnConfig.ip.key,
                    translationKey: tableInfo.columnConfig.ip.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: site.ip
                },
                {
                    name: tableInfo.columnConfig.host.key,
                    translationKey: tableInfo.columnConfig.host.translationKey,
                    readOnly: false,
                    propertyType: GeneralHelper.propertyTypes.string.index,
                    [GeneralHelper.propertyTypes.string.key]: site.host
                }
            ]
        }))

    public static prepareGroups = (groups: TTableGroup<TSite>[], pluginTypes: TPluginType[], openSiteDetailsModal: (id: number) => void): TTableGroup<TTableRowData>[] => groups.map(group => ({
        ...group,
        data: group.data ? SiteTablePreparer.prepareSiteRows(group.data, pluginTypes, openSiteDetailsModal) : undefined
    }))
}

export default SiteTablePreparer