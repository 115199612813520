import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TGroup, TTableFetchState } from 'types/network'
import GeneralHelper from 'helpers/GeneralHelper'
import styled from 'styled-components'
import { i18nKeys } from 'i18n/keys'
import { TModalProps } from 'types/ui/modal'
import { clearGroups, deleteUmbrellaUsersAsync, getGroupDataAsync, getUmbrellaUsersAsync } from 'state/slices/umbrellaUserSlice'
import UmbrellaUserTablePreparer from 'views/Admin/UmbrellaUser/UmbrellaUserTablePreparer'
import { tableInfo } from 'views/Admin/UmbrellaUser/UmbrellaUserTableColumns'
import UmbrellaUserDetails from 'views/Admin/UmbrellaUser/UmbrellaUserDetails/UmbrellaUserDetails'
import { useIntl } from 'react-intl'
import UmbrellaUsers from 'views/Admin/UmbrellaUser/UmbrellaUsers'
import useBreadCrumbs from 'hooks/useBreadCrumbs'
import UmbrellaUserService from 'services/umbrellaUserService'
import { useModal } from 'hooks/modalProvider'
import { showMessage } from 'state/slices/uiSlice'
import { EMessageType } from 'enums/enums'

const ViewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
`

const UmbrellaUsersContainer = () => {

    const intl = useIntl()
    const dispatch = useAppDispatch()
    const {showModal} = useModal()
    const { umbrellaUsers, totalNumber, groups, builtInUserIds } = useAppSelector(state => state.umbrellaUser)
    
    useBreadCrumbs([i18nKeys.breadcrumb_umbrella_users])

    const getGroupData = useCallback((params: TTableFetchState, group : TGroup) => {
        const requestParams = GeneralHelper.buildGroupDataRequestFromTableState(params, group.name)
        dispatch(getGroupDataAsync({
            ...requestParams
        }))
    }, [dispatch])

    const getTableData = useCallback((params: TTableFetchState) => {
        if (!params.global.groupBy) {
            const requestParams = GeneralHelper.buildRequestFromTableState(params)
            dispatch(getUmbrellaUsersAsync(requestParams))
        } else {
            const getGroups = async () => {
                const groupingParams = GeneralHelper.buildGroupingRequestFromTableState(params)

                try {
                    const result = await UmbrellaUserService.getUserGroups(groupingParams)
                    dispatch(clearGroups(result))
                } catch {
                    dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
                }
            }

            getGroups()
        }
    }, [dispatch])

    const openUmbrellaUserDetailsModal = (id: string) => {
        const umbrellaUserDetails = umbrellaUsers.find(umbrellaUser => umbrellaUser.id === id)

        if (!umbrellaUserDetails) return

        const isBuiltIn = umbrellaUserDetails.builtIn
        const title = isBuiltIn ?
            intl.formatMessage(i18nKeys.umbrella_user_details_title_built_in_user, {name: umbrellaUserDetails.userName}) :
            intl.formatMessage(i18nKeys.umbrella_user_details_title, {name: umbrellaUserDetails.userName})

        const description = isBuiltIn ?
            intl.formatMessage(i18nKeys.umbrella_user_details_subtitle_built_in_user, {name: umbrellaUserDetails.userName}) :
            intl.formatMessage(i18nKeys.umbrella_user_details_subtitle, {name: umbrellaUserDetails.userName})

        showModal({
            content: <UmbrellaUserDetails umbrellaUser={umbrellaUserDetails} />,
            title,
            description
        })
    }

    const deleteUmbrellaUsers = (params: TModalProps) => {
        if (params.ids.length > 0) {
            dispatch(deleteUmbrellaUsersAsync(params.ids as string[]))
        }
    }

    const data = UmbrellaUserTablePreparer.prepareUmbrellaUserRows(umbrellaUsers, openUmbrellaUserDetailsModal)
    const preparedGroups = UmbrellaUserTablePreparer.prepareGroups(groups, openUmbrellaUserDetailsModal)
    
    return (
        <ViewContainer>
            <UmbrellaUsers
                data={data}
                totalCount={totalNumber}
                groups={preparedGroups}
                tableInfo={tableInfo}
                builtInusers={builtInUserIds}
                getTableData={getTableData}
                deleteUmbrellaUsers={(params: TModalProps) => deleteUmbrellaUsers(params)}
                getGroupData={getGroupData}
            />
        </ViewContainer>
    )
}

export default UmbrellaUsersContainer