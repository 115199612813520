import { Dialog } from '@mui/material'
import Icon, { Icons } from 'components/Icon/Icon'
import React, { PropsWithChildren } from 'react'
import colors from 'style/colors'
import styled from 'styled-components'
import borderRadius from 'style/borderRadius'
import fonts from 'style/fonts'

const CustomDialog = styled(Dialog)<{$maxWidth?: string, $height: string}>` 
    border-radius: ${borderRadius.SECONDARY};
    
    & .MuiDialog-paperWidthMd {
        max-width: ${p => p.$maxWidth ? p.$maxWidth : '1000px'};
        height: ${p => p.$height};
        overflow: hidden;
        background-color: ${colors.shades.LIGHT_WHITE};

        @media screen and (max-width: 1280px) {
            max-width: 80%;
        }

        @media screen and (max-width: 1024px) {
            max-width: 90%;
        }
    }
`

const Wrapper = styled.div`  
    display: flex;
    flex-flow: column;
    height: 100%;
`
const Sticky = styled.div`
    background-color: ${colors.shades.PURE_WHITE};       
    padding: 20px 40px;
    box-shadow: 0 10px 10px -10px rgb(0 0 0 / 10%);
`
const Content = styled.div`
    overflow: hidden;
    flex: 1 1 auto;
`

const Title = styled.h1`
    color: ${colors.primary.DARK_PRIMARY};
    font-family: ${fonts.PRIMARY_HEAVY};
`

const Description = styled.p`
    padding-top: 10px;
    font-family: ${fonts.PRIMARY_REGULAR};
`

const CloseIcon = styled(Icon)`
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 500;
    position: absolute;
    fill: ${colors.primary.DEFAULT_PRIMARY};
`

export type TCustomModalProps = {
    onClose?: () => void
    open: boolean
    title?: JSX.Element | string
    description?: JSX.Element | string
    width?: string
    dynamicHeight?: boolean
}

export const CustomModal = (props: PropsWithChildren<TCustomModalProps>) => {
    return (
        <CustomDialog
            fullWidth
            $maxWidth={props.width}
            maxWidth='md'
            $height={props.dynamicHeight ? 'auto' : '100%'}
            onClose={props.onClose}
            open={props.open}
            // disableBackdropClick={props.onClose === undefined}
        >
            {
                props.onClose !== undefined &&
                <CloseIcon
                    id='closeModalButton'
                    d={Icons.CLOSE}
                    onClick={props.onClose}
                />
            }
            <Wrapper>
                {
                    (props.title || props.description) && (
                        <Sticky>
                            {
                                props.title &&
                                <Title>{props.title}</Title>
                            }
                            {
                                props.description &&
                                <Description>{props.description}</Description>
                            }
                        </Sticky>
                    )
                }
                
                <Content>{props.children}</Content>
            </Wrapper>
        </CustomDialog>
    )
}
