import styled from 'styled-components'
import { ButtonGroup } from '@mui/material'
import colors from 'style/colors'
import shadows from 'style/shadows'

export const ButtonGroupWrapper = styled(ButtonGroup)`
    margin-left: 32px;
    margin-right: 32px;
    
    .active {
        background-color: ${colors.primary.DEFAULT_PRIMARY};
        color: ${colors.shades.PURE_WHITE} !important;
        box-shadow: ${shadows.PRIMARY}
    }

    .MuiButtonGroup-groupedHorizontal:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .MuiButtonGroup-groupedHorizontal:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .MuiButton-root {
        text-transform: none;
        font-family: LatoBold;
        color: ${colors.shades.PURE_BLACK};
        height: 32px;
        white-space: nowrap;
    }
`