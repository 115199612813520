import { debounce, CircularProgress } from '@mui/material'
import { CustomButton } from 'components/CustomControls/CustomButton'
import { FormAsyncCreatableSelect } from 'components/FormControls/FormAsyncCreatableSelect'
import { FormButtonGroup, FormCheckbox, FormContent, FormFooter, FormGroup, FormLabel, FormWrapper, InputWrapper } from 'components/FormControls/FormControls'
import TransMessage from 'components/TransMessage/TransMessage'
import LabelsHelper from 'helpers/LabelsHelper'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TSiteLabel } from 'types/businessLogic/misc'
import { TSelectOptions } from 'types/ui/dropdown'
import styled from 'styled-components'

const Warning = styled.div`
    color: red;
    //width: '300px';
    font-size: '16px';
    max-width: 800px;
    font-family: LatoSemibold;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: justify;
	text-align-last: none;
`

type TMultiConfigLabelForm = {
    labels: TSelectOptions
    override: boolean
}

type TProps = {
    labels: TSiteLabel[]
    onSubmit: (addLabels: TSiteLabel[], removedLabels: TSiteLabel[], override: boolean) => void
}

const prepareInitialValues = (labels: TSiteLabel[]) : TMultiConfigLabelForm => {
    return {
        labels: labels.map(label => {
            return {
                label: label.name,
                value: label.id
            }
        }),
        override: false
    }
}

const EditLabelsForm = (props: TProps) => {

    const [initialValues] = useState<TMultiConfigLabelForm>(prepareInitialValues(props.labels))
    const [submitting, setSubmitting] = useState(false)
    
    const formSubmit = (formState : TMultiConfigLabelForm) => {
        const addLabels: TSiteLabel[] = []
        const removedLabels: TSiteLabel[] = []

        if (formState.override) {
            formState.labels.forEach(label => addLabels.push({id: label.value as number, name: label.label}))
        } else {
            initialValues.labels.forEach(label => {
                if (formState.labels.findIndex(x => x.value === label.value) === -1) {
                    removedLabels.push({id: label.value as number, name: label.label})
                }
            })
    
            formState.labels.forEach(label => {
                if (initialValues.labels.findIndex(x => x.value === label.value) === -1) {
                    addLabels.push({id: label.value as number, name: label.label})
                }
            })
        }

        setSubmitting(true)
        props.onSubmit(addLabels, removedLabels, formState.override)
    }

    const { handleSubmit, formState, control, watch } = useForm<TMultiConfigLabelForm>({
        mode: 'onChange',
        defaultValues: initialValues,
    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadOptions = useCallback(
        debounce((inputText: string, callback: any) => {
            LabelsHelper.loadlabelsWithId(inputText).then((options) => callback(options))
        }, 500), [])
    
    const { isValid, isValidating, isSubmitting, isDirty } = formState

    const isOverrideCheckd = watch('override')

    return (
        <FormWrapper onSubmit={handleSubmit(formSubmit)}>
            <FormContent>
                <FormGroup>
                    <InputWrapper>
                        <FormLabel htmlFor='labels'>
                            <TransMessage {...i18nKeys.multiconfig_labels_labels_label} />
                        </FormLabel>
                        <FormAsyncCreatableSelect
                            name='labels'
                            control={control}
                            isMulti
                            loadOptions={loadOptions}
                            defaultOptions
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='override'>
                            <TransMessage {...i18nKeys.multiconfig_labels_overwrite_label} />
                        </FormLabel>
                        <FormCheckbox
                            name='override'
                            control={control}
                        />
                    </InputWrapper>
                </FormGroup>
                
                { isOverrideCheckd && (
                    <FormGroup title={i18nKeys.multiconfig_label_override_warning_title}>
                        <Warning>
                            <TransMessage {...i18nKeys.multiconfig_label_override_warning_message} />
                        </Warning>
                    </FormGroup>
                )}
                
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty || submitting}
                        id='submitBtn'
                        type='submit'
                    >
                        { submitting ? <CircularProgress size={40} thickness={5} /> : <TransMessage {...i18nKeys.multiconfig_labels_button_label} />}
                    </CustomButton>
                </FormButtonGroup>
            </FormFooter>
        </FormWrapper>
    )
}
export default EditLabelsForm