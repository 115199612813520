
import { CustomCheckbox } from 'components/CustomControls/CustomCheckbox'
import TransMessage from 'components/TransMessage/TransMessage'
import React, { useState } from 'react'
import colors from 'style/colors'
import styled from 'styled-components'
import { timeProfileDaysEnum, timeStartAndEndValuesMs } from 'types/timeprofile'

const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 25px;
`

const Row = styled.div`
    display: flex;
    height: 28px;
    margin-bottom: 4px;
`

const Box = styled.div`
    height: 100%;
    margin-right: 4px;
    width: 28px;
    position: relative;
    text-align: center;
    border-radius: 4px;
    &:last-child {
        margin-right: 0;
    }
    overflow: hidden;
    background-color: ${colors.shades.MID_SHADE};
`

const HeaderBox = styled(Box)`
    background-color: transparent;
`

const Label = styled.span`
    width: 28px;
    height: 28px;
    font-size: 10px;
    font-family: LatoSemibold;
    line-height: 24px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    display: grid;
    place-items: center;
    margin-right: 4px;
`

const BoxedFilledPart = styled(Box)`
    background-color: ${colors.primary.DEFAULT_PRIMARY};
    position: absolute;
    height: 100%;
    width: 29px;
    left: 0;
    top: 0;
`

const StyledCheckBox = styled(CustomCheckbox)`
    padding: 0 16px;
`

const Text = styled.div`
    width: 100px;
    font-size: 10px;
    font-family: LatoSemibold;
    line-height: 24px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
`

type TProps = {
    disabled?: boolean
    rowData: [number, number][][]
    onRowSelectChange: (index: number | undefined) => void
}

type BoxDataType = {
    index?: number
    left?: number
    width?: number
    inSameBox?: boolean
}

const columnMarkers = [
    '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
    '19', '20', '21', '22', '23'
]
const columnsCount = columnMarkers.length
const maximumRowValue = timeStartAndEndValuesMs.MAX

const BoxedRangesGrid = (props: TProps) => {
    const [selectedRow, SetSelectedRow] = useState<number | undefined>(undefined)

    const rowBoxes: BoxDataType[][][] = []

    const calculateRowBoxTypes = () => {
        const singleColumnStep = maximumRowValue / columnsCount
        let lowerRangeIndex
        let upperRangeIndex
        let lowerBoundary
        let upperBoundary
        let currentArr: BoxDataType[] = []
        let currentObj: BoxDataType = {}
        
        for (let index = 0; index < props.rowData.length; index++) {
            rowBoxes[index] = []
            const ranges = props.rowData[index]
            ranges.sort((a: [number, number], b: [number, number]) => a[0] - b[0])
            
            for (const range of ranges) {
                lowerRangeIndex = Math.floor(range[0] / singleColumnStep)
                upperRangeIndex = Math.floor((range[1] / singleColumnStep))

                // Handle case when range is inside same hour
                if (lowerRangeIndex === upperRangeIndex) {
                    lowerBoundary = lowerRangeIndex * singleColumnStep
                    upperBoundary = lowerBoundary + singleColumnStep
                    currentObj.inSameBox = true
                    currentObj.index = lowerRangeIndex
                    currentObj.left = ((range[0] - lowerBoundary) / (upperBoundary - lowerBoundary)) * 100
                    const rightSpace = ((upperBoundary - range[1]) / (upperBoundary - lowerBoundary)) * 100
                    currentObj.width = 100 - currentObj.left - rightSpace

                    // Push range information to the object
                    currentArr = [currentObj, currentObj]
                    rowBoxes[index].push(currentArr)
                } else {
                    // Calculate range, lower boundary
                    lowerBoundary = lowerRangeIndex * singleColumnStep
                    upperBoundary = lowerBoundary + singleColumnStep
                    currentObj.index = lowerRangeIndex
                    currentObj.left = ((range[0] - lowerBoundary) / (upperBoundary - lowerBoundary)) * 100
                    currentObj.width = 100 - currentObj.left

                    currentArr.push(currentObj)
                    currentObj = {}

                    // Calculate upper boundary
                    lowerBoundary = upperRangeIndex * singleColumnStep
                    upperBoundary = lowerBoundary + singleColumnStep
                    currentObj.index = upperRangeIndex
                    currentObj.width = ((range[1] - lowerBoundary) / (upperBoundary - lowerBoundary)) * 100
                    currentObj.left = 0
                    currentArr.push(currentObj)

                    // Push range information to the object
                    rowBoxes[index].push(currentArr)
                }

                // Reset states
                currentObj = {}
                currentArr = []
            }
        }
    }

    const renderRow = (rowIndex: number) => {
        calculateRowBoxTypes()
        let isInsideRange = false
        let currentRangeIndex = 0
        let currentRange: BoxDataType[]

        const calculateBoxRender = (boxIndex: number) => {
            currentRange = rowBoxes[rowIndex][currentRangeIndex]

            if (currentRange && boxIndex === currentRange[0].index && !isInsideRange) {
                if (currentRange[0].inSameBox === true) {
                    currentRangeIndex++
                    return (
                        <React.Fragment>
                            <BoxedFilledPart
                                style={{
                                    left: `${currentRange[0].left}%`,
                                    width: `${currentRange[0].width}%`
                                }}
                            />
                            {calculateBoxRender(boxIndex)}
                        </React.Fragment>
                    )
                }
                isInsideRange = true
                return (
                    <BoxedFilledPart
                        style={{
                            left: `${currentRange[0].left}%`,
                            width: `${currentRange[0].width}%`
                        }}
                    />
                )
            } else if (currentRange && boxIndex === currentRange[1].index && isInsideRange) {
                isInsideRange = false
                currentRangeIndex++
                return (
                    <React.Fragment>
                        <BoxedFilledPart
                            style={{
                                left: `${currentRange[1].left}%`,
                                width: `${currentRange[1].width}%`
                            }}
                        />
                        {calculateBoxRender(boxIndex)}
                    </React.Fragment>
                )
            } else if (isInsideRange) {
                return <BoxedFilledPart />
            } else {
                return null
            }
        }

        const fillArr = new Array(columnsCount).fill(null)
        return fillArr.map((_: null, index: number) => (
            <Box key={index}>
                {calculateBoxRender(index)}
            </Box>
        ))
    }

    const handleCheckBoxClick = (checked: boolean, index: number) => {
        if (!checked) {
            props.onRowSelectChange(undefined)
            SetSelectedRow(undefined)
        } else {
            props.onRowSelectChange(index)
            SetSelectedRow(index)
        }
    }

    return (
        <Root>
            <Row style={{marginLeft: '156px'}}>
                {columnMarkers.map((marker: string) => (
                    <HeaderBox key={marker}>
                        <Label>{marker}</Label>
                    </HeaderBox>
                ))}
            </Row>
            {
                props.rowData.map((_: [number, number][], index: number) => (
                    <Row key={index}>
                        <StyledCheckBox disabled={props.disabled} checked={selectedRow === index} onChange={(_, checked) => handleCheckBoxClick(checked, index)} />
                        <Text>
                            <TransMessage {...timeProfileDaysEnum[index].translation} />
                        </Text>
                        {renderRow(index)}
                    </Row>
                ))
            }
        </Root>
    )
}

export default BoxedRangesGrid