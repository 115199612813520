import TableHelper from 'components/Tables/TableHelper'
import React, { useCallback, useEffect, useState } from 'react'
import { restoreGridLayoutsAsync, getGridLayoutsAsync, setGridLayoutAsync } from 'state/slices/profileSlice'
import { useAppDispatch, useAppSelector } from 'state/store'
import { TTableColumn, TTableColumnProperty, TTableInfo } from 'types/ui/table'

export type TUseColumnsHandler = {
    onToggleColumn: (key: string, checked: boolean) => void
    saveGridLayout: () => void
    restoreDefaultLayout: () => void
    columns: TTableColumn[]
    gridLayoutChanged: boolean
    gridLayoutDefault: boolean
}

type TUseColumnHandlerProps = TTableInfo & {
    dynamicColumns?: TTableColumnProperty[]
    initialColumns?: TTableColumn[]
}

const setColumnDisplayValue = (columns: TTableColumn[], visibleColumnNames: string[]) : TTableColumn[] => {
    const newColumns = [...columns]
    newColumns.forEach(c => c.displayed = visibleColumnNames.includes(c.key))
    return newColumns
}

const useColumnsHandler = (props: TUseColumnHandlerProps) : TUseColumnsHandler => {
    const gridLayouts = useAppSelector(state => state.profile.gridLayouts)
    const dispatch = useAppDispatch()

    const [columns, setColumns] = useState<TTableColumn[]>(Object.values(props.columnConfig))
    const [dynamicColumns, setDynamicColumns] = useState<TTableColumnProperty[]>(props.dynamicColumns ?? [])
    const [gridLayoutChanged, setGridLayoutChanged] = useState<boolean>(false)
    const [gridLayoutDefault, setGridLayoutDefault] = useState<boolean>(true)

    const filterPropertiesFromDuplicates = useCallback((items: TTableColumn[]) =>
        items.filter(column => !Object.values(props.columnConfig).some(col => col.key === column.key)), [props.columnConfig])

    useEffect(() => {
        dispatch(getGridLayoutsAsync())
    }, [dispatch])

    useEffect(() => {
        if (gridLayoutChanged || !props.dynamicColumns || (props.dynamicColumns.length === dynamicColumns.length && props.dynamicColumns.every(dc => !!dynamicColumns.find(c => c.displayKey === dc.displayKey)))) {
            return
        }

        setDynamicColumns(props.dynamicColumns)
    }, [dynamicColumns, gridLayoutChanged, props.dynamicColumns])

    useEffect(() => {
        if (gridLayoutChanged) {
            return
        }
        
        let newDynamicColumns: TTableColumn[] = []
        if (props.dynamicColumns && props.dynamicColumns.length) {
            const mappedColumns = TableHelper.prepareTableColumns(props.dynamicColumns)
            const filteredColumns = filterPropertiesFromDuplicates(mappedColumns)
            newDynamicColumns = [...Object.values(props.columnConfig), ...filteredColumns]
        }

        const currentLayout = gridLayouts.find(layout => layout.tableName === props.id)
        if (!currentLayout) {
            setColumns(prevState => {
                if (newDynamicColumns.length && prevState.length !== newDynamicColumns.length) {
                    return setColumnDisplayValue(newDynamicColumns, props.defaultLayout)
                }
                return setColumnDisplayValue(prevState, props.defaultLayout)
            })
        } else if (currentLayout) {
            setColumns(prevState => {
                if (newDynamicColumns.length && prevState.length !== newDynamicColumns.length) {
                    return setColumnDisplayValue(newDynamicColumns, currentLayout.visibleColumnNames)
                }
                return setColumnDisplayValue(prevState, currentLayout.visibleColumnNames)
            })
            setGridLayoutDefault(false)
        }

    }, [filterPropertiesFromDuplicates, gridLayoutChanged, gridLayouts, props.columnConfig, props.defaultLayout, props.dynamicColumns, props.id])

    const onToggleColumn = (key: string, checked: boolean) => {
        const prevColumns = [...columns]
        const changedColumn = prevColumns.find(column => column.key === key)

        if (!changedColumn) return

        changedColumn.displayed = checked

        setColumns(prevColumns)
        setGridLayoutChanged(true)
    }

    const saveGridLayout = () => {
        let currentGridLayout = gridLayouts.find(layout => layout.tableName === props.id)

        if (!currentGridLayout) {
            currentGridLayout = {
                id: 0,
                tableName: props.id,
                visibleColumnNames: []
            }
        } else {
            currentGridLayout = {...currentGridLayout}
        }

        currentGridLayout.visibleColumnNames = columns.filter(col => col.displayed).map(col => col.key)

        dispatch(setGridLayoutAsync(currentGridLayout))
        setGridLayoutChanged(false)
        setGridLayoutDefault(false)
    }

    const restoreDefaultLayout = () => {
        const currentGridLayout = gridLayouts.find(layout => layout.tableName === props.id)

        if (currentGridLayout) {
            dispatch(restoreGridLayoutsAsync(currentGridLayout.id))
            setColumns((prevState) => {
                prevState.forEach(column => column.displayed = props.defaultLayout.includes(column.key))
                return prevState
            })
        }

        setGridLayoutChanged(false)
        setGridLayoutDefault(true)
    }

    return {
        onToggleColumn,
        saveGridLayout,
        restoreDefaultLayout,
        columns,
        gridLayoutChanged,
        gridLayoutDefault
    }
}

export default useColumnsHandler