import { yupResolver } from '@hookform/resolvers/yup'
import { CustomButton } from 'components/CustomControls/CustomButton'
import CustomTooltip from 'components/CustomTooltip/CustomTooltip'
import { FormWrapper, FormContent, FormGroup, InputWrapper, FormLabel, FormInput, FormCheckbox, FormFooter, FormButtonGroup, Instructions } from 'components/FormControls/FormControls'
import { FormNumberInput } from 'components/FormControls/FormNumberInput'
import Icon, { Icons } from 'components/Icon/Icon'
import { useModal } from 'hooks/modalProvider'
import TransMessage from 'components/TransMessage/TransMessage'
import { EMessageType } from 'enums/enums'
import Validation from 'helpers/Validation'
import { i18nKeys } from 'i18n/keys'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import SiteService from 'services/siteService'
import { showMessage } from 'state/slices/uiSlice'
import { useAppDispatch } from 'state/store'
import styled from 'styled-components'
import { TDeployMonitoringPlugin } from 'types/businessLogic/site'
import { TModalProps } from 'types/ui/modal'
import * as yup from 'yup'

const Warning = styled.div`
    color: red;
    //width: '300px';
    font-size: '16px';
    max-width: 800px;
    font-family: LatoSemibold;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: justify;
	text-align-last: none;
`

type TDeployMonitoringPluginProps = TModalProps & {
    host: string
}

const DeployMonitoringPlugin = (props: TDeployMonitoringPluginProps) => {
    const { host } = props
    const {closeModal} = useModal()
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const handleDeploySubmit = useCallback(async (formValues: TDeployMonitoringPlugin) : Promise<void> => {
        try {
            await SiteService.deployMonitoringPlugin(formValues)
            dispatch(showMessage({message: i18nKeys.deploy_monitoring_plugin_success_message, type: EMessageType.SUCCESS}))
        } catch (err) {
            dispatch(showMessage({message: i18nKeys.deploy_monitoring_plugin_error_message, type: EMessageType.ERROR}))
        }

        closeModal()
    }, [dispatch, closeModal])
    
    const defaultValue : TDeployMonitoringPlugin = useMemo(() => ({
        gatewayHost: host,
        gatewayPort: 54444,
        pluginHost: '',
        pluginPort: 56000,
        isService: true,
        serviceName: 'VMS_Umbrella_Monitoring_Plugin',
        workingDir: ''
    }), [host])

    const validationSchema = yup.object().shape({
        gatewayHost: yup.string()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .matches(Validation.config.HOSTNAME, intl.formatMessage(i18nKeys.deploy_monitoring_plugin_input_validation_host))
            .required(),
        gatewayPort: yup.number()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .required()
            .test('port', intl.formatMessage(i18nKeys.deploy_monitoring_plugin_input_validation_port), Validation.isValidPortNumber),
        pluginHost: yup.string()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .matches(Validation.config.HOSTNAME, intl.formatMessage(i18nKeys.deploy_monitoring_plugin_input_validation_host))
            .required(),
        pluginPort: yup.number()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .required()
            .test('port', intl.formatMessage(i18nKeys.deploy_monitoring_plugin_input_validation_port), Validation.isValidPortNumber),
        serviceName: yup.string().optional()
            .when('asAService', { is: true, then:
            yup.string()
                .matches(Validation.config.SERVICE_NAME_REGEX)
                .required(intl.formatMessage(i18nKeys.general_validation_required_field))}
            ),
        workingDir: yup.string()
            .typeError(intl.formatMessage(i18nKeys.general_validation_required_field))
            .required()
            .test('path', intl.formatMessage(i18nKeys.deploy_monitoring_plugin_input_validation_working_dir), Validation.isValidDirPath),
    })

    const { handleSubmit, formState, control, } = useForm<TDeployMonitoringPlugin>({
        mode: 'onChange',
        defaultValues: defaultValue,
        resolver: yupResolver(validationSchema)
    })
    
    const { isValid, isValidating, isSubmitting, isDirty } = formState
    return (
        <FormWrapper onSubmit={handleSubmit(handleDeploySubmit)}>
            <FormContent>
                <FormGroup title={i18nKeys.deploy_monitoring_plugin_warning_label}>
                    <Warning>
                        <TransMessage {...i18nKeys.deploy_monitoring_plugin_input_warning_label} />
                    </Warning>
                </FormGroup>
                <FormGroup title={i18nKeys.deploy_monitoring_plugin_required_parameters_label}>
                    <InputWrapper>
                        <FormLabel htmlFor='gatewayHost'>
                            <TransMessage {...i18nKeys.deploy_monitoring_plugin_input_gateway_host_label} />
                        </FormLabel>
                        <FormInput
                            name='gatewayHost'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='gatewayPort'>
                            <TransMessage {...i18nKeys.deploy_monitoring_plugin_input_gateway_port_label} />
                        </FormLabel>
                        <FormNumberInput
                            name='gatewayPort'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='pluginHost'>
                            <TransMessage {...i18nKeys.deploy_monitoring_plugin_input_plugin_host_label} />
                        </FormLabel>
                        <FormInput
                            name='pluginHost'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='pluginPort'>
                            <TransMessage {...i18nKeys.deploy_monitoring_plugin_input_plugin_port_label} />
                        </FormLabel>
                        <FormNumberInput
                            name='pluginPort'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='isService'>
                            <TransMessage {...i18nKeys.deploy_monitoring_plugin_input_as_a_service_label} />
                        </FormLabel>
                        <FormCheckbox
                            name='isService'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='serviceName'>
                            <TransMessage {...i18nKeys.deploy_monitoring_plugin_input_service_name_label} />
                        </FormLabel>
                        <FormInput
                            name='serviceName'
                            control={control}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <FormLabel htmlFor='workingDir'>
                            <TransMessage {...i18nKeys.deploy_monitoring_plugin_input_working_dir_label} />
                        </FormLabel>
                        <FormInput
                            name='workingDir'
                            control={control}
                            endIcon={
                                <CustomTooltip title={intl.formatMessage({...i18nKeys.deploy_monitoring_plugin_input_working_dir_tooltip})}>
                                    <Icon d={Icons.INFO} size={16} />
                                </CustomTooltip>
                            }
                        />
                    </InputWrapper>
                </FormGroup>
            </FormContent>
            <FormFooter>
                <FormButtonGroup>
                    <CustomButton
                        disabled={!isValid || isValidating || isSubmitting || !isDirty}
                        id='submitBtn'
                        type='submit'
                        endIcon={<Icon d={Icons.DOWNLOAD} />}
                    >
                        <TransMessage {...i18nKeys.deploy_monitoring_plugin_download_installer_button} />
                    </CustomButton>
                </FormButtonGroup>
                <Instructions>
                    <TransMessage {...i18nKeys.deploy_monitoring_plugin_instructions} />
                </Instructions>
            </FormFooter>
        </FormWrapper>
    )
}

export default DeployMonitoringPlugin