import Copyright from 'components/Copyright/Copyright'
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs'
import {CustomButton} from 'components/CustomControls/CustomButton'
import TransMessage from 'components/TransMessage/TransMessage'
import Icon, { Icons } from 'components/Icon/Icon'
import LanguagePicker from 'components/LanguagePicker/LanguagePicker'
import { i18nKeys } from 'i18n/keys'
import React, { PropsWithChildren, useCallback, useState } from 'react'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import fonts from 'style/fonts'
import images from 'style/images'
import shadows from 'style/shadows'
import sizes from 'style/sizes'
import zIndex from 'style/zIndex'
import styled from 'styled-components'
import NavigationList from 'components/NavigationList/NavigationList'
import { NavLink } from 'react-router-dom'
import { useAppSelector } from 'state/store'
import { TTranslationKey } from 'types/ui/translation'
import ImageUpload from 'views/Layout/ImageUpload'
import { TLicense } from 'types/businessLogic/license'
import LicenseHelper from 'helpers/LicenseHelper'
import UploadManagerContainer from 'components/UploadManager/UploadManagerContainer'
import LicensePopover from 'components/License/LicensePopover'
import DateHelper from 'helpers/DateHelper'
import NotificationManagerContainer from 'components/NotificationManager/NotificationManagerContainer'
import SessionObserver from 'components/SessionObserver/SessionObserver'

const SideBar = styled.div<{open: boolean}>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${p => p.open ? sizes.SIDE_NAV_WIDTH : sizes.COLLAPSED_SIDE_NAV_WIDTH};
  height: 100vh;
  background-color: #303030;
  transition: 0.2s ease-in;
  z-index: ${zIndex.SIDE_NAV};
  box-shadow: ${shadows.SECONDARY};
  & > div:last-child {
    background-color: transparent;
    height: 100vh
  }
`

const AppBar = styled.div`
  height: ${sizes.APP_BAR_HEIGHT};
  background-color: ${colors.shades.PURE_WHITE};
  color: black;
  align-items: center;
  padding: 0px 24px;
  display: flex;
  justify-content: 'space-between';
  z-index: ${zIndex.MEDIUM};
  box-shadow: ${shadows.SECONDARY_BOTTOM_ONLY};
`

const Main = styled.main<{open: boolean}>`
  margin-left: ${p => p.open ? sizes.SIDE_NAV_WIDTH : sizes.COLLAPSED_SIDE_NAV_WIDTH};
  height: 100vh;
  transition: 0.2s ease-in;
  background-color: #f4f4f4;
  overflow: hidden;
`

const Content = styled.div`
  height: calc(100vh - 56px);
  overflow: auto;
  position: relative;
`

const Header = styled.div`
  height: 96px;
  background-color: black;
  margin-bottom: 8px;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const ProfileDrawer = styled.div<{open: boolean}>`
  position: absolute;
  width: ${p => p.open ? '100vw' : '0px'};
  height: 100vh;
  z-index: ${zIndex.PROFILE_BAR};
  top: 0;
  right: 0;
  transition: 0.2s ease-in;

  & > .hitzone {
    width: 100%;
    height: 100%;
  }

  & > .content {
    position: absolute;
    overflow: hidden;
    width: ${p => p.open ? sizes.PROFILE_DRAWER : '0px'};
    height: calc(100% - ${sizes.APP_BAR_HEIGHT});
    top: ${sizes.APP_BAR_HEIGHT};
    right: 0;
    display: flex;
    flex-direction: column;
    transition: 0.2s ease-in;
    background-color: white;
  }
`

const UserBarClone = styled(ProfileDrawer)`
  box-shadow: ${shadows.SECONDARY};
  z-index: ${zIndex.BELOW};
`

const ProfileInfo = styled.div`
  padding: 25px 40px;
  display: flex;
  margin-top: -56px;
  flex-direction: column;
  border-bottom: 1px solid #c2c0bf;
  & > div {
    font-family: ${fonts.PRIMARY_HEAVY};
    display: block;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 4px;
    letter-spacing: 1px;
  }
`

const ToggleButton = styled.div`
  position: absolute;
  right: -20px;
  top: 76px;
  padding: 8px;
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: ${borderRadius.TERTIARY};
  background-color: ${colors.primary.DEFAULT_PRIMARY};
  box-shadow: ${shadows.PRIMARY};
  cursor: pointer;
  fill: ${colors.shades.PURE_WHITE};
`

const UserBlock = styled.div`
  position: relative;
  right: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: ${fonts.PRIMARY_SEMIBOLD};
  
  & > span {
    padding-left: 8px;
    padding-right: 16px;
    letter-spacing: 0.4px;
  }

  :focus {
    color: ${colors.primary.DEFAULT_PRIMARY};
  }

  :hover {
    color: ${colors.primary.DEFAULT_PRIMARY};
    
    & > svg {
      fill: ${colors.primary.DEFAULT_PRIMARY};
    }
  }
`

const HeaderImage = styled.img`
  max-height: 42px;
  margin-left: 20px;
`

const UserImage = styled.img`
  height: 360px;
`

const UserThumbnail = styled.img`
    max-width: 32px;
    max-height: 32px;
    border-radius: 50%;
`

const LicenseWarningIcon = styled(Icon).attrs({
    size: 32,
    d: Icons.WARNING
})`
  margin-left: 16px;
  cursor: pointer;
  fill: ${colors.extra.WARNING};
`

const ProfileInfo2 = styled.div`
    flex: 1;
    display: flex;
    padding: 40px 32px 0 40px;
    flex-direction: column;
    justify-content: space-between;
`

const ProfileInfo2Wrapper = styled.div`
  display: block;

  & > span {
    display: block;
    font-size: 16px;
    font-family: LatoHeavy;
    line-height: 24px;
    letter-spacing: 0.4px;
    padding-bottom: 40px;
    text-transform: uppercase;
  }

  & > table {
    width: 100%;
    margin-bottom: 40px;
    font-family: LatoRegular, Courier;
    font-size: 16px;
    line-height: 24px;

    & td {
      max-width: 135;
      vertical-align: top;
    }
    & tr:not(:last-of-type) > td {
      padding-bottom: 24px;
    }
    & tr td:first-of-type {
      font-family: ${fonts.PRIMARY_SEMIBOLD};
      padding-right: 8px;
    }
  }
`

const ButtonWrapper = styled.div`
  margin-bottom: 16px;
  & button {
    margin-bottom: 24px;
    cursor: pointer;
  }
`

const FixedCopyright = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 16px;
  width: 100%;
  height: 80px;
`

type TProps = {
    refreshTimestamp: number
    onLogout: () => void
    onUploadImage: (params: any) => void
    onError: (message: TTranslationKey) => void
    onIsNavbarCollapsedChanged: (value: boolean) => void
    username: string
    roles: string[]
    lastLogin: string
    license: TLicense
  }

const Layout = (props: PropsWithChildren<TProps>) => {
    const {license} = props
    const [openProfileDrawer, setOpenProfileDrawer] = useState(false)
    const [hideLicenseWarning, setHideLicenseWarning] = useState(false)
    const [openSideBar, setOpenSideBar] = useState(true)
    const [hoverSideBar, setHoverSideBar] = useState(false)
    const [sidebarStyle, setSidebarStyle] = useState({})
    const {version} = useAppSelector(store => store.ui)
    
    const handleToggelButtonOnClick = useCallback(() => {
        props.onIsNavbarCollapsedChanged(openSideBar)
        setOpenSideBar(!openSideBar)
        setHoverSideBar(false)
        setSidebarStyle({ pointerEvents: 'none' })
        setSidebarStyle({ pointerEvents: 'auto' })
    }, [props, openSideBar])

    const onMouseEnter = useCallback(() => setHoverSideBar(true), [])
    const onMouseLeave = useCallback(() => setHoverSideBar(false), [])

    const showLicenseWarning = LicenseHelper.showLicenseWarning(license)

    return <>
        {
            (showLicenseWarning && !hideLicenseWarning) &&
            <LicensePopover license={license} onClose={() => setHideLicenseWarning(true)} />
        }
        <SideBar open={openSideBar || hoverSideBar} style={sidebarStyle}>
            <ToggleButton onClick={() => handleToggelButtonOnClick()} onMouseEnter={() => {
                if (hoverSideBar) onMouseEnter()
            } } onMouseLeave={() => {
                if (hoverSideBar) onMouseLeave()
            } }>
                <IconWrapper>
                    <Icon d={Icons.ARROW_RIGHT} rotate={openSideBar ? 90 : -90} />
                </IconWrapper>
            </ToggleButton>
            <div onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
                <Header>
                    {
                        (openSideBar || hoverSideBar) ? <HeaderImage src={images.logo} alt='Logo' /> : <HeaderImage src={images.logoSmall} alt='Logo' />
                    }
                </Header>
                <NavigationList collapsed={!(openSideBar || hoverSideBar)} />
                    
                { (openSideBar || hoverSideBar) && (
                    <FixedCopyright>
                        <Copyright version={version} />
                    </FixedCopyright>
                )}

            </div>
        </SideBar>
        <Main open={openSideBar}>
            <AppBar>
                { showLicenseWarning && <LicenseWarningIcon onClick={() => setHideLicenseWarning(false)} /> }
                <NotificationManagerContainer />
                <UploadManagerContainer />
                <BreadCrumbs />
                <UserBlock id='appBarUser' onClick={() => setOpenProfileDrawer(!openProfileDrawer)}>
                    <Icon d={Icons.ARROW_RIGHT} rotate={openProfileDrawer ? -90 : 90} />
                    <span>{props.username}</span>
                    <UserThumbnail src={`/ClientAPI/Profile/ProfilePicture?username=${props.username}&thumbnail=true&${props.refreshTimestamp}`} />
                </UserBlock>
            </AppBar>
            <UserBarClone open={openProfileDrawer} />
            <ProfileDrawer open={openProfileDrawer}>
                <div className='hitzone' onClick={() => setOpenProfileDrawer(false)} />
                <div className='content'>
                    <UserImage src={`/ClientAPI/Profile/ProfilePicture?username=${props.username}&thumbnail=false&${props.refreshTimestamp}`} />
                    <ImageUpload onChange={props.onUploadImage} onError={props.onError} />
                    <ProfileInfo>
                        <div>{props.username}</div>
                        <LanguagePicker iconColor={colors.primary.DEFAULT_PRIMARY} />
                    </ProfileInfo>
                    <ProfileInfo2>
                        <ProfileInfo2Wrapper>
                            <span>
                                <TransMessage {...i18nKeys.profile_drawer_profile_information} />
                            </span>
                            <table>
                                <tbody>
                                    <tr>
                                        <td><TransMessage {...i18nKeys.profile_drawer_role} /></td>
                                        <td id='profileRoles'>
                                            {props.roles.join(', ')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><TransMessage {...i18nKeys.profile_drawer_last_login} /></td>
                                        <td id='profileLastLogin'>
                                            {DateHelper.convertUTCToLocal(props.lastLogin, DateHelper.formats.YEAR_TO_SECONDS_PM_AM)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ProfileInfo2Wrapper>
                        <ButtonWrapper>

                            <NavLink to='/change-password'>
                                <CustomButton fullWidth endIcon={<Icon d={Icons.CHANGE_PASSWORD} />}>
                                    <TransMessage {...i18nKeys.profile_drawer_change_password_button} />
                                </CustomButton>
                            </NavLink>
                        
                            <CustomButton fullWidth onClick={() => props.onLogout()} endIcon={<Icon d={Icons.POWER_OFF} />}>
                                <TransMessage {...i18nKeys.profile_drawer_logout_button} />
                            </CustomButton>
                        </ButtonWrapper>
                    </ProfileInfo2>
                </div>
            </ProfileDrawer>
            <Content>
                {props.children}
            </Content>
            <SessionObserver />
        </Main>
    </>
}

export default Layout
