import axiosClient from 'api/axiosClient'
import { TSettings } from 'types/businessLogic/misc'

class SettingsService {
    private static _api = axiosClient()

    public static async getSettings(): Promise<TSettings> {
        const response = await this._api.get<TSettings>('Settings')
        return response.data
    }

    public static async postSettings(settings: TSettings): Promise<void> {
        settings.licenseKey = '' // must be empty because backend validates against empty
        await this._api.post('Settings', settings)
    }

    public static async restoreDefaultSettings(): Promise<TSettings> {
        const response = await this._api.get('Settings/RestoreDefault')
        return response.data
    }
}

export default SettingsService