import dayjs from 'dayjs'
import { TLicense } from 'types/businessLogic/license'

class LicenseHelper {
    private static LICENSE_EXPIRATION_WARNING_IN_DAYS = 30

    public static willLicenseExpireSoon(date: string) : boolean {
        const now = dayjs()
        const licenseDate = dayjs(date)

        const diff = licenseDate.diff(now, 'days')
        return diff <= this.LICENSE_EXPIRATION_WARNING_IN_DAYS
    }

    public static getDaysUntilLicenseExpiration(expirationDate: string) {
        const now = dayjs()
        const licenseDate = dayjs(expirationDate)

        return licenseDate.diff(now, 'days')
    }

    public static showLicenseWarning(license: TLicense) : boolean {
        const licenseWillExpireSoon = this.willLicenseExpireSoon(license.expirationDate)
        return !!(licenseWillExpireSoon || license.unlicensedCameras)
    }
}

export default LicenseHelper