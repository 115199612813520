import { CircularProgress, FormControl } from '@mui/material'
import { FormContent, FormLabel, FormWrapper } from 'components/FormControls/FormControls'
import SiteJobCard from 'components/StatusCard/SiteJobCard'
import TransMessage from 'components/TransMessage/TransMessage'
import { i18nKeys } from 'i18n/keys'
import React, { useState, useEffect, useCallback } from 'react'
import DateHelper from 'helpers/DateHelper'
import SiteHelper from 'helpers/SiteHelper'
import JobService from 'services/jobService'
import colors from 'style/colors'
import fonts from 'style/fonts'
import fontWeights from 'style/fontWeights'
import letterSpacings from 'style/letterSpacings'
import lineHeights from 'style/lineHeights'
import styled from 'styled-components'
import { TSingleJob } from 'types/businessLogic/job'
import { EMessageType } from 'enums/enums'
import { showMessage } from 'state/slices/uiSlice'
import { dispatch } from 'state/store'

const RelatedJobTitle = styled.div`
    font-family: ${fonts.PRIMARY_HEAVY};
    font-size: 16px;
    line-height: ${lineHeights.TYPE16LineHeight};
    color: ${colors.primary.DARK_PRIMARY};
    letter-spacing: ${letterSpacings.TYPE16LS};
    font-weight: ${fontWeights.BLACK};
`
const ModalFormControl = styled(FormControl)`
    flex-direction: row;
    width: 100%;
    max-width: 680px;
    align-items: center;
    & span {
        text-align: left;
    }
`
const ModalSection = styled.div`
    margin-bottom: 30px;

    &:first-child {
        margin-top: 56px;
    }
    span {
        color: ${colors.extra.ALERT};
    }
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 80px;
`
const RelatedJobWrapper = styled.div`
    margin-left: 40px;
`
const CardClickWrapper = styled.div`
    cursor: pointer;
`
const LoaderContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

type TProps = {
    jobId: number
    switchToRelatedJob?: (jobId: number) => void
}

const SiteJobDetails = (props: TProps) => {
    const [job, setJob] = useState<TSingleJob>()

    const getJob = useCallback(async () => {
        try {
            const result = await JobService.getSingleJob(props.jobId)
            setJob(result)
        } catch {
            dispatch(showMessage({message: i18nKeys.error_failed_request, type: EMessageType.ERROR}))
        }

    }, [props.jobId])

    useEffect(() => {
        getJob()
    }, [getJob])

    const onClick = useCallback(() => {
        if (job?.relatedJob && props.switchToRelatedJob) {
            props.switchToRelatedJob(job.relatedJob.id)
        }
    }, [job?.relatedJob, props])

    const getLastJobState = (singleJob: TSingleJob) =>
        singleJob.states.length ? singleJob.states[singleJob.states.length - 1] : undefined

    const getLastJobStateDate = (singleJob: TSingleJob) => {
        const lastState = getLastJobState(singleJob)
        return lastState ? lastState.stateReachedAt : undefined
    }

    let relatedJobLastState
    let relatedJobLastStateDate

    if (!job) return (
        <LoaderContainer>
            <CircularProgress size={200} thickness={2} />
        </LoaderContainer>
    )

    const lastState = getLastJobState(job)
    const lastStateDate = getLastJobStateDate(job)

    if (job.relatedJob) {
        relatedJobLastState = getLastJobState(job.relatedJob)
        relatedJobLastStateDate = getLastJobStateDate(job.relatedJob)
    }

    return (
        <FormWrapper>
            <FormContent>
                <Row>
                    <div>
                        <RelatedJobTitle>
                            <TransMessage {...i18nKeys.site_job_card_title} />
                        </RelatedJobTitle>
                        <SiteJobCard
                            lastState={lastState}
                            lastStateDate={lastStateDate}
                            createdAt={job.createdAt}
                        />
                    </div>
                    {
                        job.relatedJob &&
                    <RelatedJobWrapper>
                        <RelatedJobTitle>
                            <TransMessage {...i18nKeys.site_job_related_undo_job} />
                        </RelatedJobTitle>
                        <CardClickWrapper onClick={onClick}>
                            <SiteJobCard
                                lastState={relatedJobLastState}
                                lastStateDate={relatedJobLastStateDate}
                                createdAt={job.relatedJob.createdAt}
                            />
                        </CardClickWrapper>
                    </RelatedJobWrapper>
                    }
                </Row>
                <ModalSection>
                    <ModalFormControl>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_job_details_description} />
                        </FormLabel>
                        <FormLabel id='siteJobDescription'>
                            {job.about}
                        </FormLabel>
                    </ModalFormControl>
                </ModalSection>
                <ModalSection>
                    <ModalFormControl>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_job_details_comment} />
                        </FormLabel>
                        <FormLabel id='siteJobComment'>
                            {job.comment}
                        </FormLabel>
                    </ModalFormControl>
                </ModalSection>
                <ModalSection>
                    <ModalFormControl>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_job_details_type} />
                        </FormLabel>
                        <FormLabel id='siteJobType'>
                            {job.job}
                        </FormLabel>
                    </ModalFormControl>
                </ModalSection>
                <ModalSection>
                    <ModalFormControl>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_job_details_site} />
                        </FormLabel>
                        <FormLabel id='siteJobSite'>
                            {job.site}
                        </FormLabel>
                    </ModalFormControl>
                </ModalSection>
                {
                    !!job.createdBy &&
                    <ModalSection>
                        <ModalFormControl>
                            <FormLabel>
                                <TransMessage {...i18nKeys.site_job_details_createdBy} />
                            </FormLabel>
                            <FormLabel id='siteJobCreatedBy'>
                                {job.createdBy}
                            </FormLabel>
                        </ModalFormControl>
                    </ModalSection>
                }
                <ModalSection>
                    <ModalFormControl>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_job_details_created} />
                        </FormLabel>
                        <FormLabel id='siteJobCreatedAt'>
                            {DateHelper.formatDate(job.createdAt, DateHelper.formats.DATE_TIME_SECONDARY)}
                        </FormLabel>
                    </ModalFormControl>
                </ModalSection>
                {
                    job.states.map(state => (
                        <React.Fragment key={state.state}>
                            <ModalSection>
                                <ModalFormControl>
                                    <FormLabel>
                                        <TransMessage {...SiteHelper.getJobStateIntlKey(state.state)} />:
                                    </FormLabel>
                                    <FormLabel id={`siteJobState${state.state}`}>
                                        {DateHelper.formatDate(state.stateReachedAt, DateHelper.formats.DATE_TIME_SECONDARY)}
                                    </FormLabel>
                                </ModalFormControl>
                            </ModalSection>
                        </React.Fragment>
                    ))
                }
                {
                    job.message &&
                    <ModalSection>
                        <ModalFormControl>
                            <FormLabel>
                                <TransMessage {...i18nKeys.site_job_details_message} />
                            </FormLabel>
                            <FormLabel id='siteJobMessage'>
                                {job.message}
                            </FormLabel>
                        </ModalFormControl>
                    </ModalSection>
                }
                <ModalSection>
                    <ModalFormControl>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_job_details_execution_time} />
                        </FormLabel>
                        <FormLabel id='siteJobExecutionTime'>
                            {job.executionTime ? DateHelper.formatUtcDate(job.executionTime, DateHelper.formats.DATE_TIME_SECONDARY) : '-'}
                        </FormLabel>
                    </ModalFormControl>
                </ModalSection>
                <ModalSection>
                    <ModalFormControl>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_jobs_table_head_undo_time} />
                        </FormLabel>
                        <FormLabel id='siteJobUndoTime'>
                            {job.undoTime ? DateHelper.formatUtcDate(job.undoTime, DateHelper.formats.DATE_TIME_SECONDARY) : '-'}
                        </FormLabel>
                    </ModalFormControl>
                </ModalSection>
                <ModalSection>
                    <ModalFormControl>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_job_details_retries} />
                        </FormLabel>
                        <FormLabel id='siteJobRetries'>
                            {job.retries ? job.retries : 0}
                        </FormLabel>
                    </ModalFormControl>
                </ModalSection>
                <ModalSection>
                    <ModalFormControl>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_job_details_sequence_id} />
                        </FormLabel>
                        <FormLabel id='siteJobSequenceId'>
                            {job.sequenceId}
                        </FormLabel>
                    </ModalFormControl>
                </ModalSection>
                <ModalSection>
                    <ModalFormControl>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_job_details_sequence_order} />
                        </FormLabel>
                        <FormLabel id='siteJobSequenceOrder'>
                            {job.sequenceOrder}
                        </FormLabel>
                    </ModalFormControl>
                </ModalSection>
                <ModalSection>
                    <ModalFormControl>
                        <FormLabel>
                            <TransMessage {...i18nKeys.site_job_details_sequence_total} />
                        </FormLabel>
                        <FormLabel id='siteJobSequenceTotal'>
                            {job.sequenceTotalCount}
                        </FormLabel>
                    </ModalFormControl>
                </ModalSection>
            </FormContent>
        </FormWrapper>
    )
}

export default SiteJobDetails