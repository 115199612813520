import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import React, { useEffect, useState } from 'react'
import { Area, AreaChart, Tooltip, XAxis, YAxis } from 'recharts'
import DateHelper from 'helpers/DateHelper'
import borderRadius from 'style/borderRadius'
import colors from 'style/colors'
import fonts from 'style/fonts'
import fontWeights from 'style/fontWeights'
import styled from 'styled-components'
import { uid } from 'uid'

dayjs.extend(advancedFormat)

const ToolTipWrapper = styled.div`
    background-color: ${colors.primary.DARK_PRIMARY};
    border-radius: ${borderRadius.SECONDARY};
    color: ${colors.shades.PURE_WHITE};
    padding: 16px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-family: ${fonts.PRIMARY_REGULAR};
    font-weight: ${fontWeights.SEMI_BOLD};
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: 0.5px;
`

export type TData = {
    value: number
    time: number
}

type TCustomAreaChart = {
    width: number
    height: number
    from: Dayjs
    till: Dayjs
    graphColor: string
    data: TData[]
    unit: string
}

const StyledAreaChart = styled(AreaChart)`
    & .recharts-layer {
        font-size: 10px;
    }
`

const tickFormatHours = 'Do HH:mm' // ex. 20th 21:00
const tickFormatDays = 'MMM D' // ex. October 20th
const tickFormatMinutes = 'HH:mm' // ex. 21:00

const timeBreakPoints = {
    days : 96,
    hours: 6
}

const CustomAreaChart = (props: TCustomAreaChart) => {
    const [yAxisScope, setYAxisScope] = useState('days')

    const xAxisTickFormatter = (value: number) => {
        if (yAxisScope === 'hours') {
            return dayjs.unix(value).format(tickFormatHours)
        } else if (yAxisScope === 'days') {
            return dayjs.unix(value).format(tickFormatDays)
        } else {
            return dayjs.unix(value).format(tickFormatMinutes)
        }
    }

    const yAxisTickFormatter = (value: any) : string => {
        const rounded = Math.round(value * 100) / 100
        return rounded.toString()
    }

    const findTicks = (from: Dayjs, to: Dayjs) => {
        const tick = (to.unix() - from.unix()) / 4  // magic number 4
        return [0, 1, 2, 3, 4].map((i: number) => from.unix() + tick * i)
    }

    const renderTooltip = (point : any) => {
        const current = props.data.find((item: TData) => item.time === point.label)
        if (current) {
            return <ToolTipWrapper>
                <span>
                    {DateHelper.convertUTCToLocal(dayjs.unix(point.label).toString(), DateHelper.formats.YEAR_TO_SECONDS_PM_AM)}
                </span>
                <span>
                    {Math.round(current.value * 100) / 100} {props.unit}
                </span>
            </ToolTipWrapper>
        } else return null
    }

    const trimData = (ticks: number[], data: TData[]): TData[] => {
        const temp = [...data]
        
        for (let index = 0; index < ticks.length; index++) {
            const tick = ticks[index]

            if (temp.findIndex(x => x.time === tick) === -1) {
                const neighbor = temp.find(x => x.time > tick && x.time < ticks[index + 1])
                
                temp.push({
                    time: tick,
                    value: neighbor?.value ?? 0
                })
            }
            
        }

        return temp.sort((a, b) => a.time - b.time)
    }

    useEffect(() => {
        const diff = props.till.diff(props.from, 'hours')
        if (diff > timeBreakPoints.days && yAxisScope !== 'days') {
            setYAxisScope('days')
        }
        if (diff <= timeBreakPoints.days && diff > timeBreakPoints.hours && yAxisScope !== 'hours') {
            setYAxisScope('hours')
        }
        if (diff <= timeBreakPoints.hours && yAxisScope !== 'minutes') {
            setYAxisScope('minutes')
        }
    }, [props.from, props.till, yAxisScope])
   
    const ticks = findTicks(props.from, props.till)
    const id = uid()

    const data = trimData(ticks, props.data)

    return (
        <StyledAreaChart width={props.width} height={props.height} data={data}
            margin={{ top: 10, right: 30, left: 10, bottom: 10 }}>
            <defs>
                <linearGradient id={id} x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='0%' stopColor={props.graphColor} stopOpacity={0.8} />
                    <stop offset='100%' stopColor={props.graphColor} stopOpacity={0} />
                </linearGradient>
                
            </defs>
            <XAxis
                tickMargin={10}
                interval={0}
                ticks={ticks}
                dataKey='time'
                type='number'
                scale='time'
                tickFormatter={xAxisTickFormatter}
                domain={[props.from.unix(), props.till.unix()]}
            />
            <YAxis
                tickMargin={10}
                interval={0}
                dataKey='value'
                unit={props.unit}
                tickFormatter={yAxisTickFormatter}
            />
            <Tooltip
                content={renderTooltip}
            />
            <Area type='monotone' dataKey='value' stroke={props.graphColor} fillOpacity={1} fill={`url(#${id})`} />
            
        </StyledAreaChart>
    )
}
export default CustomAreaChart